import React from "react";
import { uniq } from "lodash";
import { faReply } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import { answerToQuestion, replyToMessage } from "redux/actions";
import { conversationActions, conversationType } from "../enums";
import { InboxAttachments } from "../InboxAttachments";
import { InboxAvatar } from "../InboxAvatar";
import { InboxEditorSection } from "../InboxEditorSection";
import { formatDate, getSenderName } from "../inboxUtils";
import { MessageOptions } from "../message/MessageOptions";
import useCreateConvoModalStore from "../useCreateConvoModalStore";

const InboxReplyItem = ({ isFollowUp, conversationDetails, message }) => {
  const { setOpenModalEditAnswer } = useCreateConvoModalStore();
  const [showEditor, setShowEditor] = React.useState(false);
  const { _senderId = [], _senderCompanyId = [] } = message;
  const [sender] = _senderId;
  const [senderCompany] = _senderCompanyId;
  const senderName = getSenderName(sender, senderCompany);

  const toggleShowEditor = () => {
    setShowEditor((prev) => !prev);
  };

  const handleSubmitEditor = (values) => {
    switch (conversationDetails.type) {
      case conversationType.Message:
        submitMessage(values);
        break;
      case conversationType.Question:
        submitQuestion(values);
        break;
      default:
        break;
    }
  };

  const submitMessage = (values) => {
    const params = {
      conversationId: conversationDetails._id,
      messageId: message._id,
    };
    const formData = new FormData();
    formData.append("subject", "Reply");
    formData.append("message", values.message);
    formData.append("isPrivate", values.isPrivate);

    if (values.files) {
      values.files.forEach((file) => {
        formData.append("files", file.originFileObj);
      });
    }

    const receiverCompanyIds = uniq([
      ...conversationDetails.receiverCompanyIds,
      conversationDetails._senderCompanyId[0]._id,
    ]);
    receiverCompanyIds.forEach((companyId) => {
      formData.append("receiverCompanyIds", companyId);
    });

    replyToMessage(params, formData);
    setShowEditor(false);
  };

  const submitQuestion = (values) => {
    const params = {
      conversationId: conversationDetails._id,
    };
    const formData = new FormData();
    formData.append("message", values.message);
    formData.append("isPrivate", values.isPrivate);
    for (let file of values.files || []) {
      formData.append("files", file);
    }
    answerToQuestion(params, formData);
    setShowEditor(false);
  };

  const handleOptionAction = (option) => {
    switch (option) {
      case conversationActions.editAnswer:
        setOpenModalEditAnswer(conversationDetails);
        break;
      default:
        break;
    }
  };

  return (
    <section
      key={message._id}
      className="InboxReplies--item d-flex flex-column gap-20"
    >
      <div className="d-flex justify-content-between">
        <InboxAvatar
          name={senderName}
          subtitle={`Added an answer on ${formatDate(
            message.createdAt,
            "ll, LT"
          )}`}
          imageUrl={sender?.profileImageXS}
        />
        {conversationDetails.type === conversationType.Question &&
          !isFollowUp && (
            <p>
              <MessageOptions
                isAnswer
                sender={sender}
                type={conversationType.Question}
                onOptionClick={handleOptionAction}
              />
            </p>
          )}
      </div>

      <div
        dangerouslySetInnerHTML={{
          __html: message.message,
        }}
      />

      <InboxAttachments attachments={message.attachments} />

      {showEditor && (
        <InboxEditorSection
          type={conversationDetails.type}
          onCancel={toggleShowEditor}
          onSubmit={handleSubmitEditor}
        />
      )}

      {!showEditor &&
        conversationDetails.type !== conversationType.Question && (
          <div className="d-flex align-items-center">
            <FontAwesomeIcon className="text-secondary mr-2" icon={faReply} />
            <Button
              type="submit"
              variant="link"
              className="font-14 text-secondary p-0"
              onClick={toggleShowEditor}
            >
              Reply
            </Button>
          </div>
        )}
    </section>
  );
};

export default InboxReplyItem;
