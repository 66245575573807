import React from "react";
import { connect } from "react-redux";
import { Button, Form, Row, Col } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { required, domain, url, noSpaces } from "utils/validation";
import {
  reduxFormInput,
  reduxFormTextarea,
  reduxFormSelect,
  reduxFormNumber,
} from "components/form/ReduxForm";
import { states } from "utils/dataUtil";
import { updateCompany, getCompanyById } from "redux/actions";
import { addPlacesAutoSuggestionsListener } from "components/google/places";
import Spacer from "components/layout/Spacer";
import Loader from "components/ui/Loader";
import ImageUpload from "components/form/ImageUpload";
import imageCompression from "browser-image-compression";

const addressFieldsMapping = {
  companyAddress: "address",
  companyCity: "city",
  companyState: "state",
  companyZipCode: "zip",
};

class CompanyUpdate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      compressing: false,
      companyZipCode: 0,
      previewFile: null,
      uploadedFile: null,
    };

    this.submitFn = this.submitFn.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.company !== this.props.company) {
      const { company } = this.props;

      this.setState({
        previewFile: company.companyLogo,
        companyZipCode: company.companyZipCode,
      });
    }
  }

  componentDidMount() {
    const { match } = this.props;

    getCompanyById(match.params.id);

    addPlacesAutoSuggestionsListener(
      "company-address-field",
      addressFieldsMapping,
      (data) => {
        Object.keys(data).map((key) => {
          if (key === "companyState") {
            this.props.change(key, {
              label: data[key].name,
              value: data[key].nick_name.toUpperCase(),
            });
          } else {
            this.props.change(key, data[key]);
          }

          return true;
        });
      }
    );
  }

  submitFn(data) {
    const { match } = this.props;

    const formData = new FormData();

    for (let property in data) {
      if (typeof data[property] === "object" && data[property] !== null) {
        data[property] = data[property].value;
      } else if (
        data[property] === "" ||
        data[property] === undefined ||
        data[property] === null
      ) {
        data[property] = " ";
      }

      formData.append(property, data[property]);
    }

    formData.delete("companyType");
    formData.append("companyLogo", this.state.uploadedFile);

    updateCompany(match.params.id, formData);
  }

  async handleFileChange(file) {
    this.setState({
      previewFile: URL.createObjectURL(file),
    });

    const options = {
      maxSizeMB: 0.75,
    };

    try {
      this.setState({ compressing: true });
      const compressedFile = await imageCompression(file, options);

      this.setState({
        uploadedFile: compressedFile,
        compressing: false,
      });
    } catch (error) {
      return error;
    }
  }

  render() {
    const { handleSubmit, action, initialValues } = this.props;

    if (
      !action.GET_COMPANY_BY_ID.isFetching &&
      !action.GET_MY_PROFILE.isFetching
    ) {
      const disableChrome = document.querySelectorAll(
        'input[autocomplete="off"'
      );

      for (let el of disableChrome) {
        if (el !== null) {
          el.setAttribute("autocomplete", "new-password");
        }
      }
    }

    if (action.GET_COMPANY_BY_ID.isFetched && action.GET_MY_PROFILE.isFetched) {
      const { company, whoami } = this.props;

      let isAdmin = false;
      let isCompanyMember = false;

      if (whoami.role === "Admin") {
        isAdmin = true;
      } else {
        isAdmin = false;
      }

      if (company.companyMembers?.includes(whoami._id)) {
        isCompanyMember = true;
      } else {
        isCompanyMember = false;
      }

      if (!isAdmin && !isCompanyMember) {
        return <Redirect to="/" />;
      } else {
        return (
          <>
            {this.state.compressing && <Loader files />}
            <Spacer size={50} />
            <Form className="full-width no-padding no-border">
              <Row>
                <Col xs="12">
                  <h3 className="bold">Basic Info</h3>
                  <Spacer size={30} />
                </Col>
              </Row>
              <Row>
                <Col xs="12" sm="6">
                  <Field
                    className="form-control"
                    component={reduxFormInput}
                    validate={[required, noSpaces]}
                    name="companyName"
                    type="text"
                    placeholder="Enter company name"
                    label="*Company name"
                  />
                  <Spacer size={20} />
                  <Field
                    className="form-control"
                    component={reduxFormInput}
                    validate={[url, noSpaces]}
                    name="companyWebsiteURL"
                    type="text"
                    placeholder="Enter company website"
                    label="Company website"
                  />
                  <Spacer size={20} />
                  {/* <Field
                    className="form-control"
                    component={reduxFormInput}
                    validate={[required, domain, noSpaces]}
                    name="companyDomain"
                    type="text"
                    placeholder="Enter company domain"
                    label="*Company domain"
                  />
                  <Spacer size={20} /> */}
                  <Field
                    className="form-control"
                    component={reduxFormSelect}
                    validate={[required]}
                    name="companyType"
                    type="select"
                    placeholder="Enter company type"
                    label="*Company type"
                    disabled={true}
                  />
                  <Spacer size={20} />
                  {initialValues.companyType.value === "Lender" && (
                    <>
                      <Field
                        className="form-control"
                        component={reduxFormSelect}
                        validate={[required]}
                        name="lenderType"
                        type="select"
                        placeholder="Select lender type"
                        label="*Lender Type"
                        disabled={true}
                      />
                      <Spacer size={20} />
                      {initialValues.lenderType.value === "Other" && (
                        <Field
                          className="form-control"
                          component={reduxFormInput}
                          validate={[noSpaces]}
                          name="otherLenderType"
                          type="text"
                          placeholder="Other (Lender Type)"
                          label="*Other (Lender Type)"
                          disabled={true}
                        />
                      )}
                      <Spacer size={20} />
                    </>
                  )}
                  <Field
                    className="form-control"
                    component={reduxFormInput}
                    validate={[noSpaces]}
                    name="companyLinkedin"
                    type="text"
                    placeholder="Enter LinkedIn URL"
                    label="LinkedIn URL"
                  />
                  <Spacer size={20} />
                </Col>
                <Col xs="12" sm="6">
                  <Field
                    component={ImageUpload}
                    name="companyLogo"
                    uploadButtonText="Upload Logo"
                    src={
                      this.state.previewFile !== null
                        ? this.state.previewFile
                        : null
                    }
                    onChange={this.handleFileChange}
                  />
                  <Spacer size={20} />
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  <Field
                    className="form-control"
                    component={reduxFormTextarea}
                    validate={[required, noSpaces]}
                    name="companyBio"
                    rows="10"
                    placeholder="Enter company bio"
                    label="*Company Bio"
                  />
                  <Spacer size={20} />
                </Col>
                <Col xs="12">
                  <Spacer size={20} />
                  <Col className="border-bottom" xs="12"></Col>
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  <Spacer size={20} />
                  <h3 className="bold">Address Info</h3>
                  <Spacer size={30} />
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  <Field
                    className="form-control"
                    component={reduxFormInput}
                    validate={[noSpaces]}
                    name="companyAddress"
                    type="text"
                    placeholder="Enter address"
                    label="Address"
                    id="company-address-field"
                  />
                  <Spacer size={20} />
                </Col>
                <Col xs="12">
                  <Field
                    className="form-control"
                    component={reduxFormInput}
                    validate={[noSpaces]}
                    name="companyAddress2"
                    type="text"
                    placeholder="Enter address"
                    label="Address 2"
                  />
                  <Spacer size={20} />
                </Col>
                <Col xs="12" sm="6">
                  <Field
                    className="form-control"
                    component={reduxFormInput}
                    validate={[noSpaces]}
                    name="companyCity"
                    type="text"
                    placeholder="Enter city"
                    label="City"
                  />
                  <Spacer size={20} />
                </Col>
                <Col xs="12" sm="6">
                  <Field
                    className="form-control"
                    component={reduxFormSelect}
                    // validate={[required]}
                    name="companyState"
                    type="select"
                    options={states}
                    placeholder="Pick state"
                    label="State"
                  />
                  <Spacer size={20} />
                </Col>
                <Col xs="12" sm="6">
                  <Field
                    className="form-control"
                    component={reduxFormNumber}
                    // validate={[required]}
                    name="companyZipCode"
                    placeholder="Enter zipcode"
                    label="Zipcode"
                    thousandSeparator={false}
                    value={this.state.companyZipCode}
                  />
                  <Spacer size={20} />
                </Col>
              </Row>
              <Spacer size={50} />
              <Row>
                <Col className="text-right" xs="12">
                  <Button
                    variant="primary"
                    onClick={handleSubmit(this.submitFn)}
                  >
                    Update
                  </Button>
                </Col>
              </Row>
            </Form>
            <Spacer size={50} />
          </>
        );
      }
    } else {
      return <Loader />;
    }
  }
}

function mapStateToProps(state) {
  const {
    companyName,
    companyWebsiteURL,
    // companyDomain,
    companyType,
    companyLogo,
    companyBio,
    companyLinkedin,
    companyAddress,
    companyAddress2,
    companyCity,
    companyState,
    companyZipCode,
    lenderType,
    otherLenderType,
  } = state.company.company;

  return {
    action: state.action,
    whoami: state.auth.whoami,
    company: state.company.company,
    initialValues: {
      companyName: companyName !== " " ? companyName : "",
      companyWebsiteURL: companyWebsiteURL !== " " ? companyWebsiteURL : "",
      // companyDomain: companyDomain !== " " ? companyDomain : "",
      companyLogo: companyLogo !== " " ? companyLogo : "",
      companyBio: companyBio !== " " ? companyBio : "",
      companyLinkedin: companyLinkedin !== " " ? companyLinkedin : "",
      companyAddress: companyAddress !== " " ? companyAddress : "",
      companyAddress2: companyAddress2 !== " " ? companyAddress2 : "",
      companyCity: companyCity !== " " ? companyCity : "",
      companyZipCode: companyZipCode !== " " ? companyZipCode : "",
      companyType:
        companyType && companyType !== null
          ? { label: companyType, value: companyType }
          : "Select company type",
      lenderType:
        lenderType && lenderType !== null
          ? { label: lenderType, value: lenderType }
          : "Select lender type",
      otherLenderType: otherLenderType,
      companyState:
        companyState && companyState !== null
          ? { label: companyState, value: companyState }
          : "Pick state",
    },
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "UpdateCompany",
    enableReinitialize: true,
  })(CompanyUpdate)
);
