import React, { Component } from 'react'
import { connect } from 'react-redux';
import { easeSections } from './sections';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { updateTermsheet, getTermsheet, storeSections } from 'redux/actions';
import Spacer from 'components/layout/Spacer';
import Loader from 'components/ui/Loader';
import update from 'immutability-helper'
import SectionRow from './SectionRow';
import DraggableItem from 'components/ui/DraggableItem';
import { toast } from 'react-toastify';

class EditTermsheet extends Component {
    constructor(props) {
        super(props)
        this.state = {
            columns: this.props.columns
        }
    }

    componentDidMount() {
        if (!this.props.columns.length) {
            const { termsheetId } = this.props.match.params;
            getTermsheet(termsheetId);
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.columns.length !== this.props.columns.length) {
            this.setState({ columns: this.props.columns });
        }
    }

    handleOnChange = (key, value, index) => {
        this.setState((prevState) => {
            const newColumns = [...prevState.columns];
            const column = newColumns[index];
            column[key] = value;
            newColumns[index] = column;
            return { columns: newColumns };;
        })
    }

    handleOnRemove = (index) => {
        this.setState((prevState) => {
            const newColumns = [...prevState.columns];
            newColumns.splice(index, 1);
            return { columns: newColumns };
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        let updatedCoumns = [];
        for (let i = 0; i < this.state.columns.length; i++) {
            const column = this.state.columns[i];
            let errorMessage = "";
            if (column.sectionType && !column.sectionValue) {
                errorMessage = `Please fill ${column.sectionName}`
            } else if (!column.sectionType && !column.langDesc) {
                errorMessage = `Please add language for ${column.sectionName}`
            }
            if (errorMessage) {
                toast.error(errorMessage, {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                return;
            }
            updatedCoumns.push({ ...column, order: i });
        }

        updateTermsheet(this.props.match.params.termsheetId, { columns: updatedCoumns })
    }

    handleAddRemoveSections = () => {
        const { dealId, termsheetId } = this.props.match.params;
        storeSections(this.state.columns);
        this.props.history.replace(`/deal/${dealId}/termsheet/${termsheetId}/sections`);
    }

    handleMoveCard = (dragIndex, hoverIndex) => {
        const { columns } = this.state;
        const dragCard = columns[dragIndex];
        this.setState((prevState) => ({
            columns: update(prevState.columns, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, dragCard],
                ],
            }),
        }));
    }

    render() {
        const { columns } = this.state;
        const { UPDATE_TERMSHEET, GET_TERMSHEET } = this.props.action;

        return (
            <Container>
                <Spacer size={20} />
                {(UPDATE_TERMSHEET.isFetching || GET_TERMSHEET.isFetching) &&
                    <Loader />
                }
                {
                    !columns.length &&
                    <Row>
                        <Col xs="12">
                            <p className="text-danger text-center">
                                No sections available
                                <Button variant="link" onClick={this.handleAddRemoveSections}>
                                    Add Sections
                                </Button>
                            </p>
                        </Col>
                    </Row>
                }
                {
                    columns.map((column, index) => {
                        const id = `${column.category}-${column.sectionName}`;
                        const easeSection = easeSections[id];
                        return (
                            <DraggableItem
                                key={id}
                                id={id}
                                index={index}
                                easeSection={easeSection}
                                handleOnChange={this.handleOnChange}
                                handleMoveCard={this.handleMoveCard}
                                handleOnRemove={this.handleOnRemove}
                                column={column}
                                componentToDrag={SectionRow}
                            />
                        )
                    })
                }
                {
                    columns.length ?
                        <Row >
                            <Col md={{ span: 8, offset: 2 }}>
                                <Button className="fake-link" variant="fake-link" onClick={this.handleAddRemoveSections}>Add / Remove Sections</Button>
                            </Col>
                        </Row>
                        : null
                }
                <Row>
                    <Col className="text-right">
                        <Button variant="primary" onClick={this.handleSubmit} disabled={!columns.length}>
                            SAVE & CONTINUE
                        </Button>
                    </Col>
                </Row>
                <Spacer size={20} />
            </Container>
        )
    }
}
const mapStateToProps = (state) => ({
    sections: state.termsheet.sections,
    action: state.action,
    columns: state.termsheet.termsheet.columns || [],

})
export default connect(mapStateToProps)(EditTermsheet)
