import React from "react";
import { useSelector } from "react-redux";
import { Row, Col, Button } from "react-bootstrap";
import { updateProfile } from "redux/actions";
import Spacer from "components/layout/Spacer";
import { Alert, Form, Radio, Space, Switch } from "antd";

const CommsPreferences = () => {
  const whoami = useSelector((state) => state.auth.whoami);
  const isFetching = useSelector(
    (state) => state.action.UPDATE_PROFILE.isFetching
  );

  const { receiveEmailsTermSt, commsPreferences } = whoami;

  if (!commsPreferences) return null;

  const onFinish = (values) => {
    const formData = new FormData();
    formData.append("accountActivated", true);
    formData.append("receiveEmailsTermSt", receiveEmailsTermSt);
    formData.append(
      "commsPreferences",
      JSON.stringify({
        autoArchive: {
          turnedOn: values.turnedOn,
          myResponsesOnly: values.myResponsesOnly,
        },
      })
    );

    updateProfile(formData, true);
  };

  const { turnedOn, myResponsesOnly } = commsPreferences.autoArchive;

  return (
    <div>
      <Spacer size={20} />
      <Row>
        <Col xs="12">
          <h3 className="bold">Auto-Archive</h3>
          <Spacer size={30} />
        </Col>
      </Row>
      <Row>
        <Col xs="12" sm="6">
          <Form
            className="no-border no-padding"
            initialValues={{ turnedOn, myResponsesOnly }}
            onFinish={onFinish}
          >
            <Form.Item label="">
              <Space direction="vertical">
                <Space>
                  <Form.Item
                    noStyle
                    label=""
                    name="turnedOn"
                    valuePropName="checked"
                  >
                    <Switch />
                  </Form.Item>

                  <label htmlFor="turnedOn">Toggle Auto-Archiving</label>
                </Space>
                <Alert
                  closable
                  type="info"
                  message="When turned on, auto-archive after respond"
                />
              </Space>
            </Form.Item>

            <Form.Item label="" dependencies={["turnedOn"]}>
              {({ getFieldValue }) => (
                <Form.Item noStyle name="myResponsesOnly">
                  <Radio.Group disabled={!getFieldValue("turnedOn")}>
                    <Space direction="vertical">
                      <Radio value={true}>My Responses Only</Radio>
                      <Radio value={false}>
                        My Responses and Team Member Responses
                      </Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
              )}
            </Form.Item>

            <Button type="submit" variant="primary" disabled={isFetching}>
              Update Comms Preferences
            </Button>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default CommsPreferences;
