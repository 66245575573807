import React, { useEffect, useRef } from "react";
import kebabCase from "lodash/kebabCase";
import { ListGroup } from "react-bootstrap";

const ListGroupItem = ({ label, children, isLastItem }) => {
  const defaultHeight = 49;
  const ref = useRef(null);
  const maxHeightRef = useRef(defaultHeight);
  let maxHeight = maxHeightRef.current;

  const setHeaderHeight = () => {
    const headerEl = document.querySelector(
      `.CompareQuotes .headers.list-group-flush .list-group-item.${kebabCase(
        label
      )}`
    );
    headerEl && (headerEl.style.height = `${maxHeight}px`);
  };

  const setItemsHeight = () => {
    const itemsEl = document.querySelectorAll(
      `.CompareQuotes .list-group-flush .list-group-item.${kebabCase(label)}`
    );
    itemsEl.forEach((item) => {
      if (item.offsetHeight > maxHeight) {
        maxHeight = item.offsetHeight;
      }
    });
    itemsEl.forEach((item) => {
      item.style.height = `${maxHeight}px`;
    });
  };

  useEffect(() => {
    if (isLastItem) {
      setItemsHeight();
      setHeaderHeight();
    }
  }, []);

  return (
    <ListGroup.Item ref={ref} className={kebabCase(label)}>
      {children}
    </ListGroup.Item>
  );
};

export default ListGroupItem;
