import React from "react";
import { connect } from "react-redux";
import { Button, Form, Container, Row, Col } from "react-bootstrap";
import { Field, reduxForm } from "redux-form";
import { required, noSpaces } from "utils/validation";
import { reduxFormInput, reduxFormSelect } from "components/form/ReduxForm";
import { createSection, updateSection } from "redux/actions";
import Spacer from "components/layout/Spacer";
import { adminAvailabilityOptions } from "./lookup";

class CreateEditSection extends React.Component {
  constructor(props) {
    super(props);

    this.submitFn = this.submitFn.bind(this);

    this.statusOptions = [
      {
        label: "ACTIVE",
        value: "ACTIVE",
      },
      {
        label: "ARCHIVED",
        value: "ARCHIVED",
      },
    ];
  }

  getAvailabilityOptions = () => {
    const { whoami } = this.props;
    return whoami.role === "Admin"
      ? adminAvailabilityOptions
      : adminAvailabilityOptions.filter((e) => e.value !== "ALL_LENDERS");
  };

  submitFn(data) {
    const { promptType, selectedSection, whoami } = this.props;

    if (promptType === "create" || promptType === "copy") {
      createSection({
        clauses: selectedSection.clauses,
        sectionName: data.sectionName,
        adminAvailability: {
          key: data.adminAvailability.value,
          values: [],
        },
        owner: whoami._id,
        status: "ACTIVE",
      });
    } else if (promptType === "edit") {
      updateSection(selectedSection._id, {
        sectionName: data.sectionName,
        adminAvailability: {
          key: data.adminAvailability.value,
          values: [],
        },
      });
    } else if (promptType === "change_status") {
      updateSection(selectedSection._id, {
        status: data.status.value,
      });
    } else if (promptType === "change_availability") {
      updateSection(selectedSection._id, {
        adminAvailability: {
          key: data.adminAvailability.value,
          values: [],
        },
      });
    }
  }

  render() {
    const { action, promptType, handleSubmit } = this.props;
    const isProcessing =
      action.CREATE_TAG.isFetching || action.UPDATE_TAG.isFetching;

    return (
      <>
        <Container>
          <Form
            className="no-border no-padding"
            onSubmit={handleSubmit(this.submitFn)}
          >
            {(promptType === "create" ||
              promptType === "edit" ||
              promptType === "copy") && (
              <Row>
                <Col xs="12">
                  <Field
                    className="form-control"
                    component={reduxFormInput}
                    validate={[required, noSpaces]}
                    name="sectionName"
                    type="text"
                    placeholder="Enter section name"
                    label="*Section name"
                  />
                  <Spacer size={10} />
                </Col>
              </Row>
            )}

            {(promptType === "create" ||
              promptType === "edit" ||
              promptType === "copy" ||
              promptType === "change_availability") && (
              <Row>
                <Col xs="12">
                  <Field
                    className="form-control"
                    component={reduxFormSelect}
                    validate={[required]}
                    name="adminAvailability"
                    type="select"
                    options={this.getAvailabilityOptions()}
                    label="*Available to"
                  />
                </Col>
              </Row>
            )}

            {promptType === "change_status" && (
              <Row>
                <Col xs="12">
                  <Field
                    className="form-control"
                    component={reduxFormSelect}
                    validate={[required]}
                    name="status"
                    type="select"
                    options={this.statusOptions}
                    label="*Status"
                  />
                </Col>
              </Row>
            )}

            <Spacer size={50} />

            <Row className="justify-content-center">
              <Col className="text-center" xs="12" sm="6">
                <Button disabled={isProcessing} type="submit" variant="primary">
                  {isProcessing ? "Saving..." : "Save"}
                </Button>
              </Col>
            </Row>
          </Form>
          <Spacer size={30} />
        </Container>
      </>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    action: state.action,
    whoami: state.auth.whoami,
    initialValues: {
      sectionName: props.selectedSection?.sectionName,
      status: props.selectedSection?.status
        ? {
            label: props.selectedSection?.status,
            value: props.selectedSection?.status,
          }
        : null,
      adminAvailability: props.selectedSection?.adminAvailability.key
        ? adminAvailabilityOptions.find(
            (e) => e.value === props.selectedSection?.adminAvailability.key
          )
        : null,
    },
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "CreateEditSection",
    enableReinitialize: true,
  })(CreateEditSection)
);
