import React, { useCallback } from "react";
import { connect } from "react-redux";
import {
  Dropdown,
  Button,
  OverlayTrigger,
  Tooltip,
  Row,
  Col,
  Popover,
  Badge,
} from "react-bootstrap";
import {
  faLock,
  faLockOpen,
  faEllipsisV,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { faFileAlt } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FolderFileItem = (props) => {
  const {
    whoami,
    userId,
    _id,
    name,
    displayName = name,
    location,
    parentPermission,
    permission,
    fetchFile,
    removeFile,
    showSecuritySharing,
    showRenaming,
    fromComms,
    addSelectedFile,
    removeSelectedFile,
    getSelectedFiles,
    canManage,
    isPublic,
    lendersPermission = [],
    fileKey,
    isShareable,
    isEditable,
    isDeletable,
  } = props;

  const checkPermission = useCallback(() => {
    if (permission && permission.length > 0) {
      if (permission.includes(userId)) return true;
      else return false;
    }
    if (parentPermission && parentPermission.length > 0) {
      if (parentPermission.includes(userId)) return true;
      else return false;
    }
    return true;
  }, [userId, parentPermission, permission]);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Button
      ref={ref}
      className="p-1"
      variant="folder"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </Button>
  ));

  const CustomMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) => child.props.children
            )}
          </ul>
        </div>
      );
    }
  );

  return (
    <Row
      className={
        fromComms && getSelectedFiles().some((file) => file._id === _id)
          ? "file-item-row is-selected-file"
          : "file-item-row"
      }
    >
      <Col xs={fromComms ? 8 : undefined}>
        <FontAwesomeIcon className="ml-3" icon={faFileAlt} />
        {fromComms ? (
          <span
            className="folder-file-name font-14 ml-3 hover-cursor"
            onClick={(e) => {
              const wrapper = e.target.parentNode.parentNode;

              if (wrapper.classList.contains("is-selected-file")) {
                wrapper.classList.remove("is-selected-file");
                removeSelectedFile(_id);
              } else {
                wrapper.classList.add("is-selected-file");
                addSelectedFile({ _id, name, fileLink: location, fileKey });
              }
            }}
            onKeyDown={(e) =>
              e.keyCode === 13 ? document.activeElement.click() : null
            }
            tabIndex="0"
          >
            {displayName}
          </span>
        ) : (
          <a
            href={location}
            target="_blank"
            rel="noopener noreferrer"
            className="folder-file-name font-14 ml-3"
          >
            {displayName}
          </a>
        )}
      </Col>
      <Col xs="2"></Col>
      {whoami?.role !== "Lender" && (
        <Col
          className="d-flex align-items-center justify-content-center"
          xs="2"
        >
          {isPublic ? (
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip id={`tooltip-bottom`}>Public</Tooltip>}
            >
              <FontAwesomeIcon icon={faLockOpen} />
            </OverlayTrigger>
          ) : (
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Popover>
                  <Popover.Title as="h3">Companies with Access</Popover.Title>
                  <Popover.Content>
                    <div className="chips-container">
                      {lendersPermission.map((item) => {
                        return (
                          <Badge
                            key={item._id}
                            pill
                            variant="primary"
                            className="d-flex p-2 m-1"
                          >
                            {item.companyName}
                          </Badge>
                        );
                      })}
                    </div>
                  </Popover.Content>
                </Popover>
              }
            >
              <FontAwesomeIcon icon={faLock} />
            </OverlayTrigger>
          )}
        </Col>
      )}
      <Col className="d-flex justify-content-end align-items-center" xs="3">
        {!fromComms && checkPermission() && (
          <>
            <Button
              className="p-1 d-flex justify-content-center align-items-center"
              variant="folder"
              onClick={() => fetchFile(_id, name)}
            >
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id={`tooltip-bottom`}>Download</Tooltip>}
              >
                <FontAwesomeIcon icon={faDownload} />
              </OverlayTrigger>
            </Button>
            <span className="mr-2"></span>
            <Dropdown className="d-inline-block folder-file-dropdown">
              <Dropdown.Toggle as={CustomToggle} id="toggle-dropdown">
                <h4 className="view-as">
                  <FontAwesomeIcon icon={faEllipsisV} />
                </h4>
              </Dropdown.Toggle>

              <Dropdown.Menu as={CustomMenu}>
                <Dropdown.Item onClick={() => fetchFile(_id, name)}>
                  Download
                </Dropdown.Item>
                {canManage && (
                  <>
                    {isEditable && (
                      <Dropdown.Item onClick={() => showRenaming(_id)}>
                        Edit
                      </Dropdown.Item>
                    )}
                    {isShareable && (
                      <Dropdown.Item
                        onClick={() => {
                          showSecuritySharing(_id);
                        }}
                      >
                        Security & Sharing
                      </Dropdown.Item>
                    )}
                    {isDeletable && (
                      <>
                        <Dropdown.Divider> </Dropdown.Divider>
                        <Dropdown.Item onClick={() => removeFile(_id)}>
                          Delete
                        </Dropdown.Item>
                      </>
                    )}
                  </>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </>
        )}
      </Col>
    </Row>
  );
};

function mapStateToProps(state) {
  return {
    whoami: state.auth.whoami,
  };
}

export default connect(mapStateToProps)(FolderFileItem);
