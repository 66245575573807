import React from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { Field, reduxForm } from "redux-form";
import { required, noSpaces, url } from "utils/validation";
import { reduxFormInput, reduxFormTextarea } from "components/form/ReduxForm";
import { createManager, updateManager } from "redux/actions";
import { toast } from "react-toastify";
import Spacer from "components/layout/Spacer";
import { connect } from "react-redux";

class ManagerCreate extends React.Component {
  constructor(props) {
    super(props);

    this.submitFn = this.submitFn.bind(this);
    this.submitError = this.submitError.bind(this);
  }

  submitFn(data, e) {
    const { company, onSubmit, selectedManager, isEdit } = this.props;
    const { _id, managers } = company;
    const isExists = managers.find(
      (manager) => manager.managementCompanyName === data.managerName
    )
    const updateCallback = isEdit ? updateManager : createManager;
    if (
      !isExists || (isExists && isExists._id === selectedManager?._id)
    ) {
      const { managementCompanyWebsite, managementCompanyBio } = data;
      updateCallback(_id, {
        managementCompanyName: data.managerName,
        managementCompanyWebsite,
        managementCompanyBio,
      },
      selectedManager?._id
      );

      onSubmit();
    } else {
      this.submitError("That manager already exists!");
    }
  }

  submitError(text) {
    toast.error(text, {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  render() {
    const { handleSubmit, isEdit } = this.props;

    return (
      <>
        <Form
          className="full-width no-border no-padding"
          onSubmit={handleSubmit(this.submitFn)}
        >
          <Row>
            <Col xs="12">
              <Field
                className="form-control"
                component={reduxFormInput}
                validate={[required, noSpaces]}
                name="managerName"
                type="text"
                placeholder="Enter manager name..."
                label="*Manager Name"
              />
              <Spacer size={20} />
            </Col>
          </Row>
          <Row>
            <Col xs="12" sm="6">
              <Field
                className="form-control"
                component={reduxFormInput}
                name="managementCompanyWebsite"
                validate={[url, noSpaces]}
                type="text"
                placeholder="Enter website URL..."
                label="Management Company Website"
              />
              <Spacer size={20} />
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <Field
                className="form-control"
                component={reduxFormTextarea}
                validate={[noSpaces]}
                name="managementCompanyBio"
                rows="10"
                type="text"
                placeholder="Please describe..."
                label="Management Company Bio"
              />
            </Col>
          </Row>
          <Row>
            <Col className="text-right" xs="12">
              <Spacer size={50} />
              <Button type="submit" variant="primary">
              {isEdit ? "Update" : "Add"}
              </Button>
            </Col>
          </Row>
        </Form>
      </>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    action: state.action,
    initialValues: {
      managerName: props.selectedManager?.managementCompanyName,
      managementCompanyWebsite: props.selectedManager?.managementCompanyWebsite,
      managementCompanyBio: props.selectedManager?.managementCompanyBio,
    },
  };
}
export default connect(mapStateToProps)(reduxForm({
  form: "AddManager",
  // destroyOnUnmount: false,
  enableReinitialize:true,
  forceUnregisterOnUnmount: true,
})(ManagerCreate));
