import React from "react";
import { connect } from "react-redux";
import AccountProfile from "./AccountProfile";
class AccountManagement extends React.Component {
  render() {
    const { match, whoami } = this.props;

    return <AccountProfile match={match} profile={whoami} />;
  }
}

function mapStateToProps(state) {
  return {
    whoami: state.auth.whoami,
  };
}

export default connect(mapStateToProps)(AccountManagement);
