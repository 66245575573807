import React, { useState, useCallback, useRef } from "react";
import { Button } from "react-bootstrap";
import { faFileAlt } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PopupPrompt from "components/ui/PopupPrompt";
import Cropper from "components/form/Cropper";
import Spacer from "components/layout/Spacer";
import { toast } from "react-toastify";

const ALLOWED_FILE_TYPES = [".jpeg", ".jpg", ".png"];

const PrimaryPhotoItem = (props) => {
  const [showPrompt, setShowPrompt] = useState(false);
  const [newSrc, setNewSrc] = useState({});
  const primaryPhotoInput = useRef();
  const [filename, setFilename] = useState("");

  const { name, files, handlePrimaryPhoto, isRequired } = props;

  const onChange = useCallback((e) => {
    if (e.target.files && e.target.files.length) {
      const ext = e.target.files[0].name.split(".").reverse()[0];
      if (ALLOWED_FILE_TYPES.indexOf(`.${ext.toLowerCase()}`) === -1) {
        toast.error(
          `Unsupported file. Allows only ${ALLOWED_FILE_TYPES.join(", ")}`,
          {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        return;
      }
      setFilename(e.target.files[0].name);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setNewSrc(reader.result);
        setShowPrompt(true);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  }, []);

  const onSave = useCallback(
    (file, filename) => {
      setShowPrompt(false);
      handlePrimaryPhoto(file, filename);
    },
    [handlePrimaryPhoto]
  );

  const onChangePhoto = useCallback(() => {
    primaryPhotoInput.current.click();
  }, []);

  return (
    <>
      <div className="d-flex flex-column mb-30 pl-3 pr-3 align-items-end">
        <div
          style={{ minHeight: 160 }}
          className={
            files.length > 0 ? "grid-col-container dark" : "grid-col-container"
          }
        >
          <div className="grid-item grid-col-name">
            <div className="grid-row">
              <span className="font-16 bold grid-file-name">{name}</span>
            </div>
          </div>
          {files.length > 0 ? (
            <span className="mb-1 font-18">
              {files.length} <FontAwesomeIcon icon={faFileAlt} />
            </span>
          ) : (
            <span className="mb-1 font-12">Drop files here or</span>
          )}
          <Button
            variant="file-upload"
            className={files.length > 0 ? "dark" : null}
            onClick={() => {
              primaryPhotoInput.current.click();
            }}
          >
            Select File
          </Button>
          <input
            ref={primaryPhotoInput}
            className="drag-drop-file-upload"
            type="file"
            id="primary-photo"
            onChange={onChange}
            accept={ALLOWED_FILE_TYPES.join(", ")}
          />
        </div>
        {isRequired && (
          <div
            className="grid-required mt-2 text-center w-100"
            style={{ borderTop: "dotted 2px #753972" }}
          >
            <p className="font-14 color-purple">Required</p>
          </div>
        )}
      </div>
      <PopupPrompt
        promptTitle={"Primary Photo"}
        promptBody={
          <Cropper
            src={newSrc}
            onSave={(file) => onSave(file, filename)}
            onCancel={() => setShowPrompt(false)}
            onChangePhoto={onChangePhoto}
          />
        }
        show={showPrompt}
        onHide={() => setShowPrompt(false)}
        onCancel={() => setShowPrompt(false)}
      />
    </>
  );
};

export default PrimaryPhotoItem;
