import { dispatch } from "config/store";
import { actionTypes } from "./actionTypes";

export const getUsers = (data) => {
  const action = {
    type: actionTypes.GET_USERS,
    config: {
      method: "get",
      url: `/user/lookup?search=${data.search}&accountActivated=${data.accountActivated}`,
    },
  };

  dispatch(action);
};

export const getAllUsers = (options) => {
  let url = `/user/lookup?limit=${options.limit || 10}&page=${
    options.page || 1
  }&order=${options.order || "DESC"}`;
  if (options.status) {
    url = `${url}&status=${options.status.value}`;
  }
  if (options.role) {
    url = `${url}&role=${options.role.value}`;
  }
  if (options.accountActivate) {
    url = `${url}&accountActivate=${options.accountActivate.value}`;
  }
  if (options.search) {
    url = `${url}&search=${options.search}`;
  }
  if (options.companyId) {
    url = `${url}&companyId=${options.companyId}`;
  }
  const action = {
    type: actionTypes.GET_MANAGE_USERS,
    config: {
      method: "get",
      url,
    },
  };
  dispatch(action);
};

export const getUserProfile = (userId) => {
  const action = {
    type: actionTypes.GET_USER_PROFILE,
    config: {
      method: "get",
      url: `/user/lookup/${userId}`,
    },
  };

  dispatch(action);
};
export const partialUpdateUser = (userId, data) => {
  const action = {
    type: actionTypes.PARTIAL_UPDATE_USER,
    config: {
      method: "put",
      url: `/user/partialUpdate/${userId}`,
      data,
    },
  };
  dispatch(action);
};

export const createUser = (data) => {
  const action = {
    type: actionTypes.CREATE_USER,
    config: {
      method: "post",
      url: `/user/add`,
      data,
    },
  };

  dispatch(action);
};
