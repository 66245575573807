import React from "react";

const DefaultColumnFilter = ({ column: { filterValue, setFilter } }) => {
  return (
    <input
      style={{ width: 120 }}
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    />
  );
};

export default DefaultColumnFilter;
