import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row, Spin, Tabs } from "antd";
import DealLoanApplication from "./DealLoanApplication";
import PortifolioLevel from "./PortifolioLevel";
import { ProfileOutlined, FileTextOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import constants from "config/constants";
import { getKeyFromLocal, storeJsonInLocal } from "utils/lsUtils";
import { useHistory, useParams } from "react-router-dom";
import { addProperty } from "redux/actions";

const portifolioDeal = {
  label: (
    <span>
      <ProfileOutlined />
      Portfolio Level
    </span>
  ),
  key: "portifolio",
  children: <PortifolioLevel />,
  closable: false,
};

const defaultPanes = [portifolioDeal];
const PortifolioDealApplication = (props) => {
  const [activeKey, setActiveKey] = useState(defaultPanes[0].key);
  const [items, setItems] = useState(defaultPanes);
  const [targetKey, setTargetKey] = useState(null);
  const [modal, contextHolder] = Modal.useModal();
  const params = useParams();
  const history = useHistory();
  const dealId = params.dealId;
  const { properties = [] } = props.deal;
  useEffect(() => {
    if (properties.length) {
      const subDeals = properties.map(({ _id, propertyName }, index) => {
        return {
          label: (
            <span>
              <FileTextOutlined />
              {propertyName}
            </span>
          ),
          children: <DealLoanApplication dealId={_id} />,
          key: _id,
        };
      });
      const tabs = [portifolioDeal, ...subDeals];
      setItems(tabs);

      console.log("lenght use effect called", properties.length);
    }
  }, [properties]);

  useEffect(() => {
    const portifolioDealId = getKeyFromLocal(
      constants.TERMST_LOCAL_STORAGE.PORTIFOLIO_DEAL
    );
    const isPortifolioDeal = portifolioDealId === dealId;
    const jsonObj = {};
    jsonObj[constants.TERMST_LOCAL_STORAGE.DEAL_ID] = dealId;
    storeJsonInLocal(jsonObj);
    setActiveKey(isPortifolioDeal ? "portifolio" : dealId);
  }, [dealId]);

  const onChange = (key) => {
    if (key === "portifolio") {
      const jsonObj = {};
      const dealId = getKeyFromLocal(
        constants.TERMST_LOCAL_STORAGE.PORTIFOLIO_DEAL
      );
      history.push(`/deal/portifolio-application/${dealId}`);
      jsonObj[constants.TERMST_LOCAL_STORAGE.DEAL_ID] = dealId;
      storeJsonInLocal(jsonObj);
      setActiveKey(key);
      return;
    }
    const jsonObj = {};
    jsonObj[constants.TERMST_LOCAL_STORAGE.DEAL_ID] = key;
    storeJsonInLocal(jsonObj);
    history.push(`/deal/portifolio-application/${key}`);

    setActiveKey(key);
  };

  const config = {
    title: "You have elected to delete a property!",
    content: <>This cannot be undone. Are you sure?</>,
    onOk: () => remove(targetKey),
  };

  const add = () => {
    const dealId = getKeyFromLocal(
      constants.TERMST_LOCAL_STORAGE.PORTIFOLIO_DEAL
    );
    addProperty({
      dealId,
    });
  };

  const remove = (targetKey) => {
    if (!targetKey) {
      return null;
    }
    const targetIndex = items.findIndex((pane) => pane.key === targetKey);
    const newPanes = items.filter((pane) => pane.key !== targetKey);
    if (newPanes.length && targetKey === activeKey) {
      const { key } =
        newPanes[
          targetIndex === newPanes.length ? targetIndex - 1 : targetIndex
        ];
      setActiveKey(key);
    }
    setItems(newPanes);
  };

  const onEdit = (targetKey, action) => {
    if (action === "add") {
      add();
    } else {
      setTargetKey(targetKey);
      modal.warning(config);
      //   remove(targetKey);
    }
  };
  const { isFetching } = props;
  return (
    <div
      style={{ marginBottom: 16, marginTop: 25 }}
      className="portifolio-deals"
    >
      <Spin spinning={isFetching}>
        <Row>
          <Col span={8}>
            <h3>Loan Application</h3>
          </Col>
          <Col span={6} offset={6}>
            <Button type="primary" onClick={add}>
              Add Property
            </Button>
          </Col>
        </Row>
        <Row style={{ marginTop: 16, marginBottom: 16 }}>
          <Col span={24}>
            <p>
              Fill out as many sections as you can to increase the likelihood of
              closing a deal
            </p>
            <p className="bold">All details will be saved automatically.</p>
          </Col>
        </Row>

        <Tabs
          hideAdd
          size="large"
          destroyInactiveTabPane
          onChange={onChange}
          activeKey={activeKey}
          type="editable-card"
          onEdit={onEdit}
          items={items}
        />
        {contextHolder}
      </Spin>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    isFetching:
      state.action.GET_DEAL_BY_ID.isFetching ||
      state.action.SAVE_FINANCIALS.isFetching ||
      state.action.ADD_PROPERTY.isFetching ||
      state.action.SAVE_PICTURES.isFetching,
    deal: state.deal.deal,
  };
}
export default connect(mapStateToProps)(PortifolioDealApplication);
