import React, { useEffect, useMemo } from "react";
import { useRouteMatch } from "react-router-dom";
import { noop } from "lodash";
import { PlusCircleOutlined, DownOutlined } from "@ant-design/icons";
import { Tooltip, Tree } from "antd";
import {
  clearConversationDetails,
  createLabel,
  deleteLabel,
  getConversationAllByLabels,
  getConversationMessagesByLabels,
  getConversationQuestionsByLabels,
  getLabelsTreeView,
  updateLabel,
} from "redux/actions";
import { connect } from "react-redux";
import {
  createTreeData,
  getNodeChildrenFlat,
  getParentKey,
} from "../inboxUtils";
import CreateLabel from "./CreateLabel";
import { LabelOptions } from "./LabelOptions";
import {
  labelActions,
  conversationTypes,
  conversationStatuses,
} from "../enums";

const apiMap = {
  [conversationTypes.all]: getConversationAllByLabels,
  [conversationTypes.messages]: getConversationMessagesByLabels,
  [conversationTypes.questions]: getConversationQuestionsByLabels,
};

const InboxLabels = ({
  tab,
  status,
  setStatus,
  labelsTreeView,
  lastLabelCreated,
  lastLabelUpdated,
  lastLabelDeleted,
}) => {
  const match = useRouteMatch();
  const dealId = match.params.id;

  const [openModal, setOpenModal] = React.useState(false);
  const [selectedLabel, setSelectedLabel] = React.useState(null);
  const treeData = useMemo(
    () => createTreeData(labelsTreeView),
    [labelsTreeView]
  );

  useEffect(() => {
    setSelectedLabel(null);
    getLabelsTreeView();
  }, [lastLabelCreated, lastLabelUpdated, lastLabelDeleted]);

  useEffect(() => {
    if (status === conversationStatuses.labels && tab && selectedLabel) {
      const labels = getNodeLabels(selectedLabel);
      getConversationsByLabels(labels);
    }
  }, [status, tab, selectedLabel]);

  const getNodeLabels = (node) => {
    const nodeChildren = getNodeChildrenFlat(node.key, [node]);
    const labels = [node.key, ...nodeChildren.map((child) => child.key)];
    return labels.join(",");
  };

  const getConversationsByLabels = (labels) => {
    const query = { dealId, labels, populate: true };
    const apiCall = apiMap[tab] || noop;
    apiCall(query);
  };

  const handleSubmit = (values) => {
    if (selectedLabel) {
      updateLabel(selectedLabel.key, values);
    } else {
      createLabel(values);
    }
    setOpenModal(false);
    setSelectedLabel(null);
  };

  const handleCancel = () => {
    setOpenModal(false);
    setSelectedLabel(null);
  };

  const handleClickLabel = (node) => {
    setStatus(conversationStatuses.labels);
    setSelectedLabel(node);
    clearConversationDetails();
  };

  const handleClickLabelOptions = ({ key, node }) => {
    switch (key) {
      case labelActions.edit:
        node.parent = getParentKey(node.key, treeData);
        setSelectedLabel(node);
        setOpenModal(true);
        break;
      case labelActions.delete:
        deleteLabel(node.key);
        break;
      default:
        break;
    }
  };

  const titleRender = (node) => {
    const onClick = () => {
      handleClickLabel(node);
    };
    const onClickOptions = (args) => {
      args.node = node;
      handleClickLabelOptions(args);
    };
    return (
      <div className="inbox-label-node d-flex align-items-center">
        <p onClick={onClick} style={{ paddingRight: 20 }}>
          {node.title}
        </p>
        <LabelOptions onClick={onClickOptions} />
      </div>
    );
  };

  return (
    <div className="inbox-labels">
      <h4 className="inbox-label-header mb-2">
        Labels{" "}
        <Tooltip title="Create new label">
          <PlusCircleOutlined
            className="inbox-label-add cursor-pointer"
            onClick={() => setOpenModal(true)}
          />
        </Tooltip>
      </h4>
      <Tree
        defaultExpandedKeys={["0-0-0"]}
        treeData={treeData}
        switcherIcon={<DownOutlined />}
        titleRender={titleRender}
      />
      <CreateLabel
        open={openModal}
        selectedLabel={selectedLabel}
        onCreate={handleSubmit}
        onCancel={handleCancel}
      />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    labelsTreeView: state.label.labelsTreeView,
    lastLabelCreated: state.label.lastLabelCreated,
    lastLabelUpdated: state.label.lastLabelUpdated,
    lastLabelDeleted: state.label.lastLabelDeleted,
  };
}

export default connect(mapStateToProps)(InboxLabels);
