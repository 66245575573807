const showIfRate = (selectedData) => selectedData.isRateOrSpread === "Rate";
const showIfSpread = (selectedData) =>
  selectedData.isRateOrSpread === "Spread over Index";
// const showIfNotNoFloor = (selectedData) =>
//   showIfSpread(selectedData) &&
//   selectedData.floorOption &&
//   selectedData.floorOption !== "No Floor";
const disableIf = (disable) => disable;

export const calculateRate = (selectedData) => {
  const { indexRate, spread } = selectedData;
  if (indexRate === "" || spread === "") {
    return "";
  }
  return (parseFloat(indexRate) + parseFloat(spread) / 100).toFixed(2);
};
export const calculateFloorRate = (selectedData) => {
  if (selectedData.floorOption === "No Floor") {
    return "";
  }
  if (selectedData.floorOption === "Rate Floor") {
    return selectedData.floorOptionValue;
  }
  const { floorOptionValue = 0, spread = 0 } = selectedData;
  return (
    parseFloat(Number(floorOptionValue)) +
    parseFloat(Number(spread)) / 100
  ).toFixed(2);
};
const parseChildSymbol = (value) => {
  const [s1, s2] = value.split(" ");
  return `${s2.trim()[0]}${s1.trim()}`.toUpperCase();
};
const parseChildSymbolForSOFRTerm = (value) => {
  const [s1, s2] = value.split(" ");
  return `_${s2.trim()[0]}${s1.trim()}`.toUpperCase();
};
export const quoteIndexes = [
  {
    value: "US Treasury Rate",
    label: "US Treasury Rate",
    symbol: () => "TREASURY",
    childSymbol: parseChildSymbol,
    childOptions: [
      {
        value: "1 Year",
        label: "1 Year",
      },
      {
        value: "2 Years",
        label: "2 Years",
      },
      {
        value: "3 Years",
        label: "3 Years",
      },
      {
        value: "5 Years",
        label: "5 Years",
      },
      {
        value: "7 Years",
        label: "7 Years",
      },
      {
        value: "10 Years",
        label: "10 Years",
      },
      {
        value: "20 Years",
        label: "20 Years",
      },
      {
        value: "30 Years",
        label: "30 Years",
      },
    ],
  },
  // {
  //   value: "US Treasury Swap Rate",
  //   label: "US Treasury Swap Rate",
  //   symbol: () => "IR SWAPSPRD",
  //   childSymbol: parseChildSymbol,
  //   childOptions: [
  //     {
  //       value: "1 Year",
  //       label: "1 Year",
  //     },
  //     {
  //       value: "2 Years",
  //       label: "2 Years",
  //     },
  //     {
  //       value: "3 Years",
  //       label: "3 Years",
  //     },
  //     {
  //       value: "5 Years",
  //       label: "5 Years",
  //     },
  //     {
  //       value: "7 Years",
  //       label: "7 Years",
  //     },
  //     {
  //       value: "10 Years",
  //       label: "10 Years",
  //     },
  //     {
  //       value: "30 Years",
  //       label: "30 Years",
  //     },
  //   ],
  // },
  {
    value: "SOFR",
    label: "SOFR",
    symbol: () => "SOFR",
    childSymbol: () => "",
  },
  {
    value: "SOFRSWAP",
    label: "SOFR Swap",
    symbol: () => "SOFRSWAP",
    childSymbol: parseChildSymbol,
    childOptions: [
      {
        value: "1 Year",
        label: "1 Year",
      },
      {
        value: "2 Years",
        label: "2 Years",
      },
      {
        value: "3 Years",
        label: "3 Years",
      },
      {
        value: "5 Years",
        label: "5 Years",
      },
      {
        value: "7 Years",
        label: "7 Years",
      },
      {
        value: "10 Years",
        label: "10 Years",
      },
      {
        value: "30 Years",
        label: "30 Years",
      },
    ],
  },
  {
    value: "SOFRTERM",
    label: "SOFR Term",
    symbol: () => "SOFRTERM",
    childSymbol: parseChildSymbolForSOFRTerm,
    childOptions: [
      {
        value: "1 month",
        label: "1 month",
      },
      {
        value: "3 months",
        label: "3 months",
      },
      {
        value: "6 months",
        label: "6 months",
      },
      {
        value: "1 year",
        label: "1 year",
      },
    ],
  },
  {
    value: "Prime Rate",
    label: "Prime Rate",
    symbol: () => "",
    childSymbol: (selectedData) =>
      selectedData.indexTerm === "FED Prime Rate" ? "PRIME RATE" : "WSJ PRIME",
    childOptions: [
      {
        value: "FED Prime Rate",
        label: "FED Prime Rate",
      },
      {
        value: "WSJ Prime Rate",
        label: "WSJ Prime Rate",
      },
    ],
  },
  {
    value: "Tbill",
    label: "Tbill",
    symbol: () => "TBILL",
    childSymbol: parseChildSymbol,
    childOptions: [
      {
        value: "3 months",
        label: "3 months",
      },
      {
        value: "6 months",
        label: "6 months",
      },
    ],
  },
  {
    value: "Commercial Paper",
    label: "Commercial Paper",
    symbol: () => "C_PAPER_FIN",
    childSymbol: parseChildSymbol,
    childOptions: [
      {
        value: "1 month",
        label: "1 month",
      },
      {
        value: "2 months",
        label: "2 months",
      },
      {
        value: "3 months",
        label: "3 months",
      },
    ],
  },
];
export const injectPrimaryClause = (columns) => {
  return columns.map((col) => {
    const found =
      col.isPrimary &&
      !col.clause?._id &&
      config.find((e) => e.label === col.sectionName);
    let newCol = col;
    if (found) {
      newCol = {
        ...newCol,
        clause: found.clause,
      };
    }
    if (typeof newCol.isPrimary === "undefined") {
      newCol.isPrimary = config.some(
        (s) => s.label === col.sectionName && s.plus
      );
    }
    return newCol;
  });
};
export const getPrimaryClauseValueKey = (clauseName) => {
  const found = config.find((e) => e.label === clauseName);
  if (found) {
    return found.key;
  }
  let key = clauseName;
  switch (clauseName) {
    case "Rate Calculation":
      key = "isRateOrSpread";
      break;
    case "Index":
      key = "indexRate";
      break;
    case "Index Term":
      key = "indexTerm1";
      break;
    default:
      break;
  }
  return key;
};

// export const spreadClauses = [
//   "loanAmount",
//   "term",
//   "amortization",
//   "IOPeriod",
//   "spread",
//   "floorOptionValue",
//   "indexRate",
//   "quotedRate",
// ];

// export const rateClauses = [
//   "loanAmount",
//   "term",
//   "amortization",
//   "IOPeriod",
//   "rate",
// ];

// export const getRateClauses = () =>
//   config.filter(
//     (e) =>
//       rateClauses.includes(e.key) &&
//       (!e.showIf || (e.showIf && e.showIf({ isRateOrSpread: "Rate" })))
//   );

export const getPrimaryClauses = (isSpread, numOfQuotes = 0) => {
  return config.filter((e) => e.plus);
  // if (numOfQuotes === 0) {
  //   return config
  // }
  // if (isSpread) {
  //   return config.filter((e) => spreadClauses.includes(e.key));
  // }
  // return getRateClauses();
};

const config = [
  {
    key: "loanAmount",
    label: "Loan Amount",
    type: "currency",
    plus: true,
    clause: {
      clauseName: "loanAmount",
      format: "$#,###",
      addOn: "None",
    },
  },
  {
    key: "term",
    label: "Term",
    subLabelType: "select",
    subLabelOptions: [
      { value: "years", label: "Years" },
      { value: "months", label: "Months" },
    ],
    subLabelKey: "termMetric",
    type: "number",
    plus: true,
    clause: {
      clauseName: "term",
      format: "#,###",
      addOn: "Years / Months Selection",
    },
  },
  {
    key: "amortization",
    label: "Amortization",
    subLabelType: "select",
    subLabelOptions: [
      { value: "years", label: "Years" },
      { value: "months", label: "Months" },
    ],
    subLabelKey: "amortizationMetric",
    type: "number",
    plus: true,
    clause: {
      clauseName: "amortization",
      format: "#,###",
      addOn: "Years / Months Selection",
    },
  },
  {
    key: "IOPeriod",
    label: "IO Period",
    subLabelType: "select",
    subLabelOptions: [
      { value: "years", label: "Years" },
      { value: "months", label: "Months" },
    ],
    subLabelKey: "IOPeriodMetric",
    type: "number",
    plus: true,
    clause: {
      clauseName: "IOPeriod",
      format: "#,###",
      addOn: "Years / Months Selection",
    },
  },
  {
    key: "isRateOrSpread",
    label: "Rate Calculation",
    type: "select",
    options: [
      {
        value: "Rate",
        label: "Rate",
      },
      {
        value: "Spread over Index",
        label: "Spread over Index",
      },
    ],
    disableIf,
    plus: true,
    clause: {
      clauseName: "isRateOrSpread",
      format: "Text",
      addOn: "None",
    },
  },
  {
    key: "rate",
    label: "Rate",
    type: "percent",
    showIf: showIfRate,
    clause: {
      clauseName: "rate",
      format: "#.##%",
    },
  },
  {
    key: "indexOption",
    label: "Index Option",
    type: "select",
    options: [
      {
        value: "Equal to Chosen Index",
        label: "Equal to Chosen Index",
      },
      {
        value: "Greater of Two Indexes",
        label: "Greater of Two Indexes",
      },
      {
        value: "Lesser of Two Indexes",
        label: "Lesser of Two Indexes",
      },
    ],
    showIf: showIfSpread,
    disableIf,
  },
  {
    key: "index1",
    label: "Index",
    getLabel: (selectedData) =>
      selectedData.indexOption === "Equal to Chosen Index"
        ? "Index"
        : "Index 1",
    type: "select",
    options: quoteIndexes,
    showIf: showIfSpread,
    forcedState: () => ({
      indexTerm1: "",
      indexRate: "",
      rate: "",
    }),
    plus: true,
    clause: {
      clauseName: "index1",
      format: "Text",
      addOn: "None",
    },
  },
  {
    key: "indexTerm1",
    label: "Index Term",
    getLabel: (selectedData) =>
      selectedData.indexOption === "Equal to Chosen Index"
        ? "Index Term"
        : "Index Term 1",
    type: "select",
    getOptions: (selectedData) => {
      const index = quoteIndexes.find(
        (item) => item.value === selectedData.index1
      );
      return index ? index.childOptions : [];
    },
    showIf: (selectedData) =>
      showIfSpread(selectedData) && selectedData.index1 !== "SOFR",
    plus: true,
    clause: {
      clauseName: "indexTerm1",
      format: "Text",
      addOn: "None",
    },
  },
  {
    key: "index2",
    label: "Index 2",
    type: "select",
    options: quoteIndexes,
    showIf: (selectedData) =>
      showIfSpread(selectedData) &&
      selectedData.indexOption !== "Equal to Chosen Index",
    forcedState: () => ({
      indexTerm2: "",
      indexRate: "",
      rate: "",
    }),
  },
  {
    key: "indexTerm2",
    label: "Index Term 2",
    type: "select",
    getOptions: (selectedData) => {
      const index = quoteIndexes.find(
        (item) => item.value === selectedData.index2
      );
      return index ? index.childOptions : [];
    },
    showIf: (selectedData) =>
      showIfSpread(selectedData) &&
      selectedData.indexOption !== "Equal to Chosen Index" &&
      selectedData.index2 !== "SOFR",
  },
  {
    key: "spread",
    label: "Spread",
    subLabel: "(bps)",
    type: "number",
    showIf: showIfSpread,
    forcedState: (selectedData) => ({
      floorRate: calculateFloorRate(selectedData),
      rate: calculateRate(selectedData),
    }),
    plus: true,
    clause: {
      clauseName: "spread",
      format: "#,###",
      addOn: "None",
    },
  },
  {
    key: "floorOption",
    label: "Floor Option",
    type: "select",
    options: [
      {
        value: "No Floor",
        label: "No Floor",
      },
      {
        value: "Rate Floor",
        label: "Rate Floor",
      },
      {
        value: "Index Floor",
        label: "Index Floor",
      },
    ],
    showIf: showIfSpread,
    forcedState: () => ({
      floorOptionValue: "",
      floorRate: "",
    }),
  },
  {
    key: "floorOptionValue",
    label: "Floor",
    type: "percent",
    // getLabel: (selectedData) => selectedData.floorOption === 'Rate Floor' ? 'Rate Floor' : 'Index Floor',
    showIf: showIfSpread,
    disableIf: (canDisable, selectedData) =>
      selectedData.floorOption === "No Floor",
    forcedState: (selectedData) => ({
      floorRate: calculateFloorRate(selectedData),
    }),
    plus: true,
    clause: {
      clauseName: "floorOptionValue",
      format: "#.##%",
      addOn: "None",
    },
  },
  {
    key: "indexRate",
    label: "Index Rate",
    type: "percent",
    disableIf: () => true,
    showIf: showIfSpread,
    clause: {
      clauseName: "indexRate",
      format: "#.##%",
      addOn: "None",
    },
  },
  // {
  //   key: "rate",
  //   label: "Rate",
  //   type: "percent",
  //   disableIf: () => true,
  //   showIf: showIfSpread,
  // },
  {
    key: "floorRate",
    label: "Floor Rate",
    type: "percent",
    disableIf: () => true,
    // showIf: showIfNotNoFloor,
  },
  {
    key: "quotedRate",
    label: "Quoted Rate",
    type: "percent",
    disableIf: () => true,
    showIf: showIfSpread,
    plus: true,
    clause: {
      clauseName: "quotedRate",
      format: "#.##%",
      addOn: "None",
    },
  },
];

export default config;
