import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Dropdown, Image } from "react-bootstrap";
import { signOut } from "redux/actions";
import Avatar from "images/avatar.jpg";
import constants from "config/constants";

class ProfilePic extends React.Component {
  constructor(props) {
    super(props);

    this.handleLogout = this.handleLogout.bind(this);
  }

  handleLogout() {
    window.Intercom("shutdown");
    window.Intercom("boot", {
      app_id: constants.INTERCOM_APP_ID,
    });
    signOut();
  }

  render() {
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
      <Button
        className="fake-link pt-0 pb-0"
        variant="fake-link"
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
      >
        {children}
      </Button>
    ));

    const CustomMenu = React.forwardRef(
      ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
        const [value] = useState("");

        return (
          <div
            ref={ref}
            style={style}
            className={className}
            aria-labelledby={labeledBy}
          >
            <ul className="list-unstyled">
              {React.Children.toArray(children).filter(
                (child) =>
                  !value || child.props.children.toLowerCase().startsWith(value)
              )}
            </ul>
          </div>
        );
      }
    );

    const { profilePic, myCompany, whoami } = this.props;

    return (
      <Dropdown>
        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
          <Image
            src={profilePic !== null ? profilePic : Avatar}
            alt="My profile picture."
            width="35px"
            height="35px"
            roundedCircle
          />
        </Dropdown.Toggle>

        <Dropdown.Menu as={CustomMenu}>
          {myCompany && !myCompany.message ? (
            <Dropdown.Item as="a" href={"/company/profile/" + myCompany._id}>
              {myCompany.companyName}
            </Dropdown.Item>
          ) : (
            <Dropdown.Item as={Link} to="/company/create">
              Create Company
            </Dropdown.Item>
          )}
          <Dropdown.Divider />
          <Dropdown.Item as={Link} to="/profile/view" eventKey="1">
            View/Edit Bio
          </Dropdown.Item>
          <Dropdown.Item as={Link} to="/profile/emailpreferences" eventKey="2">
            Email Preferences
          </Dropdown.Item>
          <Dropdown.Item as={Link} to="/profile/commspreferences" eventKey="3">
            Comms Preferences
          </Dropdown.Item>
          <Dropdown.Item
            as={Link}
            to="/profile/accountpreferences"
            eventKey="4"
          >
            Account Preferences
          </Dropdown.Item>
          {whoami && whoami.role === "Borrower" && (
            <>
              <Dropdown.Divider />
              <Dropdown.Item as={Link} to="/deal" eventKey="5">
                Find a Loan
              </Dropdown.Item>
            </>
          )}
          <Dropdown.Divider />
          <Dropdown.Item as={Link} to="#" onClick={() => this.handleLogout()}>
            Logout
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

export default ProfilePic;
