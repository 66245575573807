import { actionTypes } from "redux/actions";
import { reducerCreator, multipleReducers } from "./meta";

export const company = multipleReducers({
  initializers: {
    initialState: {
      company: {},
      myCompany: {},
      members: [],
      lastInviteToCompany: null,
    },
  },
  list: [
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.GET_MY_COMPANY,
        successFn: (action) => ({
          myCompany: action.data.body,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.GET_COMPANY_MEMBERS,
        successFn: (action) => ({
          members: action.data.body.rows,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.GET_COMPANY_BY_ID,
        successFn: (action) => ({
          company: action.data.body,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.INVITE_TO_COMPANY,
        successFn: (action) => ({
          lastInviteToCompany: action.data,
        }),
      },
    },
  ],
});
