import React from "react";
import { Col, Row, Tooltip } from "antd";
import Spacer from "components/layout/Spacer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { dealViewIds } from "../DealViewContext";
import useCardHidden from "../hooks/useCardHidden";
import { useSelector } from "react-redux";

const PropertyDescription = ({ deal }) => {
  const { propertyDescription, cardHidden = [] } = deal;

  const whoami = useSelector((state) => state.auth.whoami);
  const { isHidden, toggleHidden } = useCardHidden({
    dealId: deal._id,
    cardHidden,
    cardId: dealViewIds.PropertyDescription,
  });

  if (whoami.role !== "Admin" && isHidden) return null;

  return (
    <section className={isHidden ? "tw-bg-gray-200" : ""}>
      <div className="tw-flex tw-space-x-3 tw-items-center">
        <h4>Property Description</h4>
        {whoami.role === "Admin" && (
          <span className="px-2 cursor-pointer">
            <Tooltip
              title={isHidden ? "Show this section" : "Hide this section"}
            >
              <FontAwesomeIcon
                icon={isHidden ? faEyeSlash : faEye}
                className="tw-text-gray-500"
                onClick={toggleHidden}
              />
            </Tooltip>
          </span>
        )}
      </div>
      <Spacer size={5} />
      <div className="border-bottom"></div>
      <Spacer size={20} />
      <Row>
        <Col md={24}>
          <div
            className="tw-space-y-3"
            dangerouslySetInnerHTML={{
              __html: propertyDescription,
            }}
          ></div>
        </Col>
      </Row>
    </section>
  );
};

export default PropertyDescription;
