import { dispatch } from "config/store";
import { actionTypes } from "./actionTypes";

export const createCategory = (data) => {
  const action = {
    type: actionTypes.CREATE_CATEGORY,
    config: {
      method: "post",
      url: `/categories`,
      data,
    },
  };

  dispatch(action);
};

export const updateCategory = (categoryId, data) => {
  const action = {
    type: actionTypes.UPDATE_CATEGORY,
    config: {
      method: "put",
      url: `/categories/${categoryId}`,
      data,
    },
  };

  dispatch(action);
};

export const deleteCategory = (categoryId) => {
  const action = {
    type: actionTypes.DELETE_CATEGORY,
    config: {
      method: "delete",
      url: `/categories/${categoryId}`,
    },
  };

  dispatch(action);
};

export const getCategories = (query = { limit: 100, order: "ASC" }) => {
  const action = {
    type: actionTypes.GET_CATEGORIES,
    config: {
      method: "get",
      url: `/categories?limit=${query.limit}&order=${query.order}&orderColumn=${query.orderColumn}`,
    },
  };

  dispatch(action);
};

export const getManageCategories = (
  query = { limit: 100, order: "ASC", orderColumn: "createdAt", page: 1 }
) => {
  const action = {
    type: actionTypes.GET_MANAGE_CATEGORIES,
    config: {
      method: "get",
      url: `/categories?limit=${query.limit}&order=${query.order}&orderColumn=${query.orderColumn}&page=${query.page}`,
    },
  };

  dispatch(action);
};

export const mergeCategories = (data) => {
  const action = {
    type: actionTypes.MERGE_CATEGORIES,
    config: {
      method: "post",
      url: `/categories/merge`,
      data,
    },
  };

  dispatch(action);
};

export const reorderCategories = (data) => {
  const action = {
    type: actionTypes.REORDER_CATEGORIES,
    config: {
      method: "put",
      url: `/categories/bulk`,
      data,
    },
  };

  dispatch(action);
};
