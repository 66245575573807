import React, { useState } from "react";
import { connect } from "react-redux";
import { Row, Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp, faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import { getKeyFromLocal } from "utils/lsUtils";
import { Redirect } from "react-router-dom";
import Spacer from "components/layout/Spacer";
import constants from "config/constants";
import DealCreateModal from "./DealCreateModal";
import { getDealCreationPath } from "utils/commonUtils";

const DealChoice = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  if (getKeyFromLocal(constants.TERMST_LOCAL_STORAGE.DEAL_ID)) {
    const path = getDealCreationPath();
    return <Redirect to={path} />;
  }

  return (
    <Row className="price-table">
      <Col xs="12">
        <Spacer size={50} />
      </Col>
      <Col className="price-table-item" xs="12" md={{ span: 4, offset: 1 }}>
        <h5 className="price-table-heading">
          <FontAwesomeIcon icon={faThumbsUp} />
          &nbsp;&nbsp;I'm ready
        </h5>
        <Spacer size={40} />
        <h3>Create Loan Package</h3>
        <h4>(30 min)</h4>
        <Spacer size={40} />
        <Button
          onClick={() => setIsModalOpen(true)}
          variant="primary"
          className="outline"
        >
          LET'S GO
        </Button>
      </Col>
      <Col className="d-xs-block d-sm-block d-md-none d-lg-none">
        <Spacer size={20} />
      </Col>
      <Col className="price-table-item" xs="12" md={{ span: 4, offset: 2 }}>
        <Spacer size={20} />
        <h5 className="price-table-heading">
          <FontAwesomeIcon icon={faPhoneAlt} />
          &nbsp;&nbsp;Consult with us
        </h5>
        <Spacer size={40} />
        <h3>Set up call to discuss my loan needs</h3>
        <Spacer size={40} />
        <Button href="/deal/scheduler" variant="primary" className="outline">
          LET'S GO
        </Button>
      </Col>
      <Col xs="12">
        <Spacer size={50} />
      </Col>
      <DealCreateModal show={isModalOpen} setIsModalOpen={setIsModalOpen} history={props.history}/>
    </Row>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    whoami: state.auth.whoami,
  };
}

export default connect(mapStateToProps)(DealChoice);
