import React from "react";
import { Image } from "react-bootstrap";
import { getInitials } from "utils/commonUtils";

const LendersLogo = ({ company }) =>
  company?.companyLogoXS ? (
    <Image
      src={company.companyLogoXS}
      alt={company.companyName}
      width="50px"
      height="50px"
    />
  ) : (
    <div
      className="d-flex align-items-center justify-content-center color-white"
      style={{
        width: 50,
        height: 50,
        minWidth: 50,
        minHeight: 50,
        fontSize: 30,
        background: "#0A467F",
      }}
    >
      {getInitials(company?.companyName)}
    </div>
  );

export default LendersLogo;
