import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import {
  getConversationByIdOptimisticUpdate,
  readConversation,
} from "redux/actions";
import Socket from "utils/Socket";
import { conversationType, conversationTypes } from "./enums";
import InboxMessageDetails from "./message/InboxMessageDetails";
import InboxQuestionDetails from "./question/InboxQuestionDetails";

const InboxDetails = ({ status, tab }) => {
  const conversationSubscriptionId = useRef(null);
  const match = useRouteMatch();
  const whoami = useSelector((state) => state.auth.whoami);
  const myCompany = useSelector((state) => state.company.myCompany);
  const conversationDetails = useSelector(
    (state) => state.conversation.conversationDetails
  );

  const dealId = match.params.id;

  useEffect(() => {
    if (
      whoami &&
      conversationDetails &&
      !conversationDetails.readBy.includes(whoami._id)
    ) {
      readConversation(conversationDetails._id);
    }
  }, [whoami, conversationDetails?._id]);

  useEffect(() => {
    if (conversationDetails) {
      conversationSubscriptionId.current = Socket.subscribe(
        `/deal/${dealId}/conversations/${conversationDetails._id}`,
        (socketData) => {
          if (socketData.action === "ARCHIVE") {
            getConversationByIdOptimisticUpdate({
              ...conversationDetails,
              status: socketData.payload.status,
            });
          }
        }
      );
    }
    return () => {
      Socket.unsubscribe(conversationSubscriptionId.current);
    };
  }, [conversationDetails, dealId]);

  if (!conversationDetails) return null;

  if (
    conversationDetails.type === conversationType.Message ||
    tab === conversationTypes.messages
  )
    return <InboxMessageDetails status={status} tab={tab} />;

  if (
    whoami.role === "Lender" &&
    !conversationDetails.receiverCompanyIds.includes(myCompany._id)
  )
    return null;

  return <InboxQuestionDetails status={status} tab={tab} />;
};

export default InboxDetails;
