export const archiveFilters = {
  all: { label: "All", value: "" },
  archived: { label: "Archived", value: true },
  unarchived: { label: "Unarchived", value: false },
};

export const statusFilters = {
  all: { label: "All", value: "" },
  active: { label: "Active", value: "Active" },
  deleted: { label: "Deleted", value: "Deleted" },
};
export const defaultFilters = {
  status: statusFilters.active,
  archived: archiveFilters.unarchived,
};

export const defaultColumn = {
  category: "",
  order: "",
  sectionName: "",
  sectionDesc: "",
  sectionAmount: "",
  sectionDollarAmount: "",
  sectionPercentage: "",
  sectionDropdown: "",
  sectionYearsMonths: "",
  isSet: false,
  primaryMatrix: true,
};

export const colors = {
  blue: "#0a467f",
  white: "#ffffff",
};
