import constants from "config/constants";
import { getKeyFromLocal } from "utils/lsUtils";
import crypto from 'crypto'


export const isAuthenticated = () => {
  const isAuthenticated = getKeyFromLocal(constants.TERMST_LOCAL_STORAGE.IS_AUTH_KEY);
  return isAuthenticated ? true : false;
};

export const getUserRole = () => {
  const userRole = getKeyFromLocal(constants.TERMST_LOCAL_STORAGE.ROLE);
  return userRole;
};

export const getIdentityForIntercom = (email) => {
//creating hmac object 
// need to add this key in env variables
const hmac = crypto.createHmac('sha256', 'BHECSx5Ug_OdbjsiqlcHxYEdfOjcJT-usDX7uMZ5');
return hmac.update(email).digest('hex')

}