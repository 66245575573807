import React from "react";
import { Button, Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import Spacer from "components/layout/Spacer";
import PopupPrompt from "components/ui/PopupPrompt";
import Cropper from "components/form/Cropper";
import { toast } from "react-toastify";

const ALLOWED_FILE_EXTS = ['.jpg', '.jpeg', '.png'];

class ImageUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showPrompt: false };
    this.onChange = this.onChange.bind(this);
    this.inputRef = React.createRef();
  }

  isValidFile = (file) => {
    const ext = file.name.split('.').reverse()[0];
    if (ALLOWED_FILE_EXTS.includes(`.${ext.toLowerCase()}`)) {
      return true;
    }
    toast.error("Unsupported file", {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    return false;
  }
  onChange(files) {
    if (files && files.length) {
      if (!this.isValidFile(files[0])) {
        return;
      }
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({ newSrc: reader.result, showPrompt: true })
      );
      reader.readAsDataURL(files[0]);
    }
  }

  onSave = (file) => {
    const {
      input: { onChange },
    } = this.props;
    onChange(file);
    this.hidePrompt();
  };

  onChangePhoto = () => {
    this.inputRef.current.click();
  };

  hidePrompt = () => {
    this.setState({ showPrompt: false });
  };

  render() {
    const {
      src,
      meta: { touched, error },
    } = this.props;

    return (
      <div className="img-upload text-center">
        <div
          className="profile-image-upload-icon-wrapper"
          onDragOver={(e) => {
            e.preventDefault();
            e.target.style.background = "#CCC";
            e.target.style.border = "dashed 2px black";
          }}
          onDragLeave={(e) => {
            e.target.style.removeProperty('background');
            e.target.style.removeProperty('border');
          }}
          onDrop={(e) => {
            e.preventDefault();
            if (e.dataTransfer.items) {
              const files = []
              for (let file of e.dataTransfer.items) {
                if (!file.kind === 'file') {
                  continue
                }
                files.push(file.getAsFile())

              }
              this.onChange(files)
            }

            e.target.style.removeProperty('background');
            e.target.style.removeProperty('border');

          }}
        >
          {src ? (
            <Image
              className={"d-block " + (touched && error ? "input-error" : "")}
              src={src}
              alt="Profile picture."
            />
          ) : (
              <FontAwesomeIcon icon={faUpload} />
            )}
        </div>
        <Spacer size={10} />
        <input
          ref={this.inputRef}
          className="d-none"
          type="file"
          accept={ALLOWED_FILE_EXTS.join(',')}
          onChange={(e) => this.onChange(e.target.files)}
        />
        <Button variant="upload" onClick={() => this.inputRef.current.click()}>
          {this.props.uploadButtonText}
        </Button>
        <PopupPrompt
          promptTitle={"Edit Photo"}
          promptBody={
            <Cropper
              src={this.state.newSrc}
              onSave={this.onSave}
              onCancel={this.hidePrompt}
              onChangePhoto={this.onChangePhoto}
            />
          }
          show={this.state.showPrompt}
          onHide={this.hidePrompt}
          onCancel={this.hidePrompt}
        />
      </div>
    );
  }
}
ImageUpload.defaultProps = {
  uploadButtonText: "Upload"
}
export default ImageUpload;
