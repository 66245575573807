import React from "react";
import { Col, Button, Row, Form } from "react-bootstrap";
import { Field, reduxForm } from "redux-form";
import {
  reduxFormInput,
  reduxFormSelect,
  reduxFormTextarea,
} from "components/form/ReduxForm";
import { required } from "utils/validation";
import Spacer from "components/layout/Spacer";

class EditFolder extends React.Component {
  constructor(props) {
    super(props);
    this.rename = this.rename.bind(this);

    this.state = {
      isPublic: true,
      showDropdown: false,
      groupOptions: [],
      selectedLendersAndGroups: [],
      lenderOptions: this.getLenderOptions(),
      categoryOptions: this.getCategoryOptions(),
    };
  }

  componentDidMount() {
    const { change, node, categories } = this.props;
    const category = categories.find((item) =>
      node?.ancestors?.includes(item?._id)
    );
    const categoryItem = { label: category?.name, value: category?._id };
    change("category", categoryItem);
  }

  getCategoryOptions = () => {
    const { categories } = this.props;
    return categories?.map((category) => ({
      label: category.name,
      value: category._id,
    }));
  };

  getLenderOptions() {
    const { deal } = this.props;
    return (
      deal?.lendersAssigned?.map((e) => ({
        group: false,
        value: e.companyId,
        label: e.companyName,
        teamId: e.teamId,
      })) || []
    );
  }

  rename(data) {
    const { onSubmit, id, node } = this.props;
    const payload = { ...data };
    const [root] = node.ancestors;

    if (node.parent !== payload.category?.value) {
      payload.parent = payload.category?.value;
      payload.ancestors = [root].concat([payload.category?.value]);
    }

    delete payload.category;

    onSubmit(id, payload);
  }

  handlePublicToggle = (isPublic) => {
    this.setState({ isPublic });
  };

  handleSelectLendersAndGroups = (selectedLendersAndGroups) => {
    this.setState({ selectedLendersAndGroups });
  };

  render() {
    const { handleSubmit } = this.props;

    return (
      <Form
        className="full-width no-border no-padding"
        onSubmit={handleSubmit(this.rename)}
      >
        <Row>
          <Col xs="12">
            <Field
              className="form-control"
              component={reduxFormSelect}
              name="category"
              type="select"
              options={this.state.categoryOptions}
              placeholder="Select..."
              label="Belongs to Category"
            />
            <Spacer size={20} />
          </Col>
          <Col xs="12">
            <Field
              className="form-control"
              component={reduxFormInput}
              validate={[required]}
              name="name"
              type="text"
              placeholder="Enter folder name..."
              label="Folder Name"
            />
            <Spacer size={20} />
          </Col>
          <Col xs="12">
            <Field
              className="form-control"
              component={reduxFormTextarea}
              validate={[required]}
              name="description"
              type="text"
              placeholder="Enter folder description..."
              label="Folder Description"
              rows={3}
            />
            <Spacer size={20} />
          </Col>
          <Col className="text-right" xs="12">
            <Button type="submit" variant="primary">
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default reduxForm({
  form: "EditFolder",
  enableReinitialize: true,
})(EditFolder);
