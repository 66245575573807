import Spacer from "components/layout/Spacer";
import Loader from "components/ui/Loader";
import constants from "config/constants";
import TSLogo from "components/ui/TSLogo";
import React, { useEffect } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { storeJsonInLocal } from "utils/lsUtils";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import withPageTitle from "components/hoc/withPageTitle";
import { deactivateAccount } from "redux/actions";

const DeactivateConfirm = (props) => {
  const location = useLocation();
  useEffect(() => {
    const paramsString = location.search;
    const searchParams = new URLSearchParams(paramsString);
    const token = searchParams.get("t");
    if (token) {
      let jsonObj = {};
      jsonObj[constants.TERMST_LOCAL_STORAGE.TEMP_TOKEN_KEY] = token;
      storeJsonInLocal(jsonObj);
    }
  }, []);
  const submitFn = () => {
    const formData = new FormData();
    formData.append("accountActivated", false);
    deactivateAccount({ accountActivated: false });
  };
  const onCancel = () => {
    window.location.pathname = "/login";
  };
  const { handleSubmit, action } = props;
  const { isFetching, isFetched } = action.DEACTIVATE_ACCOUNT;
  return (
    <>
      {isFetching && <Loader />}
      <header className="border-bottom">
        <Spacer size={23} />
        <h1 className="text-center">Confirm Account Deactivation</h1>
        <Spacer size={23} />
      </header>
      <Container>
        <Spacer size={50} />
        <Form className="auth-form" onSubmit={handleSubmit(submitFn)}>
          <Row className="justify-content-center">
            <Col className="d-flex justify-content-center" xs="6">
              <TSLogo blue title />
            </Col>
          </Row>
          {!isFetched ? (
            <>
              <Row className="justify-content-center">
                <Col xs="12">
                  <Spacer size={50} />
                </Col>
                <Col xs="12">
                  <p className="text-center">
                    You have requested to deactivate your account.
                    <br />
                    Please confirm your desire to deactivate this account. This
                    cannot be undone.
                  </p>
                  <Spacer size={50} />
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col className="text-center" xs="12" sm="6">
                  <Button variant="dark" onClick={onCancel}>
                    Cancel
                  </Button>
                </Col>
                <Col className="text-center" xs="12" sm="6">
                  <Button type="submit" variant="primary">
                    Confirm
                  </Button>
                </Col>
              </Row>
            </>
          ) : (
            <div className="text-center">
              <Spacer size={50} />
              Your account has been deactivated !!
            </div>
          )}
        </Form>
        <Spacer size={50} />
      </Container>
    </>
  );
};
function mapStateToProps(state) {
  return {
    action: state.action,
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "DeactivateConfirm",
    enableReinitialize: true,
  })(withPageTitle(DeactivateConfirm, "DeactivateConfirm"))
);
