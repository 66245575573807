import React, { useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import ManageLanguage from "containers/termsheet/ManageLanguage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faDollarSign } from "@fortawesome/free-solid-svg-icons";
import {
  reduxFormSelect as Select,
  reduxFormNumber as InputNumber,
} from "components/form/ReduxForm";
import PopupPrompt from "components/ui/PopupPrompt";
import EditDisplayName from "./EditDisplayName";
import { colors } from "containers/quotes/constants";

const yearMonthOptions = [
  { value: "years", label: "Years" },
  { value: "months", label: "Months" },
];

const WrapInForm = ({ children }) => (
  <form onSubmit={(e) => e.preventDefault()} className="p-0 border-0">
    {children}
  </form>
);

const defaultStyle = (isDragging) =>
  isDragging ? {} : { borderBottom: "1px solid #e0dede" };

const getItemStyle = (isDragging, draggableStyle) => ({
  background: isDragging ? colors.white : "",
  border: isDragging ? `1px solid ${colors.blue}` : "",
  paddingTop: isDragging ? "10px" : "",
  ...defaultStyle(isDragging),
  ...draggableStyle,
});

const ClauseRow = ({
  provided,
  snapshot,
  column,
  easeSection,
  index,
  match,
  handleOnChange,
  handleOnRemove,
  handleAddToMatrix,
  quoteMatrices,
  action,
  isDragDisabled,
}) => {
  const [state, setState] = useState({
    showPrompt: false,
  });

  const handleModalOpen = () => {
    setState((prev) => ({ ...prev, showPrompt: true }));
  };

  const handleModalClose = () => {
    setState((prev) => ({ ...prev, showPrompt: false }));
  };

  const addToMatrixDisabled =
    Boolean(column.langDesc) ||
    quoteMatrices.some((quote) => quote.clauseId === column.clause?._id) ||
    column.isPrimary;

  const style = getItemStyle(
    snapshot.isDragging,
    provided.draggableProps.style
  );

  return (
    <>
      <Row
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        style={{
          ...style,
          marginBottom: 10,
        }}
      >
        <Col xs>
          <div className="h-100 d-flex align-items-center">
            <div className="d-flex flex-column align-items-start">
              <p className="bold font-18">{column.sectionName}</p>
              <Button
                className="fake-link color-purple-2 font-14 mb-3 pl-3"
                variant="fake-link"
                onClick={handleModalOpen}
              >
                edit display name
              </Button>
              <div className="d-flex flex-column ">
                {match?.params?.dealId && (
                  <Button
                    disabled={
                      addToMatrixDisabled || action.UPDATE_TERMSHEET.isFetching
                    }
                    className="fake-link color-purple-2 font-14 pl-3"
                    variant="fake-link"
                    onClick={() => handleAddToMatrix(column)}
                    title={
                      column.langDesc
                        ? "Cannot add to matrix. Language must be empty."
                        : ""
                    }
                  >
                    Add to Matrix
                  </Button>
                )}
                <Button
                  className="fake-link color-purple-2 font-14 pl-3 text-left"
                  variant="fake-link"
                  disabled={action.UPDATE_TERMSHEET.isFetching}
                  onClick={() => handleOnRemove(index)}
                >
                  remove
                </Button>
              </div>
            </div>
          </div>
        </Col>
        <Col xs={8}>
          <Row>
            {/* number */}
            {!column.isPrimary &&
              ["#,###", "#.##x"].includes(column.clause?.format) && (
                <Col xs={4} className="mb-2">
                  <WrapInForm>
                    <InputNumber
                      thousandSeparator
                      decimalScale={column.clause?.format === "#,###" ? 0 : 10}
                      allowNegative={false}
                      className="form-control"
                      placeholder="enter number"
                      meta={{}}
                      input={{
                        value: column.sectionValue,
                        onChange: (value) =>
                          handleOnChange("sectionValue", value, index),
                      }}
                    />
                  </WrapInForm>
                </Col>
              )}
            {/* currency */}
            {!column.isPrimary && column.clause?.format === "$#,###" && (
              <Col xs={4} className="mb-2">
                <WrapInForm>
                  <InputNumber
                    thousandSeparator
                    decimalScale={0}
                    allowNegative={false}
                    className="form-control"
                    placeholder="enter currency"
                    meta={{}}
                    input={{
                      value: column.sectionValue,
                      onChange: (value) =>
                        handleOnChange("sectionValue", value, index),
                    }}
                    icon={<FontAwesomeIcon icon={faDollarSign} />}
                  />
                </WrapInForm>
              </Col>
            )}
            {/* percent */}
            {!column.isPrimary &&
              ["#%", "#.#%", "#.##%"].includes(column.clause?.format) && (
                <Col xs={4} className="mb-2">
                  <WrapInForm>
                    <InputNumber
                      decimalScale={
                        column.clause?.format === "#%"
                          ? 0
                          : column.clause?.format === "#.#%"
                          ? 1
                          : 2
                      }
                      allowNegative={false}
                      suffix="%"
                      className="form-control"
                      placeholder="enter percent"
                      meta={{}}
                      input={{
                        value: column.sectionValue,
                        onChange: (value) =>
                          handleOnChange("sectionValue", value, index),
                      }}
                    />
                  </WrapInForm>
                </Col>
              )}
            {/* Add on */}
            {!column.isPrimary &&
              column.clause?.addOn === "Years / Months Selection" && (
                <Col xs={3} className="mb-2">
                  <WrapInForm>
                    <Select
                      className="form-control"
                      wrapperClassName="form-control-wrapper"
                      classNamePrefix="select"
                      placeholder="Select"
                      meta={{}}
                      input={{
                        value: column.sectionYearsMonths
                          ? yearMonthOptions.find(
                              (e) => e.value === column.sectionYearsMonths
                            )
                          : null,
                        onChange: (item) => {
                          handleOnChange(
                            "sectionYearsMonths",
                            item.value,
                            index
                          );
                        },
                      }}
                      options={yearMonthOptions}
                    />
                  </WrapInForm>
                </Col>
              )}
            <Col xs={12}>
              <ManageLanguage
                easeSection={easeSection}
                column={column}
                onChange={(langDesc) =>
                  handleOnChange("langDesc", langDesc, index)
                }
                onChangeClause={(childClauseId) =>
                  handleOnChange("childClauseId", childClauseId, index)
                }
              />
            </Col>
          </Row>
        </Col>
        <Col xs="auto" style={{ textAlign: "right", minWidth: 44 }}>
          {!isDragDisabled && <FontAwesomeIcon icon={faBars} />}
        </Col>
      </Row>
      <PopupPrompt
        promptTitle={`Edit display name for ${column.sectionName}`}
        promptBody={
          <EditDisplayName
            displayName={column.sectionName}
            onCancel={handleModalClose}
            onSubmit={(data) => {
              handleOnChange("sectionName", data.displayName, index);
              handleModalClose();
            }}
          />
        }
        show={Boolean(state.showPrompt)}
        onCancel={handleModalClose}
        onHide={handleModalClose}
      />
    </>
  );
};

ClauseRow.defaultProps = {
  quoteMatrices: [],
};

export default ClauseRow;
