import React from "react";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { required } from "utils/validation";
import { Field, reduxForm } from "redux-form";
import { reduxFormInput, reduxFormNumber } from "components/form/ReduxForm";
import { Col, Button, Row, Form } from "react-bootstrap";
import { connect } from "react-redux";
import Spacer from "components/layout/Spacer";

class AddItem extends React.Component {
  constructor(props) {
    super(props);

    this.addItem = this.addItem.bind(this);
  }

  addItem(data) {
    const { onSubmit, type } = this.props;

    data.type = type;

    onSubmit(data);
  }

  render() {
    const { handleSubmit } = this.props;

    return (
      <>
        <Form
          className="full-width no-border no-padding"
          onSubmit={handleSubmit(this.addItem)}
        >
          <Row>
            <Col xs="12" sm="6">
              <Field
                icon={<FontAwesomeIcon icon={faDollarSign} />}
                className="form-control"
                component={reduxFormNumber}
                validate={[required]}
                name="amount"
                placeholder="Enter amount..."
                label="Amount"
                thousandSeparator={true}
              />
              <Spacer size={20} />
            </Col>
            <Col xs="12" sm="6">
              <Field
                className="form-control"
                component={reduxFormInput}
                validate={[required]}
                name="text"
                type="text"
                placeholder="Enter description..."
                label="Description"
              />
              <Spacer size={20} />
            </Col>
            <Col className="text-right" xs="12">
              <Button type="submit" variant="primary">
                Add
              </Button>
            </Col>
          </Row>
        </Form>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    action: state.action,
    dropdowns: state.program.dropdowns,
    deal: state.deal.deal,
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "AddItem",
    enableReinitialize: true,
  })(AddItem)
);
