import React from "react";
import { connect } from "react-redux";
import { Spinner, Row, Col } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { getTeamsByCompanyId } from "redux/actions";
import Paginate from "components/ui/Paginate";
import Spacer from "components/layout/Spacer";
import TeamLinkItem from './TeamLinkItem'

class ProfileTeams extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gotMyCompany: false,
      gotMyProfile: false,
      page: 1,
    };
  }
  fetchTeams = () => {
    const { user } = this.props;
    getTeamsByCompanyId(user.company._id, {
      limit: 6,
      page: this.state.page,
      userId: user._id,
      includeNotification: true
    });
  }
  componentDidMount() {
    if (this.props.user.company?._id) {
      this.fetchTeams()
    }
  }

  handlePageChange = (page) => {
    this.setState({ page }, this.fetchTeams)
  }


  render() {
    const { action, teams, totalTeams, user } = this.props;
    const { isFetching, isFetched } = action.GET_TEAMS_BY_COMPANY_ID;

    return (
      <>
        <Row>
          <Col xs="12">
            <h3 className="bold">Teams</h3>
            <Spacer size={30} />
          </Col>
        </Row>
        <Row>
          {isFetching ? (
            <Col xs="12">
              <div className="text-center">
                <Spinner animation="border" role="status" variant="primary">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </div>
            </Col>
          ) : !user.company?._id || (isFetched && teams.length === 0) ? (
            <Col xs="12">
              <p className="text-danger">You don't belong to any teams!</p>
              <Spacer size={10} />
            </Col>
          ) : (
                <>
                  {teams.map((team, idx) => {
                    return (
                      <Col xs="12" sm="6" md="4" key={idx}>
                        <div className="panel">
                          <h5 className="bold">
                            <TeamLinkItem
                              id={team._id}
                              name={team.teamName}
                              showIcon={team.noNotification}
                            />
                          </h5>
                        </div>
                      </Col>
                    );
                  })}
                  <Paginate
                    page={this.state.page}
                    list={teams}
                    listTotalCount={totalTeams}
                    listOffset={6}
                    previousPage={this.handlePageChange}
                    nextPage={this.handlePageChange}
                  />
                </>
              )}
        </Row>
        <Spacer size={20} />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    action: state.action,
    user: state.user.userProfile,
    totalTeams: state.team.totalTeams,
    teams: state.team.teams,
  };
}
export default withRouter(connect(mapStateToProps)(ProfileTeams));
