import React from "react";
import { connect } from "react-redux";
import { Form, Button, Row, Col } from "react-bootstrap";
import { Field, reduxForm, formValueSelector } from "redux-form";
import {
  reduxFormInput,
  reduxFormSelect,
  reduxFormTextarea,
} from "components/form/ReduxForm";
import Spacer from "components/layout/Spacer";
import programCategories from "../constants/programCategories";
import { generateSelectOption } from "utils/commonUtils";

class BrokerStep extends React.Component {
  submitFn = () => {
    if (this.saveType === "saveAndContinue") {
      this.props.handleSaveAndContinue(this.props.nextStep);
    } else {
      this.props.handleSaveAndContinue();
    }
  };

  setSave = (type) => () => {
    this.saveType = type;
  };

  componentDidUpdate(prevProps) {
    if (prevProps.isActive !== this.props.isActive && this.props.isActive) {
      this.props.setStepName("How we work with brokers?");
    }
  }

  render() {
    const { handleSubmit, selectedProgramType, selectedPremiumType, action } =
      this.props;
    return (
      <fieldset>
        <Form
          className="no-padding no-border"
          onSubmit={handleSubmit(this.submitFn)}
        >
          <Row>
            <Col xs="12" sm="6">
              {programCategories.agency.indexOf(selectedProgramType.value) ===
                -1 && (
                <>
                  <Field
                    className="form-control"
                    component={reduxFormSelect}
                    name="workWithCorrespondents"
                    type="select"
                    options={this.props.getDropdownValues(
                      "workWithCorrespondents"
                    )}
                    isLoading={action.GET_DROPDOWNS.isFetching}
                    onFocus={() =>
                      this.props.getDropdownFromAPI("workWithCorrespondents")
                    }
                    placeholder="We only work with correspondents?"
                    label="We only work with correspondents?"
                  />
                  <Spacer size={20} />
                </>
              )}
              <Field
                className="form-control"
                component={reduxFormSelect}
                name="canPayBrokerPremium"
                type="select"
                options={this.props.getDropdownValues("yesOrNo")}
                isLoading={action.GET_DROPDOWNS.isFetching}
                onFocus={() => this.props.getDropdownFromAPI("yesOrNo")}
                placeholder="Can You Pay a Broker Premium?"
                label="Can You Pay a Broker Premium?"
              />
              <Spacer size={20} />
              <Field
                className="form-control"
                component={reduxFormSelect}
                name="premiumImpact"
                type="select"
                options={this.props.getDropdownValues("premiumImpact")}
                onFocus={() => this.props.getDropdownFromAPI("premiumImpact")}
                isLoading={action.GET_DROPDOWNS.isFetching}
                placeholder="Premium - Impact on Borrower"
                label="Premium - Impact on Borrower"
              />
              <Spacer size={20} />
            </Col>
            <Col xs="12" sm="6">
              <Field
                className="form-control"
                component={reduxFormSelect}
                name="premiumType"
                type="select"
                options={this.props.getDropdownValues("premiumType")}
                onFocus={() => this.props.getDropdownFromAPI("premiumType")}
                isLoading={action.GET_DROPDOWNS.isFetching}
                placeholder="Premium Type"
                label="Premium Type"
              />
              <Spacer size={20} />
              {selectedPremiumType.value === "Other" && (
                <>
                  <Field
                    className="form-control"
                    component={reduxFormTextarea}
                    name="otherPremiumType"
                    type="textarea"
                    rows="3"
                    placeholder="Other (Premium Type)"
                    label="Other (Premium Type)"
                  />
                  <Spacer size={20} />
                </>
              )}
              <Field
                className="form-control"
                component={reduxFormSelect}
                name="primaryServingOffered"
                type="select"
                options={this.props.getDropdownValues("yesOrNo")}
                onFocus={() => this.props.getDropdownFromAPI("yesOrNo")}
                isLoading={action.GET_DROPDOWNS.isFetching}
                placeholder="Primary Servicing Can be Offered to Brokers"
                label="Primary Servicing Can be Offered to Brokers"
              />
            </Col>
          </Row>
          <Row>
            <Col className="text-left" xs="3">
              <Spacer size={50} />
              <Button
                onClick={this.props.handlePrevious(this.props.previousStep)}
                variant="dark"
              >
                Back
              </Button>
            </Col>
            <Col className="text-right" xs="9">
              <Spacer size={50} />
              <Button
                type="submit"
                variant="primary"
                onClick={this.setSave("saveAndExit")}
              >
                Save & Exit
              </Button>
            </Col>
          </Row>
        </Form>
      </fieldset>
    );
  }
}

const selectorInitialStep = formValueSelector("ProgramCreateInitialStep");
const selectorBrokerStep = formValueSelector("ProgramCreateBrokerStep");

const getInitialValues = ({ isUpdate, isClone, selectedProgram: program }) => {
  if (!isUpdate && !isClone) {
    return {};
  }
  return {
    workWithCorrespondents: generateSelectOption(
      program.workWithCorrespondents
    ),
    canPayBrokerPremium: generateSelectOption(program.canPayBrokerPremium),
    premiumImpact: generateSelectOption(program.premiumImpact),
    premiumType: generateSelectOption(program.premiumType),
    otherPremiumType: program.otherPremiumType,
    primaryServingOffered: generateSelectOption(program.primaryServingOffered),
  };
};

function mapStateToProps(state, props) {
  return {
    action: state.action,
    dropdowns: state.program.dropdowns,
    selectedProgramType: selectorInitialStep(state, "programTypes") || {},
    selectedPremiumType: selectorBrokerStep(state, "premiumType") || {},
    initialValues: getInitialValues(props),
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "ProgramCreateBrokerStep",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    enableReinitialize: true,
  })(BrokerStep)
);
