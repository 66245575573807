import React from "react";
import { connect } from "react-redux";
import { Button, Form, Container, Row, Col } from "react-bootstrap";
import { Field, formValueSelector, reduxForm } from "redux-form";
import { required, noSpaces } from "utils/validation";
import {
  reduxFormInput,
  reduxFormSelect,
  reduxFormTextarea,
  reduxFormWYSIWYG as TextArea,
} from "components/form/ReduxForm";
import { createClause, getClauses } from "redux/actions";
import Spacer from "components/layout/Spacer";
import { adminAvailabilityOptions } from "containers/admin/lookup";

class SaveNewClause extends React.Component {
  constructor(props) {
    super(props);

    this.submitFn = this.submitFn.bind(this);

    this.draft = false;
    this.state = {
      value: props.initialValues.clause || "",
      parentOptions: [],
      ownerOptions: [
        {
          label: "Current User",
          value: props.whoami._id,
        },
      ],
    };
  }

  componentDidMount() {
    this.handleGetClauses();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.column !== this.props.column) {
      this.props.change("clause", this.props.column?.langDesc);
    }
    if (prevProps.lastClauseCreated !== this.props.lastClauseCreated) {
      this.props.onCancel();
    }
  }

  handleGetClauses = () => {
    getClauses({
      limit: 100,
      order: "ASC",
      orderColumn: "clauseName",
      parent: true,
    });
  };

  submitFn(data) {
    createClause({
      clauseName: data.clauseName,
      displayName: data.clauseName,
      parent: data.parent.value,
      adminAvailability: {
        key: data.adminAvailability.value,
        values: [],
      },
      owner: data.owner.value,
      category: data.category,
      format: data.format?.value || data.format,
      addOn: data.addOn?.value || data.addOn,
      notes: data.notes,
      clause: data.clause,
      status: "ACTIVE",
    });
  }

  handleClauseChange = (value) => {
    this.props.change("clause", value);
    this.setState({ value });
  };

  getAvailabilityOptions = () => {
    const { whoami } = this.props;
    return whoami.role === "Admin"
      ? adminAvailabilityOptions
      : adminAvailabilityOptions.filter((e) => e.value !== "ALL_LENDERS");
  };

  render() {
    const { action, onCancel, handleSubmit } = this.props;
    const isProcessing =
      action.CREATE_TAG.isFetching || action.UPDATE_TAG.isFetching;

    return (
      <>
        <Container>
          <Form
            className="no-border no-padding"
            onSubmit={handleSubmit(this.submitFn)}
          >
            <Row>
              <Col xs="12">
                <Field
                  className="form-control"
                  component={reduxFormInput}
                  validate={[required, noSpaces]}
                  name="clauseName"
                  type="text"
                  placeholder="Enter Clause Name and/or Description"
                  label="*Clause Name and/or Description"
                />
              </Col>
            </Row>

            <Spacer size={10} />

            <Row>
              <Col xs="12">
                <Field
                  className="form-control"
                  component={reduxFormSelect}
                  validate={[required]}
                  name="adminAvailability"
                  type="select"
                  options={this.getAvailabilityOptions()}
                  placeholder="Add Availability"
                  label="*Availability"
                />
              </Col>
            </Row>

            <Spacer size={20} />

            <Row>
              <Col xs="12">
                <Field
                  className="form-control"
                  component={reduxFormTextarea}
                  validate={[noSpaces]}
                  name="notes"
                  type="textarea"
                  rows="4"
                  label="Useage Note"
                  placeholder="Add Useage Note..."
                />
              </Col>
            </Row>

            <Spacer size={10} />

            <Row>
              <Col xs="12">
                <TextArea
                  required
                  height={200}
                  className="form-control"
                  name="clause"
                  type="text"
                  label="*Edit for Saving..."
                  placeholder="Add Clause..."
                  input={{
                    name: "clause",
                    value: this.state.value,
                    onChange: this.handleClauseChange,
                  }}
                />
              </Col>
            </Row>

            <Spacer size={20} />

            <Row className="justify-content-center">
              <Col className="text-right" xs="12">
                <Button
                  disabled={isProcessing}
                  variant="dark"
                  className="mr-2"
                  onClick={onCancel}
                >
                  Cancel
                </Button>
                <Button
                  disabled={isProcessing}
                  type="submit"
                  onClick={() => (this.draft = false)}
                  variant="primary"
                >
                  {isProcessing ? "Saving..." : "Save"}
                </Button>
              </Col>
            </Row>
          </Form>

          <Spacer size={20} />
        </Container>
      </>
    );
  }
}

const selector = formValueSelector("SaveNewClause");

function mapStateToProps(state, props) {
  return {
    action: state.action,
    whoami: state.auth.whoami,
    clauses: state.clause.clauses,
    lastClauseCreated: state.clause.lastClauseCreated,
    initialValues: {
      category: props.selectedClause?.category,
      categoryName: props.selectedClause?.category?.categoryName,
      format: props.selectedClause?.format || null,
      addOn: props.selectedClause?.addOn || null,
      notes: props.selectedClause?.notes,
      clause: props.column?.langDesc,
      adminAvailability: null,
      parent: props.selectedClause?._id
        ? {
            label: props.selectedClause?.clauseName,
            value: props.selectedClause?._id,
          }
        : null,
      owner: {
        label: "Current User",
        value: props.selectedClause?.owner._id,
      },
    },
    selectedParent: selector(state, "parent"),
    selectedDisplayName: selector(state, "displayName"),
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "SaveNewClause",
    enableReinitialize: true,
  })(SaveNewClause)
);
