import { dispatch } from "config/store";
import { actionTypes } from "./actionTypes";

export const createTag = (data) => {
  const action = {
    type: actionTypes.CREATE_TAG,
    config: {
      method: "post",
      url: `/tags`,
      data,
    },
  };

  dispatch(action);
};

export const updateTag = (tagId, data) => {
  const action = {
    type: actionTypes.UPDATE_TAG,
    config: {
      method: "put",
      url: `/tags/${tagId}`,
      data,
    },
  };

  dispatch(action);
};

export const deleteTag = (tagId) => {
  const action = {
    type: actionTypes.DELETE_TAG,
    config: {
      method: "delete",
      url: `/tags/${tagId}`,
    },
  };

  dispatch(action);
};

export const getTags = (query = { limit: 100, order: "ASC" }) => {
  const action = {
    type: actionTypes.GET_TAGS,
    config: {
      method: "get",
      url: `/tags?limit=${query.limit}&order=${query.order}`,
    },
  };

  dispatch(action);
};

export const getManageTags = (
  query = { limit: 100, order: "ASC", orderColumn: "createdAt", page: 1 }
) => {
  const action = {
    type: actionTypes.GET_MANAGE_TAGS,
    config: {
      method: "get",
      url: `/tags?limit=${query.limit}&order=${query.order}&orderColumn=${query.orderColumn}&page=${query.page}`,
    },
  };

  dispatch(action);
};

export const mergeTags = (data) => {
  const action = {
    type: actionTypes.MERGE_TAGS,
    config: {
      method: "post",
      url: `/tags/merge`,
      data,
    },
  };

  dispatch(action);
};
