import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Row, Col, Button, Form } from "react-bootstrap";
import { url, noSpaces, required } from "utils/validation";
import { faHashtag } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Field, reduxForm, isPristine } from "redux-form";
import {
  reduxFormInput,
  reduxFormTextarea,
  reduxFormSelect,
  reduxFormNumber,
} from "components/form/ReduxForm";
import { getDropdown, updateDeal, getDealById } from "redux/actions";
import { getKeyFromLocal } from "utils/lsUtils";
import constants from "config/constants";
import Spacer from "components/layout/Spacer";
import Loader from "components/ui/Loader";
import PopupPrompt from "components/ui/PopupPrompt";
import ManagerCreate from "containers/deals/update/ManagerCreate";
import { isEmpty } from "lodash";
import { getDealCreationPath } from "utils/commonUtils";

class Manager extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      managerSizeApartments: 0,
      managerSizeOffice: 0,
      managerSizeMedicalOffice: 0,
      managerSizeIndustrial: 0,
      managerSizeRetail: 0,
      managerSizeHotel: 0,
      managerSizeSeniorLiving: 0,
      managers: [],
      selectedManager: {},
      loadedDropdowns: [],
      showPrompt: false,
      manager: false,
    };

    this.submitFn = this.submitFn.bind(this);
    this.getDropdownFromAPI = this.getDropdownFromAPI.bind(this);
    this.getManagers = this.getManagers.bind(this);
    this.handleShowPrompt = this.handleShowPrompt.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.deal !== this.props.deal && !this.state.initialized) {
      const { deal } = this.props;

      if (deal.managerSizeApartments) {
        this.setState({ managerSizeApartments: deal.managerSizeApartments });
      }

      if (deal.managerSizeOffice) {
        this.setState({ managerSizeOffice: deal.managerSizeOffice });
      }

      if (deal.managerSizeMedicalOffice) {
        this.setState({
          managerSizeMedicalOffice: deal.managerSizeMedicalOffice,
        });
      }

      if (deal.managerSizeIndustrial) {
        this.setState({ managerSizeIndustrial: deal.managerSizeIndustrial });
      }

      if (deal.managementCompanyName) {
        const { myCompany } = this.props;
        const { managers } = myCompany;
        const managerDetails = managers.find(
          (item) =>
            item.managementCompanyName ===
            this.props.deal?.managementCompanyName
        );
        this.setState({
          selectedManager: managerDetails,
        });
      }

      if (deal.managerSizeRetail) {
        this.setState({ managerSizeRetail: deal.managerSizeRetail });
      }

      if (deal.managerSizeHotel) {
        this.setState({ managerSizeHotel: deal.managerSizeHotel });
      }

      if (deal.managerSizeSeniorLiving) {
        this.setState({
          managerSizeSeniorLiving: deal.managerSizeSeniorLiving,
        });
      }

      if (deal.managementCompanyName) {
        const { myCompany } = this.props;
        const { managers } = myCompany;
        const managerDetails = managers.find(
          (item) =>
            item.managementCompanyName ===
            this.props.deal?.managementCompanyName
        );
        this.setState({
          selectedManager: managerDetails,
        });
      }

      this.getManagers();
    }

    if (prevProps.myCompany !== this.props.myCompany) {
      console.log("entered if block");
      const { myCompany } = this.props;
      const { managers } = myCompany;

      this.setState({ managers: [] });

      managers.map((manager) => {
        this.setState((prevState) => ({
          managers: [
            ...prevState.managers,
            {
              ...manager,
              label: manager.managementCompanyName,
              value: manager.managementCompanyName,
            },
          ],
        }));
        return true;
      });
    }
  }

  componentDidMount() {
    if (getKeyFromLocal(constants.TERMST_LOCAL_STORAGE.DEAL_ID) !== false) {
      const dealId = getKeyFromLocal(constants.TERMST_LOCAL_STORAGE.DEAL_ID);

      getDealById(dealId);
    }
  }

  getDropdownFromAPI(value) {
    let canGet = false;

    if (this.state.loadedDropdowns.length > 0) {
      this.state.loadedDropdowns.some((dropdown) => {
        if (dropdown === value) {
          canGet = false;

          return true;
        } else {
          canGet = true;

          return false;
        }
      });
    } else {
      getDropdown(value);

      this.setState((prevState) => ({
        loadedDropdowns: [...prevState.loadedDropdowns, value],
      }));
    }

    if (canGet) {
      getDropdown(value);

      this.setState((prevState) => ({
        loadedDropdowns: [...prevState.loadedDropdowns, value],
      }));
    }
  }

  onManagerChange = (e) => {
    this.setState({
      selectedManager: e,
    });
  };

  getManagers() {
    const { myCompany, deal } = this.props;
    const { managers } = myCompany;

    if (
      isEmpty(this.state.selectedManager) &&
      !isEmpty(deal.managementCompanyName)
    ) {
      const selectedManager = managers.find(
        (manager) =>
          manager.managementCompanyName === deal.managementCompanyName
      );
      this.setState({
        selectedManager,
      });
    }

    if (!this.state.managers.length > 0) {
      managers.map((manager) => {
        this.setState((prevState) => ({
          managers: [
            ...prevState.managers,
            {
              label: manager.managementCompanyName,
              value: manager.managementCompanyName,
              ...manager,
            },
          ],
        }));

        return true;
      });
    }
  }

  submitFn(data) {
    const { isPristine } = this.props;
    const dealId = getKeyFromLocal(constants.TERMST_LOCAL_STORAGE.DEAL_ID);

    for (let property in data) {
      if (typeof data[property] === "object" && data[property] !== null) {
        data[property] = data[property].value;
      } else if (data[property] === null) {
        data[property] = "";
      }
    }
    data.managementCompanyBio =
      this.state.selectedManager?.managementCompanyBio || "";
    data.managementCompanyWebsite =
      this.state.selectedManager?.managementCompanyWebsite || "";
    const path = getDealCreationPath();

    if (isPristine) {
      window.location.pathname = path;
    } else {
      data.manager = true;
      data.managerId = this.state.selectedManager?._id;
      data.isPortifolio = this.props.deal.isPortifolio;

      updateDeal(dealId, data);
    }
  }

  handleShowPrompt(showPrompt, val) {
    this.setState({ showPrompt, manager: val });
  }
  onEditManager = () => {
    this.setState({
      isEdit: true,
      showPrompt: true,
      manager: true,
    });
  };

  render() {
    const { action, dropdowns, handleSubmit, deal, myCompany } = this.props;
    const { isFetching } = action.GET_DEAL_BY_ID;
    const { selectedManager, isEdit } = this.state;
    const path = getDealCreationPath();

    return (
      <>
        {isFetching && <Loader />}
        {this.state.manager && (
          <PopupPrompt
            promptTitle={"Add Manager"}
            promptBody={
              <ManagerCreate
                company={myCompany}
                onSubmit={() => {
                  this.handleShowPrompt(false, false);
                  this.setState({
                    isEdit: false,
                  });
                  this.getManagers();
                }}
                selectedManager={isEdit ? selectedManager : {}}
                isEdit={isEdit}
              />
            }
            show={this.state.showPrompt}
            onCancel={() => {
              this.handleShowPrompt(false, false);
            }}
            onHide={() => {
              this.handleShowPrompt(false, false);
            }}
          />
        )}
        <Form
          className="full-width no-border mobile-no-padding"
          onSubmit={handleSubmit(this.submitFn)}
        >
          <Row>
            <Col xs="12" sm="6" md="8">
              <Field
                className="form-control"
                component={reduxFormSelect}
                validate={[required]}
                options={this.state.managers}
                isLoading={action.GET_MY_COMPANY.isFetching}
                onFocus={() => this.getManagers()}
                onChange={this.onManagerChange}
                name="managementCompanyName"
                type="select"
                placeholder="Select manager..."
                label="*Manager Name"
              />
              <Spacer size={20} />
            </Col>
            <Col xs="12" sm="6" md="4">
              <div className="d-flex">
                <Button
                  className="w-50 mr-2"
                  onClick={() => {
                    this.handleShowPrompt(true, true);
                  }}
                >
                  Add Manager
                </Button>
                {!isEmpty(selectedManager) && (
                  <Button className="w-50" onClick={this.onEditManager}>
                    Edit Manager
                  </Button>
                )}
              </div>
              <Spacer size={20} />
            </Col>
          </Row>
          <Row>
            <Col xs="12" sm="6">
              <Field
                className="form-control"
                component={reduxFormSelect}
                name="managementCompanyAffiliate"
                type="select"
                options={dropdowns["yesNo"] !== {} ? dropdowns["yesNo"] : {}}
                isLoading={action.GET_DROPDOWNS.isFetching}
                onFocus={() => this.getDropdownFromAPI("yesNo")}
                placeholder="None"
                label={"Is Management Company an Affiliate of Sponsor?"}
              />
              <Spacer size={20} />
              <Field
                className="form-control"
                component={reduxFormInput}
                name="managementCompanyWebsite"
                validate={[url, noSpaces]}
                type="text"
                input={{
                  value: selectedManager?.managementCompanyWebsite,
                }}
                placeholder="Enter website URL..."
                label="Management Company Website"
              />
              <Spacer size={20} />
            </Col>
            <Col xs="12" sm="6">
              {deal.propertyType && deal.propertyType === "Multifamily" && (
                <>
                  <Field
                    icon={<FontAwesomeIcon icon={faHashtag} />}
                    className="form-control"
                    component={reduxFormNumber}
                    name="managerSizeApartments"
                    placeholder="Enter amount..."
                    label={deal.propertySizeType}
                    thousandSeparator={true}
                    value={this.state.managerSizeApartments}
                  />
                  <Spacer size={20} />
                </>
              )}
              {deal.propertyType && deal.propertyType === "Office" && (
                <>
                  <Field
                    className="form-control"
                    component={reduxFormNumber}
                    name="managerSizeOffice"
                    placeholder="Enter amount..."
                    label={deal.propertySizeType + " of Office Managed"}
                    thousandSeparator={true}
                    value={this.state.managerSizeOffice}
                    suffix={" sq ft."}
                  />
                  <Spacer size={20} />
                </>
              )}
              {deal.propertyType && deal.propertyType === "Medical Office" && (
                <>
                  <Field
                    className="form-control"
                    component={reduxFormNumber}
                    name="managerSizeMedicalOffice"
                    placeholder="Enter amount..."
                    label={deal.propertySizeType + " of Medical Office Managed"}
                    thousandSeparator={true}
                    value={this.state.managerSizeMedicalOffice}
                    suffix={" sq ft."}
                  />
                  <Spacer size={20} />
                </>
              )}
              {deal.propertyType && deal.propertyType === "Industrial" && (
                <>
                  <Field
                    className="form-control"
                    component={reduxFormNumber}
                    name="managerSizeIndustrial"
                    placeholder="Enter amount..."
                    label={
                      deal.propertySizeType + " of Industrial Area Managed"
                    }
                    thousandSeparator={true}
                    value={this.state.managerSizeIndustrial}
                    suffix={" sq ft."}
                  />
                  <Spacer size={20} />
                </>
              )}
              {deal.propertyType && deal.propertyType === "Retail" && (
                <>
                  <Field
                    className="form-control"
                    component={reduxFormNumber}
                    name="managerSizeRetail"
                    placeholder="Enter amount..."
                    label={deal.propertySizeType + " of Retail Area Managed"}
                    thousandSeparator={true}
                    value={this.state.managerSizeRetail}
                    suffix={" sq ft."}
                  />
                  <Spacer size={20} />
                </>
              )}
              {deal.propertyType && deal.propertyType === "Senior Living" && (
                <>
                  <Field
                    icon={<FontAwesomeIcon icon={faHashtag} />}
                    className="form-control"
                    component={reduxFormNumber}
                    name="managerSizeSeniorLiving"
                    placeholder="Enter amount..."
                    label={
                      deal.propertySizeType + " in Senior Living Area Managed"
                    }
                    thousandSeparator={true}
                    value={this.state.managerSizeSeniorLiving}
                  />
                  <Spacer size={20} />
                </>
              )}
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <Field
                className="form-control"
                component={reduxFormTextarea}
                validate={[noSpaces]}
                name="managementCompanyBio"
                rows="10"
                type="text"
                input={{
                  value: selectedManager?.managementCompanyBio,
                }}
                placeholder="Please describe..."
                label="Management Company Bio"
              />
            </Col>
          </Row>
          <Spacer size={50} />
          <Row className="application-controls">
            <Col className="text-left" xs="6">
              <Button as={Link} className="pl-3 pr-3" to={path} variant="dark">
                Back
              </Button>
            </Col>
            <Col className="text-right" xs="6">
              <Button className="pl-2 pr-2" type="submit" variant="primary">
                Continue
              </Button>
            </Col>
          </Row>
        </Form>
      </>
    );
  }
}

function mapStateToProps(state) {
  const {
    managementCompanyName,
    managerSizeApartments,
    managerSizeOffice,
    managerSizeMedicalOffice,
    managerSizeIndustrial,
    managerSizeRetail,
    managerSizeHotel,
    managerSizeSeniorLiving,
    managementCompanyAffiliate,
    managementCompanyWebsite,
    managementCompanyBio,
  } = state.deal.deal;

  return {
    isPristine: isPristine("Manager")(state),
    action: state.action,
    myCompany: state.company.myCompany,
    dropdowns: state.program.dropdowns,
    deal: state.deal.deal,
    initialValues: {
      managerSizeApartments,
      managerSizeOffice,
      managerSizeMedicalOffice,
      managerSizeIndustrial,
      managerSizeRetail,
      managerSizeHotel,
      managerSizeSeniorLiving,
      managementCompanyWebsite,
      managementCompanyBio,
      managementCompanyName:
        managementCompanyName && managementCompanyName !== null
          ? { label: managementCompanyName, value: managementCompanyName }
          : null,
      managementCompanyAffiliate:
        managementCompanyAffiliate && managementCompanyAffiliate !== null
          ? {
              label: managementCompanyAffiliate,
              value: managementCompanyAffiliate,
            }
          : null,
    },
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "Manager",
    enableReinitialize: true,
  })(Manager)
);
