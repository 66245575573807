import React, { createRef } from "react";
import { connect } from "react-redux";
import { Form, Row, Col, Button } from "react-bootstrap";
import { Field, reduxForm } from "redux-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";

import {
    reduxFormSearch
} from "components/form/ReduxForm";
import Spacer from "components/layout/Spacer";
import { getAllCompaniesBySearch } from "redux/actions";

class AddToCompany extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchInput: "",
            selectedCompany: {}
        };
        this.searchResults = createRef();
    }


    submitFn = (data) => {
        this.props.handleAddToCompany(this.props.user._id, this.state.selectedCompany._id)
    }

    handleCancel = () => {
        this.props.onCancel()
    }

    handleSearchChange = (e) => {
        this.setState({ searchInput: e.target.value, selectedCompany: {} })
    }

    handleSeachCancel = () => {
        this.setState({ searchInput: "", selectedCompany: {} })
    }

    handleSearch = () => {
        getAllCompaniesBySearch({
            search: this.state.searchInput,
            companyType: this.props.user.role
        })
    }

    handleSelectCompany = (company) => {
        this.setState({ searchInput: company.companyName, selectedCompany: company })
        this.searchResults.current.style.display = "none";
    }

    handleSearchBlur = (e) => {
        if (
            e.relatedTarget !==
            this.searchResults.current.target
        ) {
            this.searchResults.current.style.display = "none";
        }
    }

    handleSearchFocus = () => {
        this.searchResults.current.style.display = "block";
    }

    render() {
        const { handleSubmit, searchCompanies, action, user } = this.props;
        const { selectedCompany } = this.state;
        return (
            <>
                <Form
                    className="no-padding no-border"
                    onSubmit={handleSubmit(this.submitFn)}
                >
                    <Row>
                        <Col xs="12" sm="12" >
                            <Field
                                className="form-control"
                                component={reduxFormSearch}
                                name="search"
                                label="Company"
                                type="text"
                                placeholder="Search company..."
                                input={{
                                    onChange: this.handleSearchChange,
                                    onFocus: this.handleSearchFocus
                                }}
                                inputValue={this.state.searchInput}
                                onKeyDown={(e) => {
                                    if (e.keyCode === 13) {
                                        e.preventDefault();
                                        this.handleSearch();
                                    }
                                }}
                            />
                            {(this.state.searchInput.length > 0) && (
                                <Button
                                    className="clear-icon color-medium-gray"
                                    type="submit"
                                    variant=""
                                    onClick={this.handleSeachCancel}
                                >
                                    <FontAwesomeIcon className="search-icon" icon={faTimes} />
                                </Button>
                            )}
                            <Button className="search-icon" type="submit" variant="" style={{ right: 15 }}>
                                <FontAwesomeIcon className="search-icon" icon={faSearch} />
                            </Button>
                            <div
                                className="search-results-wrapper"
                                ref={this.searchResults}
                                style={{ width: "94%", display: "none" }}
                            >
                                <div
                                    className="search-results"
                                // onBlur={(e) => this.handleOptionBlur(e)}
                                // onKeyDown={(e) => this.handleOptionKeyDown(e)}
                                >
                                    {action.GET_ALL_COMPANIES_BY_SEARCH.isFetching && <p> Searching....</p>}
                                    {action.GET_ALL_COMPANIES_BY_SEARCH.isFetched && searchCompanies.map((option, idx) => {
                                        return (
                                            <p
                                                key={idx}
                                                tabIndex="1"
                                                onClick={() => this.handleSelectCompany(option)}
                                            >
                                                <span className="color-medium-gray">
                                                    {option.companyName} <i style={{ fontSize: 14 }}>({option.companyType})</i>
                                                </span>
                                            </p>
                                        );
                                    })}
                                </div>
                            </div>
                            <Spacer size={20} />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" sm="12">
                            {selectedCompany.companyName &&
                                <>Do you want to add <b>{user.firstName} {user.lastName}</b> to <b>{selectedCompany.companyName}</b> ({selectedCompany.companyType})?</>
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-right" xs="12">
                            <Spacer size={50} />
                            <Button
                                className="mr-3"
                                variant="dark"
                                onClick={this.handleCancel}
                            >
                                Cancel
                            </Button>
                            <Button type="submit" variant="primary" disabled={!Boolean(this.state.selectedCompany?._id)}>
                                Add
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </>
        );
    }
}

function mapStateToProps(state, props) {
    return {
        searchCompanies: state.admin.searchCompanies,
        action: state.action
    };

}

export default connect(mapStateToProps)(
    reduxForm({
        form: "addToCompany",
        enableReinitialize: true,
    })(AddToCompany)
);
