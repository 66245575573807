import React from "react";
import { Form, Button, Col, Card, Row } from "react-bootstrap";
import quotesConfig from "./quotesConfig";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { reduxFormNumber as InputNumber } from "components/form/ReduxForm";
import { reduxFormSelect as Select } from "components/form/ReduxForm";
import { toast } from "react-toastify";
import debounce from "lodash/debounce";

class CreateQuote extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.defaultState,
      ...this.props.quote,
      matrices: this.getMatrices(),
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.quoteMatrices !== this.props.quoteMatrices) {
      this.setState({ matrices: this.getMatrices() });
    }
  }

  getMatrices = () => {
    const { quote, quoteMatrices } = this.props;
    const quoteMatricesWithValues = quoteMatrices.map((matrix) => {
      const addedClause = quote.addedClauses?.find((e) => e._id === matrix._id);
      if (addedClause) {
        return {
          ...matrix,
          value: addedClause.value,
        };
      }
      return matrix;
    });
    return [...quotesConfig, ...quoteMatricesWithValues];
  };

  handleSave = (showError) => {
    const { firstQuote, quote } = this.props;
    for (let i in quotesConfig) {
      const config = quotesConfig[i];
      let value = quote[config.key];
      let targetClause =
        config.clauseId &&
        quote.addedClauses?.find((e) => e.clauseId === config.clauseId);
      if (targetClause) {
        value = targetClause.value;
      }

      if (config.showIf && !config.showIf(firstQuote)) {
        continue;
      }
      if (quote.isRateOrSpread === "Rate" && quote.rate === "0.00") {
        return;
      }
      if (!value) {
        if (
          ["floorOptionValue", "floorRate"].includes(config.key) &&
          quote["floorOption"] === "No Floor"
        ) {
          continue;
        }
        if (config.key === "IOPeriod") {
          continue;
        }
        if (config.key === "floorRate") {
          continue;
        }
        const label = config.getLabel
          ? config.getLabel(firstQuote)
          : config.label;
        let message = `Please enter ${label}`;
        if (config.type === "select") {
          message = `Please select ${label}`;
        } else if (config.type === "label") {
          message = `${label} should not be empty`;
        }
        if (showError) {
          toast.error(message, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        return;
      }
    }
    this.props.saveQuote();
  };

  handleReset = () => {
    this.setState(this.defaultState);
    this.props.resetQuote();
  };

  debouncedHandleSave = debounce(this.handleSave, 1000);

  handleBlur = () => {
    if (!this.props.quote?._id) {
      this.debouncedHandleSave(false);
    }
  };

  render() {
    const {
      id,
      quote,
      firstQuote,
      onQuoteOnChange,
      readOnly,
      onRemoveEmptyQuote,
    } = this.props;

    return (
      <Row>
        <Col>
          <Card
            className={`quote-create-card ${readOnly ? "readOnly" : ""}`}
            id="create-quote-card"
          >
            <Card.Body className="quote-card-body">
              <Form
                className="no-border"
                onSubmit={() => this.handleSave(true)}
                onBlur={this.handleBlur}
                style={{ padding: "0px 15px 25px 15px" }}
              >
                {this.state.matrices.map((config) => {
                  if (config.showIf && !config.showIf(firstQuote)) {
                    return null;
                  }
                  let key = config.key + config.label;
                  let value = quote[config.key];
                  let targetClause =
                    config.clauseId &&
                    quote.addedClauses?.find(
                      (e) => e.clauseId === config.clauseId
                    );
                  if (targetClause) {
                    value = targetClause.value;
                    key = key + Object.keys(targetClause).length;
                  }
                  const disabled = config.disableIf
                    ? config.disableIf(this.props.canDisable, quote)
                    : false;
                  const label = config.getLabel
                    ? config.getLabel(firstQuote)
                    : config.label;

                  if (config.type === "label") {
                    return (
                      <InputNumber
                        key={key}
                        className="form-control"
                        id={config.key}
                        placeholder={`${label}`}
                        disabled={disabled || readOnly}
                        meta={{}}
                        defaultValue={value}
                        input={{
                          value,
                          onChange: () => null,
                        }}
                      />
                    );
                  }
                  if (config.type === "currency") {
                    return (
                      <InputNumber
                        key={key}
                        className="form-control"
                        id={config.key}
                        placeholder={`${!disabled ? "Enter" : ""} ${label}`}
                        disabled={disabled || readOnly}
                        meta={{}}
                        defaultValue={value}
                        allowNegative={false}
                        input={{
                          value,
                          onChange: (value) => onQuoteOnChange(config, value),
                        }}
                        icon={<FontAwesomeIcon icon={faDollarSign} />}
                        thousandSeparator
                      />
                    );
                  }
                  if (config.type === "percent") {
                    return (
                      <InputNumber
                        key={key}
                        className="form-control"
                        id={config.key}
                        placeholder={`${!disabled ? "Enter" : ""} ${label}`}
                        disabled={disabled || readOnly}
                        meta={{}}
                        defaultValue={value}
                        allowNegative={false}
                        input={{
                          value,
                          onChange: (value) =>
                            onQuoteOnChange(
                              config,
                              parseFloat(value).toFixed(2)
                            ),
                        }}
                        suffix="%"
                        decimalScale={2}
                        fixedDecimalScale
                      />
                    );
                  }
                  if (config.type === "number") {
                    return (
                      <InputNumber
                        key={key}
                        className="form-control"
                        id={config.key}
                        placeholder={`${!disabled ? "Enter" : ""} ${label}`}
                        disabled={disabled || readOnly}
                        meta={{}}
                        defaultValue={value}
                        allowNegative={false}
                        input={{
                          value,
                          onChange: (value) => onQuoteOnChange(config, value),
                        }}
                      />
                    );
                  }
                  if (config.type === "select") {
                    const selectedValue = value;
                    return (
                      <Select
                        key={key}
                        className="form-control"
                        wrapperClassName="form-control-wrapper"
                        classNamePrefix="select"
                        id={config.key}
                        placeholder={`${!disabled ? "Select" : ""} ${label}`}
                        meta={{}}
                        disabled={disabled || readOnly}
                        input={{
                          value: selectedValue
                            ? { value: selectedValue, label: selectedValue }
                            : null,
                          onChange: (item) =>
                            onQuoteOnChange(config, item.value),
                        }}
                        options={config.options || config.getOptions(quote)}
                      />
                    );
                  }
                  return null;
                })}
              </Form>
            </Card.Body>
          </Card>
          <Row>
            <Col style={{ minHeight: 38 }} className="text-center">
              {!readOnly && (
                <>
                  <Button
                    className="fake-link"
                    variant="fake-link"
                    onClick={this.handleReset}
                  >
                    Reset
                  </Button>{" "}
                  &nbsp;&nbsp;
                  <Button
                    className="fake-link"
                    variant="fake-link"
                    onClick={() => this.handleSave(true)}
                  >
                    Save
                  </Button>
                  &nbsp;&nbsp;
                  <Button
                    className="fake-link"
                    variant="fake-link"
                    onClick={() => onRemoveEmptyQuote(id)}
                  >
                    Delete
                  </Button>
                </>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

CreateQuote.defaultProps = {
  quoteMatrices: [],
};

export default CreateQuote;
