export const getDraggedDom = (draggableId) => {
  const queryAttr = "data-rbd-drag-handle-draggable-id";
  const domQuery = `[${queryAttr}='${draggableId}']`;
  const draggedDOM = document.querySelector(domQuery);

  return draggedDOM;
};

export const getDestinationDom = (dropabbleId) => {
  const destinationQuertAttr = "data-rbd-droppable-id";
  const domQuery = `[${destinationQuertAttr}='${dropabbleId}']`;
  const destinationDOm = document.querySelector(domQuery);
  return destinationDOm;
};

export const updatePlaceholderStyle = (styleValues) => {
  const placeholder = document.querySelector(".droppable-placeholder");
  if (placeholder) {
    Object.assign(placeholder.style, styleValues);
  }
};

export const getDndPlaceholderStyleValuesFromStart = (start) => {
  const draggedDOM = getDraggedDom(start.draggableId);

  if (!draggedDOM) {
    return;
  }

  const { clientHeight, clientWidth } = draggedDOM;
  const sourceIndex = start.source.index;
  const clientY =
    parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
    [...draggedDOM.parentNode.children]
      .slice(0, sourceIndex)
      .reduce((total, curr) => {
        const style = curr.currentStyle || window.getComputedStyle(curr);
        const marginBottom = parseFloat(style.marginBottom);
        return total + curr.clientHeight + marginBottom;
      }, 0);
  const clientX = parseFloat(
    window.getComputedStyle(draggedDOM.parentNode.parentNode).paddingLeft
  );
  const styleValues = {
    top: `${clientY}px`,
    left: `${clientX}px`,
    height: `${clientHeight}px`,
    width: `${clientWidth}px`,
  };

  return styleValues;
};

export const getDndPlaceholderStyleValuesFromUpdate = (event) => {
  if (!event.destination) {
    return;
  }

  const draggedDOM = getDraggedDom(event.draggableId);
  const droppedDom = getDestinationDom(event.destination.droppableId);

  if (!draggedDOM) {
    return;
  }

  let { clientHeight, clientWidth } = draggedDOM;
  let clientX;

  const destinationIndex = event.destination.index;
  const sourceIndex = event.source.index;

  const childrenArray = [...draggedDOM.parentNode.children];
  const destinationChildrenArray = [...droppedDom.children];
  const movedItem = childrenArray[sourceIndex];
  childrenArray.splice(sourceIndex, 1);

  let updatedArray;

  if (draggedDOM.parentNode === droppedDom) {
    clientX = parseFloat(
      window.getComputedStyle(draggedDOM.parentNode.parentNode).paddingLeft
    );
    updatedArray = [
      ...childrenArray.slice(0, destinationIndex),
      movedItem,
      ...childrenArray.slice(destinationIndex + 1),
    ];
  } else {
    clientWidth = droppedDom.parentNode.clientWidth;
    clientX = parseFloat(
      window.getComputedStyle(droppedDom.parentNode).paddingLeft
    );
    updatedArray = [
      ...destinationChildrenArray.slice(0, destinationIndex),
      movedItem,
      ...destinationChildrenArray.slice(destinationIndex + 1),
    ];
  }

  const clientY =
    parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
    updatedArray.slice(0, destinationIndex).reduce((total, curr) => {
      const style = curr.currentStyle || window.getComputedStyle(curr);
      const marginBottom = parseFloat(style.marginBottom);
      return total + curr.clientHeight + marginBottom;
    }, 0);

  const styleValues = {
    top: `${clientY}px`,
    left: `${clientX}px`,
    height: `${clientHeight}px`,
    width: `${clientWidth}px`,
  };

  return styleValues;
};
