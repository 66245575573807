import React, { Component } from "react";
import last from "lodash/last";
import debounce from "lodash/debounce";
import cloneDeep from "lodash/cloneDeep";
import {
  getQuotes,
  createQuote,
  deleteQuote,
  updateQuote,
  getTermsheet,
  getClauses,
  getCategories,
  updateTermsheet,
} from "redux/actions";
import { connect } from "react-redux";
import { Row, Col, Container } from "react-bootstrap";
import ViewQuote from "./ViewQuote";
import CreateQuote from "./CreateQuote";
import quotesConfig, { quoteIndexes, calculateRate } from "./quotesConfig";
import ViewQuoteCard from "./ViewQuoteCard";
import Socket from "utils/Socket";
import queryString from "query-string";
import SplitPane from "react-split-pane";
import BorrowerView from "./BorrowerView";
import QuoteLabels from "./QuoteLabels";
import AddEmptyQuote from "./AddEmptyQuote";
import { sortColumnsByPrimary } from "utils/termsheetUtils";

const emptyQuoteState = {
  loanAmount: "",
  term: "",
  amortization: "",
  IOPeriod: "",
  rate: "",
  index1: "",
  indexTerm1: "",
  index2: "",
  indexTerm2: "",
  indexRate1: "",
  indexRate2: "",
  indexRate: "",
  spread: "",
  floorOptionValue: "",
  floorRate: "",
  quotedRate: "",
};
const defaultQuoteState = {
  ...emptyQuoteState,
  isRateOrSpread: "Spread over Index",
  indexOption: "Equal to Chosen Index",
  floorOption: "",
  termMetric: "years",
  amortizationMetric: "years",
  IOPeriodMetric: "months",
};
class QuoteMatrix extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstQuote: defaultQuoteState,
      newQuote: defaultQuoteState,
      editQuote: null,
      matrices: this.getMatrices(),
      emptyQuotes: [1],
    };
  }

  componentDidMount() {
    this.fetchQuotes();
    const { termsheetId } = this.props.match.params;
    this.subscriptionId = Socket.subscribe(
      `/termsheet/${termsheetId}/quotes`,
      this.debouncedFetchQuotes
    );
    this.toggleSideBar(true);
    getTermsheet(termsheetId);
    getClauses({
      limit: 500,
      order: "ASC",
      orderColumn: "clauseName",
      parent: true,
    });
    getCategories({
      order: "ASC",
      orderColumn: "order",
      limit: 500,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.quotes.length !== this.props.quotes.length ||
      prevProps.quoteMatrices.length !== this.props.quoteMatrices.length
    ) {
      this.setFirstQuote();
    }

    if (
      prevState.newQuote.rate !== this.state.newQuote.rate ||
      prevState.newQuote.floorRate !== this.state.newQuote.floorRate
    ) {
      this.setState((oldState) => ({
        newQuote: {
          ...oldState.newQuote,
          quotedRate: Math.max(
            this.state.newQuote.rate,
            this.state.newQuote.floorRate
          ),
        },
      }));
    }

    if (this.props.quoteMatrices !== prevProps.quoteMatrices) {
      this.setState({ matrices: this.getMatrices() });
    }

    if (this.props.quotes !== prevProps.quotes) {
      this.setState({ editQuote: null });
    }
  }

  componentWillUnmount() {
    Socket.unsubscribe(this.subscriptionId);
    this.toggleSideBar(false);
  }

  toggleSideBar(show) {
    const resizerEl = document.querySelector(".SideBar .Resizer");
    const pane1El = document.querySelector(".SideBar .Pane1");
    const pane2El = document.querySelector(".SideBar .Pane2");
    const addOrRemove = show ? "add" : "remove";
    if (resizerEl) {
      resizerEl.classList[addOrRemove]("d-flex");
    }
    if (pane2El) {
      pane2El.classList[addOrRemove]("d-flex");
    }
    if (pane1El) {
      pane1El.style.height = show ? "220px" : "auto";
    }
  }

  fetchQuotes = () => {
    const { dealId, termsheetId } = this.props.match.params;
    if (dealId) {
      getQuotes({
        dealId,
        termsheetId,
        status: "Active",
      });
    }
  };

  debouncedFetchQuotes = debounce(this.fetchQuotes, 750);

  getMatrices = () => {
    return [...quotesConfig, ...this.props.quoteMatrices];
  };

  getUpdatedCoumns = () => {
    return this.props.columns.map(({ clause, ...col }) => ({
      ...col,
      clauseId: clause?._id,
    }));
  };

  setFirstQuote = () => {
    const { quotes, quoteMatrices } = this.props;
    let firstQuote = quotes.length ? { ...quotes[0] } : defaultQuoteState;
    delete firstQuote._id;
    let newQuote = cloneDeep({
      ...firstQuote,
      ...emptyQuoteState,
      floorOption: "",
    });
    if (!newQuote._id && quoteMatrices) {
      newQuote.addedClauses = quoteMatrices.map((e) => {
        delete e.value;
        return e;
      });
    }
    this.setState({
      firstQuote,
      newQuote,
    });
  };

  onQuoteOnChange = (config, value) => {
    const { quoteMatrices } = this.props;
    const newOrEditField = this.state.editQuote ? "editQuote" : "newQuote";
    let updatedQuote = cloneDeep(this.state[newOrEditField]);
    if (config.clauseId) {
      const updatedAddedClauses = quoteMatrices.map((matrix) => {
        if (matrix._id === config._id) {
          return {
            ...matrix,
            value,
          };
        }
        const found = updatedQuote.addedClauses.find(
          (e) => e._id === matrix._id
        );
        if (found) {
          return found;
        }
        return matrix;
      });
      updatedQuote.addedClauses = updatedAddedClauses;
    } else {
      updatedQuote[config.key] = value;
    }

    this.setState(
      {
        [newOrEditField]: {
          ...updatedQuote,
          ...(config.forcedState ? config.forcedState(updatedQuote) : {}),
        },
      },
      () => {
        if (
          config.key === "indexTerm1" ||
          (config.key === "index1" && value === "SOFR") ||
          config.key === "indexTerm2" ||
          (config.key === "index2" && value === "SOFR")
        ) {
          this.getExnternalIndexRate(updatedQuote, config);
        }
      }
    );
  };

  findExternalIndexRate = (data, externalKey) => {
    const records = data.getElementsByTagName("record");
    for (let i = 0; i < records.length; i++) {
      if (
        records[i].getElementsByTagName("symbol")[0].textContent == externalKey
      ) {
        let extIndexRate =
          records[i].getElementsByTagName("rate")[0].textContent;
        extIndexRate = parseFloat((extIndexRate * 100).toFixed(2));
        return extIndexRate;
      }
    }
    return 0;
  };

  getExnternalIndexRate = (quote, config) => {
    const [index, indexTerm, indexRate] =
      config.key === "index1" || config.key === "indexTerm1"
        ? ["index1", "indexTerm1", "indexRate1"]
        : ["index2", "indexTerm2", "indexRate2"];
    const indexDetails = quoteIndexes.find(
      (eachIndex) => eachIndex.value === quote[index]
    );
    const childSymbol = indexDetails.childSymbol(quote[indexTerm]);
    const externalKey = `${indexDetails.symbol()}${
      childSymbol.startsWith("_") ? childSymbol : ` ${childSymbol}`
    }`.trim();
    fetch("https://www.thefinancials.com/Syndicated/CAPITALSLACK/quotes.xml")
      .then((response) => response.text())
      .then((str) => new window.DOMParser().parseFromString(str, "text/xml"))
      .then((data) => {
        const extIndexRate = this.findExternalIndexRate(data, externalKey);
        this.setState((prevState) => {
          const newState = {
            newQuote: {
              ...prevState.newQuote,
              [indexRate]: extIndexRate,
              rate: calculateRate({
                ...prevState.newQuote,
                indexRate: extIndexRate,
              }),
            },
          };
          const updateIndexRate =
            newState.newQuote.indexOption === "Lesser of Two Indexes"
              ? Math.min(
                  newState.newQuote.indexRate1,
                  newState.newQuote.indexRate2
                )
              : Math.max(
                  newState.newQuote.indexRate1,
                  newState.newQuote.indexRate2
                );
          newState.newQuote.indexRate = parseFloat(updateIndexRate);
          newState.newQuote.rate = calculateRate(newState.newQuote);
          return newState;
        });
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  focusCreateQuoteCard = () => {
    try {
      document
        .getElementById("create-quote-card")
        .scrollIntoView({ inline: "end" });
    } catch (e) {}
  };

  copyQuote = (quote) => {
    const newQuote = { ...quote };
    delete newQuote._id;
    const { emptyQuotes } = this.state;
    const newEmptyQuotes = emptyQuotes.length ? emptyQuotes : [1];
    this.setState({ newQuote, emptyQuotes: newEmptyQuotes });
    this.focusCreateQuoteCard();
  };

  deleteQuote = (quote) => {
    deleteQuote(quote._id);
  };

  editQuote = (quote) => {
    this.setState({ editQuote: quote });
  };

  resetQuote = () => {
    this.setFirstQuote();
  };

  saveQuotes = (quoteMatrices) => {
    const { quotes } = this.props;
    const newMatrix = last(quoteMatrices);
    quotes.forEach((quote) => {
      if (newMatrix) {
        quote.addedClauses = quote.addedClauses
          ? [...quote.addedClauses, newMatrix]
          : [newMatrix];
      }
      this.saveQuote(quote);
    });
  };

  saveQuote = (quote) => {
    const { editQuote, newQuote } = this.state;
    if (editQuote) {
      updateQuote(editQuote._id, editQuote);
      this.resetQuote();
      return;
    }

    const data = quote || newQuote;
    const { quoteMatrices } = this.props;
    const newMatrix = last(quoteMatrices);
    if (newMatrix) {
      data.addedClauses = data.addedClauses
        ? [...data.addedClauses, newMatrix]
        : [newMatrix];
    }
    const { dealId, termsheetId } = this.props.match.params;
    data.dealId = dealId;
    data.termsheetId = termsheetId;
    createQuote(data);
    this.resetQuote();
  };

  handleSubLabelChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      firstQuote: {
        ...prevState.firstQuote,
        [name]: value,
      },
      newQuote: {
        ...prevState.newQuote,
        [name]: value,
      },
    }));
    const allP = this.props.quotes.map((quote) =>
      updateQuote(quote._id, { ...quote, [name]: value })
    );
    if (allP.length) {
      Promise.all(allP);
    }
  };

  handleAddEmptyQuote = () => {
    this.setState((prevState) => ({
      emptyQuotes: [...prevState.emptyQuotes, prevState.emptyQuotes.length + 1],
    }));
  };

  handleRemoveEmptyQuote = (id) => {
    this.setState((prevState) => ({
      emptyQuotes: prevState.emptyQuotes.filter((number) => number !== id),
    }));
  };

  handleSaveAddedClauses = (addedClauses) => {
    updateTermsheet(this.props.match.params.termsheetId, {
      addedClauses,
      doNotRedirect: true,
    });
  };

  handleRemoveAddedClauses = (addedClause) => {
    const { quoteMatrices } = this.props;
    const newQuoteMatrices = quoteMatrices.filter(
      (e) => e._id !== addedClause._id
    );
    this.handleSaveAddedClauses(newQuoteMatrices);
  };

  handleAddClauseToColumns = (config) => {
    const { clauses } = this.props;
    const updatedColumns = this.getUpdatedCoumns();
    const found = clauses.find((clause) => clause.clauseName === config.label);
    let newColumn = {
      sectionName: config.label,
      sectionDesc: "",
      sectionDropdown: "",
      sectionYearsMonths: "",
      isSet: false,
      isPrimary: true,
    };
    if (found) {
      newColumn = {
        ...newColumn,
        order: "",
        primaryMatrix: true,
        clauseId: found._id,
        category: found.category?.categoryName,
      };
    }

    const newColumns = sortColumnsByPrimary([...updatedColumns, newColumn]);
    updateTermsheet(this.props.match.params.termsheetId, {
      columns: newColumns,
      doNotRedirect: true,
    });
  };

  render() {
    const { quotes, match, whoami, quoteMatrices, columns } = this.props;
    const { newQuote, editQuote, matrices, emptyQuotes } = this.state;
    const firstQuote = quotes.length ? quotes[0] : newQuote;
    const { viewQuoteId } = queryString.parse(this.props.location.search);
    const readOnlyQuote = whoami.role === "Admin";

    return (
      <Container style={{ minHeight: "100vh" }}>
        <SplitPane
          className={
            "QuoteMatrix container" +
            (whoami.role === "Borrower" ? " hidePane2" : "")
          }
          split="horizontal"
          minSize={100}
          defaultSize={650}
        >
          {/* Pane1 */}
          <Col xs={12}>
            <Row>
              {/* Mobile Only */}
              {quotes.map((quote, index) => (
                <ViewQuoteCard
                  key={quote._id}
                  whoami={whoami}
                  index={index}
                  quote={quote}
                  firstQuote={firstQuote}
                  copyQuote={this.copyQuote}
                  deleteQuote={this.deleteQuote}
                  editQuote={this.editQuote}
                  className="d-xs-block d-md-none"
                  isFocused={quote._id === viewQuoteId}
                  quoteMatrices={quoteMatrices}
                />
              ))}
            </Row>
            <Row>
              <Col xs={4} sm={4} md={2} lg={2}>
                <QuoteLabels
                  whoami={whoami}
                  matrices={matrices}
                  firstQuote={firstQuote}
                  columns={columns}
                  onSubLabelChange={this.handleSubLabelChange}
                  onRemoveAddedClauses={this.handleRemoveAddedClauses}
                  onAddClauseToColumns={this.handleAddClauseToColumns}
                />
              </Col>
              <Col
                xs={8}
                sm={8}
                md={10}
                lg={10}
                style={{ overflowX: "auto", display: "flex", maxWidth: 975 }}
                className="hide-scrollbar"
              >
                {quotes.map((quote) =>
                  quote._id === editQuote?._id ? (
                    <CreateQuote
                      key={editQuote._id}
                      id={editQuote._id}
                      quote={editQuote}
                      firstQuote={firstQuote}
                      saveQuote={this.saveQuote}
                      resetQuote={this.resetQuote}
                      onQuoteOnChange={this.onQuoteOnChange}
                      readOnly={readOnlyQuote}
                      quoteMatrices={quoteMatrices}
                      onRemoveEmptyQuote={this.handleRemoveEmptyQuote}
                    />
                  ) : (
                    <ViewQuote
                      key={quote._id}
                      quote={quote}
                      firstQuote={firstQuote}
                      copyQuote={this.copyQuote}
                      deleteQuote={this.deleteQuote}
                      editQuote={this.editQuote}
                      className={`d-none d-md-block d-lg-block`}
                      isFocused={quote._id === viewQuoteId}
                      whoami={whoami}
                      quoteMatrices={quoteMatrices}
                    />
                  )
                )}
                {(whoami?.role === "Lender" || whoami?.role === "Admin") &&
                  emptyQuotes.map((id) => (
                    <CreateQuote
                      key={`${newQuote._id || "no-key"}-${id}`}
                      id={id}
                      quote={newQuote}
                      firstQuote={firstQuote}
                      saveQuote={this.saveQuote}
                      resetQuote={this.resetQuote}
                      onQuoteOnChange={this.onQuoteOnChange}
                      readOnly={readOnlyQuote}
                      quoteMatrices={quoteMatrices}
                      onRemoveEmptyQuote={this.handleRemoveEmptyQuote}
                    />
                  ))}
                {!readOnlyQuote && (
                  <AddEmptyQuote
                    whoami={whoami}
                    quote={newQuote}
                    firstQuote={firstQuote}
                    quoteMatrices={quoteMatrices}
                    onAddEmptyQuote={this.handleAddEmptyQuote}
                  />
                )}
              </Col>
            </Row>
          </Col>

          {/* Pane2 */}
          {whoami.role !== "Borrower" && (
            <BorrowerView match={match} saveQuotes={this.saveQuotes} />
          )}
        </SplitPane>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  quotes: state.termsheet.quotes,
  action: state.action,
  whoami: state.auth.whoami,
  columns: state.termsheet.termsheet.columns || [],
  quoteMatrices: state.termsheet.termsheet.addedClauses || [],
  defaultTermsheet: state.deal.deal?.defaultTermsheet,
  clauses: state.clause.clauses,
});

export default connect(mapStateToProps)(QuoteMatrix);
