import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Row, Col, Button, Form } from "react-bootstrap";
import { Field, reduxForm, change, getFormValues } from "redux-form";
import { reduxFormNumber } from "components/form/ReduxForm";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  updateDeal,
  getDealById,
  addSourcesToDeal,
  addUsesToDeal,
  removeUsesFromDeal,
  removeSourcesFromDeal,
  addRefinanceUsesToDeal,
  addRefinanceSourcesToDeal,
  removeRefinanceUsesFromDeal,
  removeRefinanceSourcesFromDeal,
} from "redux/actions";
import { getKeyFromLocal } from "utils/lsUtils";
import NumberFormat from "react-number-format";
import constants from "config/constants";
import PopupPrompt from "components/ui/PopupPrompt";
import Spacer from "components/layout/Spacer";
import AddItem from "containers/deals/ApplicationSteps/AddItem";
import { getDealCreationPath } from "utils/commonUtils";

class SourcesAndUses extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      calculated: false,
      initialized: false,
      showPrompt: false,
      type: "",
      purchasePrice: 0,
      sourceReservesForCapex: 0,
      closingCosts: 0,
      usesArray: [],
      sourceLoan: 0,
      sourceEquity: null,
      sourcesArray: [],
      refinanceExistingLoanPayOff: 0,
      refinanceCurrentBasisCapex: 0,
      refinanceCurrentBasisClosingCosts: 0,
      refinanceUsesArray: [],
      refinanceNewLoan: 0,
      refinanceExistingEquity: null,
      refinanceSourcesArray: [],

      sourceUsesTotal: 0,
      sourcesTotal: 0,
      refinanceCurrentBasisUsesTotal: 0,
      refinanceExistingSourcesTotal: 0,
    };

    this.payoffLoan = React.createRef();

    this.submitFn = this.submitFn.bind(this);
    this.handleShowPrompt = this.handleShowPrompt.bind(this);
    this.addItem = this.addItem.bind(this);
    this.removeItem = this.removeItem.bind(this);
    this.updateTotal = this.updateTotal.bind(this);
    this.updateTotals = this.updateTotals.bind(this);
    this.updateEquity = this.updateEquity.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState !== this.state) {
      const { deal } = this.props;

      if (prevState.sourceUsesTotal !== this.state.sourceUsesTotal) {
        this.setState({
          sourceUsesTotal:
            deal &&
            deal.sourceSources &&
            deal.sourceSources.length >= 0 &&
            this.state.purchasePrice !== null &&
            this.state.sourceReservesForCapex !== null &&
            this.state.closingCosts !== null
              ? deal.sourceUses.reduce(
                  (totalAmount, use) => totalAmount + use.amount,
                  0
                ) +
                this.state.purchasePrice +
                this.state.sourceReservesForCapex +
                this.state.closingCosts
              : 0,
        });
      } else if (prevState.sourcesTotal !== this.state.sourcesTotal) {
        this.setState({
          sourcesTotal:
            deal &&
            deal.sourceSources &&
            deal.sourceSources.length >= 0 &&
            this.state.sourceLoan !== null &&
            this.state.sourceEquity !== null
              ? Number.parseFloat(
                  deal.sourceSources.reduce(
                    (totalAmount, source) => totalAmount + source.amount,
                    0
                  )
                ) +
                Number.parseFloat(this.state.sourceLoan) +
                Number.parseFloat(this.state.sourceEquity)
              : 0,
        });
      } else if (
        prevState.refinanceCurrentBasisUsesTotal !==
        this.state.refinanceCurrentBasisUsesTotal
      ) {
        this.setState({
          refinanceCurrentBasisUsesTotal:
            deal &&
            deal.refinanceAdditionalCurrentBasisUses &&
            deal.refinanceAdditionalCurrentBasisUses.length >= 0 &&
            this.state.refinanceExistingLoanPayOff !== null &&
            this.state.refinanceCurrentBasisCapex !== null &&
            this.state.refinanceCurrentBasisClosingCosts !== null
              ? Number.parseFloat(
                  deal.refinanceAdditionalCurrentBasisUses.reduce(
                    (totalAmount, use) => totalAmount + use.amount,
                    0
                  )
                ) +
                Number.parseFloat(this.state.refinanceExistingLoanPayOff) +
                Number.parseFloat(this.state.refinanceCurrentBasisCapex) +
                Number.parseFloat(this.state.refinanceCurrentBasisClosingCosts)
              : 0,
        });
      } else if (
        prevState.refinanceExistingSourcesTotal !==
        this.state.refinanceExistingSourcesTotal
      ) {
        this.setState({
          refinanceExistingSourcesTotal:
            deal &&
            deal.refinanceAdditionalCurrentBasisSources &&
            deal.refinanceAdditionalCurrentBasisSources.length >= 0 &&
            this.state.refinanceNewLoan !== null &&
            this.state.refinanceExistingEquity !== null
              ? Number.parseFloat(
                  deal.refinanceAdditionalCurrentBasisSources.reduce(
                    (totalAmount, source) => totalAmount + source.amount,
                    0
                  )
                ) +
                Number.parseFloat(this.state.refinanceNewLoan) +
                Number.parseFloat(this.state.refinanceExistingEquity)
              : 0,
        });
      }
    }

    if (prevProps.deal !== this.props.deal && !this.state.initialized) {
      const { deal } = this.props;

      if (deal.sourcePurchasePrice) {
        this.setState({
          purchasePrice: Number.parseFloat(deal.sourcePurchasePrice),
        });
      }

      if (deal.sourceReservesForCapex) {
        this.setState({
          sourceReservesForCapex: Number.parseFloat(
            deal.sourceReservesForCapex
          ),
        });
      }

      if (deal.closingCosts) {
        this.setState({
          closingCosts: Number.parseFloat(deal.closingCosts),
        });
      }

      if (deal.sourceLoan) {
        this.setState({ sourceLoan: Number.parseFloat(deal.sourceLoan) });
      }

      if (deal.refinanceExistingLoanPayOff) {
        this.setState({
          refinanceExistingLoanPayOff: Number.parseFloat(
            deal.refinanceExistingLoanPayOff
          ),
        });
      }

      if (deal.refinanceCurrentBasisCapex) {
        this.setState({
          refinanceCurrentBasisCapex: Number.parseFloat(
            deal.refinanceCurrentBasisCapex
          ),
        });
      }

      if (deal.sourceUsesTotal) {
        this.setState({
          sourceUsesTotal: Number.parseFloat(deal.sourceUsesTotal),
        });
      }

      if (deal.sourcesTotal) {
        this.setState({
          sourcesTotal: Number.parseFloat(deal.sourcesTotal),
        });
      }

      if (deal.refinanceCurrentBasisUsesTotal) {
        this.setState({
          refinanceCurrentBasisUsesTotal: Number.parseFloat(
            deal.refinanceCurrentBasisUsesTotal
          ),
        });
      }

      if (deal.refinanceExistingSourcesTotal) {
        this.setState({
          refinanceExistingSourcesTotal: Number.parseFloat(
            deal.refinanceExistingSourcesTotal
          ),
        });
      }

      if (deal.refinanceCurrentBasisClosingCosts) {
        this.setState({
          refinanceCurrentBasisClosingCosts: Number.parseFloat(
            deal.refinanceCurrentBasisClosingCosts
          ),
        });
      }

      if (deal.refinanceNewLoan) {
        this.setState({
          refinanceNewLoan: Number.parseFloat(deal.refinanceNewLoan),
        });
      } else {
        this.setState({
          refinanceNewLoan: Number.parseFloat(deal.maximumLoanAmountRequested),
        });
      }

      this.setState({ initialized: true });
    }
  }

  componentDidMount() {
    if (getKeyFromLocal(constants.TERMST_LOCAL_STORAGE.DEAL_ID) !== false) {
      const dealId = getKeyFromLocal(constants.TERMST_LOCAL_STORAGE.DEAL_ID);

      getDealById(dealId);
    }
  }

  submitFn(data) {
    const { deal } = this.props;
    const dealId = deal._id;

    data.sourceUsesTotal = (
      deal.sourceUses.reduce(
        (totalAmount, use) => totalAmount + use.amount,
        0
      ) +
      this.state.purchasePrice +
      this.state.sourceReservesForCapex +
      this.state.closingCosts
    ).toString();
    data.sourcesTotal = (
      deal.sourceSources?.reduce(
        (totalAmount, source) => totalAmount + source.amount,
        0
      ) +
      this.state.sourceLoan +
      this.state.sourceEquity
    ).toString();
    data.refinanceCurrentBasisUsesTotal = (
      deal.refinanceAdditionalCurrentBasisUses?.reduce(
        (totalAmount, use) => totalAmount + use.amount,
        0
      ) +
      this.state.refinanceExistingLoanPayOff +
      this.state.refinanceCurrentBasisCapex +
      this.state.refinanceCurrentBasisClosingCosts
    ).toString();
    data.refinanceExistingSourcesTotal = (
      deal.refinanceAdditionalCurrentBasisSources?.reduce(
        (totalAmount, source) => totalAmount + source.amount,
        0
      ) +
      this.state.refinanceNewLoan +
      this.state.refinanceExistingEquity
    ).toString();
    data.sourcesAndUses = true;

    data.sourceEquity = this.state.sourceEquity.toString();
    data.refinanceExistingEquity =
      this.state.refinanceExistingEquity.toString();

    for (let property in data) {
      if (
        data[property] === null ||
        data[property] === "" ||
        isNaN(data[property])
      ) {
        data[property] = "0";
      } else {
        data[property] = data[property].toString();
      }
    }

    updateDeal(dealId, data);
  }

  handleShowPrompt(showPrompt) {
    this.setState({ showPrompt });
  }

  addItem(data) {
    const { deal } = this.props;

    this.setState({
      calculated: false,
      sourceEquity: null,
      refinanceExistingEquity: null,
    });
    this.handleShowPrompt(false);

    const dealId = deal._id;

    if (data.type === "use") {
      addUsesToDeal(dealId, {
        add: data,
      });
    } else if (data.type === "source") {
      addSourcesToDeal(dealId, {
        add: data,
      });
    } else if (data.type === "refinanceUse") {
      addRefinanceUsesToDeal(dealId, {
        add: data,
      });
    } else if (data.type === "refinanceSource") {
      addRefinanceSourcesToDeal(dealId, {
        add: data,
      });
    }
  }

  removeItem(data, type) {
    const { deal } = this.props;

    const dealId = deal._id;

    this.setState({
      calculated: false,
      sourceEquity: null,
      refinanceExistingEquity: null,
    });

    if (type === "use") {
      removeUsesFromDeal(dealId, {
        remove: data,
      });
    } else if (type === "source") {
      removeSourcesFromDeal(dealId, {
        remove: data,
      });
    } else if (type === "refinanceUse") {
      removeRefinanceUsesFromDeal(dealId, {
        remove: data,
      });
    } else if (type === "refinanceSource") {
      removeRefinanceSourcesFromDeal(dealId, {
        remove: data,
      });
    }
  }

  updateTotal(value, prop) {
    if (!value) {
      value = 0;
    }

    value = Number.parseFloat(value);

    switch (prop) {
      case "purchasePrice":
        this.setState({ purchasePrice: value });
        break;
      case "sourceReservesForCapex":
        this.setState({ sourceReservesForCapex: value });
        break;
      case "closingCosts":
        this.setState({ closingCosts: value });
        break;
      case "sourceLoan":
        this.setState({ sourceLoan: value });
        break;
      case "sourceEquity":
        this.setState({ sourceEquity: value });
        break;
      case "refinanceExistingLoanPayOff":
        this.setState({ refinanceExistingLoanPayOff: value });
        break;
      case "refinanceCurrentBasisCapex":
        this.setState({ refinanceCurrentBasisCapex: value });
        break;
      case "refinanceCurrentBasisClosingCosts":
        this.setState({ refinanceCurrentBasisClosingCosts: value });
        break;
      case "refinanceNewLoan":
        this.setState({ refinanceNewLoan: value });
        break;
      case "refinanceExistingEquity":
        this.setState({ refinanceExistingEquity: value });
        break;
      default:
        break;
    }
  }

  updateTotals() {
    const { deal } = this.props;

    this.setState({
      sourceUsesTotal:
        deal &&
        deal.sourceSources &&
        deal.sourceSources.length >= 0 &&
        this.state.purchasePrice !== null &&
        this.state.sourceReservesForCapex !== null &&
        this.state.closingCosts !== null
          ? Number.parseFloat(
              deal.sourceUses.reduce(
                (totalAmount, use) => totalAmount + use.amount,
                0
              )
            ) +
            Number.parseFloat(this.state.purchasePrice) +
            Number.parseFloat(this.state.sourceReservesForCapex) +
            Number.parseFloat(this.state.closingCosts)
          : 0,
      sourcesTotal:
        deal &&
        deal.sourceSources &&
        deal.sourceSources.length >= 0 &&
        this.state.sourceLoan !== null &&
        this.state.sourceEquity !== null
          ? Number.parseFloat(
              deal.sourceSources.reduce(
                (totalAmount, source) => totalAmount + source.amount,
                0
              )
            ) +
            Number.parseFloat(this.state.sourceLoan) +
            Number.parseFloat(this.state.sourceEquity)
          : 0,
      refinanceCurrentBasisUsesTotal:
        deal &&
        deal.refinanceAdditionalCurrentBasisUses &&
        deal.refinanceAdditionalCurrentBasisUses.length >= 0 &&
        this.state.refinanceExistingLoanPayOff !== null &&
        this.state.refinanceCurrentBasisCapex !== null &&
        this.state.refinanceCurrentBasisClosingCosts !== null
          ? Number.parseFloat(
              deal.refinanceAdditionalCurrentBasisUses.reduce(
                (totalAmount, use) => totalAmount + use.amount,
                0
              )
            ) +
            Number.parseFloat(this.state.refinanceExistingLoanPayOff) +
            Number.parseFloat(this.state.refinanceCurrentBasisCapex) +
            Number.parseFloat(this.state.refinanceCurrentBasisClosingCosts)
          : 0,
      refinanceExistingSourcesTotal:
        deal &&
        deal.refinanceAdditionalCurrentBasisSources &&
        deal.refinanceAdditionalCurrentBasisSources.length >= 0 &&
        this.state.refinanceNewLoan !== null &&
        this.state.refinanceExistingEquity !== null
          ? Number.parseFloat(
              deal.refinanceAdditionalCurrentBasisSources.reduce(
                (totalAmount, source) => totalAmount + source.amount,
                0
              )
            ) +
            Number.parseFloat(this.state.refinanceNewLoan) +
            Number.parseFloat(this.state.refinanceExistingEquity)
          : 0,
    });
  }

  updateEquity(val) {
    const { deal } = this.props;

    let sum = 0;

    switch (val) {
      case "sourceEquity":
        sum =
          Number.parseFloat(
            (deal.sourceUses || []).reduce(
              (totalAmount, use) => totalAmount + use.amount,
              0
            )
          ) +
          Number.parseFloat(this.state.purchasePrice) +
          Number.parseFloat(this.state.sourceReservesForCapex) +
          Number.parseFloat(this.state.closingCosts) -
          (Number.parseFloat(
            (deal.sourceSources || []).reduce(
              (totalAmount, source) => totalAmount + source.amount,
              0
            )
          ) +
            Number.parseFloat(this.state.sourceLoan));

        this.setState({ sourceEquity: sum }, function () {
          this.updateTotals();
        });

        break;
      case "refinanceEquity":
        sum =
          Number.parseFloat(
            (deal.refinanceAdditionalCurrentBasisUses || []).reduce(
              (totalAmount, use) => totalAmount + use.amount,
              0
            )
          ) +
          Number.parseFloat(this.state.refinanceExistingLoanPayOff) +
          Number.parseFloat(this.state.refinanceCurrentBasisCapex) +
          Number.parseFloat(this.state.refinanceCurrentBasisClosingCosts) -
          (Number.parseFloat(
            (deal.refinanceAdditionalCurrentBasisSources || []).reduce(
              (totalAmount, source) => totalAmount + source.amount,
              0
            )
          ) +
            Number.parseFloat(this.state.refinanceNewLoan));

        this.setState({ refinanceExistingEquity: sum }, function () {
          this.updateTotals();
        });

        break;
      default:
        break;
    }
  }

  render() {
    const { handleSubmit, deal, dispatch } = this.props;
    const path = getDealCreationPath();

    return (
      <>
        <Form
          className="full-width no-border mobile-no-padding"
          onSubmit={handleSubmit(this.submitFn)}
        >
          <Row>
            <Col xs="6"></Col>
            <Col className="application-controls text-right" xs="6">
              <Button
                className="pl-2 pr-2"
                variant="tertiary"
                onClick={() => {
                  this.updateEquity("sourceEquity");
                  this.updateEquity("refinanceEquity");
                  this.setState({ calculated: true });
                }}
              >
                Calculate
              </Button>
            </Col>
            <Col xs="12">
              <Spacer size={20} />
              <h3 className="bold">
                Sources and Uses{" "}
                {deal && deal.purpose && "(" + deal.purpose + ")"}
              </h3>
              <Spacer size={30} />
            </Col>
          </Row>
          <Row>
            <Col xs="6">
              <p className="bold">
                {deal && deal.purpose === "Acquisition"
                  ? "Uses"
                  : "Current Basis"}
              </p>
              <Field
                icon={<FontAwesomeIcon icon={faDollarSign} />}
                className="form-control"
                component={reduxFormNumber}
                name="sourcePurchasePrice"
                placeholder="Enter purchase price..."
                label="Purchase Price"
                thousandSeparator={true}
                value={this.state.purchasePrice}
                onChange={(e) => {
                  this.updateTotal(Number.parseFloat(e), "purchasePrice");

                  return e.value;
                }}
                onBlur={() => {
                  this.setState({
                    calculated: false,
                    sourceEquity: null,
                    refinanceExistingEquity: null,
                  });
                }}
              />
              <Spacer size={20} />
              <Field
                icon={<FontAwesomeIcon icon={faDollarSign} />}
                className="form-control"
                component={reduxFormNumber}
                name="sourceReservesForCapex"
                placeholder="Enter Prior Cap Ex..."
                label="Prior Cap Ex"
                thousandSeparator={true}
                value={this.state.sourceReservesForCapex}
                onChange={(e) => {
                  this.updateTotal(
                    Number.parseFloat(e),
                    "sourceReservesForCapex"
                  );

                  return e.value;
                }}
                onBlur={() => {
                  this.setState({
                    calculated: false,
                    sourceEquity: null,
                    refinanceExistingEquity: null,
                  });
                }}
              />
              <Spacer size={20} />
              <Field
                icon={<FontAwesomeIcon icon={faDollarSign} />}
                className="form-control"
                component={reduxFormNumber}
                name="closingCosts"
                placeholder="Enter closing costs..."
                label="Closing Costs"
                thousandSeparator={true}
                value={this.state.closingCosts}
                onChange={(e) => {
                  this.updateTotal(Number.parseFloat(e), "closingCosts");

                  return e.value;
                }}
                onBlur={() => {
                  this.setState({
                    calculated: false,
                    sourceEquity: null,
                    refinanceExistingEquity: null,
                  });
                }}
              />
              {deal.sourceUses &&
                deal.sourceUses.map((use, idx) => {
                  return (
                    <div className="field-wrapper" key={idx}>
                      <Spacer size={20} />
                      <Field
                        icon={<FontAwesomeIcon icon={faDollarSign} />}
                        className="form-control"
                        component={reduxFormNumber}
                        name={"use" + idx}
                        defaultValue={use.amount}
                        label={use.text}
                        thousandSeparator={true}
                        normalize={(value) => parseFloat(value)}
                        disabled
                      />
                      <p
                        className="field-remove"
                        onClick={() => {
                          this.removeItem(use._id, "use");
                        }}
                        onKeyDown={(e) =>
                          e.keyCode === 13
                            ? document.activeElement.click()
                            : null
                        }
                        tabIndex="0"
                      >
                        Remove
                      </p>
                    </div>
                  );
                })}
            </Col>
            <Col xs="6">
              <p className="bold">
                {deal && deal.purpose === "Acquisition"
                  ? "Sources"
                  : "Existing Capitalization"}
              </p>
              <Field
                icon={<FontAwesomeIcon icon={faDollarSign} />}
                className="form-control"
                component={reduxFormNumber}
                name="sourceLoan"
                placeholder="Enter existing loan amount..."
                label="Existing Loan"
                thousandSeparator={true}
                value={this.state.sourceLoan}
                onChange={(e) => {
                  this.updateTotal(Number.parseFloat(e), "sourceLoan");

                  return e.value;
                }}
                onBlur={() => {
                  this.setState(
                    {
                      refinanceExistingLoanPayOff: this.state.sourceLoan,
                      calculated: false,
                      sourceEquity: null,
                      refinanceExistingEquity: null,
                    },
                    function () {
                      dispatch(
                        change(
                          "SourcesAndUses",
                          "refinanceExistingLoanPayOff",
                          this.state.refinanceExistingLoanPayOff
                        )
                      );
                    }
                  );
                }}
              />
              {deal.sourceSources &&
                deal.sourceSources.map((source, idx) => {
                  return (
                    <div className="field-wrapper" key={idx}>
                      <Spacer size={20} />
                      <Field
                        icon={<FontAwesomeIcon icon={faDollarSign} />}
                        className="form-control"
                        component={reduxFormNumber}
                        name={"source" + idx}
                        defaultValue={source.amount}
                        label={source.text}
                        thousandSeparator={true}
                        disabled
                      />
                      <p
                        className="field-remove"
                        onClick={() => {
                          this.removeItem(source._id, "source");
                        }}
                        onKeyDown={(e) =>
                          e.keyCode === 13
                            ? document.activeElement.click()
                            : null
                        }
                        tabIndex="0"
                      >
                        Remove
                      </p>
                    </div>
                  );
                })}
              <Spacer size={20} />
              <div className="equity-container">
                <FontAwesomeIcon icon={faDollarSign} />
                <NumberFormat
                  className="d-inline-block"
                  name="sourceEquity"
                  value={this.state.sourceEquity}
                  displayType={"text"}
                  thousandSeparator={true}
                  renderText={(value) => <div>{value}</div>}
                  onChange={(e) => {
                    this.updateTotal(Number.parseFloat(e), "sourceEquity");

                    return e.value;
                  }}
                />
              </div>
              <label>
                <h6 className="bold">
                  {this.state.sourceEquity >= 0
                    ? "EQUITY (Auto-calculated, click Calculate button)"
                    : "RETURN OF EQUITY (Auto-calculated, click Calculate button)"}
                </h6>
              </label>
            </Col>
            <Col xs="6">
              <Spacer size={20} />
              <Button
                className="fake-link"
                variant="fake-link"
                onClick={() => {
                  this.setState({ type: "use" });
                  this.handleShowPrompt(true);
                }}
              >
                Add Use
              </Button>
            </Col>
            <Col xs="6">
              <Spacer size={20} />
              <Button
                className="fake-link"
                variant="fake-link"
                onClick={() => {
                  this.setState({ type: "source" });
                  this.handleShowPrompt(true);
                }}
              >
                Add Source
              </Button>
            </Col>
            <Col xs="6">
              <Spacer size={20} />
              {this.state.calculated ? (
                <>
                  {deal && deal.sourceUses && deal.sourceUses.length >= 0 ? (
                    <NumberFormat
                      value={this.state.sourceUsesTotal}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                      renderText={(value) => <div>{value}</div>}
                    />
                  ) : (
                    "$0"
                  )}
                </>
              ) : (
                <i>
                  <small>Click on calculate button to view the total</small>
                </i>
              )}
              <h5 className="bold">TOTAL</h5>
            </Col>
            <Col xs="6">
              <Spacer size={20} />
              {this.state.calculated ? (
                <>
                  {deal &&
                  deal.sourceSources &&
                  deal.sourceSources.length >= 0 ? (
                    <NumberFormat
                      value={this.state.sourcesTotal}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                      renderText={(value) => <div>{value}</div>}
                    />
                  ) : (
                    "$0"
                  )}
                </>
              ) : (
                <i>
                  <small>Click on calculate button to view the total</small>
                </i>
              )}
              <h5 className="bold">TOTAL</h5>
            </Col>
            <Col xs="12">
              <Spacer size={20} />
              <div className="border-bottom"></div>
              <Spacer size={20} />
            </Col>
          </Row>
          <Spacer size={30} />
          {deal && deal.purpose === "Refinance" && (
            <>
              <Row>
                <Col xs="12">
                  <h3 className="mt-2 bold">Post-Closing Sources & Uses</h3>
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  <Spacer size={30} />
                </Col>
                <Col xs="6">
                  <p className="bold">Uses</p>
                  <Field
                    inputRef={this.payoffLoan}
                    icon={<FontAwesomeIcon icon={faDollarSign} />}
                    className="form-control"
                    component={reduxFormNumber}
                    name="refinanceExistingLoanPayOff"
                    placeholder="Payoff existing loan..."
                    label="Payoff existing loan"
                    thousandSeparator={true}
                    value={this.state.refinanceExistingLoanPayOff}
                    onChange={(e) => {
                      this.updateTotal(
                        parseFloat(e),
                        "refinanceExistingLoanPayOff"
                      );

                      return e.value;
                    }}
                    onBlur={() => {
                      this.setState({
                        calculated: false,
                        sourceEquity: null,
                        refinanceExistingEquity: null,
                      });
                    }}
                  />
                  <Spacer size={20} />
                  <Field
                    icon={<FontAwesomeIcon icon={faDollarSign} />}
                    className="form-control"
                    component={reduxFormNumber}
                    name="refinanceCurrentBasisCapex"
                    placeholder="Planned Capital Expenditures..."
                    label="Planned Capital Expenditures"
                    thousandSeparator={true}
                    value={this.state.refinanceCurrentBasisCapex}
                    onChange={(e) => {
                      this.updateTotal(
                        parseFloat(e),
                        "refinanceCurrentBasisCapex"
                      );

                      return e.value;
                    }}
                    onBlur={() => {
                      this.setState({
                        calculated: false,
                        sourceEquity: null,
                        refinanceExistingEquity: null,
                      });
                    }}
                  />
                  <Spacer size={20} />
                  <Field
                    icon={<FontAwesomeIcon icon={faDollarSign} />}
                    className="form-control"
                    component={reduxFormNumber}
                    name="refinanceCurrentBasisClosingCosts"
                    placeholder="Enter closing costs..."
                    label="Closing costs"
                    thousandSeparator={true}
                    value={this.state.refinanceCurrentBasisClosingCosts}
                    onChange={(e) => {
                      this.updateTotal(
                        parseFloat(e),
                        "refinanceCurrentBasisClosingCosts"
                      );

                      return e.value;
                    }}
                    onBlur={() => {
                      this.setState({
                        calculated: false,
                        sourceEquity: null,
                        refinanceExistingEquity: null,
                      });
                    }}
                  />
                  {deal.refinanceAdditionalCurrentBasisUses &&
                    deal.refinanceAdditionalCurrentBasisUses.map((use, idx) => {
                      return (
                        <div className="field-wrapper" key={idx}>
                          <Spacer size={20} />
                          <Field
                            icon={<FontAwesomeIcon icon={faDollarSign} />}
                            className="form-control"
                            component={reduxFormNumber}
                            name={"refinanceUse" + idx}
                            defaultValue={use.amount}
                            label={use.text}
                            thousandSeparator={true}
                            disabled
                          />
                          <p
                            className="field-remove"
                            onClick={() => {
                              this.removeItem(use._id, "refinanceUse");
                            }}
                            onKeyDown={(e) =>
                              e.keyCode === 13
                                ? document.activeElement.click()
                                : null
                            }
                            tabIndex="0"
                          >
                            Remove
                          </p>
                        </div>
                      );
                    })}
                </Col>
                <Col xs="6">
                  <p className="bold">Sources</p>
                  <Field
                    icon={<FontAwesomeIcon icon={faDollarSign} />}
                    className="form-control"
                    component={reduxFormNumber}
                    name="refinanceNewLoan"
                    placeholder="Enter loan amount..."
                    label="New Loan"
                    thousandSeparator={true}
                    value={this.state.refinanceNewLoan}
                    onChange={(e) => {
                      this.updateTotal(
                        Number.parseFloat(e),
                        "refinanceNewLoan"
                      );

                      return e.value;
                    }}
                    onBlur={() => {
                      this.setState({
                        calculated: false,
                        sourceEquity: null,
                        refinanceExistingEquity: null,
                      });
                    }}
                  />
                  {deal.refinanceAdditionalCurrentBasisSources &&
                    deal.refinanceAdditionalCurrentBasisSources.map(
                      (source, idx) => {
                        return (
                          <div className="field-wrapper" key={idx}>
                            <Spacer size={20} />
                            <Field
                              icon={<FontAwesomeIcon icon={faDollarSign} />}
                              className="form-control"
                              component={reduxFormNumber}
                              name={"refinanceSource" + idx}
                              defaultValue={source.amount}
                              label={source.text}
                              thousandSeparator={true}
                              disabled
                            />
                            <p
                              className="field-remove"
                              onClick={() => {
                                this.removeItem(source._id, "refinanceSource");
                              }}
                              onKeyDown={(e) =>
                                e.keyCode === 13
                                  ? document.activeElement.click()
                                  : null
                              }
                              tabIndex="0"
                            >
                              Remove
                            </p>
                          </div>
                        );
                      }
                    )}
                  <Spacer size={20} />
                  <div className="equity-container">
                    <FontAwesomeIcon icon={faDollarSign} />
                    <NumberFormat
                      className="d-inline-block"
                      name="refinanceExistingEquity"
                      value={this.state.refinanceExistingEquity}
                      displayType={"text"}
                      thousandSeparator={true}
                      renderText={(value) => <div>{value}</div>}
                      onChange={(e) => {
                        this.updateTotal(
                          parseFloat(e),
                          "refinanceExistingEquity"
                        );

                        return e.value;
                      }}
                    />
                  </div>
                  <label>
                    <h6 className="bold">
                      {this.state.refinanceExistingEquity >= 0
                        ? "EQUITY (Auto-calculated, click Calculate button)"
                        : "RETURN OF EQUITY (Auto-calculated, click Calculate button)"}
                    </h6>
                  </label>
                </Col>
                <Col xs="6">
                  <Spacer size={20} />
                  <Button
                    className="fake-link"
                    variant="fake-link"
                    onClick={() => {
                      this.setState({ type: "refinanceUse" });
                      this.handleShowPrompt(true);
                    }}
                  >
                    Add Use
                  </Button>
                </Col>
                <Col xs="6">
                  <Spacer size={20} />
                  <Button
                    className="fake-link"
                    variant="fake-link"
                    onClick={() => {
                      this.setState({ type: "refinanceSource" });
                      this.handleShowPrompt(true);
                    }}
                  >
                    Add Source
                  </Button>
                </Col>
                <Col xs="6">
                  <Spacer size={20} />
                  {this.state.calculated ? (
                    <>
                      {deal &&
                      deal.refinanceAdditionalCurrentBasisUses &&
                      deal.refinanceAdditionalCurrentBasisUses.length >= 0 ? (
                        <NumberFormat
                          value={this.state.refinanceCurrentBasisUsesTotal}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                          renderText={(value) => <div>{value}</div>}
                        />
                      ) : (
                        "$0"
                      )}
                    </>
                  ) : (
                    <i>
                      <small>Click on calculate button to view the total</small>
                    </i>
                  )}
                  <h5 className="bold">TOTAL</h5>
                </Col>

                <Col xs="6">
                  <Spacer size={20} />
                  {this.state.calculated ? (
                    <>
                      {deal &&
                      deal.refinanceAdditionalCurrentBasisSources &&
                      deal.refinanceAdditionalCurrentBasisSources.length >=
                        0 ? (
                        <NumberFormat
                          value={this.state.refinanceExistingSourcesTotal}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                          renderText={(value) => <div>{value}</div>}
                        />
                      ) : (
                        "$0"
                      )}
                    </>
                  ) : (
                    <i>
                      <small>Click on calculate button to view the total</small>
                    </i>
                  )}
                  <h5 className="bold">TOTAL</h5>
                </Col>
                <Col xs="12">
                  <Spacer size={20} />
                  <div className="border-bottom"></div>
                  <Spacer size={20} />
                </Col>
              </Row>
            </>
          )}
          <Spacer size={50} />
          <Row className="application-controls">
            <Col className="text-left" xs="6">
              <Button as={Link} className="pl-3 pr-3" to={path} variant="dark">
                Back
              </Button>
              <Spacer size={20} />
            </Col>
            <Col className="text-right" xs="6">
              <Button
                className="pl-2 pr-2"
                variant="tertiary"
                onClick={() => {
                  this.updateEquity("sourceEquity");
                  this.updateEquity("refinanceEquity");
                  this.setState({ calculated: true });
                }}
              >
                Calculate
              </Button>
              <Spacer size={20} />
              {this.state.calculated && (
                <Button className="pl-2 pr-2" type="submit" variant="primary">
                  Continue
                </Button>
              )}
            </Col>
          </Row>
        </Form>
        <PopupPrompt
          promptTitle={
            this.state.type === "use" ? "Add Capital Use" : "Add Capital Source"
          }
          promptBody={
            <AddItem
              onSubmit={(e) => {
                this.addItem(e);
              }}
              type={this.state.type}
            />
          }
          show={this.state.showPrompt}
          onHide={() => this.handleShowPrompt(false)}
          onCancel={() => this.handleShowPrompt(false)}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  const {
    sourcePurchasePrice,
    sourceReservesForCapex,
    closingCosts,
    sourceLoan,
    sourceEquity,
    maximumLoanAmountRequested,
    refinanceExistingLoanPayOff,
    refinanceCurrentBasisCapex,
    refinanceCurrentBasisClosingCosts,
    refinanceNewLoan,
    refinanceExistingEquity,
  } = state.deal.deal;

  return {
    action: state.action,
    dropdowns: state.program.dropdowns,
    deal: state.deal.deal,
    initialValues: {
      sourcePurchasePrice,
      sourceReservesForCapex,
      closingCosts,
      sourceLoan,
      sourceEquity,
      refinanceExistingLoanPayOff: refinanceExistingLoanPayOff
        ? refinanceExistingLoanPayOff
        : sourceLoan
        ? sourceLoan
        : null,
      refinanceCurrentBasisCapex,
      refinanceCurrentBasisClosingCosts,
      refinanceNewLoan: refinanceNewLoan
        ? refinanceNewLoan
        : maximumLoanAmountRequested
        ? maximumLoanAmountRequested
        : null,
      refinanceExistingEquity,
    },
    formValues: getFormValues("SourcesAndUses")(state) || {},
  };
}

function mapDispatchToProps() {
  return {
    change,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: "SourcesAndUses",
    enableReinitialize: true,
  })(SourcesAndUses)
);
