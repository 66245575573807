import React from "react";
import { Col, Row, Tooltip } from "antd";
import Spacer from "components/layout/Spacer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { dealViewIds } from "../DealViewContext";
import useCardHidden from "../hooks/useCardHidden";
import { useSelector } from "react-redux";

const SponsorInformation = ({ deal }) => {
  const {
    sponsorName,
    keyPrincipleBios,
    netWorthKeyPrincipals,
    liquidityOfKeyPrincipals,
    cardHidden = [],
  } = deal;

  const whoami = useSelector((state) => state.auth.whoami);
  const { isHidden, toggleHidden } = useCardHidden({
    dealId: deal._id,
    cardHidden,
    cardId: dealViewIds.SponsorInformation,
  });

  if (whoami.role !== "Admin" && isHidden) return null;

  if (!sponsorName) return null;

  return (
    <section className={isHidden ? "tw-bg-gray-200" : ""}>
      <div className="tw-flex tw-space-x-3 tw-items-center">
        <h4>Sponsor Information</h4>
        {whoami.role === "Admin" && (
          <span className="px-2 cursor-pointer">
            <Tooltip
              title={isHidden ? "Show this section" : "Hide this section"}
            >
              <FontAwesomeIcon
                icon={isHidden ? faEyeSlash : faEye}
                className="tw-text-gray-500"
                onClick={toggleHidden}
              />
            </Tooltip>
          </span>
        )}
      </div>
      <Spacer size={5} />
      <div className="border-bottom"></div>
      <Spacer size={20} />
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <p className="bold">{sponsorName}</p>
          <p className="font-12 color-medium-gray">Sponsor Name</p>
        </Col>
        <Col xs={24} md={12}>
          <p className="bold">{netWorthKeyPrincipals || "-"}</p>
          <p className="font-12 color-medium-gray">Net Worth Key Principals</p>
        </Col>
        <Col xs={24} md={12}>
          <p className="bold">{liquidityOfKeyPrincipals || "-"}</p>
          <p className="font-12 color-medium-gray">Liquidity Key Principals</p>
        </Col>
        <Col xs={24}>
          <p className="bold">Key Principals Bios</p>
          <p className="font-12 color-medium-gray">{keyPrincipleBios || "-"}</p>
        </Col>
      </Row>
    </section>
  );
};

export default SponsorInformation;
