import React from "react";
import { connect } from "react-redux";
import { Button, Form, Row, Col } from "react-bootstrap";
import { Field, reduxForm } from "redux-form";
import { reduxFormSelect } from "components/form/ReduxForm";
import { required } from "utils/validation";
import { getDeals } from "redux/actions";
import Spacer from "components/layout/Spacer";
import debounce from "lodash/debounce";

class AddToDeal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deals: [],
      companyToAdd: "",
      companyName: "",
      teamToAdd: "",
    };

    this.submitFn = this.submitFn.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.deals !== this.props.deals) {
      const { deals } = this.props;

      this.setState({
        deals: deals.map((deal) => ({
          label: deal.propertyName,
          value: deal._id,
        })),
      });
    }
  }

  componentDidMount() {
    const { companyToAdd, companyName, teamToAdd } = this.props;

    this.setState({
      companyToAdd,
      companyName,
      teamToAdd,
    });
  }

  submitFn(data) {
    const { onSubmit } = this.props;

    onSubmit(data.add.value);
  }

  handleSearchDeals = (search) => {
    const { companyToAdd } = this.props;
    getDeals({
      isPublic: true,
      archived: false,
      companyId: companyToAdd,
      addToDeal: true,
      search,
      pagination: {
        page: 1,
        listOffset: 20,
      },
    });
  };

  debouncedSearchDeals = debounce(this.handleSearchDeals, 750);

  handleInputChange = (inputValue) => {
    if (inputValue?.length >= 3) {
      this.debouncedSearchDeals(inputValue);
    }
  };

  render() {
    const { handleSubmit, onSubmit, action } = this.props;

    return (
      <Form
        className="no-padding no-border"
        onSubmit={handleSubmit(this.submitFn)}
      >
        <Row>
          <Col xs="12">
            <h4 className="bold">Search all Deals</h4>
            <Spacer size={20} />
          </Col>
          <Col xs="12">
            <Field
              className="form-control"
              component={reduxFormSelect}
              validate={[required]}
              name="add"
              type="select"
              options={this.state.deals}
              isLoading={action.GET_DEALS.isFetching}
              placeholder="Type to search deals..."
              label="*Select deal"
              onInputChange={this.handleInputChange}
            />
            <Spacer size={20} />
          </Col>
          {this.state.deals.length > 0 && (
            <Col className="text-right" xs="12">
              <Button variant="dark" onClick={() => onSubmit(null)}>
                Cancel
              </Button>
              <Button className="ml-3" type="submit">
                Add
              </Button>
            </Col>
          )}
        </Row>
      </Form>
    );
  }
}

function mapStateToProps(state) {
  return {
    action: state.action,
    deals: state.deal.deals,
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "AddToDeal",
    enableReinitialize: true,
  })(AddToDeal)
);
