import { actionTypes } from "redux/actions";
import { reducerCreator, multipleReducers } from "./meta";

export const admin = multipleReducers({
  initializers: {
    initialState: {
      schedules: [],
      companies: [],
      companiesTotal: 0,
      searchCompanies: [],
    },
  },
  list: [
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.GET_SCHEDULES,
        successFn: (action) => ({
          schedules: action.data.body.rows,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.GET_ALL_COMPANIES,
        successFn: (action) => ({
          companies: action.data.body.rows
            ? action.data.body.rows
            : action.data.body,
          companiesTotal: action.data.body.count,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.GET_ALL_COMPANIES_BY_SEARCH,
        successFn: (action) => ({
          searchCompanies: action.data.body,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.SEARCH_COMPANIES,
        successFn: (action) => ({
          companies: action.data.body.rows
            ? action.data.body.rows
            : action.data.body,
          companiesTotal: action.data.body.count,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.GET_FILTERED_BORROWERS,
        successFn: (action) => ({
          companies: action.data.body.rows
            ? action.data.body.rows
            : action.data.body,
          companiesTotal: action.data.body.count,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.GET_FILTERED_LENDERS,
        successFn: (action) => ({
          companies: action.data.body.rows
            ? action.data.body.rows
            : action.data.body,
          companiesTotal: action.data.body.count,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.GET_LENDERS_BY_PROGRAM_TAGS,
        successFn: (action) => ({
          companies: action.data.body.rows
            ? action.data.body.rows
            : action.data.body,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.GET_LENDERS_BY_PROGRAM_NOTES,
        successFn: (action) => ({
          companies: action.data.body.rows
            ? action.data.body.rows
            : action.data.body,
        }),
      },
    },
  ],
});
