import React from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { Field, reduxForm } from "redux-form";
import { reduxFormInput } from "components/form/ReduxForm";
import { required, email, noSpaces } from "utils/validation";
import { inviteToCompany } from "redux/actions";
import Spacer from "components/layout/Spacer";

class TeamInvite extends React.Component {
  constructor(props) {
    super(props);

    this.submitFn = this.submitFn.bind(this);
  }

  submitFn(data) {
    const { companyId, teamId, onSubmit } = this.props;

    data.teamId = teamId;
    data.sendInvite = true;

    inviteToCompany(companyId, data);

    onSubmit();
  }

  render() {
    const { handleSubmit } = this.props;

    return (
      <>
        <Row>
          <Col xs="12">
            <Form
              className="no-padding no-border"
              onSubmit={handleSubmit(this.submitFn)}
            >
              <Row>
                <Col xs="12">
                  <p>
                    Invite users who do not have accounts on Term St. to
                    instantly join your company and team. An account will be
                    created for them and they will receive an email with
                    instructions to join.
                  </p>
                  <Spacer size={30} />
                </Col>
                <Col xs="12" sm="4">
                  <Field
                    className="form-control"
                    component={reduxFormInput}
                    validate={[required, noSpaces]}
                    name="firstName"
                    type="text"
                    placeholder="Enter first name"
                    label="*First name"
                  />
                  <Spacer size={20} />
                </Col>
                <Col xs="12" sm="4">
                  <Field
                    className="form-control"
                    component={reduxFormInput}
                    validate={[required, noSpaces]}
                    name="lastName"
                    type="text"
                    placeholder="Enter last name"
                    label="*Last name"
                  />
                  <Spacer size={20} />
                </Col>
                <Col xs="12" sm="4">
                  <Field
                    className="form-control"
                    component={reduxFormInput}
                    validate={[required, email, noSpaces]}
                    name="email"
                    type="email"
                    placeholder="Enter email"
                    label="*Email"
                  />
                  <Spacer size={50} />
                </Col>
                <Col className="text-right" xs="12">
                  <Button type="submit" variant="primary">
                    Invite
                  </Button>
                  <Spacer size={20} />
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </>
    );
  }
}

export default reduxForm({
  form: "TeamInvite",
  enableReinitialize: true,
})(TeamInvite);
