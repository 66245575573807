import { actionTypes } from "redux/actions";
import { reducerCreator, multipleReducers } from "./meta";

export const tag = multipleReducers({
  initializers: {
    initialState: {
      tagsTotalCount: 0,
      tags: [],
      manageTags: {
        count: 0,
        limit: 10,
        rows: [],
      },
      lastTagCreated: null,
      lastTagUpdated: null,
      lastTagDeleted: null,
      lastMerged: null,
    },
  },
  list: [
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.GET_TAGS,
        successFn: (action) => ({
          tags: action.data.body.rows,
          tagsTotalCount: action.data.body.count,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.GET_MANAGE_TAGS,
        successFn: (action) => ({
          manageTags: action.data.body,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.CREATE_TAG,
        successFn: (action) => ({
          lastTagCreated: action.data,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.UPDATE_TAG,
        successFn: (action) => ({
          lastTagUpdated: action.data,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.DELETE_TAG,
        successFn: (action) => ({
          lastTagDeleted: action.data,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.MERGE_TAGS,
        successFn: (action) => ({
          lastMerged: action.data,
        }),
      },
    },
  ],
});
