import React from "react";
import { connect } from "react-redux";
import { Button, Form, Row, Col } from "react-bootstrap";
import { Field, reduxForm } from "redux-form";
import { reduxFormTextarea } from "components/form/ReduxForm";
import { required, noSpaces } from "utils/validation";
import { editComm, editMessage } from "redux/actions";
import Spacer from "components/layout/Spacer";

class EditQuestion extends React.Component {
  constructor(props) {
    super(props);

    this.submitFn = this.submitFn.bind(this);
  }

  submitFn(data) {
    const { onSubmit, whoami, actionComm } = this.props;

    editMessage(actionComm._id, actionComm.messages[0]._id, {
      editedMsg: data.editedInitialMsg,
      whoami,
    });
    editComm(actionComm._id, { editedInitialMsg: data.editedInitialMsg });
    onSubmit();
  }

  render() {
    const { handleSubmit } = this.props;

    return (
      <Form
        className="no-padding no-border"
        onSubmit={handleSubmit(this.submitFn)}
      >
        <Row>
          <Col xs="12">
            <Field
              className="form-control"
              component={reduxFormTextarea}
              validate={[required, noSpaces]}
              name="editedInitialMsg"
              type="textarea"
              rows="10"
              placeholder="Edited question..."
            />
            <Spacer size={50} />
          </Col>
          <Col className="text-right" xs="12">
            <Button type="submit">Edit</Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

function mapStateToProps(state) {
  const { initialMessage, editedInitialMsg } = state.comm.communication;

  return {
    whoami: state.auth.whoami,
    initialValues: {
      editedInitialMsg:
        editedInitialMsg && editedInitialMsg.length > 0
          ? editedInitialMsg
          : initialMessage,
    },
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "EditQuestion",
    enableReinitialize: true,
  })(EditQuestion)
);
