import React from "react";
import { Card, Checkbox, Dropdown, Tooltip } from "antd";
import { useState } from "react";
import {
  InboxOutlined,
  DeleteOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";
import ConversationList from "./inboxList/ConversationList";
import { conversationTypes, inboxBulkActions } from "./enums";
import { bulkArchiveConversation, bulkDeleteConversation } from "redux/actions";
import { useSelector } from "react-redux";
import {
  getConversationsReadByMe,
  getConversationsRespondedByMe,
  getConversationsRespondedByTeam,
} from "./inboxUtils";

const InboxList = ({ status, tab, onTabChange }) => {
  const whoami = useSelector((state) => state.auth.whoami);
  const inboxAll = useSelector((state) => state.conversation.inboxAll);
  const inboxMessages = useSelector(
    (state) => state.conversation.inboxMessages
  );
  const inboxQuestions = useSelector(
    (state) => state.conversation.inboxQuestions
  );
  const inboxAllTotalCount = useSelector(
    (state) => state.conversation.inboxAllTotalCount
  );
  const inboxMessagesTotalCount = useSelector(
    (state) => state.conversation.inboxMessagesTotalCount
  );
  const inboxQuestionsTotalCount = useSelector(
    (state) => state.conversation.inboxQuestionsTotalCount
  );

  const [activeTabKey2, setActiveTabKey2] = useState(tab);
  const [selectedIds, setSelectedIds] = useState([]);

  const bulkDisabled = !selectedIds.length;

  const { list, listCount } = React.useMemo(() => {
    switch (tab) {
      case conversationTypes.all:
        return {
          list: inboxAll,
          listCount: inboxAllTotalCount,
        };
      case conversationTypes.messages:
        return {
          list: inboxMessages,
          listCount: inboxMessagesTotalCount,
        };
      case conversationTypes.questions:
        return {
          list: inboxQuestions,
          listCount: inboxQuestionsTotalCount,
        };
      default:
        return {
          list: [],
          listCount: 0,
        };
    }
  }, [
    tab,
    inboxAll,
    inboxMessages,
    inboxQuestions,
    inboxAllTotalCount,
    inboxMessagesTotalCount,
    inboxQuestionsTotalCount,
  ]);

  const isAllSelected = !!list.length && list.length === selectedIds.length;

  const onCheck = (_, id) => {
    let selected = [...selectedIds];
    const exists = selected.includes(id);
    if (exists) {
      selected = selected.filter((item) => item !== id);
    } else {
      selected = [...selected, id];
    }
    setSelectedIds(selected);
  };

  const onTab2Change = (key) => {
    setSelectedIds([]);
    setActiveTabKey2(key);
    switch (key) {
      case conversationTypes.all:
        onTabChange(conversationTypes.all);
        break;
      case conversationTypes.messages:
        onTabChange(conversationTypes.messages);
        break;
      case conversationTypes.questions:
        onTabChange(conversationTypes.questions);
        break;
      default:
        break;
    }
  };

  const onSelectAll = (e) => {
    const { checked } = e.target;
    if (checked) {
      const allIds = list.map((item) => item._id);
      setSelectedIds(allIds);
    } else {
      setSelectedIds([]);
    }
  };

  const onDelete = () => {
    bulkDeleteConversation({ ids: selectedIds });
    setSelectedIds([]);
  };

  const onArchive = () => {
    bulkArchiveConversation({ ids: selectedIds });
    setSelectedIds([]);
  };

  const selectReadConversations = () => {
    const items = getConversationsReadByMe(list, whoami);
    const ids = items.map((item) => item._id);
    setSelectedIds(ids);
  };

  const selectConversationsRespondedByMe = () => {
    const items = getConversationsRespondedByMe(list, whoami);
    const ids = items.map((item) => item._id);
    setSelectedIds(ids);
  };

  const selectConversationsRespondedByTeam = () => {
    const items = getConversationsRespondedByTeam(list);
    const ids = items.map((item) => item._id);
    setSelectedIds(ids);
  };

  const onClick = ({ key }) => {
    switch (key) {
      case inboxBulkActions.all:
        onSelectAll({ target: { checked: true } });
        break;
      case inboxBulkActions.read:
        selectReadConversations();
        break;
      case inboxBulkActions.respondedByTeam:
        selectConversationsRespondedByTeam();
        break;
      case inboxBulkActions.respondedByMe:
        selectConversationsRespondedByMe();
        break;
      default:
        break;
    }
  };

  const bulkActions = (
    <div className="inbox-bulk-actions">
      <Tooltip title="Select">
        <Checkbox checked={isAllSelected} onClick={onSelectAll} />
        <Dropdown
          menu={{ items, onClick }}
          trigger={["click"]}
          placement="bottomLeft"
        >
          <span className="px-2 cursor-pointer">
            <CaretDownOutlined style={{ fontSize: 14 }} />
          </span>
        </Dropdown>
      </Tooltip>
      <Tooltip title="Delete">
        <DeleteOutlined
          style={{ color: bulkDisabled ? "darkgray" : undefined }}
          disabled={bulkDisabled}
          onClick={onDelete}
        />
      </Tooltip>
      <Tooltip title="Archive">
        <InboxOutlined
          style={{ color: bulkDisabled ? "darkgray" : undefined }}
          disabled={bulkDisabled}
          onClick={onArchive}
        />
      </Tooltip>
    </div>
  );

  return (
    <Card
      style={{
        width: "30%",
      }}
      headStyle={{ padding: "0 16px" }}
      bodyStyle={{ padding: 0 }}
      tabList={tabListNoTitle}
      title={bulkActions}
      activeTabKey={activeTabKey2}
      onTabChange={onTab2Change}
    >
      <ConversationList
        key={`${tab}_${status}`}
        list={list}
        listCount={listCount}
        selectedIds={selectedIds}
        tab={activeTabKey2}
        status={status}
        onCheck={onCheck}
      />
    </Card>
  );
};

const tabListNoTitle = [
  {
    key: conversationTypes.all,
    tab: "All",
  },
  {
    key: conversationTypes.messages,
    tab: "Messages",
  },
  {
    key: conversationTypes.questions,
    tab: "Questions",
  },
];

const items = [
  {
    label: "All",
    key: inboxBulkActions.all,
  },
  {
    label: "Read",
    key: inboxBulkActions.read,
  },
  {
    label: "Responded (by team)",
    key: inboxBulkActions.respondedByTeam,
  },
  {
    label: "Responded (by me)",
    key: inboxBulkActions.respondedByMe,
  },
];

export default InboxList;
