import React, { Fragment } from "react";
import { connect } from "react-redux";
import {
  Card,
  Button,
  Container,
  Row,
  Col,
  Image,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { getTeamById, updateTeamDefault } from "redux/actions";
import { reset } from "redux-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus, faEdit } from "@fortawesome/free-solid-svg-icons";
import Spacer from "components/layout/Spacer";
import SocialMediaButton from "components/ui/SocialMediaButton";
import Avatar from "images/avatar.jpg";
import PopupPrompt from "components/ui/PopupPrompt";
import TeamUpdate from "../update/TeamUpdate";
import Socket from "utils/Socket";
import TeamInvite from "./TeamInvite";
import { Form } from "react-bootstrap";

class Team extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      promptAction: "",
      showPrompt: false,
      defaultTeam: false,
    };

    this.handleShowPrompt = this.handleShowPrompt.bind(this);
  }

  componentDidMount() {
    this.getTeam();
    const { match } = this.props;
    this.subcriptionId = Socket.subscribe(
      `/team/${match.params.id}`,
      this.getTeam
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.team !== prevProps.team) {
      this.setState({
        defaultTeam: Boolean(this.props.team?.isDefault),
      });
    }
    if (
      this.props.lastTeamDefaultUpdated &&
      !prevProps.lastTeamDefaultUpdated
    ) {
      this.setState({
        defaultTeam: true,
      });
    }
  }

  getTeam = () => {
    const { match } = this.props;

    getTeamById(match.params.id);
  };

  handleShowPrompt(showPrompt) {
    this.setState({ showPrompt });
  }

  handleChangeDefaultTeam = () => {
    const { team, match } = this.props;
    const yes = window.confirm(
      "Are you sure you want this to be Default Team?"
    );
    if (yes) {
      const teamLeader = team.teamMembers.find(
        (member) => member.globalRole === "Team Leader"
      );
      updateTeamDefault(match.params.id, {
        userId: teamLeader?.userId?._id,
      });
    }
  };

  componentWillUnmount() {
    Socket.unsubscribe(this.subcriptionId);
  }

  render() {
    const { match, team, dispatch, myCompany, whoami } = this.props;
    const { teamMembers = [] } = team;
    const teamLeader = teamMembers.find(
      (member) => member.globalRole === "Team Leader"
    );
    const isTeamLeader = teamLeader?.userId?._id === whoami?._id;
    const isAdmin = whoami?.role === "Admin";
    const canEdit =
      isAdmin ||
      (team?.teamMembers &&
        myCompany?._id === team.companyId &&
        myCompany?.companyAdmin === whoami?._id &&
        isTeamLeader);
    return (
      <>
        <div className="text-right">
          {canEdit && (
            <>
              {this.state.promptAction === "Invite" && (
                <PopupPrompt
                  promptTitle={"Invite to Team"}
                  promptBody={
                    <TeamInvite
                      companyId={team.companyId}
                      teamId={match.params.id}
                      onSubmit={() => {
                        this.setState({ promptAction: "" });
                        this.handleShowPrompt(false);
                      }}
                    />
                  }
                  show={this.state.showPrompt}
                  onCancel={() => {
                    dispatch(reset("UpdateTeam"));
                    this.setState({ promptAction: "" });
                    this.handleShowPrompt(false);
                  }}
                  onHide={() => {
                    dispatch(reset("UpdateTeam"));
                    this.setState({ promptAction: "" });
                    this.handleShowPrompt(false);
                  }}
                />
              )}
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip id={`tooltip-bottom`}>Invite to Team</Tooltip>
                }
              >
                <Button
                  className="mr-2 pl-3 pt-2 pr-3 pb-2"
                  onClick={() => {
                    this.setState({ promptAction: "Invite" });
                    this.handleShowPrompt(true);
                  }}
                >
                  <FontAwesomeIcon icon={faUserPlus} />
                </Button>
              </OverlayTrigger>
              {this.state.promptAction === "Edit" && (
                <PopupPrompt
                  promptTitle={"Edit Team"}
                  promptBody={
                    <TeamUpdate
                      companyId={team.companyId}
                      teamId={match.params.id}
                      closePrompt={() => {
                        this.setState({ promptAction: "" });
                        this.handleShowPrompt(false);
                      }}
                    />
                  }
                  show={this.state.showPrompt}
                  onCancel={() => {
                    dispatch(reset("UpdateTeam"));
                    this.setState({ promptAction: "" });
                    this.handleShowPrompt(false);
                  }}
                  onHide={() => {
                    dispatch(reset("UpdateTeam"));
                    this.setState({ promptAction: "" });
                    this.handleShowPrompt(false);
                  }}
                />
              )}
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id={`tooltip-bottom`}>Edit</Tooltip>}
              >
                <Button
                  className="pl-3 pt-2 pr-3 pb-2"
                  onClick={() => {
                    this.setState({ promptAction: "Edit" });
                    this.handleShowPrompt(true);
                  }}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </Button>
              </OverlayTrigger>
            </>
          )}
        </div>
        <Container fluid>
          <Row>
            {team &&
              teamMembers &&
              teamMembers.map((member, idx) => {
                const {
                  firstName,
                  lastName,
                  linkedin,
                  email,
                  phoneMobile,
                  phoneLandline,
                  bio,
                  profileImage,
                } = member.userId;

                if (member.globalRole.includes("Lead")) {
                  return (
                    <Fragment key={idx}>
                      <Col xs="12">
                        <h3 className="bold">Team Leader</h3>
                        <Spacer size={10} />
                      </Col>
                      <Col
                        className="d-block d-sm-none d-flex align-items-center"
                        xs="12"
                      >
                        <Image
                          className="d-inline-block mobile-img"
                          src={profileImage ? profileImage : Avatar}
                          alt={
                            firstName + " " + lastName + "'s profile picture."
                          }
                          width="50px"
                          height="auto"
                        />
                        <h3 className="bold d-inline-block pl-3">
                          {firstName + " " + lastName}
                        </h3>
                      </Col>
                      <Col className="d-none d-sm-block" xs="0" sm="4" lg="2">
                        <Image
                          className="mobile-img"
                          src={profileImage ? profileImage : Avatar}
                          alt={
                            firstName + " " + lastName + "'s profile picture."
                          }
                          width="100%"
                          height="auto"
                        />
                      </Col>
                      <Col xs="12" sm="8" lg="10">
                        <h3 className="bold d-none d-sm-block">
                          {firstName + " " + lastName}
                        </h3>
                        <Spacer size={20} />
                        {linkedin && linkedin !== " " && (
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip id={`tooltip-bottom`}>LinkedIn</Tooltip>
                            }
                          >
                            <div className="mr-2 d-inline-block">
                              <SocialMediaButton
                                icon="linkedin"
                                url={linkedin}
                              />
                            </div>
                          </OverlayTrigger>
                        )}
                        {email && (
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip id={`tooltip-bottom`}>Email</Tooltip>
                            }
                          >
                            <div className="mr-2 d-inline-block">
                              <SocialMediaButton
                                icon="email"
                                data={"mailto:" + email}
                              />
                            </div>
                          </OverlayTrigger>
                        )}
                        {phoneMobile && phoneMobile !== " " && (
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip id={`tooltip-bottom`}>
                                Mobile Phone
                              </Tooltip>
                            }
                          >
                            <div className="mr-2 d-inline-block">
                              <SocialMediaButton
                                icon="mobile"
                                data={"tel:" + phoneMobile}
                              />
                            </div>
                          </OverlayTrigger>
                        )}
                        {phoneLandline && phoneLandline !== " " && (
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip id={`tooltip-bottom`}>
                                Office Phone
                              </Tooltip>
                            }
                          >
                            <div className="mr-2 d-inline-block">
                              <SocialMediaButton
                                icon="office"
                                data={"tel:" + phoneLandline}
                              />
                            </div>
                          </OverlayTrigger>
                        )}
                        <Spacer size={10} />
                        {bio && bio !== " " && (
                          <>
                            <h5 className="bold">Bio</h5>
                            <p className="bio">{bio}</p>
                            <Spacer size={10} />
                          </>
                        )}
                        <Form.Check
                          id="default-switch"
                          type="switch"
                          label="Default Team"
                          disabled={this.state.defaultTeam}
                          checked={this.state.defaultTeam}
                          onChange={this.handleChangeDefaultTeam}
                        />
                      </Col>
                    </Fragment>
                  );
                }

                return true;
              })}
          </Row>
          <Spacer size={50} />
          <Row>
            <Col>
              <h3 className="bold">Team Members</h3>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <Spacer size={10} />
            </Col>
            {team &&
              teamMembers &&
              teamMembers
                .filter((member) => member.userId?.status !== "SUSPENDED")
                .map((member, idx) => {
                  const {
                    firstName,
                    lastName,
                    linkedin,
                    email,
                    phoneMobile,
                    phoneLandline,
                    profileImage,
                  } = member.userId;

                  if (!member.globalRole.includes("Lead")) {
                    return (
                      <Col xs="12" sm="6" key={idx}>
                        <Card className="team-card">
                          <Card.Header>
                            <Row>
                              <Col
                                className="d-flex align-items-center"
                                xs="12"
                              >
                                <Image
                                  className="d-inline-block"
                                  src={profileImage ? profileImage : Avatar}
                                  alt={
                                    firstName +
                                    " " +
                                    lastName +
                                    "'s profile picture."
                                  }
                                  width="50px"
                                  height="auto"
                                />
                                <h3 className="bold d-inline-block pl-3">
                                  {firstName + " " + lastName}
                                </h3>
                                {!member.userId.accountActivated && (
                                  <p className="pl-2 bold">(Pending)</p>
                                )}
                              </Col>
                            </Row>
                          </Card.Header>
                          <Card.Body>
                            <Row>
                              <Col xs="12">
                                <p className="bold">Member Information:</p>
                              </Col>
                              <Col xs="12">
                                <Spacer size={10} />
                                {linkedin && linkedin !== " " && (
                                  <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                      <Tooltip id={`tooltip-bottom`}>
                                        LinkedIn
                                      </Tooltip>
                                    }
                                  >
                                    <div className="mr-2 d-inline-block">
                                      <SocialMediaButton
                                        icon="linkedin"
                                        url={linkedin}
                                      />
                                    </div>
                                  </OverlayTrigger>
                                )}
                                {email && (
                                  <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                      <Tooltip id={`tooltip-bottom`}>
                                        Email
                                      </Tooltip>
                                    }
                                  >
                                    <div className="mr-2 d-inline-block">
                                      <SocialMediaButton
                                        icon="email"
                                        data={"mailto:" + email}
                                      />
                                    </div>
                                  </OverlayTrigger>
                                )}
                                {phoneMobile && phoneMobile !== " " && (
                                  <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                      <Tooltip id={`tooltip-bottom`}>
                                        Mobile Phone
                                      </Tooltip>
                                    }
                                  >
                                    <div className="mr-2 d-inline-block">
                                      <SocialMediaButton
                                        icon="mobile"
                                        data={"tel:" + phoneMobile}
                                      />
                                    </div>
                                  </OverlayTrigger>
                                )}
                                {phoneLandline && phoneLandline !== " " && (
                                  <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                      <Tooltip id={`tooltip-bottom`}>
                                        Office Phone
                                      </Tooltip>
                                    }
                                  >
                                    <div className="mr-2 d-inline-block">
                                      <SocialMediaButton
                                        icon="office"
                                        data={"tel:" + phoneLandline}
                                      />
                                    </div>
                                  </OverlayTrigger>
                                )}
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                        <Spacer size={20} />
                      </Col>
                    );
                  }

                  return true;
                })}
            <Spacer size={20} />
          </Row>
        </Container>
        <Spacer size={50} />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    action: state.action,
    whoami: state.auth.whoami,
    team: state.team.team,
    myCompany: state.company.myCompany,
    lastTeamDefaultUpdated: state.team.lastTeamDefaultUpdated,
  };
}

export default connect(mapStateToProps)(Team);
