const { hiddenDealCard } = require("redux/actions");

const useCardHidden = ({ dealId, cardHidden, cardId }) => {
  const isHidden = cardHidden.includes(cardId);

  const toggleHidden = () => {
    if (isHidden) {
      hiddenDealCard(dealId, {
        remove: cardId,
      });
    } else {
      hiddenDealCard(dealId, {
        add: cardId,
      });
    }
  };

  return {
    isHidden,
    toggleHidden,
  };
};

export default useCardHidden;
