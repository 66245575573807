import React from "react";
import { connect } from "react-redux";
import { Button, Form, Row, Col } from "react-bootstrap";
import { Field, reduxForm, reset } from "redux-form";
import { reduxFormInput, reduxFormSelect } from "components/form/ReduxForm";
import { required } from "utils/validation";
import { getGroups, createGroup, editGroup, deleteGroup } from "redux/actions";
import Spacer from "components/layout/Spacer";

class Groups extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lendersAssigned: [],
      groupToAddTo: {},
      step: 0,
    };

    this.submitFn = this.submitFn.bind(this);
    this.removeFromGroup = this.removeFromGroup.bind(this);
    this.addToGroup = this.addToGroup.bind(this);
  }

  componentDidMount() {
    const { deal } = this.props;

    deal.lendersAssigned.map((lender) => {
      this.setState((prevState) => ({
        lendersAssigned: [
          ...prevState.lendersAssigned,
          {
            label: lender.companyName,
            value: lender.companyId,
            team: lender.teamId,
          },
        ],
      }));

      return true;
    });

    getGroups(deal._id);
  }

  removeFromGroup(group, removedLender) {
    const { deal } = this.props;
    const lenders = group.lender.filter(
      (l) => l.companyId !== removedLender.companyId
    );

    editGroup(group._id, {
      dealId: deal._id,
      groupName: group.groupName,
      borrowerTeam: group.borrowerTeam,
      borrowerCompany: group.borrowerCompany,
      borrowerCompanyName: "Deal Borrower",
      lenders,
    });
  }

  addToGroup(addedLenders) {
    const { deal, dispatch } = this.props;
    const group = this.state.groupToAddTo;
    let lenders = [...group.lender];

    addedLenders.lenders.map((lender) => {
      lenders.push({
        companyId: lender.value,
        companyName: lender.label,
        teamId: lender.team,
      });

      return true;
    });

    editGroup(group._id, {
      dealId: deal._id,
      groupName: group.groupName,
      borrowerTeam: group.borrowerTeam,
      borrowerCompany: group.borrowerCompany,
      borrowerCompanyName: "Deal Borrower",
      lenders,
    });

    this.setState({ step: 0, groupToAddTo: {} });
    dispatch(reset("Groups"));
  }

  submitFn(data) {
    const { deal, dispatch } = this.props;
    let lenders = [];

    data.lenders.map((lender) => {
      lenders.push({
        companyId: lender.value,
        companyName: lender.label,
        teamId: lender.team,
      });

      return true;
    });

    createGroup({
      dealId: deal._id,
      groupName: data.groupName,
      borrowerTeam: deal.teamId,
      borrowerCompany: deal.company,
      borrowerCompanyName: "Deal Borrower",
      lenders,
    });

    this.setState({ step: 0 });
    dispatch(reset("Groups"));
  }

  render() {
    const { handleSubmit, whoami, deal, groups } = this.props;

    return (
      <>
        {this.state.step === 0 && (
          // View groups
          <>
            <Row className="mb-3">
              <Col xs="9">
                <p className="pt-1">
                  Groups share access to communications and files.
                </p>
              </Col>
              <Col className="text-right" xs="3">
                <Button
                  className="fake-link"
                  variant="fake-link"
                  onClick={() => {
                    this.setState({
                      step: 1,
                    });
                  }}
                >
                  Add Group
                </Button>
              </Col>
              <Col xs="12">
                <Spacer size={50} />
              </Col>
            </Row>
            <Row>
              {groups && groups.length > 0 ? (
                <>
                  {groups.map((group, idx) => {
                    return (
                      <Col className="mb-3" xs="6" md="4" key={idx}>
                        <div className="group-list">
                          <h4 className="bold">{group.groupName}</h4>
                          {group.lender.length > 0 ? (
                            <>
                              {group.lender.map((l, idx2) => {
                                return (
                                  <div
                                    className="d-flex justify-content-between"
                                    key={idx2}
                                  >
                                    <p>{l.companyName}</p>
                                    <Button
                                      className="fake-link font-12 m-0"
                                      variant="fake-link"
                                      onClick={() => {
                                        this.removeFromGroup(group, l);
                                      }}
                                    >
                                      Remove
                                    </Button>
                                  </div>
                                );
                              })}
                            </>
                          ) : (
                            <p className="d-flex">No members.</p>
                          )}
                          <Button
                            className="fake-link p-0 font-12"
                            variant="fake-link"
                            onClick={() => {
                              this.setState({ step: 2, groupToAddTo: group });
                            }}
                          >
                            Add Lender
                          </Button>
                          <span className="d-block"></span>
                          <Button
                            className="fake-link font-12 m-0"
                            variant="fake-link"
                            onClick={() => {
                              deleteGroup(group._id, {
                                dealId: deal._id,
                                borrowerTeam: deal.teamId,
                                borrowerCompany: deal.company,
                              });

                              getGroups(deal._id);
                            }}
                          >
                            Delete Group
                          </Button>
                        </div>
                      </Col>
                    );
                  })}
                </>
              ) : (
                <p className="font-14">No groups found.</p>
              )}
            </Row>
          </>
        )}
        {this.state.step === 1 && (
          // Create group
          <Form
            className="no-padding no-border"
            onSubmit={handleSubmit(this.submitFn)}
          >
            <Row>
              <Col xs="12">
                <Field
                  className="form-control"
                  component={reduxFormInput}
                  validate={[required]}
                  name="groupName"
                  type="text"
                  placeholder="Enter group name..."
                  label="*Group Name"
                />
                <Spacer size={20} />
                {deal.lendersAssigned && deal.lendersAssigned.length > 0 ? (
                  <Field
                    className="form-control"
                    component={reduxFormSelect}
                    validate={[required]}
                    name="lenders"
                    type="select"
                    options={this.state.lendersAssigned}
                    placeholder={
                      whoami.role === "Admin"
                        ? "Select companies..."
                        : "Select lenders..."
                    }
                    label={
                      whoami.role === "Admin"
                        ? "*Select companies to add"
                        : "*Select lenders to add"
                    }
                    isMulti={true}
                  />
                ) : (
                  <p>There are no lenders assigned to this deal.</p>
                )}
                <Spacer size={20} />
              </Col>
              <Col xs="6">
                <Button
                  variant="dark"
                  onClick={() => {
                    this.setState({ step: 0 });
                  }}
                >
                  Back
                </Button>
              </Col>
              <Col className="text-right" xs="6">
                <Button type="submit">Submit</Button>
              </Col>
            </Row>
          </Form>
        )}
        {this.state.step === 2 && (
          // Add to group
          <Form
            className="no-padding no-border"
            onSubmit={handleSubmit(this.addToGroup)}
          >
            <Row>
              <Col xs="12">
                {deal.lendersAssigned && deal.lendersAssigned.length > 0 ? (
                  <Field
                    className="form-control"
                    component={reduxFormSelect}
                    validate={[required]}
                    name="lenders"
                    type="select"
                    options={this.state.lendersAssigned.filter(
                      ({ value: a }) =>
                        !this.state.groupToAddTo.lender.some(
                          ({ companyId: b }) => b === a
                        )
                    )}
                    placeholder={
                      whoami.role === "Admin"
                        ? "Select companies..."
                        : "Select lenders..."
                    }
                    label={
                      whoami.role === "Admin"
                        ? "*Select companies to add"
                        : "*Select lenders to add"
                    }
                    isMulti={true}
                  />
                ) : (
                  <p>There are no lenders assigned to this deal.</p>
                )}
                <Spacer size={20} />
              </Col>
              <Col xs="6">
                <Button
                  variant="dark"
                  onClick={() => {
                    this.setState({ step: 0, groupToAddTo: {} });
                  }}
                >
                  Back
                </Button>
              </Col>
              <Col className="text-right" xs="6">
                <Button type="submit">Submit</Button>
              </Col>
            </Row>
          </Form>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    action: state.action,
    whoami: state.auth.whoami,
    deal: state.deal.deal,
    company: state.company.company,
    groups: state.comm.groups,
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "Groups",
    enableReinitialize: true,
  })(Groups)
);
