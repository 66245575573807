import React, { Fragment } from "react";
import { connect } from "react-redux";
import { OverlayTrigger, Tooltip, Row, Col } from "react-bootstrap";
import { getCompanyMembers } from "redux/actions";
import { reset } from "redux-form";
import Spacer from "components/layout/Spacer";
import PopupPrompt from "components/ui/PopupPrompt";
import ManagerCreate from "containers/company/ManagerCreate";
import { isEmpty } from "lodash";
import SocialMediaButton from "components/ui/SocialMediaButton";

class CompanyUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPrompt: false,
    };
  }

  handleShowPrompt = (showPrompt) => {
    this.setState({ showPrompt });
  };
  componentDidMount() {
    const { company } = this.props;
    getCompanyMembers(company._id);
  }
  render() {
    const { company, isCompanyAdmin, dispatch, members } = this.props;

    const { managers } = company;
    return (
      <>
        <PopupPrompt
          promptTitle={"Add Manager"}
          promptBody={
            <ManagerCreate
              isCompanyAdmin={isCompanyAdmin}
              companyId={company._id}
              managers={managers}
              onSubmit={() => {
                this.handleShowPrompt(false);
                dispatch(reset("AddManager"));
              }}
            />
          }
          show={this.state.showPrompt}
          onCancel={() => {
            this.handleShowPrompt(false);
            dispatch(reset("AddManager"));
          }}
          onHide={() => {
            this.handleShowPrompt(false);
            dispatch(reset("AddManager"));
          }}
        />
        <Row>
          <Col xs="12">
            <Spacer size={30} />
          </Col>
          <Col className="align-self-end" xs="6">
            <h3 className="bold">Users</h3>
          </Col>
          {/* <Col className="text-right" xs="6">
            <OverlayTrigger
              placement="left"
              overlay={<Tooltip id={`tooltip-left`}>Add Users</Tooltip>}
            >
              <Button
                className="mr-2 pl-3 pt-2 pr-3 pb-2"
                onClick={() => {
                  this.handleShowPrompt(true);
                }}
              >
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            </OverlayTrigger>
          </Col> */}
          <Col xs="12">
            <div className="border-top"></div>
            <Spacer size={30} />
          </Col>

          <Col xs="12">
            <Spacer size={20} />
          </Col>
        </Row>
        {members && members.length === 0 ? (
          <Row>
            <Col xs="12">
              <p className="text-danger">
                Your company doesn't have any users yet!
              </p>
              <Spacer size={10} />
            </Col>
          </Row>
        ) : (
          members.map((member, idx) => {
            const { linkedin = "", teams = [] } = member;
            return (
              <Fragment key={member._id}>
                <Row className="company-users-list">
                  <Col xs="3" sm="3" style={{ width: "25%" }}>
                    <div>
                      <span className="label-text">Contact</span>
                      <div className="font-14">
                        <div>
                          {" "}
                          <span> {member.firstName}</span>{" "}
                          <span>{member.lastName}</span>
                        </div>
                        <div>{member.email}</div>
                        <div>{member.title}</div>
                        <div>
                          {!member.phoneMobile || isEmpty(member.phoneMobile)
                            ? ""
                            : `${member.phoneMobile} (mobile)`}
                        </div>
                        <div>
                          {!member.phoneLandline || member.phoneLandline === " "
                            ? ""
                            : `${member.phoneLandline} (office)`}
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xs="3" sm="3" style={{ width: "25%" }}>
                    <div>
                      <span className="label-text">Address</span>
                      <div className="font-14">
                        <div>{member.address}</div>
                        <div>{member.address2}</div>
                        <div>
                          {member.city && member.state
                            ? `${member.city}, ${member.state}, ${member.country}`
                            : ""}
                        </div>
                        <div>{member.zipcode}</div>
                      </div>
                    </div>
                  </Col>
                  <Col xs="3" sm="3" style={{ width: "25%" }}>
                    <div>
                      <span className="label-text">Team's</span>
                      <div className="font-14">
                        {teams.map((teamName) => {
                          return <div key={teamName}>{teamName}</div>;
                        })}
                      </div>
                    </div>
                  </Col>
                  <Col xs="3" sm="3" style={{ width: "25%" }}>
                    {!isEmpty(linkedin?.trim()) && (
                      <OverlayTrigger
                        placement="bottom"
                        overlay={
                          <Tooltip id={`tooltip-bottom`}>LinkedIn</Tooltip>
                        }
                      >
                        <div className="mr-2 d-inline-block">
                          <SocialMediaButton icon="linkedin" url={linkedin} />
                        </div>
                      </OverlayTrigger>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col xs="12">
                    <div className="border-top"></div>
                    <Spacer size={30} />
                  </Col>
                </Row>
              </Fragment>
            );
          })
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    company: state.company.company,
    members: state.company.members,
  };
}

export default connect(mapStateToProps)(CompanyUsers);
