import React from "react";
import { getPrimaryClauses } from "containers/quotes/quotesConfig";
import { renderToString } from "react-dom/server";
import NumberFormat from "react-number-format";
import * as XLSX from "xlsx";
import { getIndexAsOfLabel, getSpreadTextValue } from "utils/commonUtils";
import {
  extractTextFromHtmlString,
  replaceMergeFields,
} from "utils/termsheetUtils";
import { getAllFields, getSortedAllFields } from "./CompareQuotes/utils";

const primaryClauses = getPrimaryClauses();
const primaryFields = [
  {
    label: "Lender",
  },
  ...primaryClauses,
];

const downloadXLSX = (
  quotes = [],
  addedFields = [],
  parentClauses = [],
  { fileName = "Quotes", deal, whoami, borrowerCompany }
) => {
  const data = [];
  const allFields = getAllFields(primaryFields, addedFields);
  const sortedAllFields = getSortedAllFields(allFields, parentClauses);

  sortedAllFields.forEach(({ label, clause }) => {
    const item = [label];
    quotes.forEach((quote) => {
      let listItemElement = "-";
      switch (label) {
        case "Lender":
          listItemElement = quote.companyId.companyName;
          break;
        case "Loan Amount":
          listItemElement = (
            <NumberFormat
              value={quote.loanAmount}
              displayType={"text"}
              thousandSeparator
              prefix="$"
            />
          );
          break;
        case "Term":
          listItemElement = (
            <NumberFormat
              value={quote.term}
              displayType={"text"}
              thousandSeparator
              suffix={` ${quote.termMetric}`}
            />
          );
          break;
        case "Amortization":
          listItemElement = (
            <NumberFormat
              value={quote.amortization}
              displayType={"text"}
              thousandSeparator
              suffix={` ${quote.amortizationMetric}`}
            />
          );
          break;
        case "IO Period":
          listItemElement = (
            <NumberFormat
              value={quote.IOPeriod}
              displayType={"text"}
              suffix={` ${quote.IOPeriodMetric}`}
              thousandSeparator
            />
          );
          break;
        case "Rate Calculation":
          listItemElement = quote.isRateOrSpread;
          break;
        case "Spread":
          item[0] = getIndexAsOfLabel();
          listItemElement = getSpreadTextValue(quote);
          break;
        case "Floor":
          listItemElement = quote.floorRate ? (
            <NumberFormat
              value={quote.floorRate}
              displayType={"text"}
              thousandSeparator
              suffix={`% ${quote.floorOption}`}
            />
          ) : (
            quote.floorOption
          );
          break;
        case "Index":
          listItemElement = (
            <NumberFormat
              value={quote.indexRate}
              displayType={"text"}
              thousandSeparator
              suffix={`%`}
            />
          );
          break;
        case "Index Term":
          listItemElement = (
            <NumberFormat value={quote.indexTerm1} displayType={"text"} />
          );
          break;
        case "Quoted Rate":
          listItemElement = (
            <NumberFormat
              value={quote.quotedRate}
              displayType={"text"}
              thousandSeparator
              suffix={`%`}
            />
          );
          break;
        default: {
          listItemElement = quote[label] || listItemElement;
          break;
        }
      }

      if (!clause?._id) {
        clause = quote.termsheet?.columns?.find((col) => {
          const name = col.clause?.displayName || col.clause?.clauseName;
          return name === label;
        })?.clause;
      }

      if (clause?._id) {
        let addedToMatrix = quote.addedClauses?.find(
          (e) =>
            e.clauseId === clause?._id &&
            quote.termsheet?.addedClauses?.find(
              (ac) => ac.clauseId === e.clauseId
            )
        );

        let found = addedToMatrix ?? {};
        let addedToColumn = addedToMatrix
          ? null
          : quote.termsheet?.columns?.find(
              (col) => col.clause?._id === clause?._id
            );

        if (addedToColumn) {
          found.value = addedToColumn.sectionValue;

          found.sectionYearsMonths = addedToColumn.sectionYearsMonths;

          found.langDesc = replaceMergeFields(
            addedToColumn.langDesc,
            deal,
            whoami,
            borrowerCompany
          );

          if (["$#,###"].includes(addedToColumn.clause?.format)) {
            found.type = "currency";
          } else if (
            ["#,###", "#.##x"].includes(addedToColumn.clause?.format)
          ) {
            found.type = "number";
          } else if (
            ["#%", "#.#%", "#.##%"].includes(addedToColumn.clause?.format)
          ) {
            found.type = "percent";
          }
        }

        if (found.value) {
          switch (found.type) {
            case "currency":
              listItemElement = (
                <NumberFormat
                  value={found.value}
                  displayType={"text"}
                  thousandSeparator
                  prefix="$"
                />
              );
              break;
            case "number":
              listItemElement = (
                <NumberFormat
                  value={found.value}
                  displayType={"text"}
                  thousandSeparator
                  decimalScale={found.format === "#,###" ? 0 : 10}
                  suffix={` ${found.sectionYearsMonths}`}
                />
              );
              break;
            case "percent":
              listItemElement = (
                <NumberFormat
                  value={found.value}
                  displayType={"text"}
                  thousandSeparator
                  suffix={`%`}
                />
              );
              break;
            default:
              listItemElement = found.value;
              break;
          }
        }

        if (found.langDesc) {
          listItemElement = (
            <>
              {listItemElement}
              {` \n*${extractTextFromHtmlString(found.langDesc)}`}
            </>
          );
        }
      }

      item.push(extractTextFromHtmlString(renderToString(listItemElement)));
    });

    data.push(item);
  });

  const worksheet = XLSX.utils.aoa_to_sheet(data);
  worksheet["!cols"] = [...quotes, quotes[0]].map(() => ({ wch: 45 }));

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  XLSX.writeFile(workbook, fileName + ".xlsx");
};

const DownloadQuotes = {
  XLSX: downloadXLSX,
};

export default DownloadQuotes;
