import React from "react";
import { connect } from "react-redux";
import { Button, Form, Container, Row, Col } from "react-bootstrap";
import { Field, formValueSelector, reduxForm } from "redux-form";
import { required, noSpaces } from "utils/validation";
import {
  reduxFormInput,
  reduxFormSelect,
  reduxFormTextarea,
} from "components/form/ReduxForm";
import {
  createClause,
  getCategories,
  getClauses,
  updateClause,
} from "redux/actions";
import Spacer from "components/layout/Spacer";
import { addOnOptions, formatOptions, formatSampleMapping } from "./lookup";

class CreateEditParentClause extends React.Component {
  constructor(props) {
    super(props);

    this.submitFn = this.submitFn.bind(this);
    this.state = {
      categoryOptions: [],
      clauseOptions: [],
      addOnOptions,
      formatOptions,
    };
  }

  get isCurrency() {
    return this.props.selectedFormat?.value?.includes("#");
  }

  componentDidMount() {
    getCategories({ order: "ASC", orderColumn: "categoryName", limit: 100 });
    this.handleGetClauses();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.categories !== this.props.categories) {
      const categoryOptions = this.props.categories.map((item) => ({
        value: item._id,
        label: item.categoryName,
      }));
      this.setState({ categoryOptions });
    }
    if (
      prevProps.selectedFormat !== this.props.selectedFormat &&
      this.props.selectedFormat?.value
    ) {
      const newFormatSample =
        formatSampleMapping[this.props.selectedFormat?.value] ||
        this.props.selectedFormat?.value;
      this.props.change("formatSample", newFormatSample);
    }
    if (prevProps.clauses !== this.props.clauses) {
      const clauseOptions = this.props.clauses.map((item) => ({
        value: item._id,
        label: item.clauseName,
      }));
      this.setState({ clauseOptions });
    }
  }

  submitFn(data) {
    const {
      promptType,
      selectedClause,
      whoami,
      orderOptions,
      onReorder,
      onMergeClauses,
    } = this.props;

    if (promptType === "create") {
      const mergeClauseIds = data.clauses?.map((e) => e.value);
      createClause({
        clauseName: data.clauseName,
        category: data.category.value,
        addOn: data.addOn.value,
        format: data.format.value,
        status: whoami.role === "Admin" ? "ACTIVE" : "UNDER_REVIEW",
      });
      onMergeClauses(mergeClauseIds, data.clauseName);
    } else if (promptType === "edit") {
      updateClause(selectedClause._id, {
        clauseName: data.clauseName,
        category: data.category.value,
        addOn: data.addOn.value,
        format: data.format.value,
      });
    } else if (promptType === "change_category") {
      updateClause(selectedClause._id, { category: data.category.value });
    } else if (promptType === "change_order") {
      const fromIndex = orderOptions.findIndex(
        (e) => e._id === selectedClause._id
      );
      const toIndex = data.newOrder.value - 1;
      onReorder({ fromIndex, toIndex });
    }
  }

  handleContinue = () => {
    this.props.onContinue();
  };

  handleGetClauses = () => {
    getClauses({
      limit: 500,
      order: "ASC",
      orderColumn: "clauseName",
      parent: true,
    });
  };

  showConfirmation = () => {
    return !this.props.willContinue && this.props.promptType === "create";
  };

  render() {
    const { action, promptType, onCancel, handleSubmit } = this.props;
    const isProcessing =
      action.CREATE_CLAUSE.isFetching || action.UPDATE_CLAUSE.isFetching;

    return (
      <>
        <Container>
          <Form
            className="no-border no-padding"
            onSubmit={handleSubmit(this.submitFn)}
          >
            {this.showConfirmation() && (
              <Row>
                <Col xs="12">
                  <p>
                    We use the Term Street Parent Clause to ensure term sheets
                    can be compared between various lenders. We attempt to have
                    a parent clause for any term sheet provision that you would
                    like to add to a term sheet.
                  </p>
                  <Spacer size={10} />
                  <p>
                    At times, you may be creating a provision in a term sheet
                    that we have not planned for and you may need to request a
                    new Parent Clause.
                  </p>
                  <Spacer size={20} />
                  <p>
                    <i>
                      Before requesting a new Parent Clause, please search and
                      make sure that there is not a suitable Parent available
                      for the provision you’d like to create.
                    </i>
                  </p>
                </Col>
              </Row>
            )}

            {!this.showConfirmation() && (
              <>
                {(promptType === "create" ||
                  promptType === "edit" ||
                  promptType === "change_category") && (
                  <Row>
                    {(promptType === "create" || promptType === "edit") && (
                      <Col xs="6">
                        <Field
                          className="form-control"
                          component={reduxFormInput}
                          validate={[required, noSpaces]}
                          name="clauseName"
                          type="text"
                          placeholder="Enter New Parent Clause"
                          label="*Term Street Parent Clause"
                        />
                      </Col>
                    )}

                    {(promptType === "create" ||
                      promptType === "edit" ||
                      promptType === "change_category") && (
                      <Col xs="6">
                        <Field
                          className="form-control"
                          component={reduxFormSelect}
                          validate={[required]}
                          name="category"
                          type="select"
                          options={this.state.categoryOptions}
                          placeholder="Choose Clause Category"
                          label="*Clause Category"
                        />
                      </Col>
                    )}
                  </Row>
                )}

                <Spacer size={10} />

                {promptType === "change_order" && (
                  <Row>
                    <Col xs="6">
                      <Field
                        className="form-control"
                        component={reduxFormSelect}
                        validate={[required]}
                        name="newOrder"
                        type="select"
                        options={this.props.orderOptions}
                        placeholder="Choose New Order"
                        label="*New Order"
                      />
                    </Col>
                  </Row>
                )}

                <Spacer size={10} />

                {(promptType === "create" || promptType === "edit") && (
                  <>
                    <Row>
                      <Col xs="12">
                        <h4>Formatting</h4>
                      </Col>
                    </Row>
                    <Spacer size={5} />
                    <Row>
                      <Col xs="6">
                        <Field
                          className="form-control"
                          component={reduxFormSelect}
                          validate={[required]}
                          name="format"
                          type="select"
                          options={this.state.formatOptions}
                          label="*Format"
                        />
                      </Col>
                      <Col xs="6">
                        <Field
                          className="form-control"
                          component={reduxFormSelect}
                          validate={[required]}
                          name="addOn"
                          type="select"
                          options={this.state.addOnOptions}
                          label="*Add-On"
                        />
                      </Col>
                    </Row>
                  </>
                )}

                {promptType === "create" && (
                  <>
                    <Spacer size={10} />
                    <Row>
                      <Col xs="6">
                        <Field
                          isMulti
                          showLoading
                          closeMenuOnSelect={false}
                          className="form-control"
                          component={reduxFormSelect}
                          name="clauses"
                          type="select"
                          isLoading={action.GET_CLAUSES.isFetching}
                          options={this.state.clauseOptions}
                          placeholder="Select at least 1 clause..."
                          label="Merge Clauses"
                          onFocus={this.handleGetClauses}
                        />
                      </Col>
                    </Row>
                  </>
                )}

                <Spacer size={10} />

                {(promptType === "create" || promptType === "edit") && (
                  <>
                    <Row>
                      <Col xs="12">
                        <h4>Sample</h4>
                      </Col>
                    </Row>
                    <Spacer size={5} />
                    <Row>
                      {this.props.selectedFormat?.value ===
                      "Open Text, left aligned, no header" ? (
                        <Col xs="12">
                          <Field
                            disabled
                            className="form-control"
                            component={reduxFormTextarea}
                            validate={[]}
                            name="formatSample"
                            type="textarea"
                            rows="6"
                          />
                        </Col>
                      ) : (
                        <>
                          <Col xs="3">
                            <p>[Display Name]</p>
                          </Col>
                          <Col xs="9">
                            <Field
                              disabled
                              className="form-control"
                              component={
                                this.isCurrency
                                  ? reduxFormInput
                                  : reduxFormTextarea
                              }
                              validate={[]}
                              name="formatSample"
                              type="textarea"
                              rows="6"
                            />
                          </Col>
                        </>
                      )}
                    </Row>
                    {this.isCurrency && (
                      <Row>
                        <Col xs="3"></Col>
                        <Col xs="9">
                          <Field
                            disabled
                            className="form-control"
                            component={reduxFormTextarea}
                            validate={[]}
                            name="numberFormatText"
                            type="textarea"
                            rows="4"
                          />
                        </Col>
                      </Row>
                    )}
                  </>
                )}
              </>
            )}

            <Spacer size={50} />

            {this.showConfirmation() && (
              <Row className="justify-content-center">
                <Col className="text-center" xs="12">
                  <Button
                    disabled={isProcessing}
                    variant="primary"
                    className="px-3 mr-2"
                    onClick={onCancel}
                  >
                    {isProcessing
                      ? "Saving..."
                      : "Go Back to Search available Parent Clauses"}
                  </Button>
                  <Button
                    disabled={isProcessing}
                    variant="tertiary"
                    className="px-3"
                    onClick={this.handleContinue}
                  >
                    {isProcessing
                      ? "Saving..."
                      : "Continue to Request New Clause"}
                  </Button>
                </Col>
              </Row>
            )}

            {!this.showConfirmation() && (
              <Row className="justify-content-center">
                <Col className="text-center" xs="12" sm="6">
                  <Button
                    disabled={isProcessing}
                    variant="tertiary"
                    className="mr-3"
                    onClick={onCancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={isProcessing}
                    type="submit"
                    variant="primary"
                  >
                    {isProcessing ? "Saving..." : "Save"}
                  </Button>
                </Col>
              </Row>
            )}
          </Form>
          <Spacer size={30} />
        </Container>
      </>
    );
  }
}

const selector = formValueSelector("CreateEditParentClause");

function mapStateToProps(state, props) {
  return {
    action: state.action,
    whoami: state.auth.whoami,
    categories: state.category.categories,
    clauses: state.clause.clauses,
    initialValues: {
      clauseName: props.selectedClause?.clauseName || "",
      category: props.selectedClause?.category
        ? {
            label: props.selectedClause?.category?.categoryName,
            value: props.selectedClause?.category?._id,
          }
        : null,
      format: props.selectedClause?.format
        ? formatOptions.find((e) => e.value === props.selectedClause?.format)
        : formatOptions[0],
      addOn: props.selectedClause?.addOn
        ? addOnOptions.find((e) => e.value === props.selectedClause?.addOn)
        : addOnOptions[0],
      newOrder:
        props.orderOptions?.find((e) => e._id === props.selectedClause?._id) ||
        props.orderOptions?.[0] ||
        null,
      numberFormatText: `This is open text added to any other type of formatted clause.  This is meant to display a formatted number with paragraphical text added as additional color.  There is no limit to the text that you may write in this box.`,
    },
    selectedFormat: selector(state, "format"),
    selectedFormatSample: selector(state, "formatSample"),
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "CreateEditParentClause",
    enableReinitialize: true,
  })(CreateEditParentClause)
);
