import Spacer from "components/layout/Spacer";
import React, { useEffect, useMemo } from "react";
import { Button, Col, Row } from "react-bootstrap";
import queryString from "query-string";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { createTermsheet, updateTermsheet } from "redux/actions";
import PopupPrompt from "components/ui/PopupPrompt";
import CreateTermSheetConfirmation from "./CreateTermSheetConfirmation";
import CreateConfirmation from "./CreateConfirmation";
import TermsheetCreateFromTemplate from "containers/termsheet/TermsheetCreateFromTemplate";
import SelectedClauses from "containers/section/SelectedClauses";
import TermsheetPreview from "../termsheet/TermsheetPreview";
import { noop } from "lodash";
import { Droppable } from "react-beautiful-dnd";
import { Modal, Button as AntdButton } from "antd";

export const ItemTypes = {
  BOX: "box",
};

const BorrowerView = (props) => {
  const {
    quotes,
    action,
    saveAction,
    columns,
    whoami,
    myCompany,
    match,
    saveQuotes,
  } = props;

  const history = useHistory();
  const search = queryString.parse(history.location?.search);
  const fromScratch = Boolean(search?.fromScratch);

  const [showPreview, setShowPreview] = React.useState(false);
  const [showTermsheetConfirmModal, setShowTermsheetConfirmModal] =
    React.useState(false);
  const [showSaveConfirmModal, setShowSaveConfirmModal] = React.useState("");
  const [showTemplateModal, setShowTemplateModal] = React.useState(false);
  const isMobile = window.isMobile;

  useEffect(() => {
    if (isMobile) {
      setShowPreview(true);
    }
  });
  const initialName = useMemo(() => {
    const date = `${new Date().toDateString()} ${new Date().toLocaleTimeString()}`;
    return match?.params?.dealId
      ? `Termsheet - [Deal Name] - ${date}`
      : `Termsheet - ${date}`;
  }, [showSaveConfirmModal]);

  const updatedCoumns = useMemo(
    () =>
      columns.map(({ clause, ...col }) => ({
        ...col,
        clauseId: clause?._id,
      })),
    [columns]
  );

  const handleSubmitCreateFromTemplate = (_, termsheetTemplate) => {
    updateTermsheet(match.params.termsheetId, {
      columns: termsheetTemplate.columns,
      isDefault: true,
    });
    setShowTemplateModal(false);
  };

  const handleSubmit = ({ name, isDraft }) => {
    const isDefault = showSaveConfirmModal === "default";
    const isTemplate = match?.params?.dealId ? false : true;

    if (saveAction === "create") {
      createTermsheet({
        termsheetName: name,
        columns: updatedCoumns,
        companyID: myCompany._id,
        status: isDraft ? "DRAFT" : "ACTIVE",
        isTemplate,
        isDefault,
        adminAvailability: isTemplate
          ? {
              key: "MY_TEAM",
              values: [],
            }
          : undefined,
        doNotRedirect: true,
      });
    } else {
      updateTermsheet(match.params.termsheetId, {
        termsheetName: name,
        columns: updatedCoumns,
        status: isDraft ? "DRAFT" : "ACTIVE",
        isDefault,
        doNotRedirect: true,
      });
    }

    setShowSaveConfirmModal(false);
    setShowTermsheetConfirmModal(false);
    showConfirmBackToDashboardModal();
  };

  const showConfirmBackToDashboardModal = () => {
    const modal = Modal.confirm({
      title: "Confirm",
      footer: (_, { CancelBtn }) => (
        <>
          <CancelBtn />
          <AntdButton type="primary" onClick={backToDashboard}>
            Back to Dashboard
          </AntdButton>
        </>
      ),
    });
    const backToDashboard = () => {
      modal.destroy();
      history.push("/dashboard");
    };
  };

  const handleSaveTemplate = () => {
    updateTermsheet(match.params.termsheetId, {
      columns: updatedCoumns,
      isTemplate: true,
    });
  };

  const handleSaveAddedClauses = (addedClauses) => {
    updateTermsheet(match.params.termsheetId, {
      addedClauses,
      doNotRedirect: true,
    });
  };

  return (
    <Col xs={12}>
      {match.params.termsheetId && (
        <Row>
          <Col className="text-left p-0">
            <Spacer size={20} />
            <p
              className="left-align color-purple-2 cursor-pointer"
              onClick={isMobile ? noop : () => setShowPreview(!showPreview)}
            >
              Toggle View: {showPreview ? "Editor View" : "Borrower View"}
            </p>
          </Col>
        </Row>
      )}

      {match.params.termsheetId && (
        <Row style={{ display: showPreview ? "block" : "none" }}>
          <TermsheetPreview key={quotes} match={match} columns={columns} />
        </Row>
      )}

      {columns.length ? (
        <Row style={{ display: showPreview ? "none" : "block" }}>
          <Col xs={12}>
            <Spacer size={20} />
            <SelectedClauses
              match={match}
              saveQuotes={saveQuotes}
              onSaveAddedClauses={handleSaveAddedClauses}
            />
          </Col>
        </Row>
      ) : (
        <Droppable droppableId="droppableId-clauses" type="CLAUSES">
          {(provided) => (
            <Row
              className="droppable-container justify-content-center"
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={{
                display: showPreview ? "none" : "block",
              }}
            >
              <Col xs={12} className="droppable-content">
                <Spacer size={20} />
                <p className="text-center bold mb-2">
                  Here is where your Term Sheet will appear as you add sections,
                  clauses, or load a template.
                </p>
                <p className="text-center bold mb-2">
                  Add Clauses from the Matrix above or Drag and Drop from the
                  left hand panel.
                </p>
                <p className="text-center bold mb-3">
                  If you’d prefer to load a template,
                </p>
                <div className="text-center">
                  <Button
                    type="submit"
                    variant="link"
                    className="bold color-purple-2"
                    onClick={() => setShowTemplateModal(true)}
                  >
                    Click here to load a template
                  </Button>
                </div>
              </Col>
            </Row>
          )}
        </Droppable>
      )}

      <Row>
        <Col xs={12}>
          <Spacer size={60} />
        </Col>
      </Row>

      {!match?.params?.dealId && (
        <Row>
          <Col xs={12} className="d-flex justify-content-end">
            <div>
              <Link to={`/termsheet-clause/termsheet/choice`}>
                <Button variant="primary mr-3" to="/">
                  Exit
                </Button>
              </Link>

              <Button
                variant="primary mr-3"
                onClick={() => {
                  setShowSaveConfirmModal("save");
                }}
              >
                Save
              </Button>

              <Button
                variant="primary mr-3"
                onClick={() => {
                  setShowSaveConfirmModal("draft");
                }}
              >
                Save Draft
              </Button>
            </div>
          </Col>
        </Row>
      )}

      {match?.params?.dealId && !isMobile && (
        <Row>
          <Col
            xs={12}
            className={`d-flex justify-content-${
              fromScratch ? "end" : "between"
            }`}
          >
            {!fromScratch && (
              <div>
                <Button
                  variant="primary mr-3"
                  disabled={action.UPDATE_TERMSHEET.isFetching}
                  onClick={handleSaveTemplate}
                >
                  Save Template
                </Button>
              </div>
            )}

            <div>
              <Link to={`/deal/${match?.params?.dealId}/quote-summary`}>
                <Button variant="primary mr-3" to="/">
                  Exit
                </Button>
              </Link>
              {!fromScratch && (
                <Button
                  variant="primary mr-3"
                  onClick={() => {
                    setShowSaveConfirmModal("draft");
                  }}
                >
                  Save Draft
                </Button>
              )}

              {whoami.role === "Admin" ? (
                <Button
                  type="submit"
                  variant="tertiary"
                  onClick={() => {
                    setShowSaveConfirmModal("default");
                  }}
                >
                  Set Default
                </Button>
              ) : (
                <Button
                  type="submit"
                  variant="tertiary"
                  onClick={() => {
                    setShowTermsheetConfirmModal(true);
                  }}
                >
                  Submit Term Sheet
                </Button>
              )}
            </div>
          </Col>
        </Row>
      )}

      <Row>
        <Col xs={12}>
          <Spacer size={60} />
        </Col>
      </Row>

      <PopupPrompt
        promptTitle="Submit Term Sheet Confirmation"
        promptBody={
          <CreateTermSheetConfirmation
            data={props.termsheet}
            onCancel={() => {
              setShowTermsheetConfirmModal(false);
            }}
            onSubmit={handleSubmit}
          />
        }
        show={showTermsheetConfirmModal}
        onCancel={() => {
          setShowTermsheetConfirmModal(false);
        }}
        onHide={() => {
          setShowTermsheetConfirmModal(false);
        }}
      />
      <PopupPrompt
        promptTitle={
          showSaveConfirmModal === "draft"
            ? "Save Draft Term Sheet"
            : showSaveConfirmModal === "default"
            ? "Save Default Term Sheet"
            : "Save Term Sheet"
        }
        promptBody={
          <CreateConfirmation
            data={props.termsheet}
            dealId={match?.params?.dealId}
            isDraft={showSaveConfirmModal === "draft"}
            initialName={initialName}
            onCancel={() => {
              setShowSaveConfirmModal(false);
            }}
            onSubmit={handleSubmit}
          />
        }
        show={!!showSaveConfirmModal}
        onCancel={() => {
          setShowSaveConfirmModal(false);
        }}
        onHide={() => {
          setShowSaveConfirmModal(false);
        }}
      />
      <PopupPrompt
        promptTitle="Choose a TermStreet Template"
        promptBody={
          <TermsheetCreateFromTemplate
            onCancel={() => setShowTemplateModal(false)}
            onSubmit={handleSubmitCreateFromTemplate}
          />
        }
        show={showTemplateModal}
        onCancel={() => setShowTemplateModal(false)}
        onHide={() => setShowTemplateModal(false)}
      />
    </Col>
  );
};

const mapStateToProps = (state) => ({
  action: state.action,
  whoami: state.auth.whoami,
  myCompany: state.company.myCompany,
  quotes: state.termsheet.quotes || [],
  columns: state.termsheet.termsheet.columns || [],
  termsheet: state.termsheet.termsheet,
  lastTermsheetUpdated: state.termsheet.lastTermsheetUpdated,
});
export default connect(mapStateToProps)(BorrowerView);
