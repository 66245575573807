const states = [
  {
    label: "Alabama",
    value: "AL",
  },
  {
    label: "Alaska",
    value: "AK",
  },
  {
    label: "Arizona ",
    value: "AZ",
  },
  {
    label: "Arkansas",
    value: "AR",
  },
  {
    label: "California ",
    value: "CA",
  },
  {
    label: "Colorado ",
    value: "CO",
  },
  {
    label: "Connecticut",
    value: "CT",
  },
  {
    label: "Delaware",
    value: "DE",
  },
  {
    label: "Florida",
    value: "FL",
  },
  {
    label: "Georgia",
    value: "GA",
  },
  {
    label: "Hawaii",
    value: "HI",
  },
  {
    label: "Idaho",
    value: "ID",
  },
  {
    label: "Illinois",
    value: "IL",
  },
  {
    label: "Indiana",
    value: "IN",
  },
  {
    label: "Iowa",
    value: "IA",
  },
  {
    label: "Kansas",
    value: "KS",
  },
  {
    label: "Kentucky",
    value: "KY",
  },
  {
    label: "Louisiana",
    value: "LA",
  },
  {
    label: "Maine",
    value: "ME",
  },
  {
    label: "Maryland",
    value: "MD",
  },
  {
    label: "Massachusetts",
    value: "MA",
  },
  {
    label: "Michigan",
    value: "MI",
  },
  {
    label: "Minnesota",
    value: "MN",
  },
  {
    label: "Mississippi",
    value: "MS",
  },
  {
    label: "Missouri",
    value: "MO",
  },
  {
    label: "Montana",
    value: "MT",
  },
  {
    label: "Nebraska",
    value: "NE",
  },
  {
    label: "Nevada",
    value: "NV",
  },
  {
    label: "New Hampshire",
    value: "NH",
  },
  {
    label: "New Jersey",
    value: "NJ",
  },
  {
    label: "New Mexico",
    value: "NM",
  },
  {
    label: "New York",
    value: "NY",
  },
  {
    label: "North Carolina",
    value: "NC",
  },
  {
    label: "North Dakota",
    value: "ND",
  },
  {
    label: "Ohio",
    value: "OH",
  },
  {
    label: "Oklahoma",
    value: "OK",
  },
  {
    label: "Oregon",
    value: "OR",
  },
  {
    label: "Pennsylvania",
    value: "PA",
  },
  {
    label: "Rhode Island",
    value: "RI",
  },
  {
    label: "South Carolina",
    value: "SC",
  },
  {
    label: "South Dakota",
    value: "SD",
  },
  {
    label: "Tennessee",
    value: "TN",
  },
  {
    label: "Texas",
    value: "TX",
  },
  {
    label: "Utah",
    value: "UT",
  },
  {
    label: "Vermont",
    value: "VT",
  },
  {
    label: "Virginia ",
    value: "VA",
  },
  {
    label: "Washington",
    value: "WA",
  },
  {
    label: "West Virginia",
    value: "WV",
  },
  {
    label: "Wisconsin",
    value: "WI",
  },
  {
    label: "Wyoming",
    value: "WY",
  },
];

const MSAs = [
  { label: "Akron, OH", value: "Akron, OH" },
  { label: "Albany, NY", value: "Albany, NY" },
  { label: "Albuquerque, NM", value: "Albuquerque, NM" },
  { label: "Allentown, PA", value: "Allentown, PA" },
  { label: "Anchorage, AK", value: "Anchorage, AK" },
  { label: "Ann Arbor, MI", value: "Ann Arbor, MI" },
  { label: "Asheville, NC", value: "Asheville, NC" },
  { label: "Atlanta, GA", value: "Atlanta, GA" },
  { label: "Augusta, GA", value: "Augusta, GA" },
  { label: "Austin, TX", value: "Austin, TX" },
  { label: "Bakersfield, CA", value: "Bakersfield, CA" },
  { label: "Baltimore, MD", value: "Baltimore, MD" },
  { label: "Baton Rouge, LA", value: "Baton Rouge, LA" },
  { label: "Beaumont, TX", value: "Beaumont, TX" },
  { label: "Birmingham, AL", value: "Birmingham, AL" },
  { label: "Boise, ID", value: "Boise, ID" },
  { label: "Boston, MA", value: "Boston, MA" },
  { label: "Boston, MA", value: "Boston, MA" },
  { label: "Boulder, CO", value: "Boulder, CO" },
  { label: "Brownsville, TX", value: "Brownsville, TX" },
  { label: "Buffalo, NY", value: "Buffalo, NY" },
  { label: "Cape Coral, FL", value: "Cape Coral, FL" },
  { label: "Charleston, SC", value: "Charleston, SC" },
  { label: "Charlotte, NC", value: "Charlotte, NC" },
  { label: "Chattanooga, TN", value: "Chattanooga, TN" },
  { label: "Chicago, IL", value: "Chicago, IL" },
  { label: "Cincinnati, OH", value: "Cincinnati, OH" },
  { label: "Cleveland, OH", value: "Cleveland, OH" },
  { label: "Colorado Springs, CO", value: "Colorado Springs, CO" },
  { label: "Columbia, SC", value: "Columbia, SC" },
  { label: "Columbus, GA", value: "Columbus, GA" },
  { label: "Columbus, OH", value: "Columbus, OH" },
  { label: "Corpus Christi, TX", value: "Corpus Christi, TX" },
  { label: "Dallas, TX", value: "Dallas, TX" },
  { label: "Davenport, IA", value: "Davenport, IA" },
  { label: "Dayton, OH", value: "Dayton, OH" },
  { label: "Denver, CO", value: "Denver, CO" },
  { label: "Des Moines, IA", value: "Des Moines, IA" },
  { label: "Detroit, MI", value: "Detroit, MI" },
  { label: "El Paso, TX", value: "El Paso, TX" },
  { label: "Eugene, OR", value: "Eugene, OR" },
  { label: "Fayetteville, AR", value: "Fayetteville, AR" },
  { label: "Fayetteville, NC", value: "Fayetteville, NC" },
  { label: "Flint, MI", value: "Flint, MI" },
  { label: "Fort Collins, CO", value: "Fort Collins, CO" },
  { label: "Fort Wayne, IN", value: "Fort Wayne, IN" },
  { label: "Fresno, CA", value: "Fresno, CA" },
  { label: "Gainesville, FL", value: "Gainesville, FL" },
  { label: "Grand Rapids, MI", value: "Grand Rapids, MI" },
  { label: "Green Bay, WI", value: "Green Bay, WI" },
  { label: "Greensboro, NC", value: "Greensboro, NC" },
  { label: "Greenville, SC", value: "Greenville, SC" },
  { label: "Gulfport, MS", value: "Gulfport, MS" },
  { label: "Harrisburg, NY", value: "Harrisburg, NY" },
  { label: "Harrisburg, PA", value: "Harrisburg, PA" },
  { label: "Hartford, CT", value: "Hartford, CT" },
  { label: "Hickory, NC", value: "Hickory, NC" },
  { label: "Honolulu, HI", value: "Honolulu, HI" },
  { label: "Houston, TX", value: "Houston, TX" },
  { label: "Huntsville, AL", value: "Huntsville, AL" },
  { label: "Indianaplis, IN", value: "Indianaplis, IN" },
  { label: "Jackson, MS", value: "Jackson, MS" },
  { label: "Jacksonville, FL", value: "Jacksonville, FL" },
  { label: "Kansas City, MO", value: "Kansas City, MO" },
  { label: "Killeen, TX", value: "Killeen, TX" },
  { label: "Knoxville, TN", value: "Knoxville, TN" },
  { label: "Lafayette, LA", value: "Lafayette, LA" },
  { label: "Lancaster, PA", value: "Lancaster, PA" },
  { label: "Lansing, MI", value: "Lansing, MI" },
  { label: "Las Vegas, NV", value: "Las Vegas, NV" },
  { label: "Lexington, KY", value: "Lexington, KY" },
  { label: "Lincoln, NE", value: "Lincoln, NE" },
  { label: "Little Rock, AR", value: "Little Rock, AR" },
  { label: "Los Angeles, CA", value: "Los Angeles, CA" },
  { label: "Louisville, KY", value: "Louisville, KY" },
  { label: "Lubbock, TX", value: "Lubbock, TX" },
  { label: "Madison, WI", value: "Madison, WI" },
  { label: "Manchest, NH", value: "Manchest, NH" },
  { label: "McAllen, TX", value: "McAllen, TX" },
  { label: "Memphis, TN", value: "Memphis, TN" },
  { label: "Miami, FL", value: "Miami, FL" },
  { label: "Milwaukee, WI", value: "Milwaukee, WI" },
  { label: "Minneapolis, MN", value: "Minneapolis, MN" },
  { label: "Mobile, AL", value: "Mobile, AL" },
  { label: "Montgomery, AL", value: "Montgomery, AL" },
  { label: "Myrtle Beach, SC", value: "Myrtle Beach, SC" },
  { label: "Naples, FL", value: "Naples, FL" },
  { label: "Nashville, TN", value: "Nashville, TN" },
  { label: "New Orleans, LA", value: "New Orleans, LA" },
  { label: "New york, NY", value: "New york, NY" },
  { label: "Ocala, FL", value: "Ocala, FL" },
  { label: "Oklahoma City, OK", value: "Oklahoma City, OK" },
  { label: "Omaha, NE", value: "Omaha, NE" },
  { label: "Orlando, FL", value: "Orlando, FL" },
  { label: "Pensacola, FL", value: "Pensacola, FL" },
  { label: "Peoria, IL", value: "Peoria, IL" },
  { label: "Philadelphia, PA", value: "Philadelphia, PA" },
  { label: "Phoenix, AZ", value: "Phoenix, AZ" },
  { label: "Pittsburgh, PA", value: "Pittsburgh, PA" },
  { label: "Portland, ME", value: "Portland, ME" },
  { label: "Portland, OR", value: "Portland, OR" },
  { label: "Providence, RI", value: "Providence, RI" },
  { label: "Raleigh, NC", value: "Raleigh, NC" },
  { label: "Reading, PA", value: "Reading, PA" },
  { label: "Reno, NV", value: "Reno, NV" },
  { label: "Richmond, VA", value: "Richmond, VA" },
  { label: "Rochester, NY", value: "Rochester, NY" },
  { label: "Rockford, IL", value: "Rockford, IL" },
  { label: "Sacramento, CA", value: "Sacramento, CA" },
  { label: "Salem, OR", value: "Salem, OR" },
  { label: "Salinas, CA", value: "Salinas, CA" },
  { label: "Salisbury, MD", value: "Salisbury, MD" },
  { label: "Salt Lake City, UT", value: "Salt Lake City, UT" },
  { label: "San Antonio, TX", value: "San Antonio, TX" },
  { label: "San Diego, CA", value: "San Diego, CA" },
  { label: "San Fransisco, CA", value: "San Fransisco, CA" },
  { label: "San Jose, CA", value: "San Jose, CA" },
  { label: "Santa Barbara, CA", value: "Santa Barbara, CA" },
  { label: "Sarasota, FL", value: "Sarasota, FL" },
  { label: "Savannah, GA", value: "Savannah, GA" },
  { label: "Scranton, PA", value: "Scranton, PA" },
  { label: "Seattle, WA", value: "Seattle, WA" },
  { label: "Shreveport, LA", value: "Shreveport, LA" },
  { label: "South Bend, IN", value: "South Bend, IN" },
  { label: "Spokane, WA", value: "Spokane, WA" },
  { label: "Springfield, MA", value: "Springfield, MA" },
  { label: "Springfield, MO", value: "Springfield, MO" },
  { label: "St. Louis, MO", value: "St. Louis, MO" },
  { label: "Stockton, CA", value: "Stockton, CA" },
  { label: "Syracuse, NY", value: "Syracuse, NY" },
  { label: "Talahassee, FL", value: "Talahassee, FL" },
  { label: "Tampa, FL", value: "Tampa, FL" },
  { label: "Toledo, OH", value: "Toledo, OH" },
  { label: "Trenton, NJ", value: "Trenton, NJ" },
  { label: "Tucson, AZ", value: "Tucson, AZ" },
  { label: "Tulsa, OK", value: "Tulsa, OK" },
  { label: "Virginia Beach, VA", value: "Virginia Beach, VA" },
  { label: "Visalia, CA", value: "Visalia, CA" },
  { label: "Washington, DC", value: "Washington, DC" },
  { label: "Wichita, KS", value: "Wichita, KS" },
  { label: "Winston - Salem, NC", value: "Winston - Salem, NC" },
  { label: "Youngstown, OH", value: "Youngstown, OH" },
];

export { states, MSAs };
