import { actionTypes } from "redux/actions";
import { reducerCreator, multipleReducers } from "./meta";

export const label = multipleReducers({
  initializers: {
    initialState: {
      labelsTotalCount: 0,
      labels: [],
      labelsTreeView: [],
      lastLabelCreated: null,
      lastLabelUpdated: null,
      lastLabelDeleted: null,
    },
  },
  list: [
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.GET_LABELS,
        successFn: (action) => ({
          labels: action.data.body.rows,
          labelsTotalCount: action.data.body.count,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.GET_LABELS_TREE_VIEW,
        successFn: (action) => ({
          labelsTreeView: action.data.body,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.CREATE_LABEL,
        successFn: (action) => ({
          lastLabelCreated: action.data,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.UPDATE_LABEL,
        successFn: (action) => ({
          lastLabelUpdated: action.data,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.DELETE_LABEL,
        successFn: (action) => ({
          lastLabelDeleted: action.data,
        }),
      },
    },
  ],
});
