import { create } from "zustand";

export const types = {
  create: "create",
  edit: "edit",
};

const defaultValues = {
  openModal: "",
  contextData: null,
  editData: null,
};

const useAddEditSectionModalStore = create((set) => ({
  ...defaultValues,

  openCreateModal: (contextData) =>
    set({
      openModal: types.create,
      contextData,
    }),
  openEditModal: (contextData, editData) =>
    set({ openModal: types.edit, contextData, editData }),

  closeModal: () => set({ ...defaultValues }),
}));

export default useAddEditSectionModalStore;
