import React from "react";
import { Card, Image, Space, Typography } from "antd";
import {
  FilePdfOutlined,
  FileZipOutlined,
  FileUnknownOutlined,
  FileExcelOutlined,
  FileWordOutlined,
} from "@ant-design/icons";
import { ALLOWED_FILE_MANAGER_PICTURE_FILE_TYPES as imageTypes } from "containers/deals/FileManager/constants";
import { toLower } from "lodash";

export const InboxAttachments = ({ attachments = [] }) => {
  return (
    <Space size={[16, 16]} wrap>
      {attachments.map((attachment) => (
        <InboxAttachmentItem key={attachment._id} attachment={attachment} />
      ))}
    </Space>
  );
};

const InboxAttachmentItem = ({ attachment }) => {
  const fileType = toLower(attachment.name.split(".").pop());
  const isImageType = imageTypes.includes(fileType);
  const icon = getFileIcon(fileType);

  const handleClick = () => {
    window.open(attachment.location, "_blank");
  };

  return (
    <Card
      onClick={handleClick}
      bodyStyle={{
        width: 166,
        height: 94,
        padding: "1rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        cursor: "pointer",
      }}
    >
      {isImageType ? (
        <Image
          preview={false}
          width={40}
          height={40}
          style={{ objectFit: "cover" }}
          src={attachment.location}
        />
      ) : (
        <div
          style={{
            width: 40,
            height: 40,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {icon}
        </div>
      )}
      <Typography.Text ellipsis={{ tooltip: attachment.name }}>
        {attachment.name}
      </Typography.Text>
    </Card>
  );
};

const getFileIcon = (fileType) => {
  let Icon;

  switch (fileType) {
    case "pdf":
      Icon = FilePdfOutlined;
      break;
    case "zip":
      Icon = FileZipOutlined;
      break;
    case "csv":
    case "xls":
    case "xlsx":
      Icon = FileExcelOutlined;
      break;
    case "doc":
    case "docx":
      Icon = FileWordOutlined;
      break;
    default:
      Icon = FileUnknownOutlined;
      break;
  }

  return <Icon style={{ fontSize: "24px" }} />;
};
