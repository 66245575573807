import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { OverlayTrigger, Tooltip, Button, Row, Col } from "react-bootstrap";
import { getProgramsByCompanyId, getTeamsByCompanyId } from "redux/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Spacer from "components/layout/Spacer";
import Loader from "components/ui/Loader";
import Socket from "utils/Socket";
import Paginate from "components/ui/Paginate";
import ProgramPrompt from "containers/program/ProgramPrompt";
import PopupPrompt from "components/ui/PopupPrompt";
import NewProgramIntro from "containers/program/NewProgramIntro";

class CompanyPrograms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      showPrompt: false,
      showIntroPrompt: false,
    };

    this.handleShowPrompt = this.handleShowPrompt.bind(this);
    this.getProgramsPage = this.getProgramsPage.bind(this);
  }

  componentDidMount() {
    this.getPrograms();
    const { company } = this.props;
    this.subscriptionId = Socket.subscribe(
      `/company/${company._id}/program`,
      this.getPrograms
    );
  }

  getPrograms = () => {
    const { company } = this.props;

    getProgramsByCompanyId(company._id, 1);
  };

  handleShowPrompt(showPrompt) {
    this.setState({ showPrompt });
  }

  handleShowIntroPrompt(showIntroPrompt) {
    this.setState({ showIntroPrompt });
  }

  componentWillUnmount() {
    Socket.unsubscribe(this.subscriptionId);
  }

  getProgramsPage(page) {
    const { company } = this.props;

    getProgramsByCompanyId(company._id, page);
  }

  render() {
    const { programsTotalCount, programs, action, isCompanyAdmin, company } =
      this.props;
    const { isFetching } = action.GET_PROGRAMS_BY_COMPANY_ID.isFetching;
    return (
      <>
        {isFetching && <Loader />}
        <PopupPrompt
          promptTitle="About Lender Programs"
          promptBody={
            <NewProgramIntro
              onContinue={() => {
                this.handleShowIntroPrompt(false);
                this.handleShowPrompt(true);
              }}
              onSetupMeeting={() => {
                window.open("https://calendly.com/matt-termstreet");
              }}
            />
          }
          show={this.state.showIntroPrompt}
          onCancel={() => {
            this.handleShowIntroPrompt(false);
          }}
          onHide={() => {
            this.handleShowIntroPrompt(false);
          }}
        />
        <ProgramPrompt
          isCompanyAdmin={isCompanyAdmin}
          companyId={company._id}
          onSubmit={() => {
            this.handleShowPrompt(false);
            this.getPrograms();
          }}
          show={this.state.showPrompt}
          onCancel={() => {
            this.handleShowPrompt(false);
            getTeamsByCompanyId(company._id);
          }}
        />
        <Row>
          <Col xs="12">
            <Spacer size={30} />
          </Col>
          <Col className="align-self-end" xs="6">
            <h3 className="bold">Programs</h3>
          </Col>
          <Col className="text-right" xs="6">
            <OverlayTrigger
              placement="left"
              overlay={<Tooltip id={`tooltip-left`}>Create Program</Tooltip>}
            >
              <Button
                className="pl-3 pt-2 pr-3 pb-2"
                onClick={() => {
                  this.setState({ showIntroPrompt: true });
                }}
              >
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            </OverlayTrigger>
          </Col>
          <Col xs="12">
            <div className="border-top"></div>
            <Spacer size={30} />
          </Col>
          {!isFetching && programs && programs.length === 0 ? (
            <>
              <Col xs="12">
                <p className="text-danger">
                  Your company doesn't have any programs yet!
                </p>
                <Spacer size={10} />
              </Col>
            </>
          ) : (
            programs.map((program, idx) => {
              return (
                <Col xs="12" sm="4" key={idx}>
                  <div className="panel">
                    <h5>
                      <Link to={"/program/view/" + program._id}>
                        {program.programName}
                      </Link>
                    </h5>
                  </div>
                  <Spacer size={20} />
                </Col>
              );
            })
          )}
        </Row>
        <Paginate
          page={this.state.page}
          list={programs}
          listTotalCount={programsTotalCount}
          listOffset={6}
          previousPage={(p) => {
            this.setState({ page: p });
            this.getProgramsPage(p);
          }}
          nextPage={(p) => {
            this.setState({ page: p });
            this.getProgramsPage(p);
          }}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    action: state.action,
    whoami: state.auth.whoami,
    company: state.company.company,
    programs: state.program.programs,
    programsTotalCount: state.program.programsTotalCount,
  };
}

export default connect(mapStateToProps)(CompanyPrograms);
