import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import startCase from "lodash/startCase";
import { Form, Row, Col, Button, Container, Table } from "react-bootstrap";
import { getSections } from "redux/actions";
import { reduxFormSearch } from "components/form/ReduxForm";
import Spacer from "components/layout/Spacer";
import { Field, reduxForm } from "redux-form";

function SpecialtySectionCreateFromTemplate(props) {
  const [searchInput, setSearchInput] = useState("");
  const [sectionId, setSectionId] = useState("");

  useEffect(() => {
    setSectionId("");
    getSections({
      limit: 100,
      order: "ASC",
      isTemplate: true,
      status: "ACTIVE",
    });
  }, []);

  const sectionsList = useMemo(() => {
    return props.sections.filter((e) =>
      e.sectionName.toLocaleLowerCase().includes(searchInput)
    );
  }, [props.sections, searchInput]);

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handleSave = (e) => {
    e.preventDefault();
  };

  return (
    <Container className="create-from-deal">
      <Form className="no-border" onSubmit={handleSave}>
        <Row>
          <Col xs={12}>
            <h4 className="text-heading">TermStreet Template Sections</h4>
            <Spacer size={20} />
          </Col>
          <Col xs={12}>
            <Field
              className="form-control"
              component={reduxFormSearch}
              name="search"
              type="text"
              placeholder="Search by Section Name"
              inputValue={searchInput}
              input={{
                onChange: handleSearchChange,
              }}
            />
          </Col>
        </Row>

        <Spacer size={20} />

        <Row>
          <Col xs={12}>
            <Table responsive="sm">
              <thead>
                <tr>
                  <th>Section Name</th>
                  <th style={{ width: 160 }}>Status</th>
                  <th className="text-center" style={{ width: 160 }}>
                    Date Last Edited
                  </th>
                </tr>
              </thead>
              <tbody>
                {sectionsList.map((item) => (
                  <tr
                    key={item._id}
                    className={item._id === sectionId ? "row-item-primary" : ""}
                    onClick={() => setSectionId(item._id)}
                  >
                    <td>{item.sectionName}</td>
                    <td>{startCase(item.status.toLowerCase())}</td>
                    <td className="text-center">
                      {new Date(item.updatedAt).toLocaleDateString()}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>

        <Spacer size={60} />

        <Row>
          <Col className="text-right">
            <Button variant="primary" className="mr-2" onClick={props.onCancel}>
              Go Back
            </Button>

            <Button
              disabled={!sectionId}
              variant="tertiary"
              onClick={() => props.onSubmit(sectionId)}
            >
              Select
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
}
function mapStateToProps(state) {
  return {
    action: state.action,
    myCompany: state.company.myCompany,
    sections: state.section.sections,
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "SpecialtySectionCreateFromTemplate",
    enableReinitialize: true,
  })(SpecialtySectionCreateFromTemplate)
);
