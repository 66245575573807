import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { OverlayTrigger, Tooltip, Button, Row, Col } from "react-bootstrap";
import { getTeamsByCompanyId } from "redux/actions";
import { reset } from "redux-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Spacer from "components/layout/Spacer";
import Loader from "components/ui/Loader";
import PopupPrompt from "components/ui/PopupPrompt";
import TeamCreate from "containers/team/TeamCreate";
import Paginate from "components/ui/Paginate";
import Socket from "utils/Socket";

class CompanyTeams extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPrompt: false,
      page: 1,
    };

    this.handleShowPrompt = this.handleShowPrompt.bind(this);
  }

  componentDidMount() {
    this.getTeams();
    const { company } = this.props;
    this.subscriptionId = Socket.subscribe(
      `/company/${company._id}/team`,
      this.getTeams
    );
  }

  getTeams = () => {
    const { company } = this.props;

    getTeamsByCompanyId(company._id, { limit: 6, page: this.state.page });
  };

  handleShowPrompt(showPrompt) {
    this.setState({ showPrompt });
  }

  componentWillUnmount() {
    Socket.unsubscribe(this.subscriptionId);
  }

  render() {
    const {
      teams,
      totalTeams,
      action,
      dispatch,
      isCompanyAdmin,
      company,
    } = this.props;
    const { isFetching } = action.GET_TEAMS_BY_COMPANY_ID.isFetching;

    return (
      <>
        {isFetching && <Loader />}
        <PopupPrompt
          promptTitle={"Create Team"}
          promptBody={<TeamCreate companyId={company._id} />}
          show={this.state.showPrompt}
          onCancel={() => {
            dispatch(reset("CreateTeam"));
            this.handleShowPrompt(false);
          }}
          onHide={() => {
            dispatch(reset("CreateTeam"));
            this.handleShowPrompt(false);
          }}
        />
        <Row>
          <Col xs="12">
            <Spacer size={30} />
          </Col>
          <Col className="align-self-end" xs="6">
            <h3 className="bold">Teams</h3>
          </Col>
          <Col className="text-right" xs="6">
            <OverlayTrigger
              placement="left"
              overlay={<Tooltip id={`tooltip-left`}>Create Team</Tooltip>}
            >
              <Button
                className="pl-3 pt-2 pr-3 pb-2"
                onClick={() => {
                  this.handleShowPrompt(true);
                }}
              >
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            </OverlayTrigger>
          </Col>
          <Col xs="12">
            <div className="border-top"></div>
            <Spacer size={30} />
          </Col>
          {!isFetching && teams && teams.length === 0 ? (
            <>
              <Col xs="12">
                <p className="text-danger">
                  Your company doesn't have any teams yet!
                </p>
                <Spacer size={10} />
              </Col>
            </>
          ) : (
            <>
              {teams.map((team, idx) => {
                return (
                  <Col xs="12" sm="4" key={idx}>
                    <div className="panel">
                      <h5>
                        <Link to={"/team/view/" + team._id}>
                          {team.teamName}
                        </Link>
                      </h5>
                    </div>
                    <Spacer size={20} />
                  </Col>
                );
              })}
              <Paginate
                page={this.state.page}
                list={teams}
                listTotalCount={totalTeams}
                listOffset={6}
                previousPage={(p) => {
                  this.setState({ page: p });

                  getTeamsByCompanyId(company._id, { limit: 6, page: p });
                }}
                nextPage={(p) => {
                  this.setState({ page: p });

                  getTeamsByCompanyId(company._id, { limit: 6, page: p });
                }}
              />
            </>
          )}
        </Row>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    action: state.action,
    whoami: state.auth.whoami,
    company: state.company.company,
    teams: state.team.teams,
    totalTeams: state.team.totalTeams,
  };
}

export default connect(mapStateToProps)(CompanyTeams);
