import React, { Component } from "react";
import camelCase from "lodash/camelCase";
import { connect } from "react-redux";
import { Container } from "react-bootstrap";
import { storeSections, updateTermsheet } from "redux/actions";
import update from "immutability-helper";
import { easeSections } from "containers/termsheet/sections";
import ClauseRow from "./ClauseRow";
import debounce from "lodash/debounce";
import { Draggable, Droppable } from "react-beautiful-dnd";

class SelectedClauses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: this.props.columns,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.columns.length !== this.props.columns.length) {
      this.setState({ columns: this.props.columns });
    }
    if (prevProps.columns !== this.props.columns) {
      this.setState({ columns: this.props.columns });
    }
    if (prevProps.columns.length < this.props.columns.length) {
      this.handleSaveTermsheetColumns(this.props.columns);
    }
  }

  handleSaveTermsheetColumns = (columns) => {
    const { match } = this.props;
    if (match.params.termsheetId) {
      const updatedCoumns = columns.map(({ clause, ...col }) => ({
        ...col,
        clauseId: clause?._id,
      }));
      updateTermsheet(match.params.termsheetId, {
        columns: updatedCoumns,
        doNotRedirect: true,
      });
    }
  };

  debouncedHandleSaveTermsheetColumns = debounce(
    this.handleSaveTermsheetColumns,
    1000
  );

  handleOnChange = (key, value, index) => {
    this.setState(
      (prevState) => {
        const newColumns = [...prevState.columns];
        const column = newColumns[index];
        column[key] = value;
        newColumns[index] = column;
        return { columns: newColumns };
      },
      () => {
        storeSections(this.state.columns);
        this.debouncedHandleSaveTermsheetColumns(this.state.columns);
      }
    );
  };

  handleOnRemove = (index) => {
    this.setState(
      (prevState) => {
        const newColumns = [...prevState.columns];
        newColumns.splice(index, 1);
        return { columns: newColumns };
      },
      () => {
        storeSections(this.state.columns);
        this.handleSaveTermsheetColumns(this.state.columns);
      }
    );
  };

  handleMoveCard = (dragIndex, hoverIndex) => {
    const { columns } = this.state;
    const dragCard = columns[dragIndex];
    this.setState(
      (prevState) => ({
        columns: update(prevState.columns, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        }),
      }),
      () => {
        storeSections(this.state.columns);
        this.handleSaveTermsheetColumns(this.state.columns);
      }
    );
  };

  handleAddToMatrix = (column) => {
    const { quoteMatrices, onSaveAddedClauses } = this.props;
    let key = camelCase(column.sectionName);
    let type = "number";
    let subLabelKey;
    let subLabelOptions;
    let subLabelType;

    if (column.clause?.addOn === "Years / Months Selection") {
      subLabelKey = key + "Metric";
      subLabelType = "select";
      subLabelOptions = [
        { value: "years", label: "Years" },
        { value: "months", label: "Months" },
      ];
    }

    if (["$#,###"].includes(column.clause?.format)) {
      type = "currency";
    } else if (["#,###", "#.##x"].includes(column.clause?.format)) {
      type = "number";
    } else if (["#%", "#.#%", "#.##%"].includes(column.clause?.format)) {
      type = "percent";
    }

    const newMatrix = {
      clauseId: column.clause?._id,
      key,
      label: column.sectionName,
      type,
      subLabelKey,
      subLabelType,
      subLabelOptions,
    };
    const newQuoteMatrices = [...quoteMatrices, newMatrix];
    onSaveAddedClauses(newQuoteMatrices);
  };

  render() {
    const { columns } = this.state;
    const { quoteMatrices, match, action } = this.props;

    return (
      <Droppable droppableId="droppableId-clauses" type="CLAUSES">
        {(provided, snapshot) => (
          <Container className="droppable-container">
            {/* <Spacer size={20} /> */}
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {columns.map((column, index) => {
                const id = `${column.category}-${column.sectionName}-move`;
                const easeSection = easeSections[id];
                const isDragDisabled = column.isPrimary;
                return (
                  <Draggable
                    key={id}
                    draggableId={id}
                    index={index}
                    isDragDisabled={isDragDisabled}
                  >
                    {(provided, snapshot) => {
                      return (
                        <ClauseRow
                          key={index}
                          id={id}
                          index={index}
                          match={match}
                          provided={provided}
                          snapshot={snapshot}
                          easeSection={easeSection}
                          handleOnChange={this.handleOnChange}
                          handleMoveCard={this.handleMoveCard}
                          handleOnRemove={this.handleOnRemove}
                          handleAddToMatrix={this.handleAddToMatrix}
                          column={column}
                          componentToDrag={ClauseRow}
                          quoteMatrices={quoteMatrices}
                          action={action}
                          isDragDisabled={isDragDisabled}
                        />
                      );
                    }}
                  </Draggable>
                );
              })}
              {provided.placeholder}
              {snapshot.isDraggingOver && (
                <div className="droppable-placeholder" />
              )}
            </div>
            {/* <Spacer size={20} /> */}
          </Container>
        )}
      </Droppable>
    );
  }
}

const mapStateToProps = (state) => ({
  sections: state.termsheet.sections,
  action: state.action,
  columns: state.termsheet.termsheet.columns || [],
  quoteMatrices: state.termsheet.termsheet.addedClauses || [],
});
export default connect(mapStateToProps)(SelectedClauses);
