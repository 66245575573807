import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Form, Container, Row, Col } from "react-bootstrap";
import { Field, reduxForm } from "redux-form";
import { required, email, passwordRule, noSpaces } from "utils/validation";
import { reduxFormInput } from "components/form/ReduxForm";
import { loginUser } from "redux/actions";
import Spacer from "components/layout/Spacer";
import Loader from "components/ui/Loader";
import TSLogo from "components/ui/TSLogo";
import withPageTitle from "components/hoc/withPageTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import constants from "config/constants";

const Welcome = (props) => {
  const submitFn = (data) => {   
    loginUser(data);
  };
  const onChangeEmail = () => {
    props.setShowWelcome(false);
  };

  const { handleSubmit, action } = props;
  const { isFetching } = action.LOGIN_USER;

  return (
    <>
      {isFetching && <Loader />}
      <header className="border-bottom">
        <Spacer size={23} />
        <h1 className="text-center">Welcome</h1>
        <Spacer size={23} />
      </header>
      <Container>
        <Spacer size={50} />
        <Form className="auth-form" onSubmit={handleSubmit(submitFn)}>
          <Row className="justify-content-center">
            <Col className="d-flex justify-content-center" xs="6">
              <TSLogo blue title />
            </Col>
          </Row>
          <Spacer size={50} />
          <Row className="justify-content-center">
            <Col xs={12} sm={6} className="text-center">
              <div className="welcome-user-email">
                {" "}
                <FontAwesomeIcon
                  icon={faUser}
                  tabIndex="0"
                  className="font-20 mr-3"
                />
                {props.email}
              </div>
              {/* <div
                onClick={onChangeEmail}
                className="welcome-user-change tex-center"
              >
                Change
              </div> */}
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs="12">
              <Spacer size={30} />
            </Col>
            <Col xs="12" sm="6">
              <Field
                className="form-control"
                component={reduxFormInput}
                validate={[required, passwordRule]}
                name="password"
                type="password"
                placeholder="Enter password"
                label="Password"
              />

              <Spacer size={15} />
              <Link to="/forgot-password">Forgot password.</Link>

              {/* <Spacer size={50} /> */}
            </Col>
            {/* <Col xs="12" className="text-center">
                <Link to="/forgot-password">Forgot password.</Link>
              </Col> */}
          </Row>
          <Spacer size={50} />
          <Row className="justify-content-center">
            <Col className="text-center" xs="12" sm="6">
              <Button
                variant="primary"
                type="submit"
                onClick={handleSubmit(submitFn)}
              >
                Login
              </Button>
              <Spacer size={50} />
            </Col>
          </Row>
        </Form>
        <Spacer size={50} />
      </Container>
    </>
  );
};

function mapStateToProps(state) {
  return {
    action: state.action,
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "login",
    enableReinitialize: true,
  })(withPageTitle(Welcome, "Welcome"))
);
