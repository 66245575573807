import React from "react";
import { connect } from "react-redux";
import { Button, Form, Container, Row, Col } from "react-bootstrap";
import { Field, formValueSelector, reduxForm } from "redux-form";
import { required, noSpaces } from "utils/validation";
import {
  reduxFormInput,
  reduxFormSelect,
  reduxFormTextarea,
  reduxFormWYSIWYG as TextArea,
} from "components/form/ReduxForm";
import { createClause, getClauses, updateClause } from "redux/actions";
import Spacer from "components/layout/Spacer";
import { adminAvailabilityOptions } from "./lookup";

const statusOptions = [
  {
    label: "ACTIVE",
    value: "ACTIVE",
  },
  {
    label: "ARCHIVED",
    value: "ARCHIVED",
  },
  {
    label: "UNDER_REVIEW",
    value: "UNDER_REVIEW",
  },
];

class CreateEditClause extends React.Component {
  constructor(props) {
    super(props);

    this.submitFn = this.submitFn.bind(this);

    this.draft = false;
    this.state = {
      value: props.initialValues.clause || "",
      parentOptions: [],
      ownerOptions: [
        {
          label: "Current User",
          value: props.whoami._id,
        },
      ],
    };
  }

  componentDidMount() {
    this.handleGetClauses();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.clauses !== this.props.clauses) {
      const parentOptions = this.props.clauses.map((item) => ({
        value: item._id,
        label: item.clauseName,
      }));
      this.setState({ parentOptions });
    }
    if (
      this.props.selectedParent &&
      prevProps.selectedParent !== this.props.selectedParent &&
      (!this.props.selectedDisplayName ||
        this.props.selectedDisplayName === prevProps.selectedDisplayName)
    ) {
      const parentObject = this.props.clauses.find(
        (e) => e._id === this.props.selectedParent.value
      );
      if (parentObject) {
        this.props.change("category", parentObject?.category._id);
        this.props.change("categoryName", parentObject?.category.categoryName);
        this.props.change("addOn", parentObject?.addOn || null);
        this.props.change("format", parentObject?.format || null);
        this.props.change("displayName", parentObject?.clauseName);
      }
    }
  }

  handleGetClauses = () => {
    getClauses({
      limit: 100,
      order: "ASC",
      orderColumn: "clauseName",
      parent: true,
    });
  };

  submitFn(data) {
    const { promptType, selectedClause } = this.props;

    if (promptType === "create" || promptType === "copy") {
      createClause({
        clauseName: data.clauseName,
        displayName: data.displayName,
        parent: data.parent.value,
        adminAvailability: {
          key: data.adminAvailability.value,
          values: [],
        },
        owner: data.owner.value,
        category: data.category,
        format: data.format?.value || data.format,
        addOn: data.addOn?.value || data.addOn,
        notes: data.notes,
        clause: data.clause,
        status: this.draft ? "DRAFT" : "ACTIVE",
      });
    } else if (promptType === "edit") {
      updateClause(selectedClause._id, {
        clauseName: data.clauseName,
        displayName: data.displayName,
        parent: data.parent.value,
        adminAvailability: {
          key: data.adminAvailability.value,
          values: [],
        },
        owner: data.owner.value,
        category: data.category,
        format: data.format?.value || data.format,
        addOn: data.addOn?.value || data.addOn,
        notes: data.notes,
        clause: data.clause,
      });
    } else if (promptType === "change_status") {
      updateClause(selectedClause._id, {
        status: data.status.value,
      });
    } else if (promptType === "change_availability") {
      updateClause(selectedClause._id, {
        adminAvailability: {
          key: data.adminAvailability.value,
          values: [],
        },
      });
    }
  }

  handleClauseChange = (value) => {
    this.props.change("clause", value);
    this.setState({ value });
  };

  getAvailabilityOptions = () => {
    const { whoami } = this.props;
    return whoami.role === "Admin"
      ? adminAvailabilityOptions
      : adminAvailabilityOptions.filter((e) => e.value !== "ALL_LENDERS");
  };

  render() {
    const { action, promptType, handleSubmit } = this.props;
    const isProcessing =
      action.CREATE_TAG.isFetching || action.UPDATE_TAG.isFetching;

    return (
      <>
        <Container>
          <Form
            className="no-border no-padding"
            onSubmit={handleSubmit(this.submitFn)}
          >
            {(promptType === "create" ||
              promptType === "edit" ||
              promptType === "copy") && (
              <>
                <Row>
                  <Col xs="12">
                    <Field
                      className="form-control"
                      component={reduxFormInput}
                      validate={[required, noSpaces]}
                      name="clauseName"
                      type="text"
                      placeholder="Enter Clause Name and/or Description"
                      label="*Clause Name and/or Description"
                    />
                  </Col>
                </Row>

                <Spacer size={10} />

                <Row>
                  <Col xs="6">
                    <Field
                      className="form-control"
                      component={reduxFormSelect}
                      validate={[required]}
                      name="parent"
                      type="select"
                      options={this.state.parentOptions}
                      placeholder="Choose Parent Clause"
                      label="*Parent Clause"
                      onFocus={this.handleGetClauses}
                    />
                  </Col>
                  <Col xs="6">
                    <Field
                      className="form-control"
                      component={reduxFormInput}
                      validate={[required, noSpaces]}
                      name="displayName"
                      type="text"
                      placeholder="Enter Clause Display Name"
                      label="*Clause Display Name"
                    />
                  </Col>
                </Row>

                <Spacer size={10} />

                <Row>
                  <Col xs="6">
                    <Field
                      className="form-control"
                      component={reduxFormSelect}
                      validate={[required]}
                      name="owner"
                      type="select"
                      options={this.state.ownerOptions}
                      label="*Owner"
                    />
                  </Col>

                  <Col xs="6">
                    <Field
                      className="form-control"
                      component={reduxFormSelect}
                      validate={[required]}
                      name="adminAvailability"
                      type="select"
                      options={this.getAvailabilityOptions()}
                      placeholder="Add Availability"
                      label="*Availability"
                    />
                  </Col>
                </Row>

                <Spacer size={20} />

                <Row>
                  <Col xs={12}>
                    <p>
                      Formatting is controlled by the parent clause and cannot
                      be modified here. The formatting is shown below.
                    </p>
                  </Col>
                </Row>

                <Spacer size={10} />

                <Row>
                  <Col xs="6">
                    <Field
                      disabled={promptType === "create"}
                      className="form-control"
                      component={reduxFormInput}
                      validate={[]}
                      name="categoryName"
                      type="text"
                      label="Clause Category"
                    />
                  </Col>
                  <Col xs="6">
                    <Field
                      disabled
                      className="form-control"
                      component={reduxFormInput}
                      validate={[]}
                      name="format"
                      type="text"
                      label="Format"
                    />
                  </Col>
                </Row>

                <Spacer size={10} />

                <Row>
                  <Col xs="6">
                    <Field
                      disabled
                      className="form-control"
                      component={reduxFormInput}
                      validate={[]}
                      name="addOn"
                      type="text"
                      label="Add On"
                    />
                  </Col>
                </Row>

                <Spacer size={10} />

                <Row>
                  <Col xs="12">
                    <Field
                      className="form-control"
                      component={reduxFormTextarea}
                      validate={[noSpaces]}
                      name="notes"
                      type="textarea"
                      rows="4"
                      label="Useage Note"
                      placeholder="Add Useage Note..."
                    />
                  </Col>
                </Row>

                <Spacer size={10} />

                <Row>
                  <Col xs="12">
                    <TextArea
                      required
                      height={200}
                      className="form-control"
                      name="clause"
                      type="text"
                      label="*Clause"
                      placeholder="Add Clause..."
                      input={{
                        name: "clause",
                        value: this.state.value,
                        onChange: this.handleClauseChange,
                      }}
                    />
                  </Col>
                </Row>

                <Spacer size={20} />
              </>
            )}

            {promptType === "change_status" && (
              <Row>
                <Col xs="12">
                  <Field
                    className="form-control"
                    component={reduxFormSelect}
                    validate={[required]}
                    name="status"
                    type="select"
                    options={statusOptions}
                    placeholder="Status"
                    label="*Status"
                  />
                </Col>
                <Spacer size={40} />
              </Row>
            )}

            {promptType === "change_availability" && (
              <Row>
                <Col xs="12">
                  <Field
                    className="form-control"
                    component={reduxFormSelect}
                    validate={[required]}
                    name="adminAvailability"
                    type="select"
                    options={this.getAvailabilityOptions()}
                    placeholder="Add Availability"
                    label="*Availability"
                  />
                </Col>
                <Spacer size={40} />
              </Row>
            )}

            <Row className="justify-content-center">
              <Col className="text-center" xs="12">
                {(promptType === "create" || promptType === "copy") && (
                  <Button
                    disabled={isProcessing}
                    type="submit"
                    onClick={() => (this.draft = true)}
                    variant="tertiary"
                    className="mr-3"
                  >
                    {isProcessing ? "Saving..." : "Save Draft"}
                  </Button>
                )}
                <Button
                  disabled={isProcessing}
                  type="submit"
                  onClick={() => (this.draft = false)}
                  variant="primary"
                >
                  {isProcessing ? "Saving..." : "Save Clause"}
                </Button>
              </Col>
            </Row>
          </Form>

          <Spacer size={20} />
        </Container>
      </>
    );
  }
}

const selector = formValueSelector("CreateEditClause");

function mapStateToProps(state, props) {
  return {
    action: state.action,
    whoami: state.auth.whoami,
    clauses: state.clause.clauses,
    initialValues: {
      clauseName: props.selectedClause?.clauseName,
      displayName: props.selectedClause?.displayName,
      category: props.selectedClause?.category,
      categoryName: props.selectedClause?.category?.categoryName,
      format: props.selectedClause?.format || null,
      addOn: props.selectedClause?.addOn || null,
      notes: props.selectedClause?.notes,
      clause: props.selectedClause?.clause,
      status: props.selectedClause?.status
        ? {
            label: props.selectedClause?.status,
            value: props.selectedClause?.status,
          }
        : null,
      adminAvailability: props.selectedClause?.adminAvailability.key
        ? adminAvailabilityOptions.find(
            (e) => e.value === props.selectedClause?.adminAvailability.key
          )
        : null,
      parent: props.selectedClause?.parent._id
        ? {
            label: props.selectedClause?.parent.clauseName,
            value: props.selectedClause?.parent._id,
          }
        : null,
      owner: props.selectedClause?.owner._id
        ? {
            label: "Current User",
            value: props.selectedClause?.owner._id,
          }
        : null,
    },
    selectedParent: selector(state, "parent"),
    selectedDisplayName: selector(state, "displayName"),
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "CreateEditClause",
    enableReinitialize: true,
  })(CreateEditClause)
);
