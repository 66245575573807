export const conversationStatuses = {
  all: "all",
  inbox: "inbox",
  archived: "archived",
  draft: "draft",
  sent: "sent",
  deleted: "deleted",
  labels: "labels",
};

export const conversationTypes = {
  all: "all",
  messages: "messages",
  questions: "questions",
};

export const conversationType = {
  Message: "Message",
  Question: "Question",
};

export const conversationActions = {
  reply: "reply",
  forward: "forward",
  delete: "delete",
  archive: "archive",
  editQuestion: "editQuestion",
  editAnswer: "editAnswer",
  editVisibility: "editVisibility",
};

export const labelActions = {
  edit: "edit",
  delete: "delete",
};

export const inboxBulkActions = {
  all: "all",
  read: "read",
  respondedByTeam: "respondedByTeam",
  respondedByMe: "respondedByMe",
};

export const inboxModaTypes = {
  createMessage: "createMessage",
  askQuestion: "askQuestion",
  editQuestion: "editQuestion",
  editAnswer: "editAnswer",
  editVisibility: "editVisibility",
};
