import { create } from "zustand";
import { inboxModaTypes } from "./enums";

const useCreateConvoModalStore = create((set) => ({
  openModal: "",
  followUpToConvoId: "",
  conversationDetails: null,

  setOpenModalMessage: () => set({ openModal: inboxModaTypes.createMessage }),
  setOpenModalQuestion: () => set({ openModal: inboxModaTypes.askQuestion }),
  setOpenModalEditQuestion: (conversationDetails) =>
    set({ openModal: inboxModaTypes.editQuestion, conversationDetails }),
  setOpenModalEditAnswer: (conversationDetails) =>
    set({ openModal: inboxModaTypes.editAnswer, conversationDetails }),
  setOpenModalEditVisibility: (conversationDetails) =>
    set({ openModal: inboxModaTypes.editVisibility, conversationDetails }),
  setOpenModalFollowUpQuestion: (followUpToConvoId) =>
    set({ openModal: inboxModaTypes.askQuestion, followUpToConvoId }),

  closeModal: () =>
    set({ openModal: "", followUpToConvoId: "", conversationDetails: "" }),
}));

export default useCreateConvoModalStore;
