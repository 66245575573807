import React, { useCallback, useRef } from "react";
import { Button, Dropdown } from "react-bootstrap";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { faFileAlt } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ALLOWED_FILE_EXTS } from "./constants";

const GridFolderItem = (props) => {
  const {
    userId,
    _id,
    name,
    files,
    parentPermission,
    permission,
    handleUploadFile,
    fetchFolder,
    removeFolder,
    showRenaming,
    canManage,
  } = props;

  const inputRef = useRef(null);

  const checkPermission = useCallback(() => {
    if (permission && permission.length > 0) {
      if (permission.includes(userId)) return true;
      else return false;
    }
    if (parentPermission && parentPermission.length > 0) {
      if (parentPermission.includes(userId)) return true;
      else return false;
    }
    return true;
  }, [userId, parentPermission, permission]);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Button
      ref={ref}
      className="p-1"
      variant="folder"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </Button>
  ));

  const CustomMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) => child.props.children
            )}
          </ul>
        </div>
      );
    }
  );

  return (
    <>
      <div className="d-flex mb-30 pl-3 pr-3 align-items-end">
        <div
          className={
            files.length > 0
              ? "grid-col-container dark folder"
              : "grid-col-container"
          }
        >
          <div className="grid-item grid-col-name">
            <div className="grid-row">
              <span className="bold grid-file-name">{name}</span>
              {checkPermission() && (
                <Dropdown className="d-inline-block folder-file-dropdown">
                  <Dropdown.Toggle as={CustomToggle} id="toggle-dropdown">
                    <h4 className="view-as">
                      <FontAwesomeIcon icon={faEllipsisH} />
                    </h4>
                  </Dropdown.Toggle>

                  <Dropdown.Menu as={CustomMenu}>
                    <Dropdown.Item onClick={() => fetchFolder(_id, name)}>
                      Download
                    </Dropdown.Item>
                    {canManage && (
                      <>
                        <Dropdown.Item onClick={() => showRenaming(_id)}>
                          Edit
                        </Dropdown.Item>
                        {/* <Dropdown.Item
                          onClick={() => {
                            showSecuritySharing(_id);
                          }}
                        >
                          Security & Sharing
                        </Dropdown.Item> */}
                        <Dropdown.Divider> </Dropdown.Divider>
                        <Dropdown.Item onClick={() => removeFolder(_id)}>
                          Delete
                        </Dropdown.Item>
                      </>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </div>
          </div>
          {canManage && (
            <>
              {files.length > 0 ? (
                <span className="mb-1 font-18">
                  {files.length} <FontAwesomeIcon icon={faFileAlt} />
                </span>
              ) : (
                <span className="mb-1 font-12">Drop files here or</span>
              )}
              <Button
                variant="file-upload"
                className={files.length > 0 ? "dark" : null}
                onClick={() => {
                  inputRef.current.click();
                }}
              >
                Select Files
              </Button>
              <input
                ref={inputRef}
                className="drag-drop-file-upload"
                type="file"
                id={_id}
                onChange={(event) => handleUploadFile(event, _id)}
                accept={ALLOWED_FILE_EXTS.join(",")}
                multiple
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default GridFolderItem;
