import React from "react";
import { Col, Row } from "react-bootstrap";
import { Badge } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

const SelectedBadges = (props) => {
  const { show, selected = [], onChange } = props;

  return (
    <Row>
      {!show && (
        <Col className="d-flex align-items-center justify-content-between pr-2">
          <div className="chips-container">
            {selected.map((item) => {
              const isDisabled = false;
              return (
                <Badge
                  key={item.value}
                  pill
                  variant="primary"
                  className="d-flex p-2 m-1"
                >
                  {item.label}
                  {!isDisabled && (
                    <FontAwesomeIcon
                      className="ml-1"
                      style={{ cursor: "pointer" }}
                      icon={faTimesCircle}
                      onClick={() => {
                        onChange(
                          selected.filter((e) => e.value !== item.value)
                        );
                      }}
                    />
                  )}
                </Badge>
              );
            })}
          </div>
        </Col>
      )}
    </Row>
  );
};

export default SelectedBadges;
