import React from "react";
import { connect } from "react-redux";
import AccountDeactivate from "./AccountDeactivate";
import AccountSecurity from "./AccountSecurity";

const AccountPreferences = ({ whoami }) => {
  return (
    <div>
      <AccountSecurity profile={whoami} />
      <AccountDeactivate profile={whoami} />
    </div>
  );
};
function mapStateToProps(state) {
  return {
    whoami: state.auth.whoami,
  };
}

export default connect(mapStateToProps)(AccountPreferences);
