import React, { useEffect, useMemo, useState } from "react";
import { Form, Modal, Select, Switch } from "antd";
import TextArea from "components/form/TextArea";
import { useSelector } from "react-redux";
import { last } from "lodash";
import { inboxModaTypes } from "../enums";

const allLenders = "All Lenders";
const all = {
  label: allLenders,
  value: allLenders,
};

const EditAnswer = ({ open, type, data, onCreate, onCancel }) => {
  const whoami = useSelector((state) => state.auth.whoami);
  const myCompany = useSelector((state) => state.company.myCompany);
  const deal = useSelector((state) => state.deal.deal);

  const [form] = Form.useForm();
  const [message, setMessage] = useState(" ");
  const [retainReceiverCompanyIds, setRetainReceiverCompanyIds] = useState([]);
  const isPrivate = Form.useWatch("isPrivate", form);

  const questionCompanyOptions = useMemo(() => {
    const lenders = (deal.lendersAssigned || [])
      .filter((lender) => {
        const isNotSameCompany = lender.companyId !== myCompany._id;
        return isNotSameCompany;
      })
      .map((lender) => ({
        label: lender.companyName,
        value: lender.companyId,
      }));
    const options = [
      {
        label: "Select All Lenders",
        options: [all],
      },
      {
        label: "Lenders",
        options: lenders,
      },
    ];
    return {
      options,
      lenders,
    };
  }, [deal, whoami]);

  useEffect(() => {
    if (!data) {
      form.setFieldsValue(null);
      setMessage(" ");
      return;
    }
    const lastMessage = last(data.messages);
    const receiverCompanyIds = [];
    const retainCompanyIds = [];
    data.receiverCompanyIds.forEach((id) => {
      const found = questionCompanyOptions.lenders.find(
        (opt) => opt.value === id
      );
      if (found) receiverCompanyIds.push(id);
      else retainCompanyIds.push(id);
    });
    form.setFieldsValue({
      message: lastMessage.message,
      isPrivate: lastMessage.isPrivate,
      receiverCompanyIds,
    });
    setMessage(lastMessage.message);
    setRetainReceiverCompanyIds(retainCompanyIds);
  }, [data, questionCompanyOptions]);

  const resetForm = () => {
    form.resetFields();
    setMessage(" ");
  };

  const onFinish = (values) => {
    console.log("Success:", values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const getValueFromEventReceiverCompanyIdsQuestion = (value) => {
    if (value.includes(allLenders))
      return questionCompanyOptions.lenders.map((e) => e.value);
    return value;
  };

  const title =
    type === inboxModaTypes.editAnswer ? "Edit Answer" : "Edit Visibility";

  return (
    <Modal
      open={open}
      title={title}
      okText="Submit"
      cancelText="Cancel"
      onCancel={onCancel}
      afterClose={resetForm}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            const newValues = values.isPrivate
              ? {
                  ...values,
                  receiverCompanyIds: [
                    ...values.receiverCompanyIds,
                    ...retainReceiverCompanyIds,
                  ],
                }
              : values;
            onCreate(newValues);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form
        form={form}
        autoComplete="off"
        layout="vertical"
        style={{ border: 0 }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label=""
          name="isPrivate"
          initialValue={false}
          className="d-flex justify-content-end"
        >
          <div className="align-self-end d-flex align-items-center">
            <p className="font-14 font-weight-bold mr-2">Answer Privately</p>
            <Switch
              size="small"
              defaultChecked={false}
              checked={form.getFieldValue("isPrivate")}
              onChange={() => {
                form.setFieldValue(
                  "isPrivate",
                  !form.getFieldValue("isPrivate")
                );
              }}
            />
          </div>
        </Form.Item>

        {!!isPrivate && (
          <Form.Item
            label=""
            name="receiverCompanyIds"
            rules={[{ required: true, message: "'recipient' is required" }]}
            getValueFromEvent={getValueFromEventReceiverCompanyIdsQuestion}
          >
            <Select
              showSearch
              allowClear
              mode="multiple"
              placeholder="Recipient"
              style={{ borderRadius: 0 }}
              options={questionCompanyOptions.options}
            />
          </Form.Item>
        )}

        {type === inboxModaTypes.editAnswer && (
          <Form.Item label="" name="message" rules={[{ required: true }]}>
            <TextArea
              hideLabel
              height={190}
              placeholder="Type your message here..."
              input={{
                value: message,
                onChange: (newMessage) => {
                  setMessage(newMessage);
                  form.setFieldValue("message", newMessage);
                },
              }}
              init={{
                content_style: "body { font-size:14px }",
              }}
            />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export default EditAnswer;
