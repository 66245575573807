import React from "react";
import { connect } from "react-redux";
import { Form, Button } from "react-bootstrap";
import PopupPrompt from "components/ui/PopupPrompt";
import { createTermsheetLanguage, getTermsheetLanguages } from "redux/actions";
import { reduxFormWYSIWYG as TextArea } from "components/form/ReduxForm";
import LoadSavedClause from "./LoadSavedClause";
import SaveNewClause from "./SaveNewClause";
import SaveReplaceClause from "./SaveReplaceClause";

const tokens = [
  { text: "Sponsor", value: "{{Sponsor}}" },
  { text: "Borrower Entity", value: "{{BorrowerEntity}}" },
  { text: "Sponsor Address", value: "{{SponsorAddress}}" },
  { text: "Sponsor Address II", value: "{{SponsorAddressII}}" },
  { text: "Sponsor City", value: "{{SponsorCity}}" },
  { text: "Sponsor State", value: "{{SponsorState}}" },
  { text: "Sponsor Zip", value: "{{SponsorZip}}" },
  { text: "Key Principal(s) List", value: "{{KeyPrincipalList}}" },
  { text: "Key Principal (Signor)", value: "{{KeyPrincipalSignor}}" },
  { text: "Property Address", value: "{{PropertyAddress}}" },
  { text: "Property City", value: "{{PropertyCity}}" },
  { text: "Property State", value: "{{PropertyState}}" },
  { text: "Property Zip", value: "{{PropertyZip}}" },
  { text: "Property Land Size", value: "{{PropertyLandSize}}" },
  { text: "Property SF", value: "{{PropertySF}}" },
  { text: "Property Units", value: "{{PropertyUnits}}" },
  { text: "Property Type", value: "{{PropertyType}}" },
];

class ManageLanguage extends React.Component {
  state = {
    showPrompt: false,
    showSaveLangPrompt: false,
    showSaveReplace: false,
    languageTitle: "",
    selectedLang: {},
  };

  showPrompt = () => {
    getTermsheetLanguages(this.props.column.sectionName);
    this.setState({ showPrompt: true });
  };

  hidePrompt = () => this.setState({ showPrompt: false, selectedLang: {} });

  onConfirm = (selectedRow) => {
    this.props.onChange(selectedRow.clause);
    this.props.onChangeClause(selectedRow._id);
    this.hidePrompt();
  };

  showSaveLanguagePrompt = () => {
    this.setState({ showSaveLangPrompt: true, showSaveReplace: false });
  };

  showSaveReplace = () => {
    this.setState({ showSaveReplace: true, showSaveLangPrompt: false });
  };

  hideSaveLanguagePrompt = () =>
    this.setState({
      showSaveLangPrompt: false,
      showSaveReplace: false,
      languageTitle: "",
    });

  submitLanguage = () => {
    this.hideSaveLanguagePrompt();
    createTermsheetLanguage({
      languageTitle: this.state.languageTitle,
      languageDesc: this.props.column.langDesc,
      section: this.props.column.sectionName,
    });
  };

  handleSelectedLang = (selectedLang) => {
    this.setState({ selectedLang });
  };

  handleLanguageChange = (e) => {
    const { id, value } = e.target;
    if (id === "languageTitle") {
      this.setState({ languageTitle: value });
    } else {
      this.props.onChange(value);
    }
  };

  handleSaveClause = () => {
    const { column } = this.props;
    if (column.childClauseId) {
      this.showSaveReplace();
    } else {
      this.showSaveLanguagePrompt();
    }
  };

  init = {
    plugins: [
      "advlist autolink lists link image charmap print preview anchor",
      "searchreplace visualblocks code fullscreen",
      "insertdatetime media table paste code help wordcount noneditable",
    ],
    // The noneditable_regexp option transforms matched text into spans which is then
    // visually styled to look like tags.
    // https://www.tiny.cloud/docs/plugins/noneditable/
    noneditable_regexp: /\{\{[^}]+\}\}/g, // matches {{handlebar}},
    setup: (editor) => {
      // Register a custom toolbar menu button to insert tokens
      // https://www.tiny.cloud/docs/ui-components/typesoftoolbarbuttons/#menubutton
      editor.ui.registry.addMenuButton("mergefields", {
        text: "",
        tooltip: "Insert field",
        fetch: (callback) => {
          const items = tokens.map((token) => {
            return {
              type: "menuitem",
              text: token.text,
              onAction: () => {
                // Insert content at the location of the cursor.
                // https://www.tiny.cloud/docs/api/tinymce/tinymce.editor/#insertcontent
                editor.insertContent(token.value);
              },
            };
          });
          callback(items);
        },
      });
    },
  };

  render() {
    const { onChange, column } = this.props;
    const parentClauseId = column.clause?._id;

    return (
      <Form className="no-padding no-border">
        <TextArea
          height={160}
          className="form-control"
          name="languageDesc"
          type="text"
          placeholder="Type Language here..."
          input={{
            name: "languageDesc",
            value: column.langDesc || "<p></p>",
            onChange,
          }}
          toolbar="undo redo | h3 | bold italic underline removeformat | bullist numlist outdent indent | link unlink | mergefields"
          init={this.init}
        />
        <Button
          className="fake-link"
          variant="fake-link"
          onClick={this.showPrompt}
        >
          Load Clause
        </Button>{" "}
        &nbsp;
        <Button
          className="fake-link"
          variant="fake-link"
          onClick={this.handleSaveClause}
        >
          Save Clause
        </Button>
        <PopupPrompt
          promptTitle={`Load Saved Clause`}
          promptBody={
            <LoadSavedClause
              parentClauseId={parentClauseId}
              onCancel={this.hidePrompt}
              onSubmit={this.onConfirm}
            />
          }
          show={this.state.showPrompt}
          onHide={this.hidePrompt}
          onCancel={this.hidePrompt}
          onConfirm={this.onConfirm}
        />
        <PopupPrompt
          promptTitle={`Save as New Clause`}
          promptBody={
            <SaveNewClause
              column={column}
              selectedClause={column.clause}
              promptType={this.state.showPrompt}
              onCancel={this.hideSaveLanguagePrompt}
            />
          }
          show={this.state.showSaveLangPrompt}
          onHide={this.hideSaveLanguagePrompt}
          onCancel={this.hideSaveLanguagePrompt}
        />
        <PopupPrompt
          promptTitle={`Please choose to Save or Replace...`}
          promptBody={
            <SaveReplaceClause
              column={column}
              selectedClause={column.childClause}
              promptType={this.state.showPrompt}
              onCancel={this.hideSaveLanguagePrompt}
              onSaveNew={this.showSaveLanguagePrompt}
            />
          }
          show={this.state.showSaveReplace}
          onHide={this.hideSaveLanguagePrompt}
          onCancel={this.hideSaveLanguagePrompt}
        />
      </Form>
    );
  }
}
const mapStateToProps = (state) => ({
  languages: state.termsheet.languages,
  action: state.action,
});
export default connect(mapStateToProps)(ManageLanguage);
