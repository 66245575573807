import React from 'react';
import { Container } from 'react-bootstrap';
import StepWizard from 'react-step-wizard';
import Step1 from 'containers/deals/schedule/WizardSteps/Step1';
import Step2 from 'containers/deals/schedule/WizardSteps/Step2';

class DealSchedule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      canView: false,
      stepVar: {}
    }
    
    this.passOnVariable = this.passOnVariable.bind(this);
  }
  
  passOnVariable(data){
    this.setState({ stepVar: data });
  }

  render() {
    return(
      <Container className="form-wizard-wrapper no-border no-padding overflow-hidden" fluid>
        <StepWizard>
          <Step1 onSubmit={this.passOnVariable}/>
          <Step2 previousStepVars={this.state.stepVar}/>
        </StepWizard>
      </Container>
    )
  }
}

export default DealSchedule;