import React from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import SocialMediaButton from "components/ui/SocialMediaButton";

const LenderShortInfo = ({ company, primaryTeam }) => {
  const teamLead = primaryTeam?.teamMembers?.find(
    (teamMember) => teamMember.globalRole === "Team Leader"
  )?.userId;
  return (
    <>
      <h3>{company.companyName}</h3>
      <p className="bold">
        {teamLead && teamLead.firstName + " " + teamLead.lastName}
      </p>
      {teamLead?.linkedin && teamLead.linkedin !== " " && (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id={`tooltip-bottom`}>LinkedIn</Tooltip>}
        >
          <div className="mr-2 d-inline-block">
            <SocialMediaButton icon="linkedin" url={teamLead.linkedin} />
          </div>
        </OverlayTrigger>
      )}
      {teamLead?.email && teamLead.email && (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id={`tooltip-bottom`}>Email</Tooltip>}
        >
          <div className="mr-2 d-inline-block">
            <SocialMediaButton icon="email" data={"mailto:" + teamLead.email} />
          </div>
        </OverlayTrigger>
      )}
      {teamLead?.phoneMobile && teamLead.phoneMobile !== " " && (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id={`tooltip-bottom`}>Mobile Phone</Tooltip>}
        >
          <div className="mr-2 d-inline-block">
            <SocialMediaButton
              icon="mobile"
              data={"tel:" + teamLead.phoneMobile}
            />
          </div>
        </OverlayTrigger>
      )}
      {teamLead?.phoneLandline && teamLead.phoneLandline !== " " && (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id={`tooltip-bottom`}>Office Phone</Tooltip>}
        >
          <div className="mr-2 d-inline-block">
            <SocialMediaButton
              icon="office"
              data={"tel:" + teamLead.phoneLandline}
            />
          </div>
        </OverlayTrigger>
      )}
    </>
  );
};

export default LenderShortInfo;
