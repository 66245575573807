import React, { useMemo } from "react";
import orderBy from "lodash/orderBy";
import { Card, theme } from "antd";
import { useSelector } from "react-redux";
import { conversationType } from "../enums";
import InboxReplyItem from "./InboxReplyItem";

const InboxReplies = ({ isFollowUp, messages = [] }) => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const conversationDetails = useSelector(
    (state) => state.conversation.conversationDetails
  );

  const sortedMessages = useMemo(
    () => orderBy(messages, "updatedAt", "desc"),
    [messages]
  );

  if (sortedMessages.length === 0) return null;

  const label =
    conversationDetails.type === conversationType.Question
      ? "Answers"
      : "Messages";

  return (
    <section className="InboxReplies">
      <div style={{ margin: "16px 0" }}>
        <p className="font-16 font-weight-bold">
          {sortedMessages.length} {label}
        </p>
      </div>

      <Card
        style={{
          padding: 16,
          margin: 0,
          background: colorBgContainer,
        }}
        bodyStyle={{ padding: 0 }}
        className="d-flex flex-column"
      >
        {sortedMessages.map((message) => (
          <InboxReplyItem
            key={message._id}
            isFollowUp={isFollowUp}
            conversationDetails={conversationDetails}
            message={message}
          />
        ))}
      </Card>
    </section>
  );
};

export default InboxReplies;
