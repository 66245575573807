import React, { createRef } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Form, Container, Row, Col } from "react-bootstrap";
import { Field, reduxForm } from "redux-form";
import { required, email, noSpaces } from "utils/validation";
import { reduxFormInput, reduxFormSelect } from "components/form/ReduxForm";
import { signUp } from "redux/actions";
import Spacer from "components/layout/Spacer";
import Loader from "components/ui/Loader";
import TSLogo from "components/ui/TSLogo";
import withPageTitle from "components/hoc/withPageTitle";

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      formId: Date.now().toString(),
    };

    this.formRef = createRef();
    this.submitFn = this.submitFn.bind(this);
  }

  componentDidMount() {
    if (this.formRef.current) {
      this.formRef.current.id = this.state.formId;
    }
  }

  submitFn(data) {
    const updatedData = { ...data };
    this.setState({ email: updatedData.email });
    updatedData.type = updatedData.type.value || updatedData.type;
    signUp(updatedData);
  }

  render() {
    const { handleSubmit, action } = this.props;
    const { isFetching, isFetched } = action.REGISTER_USER;
    return (
      <>
        {isFetching && <Loader />}
        <header className="border-bottom">
          <Spacer size={23} />
          <h1 className="text-center">Register</h1>
          <Spacer size={23} />
        </header>
        <Container>
          <Spacer size={50} />
          <Form ref={this.formRef} className="auth-form">
            <Row className="justify-content-center">
              <Col className="d-flex justify-content-center" xs="6">
                <TSLogo blue title />
              </Col>
            </Row>
            {!isFetched ? (
              <>
                <Row>
                  <Col xs="12">
                    <Spacer size={50} />
                  </Col>
                  <Col xs="12" sm="6">
                    <Field
                      className="form-control"
                      component={reduxFormInput}
                      validate={[required, noSpaces]}
                      name="firstName"
                      type="text"
                      placeholder="Enter first name"
                      label="*First name"
                    />
                    <Spacer size={50} />
                  </Col>
                  <Col xs="12" sm="6">
                    <Field
                      className="form-control"
                      component={reduxFormInput}
                      validate={[required, noSpaces]}
                      name="lastName"
                      type="text"
                      placeholder="Enter last name"
                      label="*Last name"
                    />
                    <Spacer size={50} />
                  </Col>
                  <Col xs="6">
                    <Field
                      className="form-control"
                      component={reduxFormInput}
                      validate={[required, email, noSpaces]}
                      name="email"
                      placeholder="Enter email"
                      label="*Email"
                      normalize={(value) => value.toLowerCase()}
                    />
                    <Spacer size={50} />
                  </Col>
                  <Col xs="12" sm="6">
                    <Field
                      className="form-control"
                      component={reduxFormSelect}
                      validate={[required]}
                      name="type"
                      type="select"
                      options={[
                        { label: "Yes", value: "Lender" },
                        { label: "No", value: "Borrower" },
                      ]}
                      label="*Are you a lender?"
                    />
                    <Spacer size={50} />
                  </Col>
                  <Col className="text-center" xs="12">
                    <Link to="/login">Already have an account? Login now.</Link>
                    <Spacer size={15} />
                  </Col>
                </Row>
                <Spacer size={50} />
                <Row className="justify-content-center">
                  <Col className="text-center" xs="12" sm="6">
                    <Button
                      onClick={handleSubmit(this.submitFn)}
                      variant="primary"
                    >
                      Register
                    </Button>
                  </Col>
                </Row>
              </>
            ) : (
              <div className="text-center">
                <Spacer size={50} />
                <p className="bold">Thank you for registering !</p>
                <p>
                  An email has been sent to{" "}
                  <Button className="fake-link" variant="fake-link">
                    {this.state.email}.
                  </Button>
                </p>
                <p>Please confirm your email to continue.</p>
              </div>
            )}
          </Form>
          <Spacer size={50} />
        </Container>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    action: state.action,
    initialValues: {
      type: { label: "No", value: "Borrower" },
    },
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "register",
    enableReinitialize: true,
  })(withPageTitle(Register, "Register"))
);
