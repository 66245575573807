import React, { useCallback, useRef } from "react";
import { connect } from "react-redux";
import {
  Dropdown,
  Button,
  OverlayTrigger,
  Tooltip,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFolderOpen,
  faFolder,
  faLockOpen,
  faLock,
  faEllipsisV,
  faPlus,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import FolderFileItem from "./FolderFileItem";
import { ALLOWED_FILE_EXTS } from "./constants";

const FolderItem = (props) => {
  const {
    whoami,
    userId,
    _id,
    isOpen,
    name,
    files,
    onCategorySelect,
    parentPermission,
    permission,
    handleUploadFile,
    fetchFolder,
    removeFolder,
    showRenaming,
    fromComms,
    addSelectedFile,
    removeSelectedFile,
    getSelectedFiles,
    canManage,
  } = props;

  const fileInput = useRef(null);

  const checkPermission = useCallback(() => {
    if (permission && permission.length > 0) {
      if (permission.includes(userId)) return true;
      else return false;
    }
    if (parentPermission && parentPermission.length > 0) {
      if (parentPermission.includes(userId)) return true;
      else return false;
    }
    return true;
  }, [userId, parentPermission, permission]);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Button
      ref={ref}
      className="p-1"
      variant="folder"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </Button>
  ));

  const CustomMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) => child.props.children
            )}
          </ul>
        </div>
      );
    }
  );

  return (
    <Container fluid>
      <input
        className="drag-drop-file-upload"
        ref={fileInput}
        type="file"
        id={_id}
        onChange={(event) => handleUploadFile(event, _id)}
        accept={ALLOWED_FILE_EXTS.join(",")}
        multiple
      />
      <Row className="file-item-row">
        <Col xs={fromComms ? 8 : undefined}>
          {isOpen ? (
            <FontAwesomeIcon icon={faFolderOpen} />
          ) : (
              <FontAwesomeIcon icon={faFolder} />
            )}
          <span
            className="folder-file-name font-14 ml-3 hover-cursor"
            onClick={() => onCategorySelect(_id)}
            onKeyDown={(e) =>
              e.keyCode === 13 ? document.activeElement.click() : null
            }
            tabIndex="0"
          >
            {name}
          </span>
        </Col>
        <Col
          className="d-flex align-items-center justify-content-center"
          xs="2"
        >
          {files.length}
        </Col>
        {whoami?.role !== "Lender" && (
          <Col
            className="d-flex align-items-center justify-content-center"
            xs="2"
          >
            {checkPermission() ? (
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id={`tooltip-bottom`}>Public</Tooltip>}
              >
                <FontAwesomeIcon icon={faLockOpen} />
              </OverlayTrigger>
            ) : (
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip id={`tooltip-bottom`}>Restricted</Tooltip>}
                >
                  <FontAwesomeIcon icon={faLock} />
                </OverlayTrigger>
              )}
          </Col>
        )}
        <Col className="d-flex justify-content-end align-items-center" xs="3">
          {!fromComms && checkPermission() && (
            <>
              {canManage && (
                <Button
                  className="p-1 d-flex justify-content-center align-items-center"
                  variant="folder"
                  onClick={() => fileInput.current.click()}
                >
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip id={`tooltip-bottom`}>Add Files</Tooltip>}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </OverlayTrigger>
                </Button>
              )}
              <span className="mr-2"></span>
              <Button
                className="p-1 d-flex justify-content-center align-items-center"
                variant="folder"
                onClick={() => fetchFolder(_id, name)}
              >
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip id={`tooltip-bottom`}>Download</Tooltip>}
                >
                  <FontAwesomeIcon icon={faDownload} />
                </OverlayTrigger>
              </Button>
              <span className="mr-2"></span>
              <Dropdown className="d-inline-block folder-file-dropdown">
                <Dropdown.Toggle as={CustomToggle} id="toggle-dropdown">
                  <h4 className="view-as">
                    <FontAwesomeIcon icon={faEllipsisV} />
                  </h4>
                </Dropdown.Toggle>

                <Dropdown.Menu as={CustomMenu}>
                  <Dropdown.Item onClick={() => onCategorySelect(_id, true)}>
                    Open
                  </Dropdown.Item>
                  {canManage && (
                    <Dropdown.Item onClick={() => fileInput.current.click()}>
                      Add Files
                    </Dropdown.Item>
                  )}
                  <Dropdown.Item onClick={() => fetchFolder(_id, name)}>
                    Download
                  </Dropdown.Item>
                  {canManage && (
                    <>
                      <Dropdown.Item onClick={() => showRenaming(_id)}>
                        Edit
                      </Dropdown.Item>
                      {/* <Dropdown.Item
                        onClick={() => {
                          showSecuritySharing(_id);
                        }}
                      >
                        Security & Sharing
                      </Dropdown.Item> */}
                      <Dropdown.Divider> </Dropdown.Divider>
                      <Dropdown.Item onClick={() => removeFolder(_id)}>
                        Delete
                      </Dropdown.Item>
                    </>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </>
          )}
        </Col>
      </Row>
      {files.length > 0 &&
        isOpen &&
        files.map((file) => (
          <FolderFileItem
            key={file._id}
            userId={userId}
            fetchFile={props.fetchFile}
            removeFile={props.removeFile}
            parentPermission={
              permission.lenght > 0 ? permission : parentPermission
            }
            showSecuritySharing={props.showSecuritySharing}
            showRenaming={showRenaming}
            fromComms={fromComms}
            addSelectedFile={addSelectedFile}
            removeSelectedFile={removeSelectedFile}
            getSelectedFiles={getSelectedFiles}
            canManage={canManage}
            fileKey={file.key}
            {...file}
          />
        ))}
    </Container>
  );
};

function mapStateToProps(state) {
  return {
    whoami: state.auth.whoami,
  };
}

export default connect(mapStateToProps)(FolderItem);
