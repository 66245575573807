import React from "react";
import { Col, Button, Row, Form } from "react-bootstrap";
import { Field, reduxForm } from "redux-form";
import {
  reduxFormInput,
  reduxFormSelect,
  reduxFormTextarea,
} from "components/form/ReduxForm";
import { required } from "utils/validation";
import Spacer from "components/layout/Spacer";

class EditFile extends React.Component {
  constructor(props) {
    super(props);
    this.rename = this.rename.bind(this);

    this.state = {
      isPublic: true,
      showDropdown: false,
      groupOptions: [],
      folderOptions: [],
      selectedLendersAndGroups: [],
      lenderOptions: this.getLenderOptions(),
      categoryOptions: this.getCategoryOptions(),
    };
  }

  componentDidMount() {
    const { change, node, categories, getCategoryFolders } = this.props;
    const { lenderOptions } = this.state;
    const category = categories.find((item) =>
      node?.ancestors?.includes(item?._id)
    );
    const categoryItem = { label: category?.name, value: category?._id };
    change("category", categoryItem);

    const folderOptions = this.getFolderOptions(categoryItem.value);
    const folders = getCategoryFolders(categoryItem.value);
    const folder = folders.find((item) => node?.ancestors?.includes(item?._id));
    const folderItem = folder
      ? { label: folder?.name, value: folder?._id }
      : null;
    const lendersPermissionIds = node.lendersPermission.map(
      (lender) => lender._id
    );

    const isPublic = node.isPublic;
    const selectedLendersAndGroups = lenderOptions.filter((option) =>
      lendersPermissionIds.includes(option.value)
    );

    change("folder", folderItem);
    this.setState({
      folderOptions,
      selectedLendersAndGroups,
      isPublic,
    });
  }

  getCategoryOptions = () => {
    const { categories } = this.props;
    return categories?.map((category) => ({
      label: category.name,
      value: category._id,
    }));
  };

  getLenderOptions() {
    const { deal } = this.props;
    return (
      deal?.lendersAssigned?.map((e) => ({
        group: false,
        value: e.companyId,
        label: e.companyName,
        teamId: e.teamId,
      })) || []
    );
  }

  getFolderOptions = (categoryId) => {
    const { getCategoryFolders } = this.props;
    const folders = getCategoryFolders(categoryId);
    return folders.map((folder) => ({ label: folder.name, value: folder._id }));
  };

  rename(data) {
    const { onSubmit, id, node } = this.props;
    const payload = { ...data };
    const [root] = node.ancestors;

    if (payload.folder && payload.folder?.value !== node.parent) {
      payload.parent = payload.folder?.value;
      payload.ancestors = [root].concat([
        payload.category?.value,
        payload.folder?.value,
      ]);
    } else if (!payload.folder && payload.category?.value !== node.parent) {
      payload.parent = payload.category?.value;
      payload.ancestors = [root].concat([payload.category?.value]);
    }

    delete payload.category;
    delete payload.folder;

    onSubmit(id, payload);
  }

  handleChange = (newInput, input, prevInput) => {
    if (newInput?.value !== prevInput?.value) {
      const { change } = this.props;
      const folderOptions = this.getFolderOptions(newInput?.value);

      change("folder", null);
      this.setState({ folderOptions });
    }
  };

  handlePublicToggle = (isPublic) => {
    this.setState({ isPublic });
  };

  handleSelectLendersAndGroups = (selectedLendersAndGroups) => {
    this.setState({ selectedLendersAndGroups });
  };

  render() {
    const { handleSubmit } = this.props;

    return (
      <Form
        className="full-width no-border no-padding"
        onSubmit={handleSubmit(this.rename)}
      >
        <Row>
          <Col xs="12">
            <Field
              className="form-control"
              component={reduxFormSelect}
              name="category"
              type="select"
              options={this.state.categoryOptions}
              placeholder="Select..."
              label="Belongs to Category"
              onChange={this.handleChange}
            />
            <Spacer size={20} />
          </Col>
          <Col xs="12">
            <Field
              isClearable
              className="form-control"
              component={reduxFormSelect}
              name="folder"
              type="select"
              options={this.state.folderOptions}
              placeholder="Select..."
              label="Belongs to Folder"
            />
            <Spacer size={20} />
          </Col>
          <Col xs="12">
            <Field
              className="form-control"
              component={reduxFormInput}
              validate={[required]}
              name="name"
              type="text"
              placeholder="Enter file name..."
              label="File Name"
            />
            <Spacer size={20} />
          </Col>
          <Col xs="12">
            <Field
              className="form-control"
              component={reduxFormInput}
              validate={[required]}
              name="displayName"
              type="text"
              placeholder="Enter display name..."
              label="Display Name"
            />
            <Spacer size={20} />
          </Col>
          <Col xs="12">
            <Field
              className="form-control"
              component={reduxFormTextarea}
              validate={[required]}
              name="description"
              type="text"
              placeholder="Enter file description..."
              label="File Description"
              rows={3}
            />
            <Spacer size={20} />
          </Col>
          <Col className="text-right" xs="12">
            <Button type="submit" variant="primary">
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default reduxForm({
  form: "EditFile",
  enableReinitialize: true,
})(EditFile);
