import { actionTypes } from "redux/actions";
import { reducerCreator, multipleReducers } from "./meta";

export const team = multipleReducers({
  initializers: {
    initialState: {
      teams: [],
      team: {},
      lastTeamDefaultUpdated: null,
      totalTeams: 0,
    },
  },
  list: [
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.GET_TEAMS_BY_COMPANY_ID,
        successFn: (action) => ({
          teams: action.data.body.rows,
          totalTeams: action.data.body.count,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.GET_TEAM_BY_ID,
        successFn: (action) => ({
          team: action.data.body,
          lastTeamDefaultUpdated: null,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.UPDATE_TEAM_DEFAULT,
        successFn: (action) => ({
          lastTeamDefaultUpdated: action.data.body,
        }),
      },
    },
  ],
});
