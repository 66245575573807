import React, { useMemo, useEffect } from "react";
import { connect } from "react-redux";
import pick from "lodash/pick";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row } from "react-bootstrap";
import { getClauses } from "redux/actions";
import { reduxFormInput as Input } from "components/form/ReduxForm";
import Spacer from "components/layout/Spacer";
import { Droppable } from "react-beautiful-dnd";
import { colors } from "containers/quotes/constants";
import DraggablePortal from "components/DraggablePortal";

const filterList = [
  {
    value: "previously used",
    label: "previously used",
  },
  {
    value: "saved language availability",
    label: "saved language availability",
  },
  {
    value: "included in default term sheet",
    label: "included in default term sheet",
  },
];

const getItemStyle = (isDragging, draggableStyle) => {
  return {
    background: isDragging ? colors.blue : "",
    border: isDragging ? `1px solid ${colors.white}` : "",
    height: isDragging ? "50px" : "",
    color: colors.white,
    ...draggableStyle,
  };
};

const SidebarSelectSections = ({
  categories,
  clauses,
  lastClauseCreated,
  columns,
}) => {
  const [sections, setSections] = React.useState([]);
  const [searchKey, setSearchKey] = React.useState("");
  const searchResults = React.createRef(null);

  const allSections = useMemo(() => {
    return categories.map((category) => {
      const options = clauses
        .filter((clause) => clause.category._id === category._id)
        .map((clause) => ({
          title: clause.displayName || clause.clauseName,
          clause,
        }));
      return {
        title: category.categoryName,
        category,
        options,
      };
    });
  }, [clauses]);

  useEffect(() => {
    if (lastClauseCreated) {
      getClauses({
        limit: 500,
        order: "ASC",
        orderColumn: "clauseName",
        parent: true,
      });
    }
  }, [lastClauseCreated]);

  useEffect(() => {
    setSections(allSections);
  }, [allSections]);

  const addedSectionNames = React.useMemo(
    () => columns.map((col) => col.sectionName),
    [columns]
  );

  const unSelectedSections = React.useMemo(() => {
    return sections.map((section) => {
      const newOptions = section.options.filter(
        (option) => !addedSectionNames.includes(option.title)
      );
      return {
        ...section,
        options: newOptions,
      };
    });
  }, [sections, addedSectionNames]);

  const sectionsWithOptions = React.useMemo(
    () => unSelectedSections.filter((section) => section.options.length),
    [unSelectedSections]
  );

  const handleSearch = (e) => {
    const text = e.target.value;
    setSearchKey(text);
    setSections(searchSections(text, allSections));
  };

  const handleSearchInputFocus = () => {
    searchResults.current.style.display = "block";
  };

  const handleSearchInputBlur = (e) => {
    if (!e.relatedTarget) {
      searchResults.current.style.display = "none";
    }
  };

  const handleSelectFilterOption = (option) => {
    searchResults.current.style.display = "none";
    setSearchKey(option.value);
  };

  return (
    <>
      <Col xs={12}>
        <Input
          name="searchKey"
          type="text"
          placeholder="Search..."
          meta={{}}
          hideLabel
          input={{
            value: searchKey,
            onChange: handleSearch,
            className: "SearchInput search-input",
            onFocus: handleSearchInputFocus,
            onBlur: handleSearchInputBlur,
          }}
        />
        <div
          ref={searchResults}
          className="search-results-wrapper"
          style={{ display: "none" }}
        >
          <div className="search-results py-1 px-2">
            <p className="pb-1 px-1">Filter</p>
            {filterList.map((option) => {
              return (
                <p
                  key={option.value}
                  tabIndex="1"
                  className="cursor-pointer px-1"
                  onClick={() => handleSelectFilterOption(option)}
                >
                  <span>{option.label}</span>
                </p>
              );
            })}
          </div>
        </div>
        <Spacer size={10} />
      </Col>
      <Droppable
        isDropDisabled
        droppableId="droppableId-sidebar"
        type="CLAUSES"
      >
        {(provided) => (
          <Col
            xs={12}
            className="SidebarSelectSections"
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {sectionsWithOptions.map((section) => (
              <Row
                key={section.category?._id}
                className="rowGroup justify-content-end"
              >
                <Col xs={12} className="title py-2">
                  {section.title}
                </Col>
                <Col xs={12}>
                  {section.options.map((option, index) => {
                    const draggableId = btoa(
                      JSON.stringify({
                        section: pick(section, ["title"]),
                        option: pick(option, [
                          "title",
                          "sectionType",
                          "clause",
                        ]),
                      })
                    );
                    return (
                      <DraggablePortal
                        key={option.title}
                        index={index}
                        draggableId={draggableId}
                        option={option}
                        section={section}
                        Component={RowItem}
                      />
                    );
                  })}
                </Col>
              </Row>
            ))}
          </Col>
        )}
      </Droppable>
    </>
  );
};

const RowItem = ({ provided, snapshot, option }) => (
  <Row
    className="rowItem py-1 pl-3"
    ref={provided.innerRef}
    {...provided.draggableProps}
    {...provided.dragHandleProps}
    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
  >
    <Col xs={10} className="label label-ellipsis" title={option.title}>
      {option.title}{" "}
      {option?.clause?.children?.length > 1 && (
        <span>({option?.clause?.children?.length})</span>
      )}
    </Col>
    <Col xs={1}>
      <FontAwesomeIcon
        className="icon"
        icon={faBars}
        size="sm"
        style={{ cursor: "grab" }}
      />
    </Col>
  </Row>
);

const searchSections = (key, allSections) => {
  key = key.trim();
  if (!key) {
    return allSections;
  }
  const foundSections = [];
  for (let i = 0; i < allSections.length; i++) {
    const { title, options } = allSections[i];
    if (title.toLowerCase().includes(key.toLowerCase())) {
      foundSections.push(allSections[i]);
    } else {
      const newSection = { ...allSections[i], options: [] };
      for (let j = 0; j < options.length; j++) {
        if (options[j].title.toLowerCase().includes(key.toLowerCase())) {
          newSection.options.push(options[j]);
        }
      }
      if (newSection.options.length) {
        foundSections.push(newSection);
      }
    }
  }
  return foundSections;
};

const mapStateToProps = (state) => ({
  columns: state.termsheet.termsheet.columns || [],
  clauses: state.clause.clauses,
  lastClauseCreated: state.clause.lastClauseCreated,
  categories: state.category.categories,
});

export default connect(mapStateToProps)(SidebarSelectSections);
