import queryString from "query-string";
import { dispatch } from "config/store";
import { actionTypes } from "./actionTypes";

export const createConversationMessage = (data) => {
  const action = {
    type: actionTypes.CREATE_CONVERSATION_MESSAGE,
    config: {
      method: "post",
      url: `/conversations/message/create`,
      data,
    },
  };

  dispatch(action);
};

export const createConversationQuestion = (data) => {
  const action = {
    type: actionTypes.CREATE_CONVERSATION_QUESTION,
    config: {
      method: "post",
      url: `/conversations/question/create`,
      data,
    },
  };

  dispatch(action);
};

export const replyToMessage = (params, data) => {
  const action = {
    type: actionTypes.REPLY_TO_MESSAGE,
    config: {
      method: "post",
      url: `/conversations/${params.conversationId}/message/${params.messageId}/reply`,
      data,
    },
  };

  dispatch(action);
};

export const answerToQuestion = (params, data) => {
  const action = {
    type: actionTypes.ANSWER_TO_QUESTION,
    config: {
      method: "post",
      url: `/conversations/${params.conversationId}/question/answer`,
      data,
    },
  };

  dispatch(action);
};

export const bulkArchiveConversation = (data) => {
  const action = {
    type: actionTypes.BULK_ARCHIVE_CONVERSATION,
    config: {
      method: "post",
      url: `/conversations/bulk/archive`,
      data,
    },
  };

  dispatch(action);
};

export const bulkDeleteConversation = (data) => {
  const action = {
    type: actionTypes.BULK_DELETE_CONVERSATION,
    config: {
      method: "post",
      url: `/conversations/bulk/delete`,
      data,
    },
  };

  dispatch(action);
};

export const getConversations = ({ dealId, ...query }) => {
  const action = {
    type: actionTypes.GET_CONVERSATIONS,
    config: {
      method: "get",
      url: `/conversations?${queryString.stringify({ dealId: query.dealId })}`,
    },
  };

  dispatch(action);
};

export const getConversationById = (conversationId) => {
  const action = {
    type: actionTypes.GET_CONVERSATION_BY_ID,
    config: {
      method: "get",
      url: `/conversations/${conversationId}?populate=true`,
    },
  };

  dispatch(action);
};

export const getConversationByIdOptimisticUpdate = (conversationItem) => {
  const action = {
    type: actionTypes.GET_CONVERSATION_BY_ID_OPTIMISTIC_UPDATE,
    data: conversationItem,
  };

  dispatch(action);
};

export const getConversationAllInbox = ({ dealId, ...query }) => {
  const action = {
    type: actionTypes.GET_CONVERSATION_ALL_INBOX,
    config: {
      method: "get",
      url: `/conversations/all/inbox?${queryString.stringify({
        dealId,
        ...query,
      })}`,
    },
  };

  dispatch(action);
};

export const getConversationMessageInbox = ({ dealId, ...query }) => {
  const action = {
    type: actionTypes.GET_CONVERSATION_MESSAGE_INBOX,
    config: {
      method: "get",
      url: `/conversations/messages/inbox?${queryString.stringify({
        dealId,
        ...query,
      })}`,
    },
  };

  dispatch(action);
};

export const getConversationQuestionInbox = ({ dealId, ...query }) => {
  const action = {
    type: actionTypes.GET_CONVERSATION_QUESTION_INBOX,
    config: {
      method: "get",
      url: `/conversations/questions/inbox?${queryString.stringify({
        dealId,
        ...query,
      })}`,
    },
  };

  dispatch(action);
};

export const getConversationAllArchived = ({ dealId, ...query }) => {
  const action = {
    type: actionTypes.GET_CONVERSATION_ALL_INBOX,
    config: {
      method: "get",
      url: `/conversations/all/archived?${queryString.stringify({
        dealId,
        ...query,
      })}`,
    },
  };

  dispatch(action);
};

export const getConversationMessagesArchived = ({ dealId, ...query }) => {
  const action = {
    type: actionTypes.GET_CONVERSATION_MESSAGE_INBOX,
    config: {
      method: "get",
      url: `/conversations/messages/archived?${queryString.stringify({
        dealId,
        ...query,
      })}`,
    },
  };

  dispatch(action);
};

export const getConversationQuestionsArchived = ({ dealId, ...query }) => {
  const action = {
    type: actionTypes.GET_CONVERSATION_QUESTION_INBOX,
    config: {
      method: "get",
      url: `/conversations/questions/archived?${queryString.stringify({
        dealId,
        ...query,
      })}`,
    },
  };

  dispatch(action);
};

export const getConversationAllDraft = ({ dealId, ...query }) => {
  const action = {
    type: actionTypes.GET_CONVERSATION_ALL_INBOX,
    config: {
      method: "get",
      url: `/conversations/all/draft?${queryString.stringify({
        dealId,
        ...query,
      })}`,
    },
  };

  dispatch(action);
};

export const getConversationMessagesDraft = ({ dealId, ...query }) => {
  const action = {
    type: actionTypes.GET_CONVERSATION_MESSAGE_INBOX,
    config: {
      method: "get",
      url: `/conversations/messages/draft?${queryString.stringify({
        dealId,
        ...query,
      })}`,
    },
  };

  dispatch(action);
};

export const getConversationQuestionsDraft = ({ dealId, ...query }) => {
  const action = {
    type: actionTypes.GET_CONVERSATION_QUESTION_INBOX,
    config: {
      method: "get",
      url: `/conversations/questions/draft?${queryString.stringify({
        dealId,
        ...query,
      })}`,
    },
  };

  dispatch(action);
};

export const getConversationAllSent = ({ dealId, ...query }) => {
  const action = {
    type: actionTypes.GET_CONVERSATION_ALL_INBOX,
    config: {
      method: "get",
      url: `/conversations/all/sent?${queryString.stringify({
        dealId,
        ...query,
      })}`,
    },
  };

  dispatch(action);
};

export const getConversationMessagesSent = ({ dealId, ...query }) => {
  const action = {
    type: actionTypes.GET_CONVERSATION_MESSAGE_INBOX,
    config: {
      method: "get",
      url: `/conversations/messages/sent?${queryString.stringify({
        dealId,
        ...query,
      })}`,
    },
  };

  dispatch(action);
};

export const getConversationQuestionsSent = ({ dealId, ...query }) => {
  const action = {
    type: actionTypes.GET_CONVERSATION_QUESTION_INBOX,
    config: {
      method: "get",
      url: `/conversations/questions/sent?${queryString.stringify({
        dealId,
        ...query,
      })}`,
    },
  };

  dispatch(action);
};

export const getConversationAllDeleted = ({ dealId, ...query }) => {
  const action = {
    type: actionTypes.GET_CONVERSATION_ALL_INBOX,
    config: {
      method: "get",
      url: `/conversations/all/deleted?${queryString.stringify({
        dealId,
        ...query,
      })}`,
    },
  };

  dispatch(action);
};

export const getConversationMessagesDeleted = ({ dealId, ...query }) => {
  const action = {
    type: actionTypes.GET_CONVERSATION_MESSAGE_INBOX,
    config: {
      method: "get",
      url: `/conversations/messages/deleted?${queryString.stringify({
        dealId,
        ...query,
      })}`,
    },
  };

  dispatch(action);
};

export const getConversationQuestionsDeleted = ({ dealId, ...query }) => {
  const action = {
    type: actionTypes.GET_CONVERSATION_QUESTION_INBOX,
    config: {
      method: "get",
      url: `/conversations/questions/deleted?${queryString.stringify({
        dealId,
        ...query,
      })}`,
    },
  };

  dispatch(action);
};

export const getConversationAllByLabels = ({ dealId, labels, ...query }) => {
  const action = {
    type: actionTypes.GET_CONVERSATION_ALL_INBOX,
    config: {
      method: "get",
      url: `/conversations/all/labels?${queryString.stringify({
        dealId,
        labels,
        ...query,
      })}`,
    },
  };

  dispatch(action);
};

export const getConversationMessagesByLabels = ({
  dealId,
  labels,
  ...query
}) => {
  const action = {
    type: actionTypes.GET_CONVERSATION_MESSAGE_INBOX,
    config: {
      method: "get",
      url: `/conversations/messages/labels?${queryString.stringify({
        dealId,
        labels,
        ...query,
      })}`,
    },
  };

  dispatch(action);
};

export const getConversationQuestionsByLabels = ({
  dealId,
  labels,
  ...query
}) => {
  const action = {
    type: actionTypes.GET_CONVERSATION_QUESTION_INBOX,
    config: {
      method: "get",
      url: `/conversations/questions/labels?${queryString.stringify({
        dealId,
        labels,
        ...query,
      })}`,
    },
  };

  dispatch(action);
};

export const getConversationsByLabels = ({
  dealId,
  type,
  labels,
  ...query
}) => {
  const action = {
    type: actionTypes.GET_CONVERSATION_MESSAGE_INBOX,
    config: {
      method: "get",
      url: `/conversations/${type}/labels?${queryString.stringify({
        dealId,
        labels,
        ...query,
      })}`,
    },
  };

  dispatch(action);
};

export const updateConversation = (conversationId, data) => {
  const action = {
    type: actionTypes.UPDATE_CONVERSATION,
    config: {
      method: "put",
      url: `/conversations/${conversationId}`,
      data,
    },
  };

  dispatch(action);
};

export const deleteConversation = (conversationId) => {
  const action = {
    type: actionTypes.DELETE_CONVERSATION,
    config: {
      method: "put",
      url: `/conversations/${conversationId}/delete`,
    },
  };

  dispatch(action);
};

export const archiveConversation = (conversationId) => {
  const action = {
    type: actionTypes.ARCHIVE_CONVERSATION,
    config: {
      method: "put",
      url: `/conversations/${conversationId}/archive`,
    },
  };

  dispatch(action);
};

export const draftConversation = (conversationId) => {
  const action = {
    type: actionTypes.DRAFT_CONVERSATION,
    config: {
      method: "put",
      url: `/conversations/${conversationId}/draft`,
    },
  };

  dispatch(action);
};

export const readConversation = (conversationId) => {
  const action = {
    type: actionTypes.READ_CONVERSATION,
    config: {
      method: "put",
      url: `/conversations/${conversationId}/read`,
    },
  };

  dispatch(action);
};

export const unReadConversation = (conversationId) => {
  const action = {
    type: actionTypes.UNREAD_CONVERSATION,
    config: {
      method: "put",
      url: `/conversations/${conversationId}/unread`,
    },
  };

  dispatch(action);
};

export const assignConversationLabels = (conversationId) => {
  const action = {
    type: actionTypes.ASSIGN_CONVERSATION_LABELS,
    config: {
      method: "put",
      url: `/conversations/${conversationId}/assign/labels`,
    },
  };

  dispatch(action);
};

export const clearConversationDetails = () => {
  const action = {
    type: actionTypes.CLEAR_CONVERSATION_DETAILS,
  };

  dispatch(action);
};
