import React from "react";
import Select, { components } from "react-select";
import { Form } from "react-bootstrap";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const allOption = {
  label: "Select all",
  value: "*",
};

const Option = (props) => {
  return (
    <components.Option {...props}>
      <Form.Check
        type="checkbox"
        disabled={props.isDisabled}
        label={props.label}
        checked={props.isSelected}
        onChange={() => null}
      />
    </components.Option>
  );
};

const ValueContainer = ({ children, ...props }) => {
  const currentValues = props.getValue();
  let toBeRendered = children;

  if (props.selectProps.menuIsOpen) {
    toBeRendered = [children[1]];
  } else if (currentValues.length) {
    const count = currentValues.filter((e) => e.value !== allOption.value)
      .length;
    toBeRendered = [`${props.label}: ${count}`, children[1]];
  }

  return (
    <components.ValueContainer {...props}>
      {toBeRendered}
    </components.ValueContainer>
  );
};

const Control = (props) => (
  <div className="control-container">
    <components.Control {...props} />
    <div className="horizontal-separator" />
  </div>
);

const DropdownIndicator = ({ innerRef, innerProps }) => (
  <span ref={innerRef} {...innerProps} className="icon-container">
    <FontAwesomeIcon icon={faSearch} />
  </span>
);

const MultiDropdown = (props) => {
  return (
    <Select
      {...props}
      isMulti
      allowSelectAll
      isClearable={false}
      closeMenuOnSelect={false}
      className="react-select-container_inbox-messenger"
      classNamePrefix="react-select"
      value={props.value}
      options={props.options}
      components={{
        Control,
        ValueContainer,
        Option,
        DropdownIndicator,
      }}
    />
  );
};

MultiDropdown.defaultProps = {
  allOption,
};

export default MultiDropdown;
