import React, { Component } from "react";
import {
  getQuotes,
  createQuote,
  deleteQuote,
  updateQuote,
  getClauses,
  getTermsheet,
  clearTermsheet,
  getCategories,
  updateTermsheet,
  storeSections,
} from "redux/actions";
import { connect } from "react-redux";
import { Row, Col, Container } from "react-bootstrap";
import ViewQuote from "containers/quotes/ViewQuote";
import CreateQuote from "containers/quotes/CreateQuote";
import Loader from "components/ui/Loader";
import quotesConfig, {
  quoteIndexes,
  calculateRate,
} from "containers/quotes/quotesConfig";
import ViewQuoteCard from "containers/quotes/ViewQuoteCard";
import Socket from "utils/Socket";
import queryString from "query-string";
import SplitPane from "react-split-pane";
import BorrowerView from "containers/quotes/BorrowerView";
import QuoteLabels from "containers/quotes/QuoteLabels";
import { sortColumnsByPrimary } from "utils/termsheetUtils";

const emptyQuoteState = {
  loanAmount: "",
  term: "",
  amortization: "",
  IOPeriod: "",
  rate: "",
  index1: "",
  indexTerm1: "",
  index2: "",
  indexTerm2: "",
  indexRate1: "",
  indexRate2: "",
  indexRate: "",
  spread: "",
  floorOptionValue: "",
  floorRate: "",
  quotedRate: "",
};
const defaultQuoteState = {
  ...emptyQuoteState,
  isRateOrSpread: "Spread over Index",
  indexOption: "Equal to Chosen Index",
  floorOption: "No Floor",
  termMetric: "years",
  amortizationMetric: "years",
  IOPeriodMetric: "months",
};
class TermsheetCreateEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstQuote: defaultQuoteState,
      newQuote: defaultQuoteState,
      matrices: this.getMatrices(),
    };
  }

  componentDidMount() {
    this.fetchQuotes();
    this.toggleSideBar(true);

    getClauses({
      limit: 500,
      order: "ASC",
      orderColumn: "clauseName",
      parent: true,
    });
    getCategories({
      order: "ASC",
      orderColumn: "order",
      limit: 500,
    });

    const { params } = this.props.match;
    const search = queryString.parse(this.props.location.search);
    const termsheetId = params?.termsheetId || search?.termsheetId;

    if (termsheetId) {
      getTermsheet(termsheetId);
    } else {
      clearTermsheet();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.search !== this.props.location.search) {
      const search = queryString.parse(this.props.location.search);
      if (search?.termsheetId) {
        getTermsheet(search?.termsheetId);
      }
    }

    if (
      prevProps.quotes.length !== this.props.quotes.length ||
      prevProps.quoteMatrices.length !== this.props.quoteMatrices.length
    ) {
      this.setFirstQuote();
    }

    if (
      prevState.newQuote.rate !== this.state.newQuote.rate ||
      prevState.newQuote.floorRate !== this.state.newQuote.floorRate
    ) {
      this.setState((oldState) => ({
        newQuote: {
          ...oldState.newQuote,
          quotedRate: Math.max(
            this.state.newQuote.rate,
            this.state.newQuote.floorRate
          ),
        },
      }));
    }

    if (this.props.quoteMatrices !== prevProps.quoteMatrices) {
      this.setState({ matrices: this.getMatrices() });
    }
  }

  componentWillUnmount() {
    Socket.unsubscribe(this.subscriptionId);
    this.toggleSideBar(false);
  }

  getMatrices = () => {
    return [...quotesConfig, ...this.props.quoteMatrices];
  };

  toggleSideBar(show) {
    const resizerEl = document.querySelector(".SideBar .Resizer");
    const pane1El = document.querySelector(".SideBar .Pane1");
    const pane2El = document.querySelector(".SideBar .Pane2");
    const addOrRemove = show ? "add" : "remove";
    if (resizerEl) {
      resizerEl.classList[addOrRemove]("d-flex");
    }
    if (pane2El) {
      pane2El.classList[addOrRemove]("d-flex");
    }
    if (pane1El) {
      pane1El.style.height = show ? "220px" : "auto";
    }
  }

  fetchQuotes = () => {
    const { dealId, termsheetId } = this.props.match.params;
    if (dealId) {
      getQuotes({
        dealId,
        termsheetId,
        status: "Active",
      });
    }
  };

  getUpdatedCoumns = () => {
    return this.props.columns.map(({ clause, ...col }) => ({
      ...col,
      clauseId: clause?._id,
    }));
  };

  setFirstQuote = () => {
    const { quotes } = this.props;
    const firstQuote = quotes.length ? { ...quotes[0] } : defaultQuoteState;
    delete firstQuote._id;
    this.setState({
      firstQuote,
      newQuote: {
        ...firstQuote,
        ...emptyQuoteState,
      },
    });
  };

  onQuoteOnChange = (config, value) => {
    const updatedQuote = {
      ...this.state.newQuote,
      [config.key]: value,
    };

    this.setState(
      {
        newQuote: {
          ...updatedQuote,
          ...(config.forcedState ? config.forcedState(updatedQuote) : {}),
        },
      },
      () => {
        if (
          config.key === "indexTerm1" ||
          (config.key === "index1" && value === "SOFR") ||
          config.key === "indexTerm2" ||
          (config.key === "index2" && value === "SOFR")
        ) {
          this.getExnternalIndexRate(updatedQuote, config);
        }
      }
    );
  };

  findExternalIndexRate = (data, externalKey) => {
    const records = data.getElementsByTagName("record");
    for (let i = 0; i < records.length; i++) {
      if (
        records[i].getElementsByTagName("symbol")[0].textContent == externalKey
      ) {
        let extIndexRate =
          records[i].getElementsByTagName("rate")[0].textContent;
        extIndexRate = parseFloat((extIndexRate * 100).toFixed(2));
        return extIndexRate;
      }
    }
    return 0;
  };

  getExnternalIndexRate = (quote, config) => {
    const [index, indexTerm, indexRate] =
      config.key === "index1" || config.key === "indexTerm1"
        ? ["index1", "indexTerm1", "indexRate1"]
        : ["index2", "indexTerm2", "indexRate2"];
    const indexDetails = quoteIndexes.find(
      (eachIndex) => eachIndex.value === quote[index]
    );
    const externalKey = `${indexDetails.symbol()} ${indexDetails.childSymbol(
      quote[indexTerm]
    )}`.trim();
    fetch("https://www.thefinancials.com/Syndicated/CAPITALSLACK/quotes.xml")
      .then((response) => response.text())
      .then((str) => new window.DOMParser().parseFromString(str, "text/xml"))
      .then((data) => {
        const extIndexRate = this.findExternalIndexRate(data, externalKey);
        this.setState((prevState) => {
          const newState = {
            newQuote: {
              ...prevState.newQuote,
              [indexRate]: extIndexRate,
              rate: calculateRate({
                ...prevState.newQuote,
                indexRate: extIndexRate,
              }),
            },
          };
          const updateIndexRate =
            newState.newQuote.indexOption === "Lesser of Two Indexes"
              ? Math.min(
                  newState.newQuote.indexRate1,
                  newState.newQuote.indexRate2
                )
              : Math.max(
                  newState.newQuote.indexRate1,
                  newState.newQuote.indexRate2
                );
          newState.newQuote.indexRate = parseFloat(updateIndexRate);
          newState.newQuote.rate = calculateRate(newState.newQuote);
          return newState;
        });
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  focusCreateQuoteCard = () => {
    try {
      document
        .getElementById("create-quote-card")
        .scrollIntoView({ inline: "end" });
    } catch (e) {}
  };

  copyQuote = (quote) => {
    const newQuote = { ...quote };
    delete newQuote._id;
    this.setState({ newQuote });
    this.focusCreateQuoteCard();
  };

  deleteQuote = (quote) => {
    deleteQuote(quote._id);
  };

  editQuote = (quote) => {
    this.setState({ newQuote: quote });
    this.focusCreateQuoteCard();
  };

  resetQuote = () => {
    this.setFirstQuote();
  };

  saveQuote = () => {
    const { newQuote: data } = this.state;
    if (data._id) {
      this.deleteQuote(data);
      delete data._id;
    }
    const { dealId, termsheetId } = this.props.match.params;
    data.dealId = dealId;
    data.termsheetId = termsheetId;
    createQuote(data);
    this.resetQuote();
  };

  handleSubLabelChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      firstQuote: {
        ...prevState.firstQuote,
        [name]: value,
      },
      newQuote: {
        ...prevState.newQuote,
        [name]: value,
      },
    }));
    const allP = this.props.quotes.map((quote) =>
      updateQuote(quote._id, { ...quote, [name]: value })
    );
    if (allP.length) {
      Promise.all(allP);
    }
  };

  handleSaveAddedClauses = (addedClauses) => {
    const { termsheetId } = this.props.match.params;
    if (termsheetId) {
      updateTermsheet(termsheetId, {
        addedClauses,
        doNotRedirect: true,
      });
    }
  };

  handleRemoveAddedClauses = (addedClause) => {
    const { quoteMatrices } = this.props;
    const newQuoteMatrices = quoteMatrices.filter(
      (e) => e._id !== addedClause._id
    );
    this.handleSaveAddedClauses(newQuoteMatrices);
  };

  handleAddClauseToColumns = (config) => {
    const { termsheetId } = this.props.match.params;
    const { clauses } = this.props;
    const updatedColumns = this.getUpdatedCoumns();
    const found = clauses.find((clause) => clause.clauseName === config.label);
    let newColumn = {
      sectionName: config.label,
      sectionDesc: "",
      sectionDropdown: "",
      sectionYearsMonths: "",
      isSet: false,
      isPrimary: true,
    };
    if (found) {
      newColumn = {
        ...newColumn,
        order: "",
        primaryMatrix: true,
        clauseId: found._id,
        category: found.category?.categoryName,
      };
    }
    const newColumns = sortColumnsByPrimary([...updatedColumns, newColumn]);
    if (termsheetId) {
      updateTermsheet(termsheetId, {
        columns: newColumns,
        doNotRedirect: true,
      });
    } else {
      storeSections(newColumns);
    }
  };

  render() {
    const { quotes, action, match, whoami, columns } = this.props;
    const { newQuote, matrices } = this.state;
    const firstQuote = quotes.length ? quotes[0] : newQuote;
    const { viewQuoteId } = queryString.parse(this.props.location.search);
    return (
      <Container style={{ minHeight: "100vh" }}>
        <SplitPane
          className={
            "QuoteMatrix container" +
            (whoami.role === "Borrower" ? " hidePane2" : "")
          }
          split="horizontal"
          minSize={60}
          defaultSize={350}
        >
          {/* Pane1 */}
          <Col xs={12}>
            <Row>
              {quotes.map((quote, index) => (
                <ViewQuoteCard
                  key={quote._id}
                  whoami={whoami}
                  index={index}
                  quote={quote}
                  firstQuote={firstQuote}
                  copyQuote={this.copyQuote}
                  deleteQuote={this.deleteQuote}
                  editQuote={this.editQuote}
                  className="d-xs-block d-md-none"
                  isFocused={quote._id === viewQuoteId}
                />
              ))}
            </Row>
            <Row>
              {(action.GET_QUOTES.isFetching ||
                action.CREATE_QUOTE.isFetching ||
                action.DELETE_QUOTE.isFetching ||
                action.UPDATE_QUOTE.isFetching) && <Loader />}
              <Col xs={4} sm={4} md={2} lg={2}>
                <QuoteLabels
                  whoami={whoami}
                  matrices={matrices}
                  firstQuote={firstQuote}
                  columns={columns}
                  onSubLabelChange={this.handleSubLabelChange}
                  onRemoveAddedClauses={this.handleRemoveAddedClauses}
                  onAddClauseToColumns={this.handleAddClauseToColumns}
                />
              </Col>
              <Col
                xs={8}
                sm={8}
                md={10}
                lg={10}
                style={{ overflowX: "auto", display: "flex", maxWidth: 975 }}
                className="hide-scrollbar"
              >
                {quotes.map((quote) => (
                  <ViewQuote
                    key={quote._id}
                    quote={quote}
                    firstQuote={firstQuote}
                    copyQuote={this.copyQuote}
                    deleteQuote={this.deleteQuote}
                    editQuote={this.editQuote}
                    className={`d-none d-md-block d-lg-block`}
                    isFocused={quote._id === viewQuoteId}
                    whoami={whoami}
                  />
                ))}
                {(whoami?.role === "Lender" || whoami?.role === "Admin") && (
                  <CreateQuote
                    readOnly
                    key={newQuote._id || "no-key"}
                    quote={newQuote}
                    firstQuote={firstQuote}
                    saveQuote={this.saveQuote}
                    resetQuote={this.resetQuote}
                    onQuoteOnChange={this.onQuoteOnChange}
                    canDisable={Boolean(quotes.length)}
                  />
                )}
              </Col>
            </Row>
          </Col>

          {/* Pane2 */}
          {whoami.role !== "Borrower" && (
            <BorrowerView
              saveAction={match.params.termsheetId ? "edit" : "create"}
              match={match}
            />
          )}
        </SplitPane>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  quotes: state.termsheet.quotes,
  action: state.action,
  whoami: state.auth.whoami,
  quoteMatrices: state.termsheet.termsheet.addedClauses || [],
  columns: state.termsheet.termsheet.columns || [],
  clauses: state.clause.clauses,
});

export default connect(mapStateToProps)(TermsheetCreateEdit);
