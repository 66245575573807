import Spacer from "components/layout/Spacer";
import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

export default function NewProgramIntro({ onSetupMeeting, onContinue }) {
  return (
    <Container className="form-wizard-wrapper no-border no-padding" fluid>
      <Row>
        <Col>
          <p>
            At Term Street, we strive to help our lenders find and close more
            transactions and to build better relationships with more borrowers.
            To do that, we need to get to know you. Informing us about your
            various lending programs helps us to show you deals that are a good
            fit for you. <br /> <br />
            Nothing is required as you create a program on our platform. Simply
            walk through the steps and tell us what you find important for us to
            know about your lending programs. <br /> <br />
            We’d also love to meet you in person or on the phone.{" "}
            <strong>
              <u>We match deals to lenders by hand, not by algorithm,</u>
            </strong>{" "}
            so getting to know our team will ensure that you see the right deals
            for you. We are happy to create your programs for you if you’d like
            to setup a meeting.
          </p>
        </Col>
      </Row>
      <Row>
        <Col className="text-left" xs="6">
          <Spacer size={50} />
          <Button variant="primary" onClick={onSetupMeeting}>
            Setup a Meeting
          </Button>
        </Col>
        <Col className="text-right" xs="6">
          <Spacer size={50} />
          <Button variant="primary" onClick={onContinue}>
            Continue
          </Button>
        </Col>
      </Row>
    </Container>
  );
}
