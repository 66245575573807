import React, { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Divider } from "antd";
import { connect, useSelector } from "react-redux";
import ConversationItem from "./ConversationItem";
import {
  getConversationAllArchived,
  getConversationAllDeleted,
  getConversationAllDraft,
  getConversationAllInbox,
  getConversationAllSent,
  getConversationMessageInbox,
  getConversationMessagesArchived,
  getConversationMessagesDeleted,
  getConversationMessagesDraft,
  getConversationMessagesSent,
  getConversationQuestionInbox,
  getConversationQuestionsArchived,
  getConversationQuestionsDeleted,
  getConversationQuestionsDraft,
  getConversationQuestionsSent,
} from "redux/actions";
import { useRouteMatch } from "react-router-dom";
import Socket from "utils/Socket";
import { noop } from "lodash";
import useAutoArchive from "./useAutoArchive";

const apiMap = {
  all_inbox: getConversationAllInbox,
  all_archived: getConversationAllArchived,
  all_draft: getConversationAllDraft,
  all_sent: getConversationAllSent,
  all_deleted: getConversationAllDeleted,
  messages_inbox: getConversationMessageInbox,
  messages_archived: getConversationMessagesArchived,
  messages_draft: getConversationMessagesDraft,
  messages_sent: getConversationMessagesSent,
  messages_deleted: getConversationMessagesDeleted,
  questions_inbox: getConversationQuestionInbox,
  questions_archived: getConversationQuestionsArchived,
  questions_draft: getConversationQuestionsDraft,
  questions_sent: getConversationQuestionsSent,
  questions_deleted: getConversationQuestionsDeleted,
};

const ConversationList = ({
  list,
  listCount,
  selectedIds,
  tab,
  status,
  isFetching,
  onCheck,
}) => {
  const lastQuestionAnswer = useSelector(
    (state) => state.conversation.lastQuestionAnswer
  );
  const lastMessageReply = useSelector(
    (state) => state.conversation.lastMessageReply
  );

  const match = useRouteMatch();
  const dealId = match.params.id;
  const conversationSubscriptionId1 = useRef(null);

  const showFetching = useRef(true);
  const [subscriptionChange, setSubscriptionChange] = useState(Date.now());
  const [limit] = useState(10);
  const [page, setPage] = useState(1);
  const hasMore = page * limit < listCount;

  useAutoArchive();

  useEffect(() => {
    conversationSubscriptionId1.current = Socket.subscribe(
      `/deal/${dealId}/conversations`,
      () => {
        showFetching.current = false;
        setSubscriptionChange(Date.now());
      }
    );
    return () => {
      Socket.unsubscribe(conversationSubscriptionId1.current);
    };
  }, []);

  useEffect(() => {
    showFetching.current = true;
  }, [list]);

  useEffect(() => {
    const query = { dealId, populate: true, page, limit };
    const apiKey = `${tab}_${status}`;
    const apiCall = apiMap[apiKey] || noop;
    apiCall(query);
  }, [
    tab,
    subscriptionChange,
    status,
    lastQuestionAnswer,
    lastMessageReply,
    page,
    limit,
  ]);

  if (showFetching.current && isFetching)
    return <div className="text-center p-4">Loading data...</div>;

  if (!isFetching && !list.length)
    return <div className="text-center p-4">No results found.</div>;

  return (
    <InfiniteScroll
      dataLength={list.length}
      hasMore={hasMore}
      height={50 * limit}
      loader={
        <p className="text-center font-italic p-2">
          <i>Fetching more...</i>
        </p>
      }
      next={() => {
        showFetching.current = false;
        setPage((prev) => prev + 1);
      }}
    >
      {list.map((item) => {
        const isSelected = selectedIds.includes(item._id);
        return (
          <React.Fragment key={item._id}>
            <ConversationItem
              tab={tab}
              isSelected={isSelected}
              status={status}
              item={item}
              onCheck={onCheck}
            />
            <Divider style={{ margin: "0" }} />
          </React.Fragment>
        );
      })}
    </InfiniteScroll>
  );
};

function mapStateToProps(state) {
  return {
    isFetching:
      state.action.GET_CONVERSATION_MESSAGE_INBOX.isFetching ||
      state.action.GET_CONVERSATION_QUESTION_INBOX.isFetching,
  };
}

export default connect(mapStateToProps)(ConversationList);
