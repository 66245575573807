import React from "react";
import { Row, Col, Button, Card, Dropdown } from "react-bootstrap";
import Spacer from "components/layout/Spacer";
import quotesConfig from "./quotesConfig";
import { reduxFormNumber as InputNumber } from "components/form/ReduxForm";

import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <Button
    ref={ref}
    variant="inbox-action"
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </Button>
));

function ViewQuote({
  quote,
  copyQuote,
  deleteQuote,
  editQuote,
  whoami,
  quoteMatrices = [],
  ...props
}) {
  const matrices = React.useMemo(() => {
    const quoteMatricesWithValues = quoteMatrices.map((matrix) => {
      const addedClause = quote.addedClauses?.find((e) => e._id === matrix._id);
      if (addedClause) {
        return {
          ...matrix,
          value: addedClause.value,
        };
      }
      return matrix;
    });
    return [...quotesConfig, ...quoteMatricesWithValues];
  }, [quote, quotesConfig, quoteMatrices]);

  const canManage =
    quote.createdBy === whoami._id ||
    quote.companyId.companyAdmin === whoami._id;

  return (
    <Row className={props.className}>
      <Col>
        <Card className={`quote-card ${props.isFocused ? "focus-quote" : ""}`}>
          <Card.Body className="quote-card-body">
            {whoami && whoami.role === "Lender" && (
              <Dropdown
                className="d-inline-block quote-actions-dropdown"
                drop="down"
              >
                <Dropdown.Toggle as={CustomToggle}>
                  <FontAwesomeIcon icon={faEllipsisH} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => copyQuote(quote)}>
                    Copy
                  </Dropdown.Item>
                  {canManage && (
                    <>
                      <Dropdown.Item onClick={() => editQuote(quote)}>
                        Edit
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => deleteQuote(quote)}>
                        Delete
                      </Dropdown.Item>
                    </>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            )}
            {matrices.map((config, index) => {
              if (config.showIf && !config.showIf(quote)) {
                return null;
              }
              let value = quote[config.key];
              let targetClause =
                config.clauseId &&
                quote.addedClauses?.find((e) => e.clauseId === config.clauseId);
              if (targetClause) {
                value = targetClause.value;
              }
              const key = config.key + config.label;
              if (config.type === "currency") {
                return (
                  <Row key={index}>
                    <InputNumber
                      key={key}
                      displayType="text"
                      prefix={"$"}
                      meta={{}}
                      input={{
                        value,
                      }}
                      thousandSeparator
                    />
                  </Row>
                );
              }
              if (config.type === "percent") {
                return (
                  <Row key={index}>
                    <InputNumber
                      key={key}
                      displayType="text"
                      suffix={"%"}
                      meta={{}}
                      input={{
                        value,
                      }}
                      decimalScale={2}
                      fixedDecimalScale
                    />
                  </Row>
                );
              }
              return <Row key={index}>{value || "NA"}</Row>;
            })}
            <Spacer size={23} />
          </Card.Body>
        </Card>
        {whoami && whoami.role === "Lender" && (
          <Row>
            <Col className="text-center">
              <Button
                className="fake-link"
                variant="fake-link"
                onClick={() => copyQuote(quote)}
              >
                Copy
              </Button>{" "}
              &nbsp;&nbsp;
              <Button
                className="fake-link"
                variant="fake-link"
                onClick={() => editQuote(quote)}
              >
                Edit
              </Button>{" "}
              &nbsp;&nbsp;
              <Button
                className="fake-link"
                variant="fake-link"
                onClick={() => deleteQuote(quote)}
              >
                Delete
              </Button>
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
}

export default ViewQuote;
