import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import LogRocket from "logrocket";
import store from "./config/store";
import * as serviceWorker from "./serviceWorker";
// CSS
import "./styles/reset.css";
import "./styles/main.scss";
import "./styles/accessibility.scss";
import { ConfigProvider } from "antd";
import { antdTheme } from "config/antd";

if (process.env.NODE_ENV !== "development") {
  LogRocket.init("iedeuv/termstreet");
}

ReactDOM.render(
  <Provider store={store}>
    {
      //window.origin.includes('app.termst.com') &&
      // <NewRelic licenseKey={constants.NEW_RELIC.LICENSE_KEY} applicationID={constants.NEW_RELIC.APPLICATION_ID} />
    }
    <DndProvider backend={HTML5Backend}>
      <ConfigProvider theme={antdTheme}>
        <Router>
          <Router>
            <Switch>
              <Route path="/" component={App} />
            </Switch>
          </Router>
        </Router>
      </ConfigProvider>
    </DndProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
