import React from "react";
import { connect } from "react-redux";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { get } from "lodash";
import {
  reduxFormInput,
  reduxFormSelect,
  reduxFormNumber,
  reduxFormTextarea,
} from "components/form/ReduxForm";
import { required, noSpaces, minMax } from "utils/validation";
import { getTeamsByCompanyId } from "redux/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons";
import InviteToTeamFromProgram from "containers/program/WizardSteps/InviteToTeamFromProgram";
import Spacer from "components/layout/Spacer";
import programCategories from "../constants/programCategories";
import { generateSelectOption } from "utils/commonUtils";

class InitialStep extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addNewTeamLead: false,
      teamLeads: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isActive !== this.props.isActive && this.props.isActive) {
      this.props.setStepName("");
    }
    if (prevProps.teams !== this.props.teams) {
      const { teams } = this.props;

      this.setState({ teamLeads: [] });

      teams.map((team) => {
        team.teamMembers.map((member) => {
          if (member.globalRole === "Team Leader") {
            this.setState((prevState) => ({
              teamLeads: [
                ...prevState.teamLeads,
                {
                  label: member.userId.firstName + " " + member.userId.lastName,
                  value: member.userId._id,
                  email: member.userId.email,
                },
              ],
            }));
          }

          return true;
        });

        return true;
      });
    }
  }

  componentDidMount() {
    const { teams } = this.props;

    teams.map((team) => {
      team.teamMembers.map((member) => {
        if (member.globalRole === "Team Leader") {
          this.setState((prevState) => ({
            teamLeads: [
              ...prevState.teamLeads,
              {
                label: member.userId.firstName + " " + member.userId.lastName,
                value: member.userId._id,
                email: member.userId.email,
              },
            ],
          }));
        }

        return true;
      });

      return true;
    });
  }

  submitFn = () => {
    if (this.saveType === "saveAndContinue") {
      this.props.handleSaveAndContinue(this.props.nextStep);
    } else {
      this.props.handleSaveAndContinue();
    }
  };

  setSave = (type) => () => {
    this.saveType = type;
  };

  render() {
    const {
      action,
      isCompanyAdmin,
      companyId,
      handleSubmit,
      selectedProgramType,
    } = this.props;

    return (
      <>
        {this.state.addNewTeamLead ? (
          <>
            {isCompanyAdmin ? (
              <InviteToTeamFromProgram
                onBack={() => {
                  getTeamsByCompanyId(companyId);
                  this.setState({ addNewTeamLead: false });
                }}
              />
            ) : (
              <></>
            )}
          </>
        ) : (
          <fieldset>
            <Form
              className="no-padding no-border"
              onSubmit={handleSubmit(this.submitFn)}
            >
              <Row>
                <Col className="pl-0 pr-0" xs="12" sm="6">
                  <Col xs="12">
                    <Field
                      className="form-control"
                      component={reduxFormInput}
                      validate={[required, noSpaces]}
                      name="programName"
                      type="text"
                      placeholder="Enter program name"
                      label="*Program Name"
                    />
                    <Spacer size={20} />
                  </Col>
                  <Container>
                    <Row>
                      <Col xs="12" md="6">
                        <Field
                          icon={<FontAwesomeIcon icon={faDollarSign} />}
                          className="form-control d-inline"
                          component={reduxFormNumber}
                          validate={[required]}
                          name="loanMinimum"
                          placeholder="Enter loan minimum"
                          label="*Loan Minimum"
                          thousandSeparator={true}
                        />
                        <Spacer size={20} />
                      </Col>
                      <Col xs="12" md="6">
                        <Field
                          icon={<FontAwesomeIcon icon={faDollarSign} />}
                          className="form-control d-inline"
                          component={reduxFormNumber}
                          validate={[required, minMax]}
                          name="loanMaximum"
                          placeholder="Enter loan maximum"
                          label="*Loan Maximum"
                          thousandSeparator={true}
                        />
                        <Spacer size={20} />
                      </Col>
                    </Row>
                  </Container>
                  <Col xs="12">
                    <Field
                      className="form-control"
                      component={reduxFormSelect}
                      name="primaryContact"
                      type="select"
                      options={this.state.teamLeads}
                      isLoading={action.GET_TEAMS_BY_COMPANY_ID.isFetching}
                      placeholder="Select Primary Contact"
                      label="Primary Contact"
                      onFocus={() => {
                        getTeamsByCompanyId(companyId);
                      }}
                    />
                    <Spacer size={20} />
                    {isCompanyAdmin && (
                      <>
                        <Button
                          type="primary"
                          onClick={() => {
                            this.setState({ addNewTeamLead: true });
                          }}
                        >
                          Add New Team Lead
                        </Button>
                        <Spacer size={20} />
                      </>
                    )}
                  </Col>
                </Col>
                <Col xs="12" sm="6">
                  <Field
                    className="form-control"
                    component={reduxFormSelect}
                    validate={[required]}
                    name="programTypes"
                    type="select"
                    options={this.props.getDropdownValues("programTypes")}
                    isLoading={action.GET_DROPDOWNS.isFetching}
                    onFocus={() =>
                      this.props.getDropdownFromAPI("programTypes")
                    }
                    placeholder="Select Program Type"
                    label="*Program Type"
                  />
                  <Spacer size={20} />
                  {get(this.props, "selectedProgramType.value", null) ===
                    "Other" && (
                    <>
                      <Field
                        className="form-control"
                        component={reduxFormInput}
                        validate={[noSpaces]}
                        name="otherProgramType"
                        type="text"
                        placeholder="Other (Program Type)"
                        label="Other (Program Type)"
                      />
                      <Spacer size={20} />
                    </>
                  )}
                  {selectedProgramType ? (
                    <>
                      {programCategories.equity.indexOf(
                        selectedProgramType.value
                      ) === -1 &&
                        programCategories.mezz.indexOf(
                          selectedProgramType.value
                        ) === -1 && (
                          <>
                            <Field
                              className="form-control"
                              component={reduxFormSelect}
                              name="servicing"
                              type="select"
                              options={this.props.getDropdownValues(
                                "servicing"
                              )}
                              isLoading={action.GET_DROPDOWNS.isFetching}
                              onFocus={() =>
                                this.props.getDropdownFromAPI("servicing")
                              }
                              placeholder="Loan Servicing"
                              label="Loan Servicing"
                            />
                            <Spacer size={20} />
                            {get(
                              this.props,
                              "selectedServicing.value",
                              null
                            ) === "Other - Please Describe" && (
                              <>
                                <Field
                                  className="form-control"
                                  component={reduxFormTextarea}
                                  validate={[noSpaces]}
                                  name="otherServicing"
                                  type="textarea"
                                  rows="3"
                                  placeholder="Additional information"
                                  label="Other (Loan Servicing)"
                                />
                                <Spacer size={20} />
                              </>
                            )}
                          </>
                        )}
                      {(programCategories.equity.indexOf(
                        selectedProgramType.value
                      ) !== -1 ||
                        programCategories.mezz.indexOf(
                          selectedProgramType.value
                        ) !== -1) && (
                        <>
                          <Field
                            className="form-control"
                            component={reduxFormSelect}
                            name="riskProfile"
                            type="select"
                            options={this.props.getDropdownValues(
                              "riskProfile"
                            )}
                            isLoading={action.GET_DROPDOWNS.isFetching}
                            onFocus={() =>
                              this.props.getDropdownFromAPI("riskProfile")
                            }
                            placeholder="Risk Profile"
                            label="Risk Profile"
                            isMulti={true}
                          />
                          <Spacer size={20} />
                        </>
                      )}
                      {programCategories.agency.indexOf(
                        selectedProgramType.value
                      ) === -1 &&
                        programCategories.equity.indexOf(
                          selectedProgramType.value
                        ) === -1 &&
                        programCategories.mezz.indexOf(
                          selectedProgramType.value
                        ) === -1 && (
                          <>
                            <Field
                              className="form-control"
                              component={reduxFormSelect}
                              name="recourseOptions"
                              type="select"
                              options={this.props.getDropdownValues(
                                "recourseOptions"
                              )}
                              isLoading={action.GET_DROPDOWNS.isFetching}
                              onFocus={() =>
                                this.props.getDropdownFromAPI("recourseOptions")
                              }
                              placeholder="Recourse vs. Non-Recourse"
                              label="Recourse vs. Non-Recourse"
                              isMulti={true}
                            />
                            <Spacer size={20} />
                          </>
                        )}
                    </>
                  ) : null}
                </Col>
              </Row>
              <Row>
                <Col xs="3"></Col>
                <Col className="text-right" xs="9">
                  <Spacer size={50} />
                  <Button
                    type="submit"
                    variant="primary"
                    onClick={this.setSave("saveAndExit")}
                  >
                    Save & Exit
                  </Button>
                  &nbsp;
                  <Button
                    type="submit"
                    variant="primary"
                    onClick={this.setSave("saveAndContinue")}
                  >
                    Save & Continue
                  </Button>
                </Col>
              </Row>
            </Form>
          </fieldset>
        )}
      </>
    );
  }
}

const selector = formValueSelector("ProgramCreateInitialStep");
const getInitialValues = ({ isUpdate, isClone, selectedProgram: program }) => {
  if (!isUpdate && !isClone) {
    return {};
  }
  return {
    programName: isClone ? program.programName + " Clone" : program.programName,
    loanMinimum: program.loanMinimum,
    loanMaximum: program.loanMaximum,
    primaryContact:
      program.primaryContactName !== null
        ? {
            label: JSON.parse(program.primaryContactName).label,
            value: JSON.parse(program.primaryContactName).value,
            email: JSON.parse(program.primaryContactName).email,
          }
        : null,
    programTypes: generateSelectOption(program.programTypes),
    otherProgramType: program.otherProgramType,
    servicing: generateSelectOption(program.servicing),
    otherServicing: program.otherServicing,
    recourseOptions: get(program, "recourseOptions", null),
    riskProfile: get(program, "riskProfile", null),
  };
};
function mapStateToProps(state, ownProps) {
  return {
    action: state.action,
    teams: state.team.teams,
    selectedProgramType: selector(state, "programTypes") || "",
    selectedServicing: selector(state, "servicing") || "",
    initialValues: getInitialValues(ownProps),
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "ProgramCreateInitialStep",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    enableReinitialize: true,
  })(InitialStep)
);
