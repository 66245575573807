export const noSpaces = (value) =>
  !/\S/.test(value) && value.length > 0
    ? "Can't accept only spaces"
    : undefined;

export const required = (value) => (value ? undefined : "Required");

export const minLength = (max) => (value) =>
  value && value.length < max ? `Must be ${max} characters or more` : undefined;

export const maxLength = (max) => (value) =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;

export const number = (value) => {
  if (typeof value === "string") {
    value.replace(/,/g, "");
  }

  return isNaN(Number(value)) && value === "" ? "Must be a number" : undefined;
};

export const minValue = (min) => (value) =>
  value && value < min ? `Must be at least ${min}` : undefined;

export const email = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,8}$/i.test(value)
    ? "Invalid email address"
    : undefined;

export const domain = (value) =>
  value &&
  !/^(?!:\/\/)([a-zA-Z0-9-]+\.){0,5}[a-zA-Z0-9-][a-zA-Z0-9-]+\.[a-zA-Z]{2,64}?$/gi.test(
    value
  )
    ? "Invalid domain"
    : undefined;

export const passwordRule = (value) =>
  value &&
  !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,100}$/.test(
    value
  )
    ? "Must contain at least one number and one uppercase and lowercase letter and one symbol, and at least 8 or more characters"
    : undefined;

export const passwordsMatch = (value, allValues) =>
  value !== allValues.PASSWORD ? "Passwords don't match" : undefined;

export const passwordsMatchUpdateProfile = (value, allValues) => {
  if (allValues && allValues.user && allValues.user.new_password) {
    return value !== allValues.user.new_password
      ? "Passwords don't match"
      : undefined;
  }
};

export const minMax = (value, allValues) =>
  parseFloat(value) < parseFloat(allValues.minimumLoanAmountRequested) ||
  parseFloat(value) < parseFloat(allValues.minimumRate) ||
  parseFloat(value) < parseFloat(allValues.minimumSpread) ||
  parseFloat(value) < parseFloat(allValues.loanMinimum)
    ? "Maximum cannot be less than the minimum"
    : undefined;

export const url = (value) => {
  const urlPattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  if (value && !urlPattern.test(value)) {
    return "URL is invalid.";
  } else {
    return undefined;
  }
};

export const mobileNumber = (value) =>
  value && !/^\(?(\d{3})\)?(\s|-)?(\d{3})(\s|-)?(\d{4})$/i.test(value)
    ? "Invalid mobile number."
    : undefined;
