import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

class Paginate extends React.Component {
  render() {
    const {
      list,
      listTotalCount,
      listOffset,
      nextPage,
      previousPage,
      page,
    } = this.props;

    return (
      <>
        {list && list.length > 0 && (
          <Row className="w-100 align-items-center">
            <Col className="text-right" xs="4" sm="5">
              {page > 1 && (
                <Button
                  variant="paginate"
                  onClick={() => {
                    const previous = parseInt(page) - 1;

                    this.setState({ page: previous });

                    previousPage(previous);
                  }}
                >
                  <FontAwesomeIcon icon={faChevronLeft} />
                </Button>
              )}
            </Col>
            <Col className="text-center" xs="4" sm="2">
              <p className="bold font-14">
                {page} /{" "}
                <span className="color-medium-gray">
                  {Math.ceil(listTotalCount / listOffset)}
                </span>
              </p>
              <p className="font-14">Page</p>
            </Col>
            <Col className="text-left" xs="4" sm="5">
              {page < Math.ceil(listTotalCount / listOffset) && (
                <Button
                  variant="paginate"
                  onClick={() => {
                    const next = parseInt(page) + 1;

                    this.setState({ page: next });

                    nextPage(next);
                  }}
                >
                  <FontAwesomeIcon icon={faChevronRight} />
                </Button>
              )}
            </Col>
          </Row>
        )}
      </>
    );
  }
}

export default Paginate;
