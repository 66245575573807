import React from "react";
import { Form, Button, Row, Col, Tabs, Tab } from "react-bootstrap";
import { reduxForm, Field, change, reset } from "redux-form";
import { reduxFormSelect, reduxFormInput } from "components/form/ReduxForm";
import {
  assignToComm,
  removeFromComm,
  setPublicStatus,
  editCommGroups,
} from "redux/actions";
import { required } from "utils/validation";
import Spacer from "components/layout/Spacer";

const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

const groupBadgeStyles = {
  backgroundColor: "#EBECF0",
  borderRadius: "2em",
  color: "#172B4D",
  display: "inline-block",
  fontSize: 12,
  fontWeight: "normal",
  lineHeight: "1",
  minWidth: 1,
  padding: "0.16666666666667em 0.5em",
  textAlign: "center",
};

class SetPrivacyMessages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: 0,
      isChangingToPrivate: false,
      availableToSelect: [],
      commRecipients: [],
      selectLenderRecipient: [],
      addRecipient: [],
      deleteRecipient: [],
    };

    this.lenderRef = React.createRef();
    this.submitRecipients = this.submitRecipients.bind(this);
    this.submitPrivacy = this.submitPrivacy.bind(this);
    this.addRecipient = this.addRecipient.bind(this);
    this.removeRecipient = this.removeRecipient.bind(this);
  }

  componentDidMount() {
    const {
      borrowerId,
      borrowerTeam,
      actionComm,
      lendersAssigned,
      groupsAssigned,
      groups,
      whoami,
    } = this.props;

    let commRecipients = [];
    let categoryCompanies = [];
    let categoryGroups = [];
    let assigned = lendersAssigned;

    if (
      whoami.role !== "Borrower" &&
      !lendersAssigned.some((lender) => lender.companyId === borrowerId)
    ) {
      assigned.push({
        companyName: "Deal Borrower",
        companyId: borrowerId,
        teamId: borrowerTeam,
      });
    }

    const availableLenders = assigned.filter(
      ({ companyId: a }) =>
        !actionComm.lender.some(({ companyId: b }) => b === a)
    );

    const availableGroups = groups.filter(
      ({ _id: a }) => !groupsAssigned.some((b) => b === a)
    );

    const lenderRecipients = assigned.filter(({ companyId: a }) =>
      actionComm.lender.some(({ companyId: b }) => b === a)
    );

    const groupRecipients = groups.filter(({ _id: a }) =>
      groupsAssigned.some((b) => b === a)
    );

    availableLenders.map((lender) => {
      categoryCompanies.push({
        label: lender.companyName,
        value: lender.companyId,
        teamId: lender.teamId,
        isGroup: false,
      });

      return true;
    });

    availableGroups.map((group) => {
      categoryGroups.push({
        label: group.groupName,
        value: group._id,
        groupId: group._id,
        isGroup: true,
      });

      return true;
    });

    lenderRecipients.map((lender) => {
      if (lender.companyName && lender.companyId) {
        commRecipients.push({
          label: lender.companyName,
          value: lender.companyId,
          teamId: lender.teamId,
          isGroup: false,
        });
      }

      return true;
    });

    groupRecipients.map((group) => {
      commRecipients.push({
        label: group.groupName,
        value: group._id,
        groupId: group._id,
        isGroup: true,
      });

      return true;
    });

    this.setState({
      availableToSelect: [
        {
          label: "Companies",
          options: categoryCompanies.sort((a, b) =>
            a.label.localeCompare(b.label)
          ),
        },
        {
          label: "Groups",
          options: categoryGroups.sort((a, b) =>
            a.label.localeCompare(b.label)
          ),
        },
      ],
      commRecipients,
    });

    actionComm.lender.map((lender) => {
      if (lender.companyName && lender.companyId) {
        this.setState((prevState) => ({
          selectLenderRecipient: [
            ...prevState.selectLenderRecipient,
            { label: lender.companyName, value: lender.companyId },
          ],
        }));
      }

      return true;
    });
  }

  addRecipient(data) {
    const { dispatch } = this.props;

    data.map((item) => {
      if (item.isGroup) {
        this.setState((prevState) => ({
          availableToSelect: [
            prevState.availableToSelect[0],
            {
              label: "Groups",
              options: prevState.availableToSelect[1].options
                .sort((a, b) => a.label.localeCompare(b.label))
                .filter((recipient) => recipient.value !== item.value),
            },
          ],
          commRecipients: [
            ...prevState.commRecipients,
            {
              label: item.label,
              value: item.value,
              groupId: item.value,
              isGroup: item.isGroup,
            },
          ],
          addRecipient: [...prevState.addRecipient, item],
        }));
      } else {
        this.setState((prevState) => ({
          availableToSelect: [
            {
              label: "Companies",
              options: prevState.availableToSelect[0].options
                .sort((a, b) => a.label.localeCompare(b.label))
                .filter((recipient) => recipient.value !== item.value),
            },
            prevState.availableToSelect[1],
          ],
          commRecipients: [
            ...prevState.commRecipients,
            {
              label: item.label,
              value: item.value,
              teamId: item.teamId,
              isGroup: item.isGroup,
            },
          ],
          addRecipient: [...prevState.addRecipient, item],
        }));
      }

      return true;
    });

    if (
      this.state.deleteRecipient.some(
        (recipient) => recipient.value === data.value
      )
    ) {
      this.setState((prevState) => ({
        deleteRecipient: prevState.deleteRecipient.filter(
          (r) => r.value !== data.value
        ),
      }));
    }

    dispatch(change("SetPrivacyMessages", "availableToSelect", null));
  }

  removeRecipient(data) {
    if (data.isGroup) {
      this.setState((prevState) => ({
        deleteRecipient: [...prevState.deleteRecipient, data],
        commRecipients: prevState.commRecipients.filter(
          (recipient) => recipient.groupId !== data.groupId
        ),
        availableToSelect: [
          prevState.availableToSelect[0],
          {
            label: "Groups",
            options: prevState.availableToSelect[1].options
              .concat([
                {
                  label: data.label,
                  value: data.value,
                  groupId: data.value,
                  isGroup: data.isGroup,
                },
              ])
              .sort((a, b) => a.label.localeCompare(b.label)),
          },
        ],
      }));
    } else {
      this.setState((prevState) => ({
        deleteRecipient: [...prevState.deleteRecipient, data],
        commRecipients: prevState.commRecipients.filter(
          (recipient) => recipient.teamId !== data.teamId
        ),
        availableToSelect: [
          {
            label: "Companies",
            options: prevState.availableToSelect[0].options
              .concat([
                {
                  label: data.label,
                  value: data.value,
                  teamId: data.teamId,
                  isGroup: data.isGroup,
                },
              ])
              .sort((a, b) => a.label.localeCompare(b.label)),
          },
          prevState.availableToSelect[1],
        ],
      }));
    }

    if (
      this.state.addRecipient.some(
        (recipient) => recipient.value === data.value
      )
    ) {
      this.setState((prevState) => ({
        addRecipient: prevState.addRecipient.filter(
          (r) => r.value !== data.value
        ),
      }));
    }
  }

  submitRecipients() {
    const { actionComm, onSubmit, groupsAssigned } = this.props;
    let commGroups = groupsAssigned;

    this.state.deleteRecipient.map((recipient) => {
      if (recipient.isGroup) {
        commGroups = commGroups.filter((id) => id !== recipient.groupId);
      } else {
        removeFromComm(actionComm._id, {
          remove: recipient.value,
        });
      }

      return true;
    });

    this.state.addRecipient.map((recipient) => {
      if (recipient.isGroup) {
        commGroups.push(recipient.groupId);
      } else {
        assignToComm(actionComm._id, {
          companyId: recipient.value,
          companyName: recipient.label,
          teamId: recipient.teamId,
        });
      }

      return true;
    });

    editCommGroups(actionComm._id, {
      groups: commGroups,
    });

    onSubmit();
  }

  submitPrivacy(data) {
    const { actionComm, onSubmit } = this.props;

    setPublicStatus(actionComm._id, {
      isPublic: data.publicStatus.value,
    });

    if (data.selectRecipient) {
      this.state.selectLenderRecipient.map((lender) => {
        if (lender.value !== data.selectRecipient.value) {
          removeFromComm(actionComm._id, {
            remove: lender.value,
          });
        }

        return true;
      });
    }

    onSubmit();
  }

  render() {
    const { handleSubmit, actionComm, dispatch } = this.props;

    const formatGroupLabel = (data) => (
      <div style={groupStyles}>
        <span>{data.label}</span>
        <span style={groupBadgeStyles}>{data.options.length}</span>
      </div>
    );

    return (
      <>
        <p className="bold">
          {actionComm.editedInitialMessage &&
          actionComm.editedInitialMessage.length > 0
            ? actionComm.editedInitialMessage.replace(
                /^(.{25}[^\s]*).*/,
                "$1"
              ) + "..."
            : actionComm.initialMessage.replace(/^(.{25}[^\s]*).*/, "$1") +
              "..."}
        </p>
        <Spacer size={20} />
        <Tabs
          defaultActiveKey="recipients"
          onSelect={(key) => {
            if (key === "settings") {
              this.setState({ tab: 1 });
            } else {
              this.setState({ tab: 0, isChangingToPrivate: false });
              dispatch(reset("SetPrivacyMessages"));
            }
          }}
        >
          <Tab eventKey="recipients" title="Recipients">
            {this.state.tab === 0 && (
              <Form
                className="no-padding no-border"
                onSubmit={handleSubmit(this.submitRecipients)}
              >
                <Row>
                  <Col xs="12">
                    <Spacer size={20} />
                    <h4 className="bold">Add Recipient</h4>
                    <Spacer size={20} />
                  </Col>
                  <Col xs="8" sm="10">
                    {this.state.availableToSelect[0] &&
                    this.state.availableToSelect[1] &&
                    (this.state.availableToSelect[0].options.length > 0 ||
                      this.state.availableToSelect[1].options.length > 0) ? (
                      <Field
                        ref={this.lenderRef}
                        className="form-control"
                        component={reduxFormSelect}
                        name="availableToSelect"
                        type="select"
                        options={this.state.availableToSelect}
                        formatGroupLabel={formatGroupLabel}
                        placeholder="Select recipient..."
                        label="*Select recipient to add to comm"
                        isMulti={true}
                      />
                    ) : (
                      <p>There are no available recipients to choose from.</p>
                    )}
                    <Spacer size={20} />
                  </Col>
                  <Col xs="4" sm="2">
                    {this.state.availableToSelect &&
                    this.state.availableToSelect.length > 0 ? (
                      <Button
                        className="full-width"
                        variant="primary"
                        onClick={() =>
                          this.lenderRef.current.value
                            ? this.addRecipient(this.lenderRef.current.value)
                            : null
                        }
                      >
                        Add
                      </Button>
                    ) : (
                      <></>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col xs="12">
                    <h4 className="bold">Recipients:</h4>
                    <Spacer size={20} />
                  </Col>
                </Row>
                <Row>
                  {this.state.commRecipients
                    .sort((a, b) => a.label.localeCompare(b.label))
                    .map((recipient, idx) => {
                      return (
                        <Col
                          className="field-wrapper-no-spacer"
                          xs="12"
                          key={idx}
                        >
                          <Field
                            className="form-control"
                            component={reduxFormInput}
                            name={"recipient" + idx}
                            type="text"
                            placeholder={
                              recipient.label
                                ? recipient.label
                                : "Deal Borrower"
                            }
                            disabled
                          />
                          <p
                            className="field-remove"
                            onClick={() => this.removeRecipient(recipient)}
                            onKeyDown={(e) =>
                              e.keyCode === 13
                                ? document.activeElement.click()
                                : null
                            }
                            tabIndex="0"
                          >
                            Remove
                          </p>
                        </Col>
                      );
                    })}
                </Row>
                <Row>
                  <Col className="text-right" xs="12">
                    <Spacer size={50} />
                    <Button type="submit" variant="primary">
                      Update
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Tab>
          {actionComm.type === "Question" && (
            <Tab eventKey="settings" title="Privacy Settings">
              {this.state.tab === 1 && (
                <Form
                  className="no-padding no-border"
                  onSubmit={handleSubmit(this.submitPrivacy)}
                >
                  <Row>
                    <Col xs="12">
                      <Spacer size={20} />
                      <h4 className="bold">Change Privacy Setting</h4>
                      <Spacer size={20} />
                    </Col>
                    <Col className="border-right" xs="12" sm="6">
                      <Field
                        className="form-control"
                        component={reduxFormSelect}
                        validate={[required]}
                        name="publicStatus"
                        type="select"
                        options={
                          actionComm.isPublic
                            ? [
                                {
                                  label: "Private",
                                  value: false,
                                  isDisabled: actionComm.isPublic
                                    ? false
                                    : true,
                                },
                                {
                                  label: "Public",
                                  value: true,
                                  isDisabled: actionComm.isPublic
                                    ? true
                                    : false,
                                },
                              ]
                            : [
                                {
                                  label: "Public",
                                  value: true,
                                  isDisabled: actionComm.isPublic
                                    ? true
                                    : false,
                                },
                                {
                                  label: "Private",
                                  value: false,
                                  isDisabled: actionComm.isPublic
                                    ? false
                                    : true,
                                },
                              ]
                        }
                        placeholder={actionComm.isPublic ? "Public" : "Private"}
                        onChange={(e) => {
                          this.setState({ isChangingToPrivate: !e.value });
                        }}
                        label="*Change privacy setting"
                      />
                      <Spacer size={20} />
                    </Col>
                    <Col xs="12" sm="6">
                      <p>
                        Changing the privacy from{" "}
                        <span className="bold">Public</span> to{" "}
                        <span className="bold">Private</span> requires the
                        conversation to have one other recipient than you.
                      </p>
                      {this.state.isChangingToPrivate && (
                        <>
                          <Spacer size={10} />
                          <p>Please choose from the list below.</p>
                        </>
                      )}
                      <Spacer size={20} />
                    </Col>
                  </Row>
                  {this.state.isChangingToPrivate ? (
                    <>
                      <Row>
                        <Col xs="12">
                          <h4 className="bold">Recipient:</h4>
                          <Spacer size={20} />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12">
                          <Field
                            className="form-control"
                            component={reduxFormSelect}
                            validate={[required]}
                            name="selectRecipient"
                            type="select"
                            options={this.state.commRecipients}
                            formatGroupLabel={formatGroupLabel}
                            placeholder="Choose a single recipient"
                            label="*Select recipient"
                          />
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <></>
                  )}
                  <Row>
                    <Col className="text-right" xs="12">
                      <Spacer size={50} />
                      <Button type="submit" variant="primary">
                        Set Privacy
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Tab>
          )}
        </Tabs>
      </>
    );
  }
}

export default reduxForm({
  form: "SetPrivacyMessages",
  enableReinitialize: true,
})(SetPrivacyMessages);
