import React from "react";
import moment from "moment";
import orderBy from "lodash/orderBy";
import { Badge } from "react-bootstrap";

const NotesTimeline = (props) => {
  const { notes = [], className = "" } = props;
  return (
    <div className={`NotesTimeline ${className}`}>
      <ul className="timeline">
        {orderBy(notes, "createdAt", "desc").map((item) => (
          <li key={item._id}>
            <p>
              <span className="font-weight-bold">
                {moment(item.createdAt).format("MMMM Do YYYY, h:mm a")}
              </span>{" "}
              |
              <Badge pill className="ml-1">
                {`${item.createdBy?.firstName} ${item.createdBy?.lastName}`}
              </Badge>
            </p>
            <p className="text-muted">{item.content}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default NotesTimeline;
