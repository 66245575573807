import React from "react";
import { connect } from "react-redux";
import { Form, Button, Row, Col } from "react-bootstrap";
import { Field, reduxForm, reset } from "redux-form";
import { inviteToCompany } from "redux/actions";
import { reduxFormInput } from "components/form/ReduxForm";
import { required, email, noSpaces } from "utils/validation";
import Spacer from "components/layout/Spacer";

class InviteToTeamFromProgram extends React.Component {
  constructor(props) {
    super(props);

    this.submitFn = this.submitFn.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.lastInviteToCompany &&
      this.props.lastInviteToCompany !== prevProps.lastInviteToCompany
    ) {
      this.props.onBack();
    }
  }

  submitFn(data) {
    const { dispatch, myCompany } = this.props;

    data.companyId = myCompany._id;
    data.makeTeamLead = true;
    data.teamName = data.firstName + " " + data.lastName + "'s Team";

    inviteToCompany(myCompany._id, data);

    dispatch(reset("InviteToTeamFromProgram"));
  }

  render() {
    const { handleSubmit, onBack } = this.props;

    return (
      <>
        <h3>Invite to be a team leader in your company.</h3>
        <Spacer size={30} />
        <Form
          className="no-padding no-border"
          onSubmit={handleSubmit(this.submitFn)}
        >
          <Row>
            <Col xs="12">
              <Spacer size={20} />
            </Col>
            <Col xs="12" sm="4">
              <Field
                className="form-control"
                component={reduxFormInput}
                validate={[required, noSpaces]}
                name="firstName"
                type="text"
                placeholder="Enter first name"
                label="*First name"
              />
              <Spacer size={20} />
            </Col>
            <Col xs="12" sm="4">
              <Field
                className="form-control"
                component={reduxFormInput}
                validate={[required, noSpaces]}
                name="lastName"
                type="text"
                placeholder="Enter last name"
                label="*Last name"
              />
              <Spacer size={20} />
            </Col>
            <Col xs="12" sm="4">
              <Field
                className="form-control"
                component={reduxFormInput}
                validate={[required, email, noSpaces]}
                name="email"
                type="email"
                placeholder="Enter email"
                label="*Email"
              />
              <Spacer size={30} />
            </Col>
            <Col xs="6">
              <Button
                variant="dark"
                onClick={() => {
                  onBack();
                }}
              >
                Back
              </Button>
              <Spacer size={20} />
            </Col>
            <Col className="text-right" xs="6">
              <Button type="submit" variant="primary">
                Invite
              </Button>
              <Spacer size={20} />
            </Col>
          </Row>
        </Form>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    myCompany: state.company.myCompany,
    lastInviteToCompany: state.company.lastInviteToCompany,
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "InviteToTeamFromProgram",
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
  })(InviteToTeamFromProgram)
);
