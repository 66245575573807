import React from "react";
import { Avatar, Tooltip } from "antd";
import { getInitials } from "utils/commonUtils";

export const InboxAvatar = ({ name, imageUrl, subtitle }) => (
  <div className="d-flex align-items-center gap-16">
    <Tooltip title={name} placement="top">
      <Avatar style={{ minWidth: 32 }} src={imageUrl}>
        {getInitials(name)}
      </Avatar>
    </Tooltip>
    <div>
      <p className="font-14 line-height-none mb-1">{name || "-"}</p>
      <p className="font-12 line-height-none text-secondary">{subtitle}</p>
    </div>
  </div>
);
