import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Row, Col, Button, Form } from "react-bootstrap";
import { reduxForm } from "redux-form";
import { reduxFormWYSIWYG as TextArea } from "components/form/ReduxForm";
import { updateDeal, getDealById } from "redux/actions";
import { getKeyFromLocal } from "utils/lsUtils";
import constants from "config/constants";
import Spacer from "components/layout/Spacer";
import Loader from "components/ui/Loader";
import { getDealCreationPath } from "utils/commonUtils";

class PropertyDescription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      error: null,
      hasValue: false,
    };

    this.submitFn = this.submitFn.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.deal !== this.props.deal) {
      const { deal } = this.props;

      if (deal.propertyDescription) {
        this.setState({ hasValue: true });
      }

      this.setState({
        value:
          deal.propertyDescription && deal.propertyDescription !== null
            ? deal.propertyDescription
            : "",
      });
    }
  }

  componentDidMount() {
    if (getKeyFromLocal(constants.TERMST_LOCAL_STORAGE.DEAL_ID) !== false) {
      const dealId = getKeyFromLocal(constants.TERMST_LOCAL_STORAGE.DEAL_ID);

      getDealById(dealId);
    }
  }

  submitFn(data) {
    const { deal } = this.props;
    const dealId = getKeyFromLocal(constants.TERMST_LOCAL_STORAGE.DEAL_ID);

    let noSpaceCheck = this.state.value;
    noSpaceCheck = noSpaceCheck.split("&nbsp;").join("");
    noSpaceCheck = noSpaceCheck.split("<p>").join("");
    noSpaceCheck = noSpaceCheck.split("</p>").join("");

    if (noSpaceCheck.trim() === "") {
      this.setState({ error: "Required", hasValue: false });
    } else if (Object.entries(data).length === 0 && this.state.value !== "") {
      this.setState({ error: null, hasValue: true });
      const path = getDealCreationPath()
      window.location.pathname = path;
    } else if (
      // this.state.value !== deal.propertyDescription &&
      Object.entries(data).length !== 0
    ) {
      this.setState({ error: null, hasValue: true });

      data.propertyDescriptionSet = true;

      updateDeal(dealId, data);
    } else if (!this.state.hasValue) {
      this.setState({ error: "Required" });
    } else {
      this.setState({ error: "Required" });
    }
  }

  handleDescriptionChange = (value) => {
    this.props.change("propertyDescription", value);
    this.setState({ value });
  };

  render() {
    const { action, handleSubmit } = this.props;
    const { isFetching } = action.GET_DEAL_BY_ID;
    const path = getDealCreationPath();

    return (
      <>
        {isFetching && <Loader />}
        <Form
          className="full-width no-border mobile-no-padding"
          onSubmit={handleSubmit(this.submitFn)}
        >
          <Row>
            <Col xs="12">
              <TextArea
                className="form-control"
                name="propertyDescription"
                type="text"
                placeholder="enter description"
                label="*Description"
                input={{
                  name: "propertyDescription",
                  value: this.state.value ? this.state.value : "",
                  onChange: this.handleDescriptionChange,
                }}
              />
              <Spacer size={20} />
            </Col>
          </Row>
          <Row className="application-controls">
            <Col className="text-left" xs="6">
              <Button
                as={Link}
                className="pl-3 pr-3"
                to={path}
                variant="dark"
              >
                Back
              </Button>
            </Col>
            <Col className="text-right" xs="6">
              <Button className="pl-2 pr-2" type="submit" variant="primary">
                Continue
              </Button>
            </Col>
          </Row>
        </Form>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { propertyDescription } = state.deal.deal;

  return {
    action: state.action,
    deal: state.deal.deal,
    initialValue: {
      propertyDescription,
    },
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "PropertyDescription",
    enableReinitialize: true,
  })(PropertyDescription)
);
