import React from "react";
import { Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Loader from "./Loader";

class PopupPrompt extends React.Component {
  render() {
    const {
      promptTitle,
      promptBody,
      show = false,
      isLoading,
      onHide = () => null,
      onCancel,
      onConfirm,
      confirmLabel = "Confirm",
      dark,
      showLoader,
      size = "lg",
    } = this.props;

    return (
      <Modal
        className={dark ? "dark-modal" : "modal-class"}
        show={show}
        onHide={onHide}
        size={size}
        centered
        style={{ marginRight: "20px" }}
      >
        {showLoader && isLoading && <Loader />}
        <Modal.Header>
          <Modal.Title>{promptTitle}</Modal.Title>
          <FontAwesomeIcon
            focusable="true"
            icon={faTimes}
            onClick={onCancel}
            onKeyDown={(e) => (e.keyCode === 13 ? onCancel() : null)}
            tabIndex="0"
          />
        </Modal.Header>
        <Modal.Body style={{ minHeight: 100 }}>{promptBody}</Modal.Body>
        {this.props.withFooter ? (
          <Modal.Footer>
            {this.props.withCancel ? (
              <Button variant="dark" disabled={isLoading} onClick={onCancel}>
                Cancel
              </Button>
            ) : (
              <></>
            )}
            <Button variant="primary" disabled={isLoading} onClick={onConfirm}>
              {confirmLabel}
            </Button>
          </Modal.Footer>
        ) : (
          <></>
        )}
      </Modal>
    );
  }
}

export default PopupPrompt;
