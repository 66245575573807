import React from "react";
import { connect } from "react-redux";
import { Button, Form, Container, Row, Col } from "react-bootstrap";
import { Field, reduxForm } from "redux-form";
import { required, noSpaces } from "utils/validation";
import {
  reduxFormInput,
  reduxFormSelect,
  reduxFormTextarea,
} from "components/form/ReduxForm";
import { createTermsheet, getDeals, updateTermsheet } from "redux/actions";
import Spacer from "components/layout/Spacer";
import { adminAvailabilityOptions } from "./lookup";

const statusOptions = [
  {
    label: "ACTIVE",
    value: "ACTIVE",
  },
  {
    label: "ARCHIVED",
    value: "ARCHIVED",
  },
];

class CreateEditTermsheet extends React.Component {
  constructor(props) {
    super(props);

    this.submitFn = this.submitFn.bind(this);

    this.state = {
      ownerOptions: [
        {
          label: "Current User",
          value: props.whoami._id,
        },
      ],
    };
  }

  componentDidMount() {
    this.handleGetDeals();
  }

  getAvailabilityOptions = () => {
    const { whoami } = this.props;
    return whoami.role === "Admin"
      ? adminAvailabilityOptions
      : adminAvailabilityOptions.filter((e) => e.value !== "ALL_LENDERS");
  };

  handleGetDeals = () => {
    getDeals({
      limit: 100,
      order: "ASC",
      orderColumn: "propertyName",
    });
  };

  submitFn(data) {
    const { promptType, selectedTermsheet, myCompany } = this.props;

    if (promptType === "create" || promptType === "copy") {
      createTermsheet({
        termsheetName: data.termsheetName,
        columns: [],
        dealId: data.dealId?.value,
        isTemplate: data.isTemplate,
        owner: data.owner.value,
        adminAvailability: {
          key: data.adminAvailability.value,
          values: [],
        },
        companyID: myCompany._id,
        status: "ACTIVE",
      });
    } else if (promptType === "edit") {
      updateTermsheet(selectedTermsheet._id, {
        termsheetName: data.termsheetName,
        dealId: data.dealId?.value,
        isTemplate: data.isTemplate,
        owner: data.owner.value,
        adminAvailability: {
          key: data.adminAvailability.value,
          values: [],
        },
      });
    } else if (promptType === "change_status") {
      updateTermsheet(selectedTermsheet._id, {
        status: data.status.value,
      });
    } else if (promptType === "change_availability") {
      updateTermsheet(selectedTermsheet._id, {
        adminAvailability: {
          key: data.adminAvailability.value,
          values: [],
        },
      });
    }
  }

  render() {
    const { action, promptType, handleSubmit } = this.props;
    const isProcessing =
      action.CREATE_TAG.isFetching || action.UPDATE_TAG.isFetching;

    return (
      <>
        <Container>
          <Form
            className="no-border no-padding"
            onSubmit={handleSubmit(this.submitFn)}
          >
            {(promptType === "create" ||
              promptType === "edit" ||
              promptType === "copy") && (
              <>
                <Row>
                  <Col xs="12">
                    <Field
                      className="form-control"
                      component={reduxFormInput}
                      validate={[required, noSpaces]}
                      name="termsheetName"
                      type="text"
                      placeholder="Enter termsheet name"
                      label="*Termsheet name"
                    />
                  </Col>
                </Row>

                <Spacer size={10} />
              </>
            )}

            {(promptType === "create" ||
              promptType === "edit" ||
              promptType === "copy" ||
              promptType === "change_availability") && (
              <>
                <Row>
                  {promptType !== "change_availability" && (
                    <Col xs="6">
                      <Field
                        className="form-control"
                        component={reduxFormSelect}
                        validate={[required]}
                        name="owner"
                        type="select"
                        options={this.state.ownerOptions}
                        label="*Owner"
                      />
                    </Col>
                  )}

                  <Col xs={promptType === "change_availability" ? "12" : "6"}>
                    <Field
                      className="form-control"
                      component={reduxFormSelect}
                      validate={[required]}
                      name="adminAvailability"
                      type="select"
                      options={this.getAvailabilityOptions()}
                      label="*Available to"
                    />
                  </Col>
                </Row>

                <Spacer size={10} />
              </>
            )}

            {promptType === "change_status" && (
              <>
                <Row>
                  <Col xs="12">
                    <Field
                      className="form-control"
                      component={reduxFormSelect}
                      validate={[required]}
                      name="status"
                      type="select"
                      options={statusOptions}
                      label="*Status"
                    />
                  </Col>
                </Row>

                <Spacer size={10} />
              </>
            )}

            {(promptType === "create" ||
              promptType === "edit" ||
              promptType === "copy") && (
              <>
                <Row>
                  <Col xs="12">
                    <Field
                      className="form-control"
                      component={reduxFormTextarea}
                      validate={[noSpaces]}
                      name="notes"
                      type="textarea"
                      rows="4"
                      label="Useage Note"
                      placeholder="Add Useage Note..."
                    />
                  </Col>
                </Row>

                <Spacer size={10} />

                <Row>
                  <Col xs="12">
                    <Field
                      className="form-control"
                      component={reduxFormInput}
                      name="isTemplate"
                      type="checkbox"
                      label="Is Template"
                    />
                  </Col>
                </Row>
              </>
            )}

            <Spacer size={40} />

            <Row className="justify-content-center">
              <Col className="text-center" xs="12" sm="6">
                <Button disabled={isProcessing} type="submit" variant="primary">
                  {isProcessing ? "Saving..." : "Save"}
                </Button>
              </Col>
            </Row>
          </Form>
          <Spacer size={30} />
        </Container>
      </>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    action: state.action,
    whoami: state.auth.whoami,
    myCompany: state.company.myCompany,
    initialValues: {
      termsheetName: props.selectedTermsheet?.termsheetName || "",
      notes: props.selectedTermsheet?.notes || "",
      isTemplate: props.selectedTermsheet?.isTemplate || "",
      status: props.selectedTermsheet?.status
        ? {
            label: props.selectedTermsheet?.status,
            value: props.selectedTermsheet?.status,
          }
        : null,
      adminAvailability: props.selectedTermsheet?.adminAvailability?.key
        ? adminAvailabilityOptions.find(
            (e) => e.value === props.selectedTermsheet?.adminAvailability.key
          )
        : null,
      owner: props.selectedTermsheet?.owner?._id
        ? {
            label: "Current User",
            value: props.selectedTermsheet.owner?._id,
          }
        : null,
    },
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "CreateEditTermsheet",
    enableReinitialize: true,
  })(CreateEditTermsheet)
);
