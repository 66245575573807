import React from "react";
import { connect } from "react-redux";
import { Button, Form, Row, Col } from "react-bootstrap";
import { Field, reduxForm } from "redux-form";
import { reduxFormTextarea } from "components/form/ReduxForm";
import { required, noSpaces } from "utils/validation";
import { createComm } from "redux/actions";
import Spacer from "components/layout/Spacer";

class CreateMessage extends React.Component {
  constructor(props) {
    super(props);

    this.submitFn = this.submitFn.bind(this);
  }

  submitFn(data) {
    const { whoami, dealId, lenders, onSubmit } = this.props;

    lenders.map((lender) => {
      createComm({
        dealId: dealId,
        lenderCompany: lender.id,
        lenderCompanyName: lender.id,
        lenderTeam: lender.teamId,
        type: "Message",
        isPublic: false,
        initialMessage: data.description,
        sender: whoami.firstName + " " + whoami.lastName,
        senderId: whoami._id,
        isFollowUpQuestion: false,
        linkedQuestionId: undefined,
        linkedQuestionTitle: undefined,
        uploads: [],
        groupIds: [],
      });

      return true;
    });

    onSubmit();
  }

  render() {
    const { handleSubmit } = this.props;

    return (
      <Form
        className="no-padding no-border"
        onSubmit={handleSubmit(this.submitFn)}
      >
        <Row>
          <Col xs="12">
            <Field
              className="form-control"
              component={reduxFormTextarea}
              validate={[required, noSpaces]}
              name="description"
              type="textarea"
              rows="10"
              placeholder="Description..."
            />
            <Spacer size={50} />
          </Col>
          <Col className="text-right" xs="12">
            <Button type="submit">Submit</Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

function mapStateToProps(state) {
  return {
    whoami: state.auth.whoami,
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "CreateMessage",
    enableReinitialize: true,
  })(CreateMessage)
);
