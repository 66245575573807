import React, { useEffect, useMemo, useState } from "react";
import { faEllipsisH, faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import update from "immutability-helper";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import {
  Button,
  Col,
  Dropdown,
  Row,
  Table as BootstrapTable,
} from "react-bootstrap";
import {
  useTable,
  usePagination,
  useRowSelect,
  useFilters,
  useGlobalFilter,
} from "react-table";
import CustomMenu from "components/ui/CustomMenu";
import CustomToggle from "components/ui/CustomToggle";
import DefaultColumnFilter from "components/ui/DefaultColumnFilter";
import Spacer from "components/layout/Spacer";
import PopupPrompt from "components/ui/PopupPrompt";
import CreateEditCategory from "./CreateEditCategory";
import { getManageCategories, reorderCategories } from "redux/actions";
import { connect } from "react-redux";
import MergeCategories from "./MergeCategories";

const ManageCategories = (props) => {
  const [state, setState] = useState({
    promptType: "",
    showPrompt: false,
    selectedCategory: null,
    selectedCategories: [],
    pagination: {
      page: 1,
      listOffset: 500,
    },
    sort: {
      direction: "ASC",
      column: "order",
    },
  });
  const [, setSelectedRows] = useState([]);
  const handleRowSelectChange = (selectedRows) => {
    setSelectedRows(selectedRows);
  };

  const handleModalClose = () =>
    setState((prevState) => ({
      ...prevState,
      showPrompt: false,
      promptType: "",
      selectedCategory: null,
      selectedCategories: [],
    }));

  const handleModelOpen = (promptType, selectedCategory) => {
    setState((prevState) => ({
      ...prevState,
      showPrompt: true,
      promptType,
      selectedCategory,
      selectedCategories: [selectedCategory],
    }));
  };

  const handleEdit = (rowValues) => {
    handleModelOpen("edit", rowValues);
  };

  const handleMerge = (rowValues) => {
    handleModelOpen("merge", rowValues);
  };

  const handleRowReorder = (newRows) => {
    const categories = newRows.map((e) => ({ _id: e._id, order: e.order }));
    reorderCategories({ categories });
  };

  const columns = useMemo(
    () => [
      {
        Header: "Category",
        accessor: "categoryName",
        disableFilters: true,
      },
      {
        Header: "In Use",
        accessor: "inUse",
        disableFilters: true,
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <Dropdown className="d-inline-block">
              <Dropdown.Toggle as={CustomToggle}>
                <p className="link-text">
                  <FontAwesomeIcon icon={faEllipsisH} />
                </p>
              </Dropdown.Toggle>

              <Dropdown.Menu as={CustomMenu}>
                <Dropdown.Item
                  onClick={() => handleEdit(cellProps.cell.row.original)}
                >
                  Edit Name
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => handleMerge(cellProps.cell.row.original)}
                >
                  Merge
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          );
        },
      },
    ],
    []
  );

  const tableData = useMemo(
    () =>
      props.manageCategories.rows.map((e) => ({
        ...e,
        inUse: 0,
      })),
    [props.manageCategories]
  );

  const fetchCategories = () => {
    const { pagination, sort } = state;
    const { manageCategories } = props;
    getManageCategories({
      page: pagination.page,
      limit: pagination.listOffset,
      search: manageCategories.search,
      order: sort.direction,
      orderColumn: sort.column,
    });
  };

  useEffect(() => {
    handleModalClose();
    fetchCategories();
  }, [
    props.lastCategoryCreated,
    props.lastCategoryUpdated,
    props.lastCategoryDeleted,
    props.lastCategoryMerged,
    props.lastCategoryReorderDate,
  ]);

  return (
    <Row>
      <Col xs={12} className="d-flex justify-content-end">
        <Button
          type="submit"
          variant="tertiary"
          onClick={() => handleModelOpen("create")}
        >
          New Category
        </Button>
      </Col>
      <Col xs={12}>
        <Spacer size={8} />
        <Table
          columns={columns}
          data={tableData}
          onRowSelectChange={handleRowSelectChange}
          onRowReorder={handleRowReorder}
        />
      </Col>

      {(state.promptType === "create" || state.promptType === "edit") && (
        <PopupPrompt
          promptTitle={`${
            state.promptType === "create" ? "Add New" : "Edit"
          } Category`}
          promptBody={
            <CreateEditCategory
              promptType={state.promptType}
              selectedCategory={state.selectedCategory}
              onCancel={handleModalClose}
            />
          }
          show={state.showPrompt}
          onCancel={handleModalClose}
          onHide={handleModalClose}
        />
      )}
      {state.promptType === "merge" && (
        <PopupPrompt
          promptTitle="Merge Categories"
          promptBody={
            <MergeCategories
              promptType={state.promptType}
              selectedCategories={state.selectedCategories}
              onCancel={handleModalClose}
            />
          }
          show={state.showPrompt}
          onCancel={handleModalClose}
          onHide={handleModalClose}
        />
      )}
    </Row>
  );
};

const Table = ({ columns, data, onRowSelectChange, onRowReorder }) => {
  const [records, setRecords] = React.useState(data);

  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const getRowId = React.useCallback((row) => {
    return row._id;
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data: records,
      defaultColumn,
      getRowId,
      initialState: {
        pageSize: 100,
      },
    },
    useFilters,
    useGlobalFilter,
    usePagination,
    useRowSelect
  );

  useEffect(() => {
    setRecords(data);
  }, [data]);

  useEffect(() => {
    onRowSelectChange(selectedFlatRows);
  }, [selectedFlatRows]);

  const moveRow = (dragIndex, hoverIndex) => {
    const dragRecord = records[dragIndex];
    setRecords(
      update(records, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRecord],
        ],
      })
    );
  };

  const onDrop = () => {
    const newRecords = records.map((e, i) => ({ ...e, order: i + 1 }));
    setRecords(newRecords);
    onRowReorder(newRecords);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <BootstrapTable bordered hover {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              <th></th>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>
                  <div className="d-flex flex-column">
                    {column.render("Header")}
                    {column.canFilter && column.render("Filter")}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            return (
              prepareRow(row) || (
                <TableRow
                  index={index}
                  row={row}
                  moveRow={moveRow}
                  onDrop={onDrop}
                  {...row.getRowProps()}
                />
              )
            );
          })}
        </tbody>
      </BootstrapTable>
    </DndProvider>
  );
};

const DND_ITEM_TYPE = "row";

const TableRow = ({ row, index, moveRow, onDrop }) => {
  const dropRef = React.useRef(null);
  const dragRef = React.useRef(null);

  const [, drop] = useDrop({
    accept: DND_ITEM_TYPE,
    hover(item, monitor) {
      if (!dropRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = dropRef.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveRow(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
    drop(item, monitor) {
      onDrop();
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: DND_ITEM_TYPE, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;

  preview(drop(dropRef));
  drag(dragRef);

  return (
    <tr ref={dropRef} style={{ opacity }}>
      <td ref={dragRef} className="text-center">
        <FontAwesomeIcon icon={faBars} className="cursor-move" />
      </td>
      {row.cells.map((cell) => {
        return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
      })}
    </tr>
  );
};

function mapStateToProps(state) {
  return {
    action: state.action,
    manageCategories: state.category.manageCategories,
    lastCategoryCreated: state.category.lastCategoryCreated,
    lastCategoryUpdated: state.category.lastCategoryUpdated,
    lastCategoryDeleted: state.category.lastCategoryDeleted,
    lastCategoryMerged: state.category.lastCategoryMerged,
    lastCategoryReorderDate: state.category.lastCategoryReorderDate,
  };
}

export default connect(mapStateToProps)(ManageCategories);
