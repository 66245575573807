import React from "react";
import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDrop } from "react-dnd";
import { NativeTypes } from "react-dnd-html5-backend";
import CategoryItem from "./CategoryItem";

const CategoryItemDropTarget = (props) => {
  const { _id, name, handleUploadFile } = props;
  const [isValid] = React.useState(true);
  const [{ canDrop, isOverCurrent }, drop] = useDrop({
    accept: [NativeTypes.FILE],
    drop(item, monitor) {
      const didDrop = monitor.didDrop();

      if (didDrop) {
        return;
      }

      const event = {
        target: {
          files: item?.files,
        },
      };
      handleUploadFile(event, _id);
    },
    collect: (monitor) => ({
      isOverCurrent: monitor.isOver({ shallow: true }),
      canDrop: monitor.canDrop(),
    }),
  });
  const isActive = canDrop && isOverCurrent && _id && handleUploadFile;

  return (
    <div
      ref={drop}
      className={
        isActive ? `droptarget-active ${isValid ? "" : "error"}` : undefined
      }
    >
      <CategoryItem {...props} />
      {isActive && (
        <div
          className="droptarget-info"
          style={{
            position: "fixed",
            bottom: 0,
            left: "70%",
          }}
        >
          <div
            className="d-flex flex-column align-items-center justify-content-center"
            style={{
              position: "relative",
              left: "-50%",
            }}
          >
            <FontAwesomeIcon className="display-4" icon={faCloudUploadAlt} />
            <div className="detail p-3 my-2 text-center shadow">
              <p>Drop files to instantly upload them to:</p>
              <p className="font-weight-bold">{name}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CategoryItemDropTarget;
