export default {
    general: [
        "Perm",
        "Bridge",
        "Bridge to Perm",
        "Stretch Senior",
        "Construction",
        "construction / Perm",
        "CMBS",
        "HUD",
        "CTL",
        "Credit Facilty",
        "Hard Money"
    ],
    agency: [
        "Fannie Mae",
        "Freddie Mac",
        "Fannie SBL",
        "Freddie SBL",
    ],
    equity: [
        "Equity"
    ],
    mezz: [
        "Mezzanine / Pref"
    ],
    minOpenWrite: [
        "USDA",
        "SBA 7a",
        "SBA 504",
        "PACE",
        "Loan Guaranty",
        "Other",
    ]

}