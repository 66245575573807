import React from "react";
import { connect } from "react-redux";
import { Button, Form, Container, Row, Col } from "react-bootstrap";
import { formValueSelector, reduxForm } from "redux-form";
import { getClauses, updateClause } from "redux/actions";
import Spacer from "components/layout/Spacer";

class SaveReplaceClause extends React.Component {
  constructor(props) {
    super(props);

    this.submitFn = this.submitFn.bind(this);

    this.draft = false;
    this.state = {
      value: props.initialValues.clause || "",
      parentOptions: [],
      ownerOptions: [
        {
          label: "Current User",
          value: props.whoami._id,
        },
      ],
    };
  }

  componentDidMount() {
    this.handleGetClauses();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.column !== this.props.column) {
      this.props.change("clause", this.props.column?.langDesc);
    }
    if (prevProps.lastClauseUpdated !== this.props.lastClauseUpdated) {
      this.props.onCancel();
    }
  }

  handleGetClauses = () => {
    getClauses({
      limit: 100,
      order: "ASC",
      orderColumn: "clauseName",
      parent: true,
    });
  };

  submitFn(data) {
    const { selectedClause } = this.props;
    updateClause(selectedClause._id, {
      clause: data.clause,
    });
  }

  handleClauseChange = (value) => {
    this.props.change("clause", value);
    this.setState({ value });
  };

  render() {
    const { action, onCancel, onSaveNew, handleSubmit, initialValues } =
      this.props;

    const isProcessing =
      action.CREATE_TAG.isFetching || action.UPDATE_TAG.isFetching;

    return (
      <>
        <Container>
          <Form
            className="no-border no-padding"
            onSubmit={handleSubmit(this.submitFn)}
          >
            <Row>
              <Col xs="12">
                <p>
                  You have edited a previously saved clause. That clause was
                  saved as:
                </p>
              </Col>
            </Row>

            <Spacer size={10} />

            <Row>
              <Col xs="12">
                <p>
                  <b>{initialValues?.clauseName}</b>
                </p>
              </Col>
            </Row>

            <Spacer size={20} />

            <Row>
              <Col xs="12">
                <p>
                  Would you like to save your changes and replace the saved
                  clause or save this as a new clause?
                </p>
              </Col>
            </Row>

            <Spacer size={20} />

            <Row className="justify-content-center">
              <Col className="text-right" xs="12">
                <Button
                  disabled={isProcessing}
                  variant="dark"
                  className="mr-2"
                  onClick={onCancel}
                >
                  Cancel
                </Button>
                <Button
                  disabled={isProcessing}
                  variant="primary"
                  className="mr-2"
                  type="submit"
                >
                  Replace
                </Button>
                <Button
                  disabled={isProcessing}
                  onClick={onSaveNew}
                  variant="tertiary"
                >
                  Save New
                </Button>
              </Col>
            </Row>
          </Form>

          <Spacer size={20} />
        </Container>
      </>
    );
  }
}

const selector = formValueSelector("SaveReplaceClause");

function mapStateToProps(state, props) {
  return {
    action: state.action,
    whoami: state.auth.whoami,
    clauses: state.clause.clauses,
    lastClauseUpdated: state.clause.lastClauseUpdated,
    initialValues: {
      clauseName: props.selectedClause?.clauseName,
      clause: props.column?.langDesc,
    },
    selectedDisplayName: selector(state, "displayName"),
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "SaveReplaceClause",
    enableReinitialize: true,
  })(SaveReplaceClause)
);
