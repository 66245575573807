import React from "react";
import { connect } from "react-redux";
import { Accordion, Row, Col, Card } from "react-bootstrap";
import Paginate from "components/ui/Paginate";
import Loader from "components/ui/Loader";
import moment from "moment";
import api from "utils/api";
import Socket from "utils/Socket";
import LenderShortInfo from "containers/admin/LenderShortInfo";
import Spacer from "components/layout/Spacer";

const dateTimeAgo = (date) => (!date ? "NA" : moment(date).fromNow());

const showCount = (count) => count || 0;

const LenderStatisticsBlock = ({ stat }) => {
  return (
    <Row>
      <Col className="text-left" xs="4" sm="2">
        <p className="bold">
          {stat.assignedAt
            ? moment(stat.assignedAt).format("MM/DD/YYYY")
            : "NA"}
        </p>
        <p className="font-12 color-medium-gray">Date Assigned</p>
        <p className="bold">{showCount(stat.totalFiles)}</p>
        <p className="font-12 color-medium-gray">Files</p>
      </Col>
      <Col className="text-left" xs="4" sm="2">
        <p className="bold">{dateTimeAgo(stat.firstFileDownloadAt)}</p>
        <p className="font-12 color-medium-gray">First Download</p>
        <p className="bold">{showCount(stat.totalFileDownloads)}</p>
        <p className="font-12 color-medium-gray">File Downloads</p>
      </Col>
      <Col className="text-left" xs="4" sm="2">
        <p className="bold">{dateTimeAgo(stat.firstPageViewAt)}</p>
        <p className="font-12 color-medium-gray">First View</p>
        <p className="bold">{showCount(stat.totalPageViews)}</p>
        <p className="font-12 color-medium-gray">Page views</p>
      </Col>
      <Col className="text-left" xs="4" sm="2">
        <p className="bold">{dateTimeAgo(stat.firstQuoteCreatedAt)}</p>
        <p className="font-12 color-medium-gray">First Quote</p>
        <p className="bold">{showCount(stat.totalQuotes)}</p>
        <p className="font-12 color-medium-gray">Quotes</p>
      </Col>
      <Col className="text-left" xs="4" sm="2">
        <p className="bold">{dateTimeAgo(stat.firstQuestionCreatedAt)}</p>
        <p className="font-12 color-medium-gray">First Question</p>
        <p className="bold">{showCount(stat.totalQuestions)}</p>
        <p className="font-12 color-medium-gray">Questions</p>
      </Col>
      <Col className="text-left" xs="4" sm="2">
        <p className="bold">{dateTimeAgo(stat.firstMessageCreatedAt)}</p>
        <p className="font-12 color-medium-gray">First Message</p>
        <p className="bold">{showCount(stat.totalMessages)}</p>
        <p className="font-12 color-medium-gray">Messages</p>
      </Col>
    </Row>
  );
};
const StatsCard = ({ stat }) => (
  <Col xs="12">
    <Card className="mb-3 company-card">
      <Accordion.Toggle as={Card.Header} tabIndex="0" eventKey={stat._id}>
        <Row>
          <Col xs="12" sm="12">
            <LenderShortInfo company={stat.company} />
          </Col>
        </Row>
        <hr />
        <LenderStatisticsBlock stat={stat} />
      </Accordion.Toggle>
    </Card>
  </Col>
);
class LenderStatistics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: {
        page: 1,
        listOffset: 10,
      },
      lenderStats: {
        rows: [],
        count: 0,
        totalFiles: 0,
      },
      loading: true,
    };
    this.pendingEventsCount = 0;
  }

  componentDidMount() {
    const { dealId } = this.props.match.params;
    this.fetchLenderStats();
    this.statsSubscriptionId = Socket.subscribe(`/deal/${dealId}/stats`, () => {
      if (this.apiInProgress) {
        this.pendingEventsCount++;
      } else {
        this.fetchLenderStats(true);
      }
    });
  }

  fetchLenderStats = (silentRefresh = false) => {
    const { pagination } = this.state;
    const { dealId } = this.props.match.params;
    if (!silentRefresh) {
      this.setState({ loading: true });
    }
    this.apiInProgress = true;
    api
      .request({
        url: `/deals/${dealId}/stats?page=${pagination.page}&limit=${pagination.listOffset}`,
      })
      .then((success) => {
        this.setState({
          lenderStats: {
            ...success.data.body,
            rows: Object.values(success.data.body.rows),
          },
        });
      })
      .finally(() => {
        if (!silentRefresh) {
          this.setState({ loading: false });
        }
        this.apiInProgress = false;
        if (this.pendingEventsCount) {
          this.fetchLenderStats(true);
          this.pendingEventsCount = 0;
        }
      });
  };

  handlePagination = (page) => {
    this.setState(
      (prevState) => ({ pagination: { ...prevState.pagination, page } }),
      this.fetchLenderStats
    );
  };

  componentWillUnmount() {
    Socket.unsubscribe(this.statsSubscriptionId);
  }

  render() {
    const { lenderStats } = this.state;
    return (
      <>
        {this.state.loading && <Loader />}
        <Spacer size={20} />
        <Row>
          <Accordion className="w-100">
            {lenderStats.rows.map((stat) => {
              return <StatsCard stat={stat} key={stat._id} />;
            })}
            {!this.state.loading && lenderStats.rows.length === 0 && (
              <div className="text-center">
                <p>There are currently no stats available</p>
              </div>
            )}
          </Accordion>
          <Paginate
            {...this.state.pagination}
            list={lenderStats.rows}
            listTotalCount={lenderStats.count}
            previousPage={this.handlePagination}
            nextPage={this.handlePagination}
          />
        </Row>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    action: state.action,
  };
}
export { LenderStatisticsBlock };
export default connect(mapStateToProps)(LenderStatistics);
