import React, { useState, useEffect } from 'react'
import * as _ from 'lodash';
import allSections from './sections';
import { Container, Form, Col, Row, Button } from 'react-bootstrap';
import Spacer from 'components/layout/Spacer';
import { storeSections } from 'redux/actions/termsheetAction';
import { connect } from 'react-redux';
import { reduxFormInput as Input } from 'components/form/ReduxForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { getTermsheet } from 'redux/actions';

const defaultColumn = {
    "category": "",
    "order": "",
    "sectionName": "",
    "sectionDesc": "",
    "sectionAmount": "",
    "sectionDollarAmount": "",
    "sectionPercentage": "",
    "sectionDropdown": "",
    "sectionYearsMonths": "",
    "isSet": false,
    "primaryMatrix": true
}

const searchSections = (key) => {
    key = key.trim();
    if (!key) {
        return allSections;
    }
    const foundSections = [];
    for (let i = 0; i < allSections.length; i++) {
        const { title, options } = allSections[i];
        if (title.toLowerCase().includes(key.toLowerCase())) {
            foundSections.push(allSections[i]);
        } else {
            const newSection = { ...allSections[i], options: [] }
            for (let j = 0; j < options.length; j++) {
                if (options[j].title.toLowerCase().includes(key.toLowerCase())) {
                    newSection.options.push(options[j]);
                }
            }
            if (newSection.options.length) {
                foundSections.push(newSection);
            }
        }
    }
    return foundSections;
}

const findChecked = (checkedColumns, section, option) => {
    const index = _.findIndex(checkedColumns, (column) => (column.category === section.title && column.sectionName === option.title));
    return { isChecked: index !== -1, index }
}
function SelectSections(props) {
    const [searchKey, setSearchKey] = useState("");
    const [sections, setSections] = useState(allSections);
    const [checkedColumns, setCheckedColumns] = useState(props.columns);
    let timer = null;
    const handleCheck = (e, section, option) => {
        const newData = [...checkedColumns];
        e.persist()
        if (e.target.checked) {
            newData.push({ ...defaultColumn, category: section.title, sectionName: option.title, sectionType: option.sectionType })
        } else {
            const { index } = findChecked(checkedColumns, section, option);
            newData.splice(index, 1);
        }
        setCheckedColumns(newData)
    }
    const handleContinue = (e) => {
        if (!checkedColumns.length) {
            toast.error("Please select at least one section", {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }
        for (let i = 0; i < checkedColumns.length; i++) {
            const item = _.find(props.columns, (column) => (column.category === checkedColumns[i].category && column.sectionName === checkedColumns[i].sectionName));
            if (Boolean(item)) {
                checkedColumns[i] = item;
            }
        }
        storeSections(checkedColumns)
        props.history.push(`/deal/${props.match.params.dealId}/termsheet/${props.match.params.termsheetId}/edit`);
    }

    const handleSearch = (e) => {
        const searchKey = e.target.value;
        setSearchKey(searchKey);
        clearTimeout(timer);
        timer = setTimeout(() => {
            setSections(searchSections(searchKey));
        }, 1000)
    }

    useEffect(() => {
        if (!props.columns.length) {
            const { termsheetId } = props.match.params;
            getTermsheet(termsheetId);
        }
    }, []);
    useEffect(() => {
        setCheckedColumns(props.columns);
    }, [props.columns.length]);
    return (
        <Container>
            <Spacer size={20} />
            <Form onSubmit={(e) => e.preventDefault()}>
                <Row>
                    <Col>
                        <Input
                            className="form-control"
                            name="searchKey"
                            type="text"
                            placeholder="enter info here"
                            icon={<FontAwesomeIcon icon={faSearch} />}
                            meta={{}}
                            input={{
                                value: searchKey,
                                onChange: handleSearch
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    {
                        sections.map((section) => (
                            <Col md="4" key={section.title}>
                                <b>{section.title}</b>
                                <hr className="section-hr" />
                                {
                                    section.options.map((option) => {
                                        const key = `${section.title}-${option.title}`;
                                        return (
                                            <Input
                                                className="form-control"
                                                key={key}
                                                label={option.title}
                                                type="checkbox"
                                                input={{
                                                    checked: findChecked(checkedColumns, section, option).isChecked,
                                                    name: key,
                                                    onChange: (e) => handleCheck(e, section, option)
                                                }}
                                                meta={{}}
                                                style={{ paddingBottom: 5 }}
                                            />
                                        )
                                    })
                                }
                                <Spacer size={20} />
                            </Col>
                        ))
                    }
                </Row>
                <Row>
                    <Col className="text-right" xs="12">
                        <Button
                            variant="secondary"
                            onClick={() => setCheckedColumns(props.columns)}
                        >
                            RESET
                    </Button>
                    &nbsp;
                    <Button variant="primary" onClick={handleContinue} disabled={!checkedColumns.length} >
                            CONTINUE
                    </Button>
                    </Col>
                </Row>
            </Form>
            <Spacer size={20} />
        </Container>
    )
}

const mapStateToProps = (state) => ({
    columns: state.termsheet.termsheet.columns || [],
});

export default connect(mapStateToProps)(SelectSections)
