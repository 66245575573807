
const sections = [
    {
        title: 'Descriptive Provisions',
        options: [
            {
                title: "Borrower",
            },
            {
                title: "Purpose of Financing",
            },
            {
                title: "Payment",
            },
            {
                title: "Key Principal(s)",
            },
            {
                title: "Guarantor(s)",
            },
            {
                title: "Sponsor",
            },
            {
                title: "Manager",
            },
            {
                title: 'Collateral / Security Description',
            }
        ]
    },
    {
        title: 'Bank Loan Provisions',
        options: [
            {
                title: 'Banking Relationships',
            },
            {
                title: "Bi-Furcation / Syndication / Cooperation",
            },
            {
                title: "HVCRE",
            },
        ]
    },
    {
        title: 'Cash Management',
        options: [
            {
                title: 'Cash Management / Lockbox',
            }
        ]
    },
    {
        title: 'Construction Provisions',
        options: [
            {
                title: 'Contractor',
            },
            {
                title: 'Construction Contract',
            },
            {
                title: 'Loan Draws',
            },
            {
                title: 'Equity Balancing',
            },
            {
                title: 'Unused Fee',
            },
            {
                title: 'Completion Guaranty',
            }

        ]
    },
    {
        title: 'Fees / Deposits',
        options: [
            {
                title: 'Origination Fee',
                sectionType: 'percent',
            },
            {
                title: 'Exit Fee',
                sectionType: 'percent',
            },
            {
                title: 'Legal Deposit',
                sectionType: 'currency',
            },
            {
                title: 'Third Party Deposit',
                sectionType: 'currency',
            },
            {
                title: 'Expense Deposit',
                sectionType: 'currency',
            },
            {
                title: 'Underwriting Fee',
                sectionType: 'currency',
            },
        ]
    },
    {
        title: 'Floating Rate Provisions',
        options: [
            {
                title: 'Interest Protection',
            }
        ]
    },
    {
        title: 'Hotel Specific Provisions',
        options: [
            {
                title: 'PIP',
            },
            {
                title: 'Franchise Agreement',
            },
            {
                title: 'FF&E Reserve',
                sectionType: 'percent',
            }
        ]
    },
    {
        title: 'Legal',
        options: [
            {
                title: 'Closing Conditions',
            },
            {
                title: 'Material Adverse Change',
            },
            {
                title: 'Not a Commitment',
            },
            {
                title: 'Securitization',
            },
            {
                title: 'Exclusivity',
            },
            {
                title: 'Intermediary',
            },
            {
                title: 'Additional Debt',
            },
            {
                title: 'Confidentiality',
            },
            {
                title: 'Indemnity',
            },
            {
                title: 'Loan Assumption',
            },
            {
                title: 'Reporting Requirements',
            },
            {
                title: 'Governing Law',
            },
            {
                title: 'US Patriot Act',
            },
            {
                title: 'Expiration',
            },
            {
                title: 'PIP',
            },
        ]
    },
    {
        title: 'Prepayment',
        options: [
            {
                title: 'Prepayment',
            },
            {
                title: 'Lockout',
            },
            {
                title: 'Minimum Interest',
            },
        ]
    },
    {
        title: 'Rate Lock',
        options: [
            {
                title: 'Rate Lock Provisions',
            },
            {
                title: 'Good faith Deposit',
                sectionType: 'percent',
            },

        ]
    },
    {
        title: 'Recourse',
        options: [
            {
                title: 'Recourse',
            },
            {
                title: 'Non-Recourse',
            },
            {
                title: 'Covenants',
            },
        ]
    },
    {
        title: 'Reserves',
        options: [
            {
                title: 'Deferred Maintenance Reserves',
            },
            {
                title: 'Repair / Replacement Reserves',
            },
            {
                title: 'TI/LC Reserves',
            },
            {
                title: 'Seasonality Reserves',
            },
            {
                title: 'Tax & Insurance',
            },
            {
                title: 'Interest Reserve',
            },

        ]
    },
    {
        title: 'Underwriting Requirements',
        options: [
            {
                title: 'Equity Requirement',
            },
            {
                title: 'Min DSCR',
                sectionType: 'numeric',
            },
            {
                title: 'Max LTC',
                sectionType: 'percent',
            },
            {
                title: 'Max LTV',
                sectionType: 'percent',
            },
            {
                title: 'Min Debt Yield',
                sectionType: 'percent',
            },
        ]
    },
]
const easeSections = (() => {
    const easeData = {};
    for (let i = 0; i < sections.length; i++) {
        for (let j = 0; j < sections[i].options.length; j++) {
            const key = `${sections[i].title}-${sections[i].options[j].title}`;
            easeData[key] = { ...sections[i].options[j], category: sections[i].title };
        }
    }
    return easeData;
})()
export { easeSections };
export default sections;