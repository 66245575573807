import { dispatch } from "config/store";
import { actionTypes } from "./actionTypes";

export const NOTIFICATION_LIMIT = 15;
export const getNotifications = (page = 1, read) => {
  const date_obj = new Date();
  const last30days = new Date(new Date().setDate(date_obj.getDate() - 30));
  const action = {
    type: actionTypes.GET_NOTIFICATIONS,
    config: {
      method: "get",
      url: `/notifications?limit=${NOTIFICATION_LIMIT}&page=${page}&order=DESC&read=${read}&createdFrom=${last30days}`,
    },
    params: {
      page,
    },
  };

  dispatch(action);
};
export const markNotification = (id, index) => {
  const action = {
    type: actionTypes.READ_NOTIFICATIONS,
    data: { id, index },
  };
  dispatch(action);
};
export const markAllNotifications = () => {
  const action = {
    type: actionTypes.READ_ALL_NOTIFICATIONS,
  };
  dispatch(action);
};
export const deleteNotifications = () => {
  const action = {
    type: actionTypes.GET_NOTIFICATIONS,
    config: {
      method: "delete",
      url: `/notifications`,
    },
  };

  dispatch(action);
};
