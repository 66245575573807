import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Button,
  Form,
  Container,
  Row,
  Col,
  OverlayTrigger,
} from "react-bootstrap";
import { Field, reduxForm } from "redux-form";
import { reduxFormInput } from "components/form/ReduxForm";
import { required, passwordRule, passwordsMatch } from "utils/validation";
import { getUserForSignUp, setPassword } from "redux/actions";
import { storeJsonInLocal } from "utils/lsUtils";
import Spacer from "components/layout/Spacer";
import Loader from "components/ui/Loader";
import TSLogo from "components/ui/TSLogo";
import constants from "config/constants";
import withPageTitle from "components/hoc/withPageTitle";
import PasswordRequirements from "containers/auth/PasswordRequirements";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class SetPassword extends Component {
  constructor(props) {
    super(props);

    this.submitFn = this.submitFn.bind(this);
  }

  componentDidMount() {
    const paramsString = this.props.location.search;
    const searchParams = new URLSearchParams(paramsString);
    const token = searchParams.get("t");
    if (token) {
      let jsonObj = {};

      jsonObj[constants.TERMST_LOCAL_STORAGE.TEMP_TOKEN_KEY] = token;
      storeJsonInLocal(jsonObj);
      getUserForSignUp(token);
    } else {
      window.location.pathname = "/login";
    }
  }

  submitFn(data) {
    const paramsString = this.props.location.search;
    const searchParams = new URLSearchParams(paramsString);
    const token = searchParams.get("t");

    if (token) {
      setPassword({
        password: data.PASSWORD,
        temporaryToken: token,
      });
    }
  }

  render() {
    const { handleSubmit, signup, action } = this.props;
    const isFetchingUser = action.GET_USER_FOR_SIGNUP.isFetching;
    const isSettingPassword = action.SET_PASSWORD.isFetching;
    const userEmail =
      signup && signup.user && signup.user.email ? signup.user.email : "";

    return (
      <>
        {(isFetchingUser || isSettingPassword) && <Loader />}
        <header className="border-bottom">
          <Spacer size={23} />
          <h1 className="text-center">Set Password</h1>
          <Spacer size={23} />
        </header>
        <Container>
          <Spacer size={50} />
          <Form className="auth-form" onSubmit={handleSubmit(this.submitFn)}>
            <Row className="justify-content-center">
              <Col className="d-flex justify-content-center" xs="6">
                <TSLogo blue title />
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <Spacer size={50} />
              </Col>
              <Col xs="12">
                <p className="text-center">
                  {userEmail ? (
                    <>
                      {" "}
                      <Button className="fake-link" variant="fake-link">
                        {userEmail}
                      </Button>{" "}
                      is validated successfully! Please set your password.
                    </>
                  ) : (
                    <>Invalid Token or Token has been expired</>
                  )}
                </p>
                <Spacer size={50} />
              </Col>
            </Row>
            {userEmail && (
              <>
                <Row>
                  <Col xs="12" sm="6">
                    <Field
                      className="form-control"
                      component={reduxFormInput}
                      validate={[required, passwordRule]}
                      name="PASSWORD"
                      type="password"
                      placeholder="Enter password"
                      label={
                        <span>
                          Password &nbsp;&nbsp;
                          <OverlayTrigger
                            trigger={["hover", "focus"]}
                            placement="right"
                            overlay={PasswordRequirements}
                          >
                            <FontAwesomeIcon
                              icon={faInfoCircle}
                              style={{ fontSize: 17 }}
                            />
                          </OverlayTrigger>
                        </span>
                      }
                    />
                    <Spacer size={50} />
                  </Col>
                  <Col xs="12" sm="6">
                    <Field
                      className="form-control"
                      component={reduxFormInput}
                      validate={[required, passwordRule, passwordsMatch]}
                      name="CONFIRM_PASSWORD"
                      type="password"
                      placeholder="Confirm password"
                      label="Confirm Password"
                    />
                  </Col>
                </Row>
                <Spacer size={50} />
                <Row className="justify-content-center">
                  <Col className="text-center" xs="12" sm="6">
                    <Button type="submit" variant="primary">
                      Set Password
                    </Button>
                    <Spacer size={50} />
                  </Col>
                </Row>
              </>
            )}
          </Form>
          <Spacer size={50} />
        </Container>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    signup: state.auth.signup,
    action: state.action,
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "SetPassword",
    enableReinitialize: true,
  })(withPageTitle(SetPassword, "Set Password"))
);
