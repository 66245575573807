import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import { Container, Col, Row, Button, Form, Dropdown } from 'react-bootstrap';
import { getQuotes, archiveQuote } from 'redux/actions';
import Loader from 'components/ui/Loader';
import Spacer from 'components/layout/Spacer';
import { Link } from 'react-router-dom';
import { reduxFormInput as Input } from 'components/form/ReduxForm';
import CompareQuotes from './CompareQuotes';
import {
    faEllipsisH,
    faFilter
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PopupPrompt from "components/ui/PopupPrompt";
import FilterQuotes from './FilterQuotes';
import Socket from "utils/Socket";
import { defaultFilters } from 'containers/quotes/constants';
import { reduxFormNumber as InputNumber } from 'components/form/ReduxForm';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Button
        ref={ref}
        variant="inbox-action"
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
    </Button>
));
const DealQuotes = (props) => {
    const [selectedQuotes, setSelectedQuotes] = useState([]);
    const [showCompareView, setShowCompareView] = useState(false);
    const [showPrompt, setShowPrompt] = useState(false);
    const [selectedFilters, setSelectedFilters] = useState(defaultFilters)
    const handleCheck = (e, quote) => {
        const newData = [...selectedQuotes];
        e.persist()
        if (e.target.checked) {
            newData.push(quote)
        } else {
            const index = _.findIndex(selectedQuotes, (q) => (q._id === quote._id));
            newData.splice(index, 1);
        }
        setSelectedQuotes(newData)
    }

    const handleArchive = (q) => {
        archiveQuote(q._id);
    }
    const getAllQuotes = () => {
        getQuotes({
            dealId: props.match.params.dealId,
            status: selectedFilters.status.value,
            archived: selectedFilters.archived.value,
        });
    }
    const handleCancel = () => {
        setShowPrompt(false);
    }
    useEffect(() => {
        const subscriptionId = Socket.subscribe(`/deal/${props.match.params.dealId}/quotes`, getAllQuotes);
        return () => {
            Socket.unsubscribe(subscriptionId);
        }
    })
    useEffect(() => {
        getAllQuotes();
    }, [selectedFilters]);

    const onFiltersApply = (filters) => {
        setShowPrompt(false);
        setSelectedFilters(filters);
    }

    const onBackClick = () => {
        setShowCompareView(false);
        setSelectedQuotes([]);
    }
    const { isFetching } = props.action.GET_QUOTES;
    const { dealId } = props.match.params;
    return (
        <Container>
            <Spacer size={20} />
            {
                (props.action.GET_QUOTES.isFetching || props.action.ARCHIVE_QUOTE.isFetching) &&
                <Loader />
            }
            {
                (() => {
                    if (showCompareView) {
                        return <CompareQuotes quotes={selectedQuotes} onBackClick={onBackClick} />
                    }
                    return (
                        <Form className="no-padding no-border">
                            <Button
                                className="pt-2 pb-2"
                                variant="tertiary"
                                onClick={() => setShowPrompt(true)}
                            >
                                <FontAwesomeIcon className="font-14 mr-3" icon={faFilter} />
                                FILTER
                            </Button>
                            <Row style={{ borderBottom: '1px solid #e0dede', paddingBottom: 5, paddingTop: 5 }}>
                                <Col xs={1} md={1}><b>Select</b></Col>
                                <Col xs={1} md={1}><b>Status</b></Col>
                                <Col xs={1} md={1}><b>Lender</b></Col>
                                <Col xs={1} md={1}><b>Amount</b></Col>
                                <Col xs={1} md={1}><b>Term</b></Col>
                                <Col xs={1} md={1}><b>Amort</b></Col>
                                <Col xs={1} md={1}><b>IO Period</b></Col>
                                <Col xs={1} md={1}><b>Spread</b></Col>
                                <Col xs={1} md={1}><b>Index Rate</b></Col>
                                <Col xs={1} md={1}><b>Rate</b></Col>
                                <Col xs={2} md={2}></Col>
                            </Row>
                            {
                                props.quotes.map((quote) => {
                                    const isArchived = quote.isArchivedBy.indexOf(props.whoami._id) !== -1
                                    return (
                                        <Row key={quote._id} className={`deal-quote-row ${isArchived ? 'archive' : ''}`}>
                                            <Col xs={1} md={1}>
                                                {
                                                    !isArchived &&
                                                    <Input
                                                        className="form-control"
                                                        type="checkbox"
                                                        input={{
                                                            checked: Boolean(selectedQuotes.some((q) => q._id === quote._id)),
                                                            onChange: (e) => handleCheck(e, quote)
                                                        }}
                                                        meta={{}}
                                                    // style={{ paddingBottom: 5 }}
                                                    />
                                                }
                                            </Col>
                                            <Col xs={1} md={1}>{quote.status}</Col>
                                            <Col xs={1} md={1}> {quote.companyId?.companyName}</Col>
                                            <Col xs={1} md={1}>
                                                {
                                                    <InputNumber
                                                        displayType="text"
                                                        prefix={'$'}
                                                        meta={{}}
                                                        input={{
                                                            value: quote.loanAmount,
                                                        }}
                                                        thousandSeparator
                                                    />
                                                }
                                            </Col>
                                            <Col xs={1} md={1}>{quote.term} year(s)</Col>
                                            <Col xs={1} md={1}>{quote.amortization} year(s)</Col>
                                            <Col xs={1} md={1}>{quote.IOPeriod} month(s)</Col>
                                            <Col xs={1} md={1}>{quote.spread ? `${quote.spread} bps` : "--"}</Col>
                                            <Col xs={1} md={1}>
                                                <InputNumber
                                                    displayType="text"
                                                    suffix={'%'}
                                                    meta={{}}
                                                    input={{
                                                        value: quote.indexRate,
                                                    }}
                                                    decimalScale={2}
                                                    fixedDecimalScale
                                                />
                                            </Col>
                                            <Col xs={1} md={1}>
                                                <InputNumber
                                                    displayType="text"
                                                    suffix={'%'}
                                                    meta={{}}
                                                    input={{
                                                        value: quote.rate,
                                                    }}
                                                    decimalScale={2}
                                                    fixedDecimalScale
                                                />
                                            </Col>
                                            <Col xs={2} md={2} style={{ padding: 0 }}>
                                                {
                                                    !isArchived &&
                                                    <Link to={`/deal/${dealId}/termsheet/${quote.termsheetId}?viewQuoteId=${quote._id}`}>
                                                        <Button>
                                                            View
                                                        </Button>
                                                    </Link>
                                                }
                                                <Dropdown className="d-inline-block" drop="bottom">
                                                    <Dropdown.Toggle as={CustomToggle}>
                                                        <FontAwesomeIcon icon={faEllipsisH} />
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu >
                                                        <Dropdown.Item onClick={() => handleArchive(quote)}>
                                                            {isArchived ? 'Unarchive' : 'Archive'}
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </Col>
                                        </Row>
                                    )
                                })
                            }
                            {
                                (!props.quotes.length && !isFetching) &&
                                (
                                    <Row>
                                        <Col xs="12">
                                            <Spacer size={20} />
                                            <p className="text-danger text-center">
                                                Deal doesn't have any Quotes to show! Try changing filters
                                                </p>
                                        </Col>
                                    </Row>
                                )
                            }
                            <Spacer size={20} />
                            <Row>
                                <Col>
                                    {
                                        selectedQuotes.length > 1 &&
                                        <span className="tertiary-link" onClick={() => setShowCompareView(true)}>Compare quotes</span>
                                    }
                                </Col>
                            </Row>
                        </Form>
                    )
                })()
            }
            <Spacer size={20} />
            <PopupPrompt
                promptTitle={"Filter Quotes"}
                promptBody={<FilterQuotes onFiltersApply={onFiltersApply} selectedFilters={selectedFilters} />}
                show={showPrompt}
                onCancel={handleCancel}
                onHide={handleCancel}
            />
        </Container>
    )
}
const mapStateToProps = (state) => ({
    quotes: state.termsheet.quotes,
    action: state.action,
    whoami: state.auth.whoami,
})
export default connect(mapStateToProps)(DealQuotes);