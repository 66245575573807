import React from "react";
import { connect } from "react-redux";
import { Row, Col, Button, Badge } from "react-bootstrap";
import {
  getDealById,
  getComms,
  getFilteredComms,
  getCommById,
  archiveMessage,
  getGroups,
} from "redux/actions";
import queryString from "query-string";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTh,
  faFilter,
  faCommentAlt as faCommentAltSolid,
  faUserCircle,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import {
  faCommentAlt,
  faQuestionCircle,
} from "@fortawesome/free-regular-svg-icons";
import { reduxForm, reset, change } from "redux-form";
import { toast } from "react-toastify";
import { storeJsonInLocal } from "utils/lsUtils";
import Paginate from "components/ui/Paginate";
import PopupPrompt from "components/ui/PopupPrompt";
import Spacer from "components/layout/Spacer";
import CreateMessage from "containers/deals/Communications/actions/CreateMessage";
import MergeMessages from "containers/deals/Communications/actions/MergeMessages";
import SetPrivacyMessages from "containers/deals/Communications/actions/SetPrivacyMessages";
import EditQuestion from "containers/deals/Communications/actions/EditQuestion";
import InboxMessenger from "containers/deals/Communications/InboxMessenger";
import IsScrolling from "react-is-scrolling";
import constants from "config/constants";
import Socket from "utils/Socket";
import FilterInbox from "containers/deals/update/FilterInbox";
import Groups from "./actions/Groups";
import Communication from "./Communication";
import { ChatSkeleton, InboxSkeleton } from "components/ui/Skeletons";
import { isEmpty } from "lodash";
import NoResultsFound, {
  EmptyInbox,
} from "components/emptystate/NoResultsFound";

class Inbox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDesktop: false,
      page: 1,
      commURL: "",
      filters: {},
      deal: {},
      conversation: {},
      actionComm: {},
      questionDraggedInto: {},
      showPrompt: false,
      option: "",
      filter: false,
      merge: false,
      privacy: false,
      edit: false,
      isOwner: false,
      bulkComms: [],
      toggleBulkActions: false,
      viewArchived: false,
      badgeArchive: false,
      badgeMessage: false,
      badgeQuestion: false,
      detailsPosition: 0,
    };

    this.handleShowPrompt = this.handleShowPrompt.bind(this);
    this.selectComm = this.selectComm.bind(this);
    this.handleLongPress = this.handleLongPress.bind(this);
    this.handleLongRelease = this.handleLongRelease.bind(this);
    this.submitError = this.submitError.bind(this);
    this.archiveMessages = this.archiveMessages.bind(this);
    this.onCommDragStart = this.onCommDragStart.bind(this);
    this.onCommDragEnd = this.onCommDragEnd.bind(this);
    this.onCommDragOver = this.onCommDragOver.bind(this);
    this.onCommDragLeave = this.onCommDragLeave.bind(this);
    this.onCommTouchStart = this.onCommTouchStart.bind(this);
    this.onCommTouchEnd = this.onCommTouchEnd.bind(this);
    this.onCommDrop = this.onCommDrop.bind(this);
    this.onHandleViewComm = this.onHandleViewComm.bind(this);
    this.onHandleArchiveComm = this.onHandleArchiveComm.bind(this);
    this.onHandleMergeComm = this.onHandleMergeComm.bind(this);
    this.onHandleSetPrivacyComm = this.onHandleSetPrivacyComm.bind(this);
    this.onFieldSelect = this.onFieldSelect.bind(this);
    this.showAllFields = this.showAllFields.bind(this);
    this.hideAllFields = this.hideAllFields.bind(this);
  }

  submitError(text) {
    toast.error(text, {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  handleLongPress(target, id) {
    target.closest(".card").style.background = "rgba(10, 70, 127, 0.2)";

    this.buttonPressTimer = setTimeout(() => {
      if (!target.classList.contains("is-dragging")) {
        this.selectComm(id);
      }
    }, 500);
  }

  handleLongRelease(target) {
    if (target.closest(".card")) {
      target.closest(".card").style.background = "#FFFFFF";
    }
    clearTimeout(this.buttonPressTimer);
  }

  handleShowPrompt(showPrompt, val) {
    this.setState({ showPrompt, option: val });
  }

  componentDidMount() {
    const isDesktop = () => {
      const navigatorAgent =
        navigator.userAgent || navigator.vendor || window.opera;
      return !(
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series([46])0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
          navigatorAgent
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br([ev])w|bumb|bw-([nu])|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do([cp])o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly([-_])|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-([mpt])|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c([- _agpst])|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac([ \-/])|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja([tv])a|jbro|jemu|jigs|kddi|keji|kgt([ /])|klon|kpt |kwc-|kyo([ck])|le(no|xi)|lg( g|\/([klu])|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t([- ov])|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30([02])|n50([025])|n7(0([01])|10)|ne(([cm])-|on|tf|wf|wg|wt)|nok([6i])|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan([adt])|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c([-01])|47|mc|nd|ri)|sgh-|shar|sie([-m])|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel([im])|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c([- ])|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
          navigatorAgent.substr(0, 4)
        )
      );
    };

    const { match } = this.props;

    this.setState({ isDesktop: isDesktop() });

    getGroups(match.params.id);
    getDealById(match.params.id);

    this.getCommunications(this.state.viewArchived, this.state.page);
    this.commSubscriptionId = Socket.subscribe(
      `/deal/${match.params.id}/communications`,
      () => this.getCommunications(this.state.viewArchived, this.state.page)
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const { action } = this.props;
    const { conversation } = this.state;

    if (
      prevProps.action.SEARCH_COMMS.isFetched !== action.SEARCH_COMMS.isFetched
    ) {
      this.setState({ page: 1 });
    }

    if (prevState.conversation._id !== conversation._id) {
      if (this.convSubscriptionId) {
        Socket.unsubscribe(this.convSubscriptionId);
      }
      if (this.convStatusSubscriptionId) {
        Socket.unsubscribe(this.convStatusSubscriptionId);
      }
      this.convSubscriptionId = Socket.subscribe(
        `/conversation/${conversation._id}`,
        this.addNewMessage
      );
      this.convStatusSubscriptionId = Socket.subscribe(
        `/communications/public/${conversation._id}`,
        this.updateStatus
      );
    }

    if (prevProps.deal !== this.props.deal) {
      const { deal, myCompany } = this.props;

      let jsonObj = {};
      jsonObj[constants.TERMST_LOCAL_STORAGE.CURRENT_DEAL] = deal;
      storeJsonInLocal(jsonObj);

      this.setState({ deal });

      if (deal.company === myCompany._id) {
        this.setState({ isOwner: true });
      }
    }

    if (prevProps.communications !== this.props.communications) {
      const { communications } = this.props;
      const queryparams = queryString.parse(this.props.location.search);
      let bulkComms = [];
      communications.map((comm) => {
        if (comm._id === queryparams.convId) {
          this.onViewComm(comm);
        }
        bulkComms.push({
          showField: false,
          isSelected: false,
          id: comm._id,
          message:
            comm.editedInitialMsg && comm.editedInitialMsg.length > 0
              ? comm.editedInitialMsg
              : comm.initialMessage,
          type: comm.type,
        });

        return true;
      });

      const isSelectedConversationAvail = communications.find(
        (c) => c._id === conversation._id
      );
      if (!isSelectedConversationAvail) {
        this.setState({ conversation: {} });
      }

      this.setState({
        bulkComms,
      });
    }

    if (prevProps.communication !== this.props.communication) {
      const { communication } = this.props;

      this.setState({ conversation: communication });

      // window.scrollTo({
      //   top: 0,
      //   behavior: `smooth`,
      // });
    }
  }

  showAllFields() {
    let bulkComms = this.state.bulkComms;

    bulkComms.map((comm) => {
      comm.showField = true;

      return true;
    });

    this.setState({
      bulkComms,
    });
  }

  hideAllFields() {
    const { dispatch } = this.props;
    let bulkComms = this.state.bulkComms;

    bulkComms.map((comm) => {
      comm.showField = false;
      comm.isSelected = false;
      dispatch(change("Inbox", "selectComm" + comm.id, false));

      return true;
    });

    this.setState({
      bulkComms,
    });
  }

  selectComm(id) {
    const { dispatch } = this.props;
    let bulkComms = this.state.bulkComms;
    const index = bulkComms.findIndex((comm) => comm.id === id);

    bulkComms[index].showField = !bulkComms[index].showField;
    bulkComms[index].isSelected = true;

    dispatch(change("Inbox", "selectComm" + id, true));

    if (!bulkComms[index].showField) {
      bulkComms[index].isSelected = false;
      dispatch(change("Inbox", "selectComm" + id, false));
    }

    this.setState({
      bulkComms,
    });
  }

  archiveMessages() {
    if (this.state.bulkComms.length > 0) {
      this.state.bulkComms.map((comm) => {
        if (comm.isSelected) {
          archiveMessage(comm.id);
        }

        return true;
      });
    }
  }

  addNewMessage = (data) => {
    this.setState((prevState) => ({
      conversation: {
        ...prevState.conversation,
        messages: [...prevState.conversation.messages, ...data.payload],
      },
    }));
  };

  updateStatus = (data) => {
    const { whoami, myCompany } = this.props;

    if (
      whoami.role === "Lender" &&
      !data?.payload?.isPublic &&
      !data?.payload?.lender?.some((s) => s.companyId === myCompany._id)
    ) {
      this.setState({ conversation: {}, commURL: "" });
      this.getCommunications(this.state.viewArchived, this.state.page);
    }
  };

  getCommunications = (archived, page = this.state.page) => {
    const { match } = this.props;

    getComms({
      dealId: match.params.id,
      archived,
      page,
    });
  };

  onFieldSelect(e, id) {
    if (e.target.firstChild !== null) {
      let bulkComms = this.state.bulkComms;
      const index = bulkComms.findIndex((comm) => comm.id === id);

      bulkComms[index].isSelected = !bulkComms[index].isSelected;

      this.setState({
        bulkComms,
      });

      e.target.firstChild.click();
    }
  }

  onCommDragStart(e, comm) {
    if (this.state.isDesktop && comm.type === "Question") {
      if (this.state.bulkComms.filter((c) => c.isSelected).length > 1) {
        e.dataTransfer.setDragImage(
          document.getElementById("draggableImage"),
          15,
          60
        );
      } else {
        e.dataTransfer.setDragImage(
          document.querySelector(`[data-id="${comm._id}"]`),
          10,
          30
        );
      }

      let t = e.target;
      setTimeout(() => {
        t.classList.add("is-dragging");
      }, 100);

      this.state.bulkComms.map((c) => {
        if (c.isSelected) {
          setTimeout(() => {
            document
              .querySelector(`[data-id="${c.id}"]`)
              .classList.add("is-dragging");
          }, 100);
        }

        return true;
      });

      e.dataTransfer.setData("commData", JSON.stringify(comm));
    }
  }

  onCommDragEnd(e, comm) {
    if (this.state.isDesktop && comm.type === "Question") {
      e.target.classList.remove("is-dragging");

      this.state.bulkComms.map((c) => {
        if (c.isSelected) {
          document
            .querySelector(`[data-id="${c.id}"]`)
            .classList.remove("is-dragging");
        }

        return true;
      });
    }
  }

  onCommDragOver(e, comm) {
    if (
      !e.target.classList.contains("is-dragging") &&
      this.state.isDesktop &&
      comm.type === "Question"
    ) {
      e.preventDefault();
      e.target.closest(".card").style.background = "rgba(10, 70, 127, 0.2)";
    }
  }

  onCommDragLeave(e, comm) {
    if (this.state.isDesktop && comm.type === "Question") {
      e.preventDefault();
      if (e.target.closest(".card")) {
        e.target.closest(".card").style.background = "#FFFFFF";
      }
    }
  }

  onCommDrop(e, comm) {
    if (this.state.isDesktop && comm.type === "Question") {
      const data = e.dataTransfer.getData("commData");

      e.target.closest(".card").style.background = "#FFFFFF";
      this.setState({
        questionDraggedInto: comm,
        merge: true,
        toggleBulkActions: false,
        actionComm: JSON.parse(data),
      });
      this.handleShowPrompt(true);
    }
  }

  onCommTouchStart(e, comm) {
    if (
      !(
        e.target.nodeName === "svg" ||
        e.target.nodeName === "path" ||
        e.target.nodeName === "BUTTON"
      )
    ) {
      this.handleLongPress(e.target, comm._id);
    }
  }

  onCommTouchEnd(e) {
    this.handleLongRelease(e.target);
  }

  onViewComm = (comm, top) => {
    const { whoami } = this.props;
    clearTimeout(this.buttonPressTimer);
    getCommById(
      comm._id,
      comm.isArchivedBy.some((i) => i === whoami._id)
    );

    // const detailsPosition = window.matchMedia("(max-width: 991px)").matches
    //   ? 0
    //   : top;

    // window.scrollTo({
    //   top: detailsPosition,
    //   behavior: `smooth`,
    // });
    this.setState({ commURL: comm._id });
  };

  onHandleViewComm(e, comm, top) {
    e.stopPropagation();
    this.onViewComm(comm, top);
  }

  onHandleArchiveComm(e, comm) {
    e.stopPropagation();
    clearTimeout(this.buttonPressTimer);

    archiveMessage(comm._id);
    this.hideAllFields();

    this.setState({
      toggleBulkActions: false,
    });
  }

  onHandleMergeComm(e, comm) {
    e.stopPropagation();
    clearTimeout(this.buttonPressTimer);

    this.setState({
      merge: true,
      toggleBulkActions: false,
      actionComm: comm,
    });

    this.hideAllFields();
    this.handleShowPrompt(true);
  }

  onHandleSetPrivacyComm(e, comm) {
    e.stopPropagation();
    clearTimeout(this.buttonPressTimer);

    this.setState({
      showPrompt: true,
      privacy: true,
      toggleBulkActions: false,
      actionComm: comm,
    });

    this.hideAllFields();
  }

  componentWillUnmount() {
    Socket.unsubscribe([
      this.commSubscriptionId,
      this.convSubscriptionId,
      this.convStatusSubscriptionId,
    ]);
  }

  onResetFilters = () => {
    this.setState(
      {
        filters: {},
        badgeArchive: false,
        badgeMessage: false,
        badgeQuestion: false,
        viewArchived: false,
        page: 1,
      },
      () => {
        this.getCommunications(false, 1);
      }
    );
  };

  render() {
    const {
      dispatch,
      match,
      communications,
      communicationsTotal,
      whoami,
      action,
      groups,
      isScrolling,
    } = this.props;

    if (isScrolling) {
      clearTimeout(this.buttonPressTimer);
    }
    const { filters, badgeArchive, badgeMessage, badgeQuestion } = this.state;
    const queryparams = queryString.parse(this.props.location.search);
    const isLoading =
      action.ARCHIVE_MESSAGE.isFetching ||
      action.GET_COMMS.isFetching ||
      action.GET_FILTERED_COMMS.isFetching;
    const noMessages = communications && communications.length === 0;
    const isFiltersApplied =
      !isEmpty(filters) || badgeArchive || badgeMessage || badgeQuestion;
    const canShowNoResults = noMessages && !isLoading && isFiltersApplied;
    const canShowEmptyState = noMessages && !isLoading;

    return (
      <>
        <div
          id="draggableImage"
          style={{ position: "absolute", top: "-1000px", fontSize: "30px" }}
        >
          {this.state.bulkComms.filter((c) => c.isSelected).length > 1 && (
            <FontAwesomeIcon
              icon={faCommentAlt}
              style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                fontSize: "30px",
              }}
            />
          )}
          <FontAwesomeIcon
            icon={faCommentAltSolid}
            style={{
              position: "absolute",
              left: "5px",
              top: "5px",
              fontSize: "30px",
            }}
          />
        </div>
        {this.state.filter && (
          <PopupPrompt
            promptTitle={"Filter Inbox"}
            promptBody={
              <FilterInbox
                onSubmit={(filters) => {
                  if (
                    Object.keys(filters).some(
                      (datum) => filters[datum] !== undefined
                    )
                  ) {
                    this.setState({ page: 1, filters });

                    const isPublicParams = filters.isPublic
                      ? filters.isPublic.label === "Public"
                        ? { isPublic: true }
                        : { isPublic: false }
                      : {};
                    getFilteredComms({
                      dealId: match.params.id,
                      page: 1,
                      type: filters.type ? filters.type.value : undefined,
                      isAnswered: filters.isAnswered
                        ? filters.isAnswered.value === "Yes"
                          ? true
                          : false
                        : undefined,
                      archived: filters.archived
                        ? filters.archived.value === "Yes"
                          ? true
                          : false
                        : false,
                      ...isPublicParams,
                    });
                  }

                  this.setState({
                    filter: false,
                    toggleBulkActions: false,
                  });
                  this.hideAllFields();
                  this.handleShowPrompt(false);
                }}
                dealId={match.params.id}
                onResetFilters={() => this.setState({ filters: {} })}
                filters={this.state.filters}
              />
            }
            show={this.state.showPrompt}
            onCancel={() => {
              this.setState({ filter: false });
              this.handleShowPrompt(false);
            }}
            onHide={() => {
              this.setState({ filter: false });
              this.handleShowPrompt(false);
            }}
          />
        )}
        {this.state.option && this.state.option.length > 0 && (
          <PopupPrompt
            promptTitle={
              this.state.option === "Message" ? "Send Message" : "Ask Question"
            }
            promptBody={
              <CreateMessage
                option={this.state.option}
                dealId={match.params.id}
                onSubmit={() => {
                  this.handleShowPrompt(false);
                  this.setState({ option: "" });
                  dispatch(reset("CreateMessage"));
                }}
              />
            }
            show={this.state.showPrompt}
            onCancel={() => {
              this.handleShowPrompt(false);
              this.setState({ option: "" });
              dispatch(reset("CreateMessage"));
            }}
            onHide={() => {
              this.handleShowPrompt(false);
              this.setState({ option: "" });
              dispatch(reset("CreateMessage"));
            }}
          />
        )}
        {this.state.edit && (
          <PopupPrompt
            promptTitle={"Edit"}
            promptBody={
              <EditQuestion
                actionComm={this.state.actionComm}
                onSubmit={() => {
                  this.handleShowPrompt(false);
                  this.setState({ edit: false, actionComm: {} });
                  dispatch(reset("EditQuestion"));
                }}
              />
            }
            show={this.state.showPrompt}
            onCancel={() => {
              this.handleShowPrompt(false);
              this.setState({ edit: false, actionComm: {} });
              dispatch(reset("EditQuestion"));
            }}
            onHide={() => {
              this.handleShowPrompt(false);
              this.setState({ edit: false, actionComm: {} });
              dispatch(reset("EditQuestion"));
            }}
          />
        )}
        {this.state.merge &&
          (this.state.isOwner || (whoami && whoami.role === "Admin")) && (
            <PopupPrompt
              promptTitle={"Merge"}
              promptBody={
                <MergeMessages
                  dealId={match.params.id}
                  openConversation={
                    Object.keys(this.state.conversation).length > 0
                      ? this.state.conversation
                      : {}
                  }
                  allMessages={communications}
                  questionDraggedInto={{
                    label:
                      this.state.questionDraggedInto.editedInitialMsg &&
                      this.state.questionDraggedInto.editedInitialMsg.length > 0
                        ? this.state.questionDraggedInto.editedInitialMsg
                        : this.state.questionDraggedInto.initialMessage,
                    value: this.state.questionDraggedInto._id,
                  }}
                  actionComm={
                    Object.keys(this.state.actionComm).length > 0
                      ? {
                          id: this.state.actionComm._id,
                          message:
                            this.state.actionComm.editedInitialMsg &&
                            this.state.actionComm.editedInitialMsg.length > 0
                              ? this.state.actionComm.editedInitialMsg
                              : this.state.actionComm.initialMessage,
                          isPublic: this.state.actionComm.isPublic,
                          selected: true,
                        }
                      : {}
                  }
                  selectedMessages={this.state.bulkComms.filter((c) => {
                    return c.isSelected;
                  })}
                  onSubmit={() => {
                    this.handleShowPrompt(false);
                    this.setState({ merge: false });
                    dispatch(reset("MergeMessages"));
                  }}
                />
              }
              show={this.state.showPrompt}
              onCancel={() => {
                this.handleShowPrompt(false);
                this.setState({ merge: false, mergeSingle: {} });
                dispatch(reset("MergeMessages"));
              }}
              onHide={() => {
                this.handleShowPrompt(false);
                this.setState({ merge: false, mergeSingle: {} });
                dispatch(reset("MergeMessages"));
              }}
            />
          )}
        {this.state.privacy &&
          (this.state.isOwner || (whoami && whoami.role === "Admin")) && (
            <PopupPrompt
              promptTitle={"Privacy"}
              promptBody={
                <SetPrivacyMessages
                  borrowerId={this.state.deal.company}
                  borrowerTeam={this.state.deal.teamId}
                  lendersAssigned={this.state.deal.lendersAssigned}
                  groupsAssigned={this.state.actionComm.groups}
                  actionComm={this.state.actionComm}
                  whoami={whoami}
                  groups={groups}
                  onSubmit={() => {
                    if (this.state.actionComm) {
                      getCommById(
                        this.state.actionComm._id,
                        this.state.actionComm.isArchivedBy.some(
                          (i) => i === whoami._id
                        )
                      );
                    }
                    this.handleShowPrompt(false);
                    this.setState({ privacy: false, actionComm: {} });
                    dispatch(reset("SetPrivacyMessages"));
                    this.getCommunications(
                      this.state.viewArchived,
                      this.state.page
                    );
                  }}
                />
              }
              show={this.state.showPrompt}
              onCancel={() => {
                this.handleShowPrompt(false);
                this.setState({ privacy: false, actionComm: {} });
                dispatch(reset("SetPrivacyMessages"));
              }}
              onHide={() => {
                this.handleShowPrompt(false);
                this.setState({ privacy: false, actionComm: {} });
                dispatch(reset("SetPrivacyMessages"));
              }}
            />
          )}
        {this.state.groups && (
          <PopupPrompt
            promptTitle={"Groups"}
            promptBody={
              <Groups
                onSubmit={() => {
                  this.handleShowPrompt(false);
                  this.setState({ groups: false });
                  dispatch(reset("Groups"));
                }}
              />
            }
            show={this.state.showPrompt}
            onCancel={() => {
              this.handleShowPrompt(false);
              this.setState({ groups: false });
              dispatch(reset("Groups"));
            }}
            onHide={() => {
              this.handleShowPrompt(false);
              this.setState({ groups: false });
              dispatch(reset("Groups"));
            }}
          />
        )}
        {canShowEmptyState && !canShowNoResults ? (
          <>
            <Row>
              <Col xs="12">
                <EmptyInbox callBack={this.handleShowPrompt} />
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row noGutters={true}>
              <Col className="d-flex align-items-center" xs="12">
                <Button
                  className="fake-link bold inbox-action-item"
                  variant="fake-link"
                  onClick={() => {
                    if (this.state.toggleBulkActions) {
                      this.hideAllFields();
                    } else {
                      this.showAllFields();
                    }

                    this.setState((prevState) => ({
                      toggleBulkActions: !prevState.toggleBulkActions,
                    }));
                  }}
                  tabIndex="0"
                >
                  <FontAwesomeIcon icon={faTh} /> <span>Bulk Actions</span>
                </Button>
                {(this.state.toggleBulkActions ||
                  this.state.bulkComms.filter((comm) => comm.showField).length >
                    1) && (
                  <div className="bulk-actions">
                    {(this.state.isOwner ||
                      (whoami && whoami.role === "Admin")) && (
                      <Button
                        className="fake-link ml-3 inbox-action-item"
                        variant="fake-link"
                        onClick={() => {
                          if (this.state.bulkComms.some((c) => c.isSelected)) {
                            this.setState({ merge: true });
                            this.handleShowPrompt(true);
                          } else {
                            this.submitError("No items selected!");
                          }
                        }}
                      >
                        Merge
                      </Button>
                    )}
                    <Button
                      className="fake-link ml-3 inbox-action-item"
                      variant="fake-link"
                      onClick={() => {
                        if (this.state.bulkComms.some((c) => c.isSelected)) {
                          this.archiveMessages();
                        } else {
                          this.submitError("No items selected!");
                        }
                      }}
                    >
                      {this.state.viewArchived ? "Unarchive" : "Archive"}
                    </Button>
                  </div>
                )}
                <div className="d-none d-md-inline-block">
                  <span className="pl-3 pr-3">|</span>
                  <Button
                    className="fake-link bold inbox-action-item"
                    variant="fake-link"
                    onClick={() => this.handleShowPrompt(true, "Message")}
                  >
                    Create Message
                  </Button>

                  <span className="pl-3 pr-3">|</span>
                  <Button
                    className="fake-link bold inbox-action-item"
                    variant="fake-link"
                    onClick={() => this.handleShowPrompt(true, "Question")}
                  >
                    Ask Question
                  </Button>
                </div>
                {whoami && whoami.role === "Borrower" && (
                  <div className="d-flex flex-1 justify-content-end">
                    <Button
                      className="fake-link bold inbox-action-item"
                      variant="fake-link"
                      onClick={() => {
                        this.setState({ groups: true });
                        this.handleShowPrompt(true);
                      }}
                      tabIndex="0"
                    >
                      <FontAwesomeIcon icon={faUserCircle} />{" "}
                      <span>Groups</span>
                    </Button>
                  </div>
                )}
              </Col>
              <Col className="border-bottom" xs="12">
                {this.state.toggleBulkActions && (
                  <>
                    <div className="bulk-actions-mobile">
                      <Spacer size={5} />
                      {(this.state.isOwner ||
                        (whoami && whoami.role === "Admin")) && (
                        <Button
                          className="fake-link inbox-action-item"
                          variant="fake-link"
                          onClick={() => {
                            if (
                              this.state.bulkComms.some((c) => c.isSelected)
                            ) {
                              this.setState({ merge: true });
                              this.handleShowPrompt(true);
                            } else {
                              this.submitError("No items selected!");
                            }
                          }}
                        >
                          Merge
                        </Button>
                      )}
                      <Button
                        className="fake-link ml-3 inbox-action-item"
                        variant="fake-link"
                        onClick={() => {
                          if (this.state.bulkComms.some((c) => c.isSelected)) {
                            this.setState({ archive: true });
                            this.handleShowPrompt(true);
                          } else {
                            this.submitError("No items selected!");
                          }
                        }}
                      >
                        Archive
                      </Button>
                      {(this.state.isOwner ||
                        (whoami && whoami.role === "Admin")) && (
                        <Button
                          className="fake-link ml-3 inbox-action-item"
                          variant="fake-link"
                          onClick={() => {
                            if (
                              this.state.bulkComms.some((c) => c.isSelected)
                            ) {
                              this.setState({ public: true });
                              this.handleShowPrompt(true);
                            } else {
                              this.submitError("No items selected!");
                            }
                          }}
                        >
                          Make Public
                        </Button>
                      )}
                      <Spacer size={5} />
                    </div>
                  </>
                )}
              </Col>
              <Col className="quick-filters text-right" xs="12">
                <div className="text-right mt-2 mb-2 filter-section">
                  <Button
                    className="pt-2 pb-2"
                    onClick={() => {
                      this.handleShowPrompt(true);
                      this.setState({ filter: true });
                    }}
                    variant="tertiary"
                  >
                    <FontAwesomeIcon className="font-14 mr-3" icon={faFilter} />
                    Filter
                  </Button>
                </div>
                <div className="filter-badges">
                  <Badge
                    className={
                      this.state.badgeArchive ? "ml-2 selected" : "ml-2 outline"
                    }
                    variant="secondary"
                    tabIndex="0"
                    pill
                    onClick={() => {
                      this.setState(
                        (prevState) => ({
                          badgeArchive: !prevState.badgeArchive,
                          viewArchived: !prevState.viewArchived,
                          filters: {},
                        }),
                        function () {
                          let type;

                          if (
                            (this.state.badgeMessage &&
                              this.state.badgeQuestion) ||
                            (!this.state.badgeMessage &&
                              !this.state.badgeQuestion)
                          ) {
                            type = "";
                          } else if (this.state.badgeMessage) {
                            type = "Message";
                          } else if (this.state.badgeQuestion) {
                            type = "Question";
                          } else {
                            type = "";
                          }

                          getFilteredComms({
                            dealId: match.params.id,
                            page: 1,
                            type,
                            isAnswered: undefined,
                            archived: this.state.badgeArchive,
                            isPublic: undefined,
                          });
                        }
                      );
                    }}
                    onKeyDown={(e) =>
                      e.keyCode === 13 ? document.activeElement.click() : null
                    }
                  >
                    Archived
                  </Badge>
                  <Badge
                    className={
                      this.state.badgeMessage ? "ml-2 selected" : "ml-2 outline"
                    }
                    variant="secondary"
                    tabIndex="0"
                    pill
                    onClick={() => {
                      this.setState(
                        (prevState) => ({
                          badgeMessage: !prevState.badgeMessage,
                          filters: {},
                        }),
                        function () {
                          let type;

                          if (
                            (this.state.badgeMessage &&
                              this.state.badgeQuestion) ||
                            (!this.state.badgeMessage &&
                              !this.state.badgeQuestion)
                          ) {
                            type = "";
                          } else if (this.state.badgeMessage) {
                            type = "Message";
                          } else if (this.state.badgeQuestion) {
                            type = "Question";
                          } else {
                            type = "";
                          }

                          getFilteredComms({
                            dealId: match.params.id,
                            page: 1,
                            type,
                            isAnswered: undefined,
                            archived: this.state.badgeArchive,
                            isPublic: undefined,
                          });
                        }
                      );
                    }}
                    onKeyDown={(e) =>
                      e.keyCode === 13 ? document.activeElement.click() : null
                    }
                  >
                    Message
                  </Badge>
                  <Badge
                    className={
                      this.state.badgeQuestion
                        ? "ml-2 selected"
                        : "ml-2 outline"
                    }
                    variant="secondary"
                    tabIndex="0"
                    pill
                    onClick={() => {
                      this.setState(
                        (prevState) => ({
                          badgeQuestion: !prevState.badgeQuestion,
                          filters: {},
                        }),
                        function () {
                          let type;

                          if (
                            (this.state.badgeMessage &&
                              this.state.badgeQuestion) ||
                            (!this.state.badgeMessage &&
                              !this.state.badgeQuestion)
                          ) {
                            type = "";
                          } else if (this.state.badgeMessage) {
                            type = "Message";
                          } else if (this.state.badgeQuestion) {
                            type = "Question";
                          } else {
                            type = "";
                          }

                          getFilteredComms({
                            dealId: match.params.id,
                            page: 1,
                            type,
                            isAnswered: undefined,
                            archived: this.state.badgeArchive,
                            isPublic: undefined,
                          });
                        }
                      );
                    }}
                    onKeyDown={(e) =>
                      e.keyCode === 13 ? document.activeElement.click() : null
                    }
                  >
                    Question
                  </Badge>
                </div>
              </Col>
            </Row>
            {/* INBOX STARTS HERE */}
            <Row className="inbox-row position-relative">
              <Col xs="12">
                {canShowNoResults && (
                  <NoResultsFound onReset={this.onResetFilters} />
                )}
              </Col>

              <Col xs="12">
                <Spacer size={20} />
              </Col>
              <Col xs="12" lg="6">
                {isLoading && <InboxSkeleton />}
                {communications &&
                  communications.length > 0 &&
                  communications.map((comm, idx) => {
                    return (
                      <Communication
                        key={idx}
                        whoami={whoami}
                        comm={comm}
                        commURL={this.state.commURL}
                        isOwner={this.state.isOwner}
                        showField={this.state.bulkComms.some(
                          (c) => c.id === comm._id && c.showField
                        )}
                        onFieldSelect={this.onFieldSelect}
                        onHandleViewComm={this.onHandleViewComm}
                        onHandleMergeComm={this.onHandleMergeComm}
                        onHandleArchiveComm={this.onHandleArchiveComm}
                        onHandleSetPrivacyComm={this.onHandleSetPrivacyComm}
                        onCommDragStart={this.onCommDragStart}
                        onCommDragEnd={this.onCommDragEnd}
                        onCommDragOver={this.onCommDragOver}
                        onCommDragLeave={this.onCommDragLeave}
                        onCommDrop={this.onCommDrop}
                        onCommTouchStart={this.onCommTouchStart}
                        onCommTouchEnd={this.onCommTouchEnd}
                        buttonPressTimer={this.buttonPressTimer}
                      />
                    );
                  })}
                {communications && communications.length > 0 && (
                  <Paginate
                    page={this.state.page}
                    list={communications}
                    listTotalCount={communicationsTotal}
                    listOffset={15}
                    previousPage={(p) => {
                      this.setState({ page: p });
                      this.getCommunications(this.state.viewArchived, p);

                      window.scrollTo({
                        top: 0,
                        behavior: `smooth`,
                      });
                    }}
                    nextPage={(p) => {
                      this.setState({ page: p });
                      this.getCommunications(this.state.viewArchived, p);

                      window.scrollTo({
                        top: 0,
                        behavior: `smooth`,
                      });
                    }}
                  />
                )}
              </Col>
              <Col
                className="d-table messenger-col"
                xs="12"
                lg="6"
                style={{ top: this.state.detailsPosition + 20, right: 0 }}
              >
                <div className="sticky-top">
                  {action.GET_COMM_BY_ID.isFetching ? (
                    <div className="text-center">
                      <ChatSkeleton />
                    </div>
                  ) : (
                    <>
                      {Object.keys(this.state.conversation).length > 0 && (
                        <InboxMessenger
                          key={this.state.conversation._id}
                          actionComm={this.state.actionComm}
                          isOwner={this.state.isOwner}
                          queryparams={queryparams}
                          conversation={this.state.conversation}
                          lendersAssigned={this.state.deal.lendersAssigned}
                          onGoToMergedQuestion={(comm) => {
                            getCommById(comm);
                            this.setState({ commURL: comm });
                          }}
                          onMergeQuestion={(e, comm) => {
                            this.onHandleMergeComm(e, comm);
                          }}
                          onDragOver={(e) => {
                            if (
                              !e.target.classList.contains("is-dragging") &&
                              this.state.isDesktop
                            ) {
                              e.preventDefault();
                              document.querySelector(
                                "#messenger"
                              ).style.background = "rgba(10, 70, 127, 0.2)";
                            }
                          }}
                          onDragLeave={(e) => {
                            if (this.state.isDesktop) {
                              e.preventDefault();
                              document.querySelector(
                                "#messenger"
                              ).style.background = "#FFFFFF";
                            }
                          }}
                          onDrop={(e) => {
                            if (this.state.isDesktop) {
                              const data = e.dataTransfer.getData("commData");

                              if (
                                JSON.parse(data)._id !==
                                this.state.conversation._id
                              ) {
                                this.setState({
                                  questionDraggedInto: this.state.conversation,
                                  merge: true,
                                  toggleBulkActions: false,
                                  actionComm: JSON.parse(data),
                                });
                                this.handleShowPrompt(true);
                              } else {
                                this.submitError(
                                  "Can't merge question into itself!"
                                );
                              }

                              document.querySelector(
                                "#messenger"
                              ).style.background = "#FFFFFF";
                            }
                          }}
                          onSetConversationPrivacy={(e, conversation) => {
                            this.onHandleSetPrivacyComm(e, conversation);
                          }}
                          onEditQuestion={(conversation) => {
                            this.setState({
                              showPrompt: true,
                              edit: true,
                              actionComm: conversation,
                            });
                          }}
                          onExit={() => {
                            window.history.replaceState(
                              null,
                              null,
                              window.location.pathname
                            );
                            this.setState({ conversation: {}, commURL: "" });
                          }}
                        />
                      )}
                    </>
                  )}

                  <Spacer size={20} />
                </div>
              </Col>
              <Col xs="12">
                <Spacer size={20} />
              </Col>
            </Row>
            <div className="mobile-messages">
              <Row noGutters={true}>
                <Col className="border-right" xs="3">
                  <Button
                    onClick={() => this.handleShowPrompt(true, "Message")}
                  >
                    <Spacer size={10} />
                    <p>
                      <FontAwesomeIcon icon={faCommentAlt} />
                      <br /> <span>Message</span>
                    </p>
                    <Spacer size={10} />
                  </Button>
                </Col>
                <Col className="border-right" xs="3">
                  <Button
                    onClick={() => this.handleShowPrompt(true, "Question")}
                  >
                    <Spacer size={10} />
                    <p>
                      <FontAwesomeIcon icon={faQuestionCircle} />
                      <br /> <span>Question</span>
                    </p>
                    <Spacer size={10} />
                  </Button>
                </Col>
                <Col className="border-right" xs="3">
                  <Button
                    onClick={() => {
                      this.handleShowPrompt(true);
                      this.setState({ filter: true });
                    }}
                  >
                    <Spacer size={10} />
                    <p>
                      <FontAwesomeIcon icon={faFilter} />
                      <br />
                      <span>Filter</span>
                    </p>
                    <Spacer size={10} />
                  </Button>
                </Col>
                <Col className="border-right" xs="3">
                  <Button
                    onClick={() => this.handleShowPrompt(true, "Message")}
                  >
                    <Spacer size={10} />
                    <p>
                      <FontAwesomeIcon icon={faSearch} />
                      <br />
                      <span>Search</span>
                    </p>
                    <Spacer size={10} />
                  </Button>
                </Col>
              </Row>
            </div>
          </>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    action: state.action,
    whoami: state.auth.whoami,
    deal: state.deal.deal,
    myCompany: state.company.myCompany,
    groups: state.comm.groups,
    communication: state.comm.communication,
    communications: state.comm.communications,
    communicationsTotal: state.comm.communicationsTotal,
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "Inbox",
    enableReinitialize: true,
  })(IsScrolling(Inbox))
);
