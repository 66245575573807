import React from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import {
  faMobileAlt,
  faPhone,
  faEnvelope,
  faLink,
} from "@fortawesome/free-solid-svg-icons";

const SocialMediaButton = (props) => {
  let icon;
  switch (props.icon) {
    case "linkedin":
      icon = faLinkedinIn;
      break;
    case "email":
      icon = faEnvelope;
      break;
    case "mobile":
      icon = faMobileAlt;
      break;
    case "office":
      icon = faPhone;
      break;
    case "link":
      icon = faLink;
      break;
    default:
      return;
  }

  let url;
  let data;

  if (props.url) {
    if (props.url.indexOf("https://") >= 0) {
      url = props.url;
    } else {
      url = "https://" + props.url;
    }
  } else {
    data = props.data;
  }

  return (
    <Button
      className={"btn-social " + (props.block ? "d-block" : "")}
      href={url ? url : data}
      target="_blank"
      variant="primary"
    >
      <FontAwesomeIcon icon={icon} />
    </Button>
  );
};

export default SocialMediaButton;
