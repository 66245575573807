import React from "react";
import { connect } from "react-redux";
import { Button, Form, Row, Col, Spinner } from "react-bootstrap";
import { reduxForm, Field, change } from "redux-form";
import { reduxFormSelect, reduxFormInput } from "components/form/ReduxForm";
import { getAllCompanies, updateDealLenders, getDealById } from "redux/actions";
import { toast } from "react-toastify";
import Spacer from "components/layout/Spacer";

class ManageDealLenders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lendersAssigned: [],
      companies: [],
      addLenders: [],
      deleteLenders: [],
    };

    this.lenderRef = React.createRef();
    this.addLender = this.addLender.bind(this);
    this.removeLender = this.removeLender.bind(this);
    this.submitError = this.submitError.bind(this);
    this.submitFn = this.submitFn.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.companies !== this.props.companies) {
      const { companies } = this.props;

      companies.map((company) => {
        if (company.companyType === "Lender") {
          this.setState((prevState) => ({
            companies: [
              ...prevState.companies,
              {
                label: company.companyName,
                value: company._id,
                name: company.companyName,
              },
            ],
          }));
        }

        return true;
      });
    }
  }

  componentDidMount() {
    const { auth, dealId } = this.props;

    if (auth && auth.role === "Admin") {
      getAllCompanies();
      getDealById(dealId);
    }
  }

  submitFn() {
    const { deal, closePrompt } = this.props;
    const dealId = deal._id;

    this.state.deleteLenders.map((id) => {
      updateDealLenders(dealId, {
        remove: id,
      });

      return true;
    });

    this.state.addLenders.map((lender) => {
      updateDealLenders(dealId, {
        add: lender.value,
        companyName: lender.name,
      });

      return true;
    });

    closePrompt();
  }

  submitError(text) {
    toast.error(text, {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  addLender(data) {
    const { dispatch, deal } = this.props;

    const hasLender = deal.lendersAssigned.some((lender) => {
      if (lender.companyId === data.value) {
        return true;
      } else {
        return false;
      }
    });

    if (hasLender) {
      this.submitError("Lender already assigned!");
    } else {
      deal.lendersAssigned.push({
        companyId: data.value,
        companyName: data.label,
      });

      this.setState((prevState) => ({
        addLenders: [...prevState.addLenders, data],
      }));
    }

    dispatch(change("ManageDealLenders", "companies", null));
  }

  removeLender(id) {
    const { deal } = this.props;

    this.setState((prevState) => ({
      deleteLenders: [...prevState.deleteLenders, id],
    }));

    deal.lendersAssigned = deal.lendersAssigned.filter((lender) => {
      return lender.companyId !== id;
    });
  }

  render() {
    const { auth, closePrompt, handleSubmit, action, deal } = this.props;
    const { isFetching } = action.GET_ALL_COMPANIES;

    if (auth.role !== "Admin") {
      closePrompt();

      return <></>;
    } else {
      return (
        <>
          <p className="color-medium-gray">
            Lenders share access to previous and future private communications
            and files.
          </p>
          <Spacer size={20} />
          <Form
            className="no-padding no-border"
            onSubmit={handleSubmit(this.submitFn)}
          >
            <Row>
              <Col xs="12">
                <h4 className="bold">Companies</h4>
                <Spacer size={20} />
              </Col>
              <Col xs="8" sm="10">
                <Field
                  ref={this.lenderRef}
                  className="form-control"
                  component={reduxFormSelect}
                  name="companies"
                  type="select"
                  options={this.state.companies}
                  isLoading={action.GET_ALL_COMPANIES.isFetching}
                  placeholder="Select company"
                  label="*Select company"
                />
                <Spacer size={20} />
              </Col>
              <Col xs="4" sm="2">
                <Button
                  className="full-width"
                  variant="primary"
                  onClick={() =>
                    this.lenderRef.current.value
                      ? this.addLender(this.lenderRef.current.value)
                      : null
                  }
                >
                  Add
                </Button>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <h4 className="bold">Lenders Assigned</h4>
                <Spacer size={20} />
              </Col>
            </Row>
            {!isFetching && Object.keys(deal).length > 0 ? (
              <Row>
                {deal.lendersAssigned &&
                  deal.lendersAssigned.map((lender, idx) => {
                    return (
                      <Col
                        className="field-wrapper-no-spacer"
                        xs="12"
                        key={idx}
                      >
                        <Field
                          className="form-control"
                          component={reduxFormInput}
                          name={"lender" + idx}
                          type="text"
                          placeholder={lender.companyName}
                          disabled
                        />
                        <p
                          className="field-remove"
                          onClick={() => this.removeLender(lender.companyId)}
                          onKeyDown={(e) =>
                            e.keyCode === 13
                              ? document.activeElement.click()
                              : null
                          }
                          tabIndex="0"
                        >
                          Remove
                        </p>
                      </Col>
                    );
                  })}
              </Row>
            ) : (
              <div className="text-center">
                <Spinner animation="border" role="status" variant="primary">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </div>
            )}
            <Row>
              <Col className="text-right" xs="12">
                <Spacer size={50} />
                <Button type="submit" variant="primary">
                  Update
                </Button>
              </Col>
            </Row>
          </Form>
        </>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    action: state.action,
    deal: state.deal.deal,
    company: state.company.company,
    companies: state.admin.companies,
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "ManageDealLenders",
    enableReinitialize: true,
  })(ManageDealLenders)
);
