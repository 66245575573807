import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import toLower from "lodash/toLower";
import {
  Form,
  Row,
  Col,
  Button,
  Container,
  Table as BootstrapTable,
} from "react-bootstrap";
import { getManageClauses } from "redux/actions";
import {
  reduxFormSearch,
  reduxFormTextarea,
  reduxFormWYSIWYG as TextArea,
} from "components/form/ReduxForm";
import Spacer from "components/layout/Spacer";
import { Field, reduxForm } from "redux-form";
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useTable,
} from "react-table";
import DefaultColumnFilter from "components/ui/DefaultColumnFilter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleDoubleLeft,
  faAngleRight,
  faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons";
import { noSpaces } from "utils/validation";

function LoadSavedClause(props) {
  const [state] = useState({
    promptType: "",
    showPrompt: false,
    selectedClause: null,
    pagination: {
      page: 1,
      listOffset: 500,
    },
    sort: {
      direction: "ASC",
      column: "clauseName",
    },
  });

  const [searchInput, setSearchInput] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Clause Name",
        accessor: "clauseName",
        disableFilters: true,
      },
      {
        Header: "Owner",
        accessor: "owner",
        Cell: ({ row }) => {
          return (
            <div>
              <div>
                {row.original.owner?.firstName +
                  " " +
                  row.original.owner?.lastName}
              </div>
            </div>
          );
        },
        filter: (rows, columnIds, filterValue) => {
          return rows.filter((row) =>
            toLower(
              `${row.original.owner?.firstName} ${row.original.owner?.lastName}`
            ).includes(toLower(filterValue))
          );
        },
      },
      {
        Header: "Date Last Edited",
        accessor: "updatedAt",
        disableFilters: true,
        Cell: ({ row }) => {
          return (
            <div>{new Date(row.original.updatedAt).toLocaleDateString()}</div>
          );
        },
      },
    ],
    []
  );

  const tableData = useMemo(
    () =>
      props.manageClauses.rows.filter(
        (e) =>
          e.parent &&
          toLower(`${e.clauseName} ${e.displayName}`).includes(
            toLower(searchInput)
          )
      ),
    [props.manageClauses, searchInput]
  );

  const fetchClauses = () => {
    const { pagination, sort } = state;
    const { manageClauses, parentClauseId } = props;

    getManageClauses({
      page: pagination.page,
      limit: pagination.listOffset,
      search: manageClauses.search,
      order: sort.direction,
      orderColumn: sort.column,
      parentId: parentClauseId,
      status: "ACTIVE",
    });
  };

  useEffect(() => {
    fetchClauses();
  }, []);

  useEffect(() => {
    if (selectedRow) {
      props.change("notes", selectedRow.notes);
    }
  }, [selectedRow]);

  const handleRowClick = (row) => {
    setSelectedRow(row);
  };

  return (
    <Container className="">
      <Form className="no-border mx-0">
        <Row>
          <Col xs={6}>
            <p>Display Name: {selectedRow?.displayName || ""}</p>
          </Col>
          <Col xs={6}>
            <Field
              className="form-control"
              component={reduxFormSearch}
              name="search"
              type="text"
              placeholder="Search"
              inputValue={searchInput}
              input={{
                onChange: handleSearchChange,
              }}
            />
            <Spacer size={20} />
          </Col>

          <Col xs="12">
            <Table
              columns={columns}
              data={tableData}
              selectedRow={selectedRow}
              onRowClick={handleRowClick}
            />
            <Spacer size={20} />
          </Col>

          <Col xs="12">
            <Field
              disabled
              className="form-control"
              component={reduxFormTextarea}
              validate={[noSpaces]}
              name="notes"
              type="textarea"
              rows="4"
              label="Useage Note"
              placeholder="Useage Note..."
            />
            <Spacer size={20} />
          </Col>

          <Col xs="12">
            <TextArea
              disabled
              height={200}
              className="form-control"
              name="clause"
              type="text"
              label="Review Clause"
              placeholder="Review Clause"
              toolbar=""
              input={{
                name: "clause",
                value: selectedRow?.clause,
                onChange: () => {},
              }}
            />
          </Col>
        </Row>

        <Spacer size={60} />

        <Row>
          <Col className="text-right">
            <Button variant="dark" className="mr-2" onClick={props.onCancel}>
              Cancel
            </Button>
            <Button
              disabled={!selectedRow}
              variant="primary"
              onClick={() => props.onSubmit(selectedRow)}
            >
              Load Clause
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
}

const Table = ({ columns, data, selectedRow, onRowClick }) => {
  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        pageSize: 5,
      },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  return (
    <>
      <BootstrapTable bordered hover {...getTableProps({ className: "mb-1" })}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>
                  <div className="d-flex flex-column">
                    {column.render("Header")}
                    {column.canFilter && column.render("Filter")}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                className={`cursor-pointer ${
                  selectedRow?._id === row.original._id ? " row-selected" : ""
                }`}
                onClick={() => onRowClick(row.original)}
              >
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </BootstrapTable>
      <div className="pagination d-flex align-items-center">
        <Button
          variant="primary"
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
        >
          <FontAwesomeIcon icon={faAngleDoubleLeft} />
        </Button>
        <Button
          variant="primary"
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </Button>
        <Button
          variant="primary"
          onClick={() => nextPage()}
          disabled={!canNextPage}
        >
          <FontAwesomeIcon icon={faAngleRight} />
        </Button>

        <Button
          variant="primary"
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        >
          <FontAwesomeIcon icon={faAngleDoubleRight} />
        </Button>

        <span className="ml-2">
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
        <span>
          <span className="mx-2">|</span>
          Go to page:{" "}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{ width: "100px" }}
          />
        </span>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    action: state.action,
    myCompany: state.company.myCompany,
    manageClauses: state.clause.manageClauses,
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "LoadSavedClause",
    enableReinitialize: true,
  })(LoadSavedClause)
);
