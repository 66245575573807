import React from 'react';
import { Link } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import LogoBlue from 'images/logo-blue-no-text.svg';
import LogoWhite from 'images/logo-white-no-text.svg';

const TSLogo = (props) => {
  return (
    <Link className="d-flex align-items-center logo" to="/">
      { props.blue &&
        <Image className="d-inline-block" src={LogoBlue} alt="Term St. logo." height="45px" width="auto"/>
      }
      { props.white &&
        <Image className="d-inline-block" src={LogoWhite} alt="Term St. logo." height="45px" width="auto"/>
      }
      { props.title &&
        <h3 className="d-inline-block">Term St.</h3>
      }
    </Link>
  )
}

export default TSLogo;