import React from 'react';
import { Popover } from "react-bootstrap";

const PasswordRequirements = (
    <Popover id="popover-basic">
        <Popover.Content>
            <ol>
                <li>Must contain at least one number </li>
                <li>Must contain at least one uppercase letter </li>
                <li>Must contain at least one lowercase letter </li>
                <li>Must contain at least one symbol </li>
                <li>Must contain at least 8 or more characters</li>
            </ol>
        </Popover.Content>
    </Popover>
);

export default PasswordRequirements;