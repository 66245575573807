import React, { useEffect, useMemo } from "react";
import toLower from "lodash/toLower";
import startCase from "lodash/startCase";
import { faEllipsisH, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Col,
  Dropdown,
  Row,
  Table as BootstrapTable,
} from "react-bootstrap";
import {
  useTable,
  usePagination,
  useRowSelect,
  useFilters,
  useGlobalFilter,
} from "react-table";
import IndeterminateCheckbox from "components/ui/IndeterminateCheckbox";
import CustomMenu from "components/ui/CustomMenu";
import CustomToggle from "components/ui/CustomToggle";
import { useState } from "react";
import DefaultColumnFilter from "components/ui/DefaultColumnFilter";
import {
  createTermsheet,
  getManageTermsheets,
  updateTermsheet,
} from "redux/actions";
import { connect } from "react-redux";
import PopupPrompt from "components/ui/PopupPrompt";
import CreateEditTermsheet from "./CreateEditTermsheet";
import Spacer from "components/layout/Spacer";
import { Link, useHistory } from "react-router-dom";

const ManageTermsheet = (props) => {
  const history = useHistory();
  const [state, setState] = useState({
    promptType: "",
    showPrompt: false,
    selectedTermsheet: null,
    selectedTermsheets: [],
    pagination: {
      page: 1,
      listOffset: 500,
    },
    sort: {
      direction: "ASC",
      column: "termsheetName",
    },
  });

  const [selectedRows, setSelectedRows] = useState([]);

  const handleRowSelectChange = (selectedRows) => {
    setSelectedRows(selectedRows);
  };

  const handleModalClose = () =>
    setState((prevState) => ({
      ...prevState,
      showPrompt: false,
      promptType: "",
      selectedTermsheet: null,
      selectedTermsheets: [],
    }));

  const handleModelOpen = (promptType, selectedTermsheet) => {
    setState((prevState) => ({
      ...prevState,
      showPrompt: true,
      promptType,
      selectedTermsheet,
      selectedTermsheets: [selectedTermsheet],
    }));
  };

  const handleEdit = (rowValues) => {
    history.push(`/termsheet-clause/termsheet/${rowValues._id}`);
  };

  const handleCopy = (rowValues) => {
    const data = rowValues;
    createTermsheet({
      termsheetName: `${data.termsheetName} - Copy`,
      columns: data.columns,
      dealId: data.dealId,
      isTemplate: data.isTemplate,
      owner: data.owner?._id,
      adminAvailability: {
        key: data.adminAvailability?.value,
        values: [],
      },
      companyID: props.myCompany._id,
      status: "ACTIVE",
    });
  };

  const handleArchive = (rowValues) => {
    updateTermsheet(rowValues._id, {
      status: "ARCHIVED",
    });
  };

  const handleChangeStatus = (rowValues) => {
    handleModelOpen("change_status", rowValues);
  };

  const handleChangeAvailability = (rowValues) => {
    handleModelOpen("change_availability", rowValues);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Termsheet Name",
        accessor: "termsheetName",
      },
      {
        Header: "Is Template",
        accessor: "isTemplate",
        disableFilters: true,
        Cell: ({ row }) => {
          return <div>{row.original.isTemplate ? "Yes" : "No"}</div>;
        },
      },
      {
        Header: "Usage Notes",
        accessor: "notes",
        disableFilters: true,
      },
      {
        Header: "Company Owner",
        accessor: "company",
        Cell: ({ row }) => {
          return (
            <div>
              <div>{row.original.owner?.company?.companyName || "-"}</div>
              <div>
                {row.original.owner
                  ? row.original.owner?.firstName +
                    " " +
                    row.original.owner?.lastName
                  : ""}
              </div>
            </div>
          );
        },
        filter: (rows, columnIds, filterValue) => {
          return rows.filter((row) =>
            toLower(
              `${row.original.owner?.company?.companyName} ${row.original.owner?.firstName} ${row.original.owner?.lastName}`
            ).includes(toLower(filterValue))
          );
        },
      },
      {
        Header: "Date Last Edited",
        accessor: "updatedAt",
        disableFilters: true,
        Cell: ({ row }) => {
          return (
            <div>{new Date(row.original.updatedAt).toLocaleDateString()}</div>
          );
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ row }) => {
          return <div>{startCase(toLower(row.original.status))}</div>;
        },
      },
      {
        Header: "Available to",
        accessor: "adminAvailability",
        Cell: ({ row }) => {
          return (
            <div>{startCase(toLower(row.original.adminAvailability?.key))}</div>
          );
        },
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <Dropdown className="d-inline-block">
              <Dropdown.Toggle as={CustomToggle}>
                <p className="link-text">
                  <FontAwesomeIcon icon={faEllipsisH} />
                </p>
              </Dropdown.Toggle>

              <Dropdown.Menu as={CustomMenu}>
                <Dropdown.Item
                  onClick={() => handleEdit(cellProps.cell.row.original)}
                >
                  View / Edit
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => handleCopy(cellProps.cell.row.original)}
                >
                  Copy / Edit
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => handleArchive(cellProps.cell.row.original)}
                >
                  Archive
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    handleChangeStatus(cellProps.cell.row.original)
                  }
                >
                  Change Status
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    handleChangeAvailability(cellProps.cell.row.original)
                  }
                >
                  Change Availability
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          );
        },
      },
    ],
    []
  );

  const tableData = useMemo(
    () =>
      props.manageTermsheets.rows
        .filter((e) => !e.parent)
        .map((e, index) => ({
          ...e,
        })),
    [props.manageTermsheets]
  );

  const fetchTermsheets = () => {
    const { pagination, sort } = state;
    const { manageTermsheets } = props;
    getManageTermsheets({
      page: pagination.page,
      limit: pagination.listOffset,
      search: manageTermsheets.search,
      order: sort.direction,
      orderColumn: sort.column,
      isTemplate: true,
      companyId: props.myCompany._id,
    });
  };

  useEffect(() => {
    fetchTermsheets();
    handleModalClose();
  }, [
    props.lastTermsheetCreated,
    props.lastTermsheetUpdated,
    props.lastTermsheetDeleted,
  ]);

  return (
    <Row>
      <Col xs={12} className="d-flex justify-content-between">
        <div>
          {selectedRows.length > 0 && (
            <>
              Bulk Actions{" "}
              <Dropdown className="d-inline-block">
                <Dropdown.Toggle as={CustomToggle}>
                  <h4>
                    <FontAwesomeIcon icon={faChevronDown} />
                  </h4>
                </Dropdown.Toggle>

                <Dropdown.Menu as={CustomMenu}>
                  <Dropdown.Item>Archive</Dropdown.Item>
                  <Dropdown.Item>Change Status</Dropdown.Item>
                  <Dropdown.Item>Change Availability</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </>
          )}
        </div>
        <Link to="/termsheet-clause/termsheet/choice">
          <Button type="submit" variant="tertiary">
            New Termsheet
          </Button>
        </Link>
      </Col>

      <Col xs={12}>
        <Spacer size={8} />
        <Table
          columns={columns}
          data={tableData}
          onRowSelectChange={handleRowSelectChange}
        />
      </Col>

      {(state.promptType === "create" ||
        state.promptType === "edit" ||
        state.promptType === "copy" ||
        state.promptType === "change_status" ||
        state.promptType === "change_availability") && (
        <PopupPrompt
          promptTitle={
            state.promptType === "create"
              ? "Add New Termsheet"
              : state.promptType === "edit"
              ? "Edit Termsheet"
              : state.promptType === "copy"
              ? "Copy Termsheet"
              : state.promptType === "change_status"
              ? "Change Status"
              : "Change Availability"
          }
          promptBody={
            <CreateEditTermsheet
              promptType={state.promptType}
              selectedTermsheet={state.selectedTermsheet}
              onCancel={handleModalClose}
            />
          }
          show={state.showPrompt}
          onCancel={handleModalClose}
          onHide={handleModalClose}
        />
      )}
    </Row>
  );
};

const Table = ({ columns, data, onRowSelectChange }) => {
  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        pageSize: 100,
      },
    },
    useFilters,
    useGlobalFilter,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

  useEffect(() => {
    onRowSelectChange(selectedFlatRows);
  }, [selectedFlatRows]);

  return (
    <BootstrapTable bordered hover {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}>
                <div className="d-flex flex-column">
                  {column.render("Header")}
                  {column.canFilter && column.render("Filter")}
                </div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {page.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </BootstrapTable>
  );
};

function mapStateToProps(state) {
  return {
    action: state.action,
    myCompany: state.company.myCompany,
    manageTermsheets: state.termsheet.manageTermsheets,
    lastTermsheetCreated: state.termsheet.lastTermsheetCreated,
    lastTermsheetUpdated: state.termsheet.lastTermsheetUpdated,
    lastTermsheetDeleted: state.termsheet.lastTermsheetDeleted,
    lastTermsheetMerged: state.termsheet.lastTermsheetMerged,
  };
}

export default connect(mapStateToProps)(ManageTermsheet);
