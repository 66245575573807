import React from "react";
import { connect } from "react-redux";
import { getUserProfile } from "redux/actions";
import AccountProfile from "./AccountProfile";
class EditUser extends React.Component {
  componentDidMount() {
    getUserProfile(this.props.match.params.id);
  }
  render() {
    const { match, userProfile } = this.props;

    if (!userProfile._id) return null;

    return <AccountProfile match={match} profile={userProfile} />;
  }
}

function mapStateToProps(state) {
  return {
    userProfile: state.user.userProfile,
  };
}

export default connect(mapStateToProps)(EditUser);
