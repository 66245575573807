import React, { useEffect, useState } from "react";
import { Col, Button, Row, Form } from "react-bootstrap";
import { reduxForm } from "redux-form";
import Spacer from "components/layout/Spacer";
import SelectedBadges from "./SelectedBadges";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import MultiDropdown from "../Communications/MultiDropdown";

const SecuritySharing = (props) => {
  const getLenderOptions = () => {
    const { deal } = props;
    return (
      deal?.lendersAssigned?.map((e) => ({
        group: false,
        value: e.companyId,
        label: e.companyName,
        teamId: e.teamId,
      })) || []
    );
  };

  const getCategoryOptions = () => {
    const { categories } = props;
    return categories?.map((category) => ({
      label: category.name,
      value: category._id,
    }));
  };

  const handlePublicToggle = (isPublic) => {
    setState((prev) => ({ ...prev, isPublic }));
  };

  const handleSelectLendersAndGroups = (selectedLendersAndGroups) => {
    setState((prev) => ({ ...prev, selectedLendersAndGroups }));
  };

  const updatePermission = () => {
    const {
      node,
      onSubmit,
      addFilePermission,
      removeFilePermission,
      isPublicFilePermission,
    } = props;
    const { isPublic, selectedLendersAndGroups } = state;
    const newLenders = selectedLendersAndGroups.map((item) => item.value);
    const lendersPermissionIds = node.lendersPermission.map(
      (lender) => lender._id
    );

    const toAdd = newLenders.filter(
      (newItem) => !lendersPermissionIds.includes(newItem)
    );
    const toRemove = lendersPermissionIds.filter(
      (oldItem) => !newLenders.includes(oldItem)
    );

    toAdd.forEach((each) => {
      addFilePermission(node._id, { lenderId: each });
    });
    toRemove.forEach((each) => {
      removeFilePermission(node._id, { lenderId: each });
    });

    if (node.isPublic !== isPublic) {
      isPublicFilePermission(node._id, { isPublic });
    }

    onSubmit();
  };

  const [state, setState] = useState({
    isPublic: true,
    showDropdown: false,
    folderOptions: [],
    selectedLendersAndGroups: [],
    lenderOptions: getLenderOptions(),
    categoryOptions: getCategoryOptions(),
  });
  const [showDropdown, setShowDropdown] = useState(false);

  const { isPublic, lenderOptions, selectedLendersAndGroups } = state;

  const { node, usersPermissions } = props;

  useEffect(() => {
    const lendersPermissionIds = node.lendersPermission.map(
      (lender) => lender._id
    );
    const isPublic = node.isPublic;
    const selectedLendersAndGroups = lenderOptions.filter((option) =>
      lendersPermissionIds.includes(option.value)
    );

    setState((prev) => ({
      ...prev,
      isPublic,
      selectedLendersAndGroups,
    }));
  }, []);

  const usersList = [];
  if (usersPermissions) {
    usersPermissions.forEach((user) =>
      usersList.push({
        label: user.firstName + " " + user.lastName,
        value: user._id,
      })
    );
  }

  return (
    <>
      <Form
        className="full-width no-border no-padding"
        onSubmit={props.handleSubmit(updatePermission)}
      >
        <Row>
          <Col xs="12">
            <p>File: {node.name}</p>
          </Col>
        </Row>

        <Spacer size={20} />

        <Row>
          <Col xs="12" className="d-flex align-items-center">
            <p>Current: &nbsp;</p>
            <p style={{ minWidth: 60 }}>
              {isPublic ? "Public" : "Private"} &nbsp;
            </p>
            <Form.Check
              type="switch"
              className="switch-lg"
              style={{ marginTop: -4 }}
              label=""
              checked={isPublic}
              onChange={() => handlePublicToggle(!isPublic)}
            />
          </Col>
        </Row>

        <Spacer size={20} />

        <Row>
          <Col
            xs="12"
            className="d-flex align-items-center"
            style={{ minHeight: 28 }}
          >
            <p>Companies with Access: &nbsp;</p>
            {isPublic ? (
              <p>All</p>
            ) : (
              <div className="multiDropdown-wrapper">
                <Button
                  size="sm"
                  variant="link"
                  name="lenders"
                  className="d-flex align-items-center mr-2 px-0 text-body"
                  onClick={() => {
                    setShowDropdown(!showDropdown);
                  }}
                >
                  <FontAwesomeIcon className="ml-2" icon={faPlusCircle} />
                </Button>
                {showDropdown && (
                  <div className="multiDropdown-container left">
                    <MultiDropdown
                      autoFocus
                      menuIsOpen
                      label="Lenders"
                      options={[
                        {
                          label: "Lenders",
                          options: lenderOptions,
                        },
                      ]}
                      value={selectedLendersAndGroups}
                      onChange={(selected) => {
                        handleSelectLendersAndGroups(selected);
                      }}
                      onBlur={(evt) => {
                        if (evt.relatedTarget?.name !== "lenders") {
                          setShowDropdown(false);
                        }
                      }}
                    />
                  </div>
                )}
              </div>
            )}
          </Col>
          <Col xs="12" style={{ minHeight: 36 }}>
            <SelectedBadges
              show={isPublic}
              selected={selectedLendersAndGroups}
              onChange={handleSelectLendersAndGroups}
            />
          </Col>
        </Row>

        <Spacer size={20} />

        <Row>
          <Col className="text-right" xs="12">
            <Button type="submit" variant="primary">
              Apply
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default reduxForm({
  form: "SecuritySharing",
  enableReinitialize: true,
})(SecuritySharing);
