import React from "react";
import { useState } from "react";
import { Row, Col, Tabs, Tab } from "react-bootstrap";
import ManageClauseLibrary from "./ManageClauseLibrary";
import ManageSpecialtySections from "./ManageSpecialtySections";
import ManageTermsheet from "./ManageTermsheet";
import ManageParentClauses from "./ManageParentClauses";
import ManageCategories from "./ManageCategories";
import { connect } from "react-redux";

const ManageTermsheetAndClause = ({ match, history, whoami }) => {
  const [active, setActive] = useState(match.params.activeTab || "termsheet");

  const handleTabChange = (activeKey) => {
    setActive(activeKey);
    history.push(`/termsheet-clause/${activeKey}`);
  };

  if (!whoami.role) {
    return null;
  }

  return (
    <Row className="mb-3 pt-3" noGutters={true}>
      <Col xs={12}>
        <Tabs
          mountOnEnter
          unmountOnExit
          activeKey={active}
          onSelect={handleTabChange}
        >
          <Tab
            eventKey="termsheet"
            title="Term Sheet Library"
            tabClassName="w-20"
          >
            <ManageTermsheet />
          </Tab>
          <Tab
            eventKey="clause"
            title="Manage Clause Library"
            tabClassName="w-20"
          >
            <ManageClauseLibrary />
          </Tab>
          <Tab
            eventKey="specialty-sections"
            title="Specialty Sections"
            tabClassName="w-20"
          >
            <ManageSpecialtySections />
          </Tab>
          {whoami?.role === "Admin" && (
            <Tab
              eventKey="parent"
              title="Manage Parent Clause"
              tabClassName="w-20"
            >
              <ManageParentClauses />
            </Tab>
          )}
          {whoami?.role === "Admin" && (
            <Tab eventKey="categories" title="Categories" tabClassName="w-20">
              <ManageCategories />
            </Tab>
          )}
        </Tabs>
      </Col>
    </Row>
  );
};

function mapStateToProps(state) {
  return {
    whoami: state.auth.whoami,
  };
}

export default connect(mapStateToProps)(ManageTermsheetAndClause);
