import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Spacer from "components/layout/Spacer";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import PopupPrompt from "components/ui/PopupPrompt";
import TermsheetCreateFromTemplate from "./TermsheetCreateFromTemplate";
import CreateFromDeal from "./CreateFromDeal";
import { getDealById } from "redux/actions";

function TermsheetChoice({ whoami, deal, match }) {
  const history = useHistory();
  const [showTemplateModal, setShowTemplateModal] = useState(false);
  const [showFromDealModal, setShowFromDealModal] = useState(false);
  const [mySavedOnly, setMySavedOnly] = useState(false);

  const dealId = match.params?.dealId;

  useEffect(() => {
    if (dealId && !deal?.id) {
      getDealById(dealId);
    }
  }, [dealId]);

  useEffect(() => {
    if (!showTemplateModal && mySavedOnly) {
      setMySavedOnly(false);
    }
  }, [showTemplateModal]);

  const handleSubmitCreateFromTemplate = (termsheetId) => {
    const to = dealId
      ? `/deal/${dealId}/termsheet/create?termsheetId=${termsheetId}`
      : `/termsheet-clause/termsheet/create?termsheetId=${termsheetId}`;
    history.push(to);
  };

  let element = null;

  if (whoami?.role === "Admin") {
    element = (
      <Container>
        <Row className="price-table">
          <Col xs="12">
            <Spacer size={50} />
          </Col>

          <Col className="price-table-item" xs="12" md={{ span: 4, offset: 1 }}>
            <h5 className="price-table-heading">From Scratch</h5>
            <Spacer size={10} />
            <h3>Start from Scratch </h3>
            <Spacer size={20} />
            <Link
              to={
                dealId
                  ? `/deal/${dealId}/termsheet/create`
                  : `/termsheet-clause/termsheet/create`
              }
            >
              <Button variant="primary" className="outline">
                Select
              </Button>
            </Link>
          </Col>

          <Col className="d-xs-block d-sm-block d-md-none d-lg-none">
            <Spacer size={20} />
          </Col>

          <Col className="price-table-item" xs="12" md={{ span: 4, offset: 2 }}>
            <h5 className="price-table-heading">TermStreet Templates</h5>
            <Spacer size={10} />
            <h3>Pick a TermStreet Template</h3>
            <Spacer size={20} />
            <Button
              variant="primary"
              className="outline"
              onClick={() => setShowTemplateModal(true)}
            >
              Select
            </Button>
          </Col>
        </Row>

        <Row>
          <Col xs={12} className="text-right">
            <Spacer size={120} />
            <Link
              to={
                dealId
                  ? `/deal/${dealId}/termsheet`
                  : `/termsheet-clause/termsheet`
              }
            >
              <Button variant="primary" to="/">
                Go Back
              </Button>
            </Link>
          </Col>
        </Row>
      </Container>
    );
  }

  if (whoami?.role === "Lender" && !dealId) {
    element = (
      <Container>
        <Row className="price-table">
          <Col xs="12">
            <Spacer size={50} />
          </Col>

          <Col className="price-table-item" xs="12" md={{ span: 4, offset: 1 }}>
            <h5 className="price-table-heading">From Prior Deal</h5>
            <Spacer size={10} />
            <h3>Choose Term Sheet from Prior Deal</h3>
            <Spacer size={20} />
            <Button
              variant="primary"
              className="outline"
              onClick={() => setShowFromDealModal(true)}
            >
              Select
            </Button>
          </Col>

          <Col className="d-xs-block d-sm-block d-md-none d-lg-none">
            <Spacer size={20} />
          </Col>

          <Col className="price-table-item" xs="12" md={{ span: 4, offset: 2 }}>
            <h5 className="price-table-heading">TermStreet Templates</h5>
            <Spacer size={10} />
            <h3>Pick a TermStreet Template</h3>
            <Spacer size={20} />
            <Button
              variant="primary"
              className="outline"
              onClick={() => setShowTemplateModal(true)}
            >
              Select
            </Button>
          </Col>

          <Col xs="12">
            <Spacer size={50} />
          </Col>

          <Col className="price-table-item" xs="12" md={{ span: 4, offset: 1 }}>
            <h5 className="price-table-heading">My Saved Templates</h5>
            <Spacer size={10} />
            <h3>Start from template </h3>
            <Spacer size={20} />
            <Button
              variant="primary"
              className="outline"
              onClick={() => {
                setMySavedOnly(true);
                setShowTemplateModal(true);
              }}
            >
              Select
            </Button>
          </Col>

          <Col className="d-xs-block d-sm-block d-md-none d-lg-none">
            <Spacer size={20} />
          </Col>

          <Col className="price-table-item" xs="12" md={{ span: 4, offset: 2 }}>
            <h5 className="price-table-heading">Start from Scratch</h5>
            <Spacer size={10} />
            <h3>Open a Blank Template</h3>
            <Spacer size={20} />
            <Link to={`/termsheet-clause/termsheet/create`}>
              <Button variant="primary" className="outline">
                Select
              </Button>
            </Link>
          </Col>
        </Row>

        <Row>
          <Col xs={12} className="text-right">
            <Spacer size={120} />
            <Link to={`/termsheet-clause/termsheet`}>
              <Button variant="primary" to="/">
                Go Back
              </Button>
            </Link>
          </Col>
        </Row>
      </Container>
    );
  }

  if (whoami?.role === "Lender" && dealId) {
    element = (
      <Container>
        <Row className="price-table">
          <Col xs="12">
            <Spacer size={50} />
          </Col>

          <Col className="price-table-item" xs="12" md={{ span: 4, offset: 1 }}>
            <h5 className="price-table-heading">Default Template</h5>
            <Spacer size={10} />
            <h3>Start with TermStreet Default Template</h3>
            <Spacer size={20} />
            <Link
              to={
                deal?.defaultTermsheet
                  ? `/deal/${dealId}/termsheet/create?termsheetId=${deal?.defaultTermsheet}`
                  : `/deal/${dealId}/termsheet/create`
              }
            >
              <Button variant="primary" className="outline">
                Select
              </Button>
            </Link>
          </Col>

          <Col className="d-xs-block d-sm-block d-md-none d-lg-none">
            <Spacer size={20} />
          </Col>

          <Col className="price-table-item" xs="12" md={{ span: 4, offset: 2 }}>
            <h5 className="price-table-heading">From Prior Deal</h5>
            <Spacer size={10} />
            <h3>Choose Term Sheet from Prior Deal</h3>
            <Spacer size={20} />
            <Button
              variant="primary"
              className="outline"
              onClick={() => setShowFromDealModal(true)}
            >
              Select
            </Button>
          </Col>

          <Col xs="12">
            <Spacer size={50} />
          </Col>

          <Col className="price-table-item" xs="12" md={{ span: 4, offset: 1 }}>
            <h5 className="price-table-heading">My Saved Templates</h5>
            <Spacer size={10} />
            <h3>Start from template </h3>
            <Spacer size={20} />
            <Button
              variant="primary"
              className="outline"
              onClick={() => {
                setMySavedOnly(true);
                setShowTemplateModal(true);
              }}
            >
              Select
            </Button>
          </Col>

          <Col className="d-xs-block d-sm-block d-md-none d-lg-none">
            <Spacer size={20} />
          </Col>

          <Col className="price-table-item" xs="12" md={{ span: 4, offset: 2 }}>
            <h5 className="price-table-heading">TermStreet Templates</h5>
            <Spacer size={10} />
            <h3>Pick a TermStreet Template</h3>
            <Spacer size={20} />
            <Button
              variant="primary"
              className="outline"
              onClick={() => setShowTemplateModal(true)}
            >
              Select
            </Button>
          </Col>

          <Col xs="12">
            <Spacer size={50} />
          </Col>

          <Col
            className="price-table-item-plain"
            xs="12"
            md={{ span: 4, offset: 1 }}
          >
            <h5 className="price-table-heading">
              <Link to={`/deal/${dealId}/termsheet/create?fromScratch=true`}>
                Start From Scratch
              </Link>
            </h5>
            <p>Why Not Start with a TermStreet Template?</p>
          </Col>

          <Col className="d-xs-block d-sm-block d-md-none d-lg-none">
            <Spacer size={20} />
          </Col>

          <Col
            className="price-table-item-plain"
            xs="12"
            md={{ span: 4, offset: 2 }}
          >
            <h5 className="price-table-heading">
              <Link to={`#`}>Upload Term Sheet</Link>
            </h5>
            <p>Highly Discouraged!</p>
          </Col>
        </Row>

        <Row>
          <Col xs={12} className="text-right">
            <Spacer size={120} />
            <Link to={`/deal/${dealId}/quote-summary`}>
              <Button variant="primary" to="/">
                Go Back
              </Button>
            </Link>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <div className="termsheet-choice">
      {element}
      <PopupPrompt
        promptTitle={
          mySavedOnly
            ? "Choose a Saved Template"
            : "Choose a Termsheet Template"
        }
        promptBody={
          <TermsheetCreateFromTemplate
            mySavedOnly={mySavedOnly}
            onCancel={() => setShowTemplateModal(false)}
            onSubmit={handleSubmitCreateFromTemplate}
          />
        }
        show={showTemplateModal}
        onCancel={() => setShowTemplateModal(false)}
        onHide={() => setShowTemplateModal(false)}
      />
      <PopupPrompt
        promptTitle="Choose Term Sheet from Prior Deal"
        promptBody={
          <CreateFromDeal
            onCancel={() => setShowFromDealModal(false)}
            onSubmit={handleSubmitCreateFromTemplate}
          />
        }
        show={showFromDealModal}
        onCancel={() => setShowFromDealModal(false)}
        onHide={() => setShowFromDealModal(false)}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    whoami: state.auth.whoami,
    columns: state.termsheet.termsheet.columns || [],
    deal: state.deal.deal,
  };
}

export default connect(mapStateToProps)(TermsheetChoice);
