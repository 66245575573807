import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Button } from "react-bootstrap";
import { getKeyFromLocal } from "utils/lsUtils";
import {
  getDealFilesById,
  savePrimaryPicture,
  savePictures,
  updateDeal,
} from "redux/actions";
import PhotoItem from "./photoItem";
import PrimaryPhotoItem from "./primaryPhotoItem";
import constants from "config/constants";
import Loader from "components/ui/Loader";
import imageCompression from "browser-image-compression";
import { toast } from "react-toastify";
import { getDealCreationPath } from "utils/commonUtils";

const PhotoUploads = (props) => {
  const [primaryPhotos, setPrimaryPhotos] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [compressing, setCompressing] = useState(false);
  const dealId = getKeyFromLocal(constants.TERMST_LOCAL_STORAGE.DEAL_ID);

  const { whoami, isFetching, fileManagerData, photoSaved, photosSaved } =
    props;

  useEffect(() => {
    getDealFilesById(dealId);
  }, [dealId, photoSaved, photosSaved]);

  useEffect(() => {
    const pictureFolder = fileManagerData.filter(
      (data) => data.name === "Pictures"
    );
    if (pictureFolder.length > 0) {
      const tempPictures = fileManagerData.filter(
        (data) => data.parent === pictureFolder[0]._id
      );
      const primaryPicture = [];
      const pictures = [];
      tempPictures.forEach((p) => {
        if (p.isPrimaryPicture) {
          primaryPicture.push(p);
        } else {
          pictures.push(p);
        }
      });
      setPrimaryPhotos(primaryPicture);
      setPhotos(pictures);
    }
  }, [fileManagerData]);

  const handleUploadFile = useCallback(
    async (event) => {
      if (event.target.files?.length > 5) {
        toast.error("You can not upload more than 5 files at a time", {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }
      const data = new FormData();
      const options = {
        maxSizeMB: 0.75,
      };

      for await (let file of event.target.files) {
        try {
          const compressedFile = await imageCompression(file, options);
          data.append("files", compressedFile, file.name);
        } catch (error) {
          return error;
        }
      }

      data.append("dealId", dealId);
      savePictures(data);
    },
    [dealId]
  );

  const handlePrimaryPhoto = useCallback(
    async (file, filename) => {
      const newFile = new File([file], filename, {
        type: "image/jpeg",
      });
      const data = new FormData();

      const options = {
        maxSizeMB: 0.75,
      };

      try {
        setCompressing(true);
        const compressedFile = await imageCompression(newFile, options);
        setCompressing(false);

        data.append("files", compressedFile, filename);
      } catch (error) {
        return error;
      }

      data.append("dealId", dealId);
      savePrimaryPicture(data);
      updateDeal(dealId, {
        photoUploads: true,
        fromDashboard: true,
      });
    },
    [dealId]
  );
  const onFinish = () => {
    const path = getDealCreationPath();
    props.history.push(path);
  };

  return (
    <>
      {(isFetching || compressing) && <Loader files={true} />}
      <Container fluid>
        <Row className="file-item-header pt-2 pb-2">
          <Col xs="12">
            <p className="font-16">Photo Uploads</p>
          </Col>
        </Row>
      </Container>
      <div className="grid-container pt-2 pb-3">
        <PrimaryPhotoItem
          userId={whoami._id}
          name="Primary Photo"
          files={primaryPhotos}
          handlePrimaryPhoto={handlePrimaryPhoto}
          isRequired={true}
        />
        <PhotoItem
          userId={whoami._id}
          name="Photos"
          files={photos}
          handleUploadFile={handleUploadFile}
        />
      </div>
      <Container fluid>
        <Row>
          <Col xs="12">
            <Button variant="primary" onClick={onFinish}>
              Done
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};

function mapStateToProps(state) {
  return {
    whoami: state.auth.whoami,
    fileManagerData: state.fileManager.files,
    photoSaved: state.action.SAVE_PRIMARY_PICTURE.isFetched,
    photosSaved: state.action.SAVE_PICTURES.isFetched,
    isFetching:
      state.action.GET_DEAL_FILES.isFetching ||
      state.action.SAVE_PRIMARY_PICTURE.isFetching ||
      state.action.SAVE_PICTURES.isFetching,
  };
}

export default connect(mapStateToProps)(PhotoUploads);
