import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import NumberFormat from "react-number-format";
import { Col, Row, Button, Dropdown, Card } from "react-bootstrap";
import {
  archiveQuote,
  bulkArchiveQuote,
  bulkStarQuote,
  clearQuotes,
  getDealById,
  getManageClauses,
  getQuotes,
  starQuote,
  storeCompareQuotes,
} from "redux/actions";
import Loader from "components/ui/Loader";
import Spacer from "components/layout/Spacer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faEllipsisH,
  faEnvelope,
  faExternalLinkAlt,
  faStar,
  faCheckSquare,
} from "@fortawesome/free-solid-svg-icons";
import { faStar as faStarRegular } from "@fortawesome/free-regular-svg-icons";
import CustomMenu from "components/ui/CustomMenu";
import CustomToggle from "components/ui/CustomToggle";
import PopupPrompt from "components/ui/PopupPrompt";
import CreateMessage from "containers/deals/Communications/actions/CreateMessage";
import { reset } from "redux-form";
import DownloadTermsheet, { showDownloadSuccess } from "./DownloadTermsheet";
import DownloadQuotes from "./DownloadQuotes";
import {
  EmptyQuotesBorrower,
  EmptyQuotesLender,
} from "components/emptystate/NoResultsFound";
import LendersLogo from "./LendersLogo";
import { getSpreadTextValue } from "utils/commonUtils";

const QuoteSummary = (props) => {
  const {
    quotes,
    match,
    action,
    dispatch,
    history,
    deal,
    whoami,
    manageClauses,
    lastQuoteArchived,
    lastQuoteStarred,
    lastQuoteBulkArchived,
    lastQuoteBulkStarred,
  } = props;
  const isFetching =
    action.GET_QUOTES.isFetching || action.GET_MANAGE_CLAUSES.isFetching;
  const { dealId } = match.params;
  const [sort, setSort] = useState();
  const [liveQuotes, setLiveQuotes] = useState("all");
  const [selected, setSelected] = useState([]);
  const [state, setState] = useState({
    showPrompt: false,
    option: "",
    selectedLenders: [],
  });

  const quoteIds = useMemo(() => quotes.map((quote) => quote._id), [quotes]);
  const isMobile = window.isMobile;

  useEffect(() => {
    getQuotes({
      dealId,
      status: "Active",
      order: "ASC",
      orderColumn: sort,
      archived:
        liveQuotes === "archived" ? true : liveQuotes === "all" ? false : "",
      starred: liveQuotes === "starred" ? true : "",
    });
    setSelected([]);

    return () => {
      clearQuotes();
    };
  }, [
    sort,
    liveQuotes,
    lastQuoteArchived,
    lastQuoteStarred,
    lastQuoteBulkArchived,
    lastQuoteBulkStarred,
  ]);

  useEffect(() => {
    getDealById(dealId);
  }, [dealId]);

  const fetchClauses = useCallback(() => {
    const { pagination, sort } = {
      pagination: {
        page: 1,
        listOffset: 500,
      },
      sort: {
        direction: "ASC",
        column: "order",
      },
    };
    getManageClauses({
      page: pagination.page,
      limit: pagination.listOffset,
      order: sort.direction,
      orderColumn: sort.column,
      parent: true,
    });
  }, []);

  useEffect(() => {
    manageClauses.count === 0 && fetchClauses();
  }, [manageClauses]);

  const getIsArchived = (quote) => {
    return quote.isArchivedBy?.includes(whoami._id);
  };

  const handleSort = (newSort) => {
    setSort(newSort);
  };

  const handleLiveQuotes = (newValue) => {
    setLiveQuotes(newValue);
  };

  const handleArchive = (quote) => {
    archiveQuote(quote._id);
  };

  const handleStar = (quote) => {
    starQuote(quote._id);
  };

  const handleSelected = (id) => {
    setSelected((prev) => {
      let newSelected = [];
      if (prev.includes(id)) {
        newSelected = prev.filter((e) => e !== id);
      } else {
        newSelected = [...prev, id];
      }
      return newSelected;
    });
  };

  const handleSelectedAll = () => {
    setSelected((prev) => {
      let newSelected = [];
      if (prev.length) {
        newSelected = [];
      } else {
        newSelected = [...quoteIds];
      }
      return newSelected;
    });
  };

  const handleSendMessage = (quote) => {
    const selectedLenders = [
      { label: quote.companyId.companyName, value: quote.companyId._id },
    ];
    setState({ showPrompt: true, selectedLenders });
  };

  const handleBulkArchive = () => {
    bulkArchiveQuote({ ids: selected });
  };

  const handleBulkStar = () => {
    bulkStarQuote({ ids: selected });
  };

  const handleBulkMessage = () => {
    const firstQuote = quotes.find((quote) => quote._id === selected?.[0]);
    if (firstQuote) {
      handleSendMessage(firstQuote);
    }
  };

  const handleCompare = () => {
    history.push(`/deal/${dealId}/compare-quotes`);
    const compareQuotes = quotes.filter((quote) =>
      selected.includes(quote._id)
    );
    storeCompareQuotes(compareQuotes);
  };

  const handleShowPrompt = (showPrompt, val) => {
    setState({ showPrompt, option: val });
  };

  const handleDownloadDocx = (quote) => {
    const { termsheet } = quote;
    DownloadTermsheet.DOCX({ termsheet, deal });
  };

  const handleDownloadMultipleDocx = async () => {
    const fileStream = window.streamSaver.createWriteStream("archive.zip");
    const promises = quotes
      .filter((quote) => selected.includes(quote._id))
      .map(async ({ termsheet }, index) => ({
        name: `${index + 1} - ${termsheet.termsheetName}.docx`,
        blob: await DownloadTermsheet.DOCX({
          termsheet,
          deal,
          download: false,
        }),
      }));
    const blobs = await Promise.all(promises);
    const readableZipStream = new window.ZIP({
      start(ctrl) {
        blobs.forEach((each) => {
          const file = {
            name: each.name,
            stream: () => each.blob.stream(),
          };
          ctrl.enqueue(file);
        });
        ctrl.close();
      },
    });
    readableZipStream.pipeTo(fileStream).then(() => {
      showDownloadSuccess();
    });
  };

  const handleDownloadMultipleXlsx = async () => {
    const { rows: parentClauses } = manageClauses;
    const selectedTermsheetIds = quotes
      .filter((quote) => selected.includes(quote._id))
      .map((quote) => quote.termsheetId);
    const selectedQuotes = quotes.filter((quote) =>
      selectedTermsheetIds.includes(quote.termsheetId)
    );
    const addedFields = [];
    const borrowerCompany = deal?.company;
    DownloadQuotes.XLSX(selectedQuotes, addedFields, parentClauses, {
      deal,
      whoami,
      borrowerCompany,
      fileName: "Quote Summary",
    });
    showDownloadSuccess();
  };

  const noSelection = !selected.length;

  if (!isFetching && !quotes.length) {
    if (whoami.role === "Lender") {
      return <EmptyQuotesLender dealId={dealId} />;
    }
    if (whoami.role === "Borrower") {
      return <EmptyQuotesBorrower />;
    }
  }
  return (
    <Row className={`${isMobile ? "quote-summary-section" : ""}`}>
      <Col sm={12} lg={10} xl={8}>
        <Row className="mt-3">
          <Col>
            <div className="px-1">
              <div className="d-flex align-items-center">
                <FontAwesomeIcon className="h2 mr-3 mb-0" icon={faEnvelope} />
                <span className="h4 mb-0">Live Quotes </span>
                <Dropdown className="d-inline-block">
                  <Dropdown.Toggle as={CustomToggle}>
                    <FontAwesomeIcon className="h5 m-0" icon={faChevronDown} />
                  </Dropdown.Toggle>

                  <Dropdown.Menu as={CustomMenu}>
                    <Dropdown.Item onClick={() => handleLiveQuotes("archived")}>
                      <FontAwesomeIcon
                        className="mr-2"
                        style={{ width: 16 }}
                        icon={faExternalLinkAlt}
                      />
                      Archived
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleLiveQuotes("all")}>
                      <FontAwesomeIcon
                        style={{ width: 16 }}
                        className="mr-2"
                        icon={faCheckSquare}
                      />
                      All
                    </Dropdown.Item>
                    {(whoami.role === "Borrower" ||
                      whoami.role === "Admin") && (
                      <Dropdown.Item
                        onClick={() => handleLiveQuotes("starred")}
                      >
                        <FontAwesomeIcon
                          style={{ width: 16 }}
                          className="mr-2"
                          icon={faStar}
                        />
                        Starred
                      </Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </Col>
        </Row>

        <Spacer size={10} />

        {whoami.role === "Admin" && (
          <Row className="quote-summary-edit-termsheet">
            <Col xs={12} className="text-center text-sm-right">
              <Link
                to={
                  deal?.defaultTermsheet
                    ? `/deal/${dealId}/termsheet/${deal?.defaultTermsheet}`
                    : `/deal/${dealId}/termsheet/create?isDefault=true&fromScratch=true`
                }
                className={`color-purple ${
                  isFetching ? "pointer-events-none" : ""
                }`}
              >
                View / Edit Default Term Sheet
              </Link>
            </Col>
          </Row>
        )}

        <Spacer size={10} />

        <Row>
          <Col xs={12} sm={props.whoami.role === "Lender" ? 8 : 12}>
            <div className="d-flex align-items-center px-1">
              <input
                type="checkbox"
                style={{ width: 16, height: 16 }}
                className="cursor-pointer mr-3 ml-2"
                checked={
                  selected.length &&
                  quoteIds.every((id) => selected.includes(id))
                }
                onChange={handleSelectedAll}
              />

              <div className="d-flex align-items-center color-purple mr-3">
                <span>Selected </span>
                <Dropdown className="d-inline-block">
                  <Dropdown.Toggle as={CustomToggle}>
                    <FontAwesomeIcon
                      className="color-purple"
                      icon={faChevronDown}
                    />
                  </Dropdown.Toggle>

                  {whoami.role === "Borrower" || whoami.role === "Admin" ? (
                    <Dropdown.Menu as={CustomMenu}>
                      <Dropdown.Item
                        disabled={noSelection}
                        onClick={handleBulkArchive}
                      >
                        Archive
                      </Dropdown.Item>
                      <Dropdown.Item
                        disabled={noSelection}
                        onClick={handleBulkStar}
                      >
                        Favorite
                      </Dropdown.Item>
                      <Dropdown.Item
                        disabled={noSelection}
                        onClick={handleCompare}
                      >
                        Compare
                      </Dropdown.Item>
                      <Dropdown.Item
                        disabled={noSelection}
                        onClick={handleDownloadMultipleXlsx}
                      >
                        Download (.xlsx)
                      </Dropdown.Item>
                      <Dropdown.Item disabled={noSelection}>
                        Download (.pdf)
                      </Dropdown.Item>
                      <Dropdown.Item
                        disabled={noSelection}
                        onClick={handleDownloadMultipleDocx}
                      >
                        Download (.docx)
                      </Dropdown.Item>
                      <Dropdown.Item
                        disabled={noSelection}
                        onClick={handleBulkMessage}
                      >
                        Message Lenders
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  ) : (
                    <Dropdown.Menu as={CustomMenu}>
                      <Dropdown.Item
                        disabled={noSelection}
                        onClick={handleCompare}
                      >
                        Compare
                      </Dropdown.Item>
                      <Dropdown.Item disabled={noSelection}>
                        Archive
                      </Dropdown.Item>
                      <Dropdown.Item
                        disabled={noSelection}
                        onClick={handleDownloadMultipleXlsx}
                      >
                        Download (.xlsx)
                      </Dropdown.Item>
                      <Dropdown.Item disabled={noSelection}>
                        Download (.pdf)
                      </Dropdown.Item>
                      <Dropdown.Item
                        disabled={noSelection}
                        onClick={handleDownloadMultipleDocx}
                      >
                        Download (.docx)
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  )}
                </Dropdown>
              </div>

              <div className="d-flex align-items-center color-purple mr-5">
                <span>Sort </span>
                <Dropdown className="d-inline-block">
                  <Dropdown.Toggle as={CustomToggle}>
                    <FontAwesomeIcon
                      className="color-purple"
                      icon={faChevronDown}
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu as={CustomMenu}>
                    {(whoami.role === "Borrower" ||
                      whoami.role === "Admin") && (
                      <Dropdown.Item
                        onClick={() => handleSort("companyId.companyName")}
                      >
                        Lender
                      </Dropdown.Item>
                    )}
                    <Dropdown.Item onClick={() => handleSort("loanAmount")}>
                      Loan Amount
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleSort("term")}>
                      Term
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleSort("amortization")}>
                      Amortization
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleSort("IOPeriod")}>
                      IO Period
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleSort("rate")}>
                      Rate
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              <Button variant="tertiary" className="justify-self-end ml-auto">
                Filter
              </Button>
            </div>
          </Col>

          {props.whoami.role === "Lender" && (
            <Col xs={12} sm={4} className="mt-3 mt-sm-0">
              <p className="text-danger text-center text-sm-right">
                <Button
                  as={Link}
                  to={`/deal/${dealId}/termsheet/choice`}
                  variant="primary"
                >
                  New Quote
                </Button>
              </p>
            </Col>
          )}
        </Row>

        {isFetching && <Loader />}

        <Spacer size={20} />

        {quotes.length ? (
          quotes.map((quote) => (
            <Row key={quote._id} className="mb-3">
              <Col xs={12}>
                <Card className="px-1">
                  <Card.Body>
                    <Row className="px-2">
                      <Col xs="auto" className="border-right">
                        <div className="d-flex flex-column align-items-center justify-content-between h-100 py-2">
                          <input
                            type="checkbox"
                            style={{ width: 16, height: 16 }}
                            className="cursor-pointer"
                            checked={selected.includes(quote._id)}
                            onChange={() => handleSelected(quote._id)}
                          />
                          <div className="d-flex flex-column">
                            {(props.whoami.role === "Borrower" ||
                              props.whoami.role === "Admin") && (
                              <FontAwesomeIcon
                                style={{
                                  width: 16,
                                  color: quote.isStarredBy?.includes(whoami._id)
                                    ? "#0a467f"
                                    : undefined,
                                }}
                                icon={
                                  quote.isStarredBy?.includes(whoami._id)
                                    ? faStar
                                    : faStarRegular
                                }
                                title="Favorite"
                                className="cursor-pointer mb-3"
                                onClick={() => handleStar(quote)}
                              />
                            )}
                            <FontAwesomeIcon
                              style={{ width: 16 }}
                              icon={faExternalLinkAlt}
                              title="Archive"
                              className="cursor-pointer"
                              onClick={() => handleArchive(quote)}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col className="py-3">
                        <Row className="align-items-center border-bottom pb-2">
                          <Col xs={12} sm={4}>
                            <div className="d-flex flex-column flex-sm-row align-items-center justify-content-center justify-content-sm-start">
                              <LendersLogo company={quote.termsheet?.company} />
                              <p
                                className="ml-2 text-ellipsis"
                                title={quote.termsheet?.company.companyName}
                              >
                                {quote.termsheet?.company.companyName}
                              </p>
                            </div>
                          </Col>
                          <Col
                            xs={12}
                            sm={4}
                            className="text-center my-2 my-sm-0"
                          >
                            <p>
                              Status:{" "}
                              {getIsArchived(quote)
                                ? "Archived"
                                : quote.termsheet?.status === "DRAFT"
                                ? "Draft"
                                : quote.status}
                            </p>
                          </Col>
                          <Col
                            xs={12}
                            sm={4}
                            className="text-center text-sm-right"
                          >
                            <Button
                              as={Link}
                              to={
                                whoami.role === "Admin" ||
                                whoami.role === "Borrower"
                                  ? `/termsheet/${quote.termsheetId}`
                                  : quote.termsheet?.status === "ACTIVE"
                                  ? `/termsheet/${quote.termsheetId}`
                                  : `/deal/${dealId}/termsheet/${quote.termsheetId}`
                              }
                              variant="primary"
                              size="sm"
                              className="mr-3"
                              title={quote.termsheet?.termsheetName}
                            >
                              View
                            </Button>
                            <Dropdown className="d-inline-block">
                              <Dropdown.Toggle as={CustomToggle}>
                                <FontAwesomeIcon
                                  icon={faEllipsisH}
                                  className="cursor-pointer"
                                />
                              </Dropdown.Toggle>
                              {props.whoami.role === "Admin" ||
                              props.whoami.role === "Borrower" ? (
                                <Dropdown.Menu as={CustomMenu}>
                                  <Dropdown.Item
                                    onClick={() => handleArchive(quote)}
                                  >
                                    Archive
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => handleSendMessage(quote)}
                                  >
                                    Send Message
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => handleStar(quote)}
                                  >
                                    Favorite
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() =>
                                      history.push(
                                        `/termsheet/${quote.termsheetId}`
                                      )
                                    }
                                  >
                                    Download (.pdf)
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => handleDownloadDocx(quote)}
                                  >
                                    Download (.docx)
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              ) : (
                                <Dropdown.Menu as={CustomMenu}>
                                  <Dropdown.Item
                                    onClick={() =>
                                      history.push(
                                        `/deal/${dealId}/termsheet/${quote.termsheetId}`
                                      )
                                    }
                                  >
                                    View Quote
                                  </Dropdown.Item>
                                  {!isMobile && (
                                    <Dropdown.Item
                                      onClick={() =>
                                        history.push(
                                          `/deal/${dealId}/termsheet/${quote.termsheetId}`
                                        )
                                      }
                                    >
                                      Edit Quote
                                    </Dropdown.Item>
                                  )}
                                  <Dropdown.Item
                                    onClick={() =>
                                      history.push(
                                        `/termsheet/${quote.termsheetId}`
                                      )
                                    }
                                  >
                                    Download (.pdf)
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => handleDownloadDocx(quote)}
                                  >
                                    Download (.docx)
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              )}
                            </Dropdown>
                          </Col>
                        </Row>

                        <Row className="align-items-center text-center border-bottom py-2">
                          <Col xs={6} sm={3}>
                            <div>
                              <p className="color-medium-gray">Amount</p>
                              <p>
                                <NumberFormat
                                  displayType="text"
                                  prefix="$"
                                  thousandSeparator={true}
                                  value={quote.loanAmount}
                                />
                              </p>
                            </div>
                          </Col>
                          <Col xs={6} sm={3}>
                            <div>
                              <p className="color-medium-gray">Term</p>
                              <p>
                                <NumberFormat
                                  displayType="text"
                                  thousandSeparator={true}
                                  suffix={` ${quote.termMetric}`}
                                  value={quote.term}
                                />
                              </p>
                            </div>
                          </Col>
                          <Col xs={6} sm={3}>
                            <div>
                              <p className="color-medium-gray">Amortization</p>
                              <p>
                                <NumberFormat
                                  displayType="text"
                                  thousandSeparator={true}
                                  suffix={` ${quote.amortizationMetric}`}
                                  value={quote.amortization}
                                />
                              </p>
                            </div>
                          </Col>
                          <Col xs={6} sm={3}>
                            <div>
                              <p className="color-medium-gray">IO Period</p>
                              <p>
                                <NumberFormat
                                  displayType="text"
                                  thousandSeparator={true}
                                  suffix={` ${quote.IOPeriodMetric}`}
                                  value={quote.IOPeriod}
                                />
                              </p>
                            </div>
                          </Col>
                        </Row>

                        <Row className="align-items-center text-center pt-2">
                          <Col xs={6} sm={3}>
                            <div>
                              <p className="color-medium-gray">Index</p>
                              <p>
                                <NumberFormat
                                  displayType="text"
                                  thousandSeparator={true}
                                  suffix="%"
                                  fixedDecimalScale
                                  decimalScale={2}
                                  value={quote.indexRate}
                                />
                              </p>
                            </div>
                          </Col>
                          <Col xs={6} sm={3}>
                            <div>
                              <p className="color-medium-gray">Spread</p>
                              <p
                                className="text-ellipsis"
                                title={
                                  quote.isRateOrSpread === "Rate"
                                    ? ""
                                    : getSpreadTextValue(quote)
                                }
                              >
                                {quote.isRateOrSpread === "Rate"
                                  ? "-"
                                  : getSpreadTextValue(quote)}
                              </p>
                            </div>
                          </Col>
                          <Col xs={6} sm={3}>
                            <div>
                              <p className="color-medium-gray">
                                {quote.floorOption}
                              </p>
                              <p>
                                {quote.floorOption === "No Floor" ? (
                                  "-"
                                ) : (
                                  <NumberFormat
                                    displayType="text"
                                    thousandSeparator={true}
                                    suffix="%"
                                    fixedDecimalScale
                                    decimalScale={2}
                                    value={quote.floorRate}
                                  />
                                )}
                              </p>
                            </div>
                          </Col>
                          <Col xs={6} sm={3}>
                            <div>
                              <p className="color-medium-gray">Rate</p>
                              <p>
                                <NumberFormat
                                  displayType="text"
                                  thousandSeparator={true}
                                  suffix="%"
                                  fixedDecimalScale
                                  decimalScale={2}
                                  value={quote.rate}
                                />
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          ))
        ) : (
          <Row className="mt-3">
            <Col xs={12} className="text-center">
              No results found
            </Col>
          </Row>
        )}
      </Col>

      <PopupPrompt
        promptTitle="Send Message"
        promptBody={
          <CreateMessage
            option="Message"
            dealId={dealId}
            sendTo={state.selectedLenders?.[0]}
            onSubmit={() => {
              handleShowPrompt(false);
              setState({ option: "" });
              dispatch(reset("CreateMessage"));
            }}
          />
        }
        show={state.showPrompt}
        onCancel={() => {
          handleShowPrompt(false);
          setState({ option: "" });
          dispatch(reset("CreateMessage"));
        }}
        onHide={() => {
          handleShowPrompt(false);
          setState({ option: "" });
          dispatch(reset("CreateMessage"));
        }}
      />
    </Row>
  );
};
const mapStateToProps = (state) => ({
  action: state.action,
  whoami: state.auth.whoami,
  termsheets: state.termsheet.termsheets,
  manageClauses: state.clause.manageClauses,
  termsheetsTotalCount: state.termsheet.termsheetsTotalCount,
  lastQuoteArchived: state.termsheet.lastQuoteArchived,
  lastQuoteStarred: state.termsheet.lastQuoteStarred,
  lastQuoteBulkArchived: state.termsheet.lastQuoteBulkArchived,
  lastQuoteBulkStarred: state.termsheet.lastQuoteBulkStarred,
  quotes: state.termsheet.quotes,
  deal: state.deal.deal,
});
export default connect(mapStateToProps)(QuoteSummary);
