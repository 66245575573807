import React from "react";
import { connect } from "react-redux";
import { Form, Container, Row, Col, Accordion, Card } from "react-bootstrap";
import { Field, reduxForm, formValueSelector } from "redux-form";
import {
  reduxFormInput,
  reduxFormSelect,
  reduxFormNumber,
} from "components/form/ReduxForm";
import { required, noSpaces } from "utils/validation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Spacer from "components/layout/Spacer";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import programCategories from "../constants/programCategories";

class Bucket extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleDelete = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.handleDelete();
  };

  render() {
    const {
      action,
      selectedOtherPropertyDealTypesWeLike,
      selectedRateStructure,
      selectedTypicalIndex,
      bucketName,
      selectedProgramType,
    } = this.props;
    const CommonBlock = (
      <Col className="pl-0 pr-0" xs="12" sm="6">
        <Col xs="12">
          <Field
            className="form-control"
            component={reduxFormInput}
            validate={[required, noSpaces]}
            name="bucketName"
            type="text"
            placeholder="Enter bucket name"
            label="*Bucket Name"
          />
          <Spacer size={20} />
        </Col>
        <Col xs="12">
          <Field
            className="form-control"
            component={reduxFormSelect}
            name="apartmentTypesList"
            type="select"
            options={this.props.getDropdownValues("apartmentTypesList")}
            isLoading={action.GET_DROPDOWNS.isFetching}
            onFocus={() => this.props.getDropdownFromAPI("apartmentTypesList")}
            placeholder="None"
            label="Apartments"
            isMulti
          />
          <Spacer size={20} />
          <Field
            className="form-control"
            component={reduxFormSelect}
            name="officeTypesList"
            type="select"
            options={this.props.getDropdownValues("officeTypesList")}
            isLoading={action.GET_DROPDOWNS.isFetching}
            onFocus={() => this.props.getDropdownFromAPI("officeTypesList")}
            placeholder="None"
            label="Office"
            isMulti
          />
          <Spacer size={20} />
          <Field
            className="form-control"
            component={reduxFormSelect}
            name="retailTypeList"
            type="select"
            options={this.props.getDropdownValues("retailTypeList")}
            isLoading={action.GET_DROPDOWNS.isFetching}
            onFocus={() => this.props.getDropdownFromAPI("retailTypeList")}
            placeholder="None"
            label="Retail"
            isMulti
          />
          <Spacer size={20} />
          <Field
            className="form-control"
            component={reduxFormSelect}
            name="industrialTypeList"
            type="select"
            options={this.props.getDropdownValues("industrialTypeList")}
            isLoading={action.GET_DROPDOWNS.isFetching}
            onFocus={() => this.props.getDropdownFromAPI("industrialTypeList")}
            placeholder="None"
            label="Industrial"
            isMulti
          />
          <Spacer size={20} />
          <Field
            className="form-control"
            component={reduxFormSelect}
            name="hotelTypeList"
            type="select"
            menuPortalTarget={document.body}
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            }}
            options={this.props.getDropdownValues("hotelTypeList")}
            isLoading={action.GET_DROPDOWNS.isFetching}
            onFocus={() => this.props.getDropdownFromAPI("hotelTypeList")}
            placeholder="None"
            label="Hotel"
            isMulti={true}
          />
          <Spacer size={20} />
          <Field
            className="form-control"
            component={reduxFormSelect}
            name="seniorLivingList"
            type="select"
            menuPortalTarget={document.body}
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            }}
            options={this.props.getDropdownValues("seniorLivingList")}
            isLoading={action.GET_DROPDOWNS.isFetching}
            onFocus={() => this.props.getDropdownFromAPI("seniorLivingList")}
            placeholder="None"
            label="Senior Living"
            isMulti
          />
          <Spacer size={20} />
          <Field
            className="form-control"
            component={reduxFormSelect}
            name="otherPropertyDealTypesWeLike"
            type="select"
            menuPortalTarget={document.body}
            styles={{
              menuList: (provided) => ({ ...provided, maxHeight: 200 }),
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            }}
            options={this.props.getDropdownValues(
              "otherPropertyDealTypesWeLike"
            )}
            isLoading={action.GET_DROPDOWNS.isFetching}
            onFocus={() =>
              this.props.getDropdownFromAPI("otherPropertyDealTypesWeLike")
            }
            placeholder="None"
            label="Other Properties"
            isMulti
          />
          <Spacer size={20} />
          {selectedOtherPropertyDealTypesWeLike.some(
            (item) => item.value === "Other"
          ) && (
            <Field
              className="form-control"
              component={reduxFormInput}
              validate={[noSpaces]}
              name="otherOtherPropertyDealTypesWeLike"
              type="textarea"
              placeholder="Additional information"
              label="Other (Other Properties)"
            />
          )}
          <Spacer size={30} />
        </Col>
      </Col>
    );

    const GeneralBlock =
      programCategories.general.indexOf(selectedProgramType.value) !== -1 ? (
        <Col xs="12" sm="6">
          <Field
            component={reduxFormSelect}
            name="rateStructure"
            type="select"
            options={this.props.getDropdownValues("rateStructure")}
            isLoading={action.GET_DROPDOWNS.isFetching}
            onFocus={() => this.props.getDropdownFromAPI("rateStructure")}
            placeholder="Rate Structure"
            label="Rate Structure"
          />
          <Spacer size={20} />
          {selectedRateStructure.value ===
            "We Quote a Spread over an Index" && (
            <>
              <Field
                className="form-control"
                component={reduxFormSelect}
                name="typicalIndex"
                type="select"
                options={this.props.getDropdownValues("typicalIndex")}
                isLoading={action.GET_DROPDOWNS.isFetching}
                onFocus={() => this.props.getDropdownFromAPI("typicalIndex")}
                placeholder="Typical Index"
                label="Typical Index"
              />
              <Spacer size={20} />
              {selectedTypicalIndex.value === "Other" && (
                <>
                  <Field
                    className="form-control"
                    component={reduxFormInput}
                    validate={[noSpaces]}
                    name="otherTypicalIndex"
                    type="textarea"
                    placeholder="Other (Typical Index)"
                    label="Other (Typical Index)"
                  />
                  <Spacer size={20} />
                </>
              )}
              <Container>
                <Row>
                  <Col xs="12" md="6">
                    <Field
                      className="form-control d-inline"
                      component={reduxFormNumber}
                      decimalScale={0}
                      name="typicalSpreadMin"
                      placeholder="Enter typical spread minimum"
                      label="Typical Spread Minimum"
                      thousandSeparator={true}
                    />
                    <Spacer size={20} />
                  </Col>
                  <Col xs="12" md="6">
                    <Field
                      className="form-control d-inline"
                      component={reduxFormNumber}
                      decimalScale={0}
                      name="typicalSpreadMax"
                      placeholder="Enter typical spread maximum"
                      label="Typical Spread Maximum"
                      thousandSeparator={true}
                    />
                    <Spacer size={20} />
                  </Col>
                </Row>
              </Container>
            </>
          )}
          {selectedRateStructure.value === "We Quote Rates" && (
            <Container>
              <Row>
                <Col xs="12" md="6">
                  <Field
                    className="form-control d-inline"
                    component={reduxFormNumber}
                    suffix={"%"}
                    decimalScale={2}
                    name="typicalRateMin"
                    placeholder="Enter typical rate minimum"
                    label="Typical Rate Minimum"
                  />
                  <Spacer size={20} />
                </Col>
                <Col xs="12" md="6">
                  <Field
                    className="form-control d-inline"
                    component={reduxFormNumber}
                    suffix={"%"}
                    decimalScale={2}
                    name="typicalRateMax"
                    placeholder="Enter typical rate maximum"
                    label="Typical Rate Maximum"
                  />
                  <Spacer size={20} />
                </Col>
              </Row>
            </Container>
          )}
          <Field
            className="form-control d-inline"
            component={reduxFormNumber}
            suffix={"%"}
            decimalScale={0}
            name="maxLTV"
            placeholder="Enter Max LTV"
            label="Max LTV"
          />
          <Spacer size={20} />
          <Field
            className="form-control d-inline"
            component={reduxFormNumber}
            suffix={"%"}
            decimalScale={0}
            name="sweetspotLTV"
            placeholder="Enter Sweetspot LTV"
            label="Sweetspot LTV"
          />
          <Spacer size={20} />
          <Field
            className="form-control d-inline"
            component={reduxFormNumber}
            suffix={"%"}
            decimalScale={0}
            name="maxLTC"
            placeholder="Enter Max LTC"
            label="Max LTC"
          />
          <Spacer size={20} />
          <Field
            className="form-control d-inline"
            component={reduxFormNumber}
            suffix={"%"}
            decimalScale={0}
            name="sweetspotLTC"
            placeholder="Enter Sweetspot LTC"
            label="Sweetspot LTC"
          />
          <Spacer size={20} />
          <Field
            className="form-control d-inline"
            component={reduxFormNumber}
            suffix={"%"}
            decimalScale={2}
            name="minDebtYield"
            placeholder="Enter Min Debt Yield"
            label="Min Debt Yield"
          />
          <Spacer size={20} />
          <Field
            className="form-control d-inline"
            component={reduxFormNumber}
            suffix={"%"}
            decimalScale={2}
            name="sweetSpotDebtYield"
            placeholder="Enter Sweet Spot Debt Yield"
            label="Sweet Spot Debt Yield"
          />
          <Spacer size={20} />
          <Field
            className="form-control"
            component={reduxFormSelect}
            name="debtYieldBasedUpon"
            type="select"
            options={this.props.getDropdownValues("debtYieldBasedUpon")}
            isLoading={action.GET_DROPDOWNS.isFetching}
            onFocus={() => this.props.getDropdownFromAPI("debtYieldBasedUpon")}
            placeholder="Debt Yield Based Upon"
            label="Debt Yield Based Upon"
          />
          <Field
            className="form-control d-inline"
            component={reduxFormNumber}
            name="minDSCR"
            decimalScale={2}
            placeholder="Enter Min DSCR"
            label="Min DSCR"
          />
          <Spacer size={20} />
          <Field
            className="form-control d-inline"
            component={reduxFormNumber}
            decimalScale={2}
            name="sweetSpotDSCR"
            placeholder="Enter Sweetspot DSCR"
            label="Sweetspot DSCR"
          />
          <Spacer size={20} />
        </Col>
      ) : null;

    const EquityBlock =
      programCategories.equity.indexOf(selectedProgramType.value) !== -1 ? (
        <Col xs="12" sm="6">
          <Container>
            <Row>
              <Col xs="12" md="6">
                <Field
                  className="form-control d-inline"
                  component={reduxFormNumber}
                  decimalScale={1}
                  suffix={"%"}
                  name="targetIRRMin"
                  placeholder="Enter Target IRR Minimum"
                  label="Target IRR Minimum"
                />
                <Spacer size={20} />
              </Col>
              <Col xs="12" md="6">
                <Field
                  className="form-control d-inline"
                  component={reduxFormNumber}
                  decimalScale={1}
                  suffix={"%"}
                  name="targetIRRMax"
                  placeholder="Enter Target IRR Maximum"
                  label="Target IRR Maximum"
                />
                <Spacer size={20} />
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <Field
                  className="form-control d-inline"
                  component={reduxFormNumber}
                  decimalScale={1}
                  suffix={"%"}
                  name="targetIRRSweetspot"
                  placeholder="Enter Target IRR Sweetspot"
                  label="Target IRR Sweetspot"
                />
                <Spacer size={20} />
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col xs="12" md="6">
                <Field
                  className="form-control d-inline"
                  component={reduxFormNumber}
                  decimalScale={1}
                  suffix={"%"}
                  name="targetCashOnCashMin"
                  placeholder="Enter Target Cash on Cash Minimum"
                  label="Target Cash on Cash Minimum"
                />
                <Spacer size={20} />
              </Col>
              <Col xs="12" md="6">
                <Field
                  className="form-control d-inline"
                  component={reduxFormNumber}
                  decimalScale={1}
                  suffix={"%"}
                  name="targetCashOnCashMax"
                  placeholder="Enter Target Cash on Cash Maximum"
                  label="Target Cash on Cash Maximum"
                />
                <Spacer size={20} />
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <Field
                  className="form-control d-inline"
                  component={reduxFormNumber}
                  decimalScale={1}
                  suffix={"%"}
                  name="targetCashOnCashSweetspot"
                  placeholder="Enter Target Cash on Cash Sweetspot"
                  label="Target Cash on Cash Sweetspot"
                />
                <Spacer size={20} />
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col xs="12" md="6">
                <Field
                  className="form-control d-inline"
                  component={reduxFormNumber}
                  decimalScale={1}
                  suffix={"%"}
                  name="targetYieldOnCostMin"
                  placeholder="Enter Target Yield on Cost Minimum"
                  label="Target Yield on Cost Minimum"
                />
                <Spacer size={20} />
              </Col>
              <Col xs="12" md="6">
                <Field
                  className="form-control d-inline"
                  component={reduxFormNumber}
                  decimalScale={1}
                  suffix={"%"}
                  name="targetYieldOnCostMax"
                  placeholder="Enter Target Yield on Cost Maximum"
                  label="Target Yield on Cost Maximum"
                />
                <Spacer size={20} />
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <Field
                  className="form-control d-inline"
                  component={reduxFormNumber}
                  decimalScale={1}
                  suffix={"%"}
                  name="targetYieldOnCostSweetspot"
                  placeholder="Enter Target Yield on Cost Sweetspot"
                  label="Target Yield on Cost Sweetspot"
                />
                <Spacer size={20} />
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col xs="12" md="6">
                <Field
                  className="form-control d-inline"
                  component={reduxFormNumber}
                  decimalScale={0}
                  suffix={"%"}
                  name="tagetLeverageMin"
                  placeholder="Enter Target Leverage Minimum"
                  label="Target Leverage Minimum"
                />
                <Spacer size={20} />
              </Col>
              <Col xs="12" md="6">
                <Field
                  className="form-control d-inline"
                  component={reduxFormNumber}
                  decimalScale={0}
                  suffix={"%"}
                  name="tagetLeverageMax"
                  placeholder="Enter Target Leverage Maximum"
                  label="Target Leverage Maximum"
                />
                <Spacer size={20} />
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <Field
                  className="form-control d-inline"
                  component={reduxFormNumber}
                  decimalScale={0}
                  suffix={"%"}
                  name="tagetLeverageSweetspot"
                  placeholder="Enter Target Leverage Sweetspot"
                  label="Target Leverage Sweetspot"
                />
                <Spacer size={20} />
              </Col>
            </Row>
          </Container>
        </Col>
      ) : null;
    const MezzPrefBlock =
      programCategories.mezz.indexOf(selectedProgramType.value) !== -1 ? (
        <Col xs="12" sm="6">
          <Container>
            <Row>
              <Col xs="12" md="6">
                <Field
                  className="form-control d-inline"
                  component={reduxFormNumber}
                  decimalScale={2}
                  suffix={"%"}
                  name="targetCurrentPayYieldMin"
                  placeholder="Enter Target Current Pay Yield Minimum"
                  label="Target Current Pay Yield Minimum"
                />
                <Spacer size={20} />
              </Col>
              <Col xs="12" md="6">
                <Field
                  className="form-control d-inline"
                  component={reduxFormNumber}
                  decimalScale={2}
                  suffix={"%"}
                  name="targetCurrentPayYieldMax"
                  placeholder="Enter Target Current Pay Yield Maximum"
                  label="Target Current Pay Yield Maximum"
                />
                <Spacer size={20} />
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <Field
                  className="form-control d-inline"
                  component={reduxFormNumber}
                  decimalScale={2}
                  suffix={"%"}
                  name="targetCurrentPayYieldSweetspot"
                  placeholder="Enter Target Current Pay Yield Sweetspot"
                  label="Target Current Pay Yield Sweetspot"
                />
                <Spacer size={20} />
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col xs="12" md="6">
                <Field
                  className="form-control d-inline"
                  component={reduxFormNumber}
                  decimalScale={2}
                  suffix={"%"}
                  name="targetRateMin"
                  placeholder="Enter Target Rate Minimum"
                  label="Target Rate Minimum"
                />
                <Spacer size={20} />
              </Col>
              <Col xs="12" md="6">
                <Field
                  className="form-control d-inline"
                  component={reduxFormNumber}
                  decimalScale={2}
                  suffix={"%"}
                  name="targetRateMax"
                  placeholder="Enter Target Rate Miximum"
                  label="Target Rate Miximum"
                />
                <Spacer size={20} />
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <Field
                  className="form-control d-inline"
                  component={reduxFormNumber}
                  decimalScale={2}
                  suffix={"%"}
                  name="targetRateSweetspot"
                  placeholder="Enter Target Rate Sweetspot"
                  label="Target Rate Sweetspot"
                />
                <Spacer size={20} />
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col xs="12" md="6">
                <Field
                  className="form-control d-inline"
                  component={reduxFormNumber}
                  decimalScale={2}
                  suffix={"%"}
                  name="targetIRRMin"
                  placeholder="Enter Target IRR Minimum"
                  label="Target IRR Minimum"
                />
                <Spacer size={20} />
              </Col>
              <Col xs="12" md="6">
                <Field
                  className="form-control d-inline"
                  component={reduxFormNumber}
                  decimalScale={2}
                  suffix={"%"}
                  name="targetIRRMax"
                  placeholder="Enter Target IRR Maximum"
                  label="Target IRR Maximum"
                />
                <Spacer size={20} />
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <Field
                  className="form-control d-inline"
                  component={reduxFormNumber}
                  decimalScale={2}
                  suffix={"%"}
                  name="targetIRRSweetspot"
                  placeholder="Enter Target IRR Sweetspot"
                  label="Target IRR Sweetspot"
                />
                <Spacer size={20} />
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col xs="12" md="6">
                <Field
                  className="form-control d-inline"
                  component={reduxFormNumber}
                  decimalScale={2}
                  suffix={"%"}
                  name="targetLTVMin"
                  placeholder="Enter Target LTV Minimum"
                  label="Target LTV Minimum"
                />
                <Spacer size={20} />
              </Col>
              <Col xs="12" md="6">
                <Field
                  className="form-control d-inline"
                  component={reduxFormNumber}
                  decimalScale={2}
                  suffix={"%"}
                  name="targetLTVMax"
                  placeholder="Enter Target LTV Maximum"
                  label="Target LTV Maximum"
                />
                <Spacer size={20} />
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <Field
                  className="form-control d-inline"
                  component={reduxFormNumber}
                  decimalScale={2}
                  suffix={"%"}
                  name="targetLTVSweetspot"
                  placeholder="Enter Target LTV Sweetspot"
                  label="Target LTV Sweetspot"
                />
                <Spacer size={20} />
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col xs="12" md="6">
                <Field
                  className="form-control d-inline"
                  component={reduxFormNumber}
                  decimalScale={2}
                  suffix={"%"}
                  name="targetLTCMin"
                  placeholder="Enter Target LTC Minimum"
                  label="Target LTC Minimum"
                />
                <Spacer size={20} />
              </Col>
              <Col xs="12" md="6">
                <Field
                  className="form-control d-inline"
                  component={reduxFormNumber}
                  decimalScale={0}
                  suffix={"%"}
                  name="targetLTCMax"
                  placeholder="Enter Target LTC Maximum"
                  label="Target LTC Maximum"
                />
                <Spacer size={20} />
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <Field
                  className="form-control d-inline"
                  component={reduxFormNumber}
                  decimalScale={0}
                  suffix={"%"}
                  name="targetLTCSweetspot"
                  placeholder="Enter Target LTC Sweetspot"
                  label="Target LTC Sweetspot"
                />
                <Spacer size={20} />
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col xs="12" md="6">
                <Field
                  className="form-control d-inline"
                  component={reduxFormNumber}
                  decimalScale={2}
                  suffix={"%"}
                  name="targetDebtYieldMin"
                  placeholder="Enter Target Debt Yield Minimum"
                  label="Target Debt Yield Minimum"
                />
                <Spacer size={20} />
              </Col>
              <Col xs="12" md="6">
                <Field
                  className="form-control d-inline"
                  component={reduxFormNumber}
                  decimalScale={2}
                  suffix={"%"}
                  name="targetDebtYieldMax"
                  placeholder="Enter Target Debt Yield Maximum"
                  label="Target Debt Yield Maximum"
                />
                <Spacer size={20} />
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <Field
                  className="form-control d-inline"
                  component={reduxFormNumber}
                  decimalScale={2}
                  suffix={"%"}
                  name="targetDebtYieldSweetspot"
                  placeholder="Enter Target Debt Yield Sweetspot"
                  label="Target Debt Yield Sweetspot"
                />
                <Spacer size={20} />
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col xs="12" md="6">
                <Field
                  className="form-control d-inline"
                  component={reduxFormNumber}
                  decimalScale={2}
                  suffix={"%"}
                  name="targetDSCRMin"
                  placeholder="Enter Target DSCR Minimum"
                  label="Target DSCR Minimum"
                />
                <Spacer size={20} />
              </Col>
              <Col xs="12" md="6">
                <Field
                  className="form-control d-inline"
                  component={reduxFormNumber}
                  decimalScale={2}
                  suffix={"%"}
                  name="targetDSCRMax"
                  placeholder="Enter Target DSCR Maximum"
                  label="Target DSCR Maximum"
                />
                <Spacer size={20} />
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <Field
                  className="form-control d-inline"
                  component={reduxFormNumber}
                  decimalScale={2}
                  suffix={"%"}
                  name="targetDSCRSweetspot"
                  placeholder="Enter Target DSCR Sweetspot"
                  label="Target DSCR Sweetspot"
                />
                <Spacer size={20} />
              </Col>
            </Row>
          </Container>
        </Col>
      ) : null;
    return (
      <Card>
        <Accordion.Toggle
          as={Card.Header}
          onClick={this.props.handleToggle}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          {bucketName || <i> No name provided</i>}
          <a onClick={this.handleDelete}>
            <FontAwesomeIcon icon={faTrash} />
          </a>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={this.props.form}>
          <Form
          // className="no-padding no-border"
          >
            <Row>
              {CommonBlock}
              {GeneralBlock}
              {EquityBlock}
              {MezzPrefBlock}
            </Row>
          </Form>
        </Accordion.Collapse>
      </Card>
    );
  }
}

const selectorInitialStep = formValueSelector("ProgramCreateInitialStep");

function mapStateToProps(state, ownProps) {
  const selector = formValueSelector(ownProps.form);
  return {
    action: state.action,
    initialValues: ownProps.bucket,
    bucketName: selector(state, "bucketName") || ownProps.bucket.bucketName,
    selectedRateStructure: selector(state, "rateStructure") || {},
    selectedTypicalIndex: selector(state, "typicalIndex") || {},
    selectedOtherPropertyDealTypesWeLike:
      selector(state, "otherPropertyDealTypesWeLike") || [],
    selectedProgramType: selectorInitialStep(state, "programTypes") || "",
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    enableReinitialize: true,
  })(Bucket)
);
