import React from "react";
import { connect } from "react-redux";
import {
  Accordion,
  Button,
  Row,
  Col,
  Card,
  Image,
  Dropdown,
} from "react-bootstrap";
import {
  getAllUsers,
  loginAsUser,
  partialUpdateUser,
  resendVerification,
} from "redux/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faEllipsisH, faTh } from "@fortawesome/free-solid-svg-icons";
import PopupPrompt from "components/ui/PopupPrompt";
import FilterUsers from "containers/admin/update/FilterUsers";
import { Link } from "react-router-dom";
import Avatar from "images/avatar.jpg";
import Paginate from "components/ui/Paginate";
import Loader from "components/ui/Loader";
import moment from "moment";
import CompanyCreate from "containers/company/CompanyCreate";
import Company from "containers/company/view/Company";
import Profile from "containers/profile/view/Profile";
import AddToCompany from "./AddToCompany";
import Socket from "utils/Socket";
import CreateNewUser from "containers/admin/CreateNewUser";
import { Field, reduxForm, reset } from "redux-form";
import { reduxFormInput } from "components/form/ReduxForm";
import { toast } from "react-toastify";
import api from "utils/api";
const ToastStyle = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};
class ManageUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      promptType: "",
      showPrompt: false,
      filters: {},
      loading: false,
      companyIdToView: "",
      userIdToView: "",
      userToAddToCompany: {},
      pagination: {
        page: 1,
        listOffset: 10,
      },
      showBulkOption: false,
      bulkUsers: [],
    };
  }

  componentDidMount() {
    this.fetchUsers();

    this.subscriptionId = Socket.subscribe(
      "/admin/users",
      this.handleSocketResponse
    );
  }

  handleSocketResponse = (data) => {
    const { rows } = this.props.manageUsers;
    if (rows.find((r) => r._id === data.payload.userId)) {
      this.fetchUsers();
    }
  };

  componentWillUnmount() {
    Socket.unsubscribe(this.subscriptionId);
  }

  componentDidUpdate(prevProps) {
    if (this.props.manageUsers.search !== prevProps.manageUsers.search) {
      this.setState((prevState) => ({
        pagination: { ...prevState.pagination, page: 1 },
      }));
    }
    if (
      this.props.action.CREATE_COMPANY.isFetched !==
        prevProps.action.CREATE_COMPANY.isFetched &&
      this.props.action.CREATE_COMPANY.isFetched
    ) {
      this.handleModalClose();
    }
    if (
      this.props.action.PARTIAL_UPDATE_USER.isFetched !==
        prevProps.action.PARTIAL_UPDATE_USER.isFetched &&
      this.props.action.PARTIAL_UPDATE_USER.isFetched
    ) {
      this.handleModalClose();
    }
    if (
      this.props.action.CREATE_USER.isFetched !==
        prevProps.action.CREATE_USER.isFetched &&
      this.props.action.CREATE_USER.isFetched
    ) {
      this.handleModalClose();
      this.userCreateCallback();
    }
  }

  fetchUsers = () => {
    const { pagination, filters } = this.state;
    const { manageUsers } = this.props;
    getAllUsers({
      page: pagination.page,
      limit: pagination.listOffset,
      order: "DESC",
      search: manageUsers.search,
      ...filters,
      companyId: filters.company?._id,
    });
  };

  handleShowPrompt = (showPrompt) => {
    this.setState({ showPrompt });
  };

  handlePagination = (page) => {
    this.setState(
      (prevState) => ({ pagination: { ...prevState.pagination, page } }),
      this.fetchUsers
    );
  };

  handleFilters = (filters) => {
    this.setState(
      (prevState) => ({
        filters,
        pagination: { ...prevState.pagination, page: 1 },
      }),
      this.fetchUsers
    );
    this.handleShowPrompt(false);
  };

  handleLoginAsUser = (id) => {
    loginAsUser(id);
  };

  handlePartialUpdate = (userId, action, record) => {
    if (action === "add_to_company") {
      this.setState({ userToAddToCompany: record });
      this.handleModelOpen("add_to_company");
      return;
    }
    if (action === "view_as") {
      this.handleLoginAsUser(userId);
      return;
    }
    if (action === "resend_invite") {
      resendVerification({ email: record.email });
    }
    partialUpdateUser(userId, { action });
  };

  handleAddToCompany = (userId, companyId) => {
    partialUpdateUser(userId, { action: "add_to_company", companyId });
  };

  handleCompanyViewClick = (companyIdToView) => {
    this.setState({ companyIdToView });
    this.handleModelOpen("view_company");
  };
  handleUserViewClick = (userIdToView) => {
    this.setState({ userIdToView });
    this.handleModelOpen("view_user");
  };

  handleModelOpen = (type) => {
    this.setState({ showPrompt: true, promptType: type });
  };
  handleModalClose = () => {
    this.setState({
      showPrompt: false,
      promptType: "",
      companyIdToView: "",
      userToAddToCompany: {},
    });
  };

  userCreateCallback = () => {
    this.setState({ pagination: { page: 1, listOffset: 10 } }, this.fetchUsers);
  };

  onBulkOption = () => {
    this.setState((prevState) => ({
      showBulkOption: !prevState.showBulkOption,
    }));
  };

  onFieldSelect = (e, email) => {
    let users = [...this.state.bulkUsers];
    const index = users.findIndex((comm) => comm === email);
    if (index > -1) {
      users.splice(index, 1);
    } else users.push(email);
    // users[index].isSelected = !users[index].isSelected;
    this.setState({
      bulkUsers: users,
    });
  };

  resetSelection = () => {
    this.setState({
      loading: false,
      bulkUsers: [],
    });
    const { dispatch } = this.props;
    dispatch(reset("userBlock"));
  };

  onBulkResend = () => {
    const { bulkUsers } = this.state;
    if (!bulkUsers.length) {
      toast.error("No user Selected !", ToastStyle);
      return;
    }
    this.setState({ loading: true });
    api
      .request({
        url: "/user/resend-verification/bulk",
        method: "post",
        data: { email: bulkUsers.join(",") },
      })
      .then((response) => {
        this.resetSelection();
        toast.success(response?.data?.message, ToastStyle);
      })
      .catch((error) => {
        this.resetSelection();
        toast.error(
          "Please select valid users whose status is unconfirmed!",
          ToastStyle
        );
      });
  };

  render() {
    const { action, whoami, manageUsers } = this.props;
    const { showBulkOption, loading } = this.state;
    return (
      <>
        {(action.GET_MANAGE_USERS.isFetching ||
          action.PARTIAL_UPDATE_USER.isFetching ||
          loading ||
          action.CREATE_COMPANY.isFetching) && <Loader />}
        <Row className="mb-3 pt-3" noGutters={true}>
          <Col className="d-flex align-items-center" xs="12">
            <Button
              className="fake-link bold inbox-action-item"
              variant="fake-link"
              onClick={this.onBulkOption}
              tabIndex="0"
            >
              <FontAwesomeIcon icon={faTh} /> <span>Bulk Actions</span>
            </Button>

            <div className="bulk-actions">
              {showBulkOption && (
                <Button
                  className="fake-link ml-3 inbox-action-item"
                  variant="fake-link"
                  onClick={this.onBulkResend}
                >
                  Resend Invite
                </Button>
              )}
            </div>
          </Col>
          <Col className="d-flex" xs="12">
            <div style={{ flex: 1 }} className="text-right">
              <Button
                className="pt-2 pb-2 mr-2"
                variant="primary"
                onClick={() => {
                  this.handleModelOpen("create_user");
                }}
              >
                Create User
              </Button>

              <Button
                className="pt-2 pb-2 mr-2"
                variant="primary"
                onClick={() => {
                  this.handleModelOpen("create_company");
                }}
              >
                Create Company
              </Button>
              <Button
                className="pt-2 pb-2"
                onClick={() => {
                  this.handleModelOpen("filter_users");
                }}
                variant="tertiary"
              >
                <FontAwesomeIcon className="font-14 mr-3" icon={faFilter} />
                Filter
              </Button>
            </div>
          </Col>
        </Row>
        <Row>
          <Accordion className="w-100">
            {manageUsers.rows.map((user) => {
              return (
                <UserBlock
                  user={user}
                  whoami={whoami}
                  key={user._id}
                  type="Borrower"
                  showField={showBulkOption}
                  onFieldSelect={this.onFieldSelect}
                  handlePartialUpdate={this.handlePartialUpdate}
                  handleCompanyViewClick={this.handleCompanyViewClick}
                  handleUserViewClick={this.handleUserViewClick}
                />
              );
            })}
            {!action.GET_MANAGE_USERS.isFetching &&
              manageUsers.rows.length === 0 && (
                <div className="text-center">
                  <p>
                    There are currently no users to view here. Try changing
                    filters
                  </p>
                </div>
              )}
          </Accordion>
          <Paginate
            {...this.state.pagination}
            list={manageUsers.rows}
            listTotalCount={manageUsers.count}
            previousPage={this.handlePagination}
            nextPage={this.handlePagination}
          />
        </Row>
        {this.state.promptType === "filter_users" && (
          <PopupPrompt
            size="md"
            promptTitle="Filter Users"
            promptBody={
              <FilterUsers
                filters={this.state.filters}
                handleFilters={this.handleFilters}
              />
            }
            show={this.state.showPrompt}
            onCancel={this.handleModalClose}
            onHide={this.handleModalClose}
          />
        )}
        {this.state.promptType === "create_company" && (
          <PopupPrompt
            size="xl"
            promptTitle="Create Company"
            promptBody={<CompanyCreate location={{}} pageOrigin="prompt" />}
            show={this.state.showPrompt}
            onCancel={this.handleModalClose}
            onHide={this.handleModalClose}
          />
        )}
        {this.state.promptType === "view_company" && (
          <PopupPrompt
            size="xl"
            promptTitle="Company Profile"
            promptBody={
              <Company
                location={{}}
                companyId={this.state.companyIdToView}
                pageOrigin="prompt"
              />
            }
            show={this.state.showPrompt}
            onCancel={this.handleModalClose}
            onHide={this.handleModalClose}
          />
        )}
        {this.state.promptType === "view_user" && (
          <PopupPrompt
            size="lg"
            promptTitle="User Profile"
            promptBody={<Profile userId={this.state.userIdToView} />}
            show={this.state.showPrompt}
            onCancel={this.handleModalClose}
            onHide={this.handleModalClose}
          />
        )}
        {this.state.promptType === "add_to_company" && (
          <PopupPrompt
            size="md"
            promptTitle={`Add to ${this.state.userToAddToCompany?.role} Company`}
            promptBody={
              <AddToCompany
                user={this.state.userToAddToCompany}
                handleAddToCompany={this.handleAddToCompany}
                onCancel={this.handleModalClose}
              />
            }
            show={this.state.showPrompt}
            onCancel={this.handleModalClose}
            onHide={this.handleModalClose}
          />
        )}
        {this.state.promptType === "create_user" && (
          <PopupPrompt
            promptTitle={`Register new user`}
            promptBody={
              <CreateNewUser
                user={this.state.userToAddToCompany}
                onCancel={this.handleModalClose}
              />
            }
            show={this.state.showPrompt}
            onCancel={this.handleModalClose}
            onHide={this.handleModalClose}
          />
        )}
      </>
    );
  }
}

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <Button
    ref={ref}
    variant="inbox-action"
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </Button>
));

const actions = [
  {
    type: "view_as",
    name: "View As",
    disabledIf: (user, whoami) => user._id === whoami._id,
  },
  {
    type: "resend_invite",
    name: "Resend Invite",
    hideIf: (user) => user.status !== "UNCONFIRMED",
  },
  {
    type: "suspend",
    name: "Suspend",
    hideIf: (user) => user.status === "SUSPENDED",
  },
  {
    type: "delete",
    name: "Delete",
    hideIf: (user) => user.status === "DELETED",
  },
  { type: "make_super_admin", name: "Make Super Admin" },
  {
    type: "make_company_admin",
    name: "Make Company Admin",
    hideIf: (user) => !Boolean(user.company),
  },
  {
    type: "make_company_primary_contact",
    name: "Make Company Primary Contact",
    hideIf: (user) => !Boolean(user.company),
  },
  {
    type: "add_to_company",
    name: "Add to company",
    hideIf: (user) => Boolean(user.company),
  },
];
const UserBlock = ({
  user,
  whoami,
  handlePartialUpdate,
  onFieldSelect,
  ...props
}) => {
  return (
    <Col xs="12">
      <Card className="mb-3 company-card">
        <Accordion.Toggle
          as={Card.Header}
          className="focusable-header text-center"
          tabIndex="0"
          eventKey={user._id}
        >
          <Row className="w-100" noGutters={true}>
            <Col className="d-flex align-items-center" xs="11">
              <Row className="w-100" noGutters={true}>
                <Col className="d-flex align-items-center" xs="12" sm="6">
                  {props.showField && (
                    <Field
                      style={{
                        width: "19px",
                        marginRight: "10px",
                        display: "flex",
                      }}
                      className="form-control"
                      component={reduxFormInput}
                      name={"selectUser" + user.email}
                      type="checkbox"
                      onClick={(e) => {
                        onFieldSelect(e, user.email);
                      }}
                      onKeyDown={(e) =>
                        e.keyCode === 13 ? onFieldSelect(e, user.email) : null
                      }
                    />
                  )}
                  <Image
                    className="d-inline-block mobile-img"
                    src={user.profileImageMD || Avatar}
                    alt={user.firstName + " profille picture."}
                    width="75px"
                    height="auto"
                    roundedCircle
                  />
                  <div className="text-left manage-company-view-edit">
                    <h2 className="bold text-left font-18 d-block pl-3">
                      {user.firstName} {user.lastName}
                    </h2>
                    <h4 className="text-left font-14 d-block pl-3">
                      {user.email}{" "}
                    </h4>
                    <Link
                      to={""}
                      className="d-inline ml-3 font-14"
                      onClick={(event) => {
                        event.preventDefault();
                        props.handleUserViewClick(user._id);
                      }}
                    >
                      View User
                    </Link>
                    {user.company && (
                      <>
                        <span className="ml-2 mr-2">|</span>
                        <Link
                          to={""}
                          className="d-inline mr-3 font-14"
                          onClick={(event) => {
                            event.preventDefault();
                            props.handleCompanyViewClick(user.company._id);
                          }}
                        >
                          View Company
                        </Link>
                      </>
                    )}
                  </div>
                </Col>
                <Col className="text-left" xs="6" sm="3">
                  <p className="bold">{user.role}</p>
                  <p className="font-12 color-medium-gray">Role</p>
                  <p className="bold">{user.company?.companyName || "NA"}</p>
                  <p className="font-12 color-medium-gray">Company</p>
                </Col>
                <Col className="text-left" xs="6" sm="3">
                  <p className="bold">{user.status || "NA"}</p>
                  <p className="font-12 color-medium-gray">Status</p>
                  <p className="bold">
                    {user.lastLoginAt
                      ? moment(user.lastLoginAt).format("MMM Do YYYY, hh:mm A")
                      : "NA"}
                  </p>
                  <p className="font-12 color-medium-gray">Last Login At</p>
                </Col>
              </Row>
            </Col>
            <Col
              className="d-flex align-items-center justify-content-end"
              xs="1"
            >
              <Dropdown className="d-inline-block" drop="left">
                <Dropdown.Toggle as={CustomToggle}>
                  <FontAwesomeIcon icon={faEllipsisH} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {actions.map((action) => {
                    if (action.hideIf && action.hideIf(user, whoami)) {
                      return null;
                    }
                    const isDisabled =
                      action.disabledIf && action.disabledIf(user, whoami);
                    return (
                      <Link
                        to={""}
                        key={action.type}
                        className={`dropdown-item ${
                          isDisabled ? "disabled" : ""
                        }`}
                        onClick={(event) => {
                          event.preventDefault();
                          if (!isDisabled) {
                            handlePartialUpdate(user._id, action.type, user);
                          }
                        }}
                      >
                        {action.name}
                      </Link>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>
        </Accordion.Toggle>
      </Card>
    </Col>
  );
};

function mapStateToProps(state) {
  return {
    action: state.action,
    whoami: state.auth.whoami,
    teams: state.team.teams,
    manageUsers: state.user.manageUsers,
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "userBlock",
    enableReinitialize: true,
  })(ManageUsers)
);
