import React, { useEffect, useMemo, useState } from "react";
import toLower from "lodash/toLower";
import startCase from "lodash/startCase";
import { faEllipsisH, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Col,
  Dropdown,
  Row,
  Table as BootstrapTable,
} from "react-bootstrap";
import {
  useTable,
  usePagination,
  useRowSelect,
  useFilters,
  useGlobalFilter,
} from "react-table";
import IndeterminateCheckbox from "components/ui/IndeterminateCheckbox";
import CustomMenu from "components/ui/CustomMenu";
import CustomToggle from "components/ui/CustomToggle";
import DefaultColumnFilter from "components/ui/DefaultColumnFilter";
import Spacer from "components/layout/Spacer";
import PopupPrompt from "components/ui/PopupPrompt";
import {
  getManageClauses,
  mergeClauses,
  reorderClauses,
  updateClause,
} from "redux/actions";
import { connect } from "react-redux";
import CreateEditParentClause from "./CreateEditParentClause";
import MergeClauses from "./MergeClauses";
import { reorderArray } from "utils/commonUtils";

const ManageParentClauses = (props) => {
  const [state, setState] = useState({
    promptType: "",
    showPrompt: false,
    selectedClause: null,
    selectedClauses: [],
    pagination: {
      page: 1,
      listOffset: 500,
    },
    sort: {
      direction: "ASC",
      column: "order",
    },
    mergeClausesData: null,
  });
  const [selectedRows, setSelectedRows] = useState([]);

  const handleRowSelectChange = (selectedRows) => {
    setSelectedRows(selectedRows);
  };

  const handleModalClose = () =>
    setState((prevState) => ({
      ...prevState,
      showPrompt: false,
      willContinue: false,
      promptType: "",
      selectedClause: null,
      selectedClauses: [],
    }));

  const handleModelOpen = (promptType, selectedClause) => {
    const { whoami } = props;
    setState((prevState) => ({
      ...prevState,
      showPrompt: true,
      promptType,
      selectedClause,
      selectedClauses: [selectedClause],
      willContinue: whoami.role === "Admin",
      mergeClausesData: null,
    }));
  };

  const handleRowAction = (action, rowValues) => {
    handleModelOpen(action, rowValues);
  };

  const handleApprove = (action, rowValues) => {
    updateClause(rowValues._id, {
      status: "ACTIVE",
    });
  };

  const handleContinue = () => {
    setState((prevState) => ({
      ...prevState,
      willContinue: true,
    }));
  };

  const handleReorder = ({ fromIndex, toIndex }) => {
    const newRows = reorderArray(props.manageClauses.rows, fromIndex, toIndex);
    const clauses = newRows.map((e, i) => ({ _id: e._id, order: i + 1 }));
    reorderClauses({ clauses });
  };

  const orderOptions = useMemo(() => {
    return props.manageClauses.rows.map((item) => ({
      _id: item._id,
      value: item.order,
      label: item.order,
    }));
  }, [props.manageClauses.rows]);

  const columns = useMemo(
    () => [
      {
        Header: "Order",
        accessor: "order",
        disableFilters: true,
      },
      {
        Header: "Parent Clause",
        accessor: "clauseName",
      },
      {
        Header: "Category",
        accessor: "category",
        Cell: ({ row }) => {
          return (
            <div>
              <div>{row.original.category.categoryName}</div>
            </div>
          );
        },
        filter: (rows, columnIds, filterValue) => {
          return rows.filter((row) =>
            toLower(row.original.category.categoryName).includes(
              toLower(filterValue)
            )
          );
        },
      },
      {
        Header: () => (
          <span>
            Company <br />
            Owner
          </span>
        ),
        accessor: "company",
        Cell: ({ row }) => {
          return (
            <div>
              <div>{row.original.owner?.company?.companyName}</div>
              <div>
                {row.original.owner?.firstName +
                  " " +
                  row.original.owner?.lastName}
              </div>
            </div>
          );
        },
        filter: (rows, columnIds, filterValue) => {
          return rows.filter((row) =>
            toLower(
              `${row.original.owner?.company?.companyName} ${row.original.owner?.firstName} ${row.original.owner?.lastName}`
            ).includes(toLower(filterValue))
          );
        },
      },
      {
        Header: "Date Last Edited",
        accessor: "updatedAt",
        disableFilters: true,
        Cell: ({ row }) => {
          return (
            <div>{new Date(row.original.updatedAt).toLocaleDateString()}</div>
          );
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ row }) => {
          return <div>{startCase(toLower(row.original.status))}</div>;
        },
      },
      {
        Header: "In Use",
        accessor: "inUse",
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <Dropdown className="d-inline-block">
              <Dropdown.Toggle as={CustomToggle}>
                <p className="link-text">
                  <FontAwesomeIcon icon={faEllipsisH} />
                </p>
              </Dropdown.Toggle>

              <Dropdown.Menu as={CustomMenu}>
                <Dropdown.Item
                  onClick={() =>
                    handleRowAction("edit", cellProps.cell.row.original)
                  }
                >
                  View / Edit
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    handleRowAction("change_order", cellProps.cell.row.original)
                  }
                >
                  Change Order
                </Dropdown.Item>

                <Dropdown.Item
                  onClick={() =>
                    handleRowAction("merge", cellProps.cell.row.original)
                  }
                >
                  Merge
                </Dropdown.Item>
                {cellProps.cell.row.original.status === "UNDER_REVIEW" && (
                  <Dropdown.Item
                    onClick={() =>
                      handleApprove("approve", cellProps.cell.row.original)
                    }
                  >
                    Approve
                  </Dropdown.Item>
                )}
                <Dropdown.Item
                  onClick={() =>
                    handleRowAction(
                      "change_category",
                      cellProps.cell.row.original
                    )
                  }
                >
                  Change Category
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          );
        },
      },
    ],
    []
  );

  const tableData = useMemo(
    () => props.manageClauses.rows.filter((e) => !e.parent),
    [props.manageClauses]
  );

  const fetchClauses = () => {
    const { pagination, sort } = state;
    const { manageClauses } = props;
    getManageClauses({
      page: pagination.page,
      limit: pagination.listOffset,
      search: manageClauses.search,
      order: sort.direction,
      orderColumn: sort.column,
      parent: true,
    });
  };

  const handleMergeClauses = (ids = [], clauseName) => {
    if (ids.length) {
      setState((prevState) => ({
        ...prevState,
        mergeClausesData: { ids, clauseName },
      }));
    }
  };

  useEffect(() => {
    handleModalClose();
    fetchClauses();
  }, [
    props.lastClauseCreated,
    props.lastClauseUpdated,
    props.lastClauseDeleted,
    props.lastClauseMerged,
    props.lastClauseReorderDate,
  ]);

  useEffect(() => {
    if (props.lastClauseCreated && state.mergeClausesData) {
      mergeClauses({
        clauseName: state.mergeClausesData.clauseName,
        ids: [props.lastClauseCreated._id, ...state.mergeClausesData.ids],
      });
      setState((prevState) => ({
        ...prevState,
        mergeClausesData: null,
      }));
    }
  }, [props.lastClauseCreated, state.mergeClausesData]);

  return (
    <Row>
      <Col xs={12} className="d-flex justify-content-between">
        <div>
          {selectedRows.length > 0 && (
            <>
              Bulk Actions{" "}
              <Dropdown className="d-inline-block">
                <Dropdown.Toggle as={CustomToggle}>
                  <h4>
                    <FontAwesomeIcon icon={faChevronDown} />
                  </h4>
                </Dropdown.Toggle>

                <Dropdown.Menu as={CustomMenu}>
                  <Dropdown.Item>Change Status</Dropdown.Item>
                  <Dropdown.Item>Merge</Dropdown.Item>
                  <Dropdown.Item>Approve</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </>
          )}
        </div>
        <Button
          type="submit"
          variant="tertiary"
          onClick={() => handleModelOpen("create")}
        >
          New Parent Clause
        </Button>
      </Col>
      <Col xs={12}>
        <Spacer size={8} />
        <Table
          columns={columns}
          data={tableData}
          onRowSelectChange={handleRowSelectChange}
        />
      </Col>

      {(state.promptType === "create" ||
        state.promptType === "edit" ||
        state.promptType === "change_order" ||
        state.promptType === "change_category") && (
        <PopupPrompt
          promptTitle={
            state.promptType === "create"
              ? state.willContinue
                ? "Suggest New Parent Clause"
                : "Confirmation - Add New Parent Clause"
              : state.promptType === "edit"
              ? "Edit Parent Clause"
              : state.promptType === "change_order"
              ? `Change Order for ${state.selectedClause?.clauseName}`
              : state.promptType === "change_category"
              ? "Change Category"
              : ""
          }
          promptBody={
            <CreateEditParentClause
              promptType={state.promptType}
              willContinue={state.willContinue}
              selectedClause={state.selectedClause}
              orderOptions={orderOptions}
              onCancel={handleModalClose}
              onContinue={handleContinue}
              onReorder={handleReorder}
              onMergeClauses={handleMergeClauses}
            />
          }
          show={state.showPrompt}
          onCancel={handleModalClose}
          onHide={handleModalClose}
        />
      )}
      {state.promptType === "merge" && (
        <PopupPrompt
          promptTitle="Merge Parent Clauses"
          promptBody={
            <MergeClauses
              promptType={state.promptType}
              selectedClauses={state.selectedClauses}
              onCancel={handleModalClose}
            />
          }
          show={state.showPrompt}
          onCancel={handleModalClose}
          onHide={handleModalClose}
        />
      )}
    </Row>
  );
};

const Table = ({ columns, data, onRowSelectChange }) => {
  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        pageSize: 100,
      },
    },
    useFilters,
    useGlobalFilter,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

  useEffect(() => {
    onRowSelectChange(selectedFlatRows);
  }, [selectedFlatRows]);

  return (
    <BootstrapTable bordered hover {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}>
                <div className="d-flex flex-column">
                  {column.render("Header")}
                  {column.canFilter && column.render("Filter")}
                </div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {page.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </BootstrapTable>
  );
};

function mapStateToProps(state) {
  return {
    action: state.action,
    whoami: state.auth.whoami,
    manageClauses: state.clause.manageClauses,
    lastClauseCreated: state.clause.lastClauseCreated,
    lastClauseUpdated: state.clause.lastClauseUpdated,
    lastClauseDeleted: state.clause.lastClauseDeleted,
    lastClauseMerged: state.clause.lastClauseMerged,
    lastClauseReorderDate: state.clause.lastClauseReorderDate,
  };
}

export default connect(mapStateToProps)(ManageParentClauses);
