import React from "react";
import { Badge, Form, Row, Col, Button } from "react-bootstrap";
import { reduxForm } from "redux-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { v4 as uuidv4 } from "uuid";
import Spacer from "components/layout/Spacer";
import FileExplorer from "containers/deals/FileManager/FileExplorer";
import { ALLOWED_FILE_EXTS } from "containers/deals/FileManager/constants";

const allowedFileTypes = ALLOWED_FILE_EXTS;
class UploadFiles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      option: 0,
      selectedFiles: [],
      dropActive: false,
    };

    this.submitFn = this.submitFn.bind(this);
  }

  submitFn() {
    const { onSubmit } = this.props;

    onSubmit(this.state.selectedFiles);
  }

  isValidFile = (file) => {
    for (let i in allowedFileTypes) {
      if (file.name.toLowerCase().indexOf(allowedFileTypes[i]) !== -1) {
        return true;
      }
    }
    return false;
  };

  render() {
    const { dealId, handleSubmit, onCancel } = this.props;

    return (
      <>
        {this.state.option === 0 ? (
          <Form
            className="no-border no-padding"
            onSubmit={handleSubmit(this.submitFn)}
          >
            <div className="file-upload-wrapper">
              <Row noGutters={true} style={{ padding: "30px 0px" }}>
                <Col className="d-none d-md-flex border-right" md="8">
                  <div
                    className={`d-flex justify-content-center align-items-center w-100 ${
                      this.state.dropActive ? "droptarget-active" : ""
                    }`}
                  >
                    <p className="bold">Drop files here</p>
                    <input
                      className="drag-drop-file-upload"
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                      type="file"
                      accept={allowedFileTypes.join(",")}
                      multiple
                      onDragOver={(e) => {
                        this.setState({ dropActive: true });
                      }}
                      onDragLeave={(e) => {
                        this.setState({ dropActive: false });
                      }}
                      onDrop={(e) => {
                        if (e.dataTransfer.items) {
                          for (let file of e.dataTransfer.items) {
                            let tempFile = file.getAsFile();
                            if (!this.isValidFile(tempFile)) {
                              continue;
                            }
                            this.setState((prevState) => ({
                              selectedFiles: [
                                ...prevState.selectedFiles,
                                {
                                  _id: uuidv4(),
                                  file: tempFile,
                                  name: tempFile.name,
                                },
                              ],
                            }));
                          }
                        }
                        this.setState({
                          dropActive: false,
                        });
                      }}
                    />
                  </div>
                </Col>
                <Col
                  className="d-flex justify-content-center align-items-center"
                  xs="12"
                  md="4"
                >
                  <div className="text-center">
                    <p className="d-none d-md-block">or</p>
                    <div className="d-block">
                      <Button
                        className="fake-link"
                        variant="fake-link"
                        onClick={() => this.setState({ option: 1 })}
                      >
                        Select from File Manager
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <Spacer size={5} />
            <div className="message-uploads">
              {this.state.selectedFiles.map((file, idx) => {
                return (
                  <Badge
                    className="mr-2"
                    variant="secondary"
                    key={idx}
                    tabIndex="0"
                    pill
                    onClick={() => {
                      this.setState({
                        selectedFiles: this.state.selectedFiles.filter(
                          (arrFile) => {
                            return arrFile._id !== file._id;
                          }
                        ),
                      });
                    }}
                    onKeyDown={(e) =>
                      e.keyCode === 13 ? document.activeElement.click() : null
                    }
                  >
                    <FontAwesomeIcon icon={faTimesCircle} /> {file.name}
                  </Badge>
                );
              })}
            </div>
            <Spacer size={20} />
            <Row>
              <Col className="text-right" xs="12">
                <Button
                  className="mr-3"
                  variant="dark"
                  onClick={() => onCancel()}
                >
                  Cancel
                </Button>
                <Button type="submit" variant="primary">
                  Insert
                </Button>
              </Col>
            </Row>
          </Form>
        ) : (
          <FileExplorer
            dealId={dealId}
            fromComms={true}
            onOptionChange={() => this.setState({ option: 0 })}
            onSubmit={(files) => {
              for (let i in files) {
                if (!this.isValidFile(files[i])) {
                  continue;
                }
                this.setState((prevState) => ({
                  option: 0,
                  selectedFiles: [...prevState.selectedFiles, files[i]],
                }));
              }
            }}
          />
        )}
      </>
    );
  }
}

export default reduxForm({
  form: "UploadFiles",
  enableReinitialize: true,
})(UploadFiles);
