import { dispatch } from "config/store";
import { actionTypes } from "./actionTypes";

// Auth
export const signUp = (data) => {
  const action = {
    type: actionTypes.REGISTER_USER,
    config: {
      method: "post",
      url: `/user/create`,
      data,
    },
  };

  dispatch(action);
};

export const loginUser = (data) => {
  const action = {
    type: actionTypes.LOGIN_USER,
    config: {
      method: "post",
      url: "/user/login",
      data,
    },
  };

  dispatch(action);
};

export const verifyAccountStatus = (data) => {
  const action = {
    type: actionTypes.VERIFY_ACCOUNT_STATUS,
    config: {
      method: "post",
      url: "/user/status-verification",
      data,
    },
  };

  dispatch(action);
};

export const getUserForSignUp = (token) => {
  const action = {
    type: actionTypes.GET_USER_FOR_SIGNUP,
    config: {
      method: "get",
      url: `/user/tempToken/${token}`,
    },
  };

  dispatch(action);
};

export const updateProfile = (data, doNotRedirect) => {
  const action = {
    type: actionTypes.UPDATE_PROFILE,
    config: {
      method: "put",
      url: `/user/profile/update`,
      data,
      doNotRedirect,
    },
  };

  dispatch(action);
};

export const updateUser = (id, data) => {
  const action = {
    type: actionTypes.UPDATE_USER,
    config: {
      method: "put",
      url: `/user/update/${id}`,
      data,
    },
  };

  dispatch(action);
};

export const setPassword = (data) => {
  const action = {
    type: actionTypes.SET_PASSWORD,
    config: {
      method: "post",
      url: `/user/setpassword`,
      data,
    },
  };

  dispatch(action);
};
export const updatePassword = (data) => {
  const action = {
    type: actionTypes.UPDATE_PASSWORD,
    config: {
      method: "post",
      url: `/user/setpassword`,
      data,
    },
  };

  dispatch(action);
};

export const signOut = () => {
  const action = {
    type: actionTypes.SIGN_OUT,
    config: {
      method: "post",
      url: "/user/logout",
    },
  };

  dispatch(action);
};

export const deactivateAccount = (data) => {
  const action = {
    type: actionTypes.DEACTIVATE_ACCOUNT,
    config: {
      method: "put",
      url: `/user/deactivate/confirm/`,
      data,
    },
  };

  dispatch(action);
};

export const deactivateRequest = (data) => {
  const action = {
    type: actionTypes.REQUEST_DEACTIVATION,
    config: {
      method: "put",
      url: `/user/deactivate/request`,
      data,
    },
  };
  dispatch(action);
};
export const forgotPassword = (data) => {
  const action = {
    type: actionTypes.FORGOT_PASSWORD,
    config: {
      method: "post",
      url: "/user/forgot",
      data,
    },
  };

  dispatch(action);
};

export const resendVerification = (data) => {
  const action = {
    type: actionTypes.RESEND_VERIFICATION,
    config: {
      method: "post",
      url: "/user/resend-verification",
      data,
    },
  };

  dispatch(action);
};

export const getMyProfile = () => {
  const action = {
    type: actionTypes.GET_MY_PROFILE,
    config: {
      method: "get",
      url: "/user/profile",
    },
  };

  dispatch(action);
};

export const loginAsUser = (userId) => {
  const action = {
    type: actionTypes.LOGIN_AS_USER,
    config: {
      method: "post",
      url: `/user/loginAs/${userId}`,
      data: {},
    },
  };

  dispatch(action);
};

export const canDeactivateAccount = () => {
  const action = {
    type: actionTypes.CAN_DEACTIVATE_ACCOUNT,
    config: {
      method: "get",
      url: "/user/canDeactivate",
      data: {},
    },
  };

  dispatch(action);
};
