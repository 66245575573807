import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import {
  Image,
  Container,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
  Button,
} from "react-bootstrap";
import { toast } from "react-toastify";
import Spacer from "components/layout/Spacer";
import ProfileCompanies from "./ProfileCompanies";
import ProfileTeams from "./ProfileTeams";
import Loader from "components/ui/Loader";
import SocialMediaButton from "components/ui/SocialMediaButton";
import Avatar from "images/avatar.jpg";
import { getUserProfile } from "redux/actions";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

class Profile extends React.Component {
  constructor(props) {
    super(props);

    this.copyID = this.copyID.bind(this);
  }

  componentDidMount() {
    const userId = this.props.userId || this.props.whoami._id;
    if (userId) {
      getUserProfile(userId);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.whoami?._id !== prevProps.whoami?._id &&
      !this.props.userId
    ) {
      getUserProfile(this.props.whoami._id);
    }
  }

  copyID(id) {
    navigator.clipboard.writeText(id);
    toast.success("ID copied!", {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  render() {
    const { action, user, userId } = this.props;
    const {
      _id,
      firstName,
      lastName,
      email,
      linkedin,
      phoneMobile,
      phoneLandline,
      title,
      bio,
      profileImage,
    } = user;

    return (
      <>
        {(action.GET_USER_PROFILE.isFetching ||
          action.GET_MY_PROFILE.isFetching) && <Loader />}
        <>
          <div className="text-right">
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip id={`tooltip-bottom`}>Edit</Tooltip>}
            >
              <Button
                as={Link}
                to={userId ? `/profile/edit/${_id}` : `/profile/edit`}
                className="pl-3 pt-2 pr-3 pb-2"
              >
                <FontAwesomeIcon icon={faEdit} />
              </Button>
            </OverlayTrigger>
          </div>
        </>
        <Spacer size={50} />
        <Container fluid>
          <Row>
            <Col
              className="d-block d-sm-none d-flex align-items-center"
              xs="12"
            >
              <Image
                className="d-inline-block mobile-img"
                src={profileImage ? profileImage : Avatar}
                alt="Profile picture."
                width="50px"
                height="auto"
              />
              {firstName && lastName && (
                <h2 className="bold d-inline-block pl-3">
                  {firstName + " " + lastName}
                </h2>
              )}
            </Col>
            <Col className="d-none d-sm-block" xs="0" sm="4" lg="2">
              <Image
                className="mobile-img"
                src={profileImage ? profileImage : Avatar}
                alt="Profile picture."
                width="100%"
                height="auto"
              />
            </Col>
            <Col xs="12" sm="8" lg="10">
              {firstName && lastName && (
                <h3 className="bold d-none d-sm-block">
                  {firstName + " " + lastName}
                </h3>
              )}
              <Spacer size={20} />
              {!_.isEmpty(linkedin) && (
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip id={`tooltip-bottom`}>LinkedIn</Tooltip>}
                >
                  <div className="mr-2 d-inline-block">
                    <SocialMediaButton icon="linkedin" url={linkedin} />
                  </div>
                </OverlayTrigger>
              )}
              {!_.isEmpty(email) && (
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip id={`tooltip-bottom`}>Email</Tooltip>}
                >
                  <div className="mr-2 d-inline-block">
                    <SocialMediaButton icon="email" data={"mailto:" + email} />
                  </div>
                </OverlayTrigger>
              )}
              {!_.isEmpty(phoneMobile) && (
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip id={`tooltip-bottom`}>Mobile Phone</Tooltip>
                  }
                >
                  <div className="mr-2 d-inline-block">
                    <SocialMediaButton
                      icon="mobile"
                      data={"tel:" + phoneMobile}
                    />
                  </div>
                </OverlayTrigger>
              )}
              {!_.isEmpty(phoneLandline) && (
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip id={`tooltip-bottom`}>Office Phone</Tooltip>
                  }
                >
                  <div className="mr-2 d-inline-block">
                    <SocialMediaButton
                      icon="office"
                      data={"tel:" + phoneLandline}
                    />
                  </div>
                </OverlayTrigger>
              )}
              <Spacer size={10} />
              {title && <p className="bold">Title: {title}</p>}
              <Spacer size={10} />
              {user && _id && (
                <div>
                  <h5 className="bold">User ID</h5>
                  <span
                    className="bold my-id"
                    onClick={() => this.copyID(_id)}
                    onKeyDown={(e) =>
                      e.keyCode === 13 ? document.activeElement.click() : null
                    }
                    tabIndex="0"
                  >
                    {_id}
                  </span>
                  <span className="font-12">
                    {" "}
                    (Only send this to your company admin)
                  </span>
                </div>
              )}
              <Spacer size={10} />
              {!_.isEmpty(bio) && (
                <>
                  <h5 className="bold">Bio</h5>
                  <p className="bio">{bio}</p>
                </>
              )}
            </Col>
          </Row>
          <Spacer size={30} />
          {!action.GET_USER_PROFILE.isFetching && !_.isEmpty(user) && (
            <ProfileCompanies />
          )}
          {!action.GET_USER_PROFILE.isFetching && !_.isEmpty(user) && (
            <ProfileTeams />
          )}
        </Container>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    action: state.action,
    user: state.user.userProfile,
    whoami: state.auth.whoami,
  };
}

export default connect(mapStateToProps)(Profile);
