import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Spacer from "components/layout/Spacer";
import CustomMenu from "components/ui/CustomMenu";
import CustomToggle from "components/ui/CustomToggle";
import Loader from "components/ui/Loader";
import PopupPrompt from "components/ui/PopupPrompt";
import kebabCase from "lodash/kebabCase";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button, Col, Dropdown, ListGroup, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getDealById, storeCompareQuotes } from "redux/actions";
import AddClauseCompare from "./AddClauseCompare";
import DownloadQuotes from "./DownloadQuotes";
import DownloadTermsheet, { showDownloadSuccess } from "./DownloadTermsheet";
import CompareQuoteCards from "./CompareQuotes/CompareQuoteCards";
import { getAllFields, getSortedAllFields } from "./CompareQuotes/utils";
import { getPrimaryClauses } from "containers/quotes/quotesConfig";
import { getIndexAsOfLabel } from "utils/commonUtils";

const primaryClauses = getPrimaryClauses();
const primaryFields = [
  {
    label: "Status",
  },
  {
    label: "Lender",
  },
  ...primaryClauses,
];

const CompareQuotes = (props) => {
  const {
    match,
    history,
    action,
    whoami,
    deal,
    compareQuotes,
    compareQuotesAll,
    manageClauses,
  } = props;
  console.log(
    "file: CompareQuotes.js:44 ~ CompareQuotes ~ compareQuotes:",
    compareQuotes
  );
  const { dealId } = match.params;
  const borrowerCompany = deal?.company;
  const { rows: parentClauses } = manageClauses;

  const colRef = useRef();
  const [width, setWidth] = useState(200);
  const [selected, setSelected] = useState(compareQuotes.map((e) => e._id));
  const [state, setState] = useState({ showPrompt: false });
  const [addedClauses, setAddedClauses] = useState([]);

  const addedFields = useMemo(
    () =>
      addedClauses.map((e) => ({
        label: e.displayName || e.clauseName,
        clause: e,
      })),
    [addedClauses]
  );

  const allFields = useMemo(
    () => getAllFields(primaryFields, addedFields),
    [primaryFields, addedFields]
  );

  const sortedAllFields = useMemo(
    () => getSortedAllFields(allFields, parentClauses),
    [allFields, parentClauses]
  );
  console.log(
    "file: CompareQuotes.js:72 ~ CompareQuotes ~ sortedAllFields:",
    sortedAllFields
  );

  useEffect(() => {
    if (colRef.current) {
      setWidth(colRef.current.getBoundingClientRect().width);
    }
  }, [colRef.current]);

  useEffect(() => {
    getDealById(dealId);
  }, [dealId]);

  const getIsArchived = (quote) => {
    return quote.isArchivedBy?.includes(whoami._id);
  };

  const handleSelected = (id) => {
    setSelected((prev) => {
      let newSelected = [];
      if (prev.includes(id)) {
        newSelected = prev.filter((e) => e !== id);
      } else {
        newSelected = [...prev, id];
      }
      return newSelected;
    });
  };

  const handleRemoveQuote = (quote) => {
    const newCompareQuotes = compareQuotes.filter((e) => e._id !== quote._id);
    storeCompareQuotes(newCompareQuotes);
  };

  const handleRemoveQuotes = () => {
    const newCompareQuotes = compareQuotes.filter(
      (e) => !selected.some((s) => s === e._id)
    );
    storeCompareQuotes(newCompareQuotes);
  };

  const handleShowPrompt = (showPrompt) => {
    setState((prev) => ({ ...prev, showPrompt }));
  };

  const handleDownloadDocx = (quote) => {
    const { termsheet } = quote;
    DownloadTermsheet.DOCX({ termsheet, deal });
  };

  const handleDownloadMultipleXlsx = async () => {
    const compareQuotesIds = compareQuotes.map((quote) => quote._id);
    const selectedTermsheetIds = compareQuotesAll
      .filter((quote) => compareQuotesIds.includes(quote._id))
      .map((quote) => quote.termsheetId);
    const selectedQuotes = compareQuotesAll.filter((quote) =>
      selectedTermsheetIds.includes(quote.termsheetId)
    );
    DownloadQuotes.XLSX(selectedQuotes, addedFields, parentClauses, {
      deal,
      whoami,
      borrowerCompany,
      fileName: "Compare Quotes",
    });
    showDownloadSuccess();
  };

  const noSelection = !selected.length;

  return (
    <Row className="CompareQuotes">
      <Col xs={12}>
        <Spacer size={10} />

        <Row>
          <Col xs={12}>
            <b>{deal?.propertyName || " "}</b>
          </Col>
          <Col xs={12}>
            <hr />
          </Col>
        </Row>

        {!action.GET_MANAGE_CLAUSES.isFetching && (
          <Row>
            <Col xs="auto">
              <div style={{ width: 200 }}></div>
            </Col>

            <Col xs>
              <div>
                Bulk Actions{" "}
                <Dropdown className="d-inline-block">
                  <Dropdown.Toggle as={CustomToggle}>
                    <h4>
                      <FontAwesomeIcon icon={faChevronDown} />
                    </h4>
                  </Dropdown.Toggle>

                  <Dropdown.Menu as={CustomMenu}>
                    <Dropdown.Item
                      disabled={noSelection}
                      onClick={handleRemoveQuotes}
                    >
                      Remove
                    </Dropdown.Item>
                    <Dropdown.Item
                      disabled={noSelection}
                      onClick={handleDownloadMultipleXlsx}
                    >
                      Download All (.xlsx)
                    </Dropdown.Item>
                    <Dropdown.Item disabled={noSelection}>
                      Print All
                    </Dropdown.Item>
                    <Dropdown.Item disabled={noSelection}>
                      Send Message
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </Col>
          </Row>
        )}

        {action.GET_MANAGE_CLAUSES.isFetching && (
          <Row>
            <Loader />
          </Row>
        )}

        <Spacer size={10} />

        {!action.GET_MANAGE_CLAUSES.isFetching && (
          <Row>
            <Col xs="auto">
              <div style={{ width: 200 }}>
                <div
                  style={{ height: 24 }}
                  className="d-flex align-items-center justify-content-between my-2"
                ></div>
                <ListGroup variant="flush" className="headers">
                  {sortedAllFields.map((field) => (
                    <ListGroup.Item
                      key={field.label}
                      className={kebabCase(field.label)}
                    >
                      {field.label === "Spread"
                        ? getIndexAsOfLabel()
                        : field.label}
                    </ListGroup.Item>
                  ))}
                </ListGroup>
                <div
                  style={{ height: 24 }}
                  className="d-flex align-items-center justify-content-between my-2"
                >
                  <Link
                    className="d-inline"
                    to="/"
                    onClick={(e) => {
                      e.preventDefault();
                      handleShowPrompt(true);
                    }}
                  >
                    Add Clause to Compare
                  </Link>
                </div>
              </div>
            </Col>
            <Col xs ref={colRef}>
              <div
                className="d-flex overflow-auto"
                style={{ maxWidth: width - 60 }}
              >
                <CompareQuoteCards
                  {...{
                    history,
                    selected,
                    sortedAllFields,
                    compareQuotes,
                    deal,
                    whoami,
                    borrowerCompany,
                    getIsArchived,
                    handleSelected,
                    handleRemoveQuote,
                    handleDownloadDocx,
                  }}
                />
              </div>
            </Col>
          </Row>
        )}

        <Spacer size={30} />
        <Row>
          <Col className="text-right">
            <Link to={`/deal/${dealId}/quote-summary`}>
              <Button variant="primary">Go Back</Button>
            </Link>
          </Col>
        </Row>
        <Spacer size={30} />
      </Col>

      <PopupPrompt
        promptTitle="Add Clause to Compare"
        promptBody={
          <AddClauseCompare
            dealId={dealId}
            compareQuotes={compareQuotes}
            addedClauses={addedClauses}
            primaryFields={primaryFields}
            onSubmit={(selectedClauses) => {
              setAddedClauses((prevAdded) => [
                ...prevAdded,
                ...selectedClauses,
              ]);
              handleShowPrompt(false);
            }}
          />
        }
        show={state.showPrompt}
        onCancel={() => handleShowPrompt(false)}
        onHide={() => handleShowPrompt(false)}
      />
    </Row>
  );
};

const mapStateToProps = (state) => ({
  action: state.action,
  whoami: state.auth.whoami,
  deal: state.deal.deal,
  compareQuotes: state.termsheet.compareQuotes,
  compareQuotesAll: state.termsheet.compareQuotesAll,
  manageClauses: state.clause.manageClauses,
});
export default connect(mapStateToProps)(CompareQuotes);
