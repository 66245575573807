const constants = {
  API_PROTOCOL: `https://`,
  API_ORIGINS: {
    "localhost:3000": "api.dev-termst.srcblox.com",
    // "localhost:3000": "localhost:5000",
    "dev-termst.srcblox.com": "api.dev-termst.srcblox.com",
    "qa-termst.srcblox.com": "api.qa-termst.srcblox.com",
    "app.termst.com": "api.termst.com",
    "staging.termst.com": "api-staging.termst.com",
    "qa.termst.com": "qa-api.termst.com",
    "demo.termst.com": "demo-api.termst.com",
  },
  API_REQ_HEADER_CONTENT_TYPE: "application/json",
  // Using a localstorage name and keys which is hard to predict for better security
  TERMST_LOCAL_STORAGE: {
    STORAGE_NAME: "RWoRTheAtHdARNAr",
    JWT_KEY: "nErTorENDectaiND",
    TEMP_TOKEN_KEY: "eadIamEntoSInetH",
    ROLE: "dATelIthETORINdE",
    IS_AUTH_KEY: "artandrOcaLmAnCe",
    CURRENT_DEAL: "ctaaJLteJjfdsaKL",
    DEAL_ID: "pedadIaRWoRasRTC",
    DEAL_TEAM_ID: "asRTelIWoRTadIaR",
    NEEDS_COMPANY: false,
    PREV_JWT_KEY: "akoiweEkkSkdWkldA",
    PORTIFOLIO_DEAL_COUNT:"saielIWoRTadsan",
    PORTIFOLIO_DEAL:"saielIWoRTadsan"
  },
  TERMST_ROLEID_ROUTES_MAPPING: {
    Admin: "adminRoutes",
    Lender: "lenderRoutes",
    Borrower: "borrowerRoutes",
  },
  NEW_RELIC: {
    APPLICATION_ID: "691094525",
    LICENSE_KEY: "NRJS-3944cafe5b24af558ab",
  },
  INTERCOM_APP_ID: "mkpz8ar5",
};

export default constants;
