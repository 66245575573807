import React from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { Field, reduxForm } from "redux-form";
import { required, noSpaces } from "utils/validation";
import { reduxFormInput, reduxFormTextarea } from "components/form/ReduxForm";
import { createSponsor, updateSponsor } from "redux/actions";
import { toast } from "react-toastify";
import Spacer from "components/layout/Spacer";
import { connect } from "react-redux";

class SponsorCreate extends React.Component {
  constructor(props) {
    super(props);

    this.submitFn = this.submitFn.bind(this);
    this.submitError = this.submitError.bind(this);
  }

  submitFn(data) {
    const { companyId, sponsors, onSubmit, isEdit, selectedSponsor } = this.props;
    const isExists = sponsors.find((sponsor) => sponsor.sponsorName === data.sponsorName)
    const updateCallback = isEdit? updateSponsor : createSponsor
    if (!isExists || (isExists && isExists._id === selectedSponsor?._id)) {
      updateCallback(companyId, {
        sponsorName: data.sponsorName,
        keyPrincipleBios: data.keyPrincipleBios,
      },
      selectedSponsor?._id
      );

      onSubmit();
    } else {
      this.submitError("That key principal already exists!");
    }
  }

  submitError(text) {
    toast.error(text, {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  render() {
    const { handleSubmit, isEdit } = this.props;

    return (
      <>
        <Form
          className="full-width no-border no-padding"
          onSubmit={handleSubmit(this.submitFn)}
        >
          <Row>
            <Col xs="12">
              <Field
                className="form-control"
                component={reduxFormInput}
                validate={[required, noSpaces]}
                name="sponsorName"
                type="text"
                placeholder="Enter Key Principal name..."
                label="*Key Principal Name"
              />
              <Spacer size={20} />
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <Field
                className="form-control"
                component={reduxFormTextarea}
                validate={[noSpaces]}
                name="keyPrincipleBios"
                rows="10"
                type="text"
                placeholder="Please describe..."
                label="Key Principal Bios"
              />
            </Col>
          </Row>
          <Row>
            <Col className="text-right" xs="12">
              <Spacer size={50} />
              <Button type="submit" variant="primary">
              {isEdit ? "Update" : "Add"}
              </Button>
            </Col>
          </Row>
        </Form>
      </>
    );
  }
}
function mapStateToProps(state, props) {
  return {
    action: state.action,
    initialValues: {
      sponsorName: props.selectedSponsor?.sponsorName,
      keyPrincipleBios: props.selectedSponsor?.keyPrincipleBios,
    },
  };
}

export default  connect(mapStateToProps)(reduxForm({
  form: "AddSponsor",
  destroyOnUnmount: false,
  enableReinitialize: true,
})(SponsorCreate))