export const ALLOWED_FILE_MANAGER_PICTURE_FILE_TYPES = [
  "jpg",
  "png",
  "jpeg",
  "gif",
];

export const ALLOWED_FILE_EXTS = [
  ".pdf",
  ".jpg",
  ".jpeg",
  ".png",
  ".gif",
  ".doc",
  ".docx",
  ".ppt",
  ".pptx",
  ".ppt",
  ".pptx",
  ".xls",
  ".xlsx",
  ".xlsm",
];

export const ALLOWED_IMAGE_EXTS = [".jpg", ".jpeg", ".png", ".gif"];
