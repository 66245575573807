import { call, put } from "redux-saga/effects";
import { makeApiRequest } from "../config/axiosInstance";
import {
  actionTypes,
  getDealById,
  createTeam,
  updateTeam,
  getCompanyById,
  updateDeal,
  sendMessage,
  answerComm,
  getCommById,
  createDealFolders,
  getMyCompany,
  getGroups,
  storeQuoteMatrices,
  storeSections,
} from "redux/actions";
import { getRequestType, getSuccessType } from "redux/reducers";
import {
  removeKeyFromLocal,
  storeJsonInLocal,
  destroyLocal,
  getKeyFromLocal,
} from "utils/lsUtils";
import { toast } from "react-toastify";
import constants from "config/constants";
import { getIdentityForIntercom } from "utils/authUtils";
import { getDealCreationPath } from "utils/commonUtils";

const updateUserInIntercom = (action) => {
  console.log("update intercom called");
  const { data } = action;
  const {
    team_lead,
    team_member,
    company_name,
    no_of_company_programs,
    name,
    email,
    createdAt,
  } = data.userStats;
  window.Intercom("shutdown");
  setTimeout(() => {
    console.log("Itercom booted");
    window.Intercom("boot", {
      app_id: constants.INTERCOM_APP_ID,
      name,
      email,
      created_at: Math.floor(new Date(createdAt).getTime() / 1000), // Signup date as a Unix timestamp
      user_hash: getIdentityForIntercom(email),
      team_lead,
      team_member,
      company_name,
      no_of_company_programs,
    });
  }, 5000);
};
const isFunction = (functionToCheck) =>
  functionToCheck && {}.toString.call(functionToCheck) === "[object Function]";

export function* sagaFn(action) {
  let continueWithSaga = true;
  if (continueWithSaga && action.config) {
    yield put({
      type: getRequestType(action.type),
      isFetching: true,
      isFetched: false,
      isError: false,
    });
    yield put({
      type: "ISFETCHING",
      key: action.type,
      isFetching: true,
      isFetched: false,
      isError: false,
    });

    if (action.config.data && !action.config.data instanceof FormData) {
      const { meta, ...rest } = action.config.data;
      action.config.data = rest;
    }

    const { response, error } = yield call(makeApiRequest, action.config);
    let successCalled = false;

    if (response.status === 200 || response.status === 201) {
      if (action.type === actionTypes.SET_PASSWORD) {
        if (response.data.token) {
          /* Remove temporary token from localstorage */
          removeKeyFromLocal(constants.TERMST_LOCAL_STORAGE.TEMP_TOKEN_KEY);
          /* Store authentication in localstorage */
          let jsonObj = {};
          jsonObj[constants.TERMST_LOCAL_STORAGE.IS_AUTH_KEY] = true;
          jsonObj[constants.TERMST_LOCAL_STORAGE.JWT_KEY] = response.data.token;
          storeJsonInLocal(jsonObj);

          window.location = `${window.location.origin}/dashboard`;
        }
      }

      if (
        action.type === actionTypes.LOGIN_USER ||
        action.type === actionTypes.SOCIAL_LOGIN_USER
      ) {
        if (response.data.token) {
          console.log(
            "can update intercome",
            response.data.userStats?.canUpdateIntercome
          );
          if (Boolean(response.data.userStats?.canUpdateIntercome)) {
            console.log("if block enteered");
            updateUserInIntercom(response);
          }
          /* Remove temporary token from localstorage */
          removeKeyFromLocal(constants.TERMST_LOCAL_STORAGE.TEMP_TOKEN_KEY);
          /* Store authentication in localstorage */
          let jsonObj = {};
          jsonObj[constants.TERMST_LOCAL_STORAGE.IS_AUTH_KEY] = true;
          jsonObj[constants.TERMST_LOCAL_STORAGE.JWT_KEY] = response.data.token;
          storeJsonInLocal(jsonObj);
        }
      }
      if (action.type === actionTypes.LOGIN_AS_USER) {
        // Keep the previous token in another key PREV_JWT_KEY
        // And replace JWT_KEY key with latest token (swithced user token)
        const prevUserToken = getKeyFromLocal(
          constants.TERMST_LOCAL_STORAGE.JWT_KEY
        );
        let jsonObj = {};
        jsonObj[constants.TERMST_LOCAL_STORAGE.PREV_JWT_KEY] = prevUserToken;
        jsonObj[constants.TERMST_LOCAL_STORAGE.JWT_KEY] = response.data.token;
        storeJsonInLocal(jsonObj);
      }

      if (action.type === actionTypes.UPDATE_PASSWORD) {
        window.location = `${window.location.origin}/profile/edit`;
      }

      if (
        action.type === actionTypes.PASS_DEAL ||
        action.type === actionTypes.LOGIN_AS_USER
      ) {
        window.location.replace("/dashboard");
      }

      if (
        action.type === actionTypes.UPDATE_DEAL ||
        action.type === actionTypes.ADD_PROPERTY
      ) {
        if (!action.config.data.fromDashboard) {
          if (!action.config.data.redirect) {
            window.location.pathname = getDealCreationPath();
          } else {
            let jsonObj = {};

            jsonObj[constants.TERMST_LOCAL_STORAGE.DEAL_ID] = false;
            jsonObj[constants.TERMST_LOCAL_STORAGE.PORTIFOLIO_DEAL] = false;
            jsonObj[constants.TERMST_LOCAL_STORAGE.DEAL_TEAM_ID] = false;
            storeJsonInLocal(jsonObj);

            window.location.pathname = "/dashboard";
          }
        }
      }

      if (
        action.type === actionTypes.ADD_SOURCES_TO_DEAL ||
        action.type === actionTypes.ADD_USES_TO_DEAL ||
        action.type === actionTypes.ADD_REFINANCE_USES_TO_DEAL ||
        action.type === actionTypes.ADD_REFINANCE_SOURCES_TO_DEAL ||
        action.type === actionTypes.REMOVE_SOURCES_FROM_DEAL ||
        action.type === actionTypes.REMOVE_USES_FROM_DEAL ||
        action.type === actionTypes.REMOVE_REFINANCE_SOURCES_FROM_DEAL ||
        action.type === actionTypes.REMOVE_REFINANCE_USES_FROM_DEAL
      ) {
        const dealId = getKeyFromLocal(constants.TERMST_LOCAL_STORAGE.DEAL_ID);

        getDealById(dealId);
      }

      if (action.type === actionTypes.CREATE_DEAL) {
        let jsonObj = {};
        let id = response.data._id;
        const isPortifolio = response.data.isPortifolio;

        jsonObj[constants.TERMST_LOCAL_STORAGE.DEAL_ID] = id;
        jsonObj[constants.TERMST_LOCAL_STORAGE.PORTIFOLIO_DEAL] = isPortifolio
          ? id
          : false;
        storeJsonInLocal(jsonObj);

        createDealFolders(id, {
          isPortifolio: isPortifolio,
          propertyType:
            action.config.data.propertyType &&
            action.config.data.propertyType === "None"
              ? "Other"
              : action.config.data.propertyType,
        });
        updateDeal(id, action.config.data);
      }

      if (action.type === actionTypes.CREATE_TEAM) {
        const companyId = response.data.companyId;

        if (
          getKeyFromLocal(constants.TERMST_LOCAL_STORAGE.NEEDS_COMPANY) &&
          !action.config.data.doNotRedirect
        ) {
          let jsonObj = {};

          jsonObj[constants.TERMST_LOCAL_STORAGE.NEEDS_COMPANY] = false;
          storeJsonInLocal(jsonObj);
          window.location = getDealCreationPath();
        } else if (!action.config.data.doNotRedirect) {
          window.location = `${window.location.origin}/company/profile/${companyId}`;
        }
      }

      if (action.type === actionTypes.ASSIGN_TEAM_TO_DEAL) {
        if (action.config.data.fromPlatform) {
          toast.success("Team added to deal!", {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }

      if (action.type === actionTypes.CREATE_PROGRAM) {
        const companyId = response.data.companyId;
        window.location = `${window.location.origin}/company/profile/${companyId}`;
      }

      if (action.type === actionTypes.DELETE_PROGRAM) {
        const companyId = response.data.body.companyId;
        window.location = `${window.location.origin}/company/profile/${companyId}`;
      }

      if (
        action.type === actionTypes.CREATE_SPONSOR ||
        action.type === actionTypes.REMOVE_SPONSOR ||
        action.type === actionTypes.CREATE_MANAGER ||
        action.type === actionTypes.REMOVE_MANAGER ||
        action.type === actionTypes.UPDATE_MANAGER ||
        action.type === actionTypes.UPDATE_SPONSOR
      ) {
        const companyId = response.data.body._id;
        getCompanyById(companyId);
      }

      if (
        action.type === actionTypes.CREATE_SPONSOR ||
        action.type === actionTypes.CREATE_MANAGER ||
        action.type === actionTypes.UPDATE_MANAGER ||
        action.type === actionTypes.UPDATE_SPONSOR
      ) {
        getMyCompany();
      }

      if (action.type === actionTypes.ADD_TO_COMPANY) {
        toast.success("User added to your company.", {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }

      if (action.type === actionTypes.CREATE_SECTION) {
        toast.success("Section added to your company.", {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }

      if (action.type === actionTypes.CREATE_CONVERSATION_MESSAGE) {
        toast.success("Message created successfully.", {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }

      if (action.type === actionTypes.CREATE_CONVERSATION_QUESTION) {
        toast.success("Question sent successfully.", {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }

      if (action.type === actionTypes.UPDATE_CONVERSATION) {
        toast.success("Conversation updated successfully.", {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }

      if (action.type === actionTypes.INVITE_TO_COMPANY) {
        if (action.config.data.teamId) {
          updateTeam(action.config.data.teamId, {
            add: response.data.userId,
            globalRole: "Team Member",
          });

          toast.success(
            "User invited! They will receive an email to join your company and team.",
            {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        } else if (action.config.data.makeTeamLead) {
          createTeam({
            companyId: action.config.data.companyId,
            teamName: action.config.data.teamName,
            teamMembers: [
              {
                userId: response.data.userId,
                globalRole: "Team Leader",
              },
            ],
            doNotRedirect: true,
          });

          toast.success(
            "User invited! They will receive an email to join your company and will be assigned as a team leader.",
            {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        } else {
          toast.success(
            "User invited! They will receive an email to join your company.",
            {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        }
      }

      if (action.type === actionTypes.UPDATE_PROFILE) {
        if (action.config.doNotRedirect) {
          toast.success("Profile updated successfully.", {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          window.location = `${window.location.origin}/profile/view`;
        }
      }

      if (action.type === actionTypes.UPDATE_USER) {
        window.location = `${window.location.origin}/admin/users/platform`;
      }

      if (action.type === actionTypes.RESET_PASSWORD) {
        /* Remove termst local storage */
        destroyLocal();
        window.location = `${window.location.origin}/login`;
      }

      if (
        action.type === actionTypes.SIGN_OUT ||
        action.type === actionTypes.DEACTIVATE_ACCOUNT
      ) {
        /* Remove termst local storage */
        destroyLocal();
        window.location = `${window.location.origin}/login`;
      }

      if (
        action.type === actionTypes.GET_MY_PROFILE &&
        response.data.body &&
        response.data.body.role
      ) {
        let jsonObj = {};
        let newRole = response.data.body.role;

        jsonObj[constants.TERMST_LOCAL_STORAGE.ROLE] = newRole;
        storeJsonInLocal(jsonObj);

        // Chameleon
        if (window?.chmln) {
          const { body } = response.data;
          const USER = {
            ID_IN_DB: body._id,
            EMAIL: body.email,
            NAME: `${body.firstName} ${body.lastName}`,
            ROLE: body.role,
            SIGN_UP_DATE: body.createdAt,
          };

          window.chmln.identify(USER.ID_IN_DB, {
            // REQUIRED Unique ID of each user in your database (e.g. 23443 or "590b80e5f433ea81b96c9bf6")
            email: USER.EMAIL, // RECOMMENDED Used to connect data coming from various integrations

            // SUGGESTED - user properties:
            created: USER.SIGN_UP_DATE, // Send dates in ISO or unix timestamp format (e.g. "2017-07-01T03:21:10Z" or 1431432000)
            name: USER.NAME, // We will parse this to extra first and surnames (e.g. "James Doe")
            role: USER.ROLE, // Send properties useful for targeting types of users (e.g. "Admin")
          });
        }
      }

      if (action.type === actionTypes.CREATE_COMPANY) {
        if (!JSON.parse(action.config.data.get("isSuperAdmin"))) {
          createTeam({
            companyId: response.data._id,
            teamName: action.config.data.get("teamName"),
            teamMembers: [
              {
                userId: action.config.data.get("userId"),
                globalRole: "Team Leader",
              },
            ],
          });
        } else if (action.config.data.get("pageOrigin") !== "prompt") {
          if (action.config.data.get("companyType") === "Lender") {
            window.location = `${window.location.origin}/admin/lenders/platform`;
          } else if (action.config.data.get("companyType") === "Borrower") {
            window.location = `${window.location.origin}/admin/borrowers/platform`;
          }
        }
      }

      if (action.type === actionTypes.CREATE_COMM) {
        let formData = new FormData();

        formData.append("messageFromName", action.config.data.sender);
        formData.append(
          "message",
          JSON.stringify({
            message: action.config.data.initialMessage,
            isFollowUpQuestion: action.config.data.isFollowUpQuestion,
            linkedQuestionId: action.config.data.linkedQuestionId,
            linkedQuestionTitle: action.config.data.linkedQuestionTitle,
            commStartedBy: action.config.data.senderId,
          })
        );

        sendMessage(response.data._id, formData);

        if (action.config.data.uploads.length > 0) {
          action.config.data.uploads.map((file) => {
            formData = new FormData();

            formData.append("messageFromName", action.config.data.sender);
            formData.append("file", file.file ? file.file : "");
            formData.append("fileLink", file.fileLink);
            formData.append("fileKey", file.fileKey);
            formData.append("originalFilename", file.name);
            formData.append("dealId", response.data.dealId);

            sendMessage(response.data._id, formData);

            return true;
          });
        }

        answerComm(response.data._id, {
          isAnswered: false,
        });
      }

      if (action.type === actionTypes.CREATE_GROUP) {
        getGroups(response.data.dealId);
      }

      if (action.type === actionTypes.EDIT_GROUP) {
        getGroups(response.data[0].dealId);
      }

      if (action.type === actionTypes.EDIT_MESSAGE) {
        getCommById(
          response.data.body._id,
          response.data.body.isArchivedBy.some(
            (i) => i === action.config.data.whoami._id
          )
        );
      }

      if (action.type === actionTypes.UNMERGE_MESSAGE) {
        getCommById(
          response.data.body._id,
          response.data.body.isArchivedBy.some(
            (i) => i === action.config.data.whoami._id
          )
        );
      }

      if (action.type === actionTypes.UPDATE_COMPANY) {
        window.location = `${window.location.origin}/company/profile/${response.data.body._id}`;
      }

      if (action.type === actionTypes.CREATE_TERMSHEET) {
        const fromScratchQuery = action.config.data.fromScratch
          ? "?fromScratch=true"
          : "";
        if (!action.config.data.doNotRedirect) {
          const newLocation =
            action.config.data.dealId && !action.config.data.isTemplate
              ? `${window.location.origin}/deal/${action.config.data.dealId}/termsheet/${response.data._id}/quotes${fromScratchQuery}`
              : `${window.location.origin}/termsheet-clause/termsheet/${response.data._id}`;
          window.location.replace(newLocation);
        }
      }
      if (action.type === actionTypes.UPDATE_TERMSHEET) {
        if (action.config.data.doNotRedirect) {
          storeSections(response.data.body.columns);
          storeQuoteMatrices(response.data.body.addedClauses);
        } else if (
          !window.location.pathname.includes("/termsheet-clause/termsheet")
        ) {
          window.location = `${window.location.origin}/deal/${response.data.body.dealId}/termsheet/${response.data.body._id}`;
        }
      }

      if (action.config.actionId) {
        yield put({
          type: getSuccessType(action.type),
          data: response.data,
          isFetching: false,
          isFetched: true,
          isError: false,
          actionId: action.config.actionId,
        });
      } else {
        yield put({
          type: getSuccessType(action.type),
          data: response.data,
          isFetching: false,
          isFetched: true,
          isError: false,
          params: action.params,
        });
      }
      yield put({
        type: "ISFETCHING",
        key: action.type,
        isFetching: false,
        isFetched: true,
        isError: false,
      });
      if (
        action.onSuccessCallback &&
        action.onSuccessCallback.fn &&
        isFunction(action.onSuccessCallback.fn)
      ) {
        if (action.onSuccessCallback.response) {
          action.onSuccessCallback.fn(response.data);
        } else if (
          action.onSuccessCallback.params &&
          action.onSuccessCallback.params.length > 0
        ) {
          action.onSuccessCallback.fn(...action.onSuccessCallback.params); // execute action call back
        } else {
          action.onSuccessCallback.fn(); // execute action call back
        }
      }

      successCalled = true;
    } else if (error && error.response) {
      let errorMessage = "";

      if (
        error.response.status === 403 ||
        error.response.status === 413 ||
        error.response.status === 400 ||
        error.response.status === 500
      ) {
        if (error.response.status === 413) {
          errorMessage = "Request entity too large";
        } else if (error.response.data.error) {
          errorMessage = JSON.stringify(error.response.data.error);
        } else if (error.response.data.message.message) {
          // TOKEN EXPIRED
          if (
            error.response.data.message.message.includes("Token") ||
            error.response.data.message.message.includes("deactivated")
          ) {
            /* Remove termst local storage */
            destroyLocal();
            window.location = `${window.location.origin}/login`;
          }
          errorMessage = JSON.stringify(error.response.data.message.message);
        } else if (error.response.data.message) {
          if (error.response.data.message?.code?.includes("INVALID_TOKEN")) {
            /* Remove termst local storage */
            destroyLocal();
            window.location = `${window.location.origin}/login`;
          }
          errorMessage = JSON.stringify(error.response.data.message);
        } else if (error.response.data) {
          errorMessage = JSON.stringify(error.response.data);
        }

        toast.error(errorMessage, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
    if (!successCalled) {
      yield put({
        type: "ISFETCHING",
        key: action.type,
        isFetching: false,
        isFetched: false,
        isError: true,
      });
    }
  }
}
