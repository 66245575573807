import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { Container, Row, Col, Button, Alert } from "react-bootstrap";
import { getDealById, updateDeal, savePictures } from "redux/actions";
import { getKeyFromLocal, storeJsonInLocal } from "utils/lsUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons";
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import constants from "config/constants";
import Spacer from "components/layout/Spacer";
import Loader from "components/ui/Loader";

class DealLoanApplication extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      seconds: 30,
    };

    this.inputFinancialRef = React.createRef();
    this.inputPictureRef = React.createRef();
    this.saveFiles = this.saveFiles.bind(this);
    this.finishDeal = this.finishDeal.bind(this);
    this.lsRedirect = this.lsRedirect.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.myCompany !== this.props.myCompany) {
      const { myCompany } = this.props;

      if (myCompany && myCompany.message) {
        const timer = setInterval(() => {
          if (this.state.seconds === 0) {
            clearInterval(timer);

            let jsonObj = {};

            jsonObj[constants.TERMST_LOCAL_STORAGE.NEEDS_COMPANY] = true;
            storeJsonInLocal(jsonObj);

            window.location.pathname = "/company/create";
          } else {
            this.setState({ seconds: this.state.seconds - 1 });
          }
        }, 1000);
      }
    }
  }

  componentDidMount() {
    if (
      getKeyFromLocal(constants.TERMST_LOCAL_STORAGE.DEAL_ID) !== false &&
      !this.props.isNewPropertyDeal
    ) {
      const dealId = this.props.dealId
        ? this.props.dealId
        : getKeyFromLocal(constants.TERMST_LOCAL_STORAGE.DEAL_ID);
      this.setState({ dealId });
      getDealById(dealId);
    }
  }

  finishDeal() {
    const dealId = getKeyFromLocal(constants.TERMST_LOCAL_STORAGE.DEAL_ID);

    updateDeal(dealId, {
      dealStatus: "Under review",
      redirect: true,
    });
  }

  saveFiles(event, type) {
    let data = new FormData();
    for (let i = 0; i < event.target.files.length; i++) {
      data.append("files", event.target.files[i]);
    }
    data.append("dealId", this.state.dealId);
    savePictures(data);
  }

  lsRedirect() {
    let jsonObj = {};

    jsonObj[constants.TERMST_LOCAL_STORAGE.NEEDS_COMPANY] = true;
    storeJsonInLocal(jsonObj);

    window.location.pathname = "/company/create";
  }

  render() {
    const { whoami, deal, myCompany, isFetching } = this.props;

    if (myCompany && myCompany.message) {
      let jsonObj = {};

      jsonObj[constants.TERMST_LOCAL_STORAGE.NEEDS_COMPANY] = true;
      storeJsonInLocal(jsonObj);

      return (
        <Container fluid>
          <Row>
            <Col xs="12">
              <Spacer size={20} />
              <h3 className="bold">Loan Application</h3>
              <Spacer size={15} />
              <Alert variant="warning">
                You do not have a company! You need to create a company first in
                order to create a deal. You will be redirected in{" "}
                {this.state.seconds} seconds, or you can click{" "}
                <Button
                  variant="fake-link"
                  className="fake-link"
                  onClick={this.lsRedirect}
                >
                  here
                </Button>
                .
              </Alert>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <p>
                Fill out as many sections as you can to increase the likelihood
                of closing a deal
              </p>
              <p className="bold">All details will be saved automatically.</p>
              <Spacer size={20} />
            </Col>
            <Col xs="12" sm="8">
              <Row>
                <Col xs="10">
                  <Button
                    variant="primary"
                    className="deal-checklist-item outline"
                    block
                  >
                    Property Details
                    <Spacer size={5} />
                    <p className="font-12 bold">Must be filled out first.</p>
                    <FontAwesomeIcon icon={faSquare} />
                  </Button>
                </Col>
                <Col xs="2">
                  <Spacer size={10} />
                  <p className="d-none d-sm-block">Required</p>
                  <p className="d-block d-sm-none">*</p>
                </Col>
              </Row>
              <Row>
                <Col xs="10">
                  <Button
                    variant="primary"
                    className="deal-checklist-item outline"
                    block
                  >
                    Property Description
                    <FontAwesomeIcon icon={faSquare} />
                  </Button>
                </Col>
                <Col xs="2">
                  <Spacer size={10} />
                  <p className="d-none d-sm-block">Required</p>
                  <p className="d-block d-sm-none">*</p>
                </Col>
              </Row>
              <Row>
                <Col xs="10">
                  <Button
                    variant="primary"
                    className="deal-checklist-item outline"
                    block
                  >
                    Loan Request
                    <FontAwesomeIcon icon={faSquare} />
                  </Button>
                </Col>
                <Col xs="2">
                  <Spacer size={10} />
                  <p className="d-none d-sm-block">Required</p>
                  <p className="d-block d-sm-none">*</p>
                </Col>
              </Row>
              <Row>
                <Col xs="9" sm="10">
                  <Button
                    variant="primary"
                    className="deal-checklist-item outline"
                    block
                  >
                    Maps
                    <FontAwesomeIcon icon={faSquare} />
                  </Button>
                </Col>
                <Col xs="2"></Col>
              </Row>
              <Row>
                <Col xs="10">
                  <Button
                    variant="primary"
                    className="deal-checklist-item outline"
                    block
                  >
                    Sources and Uses
                    <FontAwesomeIcon icon={faSquare} />
                  </Button>
                </Col>
                <Col xs="2"></Col>
              </Row>
              <Row>
                <Col xs="10">
                  <Button
                    variant="primary"
                    className="deal-checklist-item outline"
                    block
                  >
                    Key Principals
                    <FontAwesomeIcon icon={faSquare} />
                  </Button>
                </Col>
                <Col xs="2"></Col>
              </Row>
              <Row>
                <Col xs="10">
                  <Button
                    variant="primary"
                    className="deal-checklist-item outline"
                    block
                  >
                    Manager
                    <FontAwesomeIcon icon={faSquare} />
                  </Button>
                </Col>
                <Col xs="2"></Col>
              </Row>
              <Row>
                <Col xs="10">
                  <Button
                    variant="primary"
                    className="deal-checklist-item outline"
                    block
                  >
                    Disclosures
                    <FontAwesomeIcon icon={faSquare} />
                  </Button>
                </Col>
                <Col xs="2"></Col>
              </Row>
              <Row>
                <Col xs="10">
                  <Button
                    variant="primary"
                    className="deal-checklist-item outline"
                    block
                  >
                    Photo Uploads
                    <FontAwesomeIcon icon={faSquare} />
                  </Button>
                </Col>
                <Col xs="2">
                  <Spacer size={10} />
                  <p className="d-none d-sm-block">Required</p>
                  <p className="d-block d-sm-none">*</p>
                </Col>
              </Row>
              <Row>
                <Col xs="10">
                  <Button
                    variant="primary"
                    className="deal-checklist-item outline"
                    block
                  >
                    Financial Uploads
                    <FontAwesomeIcon icon={faSquare} />
                  </Button>
                </Col>
                <Col xs="2">
                  <Spacer size={10} />
                  <p className="d-none d-sm-block">Required</p>
                  <p className="d-block d-sm-none">*</p>
                </Col>
              </Row>
              <Row>
                <Col xs="10">
                  <Button
                    variant="primary"
                    className="deal-checklist-item outline"
                    block
                  >
                    More Uploads
                  </Button>
                </Col>
                <Col xs="2"></Col>
              </Row>
            </Col>
          </Row>
        </Container>
      );
    } else if (
      whoami.role !== "Admin" &&
      (whoami.role === "Lender" ||
        (Object.keys(deal).length > 0 && deal.dealStatus !== "In progress"))
    ) {
      return <Redirect to="/dashboard" />;
    }
    return (
      <>
        {isFetching && <Loader />}
        <Container fluid>
          <Row>
            <Col xs="12" sm="8">
              <Row>
                <Col xs="10">
                  <Button
                    as={Link}
                    to={{
                      pathname: "/deal/application/property-details",
                      state: this.props.location?.state || {},
                    }}
                    variant="primary"
                    className={
                      "deal-checklist-item " +
                      (deal && deal.propertyDetailsComplete
                        ? "field-complete"
                        : "outline")
                    }
                    block
                  >
                    Property Details
                    {deal && !deal.propertyDetailsComplete && (
                      <>
                        <Spacer size={5} />
                        <p className="font-12 bold">
                          Must be filled out first.
                        </p>
                      </>
                    )}
                    {deal && deal.propertyDetailsComplete ? (
                      <FontAwesomeIcon icon={faCheckSquare} />
                    ) : (
                      <FontAwesomeIcon icon={faSquare} />
                    )}
                  </Button>
                </Col>
                <Col xs="2">
                  <Spacer size={10} />
                  {deal && !deal.propertyDetailsComplete && (
                    <>
                      <p className="d-none d-sm-block">Required</p>
                      <p className="d-block d-sm-none">*</p>
                    </>
                  )}
                </Col>
              </Row>
              <Row>
                <Col xs="10">
                  <Button
                    as={Link}
                    to={
                      deal && deal.propertyDetailsComplete
                        ? "/deal/application/property-description"
                        : "#"
                    }
                    variant="primary"
                    className={
                      "deal-checklist-item " +
                      (deal && deal.propertyDescriptionSet
                        ? "field-complete"
                        : "outline")
                    }
                    block
                  >
                    Property Description
                    {deal && deal.propertyDescriptionSet ? (
                      <FontAwesomeIcon icon={faCheckSquare} />
                    ) : (
                      <FontAwesomeIcon icon={faSquare} />
                    )}
                  </Button>
                </Col>
                <Col xs="2">
                  <Spacer size={10} />
                  {deal && !deal.propertyDescriptionSet && (
                    <>
                      <p className="d-none d-sm-block">Required</p>
                      <p className="d-block d-sm-none">*</p>
                    </>
                  )}
                </Col>
              </Row>
              <Row>
                <Col xs="10">
                  <Button
                    as={Link}
                    to={
                      deal && deal.propertyDetailsComplete
                        ? "/deal/application/loan-request"
                        : "#"
                    }
                    variant="primary"
                    className={
                      "deal-checklist-item " +
                      (deal && deal.loanRequest ? "field-complete" : "outline")
                    }
                    block
                  >
                    Loan Request
                    {deal && deal.loanRequest ? (
                      <FontAwesomeIcon icon={faCheckSquare} />
                    ) : (
                      <FontAwesomeIcon icon={faSquare} />
                    )}
                  </Button>
                </Col>
                <Col xs="2">
                  <Spacer size={10} />
                  {deal && !deal.loanRequest && (
                    <>
                      <p className="d-none d-sm-block">Required</p>
                      <p className="d-block d-sm-none">*</p>
                    </>
                  )}
                </Col>
              </Row>
              <Row>
                <Col xs="10">
                  <Button
                    as={Link}
                    to={
                      deal && deal.propertyDetailsComplete
                        ? "/deal/application/maps"
                        : "#"
                    }
                    variant="primary"
                    className={
                      "deal-checklist-item " +
                      (deal && deal.maps ? "field-complete" : "outline")
                    }
                    block
                  >
                    Maps
                    {deal && deal.maps ? (
                      <FontAwesomeIcon icon={faCheckSquare} />
                    ) : (
                      <FontAwesomeIcon icon={faSquare} />
                    )}
                  </Button>
                </Col>
                <Col xs="2"></Col>
              </Row>
              <Row>
                {deal && deal.loanRequest && (
                  <>
                    <Col xs="10">
                      <Button
                        as={Link}
                        to={
                          deal &&
                          deal.propertyDetailsComplete &&
                          deal.loanRequest
                            ? "/deal/application/sources-uses"
                            : "#"
                        }
                        variant="primary"
                        className={
                          "deal-checklist-item " +
                          (deal && deal.isSourcesSame
                            ? "field-disabled "
                            : "") +
                          (deal && deal.sourcesAndUses && !deal.isSourcesSame
                            ? "field-complete"
                            : "outline")
                        }
                        block
                        disabled={deal && deal.isSourcesSame}
                      >
                        <div className="property-manager">
                          <span>Sources and Uses</span>
                          {deal && deal.isSourcesSame && (
                            <span>Filled at Portfolio Level</span>
                          )}
                        </div>
                        {deal && deal.sourcesAndUses ? (
                          <FontAwesomeIcon icon={faCheckSquare} />
                        ) : (
                          <FontAwesomeIcon icon={faSquare} />
                        )}
                      </Button>
                    </Col>
                    <Col xs="2"></Col>
                  </>
                )}
              </Row>
              {!deal.portifolio && (
                <Row>
                  <Col xs="10">
                    <Button
                      as={Link}
                      to={
                        deal && deal.propertyDetailsComplete
                          ? "/deal/application/sponsor-guarantor"
                          : "#"
                      }
                      variant="primary"
                      className={
                        "deal-checklist-item " +
                        (deal &&
                        deal.sponsorGuarantor &&
                        !deal.isKeyPrincipalSame
                          ? "field-complete"
                          : "outline")
                      }
                      block
                      disabled={deal && deal.isKeyPrincipalSame}
                    >
                      <div className="property-manager">
                        <span>Key Principals</span>
                        {deal && deal.isKeyPrincipalSame && (
                          <span>Filled at Portfolio Level</span>
                        )}
                      </div>
                      {deal && deal.sponsorGuarantor ? (
                        <FontAwesomeIcon icon={faCheckSquare} />
                      ) : (
                        <FontAwesomeIcon icon={faSquare} />
                      )}
                    </Button>
                  </Col>
                  <Col xs="2"></Col>
                </Row>
              )}

              <Row>
                <Col xs="10">
                  <Button
                    as={Link}
                    to={
                      deal && deal.propertyDetailsComplete
                        ? "/deal/application/manager"
                        : "#"
                    }
                    variant="primary"
                    className={
                      "deal-checklist-item " +
                      (deal && deal.isManagerSame ? "field-disabled " : "") +
                      (deal && deal.manager && !deal.isManagerSame
                        ? "field-complete "
                        : "outline ")
                    }
                    block
                    disabled={deal && deal.isManagerSame}
                  >
                    <div className="property-manager">
                      <span>Manager</span>
                      {deal && deal.isManagerSame && (
                        <span>Filled at Portfolio Level</span>
                      )}
                    </div>
                    {deal && deal.manager ? (
                      <FontAwesomeIcon icon={faCheckSquare} />
                    ) : (
                      <FontAwesomeIcon icon={faSquare} />
                    )}
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col xs="10">
                  <Button
                    as={Link}
                    to={
                      deal && deal.propertyDetailsComplete
                        ? "/deal/application/critical-extras"
                        : "#"
                    }
                    variant="primary"
                    className={
                      "deal-checklist-item " +
                      (deal && deal.criticalExtras
                        ? "field-complete"
                        : "outline")
                    }
                    block
                  >
                    Disclosures
                    {deal && deal.criticalExtras ? (
                      <FontAwesomeIcon icon={faCheckSquare} />
                    ) : (
                      <FontAwesomeIcon icon={faSquare} />
                    )}
                  </Button>
                </Col>
                <Col xs="2"></Col>
              </Row>
              <Row>
                <Col xs="10">
                  <Button
                    as={Link}
                    to={
                      deal && deal.propertyDetailsComplete
                        ? "/deal/application/photos"
                        : "#"
                    }
                    variant="primary"
                    className={
                      "deal-checklist-item " +
                      (deal && deal.photoUploads ? "field-complete" : "outline")
                    }
                    block
                  >
                    Photo Uploads
                    {deal && deal.photoUploads ? (
                      <FontAwesomeIcon icon={faCheckSquare} />
                    ) : (
                      <FontAwesomeIcon icon={faSquare} />
                    )}
                  </Button>
                </Col>
                <Col xs="2">
                  <Spacer size={10} />
                  {deal && !deal.photoUploads && (
                    <>
                      <p className="d-none d-sm-block">Required</p>
                      <p className="d-block d-sm-none">*</p>
                    </>
                  )}
                </Col>
              </Row>
              <Row>
                <Col xs="10">
                  <Button
                    as={Link}
                    to={
                      deal && deal.propertyDetailsComplete
                        ? "/deal/application/financials"
                        : "#"
                    }
                    variant="primary"
                    className={
                      "deal-checklist-item " +
                      (deal && deal.financialUploads
                        ? "field-complete"
                        : "outline")
                    }
                    block
                  >
                    Financial Uploads
                    {deal && deal.financialUploads ? (
                      <FontAwesomeIcon icon={faCheckSquare} />
                    ) : (
                      <FontAwesomeIcon icon={faSquare} />
                    )}
                  </Button>
                </Col>
                <Col xs="2">
                  <Spacer size={10} />
                  {deal && !deal.financialUploads && (
                    <>
                      <p className="d-none d-sm-block">Required</p>
                      <p className="d-block d-sm-none">*</p>
                    </>
                  )}
                </Col>
              </Row>
              <Row>
                <Col xs="10">
                  <Button
                    as={Link}
                    to={
                      this.state && this.state.dealId
                        ? {
                            pathname: "/deal/files/" + this.state.dealId,
                            state: { fromDeal: true },
                          }
                        : "#"
                    }
                    variant="primary"
                    className="deal-checklist-item outline"
                    block
                  >
                    More Uploads
                  </Button>
                </Col>
                <Col xs="2"></Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col className="text-right" xs="12">
              <Spacer size={20} />
              {deal.propertyDetailsComplete && (
                <Button
                  className="outline pl-2 pr-2"
                  as={Link}
                  to="/dashboard"
                  variant="primary"
                >
                  Save Draft
                </Button>
              )}
              <>
                {deal &&
                deal.propertyDetailsComplete &&
                deal.propertyDescriptionSet &&
                deal.financialUploads &&
                deal.photoUploads &&
                deal.loanRequest ? (
                  <Button
                    className="ml-2 pl-3 pr-3"
                    onClick={this.finishDeal}
                    variant="primary"
                  >
                    Submit Deal
                  </Button>
                ) : (
                  <></>
                )}
              </>
              <Spacer size={20} />
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    isFetching:
      state.action.GET_DEAL_BY_ID.isFetching ||
      state.action.SAVE_FINANCIALS.isFetching ||
      state.action.SAVE_PICTURES.isFetching,
    whoami: state.auth.whoami,
    myCompany: state.company.myCompany,
    deal: state.deal.deal,
    team: state.team.team,
  };
}

export default connect(mapStateToProps)(DealLoanApplication);
