import { actionTypes } from "redux/actions";
import { reducerCreator, multipleReducers } from "./meta";

export const category = multipleReducers({
  initializers: {
    initialState: {
      categoriesTotalCount: 0,
      categories: [],
      manageCategories: {
        count: 0,
        limit: 10,
        rows: [],
      },
      lastCategoryCreated: null,
      lastCategoryUpdated: null,
      lastCategoryDeleted: null,
      lastCategoryMerged: null,
      lastCategoryReorderDate: null,
    },
  },
  list: [
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.GET_CATEGORIES,
        successFn: (action) => ({
          categories: action.data.body.rows,
          categoriesTotalCount: action.data.body.count,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.GET_MANAGE_CATEGORIES,
        successFn: (action) => ({
          manageCategories: action.data.body,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.CREATE_CATEGORY,
        successFn: (action) => ({
          lastCategoryCreated: action.data,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.UPDATE_CATEGORY,
        successFn: (action) => ({
          lastCategoryUpdated: action.data,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.DELETE_CATEGORY,
        successFn: (action) => ({
          lastCategoryDeleted: action.data,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.MERGE_CATEGORIES,
        successFn: (action) => ({
          lastCategoryMerged: action.data,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.REORDER_CATEGORIES,
        successFn: () => ({
          lastCategoryReorderDate: Date.now(),
        }),
      },
    },
  ],
});
