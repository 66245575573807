import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Carousel, Col, Row, Space } from "antd";
import { addMaps } from "components/google/maps";
import { useSearchObject } from "utils/hooks";
import AskQuestion from "containers/communicationsV2/modals/AskQuestion";
import useCreateConvoModalStore from "containers/communicationsV2/useCreateConvoModalStore";
import { inboxModaTypes } from "containers/communicationsV2/enums";
import { createConversationQuestion } from "redux/actions";
import { useSelector } from "react-redux";
import DealManager from "./DealManager";
import ActiveLoanRequest from "./ActiveLoanRequest";
import BorrowerDealTeam from "./BorrowerDealTeam";

const View = {
  Photos: "photos",
  Aerial: "aerial",
  Map: "map",
};
const MapId = "view-deal-map-id";

const ViewListItem = ({ label, onClick }) => (
  <div className="tw-col-span-1">
    {label && (
      <div
        role="button"
        aria-label={label}
        onClick={onClick}
        className="tw-cursor-pointer tw-w-[74px] tw-h-[74px] tw-border-solid tw-border-2 tw-rounded-sm tw-border-white tw-flex tw-items-end tw-shadow-sm"
      >
        <p className="tw-text-xs tw-text-center tw-bg-white tw-w-full tw-py-1">
          {label}
        </p>
      </div>
    )}
  </div>
);

const ViewList = ({ deal, property, onChange }) => {
  const showMapAndAerial =
    !deal.isPortifolio || (deal.isPortifolio && property);
  return (
    <div className="tw-absolute tw-bottom-6 tw-right-6">
      <div className="tw-grid tw-grid-cols-2 tw-gap-4">
        {showMapAndAerial && (
          <ViewListItem label="Aerial" onClick={() => onChange(View.Aerial)} />
        )}
        <ViewListItem label="" />
        <ViewListItem label="Photos" onClick={() => onChange(View.Photos)} />
        {showMapAndAerial && (
          <ViewListItem label="Map View" onClick={() => onChange(View.Map)} />
        )}
      </div>
    </div>
  );
};

const DealDetails = ({ deal, property }) => {
  const history = useHistory();
  const whoami = useSelector((state) => state.auth.whoami);
  const myCompany = useSelector((state) => state.company.myCompany);
  const { openModal, setOpenModalQuestion, closeModal } =
    useCreateConvoModalStore();
  const { _id: dealId, company } = deal;
  const { _id } = property || deal;

  if (!_id) return null;

  const askQuestion = (values) => {
    const formData = new FormData();
    formData.append("dealId", dealId);
    formData.append("subject", "Question");
    formData.append("message", values.message);

    let receiverCompanyIds = [];
    switch (whoami.role) {
      case "Admin":
        receiverCompanyIds = [company._id];
        break;
      case "Lender":
        receiverCompanyIds = [company._id];
        break;
      case "Borrower":
        receiverCompanyIds = [];
        break;
      default:
        receiverCompanyIds = [];
        break;
    }

    receiverCompanyIds.forEach((companyId) => {
      formData.append("receiverCompanyIds", companyId);
    });

    if (values.files) {
      values.files.forEach((file) => {
        formData.append("files", file.originFileObj);
      });
    }
    if (values.labels) {
      values.labels.forEach((label) => {
        formData.append("labels", label);
      });
    }
    createConversationQuestion(formData);
  };

  const handleDownloadOM = () => {
    window.open(`/deal/pdf/${_id}?download=true`, "_blank");
  };

  const handleSubmitLOI = () => {
    history.push(`/deal/${_id}/quote-summary`);
  };

  const handleAskQuestionSubmit = (values) => {
    askQuestion(values);
    closeModal();
  };

  return (
    <Row className="p-2">
      <Col xs={24} className="pt-3">
        <div className="d-flex justify-content-between">
          <Space>
            <Button type="primary" onClick={handleDownloadOM}>
              Download OM
            </Button>
            {/* @TODO: Hide for now */}
            {/* <Button>Flyer</Button> */}
            <Button type="primary" onClick={handleSubmitLOI}>
              Submit LOI
            </Button>
          </Space>
          {(whoami.role === "Admin" || whoami.role === "Lender") && (
            <Space>
              <Button type="primary" onClick={setOpenModalQuestion}>
                Ask Question
              </Button>
            </Space>
          )}
          <AskQuestion
            type={openModal}
            open={openModal === inboxModaTypes.askQuestion}
            onCreate={handleAskQuestionSubmit}
            onCancel={closeModal}
          />
        </div>
      </Col>
      <Col xs={24}>
        <Row gutter={16}>
          <Col xs={24} md={12} className="pt-4">
            <DealManager deal={deal} />
          </Col>
          <Col xs={24} md={12} className="pt-4">
            <ActiveLoanRequest property={property} deal={deal} />
          </Col>
          {company._id !== myCompany._id && (
            <Col xs={24} md={12} className="pt-4">
              <BorrowerDealTeam deal={deal} />
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};

const DealPhotoCarousel = ({ deal, property }) => {
  const { photos } = property || deal;

  if (!photos?.length)
    return <div className="tw-w-full tw-h-[50vh] tw-bg-gray-200" />;

  return (
    <div className="tw-absolute tw-w-full">
      <Carousel dotPosition="top">
        {photos?.map((photo) => (
          <div key={photo._id}>
            <img
              className="tw-object-cover tw-h-[50vh] tw-w-full"
              alt={photo.name}
              src={photo.locationCdn}
            />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

const DealMapViewer = ({ view, deal, property }) => {
  const { propertyLat: lat, propertyLong: lng } = property || deal;

  useEffect(() => {
    if (lat && lng) {
      const { map, removeMarkers } = addMaps(
        MapId,
        16,
        { lat, lng },
        {
          zoomControl: true,
          streetViewControl: true,
          zoomControlOptions: {
            position: window.google.maps.ControlPosition.TOP_RIGHT,
          },
          streetViewControlOptions: {
            position: window.google.maps.ControlPosition.TOP_RIGHT,
          },
        }
      );
      if (view === View.Aerial && map) {
        map.setMapTypeId("satellite");
      }

      return () => {
        if (removeMarkers) removeMarkers();
      };
    }
  }, [view, deal, property]);

  return (
    <>
      <div id={MapId} className="tw-w-full tw-h-[50vh]" />
      <div
        className={`tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-[50vh] tw-bg-gray-200 ${
          lat ? "-tw-z-10" : "tw-z-0"
        }`}
      />
    </>
  );
};

const DealGallery = ({ view, deal, property }) => {
  if (view === View.Photos)
    return <DealPhotoCarousel {...{ deal, property }} />;
  return <DealMapViewer {...{ view, deal, property }} />;
};

const DealInfo = ({ deal }) => {
  const [view, setView] = useState(View.Photos);

  const searchObject = useSearchObject();
  const activeKey = searchObject?.tab || "1";
  const property = deal.properties?.[Number(activeKey) - 2];

  useEffect(() => {
    if (activeKey === "1") setView(View.Photos);
  }, [activeKey]);

  return (
    <Row className="tw-relative">
      <Col xs={24}>
        <div className="tw-relative tw-h-[50vh]">
          <DealGallery {...{ view, deal, property }} />
          <h1 className="tw-absolute tw-text-white tw-bottom-6 tw-left-4">
            {deal.propertyName}
          </h1>
          <ViewList {...{ deal, property }} onChange={setView} />
        </div>
      </Col>
      <Col xs={24}>
        <DealDetails {...{ deal, property }} />
      </Col>
    </Row>
  );
};

export default DealInfo;
