import React from "react";
import { first, last } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencilAlt,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Button, Layout, Space, theme } from "antd";
import AskQuestion from "./modals/AskQuestion";
import CreateMessage from "./modals/CreateMessage";
import {
  createConversationMessage,
  createConversationQuestion,
  updateConversation,
} from "redux/actions";
import { useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import useCreateConvoModalStore from "./useCreateConvoModalStore";
import { inboxModaTypes } from "./enums";
import EditAnswer from "./modals/EditAnswer";

const InboxActionButtons = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const match = useRouteMatch();
  const {
    followUpToConvoId,
    conversationDetails,
    openModal,
    setOpenModalMessage,
    setOpenModalQuestion,
    closeModal,
  } = useCreateConvoModalStore();
  const whoami = useSelector((state) => state.auth.whoami);
  const deal = useSelector((state) => state.deal.deal);

  const dealId = match.params.id;

  const createMessage = (values) => {
    const formData = new FormData();
    formData.append("dealId", dealId);
    formData.append("subject", values.subject);
    formData.append("message", values.message);
    if (values.receiverCompanyIds) {
      values.receiverCompanyIds.forEach((receiverCompanyId) => {
        formData.append("receiverCompanyIds", receiverCompanyId);
      });
    }
    if (values.files) {
      values.files.forEach((file) => {
        formData.append("files", file.originFileObj);
      });
    }
    if (values.labels) {
      values.labels.forEach((label) => {
        formData.append("labels", label);
      });
    }
    createConversationMessage(formData);
  };

  const askQuestion = (values) => {
    const formData = new FormData();
    formData.append("dealId", dealId);
    formData.append(
      "subject",
      followUpToConvoId ? "Follow-up Question" : "Question"
    );
    formData.append("message", values.message);

    let receiverCompanyIds = [];
    switch (whoami.role) {
      case "Admin":
        receiverCompanyIds = [deal.company._id];
        break;
      case "Lender":
        receiverCompanyIds = [deal.company._id];
        break;
      case "Borrower":
        receiverCompanyIds = [];
        break;
      default:
        receiverCompanyIds = [];
        break;
    }

    receiverCompanyIds.forEach((companyId) => {
      formData.append("receiverCompanyIds", companyId);
    });

    if (followUpToConvoId) {
      formData.append("followUpToConvoId", followUpToConvoId);
    }
    if (values.files) {
      values.files.forEach((file) => {
        formData.append("files", file.originFileObj);
      });
    }
    if (values.labels) {
      values.labels.forEach((label) => {
        formData.append("labels", label);
      });
    }
    createConversationQuestion(formData);
  };

  const editQuestion = (values) => {
    const data = {
      messageId: first(conversationDetails.messages)._id,
      message: values.message,
      labels: values.labels,
    };
    updateConversation(conversationDetails._id, data);
  };

  const editAnswer = (values) => {
    const data = {
      messageId: last(conversationDetails.messages)._id,
      message: values.message,
      isPrivate: values.isPrivate,
      receiverCompanyIds: values.receiverCompanyIds,
    };
    updateConversation(conversationDetails._id, data);
  };

  const editVisibility = (values) => {
    const data = {
      messageId: last(conversationDetails.messages)._id,
      isPrivate: values.isPrivate,
      receiverCompanyIds: values.receiverCompanyIds,
    };
    updateConversation(conversationDetails._id, data);
  };

  const handleSubmitModal = (type) => (values) => {
    switch (type) {
      case inboxModaTypes.createMessage:
        createMessage(values);
        break;
      case inboxModaTypes.askQuestion:
        askQuestion(values);
        break;
      case inboxModaTypes.editQuestion:
        editQuestion(values);
        break;
      case inboxModaTypes.editAnswer:
        editAnswer(values);
        break;
      case inboxModaTypes.editVisibility:
        editVisibility(values);
        break;
      default:
        break;
    }
    closeModal();
  };

  const showCreateMessage =
    deal.dealStatus === "Under review" ||
    deal.dealStatus === "Active" ||
    deal.dealStatus === "Closed";

  return (
    <Layout.Content
      style={{
        padding: 8,
        backgroundColor: colorBgContainer,
        display: "flex",
        justifyContent: "flex-end",
      }}
    >
      <Space wrap>
        {showCreateMessage && (
          <Button
            type="primary"
            icon={<FontAwesomeIcon className="mr-2" icon={faPencilAlt} />}
            onClick={setOpenModalMessage}
          >
            Create Message
          </Button>
        )}
        <Button
          type="primary"
          icon={<FontAwesomeIcon className="mr-2" icon={faQuestionCircle} />}
          onClick={setOpenModalQuestion}
        >
          Ask Question
        </Button>
      </Space>

      <CreateMessage
        open={openModal === inboxModaTypes.createMessage}
        onCreate={handleSubmitModal(openModal)}
        onCancel={closeModal}
      />
      <AskQuestion
        data={conversationDetails}
        type={openModal}
        open={
          openModal === inboxModaTypes.askQuestion ||
          openModal === inboxModaTypes.editQuestion
        }
        onCreate={handleSubmitModal(openModal)}
        onCancel={closeModal}
      />
      <EditAnswer
        data={conversationDetails}
        type={openModal}
        open={
          openModal === inboxModaTypes.editAnswer ||
          openModal === inboxModaTypes.editVisibility
        }
        onCreate={handleSubmitModal(openModal)}
        onCancel={closeModal}
      />
    </Layout.Content>
  );
};

export default InboxActionButtons;
