import React, { useEffect } from "react";
import { connect } from "react-redux";
import { clearDealDetails, getDealById } from "redux/actions";
import DealPdf from "./DealPdf";

function DealPdfContainer(props) {
  const { deal, match, location } = props;

  useEffect(() => {
    getDealById(match.params.id);

    return () => {
      clearDealDetails();
    };
  }, [match.params.id]);

  if (deal._id) {
    const download = location.search.includes("download=true");
    return <DealPdf {...props} download={download} />;
  }

  return null;
}

function mapStateToProps(state) {
  return {
    deal: state.deal.deal,
  };
}

export default connect(mapStateToProps)(DealPdfContainer);
