import React from "react";

// Agent Routes
import Dashboard from "containers/auth/Dashboard";
import Profile from "containers/profile/view/Profile";
import AccountManagement from "containers/profile/update/AccountManagement";
import Company from "containers/company/view/Company";
import CompanyCreate from "containers/company/CompanyCreate";
import CompanyUpdate from "containers/company/update/CompanyUpdate";
import Program from "containers/program/view/Program";
import ProgramCreateEdit from "containers/program/ProgramCreateEdit";
import Team from "containers/team/view/Team";
import TeamUpdate from "containers/team/update/TeamUpdate";
import TeamCreate from "containers/team/TeamCreate";
import FileExplorer from "containers/deals/FileManager/FileExplorer";
// Admin Componenets
import ManageLenders from "containers/admin/ManageLenders";
import ManageLendersDeal from "containers/admin/ManageLendersDeal";
import ManageBorrowers from "containers/admin/ManageBorrowers";
import ManageUsers from "containers/admin/ManageUsers";

import TermsheetChoice from "containers/termsheet/TermsheetChoice";
import ProvideTermsheetName from "containers/termsheet/ProvideTermsheetName";
import SelectSections from "containers/termsheet/SelectSections";
import EditTermsheet from "containers/termsheet/EditTermsheet";
import QuoteMatrix from "containers/quotes/QuoteMatrix";
import TermSheetsList from "containers/termsheet/TermSheetsList";
import TermsheetTemplates from "containers/termsheet/TermsheetTemplates";
import DealQuotes from "containers/quotes/DealQuotes";
import LenderStatistics from "containers/deals/LenderStatistics";
import ManageTags from "containers/admin/ManageTags";
import DealLoanApplication from "containers/deals/DealLoanApplication";
import Manager from "containers/deals/ApplicationSteps/Manager";
import Sponsor from "containers/deals/ApplicationSteps/Sponsor";
import CriticalExtras from "containers/deals/ApplicationSteps/CriticalExtras";
import SourcesAndUses from "containers/deals/ApplicationSteps/SourcesAndUses";
import LoanRequest from "containers/deals/ApplicationSteps/LoanRequest";
import PropertyDescription from "containers/deals/ApplicationSteps/PropertyDescription";
import PropertyDetails from "containers/deals/ApplicationSteps/PropertyDetails";
import PhotoUploads from "containers/deals/ApplicationSteps/photos/PhotoUploads";
import FinancialUploads from "containers/deals/ApplicationSteps/financials/FinancialUploads";
import DealMaps from "containers/deals/ApplicationSteps/DealMaps";
import DealChoice from "containers/deals/DealChoice";
import DealScheduler from "containers/deals/schedule/DealScheduler";
import ManageTermsheetAndClause from "containers/admin/ManageTermsheetAndClause";
import SpecialtySectionChoice from "containers/section/SpecialtySectionChoice";
import SpecialtySectionCreate from "containers/section/SpecialtySectionCreate";
import TermsheetCreateEdit from "containers/termsheet/TermsheetCreateEdit";
import QuoteSummary from "containers/termsheet/QuoteSummary";
import AccountPreferences from "containers/profile/update/AccountPreferences";
import EmailPreference from "containers/profile/update/EmailPreference";
import TermsheetPreview from "containers/termsheet/TermsheetPreview";
import CompareQuotes from "containers/termsheet/CompareQuotes";
import DealPdfContainer from "containers/deals/pdf/DealPdfContainer";
import Inbox from "containers/deals/Communications/Inbox";
import InboxLayout from "containers/communicationsV2/InboxLayout";
import EditUser from "containers/profile/update/EditUser";
import DealV2 from "containers/deals/view/DealV2";
import PortifolioDealApplication from "containers/deals/PortifolioDealApplication";
import CommsPreferences from "containers/profile/update/CommsPreferences";

const adminRoutes = [
  {
    path: "/dashboard",
    component: Dashboard,
    isExact: true,
    title: "Dashboard",
    searchParam: "deals",
  },
  {
    path: "/profile/view",
    component: Profile,
    isExact: true,
    title: "User Profile",
    searchParam: "none",
  },
  {
    path: "/profile/edit",
    component: AccountManagement,
    isExact: true,
    title: "Edit User Profile",
    searchParam: "none",
  },
  {
    path: "/profile/edit/:id",
    component: EditUser,
    isExact: true,
    title: "Edit User",
    searchParam: "none",
  },
  {
    path: "/profile/accountpreferences",
    component: AccountPreferences,
    isExact: true,
    title: "Account Management",
    searchParam: "none",
  },
  {
    path: "/profile/emailpreferences",
    component: EmailPreference,
    isExact: true,
    title: "Email Preferences",
    searchParam: "none",
  },
  {
    path: "/profile/commspreferences",
    component: CommsPreferences,
    isExact: true,
    title: "Comms Preferences",
    searchParam: "none",
  },
  {
    path: "/company/create",
    component: CompanyCreate,
    isExact: true,
    title: "Company Create",
    searchParam: "none",
  },
  {
    path: "/company/profile/:id",
    component: Company,
    isExact: true,
    title: "Company Profile",
    searchParam: "none",
  },
  {
    path: "/company/edit/:id",
    component: CompanyUpdate,
    isExact: true,
    title: "Company Update",
    searchParam: "none",
  },
  {
    path: "/program/create",
    component: ProgramCreateEdit,
    isExact: true,
    title: "Program Create",
    searchParam: "none",
  },
  {
    path: "/program/view/:id",
    component: Program,
    isExact: true,
    title: "Program View",
    searchParam: "none",
  },
  {
    path: "/program/edit/:id",
    component: (props) => <ProgramCreateEdit {...props} isUpdate />,
    isExact: true,
    title: "Program Update",
    searchParam: "none",
  },
  {
    path: "/team/view/:id",
    component: Team,
    isExact: true,
    title: "Team View",
    searchParam: "none",
  },
  {
    path: "/team/edit/:id",
    component: TeamUpdate,
    isExact: true,
    title: "Team Update",
    searchParam: "none",
  },
  {
    path: "/team/create",
    component: TeamCreate,
    isExact: true,
    title: "Team Create",
    searchParam: "none",
  },
  {
    path: "/deal/application",
    component: DealLoanApplication,
    isExact: true,
    title: "Create a Deal",
    searchParam: "none",
  },
  {
    path: "/deal/portifolio-application/:dealId",
    component: PortifolioDealApplication,
    isExact: true,
    title: "Create a Deal",
    searchParam: "none",
  },
  {
    path: "/deal/application/property-details",
    component: PropertyDetails,
    isExact: true,
    title: "Property Details",
    searchParam: "none",
  },
  {
    path: "/deal/application/property-description",
    component: PropertyDescription,
    isExact: true,
    title: "Property Description",
    searchParam: "none",
  },
  {
    path: "/deal/application/portifolio-summary",
    component: PropertyDescription,
    isExact: true,
    title: "Portfolio Summary",
    searchParam: "none",
  },
  {
    path: "/deal/application/loan-request",
    component: LoanRequest,
    isExact: true,
    title: "Loan Request",
    searchParam: "none",
  },
  {
    path: "/deal/application/sources-uses",
    component: SourcesAndUses,
    isExact: true,
    title: "Sources and Uses",
    searchParam: "none",
  },

  {
    path: "/deal/application/critical-extras",
    component: CriticalExtras,
    isExact: true,
    title: "Disclosures",
    searchParam: "none",
  },
  {
    path: "/deal/application/sponsor-guarantor",
    component: Sponsor,
    isExact: true,
    title: "Key Principals",
    searchParam: "none",
  },
  {
    path: "/deal/application/manager",
    component: Manager,
    isExact: true,
    title: "Manager",
    searchParam: "none",
  },
  {
    path: "/deal/application/financials",
    component: FinancialUploads,
    isExact: true,
    title: "Financials",
    searchParam: "files",
  },
  {
    path: "/deal/application/photos",
    component: PhotoUploads,
    isExact: true,
    title: "Photos",
    searchParam: "files",
  },
  {
    path: "/deal/application/maps",
    component: DealMaps,
    isExact: true,
    title: "Maps",
    searchParam: "none",
  },
  {
    path: "/deal/scheduler",
    component: DealScheduler,
    isExact: true,
    title: "Schedule a meeting",
    searchParam: "none",
  },
  {
    path: "/deal",
    component: DealChoice,
    isExact: true,
    title: "Deal",
    searchParam: "none",
  },
  {
    path: "/deal/view/:id",
    component: DealV2,
    isExact: true,
    title: "Deal",
    searchParam: "none",
  },
  {
    path: "/deal/pdf/:id",
    component: DealPdfContainer,
    isExact: true,
    title: "Deal PDF",
    searchParam: "none",
  },
  {
    path: "/deal/inbox/v1/:id",
    component: Inbox,
    isExact: true,
    title: "Inbox",
    searchParam: "inbox",
  },
  {
    path: "/deal/inbox/:id",
    component: InboxLayout,
    isExact: true,
    title: "Inbox",
    searchParam: "inbox",
  },
  {
    path: "/deal/files/:id",
    component: FileExplorer,
    isExact: true,
    title: "File Manager",
    searchParam: "files",
  },
  {
    path: "/deal/:dealId/termsheet",
    component: TermSheetsList,
    isExact: true,
    title: "Term Sheets",
    searchParam: "quotes",
  },
  {
    path: "/deal/:dealId/quote-summary",
    component: QuoteSummary,
    isExact: true,
    title: "Quote Summary",
  },
  {
    path: "/deal/:dealId/compare-quotes",
    component: CompareQuotes,
    isExact: true,
    title: "Compare Quotes",
  },
  {
    path: "/deal/:dealId/termsheet/templates",
    component: TermsheetTemplates,
    isExact: true,
    title: "Term Sheet Templates",
    searchParam: "none",
  },
  {
    path: "/deal/:dealId/termsheet/choice",
    component: TermsheetChoice,
    isExact: true,
    title: "How would you like to start your term sheet?",
    searchParam: "none",
  },
  {
    path: "/deal/:dealId/termsheet/create",
    component: ProvideTermsheetName,
    isExact: true,
    title: "Term Sheet Name",
    searchParam: "none",
  },
  {
    path: "/deal/:dealId/termsheet/:termsheetId/quotes",
    component: QuoteMatrix,
    isExact: true,
    title: "Term Sheet Quotes",
    searchParam: "quotes",
  },
  {
    path: "/deal/:dealId/termsheet/:termsheetId/sections",
    component: SelectSections,
    isExact: true,
    title: "Add Sections",
    searchParam: "none",
  },
  {
    path: "/deal/:dealId/termsheet/:termsheetId/edit",
    component: EditTermsheet,
    isExact: true,
    title: "Term Sheet",
    searchParam: "none",
  },
  {
    path: "/deal/:dealId/termsheet/:termsheetId/",
    component: QuoteMatrix,
    isExact: true,
    title: "View Term Sheet",
    searchParam: "quotes",
  },
  {
    path: "/termsheet/:termsheetId",
    component: TermsheetPreview,
    isExact: true,
    title: "Termsheet Preview",
  },
  {
    path: "/admin/lenders/platform",
    component: ManageLenders,
    isExact: true,
    title: "Manage Lenders",
    searchParam: "lendersPlatform",
  },
  {
    path: "/admin/lenders/deal",
    component: ManageLendersDeal,
    isExact: true,
    title: "Deal Lenders",
    searchParam: "lendersDeal",
  },
  {
    path: "/admin/borrowers/platform",
    component: ManageBorrowers,
    isExact: true,
    title: "Manage Borrowers",
    searchParam: "borrowersPlatform",
  },
  {
    path: "/admin/users/platform",
    component: ManageUsers,
    isExact: true,
    title: "Manage Users",
    searchParam: "usersPlatform",
  },
  {
    path: "/deal/:dealId/quotes",
    component: DealQuotes,
    isExact: true,
    title: "Deal Quotes",
  },
  {
    path: "/deal/:dealId/stats",
    component: LenderStatistics,
    isExact: true,
    title: "My Lenders",
  },
  {
    path: "/admin/tags",
    component: ManageTags,
    isExact: true,
    title: "Manage Tags",
  },
  {
    path: "/termsheet-clause/:activeTab",
    component: ManageTermsheetAndClause,
    isExact: true,
    title: "Manage Termsheet and Clause",
  },
  {
    path: "/termsheet-clause/specialty-sections/choice",
    component: SpecialtySectionChoice,
    isExact: true,
    title: "How would you like to start your Section?",
  },
  {
    path: "/termsheet-clause/specialty-sections/create",
    component: SpecialtySectionCreate,
    isExact: true,
    title: "Create Specialty Section",
  },
  {
    path: "/termsheet-clause/termsheet/choice",
    component: TermsheetChoice,
    isExact: true,
    title: "How would you like to start your term sheet?",
    searchParam: "none",
  },
  {
    path: "/termsheet-clause/termsheet/create",
    component: TermsheetCreateEdit,
    isExact: true,
    title: "Create Termsheet Template from Scratch",
    searchParam: "none",
  },
  {
    path: "/termsheet-clause/termsheet/:termsheetId",
    component: TermsheetCreateEdit,
    isExact: true,
    title: "Edit Termsheet Template",
    searchParam: "none",
  },
];

export { adminRoutes };
