import { dispatch } from "config/store";
import { actionTypes } from "./actionTypes";

export const createProgram = (data) => {
  const action = {
    type: actionTypes.CREATE_PROGRAM,
    config: {
      method: "post",
      url: `/programs/create`,
      data,
    },
  };

  dispatch(action);
};

export const updateProgram = (programId, data) => {
  const action = {
    type: actionTypes.UPDATE_PROGRAM,
    config: {
      method: "put",
      url: `/programs/update/${programId}`,
      data,
    },
  };

  dispatch(action);
};

export const deleteProgram = (programId) => {
  const action = {
    type: actionTypes.DELETE_PROGRAM,
    config: {
      method: "delete",
      url: `/programs/${programId}`,
    },
  };

  dispatch(action);
};

export const getDropdown = (value) => {
  const action = {
    type: actionTypes.GET_DROPDOWNS,
    config: {
      method: "get",
      url: `/dropdown/?type=${value}`,
      searchBy: value,
    },
  };

  dispatch(action);
};

export const getProgramsByCompanyId = (companyId, page) => {
  const number = page ? page : 1;

  const action = {
    type: actionTypes.GET_PROGRAMS_BY_COMPANY_ID,
    config: {
      method: "get",
      url: `/programs?companyId=${companyId}&limit=6&page=${number}&order=DESC`,
    },
  };

  dispatch(action);
};

export const getProgramsById = (programId) => {
  const action = {
    type: actionTypes.GET_PROGRAMS_BY_ID,
    config: {
      method: "get",
      url: `/programs/${programId}`,
    },
  };

  dispatch(action);
};

export const updatePartialProgram = (programId, data) => {
  const action = {
    type: actionTypes.UPDATE_PARTIAL_PROGRAM,
    config: {
      method: "put",
      url: `/programs/${programId}`,
      data,
    },
  };

  dispatch(action);
};
