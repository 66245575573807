import React, { useState } from "react";
import {
  Popover,
  OverlayTrigger,
  Button,
  Row,
  Col,
  Badge,
} from "react-bootstrap";

// Styles
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import Notifications from "./NotificationLayout";
import { useSelector } from "react-redux";
import Socket from "utils/Socket";
import { markAllNotifications } from "redux/actions";

export const LayoutHeader = ({ activeTab, setTab }) => {
  const unread = useSelector((state) => state.notificationsData.unread);
  const handleMarkAllRead = () => {
    // markNotification(_id);
    markAllNotifications();
    Socket.send("NOTIFICATIONS", {
      action: "READ_ALL",
    });
  };
  return (
    <>
      <h4 className="">Notifications</h4>
      <Row className="mb-3 pt-3" noGutters={true}>
        <Col
          className="d-flex border-bottom justify-content-between"
          xs="12"
          lg="12"
        >
          <div>
            <Button
              // style={{ borderBottom: "solid 2px #0A467F" }}
              className={`bold fake-link mr-4 ${
                activeTab === "new" ? "active-tab" : "tab"
              }`}
              variant="fake-link"
              onClick={() => setTab("new")}
            >
              New
            </Button>

            <Button
              // style={{ borderBottom: "solid 2px #0A467F" }}
              className={`bold fake-link ${
                activeTab === "all" ? "active-tab" : "tab"
              }`}
              variant="fake-link"
              onClick={() => setTab("all")}
            >
              All
            </Button>
          </div>
          <div className="">
            {unread ? (
              <Button
                variant="fake-link"
                style={{ color: "#999" }}
                className="bold inbox-action-item fake-link link-sec-action"
                onClick={handleMarkAllRead}
              >
                mark all read
              </Button>
            ) : null}
          </div>
        </Col>
      </Row>
    </>
  );
};
const NotificationsContainer = (props) => {
  let ref = React.useRef(null);
  const [activeTab, setTab] = useState("new");
  const notificationCount = useSelector(
    (state) => state.notificationsData.unread
  );
  const onTabChange = (tab) => {
    const read = tab !== "new";
    setTab(tab);
    props.fetchNotifications(1, read);
  };
  const popover = (
    <Popover id="popover-notifications" className="notifications-layout">
      <Popover.Content className="">
        <LayoutHeader activeTab={activeTab} setTab={onTabChange} />
        <Notifications activeTab={activeTab} />
      </Popover.Content>
    </Popover>
  );

  return (
    <div ref={ref}>
      <OverlayTrigger
        // ref={(r) => (ref = r)}
        container={ref}
        trigger="click"
        placement="bottom-end"
        overlay={popover}
        rootClose
      >
        <div className="cursor-pointer mr-3" style={{ display: "flex" }}>
          <FontAwesomeIcon icon={faBell} tabIndex="0" className="font-20" />
          {notificationCount !== 0 && (
            <Badge pill variant="primary" className="notification-badge">
              {notificationCount > 99 ? "99+" : notificationCount}
            </Badge>
          )}
        </div>
      </OverlayTrigger>
    </div>
  );
};
export default NotificationsContainer;
