import React, { useMemo } from "react";
import {
  faEllipsisV,
  faReply,
  faShare,
  faTrash,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown, Tooltip } from "antd";
import { conversationActions, conversationType } from "../enums";
import { useSelector } from "react-redux";

export const MessageOptions = ({
  type,
  isAnswer,
  showReply,
  showDelete,
  sender,
  onOptionClick,
}) => {
  const whoami = useSelector((state) => state.auth.whoami);

  const items = useMemo(
    () =>
      [
        showReply && {
          label: "Reply",
          key: conversationActions.reply,
          icon: <FontAwesomeIcon icon={faReply} />,
        },
        type !== conversationType.Question && {
          label: "Forward",
          key: conversationActions.forward,
          icon: <FontAwesomeIcon icon={faShare} />,
        },
        !isAnswer &&
          type === conversationType.Question &&
          whoami.role !== "Lender" && {
            label: "Edit",
            key: conversationActions.editQuestion,
            icon: <FontAwesomeIcon icon={faEdit} />,
          },
        isAnswer &&
          type === conversationType.Question &&
          whoami.role !== "Lender" && {
            label: "Edit",
            key: conversationActions.editAnswer,
            icon: <FontAwesomeIcon icon={faEdit} />,
          },
        showDelete && {
          label: "Delete",
          key: conversationActions.delete,
          icon: <FontAwesomeIcon icon={faTrash} />,
        },
      ].filter(Boolean),
    [showReply, type, sender]
  );

  const onClick = ({ key }) => {
    onOptionClick(key);
  };

  return (
    <Dropdown
      menu={{ items, onClick }}
      trigger={["click"]}
      placement="bottomRight"
    >
      <span className="px-2 cursor-pointer">
        <Tooltip title="More">
          <FontAwesomeIcon icon={faEllipsisV} />
        </Tooltip>
      </span>
    </Dropdown>
  );
};
