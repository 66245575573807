import React from "react";
import { connect } from "react-redux";
import { Row, Col, Button, Form } from "react-bootstrap";
import { required, email, mobileNumber, noSpaces } from "utils/validation";
import { Field, reduxForm } from "redux-form";
import {
  reduxFormNumber,
  reduxFormInput,
  reduxFormTextarea,
  reduxFormSelect,
} from "components/form/ReduxForm";
import { updateProfile, updateUser } from "redux/actions";
import { states } from "utils/dataUtil";
import { addPlacesAutoSuggestionsListener } from "components/google/places";
import Spacer from "components/layout/Spacer";
import Loader from "components/ui/Loader";
import ImageUpload from "components/form/ImageUpload";
import imageCompression from "browser-image-compression";

const addressFieldsMapping = {
  address: "address",
  city: "city",
  state: "state",
  country: "country",
  zipCode: "zip",
};

class AccountProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      compressing: false,
      zipCode: 0,
      previewFile: null,
      uploadedFile: null,
    };

    this.submitFn = this.submitFn.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.profile !== this.props.profile) {
      const { profile } = this.props;

      if (profile.zipCode) {
        this.setState({ zipCode: profile.zipCode });
      }
    }
  }

  submitFn(data) {
    const formData = new FormData();

    for (let property in data) {
      if (typeof data[property] === "object" && data[property] !== null) {
        data[property] = data[property].value;
      } else if (
        data[property] === "" ||
        data[property] === undefined ||
        data[property] === null
      ) {
        data[property] = " ";
      }

      formData.append(property, data[property]);
    }

    formData.append("profileImage", this.state.uploadedFile);
    formData.append("accountActivated", true);

    const { id } = this.props.match.params;
    if (id) {
      updateUser(id, formData);
    } else {
      updateProfile(formData);
    }
  }

  async handleFileChange(file) {
    this.setState({
      previewFile: URL.createObjectURL(file),
    });

    const options = {
      maxSizeMB: 0.75,
    };

    try {
      this.setState({ compressing: true });
      const compressedFile = await imageCompression(file, options);

      this.setState({
        uploadedFile: compressedFile,
        compressing: false,
      });
    } catch (error) {
      return error;
    }
  }

  componentDidMount() {
    addPlacesAutoSuggestionsListener(
      "user-address-field",
      addressFieldsMapping,
      (data) => {
        Object.keys(data).map((key) => {
          if (key === "state") {
            this.props.change(key, {
              label: data[key].name,
              value: data[key].nick_name.toUpperCase(),
            });
          } else {
            this.props.change(key, data[key]);
          }

          return true;
        });
      }
    );
  }

  render() {
    const { handleSubmit, action, profile } = this.props;
    const { isFetched } = action.GET_MY_PROFILE;

    if (!action.GET_MY_PROFILE.isFetching) {
      const disableChrome = document.querySelectorAll(
        'input[autocomplete="off"'
      );

      for (let el of disableChrome) {
        if (el !== null) {
          el.setAttribute("autocomplete", "new-password");
        }
      }
    }

    return (
      <>
        {this.state.compressing && <Loader files />}
        <Spacer size={50} />
        {isFetched ? (
          <Form className="full-width no-padding no-border">
            <Row>
              <Col xs="12">
                <h3 className="bold">Basic Info</h3>
                <Spacer size={30} />
              </Col>
            </Row>
            <Row>
              <Col xs="12" sm="6">
                <Field
                  className="form-control"
                  component={reduxFormInput}
                  validate={[required, noSpaces]}
                  name="firstName"
                  type="text"
                  placeholder="Enter first name"
                  label="*First name"
                />
                <Spacer size={20} />
                <Field
                  className="form-control"
                  component={reduxFormInput}
                  validate={[required, noSpaces]}
                  name="lastName"
                  type="text"
                  placeholder="Enter last name"
                  label="*Last name"
                />
                <Spacer size={20} />
                <Field
                  className="form-control"
                  component={reduxFormInput}
                  validate={[required, email, noSpaces]}
                  name="email"
                  type="email"
                  placeholder="Enter email"
                  label="*Email"
                  disabled
                />
                <Spacer size={20} />
                <Field
                  className="form-control"
                  component={reduxFormInput}
                  validate={[required, mobileNumber]}
                  name="phoneMobile"
                  type="text"
                  placeholder="Enter phone number"
                  label="*Phone number"
                />
                <Spacer size={20} />
                <Field
                  className="form-control"
                  component={reduxFormInput}
                  validate={[mobileNumber, noSpaces]}
                  name="phoneLandline"
                  type="text"
                  placeholder="Enter office number"
                  label="Office number"
                />
                <Spacer size={20} />
                <Field
                  className="form-control"
                  component={reduxFormInput}
                  validate={[noSpaces]}
                  name="title"
                  type="text"
                  placeholder="Enter title"
                  label="Title"
                />
                <Spacer size={20} />
              </Col>
              <Col xs="12" sm="6">
                <Field
                  component={ImageUpload}
                  uploadButtonText="Upload Avatar"
                  name="IMAGE"
                  src={
                    this.state.previewFile !== null
                      ? this.state.previewFile
                      : profile.profileImage
                  }
                  onChange={this.handleFileChange}
                />
                <Spacer size={20} />
              </Col>
            </Row>
            <Row>
              <Col xs="12" sm="6">
                <Field
                  className="form-control"
                  component={reduxFormInput}
                  validate={[noSpaces]}
                  name="linkedin"
                  type="text"
                  placeholder="Enter LinkedIn URL"
                  label="LinkedIn URL"
                />
                <Spacer size={20} />
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <Field
                  className="form-control"
                  component={reduxFormTextarea}
                  validate={[noSpaces]}
                  name="bio"
                  rows="10"
                  type="text"
                  placeholder="Enter bio"
                  label="Bio"
                />
              </Col>
              <Col xs="12">
                <Spacer size={20} />
                <Col className="border-bottom" xs="12"></Col>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <Spacer size={20} />
                <h3 className="bold">Address Info</h3>
                <Spacer size={30} />
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <Field
                  className="form-control"
                  component={reduxFormInput}
                  validate={[required, noSpaces]}
                  name="address"
                  type="text"
                  placeholder="Enter address"
                  label="*Address"
                  id="user-address-field"
                />
                <Spacer size={20} />
              </Col>
              <Col xs="12">
                <Field
                  className="form-control"
                  component={reduxFormInput}
                  validate={[noSpaces]}
                  name="address2"
                  type="text"
                  placeholder="Enter address"
                  label="Address 2"
                />
                <Spacer size={20} />
              </Col>
              <Col xs="12" sm="6">
                <Field
                  className="form-control"
                  component={reduxFormInput}
                  validate={[required, noSpaces]}
                  name="city"
                  type="text"
                  placeholder="Enter city"
                  label="*City"
                />
                <Spacer size={20} />
              </Col>
              <Col xs="12" sm="6">
                <Field
                  className="form-control"
                  component={reduxFormSelect}
                  validate={[required]}
                  name="state"
                  type="select"
                  options={states}
                  placeholder="Pick state"
                  label="*State"
                />
                <Spacer size={20} />
              </Col>
              <Col xs="12" sm="6">
                <Field
                  className="form-control"
                  component={reduxFormNumber}
                  validate={[required]}
                  name="zipCode"
                  placeholder="Enter zipcode"
                  label="*Zipcode"
                  thousandSeparator={false}
                  value={this.state.zipCode}
                />
                <Spacer size={20} />
              </Col>
              <Col xs="12" sm="6">
                <Field
                  className="form-control"
                  component={reduxFormInput}
                  validate={[required, noSpaces]}
                  name="country"
                  type="text"
                  placeholder="Enter country name"
                  label="*Country"
                />
                <Spacer size={20} />
              </Col>
            </Row>
            <Spacer size={50} />
            <Row>
              <Col className="text-right" xs="12">
                <Button variant="primary" onClick={handleSubmit(this.submitFn)}>
                  Update Profile
                </Button>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <Spacer size={20} />
                <Col className="border-bottom" xs="12"></Col>
              </Col>
            </Row>
          </Form>
        ) : (
          <Loader />
        )}
      </>
    );
  }
}

function mapStateToProps(reduxState, props) {
  const { profile } = props;
  const {
    firstName,
    lastName,
    email,
    phoneMobile,
    phoneLandline,
    title,
    address,
    address2,
    city,
    state,
    zipCode,
    country,
    bio,
    linkedin,
    profileImage,
    receiveEmailsForDeals,
  } = profile;

  return {
    action: reduxState.action,
    initialValues: {
      firstName: firstName !== " " ? firstName : "",
      lastName: lastName !== " " ? lastName : "",
      email: email !== " " ? email : "",
      phoneMobile: phoneMobile !== " " ? phoneMobile : "",
      phoneLandline: phoneLandline !== " " ? phoneLandline : "",
      title: title !== " " ? title : "",
      address: address !== " " ? address : "",
      address2: address2 !== " " ? address2 : "",
      city: city !== " " ? city : "",
      state: {
        label: state !== " " ? state : "",
        value: state !== " " ? state : "",
      },
      zipCode: zipCode !== " " ? zipCode : "",
      country: country !== " " ? country : "",
      bio: bio !== " " ? bio : "",
      linkedin: linkedin !== " " ? linkedin : "",
      IMAGE: profileImage !== " " ? profileImage : "",
      receiveEmailsForDeals,
    },
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "accountProfile",
    enableReinitialize: true,
  })(AccountProfile)
);
