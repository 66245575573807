import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Row, Col, Button, Form } from "react-bootstrap";
import { noSpaces, required } from "utils/validation";
import { Field, reduxForm, isPristine } from "redux-form";
import {
  reduxFormTextarea,
  reduxFormSelect,
  reduxFormNumber,
} from "components/form/ReduxForm";
import { getDropdown, updateDeal, getDealById } from "redux/actions";
import { getKeyFromLocal } from "utils/lsUtils";
import { faDollarSign, faHashtag } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import constants from "config/constants";
import Spacer from "components/layout/Spacer";
import Loader from "components/ui/Loader";
import PopupPrompt from "components/ui/PopupPrompt";
import SponsorCreate from "containers/deals/update/SponsorCreate";
import { isEmpty } from "lodash";
import { getDealCreationPath } from "utils/commonUtils";

class Sponsor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sizeApartments: 0,
      sizeOffice: 0,
      sizeMedicalOffice: 0,
      sizeIndustrial: 0,
      sizeRetail: 0,
      sizeHotel: 0,
      sizeSeniorLiving: 0,
      approxNetWorthKeyPrincipals: 0,
      approxLiqudityKeyPrincipals: 0,
      sponsors: [],
      keyPrincipleBios: props.initialValues.keyPrincipleBios,
      loadedDropdowns: [],
      netWorthKeyPrincipals: "",
      liquidityOfKeyPrincipals: "",
      showPrompt: false,
      sponsor: false,
      selectedSponsor: {},
    };

    this.submitFn = this.submitFn.bind(this);
    this.getDropdownFromAPI = this.getDropdownFromAPI.bind(this);
    this.getSponsors = this.getSponsors.bind(this);
    this.handleShowPrompt = this.handleShowPrompt.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.deal !== this.props.deal) {
      const { deal } = this.props;

      this.setState({
        netWorthKeyPrincipals:
          deal.netWorthKeyPrincipals && deal.netWorthKeyPrincipals !== null
            ? deal.netWorthKeyPrincipals
            : "None",
        liquidityOfKeyPrincipals:
          deal.liquidityOfKeyPrincipals &&
          deal.liquidityOfKeyPrincipals !== null
            ? deal.liquidityOfKeyPrincipals
            : "None",
        keyPrincipleBios: deal.keyPrincipleBios,
      });

      this.getSponsors();
    }

    if (prevProps.myCompany !== this.props.myCompany) {
      const { myCompany } = this.props;
      const { sponsors } = myCompany;

      this.setState({ sponsors: [] });
      // if(this.props.deal?.sponsorName) {
      //    const updatedSponsor = sponsors.find(item => item.value === this.props.deal?.sponsorName)
      //   console.log("if entered", updatedSponsor)
      //   this.onSponsorChange(updatedSponsor || {})
      // }

      sponsors.map((sponsor) => {
        this.setState((prevState) => ({
          sponsors: [
            ...prevState.sponsors,
            {
              label: sponsor.sponsorName,
              value: sponsor.sponsorName,
              keyPrincipleBios: sponsor.keyPrincipleBios,
            },
          ],
        }));
        return true;
      });
    }
  }

  componentDidMount() {
    if (getKeyFromLocal(constants.TERMST_LOCAL_STORAGE.DEAL_ID) !== false) {
      const dealId = getKeyFromLocal(constants.TERMST_LOCAL_STORAGE.DEAL_ID);

      getDealById(dealId);
    }
  }

  getDropdownFromAPI(value) {
    let canGet = false;

    if (this.state.loadedDropdowns.length > 0) {
      this.state.loadedDropdowns.some((dropdown) => {
        if (dropdown === value) {
          canGet = false;

          return true;
        } else {
          canGet = true;

          return false;
        }
      });
    } else {
      getDropdown(value);

      this.setState((prevState) => ({
        loadedDropdowns: [...prevState.loadedDropdowns, value],
      }));
    }

    if (canGet) {
      getDropdown(value);

      this.setState((prevState) => ({
        loadedDropdowns: [...prevState.loadedDropdowns, value],
      }));
    }
  }

  getSponsors() {
    const { myCompany, deal } = this.props;
    const { sponsors } = myCompany;

    if (isEmpty(this.state.selectedSponsor) && !isEmpty(deal.sponsorName)) {
      const selectedSponsor = sponsors.find(
        (sponsor) => sponsor.sponsorName === deal.sponsorName
      );
      this.setState({
        selectedSponsor,
      });
    }

    if (!this.state.sponsors.length > 0) {
      sponsors.map((sponsor) => {
        this.setState((prevState) => ({
          sponsors: [
            ...prevState.sponsors,
            {
              label: sponsor.sponsorName,
              value: sponsor.sponsorName,
              keyPrincipleBios: sponsor.keyPrincipleBios,
            },
          ],
        }));

        return true;
      });
    }
  }

  submitFn(data) {
    const dealId = getKeyFromLocal(constants.TERMST_LOCAL_STORAGE.DEAL_ID);
    const { isPristine } = this.props;
    const redirectTo = getDealCreationPath();

    for (let property in data) {
      if (typeof data[property] === "object" && data[property] !== null) {
        data[property] = data[property].value;
      } else if (data[property] === null) {
        data[property] = "";
      }
    }
    data.keyPrincipleBios = this.state.keyPrincipleBios || "";

    if (isPristine) {
      window.location.pathname = redirectTo;
    } else {
      data.sponsorGuarantor = true;
      data.isKeyPrincipalSame = this.props.deal.isPortifolio;
      data.keyPrincialId = this.state.selectedSponsor._id;

      updateDeal(dealId, data);
    }
  }

  handleShowPrompt(showPrompt, val) {
    this.setState({ showPrompt, sponsor: val });
  }

  onSponsorChange = (e) => {
    this.setState({
      keyPrincipleBios: e.keyPrincipleBios || "",
      selectedSponsor: e,
    });
  };

  onEditSponsor = () => {
    this.setState({
      isEdit: true,
      showPrompt: true,
      sponsor: true,
    });
  };
  render() {
    const { action, dropdowns, handleSubmit, deal, myCompany } = this.props;
    const { isFetching } = action.GET_DEAL_BY_ID;
    const { selectedSponsor, isEdit } = this.state;
    const path = getDealCreationPath();

    return (
      <>
        {isFetching && <Loader />}
        {this.state.sponsor && (
          <PopupPrompt
            promptTitle={"Add Key Principal"}
            promptBody={
              <SponsorCreate
                company={myCompany}
                onSubmit={() => {
                  this.handleShowPrompt(false, false);
                  this.setState({
                    isEdit: false,
                  });
                  this.getSponsors();
                }}
                selectedSponsor={isEdit ? selectedSponsor : {}}
                isEdit={isEdit}
              />
            }
            show={this.state.showPrompt}
            onCancel={() => {
              this.handleShowPrompt(false, false);
            }}
            onHide={() => {
              this.handleShowPrompt(false, false);
            }}
          />
        )}
        <Form
          className="full-width no-border mobile-no-padding"
          onSubmit={handleSubmit(this.submitFn)}
        >
          <Row>
            <Col xs="12" sm="4" md="6">
              <Field
                className="form-control"
                component={reduxFormSelect}
                validate={[required]}
                options={this.state.sponsors}
                isLoading={action.GET_MY_COMPANY.isFetching}
                onFocus={() => this.getSponsors()}
                onChange={this.onSponsorChange}
                name="sponsorName"
                type="select"
                placeholder="Select sponsor..."
                label="*Key Principal Name"
              />
              <Spacer size={20} />
            </Col>
            <Col xs="12" sm="8" md="6">
              <div className="d-flex">
                <Button
                  className="w-50  mr-2"
                  onClick={() => {
                    this.handleShowPrompt(true, true);
                  }}
                >
                  Add Key Principal
                </Button>
                {!isEmpty(selectedSponsor) && (
                  <Button className="w-50" onClick={this.onEditSponsor}>
                    Edit Key Principal
                  </Button>
                )}
              </div>
              <Spacer size={20} />
            </Col>
          </Row>
          <Row>
            <Col xs="12" sm="6">
              <Field
                className="form-control"
                component={reduxFormSelect}
                name="netWorthKeyPrincipals"
                type="select"
                options={
                  dropdowns["netWorthKeyPrincipals"] !== {}
                    ? dropdowns["netWorthKeyPrincipals"]
                    : {}
                }
                isLoading={action.GET_DROPDOWNS.isFetching}
                onChange={(e) =>
                  this.setState({ netWorthKeyPrincipals: e.value })
                }
                onFocus={() => this.getDropdownFromAPI("netWorthKeyPrincipals")}
                placeholder="None"
                label="Net Worth Key Principals"
              />
              <Spacer size={20} />
              {this.state.netWorthKeyPrincipals ===
                "Less than 50% of Loan Amount" && (
                <>
                  <Field
                    icon={<FontAwesomeIcon icon={faDollarSign} />}
                    className="form-control"
                    component={reduxFormNumber}
                    name="approxNetWorthKeyPrincipals"
                    placeholder="Enter amount..."
                    label="Approx Net Worth Key Principals"
                    thousandSeparator={true}
                    value={this.state.approxNetWorthKeyPrincipals}
                  />
                  <Spacer size={20} />
                </>
              )}
              <Field
                className="form-control"
                component={reduxFormSelect}
                name="liquidityOfKeyPrincipals"
                type="select"
                options={
                  dropdowns["liquidityOfKeyPrincipals"] !== {}
                    ? dropdowns["liquidityOfKeyPrincipals"]
                    : {}
                }
                isLoading={action.GET_DROPDOWNS.isFetching}
                onChange={(e) =>
                  this.setState({ liquidityOfKeyPrincipals: e.value })
                }
                onFocus={() =>
                  this.getDropdownFromAPI("liquidityOfKeyPrincipals")
                }
                placeholder="None"
                label={"Liquidity Of Key Principals"}
              />
              <Spacer size={20} />
              {this.state.liquidityOfKeyPrincipals ===
                "Less than 10% of Loan Amount" && (
                <>
                  <Field
                    icon={<FontAwesomeIcon icon={faDollarSign} />}
                    className="form-control"
                    component={reduxFormNumber}
                    name="approxLiqudityKeyPrincipals"
                    placeholder="Enter amount..."
                    label="Approx Liqudity Key Principals"
                    thousandSeparator={true}
                    value={this.state.approxLiqudityKeyPrincipals}
                  />
                  <Spacer size={20} />
                </>
              )}
            </Col>
            <Col xs="12" sm="6">
              {deal.propertyType && deal.propertyType === "Multifamily" && (
                <>
                  <Field
                    icon={<FontAwesomeIcon icon={faHashtag} />}
                    className="form-control"
                    component={reduxFormNumber}
                    name="sizeApartments"
                    placeholder={`Enter ${deal.propertySizeType}...`}
                    label={deal.propertySizeType}
                    thousandSeparator={true}
                    value={this.state.sizeApartments}
                  />
                  <Spacer size={20} />
                </>
              )}
              {deal.propertyType && deal.propertyType === "Office" && (
                <>
                  <Field
                    className="form-control"
                    component={reduxFormNumber}
                    name="sizeOffice"
                    placeholder="Enter amount..."
                    label={deal.propertySizeType + " of Office Owned"}
                    thousandSeparator={true}
                    value={this.state.sizeOffice}
                    suffix={" sq ft."}
                  />
                  <Spacer size={20} />
                </>
              )}
              {deal.propertyType && deal.propertyType === "Medical Office" && (
                <>
                  <Field
                    className="form-control"
                    component={reduxFormNumber}
                    name="sizeMedicalOffice"
                    placeholder="Enter amount..."
                    label={deal.propertySizeType + " of Medical Office Owned"}
                    thousandSeparator={true}
                    value={this.state.sizeMedicalOffice}
                    suffix={" sq ft."}
                  />
                  <Spacer size={20} />
                </>
              )}
              {deal.propertyType && deal.propertyType === "Industrial" && (
                <>
                  <Field
                    className="form-control"
                    component={reduxFormNumber}
                    name="sizeIndustrial"
                    placeholder="Enter amount..."
                    label={deal.propertySizeType + " of Industrial Area Owned"}
                    thousandSeparator={true}
                    value={this.state.sizeIndustrial}
                    suffix={" sq ft."}
                  />
                  <Spacer size={20} />
                </>
              )}
              {deal.propertyType && deal.propertyType === "Retail" && (
                <>
                  <Field
                    className="form-control"
                    component={reduxFormNumber}
                    name="sizeRetail"
                    placeholder="Enter amount..."
                    label={deal.propertySizeType + " of Retail Area Owned"}
                    thousandSeparator={true}
                    value={this.state.sizeRetail}
                    suffix={" sq ft."}
                  />
                  <Spacer size={20} />
                </>
              )}
              {deal.propertyType && deal.propertyType === "Senior Living" && (
                <>
                  <Field
                    icon={<FontAwesomeIcon icon={faHashtag} />}
                    className="form-control"
                    component={reduxFormNumber}
                    name="sizeSeniorLiving"
                    placeholder="Enter amount..."
                    label={
                      deal.propertySizeType + " in Senior Living Area Owned"
                    }
                    thousandSeparator={true}
                    value={this.state.sizeSeniorLiving}
                  />
                  <Spacer size={20} />
                </>
              )}
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <Field
                className="form-control"
                component={reduxFormTextarea}
                validate={[noSpaces]}
                name="keyPrincipleBios"
                rows="10"
                type="text"
                input={{
                  value: this.state.keyPrincipleBios,
                }}
                placeholder="Please describe..."
                label="Key Principal Bios"
              />
            </Col>
          </Row>
          <Spacer size={50} />
          <Row className="application-controls">
            <Col className="text-left" xs="6">
              <Button as={Link} className="pl-3 pr-3" to={path} variant="dark">
                Back
              </Button>
            </Col>
            <Col className="text-right" xs="6">
              <Button className="pl-2 pr-2" type="submit" variant="primary">
                Continue
              </Button>
            </Col>
          </Row>
        </Form>
      </>
    );
  }
}

function mapStateToProps(state) {
  const {
    sponsorName,
    sizeApartments,
    sizeOffice,
    sizeMedicalOffice,
    sizeIndustrial,
    sizeRetail,
    sizeHotel,
    sizeSeniorLiving,
    netWorthKeyPrincipals,
    approxNetWorthKeyPrincipals,
    liquidityOfKeyPrincipals,
    approxLiqudityKeyPrincipals,
    keyPrincipleBios,
  } = state.deal.deal;

  return {
    isPristine: isPristine("Sponsor")(state),
    action: state.action,
    myCompany: state.company.myCompany,
    dropdowns: state.program.dropdowns,
    deal: state.deal.deal,
    initialValues: {
      sizeApartments,
      sizeOffice,
      sizeMedicalOffice,
      sizeIndustrial,
      sizeRetail,
      sizeHotel,
      sizeSeniorLiving,
      approxNetWorthKeyPrincipals,
      approxLiqudityKeyPrincipals,
      keyPrincipleBios,
      sponsorName:
        sponsorName && sponsorName !== null
          ? { label: sponsorName, value: sponsorName }
          : null,
      netWorthKeyPrincipals:
        netWorthKeyPrincipals && netWorthKeyPrincipals !== null
          ? { label: netWorthKeyPrincipals, value: netWorthKeyPrincipals }
          : null,
      liquidityOfKeyPrincipals:
        liquidityOfKeyPrincipals && liquidityOfKeyPrincipals !== null
          ? { label: liquidityOfKeyPrincipals, value: liquidityOfKeyPrincipals }
          : null,
    },
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "Sponsor",
    enableReinitialize: true,
  })(Sponsor)
);
