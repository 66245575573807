import React, { createRef } from "react";
import { connect } from "react-redux";
import {
  Spinner,
  Row,
  Col,
  Image,
  Button,
  Card,
  Accordion,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { getTeamsByCompanyId, searchDeals } from "redux/actions";
import SocialMediaButton from "components/ui/SocialMediaButton";
import Avatar from "images/avatar.jpg";
import CompanyPic from "images/company-placeholder.png";
import Spacer from "components/layout/Spacer";
import Paginate from "components/ui/Paginate";
import NumberFormat from "react-number-format";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileInvoiceDollar,
  faCaretDown,
} from "@fortawesome/free-solid-svg-icons";

class Company extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deals: [],
      teamsPage: 1,
      getPrimaryTeam: false,
      getDeals: false,
    };

    this.accordionToggle = createRef(null);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.teams !== this.props.teams && this.state.getPrimaryTeam) {
      const { teams, company, onAddToDeal } = this.props;
      const { _id, companyName, companyDefaultContact } = company;

      const primaryTeam = teams.filter((team) =>
        team.teamMembers.find(
          (member) =>
            member.globalRole === "Team Leader" &&
            member.userId.firstName === companyDefaultContact?.firstName &&
            member.userId.lastName === companyDefaultContact?.lastName
        )
      );

      this.setState({ getPrimaryTeam: false });

      onAddToDeal({
        companyToAdd: _id,
        teamToAdd: primaryTeam && primaryTeam.length && primaryTeam[0]._id,
        companyName,
      });
    }

    if (prevProps.deals !== this.props.deals) {
      const { deals } = this.props;

      this.setState({ deals });
    }
  }

  render() {
    const { type, action, company, teams, totalTeams, onAddToDeal } =
      this.props;

    const {
      companyName,
      companyLogo,
      companyDefaultContact = {},
      programs,
    } = company;

    return (
      <Col xs="12">
        <Card className="mb-3 company-card">
          <Accordion.Toggle
            as={Card.Header}
            className="focusable-header text-center"
            tabIndex="0"
            eventKey={company._id}
            onClick={() => {
              if (
                !this.accordionToggle.current.firstChild.classList.contains(
                  "show"
                )
              ) {
                getTeamsByCompanyId(company._id, { limit: 4, page: 1 });
                searchDeals({
                  search: company._id,
                  isPublic: true,
                  archived: false,
                });
                this.props.setExpandedKey(company._id);
              } else {
                this.props.setExpandedKey(null);
              }
            }}
            onKeyDown={(e) =>
              e.keyCode === 13 ? document.activeElement.click() : null
            }
          >
            <Row className="w-100" noGutters={true}>
              <Col
                className="d-flex align-items-center"
                xs={type === "Lender" ? "6" : "9"}
                sm={type === "Lender" ? "7" : "9"}
              >
                <Image
                  className="d-inline-block mobile-img"
                  src={company && companyLogo ? companyLogo : CompanyPic}
                  alt={companyName + " company picture."}
                  width="75px"
                  height="auto"
                />
                <div className="text-left manage-company-view-edit">
                  <h2 className="bold text-left font-18 d-block pl-3">
                    {companyName}
                  </h2>
                  <Link
                    className="d-inline ml-3 font-14"
                    to={"/company/profile/" + company._id}
                  >
                    View
                  </Link>
                  <span className="ml-2 mr-2">|</span>
                  <Link
                    className="d-inline mr-3 font-14"
                    to={"/company/edit/" + company._id}
                  >
                    Edit
                  </Link>
                </div>
              </Col>
              <Col
                className={
                  type === "Lender"
                    ? "d-none d-md-flex align-items-center"
                    : "d-flex align-items-center"
                }
                xs={3}
                sm={3}
              >
                <div className="text-left mr-3">
                  <p className="d-block font-14">Primary Contact</p>
                  <p className="d-block font-14 bold">
                    {companyDefaultContact?.firstName}{" "}
                    {companyDefaultContact?.lastName}
                  </p>
                </div>
              </Col>
              {type === "Lender" && (
                <Col
                  className="d-flex align-items-center justify-content-end"
                  xs="6"
                  sm="5"
                  md="2"
                >
                  <Button
                    className="pl-3 pr-3"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      this.setState({ getPrimaryTeam: true }, function () {
                        getTeamsByCompanyId(company._id);
                      });
                      this.props.setExpandedKey(null);
                    }}
                  >
                    Add to Deal
                  </Button>
                </Col>
              )}
            </Row>
          </Accordion.Toggle>
          <div ref={this.accordionToggle}>
            <Accordion.Collapse eventKey={company._id}>
              <Card.Body>
                <Accordion>
                  <Accordion.Toggle as="div" eventKey={company._id + 1}>
                    <div
                      className="d-flex inner-accordion mb-2 pb-1 justify-content-between"
                      onClick={(e) => {
                        if (e.target.lastChild.getAttribute("style") !== null) {
                          e.target.lastChild.removeAttribute("style");
                        } else {
                          e.target.lastChild.style.transform = "rotate(180deg)";
                        }
                      }}
                    >
                      <h5 className="bold">Teams</h5>
                      <FontAwesomeIcon icon={faCaretDown} />
                    </div>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={company._id + 1}>
                    {action.GET_TEAMS_BY_COMPANY_ID.isFetched ? (
                      <>
                        {teams &&
                          teams.map((team, idx) => {
                            const teamLead = team.teamMembers.find(
                              (x) => x.globalRole === "Team Leader"
                            );

                            const { userId } = teamLead;
                            const {
                              profileImage,
                              firstName,
                              lastName,
                              linkedin,
                              email,
                              phoneMobile,
                              phoneLandline,
                            } = userId;

                            return (
                              <div
                                className="d-flex company-team-lead-info"
                                key={idx}
                              >
                                <Image
                                  className="d-inline-block mobile-img align-self-center"
                                  src={
                                    teamLead && profileImage
                                      ? profileImage
                                      : Avatar
                                  }
                                  alt={
                                    teamLead &&
                                    firstName +
                                      " " +
                                      lastName +
                                      "'s profile picture."
                                  }
                                  width="75px"
                                  height="auto"
                                />
                                <div className="company-team-lead-details pl-3">
                                  {teamLead && linkedin && linkedin !== " " && (
                                    <OverlayTrigger
                                      placement="bottom"
                                      overlay={
                                        <Tooltip id={`tooltip-bottom`}>
                                          LinkedIn
                                        </Tooltip>
                                      }
                                    >
                                      <div className="mr-2 d-inline-block">
                                        <SocialMediaButton
                                          icon="linkedin"
                                          url={linkedin}
                                        />
                                      </div>
                                    </OverlayTrigger>
                                  )}
                                  {teamLead && email && (
                                    <OverlayTrigger
                                      placement="bottom"
                                      overlay={
                                        <Tooltip id={`tooltip-bottom`}>
                                          Email
                                        </Tooltip>
                                      }
                                    >
                                      <div className="mr-2 d-inline-block">
                                        <SocialMediaButton
                                          icon="email"
                                          data={"mailto:" + email}
                                        />
                                      </div>
                                    </OverlayTrigger>
                                  )}
                                  {teamLead &&
                                    phoneMobile &&
                                    phoneMobile !== " " && (
                                      <OverlayTrigger
                                        placement="bottom"
                                        overlay={
                                          <Tooltip id={`tooltip-bottom`}>
                                            Mobile Phone
                                          </Tooltip>
                                        }
                                      >
                                        <div className="mr-2 d-inline-block">
                                          <SocialMediaButton
                                            icon="mobile"
                                            data={"tel:" + phoneMobile}
                                          />
                                        </div>
                                      </OverlayTrigger>
                                    )}
                                  {teamLead &&
                                    phoneLandline &&
                                    phoneLandline !== " " && (
                                      <OverlayTrigger
                                        placement="bottom"
                                        overlay={
                                          <Tooltip id={`tooltip-bottom`}>
                                            Office Phone
                                          </Tooltip>
                                        }
                                      >
                                        <div className="mr-2 d-inline-block">
                                          <SocialMediaButton
                                            icon="office"
                                            data={"tel:" + phoneLandline}
                                          />
                                        </div>
                                      </OverlayTrigger>
                                    )}
                                  <h4 className="bold">
                                    <Spacer size={5} />
                                    {teamLead && firstName}{" "}
                                    {teamLead && lastName}
                                  </h4>
                                </div>
                                <Row className="w-100">
                                  <Col
                                    className="align-self-end d-none d-lg-flex"
                                    sm="4"
                                  >
                                    <p>{teamLead && email}</p>
                                  </Col>
                                  <Col
                                    className="align-self-end d-none d-lg-flex"
                                    sm="3"
                                  >
                                    <p>
                                      {teamLead &&
                                        phoneLandline &&
                                        phoneLandline.length > 1 && (
                                          <>
                                            <span className="font-10">
                                              OFFICE
                                            </span>{" "}
                                            <NumberFormat
                                              format="###-###-####"
                                              displayType={"text"}
                                              value={phoneLandline}
                                            />
                                          </>
                                        )}
                                    </p>
                                  </Col>
                                  <Col
                                    className="align-self-end d-none d-lg-flex"
                                    sm="3"
                                  >
                                    <p>
                                      {teamLead &&
                                        phoneMobile &&
                                        phoneMobile.length > 1 && (
                                          <>
                                            <span className="font-10">
                                              MOBILE
                                            </span>{" "}
                                            <NumberFormat
                                              format="###-###-####"
                                              displayType={"text"}
                                              value={phoneMobile}
                                            />
                                          </>
                                        )}
                                    </p>
                                  </Col>
                                  {type === "Lender" && (
                                    <div className="flex-1 pr-3 text-right">
                                      <Button
                                        className="fake-link"
                                        variant="fake-link"
                                        onClick={() => {
                                          onAddToDeal({
                                            companyToAdd: company._id,
                                            companyName,
                                            teamToAdd: team._id,
                                          });
                                        }}
                                      >
                                        Add to Deal
                                      </Button>
                                    </div>
                                  )}
                                </Row>
                              </div>
                            );
                          })}
                        <Paginate
                          page={this.state.teamsPage}
                          list={teams}
                          listTotalCount={totalTeams}
                          listOffset={4}
                          previousPage={(p) => {
                            this.setState({ teamsPage: p });

                            getTeamsByCompanyId(company._id, {
                              limit: 4,
                              page: p,
                            });
                          }}
                          nextPage={(p) => {
                            this.setState({ teamsPage: p });

                            getTeamsByCompanyId(company._id, {
                              limit: 4,
                              page: p,
                            });
                          }}
                        />
                      </>
                    ) : (
                      <div className="text-center">
                        <Spinner
                          animation="border"
                          role="status"
                          variant="primary"
                        >
                          <span className="sr-only">Loading...</span>
                        </Spinner>
                      </div>
                    )}
                  </Accordion.Collapse>
                </Accordion>
                <Spacer size={50} />
                <Accordion>
                  <Accordion.Toggle as="div" eventKey={company._id + 2}>
                    <div
                      className="d-flex inner-accordion mb-2 pb-1 justify-content-between"
                      onClick={(e) => {
                        if (e.target.lastChild.getAttribute("style") !== null) {
                          e.target.lastChild.removeAttribute("style");
                        } else {
                          e.target.lastChild.style.transform = "rotate(180deg)";
                        }
                      }}
                    >
                      <h5 className="bold">Deals</h5>
                      <FontAwesomeIcon icon={faCaretDown} />
                    </div>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={company._id + 2}>
                    {action.SEARCH_DEALS.isFetched ? (
                      <Row>
                        {this.state.deals.map((deal, idx) => {
                          const {
                            _id,
                            propertyName,
                            propertyCity,
                            propertyState,
                            photos,
                          } = deal;

                          return (
                            <Col className="mb-3" xs="6" sm="4" key={idx}>
                              <Link className="d-flex" to={"/deal/view/" + _id}>
                                {photos &&
                                  photos !== undefined &&
                                  photos.map((photo, idx2) => {
                                    if (photo.isPrimaryPicture) {
                                      return (
                                        <Image
                                          className="d-none d-lg-inline-block mr-3"
                                          src={photo.location}
                                          width="75px"
                                          height="auto"
                                          alt={
                                            deal.propertyName +
                                            " primary photo."
                                          }
                                          key={idx2}
                                        />
                                      );
                                    }

                                    return true;
                                  })}
                                {photos &&
                                  photos !== undefined &&
                                  !photos.some(
                                    (photo) => photo.isPrimaryPicture
                                  ) && (
                                    <FontAwesomeIcon
                                      className="d-none d-lg-inline-block mr-3"
                                      style={{
                                        width: "75px",
                                        fontSize: "36px",
                                        paddingTop: "5px",
                                      }}
                                      icon={faFileInvoiceDollar}
                                    />
                                  )}
                                <div className="flex-1">
                                  <h4 className="bold">{propertyName}</h4>
                                  <p>
                                    {propertyCity}, {propertyState}
                                  </p>
                                </div>
                              </Link>
                            </Col>
                          );
                        })}
                        {this.state.deals.length === 0 && (
                          <Col xs="12">
                            <p className="font-14">
                              There are no deals to display.
                            </p>
                          </Col>
                        )}
                      </Row>
                    ) : (
                      <div className="text-center">
                        <Spinner
                          animation="border"
                          role="status"
                          variant="primary"
                        >
                          <span className="sr-only">Loading...</span>
                        </Spinner>
                      </div>
                    )}
                  </Accordion.Collapse>
                </Accordion>
                {type === "Lender" && (
                  <>
                    <Spacer size={50} />
                    <Accordion>
                      <Accordion.Toggle as="div" eventKey={company._id + 2}>
                        <div
                          className="d-flex inner-accordion mb-2 pb-1 justify-content-between"
                          onClick={(e) => {
                            if (
                              e.target.lastChild.getAttribute("style") !== null
                            ) {
                              e.target.lastChild.removeAttribute("style");
                            } else {
                              e.target.lastChild.style.transform =
                                "rotate(180deg)";
                            }
                          }}
                        >
                          <h5 className="bold">Programs</h5>
                          <FontAwesomeIcon icon={faCaretDown} />
                        </div>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey={company._id + 2}>
                        {action.SEARCH_COMPANIES.isFetched ? (
                          <Row>
                            {programs &&
                              programs.map((program, idx) => {
                                const { _id, programName } = program;

                                return (
                                  <Col xs="12" sm="4" key={idx}>
                                    <div className="panel">
                                      <h5>
                                        <Link to={"/program/view/" + _id}>
                                          {programName}
                                        </Link>
                                      </h5>
                                    </div>
                                    <Spacer size={20} />
                                  </Col>
                                );
                              })}
                            {programs && programs.length === 0 && (
                              <Col xs="12">
                                <p className="font-14">
                                  There are no programs to display.
                                </p>
                              </Col>
                            )}
                          </Row>
                        ) : (
                          <div className="text-center">
                            <Spinner
                              animation="border"
                              role="status"
                              variant="primary"
                            >
                              <span className="sr-only">Loading...</span>
                            </Spinner>
                          </div>
                        )}
                      </Accordion.Collapse>
                    </Accordion>
                  </>
                )}
              </Card.Body>
            </Accordion.Collapse>
          </div>
        </Card>
      </Col>
    );
  }
}

function mapStateToProps(state) {
  return {
    action: state.action,
    totalTeams: state.team.totalTeams,
    teams: state.team.teams,
    deals: state.deal.deals,
  };
}

export default connect(mapStateToProps)(Company);
