import { dispatch } from "config/store";
import { actionTypes } from "./actionTypes";

export const getDealFilesById = (dealId) => {
  const action = {
    type: actionTypes.GET_DEAL_FILES,
    config: {
      method: "get",
      url: `/dealfiles/${dealId}`,
    },
  };
  dispatch(action);
};

export const createFolder = (data) => {
  const action = {
    type: actionTypes.CREATE_DEAL_FOLDER,
    config: {
      method: "post",
      url: `/dealfolder`,
      data,
    },
  };
  dispatch(action);
};

export const createDealFolders = (dealId, data) => {
  const action = {
    type: actionTypes.CREATE_DEAL_FOLDERS,
    config: {
      method: "post",
      url: `/dealfolders/${dealId}`,
      data,
    },
  };
  dispatch(action);
};
export const createPropertyFolders = (dealId, data) => {
  const action = {
    type: actionTypes.CREATE_PROPERTY_FOLDERS,
    config: {
      method: "post",
      url: `/dealfiles/property/${dealId}`,
      data,
    },
  };
  dispatch(action);
};

export const saveDealFolderFiles = (data) => {
  const action = {
    type: actionTypes.SAVE_DEAL_FOLDER_FILES,
    config: {
      method: "post",
      url: `/dealfiles`,
      data,
    },
  };
  dispatch(action);
};

export const deleteFile = (id) => {
  const action = {
    type: actionTypes.DELETE_FILE,
    config: {
      method: "delete",
      url: `/removedealfile/${id}`,
    },
  };
  dispatch(action);
};

export const deleteFolder = (id) => {
  const action = {
    type: actionTypes.DELETE_FOLDER,
    config: {
      method: "delete",
      url: `/removedealfolder/${id}`,
    },
  };
  dispatch(action);
};

export const getPermission = (id) => {
  const action = {
    type: actionTypes.GET_PERMISSION,
    config: {
      method: "get",
      url: `/dealfiles/permission/folderfile/${id}`,
    },
  };
  dispatch(action);
};

export const getPermissionUsers = (dealId, folderfileId) => {
  const action = {
    type: actionTypes.GET_PERMISSION_USERS,
    config: {
      method: "get",
      url: `/dealfiles/permission/role/Borrower/deal/${dealId}/folderfile/${folderfileId}`,
    },
  };
  dispatch(action);
};

export const addPermission = (id, data) => {
  const action = {
    type: actionTypes.ADD_PERMISSION,
    config: {
      method: "post",
      url: `/dealfiles/permission/folderfile/${id}`,
      data,
    },
  };
  dispatch(action);
};

export const removePermission = (folderfileId, userId) => {
  const action = {
    type: actionTypes.REMOVE_PERMISSION,
    config: {
      method: "delete",
      url: `dealfiles/permission/folderfile/${folderfileId}/user/${userId}`,
    },
  };
  dispatch(action);
};

export const renameFolderFile = (id, data) => {
  const action = {
    type: actionTypes.RENAME_FOLDER_FILE,
    config: {
      method: "put",
      url: `/dealfolder/${id}`,
      data,
    },
  };
  dispatch(action);
};

export const updatePrimaryPicture = (folderId, data) => {
  const action = {
    type: actionTypes.UPDATE_PRIMARY_PICTURE,
    config: {
      method: "put",
      url: `/dealfiles/picture/primary/${folderId}`,
      data,
    },
  };
  dispatch(action);
};

export const searchDealFiles = (dealId, search) => {
  const action = {
    type: actionTypes.SEARCH_DEAL_FILES,
    config: {
      method: "get",
      url: `/dealfiles/${dealId}/search/${search}`,
    },
  };
  dispatch(action);
};

export const manageFilePermission = (dealId, fileId, act, data) => {
  const action = {
    type: actionTypes.MANAGE_FILE_PERMISSION,
    config: {
      method: "post",
      url: `/dealfiles/${dealId}/folderfile/${fileId}/permission/${act}`,
      data,
    },
  };
  dispatch(action);
};
