import React from "react";
import Spacer from "components/layout/Spacer";
import { Row, Col } from "react-bootstrap";
import PictureItem from "./PictureItem";
import GridPictureItem from "./GridPictureItem";

const Picture = (props) => {
  const {
    userId,
    category,
    fetchFile,
    removeFile,
    updatePrimaryPhoto,
    showSecuritySharing,
    parentPermission,
    showRenaming,
    showGrid,
    fromComms,
    addSelectedFile,
    removeSelectedFile,
    getSelectedFiles,
    canManage
  } = props;
  return (
    <>
      <div>
        {showGrid ? (
          <Row className="m-0 grid-file-item-header">
            <Col className="font-16" xs={fromComms ? 8 : 5}>
              {category}
            </Col>
          </Row>
        ) : (
            <Row className="m-0 file-item-header">
              <Col className="font-16" xs={fromComms ? 8 : 5}>
                {category}
              </Col>
              <Col className="text-center" xs="2">
                Primary Photo
            </Col>
              <Col className="text-center" xs="2">
                Security
            </Col>
              <Col xs="3"></Col>
            </Row>
          )}
        <div className="grid-container">
          {props.pictures.map((picture) =>
            showGrid ? (
              <GridPictureItem
                key={picture._id}
                userId={userId}
                parentPermission={parentPermission}
                fetchFile={fetchFile}
                removeFile={removeFile}
                showSecuritySharing={showSecuritySharing}
                showRenaming={showRenaming}
                canManage={canManage}
                {...picture}
              />
            ) : (
                <PictureItem
                  key={picture._id}
                  userId={userId}
                  parentPermission={parentPermission}
                  fetchFile={fetchFile}
                  removeFile={removeFile}
                  updatePrimaryPhoto={updatePrimaryPhoto}
                  showSecuritySharing={showSecuritySharing}
                  showRenaming={showRenaming}
                  fromComms={fromComms}
                  addSelectedFile={addSelectedFile}
                  removeSelectedFile={removeSelectedFile}
                  getSelectedFiles={getSelectedFiles}
                  canManage={canManage}
                  fileKey={picture.key}
                  {...picture}
                />
              )
          )}
        </div>
        <Spacer size={20} />
      </div>
    </>
  );
};

export default Picture;
