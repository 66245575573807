import React from "react";
import { Row, Col, Form, Button } from "react-bootstrap";

import PopupPrompt from "components/ui/PopupPrompt";
import {
  reduxFormInput as Input,
  reduxFormTextarea as TextArea,
} from "components/form/ReduxForm";
import Spacer from "components/layout/Spacer";
import { passDeal } from "redux/actions";
import { toast } from "react-toastify";

const reasons = ["Market", "Rate", "Proceeds", "Property", "Sponsor", "Other"];

class PassDealForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPrompt: false,
      reasonForPassing: [],
      passedReason: "",
    };
  }

  showPrompt = (e) => {
    e.stopPropagation();
    this.setState({ showPrompt: true });
  };

  hidePrompt = () => this.setState({ showPrompt: false });

  handleChange = (e) => {
    this.setState({ passedReason: e.target.value });
  };

  handleCheck = (e) => {
    e.persist();
    if (e.target.checked) {
      this.setState((prevState) => ({
        reasonForPassing: [...prevState.reasonForPassing, e.target.name],
      }));
    } else {
      this.setState((prevState) => {
        const reasonForPassing = [...prevState.reasonForPassing];
        const index = reasonForPassing.indexOf(e.target.name);
        reasonForPassing.splice(index, 1);
        return { reasonForPassing };
      });
    }
  };

  handlePass = () => {
    if (!this.state.reasonForPassing.length) {
      toast.error("Please select Reason", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    passDeal(this.props.dealId, {
      reasonForPassing: this.state.reasonForPassing.map((reason) => ({
        value: reason,
        label: reason,
      })),
      passedReason: this.state.passedReason,
      companyId: this.props.companyId,
    });
    this.hidePrompt();
  };

  render() {
    return (
      <>
        <PopupPrompt
          promptTitle={"Reason for passing"}
          promptBody={
            <>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                className="no-border no-padding"
              >
                <Row>
                  <Col xs="12" sm="6">
                    <Row>
                      {reasons.map((reason) => (
                        <Col xs="6" md="6" key={reason}>
                          <Input
                            className="form-control"
                            label={reason}
                            type="checkbox"
                            input={{
                              checked:
                                this.state.reasonForPassing.includes(reason),
                              name: reason,
                              onChange: this.handleCheck,
                            }}
                            meta={{}}
                            style={{ paddingBottom: 5 }}
                          />
                        </Col>
                      ))}
                    </Row>
                  </Col>
                  <Col xs="12" sm="6" className="mt-3">
                    Additional Information
                    <TextArea
                      className="form-control"
                      id="passedReason"
                      type="text"
                      placeholder="Tell us more about why you passed..."
                      meta={{}}
                      input={{
                        value: this.state.passedReason,
                        onChange: this.handleChange,
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xs="12" md="12">
                    <Spacer size={20} />
                    <Button
                      type="submit"
                      variant="primary"
                      onClick={this.handlePass}
                    >
                      Pass
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                      type="submit"
                      variant="primary"
                      onClick={this.hidePrompt}
                    >
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </Form>
            </>
          }
          show={this.state.showPrompt}
          onHide={this.hidePrompt}
          onCancel={this.hidePrompt}
        />
        <span onClick={this.showPrompt}>{this.props.children}</span>
      </>
    );
  }
}

export default PassDealForm;
