import React, { useRef } from "react";
import { Button } from "react-bootstrap";
import { faFileAlt } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ALLOWED_FILE_EXTS } from "containers/deals/FileManager/constants";

const FinancialFolderItem = (props) => {
  const { _id, name, files, handleUploadFile, isRequired, isTogether } = props;
  const inputRef = useRef(null);

  return (
    <div
      className={
        isTogether
          ? "d-flex flex-column pl-3 pr-3 align-items-end"
          : "d-flex flex-column mb-30 pl-3 pr-3 align-items-end"
      }
    >
      <div
        style={{ minHeight: 160 }}
        className={
          files.length > 0 ? "grid-col-container dark" : "grid-col-container"
        }
      >
        <div className="grid-item grid-col-name">
          <div className="grid-row">
            <span className="font-16 bold grid-file-name">{name}</span>
          </div>
        </div>
        {files.length > 0 ? (
          <span className="mb-1 font-18">
            {files.length} <FontAwesomeIcon icon={faFileAlt} />
          </span>
        ) : (
          <span className="mb-1 font-12">Drop files here or</span>
        )}
        <Button
          variant="file-upload"
          className={files.length > 0 ? "dark" : null}
          onClick={() => {
            inputRef.current.click();
          }}
        >
          Select Files
        </Button>
        <input
          ref={inputRef}
          className="drag-drop-file-upload"
          type="file"
          id={_id}
          onChange={(event) => handleUploadFile(event, _id, name)}
          accept={ALLOWED_FILE_EXTS.join(", ")}
          multiple
        />
      </div>
      {isRequired && (
        <>
          {name === "Rent Roll" ? (
            <div
              className="grid-required mt-2 text-center w-100"
              style={{ borderTop: "dotted 2px #753972" }}
            >
              <p className="font-14 color-purple">Required</p>
            </div>
          ) : (
            <div
              className="grid-required mt-2 text-center w-100"
              style={{ borderTop: "dotted 2px #753972" }}
            >
              <p className="font-14 color-purple">One required</p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default FinancialFolderItem;
