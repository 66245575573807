import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import moment from "moment";
import { Col, Dropdown, Row } from "react-bootstrap";
import CustomToggle from "components/ui/CustomToggle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import CustomMenu from "components/ui/CustomMenu";
import { getDealById, getTermsheet } from "redux/actions";
import { connect } from "react-redux";
import NumberFormat from "react-number-format";
import DownloadTermsheet, { getTeamLead } from "./DownloadTermsheet";
import { useCallback } from "react";
import LendersLogo from "./LendersLogo";
import { getPrimaryClauseValueKey } from "containers/quotes/quotesConfig";
import {
  getFloorTextValue,
  getIndexAsOfLabel,
  getSpreadTextValue,
  hasQuoteSpread,
} from "utils/commonUtils";
import {
  getAddedClauseValue,
  isOpeningParagraph,
  primaryClauses,
  replaceMergeFields,
} from "utils/termsheetUtils";

const TermsheetPreview = (props) => {
  const { match, termsheet, deal, whoami } = props;
  const tablePreviewRef = useRef();
  const [width, setWidth] = useState();

  const borrowerCompany = deal?.company;
  const teamLead = useMemo(() => getTeamLead(termsheet), [termsheet?.teams]);
  const hasMatrix = termsheet.quotes?.length > 1;
  const [firstQuote = {}] = termsheet.quotes || [];

  const isSpread = useMemo(
    () => hasQuoteSpread(termsheet.quotes),
    [termsheet.quotes]
  );

  useEffect(() => {
    if (tablePreviewRef.current) {
      setWidth(tablePreviewRef.current.clientWidth);
    }
  }, [termsheet._id, deal]);

  useEffect(() => {
    getTermsheet(match.params.termsheetId);
  }, [match.params.termsheetId]);

  useEffect(() => {
    if (termsheet.dealId) {
      getDealById(termsheet.dealId);
    }
  }, [termsheet._id]);

  const getNoMatrixSectionValue = useCallback((firstQuote, valueKey) => {
    if (valueKey === "spread") {
      return getSpreadTextValue(firstQuote);
    }
    if (valueKey === "floorOptionValue") {
      return getFloorTextValue(firstQuote);
    }
    return firstQuote[valueKey] || 0;
  }, []);

  const handlePrint = () => {
    window.print();
  };

  const handleDownloadDocx = () => {
    DownloadTermsheet.DOCX({ termsheet, deal, borrowerCompany, teamLead });
  };

  const renderColumn = useCallback(
    (
      column,
      {
        key,
        format,
        sectionValue = column.sectionValue,
        sectionYearsMonths = column.sectionYearsMonths,
      }
    ) => {
      format = format || column.clause?.format || "Text";

      const langDesc = replaceMergeFields(
        column.langDesc,
        deal,
        whoami,
        borrowerCompany
      );
      // paragraph no header
      if (format === "Open Text, left aligned, no header") {
        return (
          <Fragment key={key}>
            <tr>
              <td
                colSpan={4}
                dangerouslySetInnerHTML={{
                  __html: langDesc,
                }}
              ></td>
            </tr>
            <tr>
              <td>
                <br />
              </td>
            </tr>
          </Fragment>
        );
      }

      // paragraph with header
      if (format === "Open Text, with header") {
        return (
          <Fragment key={key}>
            <tr>
              <td>
                <b>{column.sectionName}:</b>
              </td>
              <td
                colSpan={3}
                dangerouslySetInnerHTML={{
                  __html: langDesc,
                }}
              ></td>
            </tr>
            <tr>
              <td>
                <br />
              </td>
            </tr>
          </Fragment>
        );
      }

      // currency
      if (format === "$#,###") {
        return (
          <Fragment key={key}>
            <tr>
              <td>
                <b>{column.sectionName}:</b>
              </td>
              <td colSpan={3}>
                <NumberFormat
                  value={sectionValue}
                  displayType={"text"}
                  thousandSeparator
                  prefix="$"
                />
              </td>
            </tr>
            {column.langDesc && (
              <tr>
                <td></td>
                <td
                  colSpan={3}
                  dangerouslySetInnerHTML={{
                    __html: column.langDesc,
                  }}
                ></td>
              </tr>
            )}
            <tr>
              <td>
                <br />
              </td>
            </tr>
          </Fragment>
        );
      }

      // number
      if (format === "#,###" || format === "#.##x") {
        return (
          <Fragment key={key}>
            <tr>
              <td>
                <b>{column.sectionName}:</b>
              </td>
              <td colSpan={3}>
                <NumberFormat
                  value={sectionValue}
                  displayType={"text"}
                  thousandSeparator
                  decimalScale={format === "#,###" ? 0 : 10}
                  suffix={` ${sectionYearsMonths}`}
                />
              </td>
            </tr>
            {column.langDesc && (
              <tr>
                <td></td>
                <td
                  colSpan={3}
                  dangerouslySetInnerHTML={{
                    __html: column.langDesc,
                  }}
                ></td>
              </tr>
            )}
            <tr>
              <td>
                <br />
              </td>
            </tr>
          </Fragment>
        );
      }

      // percent
      if (format === "#%" || format === "#.#%" || format === "#.##%") {
        return (
          <Fragment key={key}>
            <tr>
              <td>
                <b>{column.sectionName}:</b>
              </td>
              <td colSpan={3}>
                <NumberFormat
                  value={sectionValue}
                  displayType={"text"}
                  thousandSeparator
                  decimalScale={format === "#%" ? 0 : format === "#.#%" ? 1 : 2}
                  fixedDecimalScale
                  suffix="%"
                />
              </td>
            </tr>
            {column.langDesc && (
              <tr>
                <td></td>
                <td
                  colSpan={3}
                  dangerouslySetInnerHTML={{
                    __html: column.langDesc,
                  }}
                ></td>
              </tr>
            )}
            <tr>
              <td>
                <br />
              </td>
            </tr>
          </Fragment>
        );
      }

      // text
      if (format === "Text") {
        return (
          <Fragment key={key}>
            <tr>
              <td>
                <b>{column.sectionName}:</b>
              </td>
              <td colSpan={3}>{sectionValue}</td>
            </tr>
            {column.langDesc && (
              <tr>
                <td></td>
                <td
                  colSpan={3}
                  dangerouslySetInnerHTML={{
                    __html: column.langDesc,
                  }}
                ></td>
              </tr>
            )}
            <tr>
              <td>
                <br />
              </td>
            </tr>
          </Fragment>
        );
      }

      return null;
    },
    [deal, whoami, borrowerCompany]
  );

  if (!termsheet._id || !deal._id) {
    return <Row className="p-3"></Row>;
  }

  return (
    <Row className="TermsheetPreview py-2">
      <Col xs={12}>
        <div className="previewActions d-flex align-items-center color-purple mr-5">
          <span>Actions </span>
          <Dropdown className="d-inline-block">
            <Dropdown.Toggle as={CustomToggle}>
              <FontAwesomeIcon className="color-purple" icon={faChevronDown} />
            </Dropdown.Toggle>

            <Dropdown.Menu as={CustomMenu}>
              <Dropdown.Item onClick={handlePrint}>Print</Dropdown.Item>
              <Dropdown.Item onClick={handleDownloadDocx}>
                Download (.pdf)
              </Dropdown.Item>
              <Dropdown.Item onClick={handleDownloadDocx}>
                Download (.docx)
              </Dropdown.Item>
              <Dropdown.Item onClick={handleDownloadDocx}>
                Send Message
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Col>

      <Col xs={12}>
        <table
          className="table-termsheet-preview"
          style={{
            width: "100%",
            color: "#000",
            fontSize: 14,
            lineHeight: "28px",
          }}
          ref={tablePreviewRef}
        >
          <thead>
            <tr>
              <th style={{ width: "25%" }}></th>
              <th style={{ width: "25%" }}></th>
              <th style={{ width: "25%" }}></th>
              <th style={{ width: "25%" }}></th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td colSpan={4}>
                <p
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <LendersLogo company={termsheet.company} />
                  <span className="ml-2">{termsheet.company?.companyName}</span>
                </p>
              </td>
            </tr>

            <tr>
              <td>
                <br />
              </td>
            </tr>

            <tr>
              <td colSpan={4}>
                Issue Date: {moment(termsheet.createdAt).format("LL")}
              </td>
            </tr>

            <tr>
              <td>
                <br />
              </td>
            </tr>

            <tr>
              <td colSpan={4}>{borrowerCompany?.companyName}</td>
            </tr>
            <tr>
              <td colSpan={4}>{borrowerCompany?.companyAddress || "-"}</td>
            </tr>
            <tr>
              <td colSpan={4}>{borrowerCompany?.companyAddress2 || "-"}</td>
            </tr>
            <tr>
              <td colSpan={4}>
                {`${borrowerCompany?.companyCity}, ${borrowerCompany?.companyState}, ${borrowerCompany?.companyZipCode}`}
              </td>
            </tr>

            <tr>
              <td>
                <br />
              </td>
            </tr>

            <tr>
              <td colSpan={4}>
                RE: Financing for {deal?.propertyName} located at{" "}
                {`${deal?.propertyAddress}, ${deal?.propertyCity}, ${deal?.propertyState}, ${deal?.propertyZip}`}
              </td>
            </tr>

            <tr>
              <td>
                <br />
              </td>
            </tr>

            {termsheet.columns
              .filter((column) => isOpeningParagraph(column.clause))
              .map((column) => {
                // paragraph no header
                const key = column._id + column.sectionName;
                return renderColumn(column, {
                  key,
                  format: column.clause?.format,
                  sectionValue: column.sectionValue,
                  sectionYearsMonths: column.sectionYearsMonths,
                });
              })}

            {hasMatrix && (
              <>
                <tr>
                  <td colSpan={4} style={{ textAlign: "center" }}>
                    <b>Quote Matrix</b>
                  </td>
                </tr>

                <tr>
                  <td colSpan={4} className="td-table-matrix-wrapper">
                    <div
                      className="table-matrix-wrapper"
                      style={{
                        maxWidth: width ? width - width * 0.25 : undefined,
                      }}
                    >
                      <table>
                        <tbody>
                          {/* Loan Amount */}
                          <tr>
                            <th className="headcol">Loan Amount</th>
                            {termsheet.quotes?.map((quote) => (
                              <td key={quote._id} className="cell">
                                <NumberFormat
                                  value={quote.loanAmount}
                                  displayType={"text"}
                                  thousandSeparator
                                  prefix="$"
                                />
                              </td>
                            ))}
                          </tr>

                          {/* Term */}
                          <tr>
                            <th className="headcol">Term</th>
                            {termsheet.quotes?.map((quote) => (
                              <td key={quote._id} className="cell">
                                <NumberFormat
                                  value={quote.term}
                                  displayType={"text"}
                                  thousandSeparator
                                  suffix={` ${quote.termMetric}`}
                                />
                              </td>
                            ))}
                          </tr>

                          {/* Amortization */}
                          <tr>
                            <th className="headcol">Amortization</th>
                            {termsheet.quotes?.map((quote) => (
                              <td key={quote._id} className="cell">
                                <NumberFormat
                                  value={quote.amortization}
                                  displayType={"text"}
                                  thousandSeparator
                                  suffix={` ${quote.amortizationMetric}`}
                                />
                              </td>
                            ))}
                          </tr>

                          {/* IO Period */}
                          <tr>
                            <th className="headcol">IO Period</th>
                            {termsheet.quotes?.map((quote) => (
                              <td key={quote._id} className="cell">
                                <NumberFormat
                                  value={quote.IOPeriod}
                                  displayType={"text"}
                                  suffix={` ${quote.IOPeriodMetric}`}
                                  thousandSeparator
                                />
                              </td>
                            ))}
                          </tr>

                          {/* Spread */}
                          {isSpread && (
                            <tr>
                              <th className="headcol">Spread</th>
                              {termsheet.quotes?.map((quote) => (
                                <td key={quote._id} className="cell">
                                  {getSpreadTextValue(quote)}
                                </td>
                              ))}
                            </tr>
                          )}

                          {/* Rate */}
                          {!isSpread && (
                            <tr>
                              <th className="headcol">Rate</th>
                              {termsheet.quotes?.map((quote) => (
                                <td key={quote._id} className="cell">
                                  <NumberFormat
                                    value={quote.rate}
                                    displayType={"text"}
                                    thousandSeparator
                                    suffix={`%`}
                                    decimalScale={2}
                                    fixedDecimalScale
                                  />
                                </td>
                              ))}
                            </tr>
                          )}

                          {/* Floor */}
                          {isSpread && (
                            <tr>
                              <th className="headcol">Floor</th>
                              {termsheet.quotes?.map((quote) => (
                                <td key={quote._id} className="cell">
                                  {quote.floorOption === "No Floor" ? (
                                    quote.floorOption
                                  ) : (
                                    <NumberFormat
                                      value={quote.floorRate}
                                      displayType={"text"}
                                      thousandSeparator
                                      suffix={`% ${quote.floorOption}`}
                                      decimalScale={2}
                                      fixedDecimalScale
                                    />
                                  )}
                                </td>
                              ))}
                            </tr>
                          )}

                          {/* getIndexAsOfLabel */}
                          {isSpread && (
                            <tr>
                              <th className="headcol">{getIndexAsOfLabel()}</th>
                              {termsheet.quotes?.map((quote) => (
                                <td key={quote._id} className="cell">
                                  <NumberFormat
                                    value={quote.indexRate}
                                    displayType={"text"}
                                    thousandSeparator
                                    suffix={`%`}
                                    decimalScale={2}
                                    fixedDecimalScale
                                  />
                                </td>
                              ))}
                            </tr>
                          )}

                          {/* Quote Rate */}
                          {isSpread && (
                            <tr>
                              <th className="headcol">Quote Rate</th>
                              {termsheet.quotes?.map((quote) => (
                                <td key={quote._id} className="cell">
                                  <NumberFormat
                                    value={quote.quotedRate}
                                    displayType={"text"}
                                    thousandSeparator
                                    suffix={`%`}
                                    decimalScale={2}
                                    fixedDecimalScale
                                  />
                                </td>
                              ))}
                            </tr>
                          )}

                          {termsheet.addedClauses?.map((addedClause) => (
                            <tr key={addedClause._id}>
                              <th className="headcol">{addedClause.label}</th>
                              {termsheet.quotes?.map((quote) => {
                                return (
                                  <td key={quote._id} className="cell">
                                    {getAddedClauseValue(addedClause, quote)}
                                  </td>
                                );
                              })}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td>
                    <br />
                  </td>
                </tr>
              </>
            )}

            {!hasMatrix &&
              primaryClauses.map((primary) => {
                const key = primary.key;
                const valueKey = getPrimaryClauseValueKey(primary.label);
                const periodKey = valueKey + "Metric";
                const sectionValue = getNoMatrixSectionValue(
                  firstQuote,
                  valueKey
                );
                const sectionYearsMonths = firstQuote[periodKey] || "";
                const sectionName =
                  primary.label === "Index Rate"
                    ? getIndexAsOfLabel()
                    : primary.label;
                const foundColumn = termsheet.columns.find(
                  (col) =>
                    col.clause?.clauseName === valueKey ||
                    col.clause?.clauseName === primary.label
                );
                const langDesc = foundColumn?.langDesc || "";
                const column = { ...primary, sectionName, langDesc };
                const format = ["spread", "floorOptionValue"].includes(valueKey)
                  ? "Text"
                  : primary.clause?.format;

                return renderColumn(column, {
                  key,
                  format,
                  sectionValue,
                  sectionYearsMonths,
                });
              })}

            {termsheet.columns
              .filter((column) => !isOpeningParagraph(column.clause))
              .filter((column) => (hasMatrix ? true : !column.isPrimary))
              .map((column) => {
                const key =
                  column._id + column.sectionName + column.clause?.clauseName;

                // isPrimary column
                if (column.isPrimary) {
                  return (
                    <Fragment key={key}>
                      <tr>
                        <td>
                          <b>{column.sectionName}:</b>
                        </td>
                        <td
                          colSpan={3}
                          dangerouslySetInnerHTML={{
                            __html: column.langDesc,
                          }}
                        ></td>
                      </tr>
                      <tr>
                        <td>
                          <br />
                        </td>
                      </tr>
                    </Fragment>
                  );
                }

                return renderColumn(column, {
                  key,
                  format: column.clause?.format,
                  sectionValue: column.sectionValue,
                  sectionYearsMonths: column.sectionYearsMonths,
                });
              })}

            <tr>
              <td>
                <br />
              </td>
            </tr>

            <tr>
              <td colSpan={4}>Sincerely,</td>
            </tr>

            <tr>
              <td>
                <br />
              </td>
            </tr>
            <tr>
              <td>
                <br />
              </td>
            </tr>

            <tr>
              <td colSpan={4}>
                {`${teamLead?.firstName} ${teamLead?.lastName}`}
              </td>
            </tr>
            <tr>
              <td colSpan={4}>{teamLead?.title}</td>
            </tr>
            <tr>
              <td colSpan={4}>{termsheet.company?.companyName}</td>
            </tr>

            <tr>
              <td>
                <br />
              </td>
            </tr>

            <tr>
              <td colSpan={4}>Accepted by:</td>
            </tr>
            <tr>
              <td colSpan={4}>{borrowerCompany?.companyName}</td>
            </tr>
            <tr>
              <td colSpan={1} style={{ borderBottom: "1px solid lightgray" }}>
                <br />
              </td>
            </tr>
            <tr>
              <td colSpan={4}>by:</td>
            </tr>
            <tr>
              <td colSpan={4}>Its:</td>
            </tr>
          </tbody>
        </table>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state, props) => {
  const { columns } = props;
  const termsheet = columns
    ? { ...state.termsheet.termsheet, columns }
    : state.termsheet.termsheet;
  return {
    action: state.action,
    whoami: state.auth.whoami,
    myCompany: state.company.myCompany,
    company: state.company.company,
    teams: state.team.teams,
    termsheet: termsheet,
    deal: state.deal.deal,
  };
};

export default connect(mapStateToProps)(TermsheetPreview);
