import { faReply, faShare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Layout, Space, theme, Tooltip } from "antd";
import { ChatBubbleSvg } from "components/svgs/ChatBubbleSvg";
import React from "react";
import { Badge } from "react-bootstrap";
import { connect } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  createConversationMessage,
  deleteConversation,
  replyToMessage,
} from "redux/actions";
import queryString from "query-string";
import { InboxAttachments } from "../InboxAttachments";
import { InboxAvatar } from "../InboxAvatar";
import { InboxEditorSection } from "../InboxEditorSection";
import { formatDate, getSenderName } from "../inboxUtils";
import { MessageOptions } from "./MessageOptions";
import { conversationActions, conversationType } from "../enums";
import { uniq } from "lodash";
const { Content } = Layout;

const InboxMessageContent = ({ whoami, messageDetails }) => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const history = useHistory();
  const match = useRouteMatch();
  const dealId = match.params.id;
  const [showEditor, setShowEditor] = React.useState(false);
  const [isForward, setIsForward] = React.useState(false);
  const {
    _senderId,
    _senderCompanyId,
    _receiverCompanyIds,
    _labels,
    messages,
  } = messageDetails;
  const [firstMessage, ...replies] = messages;
  const [sender] = _senderId;
  const [senderCompany] = _senderCompanyId;
  const senderName = getSenderName(sender, senderCompany);
  const receiverName =
    sender._id === whoami._id
      ? _receiverCompanyIds.map((e) => e.companyName).join(", ")
      : "Me";
  const labels = _labels.map(({ label }) => label);

  const searchObject = queryString.parse(history.location.search);

  const toggleShowEditor = () => {
    setShowEditor((prev) => !prev);
  };

  const handleSubmitEditor = (values) => {
    const params = {
      conversationId: messageDetails._id,
      messageId: firstMessage._id,
    };
    const formData = new FormData();
    formData.append("message", values.message);
    formData.append("isPrivate", values.isPrivate);
    if (values.files) {
      values.files.forEach((file) => {
        formData.append("files", file.originFileObj);
      });
    }
    if (isForward) {
      formData.append("subject", "Forwarded");
      formData.append("dealId", dealId);
      values.receiverCompanyIds.forEach((receiverCompanyId) => {
        formData.append("receiverCompanyIds", receiverCompanyId);
      });
      createConversationMessage(formData);
    } else {
      formData.append("subject", "Reply");
      const receiverCompanyIds = uniq([
        ...messageDetails.receiverCompanyIds,
        messageDetails._senderCompanyId[0]._id,
      ]);
      receiverCompanyIds.forEach((companyId) => {
        formData.append("receiverCompanyIds", companyId);
      });
      replyToMessage(params, formData);
    }
    setShowEditor(false);
  };

  const handleOptionAction = (option) => {
    switch (option) {
      case conversationActions.reply:
        setShowEditor(true);
        setIsForward(false);
        break;
      case conversationActions.forward:
        setShowEditor(true);
        setIsForward(true);
        break;
      case conversationActions.delete:
        deleteConversation(messageDetails._id);
        break;
      default:
        break;
    }
  };

  return (
    <Card style={{ padding: 16 }} bodyStyle={{ width: "100%", padding: 0 }}>
      <Content style={{ margin: 0, background: colorBgContainer }}>
        <section className="d-flex flex-column gap-20">
          <div className="d-flex align-items-center justify-content-between">
            <p className="font-18">
              {firstMessage.subject}{" "}
              <Badge className="text-capitalize ml-4" variant="primary">
                {searchObject.status === "inbox" &&
                messageDetails.status === "Archived"
                  ? "Archived"
                  : searchObject.status}
              </Badge>
              {searchObject.status === "sent" &&
                messageDetails.status === "Archived" && (
                  <Badge className="text-capitalize ml-1" variant="primary">
                    {messageDetails.status}
                  </Badge>
                )}
            </p>
            <div className="d-flex align-items-start gap-16">
              <p>{formatDate(firstMessage.createdAt, "ll, LT")}</p>
              <p>
                <Tooltip title="Reply">
                  <FontAwesomeIcon
                    className="mr-1 cursor-pointer"
                    icon={faReply}
                    onClick={() => setShowEditor(true)}
                  />
                </Tooltip>
              </p>
              <p>
                <MessageOptions showDelete onOptionClick={handleOptionAction} />
              </p>
            </div>
          </div>

          <div className="d-flex flex-column gap-16">
            <InboxAvatar
              name={senderName}
              subtitle={`To: ${receiverName}`}
              imageUrl={sender.profileImageXS}
            />
            <div className="d-flex align-items-center gap-10">
              {labels.map((label) => (
                <span
                  key={label}
                  className="font-12 line-height-none border border-secondary p-2"
                >
                  {label}
                </span>
              ))}
            </div>
          </div>

          <div
            dangerouslySetInnerHTML={{
              __html: firstMessage.message,
            }}
          />

          <InboxAttachments attachments={firstMessage.attachments} />

          {showEditor && (
            <InboxEditorSection
              type={conversationType.Message}
              isForward={isForward}
              forwardMessage={firstMessage}
              onCancel={toggleShowEditor}
              onSubmit={handleSubmitEditor}
            />
          )}

          {!showEditor && (
            <div className="d-flex align-items-center justify-content-between">
              {replies.length > 0 && (
                <div className="d-flex align-items-center">
                  <ChatBubbleSvg className="mr-2" />
                  <p>{replies.length} messages</p>
                </div>
              )}
              <Space wrap>
                <Button
                  ghost
                  type="primary"
                  icon={<FontAwesomeIcon className="mr-2" icon={faReply} />}
                  onClick={() => {
                    handleOptionAction(conversationActions.reply);
                  }}
                >
                  Reply
                </Button>
                <Button
                  ghost
                  type="primary"
                  icon={<FontAwesomeIcon className="mr-2" icon={faShare} />}
                  onClick={() => {
                    handleOptionAction(conversationActions.forward);
                  }}
                >
                  Forward
                </Button>
              </Space>
            </div>
          )}
        </section>
      </Content>
    </Card>
  );
};

function mapStateToProps(state) {
  return {
    whoami: state.auth.whoami,
    messageDetails: state.conversation.messageDetails,
  };
}

export default connect(mapStateToProps)(InboxMessageContent);
