import moment from "moment";
import { conversationStatuses } from "./enums";

export const normalizeFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};

export const formatRelativeDate = (date) =>
  moment(date).calendar(null, {
    sameDay: "LT",
    nextDay: "[Tomorrow at] LT",
    nextWeek: "dddd",
    lastDay: "[Yesterday at] LT",
    lastWeek: "[Last] dddd",
    sameElse: "DD/MM/YYYY",
  });

export const formatDate = (date, format = "LL") => moment(date).format(format);

export const getSenderName = (sender, senderCompany) => {
  if (!senderCompany || !sender) return "";
  return `${senderCompany.companyName} <${sender.firstName} ${sender.lastName}>`;
};

export const getReceiverNames = (
  _receiverCompanyIds,
  senderCompany,
  status,
  whoami,
  myCompany
) => {
  if (whoami.role === "Lender" && status === conversationStatuses.inbox)
    return "Me";
  let names = _receiverCompanyIds
    .filter((com) => com._id !== senderCompany._id)
    .map((com) => (com._id === myCompany._id ? "Me" : com.companyName))
    .join(", ");
  return names;
};

export const createTreeData = (
  labelsTreeView = [],
  valueKey = "key",
  path = []
) => {
  const treeData = labelsTreeView.map((node) => {
    const { label, _id } = node;
    const newPath = [...path, _id];
    return {
      [valueKey]: _id,
      title: label,
      path: newPath,
      children: createTreeData(node.children, valueKey, newPath),
    };
  });
  return treeData;
};

export const getParentKey = (key, tree) => {
  let parentKey;
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.children) {
      if (node.children.some((item) => item.key === key)) {
        parentKey = node.key;
      } else if (getParentKey(key, node.children)) {
        parentKey = getParentKey(key, node.children);
      }
    }
  }
  return parentKey;
};

export const getNodeChildren = (key, tree) => {
  let children = [];
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.children) {
      if (node.key === key) {
        children = node.children;
      } else if (getParentKey(key, node.children)) {
        children = getNodeChildren(key, node.children);
      }
    }
  }
  return children;
};

export const flattenTree = (tree) => {
  let flat = [];
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    flat.push(node);
    if (node.children) {
      flat = [...flat, ...flattenTree(node.children)];
    }
  }
  return flat;
};

export const getNodeChildrenFlat = (key, tree) => {
  const children = getNodeChildren(key, tree);
  return flattenTree(children);
};

export const getInitialForwardMessage = (forwardMessage) => {
  const [sender] = forwardMessage._senderId;
  const [senderCompany] = forwardMessage._senderCompanyId;
  return `<p><em>
    ---------- Forwarded message --------- <br/>
    From: ${getSenderName(sender, senderCompany)} <${sender.email}> <br/>
    Date: ${formatDate(forwardMessage.createdAt, "ll, LT")} <br/>
    Subject: ${forwardMessage.subject} </em></p>
    ${forwardMessage.message}
  `;
};

// Only Read
export const getConversationsReadByMe = (conversations, whoami) => {
  return conversations.filter(({ readBy = [] }) => {
    return readBy.includes(whoami._id);
  });
};

// Responded only by Me
export const getConversationsRespondedByMe = (conversations, whoami) => {
  return conversations.filter(({ messages = [] }) => {
    if (messages.length <= 1) return false;
    return messages.every((msg) => msg.senderId === whoami._id);
  });
};

// Responded by Me or Team
export const getConversationsRespondedByTeam = (conversations) => {
  return conversations.filter(({ messages = [] }) => {
    if (messages.length <= 1) return false;
    return true;
  });
};
