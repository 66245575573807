import { injectPrimaryClause } from "containers/quotes/quotesConfig";
import { actionTypes } from "redux/actions";
import {
  genericReducerCreator,
  multipleReducers,
  reducerCreator,
} from "./meta";

export const termsheet = multipleReducers({
  initializers: {
    initialState: {
      sections: [],
      languages: [],
      quotes: [],
      quote: null,
      termsheet: {},
      termsheets: [],
      adminDefaultTermsheet: {},
      manageTermsheets: {
        count: 0,
        limit: 10,
        rows: [],
      },
      lastTermsheetCreated: null,
      lastTermsheetUpdated: null,
      lastTermsheetDeleted: null,
      lastQuoteArchived: null,
      lastQuoteStarred: null,
      lastQuoteBulkArchived: null,
      lastQuoteBulkStarred: null,
      compareQuotes: [],
      compareQuotesAll: [],
    },
  },
  list: [
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.GET_MANAGE_TERMSHEETS,
        successFn: (action) => ({
          manageTermsheets: action.data.body,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.CREATE_TERMSHEET,
        successFn: (action) => ({
          lastTermsheetCreated: action.data,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.UPDATE_TERMSHEET,
        successFn: (action) => ({
          lastTermsheetUpdated: action.data,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.DELETE_TERMSHEET,
        successFn: (action) => ({
          lastTermsheetDeleted: action.data,
        }),
      },
    },
    {
      reducerCreatorFn: genericReducerCreator,
      params: {
        type: actionTypes.STORE_SECTIONS,
        successFn: (action, state) => ({
          termsheet: {
            ...state.termsheet,
            columns: injectPrimaryClause(action.data),
          },
        }),
      },
    },
    {
      reducerCreatorFn: genericReducerCreator,
      params: {
        type: actionTypes.STORE_COMPARE_QUOTES,
        successFn: (action, state) => ({
          compareQuotes: action.data,
          compareQuotesAll: [...state.quotes],
        }),
      },
    },
    {
      reducerCreatorFn: genericReducerCreator,
      params: {
        type: actionTypes.STORE_QUOTE_MATRICES,
        successFn: (action, state) => ({
          termsheet: {
            ...state.termsheet,
            addedClauses: action.data,
          },
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.GET_TERMSHEET_LANGUAGE,
        successFn: (action) => ({
          languages: action.data.body,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.GET_QUOTES,
        successFn: (action) => ({
          quotes: action.data.body,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.GET_QUOTE,
        successFn: (action) => ({
          quote: action.data.body,
        }),
      },
    },
    {
      reducerCreatorFn: genericReducerCreator,
      params: {
        type: actionTypes.GET_QUOTE,
        successFn: () => ({
          quote: null,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.GET_TERMSHEET,
        successFn: (action) => ({
          termsheet: {
            ...action.data.body,
            columns: injectPrimaryClause(
              action.data.body.columns.sort((a, b) =>
                a.order < b.order ? 1 : 0
              )
            ),
          },
        }),
      },
    },
    {
      reducerCreatorFn: genericReducerCreator,
      params: {
        type: actionTypes.CLEAR_TERMSHEET,
        successFn: () => ({
          termsheet: {},
        }),
      },
    },
    {
      reducerCreatorFn: genericReducerCreator,
      params: {
        type: actionTypes.CLEAR_QUOTES,
        successFn: () => ({
          quotes: [],
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.GET_TERMSHEETS,
        successFn: (action) => ({
          termsheets: action.data.body.rows,
          termsheetsTotalCount: action.data.body.count,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.GET_ADMIN_DEFAULT_TERMSHEET,
        successFn: (action) => ({
          adminDefaultTermsheet: action.data.body,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.ARCHIVE_QUOTE,
        successFn: (action) => ({
          lastQuoteArchived: action.data.body,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.STAR_QUOTE,
        successFn: (action) => ({
          lastQuoteStarred: action.data.body,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.BULK_ARCHIVE_QUOTE,
        successFn: (action) => ({
          lastQuoteBulkArchived: action.data.body,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.BULK_STAR_QUOTE,
        successFn: (action) => ({
          lastQuoteBulkStarred: action.data.body,
        }),
      },
    },
  ],
});
