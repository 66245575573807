import React from "react";
import ContentLoader from "react-content-loader";

const HeadBodyGrid = (props) => (
  <ContentLoader height="500" width="600" viewBox="0 0 265 230" {...props}>
    <rect x="15" y="15" rx="4" ry="4" width="350" height="25" />
    <rect x="15" y="50" rx="2" ry="2" width="350" height="150" />
    <rect x="15" y="230" rx="2" ry="2" width="170" height="20" />
    <rect x="60" y="230" rx="2" ry="2" width="170" height="20" />
  </ContentLoader>
);

HeadBodyGrid.metadata = {
  name: "Didier Munezero",
  github: "didiermunezero",
  description: "Grid for content of head and body",
  filename: "HeadBodyGrid",
};

const AuthorsList = (props) => (
  <ContentLoader height="400" width="400" viewBox="0 0 265 230" {...props}>
    <rect x="15" y="15" rx="4" ry="4" width="350" height="25" />
    <rect x="15" y="50" rx="2" ry="2" width="350" height="150" />
    <rect x="15" y="230" rx="2" ry="2" width="170" height="20" />
    <rect x="60" y="230" rx="2" ry="2" width="170" height="20" />
  </ContentLoader>
);

AuthorsList.metadata = {
  name: "BYIRINGIRO Emmanuel", // My name
  github: "emmbyiringiro", // Github username
  description: " Authors/posts list style ", // Loader description
  filename: "AuthorsList", // filename of your loader
};

export const DashboardSkeleton = () => {
  return (
    <>
      {[1, 2, 3, 4].map((i) => (
        <React.Fragment key={i}>
          <HeadBodyGrid /> <br />
        </React.Fragment>
      ))}
    </>
  );
};

export const InboxSkeleton = () => {
  return (
    <>
      {[1, 2, 3, 4].map(() => (
        <>
          <HeadBodyGrid /> <br />
        </>
      ))}
    </>
  );
};

export const ChatSkeleton = () => {
  return <AuthorsList />;
};
const SKELETONS = {
  DASHBOARD: DashboardSkeleton,
};
export default SKELETONS;
