import React from "react";
import { Col, Row, Tooltip } from "antd";
import Spacer from "components/layout/Spacer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { dealViewIds } from "../DealViewContext";
import useCardHidden from "../hooks/useCardHidden";
import { useSelector } from "react-redux";
import NumberFormat from "react-number-format";

const ManagerInformation = ({ deal }) => {
  const {
    managementCompanyName,
    propertySizeType,
    managerSizeApartments,
    managerSizeOffice,
    managerSizeMedicalOffice,
    managerSizeIndustrial,
    managerSizeRetail,
    managerSizeHotel,
    managerSizeSeniorLiving,
    managementCompanyAffiliate,
    managementCompanyWebsite,
    managementCompanyBio,
    cardHidden = [],
  } = deal;

  const whoami = useSelector((state) => state.auth.whoami);
  const { isHidden, toggleHidden } = useCardHidden({
    dealId: deal._id,
    cardHidden,
    cardId: dealViewIds.ManagerInformation,
  });

  if (whoami.role !== "Admin" && isHidden) return null;

  if (!managementCompanyName) return null;

  return (
    <section className={isHidden ? "tw-bg-gray-200" : ""}>
      <div className="tw-flex tw-space-x-3 tw-items-center">
        <h4>Manager Information</h4>
        {whoami.role === "Admin" && (
          <span className="px-2 cursor-pointer">
            <Tooltip
              title={isHidden ? "Show this section" : "Hide this section"}
            >
              <FontAwesomeIcon
                icon={isHidden ? faEyeSlash : faEye}
                className="tw-text-gray-500"
                onClick={toggleHidden}
              />
            </Tooltip>
          </span>
        )}
      </div>
      <Spacer size={5} />
      <div className="border-bottom"></div>
      <Spacer size={20} />

      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <p className="bold">{managementCompanyName}</p>
          <p className="font-12 color-medium-gray">Manager Name</p>
        </Col>
        {managerSizeApartments && (
          <Col xs={12}>
            <p className="bold">
              <NumberFormat
                value={managerSizeApartments}
                displayType={"text"}
                thousandSeparator={true}
                suffix={" sq ft."}
              />
            </p>
            <p className="font-12 color-medium-gray">
              {propertySizeType} of Apartments
            </p>
          </Col>
        )}
        {managerSizeOffice && (
          <Col xs={12}>
            <p className="bold">
              <NumberFormat
                value={managerSizeOffice}
                displayType={"text"}
                thousandSeparator={true}
                suffix={" sq ft."}
              />
            </p>
            <p className="font-12 color-medium-gray">
              {propertySizeType} of Office
            </p>
          </Col>
        )}
        {managerSizeMedicalOffice && (
          <Col xs={12}>
            <p className="bold">
              <NumberFormat
                value={managerSizeMedicalOffice}
                displayType={"text"}
                thousandSeparator={true}
                suffix={" sq ft."}
              />
            </p>
            <p className="font-12 color-medium-gray">
              {propertySizeType} of Medical Office
            </p>
          </Col>
        )}
        {managerSizeIndustrial && (
          <Col xs={12}>
            <p className="bold">
              <NumberFormat
                value={managerSizeIndustrial}
                displayType={"text"}
                thousandSeparator={true}
                suffix={" sq ft."}
              />
            </p>
            <p className="font-12 color-medium-gray">
              {propertySizeType} of Industrial Area
            </p>
          </Col>
        )}
        {managerSizeRetail && (
          <Col xs={12}>
            <p className="bold">
              <NumberFormat
                value={managerSizeRetail}
                displayType={"text"}
                thousandSeparator={true}
                suffix={" sq ft."}
              />
            </p>
            <p className="font-12 color-medium-gray">
              {propertySizeType} of Retail Area
            </p>
          </Col>
        )}
        {managerSizeHotel && (
          <Col xs={12}>
            <p className="bold">
              <NumberFormat
                value={managerSizeHotel}
                displayType={"text"}
                thousandSeparator={true}
                suffix={" sq ft."}
              />
            </p>
            <p className="font-12 color-medium-gray">
              {propertySizeType} in Hotel Area
            </p>
          </Col>
        )}
        {managerSizeSeniorLiving && (
          <Col xs={12}>
            <p className="bold">
              <NumberFormat
                value={managerSizeSeniorLiving}
                displayType={"text"}
                thousandSeparator={true}
                suffix={" sq ft."}
              />
            </p>
            <p className="font-12 color-medium-gray">
              {propertySizeType} in Senior Living Area
            </p>
          </Col>
        )}
      </Row>

      <Spacer size={10} />
      <div className="border-bottom"></div>
      <Spacer size={10} />

      <Row gutter={[16, 16]}>
        {managementCompanyAffiliate && (
          <Col xs={12}>
            <p className="bold">{managementCompanyAffiliate}</p>
            <p className="font-12 color-medium-gray">
              Management Company an Affiliate of Sponsor?
            </p>
          </Col>
        )}
        {managementCompanyWebsite && (
          <Col xs={12}>
            <a
              href={managementCompanyWebsite}
              className="bold"
              rel="noopener noreferrer"
              target="_blank"
            >
              {managementCompanyWebsite}
            </a>
            <p className="font-12 color-medium-gray">
              Management Company Wesbite
            </p>
          </Col>
        )}
        {managementCompanyBio && (
          <Col xs={24}>
            <p className="bold">Manager Bio</p>
            <p className="font-12 color-medium-gray">{managementCompanyBio}</p>
          </Col>
        )}
      </Row>

      {/* <Row gutter={[16, 16]}>
        <Col xs={24}>
          <p className="bold">{managementCompanyName}</p>
          <p className="font-12 color-medium-gray">Manager Name</p>
        </Col>
        <Col xs={24} md={12}>
          <p className="bold">{netWorthKeyPrincipals || "-"}</p>
          <p className="font-12 color-medium-gray">Net Worth Key Principals</p>
        </Col>
        <Col xs={24} md={12}>
          <p className="bold">{liquidityOfKeyPrincipals || "-"}</p>
          <p className="font-12 color-medium-gray">Liquidity Key Principals</p>
        </Col>
        <Col xs={24}>
          <p className="bold">Key Principals Bios</p>
          <p className="font-12 color-medium-gray">{keyPrincipleBios || "-"}</p>
        </Col>
      </Row> */}
    </section>
  );
};

export default ManagerInformation;
