import React from "react";
import { connect } from "react-redux";
import { Accordion, Button, Row, Col, Table } from "react-bootstrap";
import { deleteTag, getManageTags } from "redux/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import PopupPrompt from "components/ui/PopupPrompt";
import Paginate from "components/ui/Paginate";
import Loader from "components/ui/Loader";
import moment from "moment";
import Socket from "utils/Socket";
import CreateNewTag from "./CreateEditTag";
import MergeTags from "./MergeTags";

class ManageTags extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      promptType: "",
      showPrompt: false,
      filters: {},
      companyIdToView: "",
      userIdToView: "",
      userToAddToCompany: {},
      tagSelected: null,
      pagination: {
        page: 1,
        listOffset: 10,
      },
      sort: {
        direction: "ASC",
        column: "tag",
      },
      selectedTags: [],
    };
  }

  componentDidMount() {
    this.fetchTags();

    this.subscriptionId = Socket.subscribe(
      "/admin/tags",
      this.handleSocketResponse
    );
  }

  handleSocketResponse = (data) => {};

  componentWillUnmount() {
    Socket.unsubscribe(this.subscriptionId);
  }

  componentDidUpdate(prevProps) {
    // if (this.props.manageTags.search !== prevProps.manageTags.search) {
    //   this.setState((prevState) => ({
    //     pagination: { ...prevState.pagination, page: 1 },
    //   }));
    // }
    if (
      this.props.lastTagCreated !== prevProps.lastTagCreated ||
      this.props.lastTagUpdated !== prevProps.lastTagUpdated ||
      this.props.lastTagDeleted !== prevProps.lastTagDeleted ||
      this.props.lastMerged !== prevProps.lastMerged
    ) {
      this.setState({ selectedTags: [] });
      this.handleModalClose();
      this.fetchTags();
    }
    if (
      this.props.action.CREATE_TAG.isFetched !==
        prevProps.action.CREATE_TAG.isFetched &&
      this.props.action.CREATE_TAG.isFetched
    ) {
      this.handleModalClose();
    }
  }

  fetchTags = () => {
    const { pagination, sort } = this.state;
    const { manageTags } = this.props;
    getManageTags({
      page: pagination.page,
      limit: pagination.listOffset,
      search: manageTags.search,
      order: sort.direction,
      orderColumn: sort.column,
    });
  };

  handlePagination = (page) => {
    this.setState(
      (prevState) => ({ pagination: { ...prevState.pagination, page } }),
      this.fetchTags
    );
  };

  handleModalClose = () =>
    this.setState({
      showPrompt: false,
      tagSelected: null,
      promptType: "",
    });

  handleSortChange = (column) => {
    this.setState(
      (prevState) => ({
        sort: {
          column: column,
          direction: prevState.sort.direction === "ASC" ? "DESC" : "ASC",
        },
      }),
      this.fetchTags
    );
  };

  handleModelOpen = (promptType, tagSelected) => {
    this.setState({
      showPrompt: true,
      promptType,
      tagSelected,
    });
  };

  handleDelete = () => {
    deleteTag(this.state.tagSelected._id);
  };

  handleSelectTag = (tag) => {
    this.setState((prevState) => {
      const found = prevState.selectedTags.find((item) => item._id === tag._id);
      let selectedTags;
      if (found) {
        selectedTags = prevState.selectedTags.filter(
          (item) => item._id !== tag._id
        );
      } else {
        selectedTags = [...prevState.selectedTags, tag];
      }
      return {
        selectedTags,
      };
    });
  };

  render() {
    const { action, manageTags } = this.props;

    return (
      <>
        {action.GET_MANAGE_TAGS.isFetching && <Loader />}
        <Row className="mb-3 pt-3" noGutters={true}>
          <Col className="d-flex" xs="12">
            <div style={{ flex: 1 }} className="text-right">
              <Button
                disabled={action.MERGE_TAGS.isFetching}
                className="pt-2 pb-2 mr-2"
                variant="primary"
                onClick={() => {
                  this.handleModelOpen("merge");
                }}
              >
                {action.MERGE_TAGS.isFetching
                  ? "Merging Tags..."
                  : "Merge Tags"}
              </Button>
              <Button
                className="pt-2 pb-2"
                variant="primary"
                onClick={() => {
                  this.handleModelOpen("create");
                }}
              >
                Create Tag
              </Button>
            </div>
          </Col>
        </Row>
        <Row>
          <Accordion className="w-100">
            <Col>
              <Table bordered hover>
                <thead>
                  <tr>
                    <th style={{ width: 50 }}></th>
                    <th style={{ width: 50 }}>#</th>
                    <th
                      className="cursor-pointer"
                      onClick={() => this.handleSortChange("tag")}
                    >
                      Tag{" "}
                      {this.state.sort.column === "tag" && (
                        <FontAwesomeIcon
                          className="font-14 ml-1"
                          icon={
                            this.state.sort.direction === "ASC"
                              ? faSortUp
                              : faSortDown
                          }
                        />
                      )}
                    </th>
                    <th
                      className="cursor-pointer"
                      onClick={() => this.handleSortChange("updatedAt")}
                    >
                      Last Updated{" "}
                      {this.state.sort.column === "updatedAt" && (
                        <FontAwesomeIcon
                          className="font-14 ml-1"
                          icon={
                            this.state.sort.direction === "ASC"
                              ? faSortUp
                              : faSortDown
                          }
                        />
                      )}
                    </th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {manageTags.rows.map((row, index) => (
                    <tr key={row._id}>
                      <td className="text-center">
                        <input
                          type="checkbox"
                          className="cursor-pointer"
                          checked={this.state.selectedTags.some(
                            (item) => item._id === row._id
                          )}
                          onChange={() => this.handleSelectTag(row)}
                        />
                      </td>
                      <td>
                        {(this.state.pagination.page - 1) *
                          this.state.pagination.listOffset +
                          (index + 1)}
                      </td>
                      <td>{row.tag}</td>
                      <td>
                        {moment(row.updatedAt).format("MMMM Do YYYY, h:mm a")}
                      </td>
                      <td>
                        <div>
                          <Button
                            variant="link"
                            size="sm"
                            className="p-1"
                            onClick={() => this.handleModelOpen("edit", row)}
                          >
                            Edit
                          </Button>
                          <Button
                            variant="link"
                            size="sm"
                            className="p-1"
                            onClick={() => this.handleModelOpen("delete", row)}
                          >
                            Delete
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>

            {!action.GET_MANAGE_TAGS.isFetching &&
              manageTags.rows.length === 0 && (
                <div className="text-center">
                  <p>There are currently no tags to view here.</p>
                </div>
              )}
          </Accordion>
          <Paginate
            {...this.state.pagination}
            list={manageTags.rows}
            listTotalCount={manageTags.count}
            previousPage={this.handlePagination}
            nextPage={this.handlePagination}
          />
          {this.state.promptType === "delete" && (
            <PopupPrompt
              promptTitle={"CONFIRM DELETE"}
              promptBody={`By clicking confirm, you agree to delete "${this.state.tagSelected?.tag}" tag.`}
              show={this.state.showPrompt}
              onCancel={this.handleModalClose}
              onConfirm={this.handleDelete}
              isLoading={action.DELETE_TAG.isFetching}
              withFooter
              withCancel
            />
          )}
          {(this.state.promptType === "create" ||
            this.state.promptType === "edit") && (
            <PopupPrompt
              promptTitle={`${
                this.state.promptType === "create" ? "Create" : "Edit"
              } new tag`}
              promptBody={
                <CreateNewTag
                  promptType={this.state.promptType}
                  tagSelected={this.state.tagSelected}
                  onCancel={this.handleModalClose}
                />
              }
              show={this.state.showPrompt}
              onCancel={this.handleModalClose}
              onHide={this.handleModalClose}
            />
          )}
          {this.state.promptType === "merge" && (
            <PopupPrompt
              promptTitle="Merge Tags"
              promptBody={
                <MergeTags
                  promptType={this.state.promptType}
                  selectedTags={this.state.selectedTags}
                  onCancel={this.handleModalClose}
                />
              }
              show={this.state.showPrompt}
              onCancel={this.handleModalClose}
              onHide={this.handleModalClose}
            />
          )}
        </Row>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    action: state.action,
    teams: state.team.teams,
    manageTags: state.tag.manageTags,
    lastTagCreated: state.tag.lastTagCreated,
    lastTagUpdated: state.tag.lastTagUpdated,
    lastTagDeleted: state.tag.lastTagDeleted,
    lastMerged: state.tag.lastMerged,
  };
}

export default connect(mapStateToProps)(ManageTags);
