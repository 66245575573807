import React from "react";
import { connect } from "react-redux";
import { Button, Form, Container, Row, Col } from "react-bootstrap";
import { Field, reduxForm } from "redux-form";
import { required, email, noSpaces } from "utils/validation";
import { reduxFormInput } from "components/form/ReduxForm";
import { forgotPassword } from "redux/actions";
import Spacer from "components/layout/Spacer";
import Loader from "components/ui/Loader";
import TSLogo from "components/ui/TSLogo";
import withPageTitle from "components/hoc/withPageTitle";

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);

    this.submitFn = this.submitFn.bind(this);
  }

  submitFn(data) {
    forgotPassword(data);
  }

  render() {
    const { handleSubmit, action } = this.props;
    const { isFetching, isFetched } = action.FORGOT_PASSWORD;

    return (
      <>
        {isFetching && <Loader />}
        <header className="border-bottom">
          <Spacer size={23} />
          <h1 className="text-center">Forgot Password</h1>
          <Spacer size={23} />
        </header>
        <Container>
          <Spacer size={50} />
          <Form className="auth-form" onSubmit={handleSubmit(this.submitFn)}>
            <Row className="justify-content-center">
              <Col className="d-flex justify-content-center" xs="6">
                <TSLogo blue title />
              </Col>
            </Row>
            {!isFetched ? (
              <>
                <Row className="justify-content-center">
                  <Col xs="12">
                    <Spacer size={50} />
                  </Col>
                  <Col xs="12">
                    <p className="text-center">
                      Enter your email and if an account is linked with the
                      email, we’ll send you a reset password link.
                    </p>
                    <Spacer size={50} />
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Col xs="12">
                    <Field
                      className="form-control"
                      component={reduxFormInput}
                      validate={[required, email, noSpaces]}
                      name="email"
                      type="email"
                      placeholder="Enter email"
                      label="*Email"
                    />
                  </Col>
                </Row>
                <Spacer size={50} />
                <Row className="justify-content-center">
                  <Col className="text-center" xs="12" sm="6">
                    <Button type="submit" variant="primary">
                      Submit
                    </Button>
                  </Col>
                </Row>
              </>
            ) : (
              <div className="text-center">
                An email to reset your password has been sent to the input
                email.
              </div>
            )}
          </Form>
          <Spacer size={50} />
        </Container>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    action: state.action,
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "ForgotPassword",
    enableReinitialize: true,
  })(withPageTitle(ForgotPassword, "Forgot Password"))
);
