import React, { createRef } from "react";
import { connect } from "react-redux";
import { Form, Row, Col, Button } from "react-bootstrap";
import { Field, reduxForm, reset } from "redux-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";

import {
    reduxFormSelect, reduxFormSearch
} from "components/form/ReduxForm";
import Spacer from "components/layout/Spacer";
import { getAllCompaniesBySearch } from "redux/actions";
const accountStatuses = [
    "ACTIVE",
    "ACTIVE Not Assigned to Company",
    "UNCONFIRMED",
    "INACTIVE",
    "SUSPENDED",
    "DELETED"
]
class FilterUsers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchInput: this.props.filters.company?.companyName || "",
            selectedCompany: this.props.filters.company || {}
        };
        this.searchResults = createRef();
    }
    resetFilters = () => {
        this.props.dispatch(reset("FilterUsers"));
        this.props.handleFilters({})
    }

    submitFn = (data) => {
        this.props.handleFilters({ ...data, company: this.state.selectedCompany })
    }

    handleSearchChange = (e) => {
        this.setState({ searchInput: e.target.value, selectedCompany: {} })
    }

    handleSeachCancel = () => {
        this.setState({ searchInput: "", selectedCompany: {} })
    }

    handleSearch = () => {
        getAllCompaniesBySearch({
            search: this.state.searchInput
        })
    }

    handleSelectCompany = (company) => {
        this.setState({ searchInput: company.companyName, selectedCompany: company })
        this.searchResults.current.style.display = "none";
    }

    handleSearchBlur = (e) => {
        if (
            e.relatedTarget !==
            this.searchResults.current.target
        ) {
            this.searchResults.current.style.display = "none";
        }
    }

    handleSearchFocus = () => {
        this.searchResults.current.style.display = "block";
    }

    render() {
        const { handleSubmit, searchCompanies, action } = this.props;

        return (
            <>
                <Form
                    className="no-padding no-border"
                    onSubmit={handleSubmit(this.submitFn)}
                >
                    <Row>
                        <Col xs="12" sm="12">
                            <Field
                                className="form-control"
                                component={reduxFormSelect}
                                name="role"
                                type="select"
                                options={[
                                    { label: "Lender", value: "Lender" },
                                    { label: "Borrower", value: "Borrower" },
                                    { label: "Admin", value: "Admin" },
                                ]}
                                placeholder="Select..."
                                label="Role"
                            />
                            <Spacer size={20} />
                        </Col>
                        <Col xs="12" sm="12">
                            <Field
                                className="form-control"
                                component={reduxFormSelect}
                                name="status"
                                type="select"
                                options={accountStatuses.map((status) => ({ label: status, value: status }))}
                                placeholder="Select..."
                                label="Account Status"
                            />
                            <Spacer size={20} />
                        </Col>
                        <Col xs="12" sm="12">
                            <Field
                                className="form-control"
                                component={reduxFormSelect}
                                name="accountActivate"
                                type="select"
                                options={[
                                    { label: "Yes", value: "true" },
                                    { label: "No", value: "false" },
                                ]}
                                placeholder="Select..."
                                label="Is account activated"
                            />
                            <Spacer size={20} />
                        </Col>
                        <Col xs="12" sm="12" >
                            <Field
                                className="form-control"
                                component={reduxFormSearch}
                                name="search"
                                label="Company"
                                type="text"
                                placeholder="Search company..."
                                input={{
                                    onChange: this.handleSearchChange,
                                    onFocus: this.handleSearchFocus
                                }}
                                inputValue={this.state.searchInput}
                                onKeyDown={(e) => {
                                    if (e.keyCode === 13) {
                                        e.preventDefault();
                                        this.handleSearch();
                                    }
                                }}
                            />
                            {(this.state.searchInput.length > 0) && (
                                <Button
                                    className="clear-icon color-medium-gray"
                                    type="submit"
                                    variant=""
                                    onClick={this.handleSeachCancel}
                                >
                                    <FontAwesomeIcon className="search-icon" icon={faTimes} />
                                </Button>
                            )}
                            <Button className="search-icon" type="submit" variant="" style={{ right: 15 }}>
                                <FontAwesomeIcon className="search-icon" icon={faSearch} />
                            </Button>
                            <div
                                className="search-results-wrapper"
                                ref={this.searchResults}
                                style={{ width: "94%", display: "none" }}
                            >
                                <div
                                    className="search-results"
                                // onBlur={(e) => this.handleOptionBlur(e)}
                                // onKeyDown={(e) => this.handleOptionKeyDown(e)}
                                >
                                    {action.GET_ALL_COMPANIES_BY_SEARCH.isFetching && <p> Searching....</p>}
                                    {action.GET_ALL_COMPANIES_BY_SEARCH.isFetched && searchCompanies.map((option, idx) => {
                                        return (
                                            <p
                                                key={idx}
                                                tabIndex="1"
                                                onClick={() => this.handleSelectCompany(option)}
                                            >
                                                <span className="color-medium-gray">
                                                    {option.companyName}
                                                </span>
                                            </p>
                                        );
                                    })}
                                </div>
                            </div>
                            <Spacer size={20} />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-right" xs="12">
                            <Spacer size={50} />
                            <Button
                                className="mr-3"
                                variant="dark"
                                onClick={this.resetFilters}
                            >
                                Reset
                            </Button>
                            <Button type="submit" variant="primary">
                                Apply
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </>
        );
    }
}

function mapStateToProps(state, props) {
    const { filters } = props;
    return {
        initialValues: {
            role: filters.role,
            status: filters.status,
            accountActivate: filters.accountActivate,
        },
        searchCompanies: state.admin.searchCompanies,
        action: state.action
    };

}

export default connect(mapStateToProps)(
    reduxForm({
        form: "FilterUsers",
        enableReinitialize: true,
    })(FilterUsers)
);
