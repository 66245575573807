import React from "react";
import { connect } from "react-redux";
import { get } from "lodash";
import { Form, Button, Row, Col } from "react-bootstrap";
import { Field, reduxForm, formValueSelector } from "redux-form";
import {
  reduxFormTextarea,
  reduxFormSelect,
  reduxFormInput,
} from "components/form/ReduxForm";
import { noSpaces } from "utils/validation";
import Spacer from "components/layout/Spacer";
import programCategories from "../constants/programCategories";
import { generateSelectOption } from "utils/commonUtils";

class OtherStep extends React.Component {
  submitFn = () => {
    if (this.saveType === "saveAndContinue") {
      this.props.handleSaveAndContinue(this.props.nextStep);
    } else {
      this.props.handleSaveAndContinue();
    }
  };

  setSave = (type) => () => {
    this.saveType = type;
  };

  componentDidUpdate(prevProps) {
    if (prevProps.isActive !== this.props.isActive && this.props.isActive) {
      this.props.setStepName("Anything else you'd like to include?");
    }
  }

  render() {
    const {
      handleSubmit,
      selectedProgramType,
      action,
      selectedCarrots,
      selectedLoanStructureComplexities,
      selectedOtherComplexities,
    } = this.props;

    return (
      <fieldset>
        <Form
          className="no-padding no-border"
          onSubmit={handleSubmit(this.submitFn)}
        >
          <Row>
            <Col xs="12" sm="6">
              <Field
                className="form-control"
                component={reduxFormSelect}
                name="otherCarrots"
                type="select"
                options={this.props.getDropdownValues("otherCarrots")}
                isLoading={action.GET_DROPDOWNS.isFetching}
                onFocus={() => this.props.getDropdownFromAPI("otherCarrots")}
                placeholder="Borrower Transaction Incentives"
                label="Borrower Transaction Incentives"
                isMulti
              />
              <Spacer size={20} />
              {selectedCarrots.some((item) => item.label === "Other") && (
                <>
                  <Field
                    className="form-control"
                    component={reduxFormTextarea}
                    validate={[noSpaces]}
                    name="otherOtherCarrots"
                    type="textarea"
                    rows="10"
                    placeholder="Additional information"
                    label="Other (Transaction Incentives)"
                  />
                  <Spacer size={20} />
                </>
              )}
              {programCategories.agency.indexOf(selectedProgramType.value) ===
                -1 && (
                <>
                  <Field
                    className="form-control"
                    component={reduxFormSelect}
                    name="loanStructureComplexities"
                    type="select"
                    options={this.props.getDropdownValues(
                      "loanStructureComplexities"
                    )}
                    isLoading={action.GET_DROPDOWNS.isFetching}
                    onFocus={() =>
                      this.props.getDropdownFromAPI("loanStructureComplexities")
                    }
                    placeholder="Loan Structure Complexities we can work with"
                    label="Loan Structure Complexities we can work with"
                    isMulti
                  />
                  <Spacer size={20} />
                  {selectedLoanStructureComplexities.some(
                    (item) => item.value === "Other"
                  ) && (
                    <>
                      <Spacer size={20} />
                      <Field
                        className="form-control"
                        component={reduxFormInput}
                        name="otherLoanStructureComplexities"
                        type="text"
                        placeholder="Other (Loan Structure Complexities)"
                        label="Other Loan (Structure Complexities)"
                      />
                      <Spacer size={20} />
                    </>
                  )}
                  <Field
                    className="form-control"
                    component={reduxFormSelect}
                    name="otherComplexities"
                    type="select"
                    options={this.props.getDropdownValues("otherComplexities")}
                    isLoading={action.GET_DROPDOWNS.isFetching}
                    onFocus={() =>
                      this.props.getDropdownFromAPI("otherComplexities")
                    }
                    placeholder="Other Complexities"
                    label="Other Complexities"
                    isMulti
                  />
                  <Spacer size={20} />
                  {selectedOtherComplexities.some(
                    (item) => item.value === "Other"
                  ) && (
                    <>
                      <Spacer size={20} />
                      <Field
                        className="form-control"
                        component={reduxFormInput}
                        name="otherOtherComplexities"
                        type="text"
                        placeholder="Other (Complexities)"
                        label="Other (Complexities)"
                      />
                      <Spacer size={20} />
                    </>
                  )}
                </>
              )}
              {programCategories.agency.indexOf(selectedProgramType.value) !==
                -1 && (
                <>
                  <Field
                    className="form-control"
                    component={reduxFormSelect}
                    name="specialAgencyLicensesPrograms"
                    type="select"
                    options={this.props.getDropdownValues(
                      "specialAgencyLicensesPrograms"
                    )}
                    isLoading={action.GET_DROPDOWNS.isFetching}
                    onFocus={() =>
                      this.props.getDropdownFromAPI(
                        "specialAgencyLicensesPrograms"
                      )
                    }
                    placeholder="Special Agency Licenses / Programs"
                    label="Special Agency Licenses / Programs"
                    isMulti={true}
                  />
                  <Spacer size={20} />
                </>
              )}
            </Col>
            <Col xs="12" sm="6">
              <Field
                className="form-control"
                component={reduxFormSelect}
                name="authoringContent"
                type="select"
                options={this.props.getDropdownValues("yesOrNoOrDiscuss")}
                isLoading={action.GET_DROPDOWNS.isFetching}
                onFocus={() =>
                  this.props.getDropdownFromAPI("yesOrNoOrDiscuss")
                }
                placeholder="Would you consider authoring content for our blog?"
                label="Would you consider authoring content for our blog?"
              />
              <Spacer size={20} />
              <Field
                className="form-control"
                component={reduxFormSelect}
                name="canShareContent"
                type="select"
                options={this.props.getDropdownValues("yesOrNoOrDiscuss")}
                isLoading={action.GET_DROPDOWNS.isFetching}
                onFocus={() =>
                  this.props.getDropdownFromAPI("yesOrNoOrDiscuss")
                }
                placeholder="Would you like us to share content for your website?"
                label="Would you like us to share content for your website?"
              />
              <Spacer size={20} />
              <Field
                className="form-control"
                component={reduxFormTextarea}
                validate={[noSpaces]}
                name="moreInfo"
                type="textarea"
                rows="3"
                placeholder="What else should we know about this program?"
                label="What else should we know about this program?"
              />
              <Spacer size={20} />
              <Field
                className="form-control"
                component={reduxFormTextarea}
                validate={[noSpaces]}
                name="competitiveDeals"
                type="textarea"
                rows="3"
                placeholder="What types of deals are you particularly competitive on with this program?"
                label="What types of deals are you particularly competitive on with this program?"
              />
              <Spacer size={20} />
            </Col>
          </Row>
          <Row>
            <Col className="text-left" xs="3">
              <Spacer size={50} />
              <Button
                onClick={this.props.handlePrevious(this.props.previousStep)}
                variant="dark"
              >
                Back
              </Button>
            </Col>
            <Col className="text-right" xs="9">
              <Spacer size={50} />
              <Button
                type="submit"
                variant="primary"
                onClick={this.setSave("saveAndExit")}
              >
                Save & Exit
              </Button>
              &nbsp;
              <Button
                type="submit"
                variant="primary"
                onClick={this.setSave("saveAndContinue")}
              >
                Save & Continue
              </Button>
            </Col>
          </Row>
        </Form>
      </fieldset>
    );
  }
}

const selectorInitialStep = formValueSelector("ProgramCreateInitialStep");
const selectorOtherStep = formValueSelector("ProgramCreateOtherStep");

const getInitialValues = ({ isUpdate, isClone, selectedProgram: program }) => {
  if (!isUpdate && !isClone) {
    return {};
  }
  return {
    otherCarrots: get(program, "otherCarrots", null),
    otherOtherCarrots: program.otherOtherCarrots,
    loanStructureComplexities: get(program, "loanStructureComplexities", null),
    otherLoanStructureComplexities: get(
      program,
      "otherLoanStructureComplexities",
      null
    ),
    otherComplexities: get(program, "otherComplexities", null),
    otherOtherComplexities: get(program, "otherOtherComplexities", null),
    specialAgencyLicensesPrograms: get(
      program,
      "specialAgencyLicensesPrograms",
      null
    ),
    authoringContent: generateSelectOption(program.authoringContent),
    canShareContent: generateSelectOption(program.canShareContent),
    moreInfo: program.moreInfo,
    competitiveDeals: program.competitiveDeals,
  };
};
function mapStateToProps(state, ownProps) {
  return {
    action: state.action,
    selectedProgramType: selectorInitialStep(state, "programTypes") || "",
    selectedCarrots: selectorOtherStep(state, "otherCarrots") || [],
    selectedLoanStructureComplexities:
      selectorOtherStep(state, "loanStructureComplexities") || [],
    selectedOtherComplexities:
      selectorOtherStep(state, "otherComplexities") || [],
    initialValues: getInitialValues(ownProps),
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "ProgramCreateOtherStep",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    enableReinitialize: true,
  })(OtherStep)
);
