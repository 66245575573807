import React from "react";
import { connect } from "react-redux";
import { assignTeamToDeal, getTeamsByCompanyId } from "redux/actions";
import { Button, Form, Row, Col } from "react-bootstrap";
import { reduxFormSelect } from "components/form/ReduxForm";
import { Field, reduxForm } from "redux-form";
import Spacer from "components/layout/Spacer";

class ChangeLenderTeam extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      teamsSelectOptions: [],
    };

    this.submitFn = this.submitFn.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.teams !== this.props.teams) {
      const { teams } = this.props;

      teams.map((team) => {
        this.setState((prevState) => ({
          teamsSelectOptions: [
            ...prevState.teamsSelectOptions,
            { label: team.teamName, value: team._id },
          ],
        }));

        return true;
      });
    }
  }

  componentDidMount() {
    const { myCompany } = this.props;

    getTeamsByCompanyId(myCompany._id);
  }

  submitFn(data) {
    const { dealId, myCompany, onSubmit } = this.props;

    assignTeamToDeal(dealId, myCompany._id, {
      assignTeam: data.teams.value,
    });

    onSubmit(data.teams.value);
  }

  render() {
    const { handleSubmit, action } = this.props;

    return (
      <Form
        className="no-padding no-border"
        onSubmit={handleSubmit(this.submitFn)}
      >
        <Row>
          <Col xs="12">
            <h4 className="bold">Teams</h4>
            <Spacer size={20} />
          </Col>
          <Col xs="12">
            <Field
              className="form-control"
              component={reduxFormSelect}
              name="teams"
              type="select"
              options={
                this.state.teamsSelectOptions
                  ? this.state.teamsSelectOptions
                  : {}
              }
              isLoading={action.GET_TEAMS_BY_COMPANY_ID.isFetching}
              placeholder="Select team"
              label="*Select team"
            />
            <Spacer size={20} />
          </Col>
        </Row>
        <Row>
          <Col className="text-right" xs="12">
            <Spacer size={50} />
            <Button type="submit" variant="primary">
              Assign
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

function mapStateToProps(state) {
  return {
    action: state.action,
    myCompany: state.company.myCompany,
    teams: state.team.teams,
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "ChangeLenderTeam",
    enableReinitialize: true,
  })(ChangeLenderTeam)
);
