import PopupPrompt from "components/ui/PopupPrompt";
import PassDealForm from "containers/deals/PassDealForm";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { InlineWidget } from "react-calendly";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const CalendlyWidget = ({ title, url, component }) => {
  const [showCalendly, setShowCalendly] = useState(false);
  const whoami = useSelector((state) => state.auth.whoami);
  return (
    <>
      <div onClick={() => setShowCalendly(true)} className={"cursor-pointer"}>
        {component ? (
          component
        ) : (
          <Button
            className="pt-2 pb-2 cursor-pointer"
            style={{
              borderRadius: "10px",
            }}
          >
            Let's Chat
          </Button>
        )}
      </div>

      <PopupPrompt
        promptTitle={title}
        promptBody={
          <InlineWidget
            prefill={{
              name: whoami.firstName + " " + whoami.lastName,
              firstName: whoami.firstName,
              lastName: whoami.lastName,
              email: whoami.email,
            }}
            url={
              url ||
              "https://calendly.com/matt-termstreet/lender_program_update_meeting"
            }
          />
        }
        show={showCalendly}
        onCancel={() => {
          setShowCalendly(false);
        }}
        onHide={() => {
          setShowCalendly(false);
        }}
      />
    </>
  );
};

export const EmptyDashboardLender = () => {
  const companyData = useSelector((state) => state?.company);
  const { myCompany: company } = companyData;
  return (
    <div className="empty-dashboard">
      <h4>
        {" "}
        <b>Empty Dashboard? No worries..</b>
      </h4>{" "}
      <br />
      <div style={{ textAlign: "justify", marginTop: "5px" }}>
        TermStreet is a private marketplace where we only show you deals that
        fit your profile. All deals are vetted for quality and fit before they
        become available on the marketplace and lenders are hand-selected by our
        lender relations team and added to each deal. That means, when a deal is
        available to you, we believe that you are an excellent fit for that deal
        and you have a reasonable chance of winning if you elect to quote.
        <br />
        <br />
      </div>
      <div className="image-section">
        <div className="emtpy-dashboard-img"></div>
        <div>
          <div className="empty-dashboard-title">
            To see more deals, help us get to know you better. Here are a few
            things you can do right now.
          </div>

          <ul>
            <li>
              <Link
                className="d-inline ml-3 font-14"
                to={
                  company?._id
                    ? "/company/profile/" + company?._id
                    : "/company/create"
                }
              >
                Add or Edit your Lending Programs
              </Link>
            </li>
            <li>
              <Link
                className="d-inline ml-3 font-14"
                to={
                  company?._id
                    ? "/company/profile/" + company?._id
                    : "/company/create"
                }
              >
                Create a Team or Add Team Members to an Existing Team
              </Link>
            </li>
            <li>
              <Link
                className="d-inline ml-3 font-14"
                to={
                  company?._id
                    ? "/company/edit/" + company?._id
                    : "/company/create"
                }
              >
                Fill out your company bio
              </Link>
            </li>
            <li>
              <Link
                className="d-inline ml-3 font-14"
                to={
                  company?._id
                    ? "/company/edit/" + company?._id
                    : "/company/create"
                }
              >
                Add your logo
              </Link>
            </li>
            <li>
              <CalendlyWidget
                title={"Lender Update Meeting"}
                component={
                  <div
                    className="d-inline ml-3 font-14 "
                    style={{ color: "#007bff" }}
                  >
                    Setup a call with our lender relations team to tell us more
                    about how we might work together
                  </div>
                }
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export const EmptyDashboardBorrower = () => {
  const companyData = useSelector((state) => state?.company);
  const { myCompany: company } = companyData;
  return (
    <div className="empty-dashboard">
      <h4>
        {" "}
        <b>Empty Dashboard? No worries..</b>
      </h4>{" "}
      <br />
      <div style={{ textAlign: "justify", marginTop: "5px" }}>
        TermStreet is a private marketplace where you only see your deals. That
        means, if you don’t have an active deal, you will not see any live deals
        in your dashboard. This ensures the privacy of each and every deal on
        the platform. This is true for lenders as well. Lenders can only view
        deals that are assigned to them.
        <br />
        <br />
      </div>
      <div className="image-section">
        <div className="emtpy-dashboard-img"></div>
      </div>
      <div className="d-flex">
        <p>If you are ready, let’s create your first deal.</p>

        <Button
          className="pt-2 pb-2"
          style={{
            borderRadius: "10px",
            marginBottom: "2rem",
            marginTop: "2.5rem",
          }}
          onClick={() => {
            window.location.href = "/deal";
          }}
        >
          Create Deal
        </Button>
      </div>
      <div className="empty-dashboard-title">
        If you are not quite ready to create your first deal, here are some
        things you can do to prepare.
      </div>
      <ul>
        <li>
          <Link
            className="d-inline ml-3 font-14"
            to={
              company?._id
                ? "/company/profile/" + company?._id
                : "/company/create"
            }
          >
            Create a Team or Add Team Members to an Existing Team
          </Link>
        </li>
        <li>
          <Link
            className="d-inline ml-3 font-14"
            to={
              company?._id ? "/company/edit/" + company?._id : "/company/create"
            }
          >
            Fill out your company bio
          </Link>
        </li>
        <li>
          {" "}
          <Link
            className="d-inline ml-3 font-14"
            to={
              company?._id ? "/company/edit/" + company?._id : "/company/create"
            }
          >
            Add your logo
          </Link>
        </li>
        <li>
          <CalendlyWidget
            title={"Meeting with TermStreet"}
            url={"https://calendly.com/matt-termstreet/deal-call"}
            component={
              <div
                className="d-inline ml-3 font-14"
                style={{ color: "#007bff" }}
              >
                Setup a call with our team to tell us more about how we might
                work together
              </div>
            }
          />
        </li>
      </ul>
    </div>
  );
};
export const EmptyInbox = ({ callBack }) => {
  return (
    <div className="empty-inbox-screen">
      <div className="header-section">
        <h3>
          <b>There are no messages yet</b>
        </h3>
        <p>Get started by sending a message or question!</p>
      </div>

      <div className="image-section">
        <div className="emtpy-inbox-img"></div>
      </div>

      <div className="d-flex justify-content-center" style={{ gap: "15px" }}>
        <Button
          className="pt-2 pb-2"
          style={{
            borderRadius: "10px",
            marginBottom: "2rem",
            marginTop: "2.5rem",
          }}
          onClick={() => {
            callBack(true, "Message");
          }}
        >
          Send Message
        </Button>
        <Button
          className="pt-2 pb-2"
          style={{
            borderRadius: "10px",
            marginBottom: "2rem",
            marginTop: "2.5rem",
          }}
          onClick={() => {
            callBack(true, "Question");
          }}
        >
          Create Question
        </Button>
      </div>
    </div>
  );
};

const NoResultsFound = ({ onReset = () => null }) => {
  return (
    <div className="d-flex justify-content-center no-results-screen">
      <div>
        <div className="noresults-img"></div>
        <div className="no-result-text">
          <h3>Sorry ! No result found !!</h3>
          <p>Please try another way or try clearing fitlers</p>
          <Button
            className="pt-2 pb-2 cursor-pointer"
            style={{
              borderRadius: "10px",
              marginBottom: "1rem",
              // marginTop: "1rem",
            }}
            onClick={onReset}
          >
            Clear Filters
          </Button>
        </div>
      </div>
    </div>
  );
};
export const EmptyQuotesLender = ({ dealId }) => {
  return (
    <div className="empty-dashboard" style={{ margin: "3rem" }}>
      <h4>
        {" "}
        <b>You cannot win if you do not quote...</b>
      </h4>{" "}
      <br />
      <div style={{ textAlign: "justify", marginTop: "5px" }}>
        At TermStreet we make it easy to issue a quote, whether that’s a full
        term sheet or a quick indication. Click to add your quote so the
        borrower can review what you have to offer. Term Street is a private
        marketplace meaning we only show each deal to a limited lender list.
        This means our lenders have a better chance to win each deal they see
        from us.
        <br />
        <br />
      </div>
      <div className="d-flex justify-content-center">
        <Button
          className="pt-2 pb-2 cursor-pointer"
          style={{
            borderRadius: "10px",
            marginBottom: "1rem",
            // marginTop: "1rem",
          }}
          onClick={() => {
            window.location.href = `/deal/${dealId}/termsheet/choice`;
          }}
        >
          Quote Now
        </Button>
      </div>
      <div className="image-section">
        <div className="emtpy-dashboard-img"></div>
        <div style={{ width: "57%", marginTop: "15px" }}>
          <div className="empty-dashboard-title">
            Also, there is no harm in passing if this deal is not a fit for you.
            If you pass, you not only stop receiving updates on this deal, but
            you also give us great insight into what you want to see from us
            next at TermStreet.
          </div>
          <div
            className="d-flex gap-20"
            style={{ marginBottom: "2rem", marginTop: "2.5rem" }}
          >
            <PassDealForm dealId={dealId}>
              <Button
                className="pt-2 pb-2 cursor-pointer"
                style={{
                  borderRadius: "10px",
                }}
              >
                Pass on this one
              </Button>
            </PassDealForm>

            <div className="pt-2 pb-2">OR</div>
            <CalendlyWidget title={"Lender Update Meeting"} />
          </div>
        </div>
      </div>
    </div>
  );
};
export const EmptyQuotesBorrower = () => {
  return (
    <div className="empty-dashboard" style={{ margin: "3rem" }}>
      <h4>
        {" "}
        <b>We are still waiting on your first quote</b>
      </h4>{" "}
      <br />
      <div style={{ textAlign: "justify", marginTop: "5px" }}>
        The best way to get quotes quickly is to provide thoughtful responses to
        questions and messages as they arrive. The team at TermStreet is always
        here to support your efforts. If you have questions or concerns, send us
        an email, chat to the right, or click below to setup a call.
        <br />
        <br />
      </div>
      <div className="image-section">
        <div className="emtpy-dashboard-img"></div>
      </div>
      <div className="d-flex justify-content-center">
        <CalendlyWidget
          title={"Meeting with TermStreet"}
          url={"https://calendly.com/matt-termstreet/deal-call"}
        />
      </div>
    </div>
  );
};
export const NotAllowedToCreateDeal = () => {
  const companyData = useSelector((state) => state?.company);
  const { myCompany: company } = companyData;
  return (
    <div className="empty-dashboard d-flex">
      <div className="image-section">
        <div className="emtpy-dealcreation-img"></div>
      </div>
      <div>
        <div className="empty-dashboard-title font-16">
          Seems you are not quite ready to create your first deal, here are some
          things you can do to prepare.
        </div>
        <ul>
          <li>
            <Link
              className="d-inline ml-3 font-16"
              to={
                company?._id
                  ? "/company/profile/" + company?._id
                  : "/company/create"
              }
            >
              Create a Team or Add Team Members to an Existing Team
            </Link>
          </li>
          <li>
            <Link
              className="d-inline ml-3 font-16"
              to={
                company?._id
                  ? "/company/edit/" + company?._id
                  : "/company/create"
              }
            >
              Fill out your company bio
            </Link>
          </li>
          <li>
            {" "}
            <Link
              className="d-inline ml-3 font-16"
              to={
                company?._id
                  ? "/company/edit/" + company?._id
                  : "/company/create"
              }
            >
              Add your logo
            </Link>
          </li>
          <li>
            <CalendlyWidget
              title={"Meeting with TermStreet"}
              url={"https://calendly.com/matt-termstreet/deal-call"}
              component={
                <div
                  className="d-inline ml-3 font-16"
                  style={{ color: "#007bff" }}
                >
                  Setup a call with our team to tell us more about how we might
                  work together
                </div>
              }
            />
          </li>
        </ul>
      </div>
    </div>
  );
};
export default NoResultsFound;
