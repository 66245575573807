import { Button, Card, Layout, theme } from "antd";
import { ChatBubbleSvg } from "components/svgs/ChatBubbleSvg";
import { last } from "lodash";
import React, { useRef } from "react";
import { Badge } from "react-bootstrap";
import { useSelector } from "react-redux";
import { InboxAvatar } from "../InboxAvatar";
import InboxReplies from "../inboxReplies/InboxReplies";
import { formatDate, getReceiverNames, getSenderName } from "../inboxUtils";
const { Content } = Layout;

const QuestionFollowUpSource = ({ status, questionDetails }) => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const whoami = useSelector((state) => state.auth.whoami);
  const myCompany = useSelector((state) => state.company.myCompany);
  const ref = useRef(null);
  const {
    _senderId,
    _senderCompanyId,
    _receiverCompanyIds,
    _labels,
    messages,
  } = questionDetails._followUpToConvoId;
  const [firstMessage, ...replies] = messages;
  const lastMessage = last(messages);
  const [sender] = _senderId;
  const [senderCompany] = _senderCompanyId;
  const receiverNames = getReceiverNames(
    _receiverCompanyIds,
    senderCompany,
    status,
    whoami,
    myCompany
  );
  const senderName = getSenderName(sender, senderCompany);
  const labels = _labels.map(({ label }) => label);

  return (
    <>
      <Card style={{ padding: 16 }} bodyStyle={{ width: "100%", padding: 0 }}>
        <Content style={{ margin: 0, background: colorBgContainer }}>
          <section className="d-flex flex-column gap-20">
            <div className="d-flex justify-content-between">
              <p className="font-18">
                Question
                <Badge className="text-capitalize ml-5" variant="primary">
                  {lastMessage?.isPrivate ? "Private" : "Public"}
                </Badge>
              </p>
              <div className="d-flex align-items-start gap-16">
                <p>{formatDate(firstMessage.createdAt, "ll, LT")}</p>
              </div>
            </div>

            <div className="d-flex flex-column gap-16">
              <InboxAvatar
                name={senderName}
                subtitle={`To: ${receiverNames}`}
                imageUrl={sender.profileImageXS}
              />
              <div className="d-flex align-items-center gap-10">
                {labels.map((label) => (
                  <span
                    key={label}
                    className="font-12 line-height-none border border-secondary p-2"
                  >
                    {label}
                  </span>
                ))}
              </div>
            </div>

            <div
              ref={ref}
              dangerouslySetInnerHTML={{
                __html: firstMessage.message,
              }}
            />

            <div className="d-flex align-items-center justify-content-between">
              {replies.length > 0 && (
                <div className="d-flex align-items-center">
                  <ChatBubbleSvg className="mr-2" />
                  <p>{replies.length} answers</p>
                </div>
              )}
            </div>
          </section>
        </Content>
      </Card>

      <InboxReplies isFollowUp messages={replies} />

      <section className="py-3 text-center">
        <div className="follow-up">
          This is a follow-up question for:{" "}
          <Button
            block
            type="link"
            onClick={() => {
              ref.current.scrollIntoView({ behavior: "smooth" });
            }}
          >
            <div
              className="message-content"
              dangerouslySetInnerHTML={{
                __html: firstMessage.message,
              }}
            />
          </Button>
        </div>
      </section>
    </>
  );
};

export default QuestionFollowUpSource;
