import React from "react";
import { connect } from "react-redux";
import { Button, Form, Row, Col } from "react-bootstrap";
import { Field, reduxForm, reset } from "redux-form";
import {
  reduxFormInput,
  reduxFormSelect,
  reduxFormNumber,
} from "components/form/ReduxForm";
import { searchCompanies, getDropdown } from "redux/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons";
import Spacer from "components/layout/Spacer";

class FilterCompanies extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadedDropdowns: [],
    };

    this.getDropdownFromAPI = this.getDropdownFromAPI.bind(this);
    this.resetFilters = this.resetFilters.bind(this);
    this.submitFn = this.submitFn.bind(this);
  }

  getDropdownFromAPI(value) {
    let canGet = false;

    if (this.state.loadedDropdowns.length > 0) {
      this.state.loadedDropdowns.some((dropdown) => {
        if (dropdown === value) {
          canGet = false;

          return true;
        } else {
          canGet = true;

          return false;
        }
      });
    } else {
      getDropdown(value);

      this.setState((prevState) => ({
        loadedDropdowns: [...prevState.loadedDropdowns, value],
      }));
    }

    if (canGet) {
      getDropdown(value);

      this.setState((prevState) => ({
        loadedDropdowns: [...prevState.loadedDropdowns, value],
      }));
    }
  }

  resetFilters() {
    const {
      dispatch,
      onResetFilters,
      companyType,
      pagination = {},
      skipSearchOnReset,
    } = this.props;

    onResetFilters();
    dispatch(reset("FilterCompanies"));

    if (!skipSearchOnReset) {
      searchCompanies({
        companyType,
        page: pagination.page,
        limit: pagination.listOffset,
      });
    }
  }

  submitFn(data) {
    const { onSubmit } = this.props;

    onSubmit(data);
  }

  render() {
    const { handleSubmit, action, dropdowns, companyType } = this.props;

    return (
      <>
        {companyType === "Borrower" && (
          <Form
            className="no-padding no-border"
            onSubmit={handleSubmit(this.submitFn)}
          >
            <Row>
              <Col xs="12" sm="3">
                <Field
                  className="form-control"
                  component={reduxFormSelect}
                  name="hasActiveDeal"
                  type="select"
                  options={[
                    { label: "Yes", value: "Yes" },
                    { label: "No", value: "No" },
                  ]}
                  placeholder="Select..."
                  label="Has Active Deal"
                />
                <Spacer size={20} />
              </Col>
              <Col xs="12" sm="3">
                <Field
                  className="form-control"
                  component={reduxFormSelect}
                  name="hasDealUnderReview"
                  type="select"
                  options={[
                    { label: "Yes", value: "Yes" },
                    { label: "No", value: "No" },
                  ]}
                  placeholder="Select..."
                  label="Has Non-Submitted Deal"
                />
                <Spacer size={20} />
              </Col>
              <Col xs="12" sm="3">
                <Field
                  className="form-control"
                  component={reduxFormSelect}
                  name="hasDealClosed"
                  type="select"
                  options={[
                    { label: "Yes", value: "Yes" },
                    { label: "No", value: "No" },
                  ]}
                  placeholder="Select..."
                  label="Has Closed a Deal in the Past"
                />
                <Spacer size={20} />
              </Col>
              <Col xs="12" sm="3">
                <Field
                  className="form-control"
                  component={reduxFormSelect}
                  name="hasDealInProgress"
                  type="select"
                  options={[
                    { label: "Yes", value: "Yes" },
                    { label: "No", value: "No" },
                  ]}
                  placeholder="Select..."
                  label="Has a Deal Under Application"
                />
                <Spacer size={20} />
              </Col>
            </Row>
            <Row>
              <Col xs="12" sm="6">
                <Field
                  className="form-control"
                  component={reduxFormInput}
                  name="lastLoginStart"
                  type="date"
                  label="Last Login Start Date"
                  max
                />
              </Col>
              <Col xs="12" sm="6">
                <Field
                  className="form-control"
                  component={reduxFormInput}
                  name="lastLoginEnd"
                  type="date"
                  label="Last Login End Date"
                  max
                />
              </Col>
            </Row>
            <Row>
              <Col className="text-right" xs="12">
                <Spacer size={50} />
                <Button
                  className="mr-3"
                  variant="dark"
                  onClick={this.resetFilters}
                >
                  Reset
                </Button>
                <Button type="submit" variant="primary">
                  Apply
                </Button>
              </Col>
            </Row>
          </Form>
        )}
        {companyType === "Lender" && (
          <Form
            className="no-padding no-border"
            onSubmit={handleSubmit(this.submitFn)}
          >
            <Row>
              <Col xs="12" sm="6">
                <Field
                  className="form-control"
                  component={reduxFormSelect}
                  name="programTypes"
                  type="select"
                  options={
                    dropdowns["programTypes"] !== {}
                      ? dropdowns["programTypes"]
                      : {}
                  }
                  isLoading={action.GET_DROPDOWNS.isFetching}
                  onChange={(e) => this.setState({ toggleFields: true })}
                  onFocus={() => this.getDropdownFromAPI("programTypes")}
                  placeholder="Select Program Type"
                  label="Program Type"
                />
                <Spacer size={20} />
                <Field
                  className="form-control"
                  component={reduxFormSelect}
                  name="lenderType"
                  type="select"
                  options={
                    dropdowns["lenderType"] !== {}
                      ? dropdowns["lenderType"]
                      : {}
                  }
                  isLoading={action.GET_DROPDOWNS.isFetching}
                  onFocus={() => this.getDropdownFromAPI("lenderType")}
                  placeholder="Select Lender Type"
                  label="Lender Type"
                />
                <Spacer size={20} />
              </Col>
              <Col xs="12" sm="6">
                <Field
                  className="form-control"
                  component={reduxFormSelect}
                  name="servicing"
                  type="select"
                  options={
                    dropdowns["servicing"] !== {} ? dropdowns["servicing"] : {}
                  }
                  isLoading={action.GET_DROPDOWNS.isFetching}
                  onFocus={() => this.getDropdownFromAPI("servicing")}
                  placeholder="Loan Servicing"
                  label="Loan Servicing"
                />
                <Spacer size={20} />
                <Field
                  icon={<FontAwesomeIcon icon={faDollarSign} />}
                  className="form-control d-inline"
                  component={reduxFormNumber}
                  name="loanAmount"
                  placeholder="Enter loan amount"
                  label="Loan Amount"
                  thousandSeparator={true}
                />
                <Spacer size={20} />
              </Col>
            </Row>
            <Row>
              <Col className="border-bottom" xs="12"></Col>
              <Col xs="12">
                <Spacer size={30} />
              </Col>
            </Row>
            <Row>
              <Col xs="12" sm="6">
                <Field
                  className="form-control"
                  component={reduxFormSelect}
                  name="apartmentTypesList"
                  type="select"
                  options={
                    dropdowns["apartmentTypesList"] !== {}
                      ? dropdowns["apartmentTypesList"]
                      : {}
                  }
                  isLoading={action.GET_DROPDOWNS.isFetching}
                  onFocus={() => this.getDropdownFromAPI("apartmentTypesList")}
                  placeholder="None"
                  label="Apartments"
                  isMulti={true}
                />
                <Spacer size={20} />
                <Field
                  className="form-control"
                  component={reduxFormSelect}
                  name="officeTypesList"
                  type="select"
                  options={
                    dropdowns["officeTypesList"] !== {}
                      ? dropdowns["officeTypesList"]
                      : {}
                  }
                  isLoading={action.GET_DROPDOWNS.isFetching}
                  onFocus={() => this.getDropdownFromAPI("officeTypesList")}
                  placeholder="None"
                  label="Office"
                  isMulti={true}
                />
                <Spacer size={20} />
                <Field
                  className="form-control"
                  component={reduxFormSelect}
                  name="retailTypeList"
                  type="select"
                  options={
                    dropdowns["retailTypeList"] !== {}
                      ? dropdowns["retailTypeList"]
                      : {}
                  }
                  isLoading={action.GET_DROPDOWNS.isFetching}
                  onFocus={() => this.getDropdownFromAPI("retailTypeList")}
                  placeholder="None"
                  label="Retail"
                  isMulti={true}
                />
                <Spacer size={20} />
                <Field
                  className="form-control"
                  component={reduxFormSelect}
                  name="industrialTypeList"
                  type="select"
                  options={
                    dropdowns["industrialTypeList"] !== {}
                      ? dropdowns["industrialTypeList"]
                      : {}
                  }
                  isLoading={action.GET_DROPDOWNS.isFetching}
                  onFocus={() => this.getDropdownFromAPI("industrialTypeList")}
                  placeholder="None"
                  label="Industrial"
                  isMulti={true}
                />
                <Spacer size={20} />
              </Col>
              <Col xs="12" sm="6">
                <Field
                  className="form-control"
                  component={reduxFormSelect}
                  name="hotelTypeList"
                  type="select"
                  options={
                    dropdowns["hotelTypeList"] !== {}
                      ? dropdowns["hotelTypeList"]
                      : {}
                  }
                  isLoading={action.GET_DROPDOWNS.isFetching}
                  onFocus={() => this.getDropdownFromAPI("hotelTypeList")}
                  placeholder="None"
                  label="Hotel"
                  isMulti={true}
                />
                <Spacer size={20} />
                <Field
                  className="form-control"
                  component={reduxFormSelect}
                  name="seniorLivingList"
                  type="select"
                  options={
                    dropdowns["seniorLivingList"] !== {}
                      ? dropdowns["seniorLivingList"]
                      : {}
                  }
                  isLoading={action.GET_DROPDOWNS.isFetching}
                  onFocus={() => this.getDropdownFromAPI("seniorLivingList")}
                  placeholder="None"
                  label="Senior Living"
                  isMulti={true}
                />
                <Spacer size={20} />
                <Field
                  className="form-control"
                  component={reduxFormSelect}
                  name="otherPropertyDealTypesWeLike"
                  type="select"
                  options={
                    dropdowns["otherPropertyDealTypesWeLike"] !== {}
                      ? dropdowns["otherPropertyDealTypesWeLike"]
                      : {}
                  }
                  isLoading={action.GET_DROPDOWNS.isFetching}
                  onFocus={() =>
                    this.getDropdownFromAPI("otherPropertyDealTypesWeLike")
                  }
                  placeholder="None"
                  label="Other Properties"
                  isMulti={true}
                />
                <Spacer size={20} />
              </Col>
            </Row>
            <Row>
              <Col className="border-bottom" xs="12"></Col>
              <Col xs="12">
                <Spacer size={30} />
              </Col>
            </Row>
            <Row>
              <Col xs="4">
                <Field
                  className="form-control"
                  component={reduxFormSelect}
                  name="fixedFloating"
                  type="select"
                  options={
                    dropdowns["fixedFloating"] !== {}
                      ? dropdowns["fixedFloating"]
                      : {}
                  }
                  isLoading={action.GET_DROPDOWNS.isFetching}
                  onFocus={() => this.getDropdownFromAPI("fixedFloating")}
                  placeholder="Fixed or Floating"
                  label="Fixed or Floating"
                  isMulti={true}
                />
                <Spacer size={20} />
              </Col>
              <Col xs="4">
                <Field
                  className="form-control"
                  component={reduxFormSelect}
                  name="fixedRateLoanPrepay"
                  type="select"
                  options={
                    dropdowns["fixedRateLoanPrepay"] !== {}
                      ? dropdowns["fixedRateLoanPrepay"]
                      : {}
                  }
                  isLoading={action.GET_DROPDOWNS.isFetching}
                  onFocus={() => this.getDropdownFromAPI("fixedRateLoanPrepay")}
                  placeholder="Fixed Rate Loan Prepay"
                  label="Fixed Rate Loan Prepay"
                  isMulti={true}
                />
                <Spacer size={20} />
              </Col>
              <Col xs="4">
                <Field
                  className="form-control"
                  component={reduxFormSelect}
                  name="floatingRateLoanPrepay"
                  type="select"
                  options={
                    dropdowns["floatingRateLoanPrepay"] !== {}
                      ? dropdowns["floatingRateLoanPrepay"]
                      : {}
                  }
                  isLoading={action.GET_DROPDOWNS.isFetching}
                  onFocus={() =>
                    this.getDropdownFromAPI("floatingRateLoanPrepay")
                  }
                  placeholder="Floating Rate Loan Prepay"
                  label="Floating Rate Loan Prepay"
                  isMulti={true}
                />
                <Spacer size={20} />
              </Col>
            </Row>
            <Row>
              <Col className="border-bottom" xs="12"></Col>
              <Col xs="12">
                <Spacer size={30} />
              </Col>
            </Row>
            <Row>
              <Col xs="4">
                <Field
                  component={reduxFormSelect}
                  name="rateStructure"
                  type="select"
                  options={
                    dropdowns["rateStructure"] !== {}
                      ? dropdowns["rateStructure"]
                      : {}
                  }
                  isLoading={action.GET_DROPDOWNS.isFetching}
                  onFocus={() => this.getDropdownFromAPI("rateStructure")}
                  placeholder="Rate Structure"
                  label="Rate Structure"
                />
                <Spacer size={20} />
              </Col>
              <Col xs="4">
                <Field
                  className="form-control"
                  component={reduxFormSelect}
                  name="maxLTV"
                  type="select"
                  options={
                    dropdowns["maxLTV"] !== {} ? dropdowns["maxLTV"] : {}
                  }
                  isLoading={action.GET_DROPDOWNS.isFetching}
                  onFocus={() => this.getDropdownFromAPI("maxLTV")}
                  placeholder="Max LTV"
                  label="Max LTV"
                />
                <Spacer size={20} />
              </Col>
              <Col xs="4">
                <Field
                  className="form-control"
                  component={reduxFormSelect}
                  name="competitiveLTV"
                  type="select"
                  options={
                    dropdowns["competitiveLTV"] !== {}
                      ? dropdowns["competitiveLTV"]
                      : {}
                  }
                  isLoading={action.GET_DROPDOWNS.isFetching}
                  onFocus={() => this.getDropdownFromAPI("competitiveLTV")}
                  placeholder="Most Competitive LTV"
                  label="Most Competitive LTV"
                />
                <Spacer size={20} />
              </Col>
            </Row>
            <Row>
              <Col className="border-bottom" xs="12"></Col>
              <Col xs="12">
                <Spacer size={30} />
              </Col>
            </Row>
            <Row>
              <Col xs="12" sm="6">
                <Field
                  className="form-control"
                  component={reduxFormSelect}
                  name="geography"
                  type="select"
                  options={
                    dropdowns["geography"] !== {} ? dropdowns["geography"] : {}
                  }
                  isLoading={action.GET_DROPDOWNS.isFetching}
                  onFocus={() => this.getDropdownFromAPI("geography")}
                  placeholder="Geography"
                  label="Geography"
                  isMulti={true}
                />
                <Spacer size={20} />
              </Col>
              <Col xs="12" sm="6">
                <Field
                  className="form-control"
                  component={reduxFormSelect}
                  name="otherCarrots"
                  type="select"
                  options={
                    dropdowns["otherCarrots"] !== {}
                      ? dropdowns["otherCarrots"]
                      : {}
                  }
                  isLoading={action.GET_DROPDOWNS.isFetching}
                  onFocus={() => this.getDropdownFromAPI("otherCarrots")}
                  placeholder="Transaction Incentives"
                  label="Transaction Incentives"
                  isMulti={true}
                />
                <Spacer size={20} />
              </Col>
            </Row>
            <Row>
              <Col className="text-right" xs="12">
                <Spacer size={50} />
                <Button
                  className="mr-3"
                  variant="dark"
                  onClick={this.resetFilters}
                >
                  Reset
                </Button>
                <Button type="submit" variant="primary">
                  Apply
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </>
    );
  }
}

function mapStateToProps(state, props) {
  const { filters, companyType } = props;

  if (companyType === "Borrower") {
    return {
      action: state.action,
      dropdowns: state.program.dropdowns,
      initialValues: {
        hasActiveDeal: filters.hasActiveDeal,
        hasDealUnderReview: filters.hasDealUnderReview,
        hasDealClosed: filters.hasDealClosed,
        hasDealInProgress: filters.hasDealInProgress,
        lastLoginStart: filters.lastLoginStart,
        lastLoginEnd: filters.lastLoginEnd,
      },
    };
  } else {
    return {
      action: state.action,
      dropdowns: state.program.dropdowns,
      initialValues: {
        programTypes: filters.programTypes,
        lenderType: filters.lenderType,
        servicing: filters.servicing,
        loanAmount: filters.loanAmount,
        apartmentTypesList: filters.apartmentTypesList,
        officeTypesList: filters.officeTypesList,
        retailTypeList: filters.retailTypeList,
        industrialTypeList: filters.industrialTypeList,
        hotelTypeList: filters.hotelTypeList,
        seniorLivingList: filters.seniorLivingList,
        otherPropertyDealTypesWeLike: filters.otherPropertyDealTypesWeLike,
        fixedFloating: filters.fixedFloating,
        fixedRateLoanPrepay: filters.fixedRateLoanPrepay,
        floatingRateLoanPrepay: filters.floatingRateLoanPrepay,
        rateStructure: filters.rateStructure,
        maxLTV: filters.maxLTV,
        competitiveLTV: filters.competitiveLTV,
        geography: filters.geography,
        otherCarrots: filters.otherCarrots,
      },
    };
  }
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "FilterCompanies",
    enableReinitialize: true,
  })(FilterCompanies)
);
