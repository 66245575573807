import { actionTypes } from "redux/actions";

export const generic = (state = { currentRoute: {} }, action) => {
    switch (action.type) {
        case actionTypes.CHANGE_ROUTE:
            return {
                ...state,
                currentRoute: action.payload
            }
        default:
            return state;
    }

}
