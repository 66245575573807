import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Button,
  Image,
  Container,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { reset } from "redux-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faUserPlus, faUsers } from "@fortawesome/free-solid-svg-icons";
import Spacer from "components/layout/Spacer";
import Loader from "components/ui/Loader";
import SocialMediaButton from "components/ui/SocialMediaButton";
import CompanyPic from "images/company-placeholder.png";
import PopupPrompt from "components/ui/PopupPrompt";
import CompanyInvite from "./CompanyInvite";
import CompanyAdd from "./CompanyAdd";

class CompanyProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      promptAction: "",
      showPrompt: false,
    };
  }

  handleShowPrompt(showPrompt) {
    this.setState({ showPrompt });
  }

  render() {
    const {
      action,
      company,
      isCompanyAdmin,
      isCompanyMember,
      dispatch,
      whoami,
    } = this.props;
    const {
      _id,
      companyName,
      companyLinkedin,
      companyLogo,
      companyBio,
      companyAddress,
      companyCity,
      companyState,
      companyZipCode,
      companyWebsiteURL,
      defaultContact,
      companyType,
      admin: companyAdmin,
    } = company;
    const isAdmin = whoami?.role === "Admin";

    return (
      <>
        {(action.GET_COMPANY_BY_ID.isFetching ||
          action.ADD_TO_COMPANY.isFetching ||
          action.INVITE_TO_COMPANY.isFetching) && <Loader files />}

        {(isCompanyMember || isAdmin) && (
          <>
            <div className="text-right">
              {this.state.promptAction === "Add" && (
                <PopupPrompt
                  promptTitle={"Add Member to Company"}
                  promptBody={
                    <CompanyAdd
                      companyId={_id}
                      onSubmit={() => {
                        dispatch(reset("AddMembers"));
                        this.setState({ promptAction: "" });
                        this.handleShowPrompt(false);
                      }}
                    />
                  }
                  show={this.state.showPrompt}
                  onCancel={() => {
                    dispatch(reset("AddMembers"));
                    this.setState({ promptAction: "" });
                    this.handleShowPrompt(false);
                  }}
                  onHide={() => {
                    dispatch(reset("AddMembers"));
                    this.setState({ promptAction: "" });
                    this.handleShowPrompt(false);
                  }}
                />
              )}
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip id={`tooltip-bottom`}>Add to Company</Tooltip>
                }
              >
                <Button
                  className="mr-2 pl-3 pt-2 pr-3 pb-2"
                  onClick={() => {
                    this.setState({ promptAction: "Add" });
                    this.handleShowPrompt(true);
                  }}
                >
                  <FontAwesomeIcon icon={faUserPlus} />
                </Button>
              </OverlayTrigger>
              {this.state.promptAction === "Invite" && (
                <PopupPrompt
                  promptTitle={"Invite Member to Company"}
                  promptBody={
                    <CompanyInvite
                      companyId={_id}
                      companyType={companyType}
                      onSubmit={() => {
                        dispatch(reset("InviteMembers"));
                        this.setState({ promptAction: "" });
                        this.handleShowPrompt(false);
                      }}
                    />
                  }
                  show={this.state.showPrompt}
                  onCancel={() => {
                    dispatch(reset("InviteMembers"));
                    this.setState({ promptAction: "" });
                    this.handleShowPrompt(false);
                  }}
                  onHide={() => {
                    dispatch(reset("InviteMembers"));
                    this.setState({ promptAction: "" });
                    this.handleShowPrompt(false);
                  }}
                />
              )}
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip id={`tooltip-bottom`}>Invite to Company</Tooltip>
                }
              >
                <Button
                  className="mr-2 pl-3 pt-2 pr-3 pb-2"
                  onClick={() => {
                    this.setState({ promptAction: "Invite" });
                    this.handleShowPrompt(true);
                  }}
                >
                  <FontAwesomeIcon icon={faUsers} />
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id={`tooltip-bottom`}>Edit</Tooltip>}
              >
                <Button
                  as={Link}
                  to={"/company/edit/" + _id}
                  className="pl-3 pt-2 pr-3 pb-2"
                >
                  <FontAwesomeIcon icon={faEdit} />
                </Button>
              </OverlayTrigger>
            </div>
          </>
        )}
        <Spacer size={50} />
        <Container fluid>
          <Row>
            <Col
              className="d-block d-sm-none d-flex align-items-center"
              xs="12"
            >
              <Image
                className="d-inline-block mobile-img"
                src={company && companyLogo ? companyLogo : CompanyPic}
                alt={companyName + " company picture."}
                width="50px"
                height="auto"
              />
              <h2 className="bold d-inline-block pl-3">{companyName}</h2>
            </Col>
            <Col className="d-none d-sm-block" xs="0" sm="4" lg="2">
              <Image
                className="mobile-img"
                src={company && companyLogo ? companyLogo : CompanyPic}
                alt={companyName + " company picture."}
                width="100%"
                height="auto"
              />
            </Col>
            <Col xs="12" sm="8" lg="10">
              <h3 className="bold d-none d-sm-block">{companyName}</h3>
              <Spacer size={20} />
              {companyLinkedin && companyLinkedin !== " " && (
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip id={`tooltip-bottom`}>LinkedIn</Tooltip>}
                >
                  <div className="mr-2 d-inline-block">
                    <SocialMediaButton icon="linkedin" url={companyLinkedin} />
                  </div>
                </OverlayTrigger>
              )}
              {companyWebsiteURL && companyWebsiteURL !== " " && (
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip id={`tooltip-bottom`}>Company Website</Tooltip>
                  }
                >
                  <div className="mr-2 d-inline-block">
                    <SocialMediaButton icon="link" url={companyWebsiteURL} />
                  </div>
                </OverlayTrigger>
              )}
              <Row>
                <Col xs="12" sm="6">
                  <Spacer size={10} />
                  {company &&
                    companyAddress &&
                    companyCity &&
                    companyState &&
                    companyZipCode && (
                      <>
                        <h5 className="bold">Address</h5>
                        <p>
                          {companyAddress}, {companyCity} {companyState},{" "}
                          {companyZipCode}
                        </p>
                      </>
                    )}
                </Col>
                <Col xs="12" sm="6">
                  <Spacer size={10} />
                  {company && (
                    <>
                      <h5 className="bold">Primary Contact</h5>
                      <p>
                        {defaultContact
                          ? `${defaultContact.firstName} ${defaultContact.lastName}`
                          : "None"}
                      </p>
                    </>
                  )}
                  <Spacer size={10} />
                  {company && (
                    <>
                      <h5 className="bold">Admin</h5>
                      <p>
                        {companyAdmin
                          ? `${companyAdmin.firstName} ${companyAdmin.lastName}`
                          : "None"}
                      </p>
                    </>
                  )}
                </Col>
              </Row>
              <Spacer size={10} />
              {companyBio && companyBio !== " " && (
                <>
                  <h5 className="bold">Bio</h5>
                  <p className="bio">{companyBio}</p>
                </>
              )}
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    action: state.action,
    whoami: state.auth.whoami,
    company: state.company.company,
  };
}

export default connect(mapStateToProps)(CompanyProfile);
