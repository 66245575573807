import React from "react";
import { connect } from "react-redux";
import { Button, Form, Row, Col } from "react-bootstrap";
import { Field, reduxForm, reset } from "redux-form";
import { minMax } from "utils/validation";
import { reduxFormNumber, reduxFormSelect } from "components/form/ReduxForm";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getDropdown, getDeals } from "redux/actions";
import Spacer from "components/layout/Spacer";
import { DEFAULT_PAGINATION } from "containers/auth/Dashboard";

class FilterDeals extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loanMin: "",
      loanMax: "",
      propertyTypes: [],
      loadedDropdowns: [],
    };

    this.resetFilters = this.resetFilters.bind(this);
    this.getDropdownFromAPI = this.getDropdownFromAPI.bind(this);
    this.submitFn = this.submitFn.bind(this);
  }

  componentDidMount() {
    const { filters } = this.props;

    this.setState({
      loanMin: filters.minimumLoanAmountRequested,
      loanMax: filters.maximumLoanAmountRequested,
    });
  }

  resetFilters() {
    const { dispatch, onResetFilters, role } = this.props;

    onResetFilters();
    this.setState({ loanMin: "", loanMax: "" }, function () {
      dispatch(reset("FilterDeals"));
    });

    getDeals({
      isPublic: role === "Admin" ? true : false,
      archived: false,
      pagination: DEFAULT_PAGINATION,
    });
  }

  getDropdownFromAPI(value) {
    let canGet = false;

    if (this.state.loadedDropdowns.length > 0) {
      this.state.loadedDropdowns.some((dropdown) => {
        if (dropdown === value) {
          canGet = false;

          return true;
        } else {
          canGet = true;

          return false;
        }
      });
    } else {
      getDropdown(value);

      this.setState((prevState) => ({
        loadedDropdowns: [...prevState.loadedDropdowns, value],
      }));
    }

    if (canGet) {
      getDropdown(value);

      this.setState((prevState) => ({
        loadedDropdowns: [...prevState.loadedDropdowns, value],
      }));
    }
  }

  submitFn(data) {
    const { onSubmit } = this.props;

    onSubmit(data);
  }

  render() {
    const { handleSubmit, action, dropdowns } = this.props;

    return (
      <Form
        className="no-padding no-border"
        onSubmit={handleSubmit(this.submitFn)}
      >
        <Row>
          <Col xs="12" sm="6">
            <Field
              className="form-control"
              component={reduxFormSelect}
              name="propertyType"
              type="select"
              options={
                dropdowns["propertyTypes"] !== {}
                  ? dropdowns["propertyTypes"]
                  : {}
              }
              isLoading={action.GET_DROPDOWNS.isFetching}
              onChange={(e) => {
                this.setState({
                  propertyTypes: e,
                });
              }}
              onFocus={() => this.getDropdownFromAPI("propertyTypes")}
              placeholder="Select..."
              label="Property Type"
              isMulti={true}
            />
            <Spacer size={20} />
            <Field
              className="form-control"
              component={reduxFormSelect}
              name="loanType"
              type="select"
              options={
                dropdowns["loanType"] !== {} ? dropdowns["loanType"] : {}
              }
              isLoading={action.GET_DROPDOWNS.isFetching}
              onFocus={() => this.getDropdownFromAPI("loanType")}
              placeholder="Select..."
              label="Loan Type"
            />
            <Spacer size={20} />
            <Field
              className="form-control"
              component={reduxFormSelect}
              name="dealStatus"
              type="select"
              options={[
                { label: "Active", value: "Active" },
                { label: "Dead", value: "Dead" },
                { label: "Awarded", value: "Awarded" },
                { label: "Hold", value: "Hold" },
                { label: "In progress", value: "In progress" },
                { label: "Under review", value: "Under review" },
                { label: "Closed", value: "Closed" },
                { label: "Removed", value: "Removed" },
              ]}
              placeholder="Select..."
              label="Deal Status"
            />
            <Spacer size={20} />
            <Field
              className="form-control"
              component={reduxFormSelect}
              name="archived"
              type="select"
              options={dropdowns["yesNo"] !== {} ? dropdowns["yesNo"] : {}}
              isLoading={action.GET_DROPDOWNS.isFetching}
              onFocus={() => this.getDropdownFromAPI("yesNo")}
              placeholder="Select..."
              label="Archived"
            />
            <Spacer size={20} />
          </Col>
          <Col xs="12" sm="6">
            <Field
              icon={<FontAwesomeIcon icon={faDollarSign} />}
              className="form-control"
              component={reduxFormNumber}
              name="minimumLoanAmountRequested"
              placeholder="Enter min amount..."
              label="Minimum Amount Requested"
              defaultValue={this.state.loanMin}
              thousandSeparator={true}
              onChange={(e) => {
                this.setState({ loanMin: e });
              }}
            />
            <Spacer size={20} />
            <Field
              icon={<FontAwesomeIcon icon={faDollarSign} />}
              className="form-control"
              component={reduxFormNumber}
              validate={[minMax]}
              name="maximumLoanAmountRequested"
              placeholder="Enter max amount..."
              label="Maximum Amount Requested"
              defaultValue={this.state.loanMax}
              thousandSeparator={true}
              onChange={(e) => {
                this.setState({ loanMax: e });
              }}
            />
            <Spacer size={20} />
          </Col>
        </Row>
        <Row>
          <Col className="text-right" xs="12">
            <Spacer size={50} />
            <Button className="mr-3" variant="dark" onClick={this.resetFilters}>
              Reset
            </Button>
            <Button type="submit" variant="primary">
              Apply
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

function mapStateToProps(state, props) {
  const { filters } = props;

  return {
    action: state.action,
    dropdowns: state.program.dropdowns,
    initialValues: {
      propertyType: filters.propertyType,
      loanType: filters.loanType,
      dealStatus: filters.dealStatus,
      archived: filters.archived,
    },
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "FilterDeals",
    enableReinitialize: true,
  })(FilterDeals)
);
