import React, { useCallback } from "react";
import { Dropdown, Button } from "react-bootstrap";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const GridPictureItem = (props) => {
  const {
    userId,
    _id,
    name,
    permission,
    parentPermission,
    fetchFile,
    removeFile,
    showSecuritySharing,
    showRenaming,
    canManage
  } = props;

  const checkPermission = useCallback(() => {
    if (permission && permission.length > 0) {
      if (permission.includes(userId)) return true;
      else return false;
    }
    if (parentPermission && parentPermission.length > 0) {
      if (parentPermission.includes(userId)) return true;
      else return false;
    }
    return true;
  }, [userId, parentPermission, permission]);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Button
      ref={ref}
      className="p-1"
      variant="folder"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </Button>
  ));

  const CustomMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) => child.props.children
            )}
          </ul>
        </div>
      );
    }
  );

  return (
    <>
      <div className="mb-30 pl-3 pr-3">
        <div className="grid-col-container dark">
          <div className="grid-item">
            <div className="grid-row">
              <span className="bold grid-file-name">{name}</span>
              {checkPermission() && (
                <Dropdown className="d-inline-block folder-file-dropdown">
                  <Dropdown.Toggle as={CustomToggle} id="toggle-dropdown">
                    <h4 className="view-as">
                      <FontAwesomeIcon icon={faEllipsisH} />
                    </h4>
                  </Dropdown.Toggle>

                  <Dropdown.Menu as={CustomMenu}>
                    <Dropdown.Item onClick={() => fetchFile(_id, name)}>
                      Download
                    </Dropdown.Item>
                    {
                      canManage &&
                      <>
                        <Dropdown.Item onClick={() => showRenaming(_id)}>
                          Rename
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            showSecuritySharing(_id);
                          }}
                        >
                          Security & Sharing
                       </Dropdown.Item>
                        <Dropdown.Divider> </Dropdown.Divider>
                        <Dropdown.Item onClick={() => removeFile(_id)}>
                          Delete
                       </Dropdown.Item>
                      </>
                    }
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GridPictureItem;
