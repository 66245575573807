import React from "react";
import { connect } from "react-redux";
import { Form, Button, Row, Col, Alert } from "react-bootstrap";
import { reduxForm, Field } from "redux-form";
import { reduxFormSelect, reduxFormInput } from "components/form/ReduxForm";
import {
  assignToComm,
  archiveMessage,
  sendMessage,
  mergeMessage,
} from "redux/actions";
import { toast } from "react-toastify";
import { required } from "utils/validation";
import Spacer from "components/layout/Spacer";

class MergeMessages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allMessages: [],
      selectedMessages: [],
      availableQuestions: [],
      actionComm: {},
      savedData: {},
    };

    this.submitFn = this.submitFn.bind(this);
    this.submitError = this.submitError.bind(this);
    this.areYouSure = this.areYouSure.bind(this);
  }

  submitError(text) {
    toast.error(text, {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  submitFn(data) {
    const { onSubmit } = this.props;
    const commsBeingMerged = [];
    let commBeingMergedInto = {};

    this.state.allMessages.map((comm) => {
      if (comm._id === data.availableQuestions.value) {
        commBeingMergedInto = comm;
      }

      return true;
    });

    this.state.allMessages.map((comm) => {
      if (
        this.state.selectedMessages.some((selected) => selected.id === comm._id)
      ) {
        commsBeingMerged.push(comm);
      }

      return true;
    });

    const commBeingMergedIntoConversationId = commBeingMergedInto._id;

    commsBeingMerged.map((comm) => {
      comm.lender.map((lenderAssigned) => {
        if (
          !commBeingMergedInto.lender.some(
            (mergedInto) => mergedInto.companyId === lenderAssigned.companyId
          )
        ) {
          assignToComm(commBeingMergedIntoConversationId, lenderAssigned);
        }

        return true;
      });

      mergeMessage(commBeingMergedIntoConversationId, {
        merge: JSON.stringify(comm),
      });

      archiveMessage(comm._id);

      const commBeingMergedIntoFormData = new FormData();

      commBeingMergedIntoFormData.append("messageFromName", "SYSTEM:MERGED");
      commBeingMergedIntoFormData.append(
        "message",
        comm.editedInitialMsg && comm.editedInitialMsg.length > 0
          ? comm.editedInitialMsg
          : comm.initialMessage
      );

      sendMessage(
        commBeingMergedIntoConversationId,
        commBeingMergedIntoFormData
      );

      const commBeingMergedConversationId = comm._id;
      const commBeingMergedFormData = new FormData();

      commBeingMergedFormData.append("messageFromName", "SYSTEM:WAS_MERGED");
      commBeingMergedFormData.append(
        "message",
        `${commBeingMergedIntoConversationId}:${
          commBeingMergedInto.editedInitialMsg &&
          commBeingMergedInto.editedInitialMsg.length > 0
            ? commBeingMergedInto.editedInitialMsg
            : commBeingMergedInto.initialMessage
        }`
      );

      sendMessage(commBeingMergedConversationId, commBeingMergedFormData);

      return true;
    });

    onSubmit();
  }

  componentDidMount() {
    const {
      allMessages,
      selectedMessages,
      actionComm,
      openConversation,
    } = this.props;
    let isMergingIntoSelf;
    let availableQuestions = [];

    if (
      Object.keys(actionComm).length > 0 &&
      Object.keys(openConversation).length > 0 &&
      actionComm.id === openConversation.id
    ) {
      isMergingIntoSelf = true;
    } else {
      isMergingIntoSelf = false;
    }

    if (Object.keys(actionComm).length > 0) {
      this.setState({
        allMessages,
        selectedMessages: [
          ...selectedMessages.filter((select) => select.id !== actionComm.id),
          actionComm,
        ],
      });
    } else if (Object.keys(openConversation).length > 0 && !isMergingIntoSelf) {
      this.setState({
        allMessages: [...allMessages, openConversation],
        selectedMessages,
      });
    } else {
      this.setState({
        allMessages,
        selectedMessages,
      });
    }

    let sortedComms = allMessages.filter((comm) => {
      return comm.type === "Question";
    });

    function getAvailableQuestions() {
      return availableQuestions;
    }

    function addToAvailableQuestions(arr) {
      availableQuestions.push(arr);
    }

    function setAvailableQuestions(arr) {
      availableQuestions = arr;
    }

    sortedComms.map((comm) => {
      if (!selectedMessages.some((selected) => selected.id === comm._id)) {
        this.setState((prevState) => ({
          availableQuestions: [
            ...prevState.availableQuestions,
            {
              label:
                comm.editedInitialMsg && comm.editedInitialMsg.length > 0
                  ? comm.editedInitialMsg.replace(/^(.{50}[^\s]*).*/, "$1") +
                    "..."
                  : comm.initialMessage.replace(/^(.{50}[^\s]*).*/, "$1") +
                    "...",
              value: comm._id,
            },
          ],
        }));

        addToAvailableQuestions({
          label:
            comm.editedInitialMsg && comm.editedInitialMsg.length > 0
              ? comm.editedInitialMsg.replace(/^(.{50}[^\s]*).*/, "$1") + "..."
              : comm.initialMessage.replace(/^(.{50}[^\s]*).*/, "$1") + "...",
          value: comm._id,
        });
      }

      return true;
    }, this);

    if (Object.keys(actionComm).length > 0) {
      setAvailableQuestions(
        getAvailableQuestions().filter((x) => x.value !== actionComm.id)
      );
    }

    this.setState({ availableQuestions: getAvailableQuestions() });
  }

  areYouSure(data) {
    this.setState({ areYouSure: true, savedData: data });
  }

  render() {
    const { handleSubmit } = this.props;

    return (
      <>
        {!this.state.areYouSure ? (
          <Form
            className="no-padding no-border"
            onSubmit={handleSubmit(this.areYouSure)}
          >
            {this.state.selectedMessages &&
              this.state.selectedMessages.length > 0 && (
                <>
                  <Row>
                    <Col xs="12">
                      <h4 className="bold">Search all Questions</h4>
                      <Spacer size={20} />
                    </Col>
                    <Col xs="12">
                      {this.state.availableQuestions &&
                      this.state.availableQuestions.length > 0 ? (
                        <Field
                          className="form-control"
                          component={reduxFormSelect}
                          validate={[required]}
                          name="availableQuestions"
                          type="select"
                          options={this.state.availableQuestions}
                          placeholder="Select question"
                          label="*Select question to merge into"
                        />
                      ) : (
                        <p>There are no other messages to merge into.</p>
                      )}
                      <Spacer size={20} />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12">
                      <h4 className="bold">Selected Questions</h4>
                      <Spacer size={20} />
                    </Col>
                  </Row>
                  <Row>
                    {this.state.selectedMessages.map((selected, idx) => {
                      return (
                        <Col
                          className="field-wrapper-no-spacer"
                          xs="12"
                          key={idx}
                        >
                          <Field
                            className="form-control"
                            component={reduxFormInput}
                            name={"selected" + idx}
                            type="text"
                            placeholder={
                              selected.message.replace(
                                /^(.{25}[^\s]*).*/,
                                "$1"
                              ) + "..."
                            }
                            disabled
                          />
                        </Col>
                      );
                    })}
                  </Row>
                </>
              )}
            <Row>
              {this.state.selectedMessages.some(
                (comm) => comm.type === "Message"
              ) ? (
                <Col xs="12">
                  <Alert variant="warning">
                    You have messages and questions selected. Messages cannot be
                    merged.
                  </Alert>
                </Col>
              ) : (
                <>
                  {this.state.availableQuestions &&
                  this.state.availableQuestions.length > 0 ? (
                    <Col className="text-right" xs="12">
                      <Spacer size={50} />
                      <Button type="submit" variant="primary">
                        Merge
                      </Button>
                    </Col>
                  ) : null}
                </>
              )}
            </Row>
          </Form>
        ) : (
          <div className="text-right">
            <h4 className="text-left">Are you sure you want to merge?</h4>
            <Spacer size={20} />
            <Button variant="dark" onClick={() => this.props.onSubmit()}>
              Cancel
            </Button>
            <Button
              className="ml-3"
              variant="primary"
              onClick={() => this.submitFn(this.state.savedData)}
            >
              Yes, Merge
            </Button>
          </div>
        )}
      </>
    );
  }
}

function mapStateToProps(state, props) {
  const { questionDraggedInto } = props;

  if (questionDraggedInto.value !== undefined) {
    return {
      initialValues: {
        availableQuestions: questionDraggedInto,
      },
    };
  } else {
    return {};
  }
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "MergeMessages",
    enableReinitialize: true,
  })(MergeMessages)
);
