import React from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import ManageLanguage from "containers/termsheet/ManageLanguage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { reduxFormNumber as InputNumber } from "components/form/ReduxForm";
import Spacer from "components/layout/Spacer";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons";

const SectionType = ({ easeSection, column, onChange, index }) => {
  if (column.sectionType === "currency") {
    return (
      <Form className="no-border no-padding">
        <InputNumber
          className="form-control"
          placeholder="enter currency"
          meta={{}}
          input={{
            value: column.sectionValue,
            onChange,
          }}
          icon={<FontAwesomeIcon icon={faDollarSign} />}
          thousandSeparator
        />
      </Form>
    );
  }
  if (column.sectionType === "numeric") {
    return (
      <Form className="no-border no-padding">
        <InputNumber
          className="form-control"
          placeholder="enter number"
          meta={{}}
          input={{
            value: column.sectionValue,
            onChange,
          }}
        />
      </Form>
    );
  }
  if (column.sectionType === "percent") {
    return (
      <Form className="no-border no-padding">
        <InputNumber
          className="form-control"
          placeholder="enter percentage"
          meta={{}}
          input={{
            value: column.sectionValue,
            onChange,
          }}
          suffix="%"
        />
      </Form>
    );
  }
  // if (easeSection.type === 'sectionYearsMonths') {
  //     return <div style={{ display: 'flex' }}>
  //         <Form.Check
  //             type="radio"
  //             label="years"
  //             name={`sectionYearsMonths-${index}`}
  //             id="years"
  //             checked={column['sectionYearsMonths'] === "years"}
  //             onChange={(e) => onChange('sectionYearsMonths', e.target.checked ? 'years' : '', index)}
  //         /> &nbsp;
  //         <Form.Check
  //             type="radio"
  //             label="months"
  //             name={`sectionYearsMonths-${index}`}
  //             checked={column['sectionYearsMonths'] === "months"}
  //             onChange={(e) => onChange('sectionYearsMonths', e.target.checked ? 'months' : '', index)}
  //             id="months"
  //         />
  //     </div>
  // }
  return null;
};

const SectionRow = React.forwardRef(
  (
    { column, easeSection, index, id, handleOnChange, handleOnRemove, style },
    ref
  ) => {
    // const [showLangSection, setShowLangSection] = useState(false);
    return (
      <>
        <Row
          ref={ref}
          style={{
            ...style,
            borderBottom: "1px solid #e0dede",
            marginBottom: 10,
          }}
        >
          <Col xs={2} md={2} style={{ textAlign: "right" }}>
            {column.sectionName}
            <br />
            <Button
              className="fake-link"
              variant="fake-link"
              onClick={() => handleOnRemove(index)}
            >
              Remove
            </Button>
          </Col>
          <Col xs={8} md={8}>
            {column.sectionType && (
              <>
                <Row>
                  <Col md={4}>
                    <SectionType
                      index={index}
                      easeSection={easeSection}
                      column={column}
                      onChange={(sectionValue) =>
                        handleOnChange("sectionValue", sectionValue, index)
                      }
                    />
                  </Col>
                </Row>
                <Spacer size={20} />
              </>
            )}
            <ManageLanguage
              easeSection={easeSection}
              column={column}
              onChange={(langDesc) =>
                handleOnChange("langDesc", langDesc, index)
              }
              onChangeClause={(childClauseId) =>
                handleOnChange("childClauseId", childClauseId, index)
              }
            />
          </Col>
          <Col xs={2} md={2} style={{ textAlign: "right" }}>
            <FontAwesomeIcon icon={faBars} />
          </Col>
          {/* <Col xs={4} md={4}>
                    <Form.Control
                        name={id}
                        type="text"
                        placeholder={easeSection.placeholder}
                        value={column[easeSection.valueKey]}
                        onChange={(e) => handleOnChange(easeSection.valueKey, e.target.value, index)}
                    />
                </Col> */}
          {/* <Col xs={4} md={4}> <SectionType index={index} easeSection={easeSection} column={column} onChange={handleOnChange} /></Col> */}
          {/* <Col xs={2} md={2}> <Button className="fake-link" variant="fake-link" onClick={() => setShowLangSection(true)}>Add Language</Button></Col> */}
        </Row>
        {/* {
                showLangSection &&
                <Row style={showLangSection ? { borderBottom: '1px solid #e0dede' } : {}}>
                    <Col xs={{ span: 8, offset: 2 }} md={{ span: 8, offset: 2 }}>
                        <ManageLanguage easeSection={easeSection} column={column} onChange={(langDesc) => handleOnChange('langDesc', langDesc, index)} />
                    </Col>
                </Row>
            } */}
      </>
    );
  }
);

export default SectionRow;
