import { actionTypes } from "redux/actions";
import { reducerCreator, multipleReducers } from "./meta";

export const clause = multipleReducers({
  initializers: {
    initialState: {
      clausesTotalCount: 0,
      clauses: [],
      manageClauses: {
        count: 0,
        limit: 10,
        rows: [],
      },
      lastClauseCreated: null,
      lastClauseUpdated: null,
      lastClauseDeleted: null,
      lastClauseMerged: null,
      lastClauseReorderDate: null,
    },
  },
  list: [
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.GET_CLAUSES,
        successFn: (action) => ({
          clauses: action.data.body.rows,
          clausesTotalCount: action.data.body.count,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.GET_MANAGE_CLAUSES,
        successFn: (action) => ({
          manageClauses: action.data.body,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.CREATE_CLAUSE,
        successFn: (action) => ({
          lastClauseCreated: action.data,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.UPDATE_CLAUSE,
        successFn: (action) => ({
          lastClauseUpdated: action.data,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.DELETE_CLAUSE,
        successFn: (action) => ({
          lastClauseDeleted: action.data,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.MERGE_CLAUSES,
        successFn: (action) => ({
          lastClauseMerged: action.data,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.REORDER_CLAUSES,
        successFn: () => ({
          lastClauseReorderDate: Date.now(),
        }),
      },
    },
  ],
});
