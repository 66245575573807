import React, { useEffect, useState } from "react";
import { toString } from "lodash";
import { connect } from "react-redux";
import { Button, Form, Row, Col, Badge } from "react-bootstrap";
import { Field, reduxForm } from "redux-form";
import { reduxFormInput } from "components/form/ReduxForm";
import { updateProfile } from "redux/actions";
import Spacer from "components/layout/Spacer";
import Loader from "components/ui/Loader";

const TimeOptions = [
  { name: "12 AM", value: "0" },
  { name: "1 AM", value: "1" },
  { name: "2 AM", value: "2" },
  { name: "3 AM", value: "3" },
  { name: "4 AM", value: "4" },
  { name: "5 AM", value: "5" },
  { name: "6 AM", value: "6" },
  { name: "7 AM", value: "7" },
  { name: "8 AM", value: "8" },
  { name: "9 AM", value: "9" },
  { name: "10 AM", value: "10" },
  { name: "11 AM", value: "11" },
  { name: "12 PM", value: "12" },
  { name: "1 PM", value: "13" },
  { name: "2 PM", value: "14" },
  { name: "3 PM", value: "15" },
  { name: "4 PM", value: "16" },
  { name: "5 PM", value: "17" },
  { name: "6 PM", value: "18" },
  { name: "7 PM", value: "19" },
  { name: "8 PM", value: "20" },
  { name: "9 PM", value: "21" },
  { name: "10 PM", value: "22" },
  { name: "11 PM", value: "23" },
];

const PREF_OPTOINS = [
  {
    name: "instant",
    value: { type: "instant", preferences: [] },
    label:
      "Receive an email with each notice (this could result in a lot of emails)",
  },
  {
    name: "hourly",
    value: [{ schedule: "hourly_once", cron: "0 0 */1 * * *" }],
    label:
      "Receive No More than one notice email each hour for each deal(Recommended)",
    subLabel:
      "You will receive an update once per hour with all updates that have occurred during the prior hour. If no updates have occurred, you will not receive an update email.",
  },
  {
    name: "custom",
    value: [],
    label: " Customize your number of Notices per day for each deal (EST) ",
    subLabel:
      "You will receive at the times you choose if there is an update to your deal. If there has been no update, you will not receive an update email at that time.",
  },
];
function CustomSchedule({ schedules, updateSchedule }) {
  const update = (name, slot) => {
    const slots = [...schedules];
    const index = slots.findIndex((slot) => slot.schedule === name);
    if (index > -1) {
      slots.splice(index, 1); // remove schedule if its already there
    } else slots.push({ schedule: name, cron: slot });
    updateSchedule(slots);
  };
  return (
    <div className="custom-email-preference">
      {TimeOptions.map((option) => {
        const isSelected = schedules.findIndex(
          (item) => item.schedule === option.name
        );
        return (
          <Button
            className={isSelected > -1 ? "selected-btn" : "non-selected-btn"}
            onClick={() => update(option.name, option.value)}
          >
            {option.name}
          </Button>
        );
      })}
    </div>
  );
}
const EmailPreference = (props) => {
  const [selectedValue, setSelectedValue] = useState("hourly");
  const [schedules, setSchedules] = useState([]);
  const { emailPreferences } = props.initialValues;
  useEffect(() => {
    if (emailPreferences) {
      setSelectedValue(emailPreferences.type);
      if (emailPreferences.type === "custom") {
        setSchedules(emailPreferences.preferences);
      }
    }
  }, [emailPreferences]);

  const getPreferences = (data) => {
    if (data === "hourly") {
      return {
        type: "hourly",
        preferences: [{ schedule: "hourly_once", cron: "0 0 */1 * * *" }],
      };
    }
    if (data === "instant") {
      return {
        type: "instant",
        preferences: [],
      };
    }
    if (data === "custom") {
      return {
        type: "custom",
        preferences: schedules,
      };
    }
  };
  const submitFn = (data) => {
    const { address, city, state, zipCode, country } = props.profile;
    const formData = new FormData();

    const emailPreferences = getPreferences(selectedValue);
    formData.append("address", address);
    formData.append("city", city);
    formData.append("state", state);
    formData.append("zipCode", zipCode);
    formData.append("country", country);
    formData.append("accountActivated", true);
    formData.append("receiveEmailsTermSt", data.receiveEmailsTermSt);
    formData.append("receiveEmailsForDeals", true);
    formData.append("emailPreferences", JSON.stringify(emailPreferences));
    updateProfile(formData);
  };

  const updateSchedule = (schedules) => {
    if (schedules.length > 12) {
      return;
    }
    setSchedules(schedules);
  };

  const onChange = (value) => {
    if (value !== "custom") {
      setSchedules([]);
    }
    setSelectedValue(value);
  };
  const { handleSubmit, action } = props;
  const isFetching = action.UPDATE_PROFILE.isFetching;
  const error =
    schedules.length === 0
      ? "Please select at least one Schedule!"
      : schedules.length > 11
      ? "You can only select 12 schedules per day for each deal"
      : "";
  return (
    <>
      {isFetching && <Loader />}
      <Row>
        <Col xs="12">
          <Spacer size={10} />
          <div>
            <h4>System Emails</h4>{" "}
            <p style={{ fontSize: "14px" }}>
              *System emails cannot be turned off, but you can manage how often
              you receive them
            </p>
          </div>
          <Spacer size={15} />
        </Col>
        <Col xs={12}>
          Choose an Option for the pace of deal notification emails
        </Col>
        <Spacer size={30} />
      </Row>
      <Form className="no-border no-padding" onSubmit={handleSubmit(submitFn)}>
        <Row>
          <Col xs="12" sm="10">
            {PREF_OPTOINS.map((item) => {
              const customOptonLable = (
                <p>
                  {item.label}
                  {schedules.length > 0 && (
                    <Badge pill variant="secondary">
                      {schedules.length}
                    </Badge>
                  )}
                </p>
              );
              return (
                <>
                  <Form.Check
                    key={item.name}
                    inline
                    type="radio"
                    label={
                      item.name === "custom" ? customOptonLable : item.label
                    }
                    name={item.name}
                    id={item.name}
                    checked={selectedValue === item.name}
                    onChange={(e) => onChange(item.name)}
                    className="m-2"
                  />
                  <div style={{ fontSize: "14px", marginLeft: "40px" }}>
                    {item.subLabel}
                  </div>

                  {selectedValue === "custom" && item.name === "custom" && (
                    <>
                      <div style={{ color: "red" }}>{error}</div>
                      <CustomSchedule
                        schedules={schedules}
                        updateSchedule={updateSchedule}
                      />
                    </>
                  )}
                </>
              );
            })}
            <Spacer size={5} />
          </Col>
        </Row>
        <Row>
          <Col>
            <h4>Other Emails</h4>
          </Col>
        </Row>
        <Spacer size={20} />
        <Row>
          <Col xs="12" sm="10">
            <Field
              className="form-control"
              component={reduxFormInput}
              name="receiveEmailsTermSt"
              type="checkbox"
              subLabel={
                <p style={{ fontSize: "14px", marginLeft: "40px" }}>
                  We recommend leaving this on. We do not send these emails with
                  high frequency, but they can be helpful to receive. You can
                  turn this off at anytime if you receive too many emails from
                  us.
                </p>
              }
              label="Receive emails with TermStreet Updates, special offers, announcements, etc. (Recommended)"
            />
            <Spacer size={5} />
          </Col>
        </Row>
        <Row>
          <Col xs="12" sm="6">
            <Spacer size={50} />
            <Button type="submit" variant="primary">
              Update Notifications
            </Button>
          </Col>
        </Row>
      </Form>

      <Spacer size={50} />
    </>
  );
};

function mapStateToProps(state) {
  const { receiveEmailsForDeals, receiveEmailsTermSt, emailPreferences } =
    state.auth.whoami;

  return {
    action: state.action,
    initialValues: {
      receiveEmailsForDeals:
        toString(receiveEmailsForDeals) === "true" ? true : false,
      receiveEmailsTermSt:
        toString(receiveEmailsTermSt) === "true" ? true : false,
      emailPreferences,
    },
    profile: state.auth.whoami,
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "emailPreference",
    enableReinitialize: true,
  })(EmailPreference)
);
