import React from "react";
import { Avatar, Space } from "antd";
import Spacer from "components/layout/Spacer";
import { getInitials } from "utils/commonUtils";

const BorrowerDealTeam = ({ deal }) => {
  const { teamId } = deal;
  const { companyId, teamMembers = [] } = teamId;
  const teamLeadMember = teamMembers.find(
    (member) => member.globalRole === "Team Leader"
  );
  const teamLead = teamLeadMember?.userId;
  const teamLeadFullname = teamLead
    ? `${teamLead.firstName} ${teamLead.lastName}`
    : "-";
  const teamLeadContactNumber = teamLead?.phoneLandline?.trim()
    ? teamLead?.phoneLandline
    : teamLead?.phoneMobile?.trim();

  return (
    <div>
      <h4>Borrower Deal Team</h4>
      <Spacer size={5} />
      <div className="border-bottom" />
      <div className="tw-flex tw-justify-between tw-flex-wrap tw-py-2">
        <Space align="start">
          <Avatar size={46} shape="circle" src={teamLead?.profileImageXS}>
            {getInitials(teamLeadFullname)}
          </Avatar>
          <div className="font-14">
            <p>{teamLeadFullname}</p>
            <p>{teamLead?.email || "-"}</p>
            <p>{teamLeadContactNumber}</p>
          </div>
        </Space>
        {companyId.companyLogoXS && (
          <img
            src={companyId.companyLogoXS}
            alt={companyId.companyName}
            className="tw-w-[80px] tw-h-full"
          />
        )}
      </div>
    </div>
  );
};

export default BorrowerDealTeam;
