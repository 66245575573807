import React from "react";
import { createPortal } from "react-dom";
import { Draggable } from "react-beautiful-dnd";

export default class DraggablePortal extends React.PureComponent {
  getDragEl() {
    return document.getElementById("rbd-draggable");
  }
  optionalPortal(styles, element) {
    if (styles.position === "fixed") {
      const _dragEl = this.getDragEl();
      return createPortal(element, _dragEl);
    }
    return element;
  }
  render() {
    const { draggableId, index, Component, ...rest } = this.props;
    return (
      <Draggable draggableId={draggableId} index={index}>
        {(provided, snapshot) =>
          this.optionalPortal(
            provided.draggableProps.style,
            <Component provided={provided} snapshot={snapshot} {...rest} />
          )
        }
      </Draggable>
    );
  }
}
