import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Container, Col, Row, Button, Dropdown } from "react-bootstrap";
import { getTermsheets } from "redux/actions";
import Loader from "components/ui/Loader";
import Spacer from "components/layout/Spacer";
import Paginate from "components/ui/Paginate";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
const PAGE_LIMIT = 5;

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <Button
    ref={ref}
    variant="inbox-action"
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </Button>
));
const TermSheetsList = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const getAllTermsheets = (page = currentPage) => {
    getTermsheets(props.match.params.dealId, false, page, PAGE_LIMIT);
    setCurrentPage(page);
  };
  useEffect(() => {
    getAllTermsheets();
  }, []);
  const { isFetching } = props.action.GET_TERMSHEETS;
  const { dealId } = props.match.params;
  return (
    <Container>
      <Spacer size={20} />
      {isFetching && <Loader />}
      {(() => {
        if (!props.termsheets.length && !isFetching) {
          return (
            <Row>
              <Col xs="12">
                <p className="text-danger text-center">
                  Deal doesn't have any term sheets yet!
                  {(props.whoami.role === "Lender" ||
                    props.whoami.role === "Admin") && (
                    <Button variant="link">
                      <Link to={`/deal/${dealId}/termsheet/choice`}>
                        Create New
                      </Link>
                    </Button>
                  )}
                </p>
              </Col>
            </Row>
          );
        }
        return (
          <>
            <Row
              style={{
                borderBottom: "1px solid #e0dede",
                paddingBottom: 5,
                paddingTop: 5,
              }}
            >
              <Col xs={4} md={4}>
                <b>Term Sheet Name</b>
              </Col>
              <Col xs={2} md={2}>
                <b>Quotes</b>
              </Col>
              <Col xs={2} md={2}>
                <b>Created At</b>
              </Col>
              <Col xs={2} md={2}>
                <b>Updated At</b>
              </Col>
              <Col xs={2} md={2} className="text-right">
                {(props.whoami.role === "Lender" ||
                  props.whoami.role === "Admin") && (
                  <Link to={`/deal/${dealId}/termsheet/choice`}>
                    <Button variant="primary">
                      <FontAwesomeIcon icon={faPlusCircle} /> New
                    </Button>
                  </Link>
                )}
              </Col>
            </Row>
            {props.termsheets.map((termsheet) => (
              <Row
                key={termsheet._id}
                style={{
                  borderBottom: "1px solid #e0dede",
                  paddingBottom: 5,
                  paddingTop: 5,
                }}
              >
                <Col xs={4} md={4}>
                  {termsheet.termsheetName}
                </Col>
                <Col xs={2} md={2}>
                  --
                </Col>
                <Col xs={2} md={2}>
                  {moment.utc(termsheet.createdAt).format("MM/DD/YY HH:MM")}
                </Col>
                <Col xs={2} md={2}>
                  {moment.utc(termsheet.updatedAt).format("MM/DD/YY  HH:MM")}
                </Col>
                <Col xs={2} md={2} className="text-right">
                  <Dropdown className="d-inline-block" drop="left">
                    <Dropdown.Toggle as={CustomToggle}>
                      <FontAwesomeIcon icon={faEllipsisH} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Link
                        to={`/deal/${dealId}/termsheet/${termsheet._id}`}
                        className="dropdown-item"
                      >
                        View
                      </Link>
                      {props.whoami.role === "Lender" && (
                        <Link
                          to={`/deal/${dealId}/termsheet/${termsheet._id}/quotes`}
                          className="dropdown-item"
                        >
                          Edit
                        </Link>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              </Row>
            ))}
          </>
        );
      })()}
      <Spacer size={20} />
      <Paginate
        list={props.termsheets}
        listTotalCount={props.termsheetsTotalCount}
        listOffset={PAGE_LIMIT}
        page={currentPage}
        previousPage={(p) => {
          getAllTermsheets(p);
        }}
        nextPage={(p) => {
          getAllTermsheets(p);
        }}
      />
    </Container>
  );
};
const mapStateToProps = (state) => ({
  termsheets: state.termsheet.termsheets,
  termsheetsTotalCount: state.termsheet.termsheetsTotalCount,
  action: state.action,
  whoami: state.auth.whoami,
});
export default connect(mapStateToProps)(TermSheetsList);
