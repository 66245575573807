import React from "react";
import { connect } from "react-redux";
import { Button, Form, Row, Col } from "react-bootstrap";
import { Field, reduxForm } from "redux-form";
import { reduxFormInput } from "components/form/ReduxForm";
import { required, noSpaces } from "utils/validation";
import Spacer from "components/layout/Spacer";

class CreateConfirmation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      disabled: true,
    };

    this.submitFn = this.submitFn.bind(this);
  }

  submitFn(data) {
    const { isDraft, onSubmit } = this.props;
    onSubmit({ ...data, isDraft });
  }

  render() {
    const { isDraft, onCancel, handleSubmit } = this.props;

    return (
      <Form className="no-border" onSubmit={handleSubmit(this.submitFn)}>
        <Row>
          <Col xs="12">
            <p>
              Your Term Sheet will be saved. You can get back to your term sheet
              by viewing your quotes from the dashboard.
            </p>
            <Spacer size={50} />
          </Col>

          <Col xs={{ span: 10, offset: 1 }}>
            <p>This term sheet will be saved in your account as:</p>
            <Spacer size={20} />

            <div className="d-flex align-items-center justify-content-between">
              <Field
                hideLabel
                disabled={this.state.disabled}
                className="form-control0"
                wrapperClassName="w-100"
                component={reduxFormInput}
                validate={[required, noSpaces]}
                name="name"
                type="text"
                placeholder="Termsheet Name"
              />
              <Button
                variant="primary outline border-0 text-nowrap ml-3 px-0"
                onClick={() => {
                  this.setState({ disabled: false });
                }}
              >
                edit name
              </Button>
            </div>

            <Spacer size={50} />
          </Col>

          <Col className="text-right" xs="12">
            <Button onClick={onCancel}>Cancel</Button>
            <Button type="submit" variant="tertiary" className="ml-3">
              {isDraft ? "Submit Draft" : "Submit"}
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    whoami: state.auth.whoami,
    initialValues: {
      name: props.data?.termsheetName || props.initialName,
    },
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "CreateConfirmation",
    enableReinitialize: true,
  })(CreateConfirmation)
);
