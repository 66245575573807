import React from "react";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { Accordion, Spinner, Button, Row, Col } from "react-bootstrap";
import {
  searchCompanies,
  updateDealLenders,
  getDealById,
  getFilteredLenders,
} from "redux/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import Company from "containers/admin/Company";
import PopupPrompt from "components/ui/PopupPrompt";
import AddToDeal from "./actions/AddToDeal";
import Loader from "components/ui/Loader";
import FilterCompanies from "containers/admin/update/FilterCompanies";
import { Link } from "react-router-dom";
import Paginate from "components/ui/Paginate";

const generatefilterQuery = (filters) => {
  let programFiltersQuery = "";

  if (filters.programTypes) {
    programFiltersQuery += `&programTypes=${filters.programTypes.label}`;
  }

  if (filters.lenderType) {
    programFiltersQuery += `&lenderType=${filters.lenderType.label}`;
  }

  if (filters.servicing) {
    programFiltersQuery += `&servicing=${filters.servicing.label}`;
  }

  if (filters.loanAmount) {
    programFiltersQuery += `&loanAmount=${filters.loanAmount}`;
  }

  if (filters.apartmentTypesList) {
    filters.apartmentTypesList.map((item) => {
      programFiltersQuery += `&apartmentTypesList=${item.label}`;

      return true;
    });
  }

  if (filters.officeTypesList) {
    filters.officeTypesList.map((item) => {
      programFiltersQuery += `&officeTypesList=${item.label}`;

      return true;
    });
  }

  if (filters.retailTypeList) {
    filters.retailTypeList.map((item) => {
      programFiltersQuery += `&retailTypeList=${item.label}`;

      return true;
    });
  }

  if (filters.industrialTypeList) {
    filters.industrialTypeList.map((item) => {
      programFiltersQuery += `&industrialTypeList=${item.label}`;

      return true;
    });
  }

  if (filters.hotelTypeList) {
    filters.hotelTypeList.map((item) => {
      programFiltersQuery += `&hotelTypeList=${item.label}`;

      return true;
    });
  }

  if (filters.seniorLivingList) {
    filters.seniorLivingList.map((item) => {
      programFiltersQuery += `&seniorLivingList=${item.label}`;

      return true;
    });
  }

  if (filters.otherPropertyDealTypesWeLike) {
    filters.otherPropertyDealTypesWeLike.map((item) => {
      programFiltersQuery += `&otherPropertyDealTypesWeLike=${item.label}`;

      return true;
    });
  }

  if (filters.fixedFloating) {
    filters.fixedFloating.map((item) => {
      programFiltersQuery += `&fixedFloating=${item.label}`;

      return true;
    });
  }

  if (filters.fixedRateLoanPrepay) {
    filters.fixedRateLoanPrepay.map((item) => {
      programFiltersQuery += `&fixedRateLoanPrepay=${item.label}`;

      return true;
    });
  }

  if (filters.floatingRateLoanPrepay) {
    filters.floatingRateLoanPrepay.map((item) => {
      programFiltersQuery += `&floatingRateLoanPrepay=${item.label}`;

      return true;
    });
  }

  if (filters.rateStructure) {
    programFiltersQuery += `&rateStructure=${filters.rateStructure.label}`;
  }

  if (filters.maxLTV) {
    programFiltersQuery += `&maxLTV=${filters.maxLTV.label.slice(0, 2)}%25`;
  }

  if (filters.competitiveLTV) {
    programFiltersQuery += `&competitiveLTV=${filters.competitiveLTV.label.slice(
      0,
      2
    )}%25`;
  }

  if (filters.geography) {
    filters.geography.map((item) => {
      programFiltersQuery += `&geography=${item.label}`;

      return true;
    });
  }

  if (filters.otherCarrots) {
    filters.otherCarrots.map((item) => {
      programFiltersQuery += `&otherCarrots=${item.label}`;

      return true;
    });
  }
  return programFiltersQuery;
};
class ManageLenders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companies: [],
      companiesTotal: 0,
      teamsByCompany: [],
      filters: {},
      option: "",
      companyToAdd: "",
      companyName: "",
      teamToAdd: "",
      dealId: "",
      pagination: {
        page: 1,
        listOffset: 25,
      },
      showPrompt: false,
      getDeal: false,
      activeExpandKey: null,
    };

    this.addToDeal = this.addToDeal.bind(this);
    this.handleShowPrompt = this.handleShowPrompt.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.deal !== this.props.deal) {
      const { deal } = this.props;

      if (this.state.getDeal) {
        this.setState({ dealId: deal._id, getDeal: false }, function () {
          this.addToDealFromPrompt({
            companyToAdd: this.state.companyToAdd,
            companyName: this.state.companyName,
            teamToAdd: this.state.teamToAdd,
          });
        });
      }
    }

    if (prevProps.companies !== this.props.companies) {
      const { companies, companiesTotal } = this.props;
      const teamsByCompany = companies
        .filter(
          (company) =>
            !this.state.teamsByCompany.some((e) => e.companyId === company._id)
        )
        .map((company) => ({
          companyId: company._id,
          teams: [],
        }));

      this.setState({
        teamsByCompany,
        companies,
        companiesTotal,
      });
    }
  }

  componentDidMount() {
    this.getCompanies();
  }

  getCompanies = () => {
    const { pagination } = this.state;
    searchCompanies({
      companyType: "Lender",
      page: pagination.page,
      limit: pagination.listOffset,
    });
  };
  handleShowPrompt(showPrompt) {
    this.setState({ showPrompt });
  }
  handlePagination = (page) => {
    const callback = isEmpty(this.state.filters)
      ? this.getCompanies
      : this.getCompWithFilters;
    this.setState(
      (prevState) => ({ pagination: { ...prevState.pagination, page } }),
      callback
    );
  };

  addToDeal(data) {
    this.setState(
      {
        companyToAdd: data.companyToAdd,
        companyName: data.companyName,
        teamToAdd: data.teamToAdd,
        option: "Deal",
      },
      function () {
        this.handleShowPrompt(true);
      }
    );
  }

  addToDealFromPrompt(data) {
    const { deal } = this.props;

    if (
      deal &&
      !deal.lendersAssigned.some(
        (lender) =>
          lender.companyId === data.companyToAdd &&
          lender.teamId === data.teamToAdd
      )
    ) {
      updateDealLenders(this.state.dealId, {
        add: data.companyToAdd,
        companyName: data.companyName,
        teamId: data.teamToAdd,
      });
    }
  }

  getCompWithFilters = () => {
    const { pagination, filters } = this.state;
    const programFilters = generatefilterQuery(filters);
    getFilteredLenders({
      programFilters,
      page: pagination.page,
      limit: pagination.listOffset,
    });
  };
  onSubmitFilters = (filters) => {
    if (Object.keys(filters).some((datum) => filters[datum] !== undefined)) {
      this.setState(
        {
          option: "",
          filters,
          pagination: { page: 1, listOffset: 25 },
        },
        this.getCompWithFilters
      );
    }
    this.handleShowPrompt(false);
  };
  render() {
    const { action } = this.props;
    const { companies, pagination, companiesTotal } = this.state;

    if (
      !(
        action.SEARCH_COMPANIES.isFetching ||
        action.GET_FILTERED_LENDERS.isFetching
      )
    ) {
      return (
        <>
          <Row className="mb-3 pt-3" noGutters={true}>
            <Col className="d-flex" xs="12">
              <div style={{ flex: 1 }} className="text-right">
                <Link to="/company/create?type=Lender">
                  <Button className="pt-2 pb-2 mr-2" variant="primary">
                    Add Lender
                  </Button>
                </Link>
                <Button
                  className="pt-2 pb-2"
                  onClick={() => {
                    this.handleShowPrompt(true);
                    this.setState({ option: "Filter" });
                  }}
                  variant="tertiary"
                >
                  <FontAwesomeIcon className="font-14 mr-3" icon={faFilter} />
                  Filter
                </Button>
              </div>
            </Col>
          </Row>
          <Row>
            {(action.ASSIGN_TEAM_TO_DEAL.isFetching ||
              action.UPDATE_DEAL_LENDERS.isFetching) && <Loader files />}
            {this.state.option === "Deal" && (
              <PopupPrompt
                promptTitle="Add to Deal"
                promptBody={
                  <AddToDeal
                    companyToAdd={this.state.companyToAdd}
                    companyName={this.state.companyName}
                    teamToAdd={this.state.teamToAdd}
                    onSubmit={(dealId) => {
                      if (dealId) {
                        this.setState(
                          { option: "", getDeal: true },
                          function () {
                            getDealById(dealId);
                          }
                        );
                      }

                      this.handleShowPrompt(false);
                    }}
                  />
                }
                show={this.state.showPrompt}
                onCancel={() => {
                  this.setState({
                    option: "",
                    dealId: "",
                    companyToAdd: "",
                    companyName: "",
                    teamToAdd: "",
                  });
                  this.handleShowPrompt(false);
                }}
                onHide={() => {
                  this.setState({
                    option: "",
                    dealId: "",
                    companyToAdd: "",
                    companyName: "",
                    teamToAdd: "",
                  });
                  this.handleShowPrompt(false);
                }}
              />
            )}
            {this.state.option === "Filter" && (
              <PopupPrompt
                promptTitle="Filter Lenders"
                promptBody={
                  <FilterCompanies
                    onSubmit={(filters) => {
                      this.onSubmitFilters(filters);
                    }}
                    companyType="Lender"
                    pagination={pagination}
                    onResetFilters={() => this.setState({ filters: {} })}
                    filters={this.state.filters}
                  />
                }
                show={this.state.showPrompt}
                onCancel={() => {
                  this.setState({
                    option: "",
                  });
                  this.handleShowPrompt(false);
                }}
                onHide={() => {
                  this.setState({
                    option: "",
                  });
                  this.handleShowPrompt(false);
                }}
              />
            )}
            <Accordion className="w-100" activeKey={this.state.activeExpandKey}>
              {companies.map((company) => {
                return (
                  <Company
                    company={company}
                    key={company._id}
                    type="Lender"
                    onAddToDeal={(data) => {
                      this.addToDeal(data);
                    }}
                    setExpandedKey={(key) =>
                      this.setState({ activeExpandKey: key })
                    }
                  />
                );
              })}
            </Accordion>
            <Paginate
              {...pagination}
              list={companies}
              listTotalCount={companiesTotal}
              previousPage={this.handlePagination}
              nextPage={this.handlePagination}
            />
          </Row>
        </>
      );
    } else {
      return (
        <>
          <Row className="mb-3 pt-3" noGutters={true}>
            <Col className="d-flex" xs="12">
              <div style={{ flex: 1 }} className="text-right">
                <Link to="/company/create?type=Lender">
                  <Button className="pt-2 pb-2 mr-2" variant="primary">
                    Add Lender
                  </Button>
                </Link>
                <Button
                  className="pt-2 pb-2"
                  onClick={() => {
                    this.handleShowPrompt(true);
                    this.setState({ option: "Filter" });
                  }}
                  variant="tertiary"
                >
                  <FontAwesomeIcon className="font-14 mr-3" icon={faFilter} />
                  Filter
                </Button>
              </div>
            </Col>
          </Row>
          <div className="text-center">
            <Spinner animation="border" role="status" variant="primary">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        </>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    action: state.action,
    teams: state.team.teams,
    deal: state.deal.deal,
    updateDealLenders: state.deal.updateDealLenders,
    companies: state.admin.companies,
    companiesTotal: state.admin.companiesTotal,
  };
}

export default connect(mapStateToProps)(ManageLenders);
