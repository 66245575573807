import React from "react";
import { connect } from "react-redux";
import { Button, Form, Row, Col } from "react-bootstrap";
import { Field, reduxForm } from "redux-form";
import {
  reduxFormInput,
  reduxFormSelect,
  reduxFormTextarea,
} from "components/form/ReduxForm";
import { required, noSpaces } from "utils/validation";
import Spacer from "components/layout/Spacer";
import { lenderAvailabilityOptions } from "containers/admin/lookup";

class CreateSectionConfirmation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      disabled: true,
    };

    this.submitFn = this.submitFn.bind(this);
  }

  submitFn(data) {
    const { onSubmit } = this.props;
    onSubmit(data);
  }

  render() {
    const { action, onCancel, handleSubmit } = this.props;
    const isProcessing = action.CREATE_SECTION.isFetching;

    return (
      <Form className="no-border" onSubmit={handleSubmit(this.submitFn)}>
        <Row>
          <Col xs="12">
            <p>
              You are about to save a section template to your account. Please
              make a couple selections to make sure that you can find and use
              this Section in the future.
            </p>
            <Spacer size={20} />
          </Col>

          <Col xs={{ span: 10, offset: 1 }}>
            <p>This Section will be saved in you account as:</p>
            <Spacer size={5} />
            <div className="d-flex align-items-center justify-content-between">
              <Field
                hideLabel
                disabled={this.state.disabled}
                className="form-control0"
                wrapperClassName="w-100"
                component={reduxFormInput}
                validate={[required, noSpaces]}
                name="sectionName"
                type="text"
                placeholder="Section 1 - [Todays Date]"
              />
              <Button
                variant="primary outline border-0 text-nowrap ml-3 px-0"
                onClick={() => {
                  this.setState({ disabled: false });
                }}
              >
                edit name
              </Button>
            </div>
            <Spacer size={20} />
          </Col>

          <Col xs={{ span: 10, offset: 1 }}>
            <p>How would you like to share this template?</p>
            <Spacer size={5} />
            <Field
              className="form-control"
              component={reduxFormSelect}
              validate={[required]}
              name="adminAvailability"
              type="select"
              options={lenderAvailabilityOptions}
              label=""
            />
            <Spacer size={20} />
          </Col>

          <Col xs={{ span: 10, offset: 1 }}>
            <p>Notes on when this term sheet should be used</p>
            <Spacer size={5} />
            <Field
              className="form-control"
              component={reduxFormTextarea}
              validate={[noSpaces]}
              name="notes"
              type="textarea"
              rows="4"
              label=""
              placeholder="Write your notes here..."
            />
            <Spacer size={20} />
          </Col>

          <Col className="text-right" xs="12">
            <Spacer size={20} />
            <Button onClick={onCancel}>Cancel</Button>
            <Button
              disabled={isProcessing}
              type="submit"
              variant="tertiary"
              className="ml-3"
            >
              {isProcessing ? "Saving Section..." : "Save Section"}
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    action: state.action,
    whoami: state.auth.whoami,
    initialValues: {
      sectionName: `Section - ${new Date().toDateString()}`,
      adminAvailability: lenderAvailabilityOptions.find(
        (e) => e.value === "MY_TEAM"
      ),
    },
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "CreateSectionConfirmation",
    enableReinitialize: true,
  })(CreateSectionConfirmation)
);
