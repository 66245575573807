import React from "react";
import { connect } from "react-redux";
import { Button, Form, Row, Col } from "react-bootstrap";
import { Field, reduxForm } from "redux-form";
import { reduxFormSelect } from "components/form/ReduxForm";
import { required } from "utils/validation";
import { getDealById, searchCompanies } from "redux/actions";
import Spacer from "components/layout/Spacer";
import debounce from "lodash/debounce";

class AddLender extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lenders: [],
    };

    this.submitFn = this.submitFn.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.companies !== this.props.companies) {
      const { companies, deal } = this.props;

      let lenderCompanies = companies;

      deal &&
        deal.lendersAssigned.map((assignedLender) => {
          lenderCompanies = lenderCompanies.filter(
            (lender) => lender._id !== assignedLender.companyId
          );

          return true;
        });

      this.setState({
        lenders: lenderCompanies.map((company) => ({
          label: company.companyName,
          value: company._id,
        })),
      });
    }
  }

  componentDidMount() {
    const { dealId } = this.props;

    getDealById(dealId);
  }

  submitFn(data) {
    const { onSubmit } = this.props;

    onSubmit(data.add);
  }

  handleSearchCompanies = (search) => {
    searchCompanies({
      companyType: "Lender",
      search,
      page: 1,
      limit: 20,
    });
  };

  debouncedSearchCompanies = debounce(this.handleSearchCompanies, 750);

  handleInputChange = (inputValue) => {
    if (inputValue?.length >= 3) {
      this.debouncedSearchCompanies(inputValue);
    }
  };

  render() {
    const { action, handleSubmit, onSubmit } = this.props;

    return (
      <Form
        className="no-padding no-border"
        onSubmit={handleSubmit(this.submitFn)}
      >
        <Row>
          <Col xs="12">
            <h4 className="bold">Search all lenders</h4>
            <Spacer size={20} />
          </Col>
          <Col xs="12">
            <Field
              className="form-control"
              component={reduxFormSelect}
              validate={[required]}
              name="add"
              type="select"
              options={this.state.lenders}
              isLoading={action.SEARCH_COMPANIES.isFetching}
              placeholder="Type to search lenders..."
              label="*Select lenders"
              isMulti={true}
              onInputChange={this.handleInputChange}
            />
            <Spacer size={20} />
          </Col>
          {this.state.lenders.length > 0 && (
            <Col className="text-right" xs="12">
              <Button variant="dark" onClick={onSubmit}>
                Cancel
              </Button>
              <Button className="ml-3" type="submit">
                Add
              </Button>
            </Col>
          )}
        </Row>
      </Form>
    );
  }
}

function mapStateToProps(state) {
  return {
    action: state.action,
    companies: state.admin.companies,
    teams: state.team.teams,
    deal: state.deal.deal,
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "AddLender",
    enableReinitialize: true,
  })(AddLender)
);
