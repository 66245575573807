import React, { useEffect, useRef } from "react";
import InboxMessageContent from "./InboxMessageContent";
import InboxReplies from "../inboxReplies/InboxReplies";
import { connect } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import Socket from "utils/Socket";
import { getConversationById } from "redux/actions";

const InboxMessageDetails = ({ messageDetails }) => {
  const match = useRouteMatch();
  const dealId = match.params.id;
  const conversationSubscriptionId = useRef(null);

  useEffect(() => {
    if (messageDetails) {
      conversationSubscriptionId.current = Socket.subscribe(
        `/deal/${dealId}/conversations/${messageDetails._id}`,
        (socketData) => {
          if (socketData.action === "REPLY_MESSAGE") {
            getConversationById(messageDetails._id);
          }
        }
      );
    }
    return () => {
      Socket.unsubscribe(conversationSubscriptionId.current);
    };
  }, [messageDetails]);

  const messages = messageDetails?.messages || [];
  const [, ...replies] = messages;

  return (
    <section className="flex flex-column" style={{ width: "70%" }}>
      {messageDetails && (
        <>
          <InboxMessageContent key={messageDetails._id} />
          <InboxReplies messages={replies} />
        </>
      )}
    </section>
  );
};

function mapStateToProps(state) {
  return {
    messageDetails: state.conversation.messageDetails,
  };
}

export default connect(mapStateToProps)(InboxMessageDetails);
