import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Button,
  Card,
  Image,
  Badge,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import CompanyPic from "images/company-placeholder.png";
import { Field } from "redux-form";
import { reduxFormInput } from "components/form/ReduxForm";
import Spacer from "components/layout/Spacer";
import PassDealForm from "containers/deals/PassDealForm";
import { LenderStatisticsBlock } from "containers/deals/LenderStatistics";
import LenderShortInfo from "./LenderShortInfo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const DealPassInfo = ({ lender }) => {
  const {
    reasonForPassing = "testing the passed deal lenders section",
    ifIsPassedReason,
  } = lender;

  return (
    <>
      <div className="mr-3">
        <b style={{ fontSize: "20px" }}>Passed</b>
        {(ifIsPassedReason || true) && (
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id={`tooltip-top`}>
                testing the passed deal lenders section
              </Tooltip>
            }
          >
            <FontAwesomeIcon icon={faInfoCircle} style={{ fontSize: 17 }} />
          </OverlayTrigger>
        )}
      </div>
      <div>
        {reasonForPassing?.map((reason) => {
          return (
            <Badge variant="warning" pill text="dark" className="mr-2">
              {reason.label}
            </Badge>
          );
        })}
      </div>
    </>
  );
};

const Lender = (props) => {
  const {
    bulkSelected,
    company,
    handleFieldClick,
    handleSendMessage,
    handleTouchStart,
    handleTouchEnd,
    handleMouseDown,
    handleMouseUp,
    handleMouseLeave,
    handleRemoveLender,
    dealId,
    stat,
    lender = {},
    getTeamsByCompany,
  } = props;
  const [primaryTeam, setPrimaryTeam] = useState({});

  useEffect(() => {
    getTeamsByCompany(company, (teams, primaryTeams) => {
      const dealPrimaryTeam = primaryTeams?.find(
        (team) => team._id === lender?.teamId
      );
      setPrimaryTeam(dealPrimaryTeam);
    });
  }, []);

  const fieldStyle = bulkSelected.some(
    (selected) => selected.id === company._id
  )
    ? {
        boxShadow: "0 4px 9px 0 rgba(0, 0, 0, 0.25)",
        display: "inline-flex",
      }
    : {};
  const cardStyle = bulkSelected.some((selected) => selected.id === company._id)
    ? { marginLeft: "45px" }
    : {};

  return (
    <div className="action-wrapper">
      <Field
        style={fieldStyle}
        className="form-control"
        component={reduxFormInput}
        name={"selectCompany" + company._id}
        type="checkbox"
        onClick={(e) => handleFieldClick(e, company)}
        onKeyDown={(e) =>
          e.keyCode === 13
            ? e.target.parentNode.click(e.target.parentNode)
            : null
        }
      />
      <Card
        data-id={company._id}
        className={`lender-card ${
          lender.isPassed ? "lender-passed" : ""
        } pt-2 pb-2`}
        style={cardStyle}
        onTouchStart={(e) => handleTouchStart(e, company)}
        onTouchEnd={handleTouchEnd}
        onMouseUp={handleMouseUp}
        onMouseDown={(e) => handleMouseDown(e, company)}
        onMouseLeave={handleMouseLeave}
      >
        <Card.Body>
          <Row noGutters={true}>
            <Col xs="3" md="1">
              <Image
                className="d-inline-block mobile-img"
                src={
                  company && company.companyLogo
                    ? company.companyLogo
                    : CompanyPic
                }
                alt={company?.companyName + " company picture."}
                width="70%"
                height="auto"
              />
            </Col>
            <Col xs="10" md="5">
              <LenderShortInfo company={company} primaryTeam={primaryTeam} />
            </Col>
            <Col
              className="d-flex align-items-center justify-content-end"
              xs="10"
              md="5"
            >
              {lender.isPassed ? (
                <DealPassInfo lender={lender} />
              ) : (
                <>
                  <Button
                    className="fake-link mr-3"
                    variant="fake-link"
                    onClick={() => handleRemoveLender(company._id)}
                  >
                    Remove
                  </Button>
                  <PassDealForm companyId={company._id} dealId={dealId}>
                    <Button className="fake-link mr-3" variant="fake-link">
                      Pass
                    </Button>
                  </PassDealForm>
                </>
              )}

              <Button
                onClick={() => handleSendMessage(company)}
                className="d-inline"
              >
                Send Message
              </Button>
            </Col>
          </Row>
          <hr />
          <LenderStatisticsBlock stat={stat} />
        </Card.Body>
      </Card>
      <Spacer size={20} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  action: state.action,
});

export default connect(mapStateToProps)(Lender);
