import React from "react";

// Agent Routes
import Dashboard from "containers/auth/Dashboard";
import Profile from "containers/profile/view/Profile";
import AccountManagement from "containers/profile/update/AccountManagement";
import Company from "containers/company/view/Company";
import CompanyCreate from "containers/company/CompanyCreate";
import CompanyUpdate from "containers/company/update/CompanyUpdate";
import Program from "containers/program/view/Program";
import ProgramCreateEdit from "containers/program/ProgramCreateEdit";
import Team from "containers/team/view/Team";
import TeamUpdate from "containers/team/update/TeamUpdate";
import TeamCreate from "containers/team/TeamCreate";
import FileExplorer from "containers/deals/FileManager/FileExplorer";
import TermsheetChoice from "containers/termsheet/TermsheetChoice";
import ProvideTermsheetName from "containers/termsheet/ProvideTermsheetName";
import SelectSections from "containers/termsheet/SelectSections";
import EditTermsheet from "containers/termsheet/EditTermsheet";
import DealTeamNotifications from "containers/deals/update/DealTeamNotifications";
import TermSheetsList from "containers/termsheet/TermSheetsList";
import QuoteMatrix from "containers/quotes/QuoteMatrix";
import TermsheetTemplates from "containers/termsheet/TermsheetTemplates";
import ManageTermsheetAndClause from "containers/admin/ManageTermsheetAndClause";
import SpecialtySectionCreate from "containers/section/SpecialtySectionCreate";
import SpecialtySectionChoice from "containers/section/SpecialtySectionChoice";
import TermsheetCreateEdit from "containers/termsheet/TermsheetCreateEdit";
import QuoteSummary from "containers/termsheet/QuoteSummary";
import AccountPreferences from "containers/profile/update/AccountPreferences";
import EmailPreference from "containers/profile/update/EmailPreference";
import TermsheetPreview from "containers/termsheet/TermsheetPreview";
import CompareQuotes from "containers/termsheet/CompareQuotes";
import DealPdfContainer from "containers/deals/pdf/DealPdfContainer";
import InboxLayout from "containers/communicationsV2/InboxLayout";
import DealV2 from "containers/deals/view/DealV2";
import CommsPreferences from "containers/profile/update/CommsPreferences";

const lenderRoutes = [
  {
    path: "/dashboard",
    component: Dashboard,
    isExact: true,
    title: "Dashboard",
    searchParam: "deals",
  },
  {
    path: "/profile/view",
    component: Profile,
    isExact: true,
    title: "User Profile",
    searchParam: "none",
  },
  {
    path: "/profile/edit",
    component: AccountManagement,
    isExact: true,
    title: "Edit User Profile",
    searchParam: "none",
  },
  {
    path: "/profile/accountpreferences",
    component: AccountPreferences,
    isExact: true,
    title: "Account Management",
    searchParam: "none",
  },
  {
    path: "/profile/emailpreferences",
    component: EmailPreference,
    isExact: true,
    title: "Email Preferences",
    searchParam: "none",
  },
  {
    path: "/profile/commspreferences",
    component: CommsPreferences,
    isExact: true,
    title: "Comms Preferences",
    searchParam: "none",
  },
  {
    path: "/company/create",
    component: CompanyCreate,
    isExact: true,
    title: "Company Create",
    searchParam: "none",
  },
  {
    path: "/company/profile/:id",
    component: Company,
    isExact: true,
    title: "Company Profile",
    searchParam: "none",
  },
  {
    path: "/company/edit/:id",
    component: CompanyUpdate,
    isExact: true,
    title: "Company Update",
    searchParam: "none",
  },
  {
    path: "/program/create",
    component: ProgramCreateEdit,
    isExact: true,
    title: "Program Create",
    searchParam: "none",
  },
  {
    path: "/program/view/:id",
    component: Program,
    isExact: true,
    title: "Program View",
    searchParam: "none",
  },
  {
    path: "/program/edit/:id",
    component: (props) => <ProgramCreateEdit {...props} isUpdate />,
    isExact: true,
    title: "Program Update",
    searchParam: "none",
  },
  {
    path: "/team/view/:id",
    component: Team,
    isExact: true,
    title: "Team View",
    searchParam: "none",
  },
  {
    path: "/team/edit/:id",
    component: TeamUpdate,
    isExact: true,
    title: "Team Edit",
    searchParam: "none",
  },
  {
    path: "/team/create",
    component: TeamCreate,
    isExact: true,
    title: "Team Create",
    searchParam: "none",
  },
  {
    path: "/deal/view/:id",
    component: DealV2,
    isExact: true,
    title: "Deal",
    searchParam: "none",
  },
  {
    path: "/deal/pdf/:id",
    component: DealPdfContainer,
    isExact: true,
    title: "Deal PDF",
    searchParam: "none",
  },
  {
    path: "/deal/inbox/:id",
    component: InboxLayout,
    isExact: true,
    title: "Inbox",
    searchParam: "inbox",
  },
  {
    path: "/deal/:id/team",
    component: DealTeamNotifications,
    isExact: true,
    title: "Team Notifications",
    searchParam: "none",
  },
  {
    path: "/deal/files/:id",
    component: FileExplorer,
    isExact: true,
    title: "File Manager",
    searchParam: "files",
  },
  {
    path: "/deal/:dealId/termsheet",
    component: TermSheetsList,
    isExact: true,
    title: "Term Sheets",
    searchParam: "quotes",
  },
  {
    path: "/deal/:dealId/quote-summary",
    component: QuoteSummary,
    isExact: true,
    title: "Quote Summary",
  },
  {
    path: "/deal/:dealId/compare-quotes",
    component: CompareQuotes,
    isExact: true,
    title: "Compare Quotes",
  },
  {
    path: "/deal/:dealId/termsheet/templates",
    component: TermsheetTemplates,
    isExact: true,
    title: "Term Sheet Templates",
    searchParam: "none",
  },
  {
    path: "/deal/:dealId/termsheet/choice",
    component: TermsheetChoice,
    isExact: true,
    title: "How would you like to start your term sheet?",
    searchParam: "none",
  },
  {
    path: "/deal/:dealId/termsheet/create",
    component: ProvideTermsheetName,
    isExact: true,
    title: "Creating TermSheet...",
    searchParam: "none",
  },
  {
    path: "/deal/:dealId/termsheet/:termsheetId/quotes",
    component: QuoteMatrix,
    isExact: true,
    title: "Term Sheet Quotes",
    searchParam: "quotes",
  },
  {
    path: "/deal/:dealId/termsheet/:termsheetId/sections",
    component: SelectSections,
    isExact: true,
    title: "Add Sections",
    searchParam: "none",
  },
  {
    path: "/deal/:dealId/termsheet/:termsheetId/edit",
    component: EditTermsheet,
    isExact: true,
    title: "Term Sheet",
    searchParam: "none",
  },
  {
    path: "/deal/:dealId/termsheet/:termsheetId/",
    component: QuoteMatrix,
    isExact: true,
    title: "View Term Sheet",
    searchParam: "quotes",
  },
  {
    path: "/termsheet/:termsheetId",
    component: TermsheetPreview,
    isExact: true,
    title: "Termsheet Preview",
  },
  {
    path: "/termsheet-clause/:activeTab",
    component: ManageTermsheetAndClause,
    isExact: true,
    title: "Manage Termsheet and Clause",
  },
  {
    path: "/termsheet-clause/specialty-sections/choice",
    component: SpecialtySectionChoice,
    isExact: true,
    title: "How would you like to start your Section?",
  },
  {
    path: "/termsheet-clause/specialty-sections/create",
    component: SpecialtySectionCreate,
    isExact: true,
    title: "Create Specialty Section",
  },
  {
    path: "/termsheet-clause/termsheet/choice",
    component: TermsheetChoice,
    isExact: true,
    title: "How would you like to start your term sheet?",
    searchParam: "none",
  },
  {
    path: "/termsheet-clause/termsheet/create",
    component: TermsheetCreateEdit,
    isExact: true,
    title: "Create Termsheet Template from Scratch",
    searchParam: "none",
  },
  {
    path: "/termsheet-clause/termsheet/:termsheetId",
    component: TermsheetCreateEdit,
    isExact: true,
    title: "Edit Termsheet Template",
    searchParam: "none",
  },
];

export { lenderRoutes };
