import { actionTypes } from "redux/actions";
import { reducerCreator, multipleReducers } from "./meta";

export const section = multipleReducers({
  initializers: {
    initialState: {
      sectionsTotalCount: 0,
      sections: [],
      manageSections: {
        count: 0,
        limit: 10,
        rows: [],
      },
      section: null,
      lastSectionCreated: null,
      lastSectionUpdated: null,
      lastSectionDeleted: null,
      lastMerged: null,
    },
  },
  list: [
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.GET_SECTIONS,
        successFn: (action) => ({
          sections: action.data.body.rows,
          sectionsTotalCount: action.data.body.count,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.GET_MANAGE_SECTIONS,
        successFn: (action) => ({
          lastSectionCreated: null,
          lastSectionUpdated: null,
          lastSectionDeleted: null,
          manageSections: action.data.body,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.CREATE_SECTION,
        successFn: (action) => ({
          lastSectionCreated: action.data,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.GET_SECTION,
        successFn: (action) => ({
          section: action.data.body,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.CLEAR_SECTION,
        successFn: () => ({
          section: null,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.UPDATE_SECTION,
        successFn: (action) => ({
          lastSectionUpdated: action.data,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.DELETE_SECTION,
        successFn: (action) => ({
          lastSectionDeleted: action.data,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.MERGE_SECTIONS,
        successFn: (action) => ({
          lastMerged: action.data,
        }),
      },
    },
  ],
});
