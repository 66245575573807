import React, { useEffect, useMemo, useState } from "react";
import { Button, Form, Modal, TreeSelect, Upload } from "antd";
import { PaperClipOutlined } from "@ant-design/icons";
import TextArea from "components/form/TextArea";
import { createTreeData, normalizeFile } from "../inboxUtils";
import { useSelector } from "react-redux";
import { inboxModaTypes } from "../enums";

const AskQuestion = ({ open, type, data, onCreate, onCancel }) => {
  const labelsTreeView = useSelector((state) => state.label.labelsTreeView);

  const [form] = Form.useForm();
  const [message, setMessage] = useState(" ");

  const treeSelectData = useMemo(
    () => createTreeData(labelsTreeView, "value"),
    [labelsTreeView]
  );

  useEffect(() => {
    if (!data) {
      form.setFieldsValue(null);
      setMessage(" ");
      return;
    }
    const [firstMessage] = data.messages;
    form.setFieldsValue({
      message: firstMessage.message,
      labels: [...data.labels],
    });
    setMessage(firstMessage.message);
  }, [data]);

  const resetForm = () => {
    form.resetFields();
    setMessage(" ");
  };

  const onFinish = (values) => {
    console.log("Success:", values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const title =
    type === inboxModaTypes.askQuestion ? "Ask Question" : "Edit Question";

  return (
    <Modal
      open={open}
      title={title}
      okText="Submit"
      cancelText="Cancel"
      onCancel={onCancel}
      afterClose={resetForm}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onCreate(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form
        form={form}
        autoComplete="off"
        layout="vertical"
        style={{ border: 0 }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item label="" name="message" rules={[{ required: true }]}>
          <TextArea
            hideLabel
            height={190}
            placeholder="Type your question here..."
            input={{
              value: message,
              onChange: (message) => {
                setMessage(message);
                form.setFieldValue("message", message);
              },
            }}
            init={{
              content_style: "body { font-size:14px }",
            }}
          />
        </Form.Item>

        {type === inboxModaTypes.askQuestion && (
          <Form.Item
            name="files"
            valuePropName="fileList"
            getValueFromEvent={normalizeFile}
          >
            <Upload name="logo" multiple beforeUpload={() => false}>
              <Button>
                Attach Files
                <PaperClipOutlined />
              </Button>
            </Upload>
          </Form.Item>
        )}

        <Form.Item label="Tags" name="labels" rules={[{ required: false }]}>
          <TreeSelect
            multiple
            showSearch
            style={{ width: "100%" }}
            dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
            placeholder="Select here"
            treeNodeFilterProp="title"
            allowClear
            treeDefaultExpandAll
            treeData={treeSelectData}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AskQuestion;
