import React from "react";
import { Form, Button, Row, Col, Accordion } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import { connect } from "react-redux";

import { getFormValues } from "redux-form";
import Spacer from "components/layout/Spacer";
import Bucket from "./Bucket";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

class BucketStep extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: null,
    };
  }
  componentDidUpdate(prevProps) {
    if (prevProps.isActive !== this.props.isActive && this.props.isActive) {
      this.props.setStepName("Pricing Buckets");
    }
  }
  handleToggle = (key) => {
    this.setState((prevState) => {
      if (prevState.activeKey === key) {
        return { activeKey: null };
      }
      return { activeKey: key };
    });
  };

  handleAddBucketClick = () => {
    const updatedBuckets = [...this.props.buckets];
    const _tempId = uuidv4();
    updatedBuckets.push({ _tempId });
    this.handleToggle(`ProgramBucket${_tempId}`);
    this.props.handleBucketsChange(updatedBuckets);
  };

  handleDelete = (idx) => {
    const updatedBuckets = [...this.props.buckets];
    updatedBuckets.splice(idx, 1);
    this.props.handleBucketsChange(updatedBuckets);
  };

  // submitFn = (e) => {
  //     e.preventDefault()
  //     this.props.handleBucketsWithFinalDataChange(this.props.bucketsWithFinalData)
  //     if (this.props.isFinalStep) {
  //         this.props.handleProgramSubmit()
  //     } else {
  //         this.props.handleNext(this.props.nextStep);
  //     }
  // }

  submitFn = (e) => {
    e.preventDefault();
    this.props.handleBucketsWithFinalDataChange(
      this.props.bucketsWithFinalData
    );
    if (this.saveType === "saveAndContinue") {
      this.props.handleSaveAndContinue(this.props.nextStep, "bucketStep");
    } else {
      this.props.handleSaveAndContinue(null, "bucketStep");
    }
  };

  setSave = (type) => () => {
    this.saveType = type;
  };

  render() {
    return (
      <fieldset>
        <Form className="no-padding no-border" onSubmit={this.submitFn}>
          <Row>
            <Col xs="auto" className="d-flex align-items-center">
              <Button
                variant="link"
                className="px-2 mb-4"
                onClick={this.handleAddBucketClick}
              >
                <FontAwesomeIcon className="font-14 mr-3" icon={faPlus} />
                Add Bucket
              </Button>
            </Col>
            <Col>
              <p>
                User Note: Pricing Buckets allow you to differentiate terms and
                basic underwriting for your approach to different property
                types. For example, you might tell us how you underwrite and
                price multifamily and industrial in one bucket and hotels in
                another bucket.
              </p>
              <Spacer size={20} />
            </Col>
          </Row>
          <Accordion activeKey={this.state.activeKey}>
            {this.props.buckets.map((bucket, idx) => {
              const formKey = `ProgramBucket${bucket._id || bucket._tempId}`;
              return (
                <Bucket
                  {...this.props}
                  form={formKey}
                  handleToggle={() => this.handleToggle(formKey)}
                  handleDelete={() => this.handleDelete(idx)}
                  bucket={bucket}
                />
              );
            })}
          </Accordion>
          <Row>
            <Col className="text-left" xs="2">
              <Spacer size={50} />
              <Button
                onClick={this.props.handlePrevious(this.props.previousStep)}
                variant="dark"
              >
                Back
              </Button>
            </Col>
            <Col className="text-right" xs="10">
              <Spacer size={50} />
              <Button variant="link" onClick={this.handleAddBucketClick}>
                Add Bucket
              </Button>
              &nbsp;
              <Button
                type="submit"
                variant="primary"
                onClick={this.setSave("saveAndExit")}
              >
                Save & Exit
              </Button>
              &nbsp;
              {!this.props.isFinalStep && (
                <Button
                  type="submit"
                  variant="primary"
                  onClick={this.setSave("saveAndContinue")}
                >
                  Save & Continue
                </Button>
              )}
            </Col>
          </Row>
        </Form>
      </fieldset>
    );
  }
}

const mapStateToProps = (state, props) => {
  const bucketsWithFinalData = (props.buckets || []).map((bkt) =>
    getFormValues(`ProgramBucket${bkt._id || bkt._tempId}`)(state)
  );
  return {
    bucketsWithFinalData,
  };
};
export default connect(mapStateToProps)(BucketStep);
