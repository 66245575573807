import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import {
  Form,
  Row,
  Col,
  Button,
  Container,
  ListGroup,
  Table,
} from "react-bootstrap";
import { getDeals, getTermsheets } from "redux/actions";
import { reduxFormSearch } from "components/form/ReduxForm";
import Spacer from "components/layout/Spacer";
import Loader from "components/ui/Loader";
import { Field, reduxForm } from "redux-form";

function CreateFromDeal(props) {
  const [searchInput, setSearchInput] = useState("");
  const [dealId, setDealId] = useState("");
  const [termsheetId, setTermsheetId] = useState("");

  useEffect(() => {
    getDeals({
      archived: false,
    });
  }, []);

  useEffect(() => {
    if (dealId) {
      setTermsheetId("");
      getTermsheets(dealId, false, 1, 100);
    }
  }, [dealId]);

  const dealsList = useMemo(() => {
    return props.deals.filter((deal) =>
      deal.propertyName.toLocaleLowerCase().includes(searchInput)
    );
  }, [props.deals, searchInput]);

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handleSave = (e) => {
    e.preventDefault();
  };

  const { action } = props;

  return (
    <Container className="create-from-deal">
      {(action.CREATE_TERMSHEET.isFetching ||
        props.action.GET_TERMSHEET.isFetching) && <Loader />}

      <Form className="no-border" onSubmit={handleSave}>
        <Row>
          <Col xs={12}>
            <h4 className="text-heading">Search by Deal Name</h4>
            <Spacer size={20} />
          </Col>
          <Col xs={{ span: 10, offset: 1 }}>
            <Field
              className="form-control"
              component={reduxFormSearch}
              name="search"
              type="text"
              placeholder="Search by Deal Name"
              inputValue={searchInput}
              input={{
                onChange: handleSearchChange,
              }}
            />
            <Spacer size={10} />

            <ListGroup>
              {dealsList.map((deal) => (
                <ListGroup.Item
                  key={deal._id}
                  className={
                    deal._id === dealId ? "list-group-item-primary" : ""
                  }
                  onClick={() => setDealId(deal._id)}
                >
                  {deal.propertyName}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
        </Row>

        <Spacer size={40} />

        <Row>
          <Col xs={12}>
            <h4 className="text-heading">Select a Term Sheet</h4>
            <Spacer size={20} />
          </Col>
          <Col xs={{ span: 10, offset: 1 }}>
            <Table responsive="sm">
              <thead>
                <tr>
                  <th>Template Name</th>
                  <th style={{ width: 160 }}>Owner</th>
                  <th className="text-center" style={{ width: 160 }}>
                    Date Last Edited
                  </th>
                </tr>
              </thead>
              <tbody>
                {props.termsheets.map((ts) => (
                  <tr
                    key={ts._id}
                    className={ts._id === termsheetId ? "row-item-primary" : ""}
                    onClick={() => setTermsheetId(ts._id)}
                  >
                    <td>{ts.termsheetName}</td>
                    <td>
                      {ts.owner?.firstName} {ts.owner?.lastName}
                    </td>
                    <td className="text-center">
                      {new Date(ts.updatedAt).toLocaleDateString()}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>

        <Spacer size={60} />

        <Row>
          <Col className="d-flex justify-content-end">
            <Button variant="primary" className="mr-2" onClick={props.onCancel}>
              Go Back
            </Button>
            <Button
              disabled={!termsheetId}
              variant="tertiary"
              onClick={() => props.onSubmit(termsheetId)}
            >
              Select
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
}
function mapStateToProps(state) {
  return {
    action: state.action,
    myCompany: state.company.myCompany,
    termsheet: state.termsheet.termsheet,
    termsheets: state.termsheet.termsheets,
    deals: state.deal.deals,
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "CreateFromDeal",
    enableReinitialize: true,
  })(CreateFromDeal)
);
