import React, { Component } from 'react'
import { Row, Col, Container, Button } from 'react-bootstrap'
import { connect } from 'react-redux'
import QuoteMatrix from '../quotes/QuoteMatrix'
import { getTermsheet } from 'redux/actions'
import Loader from 'components/ui/Loader'
import Spacer from 'components/layout/Spacer'
import { Link } from 'react-router-dom'
import { reduxFormNumber as InputNumber } from 'components/form/ReduxForm';

class TermSheet extends Component {
    constructor(props) {
        super(props)

        this.state = {

        }
    }

    componentDidMount() {
        const { termsheetId } = this.props.match.params;
        getTermsheet(termsheetId);
    }

    render() {
        const { columns } = this.props.termsheet;
        const { isFetching } = this.props.action.GET_TERMSHEET;
        const { whoami } = this.props;
        return (
            <Container>
                {
                    isFetching &&
                    <Loader />
                }
                <QuoteMatrix {...this.props} child />
                {
                    columns && columns.map((column, index) => (
                        <Row key={column._id} style={{ paddingBottom: 20 }}>
                            <Col xs={5} sm={5} md={2}><b>{column.sectionName}</b></Col>
                            <Col xs={7} sm={7} md={10}>
                                <pre>
                                    {
                                        column.sectionType && (
                                            <><i>
                                                {
                                                    (() => {
                                                        if (column.sectionType === "currency") {
                                                            return (
                                                                <InputNumber
                                                                    displayType="text"
                                                                    prefix={'$'}
                                                                    meta={{}}
                                                                    input={{
                                                                        value: column.sectionValue,
                                                                    }}
                                                                    thousandSeparator
                                                                />
                                                            )

                                                        }
                                                        if (column.sectionType === "percent") {
                                                            return `${column.sectionValue}%`
                                                        }
                                                        return column.sectionValue;
                                                    })()
                                                }
                                            </i><br /></>
                                        )
                                    }
                                    {column.langDesc}
                                </pre>
                            </Col>
                        </Row>
                    ))
                }
                <Row>
                    <Col className="text-right">
                        <Spacer size={20} />
                        <Link to={
                            (whoami && whoami.role === "Borrower")
                                ? `/deal/${this.props.match.params.dealId}/quotes`
                                : `/deal/${this.props.match.params.dealId}/termsheet`
                        }
                        >
                            <Button
                                type="submit"
                                variant="primary"
                            >
                                DONE
                            </Button>
                        </Link>
                        <Spacer size={20} />
                    </Col>
                </Row>
            </Container>
        )
    }
}

const mapStateToProps = (state) => ({
    termsheet: state.termsheet.termsheet,
    action: state.action,
    whoami: state.auth.whoami,
})
export default connect(mapStateToProps)(TermSheet)
