import React from "react";
import { Row, Col, Button, Card, Dropdown } from "react-bootstrap";
import Spacer from "components/layout/Spacer";
import quotesConfig from "./quotesConfig";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { reduxFormNumber as InputNumber } from "components/form/ReduxForm";

function ViewQuoteCard({
  quote,
  copyQuote,
  editQuote,
  deleteQuote,
  index,
  whoami,
  quoteMatrices = [],
  ...props
}) {
  const matrices = React.useMemo(() => {
    return [...quotesConfig, ...quoteMatrices];
  }, [quotesConfig, quoteMatrices]);
  const canManage =
    quote.createdBy === whoami._id ||
    quote.companyId.companyAdmin === whoami._id;
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Button
      ref={ref}
      variant="inbox-action"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </Button>
  ));

  return (
    <Col xs={12} sm={12} className={`${props.className} no-padding`}>
      <Card
        style={{ margin: 5 }}
        className={props.isFocused ? "focus-quote" : ""}
      >
        <Card.Header>
          <Row className="align-items-center">
            <Col xs="10">Quote {index + 1}</Col>
            {whoami && whoami.role === "Lender" && (
              <Col xs="2" className="right">
                <Dropdown className="d-inline-block" drop="left">
                  <Dropdown.Toggle as={CustomToggle}>
                    <FontAwesomeIcon icon={faEllipsisH} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => copyQuote(quote)}>
                      Copy
                    </Dropdown.Item>
                    {canManage && (
                      <>
                        <Dropdown.Item onClick={() => editQuote(quote)}>
                          Edit
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => deleteQuote(quote)}>
                          Delete
                        </Dropdown.Item>
                      </>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            )}
          </Row>
        </Card.Header>
        <Card.Body>
          <Row style={{ padding: 15 }}>
            {matrices.map((config, index) => {
              if (config.showIf && !config.showIf(quote)) {
                return null;
              }
              let value = quote[config.key];
              let targetClause =
                config.clauseId &&
                quote.addedClauses?.find((e) => e.clauseId === config.clauseId);
              if (targetClause) {
                value = targetClause.value;
              }
              const label = config.getLabel
                ? config.getLabel(quote)
                : config.label;
              const key = config.key + config.label;
              return (
                <Col
                  key={index}
                  md={4}
                  xs={6}
                  sm={4}
                  style={{ borderBottom: "1px solid #e0dede" }}
                >
                  {label}
                  <br />
                  <b>
                    {(() => {
                      if (config.type === "currency") {
                        return (
                          <InputNumber
                            key={key}
                            displayType="text"
                            prefix={"$"}
                            meta={{}}
                            input={{
                              value,
                            }}
                            thousandSeparator
                          />
                        );
                      }
                      if (config.type === "percent") {
                        return (
                          <InputNumber
                            key={key}
                            displayType="text"
                            suffix={"%"}
                            meta={{}}
                            input={{
                              value,
                            }}
                            decimalScale={2}
                            fixedDecimalScale
                          />
                        );
                      }
                      return value;
                    })()}
                  </b>
                </Col>
              );
            })}
          </Row>
          <Spacer size={23} />
        </Card.Body>
      </Card>
      {/* {whoami && whoami.role === "Lender" && (
          <Row>
            <Col className="text-center">
              <Button
                className="fake-link"
                variant="fake-link"
                onClick={() => copyQuote(quote)}
              >
                Copy
              </Button>{" "}
              &nbsp;&nbsp;
              <Button className="fake-link" variant="fake-link" onClick={() => deleteQuote(quote)}>Actions</Button>
            </Col>
          </Row>
        )} */}
      {/* <Row>
                    <Col className="text-center">
                        <Button className="fake-link" variant="fake-link" onClick={() => copyQuote(quote)}>Copy</Button> &nbsp;&nbsp;
                        <Button className="fake-link" variant="fake-link" onClick={() => deleteQuote(quote)}>Delete</Button>
                    </Col>
                </Row> */}
    </Col>
  );
}

export default ViewQuoteCard;
