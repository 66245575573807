import axios from "axios";
import constants from 'config/constants';
import { getKeyFromLocal } from 'utils/lsUtils';

const axiosInstance = (config) => {
  let headers = {
    "Content-Type": constants.API_REQ_HEADER_CONTENT_TYPE,
    Accept: constants.API_REQ_HEADER_CONTENT_TYPE
  }

  const jwtToken = getKeyFromLocal(constants.TERMST_LOCAL_STORAGE.JWT_KEY);
  const tempToken = getKeyFromLocal(constants.TERMST_LOCAL_STORAGE.TEMP_TOKEN_KEY);

  if(jwtToken && typeof jwtToken === "string") {
    headers["accessToken"] = jwtToken;
  } else if(tempToken && typeof tempToken === "string") {
    headers["TEMP_TOKEN"] = tempToken;
  }

  if(config.header) {
    headers = {...headers, ...config.header};
  }

  const origins = constants.API_ORIGINS;
  let endPoint = constants.API_PROTOCOL + origins[window.location.host];

  if(config.url === "/GetHistory") {
    endPoint = constants.API_PROTOCOL + origins[window.location.host + config.url];
  }
  
  return axios.create({
    baseURL: endPoint,
    time0ut: 10000,
    headers,
    withCredentials: true
  });
}

export const makeApiRequest = (config) => {
  return axiosInstance(config)
    .request(config)
    .then((response) => ({ response }))
    .catch((error) => ({ response: {}, error }));
}
