import React from "react";
import { Row, Button } from "react-bootstrap";

export default function QuoteLabels({
  columns,
  matrices,
  firstQuote,
  whoami,
  onSubLabelChange,
  onRemoveAddedClauses,
  onAddClauseToColumns,
}) {
  const readOnly = whoami.role === "Lender" || whoami.role === "Admin";
  return (
    <div className={`quote-labels-body ${readOnly ? "readOnly" : ""}`}>
      {matrices.map((config, index) => {
        if (config.showIf && !config.showIf(firstQuote)) {
          return null;
        }
        const label = config.getLabel
          ? config.getLabel(firstQuote)
          : config.label;

        const isPlusDisabled = columns.some(
          (s) => s.isPrimary && s.sectionName === label
        );

        return (
          <Row key={index}>
            <div>
              <b>{label}</b> &nbsp;
              {config.subLabelType === "select" ? (
                readOnly ? (
                  <select
                    style={{
                      fontSize: 12,
                      height: 20,
                    }}
                    value={firstQuote[config.subLabelKey]}
                    name={config.subLabelKey}
                    onChange={onSubLabelChange}
                  >
                    {config.subLabelOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                ) : (
                  `(${firstQuote[config.subLabelKey]})`
                )
              ) : (
                <>{config.subLabel}</>
              )}
            </div>
            {config.plus && (
              <div style={{ marginTop: -6 }}>
                <Button
                  className="fake-link color-purple-2 font-12 p-0 pl-3"
                  variant="fake-link"
                  disabled={isPlusDisabled}
                  onClick={() => onAddClauseToColumns(config)}
                >
                  + clause
                </Button>
              </div>
            )}
            {config.clauseId && (
              <div style={{ marginTop: -6 }}>
                <Button
                  className="fake-link font-12 text-danger p-0 pl-3"
                  variant="fake-link"
                  onClick={() => onRemoveAddedClauses(config)}
                >
                  - remove
                </Button>
              </div>
            )}
          </Row>
        );
      })}
    </div>
  );
}
