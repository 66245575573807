import React from "react";
import { Col, Row, Tooltip } from "antd";
import Spacer from "components/layout/Spacer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { dealViewIds } from "../DealViewContext";
import useCardHidden from "../hooks/useCardHidden";
import { useSelector } from "react-redux";
import NumberFormat from "react-number-format";

const Tr = () => (
  <tr>
    <td>
      &nbsp;
      <p className="tw-text-xs tw-text-gray-500">&nbsp;</p>
    </td>
    <td>&nbsp;</td>
    <td>&nbsp;</td>
  </tr>
);

const SourcesAndUses = ({ deal }) => {
  const {
    purpose,
    propertySize,
    sourcePurchasePrice,
    sourceReservesForCapex,
    closingCosts,
    sourceUses = [],
    sourceSources = [],
    sourceLoan,
    sourceEquity,
    refinanceExistingLoanPayOff,
    refinanceCurrentBasisCapex,
    refinanceCurrentBasisClosingCosts,
    refinanceReturnOfEquity,
    refinanceNewLoan,
    refinanceExistingEquity,
    sourcesAndUses,
    sourceUsesTotal,
    sourcesTotal,
    refinanceCurrentBasisUsesTotal,
    refinanceExistingSourcesTotal,
    refinanceAdditionalCurrentBasisUses = [],
    refinanceAdditionalCurrentBasisSources = [],
    cardHidden = [],
  } = deal;

  const whoami = useSelector((state) => state.auth.whoami);
  const { isHidden, toggleHidden } = useCardHidden({
    dealId: deal._id,
    cardHidden,
    cardId: dealViewIds.SourcesAndUses,
  });

  if (whoami.role !== "Admin" && isHidden) return null;

  const getPerUnitValue = (value) => value / propertySize || 0;
  const getPerUnitPercentage = (value, total) => (value / total) * 100 || 0;

  if (!sourcesAndUses) return null;

  return (
    <section className={isHidden ? "tw-bg-gray-200" : ""}>
      <div className="tw-flex tw-space-x-3 tw-items-center">
        <h4>Sources & Uses</h4>
        {whoami.role === "Admin" && (
          <span className="px-2 cursor-pointer">
            <Tooltip
              title={isHidden ? "Show this section" : "Hide this section"}
            >
              <FontAwesomeIcon
                icon={isHidden ? faEyeSlash : faEye}
                className="tw-text-gray-500"
                onClick={toggleHidden}
              />
            </Tooltip>
          </span>
        )}
      </div>
      <Spacer size={5} />
      <div className="border-bottom"></div>

      <Row className="tw-mt-5">
        {purpose && purpose === "Refinance" && (
          <Col xs={24} className="tw-mb-3">
            <h4 className="tw-text-gray-500 tw-text-base tw-leading-none">
              Existing Capitalization
            </h4>
          </Col>
        )}
        <Col xs={24} md={12}>
          <table className="table-fixed tw-w-full [&_tr>th]:tw-py-1 [&_tr>td]:tw-py-1">
            <thead>
              <tr>
                <th className="tw-w-5/12">
                  {purpose && purpose === "Refinance"
                    ? "Current Basis"
                    : "Uses"}
                </th>
                <th className="tw-w-5/12">Per Unit</th>
                <th className="tw-w-2/12">%</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <NumberFormat
                    value={sourcePurchasePrice || 0}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                  />
                  <p className="tw-text-xs tw-text-gray-500">Purchase Price</p>
                </td>
                <td>
                  <NumberFormat
                    value={getPerUnitValue(sourcePurchasePrice)}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={0}
                    fixedDecimalScale
                    prefix={"$"}
                  />
                </td>
                <td>
                  <NumberFormat
                    value={getPerUnitPercentage(
                      sourcePurchasePrice,
                      sourceUsesTotal
                    )}
                    displayType={"text"}
                    thousandSeparator
                    decimalScale={0}
                    fixedDecimalScale
                    suffix="%"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <NumberFormat
                    value={sourceReservesForCapex || 0}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                  />
                  <p className="tw-text-xs tw-text-gray-500">
                    Previous Cap Exp
                  </p>
                </td>
                <td>
                  <NumberFormat
                    value={getPerUnitValue(sourceReservesForCapex)}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={0}
                    fixedDecimalScale
                    prefix={"$"}
                  />
                </td>
                <td>
                  <NumberFormat
                    value={getPerUnitPercentage(
                      sourceReservesForCapex,
                      sourceUsesTotal
                    )}
                    displayType={"text"}
                    thousandSeparator
                    decimalScale={0}
                    fixedDecimalScale
                    suffix="%"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <NumberFormat
                    value={closingCosts || 0}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                  />
                  <p className="tw-text-xs tw-text-gray-500">Closing Costs</p>
                </td>
                <td>
                  <NumberFormat
                    value={getPerUnitValue(closingCosts)}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={0}
                    fixedDecimalScale
                    prefix={"$"}
                  />
                </td>
                <td>
                  <NumberFormat
                    value={getPerUnitPercentage(closingCosts, sourceUsesTotal)}
                    displayType={"text"}
                    thousandSeparator
                    decimalScale={0}
                    fixedDecimalScale
                    suffix="%"
                  />
                </td>
              </tr>
              {sourceUses &&
                sourceUses.map((use, idx) => {
                  return (
                    <tr key={idx}>
                      <td>
                        <NumberFormat
                          value={use.amount || 0}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                        <p className="tw-text-xs tw-text-gray-500">
                          {use.text}
                        </p>
                      </td>
                      <td>
                        <NumberFormat
                          value={getPerUnitValue(use.amount)}
                          displayType={"text"}
                          thousandSeparator={true}
                          decimalScale={0}
                          fixedDecimalScale
                          prefix={"$"}
                        />
                      </td>
                      <td>
                        <NumberFormat
                          value={getPerUnitPercentage(
                            use.amount,
                            sourceUsesTotal
                          )}
                          displayType={"text"}
                          thousandSeparator
                          decimalScale={0}
                          fixedDecimalScale
                          suffix="%"
                        />
                      </td>
                    </tr>
                  );
                })}
              {sourceUses.length + 1 < sourceSources.length &&
                new Array(sourceSources.length - sourceUses.length + 1)
                  .fill(null)
                  .map((_, idx) => <Tr key={idx} />)}
              <tr className="tw-border-t tw-border-b tw-border-solid tw-border-gray-300">
                <td>
                  <NumberFormat
                    value={sourceUsesTotal || 0}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                  />
                  <p className="tw-text-xs tw-text-gray-500">Total</p>
                </td>
                <td>
                  <NumberFormat
                    value={getPerUnitValue(sourceUsesTotal)}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={0}
                    fixedDecimalScale
                    prefix={"$"}
                  />
                </td>
                <td>
                  <NumberFormat
                    value={100}
                    displayType={"text"}
                    thousandSeparator
                    decimalScale={0}
                    fixedDecimalScale
                    suffix="%"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </Col>

        <Col xs={24} md={12}>
          <table className="table-fixed tw-w-full [&_tr>th]:tw-py-1 [&_tr>td]:tw-py-1">
            <thead>
              <tr>
                <th className="tw-w-5/12">
                  {purpose && purpose === "Refinance"
                    ? "Existing Cap"
                    : "Sources"}
                </th>
                <th className="tw-w-5/12">Per Unit</th>
                <th className="tw-w-2/12">%</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <NumberFormat
                    value={sourceLoan || 0}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                  />
                  <p className="tw-text-xs tw-text-gray-500">Loan</p>
                </td>
                <td>
                  <NumberFormat
                    value={getPerUnitValue(sourceLoan)}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={0}
                    fixedDecimalScale
                    prefix={"$"}
                  />
                </td>
                <td>
                  <NumberFormat
                    value={getPerUnitPercentage(sourceLoan, sourcesTotal)}
                    displayType={"text"}
                    thousandSeparator
                    decimalScale={0}
                    fixedDecimalScale
                    suffix="%"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <NumberFormat
                    value={sourceEquity || 0}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                  />
                  <p className="tw-text-xs tw-text-gray-500">Equity</p>
                </td>
                <td>
                  <NumberFormat
                    value={getPerUnitValue(sourceEquity)}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={0}
                    fixedDecimalScale
                    prefix={"$"}
                  />
                </td>
                <td>
                  <NumberFormat
                    value={getPerUnitPercentage(sourceEquity, sourcesTotal)}
                    displayType={"text"}
                    thousandSeparator
                    decimalScale={0}
                    fixedDecimalScale
                    suffix="%"
                  />
                </td>
              </tr>
              {sourceSources &&
                sourceSources.map((source, idx) => {
                  return (
                    <tr key={idx}>
                      <td>
                        <NumberFormat
                          value={source.amount || 0}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                        <p className="tw-text-xs tw-text-gray-500">
                          {source.text}
                        </p>
                      </td>
                      <td>
                        <NumberFormat
                          value={getPerUnitValue(source.amount)}
                          displayType={"text"}
                          thousandSeparator={true}
                          decimalScale={0}
                          fixedDecimalScale
                          prefix={"$"}
                        />
                      </td>
                      <td>
                        <NumberFormat
                          value={getPerUnitPercentage(
                            source.amount,
                            sourcesTotal
                          )}
                          displayType={"text"}
                          thousandSeparator
                          decimalScale={0}
                          fixedDecimalScale
                          suffix="%"
                        />
                      </td>
                    </tr>
                  );
                })}
              {sourceSources.length < sourceUses.length + 1 &&
                new Array(sourceUses.length + 1 - sourceSources.length)
                  .fill(null)
                  .map((_, idx) => <Tr key={idx} />)}
              <tr className="tw-border-t tw-border-b tw-border-solid tw-border-gray-300">
                <td>
                  <NumberFormat
                    value={sourcesTotal || 0}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                  />
                  <p className="tw-text-xs tw-text-gray-500">Total</p>
                </td>
                <td>
                  <NumberFormat
                    value={getPerUnitValue(sourcesTotal)}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={0}
                    fixedDecimalScale
                    prefix={"$"}
                  />
                </td>
                <td>
                  <NumberFormat
                    value={100}
                    displayType={"text"}
                    thousandSeparator
                    decimalScale={0}
                    fixedDecimalScale
                    suffix="%"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </Col>
      </Row>

      {purpose && purpose === "Refinance" && (
        <Row className="tw-mt-5">
          <Col xs={24} className="tw-mb-3">
            <h4 className="tw-text-gray-500 tw-text-base tw-leading-none">
              Post-Closing Capitalization
            </h4>
          </Col>

          <Col xs={24} md={12}>
            <table className="table-fixed tw-w-full [&_tr>th]:tw-py-1 [&_tr>td]:tw-py-1">
              <thead>
                <tr>
                  <th className="tw-w-5/12">Uses</th>
                  <th className="tw-w-5/12">Per Unit</th>
                  <th className="tw-w-2/12">%</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <NumberFormat
                      value={refinanceExistingLoanPayOff || 0}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                    />
                    <p className="tw-text-xs tw-text-gray-500">
                      Payoff Existing Loan
                    </p>
                  </td>
                  <td>
                    <NumberFormat
                      value={getPerUnitValue(refinanceExistingLoanPayOff)}
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalScale={0}
                      fixedDecimalScale
                      prefix={"$"}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      value={getPerUnitPercentage(
                        refinanceExistingLoanPayOff,
                        refinanceCurrentBasisUsesTotal
                      )}
                      displayType={"text"}
                      thousandSeparator
                      decimalScale={0}
                      fixedDecimalScale
                      suffix="%"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <NumberFormat
                      value={refinanceCurrentBasisCapex || 0}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                    />
                    <p className="tw-text-xs tw-text-gray-500">Prior Cap Ex</p>
                  </td>
                  <td>
                    <NumberFormat
                      value={getPerUnitValue(refinanceCurrentBasisCapex)}
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalScale={0}
                      fixedDecimalScale
                      prefix={"$"}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      value={getPerUnitPercentage(
                        refinanceCurrentBasisCapex,
                        refinanceCurrentBasisUsesTotal
                      )}
                      displayType={"text"}
                      thousandSeparator
                      decimalScale={0}
                      fixedDecimalScale
                      suffix="%"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <NumberFormat
                      value={refinanceCurrentBasisClosingCosts || 0}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                    />
                    <p className="tw-text-xs tw-text-gray-500">Closing Costs</p>
                  </td>
                  <td>
                    <NumberFormat
                      value={getPerUnitValue(refinanceCurrentBasisClosingCosts)}
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalScale={0}
                      fixedDecimalScale
                      prefix={"$"}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      value={getPerUnitPercentage(
                        refinanceCurrentBasisClosingCosts,
                        refinanceCurrentBasisUsesTotal
                      )}
                      displayType={"text"}
                      thousandSeparator
                      decimalScale={0}
                      fixedDecimalScale
                      suffix="%"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <NumberFormat
                      value={refinanceReturnOfEquity || 0}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                    />
                    <p className="tw-text-xs tw-text-gray-500">
                      Return of Equity
                    </p>
                  </td>
                  <td>
                    <NumberFormat
                      value={getPerUnitValue(refinanceReturnOfEquity)}
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalScale={0}
                      fixedDecimalScale
                      prefix={"$"}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      value={getPerUnitPercentage(
                        refinanceReturnOfEquity,
                        refinanceCurrentBasisUsesTotal
                      )}
                      displayType={"text"}
                      thousandSeparator
                      decimalScale={0}
                      fixedDecimalScale
                      suffix="%"
                    />
                  </td>
                </tr>
                {refinanceAdditionalCurrentBasisUses &&
                  refinanceAdditionalCurrentBasisUses.map((use, idx) => {
                    return (
                      <tr key={idx}>
                        <td>
                          <NumberFormat
                            value={use.amount || 0}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                          />
                          <p className="tw-text-xs tw-text-gray-500">
                            {use.text}
                          </p>
                        </td>
                        <td>
                          <NumberFormat
                            value={getPerUnitValue(use.amount)}
                            displayType={"text"}
                            thousandSeparator={true}
                            decimalScale={0}
                            fixedDecimalScale
                            prefix={"$"}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            value={getPerUnitPercentage(
                              use.amount,
                              refinanceCurrentBasisUsesTotal
                            )}
                            displayType={"text"}
                            thousandSeparator
                            decimalScale={0}
                            fixedDecimalScale
                            suffix="%"
                          />
                        </td>
                      </tr>
                    );
                  })}
                {refinanceAdditionalCurrentBasisUses.length + 2 <
                  refinanceAdditionalCurrentBasisSources.length &&
                  new Array(
                    refinanceAdditionalCurrentBasisSources.length -
                      refinanceAdditionalCurrentBasisUses.length +
                      2
                  )
                    .fill(null)
                    .map((_, idx) => <Tr key={idx} />)}
                <tr className="tw-border-t tw-border-b tw-border-solid tw-border-gray-300">
                  <td>
                    <NumberFormat
                      value={refinanceCurrentBasisUsesTotal || 0}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                    />
                    <p className="tw-text-xs tw-text-gray-500">Total</p>
                  </td>
                  <td>
                    <NumberFormat
                      value={getPerUnitValue(refinanceCurrentBasisUsesTotal)}
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalScale={0}
                      fixedDecimalScale
                      prefix={"$"}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      value={100}
                      displayType={"text"}
                      thousandSeparator
                      decimalScale={0}
                      fixedDecimalScale
                      suffix="%"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>

          <Col xs={24} md={12}>
            <table className="table-fixed tw-w-full [&_tr>th]:tw-py-1 [&_tr>td]:tw-py-1">
              <thead>
                <tr>
                  <th className="tw-w-5/12">Sources</th>
                  <th className="tw-w-5/12">Per Unit</th>
                  <th className="tw-w-2/12">%</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <NumberFormat
                      value={refinanceNewLoan || 0}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                    />
                    <p className="tw-text-xs tw-text-gray-500">New Loan</p>
                  </td>
                  <td>
                    <NumberFormat
                      value={getPerUnitValue(refinanceNewLoan)}
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalScale={0}
                      fixedDecimalScale
                      prefix={"$"}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      value={getPerUnitPercentage(
                        refinanceNewLoan,
                        refinanceExistingSourcesTotal
                      )}
                      displayType={"text"}
                      thousandSeparator
                      decimalScale={0}
                      fixedDecimalScale
                      suffix="%"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <NumberFormat
                      value={refinanceExistingEquity || 0}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                    />
                    <p className="tw-text-xs tw-text-gray-500">Equity</p>
                  </td>
                  <td>
                    <NumberFormat
                      value={getPerUnitValue(refinanceExistingEquity)}
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalScale={0}
                      fixedDecimalScale
                      prefix={"$"}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      value={getPerUnitPercentage(
                        refinanceExistingEquity,
                        refinanceExistingSourcesTotal
                      )}
                      displayType={"text"}
                      thousandSeparator
                      decimalScale={0}
                      fixedDecimalScale
                      suffix="%"
                    />
                  </td>
                </tr>
                {refinanceAdditionalCurrentBasisSources &&
                  refinanceAdditionalCurrentBasisSources.map((source, idx) => {
                    return (
                      <tr key={idx}>
                        <td>
                          <NumberFormat
                            value={source.amount || 0}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                          />
                          <p className="tw-text-xs tw-text-gray-500">
                            {source.text}
                          </p>
                        </td>
                        <td>
                          <NumberFormat
                            value={getPerUnitValue(source.amount)}
                            displayType={"text"}
                            thousandSeparator={true}
                            decimalScale={0}
                            fixedDecimalScale
                            prefix={"$"}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            value={getPerUnitPercentage(
                              source.amount,
                              refinanceExistingSourcesTotal
                            )}
                            displayType={"text"}
                            thousandSeparator
                            decimalScale={0}
                            fixedDecimalScale
                            suffix="%"
                          />
                        </td>
                      </tr>
                    );
                  })}
                {refinanceAdditionalCurrentBasisSources.length <
                  refinanceAdditionalCurrentBasisUses.length + 2 &&
                  new Array(
                    refinanceAdditionalCurrentBasisUses.length +
                      2 -
                      refinanceAdditionalCurrentBasisSources.length
                  )
                    .fill(null)
                    .map((_, idx) => <Tr key={idx} />)}
                <tr className="tw-border-t tw-border-b tw-border-solid tw-border-gray-300">
                  <td>
                    <NumberFormat
                      value={refinanceExistingSourcesTotal || 0}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                    />
                    <p className="tw-text-xs tw-text-gray-500">Total</p>
                  </td>
                  <td>
                    <NumberFormat
                      value={getPerUnitValue(refinanceExistingSourcesTotal)}
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalScale={0}
                      fixedDecimalScale
                      prefix={"$"}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      value={100}
                      displayType={"text"}
                      thousandSeparator
                      decimalScale={0}
                      fixedDecimalScale
                      suffix="%"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>
      )}
    </section>
  );
};

export default SourcesAndUses;
