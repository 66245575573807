import React, { useMemo, useEffect } from "react";
import { connect } from "react-redux";
import uniq from "lodash/uniq";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { clearTermsheet, getCategories, getClauses } from "redux/actions";
import { reduxFormInput as Input } from "components/form/ReduxForm";
import Spacer from "components/layout/Spacer";
import { reduxForm } from "redux-form";
import Loader from "components/ui/Loader";

const AddClauseCompare = (props) => {
  const {
    action,
    dealId,
    categories,
    clauses,
    columns,
    primaryFields,
    addedClauses,
    compareQuotes,
    handleSubmit,
    onCancel,
    onSubmit,
  } = props;
  const [sections, setSections] = React.useState([]);
  const [selected, setSelected] = React.useState([]);
  const [searchKey, setSearchKey] = React.useState("");
  const [isAllSelected, setIsAllSelected] = React.useState(false);

  const companyIds = useMemo(() => {
    const ids = compareQuotes.map((quote) => quote.companyId?._id);
    return uniq(ids);
  }, [compareQuotes]);

  const filteredClauses = useMemo(() => {
    return clauses.filter(
      (clause) =>
        !addedClauses.some((s) => s._id === clause._id) &&
        !primaryFields.some((f) => f.label === clause.clauseName)
    );
  }, [clauses]);

  const allSections = useMemo(() => {
    return categories.map((category) => {
      const options = filteredClauses
        .filter((clause) => clause.category._id === category._id)
        .map((clause) => ({
          title: clause.displayName || clause.clauseName,
          clause,
        }));
      return {
        title: category.categoryName,
        category,
        options,
      };
    });
  }, [filteredClauses]);

  useEffect(() => {
    getClauses({
      limit: 500,
      order: "ASC",
      orderColumn: "clauseName",
      parent: true,
      dealId,
      companyIds,
    });
    getCategories({
      order: "ASC",
      orderColumn: "order",
      limit: 500,
    });
  }, []);

  useEffect(() => {
    clearTermsheet();
  }, []);

  useEffect(() => {
    setSections(allSections);
  }, [allSections]);

  const addedSectionNames = React.useMemo(
    () => columns.map((col) => col.sectionName),
    [columns]
  );

  const unSelectedSections = React.useMemo(() => {
    return sections.map((section) => {
      const newOptions = section.options.filter(
        (option) => !addedSectionNames.includes(option.title)
      );
      return {
        ...section,
        options: newOptions,
      };
    });
  }, [sections, addedSectionNames]);

  const sectionsWithOptions = React.useMemo(
    () => unSelectedSections.filter((section) => section.options.length),
    [unSelectedSections]
  );

  const handleSearch = (e) => {
    const text = e.target.value;
    setSearchKey(text);
    setSections(searchSections(text, allSections));
  };

  const handleSelected = (id) => {
    setSelected((prev) => {
      let newSelected = [];
      if (prev.includes(id)) {
        newSelected = prev.filter((e) => e !== id);
      } else {
        newSelected = [...prev, id];
      }
      return newSelected;
    });
  };

  const handleSelectAll = () => {
    let newIsAllSelected = !isAllSelected;
    let newSelected = [];
    if (newIsAllSelected) {
      newSelected = filteredClauses.map((clause) => clause._id);
    }
    setSelected(newSelected);
    setIsAllSelected(newIsAllSelected);
  };

  const submitFn = () => {
    const selectedClauses = filteredClauses.filter((clause) =>
      selected.includes(clause._id)
    );
    onSubmit(selectedClauses);
  };

  return (
    <Container>
      <Form className="no-border no-padding" onSubmit={handleSubmit(submitFn)}>
        <Row>
          <Col xs={12}>
            <Input
              name="searchKey"
              type="text"
              placeholder="Search..."
              meta={{}}
              hideLabel
              input={{
                value: searchKey,
                onChange: handleSearch,
                className: "SearchInput search-input",
              }}
            />
            <Spacer size={10} />
          </Col>
          <Col xs={12} className="AddClauseCompare">
            {action.GET_CLAUSES.isFetching ? (
              <Loader style={{ height: "100%" }} />
            ) : (
              <>
                {sectionsWithOptions.length > 0 && (
                  <Row className="rowGroup justify-content-end">
                    <Col xs={12} className="title py-2">
                      <Row
                        className="rowItem py-1 cursor-pointer"
                        onClick={handleSelectAll}
                      >
                        <Col xs="auto">
                          <input
                            type="checkbox"
                            className="cursor-pointer"
                            checked={isAllSelected}
                            onChange={() => {}}
                          />
                        </Col>
                        <Col
                          xs
                          className="label pl-0 text-under"
                          title="Select All"
                        >
                          <u>Select All</u>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}
                {sectionsWithOptions.map((section) => (
                  <Row
                    key={section.category?._id}
                    className="rowGroup justify-content-end"
                  >
                    <Col xs={12} className="title py-2">
                      <b>{section.title}</b>
                    </Col>
                    <Col xs={12}>
                      {section.options.map((option) => (
                        <Row
                          key={option.title}
                          className="rowItem py-1 pl-4 cursor-pointer"
                          onClick={() => handleSelected(option.clause._id)}
                        >
                          <Col xs="auto">
                            <input
                              type="checkbox"
                              className="cursor-pointer"
                              checked={selected.includes(option.clause._id)}
                              onChange={() => {}}
                            />
                          </Col>
                          <Col xs className="label" title={option.title}>
                            {option.title}{" "}
                            {option?.clause?.children?.length > 1 && (
                              <span>({option?.clause?.children?.length})</span>
                            )}
                          </Col>
                        </Row>
                      ))}
                    </Col>
                  </Row>
                ))}
              </>
            )}
          </Col>
        </Row>

        <Spacer size={20} />

        <Row className="justify-content-center">
          <Col className="d-flex justify-content-center" xs="12">
            <Button
              type="submit"
              onClick={onCancel}
              variant="tertiary"
              className="mr-3"
            >
              Cancel
            </Button>
            <Button type="submit" variant="primary" disabled={!selected.length}>
              Add Selected
            </Button>
          </Col>
        </Row>
      </Form>

      <Spacer size={20} />
    </Container>
  );
};

const searchSections = (key, allSections) => {
  key = key.trim();
  if (!key) {
    return allSections;
  }
  const foundSections = [];
  for (let i = 0; i < allSections.length; i++) {
    const { title, options } = allSections[i];
    if (title.toLowerCase().includes(key.toLowerCase())) {
      foundSections.push(allSections[i]);
    } else {
      const newSection = { ...allSections[i], options: [] };
      for (let j = 0; j < options.length; j++) {
        if (options[j].title.toLowerCase().includes(key.toLowerCase())) {
          newSection.options.push(options[j]);
        }
      }
      if (newSection.options.length) {
        foundSections.push(newSection);
      }
    }
  }
  return foundSections;
};

const mapStateToProps = (state) => ({
  action: state.action,
  columns: state.termsheet.termsheet.columns || [],
  clauses: state.clause.clauses,
  lastClauseCreated: state.clause.lastClauseCreated,
  categories: state.category.categories,
});

export default connect(mapStateToProps)(
  reduxForm({
    form: "AddClauseCompare",
    enableReinitialize: true,
  })(AddClauseCompare)
);
