import React from "react";
import { connect } from "react-redux";
import { get } from "lodash";
import { Form, Button, Row, Col } from "react-bootstrap";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { reduxFormSelect } from "components/form/ReduxForm";
import Spacer from "components/layout/Spacer";
import programCategories from "../constants/programCategories";

class SponsorshipStep extends React.Component {

    submitFn = () => {
        if (this.saveType === 'saveAndContinue') {
            this.props.handleSaveAndContinue(this.props.nextStep);
        } else {
            this.props.handleSaveAndContinue();
        }
    };

    setSave = (type) => () => {
        this.saveType = type;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isActive !== this.props.isActive && this.props.isActive) {
            this.props.setStepName("Preferred Sponsorship")
        }
    }

    render() {
        const { handleSubmit, selectedProgramType, action } = this.props;

        return (
            <fieldset>
                <Form
                    className="no-padding no-border"
                    onSubmit={handleSubmit(this.submitFn)}
                >
                    {

                        programCategories.minOpenWrite.indexOf(selectedProgramType.value) === -1 && (
                            <Row>
                                <Col xs="12" sm="6">
                                    <Field
                                        className="form-control"
                                        component={reduxFormSelect}
                                        name="sponsorQualityImportance"
                                        type="select"
                                        options={this.props.getDropdownValues("sponsorQualityImportance")}
                                        isLoading={action.GET_DROPDOWNS.isFetching}
                                        onFocus={() =>
                                            this.props.getDropdownFromAPI("sponsorQualityImportance")
                                        }
                                        placeholder="How Important is sponsor quality?"
                                        label="How Important is sponsor quality?"
                                        isMulti
                                    />
                                    <Spacer size={20} />
                                    <Field
                                        className="form-control"
                                        component={reduxFormSelect}
                                        name="openForSponsorTypes"
                                        type="select"
                                        options={this.props.getDropdownValues("openForSponsorTypes")}
                                        isLoading={action.GET_DROPDOWNS.isFetching}
                                        onFocus={() =>
                                            this.props.getDropdownFromAPI("openForSponsorTypes")
                                        }
                                        placeholder="Our Typical Sponsors Fall Into the following Categories"
                                        label="Our Typical Sponsors Fall Into the following Categories"
                                        isMulti
                                    />
                                    <Spacer size={20} />
                                </Col>
                                <Col xs="12" sm="6">
                                    <Field
                                        className="form-control"
                                        component={reduxFormSelect}
                                        name="potentialRedFlags"
                                        type="select"
                                        options={this.props.getDropdownValues("potentialRedFlags")}
                                        onFocus={() =>
                                            this.props.getDropdownFromAPI("potentialRedFlags")
                                        }
                                        isLoading={action.GET_DROPDOWNS.isFetching}
                                        placeholder="The following red flags are not necessarily deal killers for us"
                                        label="The following red flags are not necessarily deal killers for us"
                                        isMulti
                                    />
                                    <Spacer size={20} />
                                    <Field
                                        className="form-control"
                                        component={reduxFormSelect}
                                        name="considerTicDstIf"
                                        type="select"
                                        options={this.props.getDropdownValues("considerTicDstIf")}
                                        onFocus={() =>
                                            this.props.getDropdownFromAPI("considerTicDstIf")
                                        }
                                        isLoading={action.GET_DROPDOWNS.isFetching}
                                        placeholder="Will Consider a TIC or DST Transaction If"
                                        label="Will Consider a TIC or DST Transaction If"
                                        isMulti
                                    />
                                </Col>
                            </Row>
                        )}
                    <Row>
                        <Col className="text-left" xs="3">
                            <Spacer size={50} />
                            <Button
                                onClick={this.props.handlePrevious(this.props.previousStep)}
                                variant="dark"
                            >
                                Back
                            </Button>
                        </Col>
                        <Col className="text-right" xs="9">
                            <Spacer size={50} />
                            <Button type="submit" variant="primary" onClick={this.setSave('saveAndExit')}>
                                Save & Exit
                            </Button>
                            &nbsp;
                            <Button type="submit" variant="primary" onClick={this.setSave('saveAndContinue')}>
                                Save & Continue
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </fieldset >
        );
    }
}

const selectorInitialStep = formValueSelector("ProgramCreateInitialStep");

const getInitialValues = ({ isUpdate, isClone, selectedProgram: program }) => {
    if (!isUpdate && !isClone) {
        return {}
    }
    return {
        sponsorQualityImportance: get(program, 'sponsorQualityImportance', null),
        openForSponsorTypes: get(program, 'openForSponsorTypes', null),
        potentialRedFlags: get(program, 'potentialRedFlags', null),
        considerTicDstIf: get(program, 'considerTicDstIf', null)
    }
}

function mapStateToProps(state, ownProps) {
    return {
        action: state.action,
        selectedProgramType: selectorInitialStep(state, "programTypes") || "",
        initialValues: getInitialValues(ownProps)
    };
}

export default connect(mapStateToProps)(
    reduxForm({
        form: "ProgramCreateSponsorshipStep",
        destroyOnUnmount: false,
        forceUnregisterOnUnmount: true,
        enableReinitialize: true,
    })(SponsorshipStep)
);
