import { actionTypes } from "redux/actions";
import { reducerCreator, multipleReducers } from "./meta";

export const comm = multipleReducers({
  initializers: {
    initialState: {
      groups: [],
      communication: {},
      communications: [],
      communicationsTotal: 0,
      lastCommunicationCreated: null,
    },
  },
  list: [
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.GET_COMMS,
        successFn: (action) => ({
          communications: action.data.body.rows,
          communicationsTotal: action.data.body.count,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.GET_FILTERED_COMMS,
        successFn: (action) => ({
          communications: action.data.body.rows,
          communicationsTotal: action.data.body.count,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.SEARCH_COMMS,
        successFn: (action) => ({
          communications: action.data.body.rows,
          communicationsTotal: action.data.body.count,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.GET_COMM_BY_ID,
        successFn: (action) => ({
          communication: action.data.body,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.EDIT_COMM,
        successFn: (action) => ({
          communication: action.data.body,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.GET_GROUPS,
        successFn: (action) => ({
          groups: action.data.body.rows ? action.data.body.rows : action.data,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.CREATE_COMM,
        successFn: (action) => ({
          lastCommunicationCreated: action.data,
        }),
      },
    },
  ],
});
