import React, { useEffect } from "react";
import { Editor } from "@tinymce/tinymce-react";

const defaultPlugins = [
  "advlist autolink lists link image charmap print preview anchor",
  "searchreplace visualblocks code fullscreen",
  "insertdatetime media table paste code help wordcount",
];

const defaultToolbar =
  "undo redo | h3 | bold italic underline removeformat | bullist numlist outdent indent | link unlink";

const TextArea = (props) => {
  const {
    label,
    placeholder,
    initialValue,
    input,
    customError,
    height = 500,
    toolbar,
    disabled,
    init,
    hideLabel,
  } = props;
  const [mounted, setMounted] = React.useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) return null;

  return (
    <>
      <Editor
        disabled={disabled}
        placeholder={placeholder}
        value={input.value && input.value !== "" ? input.value : initialValue}
        init={{
          height,
          placeholder,
          menubar: false,
          plugins: defaultPlugins,
          toolbar: typeof toolbar === "string" ? toolbar : defaultToolbar,
          ...init,
        }}
        apiKey="o1cqrcqrmtsfferoyrscgec3gmrkw4gwy0liv8eibw9n73ni"
        onEditorChange={(value) => input.onChange(value)}
      />
      {customError && customError !== null && (
        <span className="error">{customError}</span>
      )}
      {!hideLabel && (
        <label>
          <h6 className="bold text-uppercase">{label}</h6>
        </label>
      )}
    </>
  );
};

TextArea.plugins = defaultPlugins;
TextArea.toolbar = defaultToolbar;

export default TextArea;
