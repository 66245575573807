
const autofillFields = {
    street_number: {
        type: 'street_number',
        field: 'address',
        name: 'long_name',

    },
    route: {
        type: 'route',
        field: 'address',
        name: 'long_name',

    },
    sublocality_level_1: {
        type: "sublocality_level_1",
        field: 'address',
        name: 'long_name',
    },
    locality: {
        type: 'locality',
        field: 'city',
        name: 'long_name',

    },
    // administrative_area_level_2: {
    //     type: 'administrative_area_level_2',
    //     field: 'city',
    //     name: 'long_name',
    // },
    administrative_area_level_1: {
        type: 'administrative_area_level_1',
        field: 'state',
        name: 'long_name',
        nick_name: 'short_name'
    },
    country: {
        type: 'country',
        field: 'country',
        name: 'long_name',
    },
    postal_code: {
        type: 'postal_code',
        field: 'zip',
        name: 'long_name',
    },
};

const mapToFields = (data, fieldsMapping) => {
    if (!fieldsMapping) {
        return data;
    }
    const mappedData = {};
    Object.keys(fieldsMapping).map((key) => {
        const value = data[fieldsMapping[key]];
        if (value) {
            mappedData[key] = value;
        }

        return true;
    });
    return mappedData;
}
export const addPlacesAutoSuggestionsListener = (elementId, fieldsMapping, dataCallback) => {
    try {
        const input = document.getElementById(elementId);
        if (!input) {
            throw new Error(`${elementId} element not available at`);
        }
        const autocomplete = new window.google.maps.places.Autocomplete(input,
            { types: ['geocode'] });
        autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace();
            const addressFields = {};
            for (let i = 0; i < place.address_components.length; i++) {
                const fieldType = place.address_components[i].types[0];
                if (autofillFields[fieldType]) {
                    const value = place.address_components[i][autofillFields[fieldType].name];
                    const field = autofillFields[fieldType].field;
                    if (field === 'address') {
                        addressFields[field] = addressFields[field] ? addressFields[field] + ' ' + value : value
                    }
                    else if (autofillFields[fieldType].nick_name) {
                        addressFields[field] = { name: value, nick_name: place.address_components[i][autofillFields[fieldType].nick_name] };
                    }
                    else {
                        addressFields[field] = value;
                    }
                }
            }
            addressFields.lat = place.geometry.location.lat();
            addressFields.lng = place.geometry.location.lng();

            dataCallback(mapToFields(addressFields, fieldsMapping));
        });
    } catch (e) {
        console.warn(e.message)
        setTimeout(() => {
            addPlacesAutoSuggestionsListener(elementId, fieldsMapping, dataCallback)
        }, 2000)
    }
}