export const addOnOptions = [
  "None",
  "Years / Months Selection",
  "Basis Points",
].map((e) => ({
  label: e,
  value: e,
}));

export const formatOptions = [
  "Open Text, with header",
  "Open Text, left aligned, no header",
  "#,###",
  "$#,###",
  "#.##%",
  "#.#%",
  "#%",
  "#.##x",
].map((e) => ({ label: e, value: e }));

export const formatSampleMapping = {
  "Open Text, with header": `This is what open text  with header looks like.  You will see a header with your Display name followed by an area where you can provide as much text as you like in paragraphical form.  There is no limit to the text that you can write in this box.  This is the most common type of clause format in Term Street Term Sheets.`,
  "Open Text, left aligned, no header": `This is open write text that is left aligned without a header.  This is most commonly used for welcome and exit paragraphs and can also be used for signature blocks.  Be sure to use “merge tags” when you create language to insert common useages like address, sponsor, borrower, property type, or property size so that you do not need to fill these blanks in from deal to deal.  `,
};

export const adminAvailabilityOptions = [
  {
    label: "Only Me",
    value: "ONLY_ME",
  },
  {
    label: "My Team(s)",
    value: "MY_TEAM",
  },
  {
    label: "My Company",
    value: "COMPANY",
  },
  {
    label: "All Lenders",
    value: "ALL_LENDERS",
  },
];

export const lenderAvailabilityOptions = [
  {
    label: "Only Me",
    value: "ONLY_ME",
  },
  {
    label: "My Team(s)",
    value: "MY_TEAM",
  },
  {
    label: "My Company",
    value: "COMPANY",
  },
];
