import React from "react";
import { faReply } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Layout, theme, Tooltip } from "antd";
import { ChatBubbleSvg } from "components/svgs/ChatBubbleSvg";
import { last, uniq } from "lodash";
import { Badge } from "react-bootstrap";
import { useSelector } from "react-redux";
import queryString from "query-string";
import { answerToQuestion, deleteConversation } from "redux/actions";
import { conversationActions, conversationType } from "../enums";
import { InboxAvatar } from "../InboxAvatar";
import { InboxEditorSection } from "../InboxEditorSection";
import { formatDate, getReceiverNames, getSenderName } from "../inboxUtils";
import { MessageOptions } from "../message/MessageOptions";
import useCreateConvoModalStore from "../useCreateConvoModalStore";
import { useHistory } from "react-router-dom";
import { InboxAttachments } from "../InboxAttachments";
const { Content } = Layout;

const InboxQuestionContent = ({ status }) => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const history = useHistory();
  const {
    setOpenModalFollowUpQuestion,
    setOpenModalEditQuestion,
    setOpenModalEditVisibility,
  } = useCreateConvoModalStore();
  const whoami = useSelector((state) => state.auth.whoami);
  const myCompany = useSelector((state) => state.company.myCompany);
  const deal = useSelector((state) => state.deal.deal);
  const questionDetails = useSelector(
    (state) => state.conversation.questionDetails
  );

  const [showEditor, setShowEditor] = React.useState(false);
  const {
    _senderId,
    _senderCompanyId,
    _receiverCompanyIds,
    _labels,
    messages,
  } = questionDetails;
  const [firstMessage, ...replies] = messages;
  const lastMessage = last(messages);
  const [sender] = _senderId;
  const [senderCompany] = _senderCompanyId;
  const receiverNames = getReceiverNames(
    _receiverCompanyIds,
    senderCompany,
    status,
    whoami,
    myCompany
  );
  const senderName = getSenderName(sender, senderCompany);
  const labels = _labels.map(({ label }) => label);

  const searchObject = queryString.parse(history.location.search);

  const toggleShowEditor = () => {
    setShowEditor((prev) => !prev);
  };

  const handleSubmitEditor = (values) => {
    const params = {
      conversationId: questionDetails._id,
    };
    const formData = new FormData();
    formData.append("message", values.message);
    formData.append("isPrivate", values.isPrivate);

    if (values.files) {
      values.files.forEach((file) => {
        formData.append("files", file.originFileObj);
      });
    }

    const includedLenderCompanyIds = values.isPrivate
      ? []
      : deal.lendersAssigned.map(({ companyId }) => companyId);

    const receiverCompanyIds = uniq([
      ...questionDetails.receiverCompanyIds,
      ...includedLenderCompanyIds,
      questionDetails._senderCompanyId[0]._id,
    ]);
    values.receiverCompanyIds = values.receiverCompanyIds
      ? [...values.receiverCompanyIds, ...receiverCompanyIds]
      : receiverCompanyIds;

    if (values.receiverCompanyIds) {
      values.receiverCompanyIds.forEach((receiverCompanyId) => {
        formData.append("receiverCompanyIds", receiverCompanyId);
      });
    }
    answerToQuestion(params, formData);
    setShowEditor(false);
  };

  const handleOptionAction = (option) => {
    switch (option) {
      case conversationActions.reply:
        setShowEditor(true);
        break;
      case conversationActions.delete:
        deleteConversation(questionDetails._id);
        break;
      case conversationActions.editQuestion:
        setOpenModalEditQuestion(questionDetails);
        break;
      case conversationActions.editVisibility:
        setOpenModalEditVisibility(questionDetails);
        break;
      default:
        break;
    }
  };

  const handleAskFollowUp = () => {
    setOpenModalFollowUpQuestion(questionDetails._id);
  };

  const shouldShowReply = () => {
    if (sender._id === whoami._id && whoami.role === "Lender") return false;

    if (messages.length >= 2) return false;

    return true;
  };

  const showReply = shouldShowReply();

  return (
    <Card style={{ padding: 16 }} bodyStyle={{ width: "100%", padding: 0 }}>
      <Content style={{ margin: 0, background: colorBgContainer }}>
        <section className="d-flex flex-column gap-20">
          <div className="d-flex align-items-center justify-content-between">
            <p className="font-18">
              {firstMessage.subject}
              <Badge
                className="text-capitalize cursor-pointer ml-4"
                variant="primary"
                onClick={() =>
                  handleOptionAction(conversationActions.editVisibility)
                }
              >
                <Tooltip title="Edit Visibility">
                  {lastMessage?.isPrivate ? "Private" : "Public"}
                </Tooltip>
              </Badge>
              {searchObject.status === "sent" &&
                questionDetails.status === "Archived" && (
                  <Badge className="text-capitalize ml-1" variant="primary">
                    {questionDetails.status}
                  </Badge>
                )}
            </p>
            <div className="d-flex align-items-start gap-16">
              <p>{formatDate(firstMessage.createdAt, "ll, LT")}</p>
              {showReply && (
                <p>
                  <Tooltip title="Reply">
                    <FontAwesomeIcon
                      className="mr-1 cursor-pointer"
                      icon={faReply}
                      onClick={() => setShowEditor(true)}
                    />
                  </Tooltip>
                </p>
              )}
              <p>
                <MessageOptions
                  sender={sender}
                  type={conversationType.Question}
                  showDelete
                  showReply={showReply}
                  onOptionClick={handleOptionAction}
                />
              </p>
            </div>
          </div>

          <div className="d-flex flex-column gap-16">
            <InboxAvatar
              name={senderName}
              subtitle={`To: ${receiverNames}`}
              imageUrl={sender.profileImageXS}
            />
            <div className="d-flex align-items-center gap-10">
              {labels.map((label) => (
                <span
                  key={label}
                  className="font-12 line-height-none border border-secondary p-2"
                >
                  {label}
                </span>
              ))}
            </div>
          </div>

          <div
            dangerouslySetInnerHTML={{
              __html: firstMessage.message,
            }}
          />

          <InboxAttachments attachments={firstMessage.attachments} />

          {showEditor && (
            <InboxEditorSection
              senderCompanyId={senderCompany._id}
              type={conversationType.Question}
              onCancel={toggleShowEditor}
              onSubmit={handleSubmitEditor}
            />
          )}

          {!showEditor && (
            <div className="d-flex align-items-center justify-content-between">
              {replies.length > 0 && (
                <div className="d-flex align-items-center">
                  <ChatBubbleSvg className="mr-2" />
                  <p>{replies.length} answers</p>
                </div>
              )}
              <div>
                {showReply ? (
                  <Button
                    ghost
                    type="primary"
                    icon={<FontAwesomeIcon className="mr-2" icon={faReply} />}
                    onClick={toggleShowEditor}
                  >
                    Reply
                  </Button>
                ) : (
                  <Button ghost type="primary" onClick={handleAskFollowUp}>
                    Ask a follow-up question
                  </Button>
                )}
              </div>
            </div>
          )}
        </section>
      </Content>
    </Card>
  );
};

export default InboxQuestionContent;
