import { simpleActionTypes } from "./simpleActionTypes.js";
import { sagaActionTypes } from "./sagaActionTypes.js";

const actionTypes = {
  ...simpleActionTypes,
  ...sagaActionTypes
};

export {
  simpleActionTypes,
  sagaActionTypes,
  actionTypes
};
