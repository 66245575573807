import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { getCompanyById } from "redux/actions";
import Spacer from "components/layout/Spacer";
import CompanyProfile from "./CompanyProfile";
import CompanyPrograms from "./CompanyPrograms";
import CompanyTeams from "./CompanyTeams";
import CompanySponsors from "./CompanySponsors";
import CompanyManagers from "./CompanyManagers";
import Loader from "components/ui/Loader";
import Socket from "utils/Socket";
import CompanyUsers from "./CompanyUsers";

class Company extends React.Component {
  componentDidMount() {
    this.getCompany();

    const { match } = this.props;
    const companyId = this.props.companyId || match?.params?.id;
    this.companySubscriptionId = Socket.subscribe(
      `/company/${companyId}`,
      this.getCompany
    );
    this.teamSubscriptionId = Socket.subscribe(
      `/company/${companyId}/team`,
      this.getCompany
    );
    /* Commented by Karthik
     * This will lead to close the program prompt on creating program in the first step,
     * hence user will not proceed with further steps
     */
    // this.programSubscriptionId = Socket.subscribe(
    //   `/company/${companyId}/program`,
    //   this.getCompany
    // );
  }

  getCompany = () => {
    const { match } = this.props;
    getCompanyById(this.props.companyId || match?.params?.id);
  };

  componentWillUnmount = () => {
    Socket.unsubscribe([
      this.companySubscriptionId,
      this.teamSubscriptionId,
      this.programSubscriptionId,
    ]);
  };

  render() {
    const { action, match, myCompany } = this.props;
    const companyId = this.props.companyId || match?.params?.id;
    // if (myCompany && myCompany.message) {
    //   return <Redirect to="/company/create" />;
    // }
    if (action.GET_COMPANY_BY_ID.isFetched && action.GET_MY_PROFILE.isFetched) {
      const { company, whoami } = this.props;

      let isAdmin = false;
      let isCompanyAdmin = false;
      let isCompanyMember = false;
      let canView = false;

      if (whoami.role === "Admin") {
        isAdmin = true;
      } else {
        isAdmin = false;
      }

      if (company.companyAdmin === whoami._id) {
        isCompanyAdmin = true;
      } else {
        isCompanyAdmin = false;
      }

      if (company.companyMembers?.includes(whoami._id)) {
        isCompanyMember = true;
      } else {
        isCompanyMember = false;
      }

      if (company && company.companyMembers) {
        company.companyMembers.map((id) => {
          if (id === whoami._id) {
            canView = true;
          }

          return true;
        });
      }
      if (!isAdmin && !isCompanyAdmin && !canView) {
        return <Redirect to="/dashboard" />;
      } else {
        return (
          <>
            <CompanyProfile
              isCompanyAdmin={isCompanyAdmin}
              isCompanyMember={isCompanyMember}
            />
            <Spacer size={20} />
            {company &&
            company.companyType === "Lender" &&
            (canView || whoami.role === "Admin") ? (
              <CompanyPrograms match={match} isCompanyAdmin={isCompanyAdmin} />
            ) : (
              <></>
            )}
            {(canView || isAdmin) && (
              <CompanyTeams isCompanyAdmin={isCompanyAdmin}/>
            )}
            {company &&
              company.companyType === "Borrower" &&
              (whoami.role === "Borrower" || whoami.role === "Admin") && (
                <>
                  <CompanySponsors isCompanyAdmin={isCompanyAdmin}  isAdmin={isAdmin}/>
                  <CompanyManagers isCompanyAdmin={isCompanyAdmin} isAdmin={isAdmin}/>
                </>
              )}
            <CompanyUsers />
          </>
        );
      }
    } else {
      return <Loader />;
    }
  }
}

function mapStateToProps(state) {
  return {
    action: state.action,
    whoami: state.auth.whoami,
    myCompany: state.company.myCompany,
    company: state.company.company,
  };
}

export default connect(mapStateToProps)(Company);
