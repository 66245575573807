import React from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { Field, reduxForm } from "redux-form";
import { reduxFormInput } from "components/form/ReduxForm";
import { required, noSpaces } from "utils/validation";
import { addToCompany } from "redux/actions";
import Spacer from "components/layout/Spacer";

class CompanyAdd extends React.Component {
  constructor(props) {
    super(props);

    this.submitFn = this.submitFn.bind(this);
  }

  submitFn(data) {
    const { companyId, onSubmit } = this.props;

    addToCompany(companyId, {
      add: data.memberId,
    });

    onSubmit();
  }

  render() {
    const { handleSubmit } = this.props;

    return (
      <Row>
        <Col xs="12">
          <Form
            className="no-padding no-border"
            onSubmit={handleSubmit(this.submitFn)}
          >
            <Row>
              <Col xs="12">
                <p>
                  Add users to your company who have an account on Term St. but
                  currently do not have a company. Their member ID can be found
                  on their profile page. Request for them to send you the ID and
                  add it to the field below.
                </p>
                <Spacer size={30} />
              </Col>
              <Col xs="12">
                <Field
                  className="form-control"
                  component={reduxFormInput}
                  validate={[required, noSpaces]}
                  name="memberId"
                  type="text"
                  placeholder="Enter member ID"
                  label="*Member ID"
                />
                <Spacer size={50} />
              </Col>
              <Col className="text-right" xs="12">
                <Button type="submit" variant="primary">
                  Add
                </Button>
                <Spacer size={20} />
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    );
  }
}

export default reduxForm({
  form: "AddMembers",
  enableReinitialize: true,
})(CompanyAdd);
