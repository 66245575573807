import React from "react";
import { Dropdown, Tooltip } from "antd";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { labelActions } from "../enums";

export const LabelOptions = ({ onClick }) => {
  return (
    <Dropdown
      menu={{ items, onClick }}
      trigger={["click"]}
      placement="bottomRight"
      className="inbox-label-node-options"
    >
      <span className="px-2 cursor-pointer">
        <Tooltip title="More">
          <FontAwesomeIcon icon={faEllipsisV} />
        </Tooltip>
      </span>
    </Dropdown>
  );
};

const items = [
  {
    label: "Edit",
    key: labelActions.edit,
  },
  {
    label: "Delete",
    key: labelActions.delete,
  },
];
