import { createStore, applyMiddleware,compose  } from "redux";
import { createLogger } from "redux-logger";
import createSagaMiddleware from "redux-saga";

import rootReducer from "../redux/reducers";
import rootSaga from "../sagas/rootSaga";

const sagaMiddleware = createSagaMiddleware();
const middlewares = [];
const loggerMiddleware = createLogger({
  collapsed: (getState, action, logEntry) => !logEntry.error
});
middlewares.push(loggerMiddleware);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, /* preloadedState, */ composeEnhancers(
  applyMiddleware(sagaMiddleware, ...middlewares),))
// const store = createStore(
//   rootReducer,
//   applyMiddleware(sagaMiddleware, ...middlewares),
// );

sagaMiddleware.run(rootSaga);

export default store;

export const dispatch = (action) => store.dispatch(action);
