import Spacer from "components/layout/Spacer";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { Button, Col, Row } from "react-bootstrap";
import { useDrop } from "react-dnd";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { createSection, getSection } from "redux/actions";
import PopupPrompt from "components/ui/PopupPrompt";
import CreateSectionConfirmation from "./CreateSectionConfirmation";
import CreateDraftSectionConfirmation from "./CreateDraftSectionConfirmation";
import SelectedClauses from "./SelectedClauses";
import { useEffect } from "react";
import SpecialtySectionCreateFromMySaved from "./SpecialtySectionCreateFromMySaved";
import { Droppable } from "react-beautiful-dnd";

export const ItemTypes = {
  BOX: "box",
};

const SpecialtySectionCreateFromBlank = (props) => {
  const { section, columns, whoami, match } = props;

  const history = useHistory();
  const [, drop] = useDrop({
    accept: [ItemTypes.BOX],
    drop(item, monitor) {},
    collect: (monitor) => ({
      isOverCurrent: monitor.isOver({ shallow: true }),
      canDrop: monitor.canDrop(),
    }),
  });
  const [showSectionConfirmModal, setShowSectionConfirmModal] = useState(false);
  const [showDraftConfirmModal, setShowDraftConfirmModal] = useState(false);
  const [showMySavedModal, setShowMySavedModal] = useState(false);

  const handleSubmitSavedSection = (sectionId) => {
    getSection(sectionId);
    setShowMySavedModal(false);
  };

  useEffect(() => {
    if (section.lastSectionCreated) {
      history.push("/termsheet-clause/specialty-sections");
    }
  }, [section.lastSectionCreated]);

  const handleSubmit = (data, isDraft) => {
    let updatedCoumns = [];
    for (let i = 0; i < columns.length; i++) {
      const column = columns[i];
      let errorMessage = "";
      if (column.sectionType && !column.sectionValue) {
        errorMessage = `Please fill ${column.sectionName}`;
      } else if (!column.sectionType && !column.langDesc) {
        errorMessage = `Please add language for ${column.sectionName}`;
      }
      if (errorMessage) {
        toast.error(errorMessage, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }
      updatedCoumns.push({ ...column, order: i });
    }

    const newClauses = updatedCoumns.map((e) => ({
      clause: e.clause._id,
      displayName: e.sectionName,
      language: e.langDesc,
    }));

    createSection({
      sectionName: data.sectionName,
      clauses: newClauses,
      notes: data.notes,
      adminAvailability: {
        key: data.adminAvailability.value,
        values: [],
      },
      owner: whoami._id,
      status: isDraft ? "DRAFT" : "ACTIVE",
      isTemplate: whoami.role === "Admin" ? true : false,
    });
  };

  return (
    <Col xs={12} ref={drop} className="d-flex flex-column h-100">
      {columns.length ? (
        <Row className="flex-grow-1">
          <Col xs={12}>
            <Spacer size={20} />
            <SelectedClauses match={match} />
          </Col>
        </Row>
      ) : (
        <Droppable droppableId="droppableId-clauses" type="CLAUSES">
          {(provided) => (
            <Row
              className="droppable-container flex-grow-1 justify-content-center"
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              <Col
                xs={12}
                className="droppable-content d-flex flex-column align-items-center"
              >
                <Spacer size={40} />
                <p className="text-center bold mb-2">
                  Create your term sheet section by dragging clauses from the
                  left and dropping them here.
                </p>
                <Spacer size={30} />
                <div className="text-center">
                  <Button
                    type="submit"
                    variant="link"
                    className="bold color-purple-2"
                    onClick={() => setShowMySavedModal(true)}
                  >
                    Click here to load an existing section
                  </Button>
                  <Spacer size={30} />
                  <div>
                    <hr />
                    <hr />
                    <hr />
                  </div>
                  <Spacer size={30} />
                  <h3>Drag and Drop here</h3>
                </div>
              </Col>
            </Row>
          )}
        </Droppable>
      )}

      <Row className="mt-3">
        <Col xs={12} className="d-flex justify-content-end">
          <div>
            <Link to="/termsheet-clause/specialty-sections/choice">
              <Button variant="primary mr-3" to="/">
                Exit
              </Button>
            </Link>

            <Button
              variant="primary mr-3"
              onClick={() => {
                setShowDraftConfirmModal(true);
              }}
            >
              Save Draft
            </Button>
            <Button
              type="submit"
              variant="tertiary"
              onClick={() => {
                setShowSectionConfirmModal(true);
              }}
            >
              Save Section
            </Button>
          </div>
        </Col>
      </Row>

      <PopupPrompt
        promptTitle="Choose a Previously Saved Section"
        promptBody={
          <SpecialtySectionCreateFromMySaved
            onCancel={() => setShowMySavedModal(false)}
            onSubmit={handleSubmitSavedSection}
          />
        }
        show={showMySavedModal}
        onCancel={() => setShowMySavedModal(false)}
        onHide={() => setShowMySavedModal(false)}
      />
      <PopupPrompt
        promptTitle="Save your Section"
        promptBody={
          <CreateSectionConfirmation
            onCancel={() => {
              setShowSectionConfirmModal(false);
            }}
            onSubmit={handleSubmit}
          />
        }
        show={showSectionConfirmModal}
        onCancel={() => {
          setShowSectionConfirmModal(false);
        }}
        onHide={() => {
          setShowSectionConfirmModal(false);
        }}
      />
      <PopupPrompt
        promptTitle="Save Draft Section"
        promptBody={
          <CreateDraftSectionConfirmation
            onCancel={() => {
              setShowDraftConfirmModal(false);
            }}
            onSubmit={(data) => handleSubmit(data, true)}
          />
        }
        show={showDraftConfirmModal}
        onCancel={() => {
          setShowDraftConfirmModal(false);
        }}
        onHide={() => {
          setShowDraftConfirmModal(false);
        }}
      />
    </Col>
  );
};

const mapStateToProps = (state) => ({
  whoami: state.auth.whoami,
  columns: state.termsheet.termsheet.columns || [],
  section: state.section,
});
export default connect(mapStateToProps)(SpecialtySectionCreateFromBlank);
