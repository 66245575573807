import { actionTypes } from "redux/actions";
import {
  reducerCreator,
  multipleReducers,
  genericReducerCreator,
} from "./meta";

export const notificationsData = multipleReducers({
  initializers: {
    initialState: {
      notifications: [],
      totalNotifications: 0,
      unread: 0,
    },
  },
  list: [
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.GET_NOTIFICATIONS,
        successFn: (action, state) => {
          return {
            notifications:
              action.params.page === 1
                ? action.data.body.rows
                : [...state.notifications, ...action.data.body.rows],
            totalNotifications: action.data.body.count,
            unread: action.data.body.unreadCount,
          };
        },
      },
    },
    {
      reducerCreatorFn: genericReducerCreator,
      params: {
        type: actionTypes.READ_NOTIFICATIONS,
        successFn: (action, state) => {
          const { id, index } = action.data;
          const updatednotifications = [...state.notifications];
          updatednotifications[index].read = true;
          return {
            notifications: updatednotifications,
            unread: state.unread - 1,
          };
        },
      },
    },
    {
      reducerCreatorFn: genericReducerCreator,
      params: {
        type: actionTypes.READ_ALL_NOTIFICATIONS,
        successFn: (action, state) => {
          const updatednotifications = [...state.notifications].map(
            (notification) => {
              return { ...notification, read: true };
            }
          );
          return {
            notifications: updatednotifications,
            unread: 0,
          };
        },
      },
    },
  ],
});
