import React, { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Spacer from "components/layout/Spacer";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import PopupPrompt from "components/ui/PopupPrompt";
import SpecialtySectionCreateFromMySaved from "./SpecialtySectionCreateFromMySaved";
import SpecialtySectionCreateFromTemplate from "./SpecialtySectionCreateFromTemplate";

function SpecialtySectionChoice({ whoami, match }) {
  const history = useHistory();
  const [showMySavedModal, setShowMySavedModal] = useState(false);
  const [showTemplateModal, setShowTemplateModal] = useState(false);

  const handleSubmitSavedSection = (sectionId) => {
    history.push(
      `/termsheet-clause/specialty-sections/create?sectionId=${sectionId}`
    );
  };
  const handleSubmitTemplateSection = (sectionId) => {
    history.push(
      `/termsheet-clause/specialty-sections/create?sectionId=${sectionId}&isTemplate=true`
    );
  };

  if (whoami?.role === "Admin" || whoami?.role === "Lender") {
    return (
      <Container>
        <Row className="price-table">
          <Col xs="12">
            <Spacer size={50} />
          </Col>

          <Col className="price-table-item" xs="12" md={{ span: 4, offset: 1 }}>
            <h5 className="price-table-heading">TermStreet Templates</h5>
            <Spacer size={10} />
            <h3>Pick a TermStreet Section Template</h3>
            <Spacer size={20} />
            <Button
              variant="primary"
              className="outline"
              onClick={() => {
                setShowTemplateModal(true);
              }}
            >
              Select
            </Button>
          </Col>

          <Col className="d-xs-block d-sm-block d-md-none d-lg-none">
            <Spacer size={20} />
          </Col>

          <Col className="price-table-item" xs="12" md={{ span: 4, offset: 2 }}>
            <h5 className="price-table-heading">my saved sections</h5>
            <Spacer size={10} />
            <h3>Start from Saved Section </h3>
            <Spacer size={20} />
            <Button
              variant="primary"
              className="outline"
              onClick={() => {
                setShowMySavedModal(true);
              }}
            >
              Select
            </Button>
          </Col>

          <Col xs="12">
            <Spacer size={50} />
          </Col>

          <Col className="price-table-item" xs="12" md={{ span: 4, offset: 4 }}>
            <h5 className="price-table-heading">Start from Scratch</h5>
            <Spacer size={10} />
            <h3>Open a Blank Template</h3>
            <Spacer size={20} />
            <Link to="/termsheet-clause/specialty-sections/create">
              <Button variant="primary" className="outline">
                Select
              </Button>
            </Link>
          </Col>

          <Col className="d-xs-block d-sm-block d-md-none d-lg-none">
            <Spacer size={20} />
          </Col>
        </Row>

        <Row>
          <Col xs={12} className="text-right">
            <Spacer size={40} />
            <Link to="/termsheet-clause/specialty-sections">
              <Button variant="primary">Go Back</Button>
            </Link>
          </Col>
        </Row>

        <PopupPrompt
          promptTitle="Choose a Previously Saved Section"
          promptBody={
            <SpecialtySectionCreateFromMySaved
              onCancel={() => setShowMySavedModal(false)}
              onSubmit={handleSubmitSavedSection}
            />
          }
          show={showMySavedModal}
          onCancel={() => setShowMySavedModal(false)}
          onHide={() => setShowMySavedModal(false)}
        />
        <PopupPrompt
          promptTitle="Choose a TermStreet Template Section"
          promptBody={
            <SpecialtySectionCreateFromTemplate
              onCancel={() => setShowTemplateModal(false)}
              onSubmit={handleSubmitTemplateSection}
            />
          }
          show={showTemplateModal}
          onCancel={() => setShowTemplateModal(false)}
          onHide={() => setShowTemplateModal(false)}
        />
      </Container>
    );
  }

  return null;
}

function mapStateToProps(state) {
  return {
    whoami: state.auth.whoami,
  };
}

export default connect(mapStateToProps)(SpecialtySectionChoice);
