import { actionTypes } from "redux/actions";
import { reducerCreator, multipleReducers } from "./meta";

export const fileManager = multipleReducers({
  initializers: {
    initialState: {
      files: [],
      permissions: [],
      usersPermissions: [],
      lendersPermissionLastUpdate: Date.now(),
    },
  },
  list: [
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.GET_DEAL_FILES,
        successFn: (action) => ({
          files: action.data.body,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.GET_PERMISSION,
        successFn: (action) => ({
          permissions: action.data.body,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.GET_PERMISSION_USERS,
        successFn: (action) => ({
          usersPermissions: action.data.body,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.SEARCH_DEAL_FILES,
        successFn: (action) => ({
          files: action.data.body,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.MANAGE_FILE_PERMISSION,
        successFn: () => ({
          lendersPermissionLastUpdate: Date.now(),
        }),
      },
    },
  ],
});
