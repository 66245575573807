import React, { useEffect, useState } from "react";
import { Form, Input, Modal } from "antd";
import TextArea from "components/form/TextArea";
import { addDealCard, editDealCard } from "redux/actions";

const AddEditSectionModal = ({ open, contextData, editData, onCancel }) => {
  const [form] = Form.useForm();
  const [description, setDescription] = useState(" ");

  const { dealId } = contextData || {};

  useEffect(() => {
    if (!editData) {
      form.setFieldsValue(null);
      setDescription(" ");
      return;
    }

    form.setFieldsValue({ title: editData.title });
    setDescription(editData.description);
  }, [editData]);

  const onFinish = (values) => {
    console.log("Success:", values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const resetForm = () => {
    form.resetFields();
    setDescription(" ");
  };

  const createSection = (values) => {
    addDealCard(dealId, {
      title: values.title,
      description: values.description,
    });
    onCancel();
  };

  const editSection = (values) => {
    editDealCard(dealId, {
      _id: editData._id,
      title: values.title,
      description: values.description,
    });
    onCancel();
  };

  return (
    <Modal
      width={"50vw"}
      open={open}
      title={editData ? "Edit Section" : "Add Section"}
      okText={editData ? "Save" : "Submit"}
      cancelText="Cancel"
      onCancel={onCancel}
      afterClose={resetForm}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            if (editData) {
              editSection(values);
            } else {
              createSection(values);
            }
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form
        form={form}
        autoComplete="off"
        layout="vertical"
        style={{ border: 0 }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item label="Title" name="title" rules={[{ required: true }]}>
          <Input placeholder="Type here" />
        </Form.Item>

        <Form.Item label="" name="description" rules={[{ required: true }]}>
          <TextArea
            hideLabel
            height={"50vh"}
            placeholder="Type your description here..."
            input={{
              value: description,
              onChange: (newDesc) => {
                setDescription(newDesc);
                form.setFieldValue("description", newDesc);
              },
            }}
            init={{
              paste_data_images: true,
              content_style: "body { font-size:14px }",
              plugins: [...TextArea.plugins, "image", "media"],
              toolbar: `${TextArea.toolbar} image media`,
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddEditSectionModal;
