import React from "react";

export const ChatBubbleSvg = (props) => (
  <svg
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 17.8575V1.97376C0 1.49962 0.147369 1.0966 0.442105 0.764698C0.736842 0.432799 1.09474 0.26685 1.51579 0.26685H14.4842C14.9053 0.26685 15.2632 0.432799 15.5579 0.764698C15.8526 1.0966 16 1.49962 16 1.97376V12.7842C16 13.2583 15.8526 13.6613 15.5579 13.9932C15.2632 14.3251 14.9053 14.4911 14.4842 14.4911H2.98947L0 17.8575ZM1.26316 14.42L2.46316 13.0687H14.4842C14.5544 13.0687 14.6142 13.0408 14.6636 12.9852C14.7124 12.9302 14.7368 12.8632 14.7368 12.7842V1.97376C14.7368 1.89473 14.7124 1.82741 14.6636 1.77177C14.6142 1.71677 14.5544 1.68927 14.4842 1.68927H1.51579C1.44561 1.68927 1.38611 1.71677 1.33726 1.77177C1.28786 1.82741 1.26316 1.89473 1.26316 1.97376V14.42ZM1.26316 1.97376V1.68927V14.42V1.97376Z"
      fill="#343A40"
    />
  </svg>
);
