import queryString from "query-string";
import { dispatch } from "config/store";
import { actionTypes } from "./actionTypes";

export const createLabel = (data) => {
  const action = {
    type: actionTypes.CREATE_LABEL,
    config: {
      method: "post",
      url: `/labels`,
      data,
    },
  };

  dispatch(action);
};

export const updateLabel = (labelId, data) => {
  const action = {
    type: actionTypes.UPDATE_LABEL,
    config: {
      method: "put",
      url: `/labels/${labelId}`,
      data,
    },
  };

  dispatch(action);
};

export const getLabels = (query = { limit: 100, order: "ASC" }) => {
  const action = {
    type: actionTypes.GET_LABELS,
    config: {
      method: "get",
      url: `/labels?${queryString.stringify({
        limit: query.dealId,
        order: query.order,
      })}`,
    },
  };

  dispatch(action);
};

export const getLabelsTreeView = (query = { limit: 100, order: "ASC" }) => {
  const action = {
    type: actionTypes.GET_LABELS_TREE_VIEW,
    config: {
      method: "get",
      url: `/labels?${queryString.stringify({
        limit: query.dealId,
        order: query.order,
        tree: true,
      })}`,
    },
  };

  dispatch(action);
};

export const deleteLabel = (labelId) => {
  const action = {
    type: actionTypes.DELETE_LABEL,
    config: {
      method: "delete",
      url: `/labels/${labelId}`,
    },
  };

  dispatch(action);
};
