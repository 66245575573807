import React, { useContext, useMemo } from "react";
import { Tabs } from "antd";
import { useHistory } from "react-router-dom";
import { Draggable, Droppable } from "react-beautiful-dnd";
import ExecutiveSummary from "./ExecutiveSummary";
import PortfolioDetails from "./PortfolioDetails";
import PropertyDetails from "./PropertyDetails";
import PropertyDescription from "./PropertyDescription";
import { useSearchObject } from "utils/hooks";
import AddSection from "./AddSection";
import AddedSection from "./AddedSection";
import SponsorInformation from "./SponsorInformation";
import Disclosures from "./Disclosures";
import SourcesAndUses from "./SourcesAndUses";
import { DealViewContext, dealViewIds } from "../DealViewContext";
import { useSelector } from "react-redux";
import { colors } from "containers/quotes/constants";
import AddEditSectionModal from "../AddEditSectionModal";
import useAddEditSectionModalStore from "../hooks/useAddEditSectionModalStore";
import ManagerInformation from "./ManagerInformation";

const DealTabs = ({ deal }) => {
  const history = useHistory();
  const searchObject = useSearchObject();
  const activeKey = searchObject?.tab || "1";

  const handleChangeTab = (tabKey) => {
    history.push({
      pathname: history.location.pathname,
      search: `?tab=${tabKey}`,
    });
  };

  const items = deal.properties?.length
    ? [
        {
          key: "1",
          label: <span className="tw-px-4">Portfolio Summary</span>,
          children: (
            <TabsContent
              deal={deal}
              portfolioDeal={deal}
              onChangeTab={handleChangeTab}
            />
          ),
        },
        ...deal.properties.map((property, index) => ({
          key: String(index + 2),
          label: property.propertyName,
          children: (
            <TabsContent
              deal={property}
              portfolioDeal={deal}
              onChangeTab={handleChangeTab}
            />
          ),
        })),
      ]
    : [
        {
          key: "1",
          label: <span className="tw-px-4">{deal.propertyName}</span>,
          children: (
            <TabsContent
              deal={deal}
              portfolioDeal={deal}
              onChangeTab={handleChangeTab}
            />
          ),
        },
      ];

  return (
    <div className="tw-absolute tw-w-full">
      <Tabs
        className="tw-ml-1"
        activeKey={activeKey}
        items={items}
        onChange={handleChangeTab}
      />
    </div>
  );
};

const TabsContent = ({ deal, portfolioDeal, onChangeTab }) => {
  const { additionalInfo = [] } = deal;
  const isProperty = deal._id !== portfolioDeal._id;

  const whoami = useSelector((state) => state.auth.whoami);
  const { openModal, contextData, editData, closeModal } =
    useAddEditSectionModalStore();
  const cardOrder = useContext(DealViewContext);

  const propertyDetails = deal.properties?.length ? (
    <PortfolioDetails deal={deal} onChangeTab={onChangeTab} />
  ) : (
    <PropertyDetails deal={deal} />
  );

  const sectionMapping = useMemo(() => {
    let result = {
      [dealViewIds.PropertyDetails]: propertyDetails,
      [dealViewIds.PropertyDescription]: <PropertyDescription deal={deal} />,
      [dealViewIds.SourcesAndUses]: <SourcesAndUses deal={deal} />,
      [dealViewIds.SponsorInformation]: <SponsorInformation deal={deal} />,
      [dealViewIds.ManagerInformation]: <ManagerInformation deal={deal} />,
      [dealViewIds.ExecutiveSummary]: <ExecutiveSummary deal={deal} />,
      [dealViewIds.Disclosures]: (
        <Disclosures deal={deal} portfolioDeal={portfolioDeal} />
      ),
    };
    additionalInfo.forEach((item) => {
      result[item._id] = <AddedSection deal={deal} section={item} />;
    });
    return result;
  }, [deal]);

  const dragDropDisabled = whoami.role !== "Admin";

  return (
    <div className="tw-ml-2">
      <Droppable
        type="DEAL_CARDS"
        isDropDisabled={dragDropDisabled}
        droppableId="droppableId-dealsView"
      >
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className="droppable-container"
          >
            {cardOrder.map((item, index) => {
              if (
                item === dealViewIds.SponsorInformation &&
                portfolioDeal?.isPortifolio &&
                isProperty
              )
                return null;

              if (
                item === dealViewIds.ExecutiveSummary &&
                ((portfolioDeal?.isPortifolio && isProperty) ||
                  !portfolioDeal?.isPortifolio)
              )
                return null;

              if (
                item === dealViewIds.PropertyDescription &&
                portfolioDeal?.isPortifolio &&
                !isProperty
              )
                return null;

              if (
                item === dealViewIds.ManagerInformation &&
                ((portfolioDeal?.isPortifolio &&
                  isProperty &&
                  portfolioDeal?.isManagerSame) ||
                  (portfolioDeal?.isPortifolio &&
                    !isProperty &&
                    !portfolioDeal?.isManagerSame))
              )
                return null;

              return (
                <Draggable
                  isDragDisabled={dragDropDisabled}
                  key={item}
                  draggableId={item}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      {sectionMapping[item]}
                    </div>
                  )}
                </Draggable>
              );
            })}
            <Draggable
              isDragDisabled
              draggableId={dealViewIds.AddSection}
              index={cardOrder.length - 1}
            >
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={getItemStyle(
                    snapshot.isDragging,
                    provided.draggableProps.style
                  )}
                >
                  <AddSection deal={deal} />
                </div>
              )}
            </Draggable>
            {snapshot.isDraggingOver && (
              <div className="droppable-placeholder" />
            )}
          </div>
        )}
      </Droppable>

      <AddEditSectionModal
        open={!!openModal}
        contextData={contextData}
        editData={editData}
        onCancel={closeModal}
      />
      {/* @TODO: Hide for now */}
      {/* <InvestmentHighlights deal={deal} /> */}
      {/* <DistanceTabs deal={deal} /> */}
    </div>
  );
};

const getItemStyle = (isDragging, draggableStyle) => ({
  background: isDragging ? colors.white : "",
  border: isDragging ? `1px solid ${colors.blue}` : "",
  marginBottom: isDragging ? "0px" : "40px",
  ...draggableStyle,
});

export default DealTabs;
