import React, { useMemo } from "react";
import { Form, Input, Modal, TreeSelect } from "antd";
import { connect } from "react-redux";
import { createTreeData } from "../inboxUtils";

const CreateLabel = ({
  open,
  selectedLabel,
  labelsTreeView,
  onCreate,
  onCancel,
}) => {
  const [form] = Form.useForm();

  React.useEffect(() => {
    if (selectedLabel) {
      form.setFieldsValue({
        label: selectedLabel.title,
        parent: selectedLabel.parent,
      });
    }
  }, [selectedLabel]);

  const treeSelectData = useMemo(
    () => createTreeData(labelsTreeView, "value"),
    [labelsTreeView]
  );

  const onFinish = (values) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Modal
      open={open}
      title="New Label"
      okText="Submit"
      cancelText="Cancel"
      onCancel={onCancel}
      afterClose={form.resetFields}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form
        form={form}
        autoComplete="off"
        layout="vertical"
        style={{ border: 0 }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item label="Label name" name="label" rules={[{ required: true }]}>
          <Input placeholder="Type here" />
        </Form.Item>
        <Form.Item
          label="Nest label under"
          name="parent"
          rules={[{ required: false }]}
        >
          <TreeSelect
            showSearch
            style={{ width: "100%" }}
            dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
            placeholder="Select here"
            treeNodeFilterProp="title"
            allowClear
            treeDefaultExpandAll
            treeData={treeSelectData}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

function mapStateToProps(state) {
  return {
    labelsTreeView: state.label.labelsTreeView,
  };
}

export default connect(mapStateToProps)(CreateLabel);
