import axios from 'axios';
import { v4 as uuidv4 } from "uuid";
import Promise from 'bluebird';
import { getKeyFromLocal } from "./lsUtils";
import constants from "config/constants";

Promise.config({
    cancellation: true,
});


const getEndPoint = () => {
    const origins = constants.API_ORIGINS;
    return constants.API_PROTOCOL + origins[window.location.host];
}
const getAccessToken = () => getKeyFromLocal(
    constants.TERMST_LOCAL_STORAGE.JWT_KEY
);


const simpleAxiosInstance = axios.create({
    baseURL: getEndPoint(),
});




class Api {
    constructor() {
        this.call = {};
    }

    doPromise = (axiosInstance, config, requestName) => {
        config = {
            ...config,
            headers: {
                ...(config.headers || {}),
                accessToken: getAccessToken()
            }
        }
        const requestPromise = new Promise((resolve, reject, onCancel) => {
            axiosInstance(config)
                .then(
                    success => {
                        resolve(success);
                    },
                    error => {
                        if (axios.isCancel(error)) {
                            // eslint-disable-next-line no-console
                            console.log(`Request Aborted for ${config.url}`);
                        } else {
                            reject(error);
                        }
                    }
                )
                .catch(error => {
                    reject(error);
                });
            onCancel(() => {
                this.call[requestName].cancel();
            });
        });
        return requestPromise;
    };

    request = (config = {}) => {
        const requestName = uuidv4();
        this.call[requestName] = axios.CancelToken.source();
        config.cancelToken = this.call[requestName].token;
        return this.doPromise(simpleAxiosInstance, config, requestName);
    };
}

const cancelApis = (apis = [], msg = '') => {
    for (let i = 0; i < apis.length; i++) {
        if (apis[i] && apis[i].cancel) {
            apis[i].cancel(msg);
        }
    }
};

const api = new Api();

export { cancelApis };
export default api;
