import React from "react";
import { Row, Form, Col, Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Spacer from "components/layout/Spacer";
import { getKeyFromLocal } from "utils/lsUtils";
import constants from "config/constants";
import { reduxFormNumber as InputNumber } from "components/form/ReduxForm";

const fields = [
  {
    key: "status",
    label: "Status",
  },
  {
    key: "lender",
    label: "Lender",
    getValue: (data) => data.companyId?.companyName,
  },
  {
    key: "loanAmount",
    label: "Amount",
    prefix: "$",
  },
  {
    key: "term",
    label: "Term",
    suffix: " years",
  },
  {
    key: "amortization",
    label: "Amortization",
    suffix: " years",
  },
  {
    key: "IOPeriod",
    label: "IO Period",
    suffix: " months",
  },
  {
    key: "spread",
    label: "Spread",
  },
  {
    key: "indexRate",
    label: "Index Rate",
    suffix: "%",
  },
  {
    key: "rate",
    label: "Rate",
    suffix: "%",
  },
];

const DisplayQuote = ({ quote, downloadQuotes, ...props }) => (
  <Row className={props.className}>
    <Col>
      <Card className="quote-card">
        <Card.Body className="quote-card-body">
          {fields.map((field) => {
            const displayValue = field.getValue
              ? field.getValue(quote)
              : quote[field.key];

            return (
              <Row
                key={field.key}
                title={displayValue}
                style={{ whiteSpace: "nowrap" }}
              >
                {(() => {
                  if (field.prefix === "$") {
                    return (
                      <InputNumber
                        displayType="text"
                        prefix={"$"}
                        meta={{}}
                        input={{
                          value: displayValue,
                        }}
                        thousandSeparator
                      />
                    );
                  }
                  if (field.suffix === "%") {
                    return (
                      <InputNumber
                        displayType="text"
                        suffix={"%"}
                        meta={{}}
                        input={{
                          value: displayValue,
                        }}
                        decimalScale={2}
                        fixedDecimalScale
                      />
                    );
                  }
                  return `${displayValue} ${field.suffix || ""}`;
                })()}
              </Row>
            );
          })}
        </Card.Body>
      </Card>
      <Row>
        <Col className="text-center">
          <Link
            target="_blank"
            to={`/deal/${quote.dealId}/termsheet/${quote.termsheetId}?viewQuoteId=${quote._id}`}
          >
            <Button className="fake-link" variant="fake-link">
              View
            </Button>
          </Link>{" "}
          &nbsp;&nbsp;
          <Button
            className="fake-link"
            variant="fake-link"
            onClick={() => downloadQuotes([quote._id])}
          >
            Download (.xlsx)
          </Button>
        </Col>
      </Row>
    </Col>
  </Row>
);
const CompareQuotes = ({ quotes, ...props }) => {
  const origins = constants.API_ORIGINS;
  let endPoint = constants.API_PROTOCOL + origins[window.location.host];
  const jwtToken = getKeyFromLocal(constants.TERMST_LOCAL_STORAGE.JWT_KEY);

  const downloadQuotes = (ids) => {
    if (!ids) {
      ids = quotes.map((q) => q._id);
    }
    fetch(`${endPoint}/quotes/export/?quoteIds=${JSON.stringify(ids)}`, {
      headers: { accessToken: jwtToken },
    })
      .then((res) => res.blob())
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "quotes.csv");
        document.body.appendChild(link);
        link.click();
      });
  };

  return (
    <Form onSubmit={(e) => e.preventDefault()} style={{ paddingTop: 10 }}>
      <Row style={{ borderBottom: "1px solid #e0dede", marginBottom: 15 }}>
        <Col className="no-padding">
          <h4>
            <b>Compare quotes</b>
          </h4>
        </Col>
      </Row>
      <Row>
        <Col xs={4} sm={4} md={2} lg={2}>
          <div className="quote-labels-body">
            {fields.map((field) => {
              return <Row key={field.key}>{field.label}</Row>;
            })}
          </div>
        </Col>
        <Col
          xs={8}
          sm={8}
          md={10}
          lg={10}
          style={{ overflowX: "scroll", display: "flex", maxWidth: 975 }}
        >
          {quotes.map((quote) => (
            <DisplayQuote
              key={quote._id}
              quote={quote}
              downloadQuotes={downloadQuotes}
            />
          ))}
        </Col>
      </Row>
      <Spacer size={20} />
      <Row className="text-right">
        <Col>
          <Button variant="tertiary" onClick={() => downloadQuotes()}>
            DOWNLOAD ALL
          </Button>{" "}
          &nbsp;&nbsp;
          <Button onClick={props.onBackClick}>BACK</Button>
        </Col>
      </Row>
    </Form>
  );
};

export default CompareQuotes;
