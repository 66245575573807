import React from "react";
import { connect } from "react-redux";
import { Button, Form, Container, Row, Col } from "react-bootstrap";
import { Field, reduxForm } from "redux-form";
import { required, noSpaces } from "utils/validation";
import { reduxFormInput, reduxFormSelect } from "components/form/ReduxForm";
import { getTags, mergeTags } from "redux/actions";
import Spacer from "components/layout/Spacer";

class MergeTags extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tagOptions: [],
    };

    this.submitFn = this.submitFn.bind(this);
  }

  componentDidMount() {
    this.handleGetTags();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.tags !== this.props.tags) {
      const tagOptions = this.props.tags.map((item) => ({
        value: item._id,
        label: item.tag,
      }));
      this.setState({ tagOptions });
    }
  }

  submitFn(data) {
    const ids = data.tags.map((item) => item.value);
    mergeTags({ ids, tag: data.tag });
  }

  handleGetTags = () => {
    getTags();
  };

  render() {
    const { action, handleSubmit } = this.props;
    const isProcessing =
      action.CREATE_TAG.isFetching || action.UPDATE_TAG.isFetching;

    return (
      <>
        <Container>
          <Form
            className="no-border no-padding"
            onSubmit={handleSubmit(this.submitFn)}
          >
            <Row>
              <Col xs="12">
                <Field
                  isMulti
                  showLoading
                  closeMenuOnSelect={false}
                  className="form-control"
                  component={reduxFormSelect}
                  validate={[required]}
                  name="tags"
                  type="select"
                  isLoading={action.GET_TAGS.isFetching}
                  options={this.state.tagOptions}
                  placeholder="Select at least 2 tags..."
                  label="*Tags"
                  onFocus={this.handleGetTags}
                />
                <Spacer size={10} />
              </Col>
            </Row>

            <Row>
              <Col xs="12">
                <Field
                  className="form-control"
                  component={reduxFormInput}
                  validate={[required, noSpaces]}
                  name="tag"
                  type="text"
                  placeholder="Enter new tag"
                  label="*New Tag"
                />
              </Col>
            </Row>
            <Spacer size={50} />
            <Row className="justify-content-center">
              <Col className="text-center" xs="12" sm="6">
                <Button disabled={isProcessing} type="submit" variant="primary">
                  {isProcessing ? "Saving..." : "Save"}
                </Button>
              </Col>
            </Row>
          </Form>
          <Spacer size={50} />
        </Container>
      </>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    action: state.action,
    tags: state.tag.tags,
    initialValues: {
      tag: props.selectedTags?.map((e) => e.tag).join(" ") || "",
      tags: (props.selectedTags || []).map((item) => ({
        value: item._id,
        label: item.tag,
      })),
    },
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "MergeTags",
    enableReinitialize: true,
  })(MergeTags)
);
