import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Row, Col, Button, Form } from "react-bootstrap";
import { reduxForm } from "redux-form";
import Spacer from "components/layout/Spacer";
import { getKeyFromLocal } from "utils/lsUtils";
import constants from "config/constants";
import { updateDeal, getDealById } from "redux/actions";
import { addMaps } from "components/google/maps";
import { getDealCreationPath } from "utils/commonUtils";

export const dealMapsDetails = [
  {
    title: "Micro Area Map",
    description:
      "The Micro Area Map is intended to display the property and the immediate surrounding streets. It should pick up nearby retail and other neighborhood features included in Google Maps.",
    meta: {
      zoom: 19,
    },
  },
  {
    title: "Neighborhood Map",
    description:
      "The Neighborhood Map is intended to show the property within its neighborhood. The area displayed here should be larger than the Micro Area Map.",
    meta: {
      zoom: 16,
    },
  },
  {
    title: "City Map",
    description:
      "The City Map should display the property location relative to the city. This map should show the entire city so that a lender can see where the property sits relative to the city center and major roads and neighborhoods throughout the city with ease.",
    meta: {
      zoom: 12,
    },
  },
];
class DealMaps extends React.Component {
  componentDidMount() {
    if (getKeyFromLocal(constants.TERMST_LOCAL_STORAGE.DEAL_ID) !== false) {
      const dealId = getKeyFromLocal(constants.TERMST_LOCAL_STORAGE.DEAL_ID);

      getDealById(dealId);
    }
    if (Object.keys(this.props.coordinates).length) {
      this.drawMaps();
    }
  }
  componentDidUpdate(prevProps) {
    if (
      !Object.keys(prevProps.coordinates).length &&
      Object.keys(this.props.coordinates).length
    ) {
      this.drawMaps();
    }
  }

  drawMaps = () => {
    dealMapsDetails.map((detail, index) => {
      addMaps(`map-${index}`, detail.meta.zoom, this.props.coordinates);

      return true;
    });
  };

  submitFn(data) {
    data.maps = true;
    if (getKeyFromLocal(constants.TERMST_LOCAL_STORAGE.DEAL_ID) !== false) {
      const dealId = getKeyFromLocal(constants.TERMST_LOCAL_STORAGE.DEAL_ID);

      updateDeal(dealId, data);
    }
  }

  render() {
    const { handleSubmit } = this.props;
    const path = getDealCreationPath();
    return (
      <Form
        className="full-width no-border mobile-no-padding"
        onSubmit={handleSubmit(this.submitFn)}
      >
        {dealMapsDetails.map((detail, index) => (
          <span key={detail.title}>
            <Row>
              <Col md="4">
                <h4>{detail.title}</h4>
                <br />
                <p>{detail.description}</p>
              </Col>
              <Col md="8">
                <div
                  style={{ height: "80vh", width: "80%" }}
                  id={`map-${index}`}
                ></div>
              </Col>
            </Row>
            <Spacer size={20} />
          </span>
        ))}
        <Spacer size={50} />
        <Row className="application-controls">
          <Col className="text-left" xs="6">
            <Button
              as={Link}
              className="pl-3 pr-3"
              to={path}
              variant="dark"
            >
              Back
            </Button>
          </Col>
          <Col className="text-right" xs="6">
            <Button className="pl-2 pr-2" type="submit" variant="primary">
              Continue
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}
function mapStateToProps(state) {
  const { propertyLat, propertyLong } = state.deal.deal;
  if (propertyLat && propertyLong) {
    return {
      coordinates: {
        lat: propertyLat,
        lng: propertyLong,
      },
    };
  }
  return {
    coordinates: {},
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "CreateDeal",
  })(DealMaps)
);
