import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button, Form } from 'react-bootstrap';
import { required, noSpaces } from 'utils/validation';
import { Field, reduxForm } from 'redux-form';
import { reduxFormInput, reduxFormTextarea, reduxFormSelect, reduxFormNumber } from 'components/form/ReduxForm';
import { states } from 'utils/dataUtil';
import Spacer from 'components/layout/Spacer';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { addPlacesAutoSuggestionsListener } from 'components/google/places';

const addressFieldsMapping = {
  address: 'address',
  state: 'state',
  city: 'city',
  zipCode: 'zip'
}


class Step1 extends React.Component {
  constructor(props) {
    super(props);

    this.submitFn = this.submitFn.bind(this);
  }
  componentDidMount() {
    addPlacesAutoSuggestionsListener(
      'deal-address-field',
      addressFieldsMapping,
      (data) => {
        Object.keys(data).map((key) => {
          if (key === 'state') {
            this.props.change(key, { label: data[key].name, value: data[key].nick_name.toUpperCase() })
          } else {
            this.props.change(key, data[key])
          }

          return true;
        });
      },
    );
  }

  submitFn(data) {
    const { onSubmit, nextStep } = this.props;

    onSubmit(data);
    nextStep();
  }

  render() {
    const { handleSubmit, currentStep } = this.props;

    const disableChrome = document.querySelectorAll('input[autocomplete="off"');

    for (let el of disableChrome) {
      if (el !== null) {
        el.setAttribute("autocomplete", "new-password");
      }
    }

    return (
      <fieldset disabled={currentStep === 1 ? false : true}>
        <Form className="full-width no-border" onSubmit={handleSubmit(this.submitFn)}>
          <Row>
            <Col xs="12">
              <Spacer size={20} />
              <h3 className="bold">Tell us a bit about your deal</h3>
              <Spacer size={30} />
            </Col>
          </Row>
          <Row>
            <Col xs="12" sm="6">
              <Field
                icon={<FontAwesomeIcon icon={faDollarSign} />}
                className="form-control"
                component={reduxFormNumber}
                validate={[required]}
                name="loanAmount"
                placeholder="Enter loan amount"
                label="*Loan Amount Requested"
                thousandSeparator={true}
              />
              <Spacer size={20} />
            </Col>
            <Col xs="12">
              <Field
                className="form-control"
                component={reduxFormInput}
                validate={[required, noSpaces]}
                name="address"
                type="text"
                placeholder="Deal address"
                label="*Deal Address"
                id="deal-address-field"
              />
              <Spacer size={20} />
            </Col>
            <Col xs="12" sm="6">
              <Field
                className="form-control"
                component={reduxFormInput}
                validate={[required, noSpaces]}
                name="city"
                type="text"
                placeholder="Enter city"
                label="*Deal City"
              />
              <Spacer size={20} />
            </Col>
            <Col xs="12" sm="6">
              <Field
                className="form-control"
                component={reduxFormSelect}
                validate={[required]}
                name="state"
                type="select"
                options={states}
                placeholder="Pick state"
                label="*Deal State"
              />
              <Spacer size={20} />
            </Col>
            <Col xs="12" sm="6">
              <Field
                className="form-control"
                component={reduxFormNumber}
                validate={[required]}
                name="zipCode"
                placeholder="Enter zipcode"
                label="*Deal Zipcode"
                thousandSeparator={false}
              />
              <Spacer size={20} />
            </Col>
            <Col xs="12">
              <Field
                className="form-control"
                component={reduxFormTextarea}
                validate={[noSpaces]}
                name="additionalInfo"
                rows="10"
                type="text"
                placeholder="Tell us a bit about your loan needs (i.e. when do you need to close, ...)"
                label="Additional Information"
              />
            </Col>
          </Row>
          <Spacer size={50} />
          <Row>
            <Col className="text-right" xs="12">
              <Button type="submit" variant="primary">
                Reserve Appointment
              </Button>
            </Col>
          </Row>
        </Form>
      </fieldset>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    action: state.action
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: 'DealScheduler',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true
  })(Step1)
);

