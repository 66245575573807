import { dispatch } from "config/store";
import { actionTypes } from "./actionTypes";

export const createCompany = (data) => {
  const action = {
    type: actionTypes.CREATE_COMPANY,
    config: {
      header: { "Content-Type": "application/x-www-form-urlencoded" },
      method: "post",
      url: `/company/create`,
      data
    }
  };
  
  dispatch(action);
};

export const updateCompany = (companyId, data) => {
  const action = {
    type: actionTypes.UPDATE_COMPANY,
    config: {
      header: { "Content-Type": "application/x-www-form-urlencoded" },
      method: "put",
      url: `/company/update/${companyId}`,
      data
    }
  };
  
  dispatch(action);
};

export const getMyCompany = () => {
  const action = {
    type: actionTypes.GET_MY_COMPANY,
    config: {
        method: "get",
        url: `/company`
    }
  };

  dispatch(action);
};

export const getCompanyById = (companyId) => {
  const action = {
    type: actionTypes.GET_COMPANY_BY_ID,
    config: {
        method: "get",
        url: `/company/${companyId}`
    }
  };

  dispatch(action);
};

export const getCompanyMembers = (companyId) => {
  const action = {
    type: actionTypes.GET_COMPANY_MEMBERS,
    config: {
        method: "get",
        url: `/company/${companyId}/members`
    }
  };

  dispatch(action);
};

export const addToCompany = (companyId, data) => {
  const action = {
    type: actionTypes.ADD_TO_COMPANY,
    config: {
        method: "put",
        url: `/company/members/update/${companyId}`,
        data
    }
  };

  dispatch(action);
};

export const inviteToCompany = (companyId, data) => {
  const action = {
    type: actionTypes.INVITE_TO_COMPANY,
    config: {
        method: "post",
        url: `/company/${companyId}/members/invite`,
        data
    }
  };

  dispatch(action);
};

export const createSponsor = (companyId, data) => {
  const action = {
    type: actionTypes.CREATE_SPONSOR,
    config: {
        method: "put",
        url: `/company/${companyId}/sponsors/create`,
        data
    }
  };

  dispatch(action);
};

export const removeSponsor = (companyId, data) => {
  const action = {
    type: actionTypes.REMOVE_SPONSOR,
    config: {
        method: "put",
        url: `/company/${companyId}/sponsors/remove/${data}`
    }
  };

  dispatch(action);
};

export const updateSponsor = (companyId, data, sponserId) => {
  const action = {
    type: actionTypes.UPDATE_SPONSOR,
    config: {
        method: "put",
        url: `/company/${companyId}/sponsors/update/${sponserId}`,
        data
    }
  };

  dispatch(action);
};
export const createManager = (companyId, data) => {
  const action = {
    type: actionTypes.CREATE_MANAGER,
    config: {
        method: "put",
        url: `/company/${companyId}/managers/create`,
        data
    }
  };

  dispatch(action);
};

export const updateManager = (companyId, data, managerId) => {
  const action = {
    type: actionTypes.UPDATE_MANAGER,
    config: {
        method: "put",
        url: `/company/${companyId}/managers/update/${managerId}`,
        data
    }
  };

  dispatch(action);
};

export const removeManager = (companyId, data) => {
  const action = {
    type: actionTypes.REMOVE_MANAGER,
    config: {
        method: "put",
        url: `/company/${companyId}/managers/remove/${data}`
    }
  };

  dispatch(action);
};
