import React from "react";
import { Space } from "antd";
import NumberFormat from "react-number-format";
import moment from "moment";
import Spacer from "components/layout/Spacer";

const ActiveLoanRequest = ({ property, deal }) => {
  const {
    purpose,
    loanType,
    preferredClosingDate,
    maximumLoanAmountRequested,
  } = property || deal;

  return (
    <div>
      <h4>Active</h4>
      <Spacer size={5} />
      <div className="border-bottom" />
      <div className="py-2 d-flex justify-content-between">
        <Space align="start">
          <div className="font-14 color-medium-gray">
            <p>Loan Request:</p>
            <p>Loan Type:</p>
            <p>Loan Purpose:</p>
            <p>Target Closing Date:</p>
          </div>
        </Space>
        <Space align="start">
          <div className="font-14 text-right">
            <p>
              {maximumLoanAmountRequested ? (
                <NumberFormat
                  value={maximumLoanAmountRequested}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix="$"
                />
              ) : (
                "-"
              )}
            </p>
            <p>{loanType || "-"}</p>
            <p>{purpose || "-"}</p>
            <p>
              {preferredClosingDate
                ? moment.utc(preferredClosingDate).format("MMM D, YYYY")
                : "-"}
            </p>
          </div>
        </Space>
      </div>
    </div>
  );
};

export default ActiveLoanRequest;
