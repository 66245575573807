import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Row, Col, Collapse, Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { getNotifications, signOut } from "redux/actions";
import { getKeyFromLocal, storeJsonInLocal } from "utils/lsUtils";
import Spacer from "components/layout/Spacer";
import ProfilePic from "components/ui/ProfilePic";
import TSLogo from "components/ui/TSLogo";
import Search from "components/search/Search";
import constants from "config/constants";
import LoginAs from "./LoginAs";
import Socket from "utils/Socket";
import NotificationsContainer from "containers/notifications/NotificationsContainer";

const Header = (props) => {
  const { whoami, myCompany, currentRoute } = props;
  const { searchParam } = currentRoute;
  const [open, setOpen] = useState(false);
  const [deal, setDeal] = useState(props.deal);
  const [stateSearchParam, setStateSearchParam] = useState(props.searchParam);
  const subscriptionRef = useRef(null);

  useEffect(() => {
    setDeal(getKeyFromLocal(constants.TERMST_LOCAL_STORAGE.CURRENT_DEAL));
  }, [props.deal]);

  useEffect(() => {
    setStateSearchParam(searchParam);
  }, [searchParam]);

  useEffect(() => {
    fetchNotifications(1, false);
  }, []);

  const fetchNotifications = (page, read) => {
    getNotifications(page, read);
  };
  useEffect(() => {
    if (whoami?._id) {
      subscriptionRef.current = Socket.subscribe(
        `/user/${whoami._id}/notifications`,
        () => fetchNotifications(1, false)
      );
    }
    return () => {
      Socket.unsubscribe(subscriptionRef.current);
    };
  }, [whoami?._id]);
  return (
    // removing className app-header (added it to fix header)
    <header>
      <Row className="application-header">
        <Col xs="12">
          <Spacer size={20} />
        </Col>
        <Col xs="10" md="auto">
          <Row>
            <Col className="header-mobile logo pr-0">
              <TSLogo blue />
            </Col>
            <Col>
              <h3 className="bold">{currentRoute.title}</h3>
              <h4 className="header-mobile bold">{deal.propertyName}</h4>
            </Col>
          </Row>
        </Col>
        <Col xs="2" md>
          <Row className="align-items-center justify-content-end pr-3">
            <LoginAs />
            <Search isMobile={false} searchParam={stateSearchParam} />
            <NotificationsContainer fetchNotifications={fetchNotifications} />
            {whoami && (
              <ProfilePic
                profilePic={whoami.profileImage ? whoami.profileImage : null}
                whoami={whoami}
                myCompany={myCompany}
              />
            )}
            <FontAwesomeIcon
              icon={faBars}
              onClick={() => setOpen(!open)}
              onKeyPress={(e) => (e.key === "Enter" ? this.toggleMenu() : null)}
              tabIndex="0"
            />
          </Row>
        </Col>
        <Col className="header-mobile" xs="12">
          <Nav className="mobile-menu">
            <Link to="/dashboard" className="nav-link">
              Dashboard
            </Link>
            {Object.keys(deal).length > 0 && (
              <>
                <Link className="nav-link" to={"/deal/view/" + deal._id}>
                  Deal
                </Link>
                <Link className="nav-link" to={"/deal/inbox/" + deal._id}>
                  Inbox
                </Link>
                <Link
                  className="nav-link"
                  to={
                    whoami && whoami.role === "Borrower"
                      ? `/deal/${deal._id}/quotes`
                      : `/deal/${deal._id}/quote-summary`
                  }
                >
                  Quotes
                </Link>
                {whoami && whoami.role !== "Lender" && (
                  <Link
                    className="nav-link"
                    to={"/deal/" + deal._id + "/stats"}
                  >
                    My Lenders
                  </Link>
                )}

                <Link
                  className="nav-link"
                  to="#"
                  onClick={() => {
                    let jsonObj = {};

                    jsonObj[constants.TERMST_LOCAL_STORAGE.DEAL_ID] = false;
                    jsonObj[
                      constants.TERMST_LOCAL_STORAGE.CURRENT_DEAL
                    ] = false;
                    jsonObj[
                      constants.TERMST_LOCAL_STORAGE.DEAL_TEAM_ID
                    ] = false;
                    storeJsonInLocal(jsonObj);

                    window.location = "/dashboard";
                  }}
                >
                  Clear
                </Link>
              </>
            )}
          </Nav>
        </Col>
        <Col className="header-mobile shadow-bottom" xs="12">
          <Collapse in={open}>
            <Nav className="flex-column hamburger-menu">
              <div className="nav-link">
                <Search isMobile={true} searchParam={stateSearchParam} />
              </div>
              <div> notifications</div>
              <LoginAs isMobile />
              {myCompany && !myCompany.message ? (
                <Link
                  className="nav-link"
                  to={"/company/profile/" + myCompany._id}
                  onClick={() => setOpen(!open)}
                >
                  {myCompany.companyName}
                </Link>
              ) : (
                <Link
                  className="nav-link"
                  to="/company/create"
                  onClick={() => setOpen(!open)}
                >
                  Create Company
                </Link>
              )}
              {whoami && whoami.role === "Admin" && (
                <>
                  <Link
                    className="nav-link"
                    to="/admin/lenders/platform"
                    onClick={() => setOpen(!open)}
                  >
                    Manage Lenders
                  </Link>
                  <Link
                    className="nav-link"
                    to="/admin/borrowers/platform"
                    onClick={() => setOpen(!open)}
                  >
                    Manage Borrowers
                  </Link>
                </>
              )}
              <Link
                className="nav-link border-top"
                to="/profile/view"
                onClick={() => setOpen(!open)}
              >
                View Profile
              </Link>
              <Link
                className="nav-link border-bottom"
                to="/profile/edit"
                onClick={() => setOpen(!open)}
              >
                Edit Profile
              </Link>
              {whoami && whoami.role === "Borrower" && (
                <Link
                  className="nav-link border-top border-bottom"
                  to="/deal"
                  onClick={() => setOpen(!open)}
                >
                  Find a Loan
                </Link>
              )}
              <Link className="nav-link" to="#" onClick={() => signOut()}>
                Logout
              </Link>
            </Nav>
          </Collapse>
        </Col>
        <Col className="header-spacer" xs="12">
          <Spacer size={20} />
        </Col>
      </Row>
    </header>
  );
};

function mapStateToProps(state) {
  return {
    deal: state.deal.deal,
    whoami: state.auth.whoami,
    myCompany: state.company.myCompany,
    currentRoute: state.generic.currentRoute,
  };
}

export default connect(mapStateToProps)(Header);
