import React from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { InlineWidget } from "react-calendly";

class Step2 extends React.Component {
  render() {
    const { whoami, previousStepVars } = this.props;

    const disableChrome = document.querySelectorAll('input[autocomplete="off"');

    for (let el of disableChrome) {
      if (el !== null) {
        el.setAttribute("autocomplete", "new-password");
      }
    }

    return (
      <>
        {whoami &&
          whoami.firstName &&
          previousStepVars.state &&
          previousStepVars.state.value && (
            <InlineWidget
              prefill={{
                name: whoami.firstName + " " + whoami.lastName,
                firstName: whoami.firstName,
                lastName: whoami.lastName,
                email: whoami.email,
                customAnswers: {
                  a1:
                    "Address: " +
                    previousStepVars.address +
                    " City: " +
                    previousStepVars.city +
                    " State: " +
                    previousStepVars.state.value +
                    " Zip Code: " +
                    previousStepVars.zipCode +
                    " Loan Amount: " +
                    previousStepVars.loanAmount +
                    " Additional Info: " +
                    previousStepVars.additionalInfo,
                },
              }}
              url="https://calendly.com/matt-termstreet/deal-call"
            />
          )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    whoami: state.auth.whoami,
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "DealScheduler",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  })(Step2)
);
