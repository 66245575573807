import React, { Component } from "react";
import {
  clearSection,
  getCategories,
  getClauses,
  getSection,
  storeSections,
} from "redux/actions";
import { connect } from "react-redux";
import queryString from "query-string";
import { Container } from "react-bootstrap";
import SpecialtySectionCreateFromBlank from "./SpecialtySectionCreateFromBlank";

class SpecialtySectionCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.toggleSideBar(true);

    storeSections([]);

    getClauses({
      limit: 100,
      order: "ASC",
      orderColumn: "clauseName",
      parent: true,
    });
    getCategories({
      order: "ASC",
      orderColumn: "order",
      limit: 500,
    });

    const search = queryString.parse(this.props.location.search);
    if (search?.sectionId) {
      getSection(search?.sectionId);
    } else {
      clearSection();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.section !== this.props.section && this.props.section) {
      const list = this.props.section.clauses.map((clause) => ({
        ...clause,
        sectionName: clause.displayName,
        clause,
      }));
      storeSections(list);
    }
  }

  componentWillUnmount() {
    this.toggleSideBar(false);
  }

  toggleSideBar(show) {
    const resizerEl = document.querySelector(".SideBar .Resizer");
    const pane1El = document.querySelector(".SideBar .Pane1");
    const pane2El = document.querySelector(".SideBar .Pane2");
    const addOrRemove = show ? "add" : "remove";
    if (resizerEl) {
      resizerEl.classList[addOrRemove]("d-flex");
    }
    if (pane2El) {
      pane2El.classList[addOrRemove]("d-flex");
    }
    if (pane1El) {
      pane1El.style.height = show ? "220px" : "auto";
    }
  }

  render() {
    const { match } = this.props;

    return (
      <Container className="py-3" style={{ height: "calc(100vh - 80px)" }}>
        <SpecialtySectionCreateFromBlank match={match} />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  action: state.action,
  whoami: state.auth.whoami,
  section: state.section.section,
});

export default connect(mapStateToProps)(SpecialtySectionCreate);
