// Agent Routes
import Dashboard from "containers/auth/Dashboard";
import Profile from "containers/profile/view/Profile";
import AccountManagement from "containers/profile/update/AccountManagement";
import Company from "containers/company/view/Company";
import CompanyCreate from "containers/company/CompanyCreate";
import CompanyUpdate from "containers/company/update/CompanyUpdate";
import Team from "containers/team/view/Team";
import TeamUpdate from "containers/team/update/TeamUpdate";
import TeamCreate from "containers/team/TeamCreate";
import DealChoice from "containers/deals/DealChoice";
import DealScheduler from "containers/deals/schedule/DealScheduler";
import DealTeamNotifications from "containers/deals/update/DealTeamNotifications";
// Application Steps
import DealLoanApplication from "containers/deals/DealLoanApplication";
import PropertyDetails from "containers/deals/ApplicationSteps/PropertyDetails";
import PropertyDescription from "containers/deals/ApplicationSteps/PropertyDescription";
import LoanRequest from "containers/deals/ApplicationSteps/LoanRequest";
import CriticalExtras from "containers/deals/ApplicationSteps/CriticalExtras";
import Manager from "containers/deals/ApplicationSteps/Manager";
import Sponsor from "containers/deals/ApplicationSteps/Sponsor";
import SourcesAndUses from "containers/deals/ApplicationSteps/SourcesAndUses";
import FileExplorer from "containers/deals/FileManager/FileExplorer";
import DealMaps from "containers/deals/ApplicationSteps/DealMaps";
import FinancialUploads from "containers/deals/ApplicationSteps/financials/FinancialUploads";
import PhotoUploads from "containers/deals/ApplicationSteps/photos/PhotoUploads";
import DealQuotes from "containers/quotes/DealQuotes";
import TermSheet from "containers/termsheet/TermSheet";
import LenderStatistics from "containers/deals/LenderStatistics";
import AccountPreferences from "containers/profile/update/AccountPreferences";
import EmailPreference from "containers/profile/update/EmailPreference";
import QuoteSummary from "containers/termsheet/QuoteSummary";
import TermsheetPreview from "containers/termsheet/TermsheetPreview";
import CompareQuotes from "containers/termsheet/CompareQuotes";
import DealPdfContainer from "containers/deals/pdf/DealPdfContainer";
import InboxLayout from "containers/communicationsV2/InboxLayout";
import DealV2 from "containers/deals/view/DealV2";
import CommsPreferences from "containers/profile/update/CommsPreferences";
import PortifolioDealApplication from "containers/deals/PortifolioDealApplication";

const borrowerRoutes = [
  {
    path: "/dashboard",
    component: Dashboard,
    isExact: true,
    title: "Dashboard",
    searchParam: "deals",
  },
  {
    path: "/profile/view",
    component: Profile,
    isExact: true,
    title: "User Profile",
    searchParam: "none",
  },
  {
    path: "/profile/edit",
    component: AccountManagement,
    isExact: true,
    title: "Edit User Profile",
    searchParam: "none",
  },
  {
    path: "/profile/accountpreferences",
    component: AccountPreferences,
    isExact: true,
    title: "Account Management",
    searchParam: "none",
  },
  {
    path: "/profile/emailpreferences",
    component: EmailPreference,
    isExact: true,
    title: "Email Preferences",
    searchParam: "none",
  },
  {
    path: "/profile/commspreferences",
    component: CommsPreferences,
    isExact: true,
    title: "Comms Preferences",
    searchParam: "none",
  },
  {
    path: "/company/create",
    component: CompanyCreate,
    isExact: true,
    title: "Company Create",
    searchParam: "none",
  },
  {
    path: "/company/profile/:id",
    component: Company,
    isExact: true,
    title: "Company Profile",
    searchParam: "none",
  },
  {
    path: "/company/edit/:id",
    component: CompanyUpdate,
    isExact: true,
    title: "Company Update",
    searchParam: "none",
  },
  {
    path: "/team/view/:id",
    component: Team,
    isExact: true,
    title: "Team View",
    searchParam: "none",
  },
  {
    path: "/team/edit/:id",
    component: TeamUpdate,
    isExact: true,
    title: "Team Update",
    searchParam: "none",
  },
  {
    path: "/team/create",
    component: TeamCreate,
    isExact: true,
    title: "Team Create",
    searchParam: "none",
  },
  {
    path: "/deal/scheduler",
    component: DealScheduler,
    isExact: true,
    title: "Schedule a meeting",
    searchParam: "none",
  },
  {
    path: "/deal",
    component: DealChoice,
    isExact: true,
    title: "Deal",
    searchParam: "none",
  },
  {
    path: "/deal/view/:id",
    component: DealV2,
    isExact: true,
    title: "Deal",
    searchParam: "none",
  },
  {
    path: "/deal/pdf/:id",
    component: DealPdfContainer,
    isExact: true,
    title: "Deal PDF",
    searchParam: "none",
  },
  {
    path: "/deal/inbox/:id",
    component: InboxLayout,
    isExact: true,
    title: "Inbox",
    searchParam: "inbox",
  },
  {
    path: "/deal/application",
    component: DealLoanApplication,
    isExact: true,
    title: "Create a Deal",
    searchParam: "none",
  },
  {
    path: "/deal/portifolio-application/:dealId",
    component: PortifolioDealApplication,
    isExact: true,
    title: "Create a Deal",
    searchParam: "none",
  },
  {
    path: "/deal/application/property-details",
    component: PropertyDetails,
    isExact: true,
    title: "Property Details",
    searchParam: "none",
  },
  {
    path: "/deal/application/property-description",
    component: PropertyDescription,
    isExact: true,
    title: "Property Description",
    searchParam: "none",
  },
  {
    path: "/deal/application/portifolio-summary",
    component: PropertyDescription,
    isExact: true,
    title: "Portfolio Summary",
    searchParam: "none",
  },
  {
    path: "/deal/application/loan-request",
    component: LoanRequest,
    isExact: true,
    title: "Loan Request",
    searchParam: "none",
  },
  {
    path: "/deal/application/sources-uses",
    component: SourcesAndUses,
    isExact: true,
    title: "Sources and Uses",
    searchParam: "none",
  },

  {
    path: "/deal/application/critical-extras",
    component: CriticalExtras,
    isExact: true,
    title: "Disclosures",
    searchParam: "none",
  },
  {
    path: "/deal/application/sponsor-guarantor",
    component: Sponsor,
    isExact: true,
    title: "Key Principals",
    searchParam: "none",
  },
  {
    path: "/deal/application/manager",
    component: Manager,
    isExact: true,
    title: "Manager",
    searchParam: "none",
  },
  {
    path: "/deal/:id/team",
    component: DealTeamNotifications,
    isExact: true,
    title: "Team Notifications",
    searchParam: "none",
  },
  {
    path: "/deal/files/:id",
    component: FileExplorer,
    isExact: true,
    title: "File Manager",
    searchParam: "files",
  },
  {
    path: "/deal/application/maps",
    component: DealMaps,
    isExact: true,
    title: "Maps",
    searchParam: "none",
  },
  {
    path: "/deal/application/financials",
    component: FinancialUploads,
    isExact: true,
    title: "New Deal Creation",
    searchParam: "files",
  },
  {
    path: "/deal/application/photos",
    component: PhotoUploads,
    isExact: true,
    title: "New Deal Creation",
    searchParam: "files",
  },
  {
    path: "/deal/:dealId/termsheet/:termsheetId/",
    component: TermSheet,
    isExact: true,
    title: "View Term Sheet",
    searchParam: "quotes",
  },
  {
    path: "/deal/:dealId/quotes",
    component: DealQuotes,
    isExact: true,
    title: "Deal Quotes",
    searchParam: "quotes",
  },
  {
    path: "/deal/:dealId/quote-summary",
    component: QuoteSummary,
    isExact: true,
    title: "Quote Summary",
  },
  {
    path: "/deal/:dealId/compare-quotes",
    component: CompareQuotes,
    isExact: true,
    title: "Compare Quotes",
  },
  {
    path: "/deal/:dealId/stats",
    component: LenderStatistics,
    isExact: true,
    title: "Deal Lenders",
  },
  {
    path: "/termsheet/:termsheetId",
    component: TermsheetPreview,
    isExact: true,
    title: "Termsheet Preview",
  },
];

export { borrowerRoutes };
