import React from "react";
import { connect } from "react-redux";
import { Button, Form, Row, Col } from "react-bootstrap";
import { reduxFormSelect } from "components/form/ReduxForm";
import { Field, formValueSelector, reduxForm } from "redux-form";
import {
  assignBorrowerTeamToDeal,
  getTeamsByCompanyId,
  searchCompanies,
} from "redux/actions";
import Spacer from "components/layout/Spacer";

class AssignBorrowerTeam extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      borrowerSelectOptions: [],
      teamSelectOptions: [],
    };

    this.submitFn = this.submitFn.bind(this);
  }

  componentDidMount() {
    searchCompanies({
      companyType: "Borrower",
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.companies !== this.props.companies) {
      const borrowerSelectOptions = this.props.companies.map((company) => ({
        label: company.companyName,
        value: company._id,
      }));

      this.setState({
        borrowerSelectOptions,
      });
    }

    if (prevProps.teams !== this.props.teams) {
      const teamSelectOptions = this.props.teams.map((team) => ({
        label: team.teamName,
        value: team._id,
      }));

      this.setState({
        teamSelectOptions,
      });
    }

    if (prevProps.selectedBorrower !== this.props.selectedBorrower) {
      this.getTeams();
      this.props.change("team", null);
      this.setState({
        teamSelectOptions: [],
      });
    }
  }

  getTeams = () => {
    const borrowerId = this.props.selectedBorrower?.value;
    getTeamsByCompanyId(borrowerId, { limit: 100, page: 1 });
  };

  submitFn(data) {
    const { dealId, closePrompt } = this.props;

    assignBorrowerTeamToDeal(dealId, {
      company: data.borrower.value,
      teamId: data.team.value,
    });

    closePrompt();
  }

  render() {
    const { handleSubmit, action, selectedBorrower, selectedTeam } = this.props;

    return (
      <Form
        className="no-padding no-border"
        onSubmit={handleSubmit(this.submitFn)}
      >
        <Row>
          <Col xs="12">
            <h4 className="bold">Borrower Teams</h4>
            <Spacer size={20} />
          </Col>
          <Col xs="12">
            <Field
              className="form-control"
              component={reduxFormSelect}
              name="borrower"
              type="select"
              options={
                this.state.borrowerSelectOptions
                  ? this.state.borrowerSelectOptions
                  : {}
              }
              isLoading={action.SEARCH_COMPANIES.isFetching}
              placeholder="Select Borrower"
              label="*Select Borrower"
            />
            <Spacer size={20} />
          </Col>
          <Col xs="12">
            <Field
              className="form-control"
              component={reduxFormSelect}
              name="team"
              type="select"
              options={
                this.state.teamSelectOptions ? this.state.teamSelectOptions : {}
              }
              isLoading={action.GET_TEAMS_BY_COMPANY_ID.isFetching}
              placeholder="Select team"
              label="*Select team"
            />
            <Spacer size={20} />
          </Col>
        </Row>
        <Row>
          <Col className="text-right" xs="12">
            <Spacer size={50} />
            <Button
              disabled={!selectedBorrower || !selectedTeam}
              type="submit"
              variant="primary"
            >
              Assign
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

const selector = formValueSelector("AssignBorrowerTeam");

function mapStateToProps(state) {
  return {
    action: state.action,
    myCompany: state.company.myCompany,
    companies: state.admin.companies,
    teams: state.team.teams,
    selectedBorrower: selector(state, "borrower"),
    selectedTeam: selector(state, "team"),
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "AssignBorrowerTeam",
    enableReinitialize: true,
  })(AssignBorrowerTeam)
);
