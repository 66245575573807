import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Row, Col, Button, Form } from "react-bootstrap";
import { Field, reduxForm, isPristine } from "redux-form";
import { faHashtag } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  reduxFormTextarea,
  reduxFormSelect,
  reduxFormNumber,
} from "components/form/ReduxForm";
import { getDropdown, updateDeal, getDealById } from "redux/actions";
import { noSpaces } from "utils/validation";
import { getKeyFromLocal } from "utils/lsUtils";
import constants from "config/constants";
import Spacer from "components/layout/Spacer";
import Loader from "components/ui/Loader";
import { getDealCreationPath } from "utils/commonUtils";

class CriticalExtras extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bankruptcy: "",
      foreclosure: "",
      TICOrDSTStructure: "",
      materialLitigation: "",
      condominium: "",
      environmentalConditions: "",
      phaseIIEnvironmentalInvestigation: "",
      materialZoningIssues: "",
      groundLease: "",
      numberOfInvestorsInStructure: 0,
      groundLeaseTerm: 0,
      loadedDropdowns: [],
    };

    this.submitFn = this.submitFn.bind(this);
    this.getDropdownFromAPI = this.getDropdownFromAPI.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.deal !== this.props.deal) {
      const { deal } = this.props;

      this.setState({
        bankruptcy:
          deal.bankruptcy && deal.bankruptcy !== null
            ? deal.bankruptcy
            : "None",
        foreclosure:
          deal.foreclosure && deal.foreclosure !== null
            ? deal.foreclosure
            : "None",
        TICOrDSTStructure:
          deal.TICOrDSTStructure && deal.TICOrDSTStructure !== null
            ? deal.TICOrDSTStructure
            : "None",
        materialLitigation:
          deal.materialLitigation && deal.materialLitigation !== null
            ? deal.materialLitigation
            : "None",
        condominium:
          deal.condominium && deal.condominium !== null
            ? deal.condominium
            : "None",
        environmentalConditions:
          deal.environmentalConditions && deal.environmentalConditions !== null
            ? deal.environmentalConditions
            : "None",
        phaseIIEnvironmentalInvestigation:
          deal.phaseIIEnvironmentalInvestigation &&
          deal.phaseIIEnvironmentalInvestigation !== null
            ? deal.phaseIIEnvironmentalInvestigation
            : "None",
        materialZoningIssues:
          deal.materialZoningIssues && deal.materialZoningIssues !== null
            ? deal.materialZoningIssues
            : "None",
        groundLease:
          deal.groundLease && deal.groundLease !== null
            ? deal.groundLease
            : "None",
        numberOfInvestorsInStructure: deal.numberOfInvestorsInStructure,
        groundLeaseTerm: deal.groundLeaseTerm,
      });
    }
  }

  componentDidMount() {
    if (getKeyFromLocal(constants.TERMST_LOCAL_STORAGE.DEAL_ID) !== false) {
      const dealId = getKeyFromLocal(constants.TERMST_LOCAL_STORAGE.DEAL_ID);

      getDealById(dealId);
    }
  }

  getDropdownFromAPI(value) {
    let canGet = false;

    if (this.state.loadedDropdowns.length > 0) {
      this.state.loadedDropdowns.some((dropdown) => {
        if (dropdown === value) {
          canGet = false;

          return true;
        } else {
          canGet = true;

          return false;
        }
      });
    } else {
      getDropdown(value);

      this.setState((prevState) => ({
        loadedDropdowns: [...prevState.loadedDropdowns, value],
      }));
    }

    if (canGet) {
      getDropdown(value);

      this.setState((prevState) => ({
        loadedDropdowns: [...prevState.loadedDropdowns, value],
      }));
    }
  }

  submitFn(data) {
    const { isPristine } = this.props;
    const dealId = getKeyFromLocal(constants.TERMST_LOCAL_STORAGE.DEAL_ID);

    for (let property in data) {
      if (typeof data[property] === "object" && data[property] !== null) {
        data[property] = data[property].value;
      } else if (data[property] === null) {
        data[property] = "";
      }
    }

    if (isPristine) {
      const path = getDealCreationPath();
      window.location.pathname = path;
    } else {
      data.criticalExtras = true;

      updateDeal(dealId, data);
    }
  }

  render() {
    const { action, dropdowns, handleSubmit } = this.props;
    const { isFetching } = action.GET_DEAL_BY_ID;
    const path = getDealCreationPath();

    return (
      <>
        {isFetching && <Loader />}
        <Form
          className="full-width no-border mobile-no-padding"
          onSubmit={handleSubmit(this.submitFn)}
        >
          <Row>
            {(this.props.deal.isPortifolio || !this.props.deal.portifolio) && (
              <>
                <Col xs="12">
                  <Field
                    className="form-control"
                    component={reduxFormSelect}
                    name="bankruptcy"
                    type="select"
                    options={
                      dropdowns["yesNo"] !== {} ? dropdowns["yesNo"] : {}
                    }
                    isLoading={action.GET_DROPDOWNS.isFetching}
                    onChange={(e) => this.setState({ bankruptcy: e.value })}
                    onFocus={() => this.getDropdownFromAPI("yesNo")}
                    label={
                      "Has Anyone in the Ownership Structure Ever Filed for Bankruptcy either personally or in a business entity?"
                    }
                  />
                  <Spacer size={20} />
                </Col>
                {this.state.bankruptcy === "Yes" ? (
                  <Col xs="12">
                    <Field
                      className="form-control"
                      component={reduxFormTextarea}
                      validate={[noSpaces]}
                      name="bankruptcyInfo"
                      rows="10"
                      type="text"
                      placeholder="Please describe..."
                      label="Please Describe"
                    />
                    <Spacer size={20} />
                  </Col>
                ) : null}
                <Col xs="12">
                  <Field
                    className="form-control"
                    component={reduxFormSelect}
                    name="foreclosure"
                    type="select"
                    options={
                      dropdowns["yesNo"] !== {} ? dropdowns["yesNo"] : {}
                    }
                    isLoading={action.GET_DROPDOWNS.isFetching}
                    onChange={(e) => this.setState({ foreclosure: e.value })}
                    onFocus={() => this.getDropdownFromAPI("yesNo")}
                    label={
                      "Has anyone in the ownership structure ever lost a property to foreclosure, deed in lieu of foreclosure, or any other property taking action?"
                    }
                  />
                  <Spacer size={20} />
                </Col>

                {this.state.foreclosure === "Yes" ? (
                  <Col xs="12">
                    <Field
                      className="form-control"
                      component={reduxFormTextarea}
                      validate={[noSpaces]}
                      name="foreclosureInfo"
                      rows="10"
                      type="text"
                      placeholder="Please describe..."
                      label="Please Describe"
                    />
                    <Spacer size={20} />
                  </Col>
                ) : null}
                <Col xs="12">
                  <Field
                    className="form-control"
                    component={reduxFormSelect}
                    name="TICOrDSTStructure"
                    type="select"
                    options={
                      dropdowns["yesNo"] !== {} ? dropdowns["yesNo"] : {}
                    }
                    isLoading={action.GET_DROPDOWNS.isFetching}
                    onChange={(e) =>
                      this.setState({ TICOrDSTStructure: e.value })
                    }
                    onFocus={() => this.getDropdownFromAPI("yesNo")}
                    label={
                      "Is the property owned through a Tenant In Common (TIC) or Delaware Statutory Trust (DST) Structure?"
                    }
                  />
                  <Spacer size={20} />
                </Col>
                {this.state.TICOrDSTStructure === "Yes" ? (
                  <>
                    <Col xs="12" sm="6">
                      <Field
                        className="form-control"
                        component={reduxFormTextarea}
                        validate={[noSpaces]}
                        name="additionalOwnershipStructureInfo"
                        rows="10"
                        type="text"
                        placeholder="Would you like to add any additional color about the ownership structure?"
                        label="Additional information on ownership structure"
                      />
                      <Spacer size={20} />
                    </Col>
                    <Col xs="12" sm="6">
                      <Field
                        icon={<FontAwesomeIcon icon={faHashtag} />}
                        className="form-control"
                        component={reduxFormNumber}
                        name="numberOfInvestorsInStructure"
                        placeholder="Enter amount..."
                        label="Number of Investors in Structure"
                        thousandSeparator={true}
                        value={this.state.numberOfInvestorsInStructure}
                      />
                      <Spacer size={20} />
                    </Col>
                  </>
                ) : null}
                <Col xs="12">
                  <Field
                    className="form-control"
                    component={reduxFormSelect}
                    name="materialLitigation"
                    type="select"
                    options={
                      dropdowns["yesNo"] !== {} ? dropdowns["yesNo"] : {}
                    }
                    isLoading={action.GET_DROPDOWNS.isFetching}
                    onChange={(e) =>
                      this.setState({ materialLitigation: e.value })
                    }
                    onFocus={() => this.getDropdownFromAPI("yesNo")}
                    label={
                      "Will a search of any of the ownership entities or Individuals turn up an material litigation?"
                    }
                  />
                  <Spacer size={20} />
                </Col>
                {this.state.materialLitigation === "Yes" ? (
                  <Col xs="12">
                    <Field
                      className="form-control"
                      component={reduxFormTextarea}
                      name="materialLitigationInfo"
                      rows="10"
                      type="text"
                      placeholder="Please describe..."
                      label="Please Describe"
                    />
                    <Spacer size={20} />
                  </Col>
                ) : null}
              </>
            )}

            {!this.props.deal.isPortifolio && (
              <>
                <Col xs="12">
                  <Field
                    className="form-control"
                    component={reduxFormSelect}
                    onChange={(e) => this.setState({ condominium: e.value })}
                    name="condominium"
                    type="select"
                    options={
                      dropdowns["yesNo"] !== {} ? dropdowns["yesNo"] : {}
                    }
                    isLoading={action.GET_DROPDOWNS.isFetching}
                    onFocus={() => this.getDropdownFromAPI("yesNo")}
                    label={"Is any part of the property a condominium?"}
                  />
                  <Spacer size={20} />
                </Col>
                {this.state.condominium === "Yes" ? (
                  <Col xs="12">
                    <Field
                      className="form-control"
                      component={reduxFormTextarea}
                      validate={[noSpaces]}
                      name="condominiumInfo"
                      rows="10"
                      type="text"
                      placeholder="Please describe..."
                      label="Please Describe"
                    />
                    <Spacer size={20} />
                  </Col>
                ) : null}
                <Col xs="12">
                  <Field
                    className="form-control"
                    component={reduxFormSelect}
                    name="environmentalConditions"
                    type="select"
                    options={
                      dropdowns["yesNo"] !== {} ? dropdowns["yesNo"] : {}
                    }
                    isLoading={action.GET_DROPDOWNS.isFetching}
                    onChange={(e) =>
                      this.setState({ environmentalConditions: e.value })
                    }
                    onFocus={() => this.getDropdownFromAPI("yesNo")}
                    label={
                      "Are there any known environmental conditions at the property?"
                    }
                  />
                  <Spacer size={20} />
                </Col>
                {this.state.environmentalConditions === "Yes" ? (
                  <Col xs="12">
                    <Field
                      className="form-control"
                      component={reduxFormTextarea}
                      validate={[noSpaces]}
                      name="environmentalConditionsInfo"
                      rows="10"
                      type="text"
                      placeholder="Please describe..."
                      label="Please Describe"
                    />
                    <Spacer size={20} />
                  </Col>
                ) : null}
                <Col xs="12">
                  <Field
                    className="form-control"
                    component={reduxFormSelect}
                    name="phaseIIEnvironmentalInvestigation"
                    type="select"
                    options={
                      dropdowns["yesNo"] !== {} ? dropdowns["yesNo"] : {}
                    }
                    isLoading={action.GET_DROPDOWNS.isFetching}
                    onChange={(e) =>
                      this.setState({
                        phaseIIEnvironmentalInvestigation: e.value,
                      })
                    }
                    onFocus={() => this.getDropdownFromAPI("yesNo")}
                    label={
                      "Has a phase II environmental investigation ever been recommended or completed?"
                    }
                  />
                  <Spacer size={20} />
                </Col>
                {this.state.phaseIIEnvironmentalInvestigation === "Yes" ? (
                  <Col xs="12">
                    <Field
                      className="form-control"
                      component={reduxFormTextarea}
                      validate={[noSpaces]}
                      name="phaseIIEnvironmentalInvestigationInfo"
                      rows="10"
                      type="text"
                      placeholder="Please describe..."
                      label="Please Describe"
                    />
                    <Spacer size={20} />
                  </Col>
                ) : null}
                <Col xs="12">
                  <Field
                    className="form-control"
                    component={reduxFormSelect}
                    name="materialZoningIssues"
                    type="select"
                    options={
                      dropdowns["yesNo"] !== {} ? dropdowns["yesNo"] : {}
                    }
                    isLoading={action.GET_DROPDOWNS.isFetching}
                    onChange={(e) =>
                      this.setState({ materialZoningIssues: e.value })
                    }
                    onFocus={() => this.getDropdownFromAPI("yesNo")}
                    label={
                      "Are there any material zoning issues with the property?"
                    }
                  />
                  <Spacer size={20} />
                </Col>
                {this.state.materialZoningIssues === "Yes" ? (
                  <Col xs="12">
                    <Field
                      className="form-control"
                      component={reduxFormTextarea}
                      validate={[noSpaces]}
                      name="materialZoningIssuesInfo"
                      rows="10"
                      type="text"
                      placeholder="Please describe..."
                      label="Please Describe"
                    />
                    <Spacer size={20} />
                  </Col>
                ) : null}
                <Col xs="12">
                  <Field
                    className="form-control"
                    component={reduxFormSelect}
                    name="groundLease"
                    type="select"
                    options={
                      dropdowns["yesNo"] !== {} ? dropdowns["yesNo"] : {}
                    }
                    isLoading={action.GET_DROPDOWNS.isFetching}
                    onChange={(e) => this.setState({ groundLease: e.value })}
                    onFocus={() => this.getDropdownFromAPI("yesNo")}
                    label={"Is the property on a ground lease?"}
                  />
                  <Spacer size={20} />
                </Col>
                {this.state.groundLease === "Yes" ? (
                  <>
                    <Col xs="12" sm="6">
                      <Field
                        className="form-control"
                        component={reduxFormSelect}
                        name="subordinateToNewMortgate"
                        type="select"
                        options={
                          dropdowns["yesNoIDK"] !== {}
                            ? dropdowns["yesNoIDK"]
                            : {}
                        }
                        isLoading={action.GET_DROPDOWNS.isFetching}
                        onFocus={() => this.getDropdownFromAPI("yesNoIDK")}
                        placeholder="None"
                        label={
                          "Is the ground lease subordinate to a new mortgage?"
                        }
                      />
                      <Spacer size={20} />
                    </Col>
                    <Col xs="12" sm="6">
                      <Field
                        icon={<FontAwesomeIcon icon={faHashtag} />}
                        className="form-control"
                        component={reduxFormNumber}
                        name="groundLeaseTerm"
                        placeholder="Enter amount..."
                        label="How many years are left on the ground lease?"
                        thousandSeparator={true}
                        value={this.state.groundLeaseTerm}
                      />
                      <Spacer size={20} />
                    </Col>
                    <Col xs="12">
                      <Field
                        className="form-control"
                        component={reduxFormTextarea}
                        name="groundLeaseInfo"
                        rows="10"
                        type="text"
                        placeholder="Would you like to provide any additional information related to the ground lease?"
                        label="Additional Information Related to Ground Lease"
                      />
                      <Spacer size={20} />
                    </Col>
                  </>
                ) : null}
              </>
            )}
          </Row>
          <Spacer size={50} />
          <Row className="application-controls">
            <Col className="text-left" xs="6">
              <Button as={Link} className="pl-3 pr-3" to={path} variant="dark">
                Back
              </Button>
            </Col>
            <Col className="text-right" xs="6">
              <Button className="pl-2 pr-2" type="submit" variant="primary">
                Continue
              </Button>
            </Col>
          </Row>
        </Form>
      </>
    );
  }
}

function mapStateToProps(state) {
  const {
    bankruptcy,
    bankruptcyInfo,
    foreclosure,
    foreclosureInfo,
    TICOrDSTStructure,
    additionalOwnershipStructureInfo,
    numberOfInvestorsInStructure,
    materialLitigation,
    materialLitigationInfo,
    condominium,
    condominiumInfo,
    environmentalConditions,
    environmentalConditionsInfo,
    phaseIIEnvironmentalInvestigation,
    phaseIIEnvironmentalInvestigationInfo,
    materialZoningIssues,
    materialZoningIssuesInfo,
    groundLease,
    subordinateToNewMortgate,
    groundLeaseTerm,
    groundLeaseInfo,
  } = state.deal.deal;

  return {
    isPristine: isPristine("CriticalExtras")(state),
    action: state.action,
    dropdowns: state.program.dropdowns,
    deal: state.deal.deal,
    initialValues: {
      bankruptcyInfo,
      foreclosureInfo,
      additionalOwnershipStructureInfo,
      numberOfInvestorsInStructure,
      materialLitigationInfo,
      condominiumInfo,
      environmentalConditionsInfo,
      phaseIIEnvironmentalInvestigationInfo,
      materialZoningIssuesInfo,
      groundLeaseTerm,
      groundLeaseInfo,
      bankruptcy:
        bankruptcy && bankruptcy !== null
          ? { label: bankruptcy, value: bankruptcy }
          : "None",
      foreclosure:
        foreclosure && foreclosure !== null
          ? { label: foreclosure, value: foreclosure }
          : "None",
      TICOrDSTStructure:
        TICOrDSTStructure && TICOrDSTStructure !== null
          ? { label: TICOrDSTStructure, value: TICOrDSTStructure }
          : "None",
      materialLitigation:
        materialLitigation && materialLitigation !== null
          ? { label: materialLitigation, value: materialLitigation }
          : "None",
      condominium:
        condominium && condominium !== null
          ? { label: condominium, value: condominium }
          : "None",
      environmentalConditions:
        environmentalConditions && environmentalConditions !== null
          ? { label: environmentalConditions, value: environmentalConditions }
          : "None",
      phaseIIEnvironmentalInvestigation:
        phaseIIEnvironmentalInvestigation &&
        phaseIIEnvironmentalInvestigation !== null
          ? {
              label: phaseIIEnvironmentalInvestigation,
              value: phaseIIEnvironmentalInvestigation,
            }
          : "None",
      materialZoningIssues:
        materialZoningIssues && materialZoningIssues !== null
          ? { label: materialZoningIssues, value: materialZoningIssues }
          : "None",
      groundLease:
        groundLease && groundLease !== null
          ? { label: groundLease, value: groundLease }
          : "None",
      subordinateToNewMortgate:
        subordinateToNewMortgate && subordinateToNewMortgate !== null
          ? { label: subordinateToNewMortgate, value: subordinateToNewMortgate }
          : "None",
    },
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "CriticalExtras",
    enableReinitialize: true,
  })(CriticalExtras)
);
