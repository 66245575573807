import React from "react";
import { connect } from "react-redux";
import { OverlayTrigger, Tooltip, Button, Row, Col } from "react-bootstrap";
import { removeSponsor } from "redux/actions";
import { reset } from "redux-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Spacer from "components/layout/Spacer";
import PopupPrompt from "components/ui/PopupPrompt";
import SponsorCreate from "containers/company/SponsorCreate";

class CompanySponsors extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPrompt: false,
    };

    this.handleShowPrompt = this.handleShowPrompt.bind(this);
  }

  handleShowPrompt(showPrompt) {
    this.setState({ showPrompt });
  }

  editSponsor = (sponsor) => {
    this.setState({
      isEdit: true,
      selectedSponsor: sponsor,
      showPrompt: true
    })
  }

  render() {
    const { company, isCompanyAdmin, dispatch } = this.props;

    const { sponsors } = company;
    const {isEdit, selectedSponsor} = this.state;

    return (
      <>
        <PopupPrompt
          promptTitle={"Add Key Principal"}
          promptBody={
            <SponsorCreate
              isCompanyAdmin={isCompanyAdmin}
              companyId={company._id}
              sponsors={sponsors}
              selectedSponsor={selectedSponsor}
              isEdit={isEdit}
              onSubmit={() => {
                this.handleShowPrompt(false);
                dispatch(reset("AddSponsor"));
              }}
            />
          }
          show={this.state.showPrompt}
          onCancel={() => {
            this.handleShowPrompt(false);
            dispatch(reset("AddSponsor"));
          }}
          onHide={() => {
            this.handleShowPrompt(false);
            dispatch(reset("AddSponsor"));
          }}
        />
        <Row>
          <Col xs="12">
            <Spacer size={30} />
          </Col>
          <Col className="align-self-end" xs="6">
            <h3 className="bold">Key Principals</h3>
          </Col>
          <Col className="text-right" xs="6">
            <OverlayTrigger
              placement="left"
              overlay={<Tooltip id={`tooltip-left`}>Add Key Principal</Tooltip>}
            >
              <Button
                className="pl-3 pt-2 pr-3 pb-2"
                onClick={() => {
                  this.handleShowPrompt(true);
                }}
              >
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            </OverlayTrigger>
          </Col>
          <Col xs="12">
            <div className="border-top"></div>
            <Spacer size={30} />
          </Col>
          {sponsors && sponsors.length === 0 ? (
            <>
              <Col xs="12">
                <p className="text-danger">
                  Your company doesn't have any sponsors yet!
                </p>
                <Spacer size={10} />
              </Col>
            </>
          ) : (
            sponsors.map((sponsor, idx) => {
              return (
                <Col xs="12" sm="4" key={idx}>
                  <div
                    className="panel"
                    onClick={(e) => {
                      if (e.target.parentNode.lastChild.hasAttribute("style")) {
                        e.target.parentNode.lastChild.removeAttribute("style");
                      } else {
                        e.target.parentNode.lastChild.style.display = "block";
                      }
                    }}
                  >
                    <span className="font-14">{sponsor.sponsorName}</span>
                    <div className="panel-controls">
                    <div className="d-flex">
                    <Button
                        className="font-12 fake-link mr-3"
                        variant="fake-link"
                        onClick={() => {
                          removeSponsor(company._id, sponsor._id);
                        }}
                      >
                        Delete
                      </Button>
                      <Button
                          className="font-12 fake-link"
                          variant="fake-link"
                          onClick={() => {
                            this.editSponsor(sponsor);
                          }}
                        >
                          Edit
                      </Button>
                      </div>
                      
                    </div>
                  </div>
                  <Spacer size={20} />
                </Col>
              );
            })
          )}
        </Row>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    company: state.company.company,
  };
}

export default connect(mapStateToProps)(CompanySponsors);
