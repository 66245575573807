import React, { Fragment, useState } from "react";
import { Button, Col, Row, Tooltip } from "antd";
import Spacer from "components/layout/Spacer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { dealViewIds } from "../DealViewContext";
import useCardHidden from "../hooks/useCardHidden";
import { useSelector } from "react-redux";
import NumberFormat from "react-number-format";

const Disclosures = ({ deal, portfolioDeal }) => {
  const isProperty = deal._id !== portfolioDeal._id;
  const {
    bankruptcy,
    bankruptcyInfo,
    foreclosure,
    foreclosureInfo,
    TICOrDSTStructure,
    additionalOwnershipStructureInfo,
    numberOfInvestorsInStructure,
    materialLitigation,
    materialLitigationInfo,
    condominium,
    condominiumInfo,
    environmentalConditions,
    environmentalConditionsInfo,
    phaseIIEnvironmentalInvestigation,
    phaseIIEnvironmentalInvestigationInfo,
    materialZoningIssues,
    materialZoningIssuesInfo,
    groundLease,
    subordinateToNewMortgate,
    groundLeaseTerm,
    groundLeaseInfo,
    cardHidden = [],
  } = deal;

  const disclosures = {
    bankruptcy,
    foreclosure,
    TICOrDSTStructure,
    materialLitigation,
    condominium,
    environmentalConditions,
    phaseIIEnvironmentalInvestigation,
    materialZoningIssues,
    groundLease,
  };

  const [expanded, setExpanded] = useState(false);

  const whoami = useSelector((state) => state.auth.whoami);
  const { isHidden, toggleHidden } = useCardHidden({
    dealId: deal._id,
    cardHidden,
    cardId: dealViewIds.Disclosures,
  });

  if (whoami.role !== "Admin" && isHidden) return null;

  const disclosureAllNo = Object.values(disclosures).every(
    (disc) => disc == null || disc === "No" || disc === "None"
  );

  const renderDisclosures = () => {
    const disclosureElements = {
      bankruptcy: {
        value: bankruptcy,
        element: (
          <>
            <Col xs={24}>
              <p className="bold">
                {bankruptcy == null || bankruptcy === "None"
                  ? "No response"
                  : bankruptcy}
              </p>
              <p className="tw-whitespace-pre-line">
                Has Anyone in the Ownership Structure Ever Filed for Bankruptcy
                either personally or in a business entity?
              </p>
            </Col>
            {bankruptcy === "Yes" && bankruptcyInfo && (
              <Col xs={24}>
                <Spacer size={10} />
                <p className="bold">Reason</p>
                <p className="tw-whitespace-pre-line">{bankruptcyInfo}</p>
              </Col>
            )}
          </>
        ),
      },
      foreclosure: {
        value: foreclosure,
        element: (
          <>
            <Col xs={24}>
              <p className="bold">
                {foreclosure == null || foreclosure === "None"
                  ? "No response"
                  : foreclosure}
              </p>
              <p className="tw-whitespace-pre-line">
                Has anyone in the ownership structure ever lost a property to
                foreclosure, deed in lieu of foreclosure, or any other property
                taking action?
              </p>
            </Col>
            {foreclosure === "Yes" && foreclosureInfo && (
              <Col xs={24}>
                <Spacer size={10} />
                <p className="bold">Reason</p>
                <p className="tw-whitespace-pre-line">{foreclosureInfo}</p>
              </Col>
            )}
          </>
        ),
      },
      TICOrDSTStructure: {
        value: TICOrDSTStructure,
        element: (
          <>
            <Col xs={24}>
              <p className="bold">
                {TICOrDSTStructure == null || TICOrDSTStructure === "None"
                  ? "No response"
                  : TICOrDSTStructure}
              </p>
              <p className="tw-whitespace-pre-line">
                Is the property owned through a Tenant In Common (TIC) or
                Delaware Statutory Trust (DST) Structure?
              </p>
            </Col>
            {TICOrDSTStructure === "Yes" && numberOfInvestorsInStructure && (
              <Col xs={24}>
                <Spacer size={10} />
                <p className="bold">
                  <NumberFormat
                    value={numberOfInvestorsInStructure}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </p>
                <p className="tw-whitespace-pre-line">
                  Number of Investors in Structure
                </p>
              </Col>
            )}
            {TICOrDSTStructure === "Yes" &&
              additionalOwnershipStructureInfo && (
                <Col xs={24}>
                  <Spacer size={10} />
                  <p className="bold">
                    Additional Information on Ownership Structure
                  </p>
                  <p className="tw-whitespace-pre-line">
                    {additionalOwnershipStructureInfo}
                  </p>
                </Col>
              )}
          </>
        ),
      },
      materialLitigation: {
        value: materialLitigation,
        element: (
          <>
            <Col xs={24}>
              <p className="bold">
                {materialLitigation == null || materialLitigation === "None"
                  ? "No response"
                  : materialLitigation}
              </p>
              <p className="tw-whitespace-pre-line">
                Will a search of any of the ownership entities or Individuals
                turn up an material litigation?
              </p>
            </Col>
            {materialLitigation === "Yes" && materialLitigationInfo && (
              <Col xs={24}>
                <Spacer size={10} />
                <p className="bold">Reason</p>
                <p className="tw-whitespace-pre-line">
                  {materialLitigationInfo}
                </p>
              </Col>
            )}
          </>
        ),
      },
      condominium: {
        value: condominium,
        element: (
          <>
            <Col xs={24}>
              <p className="bold">
                {condominium == null || condominium === "None"
                  ? "No response"
                  : condominium}
              </p>
              <p className="tw-whitespace-pre-line">
                Is any part of the property a condominium?
              </p>
            </Col>
            {condominium === "Yes" && (
              <Col xs={24}>
                <Spacer size={10} />
                <p className="bold">Reason</p>
                <p className="tw-whitespace-pre-line">{condominiumInfo}</p>
              </Col>
            )}
          </>
        ),
      },
      environmentalConditions: {
        value: environmentalConditions,
        element: (
          <>
            <Col xs={24}>
              <p className="bold">
                {environmentalConditions == null ||
                environmentalConditions === "None"
                  ? "No response"
                  : environmentalConditions}
              </p>
              <p className="tw-whitespace-pre-line">
                Are there any known environmental conditions at the property?
              </p>
            </Col>
            {environmentalConditions === "Yes" &&
              environmentalConditionsInfo && (
                <Col xs={24}>
                  <Spacer size={10} />
                  <p className="bold">Reason</p>
                  <p className="tw-whitespace-pre-line">
                    {environmentalConditionsInfo}
                  </p>
                </Col>
              )}
          </>
        ),
      },
      phaseIIEnvironmentalInvestigation: {
        value: phaseIIEnvironmentalInvestigation,
        element: (
          <>
            <Col xs={24}>
              <p className="bold">
                {phaseIIEnvironmentalInvestigation == null ||
                phaseIIEnvironmentalInvestigation === "None"
                  ? "No response"
                  : phaseIIEnvironmentalInvestigation}
              </p>
              <p className="tw-whitespace-pre-line">
                Has a phase II environmental investigation ever been recommended
                or completed?
              </p>
            </Col>
            {phaseIIEnvironmentalInvestigation === "Yes" &&
              phaseIIEnvironmentalInvestigationInfo && (
                <Col xs={24}>
                  <Spacer size={10} />
                  <p className="bold">Reason</p>
                  <p className="tw-whitespace-pre-line">
                    {phaseIIEnvironmentalInvestigationInfo}
                  </p>
                </Col>
              )}
          </>
        ),
      },
      materialZoningIssues: {
        value: materialZoningIssues,
        element: (
          <>
            <Col xs={24}>
              <p className="bold">
                {materialZoningIssues == null || materialZoningIssues === "None"
                  ? "No response"
                  : materialZoningIssues}
              </p>
              <p className="tw-whitespace-pre-line">
                Are there any material zoning issues with the property?
              </p>
            </Col>
            {materialZoningIssues === "Yes" && materialZoningIssuesInfo && (
              <Col xs={24}>
                <Spacer size={10} />
                <p className="bold">Reason</p>
                <p className="tw-whitespace-pre-line">
                  {materialZoningIssuesInfo}
                </p>
              </Col>
            )}
          </>
        ),
      },
      groundLease: {
        value: groundLease,
        element: (
          <>
            <Col xs={24}>
              <p className="bold">
                {groundLease == null || groundLease === "None"
                  ? "No response"
                  : groundLease}
              </p>
              <p className="tw-whitespace-pre-line">
                Is the property on a ground lease?
              </p>
            </Col>
            {groundLease === "Yes" && (
              <Col xs={24}>
                <Spacer size={10} />
                <p className="bold">
                  {subordinateToNewMortgate == null ||
                  subordinateToNewMortgate === "None"
                    ? "I don't know"
                    : subordinateToNewMortgate}
                </p>
                <p className="tw-whitespace-pre-line">
                  Is the ground lease subordinate to a new mortgage?
                </p>
              </Col>
            )}
            {groundLease === "Yes" && groundLeaseTerm && (
              <Col xs={24}>
                <Spacer size={10} />
                <p className="bold">
                  <NumberFormat
                    value={groundLeaseTerm}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </p>
                <p className="tw-whitespace-pre-line">
                  How many years are left on the ground lease?
                </p>
              </Col>
            )}
            {groundLease === "Yes" && groundLeaseInfo && (
              <Col xs={24}>
                <Spacer size={10} />
                <p className="bold">
                  Additional Information Related to Ground Lease
                </p>
                <p className="tw-whitespace-pre-line">{groundLeaseInfo}</p>
              </Col>
            )}
          </>
        ),
      },
    };

    const yesElements = [];
    const noElements = [];
    const noneElements = [];

    const portfolioDisclosureElements = [];
    const propertyDisclosureElements = [];

    Object.keys(disclosureElements).forEach((key) => {
      if (
        [
          "bankruptcy",
          "foreclosure",
          "TICOrDSTStructure",
          "materialLitigation",
        ].includes(key)
      ) {
        portfolioDisclosureElements[key] = disclosureElements[key];
      } else {
        propertyDisclosureElements[key] = disclosureElements[key];
      }
    });

    const visibleDisclosureElements = portfolioDeal?.isPortifolio
      ? isProperty
        ? propertyDisclosureElements
        : portfolioDisclosureElements
      : disclosureElements;

    Object.values(visibleDisclosureElements).forEach((each) => {
      if (each.value === "Yes") {
        yesElements.push(each.element);
      } else if (each.value === "No") {
        noElements.push(each.element);
      } else {
        noneElements.push(each.element);
      }
    });

    const orderedElements = [...yesElements, ...noElements, ...noneElements];

    return orderedElements.map((element, index) => (
      <Fragment key={index}>
        {element}
        <Col xs={24}>
          <div className="border-bottom"></div>
        </Col>
      </Fragment>
    ));
  };

  return (
    <section className={isHidden ? "tw-bg-gray-200" : ""}>
      <div className="tw-flex tw-space-x-3 tw-items-center">
        <h4>Disclosures</h4>
        {whoami.role === "Admin" && (
          <span className="px-2 cursor-pointer">
            <Tooltip
              title={isHidden ? "Show this section" : "Hide this section"}
            >
              <FontAwesomeIcon
                icon={isHidden ? faEyeSlash : faEye}
                className="tw-text-gray-500"
                onClick={toggleHidden}
              />
            </Tooltip>
          </span>
        )}
      </div>
      <Spacer size={5} />
      <div className="border-bottom"></div>
      <Spacer size={20} />
      <Row gutter={[16, 16]}>
        {disclosureAllNo ? (
          <Col xs={24}>
            <p>
              The Sponsor has not disclosed anything else that might impact your
              underwriting. Expand this section to view all of the disclosures.{" "}
              <Button
                type="link"
                size="small"
                onClick={() => setExpanded(!expanded)}
              >
                {expanded ? "Collapse" : "Expand"}
              </Button>
            </p>
          </Col>
        ) : (
          <Col xs={24}>
            <p>
              The Sponsor has provided the following disclosures for your
              review:{" "}
              <Button
                type="link"
                size="small"
                onClick={() => setExpanded(!expanded)}
              >
                {expanded ? "Collapse" : "Expand"}
              </Button>
            </p>
          </Col>
        )}

        {expanded && renderDisclosures()}
      </Row>
    </section>
  );
};

export default Disclosures;
