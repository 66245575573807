import { dispatch } from "config/store";
import { actionTypes } from "./actionTypes";

export const createSection = (data) => {
  const action = {
    type: actionTypes.CREATE_SECTION,
    config: {
      method: "post",
      url: `/sections`,
      data,
    },
  };

  dispatch(action);
};

export const getSection = (sectionId) => {
  const action = {
    type: actionTypes.GET_SECTION,
    config: {
      method: "get",
      url: `/sections/${sectionId}`,
    },
  };

  dispatch(action);
};

export const clearSection = () => {
  const action = {
    type: actionTypes.CLEAR_SECTION,
  };

  dispatch(action);
};

export const updateSection = (sectionId, data) => {
  const action = {
    type: actionTypes.UPDATE_SECTION,
    config: {
      method: "put",
      url: `/sections/${sectionId}`,
      data,
    },
  };

  dispatch(action);
};

export const deleteSection = (sectionId) => {
  const action = {
    type: actionTypes.DELETE_SECTION,
    config: {
      method: "delete",
      url: `/sections/${sectionId}`,
    },
  };

  dispatch(action);
};

export const getSections = (query = { limit: 100, order: "ASC" }) => {
  const action = {
    type: actionTypes.GET_SECTIONS,
    config: {
      method: "get",
      url: `/sections?limit=${query.limit}&order=${query.order}&mysaved=${
        query.mysaved || false
      }&isTemplate=${query.isTemplate || false}&status=${query.status || ""}`,
    },
  };

  dispatch(action);
};

export const getManageSections = (
  query = { limit: 100, order: "ASC", orderColumn: "createdAt", page: 1 }
) => {
  const action = {
    type: actionTypes.GET_MANAGE_SECTIONS,
    config: {
      method: "get",
      url: `/sections?limit=${query.limit}&order=${query.order}&orderColumn=${
        query.orderColumn
      }&page=${query.page}&mysaved=${query.mysaved || false}`,
    },
  };

  dispatch(action);
};

export const mergeSections = (data) => {
  const action = {
    type: actionTypes.MERGE_SECTIONS,
    config: {
      method: "post",
      url: `/sections/merge`,
      data,
    },
  };

  dispatch(action);
};
