import React, { Fragment } from "react";
import { Col, Row, Tooltip } from "antd";
import Spacer from "components/layout/Spacer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { dealViewIds } from "../DealViewContext";
import useCardHidden from "../hooks/useCardHidden";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const PortfolioDetails = ({ deal, onChangeTab }) => {
  const { properties, cardHidden = [] } = deal;

  const whoami = useSelector((state) => state.auth.whoami);
  const { isHidden, toggleHidden } = useCardHidden({
    dealId: deal._id,
    cardHidden,
    cardId: dealViewIds.PropertyDescription,
  });

  if (whoami.role !== "Admin" && isHidden) return null;

  return (
    <section className={isHidden ? "tw-bg-gray-200" : ""}>
      <div className="tw-flex tw-space-x-3 tw-items-center">
        <h4>Property Details</h4>
        {whoami.role === "Admin" && (
          <span className="px-2 cursor-pointer">
            <Tooltip
              title={isHidden ? "Show this section" : "Hide this section"}
            >
              <FontAwesomeIcon
                icon={isHidden ? faEyeSlash : faEye}
                className="tw-text-gray-500"
                onClick={toggleHidden}
              />
            </Tooltip>
          </span>
        )}
      </div>
      <Spacer size={5} />

      <div className="border-bottom"></div>
      <Spacer size={20} />

      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <p className="tw-text-base">
            {properties.length} Property Portfolio As Described Below
          </p>
          <p className="font-12 color-medium-gray">Portfolio Size</p>
        </Col>

        {properties.map((property, index) => {
          const {
            propertyName,
            propertyAddress,
            propertyCity,
            propertyState,
            propertyZip,
          } = property;
          return (
            <Fragment key={property._id}>
              <Col xs={10}>
                <Link
                  to="/"
                  className="tw-text-base"
                  onClick={(evt) => {
                    evt.preventDefault();
                    onChangeTab(String(index + 2));
                  }}
                >
                  {propertyName}
                </Link>
                <p className="font-12 color-medium-gray">Property Name</p>
              </Col>
              <Col xs={14}>
                <p className="tw-text-base">
                  {propertyAddress
                    ? `${propertyAddress}, ${propertyCity}, ${propertyState}, ${propertyZip}`
                    : "-"}
                </p>
                <p className="font-12 color-medium-gray">Address</p>
              </Col>
            </Fragment>
          );
        })}
      </Row>
    </section>
  );
};

export default PortfolioDetails;
