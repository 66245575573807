import React, { Fragment } from "react";
import { connect } from "react-redux";
import {
  getDealById,
  getDealNotifications,
  enableDealNotifications,
  disableDealNotifications,
} from "redux/actions";
import {
  OverlayTrigger,
  Tooltip,
  Alert,
  Image,
  Button,
  Row,
  Col,
} from "react-bootstrap";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import PopupPrompt from "components/ui/PopupPrompt";
import Loader from "components/ui/Loader";
import Avatar from "images/avatar.jpg";
import Spacer from "components/layout/Spacer";
import ChangeLenderTeam from "./ChangeLenderTeam";

class DealTeamNotifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notifCount: 0,
      isAssignedLender: false,
      toggled: false,
      showError: false,
      showPrompt: false,
    };

    this.handleShowPrompt = this.handleShowPrompt.bind(this);
    this.toggleNotifications = this.toggleNotifications.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.deal !== this.props.deal) {
      const { deal, myCompany } = this.props;

      let data = {
        dealId: deal._id,
        teamId: deal.teamId?._id || deal.teamId,
      };

      const isAssignedLender = deal.lendersAssigned.some((lender) => {
        if (lender.companyId === myCompany._id) {
          if (lender.teamId) {
            data = {
              dealId: deal._id,
              teamId: lender.teamId,
            };

            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      });

      const dealCompanyId = deal.company?._id || deal.company;
      if (isAssignedLender || dealCompanyId === myCompany._id) {
        getDealNotifications(data);

        if (isAssignedLender) {
          this.setState({ isAssignedLender: true });
        }
      } else {
        this.setState({ showError: true });
      }
    }

    if (prevProps.notifications !== this.props.notifications) {
      const { notifications, whoami } = this.props;

      notifications.map((member) => {
        if (member.notifications) {
          this.setState((prevState) => ({
            notifCount: prevState.notifCount + 1,
          }));
        }

        if (member.userId === whoami._id) {
          if (member.notifications) {
            this.setState({ toggled: true });
          } else {
            this.setState({ toggled: false });
          }
        }

        return true;
      });
    }
  }

  componentDidMount() {
    const { match } = this.props;

    getDealById(match.params.id);
  }

  handleShowPrompt(showPrompt) {
    this.setState({ showPrompt });
  }

  toggleNotifications(val) {
    const { deal } = this.props;
    const { _id } = deal;

    if (!val) {
      if (this.state.notifCount === 1) {
        toast.error(
          "At least one member of the deal must have notifications enabled!",
          {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      } else {
        this.setState({ toggled: false });

        disableDealNotifications({
          dealId: _id,
        });
      }
    } else {
      this.setState({ toggled: true });

      enableDealNotifications({
        dealId: _id,
      });
    }
  }

  render() {
    const { action, deal, notifications, whoami, myCompany } = this.props;

    const isBorrowerAdmin =
      whoami.role === "Borrower" && whoami._id === myCompany.companyAdmin;

    return (
      <>
        {action.GET_DEAL_BY_ID.isFetching ||
          (action.GET_DEAL_NOTIFICATIONS.isFetching && <Loader />)}
        {/* Allowing borrower also to change teams. Fix TP-529 */}
        {this.state.isAssignedLender && (
          <PopupPrompt
            promptTitle={"Change Teams"}
            promptBody={
              <ChangeLenderTeam
                dealId={deal._id}
                onSubmit={(teamId) => {
                  this.handleShowPrompt(false);

                  getDealNotifications({
                    dealId: deal._id,
                    teamId: teamId,
                  });
                }}
              />
            }
            show={this.state.showPrompt}
            onCancel={() => {
              this.handleShowPrompt(false);
            }}
            onHide={() => {
              this.handleShowPrompt(false);
            }}
          />
        )}
        <Row>
          {this.state.showError ? (
            <Col xs="12">
              <Alert variant="danger">
                You do not have a team assigned! Please go back and assign a
                team to gain full access to notifications.
              </Alert>
            </Col>
          ) : (
            <>
              {/* Allowing borrower admin also to change teams. Fix TP-529 */}
              {(this.state.isAssignedLender || isBorrowerAdmin) && (
                <Col className="text-right" xs="12">
                  <OverlayTrigger
                    placement="left"
                    overlay={
                      <Tooltip id={`tooltip-left`}>Change Teams</Tooltip>
                    }
                  >
                    <Button
                      className="pl-3 pt-2 pr-3 pb-2"
                      onClick={() => {
                        this.handleShowPrompt(true);
                      }}
                    >
                      <FontAwesomeIcon icon={faSyncAlt} />
                    </Button>
                  </OverlayTrigger>
                </Col>
              )}
              <Col xs="12">
                <Spacer size={20} />
              </Col>
              <Col xs="12" sm="6">
                <h3>Team Leader</h3>
                <Spacer size={20} />
              </Col>
              <Col className="d-none d-md-block" sm="4">
                <h3>Notifications</h3>
                <Spacer size={20} />
              </Col>
            </>
          )}
        </Row>
        <Row>
          {notifications &&
            notifications.map((member, idx) => {
              const {
                globalRole,
                notifications,
                profileImage,
                firstName,
                lastName,
                userId,
              } = member;

              if (globalRole === "Team Leader") {
                return (
                  <Fragment key={idx}>
                    <Col xs="12" sm="6">
                      <Row>
                        <Col className="pl-3 no-flex">
                          <Image
                            src={profileImage ? profileImage : Avatar}
                            alt={
                              firstName + " " + lastName + "'s profile picture."
                            }
                            width="100px"
                            height="auto"
                          />
                        </Col>
                        <Col>
                          <Row>
                            <Col xs="12">
                              <h3 className="bold">
                                {firstName} {lastName}
                              </h3>
                            </Col>
                            <Col xs="12">
                              <p className="bold">{globalRole}</p>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                    <Col className="d-block d-md-none" xs="12">
                      <Spacer size={20} />
                      <h4>Notifications</h4>
                      <Spacer size={10} />
                    </Col>
                    <Col xs="12" md="4">
                      <Row>
                        {userId === whoami._id ? (
                          <>
                            <Col xs="6">
                              <Button
                                onClick={() =>
                                  !this.state.toggled
                                    ? this.toggleNotifications(true)
                                    : null
                                }
                                className={
                                  "full-width " +
                                  (this.state.toggled ? "primary" : "outline")
                                }
                              >
                                ON
                              </Button>
                            </Col>
                            <Col xs="6">
                              <Button
                                onClick={() =>
                                  this.state.toggled
                                    ? this.toggleNotifications(false)
                                    : null
                                }
                                className={
                                  "full-width " +
                                  (this.state.toggled ? "outline" : "primary")
                                }
                              >
                                OFF
                              </Button>
                            </Col>
                          </>
                        ) : (
                          <>
                            <Col xs="6">
                              <Button
                                className={
                                  "full-width " +
                                  (notifications ? "primary" : "outline")
                                }
                                disabled={true}
                              >
                                ON
                              </Button>
                            </Col>
                            <Col xs="6">
                              <Button
                                className={
                                  "full-width " +
                                  (notifications ? "outline" : "primary")
                                }
                                disabled={true}
                              >
                                OFF
                              </Button>
                            </Col>
                          </>
                        )}
                      </Row>
                    </Col>
                    <Col className="border-bottom" xs="12">
                      <Spacer size={20} />
                    </Col>
                  </Fragment>
                );
              } else {
                return false;
              }
            })}
        </Row>
        <Row>
          {Object.keys(this.props.deal).length > 0 &&
          Object.keys(this.props.notifications).length > 0 ? (
            <>
              <Col xs="12">
                <Spacer size={20} />
              </Col>
              <Col xs="12" sm="6">
                <h3>Team Members</h3>
                <Spacer size={20} />
              </Col>
              <Col className="d-none d-md-block" sm="4">
                <h3>Notifications</h3>
                <Spacer size={20} />
              </Col>
            </>
          ) : (
            <></>
          )}
        </Row>
        <Row>
          {notifications &&
            notifications.map((member, idx) => {
              const {
                globalRole,
                notifications,
                profileImage,
                firstName,
                lastName,
                userId,
              } = member;

              if (globalRole === "Team Member") {
                return (
                  <Fragment key={idx}>
                    <Col xs="12" sm="6">
                      <Row>
                        <Col className="pl-3 no-flex">
                          <Image
                            src={profileImage ? profileImage : Avatar}
                            alt={
                              firstName + " " + lastName + "'s profile picture."
                            }
                            width="100px"
                            height="auto"
                          />
                        </Col>
                        <Col>
                          <Row>
                            <Col xs="12">
                              <h3 className="bold">
                                {firstName} {lastName}
                              </h3>
                            </Col>
                            <Col xs="12">
                              <p className="bold">{globalRole}</p>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                    <Col className="d-block d-md-none" xs="12">
                      <Spacer size={20} />
                      <h4>Notifications</h4>
                      <Spacer size={10} />
                    </Col>
                    <Col xs="12" md="4">
                      <Row>
                        {userId === whoami._id ? (
                          <>
                            <Col xs="6">
                              <Button
                                onClick={() =>
                                  !this.state.toggled
                                    ? this.toggleNotifications(true)
                                    : null
                                }
                                className={
                                  "full-width " +
                                  (this.state.toggled ? "primary" : "outline")
                                }
                              >
                                ON
                              </Button>
                            </Col>
                            <Col xs="6">
                              <Button
                                onClick={() =>
                                  this.state.toggled
                                    ? this.toggleNotifications(false)
                                    : null
                                }
                                className={
                                  "full-width " +
                                  (this.state.toggled ? "outline" : "primary")
                                }
                              >
                                OFF
                              </Button>
                            </Col>
                          </>
                        ) : (
                          <>
                            <Col xs="6">
                              <Button
                                className={
                                  "full-width " +
                                  (notifications ? "primary" : "outline")
                                }
                                disabled={true}
                              >
                                ON
                              </Button>
                            </Col>
                            <Col xs="6">
                              <Button
                                className={
                                  "full-width " +
                                  (notifications ? "outline" : "primary")
                                }
                                disabled={true}
                              >
                                OFF
                              </Button>
                            </Col>
                          </>
                        )}
                      </Row>
                    </Col>
                    <Col xs="12">
                      <Spacer size={20} />
                    </Col>
                  </Fragment>
                );
              } else {
                return false;
              }
            })}
        </Row>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    action: state.action,
    whoami: state.auth.whoami,
    myCompany: state.company.myCompany,
    deal: state.deal.deal,
    notifications: state.deal.notifications,
  };
}

export default connect(mapStateToProps)(DealTeamNotifications);
