import React from "react";
import { connect } from "react-redux";
import { Button, Form, Container, Row, Col } from "react-bootstrap";
import { Field, reduxForm } from "redux-form";
import { required, email, noSpaces } from "utils/validation";
import { reduxFormInput, reduxFormSelect } from "components/form/ReduxForm";
import { createUser } from "redux/actions";
import Spacer from "components/layout/Spacer";
import Loader from "components/ui/Loader";

class CreateNewUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
    };

    this.submitFn = this.submitFn.bind(this);
  }

  submitFn(data) {
    const updatedData = { ...data };
    this.setState({ email: updatedData.email });
    updatedData.type = updatedData.type.value || updatedData.type;
    createUser(updatedData);
  }

  render() {
    const { handleSubmit, action } = this.props;
    const { isFetching } = action.CREATE_USER;
    return (
      <>
        {isFetching && <Loader />}
        <Container>
          <Form
            className="no-border no-padding"
            onSubmit={handleSubmit(this.submitFn)}
          >
            <Row>
              <Col xs="12" sm="6">
                <Field
                  className="form-control"
                  component={reduxFormInput}
                  validate={[required, noSpaces]}
                  name="firstName"
                  type="text"
                  placeholder="Enter first name"
                  label="*First name"
                />
                <Spacer size={50} />
              </Col>
              <Col xs="12" sm="6">
                <Field
                  className="form-control"
                  component={reduxFormInput}
                  validate={[required, noSpaces]}
                  name="lastName"
                  type="text"
                  placeholder="Enter last name"
                  label="*Last name"
                />
                <Spacer size={50} />
              </Col>
              <Col xs="6">
                <Field
                  className="form-control"
                  component={reduxFormInput}
                  validate={[required, email, noSpaces]}
                  name="email"
                  type="email"
                  placeholder="Enter email"
                  label="*Email"
                  normalize={(value) => value.toLowerCase()}
                />
                <Spacer size={50} />
              </Col>
              <Col xs="12" sm="6">
                <Field
                  className="form-control"
                  component={reduxFormSelect}
                  validate={[required]}
                  name="type"
                  type="select"
                  options={[
                    { label: "Yes", value: "Lender" },
                    { label: "No", value: "Borrower" },
                  ]}
                  label="*Are you a lender?"
                />
                <Spacer size={50} />
              </Col>
            </Row>
            <Row>
              <Col>
                <Field
                  className="form-control"
                  component={reduxFormInput}
                  name="sendInvite"
                  type="checkbox"
                  label="Send Invite Immediately"
                />
              </Col>
            </Row>
            <Spacer size={50} />
            <Row className="justify-content-center">
              <Col className="text-center" xs="12" sm="6">
                <Button type="submit" variant="primary">
                  Create
                </Button>
              </Col>
            </Row>
          </Form>
          <Spacer size={50} />
        </Container>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    action: state.action,
    initialValues: {
      type: { label: "No", value: "Borrower" },
      sendInvite: false,
    },
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "register",
    enableReinitialize: true,
  })(CreateNewUser)
);
