import { actionTypes } from "redux/actions";
import {
  reducerCreator,
  multipleReducers,
  genericReducerCreator,
} from "./meta";

export const deal = multipleReducers({
  initializers: {
    initialState: {
      deals: [],
      deal: {},
      notifications: [],
      totalDeals: 0,
      withSearch: false,
      updateDealLenders: null,
      assignBorrowerTeamToDeal: null,
    },
  },
  list: [
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.GET_DEALS,
        successFn: (action) => ({
          deals: action.data.body.rows,
          totalDeals: action.data.body.count,
          withSearch: false,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.GET_FILTERED_DEALS,
        successFn: (action) => ({
          deals: action.data.body.rows,
          totalDeals: action.data.body.count,
          withSearch: false,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.SEARCH_DEALS,
        successFn: (action) => ({
          deals: action.data.body.rows,
          withSearch: true,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.GET_DEAL_NOTIFICATIONS,
        successFn: (action) => ({
          notifications: action.data,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.GET_DEAL_BY_ID,
        successFn: (action) => ({
          deal: action.data.body,
        }),
      },
    },
    {
      reducerCreatorFn: genericReducerCreator,
      params: {
        type: actionTypes.CLEAR_DEAL_DETAILS,
        successFn: () => ({
          deal: {},
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.SAVE_FINANCIALS,
        successFn: (action) => ({
          files: action.data.body,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.SAVE_PICTURES,
        successFn: (action) => ({
          deal: action.data.body,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.SAVE_PRIMARY_PICTURE,
        successFn: (action) => ({
          deal: action.data.body,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.UPDATE_DEAL_LENDERS,
        successFn: (action) => ({
          updateDealLenders: action.data.body,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.ASSIGN_TEAM_TO_DEAL,
        successFn: () => ({
          updateDealLenders: null,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.ASSIGN_BORROWER_TEAM_TO_DEAL,
        successFn: (action) => ({
          assignBorrowerTeamToDeal: action.data.body,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.ADD_DEAL_CARD,
        successFn: (action, state) => {
          if (state.deal._id === action.data.body[0]._id) {
            return {
              deal: {
                ...state.deal,
                additionalInfo: action.data.body[0].additionalInfo,
              },
            };
          }
          return {
            deal: {
              ...state.deal,
              properties: state.deal.properties.map((property) =>
                property._id === action.data.body[0]._id
                  ? {
                      ...property,
                      additionalInfo: action.data.body[0].additionalInfo,
                    }
                  : property
              ),
            },
          };
        },
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.EDIT_DEAL_CARD,
        successFn: (action, state) => {
          if (state.deal._id === action.data.body[0]._id) {
            return {
              deal: {
                ...state.deal,
                additionalInfo: action.data.body[0].additionalInfo,
              },
            };
          }
          return {
            deal: {
              ...state.deal,
              properties: state.deal.properties.map((property) =>
                property._id === action.data.body[0]._id
                  ? {
                      ...property,
                      additionalInfo: action.data.body[0].additionalInfo,
                    }
                  : property
              ),
            },
          };
        },
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.DELETE_DEAL_CARD,
        successFn: (action, state) => {
          if (state.deal._id === action.data.body[0]._id) {
            return {
              deal: {
                ...state.deal,
                additionalInfo: action.data.body[0].additionalInfo,
              },
            };
          }
          return {
            deal: {
              ...state.deal,
              properties: state.deal.properties.map((property) =>
                property._id === action.data.body[0]._id
                  ? {
                      ...property,
                      additionalInfo: action.data.body[0].additionalInfo,
                    }
                  : property
              ),
            },
          };
        },
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.HIDDEN_DEAL_CARD,
        successFn: (action, state) => {
          if (state.deal._id === action.data.body[0]._id) {
            return {
              deal: {
                ...state.deal,
                cardHidden: action.data.body[0].cardHidden,
              },
            };
          }
          return {
            deal: {
              ...state.deal,
              properties: state.deal.properties.map((property) =>
                property._id === action.data.body[0]._id
                  ? {
                      ...property,
                      cardHidden: action.data.body[0].cardHidden,
                    }
                  : property
              ),
            },
          };
        },
      },
    },
    {
      reducerCreatorFn: genericReducerCreator,
      params: {
        type: actionTypes.REORDER_DEAL_CARDS,
        successFn: (action, state) => {
          if (state.deal._id === action.config.data.dealId) {
            return {
              deal: {
                ...state.deal,
                cardOrder: action.config.data.cardOrder,
              },
            };
          }
          return {
            deal: {
              ...state.deal,
              properties: state.deal.properties.map((property) =>
                property._id === action.config.data.dealId
                  ? {
                      ...property,
                      cardOrder: action.config.data.cardOrder,
                    }
                  : property
              ),
            },
          };
        },
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.REORDER_DEAL_CARDS,
        successFn: (action, state) => {
          if (state.deal._id === action.data.body[0]._id) {
            return {
              deal: {
                ...state.deal,
                cardOrder: action.data.body[0].cardOrder,
              },
            };
          }
          return {
            deal: {
              ...state.deal,
              properties: state.deal.properties.map((property) =>
                property._id === action.data.body[0]._id
                  ? {
                      ...property,
                      cardOrder: action.data.body[0].cardOrder,
                    }
                  : property
              ),
            },
          };
        },
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.UPDATE_DEAL_MANAGER,
        successFn: (action, state) => {
          return {
            deal: {
              ...state.deal,
              teamIdManager: action.data.body[0].teamIdManager,
            },
          };
        },
      },
    },
  ],
});
