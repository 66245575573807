import constants from 'config/constants';

// Store json in termst local storage
export const storeJsonInLocal = (jsonObj) => {
	if (typeof Storage !== 'undefined') {
		const localStorageName = constants.TERMST_LOCAL_STORAGE.STORAGE_NAME;
		let termstLocal = localStorage.getItem(localStorageName);
		let value = {};

		if (termstLocal) {
			value = Object.assign({}, JSON.parse(termstLocal), jsonObj);
		} else {
			value = jsonObj;
		}

		localStorage.setItem(localStorageName, JSON.stringify(value));
	}
}

// Remove a particular key from termst local storage
export const removeKeyFromLocal = (key) => {
	if (typeof Storage !== 'undefined') {
		const localStorageName = constants.TERMST_LOCAL_STORAGE.STORAGE_NAME;
		let termstLocal = localStorage.getItem(localStorageName);

		if (termstLocal) {
			termstLocal = Object.assign({}, JSON.parse(termstLocal));

			if (termstLocal[key]) {
				delete termstLocal[key];
			}

			localStorage.setItem(localStorageName, JSON.stringify(termstLocal));
		}
	}
}

// Get the value of a key from termst local storage
export const getKeyFromLocal = (key) => {
	if (typeof Storage !== 'undefined') {
		const localStorageName = constants.TERMST_LOCAL_STORAGE.STORAGE_NAME;
		let termstLocal = localStorage.getItem(localStorageName);

		if (termstLocal) {
			termstLocal = Object.assign({}, JSON.parse(termstLocal));
			if (termstLocal[key]) {
				return termstLocal[key];
			}
			return false;
		}
		return false;
	}
}

// Destroy termst local storage
export const destroyLocal = () => {
	if (typeof Storage !== 'undefined') {
		const localStorageName = constants.TERMST_LOCAL_STORAGE.STORAGE_NAME;
		let termstLocal = localStorage.getItem(localStorageName);
		if (termstLocal) {
			localStorage.removeItem(localStorageName);
		}
		localStorage.removeItem(constants.TERMST_LOCAL_STORAGE.PREV_JWT_KEY);
	}
}
