import { actionTypes } from "redux/actions";
import {
  reducerCreator,
  multipleReducers,
  genericReducerCreator,
} from "./meta";

export const conversation = multipleReducers({
  initializers: {
    initialState: {
      conversationsTotalCount: 0,
      conversations: [],
      inboxAll: [],
      inboxAllTotalCount: 0,
      inboxMessages: [],
      inboxMessagesTotalCount: 0,
      inboxQuestions: [],
      inboxQuestionsTotalCount: 0,
      conversationDetails: null,
      messageDetails: null,
      questionDetails: null,
      lastConversationMessageCreated: null,
      lastConversationQuestionCreated: null,
      lastConversationUpdated: null,
      lastConversationDeleted: null,
      lastMessageReply: null,
      lastQuestionAnswer: null,
    },
  },
  list: [
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.GET_CONVERSATIONS,
        successFn: (action) => ({
          conversations: action.data.body.rows,
          conversationsTotalCount: action.data.body.count,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.GET_CONVERSATION_ALL_INBOX,
        successFn: (action, state) => ({
          inboxAll:
            Number(action.data.body.page) === 1
              ? action.data.body.rows
              : [...state.inboxAll, ...action.data.body.rows],
          inboxAllTotalCount: action.data.body.count,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.GET_CONVERSATION_MESSAGE_INBOX,
        successFn: (action, state) => ({
          inboxMessages:
            Number(action.data.body.page) === 1
              ? action.data.body.rows
              : [...state.inboxMessages, ...action.data.body.rows],
          inboxMessagesTotalCount: action.data.body.count,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.GET_CONVERSATION_QUESTION_INBOX,
        successFn: (action, state) => ({
          inboxQuestions:
            Number(action.data.body.page) === 1
              ? action.data.body.rows
              : [...state.inboxQuestions, ...action.data.body.rows],
          inboxQuestionsTotalCount: action.data.body.count,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.GET_CONVERSATION_BY_ID,
        successFn: (action) => {
          if (action.data.type === "Message") {
            return {
              messageDetails: action.data,
              questionDetails: null,
              conversationDetails: action.data,
            };
          }
          return {
            messageDetails: null,
            questionDetails: action.data,
            conversationDetails: action.data,
          };
        },
      },
    },
    {
      reducerCreatorFn: genericReducerCreator,
      params: {
        type: actionTypes.GET_CONVERSATION_BY_ID_OPTIMISTIC_UPDATE,
        successFn: (action) => {
          if (action.data.type === "Message") {
            return {
              messageDetails: action.data,
              questionDetails: null,
              conversationDetails: action.data,
            };
          }
          return {
            messageDetails: null,
            questionDetails: action.data,
            conversationDetails: action.data,
          };
        },
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.CREATE_CONVERSATION_MESSAGE,
        successFn: (action) => ({
          lastConversationMessageCreated: action.data,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.CREATE_CONVERSATION_QUESTION,
        successFn: (action) => ({
          lastConversationQuestionCreated: action.data,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.UPDATE_CONVERSATION,
        successFn: (action) => ({
          lastConversationUpdated: action.data,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.DELETE_CONVERSATION,
        successFn: (action) => ({
          lastConversationDeleted: action.data,
          conversationDetails: null,
          messageDetails: null,
          questionDetails: null,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.REPLY_TO_MESSAGE,
        successFn: (action) => ({
          lastMessageReply: action.data,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.ANSWER_TO_QUESTION,
        successFn: (action) => ({
          lastQuestionAnswer: action.data,
        }),
      },
    },
    {
      reducerCreatorFn: genericReducerCreator,
      params: {
        type: actionTypes.CLEAR_CONVERSATION_DETAILS,
        successFn: () => ({
          conversationDetails: null,
          messageDetails: null,
          questionDetails: null,
        }),
      },
    },
  ],
});
