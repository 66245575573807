import React, { useState, useCallback, useRef } from "react";
import { Dropdown, Button, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import PopupPrompt from "components/ui/PopupPrompt";
import AddFileCategory from "containers/deals/FileManager/AddFileCategory";
import { toast } from "react-toastify";
import {
  ALLOWED_FILE_EXTS,
  ALLOWED_FILE_MANAGER_PICTURE_FILE_TYPES,
} from "./constants";
import CategoryItemDropTarget from "./CategoryItemDropTarget";

const CategoryItem = (props) => {
  const [showPrompt, setShowPrompt] = useState(false);
  const {
    node,
    getChildNodes,
    level,
    onToggle,
    handleUploadFile,
    groupFolderFile,
    showSecuritySharing,
    fetchFolder,
    fetchFile,
    addFolder,
    removeFolder,
    showRenaming,
    fromComms,
    canManage,
  } = props;

  const fileInput = useRef(null);

  const handleShowPrompt = useCallback((value) => {
    setShowPrompt(value);
  }, []);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Button
      ref={ref}
      variant="folder"
      className="pl-1 pt-1 pr-1 pb-1"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </Button>
  ));

  const CustomMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) => child.props.children
            )}
          </ul>
        </div>
      );
    }
  );

  const handleUploadFileClick = (event) => {
    if (node.name === "Pictures") {
      const files = event.target?.files || [];
      for (let file of files) {
        const ext = file.name.split(".").reverse()[0];
        if (
          !ALLOWED_FILE_MANAGER_PICTURE_FILE_TYPES.includes(
            `${ext.toLowerCase()}`
          )
        ) {
          toast.error(
            `Unsupported file. Allows only ${ALLOWED_FILE_MANAGER_PICTURE_FILE_TYPES.join(
              ", "
            )}`,
            {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
          return;
        }
      }
    }

    handleUploadFile(event, node._id);
  };

  return (
    <>
      <Row className="file-folders">
        <Col xs="12">
          <input
            className="d-none"
            type="file"
            ref={fileInput}
            id={node._id}
            // onChange={(event) => {
            //   handleUploadFile(event, node._id);
            // }}
            onChange={handleUploadFileClick}
            accept={ALLOWED_FILE_EXTS.join(",")}
            multiple
          />
          <Row>
            <Col className="d-flex align-items-center " xs="12">
              {node.isFolder && node.ancestors.length === 1 && (
                <p
                  className={
                    node.isOpen
                      ? "bold d-inline folder-name"
                      : "d-inline folder-name"
                  }
                  onClick={() => onToggle(node._id)}
                  onKeyDown={(e) =>
                    e.keyCode === 13 ? document.activeElement.click() : null
                  }
                  tabIndex="0"
                >
                  {node.name}
                </p>
              )}
              {node.isFolder && node.ancestors.length === 2 && (
                <p
                  className={
                    node.isOpen
                      ? "pl-2 bold d-inline folder-name"
                      : "pl-2 d-inline folder-name"
                  }
                  onClick={() => onToggle(node._id)}
                  onKeyDown={(e) =>
                    e.keyCode === 13 ? document.activeElement.click() : null
                  }
                  tabIndex="0"
                >
                  {node.name}
                </p>
              )}
              {node.isFolder && node.ancestors.length === 3 && (
                 <p className="d-inline pl-2 file-name">
                 {node.displayName || node.name}
               </p>
              )}
              {!node.isFolder && node.ancestors.length === 2 && (
                <p className="d-inline pl-2 file-name">
                  {node.displayName || node.name}
                </p>
              )}
              {!node.isFolder && node.ancestors.length > 2 && (
                <p className="d-inline pl-3 file-name">
                  {node.displayName || node.name}
                </p>
              )}
              {!fromComms && (
                <Dropdown className="d-inline folder-file-dropdown">
                  <Dropdown.Toggle as={CustomToggle} id="toggle-dropdown">
                    <h4 className="view-as">
                      <FontAwesomeIcon
                        className="color-medium-gray"
                        icon={faEllipsisH}
                      />
                    </h4>
                  </Dropdown.Toggle>

                  <Dropdown.Menu as={CustomMenu}>
                    {((node.isFolder && node.ancestors.length === 1) ||
                      (node.isFolder && node.ancestors.length === 2)) && (
                        <Dropdown.Item
                          onClick={() => {
                            onToggle(node._id, true);
                          }}
                        >
                          Open
                        </Dropdown.Item>
                      )}
                    {node.ancestors.length <= 1 &&
                      node.name !== "Pictures" &&
                      canManage && (
                        <Dropdown.Item onClick={() => handleShowPrompt(true)}>
                          Add Folder
                        </Dropdown.Item>
                      )}
                    {((node.isFolder && node.ancestors.length === 1) ||
                      (node.isFolder && node.ancestors.length === 2)) &&
                      canManage && (
                        <Dropdown.Item
                          onClick={() => fileInput.current.click()}
                        >
                          Add Files
                        </Dropdown.Item>
                      )}
                    <Dropdown.Item
                      onClick={() => {
                        node.isFolder
                          ? fetchFolder(node._id, node.name)
                          : fetchFile(node._id, node.name);
                      }}
                    >
                      Download
                    </Dropdown.Item>
                    {canManage && (
                      <>
                        <Dropdown.Item
                          onClick={() => {
                            showRenaming(node._id);
                          }}
                        >
                          Edit
                        </Dropdown.Item>
                        {/* <Dropdown.Item
                          onClick={() => {
                            showSecuritySharing(node._id);
                          }}
                        >
                          Security & Sharing
                        </Dropdown.Item> */}
                        <Dropdown.Divider> </Dropdown.Divider>
                        <Dropdown.Item
                          onClick={() => {
                            removeFolder(node._id);
                          }}
                        >
                          Delete
                        </Dropdown.Item>
                      </>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <PopupPrompt
        promptTitle={"Create Folder"}
        promptBody={
          <AddFileCategory
            id={node._id}
            placeholder="Enter folder name"
            label="Folder"
            onSubmit={(id, e) => {
              setShowPrompt(false);
              addFolder(id, e);
            }}
          />
        }
        show={showPrompt}
        onHide={() => handleShowPrompt(false)}
        onCancel={() => handleShowPrompt(false)}
      />
      {node.isOpen &&
        groupFolderFile(getChildNodes(node)).map((childNode, i) => {
          if (props.canHideNode(childNode)) {
            return null;
          }
          //Lets just show the folders, Not files in side menu
          // Check comments in https://paramountsoft.atlassian.net/browse/TP-670
          if (childNode.isFolder) {
            return <CategoryItemDropTarget
              key={i}
              _id={childNode._id}
              name={childNode.name}
              node={childNode}
              level={level + 1}
              getChildNodes={getChildNodes}
              onToggle={onToggle}
              handleUploadFile={handleUploadFile}
              groupFolderFile={groupFolderFile}
              fetchFolder={fetchFolder}
              fetchFile={fetchFile}
              addFolder={addFolder}
              removeFolder={removeFolder}
              showSecuritySharing={showSecuritySharing}
              showRenaming={showRenaming}
              fromComms={fromComms}
              hideEmptyFolders={props.hideEmptyFolders}
              canHideNode={props.canHideNode}
            />
          }
          return null;
          // const CategoryItemComponent = childNode.isFolder
          //   ? CategoryItemDropTarget
          //   : CategoryItem;
          // return (
          //   <CategoryItemComponent
          //     key={i}
          //     _id={childNode._id}
          //     name={childNode.name}
          //     node={childNode}
          //     level={level + 1}
          //     getChildNodes={getChildNodes}
          //     onToggle={onToggle}
          //     handleUploadFile={handleUploadFile}
          //     groupFolderFile={groupFolderFile}
          //     fetchFolder={fetchFolder}
          //     fetchFile={fetchFile}
          //     addFolder={addFolder}
          //     removeFolder={removeFolder}
          //     showSecuritySharing={showSecuritySharing}
          //     showRenaming={showRenaming}
          //     fromComms={fromComms}
          //     hideEmptyFolders={props.hideEmptyFolders}
          //     canHideNode={props.canHideNode}
          //   />
          // );
        })}
    </>
  );
};

export default CategoryItem;
