import {
  MailOutlined,
  InboxOutlined,
  SendOutlined,
  FileOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { Divider, Layout, Menu, theme } from "antd";
import React, { useEffect } from "react";
import InboxLabels from "./labels/InboxLabels";
import "./InboxLayout.scss";
import InboxList from "./InboxList";
import InboxActionButtons from "./InboxActionButtons";
import InboxDetails from "./InboxDetails";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import { conversationStatuses, conversationTypes } from "./enums";
import { clearConversationDetails, getTeamsByCompanyId } from "redux/actions";
import { useSelector } from "react-redux";
const { Content, Sider } = Layout;

const sideItems = [
  {
    label: "All",
    key: "all",
    icon: React.createElement(InboxOutlined),
  },
  {
    label: "Inbox",
    key: "inbox",
    icon: React.createElement(MailOutlined),
  },
  {
    label: "Archived",
    key: "archived",
    icon: React.createElement(DeleteOutlined),
  },
  {
    label: "Draft",
    key: "draft",
    icon: React.createElement(FileOutlined),
  },
  {
    label: "Sent",
    key: "sent",
    icon: React.createElement(SendOutlined),
  },
  {
    label: "Trash",
    key: "deleted",
    icon: React.createElement(DeleteOutlined),
  },
];

const InboxLayout = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const history = useHistory();
  const searchObject = queryString.parse(history.location.search);
  const myCompany = useSelector((state) => state.company.myCompany);

  const [tab, setTab] = React.useState(
    searchObject.tab || conversationTypes.messages
  );
  const [status, setStatus] = React.useState(
    searchObject.status || conversationStatuses.inbox
  );

  const onSideMenuClick = (item) => {
    clearConversationDetails();
    setStatus(item.key);
  };

  useEffect(() => {
    const search = queryString.stringify({ status, tab });
    history.push({ search });
  }, [status, tab]);

  useEffect(() => {
    myCompany._id && getTeamsByCompanyId(myCompany._id);
  }, [myCompany._id]);

  return (
    <Layout className="InboxLayout">
      <InboxActionButtons />
      <Layout>
        <Sider
          width={200}
          breakpoint="lg"
          style={{
            background: colorBgContainer,
          }}
        >
          <Menu
            mode="inline"
            defaultSelectedKeys={[status]}
            onClick={onSideMenuClick}
            style={{
              borderRight: 0,
            }}
            items={sideItems}
          />
          <Divider />
          <InboxLabels tab={tab} status={status} setStatus={setStatus} />
        </Sider>
        <Layout>
          <Content
            style={{
              display: "flex",
              gap: "10px",
              padding: 8,
              margin: 0,
              background: colorBgContainer,
              height: "100%",
            }}
          >
            <InboxList status={status} tab={tab} onTabChange={setTab} />
            <InboxDetails status={status} tab={tab} />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default InboxLayout;
