import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import queryString from "query-string";
import { Container } from "react-bootstrap";
import { clearTermsheet, createTermsheet, getTermsheet } from "redux/actions";
import Loader from "components/ui/Loader";

function ProvideTermsheetName(props) {
  const { match, params, location, termsheet, myCompany } = props;
  const search = queryString.parse(location?.search);
  const fromScratch = Boolean(search?.fromScratch);
  const isDefault = Boolean(search?.isDefault);
  const termsheetId = params?.termsheetId || search?.termsheetId;

  const [termsheetName, setTermsheetName] = useState("");

  useEffect(() => {
    if (termsheetId) {
      getTermsheet(termsheetId);
    } else {
      clearTermsheet();
    }
  }, []);

  useEffect(() => {
    let name = "";
    const date = `${new Date().toDateString()} ${new Date().toLocaleTimeString()}`;
    if (termsheetId && termsheet?.termsheetName) {
      name = `${termsheet.termsheetName} - ${date}`;
    } else if (!termsheetId) {
      name = `Termsheet - [Deal Name] - ${date}`;
    }
    setTermsheetName(name);
  }, [termsheet]);

  useEffect(() => {
    if (termsheetName) {
      const columns = fromScratch ? [] : termsheet?.columns || [];
      createTermsheet({
        dealId: match.params.dealId,
        termsheetName,
        columns,
        companyID: myCompany._id,
        status: "DRAFT",
        fromScratch,
        isDefault,
      });
    }
  }, [termsheetName]);

  return (
    <Container>
      <Loader />
    </Container>
  );
}
function mapStateToProps(state) {
  return {
    action: state.action,
    myCompany: state.company.myCompany,
    termsheet: state.termsheet.termsheet,
  };
}

export default connect(mapStateToProps)(ProvideTermsheetName);
