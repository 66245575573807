import React from "react";
import { faEllipsisH, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomMenu from "components/ui/CustomMenu";
import CustomToggle from "components/ui/CustomToggle";
import { Dropdown, ListGroup } from "react-bootstrap";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
import ListGroupItem from "./ListGroupItem";
import {
  extractTextFromHtmlString,
  replaceMergeFields,
} from "utils/termsheetUtils";
import { getSpreadTextValue } from "utils/commonUtils";

const CompareQuoteCards = ({
  history,
  selected,
  sortedAllFields,
  compareQuotes,
  deal,
  whoami,
  borrowerCompany,
  getIsArchived,
  handleSelected,
  handleRemoveQuote,
  handleDownloadDocx,
}) =>
  compareQuotes.map((quote, index) => {
    const actions = (
      <div
        style={{ height: 24 }}
        className="d-flex align-items-center justify-content-between my-2"
      >
        <div className="d-flex align-items-center">
          <input
            type="checkbox"
            className="cursor-pointer mr-2"
            checked={selected.includes(quote._id)}
            onChange={() => handleSelected(quote._id)}
          />
          <FontAwesomeIcon
            icon={faTimes}
            className="cursor-pointer"
            onClick={() => handleRemoveQuote(quote)}
          />
        </div>
        <Link
          className="d-inline ml-3 font-14"
          to={`/termsheet/${quote.termsheet._id}`}
        >
          View
        </Link>
        <Dropdown className="d-inline-block">
          <Dropdown.Toggle as={CustomToggle}>
            <FontAwesomeIcon icon={faEllipsisH} className="cursor-pointer" />
          </Dropdown.Toggle>
          <Dropdown.Menu as={CustomMenu}>
            <Dropdown.Item
              onClick={() => history.push(`/termsheet/${quote.termsheet._id}`)}
            >
              View
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleRemoveQuote(quote)}>
              Remove
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleDownloadDocx(quote)}>
              Download (.docx)
            </Dropdown.Item>
            <Dropdown.Item>Download (.pdf)</Dropdown.Item>
            <Dropdown.Item>Print</Dropdown.Item>
            <Dropdown.Item>Send Message</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
    return (
      <div key={quote._id} className="column-item-container">
        {actions}
        <ListGroup variant="flush">
          {sortedAllFields.map(({ label, clause }) => {
            let listItemElement = "";

            switch (label) {
              case "Status":
                listItemElement = getIsArchived(quote)
                  ? "Archived"
                  : quote.termsheet?.status === "DRAFT"
                  ? "Draft"
                  : quote.status;
                break;
              case "Lender":
                listItemElement = quote.companyId.companyName;
                break;
              case "Loan Amount":
                listItemElement = (
                  <NumberFormat
                    value={quote.loanAmount}
                    displayType={"text"}
                    thousandSeparator
                    prefix="$"
                  />
                );
                break;
              case "Term":
                listItemElement = (
                  <NumberFormat
                    value={quote.term}
                    displayType={"text"}
                    thousandSeparator
                    suffix={` ${quote.termMetric}`}
                  />
                );
                break;
              case "Amortization":
                listItemElement = (
                  <NumberFormat
                    value={quote.amortization}
                    displayType={"text"}
                    thousandSeparator
                    suffix={` ${quote.amortizationMetric}`}
                  />
                );
                break;
              case "IO Period":
                listItemElement = (
                  <NumberFormat
                    value={quote.IOPeriod}
                    displayType={"text"}
                    suffix={` ${quote.IOPeriodMetric}`}
                    thousandSeparator
                  />
                );
                break;
              case "Rate Calculation":
                listItemElement = quote.isRateOrSpread;
                break;
              case "Spread":
                listItemElement = getSpreadTextValue(quote);
                break;
              case "Floor":
                listItemElement = quote.floorRate ? (
                  <NumberFormat
                    value={quote.floorRate}
                    displayType={"text"}
                    thousandSeparator
                    suffix={`% ${quote.floorOption}`}
                  />
                ) : (
                  quote.floorOption
                );
                break;
              case "Index":
                listItemElement = (
                  <NumberFormat
                    value={quote.indexRate}
                    displayType={"text"}
                    thousandSeparator
                    suffix={`%`}
                  />
                );
                break;
              case "Index Term":
                listItemElement = (
                  <NumberFormat value={quote.indexTerm1} displayType={"text"} />
                );
                break;
              case "Quoted Rate":
                listItemElement = (
                  <NumberFormat
                    value={quote.quotedRate}
                    displayType={"text"}
                    thousandSeparator
                    suffix={`%`}
                  />
                );
                break;
              default:
                listItemElement = quote[label] || listItemElement;
                break;
            }

            if (!clause?._id) {
              clause = quote.termsheet?.columns?.find((col) => {
                const name = col.clause?.displayName || col.clause?.clauseName;
                return name === label;
              })?.clause;
            }

            if (clause?._id) {
              let addedToMatrix = quote.addedClauses?.find(
                (e) =>
                  e.clauseId === clause?._id &&
                  quote.termsheet?.addedClauses?.find(
                    (ac) => ac.clauseId === e.clauseId
                  )
              );

              let found = addedToMatrix ?? {};
              let addedToColumn = addedToMatrix
                ? null
                : quote.termsheet?.columns?.find(
                    (col) => col.clause?._id === clause?._id
                  );

              if (addedToColumn) {
                found.value = addedToColumn.sectionValue;

                found.sectionYearsMonths = addedToColumn.sectionYearsMonths;

                found.langDesc = replaceMergeFields(
                  addedToColumn.langDesc,
                  deal,
                  whoami,
                  borrowerCompany
                );

                if (["$#,###"].includes(addedToColumn.clause?.format)) {
                  found.type = "currency";
                } else if (
                  ["#,###", "#.##x"].includes(addedToColumn.clause?.format)
                ) {
                  found.type = "number";
                } else if (
                  ["#%", "#.#%", "#.##%"].includes(addedToColumn.clause?.format)
                ) {
                  found.type = "percent";
                }
              }

              if (found.value) {
                switch (found.type) {
                  case "currency":
                    listItemElement = (
                      <NumberFormat
                        value={found.value}
                        displayType={"text"}
                        thousandSeparator
                        prefix="$"
                      />
                    );
                    break;
                  case "number":
                    listItemElement = (
                      <NumberFormat
                        value={found.value}
                        displayType={"text"}
                        thousandSeparator
                        decimalScale={found.format === "#,###" ? 0 : 10}
                        suffix={` ${found.sectionYearsMonths}`}
                      />
                    );
                    break;
                  case "percent":
                    listItemElement = (
                      <NumberFormat
                        value={found.value}
                        displayType={"text"}
                        thousandSeparator
                        suffix={`%`}
                      />
                    );
                    break;
                  default:
                    listItemElement = found.value;
                    break;
                }
              }

              if (found.langDesc) {
                listItemElement = (
                  <>
                    {listItemElement}
                    <div style={{ lineHeight: 1.25 }} className="font-14 mt-2">
                      *{extractTextFromHtmlString(found.langDesc)}
                    </div>
                  </>
                );
              }
            }

            return (
              <ListGroupItem
                key={label}
                label={label}
                isLastItem={index === compareQuotes.length - 1}
              >
                {listItemElement}
              </ListGroupItem>
            );
          })}
        </ListGroup>
        {actions}
      </div>
    );
  });

export default CompareQuoteCards;
