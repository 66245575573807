import React from "react";
import { connect } from "react-redux";
import { Button, Form, Row, Col } from "react-bootstrap";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { isEmpty } from "lodash";

import { required, domain, url, noSpaces } from "utils/validation";
import {
  reduxFormInput,
  reduxFormTextarea,
  reduxFormSelect,
  reduxFormNumber,
} from "components/form/ReduxForm";
import { states } from "utils/dataUtil";
import { createCompany } from "redux/actions";
import { addPlacesAutoSuggestionsListener } from "components/google/places";
import Spacer from "components/layout/Spacer";
import Loader from "components/ui/Loader";
import ImageUpload from "components/form/ImageUpload";
import imageCompression from "browser-image-compression";
import queryString from "query-string";
import { getDropdown } from "redux/actions";

const addressFieldsMapping = {
  companyAddress: "address",
  companyCity: "city",
  companyState: "state",
  companyZipCode: "zip",
};

class CompanyCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      compressing: false,
      previewFile: null,
      uploadedFile: null,
      loadedDropdowns: [],
    };

    this.submitFn = this.submitFn.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
  }

  getDropdownValues = (type) => {
    const { dropdowns } = this.props;
    if (isEmpty(dropdowns[type])) {
      return [];
    }
    return dropdowns[type];
  };

  getDropdownFromAPI = (value) => {
    let canGet = false;

    if (this.state.loadedDropdowns.length > 0) {
      this.state.loadedDropdowns.some((dropdown) => {
        if (dropdown === value) {
          canGet = false;

          return true;
        } else {
          canGet = true;

          return false;
        }
      });
    } else {
      getDropdown(value);

      this.setState((prevState) => ({
        loadedDropdowns: [...prevState.loadedDropdowns, value],
      }));
    }

    if (canGet) {
      getDropdown(value);

      this.setState((prevState) => ({
        loadedDropdowns: [...prevState.loadedDropdowns, value],
      }));
    }
  };

  componentDidMount() {
    addPlacesAutoSuggestionsListener(
      "company-address-field",
      addressFieldsMapping,
      (data) => {
        Object.keys(data).map((key) => {
          if (key === "companyState") {
            this.props.change(key, {
              label: data[key].name,
              value: data[key].nick_name.toUpperCase(),
            });
          } else {
            this.props.change(key, data[key]);
          }

          return true;
        });
      }
    );
    const { type } = queryString.parse(this.props.location.search);
    if (type) {
      this.props.change("companyType", { label: type, value: type });
    }
  }

  submitFn(data) {
    const { whoami } = this.props;
    const formData = new FormData();
    const { type } = queryString.parse(this.props.location.search);

    for (let property in data) {
      if (typeof data[property] === "object" && data[property] !== null) {
        data[property] = data[property].value;
      } else if (data[property] === null) {
        data[property] = "";
      }

      formData.append(property, data[property]);
    }

    formData.append("companyLogo", this.state.uploadedFile);
    formData.append("userId", whoami._id);
    formData.append("isSuperAdmin", Boolean(whoami.role === "Admin"));
    formData.append("pageOrigin", this.props.pageOrigin);
    formData.append(
      "teamName",
      whoami.firstName + " " + whoami.lastName + "'s Team"
    );

    createCompany(formData);
  }

  async handleFileChange(file) {
    this.setState({
      previewFile: URL.createObjectURL(file),
    });

    const options = {
      maxSizeMB: 0.75,
    };

    try {
      this.setState({ compressing: true });
      const compressedFile = await imageCompression(file, options);

      this.setState({
        uploadedFile: compressedFile,
        compressing: false,
      });
    } catch (error) {
      return error;
    }
  }

  render() {
    const { handleSubmit, action, selectedCompanyType, selectedLenderType } =
      this.props;
    const { isFetching } = action.CREATE_COMPANY;

    if (!isFetching) {
      const disableChrome = document.querySelectorAll(
        'input[autocomplete="off"'
      );

      for (let el of disableChrome) {
        if (el !== null) {
          el.setAttribute("autocomplete", "new-password");
        }
      }
    }

    return (
      <>
        {isFetching && <Loader />}
        {this.state.compressing && <Loader files />}
        <Spacer size={50} />
        <Form className="full-width no-border">
          <Row>
            <Col xs="12">
              <h3 className="bold">Basic Info</h3>
              <Spacer size={30} />
            </Col>
          </Row>
          <Row>
            <Col xs="12" sm="6">
              <Field
                className="form-control"
                component={reduxFormInput}
                validate={[required, noSpaces]}
                name="companyName"
                type="text"
                placeholder="Enter company name"
                label="*Company name"
              />
              <Spacer size={20} />
              <Field
                className="form-control"
                component={reduxFormInput}
                validate={[url, noSpaces]}
                name="companyWebsiteURL"
                type="text"
                placeholder="Enter company website"
                label="Company website"
              />
              <Spacer size={20} />
              {/* <Field
                className="form-control"
                component={reduxFormInput}
                validate={[required, domain, noSpaces]}
                name="companyDomain"
                type="text"
                placeholder="Enter company domain"
                label="*Company domain"
              />
              <Spacer size={20} /> */}
              <Field
                className="form-control"
                component={reduxFormSelect}
                validate={[required]}
                name="companyType"
                type="select"
                options={[
                  { label: "Lender", value: "Lender" },
                  { label: "Borrower", value: "Borrower" },
                ]}
                placeholder="Select company type"
                label="*Company type"
              />
              <Spacer size={20} />
              {selectedCompanyType.value === "Lender" && (
                <>
                  <Field
                    className="form-control"
                    component={reduxFormSelect}
                    validate={[required]}
                    name="lenderType"
                    type="select"
                    options={this.getDropdownValues("lenderType")}
                    isLoading={action.GET_DROPDOWNS.isFetching}
                    onFocus={() => this.getDropdownFromAPI("lenderType")}
                    placeholder="Select lender type"
                    label="*Lender Type"
                  />
                  <Spacer size={20} />
                  {selectedLenderType.value === "Other" && (
                    <Field
                      className="form-control"
                      component={reduxFormInput}
                      validate={[noSpaces, required]}
                      name="otherLenderType"
                      type="text"
                      placeholder="Other (Lender Type)"
                      label="*Other (Lender Type)"
                    />
                  )}
                  <Spacer size={20} />
                </>
              )}
            </Col>
            <Col xs="12" sm="6">
              <Field
                component={ImageUpload}
                name="companyLogo"
                uploadButtonText="Upload Logo"
                src={
                  this.state.previewFile !== null
                    ? this.state.previewFile
                    : null
                }
                onChange={this.handleFileChange}
              />
              <Spacer size={20} />
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <Field
                className="form-control"
                component={reduxFormTextarea}
                // validate={[required, noSpaces]}
                name="companyBio"
                rows="10"
                placeholder="Enter company bio"
                label="Company Bio"
              />
              <Spacer size={20} />
            </Col>
            <Col xs="12" sm="4">
              <Field
                className="form-control"
                component={reduxFormInput}
                validate={[noSpaces]}
                name="companyLinkedin"
                type="text"
                placeholder="Enter LinkedIn URL"
                label="LinkedIn URL"
              />
              <Spacer size={10} />
            </Col>
            <Col className="border-bottom" xs="12">
              <Spacer size={20} />
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <Spacer size={20} />
              <h3 className="bold">Address Info</h3>
              <Spacer size={30} />
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <Field
                className="form-control"
                component={reduxFormInput}
                validate={[noSpaces]}
                name="companyAddress"
                type="text"
                placeholder="Enter address"
                label="Address"
                id="company-address-field"
              />
              <Spacer size={20} />
            </Col>
            <Col xs="12">
              <Field
                className="form-control"
                component={reduxFormInput}
                validate={[noSpaces]}
                name="companyAddress2"
                type="text"
                placeholder="Enter address"
                label="Address 2"
              />
              <Spacer size={20} />
            </Col>
            <Col xs="12" sm="6">
              <Field
                className="form-control"
                component={reduxFormInput}
                validate={[noSpaces]}
                name="companyCity"
                type="text"
                placeholder="Enter city"
                label="City"
              />
              <Spacer size={20} />
            </Col>
            <Col xs="12" sm="6">
              <Field
                className="form-control"
                component={reduxFormSelect}
                // validate={[required]}
                name="companyState"
                type="select"
                options={states}
                placeholder="Pick state"
                label="State"
              />
              <Spacer size={20} />
            </Col>
            <Col xs="12" sm="6">
              <Field
                className="form-control"
                component={reduxFormNumber}
                // validate={[required]}
                name="companyZipCode"
                type="text"
                placeholder="Enter zipcode"
                thousandSeparator={false}
                label="Zipcode"
              />
              <Spacer size={20} />
            </Col>
          </Row>
          <Spacer size={50} />
          <Row className="justify-content-center">
            <Col className="text-center" xs="12" sm="6">
              <Button variant="primary" onClick={handleSubmit(this.submitFn)}>
                Create
              </Button>
            </Col>
          </Row>
        </Form>
        <Spacer size={50} />
      </>
    );
  }
}

const selector = formValueSelector("CreateCompany");

function mapStateToProps(state) {
  const { role } = state.auth.whoami;

  return {
    action: state.action,
    whoami: state.auth.whoami,
    initialValues: {
      companyType:
        role && role !== "Admin"
          ? { label: role, value: role }
          : "Enter company type",
    },
    selectedCompanyType: selector(state, "companyType") || {},
    selectedLenderType: selector(state, "lenderType") || {},
    dropdowns: state.program.dropdowns,
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "CreateCompany",
    enableReinitialize: true,
  })(CompanyCreate)
);
