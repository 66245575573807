import React from "react";
import { connect } from "react-redux";
import { OverlayTrigger, Tooltip, Button, Row, Col } from "react-bootstrap";
import { removeManager } from "redux/actions";
import { reset } from "redux-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Spacer from "components/layout/Spacer";
import PopupPrompt from "components/ui/PopupPrompt";
import ManagerCreate from "containers/company/ManagerCreate";

class CompanyManagers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPrompt: false,
      selectedManager: {},
      isEdit: false
    };

    this.handleShowPrompt = this.handleShowPrompt.bind(this);
  }

  handleShowPrompt(showPrompt) {
    this.setState({ showPrompt });
  }

  editManager = (manager) => {
    this.setState({
      isEdit: true,
      selectedManager: manager,
      showPrompt: true
    })
  }

  onSubmit = () => {
      this.props.dispatch(reset("AddManager"));
      this.setState({
        isEdit: false,
        selectedManager: {},
        showPrompt: false
      })

  }
  render() {
    const { company, isCompanyAdmin, dispatch } = this.props;

    const { managers } = company;
    const {isEdit, selectedManager} = this.state;

    return (
      <>
        <PopupPrompt
          promptTitle={isEdit ? 'Edit Manager' : 'Add Manager'}
          promptBody={
            <ManagerCreate
              isCompanyAdmin={isCompanyAdmin}
              companyId={company._id}
              managers={managers}
              selectedManager={selectedManager}
              isEdit={isEdit}
              onSubmit={this.onSubmit}
            />
          }
          show={this.state.showPrompt}
          onCancel={() => {
            this.handleShowPrompt(false);
            dispatch(reset("AddManager"));
          }}
          onHide={() => {
            this.handleShowPrompt(false);
            dispatch(reset("AddManager"));
          }}
        />
        <Row>
          <Col xs="12">
            <Spacer size={30} />
          </Col>
          <Col className="align-self-end" xs="6">
            <h3 className="bold">Managers</h3>
          </Col>
          <Col className="text-right" xs="6">
            <OverlayTrigger
              placement="left"
              overlay={<Tooltip id={`tooltip-left`}>Add Manager</Tooltip>}
            >
              <Button
                className="mr-2 pl-3 pt-2 pr-3 pb-2"
                onClick={() => {
                  this.handleShowPrompt(true);
                }}
              >
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            </OverlayTrigger>
          </Col>
          <Col xs="12">
            <div className="border-top"></div>
            <Spacer size={30} />
          </Col>
          {managers && managers.length === 0 ? (
            <>
              <Col xs="12">
                <p className="text-danger">
                  Your company doesn't have any managers yet!
                </p>
                <Spacer size={10} />
              </Col>
            </>
          ) : (
              managers.map((manager, idx) => {
                return (
                  <Col xs="12" sm="4" key={idx}>
                    <div
                      className="panel"
                      onClick={(e) => {
                        if (e.target.parentNode.lastChild.hasAttribute("style")) {
                          e.target.parentNode.lastChild.removeAttribute("style");
                        } else {
                          e.target.parentNode.lastChild.style.display = "block";
                        }
                      }}
                    >
                      <span className="font-14">
                        {manager.managementCompanyName}
                      </span>
                    <div className="panel-controls">
                      <div className="d-flex">
                        <Button
                          className="font-12 fake-link mr-3"
                          variant="fake-link"
                          onClick={() => {
                            removeManager(company._id, manager._id);
                          }}
                        >
                          Delete
                      </Button>
                      <Button
                          className="font-12 fake-link"
                          variant="fake-link"
                          onClick={() => {
                            this.editManager(manager);
                          }}
                        >
                          Edit
                      </Button>
                      </div>
                    </div>
                    </div>
                    <Spacer size={20} />
                  </Col>
                );
              })
            )}
          <Col xs="12">
            <Spacer size={20} />
          </Col>
        </Row>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    company: state.company.company,
  };
}

export default connect(mapStateToProps)(CompanyManagers);
