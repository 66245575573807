import React from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { reduxFormInput } from "components/form/ReduxForm";
import Spacer from "components/layout/Spacer";
import { Field, reduxForm } from "redux-form";
import { noSpaces, required } from "utils/validation";
import { connect } from "react-redux";

const EditDisplayName = ({ handleSubmit, onSubmit, onCancel }) => {
  const submitFn = (data) => {
    onSubmit(data);
  };

  return (
    <Form className="no-border" onSubmit={handleSubmit(submitFn)}>
      <Row>
        <Col>
          <Field
            className="form-control"
            component={reduxFormInput}
            validate={[required, noSpaces]}
            name="displayName"
            type="text"
            placeholder="Enter Clause Display Name"
            label="*Clause Display Name"
          />
        </Col>
      </Row>
      <Spacer size={20} />
      <Row>
        <Col className="text-right">
          <Button variant="dark" onClick={onCancel}>
            Cancel
          </Button>
          &nbsp;
          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

function mapStateToProps(state, props) {
  return {
    initialValues: {
      displayName: props.displayName,
    },
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "EditDisplayName",
    enableReinitialize: true,
  })(EditDisplayName)
);
