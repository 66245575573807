import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";

import { Row, Col, Image, Spinner } from "react-bootstrap";
import CompanyPic from "images/company-placeholder.png";
import { NOTIFICATION_TEMPLATE, titleMap } from "./NotificationTemplates";
// import { NOTIFICATIONS_SAMPLE } from "./sampleData";
import Socket from "utils/Socket";
import moment from "moment";
import { isEmpty } from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  getNotifications,
  markNotification,
  NOTIFICATION_LIMIT,
} from "redux/actions";

const noop = () => null;

const NOTIFICATION_LOADER = () => {
  return (
    <div className="no-notifications">
      <Spinner animation="border" variant="primary" />
    </div>
  );
};
const NotificationLayout = ({
  notification: { notificationId = {}, read, _id, createdAt },
  whoami,
  index,
}) => {
  const { action, senderCompany = {} } = notificationId || {};
  const { companyName, companyLogo } = senderCompany;
  const renderTitle = titleMap[action] || noop;

  const title = renderTitle({ ...notificationId, whoami });
  // const titleData = title(notificationId);
  const handleCheck = () => {
    markNotification(_id, index);
    Socket.send("NOTIFICATIONS", {
      action: "READ",
      id: _id,
    });
  };
  const Content = NOTIFICATION_TEMPLATE[action] || noop;
  return (
    <div className="pt-2 notification-card">
      <Row className="w-100" noGutters={true}>
        <Col className="d-flex align-items-center gap-2" xs="12" sm="12">
          <Image
            style={{ alignSelf: "flex-start" }}
            className="d-inline-block mobile-img mr-2"
            src={senderCompany && companyLogo ? companyLogo : CompanyPic}
            alt={companyName + " company picture."}
            width="50px"
            height="50px"
          />
          <div className="notification-section">
            <div className="notification-header">
              <div className="notification-title d-flex justify-content-between align-items-center">
                <div>
                  <strong>{title}</strong>
                  &nbsp;&nbsp;&nbsp;
                  {createdAt ? (
                    <span>{moment(createdAt).fromNow()}</span>
                  ) : null}
                </div>
                <div className="ml-5">
                  <input
                    type="checkbox"
                    className="cursor-pointer"
                    checked={read}
                    disabled={read}
                    onChange={handleCheck}
                  />
                </div>
              </div>
            </div>
            <Content notification={notificationId} whoami={whoami} />
          </div>
        </Col>
      </Row>
    </div>
  );
};

const Notifications = (props) => {
  const action = useSelector((state) => state.action);
  const whoami = useSelector((state) => state.auth.whoami);
  const notificationCount = useSelector(
    (state) => state.notificationsData.totalNotifications
  );
  const notifications = useSelector(
    (state) => state.notificationsData.notifications
  );
  // const labels = useRef([]);
  let labels = [];
  const [hasMore, sethasMore] = useState(true);
  const today = new Date();
  const fetchMoreData = () => {
    if (notifications.length >= notificationCount) {
      sethasMore(false);
      return;
    }
    const page = notifications.length / NOTIFICATION_LIMIT + 1;
    const read = props.activeTab !== "new";
    getNotifications(page, read);
  };

  if (action.GET_NOTIFICATIONS.isFetching & isEmpty(notifications)) {
    return <NOTIFICATION_LOADER />;
  }
  if (!notifications || isEmpty(notifications)) {
    return <div className="no-notifications">"No Notifications found"</div>;
  }
  const renderLabel = (createdAt) => {
    const difference = moment(today).diff(moment(createdAt), "days");
    // console.log("diff", difference, createdAt, today);
    if (difference === 0 && !labels.includes("TODAY")) {
      labels = [...labels, "TODAY"];
      return <div className="notification-date-label">TODAY</div>;
    }
    if (difference === 1 && !labels.includes("YESTERDAY")) {
      labels = [...labels, "YESTERDAY"];
      return <div className="notification-date-label">YESTERDAY</div>;
    }
    if (difference > 1 && !labels.includes("OLDER")) {
      // console.log("if condiion added");
      labels = [...labels, "OLDER"];
      return <div className="notification-date-label">OLDER</div>;
    }
  };
  return (
    <div>
      <>
        <InfiniteScroll
          dataLength={notifications.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={<NOTIFICATION_LOADER />}
          height={400}
          endMessage={<p style={{ textAlign: "center" }}></p>}
        >
          {/* <h4 className="notification-section-title">Today</h4> */}

          {notifications?.map((notification, index) => {
            const { createdAt } = notification;
            return (
              <div>
                {renderLabel(createdAt)}
                <NotificationLayout
                  notification={notification}
                  index={index}
                  whoami={whoami}
                />
              </div>
            );
          })}
        </InfiniteScroll>
      </>
    </div>
  );
};
export default Notifications;
