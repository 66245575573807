import { all, takeEvery } from "redux-saga/effects";
import { sagaFn as defaultSaga } from "./createSaga";

import { sagaActionTypes } from "redux/actions";

function createSaga(sagaFn) {
  return function*(type) {
    yield takeEvery(type, sagaFn);
  };
}

export default function* rootSaga() {
  yield all([
    ...Object.values(sagaActionTypes).map(createSaga(defaultSaga))
  ]);
}
