import React from "react";
import { renderToString } from "react-dom/server";
import moment from "moment";
import { saveAs } from "file-saver";
import {
  Packer,
  Document,
  Paragraph,
  Table,
  TableCell,
  TableRow,
  WidthType,
  HeightRule,
  AlignmentType,
  BorderStyle,
  TextRun,
  ShadingType,
  SectionType,
  PageOrientation,
  ImageRun,
} from "docx";
import {
  getIndexAsOfLabel,
  getInitials,
  getSpreadTextValue,
  hasQuoteSpread,
} from "utils/commonUtils";
import NumberFormat from "react-number-format";
import { toast } from "react-toastify";
import { injectPrimaryClause } from "containers/quotes/quotesConfig";
import {
  generateAddeClauses,
  generateAddendum,
  generateEmptyRows,
  generateManyQuoteMatrix,
  generateNoMatrix,
  isOpeningParagraph,
  renderColumn,
} from "utils/termsheetUtils";

const downloadDocx = async (props) => {
  const { termsheet, deal, download = true } = props;
  const columns = injectPrimaryClause(termsheet.columns);
  const borrowerCompany = props.borrowerCompany || deal?.company;
  const teamLead = props.teamLead || getTeamLead(termsheet);
  const hasMatrix = termsheet.quotes?.length > 1;
  const [firstQuote = {}] = termsheet.quotes || [];
  const hasLendersLogo = !!termsheet.company?.companyLogoXS;

  const isSpread = hasQuoteSpread(termsheet.quotes);

  const lendersLogoBlob = hasLendersLogo
    ? await fetch(termsheet.company?.companyLogoXS).then((r) => r.blob())
    : null;

  const generateQuoteMatrix = () => {
    if (!termsheet.quotes?.length || termsheet.quotes?.length <= 1) {
      return [];
    }
    const top2Quotes = termsheet.quotes.slice(0, 2);
    return [
      hasMatrix
        ? new TableRow({
            children: [
              new TableCell({
                columnSpan: 4,
                children: [
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                        text: "Quote Matrix",
                        bold: true,
                      }),
                    ],
                  }),
                ],
              }),
            ],
          })
        : new TableRow({
            children: [
              new TableCell({
                columnSpan: 1,
                children: [new Paragraph("")],
              }),
              new TableCell({
                columnSpan: 1,
                children: [
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                        text: "Quote Matrix",
                        bold: true,
                      }),
                    ],
                  }),
                ],
              }),
            ],
          }),

      new TableRow({
        children: [
          new TableCell({
            columnSpan: 1,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Loan Amount",
                    bold: true,
                  }),
                ],
              }),
            ],
          }),
          ...top2Quotes.map(
            (quote) =>
              new TableCell({
                columnSpan: 1,
                margins: {
                  top: 100,
                  right: 200,
                  left: 200,
                  marginUnitType: WidthType.DXA,
                },
                borders: {
                  top: {
                    size: 1,
                    style: BorderStyle.SINGLE,
                    color: "#d3d3d3",
                  },
                  right: {
                    size: 1,
                    style: BorderStyle.SINGLE,
                    color: "#d3d3d3",
                  },
                  bottom: {
                    size: 1,
                    style: BorderStyle.SINGLE,
                    color: "#d3d3d3",
                  },
                  left: {
                    size: 1,
                    style: BorderStyle.SINGLE,
                    color: "#d3d3d3",
                  },
                },
                children: [
                  new Paragraph(
                    renderToString(
                      <NumberFormat
                        value={quote.loanAmount}
                        displayType={"text"}
                        thousandSeparator
                        prefix="$"
                        renderText={(formattedValue) => formattedValue}
                      />
                    )
                  ),
                ],
              })
          ),
        ],
      }),

      new TableRow({
        children: [
          new TableCell({
            columnSpan: 1,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Term",
                    bold: true,
                  }),
                ],
              }),
            ],
          }),
          ...top2Quotes.map(
            (quote) =>
              new TableCell({
                columnSpan: 1,
                margins: {
                  top: 100,
                  right: 200,
                  left: 200,
                  marginUnitType: WidthType.DXA,
                },
                borders: {
                  top: {
                    size: 1,
                    style: BorderStyle.SINGLE,
                    color: "#d3d3d3",
                  },
                  right: {
                    size: 1,
                    style: BorderStyle.SINGLE,
                    color: "#d3d3d3",
                  },
                  bottom: {
                    size: 1,
                    style: BorderStyle.SINGLE,
                    color: "#d3d3d3",
                  },
                  left: {
                    size: 1,
                    style: BorderStyle.SINGLE,
                    color: "#d3d3d3",
                  },
                },
                children: [
                  new Paragraph(
                    renderToString(
                      <NumberFormat
                        value={quote.term}
                        displayType={"text"}
                        thousandSeparator
                        suffix={` ${quote.termMetric}`}
                        renderText={(formattedValue) => formattedValue}
                      />
                    )
                  ),
                ],
              })
          ),
        ],
      }),

      new TableRow({
        children: [
          new TableCell({
            columnSpan: 1,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Amortization",
                    bold: true,
                  }),
                ],
              }),
            ],
          }),
          ...top2Quotes.map(
            (quote) =>
              new TableCell({
                columnSpan: 1,
                margins: {
                  top: 100,
                  right: 200,
                  left: 200,
                  marginUnitType: WidthType.DXA,
                },
                borders: {
                  top: {
                    size: 1,
                    style: BorderStyle.SINGLE,
                    color: "#d3d3d3",
                  },
                  right: {
                    size: 1,
                    style: BorderStyle.SINGLE,
                    color: "#d3d3d3",
                  },
                  bottom: {
                    size: 1,
                    style: BorderStyle.SINGLE,
                    color: "#d3d3d3",
                  },
                  left: {
                    size: 1,
                    style: BorderStyle.SINGLE,
                    color: "#d3d3d3",
                  },
                },
                children: [
                  new Paragraph(
                    renderToString(
                      <NumberFormat
                        value={quote.amortization}
                        displayType={"text"}
                        thousandSeparator
                        suffix={` ${quote.amortizationMetric}`}
                        renderText={(formattedValue) => formattedValue}
                      />
                    )
                  ),
                ],
              })
          ),
        ],
      }),

      new TableRow({
        children: [
          new TableCell({
            columnSpan: 1,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "IO Period",
                    bold: true,
                  }),
                ],
              }),
            ],
          }),
          ...top2Quotes.map(
            (quote) =>
              new TableCell({
                columnSpan: 1,
                margins: {
                  top: 100,
                  right: 200,
                  left: 200,
                  marginUnitType: WidthType.DXA,
                },
                borders: {
                  top: {
                    size: 1,
                    style: BorderStyle.SINGLE,
                    color: "#d3d3d3",
                  },
                  right: {
                    size: 1,
                    style: BorderStyle.SINGLE,
                    color: "#d3d3d3",
                  },
                  bottom: {
                    size: 1,
                    style: BorderStyle.SINGLE,
                    color: "#d3d3d3",
                  },
                  left: {
                    size: 1,
                    style: BorderStyle.SINGLE,
                    color: "#d3d3d3",
                  },
                },
                children: [
                  new Paragraph(
                    renderToString(
                      <NumberFormat
                        value={quote.IOPeriod}
                        displayType={"text"}
                        suffix={` ${quote.IOPeriodMetric}`}
                        thousandSeparator
                        renderText={(formattedValue) => formattedValue}
                      />
                    )
                  ),
                ],
              })
          ),
        ],
      }),

      // Spread
      isSpread
        ? new TableRow({
            children: [
              new TableCell({
                columnSpan: 1,
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: "Spread",
                        bold: true,
                      }),
                    ],
                  }),
                ],
              }),
              ...top2Quotes.map(
                (quote) =>
                  new TableCell({
                    columnSpan: 1,
                    margins: {
                      top: 100,
                      right: 200,
                      left: 200,
                      marginUnitType: WidthType.DXA,
                    },
                    borders: {
                      top: {
                        size: 1,
                        style: BorderStyle.SINGLE,
                        color: "#d3d3d3",
                      },
                      right: {
                        size: 1,
                        style: BorderStyle.SINGLE,
                        color: "#d3d3d3",
                      },
                      bottom: {
                        size: 1,
                        style: BorderStyle.SINGLE,
                        color: "#d3d3d3",
                      },
                      left: {
                        size: 1,
                        style: BorderStyle.SINGLE,
                        color: "#d3d3d3",
                      },
                    },
                    children: [new Paragraph(getSpreadTextValue(quote))],
                  })
              ),
            ],
          })
        : null,

      // Rate
      isSpread
        ? null
        : new TableRow({
            children: [
              new TableCell({
                columnSpan: 1,
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: "Rate",
                        bold: true,
                      }),
                    ],
                  }),
                ],
              }),
              ...top2Quotes.map(
                (quote) =>
                  new TableCell({
                    columnSpan: 1,
                    margins: {
                      top: 100,
                      right: 200,
                      left: 200,
                      marginUnitType: WidthType.DXA,
                    },
                    borders: {
                      top: {
                        size: 1,
                        style: BorderStyle.SINGLE,
                        color: "#d3d3d3",
                      },
                      right: {
                        size: 1,
                        style: BorderStyle.SINGLE,
                        color: "#d3d3d3",
                      },
                      bottom: {
                        size: 1,
                        style: BorderStyle.SINGLE,
                        color: "#d3d3d3",
                      },
                      left: {
                        size: 1,
                        style: BorderStyle.SINGLE,
                        color: "#d3d3d3",
                      },
                    },
                    children: [
                      new Paragraph(
                        renderToString(
                          <NumberFormat
                            value={quote.rate}
                            displayType={"text"}
                            thousandSeparator
                            suffix={`%`}
                            renderText={(formattedValue) => formattedValue}
                          />
                        )
                      ),
                    ],
                  })
              ),
            ],
          }),

      // Floor
      isSpread
        ? new TableRow({
            children: [
              new TableCell({
                columnSpan: 1,
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: "Floor",
                        bold: true,
                      }),
                    ],
                  }),
                ],
              }),
              ...top2Quotes.map(
                (quote) =>
                  new TableCell({
                    columnSpan: 1,
                    margins: {
                      top: 100,
                      right: 200,
                      left: 200,
                      marginUnitType: WidthType.DXA,
                    },
                    borders: {
                      top: {
                        size: 1,
                        style: BorderStyle.SINGLE,
                        color: "#d3d3d3",
                      },
                      right: {
                        size: 1,
                        style: BorderStyle.SINGLE,
                        color: "#d3d3d3",
                      },
                      bottom: {
                        size: 1,
                        style: BorderStyle.SINGLE,
                        color: "#d3d3d3",
                      },
                      left: {
                        size: 1,
                        style: BorderStyle.SINGLE,
                        color: "#d3d3d3",
                      },
                    },
                    children: [
                      new Paragraph(
                        quote.floorRate
                          ? renderToString(
                              <NumberFormat
                                value={quote.floorRate}
                                displayType={"text"}
                                thousandSeparator
                                suffix={`% ${quote.floorOption}`}
                                decimalScale={2}
                                fixedDecimalScale
                                renderText={(formattedValue) => formattedValue}
                              />
                            )
                          : quote.floorOption
                      ),
                    ],
                  })
              ),
            ],
          })
        : null,

      // getIndexAsOfLabel: indexRate
      isSpread
        ? new TableRow({
            children: [
              new TableCell({
                columnSpan: 1,
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: getIndexAsOfLabel(),
                        bold: true,
                      }),
                    ],
                  }),
                ],
              }),
              ...top2Quotes.map(
                (quote) =>
                  new TableCell({
                    columnSpan: 1,
                    margins: {
                      top: 100,
                      right: 200,
                      left: 200,
                      marginUnitType: WidthType.DXA,
                    },
                    borders: {
                      top: {
                        size: 1,
                        style: BorderStyle.SINGLE,
                        color: "#d3d3d3",
                      },
                      right: {
                        size: 1,
                        style: BorderStyle.SINGLE,
                        color: "#d3d3d3",
                      },
                      bottom: {
                        size: 1,
                        style: BorderStyle.SINGLE,
                        color: "#d3d3d3",
                      },
                      left: {
                        size: 1,
                        style: BorderStyle.SINGLE,
                        color: "#d3d3d3",
                      },
                    },
                    children: [
                      new Paragraph(
                        renderToString(
                          <NumberFormat
                            value={quote.indexRate || 0}
                            displayType={"text"}
                            thousandSeparator
                            suffix={`%`}
                            decimalScale={2}
                            fixedDecimalScale
                            renderText={(formattedValue) => formattedValue}
                          />
                        )
                      ),
                    ],
                  })
              ),
            ],
          })
        : null,

      // Quoted Rate
      new TableRow({
        children: [
          new TableCell({
            columnSpan: 1,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Quoted Rate",
                    bold: true,
                  }),
                ],
              }),
            ],
          }),
          ...top2Quotes.map(
            (quote) =>
              new TableCell({
                columnSpan: 1,
                margins: {
                  top: 100,
                  right: 200,
                  left: 200,
                  marginUnitType: WidthType.DXA,
                },
                borders: {
                  top: {
                    size: 1,
                    style: BorderStyle.SINGLE,
                    color: "#d3d3d3",
                  },
                  right: {
                    size: 1,
                    style: BorderStyle.SINGLE,
                    color: "#d3d3d3",
                  },
                  bottom: {
                    size: 1,
                    style: BorderStyle.SINGLE,
                    color: "#d3d3d3",
                  },
                  left: {
                    size: 1,
                    style: BorderStyle.SINGLE,
                    color: "#d3d3d3",
                  },
                },
                children: [
                  new Paragraph(
                    renderToString(
                      <NumberFormat
                        value={quote.quotedRate}
                        displayType={"text"}
                        thousandSeparator
                        suffix={`%`}
                        decimalScale={2}
                        fixedDecimalScale
                        renderText={(formattedValue) => formattedValue}
                      />
                    )
                  ),
                ],
              })
          ),
        ],
      }),

      // addedClauses
      ...generateAddeClauses({
        addedClauses: termsheet.addedClauses,
        quotes: top2Quotes,
      }),

      ...generateEmptyRows(1),
    ].filter((row) => row);
  };

  const sections = [
    {
      properties: {
        page: {
          size: {
            width: 12240,
            height: 15840,
            orientation: PageOrientation.PORTRAIT,
          },
          margin: {
            top: 720,
            right: 720,
            bottom: 720,
            left: 720,
          },
        },
      },
      children: [
        new Table({
          width: {
            size: 100,
            type: WidthType.PERCENTAGE,
          },
          borders: {
            top: {
              size: 0,
            },
            right: {
              size: 0,
            },
            bottom: {
              size: 0,
            },
            left: {
              size: 0,
            },
            insideHorizontal: {
              size: 0,
            },
            insideVertical: {
              size: 0,
            },
          },
          rows: [
            new TableRow({
              height: {
                rule: HeightRule.EXACT,
                value: 1,
              },
              children: [
                new TableCell({
                  width: {
                    size: 25,
                    type: WidthType.PERCENTAGE,
                  },
                  children: [],
                }),
                new TableCell({
                  width: {
                    size: 25,
                    type: WidthType.PERCENTAGE,
                  },
                  children: [],
                }),
                new TableCell({
                  width: {
                    size: 25,
                    type: WidthType.PERCENTAGE,
                  },
                  children: [],
                }),
                new TableCell({
                  width: {
                    size: 25,
                    type: WidthType.PERCENTAGE,
                  },
                  children: [],
                }),
              ],
            }),

            new TableRow({
              children: [
                new TableCell({
                  columnSpan: 2,
                  margins: {
                    right: 200,
                    marginUnitType: WidthType.DXA,
                  },
                  children: [
                    new Paragraph({
                      alignment: AlignmentType.END,
                      children: hasLendersLogo
                        ? [
                            new ImageRun({
                              data: lendersLogoBlob,
                              transformation: {
                                width: 40,
                                height: 40,
                              },
                            }),
                          ]
                        : [
                            new TextRun({
                              text: getInitials(termsheet.company?.companyName),
                              bold: true,
                              size: 40,
                              shading: {
                                type: ShadingType.SOLID,
                                color: "0a467f",
                                fill: "ffffff",
                              },
                            }),
                          ],
                    }),
                  ],
                }),
                new TableCell({
                  columnSpan: 2,
                  children: [
                    new Paragraph({
                      alignment: AlignmentType.START,
                      spacing: {
                        before: 100,
                      },
                      children: [
                        new TextRun({
                          text: termsheet.company?.companyName,
                        }),
                      ],
                    }),
                  ],
                }),
              ],
            }),

            ...generateEmptyRows(2),

            new TableRow({
              children: [
                new TableCell({
                  columnSpan: 4,
                  children: [
                    new Paragraph(
                      `Issue Date: ${moment(termsheet.createdAt).format("LL")}`
                    ),
                  ],
                }),
              ],
            }),

            ...generateEmptyRows(2),

            new TableRow({
              children: [
                new TableCell({
                  columnSpan: 4,
                  children: [new Paragraph(borrowerCompany?.companyName)],
                }),
              ],
            }),
            new TableRow({
              children: [
                new TableCell({
                  columnSpan: 4,
                  children: [new Paragraph(borrowerCompany?.companyAddress)],
                }),
              ],
            }),
            new TableRow({
              children: [
                new TableCell({
                  columnSpan: 4,
                  children: [new Paragraph(borrowerCompany?.companyAddress2)],
                }),
              ],
            }),
            new TableRow({
              children: [
                new TableCell({
                  columnSpan: 4,
                  children: [
                    new Paragraph(
                      `${borrowerCompany?.companyCity}, ${borrowerCompany?.companyState}, ${borrowerCompany?.companyZipCode}`
                    ),
                  ],
                }),
              ],
            }),

            ...generateEmptyRows(2),

            new TableRow({
              children: [
                new TableCell({
                  columnSpan: 4,
                  children: [
                    new Paragraph(
                      `RE: Financing for ${deal?.propertyName} located at ${deal?.propertyAddress}, ${deal?.propertyCity}, ${deal?.propertyState}, ${deal?.propertyZip}`
                    ),
                  ],
                }),
              ],
            }),

            ...generateEmptyRows(2),

            ...columns
              .filter((column) => isOpeningParagraph(column.clause))
              .reduce((acc, column) => {
                // paragraph no header
                return renderColumn(column, acc, {
                  format: column.clause?.format,
                  sectionValue: column.sectionValue,
                  sectionYearsMonths: column.sectionYearsMonths,
                });
              }, [])
              .filter((row) => row),

            // Quote Matrix
            ...generateQuoteMatrix(),

            // Quote Matrix notif
            ...generateManyQuoteMatrix({
              termsheet,
              firstQuote,
              hasMatrix,
              isSpread,
            }),

            // No Quote Matrix
            ...generateNoMatrix({ termsheet, firstQuote, hasMatrix, isSpread }),

            ...columns
              .filter((column) => !isOpeningParagraph(column.clause))
              .filter((column) => (hasMatrix ? true : !column.isPrimary))
              .reduce((acc, column) => {
                // isPrimary column
                if (column.isPrimary) {
                  return renderColumn(column, acc, {
                    format: "Open Text, with header",
                    sectionValue: column.sectionValue,
                    sectionYearsMonths: column.sectionYearsMonths,
                  });
                }
                return renderColumn(column, acc, {
                  format: column.clause?.format,
                  sectionValue: column.sectionValue,
                  sectionYearsMonths: column.sectionYearsMonths,
                });
              }, [])
              .filter((row) => row),

            ...generateEmptyRows(1),

            new TableRow({
              children: [
                new TableCell({
                  columnSpan: 4,
                  children: [new Paragraph("Sincerely,")],
                }),
              ],
            }),

            ...generateEmptyRows(2),

            new TableRow({
              children: [
                new TableCell({
                  columnSpan: 4,
                  children: [
                    new Paragraph(
                      `${teamLead?.firstName} ${teamLead?.lastName}`
                    ),
                  ],
                }),
              ],
            }),
            new TableRow({
              children: [
                new TableCell({
                  columnSpan: 4,
                  children: [new Paragraph(teamLead?.title || "")],
                }),
              ],
            }),
            new TableRow({
              children: [
                new TableCell({
                  columnSpan: 4,
                  children: [new Paragraph(termsheet.company?.companyName)],
                }),
              ],
            }),

            ...generateEmptyRows(1),

            new TableRow({
              children: [
                new TableCell({
                  columnSpan: 4,
                  children: [new Paragraph("Accepted by:")],
                }),
              ],
            }),
            new TableRow({
              children: [
                new TableCell({
                  columnSpan: 4,
                  children: [new Paragraph(borrowerCompany?.companyName)],
                }),
              ],
            }),
            new TableRow({
              children: [
                new TableCell({
                  columnSpan: 1,
                  borders: {
                    bottom: {
                      size: 1,
                      style: BorderStyle.SINGLE,
                      color: "#d3d3d3",
                    },
                  },
                  children: [new Paragraph("")],
                }),
              ],
            }),
            new TableRow({
              children: [
                new TableCell({
                  columnSpan: 4,
                  children: [new Paragraph(`by:`)],
                }),
              ],
            }),
            new TableRow({
              children: [
                new TableCell({
                  columnSpan: 4,
                  children: [new Paragraph(`Its:`)],
                }),
              ],
            }),
          ],
        }),
      ],
    },
  ];

  // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< Addendum >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  generateAddendum({ termsheet, isSpread, sections });

  if (termsheet.quotes?.length > 2) {
    const children = [
      new Table({
        width: {
          size: 100,
          type: WidthType.PERCENTAGE,
        },
        borders: {
          top: {
            size: 0,
          },
          right: {
            size: 0,
          },
          bottom: {
            size: 0,
          },
          left: {
            size: 0,
          },
          insideHorizontal: {
            size: 0,
          },
          insideVertical: {
            size: 0,
          },
        },
        rows: [
          new TableRow({
            height: {
              rule: HeightRule.EXACT,
              value: 1,
            },
            children: [
              new TableCell({
                width: {
                  size: 16,
                  type: WidthType.PERCENTAGE,
                },
                children: [],
              }),
              new TableCell({
                width: {
                  size: 16,
                  type: WidthType.PERCENTAGE,
                },
                children: [],
              }),
              new TableCell({
                width: {
                  size: 16,
                  type: WidthType.PERCENTAGE,
                },
                children: [],
              }),
              new TableCell({
                width: {
                  size: 16,
                  type: WidthType.PERCENTAGE,
                },
                children: [],
              }),
              new TableCell({
                width: {
                  size: 16,
                  type: WidthType.PERCENTAGE,
                },
                children: [],
              }),
              new TableCell({
                width: {
                  size: 16,
                  type: WidthType.PERCENTAGE,
                },
                children: [],
              }),
            ],
          }),

          new TableRow({
            children: [
              new TableCell({
                columnSpan: 1,
                children: [new Paragraph("")],
              }),
              new TableCell({
                columnSpan: 5,
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: "Addendum A:  Quote Matrix",
                        bold: true,
                      }),
                    ],
                  }),
                ],
              }),
            ],
          }),

          ...generateEmptyRows(1, 6),

          new TableRow({
            tableHeader: true,
            children: [
              new TableCell({
                columnSpan: 1,
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: "",
                        bold: true,
                      }),
                    ],
                  }),
                ],
              }),
              ...termsheet.quotes?.map(
                (quote, index) =>
                  new TableCell({
                    columnSpan: 1,
                    margins: {
                      top: 100,
                      right: 200,
                      left: 200,
                      marginUnitType: WidthType.DXA,
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: `Quote ${index + 1}`,
                            bold: true,
                          }),
                        ],
                      }),
                    ],
                  })
              ),
            ],
          }),
          new TableRow({
            children: [
              new TableCell({
                columnSpan: 1,
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: "Loan Amount",
                        bold: true,
                      }),
                    ],
                  }),
                ],
              }),
              ...termsheet.quotes?.map(
                (quote) =>
                  new TableCell({
                    columnSpan: 1,
                    margins: {
                      top: 100,
                      right: 200,
                      left: 200,
                      marginUnitType: WidthType.DXA,
                    },
                    borders: {
                      top: {
                        size: 1,
                        style: BorderStyle.SINGLE,
                        color: "#d3d3d3",
                      },
                      right: {
                        size: 1,
                        style: BorderStyle.SINGLE,
                        color: "#d3d3d3",
                      },
                      bottom: {
                        size: 1,
                        style: BorderStyle.SINGLE,
                        color: "#d3d3d3",
                      },
                      left: {
                        size: 1,
                        style: BorderStyle.SINGLE,
                        color: "#d3d3d3",
                      },
                    },
                    children: [
                      new Paragraph(
                        renderToString(
                          <NumberFormat
                            value={quote.loanAmount}
                            displayType={"text"}
                            thousandSeparator
                            prefix="$"
                            renderText={(formattedValue) => formattedValue}
                          />
                        )
                      ),
                    ],
                  })
              ),
            ],
          }),

          new TableRow({
            children: [
              new TableCell({
                columnSpan: 1,
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: "Term",
                        bold: true,
                      }),
                    ],
                  }),
                ],
              }),
              ...termsheet.quotes?.map(
                (quote) =>
                  new TableCell({
                    columnSpan: 1,
                    margins: {
                      top: 100,
                      right: 200,
                      left: 200,
                      marginUnitType: WidthType.DXA,
                    },
                    borders: {
                      top: {
                        size: 1,
                        style: BorderStyle.SINGLE,
                        color: "#d3d3d3",
                      },
                      right: {
                        size: 1,
                        style: BorderStyle.SINGLE,
                        color: "#d3d3d3",
                      },
                      bottom: {
                        size: 1,
                        style: BorderStyle.SINGLE,
                        color: "#d3d3d3",
                      },
                      left: {
                        size: 1,
                        style: BorderStyle.SINGLE,
                        color: "#d3d3d3",
                      },
                    },
                    children: [
                      new Paragraph(
                        renderToString(
                          <NumberFormat
                            value={quote.term}
                            displayType={"text"}
                            thousandSeparator
                            suffix={` ${quote.termMetric}`}
                            renderText={(formattedValue) => formattedValue}
                          />
                        )
                      ),
                    ],
                  })
              ),
            ],
          }),

          new TableRow({
            children: [
              new TableCell({
                columnSpan: 1,
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: "Amortization",
                        bold: true,
                      }),
                    ],
                  }),
                ],
              }),
              ...termsheet.quotes?.map(
                (quote) =>
                  new TableCell({
                    columnSpan: 1,
                    margins: {
                      top: 100,
                      right: 200,
                      left: 200,
                      marginUnitType: WidthType.DXA,
                    },
                    borders: {
                      top: {
                        size: 1,
                        style: BorderStyle.SINGLE,
                        color: "#d3d3d3",
                      },
                      right: {
                        size: 1,
                        style: BorderStyle.SINGLE,
                        color: "#d3d3d3",
                      },
                      bottom: {
                        size: 1,
                        style: BorderStyle.SINGLE,
                        color: "#d3d3d3",
                      },
                      left: {
                        size: 1,
                        style: BorderStyle.SINGLE,
                        color: "#d3d3d3",
                      },
                    },
                    children: [
                      new Paragraph(
                        renderToString(
                          <NumberFormat
                            value={quote.amortization}
                            displayType={"text"}
                            thousandSeparator
                            suffix={` ${quote.amortizationMetric}`}
                            renderText={(formattedValue) => formattedValue}
                          />
                        )
                      ),
                    ],
                  })
              ),
            ],
          }),

          new TableRow({
            children: [
              new TableCell({
                columnSpan: 1,
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: "IO Period",
                        bold: true,
                      }),
                    ],
                  }),
                ],
              }),
              ...termsheet.quotes?.map(
                (quote) =>
                  new TableCell({
                    columnSpan: 1,
                    margins: {
                      top: 100,
                      right: 200,
                      left: 200,
                      marginUnitType: WidthType.DXA,
                    },
                    borders: {
                      top: {
                        size: 1,
                        style: BorderStyle.SINGLE,
                        color: "#d3d3d3",
                      },
                      right: {
                        size: 1,
                        style: BorderStyle.SINGLE,
                        color: "#d3d3d3",
                      },
                      bottom: {
                        size: 1,
                        style: BorderStyle.SINGLE,
                        color: "#d3d3d3",
                      },
                      left: {
                        size: 1,
                        style: BorderStyle.SINGLE,
                        color: "#d3d3d3",
                      },
                    },
                    children: [
                      new Paragraph(
                        renderToString(
                          <NumberFormat
                            value={quote.IOPeriod}
                            displayType={"text"}
                            suffix={` ${quote.IOPeriodMetric}`}
                            thousandSeparator
                            renderText={(formattedValue) => formattedValue}
                          />
                        )
                      ),
                    ],
                  })
              ),
            ],
          }),

          // Spread
          isSpread
            ? new TableRow({
                children: [
                  new TableCell({
                    columnSpan: 1,
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "Spread",
                            bold: true,
                          }),
                        ],
                      }),
                    ],
                  }),
                  ...termsheet.quotes?.map(
                    (quote) =>
                      new TableCell({
                        columnSpan: 1,
                        margins: {
                          top: 100,
                          right: 200,
                          left: 200,
                          marginUnitType: WidthType.DXA,
                        },
                        borders: {
                          top: {
                            size: 1,
                            style: BorderStyle.SINGLE,
                            color: "#d3d3d3",
                          },
                          right: {
                            size: 1,
                            style: BorderStyle.SINGLE,
                            color: "#d3d3d3",
                          },
                          bottom: {
                            size: 1,
                            style: BorderStyle.SINGLE,
                            color: "#d3d3d3",
                          },
                          left: {
                            size: 1,
                            style: BorderStyle.SINGLE,
                            color: "#d3d3d3",
                          },
                        },
                        children: [new Paragraph(getSpreadTextValue(quote))],
                      })
                  ),
                ],
              })
            : null,

          // Rate
          isSpread
            ? null
            : new TableRow({
                children: [
                  new TableCell({
                    columnSpan: 1,
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "Rate",
                            bold: true,
                          }),
                        ],
                      }),
                    ],
                  }),
                  ...termsheet.quotes?.map(
                    (quote) =>
                      new TableCell({
                        columnSpan: 1,
                        margins: {
                          top: 100,
                          right: 200,
                          left: 200,
                          marginUnitType: WidthType.DXA,
                        },
                        borders: {
                          top: {
                            size: 1,
                            style: BorderStyle.SINGLE,
                            color: "#d3d3d3",
                          },
                          right: {
                            size: 1,
                            style: BorderStyle.SINGLE,
                            color: "#d3d3d3",
                          },
                          bottom: {
                            size: 1,
                            style: BorderStyle.SINGLE,
                            color: "#d3d3d3",
                          },
                          left: {
                            size: 1,
                            style: BorderStyle.SINGLE,
                            color: "#d3d3d3",
                          },
                        },
                        children: [
                          new Paragraph(
                            renderToString(
                              <NumberFormat
                                value={quote.rate}
                                displayType={"text"}
                                thousandSeparator
                                suffix={`%`}
                                decimalScale={2}
                                fixedDecimalScale
                                renderText={(formattedValue) => formattedValue}
                              />
                            )
                          ),
                        ],
                      })
                  ),
                ],
              }),

          // Floor
          isSpread
            ? new TableRow({
                children: [
                  new TableCell({
                    columnSpan: 1,
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "Floor",
                            bold: true,
                          }),
                        ],
                      }),
                    ],
                  }),
                  ...termsheet.quotes?.map(
                    (quote) =>
                      new TableCell({
                        columnSpan: 1,
                        margins: {
                          top: 100,
                          right: 200,
                          left: 200,
                          marginUnitType: WidthType.DXA,
                        },
                        borders: {
                          top: {
                            size: 1,
                            style: BorderStyle.SINGLE,
                            color: "#d3d3d3",
                          },
                          right: {
                            size: 1,
                            style: BorderStyle.SINGLE,
                            color: "#d3d3d3",
                          },
                          bottom: {
                            size: 1,
                            style: BorderStyle.SINGLE,
                            color: "#d3d3d3",
                          },
                          left: {
                            size: 1,
                            style: BorderStyle.SINGLE,
                            color: "#d3d3d3",
                          },
                        },
                        children: [
                          new Paragraph(
                            quote.floorRate
                              ? renderToString(
                                  <NumberFormat
                                    value={quote.floorRate}
                                    displayType={"text"}
                                    thousandSeparator
                                    suffix={`% ${quote.floorOption}`}
                                    renderText={(formattedValue) =>
                                      formattedValue
                                    }
                                  />
                                )
                              : quote.floorOption
                          ),
                        ],
                      })
                  ),
                ],
              })
            : null,

          // Index
          isSpread
            ? new TableRow({
                children: [
                  new TableCell({
                    columnSpan: 1,
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: getIndexAsOfLabel(),
                            bold: true,
                          }),
                        ],
                      }),
                    ],
                  }),
                  ...termsheet.quotes?.map(
                    (quote) =>
                      new TableCell({
                        columnSpan: 1,
                        margins: {
                          top: 100,
                          right: 200,
                          left: 200,
                          marginUnitType: WidthType.DXA,
                        },
                        borders: {
                          top: {
                            size: 1,
                            style: BorderStyle.SINGLE,
                            color: "#d3d3d3",
                          },
                          right: {
                            size: 1,
                            style: BorderStyle.SINGLE,
                            color: "#d3d3d3",
                          },
                          bottom: {
                            size: 1,
                            style: BorderStyle.SINGLE,
                            color: "#d3d3d3",
                          },
                          left: {
                            size: 1,
                            style: BorderStyle.SINGLE,
                            color: "#d3d3d3",
                          },
                        },
                        children: [
                          new Paragraph(
                            renderToString(
                              <NumberFormat
                                value={quote.indexRate || 0}
                                displayType={"text"}
                                thousandSeparator
                                suffix={`%`}
                                renderText={(formattedValue) => formattedValue}
                              />
                            )
                          ),
                        ],
                      })
                  ),
                ],
              })
            : null,

          new TableRow({
            children: [
              new TableCell({
                columnSpan: 1,
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: "Quoted Rate",
                        bold: true,
                      }),
                    ],
                  }),
                ],
              }),
              ...termsheet.quotes?.map(
                (quote) =>
                  new TableCell({
                    columnSpan: 1,
                    margins: {
                      top: 100,
                      right: 200,
                      left: 200,
                      marginUnitType: WidthType.DXA,
                    },
                    borders: {
                      top: {
                        size: 1,
                        style: BorderStyle.SINGLE,
                        color: "#d3d3d3",
                      },
                      right: {
                        size: 1,
                        style: BorderStyle.SINGLE,
                        color: "#d3d3d3",
                      },
                      bottom: {
                        size: 1,
                        style: BorderStyle.SINGLE,
                        color: "#d3d3d3",
                      },
                      left: {
                        size: 1,
                        style: BorderStyle.SINGLE,
                        color: "#d3d3d3",
                      },
                    },
                    children: [
                      new Paragraph(
                        renderToString(
                          <NumberFormat
                            value={quote.quotedRate}
                            displayType={"text"}
                            thousandSeparator
                            suffix={`%`}
                            renderText={(formattedValue) => formattedValue}
                          />
                        )
                      ),
                    ],
                  })
              ),
            ],
          }),
        ].filter((row) => row),
      }),
    ];
    sections.push({
      properties: {
        type: SectionType.NEXT_PAGE,
        page: {
          size: {
            width: 12240,
            height: 15840,
            orientation: PageOrientation.LANDSCAPE,
          },
          margin: {
            top: 720,
            right: 720,
            bottom: 720,
            left: 720,
          },
        },
      },
      children,
    });
  }

  const doc = new Document({
    styles: {
      default: {
        document: {
          paragraph: {
            spacing: {
              line: 340,
            },
          },
          run: {
            font: "Roboto",
          },
        },
      },
    },
    sections,
  });

  return Packer.toBlob(doc)
    .then((blob) => {
      if (download) {
        saveAs(blob, `${termsheet.termsheetName}.docx`);
        showDownloadSuccess();
      }
      return blob;
    })
    .catch(console.error);
};

const DownloadTermsheet = {
  DOCX: downloadDocx,
};

export const getTeamLead = (termsheet) => {
  const team = termsheet?.teams?.find((team) =>
    team.teamMembers.some((s) => s.globalRole === "Team Leader")
  );
  if (!team) {
    return null;
  }
  return team.teamMembers.find((e) => e.globalRole === "Team Leader")?.userId;
};

export const showDownloadSuccess = (message = "Successfully downloaded.") => {
  toast.success(message, {
    position: "bottom-left",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export default DownloadTermsheet;
