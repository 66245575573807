import React from "react";
import { connect } from "react-redux";
import { get } from "lodash";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { reduxFormSelect, reduxFormNumber } from "components/form/ReduxForm";
import Spacer from "components/layout/Spacer";
import programCategories from "../constants/programCategories";
import { fixedFloatingTypes } from "../constants";
import { generateSelectOption } from "utils/commonUtils";

class InvestmentStep extends React.Component {
  constructor(props) {
    super(props);
    this.submitFn = this.submitFn.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isActive !== this.props.isActive && this.props.isActive) {
      this.props.setStepName("Investment Structure");
    }
  }

  submitFn = () => {
    if (this.saveType === "saveAndContinue") {
      this.props.handleSaveAndContinue(this.props.nextStep);
    } else {
      this.props.handleSaveAndContinue();
    }
  };

  setSave = (type) => () => {
    this.saveType = type;
  };

  render() {
    const {
      action,
      handleSubmit,
      selectedFixedFloating,
      selectedProgramType,
      selectedInvestmentStructure,
    } = this.props;
    return (
      <fieldset>
        <Form
          className="no-padding no-border"
          onSubmit={handleSubmit(this.submitFn)}
        >
          <Row>
            <Col xs="12" sm="6">
              {programCategories.general.indexOf(selectedProgramType.value) !==
                -1 && (
                <>
                  <Field
                    className="form-control"
                    component={reduxFormSelect}
                    name="fixedFloating"
                    type="select"
                    options={this.props.getDropdownValues("fixedFloating")}
                    isLoading={action.GET_DROPDOWNS.isFetching}
                    onFocus={() =>
                      this.props.getDropdownFromAPI("fixedFloating")
                    }
                    placeholder="Fixed or Floating"
                    label="Fixed or Floating"
                    isMulti={true}
                  />
                  <Spacer size={20} />
                  {selectedFixedFloating.some(
                    (item) =>
                      fixedFloatingTypes.floating.indexOf(item.value) !== -1
                  ) && (
                    <>
                      <Field
                        className="form-control"
                        component={reduxFormSelect}
                        name="rateProtectionOptions"
                        type="select"
                        options={this.props.getDropdownValues(
                          "rateProtectionOptions"
                        )}
                        isLoading={action.GET_DROPDOWNS.isFetching}
                        onFocus={() =>
                          this.props.getDropdownFromAPI("rateProtectionOptions")
                        }
                        placeholder="Rate Protection Options"
                        label="Rate Protection Options"
                        isMulti={true}
                      />
                      <Spacer size={20} />
                    </>
                  )}
                  <Field
                    className="form-control"
                    component={reduxFormSelect}
                    name="otherRateProtectionOptions"
                    type="select"
                    options={this.props.getDropdownValues(
                      "otherRateProtectionOptions"
                    )}
                    isLoading={action.GET_DROPDOWNS.isFetching}
                    onFocus={() =>
                      this.props.getDropdownFromAPI(
                        "otherRateProtectionOptions"
                      )
                    }
                    placeholder="Other Rate Protection Options"
                    label="Other Rate Protection Options"
                    isMulti={true}
                  />
                  <Spacer size={20} />
                  {selectedFixedFloating.some(
                    (item) =>
                      fixedFloatingTypes.fixed.indexOf(item.value) !== -1
                  ) && (
                    <>
                      <Field
                        className="form-control"
                        component={reduxFormSelect}
                        name="fixedRateLoanPrepay"
                        type="select"
                        options={this.props.getDropdownValues(
                          "fixedRateLoanPrepay"
                        )}
                        isLoading={action.GET_DROPDOWNS.isFetching}
                        onFocus={() =>
                          this.props.getDropdownFromAPI("fixedRateLoanPrepay")
                        }
                        placeholder="Fixed Rate Loan Prepay"
                        label="Fixed Rate Loan Prepay"
                        isMulti={true}
                      />
                      <Spacer size={20} />
                    </>
                  )}
                  {selectedFixedFloating.some(
                    (item) =>
                      fixedFloatingTypes.floating.indexOf(item.value) !== -1
                  ) && (
                    <>
                      <Field
                        className="form-control"
                        component={reduxFormSelect}
                        name="floatingRateLoanPrepay"
                        type="select"
                        options={this.props.getDropdownValues(
                          "floatingRateLoanPrepay"
                        )}
                        isLoading={action.GET_DROPDOWNS.isFetching}
                        onFocus={() =>
                          this.props.getDropdownFromAPI(
                            "floatingRateLoanPrepay"
                          )
                        }
                        placeholder="Floating Rate Loan Prepay"
                        label="Floating Rate Loan Prepay"
                        isMulti={true}
                      />
                      <Spacer size={20} />
                    </>
                  )}
                </>
              )}
              {(programCategories.equity.indexOf(selectedProgramType.value) !==
                -1 ||
                programCategories.mezz.indexOf(selectedProgramType.value) !==
                  -1) && (
                <>
                  <Field
                    className="form-control"
                    component={reduxFormSelect}
                    name="transactionType"
                    type="select"
                    options={this.props.getDropdownValues("transactionType")}
                    isLoading={action.GET_DROPDOWNS.isFetching}
                    onFocus={() =>
                      this.props.getDropdownFromAPI("transactionType")
                    }
                    placeholder="Typical Transaction Risk Level"
                    label="Typical Transaction Risk Level"
                    isMulti={true}
                  />
                  <Spacer size={20} />
                  <Container>
                    <Row>
                      <Col xs="12" md="6">
                        <Field
                          className="form-control"
                          component={reduxFormSelect}
                          name="investmentPeriodMin"
                          type="select"
                          options={this.props.getDropdownValues(
                            "investmentPeriod"
                          )}
                          isLoading={action.GET_DROPDOWNS.isFetching}
                          onFocus={() =>
                            this.props.getDropdownFromAPI("investmentPeriod")
                          }
                          placeholder="Investment Period Min"
                          label="Investment Period Min"
                        />
                        <Spacer size={20} />
                      </Col>
                      <Col xs="12" md="6">
                        <Field
                          className="form-control"
                          component={reduxFormSelect}
                          name="investmentPeriodMax"
                          type="select"
                          options={this.props.getDropdownValues(
                            "investmentPeriod"
                          )}
                          isLoading={action.GET_DROPDOWNS.isFetching}
                          onFocus={() =>
                            this.props.getDropdownFromAPI("investmentPeriod")
                          }
                          placeholder="Investment Period Max"
                          label="Investment Period Max"
                        />
                        <Spacer size={20} />
                      </Col>
                    </Row>
                  </Container>
                </>
              )}
            </Col>
            <Col xs="12" sm="6">
              {programCategories.general.indexOf(selectedProgramType.value) !==
                -1 && (
                <>
                  <Container>
                    <Row>
                      <Col xs="12">
                        <h6 className="font-14 bold text-uppercase">
                          Loan Term - Range
                        </h6>
                        <hr className="m-0" />
                        <Spacer size={10} />
                      </Col>
                      <Col xs="12" md="6">
                        <Field
                          className="form-control"
                          component={reduxFormSelect}
                          name="typicalTermMin"
                          type="select"
                          options={this.props.getDropdownValues("thirtyYears")}
                          isLoading={action.GET_DROPDOWNS.isFetching}
                          onFocus={() =>
                            this.props.getDropdownFromAPI("thirtyYears")
                          }
                          placeholder="Min Term"
                          label="Typical - Min Term"
                        />
                        <Spacer size={20} />
                      </Col>
                      <Col xs="12" md="6">
                        <Field
                          className="form-control"
                          component={reduxFormSelect}
                          name="typicalTermMax"
                          type="select"
                          options={this.props.getDropdownValues("thirtyYears")}
                          isLoading={action.GET_DROPDOWNS.isFetching}
                          onFocus={() =>
                            this.props.getDropdownFromAPI("thirtyYears")
                          }
                          placeholder="Max Term"
                          label="Typical - Max Term"
                        />
                        <Spacer size={20} />
                      </Col>
                    </Row>
                  </Container>
                  <Container>
                    <Row>
                      <Col xs="12">
                        <h6 className="font-14 bold text-uppercase">
                          Origination Fee - Range
                        </h6>
                        <hr className="m-0" />
                        <Spacer size={0} />
                      </Col>
                      <Col xs="12" md="6">
                        <Field
                          className="form-control"
                          component={reduxFormNumber}
                          suffix={"%"}
                          decimalScale={1}
                          fixedDecimalScale
                          name="typicalOriginationFeeMin"
                          placeholder="Min (%)"
                          label="Min Origination Fee"
                        />
                        <Spacer size={20} />
                      </Col>
                      <Col xs="12" md="6">
                        <Field
                          className="form-control"
                          component={reduxFormNumber}
                          suffix={"%"}
                          decimalScale={1}
                          fixedDecimalScale
                          name="typicalOriginationFeeMax"
                          placeholder="Max (%)"
                          label="Max Origination Fee"
                        />
                        <Spacer size={20} />
                      </Col>
                    </Row>
                  </Container>
                  <Container>
                    <Row>
                      <Col xs="12">
                        <h6 className="font-14 bold text-uppercase">
                          Exit Fee - Range
                        </h6>
                        <hr className="m-0" />
                        <Spacer size={0} />
                      </Col>
                      <Col xs="12" md="6">
                        <Field
                          className="form-control"
                          component={reduxFormNumber}
                          suffix={"%"}
                          decimalScale={1}
                          fixedDecimalScale
                          name="typicalExitFeeMin"
                          placeholder="Min (%)"
                          label="Min Exit Fee"
                        />
                        <Spacer size={20} />
                      </Col>
                      <Col xs="12" md="6">
                        <Field
                          className="form-control"
                          component={reduxFormNumber}
                          suffix={"%"}
                          decimalScale={1}
                          fixedDecimalScale
                          name="typicalExitFeeMax"
                          placeholder="Max (%)"
                          label="Max Exit Fee"
                        />
                        <Spacer size={20} />
                      </Col>
                    </Row>
                  </Container>
                </>
              )}
              {programCategories.equity.indexOf(selectedProgramType.value) !==
                -1 && (
                <>
                  <Field
                    className="form-control"
                    component={reduxFormSelect}
                    name="investmentStructure"
                    type="select"
                    options={this.props.getDropdownValues(
                      "investmentStructure"
                    )}
                    isLoading={action.GET_DROPDOWNS.isFetching}
                    onFocus={() =>
                      this.props.getDropdownFromAPI("investmentStructure")
                    }
                    placeholder="Investment Structure"
                    label="Investment Structure"
                  />
                  <Spacer size={20} />
                  {selectedInvestmentStructure.value === "Invest in GP" && (
                    <>
                      <Field
                        className="form-control"
                        component={reduxFormSelect}
                        name="minGPCoInvest"
                        type="select"
                        options={this.props.getDropdownValues("minGPCoInvest")}
                        isLoading={action.GET_DROPDOWNS.isFetching}
                        onFocus={() =>
                          this.props.getDropdownFromAPI("minGPCoInvest")
                        }
                        placeholder="Min GP Co-Invest"
                        label="Min GP Co-Invest"
                      />
                      <Spacer size={20} />
                    </>
                  )}

                  <Field
                    className="form-control"
                    component={reduxFormSelect}
                    name="relationshipType"
                    type="select"
                    options={this.props.getDropdownValues("relationshipType")}
                    isLoading={action.GET_DROPDOWNS.isFetching}
                    onFocus={() =>
                      this.props.getDropdownFromAPI("relationshipType")
                    }
                    placeholder="Relationship type"
                    label="Relationship type"
                  />
                  <Spacer size={20} />
                </>
              )}
              {programCategories.mezz.indexOf(selectedProgramType.value) !==
                -1 && (
                <>
                  <Field
                    className="form-control"
                    component={reduxFormSelect}
                    name="mezzPrefPreference"
                    type="select"
                    options={this.props.getDropdownValues("mezzPrefPreference")}
                    isLoading={action.GET_DROPDOWNS.isFetching}
                    onFocus={() =>
                      this.props.getDropdownFromAPI("mezzPrefPreference")
                    }
                    placeholder="Mezz / Pref Preference"
                    label="Mezz / Pref Preference"
                  />
                  <Spacer size={20} />
                </>
              )}
            </Col>
          </Row>
          <Row>
            <Col className="text-left" xs="3">
              <Spacer size={50} />
              <Button
                onClick={this.props.handlePrevious(this.props.previousStep)}
                variant="dark"
              >
                Back
              </Button>
            </Col>
            <Col className="text-right" xs="9">
              <Spacer size={50} />
              <Button
                type="submit"
                variant="primary"
                onClick={this.setSave("saveAndExit")}
              >
                Save & Exit
              </Button>
              &nbsp;
              <Button
                type="submit"
                variant="primary"
                onClick={this.setSave("saveAndContinue")}
              >
                Save & Continue
              </Button>
            </Col>
          </Row>
        </Form>
      </fieldset>
    );
  }
}

const selectorInitialStep = formValueSelector("ProgramCreateInitialStep");
const selectorInvestmentStep = formValueSelector("ProgramCreateInvestmentStep");

const getInitialValues = ({ isUpdate, isClone, selectedProgram: program }) => {
  if (!isUpdate && !isClone) {
    return {};
  }
  return {
    fixedFloating: get(program, "fixedFloating", null),
    rateProtectionOptions: get(program, "rateProtectionOptions", null),
    otherRateProtectionOptions: get(
      program,
      "otherRateProtectionOptions",
      null
    ),
    fixedRateLoanPrepay: get(program, "fixedRateLoanPrepay", null),
    floatingRateLoanPrepay: get(program, "floatingRateLoanPrepay", null),
    typicalTermMin: generateSelectOption(program.typicalTermMin),
    typicalTermMax: generateSelectOption(program.typicalTermMax),
    typicalOriginationFeeMin: program.typicalOriginationFeeMin,
    typicalOriginationFeeMax: program.typicalOriginationFeeMax,
    typicalExitFeeMin: program.typicalExitFeeMin,
    typicalExitFeeMax: program.typicalExitFeeMax,
    transactionType: get(program, "transactionType", null),
    investmentPeriodMin: generateSelectOption(program.investmentPeriodMin),
    investmentPeriodMax: generateSelectOption(program.investmentPeriodMax),
    investmentStructure: generateSelectOption(program.investmentStructure),
    minGPCoInvest: generateSelectOption(program.minGPCoInvest),
    relationshipType: generateSelectOption(program.relationshipType),
  };
};

function mapStateToProps(state, ownProps) {
  return {
    action: state.action,
    selectedProgramType: selectorInitialStep(state, "programTypes") || "",
    selectedFixedFloating: selectorInvestmentStep(state, "fixedFloating") || [],
    selectedInvestmentStructure:
      selectorInvestmentStep(state, "investmentStructure") || {},
    initialValues: getInitialValues(ownProps),
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "ProgramCreateInvestmentStep",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    enableReinitialize: true,
  })(InvestmentStep)
);
