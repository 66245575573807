import { sortBy } from "lodash";

export const getAllFields = (primaryFields = [], addedFields = []) => {
  const mappedFields = primaryFields.map((field) => {
    const found = addedFields.find(
      (addedField) => addedField.label === field.label
    );
    if (found) return found;
    return field;
  });
  const filteredAddedFields = addedFields.filter(
    (addedField) =>
      !primaryFields.some((field) => field.label === addedField.label)
  );
  return [...mappedFields, ...filteredAddedFields];
};

export const getSortedAllFields = (allFields = [], parentClauses = []) => {
  if (!parentClauses.length) return [];
  let labels = ["Status", "Lender"];
  let foundList = [];
  let notFoundList = [];
  let preFields = [];
  let midFields = [];
  allFields.forEach((field) => {
    if (labels.includes(field.label)) preFields.push(field);
    else midFields.push(field);
  });
  midFields.forEach((field) => {
    const found = parentClauses.find((clause) => {
      const name = clause.displayName || clause.clauseName;
      return field.label === name;
    });
    if (found) foundList.push({ ...field, order: found.order });
    else notFoundList.push(field);
  });
  return [...preFields, ...sortBy(foundList, "order"), ...notFoundList];
};
