import React from "react";
import _ from "lodash";
import { renderToString } from "react-dom/server";
import {
  Paragraph,
  Table,
  TableCell,
  TableRow,
  WidthType,
  HeightRule,
  BorderStyle,
  TextRun,
  SectionType,
  PageOrientation,
  ShadingType,
  AlignmentType,
} from "docx";
import {
  formatToCurrency,
  formatToNumber,
  formatToPercent,
  getIndexAsOfLabel,
  getSpreadTextValue,
} from "utils/commonUtils";
import NumberFormat from "react-number-format";
import {
  getPrimaryClauses,
  getPrimaryClauseValueKey,
} from "containers/quotes/quotesConfig";

export const primaryClauses = getPrimaryClauses();

export const generateManyQuoteMatrix = ({ termsheet }) => {
  if (termsheet.quotes?.length > 2) {
    return [
      new TableRow({
        children: [
          new TableCell({
            columnSpan: 1,
            children: [new Paragraph("")],
          }),
          new TableCell({
            columnSpan: 3,
            margins: {
              top: 100,
              right: 200,
              left: 200,
              marginUnitType: WidthType.DXA,
            },
            shading: {
              type: ShadingType.SOLID,
              color: "717171",
              fill: "ffffff",
            },
            children: [
              new Paragraph({
                alignment: AlignmentType.LEFT,
                children: [
                  new TextRun({
                    text: "Lender has provided an extensive menu of quotes.  Only two of the quotes are shared above.  The remainder of the quotes are included in Addendum A to this term sheet.",
                    italics: true,
                    color: "ffffff",
                  }),
                ],
              }),
            ],
          }),
        ],
      }),

      ...generateEmptyRows(2),
    ];
  }
  return [];
};

export const generateNoMatrix = ({
  termsheet,
  firstQuote,
  hasMatrix,
  isSpread,
}) => {
  if (!hasMatrix) {
    return getPrimaryClauses(isSpread, termsheet.quotes.length)
      .reduce((acc, primary) => {
        const valueKey = getPrimaryClauseValueKey(primary.label);
        const periodKey = valueKey + "Metric";
        const sectionValue = getNoMatrixSectionValue(firstQuote, valueKey);
        const sectionYearsMonths = firstQuote[periodKey] || "";
        const sectionName =
          primary.label === "Index Rate" ? getIndexAsOfLabel() : primary.label;
        const foundColumn = termsheet.columns.find(
          (col) =>
            col.clause.clauseName === valueKey ||
            col.clause.clauseName === primary.label
        );
        const langDesc = foundColumn?.langDesc || "";
        const column = { ...primary, sectionName, langDesc };
        const format = ["spread", "floorOptionValue"].includes(valueKey)
          ? "Text"
          : column.langDesc || primary.clause?.format;

        return renderColumn(column, acc, {
          format,
          sectionValue,
          sectionYearsMonths,
        });
      }, [])
      .filter((row) => row);
  }
  return [];
};

export const getNoMatrixSectionValue = (firstQuote, valueKey) => {
  if (valueKey === "spread") {
    return getSpreadTextValue(firstQuote);
  }
  return firstQuote[valueKey] || 0;
};

export const generateAddendum = ({ termsheet, isSpread, sections }) => {
  if (termsheet.quotes?.length > 2) {
    const children = [
      new Table({
        width: {
          size: 100,
          type: WidthType.PERCENTAGE,
        },
        borders: {
          top: {
            size: 0,
          },
          right: {
            size: 0,
          },
          bottom: {
            size: 0,
          },
          left: {
            size: 0,
          },
          insideHorizontal: {
            size: 0,
          },
          insideVertical: {
            size: 0,
          },
        },
        rows: [
          new TableRow({
            height: {
              rule: HeightRule.EXACT,
              value: 1,
            },
            children: [
              new TableCell({
                width: {
                  size: 16,
                  type: WidthType.PERCENTAGE,
                },
                children: [],
              }),
              new TableCell({
                width: {
                  size: 16,
                  type: WidthType.PERCENTAGE,
                },
                children: [],
              }),
              new TableCell({
                width: {
                  size: 16,
                  type: WidthType.PERCENTAGE,
                },
                children: [],
              }),
              new TableCell({
                width: {
                  size: 16,
                  type: WidthType.PERCENTAGE,
                },
                children: [],
              }),
              new TableCell({
                width: {
                  size: 16,
                  type: WidthType.PERCENTAGE,
                },
                children: [],
              }),
              new TableCell({
                width: {
                  size: 16,
                  type: WidthType.PERCENTAGE,
                },
                children: [],
              }),
            ],
          }),

          new TableRow({
            children: [
              new TableCell({
                columnSpan: 1,
                children: [new Paragraph("")],
              }),
              new TableCell({
                columnSpan: 5,
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: "Addendum A:  Quote Matrix",
                        bold: true,
                      }),
                    ],
                  }),
                ],
              }),
            ],
          }),

          ...generateEmptyRows(1, 6),

          new TableRow({
            tableHeader: true,
            children: [
              new TableCell({
                columnSpan: 1,
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: "",
                        bold: true,
                      }),
                    ],
                  }),
                ],
              }),
              ...termsheet.quotes?.map(
                (quote, index) =>
                  new TableCell({
                    columnSpan: 1,
                    margins: {
                      top: 100,
                      right: 200,
                      left: 200,
                      marginUnitType: WidthType.DXA,
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: `Quote ${index + 1}`,
                            bold: true,
                          }),
                        ],
                      }),
                    ],
                  })
              ),
            ],
          }),
          new TableRow({
            children: [
              new TableCell({
                columnSpan: 1,
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: "Loan Amount",
                        bold: true,
                      }),
                    ],
                  }),
                ],
              }),
              ...termsheet.quotes?.map(
                (quote) =>
                  new TableCell({
                    columnSpan: 1,
                    margins: {
                      top: 100,
                      right: 200,
                      left: 200,
                      marginUnitType: WidthType.DXA,
                    },
                    borders: {
                      top: {
                        size: 1,
                        style: BorderStyle.SINGLE,
                        color: "#d3d3d3",
                      },
                      right: {
                        size: 1,
                        style: BorderStyle.SINGLE,
                        color: "#d3d3d3",
                      },
                      bottom: {
                        size: 1,
                        style: BorderStyle.SINGLE,
                        color: "#d3d3d3",
                      },
                      left: {
                        size: 1,
                        style: BorderStyle.SINGLE,
                        color: "#d3d3d3",
                      },
                    },
                    children: [
                      new Paragraph(
                        renderToString(
                          <NumberFormat
                            value={quote.loanAmount}
                            displayType={"text"}
                            thousandSeparator
                            prefix="$"
                            renderText={(formattedValue) => formattedValue}
                          />
                        )
                      ),
                    ],
                  })
              ),
            ],
          }),

          new TableRow({
            children: [
              new TableCell({
                columnSpan: 1,
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: "Term",
                        bold: true,
                      }),
                    ],
                  }),
                ],
              }),
              ...termsheet.quotes?.map(
                (quote) =>
                  new TableCell({
                    columnSpan: 1,
                    margins: {
                      top: 100,
                      right: 200,
                      left: 200,
                      marginUnitType: WidthType.DXA,
                    },
                    borders: {
                      top: {
                        size: 1,
                        style: BorderStyle.SINGLE,
                        color: "#d3d3d3",
                      },
                      right: {
                        size: 1,
                        style: BorderStyle.SINGLE,
                        color: "#d3d3d3",
                      },
                      bottom: {
                        size: 1,
                        style: BorderStyle.SINGLE,
                        color: "#d3d3d3",
                      },
                      left: {
                        size: 1,
                        style: BorderStyle.SINGLE,
                        color: "#d3d3d3",
                      },
                    },
                    children: [
                      new Paragraph(
                        renderToString(
                          <NumberFormat
                            value={quote.term}
                            displayType={"text"}
                            thousandSeparator
                            suffix={` ${quote.termMetric}`}
                            renderText={(formattedValue) => formattedValue}
                          />
                        )
                      ),
                    ],
                  })
              ),
            ],
          }),

          new TableRow({
            children: [
              new TableCell({
                columnSpan: 1,
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: "Amortization",
                        bold: true,
                      }),
                    ],
                  }),
                ],
              }),
              ...termsheet.quotes?.map(
                (quote) =>
                  new TableCell({
                    columnSpan: 1,
                    margins: {
                      top: 100,
                      right: 200,
                      left: 200,
                      marginUnitType: WidthType.DXA,
                    },
                    borders: {
                      top: {
                        size: 1,
                        style: BorderStyle.SINGLE,
                        color: "#d3d3d3",
                      },
                      right: {
                        size: 1,
                        style: BorderStyle.SINGLE,
                        color: "#d3d3d3",
                      },
                      bottom: {
                        size: 1,
                        style: BorderStyle.SINGLE,
                        color: "#d3d3d3",
                      },
                      left: {
                        size: 1,
                        style: BorderStyle.SINGLE,
                        color: "#d3d3d3",
                      },
                    },
                    children: [
                      new Paragraph(
                        renderToString(
                          <NumberFormat
                            value={quote.amortization}
                            displayType={"text"}
                            thousandSeparator
                            suffix={` ${quote.amortizationMetric}`}
                            renderText={(formattedValue) => formattedValue}
                          />
                        )
                      ),
                    ],
                  })
              ),
            ],
          }),

          new TableRow({
            children: [
              new TableCell({
                columnSpan: 1,
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: "IO Period",
                        bold: true,
                      }),
                    ],
                  }),
                ],
              }),
              ...termsheet.quotes?.map(
                (quote) =>
                  new TableCell({
                    columnSpan: 1,
                    margins: {
                      top: 100,
                      right: 200,
                      left: 200,
                      marginUnitType: WidthType.DXA,
                    },
                    borders: {
                      top: {
                        size: 1,
                        style: BorderStyle.SINGLE,
                        color: "#d3d3d3",
                      },
                      right: {
                        size: 1,
                        style: BorderStyle.SINGLE,
                        color: "#d3d3d3",
                      },
                      bottom: {
                        size: 1,
                        style: BorderStyle.SINGLE,
                        color: "#d3d3d3",
                      },
                      left: {
                        size: 1,
                        style: BorderStyle.SINGLE,
                        color: "#d3d3d3",
                      },
                    },
                    children: [
                      new Paragraph(
                        renderToString(
                          <NumberFormat
                            value={quote.IOPeriod}
                            displayType={"text"}
                            suffix={` ${quote.IOPeriodMetric}`}
                            thousandSeparator
                            renderText={(formattedValue) => formattedValue}
                          />
                        )
                      ),
                    ],
                  })
              ),
            ],
          }),

          // Spread
          isSpread
            ? new TableRow({
                children: [
                  new TableCell({
                    columnSpan: 1,
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "Spread",
                            bold: true,
                          }),
                        ],
                      }),
                    ],
                  }),
                  ...termsheet.quotes?.map(
                    (quote) =>
                      new TableCell({
                        columnSpan: 1,
                        margins: {
                          top: 100,
                          right: 200,
                          left: 200,
                          marginUnitType: WidthType.DXA,
                        },
                        borders: {
                          top: {
                            size: 1,
                            style: BorderStyle.SINGLE,
                            color: "#d3d3d3",
                          },
                          right: {
                            size: 1,
                            style: BorderStyle.SINGLE,
                            color: "#d3d3d3",
                          },
                          bottom: {
                            size: 1,
                            style: BorderStyle.SINGLE,
                            color: "#d3d3d3",
                          },
                          left: {
                            size: 1,
                            style: BorderStyle.SINGLE,
                            color: "#d3d3d3",
                          },
                        },
                        children: [new Paragraph(getSpreadTextValue(quote))],
                      })
                  ),
                ],
              })
            : null,

          // Rate
          isSpread
            ? null
            : new TableRow({
                children: [
                  new TableCell({
                    columnSpan: 1,
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "Rate",
                            bold: true,
                          }),
                        ],
                      }),
                    ],
                  }),
                  ...termsheet.quotes?.map(
                    (quote) =>
                      new TableCell({
                        columnSpan: 1,
                        margins: {
                          top: 100,
                          right: 200,
                          left: 200,
                          marginUnitType: WidthType.DXA,
                        },
                        borders: {
                          top: {
                            size: 1,
                            style: BorderStyle.SINGLE,
                            color: "#d3d3d3",
                          },
                          right: {
                            size: 1,
                            style: BorderStyle.SINGLE,
                            color: "#d3d3d3",
                          },
                          bottom: {
                            size: 1,
                            style: BorderStyle.SINGLE,
                            color: "#d3d3d3",
                          },
                          left: {
                            size: 1,
                            style: BorderStyle.SINGLE,
                            color: "#d3d3d3",
                          },
                        },
                        children: [
                          new Paragraph(
                            renderToString(
                              <NumberFormat
                                value={quote.rate}
                                displayType={"text"}
                                thousandSeparator
                                suffix={`%`}
                                decimalScale={2}
                                fixedDecimalScale
                                renderText={(formattedValue) => formattedValue}
                              />
                            )
                          ),
                        ],
                      })
                  ),
                ],
              }),

          // Floor
          isSpread
            ? new TableRow({
                children: [
                  new TableCell({
                    columnSpan: 1,
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "Floor",
                            bold: true,
                          }),
                        ],
                      }),
                    ],
                  }),
                  ...termsheet.quotes?.map(
                    (quote) =>
                      new TableCell({
                        columnSpan: 1,
                        margins: {
                          top: 100,
                          right: 200,
                          left: 200,
                          marginUnitType: WidthType.DXA,
                        },
                        borders: {
                          top: {
                            size: 1,
                            style: BorderStyle.SINGLE,
                            color: "#d3d3d3",
                          },
                          right: {
                            size: 1,
                            style: BorderStyle.SINGLE,
                            color: "#d3d3d3",
                          },
                          bottom: {
                            size: 1,
                            style: BorderStyle.SINGLE,
                            color: "#d3d3d3",
                          },
                          left: {
                            size: 1,
                            style: BorderStyle.SINGLE,
                            color: "#d3d3d3",
                          },
                        },
                        children: [
                          new Paragraph(
                            quote.floorRate
                              ? renderToString(
                                  <NumberFormat
                                    value={quote.floorRate}
                                    displayType={"text"}
                                    thousandSeparator
                                    suffix={`% ${quote.floorOption}`}
                                    renderText={(formattedValue) =>
                                      formattedValue
                                    }
                                  />
                                )
                              : quote.floorOption
                          ),
                        ],
                      })
                  ),
                ],
              })
            : null,

          // Index
          isSpread
            ? new TableRow({
                children: [
                  new TableCell({
                    columnSpan: 1,
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: getIndexAsOfLabel(),
                            bold: true,
                          }),
                        ],
                      }),
                    ],
                  }),
                  ...termsheet.quotes?.map(
                    (quote) =>
                      new TableCell({
                        columnSpan: 1,
                        margins: {
                          top: 100,
                          right: 200,
                          left: 200,
                          marginUnitType: WidthType.DXA,
                        },
                        borders: {
                          top: {
                            size: 1,
                            style: BorderStyle.SINGLE,
                            color: "#d3d3d3",
                          },
                          right: {
                            size: 1,
                            style: BorderStyle.SINGLE,
                            color: "#d3d3d3",
                          },
                          bottom: {
                            size: 1,
                            style: BorderStyle.SINGLE,
                            color: "#d3d3d3",
                          },
                          left: {
                            size: 1,
                            style: BorderStyle.SINGLE,
                            color: "#d3d3d3",
                          },
                        },
                        children: [
                          new Paragraph(
                            renderToString(
                              <NumberFormat
                                value={quote.indexRate || 0}
                                displayType={"text"}
                                thousandSeparator
                                suffix={`%`}
                                renderText={(formattedValue) => formattedValue}
                              />
                            )
                          ),
                        ],
                      })
                  ),
                ],
              })
            : null,

          // Quoted Rate
          new TableRow({
            children: [
              new TableCell({
                columnSpan: 1,
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: "Quoted Rate",
                        bold: true,
                      }),
                    ],
                  }),
                ],
              }),
              ...termsheet.quotes?.map(
                (quote) =>
                  new TableCell({
                    columnSpan: 1,
                    margins: {
                      top: 100,
                      right: 200,
                      left: 200,
                      marginUnitType: WidthType.DXA,
                    },
                    borders: {
                      top: {
                        size: 1,
                        style: BorderStyle.SINGLE,
                        color: "#d3d3d3",
                      },
                      right: {
                        size: 1,
                        style: BorderStyle.SINGLE,
                        color: "#d3d3d3",
                      },
                      bottom: {
                        size: 1,
                        style: BorderStyle.SINGLE,
                        color: "#d3d3d3",
                      },
                      left: {
                        size: 1,
                        style: BorderStyle.SINGLE,
                        color: "#d3d3d3",
                      },
                    },
                    children: [
                      new Paragraph(
                        renderToString(
                          <NumberFormat
                            value={quote.quotedRate}
                            displayType={"text"}
                            thousandSeparator
                            suffix={`%`}
                            renderText={(formattedValue) => formattedValue}
                          />
                        )
                      ),
                    ],
                  })
              ),
            ],
          }),

          ...generateAddeClauses({
            addedClauses: termsheet.addedClauses,
            quotes: termsheet.quotes,
          }),
        ].filter((row) => row),
      }),
    ];
    sections.push({
      properties: {
        type: SectionType.NEXT_PAGE,
        page: {
          size: {
            width: 12240,
            height: 15840,
            orientation: PageOrientation.LANDSCAPE,
          },
          margin: {
            top: 720,
            right: 720,
            bottom: 720,
            left: 720,
          },
        },
      },
      children,
    });
  }
};

export const generateAddeClauses = ({ addedClauses = [], quotes = [] }) =>
  addedClauses.map(
    (addedClause) =>
      new TableRow({
        children: [
          new TableCell({
            columnSpan: 1,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: addedClause.label,
                    bold: true,
                  }),
                ],
              }),
            ],
          }),
          ...quotes.map((quote) => {
            return new TableCell({
              columnSpan: 1,
              margins: {
                top: 100,
                right: 200,
                left: 200,
                marginUnitType: WidthType.DXA,
              },
              borders: {
                top: {
                  size: 1,
                  style: BorderStyle.SINGLE,
                  color: "#d3d3d3",
                },
                right: {
                  size: 1,
                  style: BorderStyle.SINGLE,
                  color: "#d3d3d3",
                },
                bottom: {
                  size: 1,
                  style: BorderStyle.SINGLE,
                  color: "#d3d3d3",
                },
                left: {
                  size: 1,
                  style: BorderStyle.SINGLE,
                  color: "#d3d3d3",
                },
              },
              children: [
                new Paragraph(getAddedClauseValue(addedClause, quote)),
              ],
            });
          }),
        ],
      })
  );

export const generateEmptyRows = (length = 1, colSpan = 4) => {
  return new Array(length).fill().map(
    () =>
      new TableRow({
        children: [
          new TableCell({
            columnSpan: colSpan,
            children: [],
          }),
        ],
      })
  );
};

export const renderColumn = (
  column,
  acc,
  {
    format,
    sectionValue = column.sectionValue,
    sectionYearsMonths = column.sectionYearsMonths,
  }
) => {
  format = format || column.clause?.format || "Text";

  // paragraph no header
  if (format === "Open Text, left aligned, no header") {
    const newRows = [
      new TableRow({
        children: [
          new TableCell({
            columnSpan: 4,
            children: [
              new Paragraph(extractTextFromHtmlString(column.langDesc)),
            ],
          }),
        ],
      }),
      ...generateEmptyRows(1),
    ];

    return [...acc, ...newRows];
  }

  // paragraph with header
  if (format === "Open Text, with header") {
    const newRows = [
      new TableRow({
        children: [
          new TableCell({
            columnSpan: 1,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: `${column.sectionName}:`,
                    bold: true,
                  }),
                ],
              }),
            ],
          }),
          new TableCell({
            columnSpan: 3,
            children: [
              new Paragraph(extractTextFromHtmlString(column.langDesc)),
            ],
          }),
        ],
      }),
      ...generateEmptyRows(1),
    ];

    return [...acc, ...newRows];
  }

  // currency
  if (format === "$#,###") {
    const newRows = [
      new TableRow({
        children: [
          new TableCell({
            columnSpan: 1,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: `${column.sectionName}:`,
                    bold: true,
                  }),
                ],
              }),
            ],
          }),
          new TableCell({
            columnSpan: 3,
            children: [
              new Paragraph(
                renderToString(
                  <NumberFormat
                    value={sectionValue}
                    displayType={"text"}
                    thousandSeparator
                    prefix="$"
                    renderText={(formattedValue) => formattedValue}
                  />
                )
              ),
            ],
          }),
        ],
      }),
      column.langDesc
        ? new TableRow({
            children: [
              new TableCell({
                columnSpan: 1,
                children: [new Paragraph("")],
              }),
              new TableCell({
                columnSpan: 3,
                children: [
                  new Paragraph(extractTextFromHtmlString(column.langDesc)),
                ],
              }),
            ],
          })
        : null,
      ...generateEmptyRows(1),
    ];

    return [...acc, ...newRows];
  }

  // number
  if (format === "#,###" || format === "#.##x") {
    const newRows = [
      new TableRow({
        children: [
          new TableCell({
            columnSpan: 1,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: `${column.sectionName}:`,
                    bold: true,
                  }),
                ],
              }),
            ],
          }),
          new TableCell({
            columnSpan: 3,
            children: [
              new Paragraph(
                renderToString(
                  <NumberFormat
                    value={sectionValue}
                    displayType={"text"}
                    thousandSeparator
                    decimalScale={format === "#,###" ? 0 : 10}
                    suffix={` ${sectionYearsMonths}`}
                    renderText={(formattedValue) => formattedValue}
                  />
                )
              ),
            ],
          }),
        ],
      }),
      column.langDesc
        ? new TableRow({
            children: [
              new TableCell({
                columnSpan: 1,
                children: [new Paragraph("")],
              }),
              new TableCell({
                columnSpan: 3,
                children: [
                  new Paragraph(extractTextFromHtmlString(column.langDesc)),
                ],
              }),
            ],
          })
        : null,
      ...generateEmptyRows(1),
    ];

    return [...acc, ...newRows];
  }

  // percent
  if (format === "#%" || format === "#.#%" || format === "#.##%") {
    const newRows = [
      new TableRow({
        children: [
          new TableCell({
            columnSpan: 1,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: `${column.sectionName}:`,
                    bold: true,
                  }),
                ],
              }),
            ],
          }),
          new TableCell({
            columnSpan: 3,
            children: [
              new Paragraph(
                renderToString(
                  <NumberFormat
                    value={sectionValue}
                    displayType={"text"}
                    thousandSeparator
                    decimalScale={
                      format === "#%" ? 0 : format === "#.#%" ? 1 : 2
                    }
                    fixedDecimalScale
                    suffix="%"
                    renderText={(formattedValue) => formattedValue}
                  />
                )
              ),
            ],
          }),
        ],
      }),
      column.langDesc
        ? new TableRow({
            children: [
              new TableCell({
                columnSpan: 1,
                children: [new Paragraph("")],
              }),
              new TableCell({
                columnSpan: 3,
                children: [
                  new Paragraph(extractTextFromHtmlString(column.langDesc)),
                ],
              }),
            ],
          })
        : null,
      ...generateEmptyRows(1),
    ];

    return [...acc, ...newRows];
  }

  // text
  if (format === "Text") {
    const newRows = [
      new TableRow({
        children: [
          new TableCell({
            columnSpan: 1,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: `${column.sectionName}:`,
                    bold: true,
                  }),
                ],
              }),
            ],
          }),
          new TableCell({
            columnSpan: 3,
            children: [new Paragraph(`${sectionValue}`)],
          }),
        ],
      }),
      ...generateEmptyRows(1),
    ];

    return [...acc, ...newRows];
  }

  return acc;
};

export const getAddedClauseValue = (addedClause, quote) => {
  const foundClause = quote.addedClauses?.find(
    (c) => c.clauseId === addedClause.clauseId
  );
  const addedClauseValue = formatValue(foundClause?.value, foundClause?.type);
  return addedClauseValue;
};

export const formatValue = (value, format) => {
  if (value === undefined || value === null || value === "") return "";
  let newValue;
  switch (format) {
    case "number":
      newValue = formatToNumber(value);
      break;
    case "currency":
      newValue = formatToCurrency(value);
      break;
    case "percent":
      newValue = formatToPercent(value);
      break;
    default:
      newValue = "";
      break;
  }
  return newValue;
};

export const extractTextFromHtmlString = (htmlString) => {
  const html = htmlString;
  const div = document.createElement("div");
  div.innerHTML = html;
  const text = div.textContent || div.innerText || "";
  return text;
};

export const sortColumnsByPrimary = (columns = []) => {
  const result = [];
  primaryClauses.forEach((clause) => {
    const foundCol = columns.find((col) => col.sectionName === clause.label);
    if (foundCol) {
      result.push(foundCol);
    }
  });
  columns.forEach((col) => {
    const found = result.find((c) => c.sectionName === col.sectionName);
    if (!found) {
      result.push(col);
    }
  });
  return result;
};

export const highlightedRed = (text, whoami) => {
  let result;
  if (whoami.role !== "Borrower") {
    result = `<span style='background: red;' title='Field does not exist'>${text}</span>`;
  }
  return result;
};

export const replaceMergeFields = (langDesc, deal, whoami, borrowerCompany) => {
  let result = langDesc;
  // Use custom template delimiters. {{field}}
  try {
    _.templateSettings.interpolate = /{{([\s\S]+?)}}/g;
    const compiled = _.template(langDesc);
    result = compiled({
      Sponsor:
        borrowerCompany?.companyName ||
        highlightedRed("{{SponsorAddress}}", whoami),
      BorrowerEntity: highlightedRed("{{BorrowerEntity}}", whoami),
      SponsorAddress:
        borrowerCompany?.companyAddress ||
        highlightedRed("{{SponsorAddress}}", whoami),
      SponsorAddressII:
        borrowerCompany?.companyAddress2 ||
        highlightedRed("{{SponsorAddressII}}", whoami),
      SponsorCity:
        borrowerCompany?.companyCity ||
        highlightedRed("{{SponsorCity}}", whoami),
      SponsorState:
        borrowerCompany?.companyState ||
        highlightedRed("{{SponsorState}}", whoami),
      SponsorZip:
        borrowerCompany?.companyZipCode ||
        highlightedRed("{{SponsorZip}}", whoami),
      KeyPrincipalList: highlightedRed("{{KeyPrincipalList}}", whoami),
      KeyPrincipalSignor: highlightedRed("{{KeyPrincipalSignor}}", whoami),
      PropertyAddress:
        deal.propertyAddress || highlightedRed("{{PropertyAddress}}", whoami),
      PropertyCity:
        deal.propertyCity || highlightedRed("{{PropertyCity}}", whoami),
      PropertyState:
        deal.propertyState || highlightedRed("{{PropertyState}}", whoami),
      PropertyZip:
        deal.propertyZip || highlightedRed("{{PropertyZip}}", whoami),
      PropertyLandSize:
        deal.propertySize || highlightedRed("{{PropertyLandSize}}", whoami),
      PropertySF: highlightedRed("{{PropertySF}}", whoami),
      PropertyUnits:
        deal.propertySizeType || highlightedRed("{{PropertyUnits}}", whoami),
      PropertyType:
        deal.propertyType || highlightedRed("{{PropertyType}}", whoami),
    });
  } catch (error) {
    result = langDesc;
  }
  return result;
};

export const isOpeningParagraph = (clause) => {
  return (
    clause?.format === "Open Text, left aligned, no header" &&
    (clause?.clauseName?.includes("Opening Paragraph") ||
      clause?.displayName?.includes("Opening Paragraph"))
  );
};
