import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { Container, Row, Col, Button, Alert } from "react-bootstrap";
import { getDealById, updateDeal, savePictures } from "redux/actions";
import { getKeyFromLocal, storeJsonInLocal } from "utils/lsUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons";
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import constants from "config/constants";
import Spacer from "components/layout/Spacer";
import Loader from "components/ui/Loader";
import { Switch, Tooltip, Modal } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
const WARNING_CONFIG = {
  title: "You have elected to change the choice!",
  content: <>It will delete the work they've already done. Are you sure?</>,
};
class PortifolioLevel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      seconds: 30,
      inputAtProperty: !props.deal.isManagerSame,
      sponserAtProperty: !props.deal.isKeyPrincipalSame,
      sourcesAtProperty: !props.deal.isSourcesSame,
    };

    this.inputFinancialRef = React.createRef();
    this.inputPictureRef = React.createRef();
    this.saveFiles = this.saveFiles.bind(this);
    this.finishDeal = this.finishDeal.bind(this);
    this.lsRedirect = this.lsRedirect.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.deal._id !== this.props.deal._id) {
      this.setState({
        inputAtProperty: !this.props.deal.isManagerSame,
        sponserAtProperty: !this.props.deal.isKeyPrincipalSame,
        sourcesAtProperty: !this.props.deal.isSourcesSame,
      });
    }
    if (prevProps.myCompany !== this.props.myCompany) {
      const { myCompany } = this.props;

      if (myCompany && myCompany.message) {
        const timer = setInterval(() => {
          if (this.state.seconds === 0) {
            clearInterval(timer);

            let jsonObj = {};

            jsonObj[constants.TERMST_LOCAL_STORAGE.NEEDS_COMPANY] = true;
            storeJsonInLocal(jsonObj);

            window.location.pathname = "/company/create";
          } else {
            this.setState({ seconds: this.state.seconds - 1 });
          }
        }, 1000);
      }
    }
  }

  componentDidMount() {
    if (getKeyFromLocal(constants.TERMST_LOCAL_STORAGE.PORTIFOLIO_DEAL)) {
      const dealId = getKeyFromLocal(
        constants.TERMST_LOCAL_STORAGE.PORTIFOLIO_DEAL
      );
      this.setState({ dealId });
      getDealById(dealId);
    }
  }

  finishDeal() {
    const dealId = getKeyFromLocal(constants.TERMST_LOCAL_STORAGE.DEAL_ID);

    updateDeal(dealId, {
      dealStatus: "Under review",
      redirect: true,
    });
  }

  saveFiles(event, type) {
    let data = new FormData();
    for (let i = 0; i < event.target.files.length; i++) {
      data.append("files", event.target.files[i]);
    }
    data.append("dealId", this.state.dealId);
    savePictures(data);
  }

  lsRedirect() {
    let jsonObj = {};

    jsonObj[constants.TERMST_LOCAL_STORAGE.NEEDS_COMPANY] = true;
    storeJsonInLocal(jsonObj);

    window.location.pathname = "/company/create";
  }

  updateManagerToAll = (checked) => {
    this.setState({
      inputAtProperty: checked,
    });
    const dealId = getKeyFromLocal(constants.TERMST_LOCAL_STORAGE.DEAL_ID);
    updateDeal(dealId, {
      isManagerSame: !checked,
      isPortifolio: true,
    });
  };

  updateKeyPrincipalToAll = (checked) => {
    this.setState({
      sponserAtProperty: checked,
    });
    const dealId = getKeyFromLocal(constants.TERMST_LOCAL_STORAGE.DEAL_ID);
    updateDeal(dealId, {
      isKeyPrincipalSame: !checked,
      isPortifolio: true,
    });
  };

  updateSourceAndUsesToAll = (checked) => {
    this.setState({
      sourcesAtProperty: checked,
    });
    const dealId = getKeyFromLocal(constants.TERMST_LOCAL_STORAGE.DEAL_ID);
    updateDeal(dealId, {
      isSourcesSame: !checked,
      isPortifolio: true,
    });
  };

  render() {
    const { whoami, deal, myCompany, isFetching } = this.props;

    if (myCompany && myCompany.message) {
      let jsonObj = {};

      jsonObj[constants.TERMST_LOCAL_STORAGE.NEEDS_COMPANY] = true;
      storeJsonInLocal(jsonObj);

      return (
        <Container fluid>
          <Row>
            <Col xs="12">
              <Alert variant="warning">
                You do not have a company! You need to create a company first in
                order to create a deal. You will be redirected in{" "}
                {this.state.seconds} seconds, or you can click{" "}
                <Button
                  variant="fake-link"
                  className="fake-link"
                  onClick={this.lsRedirect}
                >
                  here
                </Button>
                .
              </Alert>
            </Col>
          </Row>
          <Row>
            <Col xs="12" sm="8">
              <Row>
                <Col xs="10">
                  <Button
                    variant="primary"
                    className="deal-checklist-item outline"
                    block
                  >
                    Property Details
                    <Spacer size={5} />
                    <p className="font-12 bold">Must be filled out first.</p>
                    <FontAwesomeIcon icon={faSquare} />
                  </Button>
                </Col>
                <Col xs="2">
                  <Spacer size={10} />
                  <p className="d-none d-sm-block">Required</p>
                  <p className="d-block d-sm-none">*</p>
                </Col>
              </Row>
              <Row>
                <Col xs="10">
                  <Button
                    variant="primary"
                    className="deal-checklist-item outline"
                    block
                  >
                    Property Description
                    <FontAwesomeIcon icon={faSquare} />
                  </Button>
                </Col>
                <Col xs="2">
                  <Spacer size={10} />
                  <p className="d-none d-sm-block">Required</p>
                  <p className="d-block d-sm-none">*</p>
                </Col>
              </Row>
              <Row>
                <Col xs="10">
                  <Button
                    variant="primary"
                    className="deal-checklist-item outline"
                    block
                  >
                    Loan Request
                    <FontAwesomeIcon icon={faSquare} />
                  </Button>
                </Col>
                <Col xs="2">
                  <Spacer size={10} />
                  <p className="d-none d-sm-block">Required</p>
                  <p className="d-block d-sm-none">*</p>
                </Col>
              </Row>
              <Row>
                <Col xs="9" sm="10">
                  <Button
                    variant="primary"
                    className="deal-checklist-item outline"
                    block
                  >
                    Maps
                    <FontAwesomeIcon icon={faSquare} />
                  </Button>
                </Col>
                <Col xs="2"></Col>
              </Row>
              <Row>
                <Col xs="10">
                  <Button
                    variant="primary"
                    className="deal-checklist-item outline"
                    block
                  >
                    Sources and Uses
                    <FontAwesomeIcon icon={faSquare} />
                  </Button>
                </Col>
                <Col xs="2">
                  <Switch defaultChecked onChange={() => null} />
                </Col>
              </Row>
              <Row>
                <Col xs="10">
                  <Button
                    variant="primary"
                    className="deal-checklist-item outline"
                    block
                  >
                    Key Principals
                    <FontAwesomeIcon icon={faSquare} />
                  </Button>
                </Col>
                <Col xs="2"></Col>
              </Row>
              <Row>
                <Col xs="10">
                  <Button
                    variant="primary"
                    className="deal-checklist-item outline"
                    block
                  >
                    Manager
                    <FontAwesomeIcon icon={faSquare} />
                  </Button>
                </Col>
                <Col xs="2"></Col>
              </Row>
              <Row>
                <Col xs="10">
                  <Button
                    variant="primary"
                    className="deal-checklist-item outline"
                    block
                  >
                    Disclosures
                    <FontAwesomeIcon icon={faSquare} />
                  </Button>
                </Col>
                <Col xs="2"></Col>
              </Row>
              <Row>
                <Col xs="10">
                  <Button
                    variant="primary"
                    className="deal-checklist-item outline"
                    block
                  >
                    Photo Uploads
                    <FontAwesomeIcon icon={faSquare} />
                  </Button>
                </Col>
                <Col xs="2">
                  <Spacer size={10} />
                  <p className="d-none d-sm-block">Required</p>
                  <p className="d-block d-sm-none">*</p>
                </Col>
              </Row>
              <Row>
                <Col xs="10">
                  <Button
                    variant="primary"
                    className="deal-checklist-item outline"
                    block
                  >
                    Financial Uploads
                    <FontAwesomeIcon icon={faSquare} />
                  </Button>
                </Col>
                <Col xs="2">
                  <Spacer size={10} />
                  <p className="d-none d-sm-block">Required</p>
                  <p className="d-block d-sm-none">*</p>
                </Col>
              </Row>
              <Row>
                <Col xs="10">
                  <Button
                    variant="primary"
                    className="deal-checklist-item outline"
                    block
                  >
                    More Uploads
                  </Button>
                </Col>
                <Col xs="2"></Col>
              </Row>
            </Col>
          </Row>
        </Container>
      );
    } else if (
      whoami.role !== "Admin" &&
      (whoami.role === "Lender" ||
        (Object.keys(deal).length > 0 && deal.dealStatus !== "In progress"))
    ) {
      return <Redirect to="/dashboard" />;
    }
    return (
      <>
        {isFetching && <Loader />}
        <Container fluid>
          <Row>
            <Col xs="12" sm="8">
              {/* <Row>
                <Col xs="10">
                  <Button
                    as={Link}
                    to={"/deal/application/property-details"}
                    variant="primary"
                    className={
                      "deal-checklist-item " +
                      (deal && deal.propertyDetailsComplete
                        ? "field-complete"
                        : "outline")
                    }
                    block
                  >
                    Portofolio Summary
                   
                    {deal && deal.propertyDetailsComplete ? (
                      <FontAwesomeIcon icon={faCheckSquare} />
                    ) : (
                      <FontAwesomeIcon icon={faSquare} />
                    )}
                  </Button>
                </Col>
                <Col xs="2">
                  <Spacer size={10} />
                  {deal && !deal.propertyDetailsComplete && (
                    <>
                      <p className="d-none d-sm-block">Required</p>
                      <p className="d-block d-sm-none">*</p>
                    </>
                  )}
                </Col>
              </Row> */}
              <Row>
                <Col xs="10">
                  <Button
                    as={Link}
                    to={
                      deal && deal.propertyDetailsComplete
                        ? "/deal/application/portifolio-summary"
                        : "#"
                    }
                    variant="primary"
                    className={
                      "deal-checklist-item " +
                      (deal && deal.propertyDescriptionSet
                        ? "field-complete"
                        : "outline")
                    }
                    block
                  >
                    Portfolio Summary
                    {deal && deal.propertyDescriptionSet ? (
                      <FontAwesomeIcon icon={faCheckSquare} />
                    ) : (
                      <FontAwesomeIcon icon={faSquare} />
                    )}
                  </Button>
                </Col>
                <Col xs="2">
                  <Spacer size={10} />
                  {deal && !deal.propertyDescriptionSet && (
                    <>
                      <p className="d-none d-sm-block">Required</p>
                      <p className="d-block d-sm-none">*</p>
                    </>
                  )}
                </Col>
              </Row>
              <Row>
                <Col xs="10">
                  <Button
                    as={Link}
                    to={
                      (deal && deal.propertyDetailsComplete) || true
                        ? "/deal/application/loan-request"
                        : "#"
                    }
                    variant="primary"
                    className={
                      "deal-checklist-item " +
                      (deal && deal.loanRequest ? "field-complete" : "outline")
                    }
                    block
                  >
                    Loan Request
                    {deal && deal.loanRequest ? (
                      <FontAwesomeIcon icon={faCheckSquare} />
                    ) : (
                      <FontAwesomeIcon icon={faSquare} />
                    )}
                  </Button>
                </Col>
                <Col xs="2">
                  <Spacer size={10} />
                  {deal && !deal.loanRequest && (
                    <>
                      <p className="d-none d-sm-block">Required</p>
                      <p className="d-block d-sm-none">*</p>
                    </>
                  )}
                </Col>
              </Row>
              <Row>
                {deal && deal.loanRequest && (
                  <>
                    <Col xs="10">
                      <Button
                        as={Link}
                        to={
                          deal &&
                          deal.propertyDetailsComplete &&
                          deal.loanRequest
                            ? "/deal/application/sources-uses"
                            : "#"
                        }
                        variant="primary"
                        className={
                          "deal-checklist-item " +
                          (this.state.sourcesAtProperty
                            ? "field-disabled "
                            : "") +
                          (deal && deal.sourcesAndUses && deal.isSourcesSame
                            ? "field-complete"
                            : "outline")
                        }
                        block
                        disabled={this.state.sourcesAtProperty}
                      >
                        Sources and Uses
                        {deal && deal.sourcesAndUses ? (
                          <FontAwesomeIcon icon={faCheckSquare} />
                        ) : (
                          <FontAwesomeIcon icon={faSquare} />
                        )}
                      </Button>
                    </Col>
                    <Col xs="2">
                      <Switch
                        onChange={(checked) => {
                          WARNING_CONFIG.onOk = () =>
                            this.updateSourceAndUsesToAll(checked);
                          Modal.warning(WARNING_CONFIG);
                        }}
                        checked={this.state.sourcesAtProperty}
                        size="small"
                      />
                      <Tooltip title="Input at property Level" color="#108ee9">
                        <InfoCircleOutlined
                          style={{ fontSize: "16px", marginLeft: "3px" }}
                        />
                      </Tooltip>
                    </Col>
                  </>
                )}
              </Row>
              <Row>
                <Col xs="10">
                  <Button
                    as={Link}
                    to={
                      deal && deal.propertyDetailsComplete
                        ? "/deal/application/sponsor-guarantor"
                        : "#"
                    }
                    variant="primary"
                    className={
                      "deal-checklist-item " +
                      (deal && deal.sponsorGuarantor
                        ? "field-complete"
                        : "outline")
                    }
                    block
                  >
                    Key Principals
                    {deal && deal.sponsorGuarantor ? (
                      <FontAwesomeIcon icon={faCheckSquare} />
                    ) : (
                      <FontAwesomeIcon icon={faSquare} />
                    )}
                  </Button>
                </Col>
                {/* <Col xs="2">
                  <Switch
                    onChange={(checked) => {
                      WARNING_CONFIG.onOk = () =>
                        this.updateKeyPrincipalToAll(checked);
                      Modal.warning(WARNING_CONFIG);
                    }}
                    checked={this.state.sponserAtProperty}
                    size="small"
                  />
                  <Tooltip title="Input at property Level" color="#108ee9">
                    <InfoCircleOutlined
                      style={{ fontSize: "16px", marginLeft: "3px" }}
                    />
                  </Tooltip>
                </Col> */}
              </Row>
              <Row>
                <Col xs="10">
                  <Button
                    as={Link}
                    to={
                      deal && deal.propertyDetailsComplete
                        ? "/deal/application/manager"
                        : "#"
                    }
                    variant="primary"
                    className={
                      "deal-checklist-item " +
                      (this.state.inputAtProperty ? "field-disabled " : "") +
                      (deal && deal.manager && deal.isManagerSame
                        ? "field-complete"
                        : "outline")
                    }
                    block
                    disabled={this.state.inputAtProperty}
                  >
                    Manager
                    {deal && deal.manager ? (
                      <FontAwesomeIcon icon={faCheckSquare} />
                    ) : (
                      <FontAwesomeIcon icon={faSquare} />
                    )}
                  </Button>
                </Col>
                <Col xs="2">
                  <Switch
                    onChange={(checked) => {
                      WARNING_CONFIG.onOk = () =>
                        this.updateManagerToAll(checked);
                      Modal.warning(WARNING_CONFIG);
                    }}
                    checked={this.state.inputAtProperty}
                    size="small"
                  />
                  <Tooltip title="Input at property Level" color="#108ee9">
                    <InfoCircleOutlined
                      style={{ fontSize: "16px", marginLeft: "3px" }}
                    />
                  </Tooltip>
                </Col>
              </Row>
              <Row>
                <Col xs="10">
                  <Button
                    as={Link}
                    to={
                      deal && deal.propertyDetailsComplete
                        ? "/deal/application/critical-extras"
                        : "#"
                    }
                    variant="primary"
                    className={
                      "deal-checklist-item " +
                      (deal && deal.criticalExtras
                        ? "field-complete"
                        : "outline")
                    }
                    block
                  >
                    Disclosures
                    {deal && deal.criticalExtras ? (
                      <FontAwesomeIcon icon={faCheckSquare} />
                    ) : (
                      <FontAwesomeIcon icon={faSquare} />
                    )}
                  </Button>
                </Col>
                <Col xs="2"></Col>
              </Row>
              <Row>
                <Col xs="10">
                  <Button
                    as={Link}
                    to={
                      deal && deal.propertyDetailsComplete
                        ? "/deal/application/photos"
                        : "#"
                    }
                    variant="primary"
                    className={
                      "deal-checklist-item " +
                      (deal && deal.photoUploads ? "field-complete" : "outline")
                    }
                    block
                  >
                    Photo Uploads
                    {deal && deal.photoUploads ? (
                      <FontAwesomeIcon icon={faCheckSquare} />
                    ) : (
                      <FontAwesomeIcon icon={faSquare} />
                    )}
                  </Button>
                </Col>
                <Col xs="2">
                  <Spacer size={10} />
                  {deal && !deal.photoUploads && (
                    <>
                      <p className="d-none d-sm-block">Required</p>
                      <p className="d-block d-sm-none">*</p>
                    </>
                  )}
                </Col>
              </Row>
              <Row>
                <Col xs="10">
                  <Button
                    as={Link}
                    to={
                      this.state && this.state.dealId
                        ? {
                            pathname: "/deal/files/" + this.state.dealId,
                            state: { fromDeal: true },
                          }
                        : "#"
                    }
                    variant="primary"
                    className="deal-checklist-item outline"
                    block
                  >
                    More Uploads
                  </Button>
                </Col>
                <Col xs="2"></Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col className="text-right" xs="12">
              <Spacer size={20} />
              {deal.propertyDetailsComplete && (
                <Button
                  className="outline pl-2 pr-2"
                  as={Link}
                  to="/dashboard"
                  variant="primary"
                >
                  Save Draft
                </Button>
              )}
              <>
                {deal &&
                deal.propertyDetailsComplete &&
                deal.propertyDescriptionSet &&
                deal.photoUploads &&
                deal.loanRequest ? (
                  <Button
                    className="ml-2 pl-3 pr-3"
                    onClick={this.finishDeal}
                    variant="primary"
                  >
                    Submit Deal
                  </Button>
                ) : (
                  <></>
                )}
              </>
              <Spacer size={20} />
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    isFetching:
      state.action.GET_DEAL_BY_ID.isFetching ||
      state.action.SAVE_FINANCIALS.isFetching ||
      state.action.SAVE_PICTURES.isFetching,
    whoami: state.auth.whoami,
    myCompany: state.company.myCompany,
    deal: state.deal.deal,
    team: state.team.team,
  };
}

export default connect(mapStateToProps)(PortifolioLevel);
