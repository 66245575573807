import React, { useState, useCallback, useRef } from "react";
import { connect } from "react-redux";
import { Dropdown, Container, Row, Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import Spacer from "components/layout/Spacer";
import FileItem from "./FileItem";
import GridFileItem from "./GridFileItem";
import PopupPrompt from "components/ui/PopupPrompt";
import AddFileCategory from "containers/deals/FileManager/AddFileCategory";
import {
  ALLOWED_FILE_EXTS,
  ALLOWED_FILE_MANAGER_PICTURE_FILE_TYPES,
} from "./constants";
import { toast } from "react-toastify";
import FolderItemDropTarget from "./FolderItemDropTarget";
import GridFolderItemDropTarget from "./GridFolderItemDropTarget";

const Folder = (props) => {
  const [showPrompt, setShowPrompt] = useState(false);

  const handleShowPrompt = useCallback((value) => {
    setShowPrompt(value);
  }, []);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Button
      ref={ref}
      variant="folder-master"
      className="p-1"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </Button>
  ));

  const CustomMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) => child.props.children
            )}
          </ul>
        </div>
      );
    }
  );

  const {
    id,
    whoami,
    userId,
    category,
    onCategorySelect,
    node,
    nodes,
    getChildNodes,
    parentPermission,
    addFolder,
    handleUploadFile,
    isValidFiles,
    fetchFile,
    fetchFolder,
    removeFile,
    removeFolder,
    showSecuritySharing,
    showRenaming,
    showGrid,
    fromComms,
    addSelectedFile,
    removeSelectedFile,
    getSelectedFiles,
    canManage,
    canHideNode,
  } = props;

  const fileInput = useRef(null);

  const handleUploadFileClick = (event) => {
    if (node.name === "Pictures") {
      const files = event.target?.files || [];
      for (let file of files) {
        const ext = file.name.split(".").reverse()[0];
        if (
          !ALLOWED_FILE_MANAGER_PICTURE_FILE_TYPES.includes(
            `${ext.toLowerCase()}`
          )
        ) {
          toast.error(
            `Unsupported file. Allows only ${ALLOWED_FILE_MANAGER_PICTURE_FILE_TYPES.join(
              ", "
            )}`,
            {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
          return;
        }
      }
    }

    handleUploadFile(event, node._id);
  };

  return (
    <>
      <div>
        {showGrid ? (
          <Row className="m-0 grid-file-item-header">
            <input
              className="d-none"
              type="file"
              ref={fileInput}
              id={node._id}
              // onChange={(event) => {
              //   handleUploadFile(event, node._id);
              // }}
              onChange={handleUploadFileClick}
              accept={ALLOWED_FILE_EXTS.join(",")}
              multiple
            />
            <Col
              className={
                fromComms ? "font-16" : "font-16 mb-3 d-flex align-items-center"
              }
              xs={fromComms ? 8 : 12}
            >
              {!fromComms && (
                <Dropdown
                  className="d-inline folder-file-dropdown mr-3"
                  style={{ zIndex: 4 }}
                >
                  <Dropdown.Toggle as={CustomToggle}>
                    <h4 className="view-as">
                      <FontAwesomeIcon icon={faEllipsisV} />
                    </h4>
                  </Dropdown.Toggle>

                  <Dropdown.Menu as={CustomMenu}>
                    {node.ancestors.length <= 1 &&
                      node.name !== "Pictures" &&
                      canManage && (
                        <Dropdown.Item onClick={() => handleShowPrompt(true)}>
                          Add Folder
                        </Dropdown.Item>
                      )}
                    {((node.isFolder && node.ancestors.length === 1) ||
                      (node.isFolder && node.ancestors.length === 2)) &&
                      canManage && (
                        <Dropdown.Item
                          onClick={() => fileInput.current.click()}
                        >
                          Add Files
                        </Dropdown.Item>
                      )}

                    <Dropdown.Item
                      onClick={() => {
                        node.isFolder
                          ? fetchFolder(node._id, node.name)
                          : fetchFile(node._id, node.name);
                      }}
                    >
                      Download
                    </Dropdown.Item>
                    {canManage && (
                      <>
                        <Dropdown.Item
                          onClick={() => {
                            showRenaming(node._id);
                          }}
                        >
                          Edit
                        </Dropdown.Item>
                        {/* <Dropdown.Item
                          onClick={() => {
                            showSecuritySharing(node._id);
                          }}
                        >
                          Security & Sharing
                        </Dropdown.Item> */}
                        <Dropdown.Divider> </Dropdown.Divider>
                        <Dropdown.Item
                          onClick={() => {
                            removeFolder(node._id);
                          }}
                        >
                          Delete
                        </Dropdown.Item>
                      </>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              )}
              {category}
            </Col>
          </Row>
        ) : (
          <Row className="m-0 file-item-header">
            <input
              className="d-none"
              type="file"
              ref={fileInput}
              id={node._id}
              onChange={(event) => {
                handleUploadFile(event, node._id);
              }}
              accept={ALLOWED_FILE_EXTS.join(",")}
              multiple
            />
            <Col className="font-16" xs={fromComms ? 8 : undefined}>
              {category}
            </Col>
            <Col className="text-center" xs="2">
              # of Files
            </Col>
            {whoami?.role !== "Lender" && (
              <Col className="text-center" xs="2">
                Security
              </Col>
            )}
            <Col className="text-right" xs="3">
              {!fromComms && (
                <Dropdown className="d-inline folder-file-dropdown">
                  <Dropdown.Toggle as={CustomToggle}>
                    <h4 className="view-as">
                      <FontAwesomeIcon icon={faEllipsisV} />
                    </h4>
                  </Dropdown.Toggle>

                  <Dropdown.Menu as={CustomMenu}>
                    {node.ancestors.length <= 1 &&
                      node.name !== "Pictures" &&
                      canManage && (
                        <Dropdown.Item onClick={() => handleShowPrompt(true)}>
                          Add Folder
                        </Dropdown.Item>
                      )}
                    {((node.isFolder && node.ancestors.length === 1) ||
                      (node.isFolder && node.ancestors.length === 2)) &&
                      canManage && (
                        <Dropdown.Item
                          onClick={() => fileInput.current.click()}
                        >
                          Add Files
                        </Dropdown.Item>
                      )}
                    <Dropdown.Item
                      onClick={() => {
                        node.isFolder
                          ? fetchFolder(node._id, node.name)
                          : fetchFile(node._id, node.name);
                      }}
                    >
                      Download
                    </Dropdown.Item>
                    {canManage && (
                      <>
                        {node.isEditable && (
                          <Dropdown.Item
                            onClick={() => {
                              showRenaming(node._id);
                            }}
                          >
                            Edit
                          </Dropdown.Item>
                        )}
                        {node.isDeletable && (
                          <>
                            <Dropdown.Divider> </Dropdown.Divider>
                            <Dropdown.Item
                              onClick={() => {
                                removeFolder(node._id);
                              }}
                            >
                              Delete
                            </Dropdown.Item>
                          </>
                        )}
                      </>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </Col>
          </Row>
        )}
        {showGrid ? (
          <>
            {nodes.length > 0 ? (
              <Container className="grid-container" fluid>
                <Row>
                  {nodes.map((node) => {
                    if (canHideNode(node)) {
                      return null;
                    }
                    if (node.isFolder) {
                      const files = getChildNodes(node);
                      return (
                        <GridFolderItemDropTarget
                          key={node._id}
                          userId={userId}
                          parentPermission={parentPermission}
                          handleUploadFile={handleUploadFile}
                          fetchFile={fetchFile}
                          fetchFolder={fetchFolder}
                          removeFile={removeFile}
                          removeFolder={removeFolder}
                          files={files}
                          showSecuritySharing={showSecuritySharing}
                          showRenaming={showRenaming}
                          canManage={canManage}
                          {...node}
                        />
                      );
                    } else {
                      return (
                        <GridFileItem
                          key={node._id}
                          userId={userId}
                          parentPermission={parentPermission}
                          fetchFile={fetchFile}
                          removeFile={removeFile}
                          showSecuritySharing={showSecuritySharing}
                          showRenaming={showRenaming}
                          canManage={canManage}
                          {...node}
                        />
                      );
                    }
                  })}
                  {canManage && (
                    <div className="mb-3 pl-3 pr-3">
                      <Button
                        className="d-flex justify-content-center align-items-center p-0"
                        variant="grid-upload"
                        onClick={() => handleShowPrompt(true)}
                      >
                        <span>+ Add Folder</span>
                      </Button>
                    </div>
                  )}
                </Row>
              </Container>
            ) : (
              <p className="text-center pt-3">
                This category contains no files yet.
              </p>
            )}
          </>
        ) : (
          <>
            {nodes.length > 0 ? (
              <>
                {nodes.map((node) => {
                  if (canHideNode(node)) {
                    return null;
                  }

                  if (node.isFolder) {
                    const files = getChildNodes(node);
                    return (
                      <FolderItemDropTarget
                        key={node._id}
                        userId={userId}
                        onCategorySelect={onCategorySelect}
                        parentPermission={parentPermission}
                        handleUploadFile={handleUploadFile}
                        isValidFiles={isValidFiles}
                        fetchFile={fetchFile}
                        fetchFolder={fetchFolder}
                        removeFile={removeFile}
                        removeFolder={removeFolder}
                        files={files}
                        showSecuritySharing={showSecuritySharing}
                        showRenaming={showRenaming}
                        fromComms={fromComms}
                        addSelectedFile={addSelectedFile}
                        removeSelectedFile={removeSelectedFile}
                        getSelectedFiles={getSelectedFiles}
                        canManage={canManage}
                        {...node}
                      />
                    );
                  } else {
                    return (
                      <FileItem
                        key={node._id}
                        userId={userId}
                        parentPermission={parentPermission}
                        fetchFile={fetchFile}
                        removeFile={removeFile}
                        showSecuritySharing={showSecuritySharing}
                        showRenaming={showRenaming}
                        fromComms={fromComms}
                        addSelectedFile={addSelectedFile}
                        removeSelectedFile={removeSelectedFile}
                        getSelectedFiles={getSelectedFiles}
                        canManage={canManage}
                        fileKey={node.key}
                        {...node}
                      />
                    );
                  }
                })}
              </>
            ) : (
              <p className="text-center pt-3">
                This category contains no files yet.
              </p>
            )}
          </>
        )}
        <Spacer size={20} />
      </div>
      <PopupPrompt
        promptTitle={"Create Folder"}
        promptBody={
          <AddFileCategory
            id={id}
            placeholder="Enter folder name"
            label="Folder"
            onSubmit={(id, e) => {
              setShowPrompt(false);
              addFolder(id, e);
            }}
          />
        }
        show={showPrompt}
        onHide={() => handleShowPrompt(false)}
        onCancel={() => handleShowPrompt(false)}
      />
    </>
  );
};

function mapStateToProps(state) {
  return {
    whoami: state.auth.whoami,
  };
}

export default connect(mapStateToProps)(Folder);
