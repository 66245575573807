import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Row,
  Select,
  Spin,
} from "antd";
import { connect } from "react-redux";
import { createDeal, getDropdown, getTeamsByCompanyId } from "redux/actions";
import constants from "config/constants";
import { storeJsonInLocal } from "utils/lsUtils";
import { NotAllowedToCreateDeal } from "components/emptystate/NoResultsFound";
/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: "${label} is required!",
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

const DealCreateModal = ({
  show,
  setIsModalOpen,
  myCompany,
  teams,
  action,
  dropdowns,
  history,
}) => {
  const [form] = Form.useForm();
  const isPortifolio = Form.useWatch("isPortifolio", form);
  useEffect(() => {
    if (myCompany._id) {
      getTeamsByCompanyId(myCompany._id);
      getDropdown("purposeOfFinancing");
      getDropdown("loanType");
    }
  }, [myCompany._id]);

  const handleOk = () => {
    form.validateFields();
    const values = form.getFieldsValue();
    const { propertyName, teamId, loanType, purpose, propertyCount } = values;
    if (propertyName && teamId) {
      const deal = {
        propertyDetailsComplete: true,
        teamSet: true,
        dealStatus: "In progress",
        loanType,
        purpose,
        teamId,
        propertyName,
        isPortifolio,
        propertyCount,
        propertyType: "",
        propertyAddress: "",
      };
      if (isPortifolio) {
        const jsonObj = {
          [constants.TERMST_LOCAL_STORAGE.PORTIFOLIO_DEAL_COUNT]: propertyCount,
        };
        storeJsonInLocal(jsonObj);
        createDeal({
          ...deal,
        });
      } else {
        history.push({
          pathname: "/deal/application",
          state: { propertyName, teamId, loanType, purpose },
        });
      }
    }

    // setIsModalOpen(false);
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  const onCreateDeal = (values) => {
    console.log("values", values);

    // window.href="deal/portifolio-application"
  };

  return (
    <Spin spinning={action.GET_MY_COMPANY.isFetching}>
      <Modal
        title={<h3>Lets Get Started!</h3>}
        open={show}
        onOk={handleOk}
        onCancel={handleCancel}
        width={900}
        footer={!myCompany._id ? null : undefined}
        okText="Continue"
      >
        {!myCompany._id ? (
          <NotAllowedToCreateDeal />
        ) : (
          <Form
            form={form}
            style={{ border: "none" }}
            name="validateOnly"
            layout="vertical"
            autoComplete="off"
            validateMessages={validateMessages}
            onFinish={onCreateDeal}
            initialValues={{
              propertyCount: 2,
            }}
          >
            <Row>
              <Col span={10}>
                <Form.Item
                  name="propertyName"
                  label="GIVE YOUR DEAL A NAME"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={10} offset={4}>
                <Form.Item
                  label="Loan Purpose"
                  rules={[{ required: true }]}
                  name="purpose"
                >
                  <Select loading={action.GET_DROPDOWNS.isFetching}>
                    {dropdowns["purposeOfFinancing"]?.map((item) => {
                      return (
                        <Select.Option value={item.value} key={item.value}>
                          {item.label}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={10}>
                <Form.Item
                  label="Loan Type"
                  rules={[{ required: true }]}
                  name="loanType"
                >
                  <Select loading={action.GET_DROPDOWNS.isFetching}>
                    {dropdowns["loanType"]?.map((item) => {
                      return (
                        <Select.Option value={item.value} key={item.value}>
                          {item.label}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={10} offset={4}>
                <Form.Item
                  label="Assign Deal Team"
                  rules={[{ required: true }]}
                  name="teamId"
                >
                  <Select loading={action.GET_TEAMS_BY_COMPANY_ID.isFetching}>
                    {teams.map((team) => {
                      return (
                        <Select.Option value={team._id} key={team._id}>
                          {team.teamName}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={10}>
                <Form.Item label="Is This a Portfolio" name="isPortifolio">
                  <Radio.Group>
                    <Radio value={true} key="yes">
                      {" "}
                      YES{" "}
                    </Radio>
                    <Radio value={false} key="no">
                      {" "}
                      NO{" "}
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              {isPortifolio && (
                <Col span={10} offset={4}>
                  <Form.Item
                    label="Number of Properties"
                    rules={[{ type: "number", min: 2, max: 10 }]}
                    name="propertyCount"
                  >
                    <InputNumber />
                  </Form.Item>
                </Col>
              )}
            </Row>
          </Form>
        )}
      </Modal>
    </Spin>
  );
};
function mapStateToProps(state) {
  return {
    myCompany: state.company.myCompany,
    teams: state.team.teams,
    action: state.action,
    dropdowns: state.program.dropdowns,
  };
}

export default connect(mapStateToProps)(DealCreateModal);
