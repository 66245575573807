import React, { useMemo } from "react";
import { Avatar, Checkbox, Tooltip } from "antd";
import {
  formatRelativeDate,
  getReceiverNames,
  getSenderName,
} from "../inboxUtils";
import {
  getConversationById,
  getConversationByIdOptimisticUpdate,
} from "redux/actions";
import { useSelector } from "react-redux";
import { conversationStatuses, conversationType } from "../enums";
import { getInitials } from "utils/commonUtils";
import clsx from "clsx";
import { QuestionCircleOutlined } from "@ant-design/icons";

const ConversationItem = ({ status, item, isSelected, onCheck }) => {
  const whoami = useSelector((state) => state.auth.whoami);
  const myCompany = useSelector((state) => state.company.myCompany);
  const myCompanyTeams = useSelector((state) => state.team.teams);
  const conversationDetails = useSelector(
    (state) => state.conversation.conversationDetails
  );

  const myTeamMembers = useMemo(
    () =>
      myCompanyTeams.reduce((acc, curr) => [...acc, ...curr.teamMembers], []),
    [myCompanyTeams]
  );

  const {
    _id,
    _senderId,
    _senderCompanyId,
    _receiverCompanyIds,
    type,
    messages,
    readBy,
  } = item;
  const [sender] = _senderId;
  const [senderCompany] = _senderCompanyId;
  const [firstMessage, ...replies] = messages;

  const senderName = getSenderName(sender, senderCompany);
  const receiverNames = getReceiverNames(
    _receiverCompanyIds,
    senderCompany,
    status,
    whoami,
    myCompany
  );

  const selected = _id === conversationDetails?._id;

  const handleClickItem = (e) => {
    if (e.target.type === "checkbox") return;
    getConversationByIdOptimisticUpdate(item);
    getConversationById(_id);
  };

  const onConvSelect = (e) => {
    onCheck(e.target.checked, _id);
  };

  const isBorrower = whoami?.role === "Borrower";
  const isMessage = type === conversationType.Message;
  const isReadByMe = readBy.includes(whoami?._id);
  const isRespondedByOtherTeamMembers = myTeamMembers.some((m) =>
    replies.some((reply) => reply.senderId === m.userId._id)
  );
  const hasReplies = !!replies.length;

  return (
    <div
      className={clsx("message-card cursor-pointer", type, status, {
        selected,
        hasReplies,
        isBorrower,
        isReadByMe,
        isNotReadByMe: !isReadByMe,
        isRespondedByOtherTeamMembers,
        isNotRespondedByOtherTeamMembers: !isRespondedByOtherTeamMembers,
      })}
      onClick={handleClickItem}
    >
      <div className="message-avatar">
        <Tooltip title="Select">
          <Checkbox checked={isSelected} onChange={onConvSelect} />
        </Tooltip>
        {status !== conversationStatuses.sent && (
          <Tooltip title={senderName}>
            <Avatar style={{ minWidth: 32 }} src={sender.profileImageXS}>
              {getInitials(senderName)}
            </Avatar>
          </Tooltip>
        )}
      </div>
      <div className="message-wrapper">
        <div className="message-details">
          {status === conversationStatuses.sent ? (
            <span title={receiverNames} className="message-receiver">
              {"To: "}
              <b>{receiverNames}</b>
            </span>
          ) : (
            <span className="message-title">
              {isMessage ? (
                firstMessage.subject
              ) : (
                <>
                  <p>
                    <Tooltip title="Question">
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </p>
                  <span
                    className="message-content ml-2"
                    dangerouslySetInnerHTML={{
                      __html: firstMessage.message,
                    }}
                  />
                </>
              )}
            </span>
          )}
          <span className="message-datetime font-12">
            {formatRelativeDate(firstMessage.createdAt)}
          </span>
        </div>
        {/* <div
          className="message-content"
          dangerouslySetInnerHTML={{
            __html: firstMessage.message,
          }}
        /> */}
      </div>
    </div>
  );
};

export default ConversationItem;
