import { dispatch } from "config/store";
import { actionTypes } from "./actionTypes";

export const storeSections = (data) => {
  const action = {
    type: actionTypes.STORE_SECTIONS,
    data,
  };

  dispatch(action);
};

export const clearTermsheet = () => {
  const action = {
    type: actionTypes.CLEAR_TERMSHEET,
  };

  dispatch(action);
};

export const clearQuotes = () => {
  const action = {
    type: actionTypes.CLEAR_QUOTES,
  };

  dispatch(action);
};

export const storeCompareQuotes = (data) => {
  const action = {
    type: actionTypes.STORE_COMPARE_QUOTES,
    data,
  };

  dispatch(action);
};

export const storeQuoteMatrices = (data) => {
  const action = {
    type: actionTypes.STORE_QUOTE_MATRICES,
    data,
  };

  dispatch(action);
};

export const createTermsheet = (data) => {
  const action = {
    type: actionTypes.CREATE_TERMSHEET,
    config: {
      method: "post",
      url: `termsheets/create`,
      data,
    },
  };

  dispatch(action);
};
export const createTermsheetColumn = (termsheetId, data) => {
  const action = {
    type: actionTypes.CREATE_TERMSHEET_COLUMN,
    config: {
      method: "put",
      url: `/termsheets/update/${termsheetId}/column/create`,
      data,
    },
  };

  dispatch(action);
};

export const createTermsheetLanguage = (data) => {
  const action = {
    type: actionTypes.CREATE_TERMSHEET_LANGUAGE,
    config: {
      method: "post",
      url: `/language/create`,
      data,
    },
  };
  dispatch(action);
};

export const getTermsheetLanguages = (section) => {
  const action = {
    type: actionTypes.GET_TERMSHEET_LANGUAGE,
    config: {
      method: "get",
      url: `/language/?section=${section}`,
    },
  };
  dispatch(action);
};

export const getQuotes = ({
  dealId,
  termsheetId,
  status,
  archived,
  starred,
  order,
  orderColumn,
}) => {
  const action = {
    type: actionTypes.GET_QUOTES,
    config: {
      method: "get",
      url: `/quotes/?dealId=${dealId}${
        termsheetId ? `&termsheetId=${termsheetId}` : ""
      }${status ? `&status=${status}` : ""}${
        archived !== "" && archived !== undefined ? `&archived=${archived}` : ""
      }${starred !== "" && starred !== undefined ? `&starred=${starred}` : ""}${
        orderColumn ? `&order=${order}&orderColumn=${orderColumn}` : ""
      }`,
    },
  };
  dispatch(action);
};

export const getQuote = (id) => {
  const action = {
    type: actionTypes.GET_QUOTE,
    config: {
      method: "get",
      url: `quotes/${id}`,
    },
  };
  dispatch(action);
};

export const createQuote = (data) => {
  const action = {
    type: actionTypes.CREATE_QUOTE,
    config: {
      method: "post",
      url: `quotes/create`,
      data,
    },
  };
  dispatch(action);
};
export const updateQuote = (quoteId, data) => {
  const action = {
    type: actionTypes.UPDATE_QUOTE,
    config: {
      method: "PUT",
      url: `quotes/update/${quoteId}`,
      data,
    },
  };
  dispatch(action);
};
export const deleteQuote = (quoteId) => {
  const action = {
    type: actionTypes.DELETE_QUOTE,
    config: {
      method: "put",
      url: `quotes/delete/${quoteId}`,
    },
  };
  dispatch(action);
};

export const archiveQuote = (quoteId) => {
  const action = {
    type: actionTypes.ARCHIVE_QUOTE,
    config: {
      method: "put",
      url: `quotes/archive/${quoteId}`,
    },
  };
  dispatch(action);
};

export const starQuote = (quoteId) => {
  const action = {
    type: actionTypes.STAR_QUOTE,
    config: {
      method: "put",
      url: `quotes/star/${quoteId}`,
    },
  };
  dispatch(action);
};

export const bulkArchiveQuote = (data) => {
  const action = {
    type: actionTypes.BULK_ARCHIVE_QUOTE,
    config: {
      method: "put",
      url: `quotes/bulk/archive`,
      data,
    },
  };
  dispatch(action);
};

export const bulkStarQuote = (data) => {
  const action = {
    type: actionTypes.BULK_STAR_QUOTE,
    config: {
      method: "put",
      url: `quotes/bulk/star`,
      data,
    },
  };
  dispatch(action);
};

export const getTermsheet = (termsheetId) => {
  const action = {
    type: actionTypes.GET_TERMSHEET,
    config: {
      method: "get",
      url: `/termsheets/get/${termsheetId}`,
    },
  };
  dispatch(action);
};

export const getAdminDefaultTermsheet = (termsheetId) => {
  const action = {
    type: actionTypes.GET_ADMIN_DEFAULT_TERMSHEET,
    config: {
      method: "get",
      url: `/termsheets/get/${termsheetId}?isDefault=true`,
    },
  };
  dispatch(action);
};

export const updateTermsheet = (termsheetId, data) => {
  const action = {
    type: actionTypes.UPDATE_TERMSHEET,
    config: {
      method: "put",
      url: `/termsheets/update/${termsheetId}`,
      data,
    },
  };
  dispatch(action);
};

export const getTermsheets = (
  dealId,
  isTemplate,
  page,
  limit,
  options = {}
) => {
  const action = {
    type: actionTypes.GET_TERMSHEETS,
    config: {
      method: "get",
      url: `/termsheets/?dealId=${dealId}&isTemplate=${isTemplate}&companyId=${
        options.companyId || ""
      }&page=${page}&limit=${limit}&order=DESC`,
    },
  };
  dispatch(action);
};

export const getManageTermsheets = (
  query = { limit: 100, order: "ASC", orderColumn: "createdAt", page: 1 }
) => {
  const action = {
    type: actionTypes.GET_MANAGE_TERMSHEETS,
    config: {
      method: "get",
      url: `/termsheets?limit=${query.limit}&order=${query.order}&orderColumn=${
        query.orderColumn
      }&page=${query.page}&isTemplate=${query.isTemplate}&companyId=${
        query.companyId || ""
      }`,
    },
  };

  dispatch(action);
};
