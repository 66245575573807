import { dispatch } from "config/store";
import { actionTypes } from "./actionTypes";

export const createDeal = (data) => {
  const action = {
    type: actionTypes.CREATE_DEAL,
    config: {
      method: "post",
      url: `/deals/create`,
      data,
    },
  };

  dispatch(action);
};

export const addProperty = (data) => {
  const action = {
    type: actionTypes.ADD_PROPERTY,
    config: {
      method: "post",
      url: `/deals/addProperty`,
      data,
    },
  };

  dispatch(action);
};
export const updateDeal = (dealId, data) => {
  const action = {
    type: actionTypes.UPDATE_DEAL,
    config: {
      method: "put",
      url: `/deals/update/${dealId}`,
      data,
    },
  };

  dispatch(action);
};

export const getDeals = (params) => {
  const { page, listOffset: limit } = params.pagination || {};
  const action = {
    type: actionTypes.GET_DEALS,
    config: {
      method: "get",
      url: `/deals?order=DESC&isPublic=${params.isPublic}${
        limit ? `&limit=${limit}` : ""
      }${page ? `&page=${page}` : ""}&archived=${params.archived}&dealStatus=${
        params.dealStatus ? params.dealStatus : ""
      }&companyId=${params.companyId ? params.companyId : ""}&addToDeal=${
        params.addToDeal ? params.addToDeal : ""
      }${params.search ? `&search=${params.search}` : ""}${
        params.populate ? "&populate=true" : ""
      }`,
    },
  };

  dispatch(action);
};

export const getFilteredDeals = (params) => {
  const { page, listOffset: limit } = params.pagination || {};

  const action = {
    type: actionTypes.GET_FILTERED_DEALS,
    config: {
      method: "get",
      url: `/deals?order=DESC&propertyType=${params.propertyType}&loanType=${
        params.loanType
      }&loanAmountMin=${params.loanAmountMin}&loanAmountMax=${
        params.loanAmountMax
      }&dealStatus=${params.dealStatus}${limit ? `&limit=${limit}` : ""}${
        page ? `&page=${page}` : ""
      }&archived=${params.archived}`,
    },
  };

  dispatch(action);
};

export const searchDeals = (params) => {
  const action = {
    type: actionTypes.SEARCH_DEALS,
    config: {
      method: "get",
      url: `/deals?order=DESC&search=${
        params && params.search ? params.search : ""
      }&isPublic=${params.isPublic ? params.isPublic : false}&activeDeals=${
        params.activeDeals ? params.activeDeals : ""
      }&isHotel=${params.isHotel ? params.isHotel : ""}&isApartment=${
        params.isApartment ? params.isApartment : ""
      }&isRefinance=${params.isRefinance ? params.isRefinance : ""}`,
    },
  };

  dispatch(action);
};

export const getDealById = (dealId, params) => {
  const action = {
    type: actionTypes.GET_DEAL_BY_ID,
    config: {
      method: "get",
      url: `/deals/${dealId}?isPublic=true&archivedNonArchived=true${
        params?.populate ? "&populate=true" : ""
      }`,
    },
  };

  dispatch(action);
};

export const clearDealDetails = () => {
  const action = {
    type: actionTypes.CLEAR_DEAL_DETAILS,
  };

  dispatch(action);
};

export const archiveDeal = (dealId) => {
  const action = {
    type: actionTypes.ARCHIVE_DEAL,
    config: {
      method: "put",
      url: `/deals/archive/${dealId}`,
    },
  };

  dispatch(action);
};

export const getDealNotifications = (data) => {
  const action = {
    type: actionTypes.GET_DEAL_NOTIFICATIONS,
    config: {
      method: "post",
      url: `/notifications`,
      data,
    },
  };

  dispatch(action);
};

export const enableDealNotifications = (data) => {
  const action = {
    type: actionTypes.ENABLE_DEAL_NOTIFICATIONS,
    config: {
      method: "put",
      url: `/notifications/re-enable`,
      data,
    },
  };

  dispatch(action);
};

export const disableDealNotifications = (data) => {
  const action = {
    type: actionTypes.DISABLE_DEAL_NOTIFICATIONS,
    config: {
      method: "post",
      url: `/notifications/disable`,
      data,
    },
  };

  dispatch(action);
};

export const addSourcesToDeal = (dealId, data) => {
  const action = {
    type: actionTypes.ADD_SOURCES_TO_DEAL,
    config: {
      method: "put",
      url: `/deals/update/${dealId}/sources`,
      data,
    },
  };

  dispatch(action);
};

export const addUsesToDeal = (dealId, data) => {
  const action = {
    type: actionTypes.ADD_USES_TO_DEAL,
    config: {
      method: "put",
      url: `/deals/update/${dealId}/uses`,
      data,
    },
  };

  dispatch(action);
};

export const removeSourcesFromDeal = (dealId, data) => {
  const action = {
    type: actionTypes.REMOVE_SOURCES_FROM_DEAL,
    config: {
      method: "put",
      url: `/deals/update/${dealId}/sources`,
      data,
    },
  };

  dispatch(action);
};

export const removeUsesFromDeal = (dealId, data) => {
  const action = {
    type: actionTypes.REMOVE_USES_FROM_DEAL,
    config: {
      method: "put",
      url: `/deals/update/${dealId}/uses`,
      data,
    },
  };

  dispatch(action);
};

export const addRefinanceSourcesToDeal = (dealId, data) => {
  const action = {
    type: actionTypes.ADD_REFINANCE_SOURCES_TO_DEAL,
    config: {
      method: "put",
      url: `/deals/update/${dealId}/refinancesources`,
      data,
    },
  };

  dispatch(action);
};

export const addRefinanceUsesToDeal = (dealId, data) => {
  const action = {
    type: actionTypes.ADD_REFINANCE_USES_TO_DEAL,
    config: {
      method: "put",
      url: `/deals/update/${dealId}/refinanceuses`,
      data,
    },
  };

  dispatch(action);
};

export const removeRefinanceSourcesFromDeal = (dealId, data) => {
  const action = {
    type: actionTypes.REMOVE_REFINANCE_SOURCES_FROM_DEAL,
    config: {
      method: "put",
      url: `/deals/update/${dealId}/refinancesources`,
      data,
    },
  };

  dispatch(action);
};

export const removeRefinanceUsesFromDeal = (dealId, data) => {
  const action = {
    type: actionTypes.REMOVE_REFINANCE_USES_FROM_DEAL,
    config: {
      method: "put",
      url: `/deals/update/${dealId}/refinanceuses`,
      data,
    },
  };

  dispatch(action);
};

export const saveFinancials = (data) => {
  const action = {
    type: actionTypes.SAVE_FINANCIALS,
    config: {
      method: "post",
      url: `/dealfiles/financials`,
      data,
    },
  };
  dispatch(action);
};

export const savePictures = (data) => {
  const action = {
    type: actionTypes.SAVE_PICTURES,
    config: {
      method: "post",
      url: `/dealfiles/pictures`,
      data,
    },
  };
  dispatch(action);
};

export const savePrimaryPicture = (data) => {
  const action = {
    type: actionTypes.SAVE_PRIMARY_PICTURE,
    config: {
      method: "post",
      url: `/dealfiles/picture/primary`,
      data,
    },
  };
  dispatch(action);
};

export const assignTeamToDeal = (dealId, companyId, data) => {
  const action = {
    type: actionTypes.ASSIGN_TEAM_TO_DEAL,
    config: {
      method: "put",
      url: `/deals/update/${dealId}/lenders/members/${companyId}/assign`,
      data,
    },
  };
  dispatch(action);
};

export const assignBorrowerTeamToDeal = (dealId, data) => {
  const action = {
    type: actionTypes.ASSIGN_BORROWER_TEAM_TO_DEAL,
    config: {
      method: "put",
      url: `/deals/update/${dealId}/assignBorrowerTeam`,
      data,
    },
  };
  dispatch(action);
};

export const passDeal = (dealId, data) => {
  const action = {
    type: actionTypes.PASS_DEAL,
    config: {
      method: "put",
      url: `/deals/${dealId}/pass`,
      data,
    },
  };
  dispatch(action);
};

export const addDealCard = (dealId, data) => {
  const action = {
    type: actionTypes.ADD_DEAL_CARD,
    config: {
      method: "post",
      url: `/deals/${dealId}/card`,
      data,
    },
  };

  dispatch(action);
};

export const editDealCard = (dealId, data) => {
  const action = {
    type: actionTypes.EDIT_DEAL_CARD,
    config: {
      method: "put",
      url: `/deals/${dealId}/card/${data._id}`,
      data,
    },
  };

  dispatch(action);
};

export const deleteDealCard = (dealId, data) => {
  const action = {
    type: actionTypes.DELETE_DEAL_CARD,
    config: {
      method: "delete",
      url: `/deals/${dealId}/card/${data._id}`,
    },
  };

  dispatch(action);
};

export const hiddenDealCard = (dealId, data) => {
  const action = {
    type: actionTypes.HIDDEN_DEAL_CARD,
    config: {
      method: "put",
      url: `/deals/${dealId}/hiddenDealCard`,
      data,
    },
  };

  dispatch(action);
};

export const reorderDealCards = (dealId, data) => {
  const action = {
    type: actionTypes.REORDER_DEAL_CARDS,
    config: {
      method: "post",
      url: `/deals/${dealId}/reorderCards`,
      data,
    },
  };

  dispatch(action);
};

export const updateDealManager = (dealId, data) => {
  const action = {
    type: actionTypes.UPDATE_DEAL_MANAGER,
    config: {
      method: "put",
      url: `/deals/${dealId}/updateDealManager`,
      data,
    },
  };

  dispatch(action);
};
