import React from "react";
import { Col, Button, Row, Form } from "react-bootstrap";
import { Field, reduxForm } from "redux-form";
import { reduxFormInput } from "components/form/ReduxForm";
import { required } from "utils/validation";
import Spacer from "components/layout/Spacer";

class AddFileCategory extends React.Component {
  constructor(props) {
    super(props);
    this.addFileCategory = this.addFileCategory.bind(this);
  }

  addFileCategory(data) {
    const { onSubmit, id } = this.props;
    onSubmit(id, data);
  }

  render() {
    const { handleSubmit, placeholder, label } = this.props;

    return (
      <Form
        className="full-width no-border no-padding"
        onSubmit={handleSubmit(this.addFileCategory)}
      >
        <Row>
          <Col xs="12">
            <Field
              className="form-control"
              component={reduxFormInput}
              validate={[required]}
              name="name"
              type="text"
              placeholder={placeholder}
              label={"*" + label + " name"}
            />
            <Spacer size={20} />
          </Col>
          <Col className="text-right" xs="12">
            <Button type="submit" variant="primary">
              Create
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default reduxForm({
  form: "AddFileCategory",
  enableReinitialize: true,
})(AddFileCategory);
