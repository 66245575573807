import React from "react";
import { connect } from "react-redux";
import { Form, Button, Row, Col } from "react-bootstrap";
import { Field, reduxForm, change } from "redux-form";
import { required } from "utils/validation";
import { reduxFormSelect, reduxFormInput } from "components/form/ReduxForm";
import { updateTeam, getCompanyMembers, updateTeamName } from "redux/actions";
import { getKeyFromLocal } from "utils/lsUtils";
import { toast } from "react-toastify";
import Spacer from "components/layout/Spacer";
import constants from "config/constants";
import axios from "axios";

class TeamUpdate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      teamMembers: [],
      membersList: [],
      addMembers: [],
      deleteMembers: [],
      leader: "",
      isSubmitting: false,
    };

    this.leaderRef = React.createRef();
    this.memberRef = React.createRef();

    this.submitFn = this.submitFn.bind(this);
    this.submitError = this.submitError.bind(this);
    this.addMember = this.addMember.bind(this);
    this.removeMember = this.removeMember.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.members !== this.props.members) {
      const { members } = this.props;

      members.map((member) => {
        this.setState((prevState) => ({
          membersList: [
            ...prevState.membersList,
            {
              label: member.firstName + " " + member.lastName,
              value: member._id,
              isTeamLeaderForATeam: member.isTeamLeaderForATeam,
            },
          ],
        }));

        return true;
      });
    }
  }

  componentDidMount() {
    const { team, history, companyId } = this.props;

    if (!companyId) {
      history.goBack();
    }

    this.setState({ teamMembers: [] });

    team.teamMembers.map((member) => {
      if (member.globalRole !== "Team Leader") {
        this.setState((prevState) => ({
          teamMembers: [
            ...prevState.teamMembers,
            {
              label: member.userId.firstName + " " + member.userId.lastName,
              value: member.userId._id,
            },
          ],
        }));
      } else {
        this.setState({ leader: member.userId._id });
      }

      return true;
    });

    getCompanyMembers(companyId);
  }

  submitFn(data) {
    const { team, closePrompt } = this.props;
    const teamId = team._id;

    let canSubmit = this.state.teamMembers.some((member) => {
      if (member.value === data.teamLeader.value) {
        return true;
      } else {
        return false;
      }
    });

    if (data.teamLeader.isTeamLeaderForATeam) {
      canSubmit = true;

      this.submitError("Team leader cannot be a team leader for another team!");
    } else {
      if (!canSubmit) {
        if (data.teamLeader.value !== this.state.leader) {
          const origins = constants.API_ORIGINS;
          let endPoint = constants.API_PROTOCOL + origins[window.location.host];
          const jwtToken = getKeyFromLocal(
            constants.TERMST_LOCAL_STORAGE.JWT_KEY
          );
          axios
            .put(
              `${endPoint}/teams/update/${teamId}/members`,
              {
                remove: this.state.leader,
              },
              {
                headers: { accessToken: jwtToken },
                responseType: "arraybuffer",
              }
            )
            .then(() => {
              axios
                .put(
                  `${endPoint}/teams/update/${teamId}/members`,
                  {
                    add: data.teamLeader.value,
                    globalRole: "Team Leader",
                  },
                  {
                    headers: { accessToken: jwtToken },
                    responseType: "arraybuffer",
                  }
                )
                .then(() => {
                  updateTeamName(teamId, {
                    teamName: data.teamLeader.label + "'s Team",
                  });
                })
                .catch(() => {});
            })
            .catch(() => {});
        }

        this.state.deleteMembers.map((id) => {
          updateTeam(teamId, {
            remove: id,
          });

          return true;
        });

        this.state.addMembers.map((member) => {
          updateTeam(teamId, {
            add: member.value,
            globalRole: "Team Member",
          });

          return true;
        });

        closePrompt();
      } else {
        this.submitError("Team leader cannot also be a member!");
      }
    }
  }

  submitError(text) {
    toast.error(text, {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  addMember(data) {
    const { dispatch } = this.props;

    if (
      this.leaderRef.current.value &&
      data.value === this.leaderRef.current.value.value
    ) {
      this.submitError("Member is already the leader!");
    } else {
      const hasMember = this.state.teamMembers.some((member) => {
        if (member.value === data.value) {
          return true;
        } else {
          return false;
        }
      });

      if (hasMember) {
        this.submitError("Member already added!");
      } else {
        this.setState((prevState) => ({
          teamMembers: [...prevState.teamMembers, data],
          addMembers: [...prevState.addMembers, data],
        }));
      }
    }

    dispatch(change("UpdateTeam", "teamMember", null));
  }

  removeMember(id) {
    this.setState((prevState) => ({
      deleteMembers: [...prevState.deleteMembers, id],
    }));

    this.setState({
      teamMembers: this.state.teamMembers.filter((member) => {
        return member.value !== id;
      }),
    });
  }

  render() {
    const { action, handleSubmit } = this.props;

    return (
      <>
        <Form
          className="full-width no-border no-padding"
          onSubmit={handleSubmit(this.submitFn)}
        >
          <Row>
            <Col xs="12">
              <h4 className="bold">Leader</h4>
              <Spacer size={20} />
            </Col>
            <Col xs="12">
              <Field
                ref={this.leaderRef}
                className="form-control"
                component={reduxFormSelect}
                validate={[required]}
                name="teamLeader"
                type="select"
                options={this.state.membersList}
                isLoading={action.GET_COMPANY_MEMBERS.isFetching}
                placeholder="Select member"
                label="*Leader name"
              />
              <Spacer size={20} />
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <h4 className="bold">Members</h4>
              <Spacer size={20} />
            </Col>
            <Col xs="8" sm="10">
              <Field
                ref={this.memberRef}
                className="form-control"
                component={reduxFormSelect}
                name="teamMember"
                type="select"
                options={this.state.membersList}
                isLoading={action.GET_COMPANY_MEMBERS.isFetching}
                placeholder="Enter name"
                label="Member name"
              />
            </Col>
            <Col xs="4" sm="2">
              <Button
                className="full-width"
                variant="primary"
                onClick={() =>
                  this.memberRef.current.value
                    ? this.addMember(this.memberRef.current.value)
                    : null
                }
              >
                Add
              </Button>
            </Col>
            <Col xs="12">
              <Spacer size={20} />
            </Col>
          </Row>
          <Row>
            {this.state.teamMembers.map((member, idx) => {
              return (
                <Col className="field-wrapper-no-spacer" xs="12" key={idx}>
                  <Field
                    className="form-control"
                    component={reduxFormInput}
                    name={"member" + idx}
                    type="text"
                    placeholder={member.label}
                    disabled
                  />
                  <p
                    className="field-remove"
                    onClick={() => this.removeMember(member.value)}
                    onKeyDown={(e) =>
                      e.keyCode === 13 ? document.activeElement.click() : null
                    }
                    tabIndex="0"
                  >
                    Remove
                  </p>
                </Col>
              );
            })}
          </Row>
          <Row>
            <Col className="text-right" xs="12">
              <Spacer size={50} />
              <Button type="submit" variant="primary">
                Update
              </Button>
            </Col>
          </Row>
        </Form>
      </>
    );
  }
}

function mapStateToProps(state) {
  let teamLeader = state.team.team.teamMembers.find(
    (member) => member.globalRole === "Team Leader"
  );

  teamLeader = {
    label: teamLeader.userId.firstName + " " + teamLeader.userId.lastName,
    value: teamLeader.userId._id,
  };

  return {
    action: state.action,
    team: state.team.team,
    members: state.company.members,
    initialValues: {
      teamLeader,
    },
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "UpdateTeam",
    enableReinitialize: true,
  })(TeamUpdate)
);
