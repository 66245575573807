import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Row, Col, Button, Form } from "react-bootstrap";
import { required, noSpaces, minMax } from "utils/validation";
import { Field, reduxForm } from "redux-form";
import {
  reduxFormInput,
  reduxFormTextarea,
  reduxFormSelect,
  reduxFormNumber,
} from "components/form/ReduxForm";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getDropdown, updateDeal, getDealById } from "redux/actions";
import { getKeyFromLocal } from "utils/lsUtils";
import constants from "config/constants";
import moment from "moment";
import Spacer from "components/layout/Spacer";
import Loader from "components/ui/Loader";
import { Switch, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { getDealCreationPath } from "utils/commonUtils";

const executivieSummaryInfo =
  "The Executive Summary describes the loan request and the purpose of the loan and lays out key details that you think a lender should know about the request.  This is typically 1-3 paragraphs but can be as simple or detailed per your preference";

class LoanRequest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadedDropdowns: [],
      loanMin: 0,
      loanMax: 0,
      inputAtProperty:
        props.deal.isPortifolio && !props.deal.isLoanAddedAtPortifolio,
    };

    this.submitFn = this.submitFn.bind(this);
    this.getDropdownFromAPI = this.getDropdownFromAPI.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.deal !== this.props.deal) {
      const { deal } = this.props;

      if (deal.minimumLoanAmountRequested) {
        this.setState({ loanMin: deal.minimumLoanAmountRequested });
      }

      if (deal.maximumLoanAmountRequested) {
        this.setState({ loanMax: deal.maximumLoanAmountRequested });
      }
    }
  }

  componentDidMount() {
    if (getKeyFromLocal(constants.TERMST_LOCAL_STORAGE.DEAL_ID) !== false) {
      const dealId = getKeyFromLocal(constants.TERMST_LOCAL_STORAGE.DEAL_ID);

      getDealById(dealId);
    }
  }

  getDropdownFromAPI(value) {
    let canGet = false;

    if (this.state.loadedDropdowns.length > 0) {
      this.state.loadedDropdowns.some((dropdown) => {
        if (dropdown === value) {
          canGet = false;

          return true;
        } else {
          canGet = true;

          return false;
        }
      });
    } else {
      getDropdown(value);

      this.setState((prevState) => ({
        loadedDropdowns: [...prevState.loadedDropdowns, value],
      }));
    }

    if (canGet) {
      getDropdown(value);

      this.setState((prevState) => ({
        loadedDropdowns: [...prevState.loadedDropdowns, value],
      }));
    }
  }

  submitFn(data) {
    const dealId = getKeyFromLocal(constants.TERMST_LOCAL_STORAGE.DEAL_ID);

    for (let property in data) {
      if (typeof data[property] === "object" && data[property] !== null) {
        data[property] = data[property].value;
      } else if (data[property] === null) {
        data[property] = "";
      }
    }
    data.isLoanAddedAtPortifolio = !this.state.inputAtProperty;
    data.loanRequest = true;

    updateDeal(dealId, data);
  }

  onToggleChange = (checked) => {
    this.setState({
      inputAtProperty: checked,
    });
  };
  render() {
    const { action, dropdowns, handleSubmit, deal } = this.props;
    const { isFetching } = action.GET_DEAL_BY_ID;
    const { inputAtProperty } = this.state;
    const path = getDealCreationPath();
    const loanAddedatPortifolio =
      !deal.isPortifolio && deal.isLoanAddedAtPortifolio;
    return (
      <>
        {isFetching && <Loader />}
        <Form
          className="full-width no-border mobile-no-padding"
          onSubmit={handleSubmit(this.submitFn)}
        >
          <Row>
            {/* <Col xs="12" sm="6">
              <Field
                className="form-control"
                component={reduxFormSelect}
                validate={[required]}
                name="purpose"
                type="select"
                options={
                  dropdowns["purposeOfFinancing"] !== {}
                    ? dropdowns["purposeOfFinancing"]
                    : {}
                }
                isLoading={action.GET_DROPDOWNS.isFetching}
                onFocus={() => this.getDropdownFromAPI("purposeOfFinancing")}
                placeholder="None"
                label={"*Purpose of Financing"}
              />
              <Spacer size={20} />
            </Col> */}
            {/* <Col xs="12" sm="6">
              <Field
                className="form-control"
                component={reduxFormSelect}
                validate={[required]}
                name="loanType"
                type="select"
                options={
                  dropdowns["loanType"] !== {} ? dropdowns["loanType"] : {}
                }
                isLoading={action.GET_DROPDOWNS.isFetching}
                onFocus={() => this.getDropdownFromAPI("loanType")}
                placeholder="None"
                label={"*Loan Type"}
              />
              <Spacer size={20} />
            </Col> */}
            {/* <Col xs="12" sm="6">
              <Field
                icon={<FontAwesomeIcon icon={faDollarSign} />}
                className="form-control"
                component={reduxFormNumber}
                validate={[required]}
                name="minimumLoanAmountRequested"
                placeholder="Enter amount..."
                label="*Minimum Amount Requested"
                value={this.state.loanMin}
                thousandSeparator={true}
              />
              <Spacer size={20} />
            </Col> */}
            <Col xs="12" sm="6">
              <Field
                icon={<FontAwesomeIcon icon={faDollarSign} />}
                className="form-control"
                component={reduxFormNumber}
                validate={inputAtProperty ? [minMax] : [required, minMax]}
                name="maximumLoanAmountRequested"
                placeholder="Enter amount..."
                label="*Loan Amount Requested"
                value={this.state.loanMax}
                thousandSeparator={true}
                disabled={inputAtProperty || loanAddedatPortifolio}
              />
              <Spacer size={20} />
            </Col>
            {deal.isPortifolio && (
              <>
              <Col xs="12" sm="6">
                Input at Property Level &nbsp;&nbsp;
                <Switch
                  onChange={this.onToggleChange}
                  checked={inputAtProperty}
                  size="small"
                />
                <Spacer size={20} />
              </Col>
              <Col xs="12" sm="6">
              <Field
                className="form-control"
                component={reduxFormInput}
                validate={[required]}
                name="preferredClosingDate"
                type="date"
                label="*Preferred Closing Date"
                min={moment().format("YYYY-MM-DD")}
              />
              <Spacer size={20} />
            </Col>
            </>
            )}
            
            <Col xs="10">
              <Field
                className="form-control"
                component={reduxFormTextarea}
                validate={[required, noSpaces]}
                name="purposeDescription"
                rows="10"
                type="text"
                placeholder="What is the purpose of the loan request?"
                label="*Executive Summary"
              />
              <p style={{ fontSize: "13px" }}>
                <InfoCircleOutlined /> {executivieSummaryInfo}
              </p>
            </Col>
          </Row>
          <Spacer size={50} />
          <Row className="application-controls">
            <Col className="text-left" xs="6">
              <Button as={Link} className="pl-3 pr-3" to={path} variant="dark">
                Back
              </Button>
            </Col>
            <Col className="text-right" xs="6">
              <Button className="pl-2 pr-2" type="submit" variant="primary">
                Continue
              </Button>
            </Col>
          </Row>
        </Form>
      </>
    );
  }
}

function mapStateToProps(state) {
  const {
    preferredClosingDate,
    minimumLoanAmountRequested,
    maximumLoanAmountRequested,
    purposeDescription,
  } = state.deal.deal;

  return {
    action: state.action,
    dropdowns: state.program.dropdowns,
    deal: state.deal.deal,
    initialValues: {
      minimumLoanAmountRequested,
      maximumLoanAmountRequested,
      purposeDescription,
      preferredClosingDate:
        preferredClosingDate && preferredClosingDate !== null
          ? moment.utc(preferredClosingDate).format("YYYY-MM-DD")
          : null,
      // purpose:
      //   purpose && purpose !== null ? { label: purpose, value: purpose } : null,
      // loanType:
      //   loanType && loanType !== null
      //     ? { label: loanType, value: loanType }
      //     : null,
    },
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "LoanRequest",
    enableReinitialize: true,
  })(LoanRequest)
);
