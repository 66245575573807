import React, { useRef } from "react";
import { Field } from "redux-form";
import { reduxFormInput } from "components/form/ReduxForm";
import {
  OverlayTrigger,
  Tooltip,
  Card,
  Row,
  Col,
  Button,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSignInAlt,
  faLock,
  faUnlockAlt,
  faArchive,
  faInbox,
} from "@fortawesome/free-solid-svg-icons";
import {
  faCommentAlt,
  faQuestionCircle,
} from "@fortawesome/free-regular-svg-icons";
import Spacer from "components/layout/Spacer";
import moment from "moment";

const Communication = (props) => {
  const {
    comm,
    commURL,
    whoami,
    isOwner,
    showField,
    onFieldSelect,
    onCommDragStart,
    onCommDragEnd,
    onCommDragOver,
    onCommDragLeave,
    onCommDrop,
    onCommTouchStart,
    onCommTouchEnd,
    onHandleViewComm,
    onHandleArchiveComm,
    onHandleMergeComm,
    onHandleSetPrivacyComm,
  } = props;

  const ref = useRef();

  return (
    <div className="action-wrapper" ref={ref}>
      <Field
        style={
          showField
            ? {
                boxShadow: "0 4px 9px 0 rgba(0, 0, 0, 0.25)",
                display: "inline-flex",
              }
            : {}
        }
        className="form-control"
        component={reduxFormInput}
        name={"selectComm" + comm._id}
        type="checkbox"
        onClick={(e) => {
          onFieldSelect(e, comm._id);
        }}
        onKeyDown={(e) =>
          e.keyCode === 13
            ? e.target.parentNode.click(e.target.parentNode)
            : null
        }
      />
      <Card
        data-id={comm._id}
        draggable={comm.type === "Question" ? true : false}
        onDragStart={(e) => {
          onCommDragStart(e, comm);
        }}
        onDragEnd={(e) => {
          onCommDragEnd(e, comm);
        }}
        onDragOver={(e) => {
          onCommDragOver(e, comm);
        }}
        onDragLeave={(e) => {
          onCommDragLeave(e, comm);
        }}
        onDrop={(e) => {
          onCommDrop(e, comm);
        }}
        className={
          commURL === comm._id ? "inbox-card card-active" : "inbox-card"
        }
        style={showField ? { marginLeft: "45px" } : {}}
        onTouchStart={(e) => {
          onCommTouchStart(e, comm);
        }}
        onTouchEnd={(e) => {
          onCommTouchEnd(e);
        }}
        onMouseDown={(e) => {
          onCommTouchStart(e, comm);
        }}
        onMouseUp={(e) => {
          onCommTouchEnd(e);
        }}
        onMouseLeave={(e) => {
          onCommTouchEnd(e);
        }}
      >
        <Card.Header>
          <Row>
            {comm.editedInitialMsg && comm.editedInitialMsg.length > 0 && (
              <div className="w-100 text-center align-items-center">
                {(isOwner || (whoami && whoami.role === "Admin")) && (
                  <>
                    <Spacer size={5} />
                    <p className="bold d-inline">
                      This question has been edited.
                    </p>{" "}
                    <Button
                      className="fake-link"
                      variant="fake-link"
                      onClick={(e) => {
                        const el1 = document.getElementById(
                          "commMsg1-" + comm._id
                        );
                        const el2 = document.getElementById(
                          "commMsg2-" + comm._id
                        );

                        if (el1.classList.contains("d-block")) {
                          e.target.innerHTML = "See edited question.";

                          el1.classList.remove("d-block");
                          el1.classList.add("d-none");
                          el2.classList.add("d-block");
                          el2.classList.remove("d-none");
                        } else {
                          e.target.innerHTML = "See original question.";

                          el1.classList.remove("d-none");
                          el1.classList.add("d-block");
                          el2.classList.add("d-none");
                          el2.classList.remove("d-block");
                        }
                      }}
                    >
                      See original question.
                    </Button>
                    <Spacer size={5} />
                  </>
                )}
              </div>
            )}
            <Col xs="7">
              <FontAwesomeIcon
                icon={
                  comm.type === "Question" ? faQuestionCircle : faCommentAlt
                }
              />
              <span className="pl-2">
                {comm.type === "Question" ? "Question" : "Message"}
                {comm.isPublic ? (
                  <span className="font-12 bold ml-2 align-bottom">
                    (Public)
                  </span>
                ) : (
                  <span className="font-12 bold ml-2 align-bottom">
                    (Private)
                  </span>
                )}
              </span>
            </Col>
            <Col className="font-12 text-right" xs="5">
              {whoami && whoami.role === "Borrower" && (
                <p className="bold">
                  {comm.lender?.map((l) => l.companyName).join(",")}
                </p>
              )}
              <p className="color-medium-gray">
                {moment(comm.createdAt).fromNow()}
              </p>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <p className="font-14 text-overflow-msg">
            <span
              id={"commMsg1-" + comm._id}
              className={
                comm.editedInitialMsg && comm.editedInitialMsg.length > 0
                  ? "d-block"
                  : "d-none"
              }
            >
              {comm.editedInitialMsg}
            </span>
            <span
              id={"commMsg2-" + comm._id}
              className={
                comm.editedInitialMsg && comm.editedInitialMsg.length > 0
                  ? "d-none"
                  : "d-block"
              }
            >
              {comm.initialMessage}
            </span>
          </p>
          <Spacer size={20} />
          <Row className="align-items-center" noGutters={true}>
            <Col xs="12">
              <p className="d-inline font-14">
                <FontAwesomeIcon
                  className="font-14 color-medium-gray"
                  icon={faCommentAlt}
                />{" "}
                {comm.messages.length} messages
              </p>
              <Spacer size={10} />
            </Col>
            <Col xs="4">
              <Button
                onClick={(e) => {
                  onHandleViewComm(e, comm, ref.current.offsetTop);
                }}
              >
                View
              </Button>
            </Col>
            <Col className="inbox-actions text-right" xs="8">
              {(isOwner || (whoami && whoami.role === "Admin")) &&
                comm.type === "Question" && (
                  <>
                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip id={`tooltip-bottom`}>Merge</Tooltip>}
                    >
                      <Button
                        className="d-inline-block"
                        variant="inbox-action"
                        onClick={(e) => {
                          onHandleMergeComm(e, comm);
                        }}
                      >
                        <FontAwesomeIcon
                          className="color-medium-gray"
                          icon={faSignInAlt}
                        />
                      </Button>
                    </OverlayTrigger>
                  </>
                )}
              {(isOwner || (whoami && whoami.role === "Admin")) && (
                <>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip id={`tooltip-bottom`}>Set Privacy</Tooltip>
                    }
                  >
                    <Button
                      className="d-inline-block"
                      variant="inbox-action"
                      onClick={(e) => {
                        onHandleSetPrivacyComm(e, comm);
                      }}
                    >
                      <FontAwesomeIcon
                        className="color-medium-gray"
                        icon={comm.isPublic ? faLock : faUnlockAlt}
                      />
                    </Button>
                  </OverlayTrigger>
                </>
              )}
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip id={`tooltip-bottom`}>
                    {comm.isArchivedBy.some((user) => user === whoami._id)
                      ? "Unarchive"
                      : "Archive"}
                  </Tooltip>
                }
              >
                <Button
                  className="d-inline-block"
                  variant="inbox-action"
                  onClick={(e) => {
                    onHandleArchiveComm(e, comm);
                  }}
                >
                  <FontAwesomeIcon
                    className="color-medium-gray"
                    icon={
                      comm.isArchivedBy.some((user) => user === whoami._id)
                        ? faInbox
                        : faArchive
                    }
                  />
                </Button>
              </OverlayTrigger>
            </Col>
          </Row>
        </Card.Body>
        {comm.isArchivedBy.some((user) => user === whoami._id) && (
          <Card.Footer className="text-muted">Archived</Card.Footer>
        )}
      </Card>
      <Spacer size={20} />
    </div>
  );
};

export default Communication;
