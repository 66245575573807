import React, { useCallback } from "react";
import axios from "axios";
import parse from 'html-react-parser';
import { isEmpty } from "lodash";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faFileAlt } from "@fortawesome/free-solid-svg-icons";
import constants from "config/constants";
import { getKeyFromLocal } from "utils/lsUtils";
import { Accordion, Card, Button } from "react-bootstrap";
import ReadMore from "./ReadMore";

export const CreateQuote = ({ notification }) => {
  const { deal, payload } = notification;
  return (
    <>
      <>
        <div className="d-flex ">
          <div className="mr-3 bold">{deal.dealName}</div>
          {payload && payload.status && (
            <div>
              <span>Status: </span> <span>{payload.status}</span>
            </div>
          )}
        </div>
        {/* need to add Link to directly access the quotes page with filter on for that lender*/}
        {
          <div className="notificaction-description">
            <Link
              className="nav-link"
              target="_blank"
              to={`/deal/${deal.dealId}/termsheet/${payload?.termsheetId}?viewQuoteId=${payload?.quoteId}`}
            >
              View termsheet
            </Link>
          </div>
        }
      </>
    </>
  );
};

const LenderToDeal = ({ notification }) => {
  const { deal, payload } = notification;
  const lenders = payload?.companyNames.join(",");
  return (
    <>
      <>
        <div className="d-flex ">
          <div className="mr-3 bold">{deal.dealName}</div>
        </div>
        {/* need to add Link to directly access the quotes page with filter on for that lender*/}
        {
          <div className="notificaction-description">
            {lenders.length > 100 ? <ReadMore>{lenders}</ReadMore> : lenders}
          </div>
        }
      </>
    </>
  );
};

const USER_COMPANY_ADDED = ({ notification }) => {
  const { payload } = notification;
  return (
    <div className="notificaction-description">
      {payload.companyBio && (
        <a
          className="nav-link"
          target="_blank"
          rel="noopener noreferrer"
          href={payload.companyBio}
        >
          View Company bio
        </a>
      )}
    </div>
  );
};

const NEW_CONVERSATION = ({ notification }) => {
  const { deal, payload } = notification;

  return (
    <div className="notificaction-description">
      {/* need to add expand collapse feature here for lengthy messages*/}
      <Accordion>
        <div className="inbox-notification">
          {payload?.message?.length > 100 ? (
            <ReadMore>{payload.message}</ReadMore>
          ) : (
            parse(payload.message)
          )}
          <Link
            className="nav-link"
            target="_blank"
            to={`/deal/inbox/${deal.dealId}${
              payload.convId ? `?convId=${payload.convId}` : ""
            }`}
          >
            View in your inbox
          </Link>
        </div>
      </Accordion>
    </div>
  );
};

const PROGRAM = ({ notification = {} }) => {
  const { payload } = notification;
  return (
    <div className="notification-description">
      <Link
        className="nav-link"
        target="_blank"
        to={`/program/view/${payload?.id}`}
      >
        View program here
      </Link>
    </div>
  );
};

const CONVERSATION_RESPONSE = ({ notification = {} }) => {
  const { deal, payload } = notification;
  const response = payload.msg || "";
  const prevMessage = payload.prevMessage || "";
  return (
    <>
      <div className="d-flex ">
        <div className="mr-3 bold">{deal.dealName}</div>
        {payload && payload.status && (
          <div>
            <span>Status: </span> <span>{payload.status}</span>
          </div>
        )}
      </div>

      <div className="notificaction-description">
        <Accordion>
          {/* need to add expand collapse feature here for lengthy messages*/}

          <div className="inbox-notification">
            {response.length > 100 ? <ReadMore>{response}</ReadMore> : parse(response)}
            <div className="reply-header">
              &nbsp;&nbsp;In Response to a message
            </div>
            {prevMessage.length > 100 ? (
              <ReadMore>{prevMessage}</ReadMore>
            ) : (
              prevMessage
            )}
            <Link
              className="nav-link"
              target="_blank"
              to={`/deal/inbox/${deal.dealId}${
                payload.convId
                  ? `?convId=${payload.convId}&msgId=${payload.msgId}`
                  : ""
              }`}
            >
              View in your inbox
            </Link>
          </div>
        </Accordion>
      </div>
    </>
  );
};
const NEW_FILE = ({ notification = {} }) => {
  const { deal, payload } = notification;
  const fetchFile = useCallback(() => {
    const origins = constants.API_ORIGINS;
    let endPoint = constants.API_PROTOCOL + origins[window.location.host];
    const jwtToken = getKeyFromLocal(constants.TERMST_LOCAL_STORAGE.JWT_KEY);
    axios
      .get(`${endPoint}/downloadfile/${payload?.id}?dealId=${deal.dealId}`, {
        headers: { accessToken: jwtToken },
        responseType: "arraybuffer",
      })
      .then((res) => {
        console.log("file download res", res);
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", payload.name);
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {})
      .finally(() => {});
  }, []);
  return (
    <div className="" onClick={fetchFile}>
      <FontAwesomeIcon
        icon={faFileAlt}
        tabIndex="0"
        className="notification-file-icon"
      />
      &nbsp;
      <span className="custom-a-link">{payload?.name}</span>
    </div>
  );
};
export const titleMap = {
  CREATE_QUOTE: ({ deal, senderCompany = {} }) =>
    `You received a new term sheet for ${deal?.dealName} from ${senderCompany?.companyName}`,

  TEAM_MEMBER_UPDATE: ({ payload }) =>
    payload?.role === "Team Leader"
      ? `Your Team Lead has been updated to ${payload?.firstName} ${payload?.lastName}`
      : `A New Team Member has been added to your team - ${payload?.firstName} ${payload?.lastName}`,

  DEAT_STATUS_CHANGE: ({ deal, payload, whoami }) =>
    payload.to === "Active" && whoami.role === "Lender"
      ? "You've been added to a deal - " + deal?.dealName
      : `${deal?.dealName} was moved from ${payload?.from} to ${payload?.to} Status`,

  ADDED_TO_DEAL: ({ deal, senderCompany = {}, action, payload }) =>
    `You have a new Deal - ${deal?.dealName}`,

  LENDER_TO_DEAL: ({ payload }) =>
    `${payload?.companyNames?.length} New Lenders were added to your deal`,

  // ADMIN ONLY
  NEW_USER: ({ deal, senderCompany = {}, action, payload }) =>
    `A new user was added - ${payload?.firstName} ${payload?.lastName} ${
      payload?.companyName ? `at ${payload?.companyName}` : ""
    }`,
  NEW_COMPANY: ({ sender, payload }) =>
    `A new ${payload?.type} was added - ${payload?.name}. ${sender.firstName} ${sender.lastName} created this company`,

  // inbox
  NEW_CONVERSATION: ({ deal, payload, senderCompany }) =>
    `You received a new ${payload.type} from ${senderCompany?.companyName} related to ${deal?.dealName}`,
  CONVERSATION_RESPONSE: ({ deal, payload }) =>
    payload.type === "Question"
      ? "Your Question was Answered"
      : "You received a response to your message",

  // lender database
  NEW_PROGRAM: ({ payload }) => `${payload?.name} program was added`,
  UPDATE_PROGRAM: ({ sender, payload }) =>
    ` ${payload?.name} program was edited by ${sender?.firstName} ${sender?.lastName}`,
  // files
  NEW_FILE: ({ payload, deal }) =>
    `A new file has been added in the file manager for ${deal?.dealName}`,
  FILE_DELETE: ({ payload, deal }) =>
    `A File - ${payload.name} - was deleted from the file manager related to ${deal.dealName}`,
};

export const NOTIFICATION_TEMPLATE = {
  CREATE_QUOTE: CreateQuote,
  // team
  TEAM_MEMBER_UPDATE: () => null,
  // deals
  DEAT_STATUS_CHANGE: () => null,
  ADDED_TO_DEAL: () => null,
  LENDER_TO_DEAL: LenderToDeal,
  // ADMIN ONLY
  NEW_USER: () => null,
  NEW_COMPANY: USER_COMPANY_ADDED,
  // INBOX
  NEW_CONVERSATION: NEW_CONVERSATION,
  CONVERSATION_RESPONSE: CONVERSATION_RESPONSE,
  // lender database
  NEW_PROGRAM: PROGRAM,
  UPDATE_PROGRAM: PROGRAM,
  // files
  NEW_FILE: NEW_FILE,
  FILE_DELETE: () => null,
};
