import React from "react";
import { Col, Dropdown, Modal, Row, Tooltip } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import Spacer from "components/layout/Spacer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import useAddEditSectionModalStore from "../hooks/useAddEditSectionModalStore";
import { deleteDealCard } from "redux/actions";
import { useSelector } from "react-redux";

const { confirm } = Modal;

const AddedSection = ({ deal, section }) => {
  const { openEditModal } = useAddEditSectionModalStore();
  const whoami = useSelector((state) => state.auth.whoami);

  const showDeleteConfirm = () => {
    confirm({
      title: "Are you sure delete this section?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteDealCard(deal._id, section);
      },
      onCancel() {},
    });
  };

  const onClick = ({ key }) => {
    switch (key) {
      case "edit":
        openEditModal({ dealId: deal._id }, section);
        break;
      case "delete":
        showDeleteConfirm();
        break;
      default:
        break;
    }
  };

  return (
    <section key={section._id}>
      <div className="tw-flex tw-space-x-3 tw-items-center">
        <h4>{section.title}</h4>
        {whoami.role === "Admin" && (
          <Dropdown
            menu={{
              items: [
                {
                  label: "Edit",
                  key: "edit",
                },
                {
                  label: "Delete",
                  key: "delete",
                },
              ],
              onClick,
            }}
            trigger={["click"]}
            placement="bottomRight"
          >
            <span className="px-2 cursor-pointer">
              <Tooltip title="More">
                <FontAwesomeIcon
                  icon={faEllipsisV}
                  className="tw-w-2.5 tw-h-2.5"
                />
              </Tooltip>
            </span>
          </Dropdown>
        )}
      </div>
      <Spacer size={5} />
      <div className="border-bottom"></div>
      <Spacer size={20} />
      <Row>
        <Col md={24}>
          <div
            className="tw-space-y-3"
            dangerouslySetInnerHTML={{
              __html: section.description,
            }}
          ></div>
        </Col>
      </Row>
    </section>
  );
};

export default AddedSection;
