import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { Link } from "react-router-dom";
import { Row, Col, Button, Form } from "react-bootstrap";
import { required, noSpaces } from "utils/validation";
import { Field, reduxForm, isPristine } from "redux-form";
import {
  reduxFormInput,
  reduxFormTextarea,
  reduxFormSelect,
  reduxFormNumber,
} from "components/form/ReduxForm";
import {
  getDropdown,
  getTeamById,
  getTeamsByCompanyId,
  createDeal,
  updateDeal,
  getDealById,
  createPropertyFolders,
} from "redux/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPercentage,
  faDollarSign,
  faHashtag,
} from "@fortawesome/free-solid-svg-icons";
import { getKeyFromLocal } from "utils/lsUtils";
import { states } from "utils/dataUtil";
import constants from "config/constants";
import Spacer from "components/layout/Spacer";
import moment from "moment";
import Loader from "components/ui/Loader";
import { addPlacesAutoSuggestionsListener } from "components/google/places";
import { getDealCreationPath } from "utils/commonUtils";

const addressFieldsMapping = {
  propertyAddress: "address",
  propertyState: "state",
  propertyCity: "city",
  propertyZip: "zip",
  propertyLat: "lat",
  propertyLong: "lng",
};

class PropertyDetails extends React.Component {
  constructor(props) {
    super(props);
    this.autocomplete = null;
    this.state = {
      parkingSpacesGarage: 0,
      parkingSpacesCovered: 0,
      parkingSpacesSurface: 0,
      propertyTypes: "",
      propertySizeType: "Total Square Feet",
      commercialSpace: "",
      loadedDropdowns: [],
      teamsSelectOptions: [],
    };

    this.submitFn = this.submitFn.bind(this);
    this.getDropdownFromAPI = this.getDropdownFromAPI.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { deal } = this.props;
    if (prevProps.deal !== this.props.deal) {
      this.setState({
        propertyTypes:
          deal.propertyType && deal.propertyType !== null
            ? deal.propertyType
            : "None",
        parkingSpacesCovered: deal.parkingSpacesCovered
          ? parseInt(deal.parkingSpacesCovered)
          : 0,
        parkingSpacesGarage: deal.parkingSpacesGarage
          ? parseInt(deal.parkingSpacesGarage)
          : 0,
        parkingSpacesSurface: deal.parkingSpacesSurface
          ? parseInt(deal.parkingSpacesSurface)
          : 0,
      });

      getTeamById(deal.teamId?._id || deal.teamId);
    }

    if (
      _.get(deal, "_id", null) &&
      _.get(prevProps.deal, "_id", null) !== _.get(deal, "_id", null)
    ) {
      getTeamsByCompanyId(deal.company);
    }
    const { myCompany } = this.props;
    if (
      _.get(myCompany, "_id", null) &&
      _.get(prevProps.myCompany, "_id", null) !== _.get(myCompany, "_id", null)
    ) {
      getTeamsByCompanyId(myCompany._id);
    }

    if (prevProps.teams !== this.props.teams) {
      const { teams } = this.props;

      teams.map((team) => {
        this.setState((prevState) => ({
          teamsSelectOptions: [
            ...prevState.teamsSelectOptions,
            { label: team.teamName, value: team._id },
          ],
        }));
        return true;
      });
    }
  }

  componentDidMount() {
    const { myCompany } = this.props;

    if (Object.keys(myCompany).length > 0) {
      getTeamsByCompanyId(myCompany._id);
    }

    if (getKeyFromLocal(constants.TERMST_LOCAL_STORAGE.DEAL_ID) !== false) {
      const dealId = getKeyFromLocal(constants.TERMST_LOCAL_STORAGE.DEAL_ID);

      getDealById(dealId);
    }

    addPlacesAutoSuggestionsListener(
      "property-address-field",
      addressFieldsMapping,
      (data) => {
        Object.keys(data).map((key) => {
          if (key === "propertyState") {
            this.props.change(key, {
              label: data[key].name,
              value: data[key].nick_name.toUpperCase(),
            });
          } else {
            this.props.change(key, data[key]);
          }

          return true;
        });
      }
    );
  }

  getDropdownFromAPI(value) {
    let canGet = false;

    if (this.state.loadedDropdowns.length > 0) {
      this.state.loadedDropdowns.some((dropdown) => {
        if (dropdown === value) {
          canGet = false;

          return true;
        } else {
          canGet = true;

          return false;
        }
      });
    } else {
      getDropdown(value);

      this.setState((prevState) => ({
        loadedDropdowns: [...prevState.loadedDropdowns, value],
      }));
    }

    if (canGet) {
      getDropdown(value);

      this.setState((prevState) => ({
        loadedDropdowns: [...prevState.loadedDropdowns, value],
      }));
    }
  }

  submitFn(data) {
    const { isPristine, deal } = this.props;

    for (let property in data) {
      if (typeof data[property] === "object" && data[property] !== null) {
        data[property] = data[property].value;
      } else if (data[property] === null) {
        data[property] = "";
      }
    }
    data.propertySizeType = this.state.propertySizeType;
    const redirectTo = getDealCreationPath()

    if (isPristine) {
      window.location.pathname = redirectTo;
    } else {
      if (deal.teamId) {
        data.propertyDetailsComplete = true;
        const dealId = getKeyFromLocal(constants.TERMST_LOCAL_STORAGE.DEAL_ID);
        if(!deal.propertyDetailsComplete && data.propertyType) {
          createPropertyFolders(dealId, {
            propertyType:
              data.propertyType &&
              data.propertyType === "None"
                ? "Other"
                : data.propertyType,
          });
        }

        updateDeal(dealId, {
          ...data,
        });
      } else {
        data.propertyDetailsComplete = true;
        data.teamSet = true;
        data.isPortifolio = false;
        data.dealStatus = "In progress";

        createDeal({
          ...data,
        });
      }
    }
  }

  render() {
    const { dropdowns, handleSubmit, action } = this.props;
    const { isFetching } = action.GET_DEAL_BY_ID;
    const path = getDealCreationPath()

    if (!isFetching) {
      const disableChrome = document.querySelectorAll(
        'input[autocomplete="off"'
      );

      for (let el of disableChrome) {
        if (el !== null) {
          el.setAttribute("autocomplete", "new-password");
        }
      }
    }
    return (
      <>
        {isFetching && <Loader />}
        <Form
          className="full-width no-border mobile-no-padding"
          onSubmit={handleSubmit(this.submitFn)}
        >
          <Row>
            <Col xs="12">
              <Field
                className="form-control"
                component={reduxFormInput}
                validate={[required, noSpaces]}
                name="propertyName"
                type="text"
                placeholder="enter name"
                label="*Property Name"
              />
              <Spacer size={20} />
            </Col>
            {/* <Col xs="12">
              <Field
                className="form-control"
                component={reduxFormSelect}
                validate={[required]}
                name="teamId"
                type="select"
                options={
                  this.state.teamsSelectOptions
                    ? this.state.teamsSelectOptions
                    : {}
                }
                isLoading={action.GET_TEAMS_BY_COMPANY_ID.isFetching}
                placeholder="Select a team"
                label="*Assign Team"
              />
              <Spacer size={20} />
            </Col> */}
          </Row>
          <Row>
            <Col xs="12">
              <Field
                className="form-control"
                component={reduxFormInput}
                validate={[required, noSpaces]}
                name="propertyAddress"
                type="text"
                placeholder="enter address"
                label="*Address"
                id="property-address-field"
              />
              <Spacer size={20} />
            </Col>
            <Col xs="12" sm="6">
              <Field
                className="form-control"
                component={reduxFormInput}
                validate={[required, noSpaces]}
                name="propertyCity"
                type="text"
                placeholder="enter city"
                label="*City"
              />
              <Spacer size={20} />
            </Col>
            <Col xs="12" sm="3">
              <Field
                className="form-control"
                component={reduxFormSelect}
                validate={[required, noSpaces]}
                name="propertyState"
                type="select"
                options={states}
                placeholder="Pick state"
                label="*State"
              />
              <Spacer size={20} />
            </Col>
            <Col xs="12" sm="3">
              <Field
                className="form-control"
                component={reduxFormNumber}
                validate={[required]}
                name="propertyZip"
                placeholder="enter zip code"
                label="*Zip Code"
                thousandSeparator={false}
                onKeyDown={(e) =>
                  e.keyCode === 189 ? e.preventDefault() : null
                }
              />
              <Spacer size={20} />
            </Col>
            <Col xs="12">
              <Field
                className="form-control"
                component={reduxFormSelect}
                validate={[required]}
                name="propertyType"
                type="select"
                options={
                  dropdowns["propertyTypes"] !== {}
                    ? dropdowns["propertyTypes"]
                    : {}
                }
                isLoading={action.GET_DROPDOWNS.isFetching}
                onChange={(e) => {
                  let selection = "";

                  switch (e.value) {
                    case "Multifamily":
                      selection = "Number of Units";
                      break;
                    case "Office":
                      selection = "Total Square Feet";
                      break;
                    case "Medical Office":
                      selection = "Total Square Feet";
                      break;
                    case "Industrial":
                      selection = "Total Square Feet";
                      break;
                    case "Retail":
                      selection = "Total Square Feet";
                      break;
                    case "Hotel":
                      selection = "Number of Rooms";
                      break;
                    case "Senior Living":
                      selection = "Number of Beds";
                      break;
                    case "CTL":
                      selection = "Total Square Feet";
                      break;
                    case "Land":
                      selection = "Total Acres";
                      break;
                    case "Golf":
                      selection = "Number of Holes";
                      break;
                    case "Marina":
                      selection = "Number of Slips";
                      break;
                    case "Parking":
                      selection = "Total Parking Spaces";
                      break;
                    case "Convenience Store":
                      selection = "Total Square Feet";
                      break;
                    case "Hospital":
                      selection = "Total Square Feet";
                      break;
                    case "Data Center":
                      selection = "Total Square Feet";
                      break;
                    case "Condo":
                      selection = "Number of Units";
                      break;
                    case "Residential / Homebuilder":
                      selection = "Total Acres";
                      break;
                    case "Single Family Residential":
                      selection = "Number of Units";
                      break;
                    default:
                      selection = "Total Square Feet";
                      break;
                  }

                  this.setState({
                    propertyTypes: e.value,
                    propertySizeType: selection,
                  });
                }}
                onFocus={() => this.getDropdownFromAPI("propertyTypes")}
                placeholder="None"
                label={"Property Type"}
              />
              <Spacer size={20} />
            </Col>
            <Col xs="12" sm="4">
              <Field
                className="form-control"
                component={reduxFormNumber}
                name="propertySize"
                placeholder="enter size"
                label={this.state.propertySizeType}
                thousandSeparator={true}
                onKeyDown={(e) =>
                  e.keyCode === 189 ? e.preventDefault() : null
                }
              />
              <Spacer size={30} />
            </Col>
            <Col xs="12" sm="4">
              <Field
                className="form-control"
                component={reduxFormNumber}
                name="propertyYearBuilt"
                numberFormat="####"
                placeholder="yyyy"
                numberMask={["y", "y", "y", "y"]}
                label="What year was the property built?"
                thousandSeparator={false}
                onKeyDown={(e) =>
                  e.keyCode === 189 ? e.preventDefault() : null
                }
              />
              <Spacer size={30} />
            </Col>
            {this.state.propertyTypes !== "Hotel" && (
              <Col xs="12" sm="4">
                <Field
                  className="form-control"
                  component={reduxFormNumber}
                  name="propertyYearMostRecentRenovation"
                  numberFormat="####"
                  placeholder="yyyy"
                  numberMask={["y", "y", "y", "y"]}
                  label="Year of most recent renovation"
                  thousandSeparator={false}
                  onKeyDown={(e) =>
                    e.keyCode === 189 ? e.preventDefault() : null
                  }
                />
                <Spacer size={30} />
              </Col>
            )}
            <Col xs="12" sm="4">
              <Field
                icon={<FontAwesomeIcon icon={faHashtag} />}
                className="form-control"
                component={reduxFormNumber}
                name="propertyNumberOfBuildings"
                placeholder="number of buildings"
                label="Number of Buildings"
                thousandSeparator={true}
                onKeyDown={(e) =>
                  e.keyCode === 189 ? e.preventDefault() : null
                }
              />
              <Spacer size={30} />
            </Col>
            <Col xs="12" sm="4">
              <Field
                icon={<FontAwesomeIcon icon={faHashtag} />}
                className="form-control"
                component={reduxFormNumber}
                name="propertyNumberOfFloors"
                placeholder="number of floors"
                label="Number of Floors"
                thousandSeparator={true}
                onKeyDown={(e) =>
                  e.keyCode === 189 ? e.preventDefault() : null
                }
              />
              <Spacer size={30} />
            </Col>
            {this.state.propertyTypes === "Multifamily" ||
              this.state.propertyTypes === "Office" ||
              this.state.propertyTypes === "Medical Office" ||
              this.state.propertyTypes === "Industrial" ||
              this.state.propertyTypes === "Senior Living" ||
              this.state.propertyTypes === "Self Storage" ? (
                <Col xs="12" sm="4">
                  <Field
                    className="form-control"
                    component={reduxFormSelect}
                    name="propertyClass"
                    type="select"
                    options={
                      dropdowns["propertyClass"] !== {}
                        ? _.sortBy(dropdowns["propertyClass"], "label")
                        : {}
                    }
                    isLoading={action.GET_DROPDOWNS.isFetching}
                    onFocus={() => this.getDropdownFromAPI("propertyClass")}
                    placeholder="None"
                    label={"Property Class"}
                  />
                  <Spacer size={30} />
                </Col>
              ) : null}
          </Row>
          {this.state.propertyTypes === "Multifamily" ||
            this.state.propertyTypes === "Office" ||
            this.state.propertyTypes === "Medical Office" ||
            this.state.propertyTypes === "Industrial" ||
            this.state.propertyTypes === "Retail" ||
            this.state.propertyTypes === "Hotel" ||
            this.state.propertyTypes === "Senior Living" ||
            this.state.propertyTypes === "Self Storage" ||
            this.state.propertyTypes === "CTL" ||
            this.state.propertyTypes === "Golf" ||
            this.state.propertyTypes === "Marina" ||
            this.state.propertyTypes === "Parking" ||
            this.state.propertyTypes === "Convenience Store" ||
            this.state.propertyTypes === "Hospital" ||
            this.state.propertyTypes === "Data Center" ||
            this.state.propertyTypes === "Condo" ||
            this.state.propertyTypes === "Other" ? (
              <>
                <Row>
                  <Col xs="12" sm="3">
                    <Field
                      icon={<FontAwesomeIcon icon={faHashtag} />}
                      className="form-control"
                      component={reduxFormNumber}
                      name="parkingSpacesGarage"
                      placeholder="Garage Parking Spaces..."
                      label="Garage Parking Spaces"
                      thousandSeparator={true}
                      onChange={(e) => {
                        this.setState({
                          parkingSpacesGarage: e,
                        });
                      }}
                      onKeyDown={(e) =>
                        e.keyCode === 189 ? e.preventDefault() : null
                      }
                    />
                    <Spacer size={30} />
                  </Col>
                  <Col xs="12" sm="3">
                    <Field
                      icon={<FontAwesomeIcon icon={faHashtag} />}
                      className="form-control"
                      component={reduxFormNumber}
                      name="parkingSpacesCovered"
                      placeholder="Covered Parking Spaces..."
                      label="Covered Parking Spaces"
                      thousandSeparator={true}
                      onChange={(e) => {
                        this.setState({
                          parkingSpacesCovered: e,
                        });
                      }}
                      onKeyDown={(e) =>
                        e.keyCode === 189 ? e.preventDefault() : null
                      }
                    />
                    <Spacer size={30} />
                  </Col>
                  <Col xs="12" sm="3">
                    <Field
                      icon={<FontAwesomeIcon icon={faHashtag} />}
                      className="form-control"
                      component={reduxFormNumber}
                      name="parkingSpacesSurface"
                      placeholder="Surface Parking Spaces..."
                      label="Surface Parking Spaces"
                      thousandSeparator={true}
                      onChange={(e) => {
                        this.setState({
                          parkingSpacesSurface: e,
                        });
                      }}
                      onKeyDown={(e) =>
                        e.keyCode === 189 ? e.preventDefault() : null
                      }
                    />
                    <Spacer size={30} />
                  </Col>
                  <Col xs="12" sm="3">
                    <Field
                      className="form-control"
                      component={reduxFormNumber}
                      name="totalParkingSpaces"
                      placeholder={
                        parseInt(this.state.parkingSpacesCovered) +
                          parseInt(this.state.parkingSpacesGarage) +
                          parseInt(this.state.parkingSpacesSurface) >=
                          0
                          ? parseInt(this.state.parkingSpacesCovered) +
                          parseInt(this.state.parkingSpacesGarage) +
                          parseInt(this.state.parkingSpacesSurface)
                          : 0
                      }
                      label="Total Parking Spaces"
                      thousandSeparator={true}
                      disabled
                    />
                    <Spacer size={30} />
                  </Col>
                </Row>
              </>
            ) : null}
          <Row>
            {this.state.propertyTypes === "Industrial" ||
              this.state.propertyTypes === "Data Center" ? (
                <Col xs="12" sm="4">
                  <Field
                    className="form-control"
                    component={reduxFormNumber}
                    name="propertyMinimumClearHeight"
                    placeholder="number of feet"
                    label="Minimum Clear Height"
                    thousandSeparator={true}
                    onKeyDown={(e) =>
                      e.keyCode === 189 ? e.preventDefault() : null
                    }
                  />
                  <Spacer size={30} />
                </Col>
              ) : null}
            {this.state.propertyTypes === "Industrial" ||
              this.state.propertyTypes === "Data Center" ? (
                <Col xs="12" sm="4">
                  <Field
                    className="form-control"
                    component={reduxFormNumber}
                    name="columnHeight"
                    placeholder="number of feet"
                    label="Column Spacing"
                    thousandSeparator={true}
                    onKeyDown={(e) =>
                      e.keyCode === 189 ? e.preventDefault() : null
                    }
                  />
                  <Spacer size={30} />
                </Col>
              ) : null}
            {this.state.propertyTypes === "Industrial" ||
              this.state.propertyTypes === "Data Center" ? (
                <Col xs="12" sm="4">
                  <Field
                    icon={<FontAwesomeIcon icon={faHashtag} />}
                    className="form-control"
                    component={reduxFormNumber}
                    name="numberOfDockDoors"
                    placeholder="number of dock doors"
                    label="Number of Dock Doors"
                    thousandSeparator={true}
                    onKeyDown={(e) =>
                      e.keyCode === 189 ? e.preventDefault() : null
                    }
                  />
                  <Spacer size={30} />
                </Col>
              ) : null}
            {this.state.propertyTypes === "Industrial" ||
              this.state.propertyTypes === "Data Center" ? (
                <Col xs="12" sm="4">
                  <Field
                    icon={<FontAwesomeIcon icon={faHashtag} />}
                    className="form-control"
                    component={reduxFormNumber}
                    name="numberOfDriveInDoors"
                    placeholder="number of drive in doors"
                    label="Number of Drive In Doors"
                    thousandSeparator={true}
                    onKeyDown={(e) =>
                      e.keyCode === 189 ? e.preventDefault() : null
                    }
                  />
                  <Spacer size={30} />
                </Col>
              ) : null}
            {this.state.propertyTypes === "Industrial" ||
              this.state.propertyTypes === "Data Center" ? (
                <Col xs="12" sm="4">
                  <Field
                    icon={<FontAwesomeIcon icon={faPercentage} />}
                    className="form-control"
                    component={reduxFormNumber}
                    name="officeSpacePercentage"
                    placeholder="percentage of office space"
                    label="Office Space Percentage"
                    thousandSeparator={true}
                    onKeyDown={(e) =>
                      e.keyCode === 189 ? e.preventDefault() : null
                    }
                  />
                  <Spacer size={30} />
                </Col>
              ) : null}
            {this.state.propertyTypes === "Multifamily" ||
              this.state.propertyTypes === "Hotel" ||
              this.state.propertyTypes === "Senior Living" ||
              this.state.propertyTypes === "Self Storage" ||
              this.state.propertyTypes === "Marina" ||
              this.state.propertyTypes === "Parking" ||
              this.state.propertyTypes === "Condo" ||
              this.state.propertyTypes === "Residential / Homebuilder" ||
              this.state.propertyTypes === "Single Family Residential" ||
              this.state.propertyTypes === "Other" ? (
                <>
                  <Col xs="12" sm="4">
                    <Field
                      className="form-control"
                      component={reduxFormSelect}
                      name="commercialSpace"
                      type="select"
                      options={
                        dropdowns["yesNo"] !== {} ? dropdowns["yesNo"] : {}
                      }
                      isLoading={action.GET_DROPDOWNS.isFetching}
                      onChange={(e) => {
                        this.setState({ commercialSpace: e.value });
                      }}
                      onFocus={() => this.getDropdownFromAPI("yesNo")}
                      placeholder="None"
                      label={"Is there commercial space?"}
                    />
                    <Spacer size={30} />
                  </Col>
                  {this.state.commercialSpace === "Yes" ? (
                    <Col xs="12" sm="4">
                      <Field
                        className="form-control"
                        component={reduxFormNumber}
                        name="commercialSpaceSqFt"
                        placeholder="square feet"
                        label="Commercial Space Square feet"
                        thousandSeparator={true}
                        onKeyDown={(e) =>
                          e.keyCode === 189 ? e.preventDefault() : null
                        }
                      />
                      <Spacer size={30} />
                    </Col>
                  ) : null}
                </>
              ) : null}
            {this.state.propertyTypes === "Hotel" ? (
              <>
                <Col xs="12" sm="4">
                  <Field
                    className="form-control"
                    component={reduxFormNumber}
                    name="conventionMeeetingSpaceSqFt"
                    placeholder="Enter amount..."
                    label="Total Square Feet of Convention & Meeting Space"
                    thousandSeparator={true}
                    onKeyDown={(e) =>
                      e.keyCode === 189 ? e.preventDefault() : null
                    }
                  />
                  <Spacer size={30} />
                </Col>
                <Col xs="12" sm="4">
                  <Field
                    className="form-control"
                    component={reduxFormSelect}
                    name="fullServiceHotel"
                    type="select"
                    options={
                      dropdowns["yesNo"] !== {} ? dropdowns["yesNo"] : {}
                    }
                    isLoading={action.GET_DROPDOWNS.isFetching}
                    onFocus={() => this.getDropdownFromAPI("yesNo")}
                    placeholder="None"
                    label={"Is this a full service hotel?"}
                  />
                  <Spacer size={30} />
                </Col>
                <Col xs="12" sm="4">
                  <Field
                    className="form-control"
                    component={reduxFormSelect}
                    name="restaurant"
                    type="select"
                    options={
                      dropdowns["yesNo"] !== {} ? dropdowns["yesNo"] : {}
                    }
                    isLoading={action.GET_DROPDOWNS.isFetching}
                    onFocus={() => this.getDropdownFromAPI("yesNo")}
                    placeholder="None"
                    label={"Is there a restaurant?"}
                  />
                  <Spacer size={30} />
                </Col>
                <Col xs="12" sm="4">
                  <Field
                    className="form-control"
                    component={reduxFormSelect}
                    name="restaurantOwnedLeased"
                    type="select"
                    options={
                      dropdowns["ownedLeased"] !== {}
                        ? dropdowns["ownedLeased"]
                        : {}
                    }
                    isLoading={action.GET_DROPDOWNS.isFetching}
                    onFocus={() => this.getDropdownFromAPI("ownedLeased")}
                    placeholder="None"
                    label={"Is the restaurant owned or leased?"}
                  />
                  <Spacer size={30} />
                </Col>
                <Col xs="12" sm="4">
                  <Field
                    className="form-control"
                    component={reduxFormSelect}
                    name="hotelFlagged"
                    type="select"
                    options={
                      dropdowns["yesNo"] !== {} ? dropdowns["yesNo"] : {}
                    }
                    isLoading={action.GET_DROPDOWNS.isFetching}
                    onFocus={() => this.getDropdownFromAPI("yesNo")}
                    placeholder="None"
                    label={"Is the Hotel Flagged?"}
                  />
                  <Spacer size={30} />
                </Col>
                <Col xs="12" sm="4">
                  <Field
                    className="form-control"
                    component={reduxFormInput}
                    validate={[noSpaces]}
                    name="hotelBrand"
                    type="text"
                    placeholder="Enter brand name..."
                    label="What is the Brand?"
                  />
                  <Spacer size={30} />
                </Col>
                <Col xs="12" sm="4">
                  <Field
                    className="form-control"
                    component={reduxFormInput}
                    name="franchiseAgreementExpiration"
                    type="date"
                    label="Franchise Agreement Expiration"
                    max
                  />
                  <Spacer size={30} />
                </Col>
                <Col xs="12" sm="4">
                  <Field
                    className="form-control"
                    component={reduxFormSelect}
                    name="goodStandingFranchise"
                    type="select"
                    options={
                      dropdowns["yesNo"] !== {} ? dropdowns["yesNo"] : {}
                    }
                    isLoading={action.GET_DROPDOWNS.isFetching}
                    onFocus={() => this.getDropdownFromAPI("yesNo")}
                    placeholder="None"
                    label={"Good standing with the Franchise?"}
                  />
                  <Spacer size={30} />
                </Col>
                {this.state.propertyTypes === "Hotel" && (
                  <Col xs="12" sm="4">
                    <Field
                      className="form-control"
                      component={reduxFormNumber}
                      name="lastPIPOrRenovation"
                      numberFormat="####"
                      placeholder="yyyy"
                      numberMask={["y", "y", "y", "y"]}
                      label="Year of Most Recent PIP or Renovation"
                      onKeyDown={(e) =>
                        e.keyCode === 189 ? e.preventDefault() : null
                      }
                    />
                    <Spacer size={30} />
                  </Col>
                )}
                <Col xs="12" sm="4">
                  <Field
                    icon={<FontAwesomeIcon icon={faDollarSign} />}
                    className="form-control"
                    component={reduxFormNumber}
                    name="lastPIPOrRenovationCost"
                    placeholder="Enter amount..."
                    label="Cost of Last PIP or Renovation"
                    thousandSeparator={true}
                    onKeyDown={(e) =>
                      e.keyCode === 189 ? e.preventDefault() : null
                    }
                  />
                  <Spacer size={30} />
                </Col>
                <Col xs="12" sm="4">
                  <Field
                    className="form-control"
                    component={reduxFormSelect}
                    name="PIPOrRenovationExpected"
                    type="select"
                    options={
                      dropdowns["yesNo"] !== {} ? dropdowns["yesNo"] : {}
                    }
                    isLoading={action.GET_DROPDOWNS.isFetching}
                    onFocus={() => this.getDropdownFromAPI("yesNo")}
                    placeholder="None"
                    label={"Expect an upcoming PIP or renovation?"}
                  />
                  <Spacer size={30} />
                </Col>
                <Col xs="12" sm="4">
                  <Field
                    icon={<FontAwesomeIcon icon={faDollarSign} />}
                    className="form-control"
                    component={reduxFormNumber}
                    name="PIPOrRenovationExpectedCost"
                    placeholder="Enter amount..."
                    label="Cost of upcoming PIP or Renovation"
                    thousandSeparator={true}
                    onKeyDown={(e) =>
                      e.keyCode === 189 ? e.preventDefault() : null
                    }
                  />
                  <Spacer size={30} />
                </Col>
                <Col xs="12">
                  <Field
                    className="form-control"
                    component={reduxFormTextarea}
                    validate={[noSpaces]}
                    name="PIPOrRenovationDescription"
                    rows="10"
                    type="text"
                    placeholder="Enter description of the renovation needed..."
                    label="PIP or Renovation Description"
                  />
                </Col>
              </>
            ) : null}
          </Row>
          <Spacer size={50} />
          <Row className="application-controls">
            <Col className="text-left" xs="6">
              <Button
                as={Link}
                className="pl-3 pr-3"
                to={path}
                variant="dark"
              >
                Back
              </Button>
            </Col>
            <Col className="text-right" xs="6">
              <Button className="pl-2 pr-2" type="submit" variant="primary">
                Continue
              </Button>
            </Col>
          </Row>
        </Form>
      </>
    );
  }
}

function mapStateToProps(state, props) {
  const locationState = props.location.state || {}
  const {
    propertyAddress,
    propertyCity,
    propertyState,
    propertyZip,
    propertyName,
    propertyType,
    propertySize,
    propertyYearBuilt,
    propertyYearMostRecentRenovation,
    propertyNumberOfBuildings,
    propertyNumberOfFloors,
    propertyClass,
    parkingSpacesGarage,
    parkingSpacesCovered,
    parkingSpacesSurface,
    propertyMinimumClearHeight,
    columnHeight,
    numberOfDockDoors,
    numberOfDriveInDoors,
    officeSpacePercentage,
    commercialSpace,
    commercialSpaceSqFt,
    conventionMeeetingSpaceSqFt,
    fullServiceHotel,
    restaurant,
    restaurantOwnedLeased,
    hotelFlagged,
    hotelBrand,
    franchiseAgreementExpiration,
    goodStandingFranchise,
    lastPIPOrRenovation,
    lastPIPOrRenovationCost,
    PIPOrRenovationExpected,
    PIPOrRenovationExpectedCost,
    PIPOrRenovationDescription,
    propertyDescription,
  } = state.deal.deal;

  let team;

  if (Object.keys(state.team.team).length > 0) {
    team = { label: state.team.team.teamName, value: state.team.team._id };
  } else if (
    state.team.teams.filter((team) =>
      team.teamMembers.some(
        (member) => member.userId._id === state.auth.whoami._id
      )
    ).length === 1
  ) {
    const myTeam = state.team.teams.filter((team) =>
      team.teamMembers.some(
        (member) => member.userId._id === state.auth.whoami._id
      )
    );
    team = { label: myTeam[0].teamName, value: myTeam[0]._id };
  } else {
    team = "";
  }
  const dealPropertyType = propertyType || locationState.propertyType;
  return {
    isPristine: isPristine("CreateDeal")(state),
    action: state.action,
    myCompany: state.company.myCompany,
    dropdowns: state.program.dropdowns,
    team: state.team.team,
    teams: state.team.teams,
    deal: state.deal.deal,
    initialValues: {
      propertyAddress,
      propertyCity,
      propertyZip,
      propertyName: propertyName || locationState.propertyName,
      propertySize,
      propertyYearBuilt,
      propertyYearMostRecentRenovation,
      propertyNumberOfBuildings,
      propertyNumberOfFloors,
      parkingSpacesGarage,
      parkingSpacesCovered,
      parkingSpacesSurface,
      propertyMinimumClearHeight,
      columnHeight,
      numberOfDockDoors,
      numberOfDriveInDoors,
      officeSpacePercentage,
      commercialSpaceSqFt,
      conventionMeeetingSpaceSqFt,
      hotelBrand,
      lastPIPOrRenovation,
      lastPIPOrRenovationCost,
      PIPOrRenovationExpectedCost,
      PIPOrRenovationDescription,
      propertyDescription,
      teamId: team || locationState.teamId,
      franchiseAgreementExpiration:
        franchiseAgreementExpiration && franchiseAgreementExpiration !== null
          ? moment.utc(franchiseAgreementExpiration).format("YYYY-MM-DD")
          : "",
      lastPIPOrRenovation:
        lastPIPOrRenovation && lastPIPOrRenovation !== null
          ? moment.utc(lastPIPOrRenovation).format("YYYY-MM-DD")
          : "",
      propertyClass:
        propertyClass && propertyClass !== null
          ? { label: propertyClass, value: propertyClass }
          : "None",
      commercialSpace:
        commercialSpace && commercialSpace !== null
          ? { label: commercialSpace, value: commercialSpace }
          : "None",
      fullServiceHotel:
        fullServiceHotel && fullServiceHotel !== null
          ? { label: fullServiceHotel, value: fullServiceHotel }
          : "None",
      restaurant:
        restaurant && restaurant !== null
          ? { label: restaurant, value: restaurant }
          : "None",
      restaurantOwnedLeased:
        restaurantOwnedLeased && restaurantOwnedLeased !== null
          ? { label: restaurantOwnedLeased, value: restaurantOwnedLeased }
          : "None",
      hotelFlagged:
        hotelFlagged && hotelFlagged !== null
          ? { label: hotelFlagged, value: hotelFlagged }
          : "None",
      goodStandingFranchise:
        goodStandingFranchise && goodStandingFranchise !== null
          ? { label: goodStandingFranchise, value: goodStandingFranchise }
          : "None",
      PIPOrRenovationExpected:
        PIPOrRenovationExpected && PIPOrRenovationExpected !== null
          ? { label: PIPOrRenovationExpected, value: PIPOrRenovationExpected }
          : "None",
      propertyState:
        propertyState && propertyState !== null
          ? { label: propertyState, value: propertyState }
          : "",
      propertyType:
      dealPropertyType && dealPropertyType !== null
          ? { label: dealPropertyType, value: dealPropertyType }
          : "None",
    },
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "CreateDeal",
    enableReinitialize: true,
  })(PropertyDetails)
);
