import { actionTypes } from "redux/actions";
import { reducerCreator, multipleReducers } from "./meta";

export const program = multipleReducers({
  initializers: {
    initialState: {
      programsTotalCount: 0,
      programs: [],
      program: {},
      dropdowns: {},
    },
  },
  list: [
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.GET_DROPDOWNS,
        successFn: (action, state) => {
          const arrName = action.data.body[0].type;
          const newArray = [];

          action.data.body.map((obj) => {
            return newArray.push({ label: obj.value, value: obj.value });
          });

          state.dropdowns[arrName] = newArray;

          return { dropdowns: state.dropdowns };
        },
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.GET_PROGRAMS_BY_COMPANY_ID,
        successFn: (action) => ({
          programs: action.data.body.rows,
          programsTotalCount: action.data.body.count,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.GET_PROGRAMS_BY_ID,
        successFn: (action) => ({
          program: action.data.body,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.UPDATE_PARTIAL_PROGRAM,
        successFn: (action) => ({
          program: action.data.body,
        }),
      },
    },
  ],
});
