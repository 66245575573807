import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Image, Container, Row, Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { getMyCompany } from "redux/actions";
import { getKeyFromLocal, storeJsonInLocal } from "utils/lsUtils";
import AssignLenderTeam from "containers/deals/update/AssignLenderTeam";
import ManageDealLenders from "containers/deals/update/ManageDealLenders";
import PopupPrompt from "components/ui/PopupPrompt";
import TSLogo from "components/ui/TSLogo";
import Spacer from "components/layout/Spacer";
import constants from "config/constants";
import SplitPane from "react-split-pane";
import SidebarSelectSections from "containers/termsheet/SidebarSelectSections";
import CreateEditParentClause from "containers/admin/CreateEditParentClause";

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deal: {},
      recentDeals: [],
      showPrompt: false,
      toggle: false,
      willContinue: false,
      promptType: "",
    };

    this.toggleMenu = this.toggleMenu.bind(this);
    this.handleShowPrompt = this.handleShowPrompt.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.deal !== this.props.deal) {
      const { deal } = this.props;
      let recentDeals = this.state.recentDeals;

      if (!recentDeals.some((d) => d.value === deal._id)) {
        recentDeals.unshift({
          label: deal.propertyName,
          value: deal._id,
        });
      }

      if (recentDeals.length > 5) {
        recentDeals.pop();
      }

      let jsonObj = {};
      jsonObj[constants.TERMST_LOCAL_STORAGE.CURRENT_DEAL] = deal;
      storeJsonInLocal(jsonObj);

      const dealInView = deal.portifolio
        ? this.state.deal
        : getKeyFromLocal(constants.TERMST_LOCAL_STORAGE.CURRENT_DEAL);

      this.setState({
        deal: dealInView,
        recentDeals,
      });
    }
    if (prevProps.lastClauseCreated !== this.props.lastClauseCreated) {
      this.handleModalClose();
    }
  }

  componentDidMount() {
    this.toggleSideBar();
    getMyCompany();

    this.setState({
      deal: getKeyFromLocal(constants.TERMST_LOCAL_STORAGE.CURRENT_DEAL),
    });
  }

  toggleSideBar() {
    const pane1El = document.querySelector(".SideBar .Pane1");
    if (pane1El) {
      pane1El.style.height = "auto";
    }
  }

  handleShowPrompt(showPrompt, val) {
    this.setState({ showPrompt, body: val });
  }

  handleModalClose = () =>
    this.setState((prevState) => ({
      ...prevState,
      showPrompt: false,
      willContinue: false,
      promptType: "",
    }));

  handleModelOpen = (promptType) => {
    const { whoami } = this.props;
    this.setState((prevState) => ({
      ...prevState,
      showPrompt: true,
      promptType,
      willContinue: whoami.role === "Admin",
    }));
  };

  handleContinue = () => {
    this.setState((prevState) => ({
      ...prevState,
      willContinue: true,
    }));
  };

  toggleMenu() {
    this.setState((prevState) => ({
      toggle: !prevState.toggle,
    }));
  }

  render() {
    const { myCompany, whoami, teams } = this.props;
    const { promptType, showPrompt, willContinue } = this.state;
    let isOnTeam;

    teams.map((team) => {
      if (team._id === this.state.deal.teamId) {
        team.teamMembers.some((member) => {
          if (member.userId._id === whoami._id) {
            isOnTeam = true;

            return true;
          } else {
            return false;
          }
        });
      }

      return true;
    });

    return (
      <Col className={"sidebar-col " + (this.state.toggle ? "toggled" : "")}>
        <div className="sidebar-wrapper">
          <aside id="sidebar">
            <Container>
              <Row className="sidebar-top">
                <Col xs="12">
                  <Spacer size={26} />
                </Col>
                <Col className={this.state.toggle ? "col-12" : "col-9"}>
                  <TSLogo white title />
                </Col>
                <Col
                  className={
                    "d-flex align-items-center text-right " +
                    (this.state.toggle
                      ? "col-12 justify-content-center"
                      : "col-3 justify-content-end ")
                  }
                >
                  <FontAwesomeIcon
                    className="navbarIcon"
                    icon={faBars}
                    onClick={this.toggleMenu}
                    onKeyPress={(e) =>
                      e.key === "Enter" ? this.toggleMenu() : null
                    }
                    tabIndex="0"
                  />
                </Col>
                <Col xs="12">
                  <Spacer size={30} />
                </Col>
              </Row>

              <Row style={{ minHeight: "100vh" }}>
                <SplitPane
                  className="SideBar col-12"
                  split="horizontal"
                  minSize={150}
                  defaultSize={220}
                  pane2Style={{ paddingTop: "1rem" }}
                >
                  {/* Pane1 */}
                  <Col xs="10">
                    <Spacer size={105} />
                    <ul id="navigation">
                      <a
                        href="#content"
                        className="skip-to-content sr-only sr-only-focusable"
                      >
                        <li className="bold">
                          <h4>Skip to main content</h4>
                        </li>
                      </a>
                      <Spacer size={15} />
                      {whoami && whoami.role === "Borrower" && (
                        <Link to="/deal">
                          <li className="bold">
                            <h4>Find a Loan</h4>
                          </li>
                          <Spacer size={15} />
                        </Link>
                      )}
                      <Link to="/dashboard">
                        <li className="bold">
                          <h4>Dashboard</h4>
                        </li>
                        <Spacer size={15} />
                      </Link>
                      {myCompany && !myCompany.message ? (
                        <a href={"/company/profile/" + myCompany._id}>
                          <li className="bold">
                            <h4>{myCompany.companyName}</h4>
                          </li>
                          <Spacer size={15} />
                        </a>
                      ) : (
                        <Link to="/company/create">
                          <li className="bold">
                            <h4>Create Company</h4>
                          </li>
                          <Spacer size={15} />
                        </Link>
                      )}
                      {whoami && whoami.role === "Admin" && (
                        <>
                          <Link to="/deal">
                            <li className="bold">
                              <h4>Create Deal</h4>
                            </li>
                            <Spacer size={15} />
                          </Link>
                          <Link to="/admin/lenders/platform">
                            <li className="bold">
                              <h4>Manage Lenders</h4>
                            </li>
                            <Spacer size={15} />
                          </Link>
                          <Link to="/admin/borrowers/platform">
                            <li className="bold">
                              <h4>Manage Borrowers</h4>
                            </li>
                            <Spacer size={15} />
                          </Link>
                          <Link to="/admin/users/platform">
                            <li className="bold">
                              <h4>Manage Users</h4>
                            </li>
                            <Spacer size={15} />
                          </Link>
                          <Link to="/admin/tags">
                            <li className="bold">
                              <h4>Manage Tags</h4>
                            </li>
                            <Spacer size={15} />
                          </Link>
                        </>
                      )}
                      {Object.keys(this.state.deal).length > 0 && (
                        <>
                          {whoami && whoami.role === "Admin" ? (
                            <PopupPrompt
                              promptTitle={"Assign Lenders"}
                              promptBody={
                                <ManageDealLenders
                                  auth={whoami}
                                  dealId={this.state.deal._id}
                                  closePrompt={() => {
                                    this.handleShowPrompt(false);
                                  }}
                                  onSubmit={() => {
                                    this.handleShowPrompt(false);
                                  }}
                                />
                              }
                              show={this.state.showPrompt}
                              onCancel={() => {
                                this.handleShowPrompt(false);
                              }}
                              onHide={() => {
                                this.handleShowPrompt(false);
                              }}
                            />
                          ) : (
                            <PopupPrompt
                              promptTitle={"Assign a team to this deal"}
                              promptBody={
                                <AssignLenderTeam
                                  dealId={this.state.deal._id}
                                  closePrompt={() => {
                                    this.setState({ assignedTeam: true });
                                    this.handleShowPrompt(false);
                                  }}
                                />
                              }
                              show={this.state.showPrompt}
                              onCancel={() => {
                                this.handleShowPrompt(false);
                              }}
                              onHide={() => {
                                this.handleShowPrompt(false);
                              }}
                            />
                          )}
                          <Spacer size={30} />
                          <p>You are viewing</p>
                          {this.state.deal.photos &&
                            this.state.deal.photos.map((photo, idx) => {
                              if (photo.isPrimaryPicture) {
                                return (
                                  <Fragment key={idx}>
                                    <Image
                                      src={photo.location}
                                      width="100%"
                                      alt={
                                        this.state.deal.propertyName +
                                        " primary photo."
                                      }
                                    />
                                    <Spacer size={15} />
                                  </Fragment>
                                );
                              } else {
                                return null;
                              }
                            })}
                          <Spacer size={10} />
                          {this.state.deal.propertyName && (
                            <h4 className="bold">
                              {this.state.deal.propertyName}
                            </h4>
                          )}
                          <Spacer size={15} />
                          <Link to={"/deal/view/" + this.state.deal._id}>
                            <li className="bold">
                              <h5>View Deal</h5>
                            </li>
                          </Link>
                          <Spacer size={10} />
                          <Link to={"/deal/inbox/" + this.state.deal._id}>
                            <li className="bold">
                              <h5>Deal Inbox</h5>
                            </li>
                          </Link>
                          <Spacer size={10} />
                          <Link
                            to={`/deal/${this.state.deal._id}/quote-summary`}
                          >
                            <li className="bold">
                              <h5>Deal Quotes</h5>
                            </li>
                          </Link>
                          <Spacer size={10} />
                          <Link to={"/deal/files/" + this.state.deal._id}>
                            <li className="bold">
                              <h5>Deal Files</h5>
                            </li>
                          </Link>
                          <Spacer size={10} />
                          <Link
                            to="#"
                            onClick={() => {
                              let jsonObj = {};

                              jsonObj[
                                constants.TERMST_LOCAL_STORAGE.DEAL_ID
                              ] = false;
                              jsonObj[
                                constants.TERMST_LOCAL_STORAGE.CURRENT_DEAL
                              ] = false;
                              jsonObj[
                                constants.TERMST_LOCAL_STORAGE.DEAL_TEAM_ID
                              ] = false;
                              storeJsonInLocal(jsonObj);

                              this.setState({ deal: {} });

                              window.location = "/dashboard";
                            }}
                          >
                            <li className="bold">
                              <h5>Clear</h5>
                            </li>
                          </Link>
                          <Spacer size={30} />
                          {whoami && whoami.role !== "Lender" && (
                            <Link
                              to={
                                whoami.role === "Admin"
                                  ? {
                                      pathname: "/admin/lenders/deal",
                                      state: {
                                        dealId: this.state.deal._id,
                                      },
                                    }
                                  : {
                                      pathname: `/deal/${this.state.deal._id}/stats`,
                                    }
                              }
                            >
                              Deal Lenders
                            </Link>
                          )}
                          <Spacer size={5} />
                          {myCompany &&
                          this.state.deal &&
                          myCompany._id === this.state.deal.company ? (
                            isOnTeam ? (
                              <Link
                                to={"/deal/" + this.state.deal._id + "/team"}
                              >
                                Manage Team
                              </Link>
                            ) : (
                              <></>
                            )
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                      {whoami?.role !== "Borrower" && (
                        <Link to="/termsheet-clause/termsheet">
                          <li className="bold">
                            <h4>Manage Termsheet and Clause</h4>
                          </li>
                        </Link>
                      )}
                      <Spacer size={30} />
                      <h4>Recent Deals</h4>
                      <Spacer size={10} />
                      {this.state.recentDeals &&
                        this.state.recentDeals.length > 0 &&
                        this.state.recentDeals.map((recentDeal, idx) => (
                          <Fragment key={idx}>
                            <Link to={"/deal/view/" + recentDeal.value}>
                              <li className="bold">
                                <h5>{recentDeal.label}</h5>
                              </li>
                            </Link>
                            <Spacer size={10} />
                          </Fragment>
                        ))}
                    </ul>
                  </Col>

                  {/* Pane2 */}
                  <Col xs="12" className="px-1">
                    <Spacer size={20} />
                    <Row className="mb-3">
                      <Col xs={12}>
                        <p className="text-center">Create Term Sheet</p>
                      </Col>
                    </Row>
                    <Row className="justify-content-center mb-3">
                      <Button
                        type="submit"
                        variant="tertiary"
                        onClick={() => this.handleModelOpen("create")}
                      >
                        New Parent Clause
                      </Button>
                    </Row>
                    <Row>
                      <SidebarSelectSections />
                    </Row>
                  </Col>
                </SplitPane>
              </Row>
            </Container>
          </aside>
        </div>

        {promptType === "create" && (
          <PopupPrompt
            promptTitle={
              promptType === "create"
                ? willContinue
                  ? "Suggest New Parent Clause"
                  : "Confirmation - Add New Parent Clause"
                : ""
            }
            promptBody={
              <CreateEditParentClause
                promptType={promptType}
                willContinue={willContinue}
                onCancel={this.handleModalClose}
                onContinue={this.handleContinue}
              />
            }
            show={showPrompt}
            onCancel={this.handleModalClose}
            onHide={this.handleModalClose}
          />
        )}
      </Col>
    );
  }
}

function mapStateToProps(state) {
  return {
    deal: state.deal.deal,
    whoami: state.auth.whoami,
    teams: state.team.teams,
    myCompany: state.company.myCompany,
    lastClauseCreated: state.clause.lastClauseCreated,
  };
}

export default connect(mapStateToProps)(Sidebar);
