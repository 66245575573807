/**
 * App Layout
 */
import React from "react";
import { Route, withRouter, Redirect, Switch } from "react-router-dom";
import { isAuthenticated, getUserRole } from "utils/authUtils";
import { getMyProfile } from "redux/actions";
import routes from "routes";
import constants from "config/constants";
import { PageTitleWrapper } from "components/hoc/withPageTitle";
import { connect } from "react-redux";
import LogRocket from "logrocket";

class Layout extends React.Component {
  componentDidMount() {
    getMyProfile();
  }

  componentDidUpdate(prevProps) {
    const { whoami } = this.props;
    if (whoami?._id && whoami?._id !== prevProps.whoami?._id) {
      LogRocket.identify(whoami._id, {
        name: whoami.firstName + " " + whoami.lastName,
        email: whoami.email,

        // Add your own custom user variables here, ie:
        role: whoami.role,
      });
    }
  }

  render() {
    const { location } = this.props;
    const isUserAuthenticated = isAuthenticated();
    const role = getUserRole();
    let permittedRoutes = [];

    /** Concat the permited routes for specific role */
    if (role) {
      let routeName = constants.TERMST_ROLEID_ROUTES_MAPPING[role];
      permittedRoutes = permittedRoutes.concat(routes[routeName]);

      if (!isUserAuthenticated && location.pathname === "/") {
        return <Redirect to={"/login"} />;
      } else if (isUserAuthenticated && location.pathname === "/") {
        return <Redirect to={`/dashboard`} />;
      }

      return (
        <>
          <Switch>
            {permittedRoutes &&
              permittedRoutes.map((route, key) => {
                return (
                  <Route
                    key={key}
                    role={role}
                    path={route.path ? route.path : ""}
                    title={route.title}
                    exact={route.isExact ? true : false}
                    render={(routeProps) => {
                      return (
                        <PageTitleWrapper
                          component={route.component}
                          title={route.title}
                          route={route}
                          searchParam={route.searchParam}
                          {...routeProps}
                        />
                      );
                    }}
                  />
                );
              })}
          </Switch>
        </>
      );
    } else {
      return <></>;
    }
  }
}

function mapStateToProps(state) {
  return {
    whoami: state.auth.whoami,
  };
}
export default withRouter(connect(mapStateToProps)(Layout));
