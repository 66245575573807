import React, { useEffect } from 'react';
import { changeRoute } from 'redux/actions/genericAction';

const APP_NAME = 'Term St.'

const setPageTitle = (title) => document.title = title ? `${APP_NAME} | ${title}` : APP_NAME;

const withPageTitle = (WrappedComponent, title = "", route) => {
    return props => {
        useEffect(() => {
            setPageTitle(title);
            if (route) {
                changeRoute(route);
            }
        }, []);
        return <WrappedComponent {...props} />
    }
}
export const PageTitleWrapper = ({ component: WrappedComponent, title = "", route, ...props }) => {
    useEffect(() => {
        setPageTitle(title);
        if (route) {
            changeRoute(route);
        }
    });
    return <WrappedComponent {...props} />
}
export default withPageTitle;