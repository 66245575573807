import React from "react";
import { connect } from "react-redux";
import { Row, Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTh, faFilter } from "@fortawesome/free-solid-svg-icons";
import { getDealById } from "redux/actions";
import { reduxForm, reset, change } from "redux-form";
import { getKeyFromLocal } from "utils/lsUtils";
import PopupPrompt from "components/ui/PopupPrompt";
import CreateMessage from "containers/admin/actions/CreateMessage";
import FilterCompanies from "containers/admin/update/FilterCompanies";
import Spacer from "components/layout/Spacer";
import Loader from "components/ui/Loader";
import Lender from "./Lender";
import AddLender from "./actions/AddLender";
import constants from "config/constants";
import axios from "axios";
import { toast } from "react-toastify";
import Socket from "utils/Socket";
import api from "utils/api";

class ManageLendersDeal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companies: [],
      bulkSelected: [],
      lendersToMessage: [],
      lendersToAdd: [],
      filters: {},
      dealId: props.location.state.dealId,
      option: "",
      showPrompt: false,
      toggleBulkActions: false,
      getCompanyToAddToDeal: false,
      notSearchingAllCompanies: false,
      loading: false,
      numOfLenders: 0,
      lenderIteration: 0,
      lenderStats: {},
    };

    this.pendingEventsCount = 0;
  }

  fetchDealLenders = (otherFilters = "") => {
    api
      .request({
        url: `/company/lookup?companyType=Lender&dealId=${this.state.dealId}&order=DESC&${otherFilters}`,
      })
      .then((response) => {
        const res = response.data.body;
        this.setState({ companies: res });
      });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.company !== this.props.company) {
      const { company } = this.props;

      if (this.state.getCompanyToAddToDeal) {
        this.setState({ getCompanyToAddToDeal: false }, function () {
          this.getTeamsByCompany(company);
        });
      } else {
        this.fetchDealLenders();
      }
    }

    if (
      this.state.numOfLenders !== 0 &&
      this.state.numOfLenders === this.state.lenderIteration
    ) {
      const { location } = this.props;

      this.setState(
        {
          companies: [],
          numOfLenders: 0,
          lenderIteration: 0,
          loading: false,
        },
        function () {
          getDealById(location.state.dealId);
        }
      );
    }
  }

  componentDidMount() {
    const { location } = this.props;

    if (location.state && location.state.dealId) {
      this.setState({
        dealId: location.state.dealId,
      });

      getDealById(location.state.dealId);

      this.fetchDealLenders();
    }

    this.fetchLenderStats();
    this.statsSubscriptionId = Socket.subscribe(
      `/deal/${location.state.dealId}/stats`,
      () => {
        if (this.apiInProgress) {
          this.pendingEventsCount++;
        } else {
          this.fetchLenderStats(true);
        }
      }
    );
  }

  fetchLenderStats = (silentRefresh = false) => {
    const { location } = this.props;
    const { dealId } = location.state;
    if (!silentRefresh) {
      this.setState({ loading: true });
    }
    this.apiInProgress = true;
    api
      .request({ url: `/deals/${dealId}/stats?page=${1}&limit=${100}` })
      .then((success) => {
        this.setState({ lenderStats: success.data.body.rows });
      })
      .finally(() => {
        if (!silentRefresh) {
          this.setState({ loading: false });
        }
        this.apiInProgress = false;
        if (this.pendingEventsCount) {
          this.fetchLenderStats(true);
          this.pendingEventsCount = 0;
        }
      });
  };

  handleShowPrompt = (showPrompt) => {
    this.setState({ showPrompt });
  };

  getTeamsByCompany = (company, callback) => {
    const { location } = this.props;
    const origins = constants.API_ORIGINS;
    let endPoint = constants.API_PROTOCOL + origins[window.location.host];
    const jwtToken = getKeyFromLocal(constants.TERMST_LOCAL_STORAGE.JWT_KEY);
    this.setState({ loading: true });
    axios
      .get(`${endPoint}/teams/?companyId=${company._id}`, {
        headers: { accessToken: jwtToken },
        responseType: "json",
      })
      .then((teams) => {
        const primaryTeam = teams.data.body.rows.filter((team) => {
          // team.teamMembers.find((member) => member.globalRole === "Team Leader")
          return team.teamMembers.find(
            (member) => member.userId?._id === company.defaultContact._id
          );
        });
        this.setState({ loading: false });

        if (typeof callback === "function") {
          callback(teams, primaryTeam);
        } else {
          this.updateDealLenderAndTeam(
            location.state.dealId,
            company._id,
            company.companyName,
            primaryTeam?.[0]?._id
          );
        }
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  };

  updateDealLenderAndTeam = (dealId, companyId, companyName, teamId) => {
    if (!teamId) {
      toast.error("This company do not have a team", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    const origins = constants.API_ORIGINS;
    let endPoint = constants.API_PROTOCOL + origins[window.location.host];
    const jwtToken = getKeyFromLocal(constants.TERMST_LOCAL_STORAGE.JWT_KEY);
    this.setState({ loading: true });
    axios
      .put(
        `${endPoint}/deals/update/${dealId}/lenders`,
        {
          add: companyId,
          companyName,
          teamId,
        },
        {
          headers: { accessToken: jwtToken },
          responseType: "json",
        }
      )
      .then(() => {
        this.setState((prevState) => {
          return {
            lenderIteration: prevState.lenderIteration + 1,
          };
        });
        this.setState({ loading: false });

        // searchCompanies({
        // 	companyType: "Lender",
        // 	dealId: this.props.location.state.dealId,
        // });
        this.fetchDealLenders();
      })
      .catch((error) => {
        this.setState({ loading: false });
        toast.error(error.response?.data?.message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  addToDeal = (data) => {
    const origins = constants.API_ORIGINS;
    let endPoint = constants.API_PROTOCOL + origins[window.location.host];
    const jwtToken = getKeyFromLocal(constants.TERMST_LOCAL_STORAGE.JWT_KEY);
    let company;
    this.setState({ loading: true });
    axios
      .get(`${endPoint}/company/${data}`, {
        headers: { accessToken: jwtToken },
        responseType: "json",
      })
      .then((result) => {
        company = result.data.body;

        this.getTeamsByCompany(company);
        this.setState({ loading: false });
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  };

  handleLongPress = (target, company) => {
    try {
      target.closest(".card").style.background = "rgba(10, 70, 127, 0.2)";

      this.buttonPressTimer = setTimeout(() => {
        this.bulkSelect(company);
      }, 500);
    } catch (e) {}
  };

  handleLongRelease = (e) => {
    if (e.target.closest(".card")) {
      e.target.closest(".card").style.background = "#FFFFFF";
    }

    clearTimeout(this.buttonPressTimer);
  };

  handleRemoveLender = (id) => {
    const { location } = this.props;
    const origins = constants.API_ORIGINS;
    let endPoint = constants.API_PROTOCOL + origins[window.location.host];
    const jwtToken = getKeyFromLocal(constants.TERMST_LOCAL_STORAGE.JWT_KEY);

    axios
      .put(
        `${endPoint}/deals/update/${location.state.dealId}/lenders`,
        {
          remove: id,
        },
        {
          headers: { accessToken: jwtToken },
          responseType: "json",
        }
      )
      .then(() => {
        this.setState({ notSearchingAllCompanies: true }, function () {
          // searchCompanies({
          // 	companyType: "Lender",
          // 	dealId: location.state.dealId,
          // });
          this.fetchDealLenders();
        });
      })
      .catch(() => {});
  };

  bulkSelect = (company) => {
    const { dispatch } = this.props;
    const hasId = this.state.bulkSelected.some((selected) =>
      selected.id === company._id ? true : false
    );
    const filteredLender = this.state.companies.filter(
      (l) => l._id === company._id
    );
    const lender = {
      id: company._id,
      companyName: company.companyName,
      teamId: filteredLender[0].teamId,
    };

    if (hasId) {
      dispatch(change("BulkActions", "selectCompany" + company._id, false));

      this.setState({
        bulkSelected: this.state.bulkSelected.filter(
          (selected) => selected.id !== company._id
        ),
        lendersToMessage: this.state.lendersToMessage.filter(
          (l) => l.id !== lender.id
        ),
      });
    } else {
      this.setState((prevState) => ({
        bulkSelected: [
          ...prevState.bulkSelected,
          { id: company._id, selected: true },
        ],
        lendersToMessage: [...prevState.lendersToMessage, lender],
      }));

      dispatch(change("BulkActions", "selectCompany" + company._id, true));
    }
  };

  handleSendMessage = (lenderToMessage) => {
    const filteredLender = this.state.companies.filter(
      (l) => l._id === lenderToMessage._id
    );
    const lender = {
      id: lenderToMessage._id,
      companyName: lenderToMessage.companyName,
      teamId: filteredLender[0].teamId,
    };

    this.setState({
      option: "Message",
      toggleBulkActions: false,
      bulkSelected: [],
      lendersToMessage: [lender],
    });

    this.handleShowPrompt(true);
  };

  handleTouchStart = (e, company) => {
    if (
      !(
        e.target.nodeName === "svg" ||
        e.target.nodeName === "path" ||
        e.target.nodeName === "BUTTON"
      )
    ) {
      this.handleLongPress(e.target, company);
    }
  };

  handleMouseDown = (e, company) => {
    if (
      !(
        e.target.nodeName === "svg" ||
        e.target.nodeName === "path" ||
        e.target.nodeName === "BUTTON"
      )
    ) {
      this.handleLongPress(e.target, company);
    }
  };

  handleFieldClick = (e, company) => {
    if (e.target.firstChild !== null) {
      const isSelected = this.state.bulkSelected.some((selected) =>
        selected.selected === true && selected.id === company._id ? true : false
      );
      const filteredLender = this.state.companies.filter(
        (l) => l._id === company._id
      );
      const lender = {
        id: company._id,
        companyName: company.companyName,
        teamId: filteredLender[0].teamId,
      };

      if (isSelected) {
        this.setState(
          {
            bulkSelected: this.state.bulkSelected.filter(
              (b) => b.id !== company._id
            ),
          },
          function () {
            this.setState((prevState) => ({
              bulkSelected: [
                ...prevState.bulkSelected,
                { id: company._id, selected: false },
              ],
              lendersToMessage: this.state.lendersToMessage.filter(
                (l) => l.id !== lender.id
              ),
            }));
          }
        );
      } else {
        this.setState(
          {
            bulkSelected: this.state.bulkSelected.filter(
              (b) => b.id !== company._id
            ),
          },
          function () {
            this.setState((prevState) => ({
              bulkSelected: [
                ...prevState.bulkSelected,
                { id: company._id, selected: true },
              ],
              lendersToMessage: [...prevState.lendersToMessage, lender],
            }));
          }
        );
      }

      e.target.firstChild.click();
    }
  };

  handleBulkActions = () => {
    if (this.state.companies.length > 0 && !this.state.toggleBulkActions) {
      this.state.companies.map((company) => {
        const hasId = this.state.bulkSelected.some(
          (selected) => selected.id === company._id
        );

        if (!hasId) {
          this.setState((prevState) => ({
            bulkSelected: [
              ...prevState.bulkSelected,
              {
                id: company._id,
                selected: false,
              },
            ],
          }));
        }

        return true;
      });

      this.setState({ toggleBulkActions: true });
    } else if (this.state.toggleBulkActions) {
      this.setState({
        bulkSelected: [],
        lendersToMessage: [],
        toggleBulkActions: false,
      });

      this.props.dispatch(reset("BulkActions"));
    }
  };

  handleSelectAll = () => {
    this.setState({ bulkSelected: [], lendersToMessage: [] });

    this.state.companies.map((company) => {
      const filteredLender = this.state.companies.filter(
        (l) => l._id === company._id
      );
      const lender = {
        id: filteredLender[0]._id,
        companyName: filteredLender[0].companyName,
        teamId: filteredLender[0].teamId,
      };

      this.setState(
        (prevState) => ({
          bulkSelected: [
            ...prevState.bulkSelected,
            {
              id: company._id,
              selected: true,
            },
          ],
          lendersToMessage: [...prevState.lendersToMessage, lender],
        }),
        function () {
          this.props.dispatch(
            change("BulkActions", "selectCompany" + company._id, true)
          );
        }
      );

      return true;
    });
  };

  handleDeselectAll = () => {
    this.setState({ bulkSelected: [], lendersToMessage: [] });

    this.state.companies.map((company) => {
      this.setState(
        (prevState) => ({
          bulkSelected: [
            ...prevState.bulkSelected,
            {
              id: company._id,
              selected: false,
            },
          ],
        }),
        function () {
          this.props.dispatch(
            change("BulkActions", "selectCompany" + company._id, false)
          );
        }
      );

      return true;
    });
  };

  componentWillUnmount() {
    Socket.unsubscribe(this.statsSubscriptionId);
  }
  getLenders = () => {
    const { companies } = this.state;
    const passedLenders = [];
    const dealLenders = [];
    if (companies) {
      companies.forEach((company) => {
        const lender = this.props.deal.lendersAssigned?.find(
          (lender) => lender.companyId === company._id
        );
        if (lender) {
          if (lender.isPassed) {
            passedLenders.push(lender);
          } else {
            dealLenders.push(lender);
          }
        }
      });
    }
    return [...dealLenders, ...passedLenders];
  };

  render() {
    const { action, whoami, location, dispatch } = this.props;
    const lenders = this.getLenders();
    return (
      <>
        {this.state.option === "Message" && (
          <PopupPrompt
            promptTitle="Send Message"
            show={this.state.showPrompt}
            promptBody={
              <CreateMessage
                option="Message"
                lenders={this.state.lendersToMessage}
                dealId={this.state.dealId}
                onSubmit={() => {
                  this.handleShowPrompt(false);
                  this.setState({
                    option: "",
                    lendersToMessage: [],
                    bulkSelected: [],
                  });
                  dispatch(reset("CreateMessage"));
                }}
              />
            }
            onCancel={() => {
              this.handleShowPrompt(false);
              this.setState({
                option: "",
                lendersToMessage: [],
                bulkSelected: [],
              });
              dispatch(reset("CreateMessage"));
            }}
            onHide={() => {
              this.handleShowPrompt(false);
              this.setState({
                option: "",
                lendersToMessage: [],
                bulkSelected: [],
              });
              dispatch(reset("CreateMessage"));
            }}
          />
        )}
        {this.state.option === "Lender" && (
          <PopupPrompt
            promptTitle="Add Lenders"
            show={this.state.showPrompt}
            promptBody={
              <AddLender
                dealId={location.state.dealId}
                onSubmit={(data) => {
                  this.handleShowPrompt(false);
                  dispatch(reset("AddLender"));

                  if (Array.isArray(data)) {
                    this.setState({
                      option: "",
                      // loading: true,
                      numOfLenders: data.length,
                    });

                    data.map((lender) => {
                      this.addToDeal(lender.value);

                      return true;
                    });
                  }
                }}
              />
            }
            onCancel={() => {
              this.handleShowPrompt(false);
              this.setState({ option: "", lendersToMessage: [] });
              dispatch(reset("AddLender"));
            }}
            onHide={() => {
              this.handleShowPrompt(false);
              this.setState({ option: "", lendersToMessage: [] });
              dispatch(reset("AddLender"));
            }}
          />
        )}
        {this.state.option === "Filter" && (
          <PopupPrompt
            promptTitle="Filter Lenders"
            promptBody={
              <FilterCompanies
                onSubmit={(filters) => {
                  if (
                    Object.keys(filters).some(
                      (datum) => filters[datum] !== undefined
                    )
                  ) {
                    this.setState({ option: "", filters });

                    let programFiltersQuery = "";

                    if (filters.programTypes) {
                      programFiltersQuery += `&programTypes=${filters.programTypes.label}`;
                    }

                    if (filters.lenderType) {
                      programFiltersQuery += `&lenderType=${filters.lenderType.label}`;
                    }

                    if (filters.servicing) {
                      programFiltersQuery += `&servicing=${filters.servicing.label}`;
                    }

                    if (filters.loanAmount) {
                      programFiltersQuery += `&loanAmount=${filters.loanAmount}`;
                    }

                    if (filters.apartmentTypesList) {
                      filters.apartmentTypesList.map((item) => {
                        programFiltersQuery += `&apartmentTypesList=${item.label}`;

                        return true;
                      });
                    }

                    if (filters.officeTypesList) {
                      filters.officeTypesList.map((item) => {
                        programFiltersQuery += `&officeTypesList=${item.label}`;

                        return true;
                      });
                    }

                    if (filters.retailTypeList) {
                      filters.retailTypeList.map((item) => {
                        programFiltersQuery += `&retailTypeList=${item.label}`;

                        return true;
                      });
                    }

                    if (filters.industrialTypeList) {
                      filters.industrialTypeList.map((item) => {
                        programFiltersQuery += `&industrialTypeList=${item.label}`;

                        return true;
                      });
                    }

                    if (filters.hotelTypeList) {
                      filters.hotelTypeList.map((item) => {
                        programFiltersQuery += `&hotelTypeList=${item.label}`;

                        return true;
                      });
                    }

                    if (filters.seniorLivingList) {
                      filters.seniorLivingList.map((item) => {
                        programFiltersQuery += `&seniorLivingList=${item.label}`;

                        return true;
                      });
                    }

                    if (filters.otherPropertyDealTypesWeLike) {
                      filters.otherPropertyDealTypesWeLike.map((item) => {
                        programFiltersQuery += `&otherPropertyDealTypesWeLike=${item.label}`;

                        return true;
                      });
                    }

                    if (filters.fixedFloating) {
                      filters.fixedFloating.map((item) => {
                        programFiltersQuery += `&fixedFloating=${item.label}`;

                        return true;
                      });
                    }

                    if (filters.fixedRateLoanPrepay) {
                      filters.fixedRateLoanPrepay.map((item) => {
                        programFiltersQuery += `&fixedRateLoanPrepay=${item.label}`;

                        return true;
                      });
                    }

                    if (filters.floatingRateLoanPrepay) {
                      filters.floatingRateLoanPrepay.map((item) => {
                        programFiltersQuery += `&floatingRateLoanPrepay=${item.label}`;

                        return true;
                      });
                    }

                    if (filters.rateStructure) {
                      programFiltersQuery += `&rateStructure=${filters.rateStructure.label}`;
                    }

                    if (filters.maxLTV) {
                      programFiltersQuery += `&maxLTV=${filters.maxLTV.label.slice(
                        0,
                        2
                      )}%25`;
                    }

                    if (filters.competitiveLTV) {
                      programFiltersQuery += `&competitiveLTV=${filters.competitiveLTV.label.slice(
                        0,
                        2
                      )}%25`;
                    }

                    if (filters.geography) {
                      filters.geography.map((item) => {
                        programFiltersQuery += `&geography=${item.label}`;

                        return true;
                      });
                    }

                    if (filters.otherCarrots) {
                      filters.otherCarrots.map((item) => {
                        programFiltersQuery += `&otherCarrots=${item.label}`;

                        return true;
                      });
                    }

                    // getFilteredLenders({
                    // 	programFilters: programFiltersQuery,
                    // 	dealId: this.state.dealId
                    // });
                    this.fetchDealLenders(programFiltersQuery);
                  }
                  this.handleShowPrompt(false);
                }}
                companyType="Lender"
                filters={this.state.filters}
                skipSearchOnReset={true}
                onResetFilters={() => {
                  this.setState({ filters: {} });
                  this.fetchDealLenders();
                }}
              />
            }
            show={this.state.showPrompt}
            onCancel={() => {
              this.setState({
                option: "",
              });
              this.handleShowPrompt(false);
            }}
            onHide={() => {
              this.setState({
                option: "",
              });
              this.handleShowPrompt(false);
            }}
          />
        )}
        {(action.GET_DEAL_BY_ID.isFetching ||
          action.GET_COMPANY_BY_ID.isFetching ||
          this.state.loading) &&
          !this.state.showPrompt && <Loader files />}

        <Row className="mb-3" noGutters={true}>
          <Col className="d-flex align-items-center border-bottom" xs="12">
            <Button
              className="fake-link bold inbox-action-item"
              variant="fake-link"
              onClick={this.handleBulkActions}
              tabIndex="0"
            >
              <FontAwesomeIcon icon={faTh} /> <span>Bulk Actions</span>
            </Button>
            {this.state.bulkSelected.length > 0 && (
              <div className="bulk-actions">
                <Button
                  className="fake-link ml-3 inbox-action-item"
                  variant="fake-link"
                  onClick={this.handleSelectAll}
                >
                  Select All
                </Button>
                <Button
                  className="fake-link ml-3 inbox-action-item"
                  variant="fake-link"
                  onClick={this.handleDeselectAll}
                >
                  Deselect All
                </Button>
              </div>
            )}
            {whoami &&
              (whoami.role === "Admin" || whoami.role === "Borrower") &&
              this.state.lendersToMessage.length > 0 && (
                <>
                  <div className="d-none d-md-inline-block">
                    <span className="pl-3 pr-3">|</span>
                    <Button
                      className="fake-link bold inbox-action-item"
                      variant="fake-link"
                      onClick={() => {
                        this.setState({ option: "Message" });
                        this.handleShowPrompt(true);
                      }}
                    >
                      Send Message
                    </Button>
                  </div>
                </>
              )}
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <div style={{ flex: 1 }} className="text-right">
              {whoami && whoami.role === "Admin" && (
                <Button
                  className="pt-2 pb-2 mr-3"
                  onClick={() => {
                    this.setState({ option: "Lender" });
                    this.handleShowPrompt(true);
                  }}
                  variant="tertiary"
                >
                  Add Lender
                </Button>
              )}
              <Button
                className="pt-2 pb-2"
                onClick={() => {
                  this.setState({ option: "Filter" });
                  this.handleShowPrompt(true);
                }}
                variant="tertiary"
              >
                <FontAwesomeIcon className="font-14 mr-3" icon={faFilter} />
                Filter
              </Button>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <Spacer size={20} />
          </Col>
          <Col xs="12">
            {this.state.companies &&
              lenders?.map((lender, idx) => {
                const company = this.state.companies?.find(
                  (com) => com._id === lender.companyId
                );
                return (
                  <Lender
                    bulkSelected={this.state.bulkSelected}
                    dealId={location.state.dealId}
                    company={company}
                    handleFieldClick={this.handleFieldClick}
                    handleRemoveLender={this.handleRemoveLender}
                    handleSendMessage={this.handleSendMessage}
                    handleTouchStart={this.handleTouchStart}
                    handleTouchEnd={this.handleLongRelease}
                    handleMouseUp={this.handleLongRelease}
                    handleMouseDown={this.handleMouseDown}
                    handleMouseLeave={this.handleLongRelease}
                    key={idx}
                    stat={this.state.lenderStats[company?._id] || {}}
                    getTeamsByCompany={this.getTeamsByCompany}
                    lender={lender}
                  />
                );
              })}
          </Col>
        </Row>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    action: state.action,
    deal: state.deal.deal,
    whoami: state.auth.whoami,
    teams: state.team.teams,
    companies: state.admin.companies,
    company: state.company.company,
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "BulkActions",
    enableReinitialize: true,
  })(ManageLendersDeal)
);
