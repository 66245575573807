import React from "react";
import { connect } from "react-redux";
import { Accordion, Spinner, Button, Row, Col } from "react-bootstrap";
import { searchCompanies, getFilteredBorrowers } from "redux/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import Company from "containers/admin/Company";
import PopupPrompt from "components/ui/PopupPrompt";
import FilterCompanies from "containers/admin/update/FilterCompanies";
import { Link } from "react-router-dom";
import Paginate from "components/ui/Paginate";
import { isEmpty } from "lodash";

class ManageBorrowers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      option: "",
      filters: {},
      companies: [],
      companiesTotal: 0,
      pagination: {
        page: 1,
        listOffset: 25,
      },
      teamsByCompany: [],
      activeExpandKey: null,
    };

    this.handleShowPrompt = this.handleShowPrompt.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.companies !== this.props.companies) {
      const { companies, companiesTotal } = this.props;
      const teamsByCompany = companies
        .filter(
          (company) =>
            !this.state.teamsByCompany.some((e) => e.companyId === company._id)
        )
        .map((company) => ({
          companyId: company._id,
          teams: [],
        }));

      this.setState({
        teamsByCompany,
        companies,
        companiesTotal,
      });
    }
  }

  componentDidMount() {
    this.getCompanies();
  }

  getCompanies = () => {
    const { pagination } = this.state;
    searchCompanies({
      companyType: "Borrower",
      page: pagination.page,
      limit: pagination.listOffset,
    });
  };
  handlePagination = (page) => {
    const callback = isEmpty(this.state.filters)
      ? this.getCompanies
      : this.getCompaniesWithFilter;
    this.setState(
      (prevState) => ({ pagination: { ...prevState.pagination, page } }),
      callback
    );
  };
  handleShowPrompt(showPrompt) {
    this.setState({ showPrompt });
  }

  getCompaniesWithFilter = () => {
    const { filters, pagination } = this.state;
    let dealStatusQuery = "";
    let loginQuery = "";

    if (filters.hasActiveDeal && filters.hasActiveDeal.value === "Yes") {
      dealStatusQuery += "&dealStatus=Active";
    }

    if (
      filters.hasDealUnderReview &&
      filters.hasDealUnderReview.value === "Yes"
    ) {
      dealStatusQuery += "&dealStatus=Under review";
    }

    if (filters.hasDealClosed && filters.hasDealClosed.value === "Yes") {
      dealStatusQuery += "&dealStatus=Closed";
    }

    if (
      filters.hasDealInProgress &&
      filters.hasDealInProgress.value === "Yes"
    ) {
      dealStatusQuery += "&dealStatus=In progress";
    }

    if (filters.lastLoginStart) {
      loginQuery += "&lastLoginStart=" + filters.lastLoginStart;
    }

    if (filters.lastLoginEnd) {
      loginQuery += "&lastLoginEnd=" + filters.lastLoginEnd;
    }

    getFilteredBorrowers({
      dealStatus: dealStatusQuery,
      loginQuery,
      page: pagination.page,
      limit: pagination.listOffset,
    });
  };

  onSubmitFilters = (filters) => {
    if (Object.keys(filters).some((datum) => filters[datum] !== undefined)) {
      this.setState(
        { option: "", filters, pagination: { page: 1, listOffset: 25 } },
        this.getCompaniesWithFilter
      );
    }

    this.handleShowPrompt(false);
  };

  render() {
    const { action } = this.props;
    const { companies, pagination, companiesTotal } = this.state;

    if (
      !(
        action.SEARCH_COMPANIES.isFetching ||
        action.GET_FILTERED_BORROWERS.isFetching
      )
    ) {
      return (
        <>
          <Row className="mb-3 pt-3" noGutters={true}>
            <Col className="d-flex" xs="12">
              <div style={{ flex: 1 }} className="text-right">
                <Link to="/company/create?type=Borrower">
                  <Button className="pt-2 pb-2 mr-2" variant="primary">
                    Add Borrower
                  </Button>
                </Link>
                <Button
                  className="pt-2 pb-2"
                  onClick={() => {
                    this.handleShowPrompt(true);
                    this.setState({ option: "Filter" });
                  }}
                  variant="tertiary"
                >
                  <FontAwesomeIcon className="font-14 mr-3" icon={faFilter} />
                  Filter
                </Button>
              </div>
            </Col>
          </Row>
          <Row>
            {this.state.option === "Filter" && (
              <PopupPrompt
                promptTitle="Filter Borrowers"
                promptBody={
                  <FilterCompanies
                    onSubmit={(filters) => this.onSubmitFilters(filters)}
                    companyType="Borrower"
                    pagination={pagination}
                    onResetFilters={() => this.setState({ filters: {} })}
                    filters={this.state.filters}
                  />
                }
                show={this.state.showPrompt}
                onCancel={() => {
                  this.setState({
                    option: "",
                  });
                  this.handleShowPrompt(false);
                }}
                onHide={() => {
                  this.setState({
                    option: "",
                  });
                  this.handleShowPrompt(false);
                }}
              />
            )}
            <Accordion className="w-100" activeKey={this.state.activeExpandKey}>
              {companies.length > 0 ? (
                companies.map((company) => {
                  return (
                    <Company
                      company={company}
                      key={company._id}
                      type="Borrower"
                      onAddToDeal={(data) => {
                        this.setState(
                          {
                            companyToAdd: data.companyToAdd,
                            companyName: data.companyName,
                            teamToAdd: data.teamToAdd,
                          },
                          function () {
                            this.handleShowPrompt(true);
                          }
                        );
                      }}
                      setExpandedKey={(key) =>
                        this.setState({ activeExpandKey: key })
                      }
                    />
                  );
                })
              ) : (
                <div className="text-center">
                  <p>There are currently no companies to view here.</p>
                </div>
              )}
            </Accordion>
            <Paginate
              {...pagination}
              list={companies}
              listTotalCount={companiesTotal}
              previousPage={this.handlePagination}
              nextPage={this.handlePagination}
            />
          </Row>
        </>
      );
    } else {
      return (
        <>
          <Row className="mb-3 pt-3" noGutters={true}>
            <Col className="d-flex" xs="12">
              <div style={{ flex: 1 }} className="text-right">
                <Link to="/company/create?type=Borrower">
                  <Button className="pt-2 pb-2 mr-2" variant="primary">
                    Add Borrower
                  </Button>
                </Link>
                <Button
                  className="pt-2 pb-2"
                  onClick={() => {
                    this.handleShowPrompt(true);
                    this.setState({ option: "Filter" });
                  }}
                  variant="tertiary"
                >
                  <FontAwesomeIcon className="font-14 mr-3" icon={faFilter} />
                  Filter
                </Button>
              </div>
            </Col>
          </Row>
          <div className="text-center">
            <Spinner animation="border" role="status" variant="primary">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        </>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    action: state.action,
    teams: state.team.teams,
    companies: state.admin.companies,
    companiesTotal: state.admin.companiesTotal,
  };
}

export default connect(mapStateToProps)(ManageBorrowers);
