import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Avatar, Button, Select, Space } from "antd";
import Spacer from "components/layout/Spacer";
import { getInitials } from "utils/commonUtils";
import { getTeamsByCompanyId, updateDealManager } from "redux/actions";

const DealManager = ({ deal }) => {
  const whoami = useSelector((state) => state.auth.whoami);
  const myCompany = useSelector((state) => state.company.myCompany);
  const myCompanyTeams = useSelector((state) => state.team.teams);

  const [editMode, setEditMode] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(null);

  const isAdmin = whoami.role === "Admin";

  useEffect(() => {
    if (isAdmin && myCompanyTeams.length === 0)
      getTeamsByCompanyId(myCompany._id);
  }, [isAdmin, myCompany, myCompanyTeams]);

  const { teamIdManager } = deal;
  const {
    _id: _teamIdManager,
    companyId,
    teamMembers = [],
  } = selectedTeam || teamIdManager;
  const teamLeadMember = teamMembers.find(
    (member) => member.globalRole === "Team Leader"
  );
  const teamLead = teamLeadMember?.userId;
  const teamLeadFullname = teamLead
    ? `${teamLead.firstName} ${teamLead.lastName}`
    : "-";
  const teamLeadContactNumber = teamLead?.phoneLandline?.trim()
    ? teamLead?.phoneLandline
    : teamLead?.phoneMobile?.trim();

  const selectOptions = useMemo(() => {
    const result = [];
    myCompanyTeams.forEach((team) => {
      const { _id, teamMembers } = team;
      const memberLead = teamMembers.find(
        (member) => member.globalRole === "Team Leader"
      );
      if (memberLead) {
        result.push({
          value: _id,
          label: `${memberLead.userId.firstName} ${memberLead.userId.lastName}`,
          team,
        });
      }
    });
    return result;
  }, [myCompanyTeams]);

  const handleChange = (_, option) => {
    setSelectedTeam(option.team);
  };

  const handleSave = () => {
    updateDealManager(deal._id, {
      teamIdManager: selectedTeam._id,
    });
    setEditMode(false);
  };

  const handleCancel = () => {
    setSelectedTeam(null);
    setEditMode(false);
  };

  return (
    <div>
      <h4>Deal Manager</h4>
      <Spacer size={5} />
      <div className="border-bottom" />
      <div className="tw-flex tw-justify-between tw-flex-wrap tw-py-2">
        <Space align="start">
          <Avatar size={46} shape="circle" src={teamLead?.profileImageXS}>
            {getInitials(teamLeadFullname)}
          </Avatar>
          <div className="font-14">
            <div className="tw-flex tw-items-center tw-gap-2">
              {editMode ? (
                <Select
                  defaultValue={_teamIdManager}
                  options={selectOptions}
                  onChange={handleChange}
                />
              ) : (
                <p>{teamLeadFullname}</p>
              )}
              {editMode ? (
                <>
                  <Button size="small" type="primary" onClick={handleSave}>
                    Save
                  </Button>
                  <Button size="small" onClick={handleCancel}>
                    Cancel
                  </Button>
                </>
              ) : (
                isAdmin && (
                  <Link
                    to={"/"}
                    className="tw-text-xs"
                    onClick={(e) => {
                      e.preventDefault();
                      setEditMode(true);
                    }}
                  >
                    Edit
                  </Link>
                )
              )}
            </div>
            <p>{teamLead?.email || "-"}</p>
            <p>{teamLeadContactNumber}</p>
          </div>
        </Space>
        {companyId.companyLogoXS && (
          <img
            src={companyId.companyLogoXS}
            alt={companyId.companyName}
            className="tw-w-[80px] tw-h-full"
          />
        )}
      </div>
    </div>
  );
};

export default DealManager;
