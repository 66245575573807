import { dispatch } from "config/store";
import { actionTypes } from "./actionTypes";

export const createTeam = (data) => {
  const action = {
    type: actionTypes.CREATE_TEAM,
    config: {
      method: "post",
      url: `/teams/create`,
      data,
    },
  };

  dispatch(action);
};

export const getTeamsByCompanyId = (companyId, params) => {
  const action = {
    type: actionTypes.GET_TEAMS_BY_COMPANY_ID,
    config: {
      method: "get",
      url: `/teams?companyId=${companyId}&limit=${
        params && params.limit ? params.limit : ""
      }&page=${params && params.page ? params.page : ""}&userId=${
        params && params.userId ? params.userId : ""
      }&includeNotification=${params?.includeNotification || ""}`,
    },
  };

  dispatch(action);
};

export const getTeamById = (teamId) => {
  const action = {
    type: actionTypes.GET_TEAM_BY_ID,
    config: {
      method: "get",
      url: `/teams/${teamId}`,
    },
  };

  dispatch(action);
};

export const updateTeam = (teamId, data) => {
  const action = {
    type: actionTypes.UPDATE_TEAM,
    config: {
      method: "put",
      url: `/teams/update/${teamId}/members`,
      data,
    },
  };

  dispatch(action);
};

export const updateTeamName = (teamId, data) => {
  const action = {
    type: actionTypes.UPDATE_TEAM_NAME,
    config: {
      method: "put",
      url: `/teams/update/${teamId}/name`,
      data,
    },
  };

  dispatch(action);
};

export const updateTeamDefault = (teamId, data) => {
  const action = {
    type: actionTypes.UPDATE_TEAM_DEFAULT,
    config: {
      method: "put",
      url: `/teams/update/${teamId}/default`,
      data,
    },
  };

  dispatch(action);
};
