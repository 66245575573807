import React from "react";
import { connect } from "react-redux";
import { Badge, Button, Form, Row, Col } from "react-bootstrap";
import { Field, reduxForm } from "redux-form";
import { reduxFormTextarea, reduxFormSelect } from "components/form/ReduxForm";
import { required, noSpaces } from "utils/validation";
import {
  assignToComm,
  createComm,
  editCommGroups,
  setPublicStatus,
} from "redux/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { faImage, faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { v4 as uuidv4 } from "uuid";
import UploadFiles from "containers/deals/Communications/actions/UploadFiles";
import imageCompression from "browser-image-compression";
import Spacer from "components/layout/Spacer";
import Loader from "components/ui/Loader";
import {
  ALLOWED_FILE_EXTS,
  ALLOWED_IMAGE_EXTS,
} from "containers/deals/FileManager/constants";
import { toast } from "react-toastify";
import MultiDropdown from "../MultiDropdown";
import SelectedBadges from "containers/deals/FileManager/SelectedBadges";

const allowedFileTypes = ALLOWED_FILE_EXTS;
const allowedImageTypes = ALLOWED_IMAGE_EXTS;

const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

const groupBadgeStyles = {
  backgroundColor: "#EBECF0",
  borderRadius: "2em",
  color: "#172B4D",
  display: "inline-block",
  fontSize: 12,
  fontWeight: "normal",
  lineHeight: "1",
  minWidth: 1,
  padding: "0.16666666666667em 0.5em",
  textAlign: "center",
};

const formatGroupLabel = (data) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);

class CreateMessage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      compressing: false,
      step: 0,
      uploads: [],
      lendersAssigned: [],
      isPublic: false,
      showDropdown: false,
      selectedLendersAndGroups: [],
    };

    this.input = React.createRef();
    this.uploadPhotos = this.uploadPhotos.bind(this);
    this.isValidFile = this.isValidFile.bind(this);
    this.submitFn = this.submitFn.bind(this);
    this.createQuestion = this.createQuestion.bind(this);
    this.createMessage = this.createMessage.bind(this);
  }

  componentDidMount() {
    const { deal, whoami, groups = [] } = this.props;
    let categoryLenders = [];
    let categoryGroups = [];
    let categoryBorrowers = [];

    if (deal?.lendersAssigned) {
      deal.lendersAssigned.forEach((lender) => {
        categoryLenders.push({
          label: lender.companyName,
          value: lender.companyId,
          team: lender.teamId,
        });
      });
    }

    groups.forEach((group) => {
      categoryGroups.push({
        label: group.groupName,
        value: group._id,
        isGroup: true,
      });
    });

    if (whoami?.role === "Admin") {
      categoryLenders.push({
        label: "Deal Borrower",
        value: deal.company,
        team: deal.teamId,
      });
    } else if (whoami?.role === "Lender") {
      categoryBorrowers = [
        {
          label: deal.company?.companyName,
          value: deal.company?._id,
        },
      ];
      this.props.change("sendTo", categoryBorrowers[0]);
    }

    const termStreetOption = {
      label: "TermStreet",
      options: [
        {
          label: "TermStreet Team Only",
          value: "TermStreetTeamOnly",
        },
      ],
    };
    const lenderOptions = [
      {
        label: "Companies",
        options: categoryLenders.sort((a, b) => a.label.localeCompare(b.label)),
      },
      {
        label: "Groups",
        options: categoryGroups.sort((a, b) => a.label.localeCompare(b.label)),
      },
    ];
    const borrowerOptions = [
      {
        label: "Borrower",
        options: categoryBorrowers,
      },
    ];

    let availableToSelect = [];

    if (whoami?.role === "Admin") {
      availableToSelect = lenderOptions;
    } else if (whoami?.role === "Lender") {
      availableToSelect = [termStreetOption, ...borrowerOptions];
    } else if (whoami?.role === "Borrower") {
      availableToSelect = [termStreetOption, ...lenderOptions];
    }

    this.setState({ availableToSelect });
  }

  componentDidUpdate(prevProps) {
    const { lastCommunicationCreated, isFollowUpQuestion, whoami } = this.props;
    if (lastCommunicationCreated !== prevProps.lastCommunicationCreated) {
      if (
        whoami?.role === "Admin" &&
        lastCommunicationCreated &&
        isFollowUpQuestion
      ) {
        this.updateVisibility();
      }
      this.props.onHide();
    }
  }

  updateVisibility() {
    const { conversation } = this.props;
    const { selectedLendersAndGroups } = this.state;

    const lenderCompanyIds = conversation?.lender?.map(
      (lender) => lender.companyId
    );
    const addLenders = selectedLendersAndGroups.filter(
      (lender) => !lender.group && !lenderCompanyIds.includes(lender.value)
    );
    const selectedGroupIds = selectedLendersAndGroups
      .filter((group) => group.group)
      .map((group) => group.value);

    addLenders.forEach((lender) => {
      this.addRecipient(lender);
    });
    this.setIsPublic();
    this.updateGroupRecipient(selectedGroupIds);
  }

  setIsPublic() {
    setPublicStatus(this.props.lastCommunicationCreated._id, {
      isPublic: this.state.isPublic,
    });
  }

  addRecipient(recipient) {
    assignToComm(this.props.lastCommunicationCreated._id, {
      companyId: recipient.value,
      companyName: recipient.label,
      teamId: recipient.teamId,
    });
  }

  updateGroupRecipient(groups = []) {
    if (groups.length) {
      editCommGroups(this.props.lastCommunicationCreated._id, {
        groups,
      });
    }
  }

  isValidFile = (file) => {
    for (let i in allowedFileTypes) {
      if (file.name.toLowerCase().indexOf(allowedFileTypes[i]) !== -1) {
        return true;
      }
    }
    return false;
  };

  isValidFiles = (files, showToast = true) => {
    for (let file of files) {
      const ext = file.name.split(".").reverse()[0];
      if (!ALLOWED_FILE_EXTS.includes(`.${ext.toLowerCase()}`)) {
        if (showToast) {
          toast.error(
            `Contains Unsupported file(s). Allows only ${ALLOWED_FILE_EXTS.join(
              ", "
            )}`,
            {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        }
        return false;
      }
    }
    return true;
  };

  isValidImage = (file) => {
    for (let i in allowedImageTypes) {
      if (file.name.toLowerCase().indexOf(allowedImageTypes[i]) !== -1) {
        return true;
      }
    }
    return false;
  };

  uploadPhotos(e) {
    if (e && e.target.files.length > 0) {
      const files = e.target.files;
      const options = {
        maxSizeMB: 0.75,
      };

      if (this.isValidFiles(files)) {
        Object.keys(files).forEach(async (file) => {
          const tempFile = files[file];
          const name = tempFile.name;

          try {
            this.setState({ compressing: true });
            let compressedFile;

            if (this.isValidImage(tempFile)) {
              compressedFile = await imageCompression(tempFile, options);
            } else {
              compressedFile = tempFile;
            }

            this.setState((prevState) => ({
              uploads: [
                ...prevState.uploads,
                { _id: uuidv4(), file: compressedFile, name },
              ],
              compressing: false,
            }));
          } catch (error) {
            return error;
          }
        });
      }
    }
  }

  createQuestion(data) {
    const {
      myCompany,
      whoami,
      deal,
      dealId,
      isFollowUpQuestion,
      linkedQuestionId,
      linkedQuestionTitle,
    } = this.props;

    const dealTeamId = deal.teamId?._id || deal.teamId;
    const dealCompanyId = deal.company?._id || deal.company;
    const companyName = deal.company?.companyName || deal.company;

    if (whoami && whoami.role === "Admin") {
      // Asking a question to the borrower
      createComm({
        dealId: dealId,
        borrowerCompany: dealCompanyId,
        borrowerCompanyName: companyName,
        borrowerTeam: dealTeamId,
        type: "Question",
        isPublic: false,
        initialMessage: data.description,
        sender: whoami.firstName + " " + whoami.lastName,
        senderId: whoami._id,
        isFollowUpQuestion: isFollowUpQuestion ? isFollowUpQuestion : false,
        linkedQuestionId: linkedQuestionId ? linkedQuestionId : undefined,
        linkedQuestionTitle: linkedQuestionTitle
          ? linkedQuestionTitle
          : undefined,
        uploads: this.state.uploads.length > 0 ? this.state.uploads : [],
        groupIds: [],
      });
    } else if (whoami && whoami.role === "Borrower") {
      // Asking a question for everyone to see
      createComm({
        dealId: dealId,
        lenderCompany: dealCompanyId,
        lenderCompanyName: "Deal Borrower",
        borrowerCompany: dealCompanyId,
        borrowerCompanyName: "Deal Borrower",
        lenderTeam: dealTeamId,
        borrowerTeam: dealTeamId,
        type: "Question",
        isPublic: false,
        initialMessage: data.description,
        sender: whoami.firstName + " " + whoami.lastName,
        senderId: whoami._id,
        isFollowUpQuestion: isFollowUpQuestion ? isFollowUpQuestion : false,
        linkedQuestionId: linkedQuestionId ? linkedQuestionId : undefined,
        linkedQuestionTitle: linkedQuestionTitle
          ? linkedQuestionTitle
          : undefined,
        uploads: this.state.uploads.length > 0 ? this.state.uploads : [],
        groupIds: [],
      });
    } else {
      let lenderCompanyName;
      let lenderCompany;
      let lenderTeam;

      if (whoami && myCompany && whoami.role === "Lender") {
        const myLender = deal.lendersAssigned.filter((lender) => {
          return lender.companyId === myCompany._id;
        });

        lenderCompanyName = myLender[0].companyName;
        lenderCompany = myLender[0].companyId;
        lenderTeam = myLender[0].teamId;
      } else {
        lenderCompanyName = data.sendTo.label;
        lenderCompany = data.sendTo.value;
        lenderTeam = data.sendTo.team;
      }

      // Asking a question to the borrower
      createComm({
        dealId: dealId,
        lenderCompany: lenderCompany,
        lenderCompanyName: lenderCompanyName,
        borrowerCompany: deal.company._id,
        borrowerCompanyName: "Deal Borrower",
        lenderTeam: lenderTeam,
        borrowerTeam: deal.teamId._id,
        type: "Question",
        isPublic: false,
        initialMessage: data.description,
        sender: whoami.firstName + " " + whoami.lastName,
        senderId: whoami._id,
        isFollowUpQuestion: isFollowUpQuestion ? isFollowUpQuestion : false,
        linkedQuestionId: linkedQuestionId ? linkedQuestionId : undefined,
        linkedQuestionTitle: linkedQuestionTitle
          ? linkedQuestionTitle
          : undefined,
        uploads: this.state.uploads.length > 0 ? this.state.uploads : [],
        groupIds: [],
      });
    }
  }

  createMessage(data) {
    const {
      myCompany,
      whoami,
      deal,
      dealId,
      isFollowUpQuestion,
      linkedQuestionId,
      linkedQuestionTitle,
    } = this.props;

    const isVisibleByAdminOnly = Boolean(
      data.sendTo?.value === "TermStreetTeamOnly"
    );
    const dealTeamId =
      deal.teamId && typeof deal.teamId === "object"
        ? deal.teamId._id
        : deal.teamId;
    const dealCompanyId =
      deal.company && typeof deal.company === "object"
        ? deal.company._id
        : deal.company;

    if (data.sendTo?.value === "TermStreetTeamOnly") {
      Object.assign(data, {
        sendTo: {
          value: "",
          label: "",
          team: "",
        },
      });
    }

    if (whoami && whoami.role === "Admin") {
      if (data.sendTo && data.sendTo.value === deal.company) {
        // Sending a message to the borrower
        createComm({
          dealId: dealId,
          borrowerCompany: deal.company,
          borrowerCompanyName: deal.company,
          borrowerTeam: dealTeamId,
          type: "Message",
          isPublic: false,
          isVisibleByAdminOnly,
          initialMessage: data.description,
          sender: whoami.firstName + " " + whoami.lastName,
          senderId: whoami._id,
          isFollowUpQuestion: isFollowUpQuestion ? isFollowUpQuestion : false,
          linkedQuestionId: linkedQuestionId ? linkedQuestionId : undefined,
          linkedQuestionTitle: linkedQuestionTitle
            ? linkedQuestionTitle
            : undefined,
          uploads: this.state.uploads.length > 0 ? this.state.uploads : [],
          groupIds: [],
        });
      } else if (data.sendTo && data.sendTo.isGroup) {
        // Sending a message to a group
        createComm({
          dealId: dealId,
          borrowerCompany: deal.company,
          borrowerCompanyName: "Deal Borrower",
          borrowerTeam: dealTeamId,
          type: "Message",
          isPublic: false,
          isVisibleByAdminOnly,
          initialMessage: data.description,
          sender: whoami.firstName + " " + whoami.lastName,
          senderId: whoami._id,
          isFollowUpQuestion: isFollowUpQuestion ? isFollowUpQuestion : false,
          linkedQuestionId: linkedQuestionId ? linkedQuestionId : undefined,
          linkedQuestionTitle: linkedQuestionTitle
            ? linkedQuestionTitle
            : undefined,
          uploads: this.state.uploads.length > 0 ? this.state.uploads : [],
          groupIds: [data.sendTo.value],
        });
      } else {
        // Sending a message to the selected lender
        createComm({
          dealId: dealId,
          lenderCompany: data.sendTo.value,
          lenderCompanyName: data.sendTo.label,
          lenderTeam: data.sendTo.team,
          type: "Message",
          isPublic: false,
          isVisibleByAdminOnly,
          initialMessage: data.description,
          sender: whoami.firstName + " " + whoami.lastName,
          senderId: whoami._id,
          isFollowUpQuestion: isFollowUpQuestion ? isFollowUpQuestion : false,
          linkedQuestionId: linkedQuestionId ? linkedQuestionId : undefined,
          linkedQuestionTitle: linkedQuestionTitle
            ? linkedQuestionTitle
            : undefined,
          uploads: this.state.uploads.length > 0 ? this.state.uploads : [],
          groupIds: [],
        });
      }
    } else if (whoami && whoami.role === "Borrower") {
      if (data.sendTo && data.sendTo?.isGroup) {
        // Sending a message to a group
        createComm({
          dealId: dealId,
          borrowerCompany: dealCompanyId,
          borrowerCompanyName: "Deal Borrower",
          borrowerTeam: dealTeamId,
          type: "Message",
          isPublic: false,
          isVisibleByAdminOnly,
          initialMessage: data.description,
          sender: whoami.firstName + " " + whoami.lastName,
          senderId: whoami._id,
          isFollowUpQuestion: isFollowUpQuestion ? isFollowUpQuestion : false,
          linkedQuestionId: linkedQuestionId ? linkedQuestionId : undefined,
          linkedQuestionTitle: linkedQuestionTitle
            ? linkedQuestionTitle
            : undefined,
          uploads: this.state.uploads.length > 0 ? this.state.uploads : [],
          groupIds: [data.sendTo.value],
        });
      } else {
        // Sending a message to the selected lender
        createComm({
          dealId: dealId,
          borrowerCompany: dealCompanyId,
          borrowerCompanyName: "Deal Borrower",
          borrowerTeam: dealTeamId,
          lenderCompany: data.sendTo?.value,
          lenderCompanyName: data.sendTo?.label,
          lenderTeam: data.sendTo?.team,
          type: "Message",
          isPublic: false,
          isVisibleByAdminOnly,
          initialMessage: data.description,
          sender: whoami.firstName + " " + whoami.lastName,
          senderId: whoami._id,
          isFollowUpQuestion: isFollowUpQuestion ? isFollowUpQuestion : false,
          linkedQuestionId: linkedQuestionId ? linkedQuestionId : undefined,
          linkedQuestionTitle: linkedQuestionTitle
            ? linkedQuestionTitle
            : undefined,
          uploads: this.state.uploads.length > 0 ? this.state.uploads : [],
          groupIds: [],
        });
      }
    } else if (whoami && whoami.role === "Lender") {
      let lenderCompanyName;
      let lenderCompany;
      let lenderTeam;

      if (myCompany) {
        const myLender = deal.lendersAssigned.filter((lender) => {
          return lender.companyId === myCompany._id;
        });

        lenderCompanyName = myLender[0].companyName;
        lenderCompany = myLender[0].companyId;
        lenderTeam = myLender[0].teamId;
      }
      // else {
      //   lenderCompanyName = data.sendTo.label;
      //   lenderCompany = data.sendTo.value;
      //   lenderTeam = data.sendTo.team;
      // }

      // Sending a message to the borrower
      createComm({
        dealId: dealId,
        lenderCompany: lenderCompany,
        lenderCompanyName: lenderCompanyName,
        borrowerCompany: dealCompanyId,
        borrowerCompanyName: "Deal Borrower",
        lenderTeam: lenderTeam,
        borrowerTeam: dealTeamId,
        type: "Message",
        isPublic: false,
        isVisibleByAdminOnly,
        initialMessage: data.description,
        sender: whoami.firstName + " " + whoami.lastName,
        senderId: whoami._id,
        isFollowUpQuestion: isFollowUpQuestion ? isFollowUpQuestion : false,
        linkedQuestionId: linkedQuestionId ? linkedQuestionId : undefined,
        linkedQuestionTitle: linkedQuestionTitle
          ? linkedQuestionTitle
          : undefined,
        uploads: this.state.uploads.length > 0 ? this.state.uploads : [],
        groupIds: [],
      });
    }
  }

  handleSelectLendersAndGroups = (selectedLendersAndGroups) => {
    this.setState({ selectedLendersAndGroups });
  };

  submitFn(data) {
    const { option, onSubmit } = this.props;

    if (option === "Question") {
      this.createQuestion(data);
    } else {
      this.createMessage(data);
    }

    onSubmit(this.props);
  }

  render() {
    const { handleSubmit, whoami, dealId, option, isFollowUpQuestion } =
      this.props;
    const { isPublic, showDropdown, selectedLendersAndGroups } = this.state;

    return (
      <>
        {this.state.compressing && <Loader files />}
        {this.state.step === 0 && (
          <Form
            className="no-padding no-border"
            onSubmit={handleSubmit(this.submitFn)}
          >
            {option === "Message" && (
              <Row>
                <Col xs="12">
                  {whoami.role === "Admin" && (
                    <h4 className="bold">Search all Companies</h4>
                  )}
                  <Spacer size={20} />
                </Col>
                <Col xs="12">
                  <Field
                    className="form-control"
                    component={reduxFormSelect}
                    validate={[required]}
                    name="sendTo"
                    type="select"
                    options={this.state.availableToSelect}
                    formatGroupLabel={formatGroupLabel}
                    placeholder={"Select recipient..."}
                    label={"Select recipient to message"}
                  />
                  {/* <p>There are no lenders or groups assigned to this deal.</p> */}
                  <Spacer size={20} />
                </Col>
              </Row>
            )}

            <Row>
              {whoami.role === "Admin" && isFollowUpQuestion && (
                <>
                  <Col xs="12" className="d-flex align-items-center">
                    <p>Current: &nbsp;</p>
                    <p style={{ minWidth: 60 }}>
                      {isPublic ? "Public" : "Private"} &nbsp;
                    </p>
                    <Form.Check
                      type="switch"
                      className="switch-lg"
                      style={{ marginTop: -4 }}
                      label=""
                      checked={isPublic}
                      onChange={() => {
                        this.setState({ isPublic: !isPublic });
                      }}
                    />
                    <Spacer size={50} />
                  </Col>

                  <Col
                    xs="12"
                    className="d-flex align-items-center"
                    style={{ minHeight: 28 }}
                  >
                    <p>Companies and Groups with Access: &nbsp;</p>
                    {isPublic ? (
                      <p>All</p>
                    ) : (
                      <div className="multiDropdown-wrapper">
                        <Button
                          size="sm"
                          variant="link"
                          name="lenders"
                          className="d-flex align-items-center mr-2 px-0 text-body"
                          onClick={() => {
                            this.setState({ showDropdown: !showDropdown });
                          }}
                        >
                          <FontAwesomeIcon
                            className="ml-2"
                            icon={faPlusCircle}
                          />
                        </Button>
                        {showDropdown && (
                          <div className="multiDropdown-container left">
                            <MultiDropdown
                              autoFocus
                              menuIsOpen
                              label="Lenders"
                              options={[
                                {
                                  label: "Lenders",
                                  options: this.props.lenderOptions,
                                },
                                {
                                  label: "Groups",
                                  options: this.props.groupOptions,
                                },
                              ]}
                              value={selectedLendersAndGroups}
                              onChange={(selected) => {
                                this.handleSelectLendersAndGroups(selected);
                              }}
                              onBlur={(evt) => {
                                if (evt.relatedTarget?.name !== "lenders") {
                                  this.setState({ showDropdown: false });
                                }
                              }}
                            />
                          </div>
                        )}
                      </div>
                    )}
                    <Spacer size={50} />
                  </Col>

                  {!isPublic && (
                    <Col xs="12">
                      <SelectedBadges
                        show={isPublic}
                        selected={selectedLendersAndGroups}
                        onChange={this.handleSelectLendersAndGroups}
                      />
                      <Spacer size={25} />
                    </Col>
                  )}
                </>
              )}
              <Col xs="12">
                <Field
                  className="form-control"
                  component={reduxFormTextarea}
                  validate={[required, noSpaces]}
                  name="description"
                  type="textarea"
                  rows="10"
                  placeholder="Description..."
                />
                <Spacer size={50} />
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col className="inbox-actions" xs="3">
                <input
                  ref={this.input}
                  className="d-none"
                  type="file"
                  accept={allowedImageTypes.join(",")}
                  onChange={(e) => {
                    this.uploadPhotos(e);
                  }}
                  multiple
                />
                <Button
                  variant="inbox-action"
                  onClick={() => {
                    this.input.current.click();
                  }}
                >
                  <FontAwesomeIcon className="font-20" icon={faImage} />
                </Button>
                <Button
                  variant="inbox-action"
                  onClick={() => {
                    this.setState({ step: 1 });
                  }}
                >
                  <FontAwesomeIcon className="font-20" icon={faPaperclip} />
                </Button>
              </Col>
              <Col className="text-right" xs="9">
                <Button type="submit">Submit</Button>
                {/* {(whoami?.role === "Lender" || whoami?.role === "Borrower") && (
                  <Button type="submit">Submit</Button>
                )}
                {this.state.availableToSelect &&
                  (this.state.availableToSelect[0]?.options.length > 0 ||
                    this.state.availableToSelect[1]?.options.length > 0) &&
                  whoami &&
                  whoami.role === "Admin" && (
                    <Button type="submit">Submit</Button>
                  )} */}
              </Col>
            </Row>
            <Spacer size={10} />
            <div className="message-uploads">
              {this.state.uploads.map((file, idx) => {
                return (
                  <Badge
                    className="mr-2"
                    variant="secondary"
                    key={idx}
                    tabIndex="0"
                    pill
                    onClick={() => {
                      this.setState({
                        uploads: this.state.uploads.filter((arrFile) => {
                          return arrFile._id !== file._id;
                        }),
                      });
                    }}
                    onKeyDown={(e) =>
                      e.keyCode === 13 ? document.activeElement.click() : null
                    }
                  >
                    <FontAwesomeIcon icon={faTimesCircle} /> {file.name}
                  </Badge>
                );
              })}
              {this.state.uploads.length > 0 && <Spacer size={10} />}
            </div>
          </Form>
        )}
        {this.state.step === 1 && (
          <UploadFiles
            dealId={dealId}
            onCancel={() => {
              this.setState({ step: 0 });
            }}
            onSubmit={(e) => {
              e.map((file) => {
                this.setState((prevState) => ({
                  uploads: [...prevState.uploads, file],
                }));

                return true;
              });

              this.setState({ step: 0 });
            }}
          />
        )}
      </>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    action: state.action,
    whoami: state.auth.whoami,
    deal: state.deal.deal,
    groups: state.comm.groups,
    myCompany: state.company.myCompany,
    company: state.company.company,
    lastCommunicationCreated: state.comm.lastCommunicationCreated,
    initialValues: {
      sendTo: props.sendTo
        ? {
            label: props.sendTo.label,
            value: props.sendTo.value,
          }
        : null,
    },
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "CreateMessage",
    enableReinitialize: true,
  })(CreateMessage)
);
