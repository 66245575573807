import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import pick from "lodash/pick";
import { getKeyFromLocal, storeJsonInLocal } from "utils/lsUtils";
import { Dropdown, Row, Col, Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTh,
  faChevronLeft,
  faStream,
} from "@fortawesome/free-solid-svg-icons";
import {
  getDealById,
  getDealFilesById,
  createFolder,
  saveDealFolderFiles,
  deleteFile,
  deleteFolder,
  updatePrimaryPicture,
  addPermission,
  removePermission,
  renameFolderFile,
  manageFilePermission,
} from "redux/actions";
import { toast } from "react-toastify";
import Loader from "components/ui/Loader";
import Category from "./Category";
import Folder from "./Folder";
import Picture from "./Picture";
import Spacer from "components/layout/Spacer";
import PopupPrompt from "components/ui/PopupPrompt";
import AddFileCategory from "containers/deals/FileManager/AddFileCategory";
import SecuritySharing from "containers/deals/FileManager/SecuritySharing";
import axios from "axios";
import constants from "config/constants";
import imageCompression from "browser-image-compression";
import EditCategory from "./EditCategory";
import EditFolder from "./EditFolder";
import EditFile from "./EditFile";
import { ALLOWED_FILE_EXTS } from "./constants";
import { Link } from "react-router-dom";

const EDIT_PROMPTS = {
  category: "category",
  folder: "folder",
  file: "file",
};

const FileExplorer = (props) => {
  const dealId = props.dealId ? props.dealId : props.match.params.id;

  const [showPrompt, setShowPrompt] = useState(false);
  const [showSecurity, setShowSecurity] = useState(false);
  const [editPrompt, setEditPrompt] = useState(null);
  const [downloading, setDownloading] = useState(false);
  const [showGrid, SetShowGrid] = useState(false);
  const [securityNode, setSecurityNode] = useState({});
  const [renameNode, setRenameNode] = useState({});
  const [folderData, setFolderData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [openedCategories, setOpenedCategories] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [compressing, setCompressing] = useState(false);
  const [hideEmptyFolders, setHideEmptyFolders] = useState(false);

  const {
    whoami,
    deal,
    isFetching,
    fileManagerData,
    categoryCreated,
    filesSaved,
    fileRemoved,
    folderRemoved,
    renamed,
    primaryPhotoUpdated,
    permissions,
    usersPermissions,
    permissionAdded,
    permissionRemoved,
    fromComms,
    onOptionChange,
    onSubmit,
    location,
    lendersPermissionLastUpdate,
  } = props;

  useEffect(() => {
    getDealById(dealId);
    // if (location && location.state && location.state.fromDeal) {
    //   SetShowGrid(true);
    // }
  }, []);

  useEffect(() => {
    if (whoami.role === "Borrower" && deal.dealStatus !== "Active") {
      SetShowGrid(true);
    } else {
      SetShowGrid(false);
    }
    if (whoami.role === "Lender") {
      setHideEmptyFolders(true);
    }
  }, [whoami, deal._id]);

  useEffect(() => {
    const { deal } = props;

    let jsonObj = {};
    jsonObj[constants.TERMST_LOCAL_STORAGE.CURRENT_DEAL] = deal;
    storeJsonInLocal(jsonObj);
  }, [props.deal]);

  useEffect(() => {
    getDealFilesById(dealId);
  }, [
    dealId,
    categoryCreated,
    filesSaved,
    fileRemoved,
    folderRemoved,
    permissionAdded,
    permissionRemoved,
    renamed,
    primaryPhotoUpdated,
    lendersPermissionLastUpdate,
  ]);

  useEffect(() => {
    if (openedCategories.length > 0) {
      const toggledNodes = [];
      fileManagerData.forEach((node) => {
        if (openedCategories.some((n) => n._id === node._id))
          node.isOpen = true;
        toggledNodes.push(node);
      });
      setFolderData(toggledNodes);
    } else setFolderData(fileManagerData);
  }, [fileManagerData, openedCategories]);

  useEffect(() => {
    const openCategories = [];
    fileManagerData.forEach((node) => {
      if (node.isFolder) {
        openCategories.push(node);
      }
    });
    setOpenedCategories(openCategories);
  }, [fileManagerData]);

  const handleShowPrompt = useCallback((value) => {
    setShowPrompt(value);
  }, []);

  const handleShowSecurity = useCallback((value) => {
    setShowSecurity(value);
  }, []);

  const handleShowRename = useCallback((value) => {
    setEditPrompt(null);
  }, []);

  const getAncestors = useCallback(
    (id) => {
      return folderData.filter((node) => node._id === id)[0].ancestors;
    },
    [folderData]
  );

  const getNode = useCallback(
    (id) => {
      return folderData.filter((node) => node._id === id)[0];
    },
    [folderData]
  );

  const getRootNodes = useCallback(() => {
    return folderData.filter((node) => node.parent === dealId);
  }, [dealId, folderData]);

  const getChildNodes = useCallback(
    (node) => {
      return folderData.filter((n) => n.parent === node._id);
    },
    [folderData]
  );
  const isTreeEmpty = useCallback(
    (node) => {
      if (!node.isFolder) {
        return false;
      }
      const folders = fileManagerData.filter((n) => n.parent === node._id);
      if (folders.length == 0) {
        return true;
      }
      for (let folder of folders) {
        if (!isTreeEmpty(folder)) {
          return false;
        }
      }
      return true;
    },
    [folderData]
  );
  const canHideNode = useCallback(
    (node) => {
      if (!node.isFolder) {
        return false;
      }
      const isEmpty = isTreeEmpty(node);
      if (isEmpty) {
        if (hideEmptyFolders || node.isUncategorized) {
          return true;
        }
      }
      return false;
    },
    [folderData, hideEmptyFolders]
  );
  const groupFolderFile = useCallback((childNodes) => {
    const folders = [];
    const files = [];
    childNodes.forEach((node) => {
      if (node.isFolder) {
        folders.push(node);
      } else {
        files.push(node);
      }
    });
    return [...folders, ...files];
  }, []);

  const getCategoryFolders = useCallback(
    (categoryId) => {
      return folderData.filter(
        (item) => item.parent === categoryId && item.isFolder
      );
    },
    [folderData]
  );

  const checkContentType = useCallback((type, childNodes) => {
    if (type === "File") {
      const temp = childNodes.filter((node) => node.isFile);
      if (childNodes.length !== 0 && temp.length === childNodes.length) {
        return true;
      } else return false;
    } else if (type === "Picture") {
      const temp = childNodes.filter((node) => node.isPicture);
      if (childNodes.length !== 0 && temp.length === childNodes.length) {
        return true;
      } else return false;
    }
  }, []);

  const showSecuritySharing = useCallback(
    (id) => {
      setSecurityNode(getNode(id));
      // getPermission(id);
      // getPermissionUsers(dealId, id);
      setShowSecurity(true);
    },
    [dealId, getNode]
  );

  const showRenaming = useCallback(
    (id) => {
      let editPromptType;
      let node = getNode(id);

      if (node.isFolder) {
        if (node.ancestors?.length <= 1) {
          editPromptType = EDIT_PROMPTS.category;
        } else {
          editPromptType = EDIT_PROMPTS.folder;
          const found = categories.find((item) => item._id === node.parent);
          const category = { label: found?.name, value: found?._id };
          node = { ...node, category };
        }
      } else {
        editPromptType = EDIT_PROMPTS.file;
      }

      if (!node.description) {
        node = { ...node, description: node.name };
      }
      if (!node.displayName) {
        node = { ...node, displayName: node.name };
      }

      setRenameNode(node);
      setEditPrompt(editPromptType);
    },
    [getNode]
  );

  useEffect(() => {
    setCategories(folderData.filter((node) => node.parent === dealId));
  }, [dealId, folderData]);

  const addFolder = useCallback(
    (id, data) => {
      data.isFolder = true;
      data.parent = id;
      if (id === dealId) data.ancestors = [id];
      else data.ancestors = [...getAncestors(id), id];
      createFolder(data);
    },
    [dealId, getAncestors]
  );

  const renamedNode = useCallback((id, data) => {
    renameFolderFile(id, data);
  }, []);

  const addFilePermission = useCallback(
    (fileId, data) => {
      manageFilePermission(dealId, fileId, "add", data);
    },
    [dealId]
  );

  const removeFilePermission = useCallback(
    (fileId, data) => {
      manageFilePermission(dealId, fileId, "remove", data);
    },
    [dealId]
  );

  const isPublicFilePermission = useCallback(
    (fileId, data) => {
      manageFilePermission(dealId, fileId, "isPublic", data);
    },
    [dealId]
  );

  const removeFolder = useCallback((id) => {
    deleteFolder(id);
  }, []);

  const removeFile = useCallback((id) => {
    deleteFile(id);
  }, []);

  const updatePrimaryPhoto = useCallback((folderId, id) => {
    updatePrimaryPicture(folderId, { id });
  }, []);

  const createPermission = useCallback((id, data) => {
    setShowSecurity(false);
    addPermission(id, { userId: data.username.value });
  }, []);

  const destroyPermission = useCallback((folderfileId, userId) => {
    setShowSecurity(false);
    removePermission(folderfileId, userId);
  }, []);

  const onCategorySelect = useCallback(
    (id, view) => {
      const openCategories = [];
      const toggledNodes = [];
      folderData.forEach((n) => {
        if (n.isFolder && n._id === id && view) {
          n.isOpen = true;
        } else if (n.isFolder && n._id === id) {
          n.isOpen = !n.isOpen;
        }
        if (n.isOpen) openCategories.push(n);
        toggledNodes.push(n);
      });
      setOpenedCategories(openCategories);
      setFolderData(toggledNodes);
    },
    [folderData]
  );
  const isValidFiles = (files, showToast = true) => {
    for (let file of files) {
      const ext = file.name.split(".").reverse()[0];
      if (!ALLOWED_FILE_EXTS.includes(`.${ext.toLowerCase()}`)) {
        if (showToast) {
          toast.error(
            `Contains Unsupported file(s). Allows only ${ALLOWED_FILE_EXTS.join(
              ", "
            )}`,
            {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        }
        return false;
      }
    }
    return true;
  };
  const handleUploadFile = useCallback(
    async (event, folderId) => {
      const ancestors = getAncestors(folderId).join() + "," + folderId;
      const data = new FormData();
      const options = {
        maxSizeMB: 0.75,
      };

      if (!isValidFiles(event.target.files)) {
        return;
      }

      for await (let file of event.target.files) {
        try {
          if (
            !fileManagerData.some(
              (fmd) =>
                fmd.name === file.name && fmd.ancestors.includes(folderId)
            )
          ) {
            if (file["type"].split("/")[0] === "image") {
              setCompressing(true);
              const compressedFile = await imageCompression(file, options);
              setCompressing(false);

              data.append("files", compressedFile, file.name);
            } else {
              data.append("files", file);
            }
          } else {
            toast.error(
              "A file with the same name already exists in the folder!",
              {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );

            const inputs = document.getElementsByTagName("input");

            for (let item of inputs) {
              item.value = "";
            }

            return;
          }
        } catch (error) {
          return error;
        }
      }
      data.append("parent", folderId);
      data.append("ancestors", ancestors);
      saveDealFolderFiles(data);
    },
    [getAncestors]
  );

  const fetchFile = useCallback((id, name) => {
    setDownloading(true);
    const origins = constants.API_ORIGINS;
    let endPoint = constants.API_PROTOCOL + origins[window.location.host];
    const jwtToken = getKeyFromLocal(constants.TERMST_LOCAL_STORAGE.JWT_KEY);
    axios
      .get(`${endPoint}/downloadfile/${id}?dealId=${dealId}`, {
        headers: { accessToken: jwtToken },
        responseType: "arraybuffer",
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", name);
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {})
      .finally(() => {
        setDownloading(false);
      });
  }, []);
  const determineDownloadFolderName = (folderName) => {
    const date = new Date();
    const formattedDate = `${
      date.getMonth() + 1
    }-${date.getDate()}-${date.getFullYear()}`;
    if (folderName) {
      return `Download-${deal.propertyName}-${folderName}-${formattedDate}`;
    }
    return `Download-${deal.propertyName}-${formattedDate}`;
  };

  const fetchFolder = useCallback(
    (id, folderName) => {
      setDownloading(true);
      const origins = constants.API_ORIGINS;
      let endPoint = constants.API_PROTOCOL + origins[window.location.host];
      const jwtToken = getKeyFromLocal(constants.TERMST_LOCAL_STORAGE.JWT_KEY);
      const expectedFolderName = determineDownloadFolderName(folderName);
      axios
        .get(
          `${endPoint}/downloadfolder/${id}?dealId=${dealId}&expectedFolderName=${expectedFolderName}`,
          {
            headers: { accessToken: jwtToken },
            responseType: "arraybuffer",
          }
        )
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", expectedFolderName + ".zip");
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {})
        .finally(() => {
          setDownloading(false);
        });
    },
    [deal]
  );

  const addSelectedFile = (file) => {
    setSelectedFiles([...selectedFiles, file]);
  };

  const removeSelectedFile = (id) => {
    setSelectedFiles(selectedFiles.filter((file) => file._id !== id));
  };

  const getSelectedFiles = useCallback(() => {
    return selectedFiles;
  }, [selectedFiles]);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Button
      ref={ref}
      variant="inbox-action"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </Button>
  ));

  const CustomMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) => child.props.children
            )}
          </ul>
        </div>
      );
    }
  );

  const canManage = whoami.role === "Borrower" || whoami.role === "Admin";

  const renderFileExplorer = (nodes) => {
    return (
      <>
        {nodes.map((node) => {
          if (canHideNode(node)) {
            return null;
          }
          if (node.isOpen) {
            const childNodes = getChildNodes(node);
            if (checkContentType("Picture", childNodes)) {
              return (
                <Picture
                  key={node._id}
                  id={node._id}
                  userId={whoami._id}
                  parentPermission={node.permission}
                  category={node.name}
                  pictures={childNodes}
                  addFolder={addFolder}
                  fetchFile={fetchFile}
                  removeFile={removeFile}
                  updatePrimaryPhoto={updatePrimaryPhoto}
                  showSecuritySharing={showSecuritySharing}
                  showRenaming={showRenaming}
                  showGrid={showGrid}
                  fromComms={fromComms}
                  addSelectedFile={addSelectedFile}
                  removeSelectedFile={removeSelectedFile}
                  getSelectedFiles={getSelectedFiles}
                  canManage={canManage}
                />
              );
            } else {
              return (
                <Folder
                  key={node._id}
                  id={node._id}
                  userId={whoami._id}
                  parentPermission={node.permission}
                  category={node.name}
                  onCategorySelect={onCategorySelect}
                  nodes={groupFolderFile(childNodes)}
                  node={node}
                  addFolder={addFolder}
                  handleUploadFile={handleUploadFile}
                  isValidFiles={isValidFiles}
                  getChildNodes={getChildNodes}
                  fetchFile={fetchFile}
                  fetchFolder={fetchFolder}
                  removeFile={removeFile}
                  removeFolder={removeFolder}
                  showSecuritySharing={showSecuritySharing}
                  showRenaming={showRenaming}
                  showGrid={showGrid}
                  fromComms={fromComms}
                  addSelectedFile={addSelectedFile}
                  removeSelectedFile={removeSelectedFile}
                  getSelectedFiles={getSelectedFiles}
                  canManage={canManage}
                  hideEmptyFolders={hideEmptyFolders}
                  canHideNode={canHideNode}
                />
              );
            }
          } else {
            return true;
          }
        })}
      </>
    );
  };
  return (
    <>
      {(isFetching || downloading || compressing) && <Loader files={true} />}
      <Form className="no-border no-padding">
        <Row className="align-items-center">
          <Col className="text-left" xs="2">
            {location && location.state && location.state.fromDeal && (
              <Button
                className="pl-3 pr-3"
                variant="primary"
                onClick={() => {
                  window.history.back();
                }}
              >
                Done
              </Button>
            )}
            {fromComms && (
              <Button
                className="fake-link"
                variant="fake-link"
                onClick={() => {
                  onOptionChange();
                }}
              >
                <FontAwesomeIcon className="font-14" icon={faChevronLeft} />{" "}
                Back
              </Button>
            )}
            {location && !location.state && !fromComms && (
              <Button
                className="fake-link"
                variant="fake-link"
                onClick={() => {
                  window.history.back();
                }}
              >
                <FontAwesomeIcon className="font-14" icon={faChevronLeft} />{" "}
                Back
              </Button>
            )}
          </Col>
          <Col className="text-right" xs="10">
            <div style={{ width: 190, paddingTop: 10, position: "absolute" }}>
              <Form.Check
                type="switch"
                label="Hide empty folders"
                checked={hideEmptyFolders}
                onChange={() => {
                  setHideEmptyFolders(!hideEmptyFolders);
                }}
              />
            </div>
            {fromComms ? (
              <Button
                variant="primary"
                onClick={() => {
                  if (selectedFiles.length > 0) {
                    onSubmit(selectedFiles);
                  }
                }}
              >
                Insert {selectedFiles.length} Files
              </Button>
            ) : (
              <>
                <Button
                  className="mr-3"
                  variant="primary"
                  onClick={() => fetchFolder(dealId)}
                >
                  Download All
                </Button>
                <Dropdown className="d-inline-block">
                  <Dropdown.Toggle as={CustomToggle} id="toggle-dropdown">
                    <h4 className="view-as">
                      <FontAwesomeIcon
                        className="color-medium-gray"
                        icon={showGrid ? faTh : faStream}
                      />
                    </h4>
                  </Dropdown.Toggle>

                  <Dropdown.Menu as={CustomMenu}>
                    <p className="text-center">View As:</p>
                    <Dropdown.Divider> </Dropdown.Divider>
                    <Dropdown.Item onClick={() => SetShowGrid(true)}>
                      <p>
                        <FontAwesomeIcon icon={faTh} />{" "}
                        <span className="d-inline-block ml-2">Grid</span>
                      </p>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => SetShowGrid(false)}>
                      <p>
                        <FontAwesomeIcon icon={faStream} />{" "}
                        <span className="d-inline-block ml-2">List</span>
                      </p>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </>
            )}
          </Col>
        </Row>
      </Form>
      <Spacer size={30} />
      <Row>
        <Col>
          <Link
            className="bold folder-name"
            onClick={() => {
              window.open("/deal/pdf/" + dealId + "?download=true");
            }}
          >
            Loan Request Package
          </Link>
        </Col>
      </Row>
      <Row>
        <Col className="border-right" xs="12" sm="4" md="3">
          {categories && (
            <Category
              getChildNodes={getChildNodes}
              canHideNode={canHideNode}
              onCategorySelect={onCategorySelect}
              dealId={dealId}
              nodes={categories}
              handleUploadFile={handleUploadFile}
              groupFolderFile={groupFolderFile}
              fetchFolder={fetchFolder}
              fetchFile={fetchFile}
              addFolder={addFolder}
              removeFolder={removeFolder}
              showSecuritySharing={showSecuritySharing}
              showRenaming={showRenaming}
              fromComms={fromComms}
              canManage={canManage}
              hideEmptyFolders={hideEmptyFolders}
            />
          )}
          {!fromComms && canManage && (
            <>
              <Spacer size={10} />
              <Button
                className="fake-link"
                variant="fake-link"
                onClick={() => {
                  handleShowPrompt(true);
                }}
              >
                + Add Category
              </Button>
              <Spacer size={20} />
            </>
          )}
        </Col>
        <Col xx="12" sm="8" md="9">
          <>
            {deal.isPortifolio ? (
              <>
                {getRootNodes().map((node) => {
                  if (canHideNode(node)) {
                    return null;
                  }
                  const parentNodes = getChildNodes(node);
                  return (
                    <>
                      <h4 className="mb-2">{node.name}</h4>
                      {renderFileExplorer(parentNodes)}
                    </>
                  );
                })}
              </>
            ) : (
              <>{renderFileExplorer(getRootNodes())}</>
            )}
            {!getRootNodes().some((node) => node.isOpen) && (
              <p className="text-center pt-3">
                Choose a category on the left to view folders and files.
              </p>
            )}
          </>
        </Col>
      </Row>
      <PopupPrompt
        promptTitle={"Create Category"}
        promptBody={
          <AddFileCategory
            id={dealId}
            placeholder="Enter category name..."
            label="Category"
            deal={deal}
            onSubmit={(id, e) => {
              setShowPrompt(false);
              addFolder(id, e);
            }}
          />
        }
        show={showPrompt}
        onHide={() => handleShowPrompt(false)}
        onCancel={() => handleShowPrompt(false)}
      />
      <PopupPrompt
        promptTitle={"Edit Category"}
        promptBody={
          <EditCategory
            key={renameNode._id}
            id={renameNode._id}
            initialValues={pick(renameNode, ["name", "description"])}
            deal={deal}
            onSubmit={(id, e) => {
              setEditPrompt(null);
              renamedNode(id, e);
            }}
          />
        }
        show={editPrompt === EDIT_PROMPTS.category}
        onHide={() => handleShowRename(false)}
        onCancel={() => handleShowRename(false)}
      />
      <PopupPrompt
        promptTitle={"Edit Folder"}
        promptBody={
          <EditFolder
            key={renameNode._id}
            id={renameNode._id}
            initialValues={pick(renameNode, ["name", "description"])}
            node={renameNode}
            categories={categories}
            deal={deal}
            onSubmit={(id, e) => {
              setEditPrompt(null);
              renamedNode(id, e);
            }}
          />
        }
        show={editPrompt === EDIT_PROMPTS.folder}
        onHide={() => handleShowRename(false)}
        onCancel={() => handleShowRename(false)}
      />
      <PopupPrompt
        promptTitle={"Edit File"}
        promptBody={
          <EditFile
            key={renameNode._id}
            id={renameNode._id}
            initialValues={pick(renameNode, [
              "name",
              "displayName",
              "description",
            ])}
            deal={deal}
            node={renameNode}
            categories={categories}
            getCategoryFolders={getCategoryFolders}
            onSubmit={(id, e) => {
              setEditPrompt(null);
              renamedNode(id, e);
            }}
          />
        }
        show={editPrompt === EDIT_PROMPTS.file}
        onHide={() => handleShowRename(false)}
        onCancel={() => handleShowRename(false)}
      />

      <PopupPrompt
        promptTitle={"Security And Sharing"}
        promptBody={
          <SecuritySharing
            key={securityNode._id}
            deal={deal}
            categories={categories}
            node={securityNode}
            permissions={permissions}
            usersPermissions={usersPermissions}
            createPermission={createPermission}
            destroyPermission={destroyPermission}
            addFilePermission={addFilePermission}
            removeFilePermission={removeFilePermission}
            isPublicFilePermission={isPublicFilePermission}
            onSubmit={() => {
              setShowSecurity(false);
            }}
          />
        }
        show={showSecurity}
        onHide={() => handleShowSecurity(false)}
        onCancel={() => handleShowSecurity(false)}
      />
    </>
  );
};

function mapStateToProps(state) {
  return {
    whoami: state.auth.whoami,
    deal: state.deal.deal,
    categoryCreated: state.action.CREATE_DEAL_FOLDER.isFetched,
    filesSaved: state.action.SAVE_DEAL_FOLDER_FILES.isFetched,
    fileRemoved: state.action.DELETE_FILE.isFetched,
    folderRemoved: state.action.DELETE_FOLDER.isFetched,
    permissionAdded: state.action.ADD_PERMISSION.isFetched,
    permissionRemoved: state.action.REMOVE_PERMISSION.isFetched,
    primaryPhotoUpdated: state.action.UPDATE_PRIMARY_PICTURE.isFetching,
    renamed: state.action.RENAME_FOLDER_FILE.isFetched,
    fileManagerData: state.fileManager.files,
    permissions: state.fileManager.permissions,
    usersPermissions: state.fileManager.usersPermissions,
    lendersPermissionLastUpdate: state.fileManager.lendersPermissionLastUpdate,
    isFetching:
      state.action.GET_DEAL_FILES.isFetching ||
      state.action.CREATE_DEAL_FOLDER.isFetching ||
      state.action.DELETE_FILE.isFetching ||
      state.action.DELETE_FOLDER.isFetching ||
      state.action.GET_PERMISSION.isFetching ||
      state.action.ADD_PERMISSION.isFetching ||
      state.action.REMOVE_PERMISSION.isFetching ||
      state.action.SAVE_DEAL_FOLDER_FILES.isFetching ||
      state.action.RENAME_FOLDER_FILE.isFetching ||
      state.action.UPDATE_PRIMARY_PICTURE.isFetching ||
      state.action.SEARCH_DEAL_FILES.isFetching,
  };
}

export default connect(mapStateToProps)(FileExplorer);
