import socketIOClient from "socket.io-client";
import { v4 as uuid } from "uuid/dist";
import constants from "config/constants";

const origins = constants.API_ORIGINS;
const endPoint = constants.API_PROTOCOL + origins[window.location.host];

const generateSubscriptionId = (topic) => `subs_${topic}_${uuid()}`;

class SocketClient {
  constructor() {
    this.connection = null;
    this.connect();
    this.subscriptions = {};
    this.emits = [];
  }
  connect() {
    this.connection = socketIOClient(endPoint);

    this.connection.on("connect", () => {
      console.log("Socket connection established...");
      if (this.emits.length) {
        this.emits.map(({ topic, data }) => this._emit(topic, data));
        this.emits = [];
      }
    });

    this.connection.on("disconnect", () => {
      console.log("Socket disconnected...");
    });
  }

  subscribe(topic, callback) {
    const subscriptionId = generateSubscriptionId(topic);
    this.connection.on(topic, callback);
    this.subscriptions[subscriptionId] = { topic, callback };
    return subscriptionId;
  }

  unsubscribe = (subscriptionIds) => {
    const isArray = Array.isArray(subscriptionIds);
    if (!isArray) {
      subscriptionIds = [subscriptionIds];
    }
    for (let i = 0; i < subscriptionIds.length; i++) {
      if (!subscriptionIds[i]) {
        continue;
      }
      const { topic, callback } = this.subscriptions[subscriptionIds[i]] || {};
      if (topic && callback) {
        this.connection.off(topic, callback);
        delete this.subscriptions[subscriptionIds[i]];
      }
    }
  };
  send = (topic, data) => {
    if (!this.connection) {
      this.emits.push({ topic, data });
    } else {
      this._emit(topic, data);
    }
  };

  _emit = (topic, data) => {
    this.connection.emit(topic, data);
  };
}

const Socket = new SocketClient();
export default Socket;
