import React from "react";
import { Button, Col, Row } from "antd";
import Spacer from "components/layout/Spacer";
import { useSelector } from "react-redux";
import useAddEditSectionModalStore from "../hooks/useAddEditSectionModalStore";

const AddSection = ({ deal }) => {
  const { _id: dealId } = deal;

  const { openCreateModal } = useAddEditSectionModalStore();
  const whoami = useSelector((state) => state.auth.whoami);

  if (whoami.role !== "Admin") return null;

  return (
    <section>
      <h4>Add Section</h4>
      <Spacer size={5} />
      <div className="border-bottom"></div>
      <Spacer size={20} />
      <Row>
        <Col md={24}>
          <Button type="primary" onClick={() => openCreateModal({ dealId })}>
            Add New
          </Button>
        </Col>
      </Row>
    </section>
  );
};

export default AddSection;
