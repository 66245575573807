import React, { useEffect, useRef } from "react";
import InboxQuestionContent from "./InboxQuestionContent";
import InboxReplies from "../inboxReplies/InboxReplies";
import { useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import Socket from "utils/Socket";
import { getConversationById } from "redux/actions";
import QuestionFollowUpSource from "./QuestionFollowUpSource";

const InboxQuestionDetails = ({ status }) => {
  const match = useRouteMatch();
  const dealId = match.params.id;
  const conversationSubscriptionId = useRef(null);
  const questionDetails = useSelector(
    (state) => state.conversation.questionDetails
  );

  useEffect(() => {
    if (questionDetails) {
      conversationSubscriptionId.current = Socket.subscribe(
        `/deal/${dealId}/conversations/${questionDetails._id}`,
        (socketData) => {
          if (
            socketData.action === "ANSWER_QUESTION" ||
            socketData.action === "UPDATE"
          ) {
            getConversationById(questionDetails._id);
          }
        }
      );
    }
    return () => {
      Socket.unsubscribe(conversationSubscriptionId.current);
    };
  }, [questionDetails]);

  const messages = questionDetails?.messages || [];
  const [, ...replies] = messages;

  return (
    <section className="flex flex-column" style={{ width: "70%" }}>
      {questionDetails && (
        <>
          {questionDetails._followUpToConvoId && (
            <QuestionFollowUpSource
              status={status}
              questionDetails={questionDetails}
            />
          )}
          <InboxQuestionContent status={status} />
          <InboxReplies messages={replies} />
        </>
      )}
    </section>
  );
};

export default InboxQuestionDetails;
