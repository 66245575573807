import { actionTypes } from "redux/actions";
import { reducerCreator, multipleReducers } from "./meta";

export const user = multipleReducers({
    initializers: {
        initialState: {
            users: [],
            manageUsers: {
                rows: [],
                count: 0,
            },
            userProfile: {}
        }
    },
    list: [
        {
            reducerCreatorFn: reducerCreator,
            params: {
                type: actionTypes.GET_USERS,
                successFn: (action) => ({
                    users: action.data.body.rows
                })
            }
        },
        {
            reducerCreatorFn: reducerCreator,
            params: {
                type: actionTypes.GET_MANAGE_USERS,
                successFn: (action) => ({
                    manageUsers: action.data.body
                })
            }
        },
        {
            reducerCreatorFn: reducerCreator,
            params: {
                type: actionTypes.GET_USER_PROFILE,
                successFn: (action) => ({
                    userProfile: action.data.body
                })
            }
        }
    ]
});
