import { combineReducers } from "redux";
import { reducer as reduxFormReducer } from "redux-form";

import { actionTypes } from "redux/actions";
import { getRequestType, getSuccessType, getErrorType } from "./meta";

import { auth } from "./authReducer";
import { action } from "./actionReducer";
import { company } from "./companyReducer";
import { program } from "./programReducer";
import { team } from "./teamReducer";
import { admin } from "./adminReducer";
import { deal } from "./dealReducer";
import { comm } from "./commReducer";
import { fileManager } from "./fileManagerReducer";
import { generic } from "./genericReducer";
import { termsheet } from "./termsheetReducer";
import { user } from "./userReducer";
import { tag } from "./tagReducer";
import { notificationsData } from "./notificationReducer";
import { category } from "./categoryReducer";
import { section } from "./sectionReducer";
import { clause } from "./clauseReducer";
import { conversation } from "./conversationReducer";
import { label } from "./labelReducer";

export { getRequestType, getSuccessType, getErrorType };

const { SIGN_OUT } = actionTypes;

const reducer = combineReducers({
  auth,
  action,
  company,
  program,
  team,
  admin,
  deal,
  notificationsData,
  comm,
  fileManager,
  generic,
  termsheet,
  user,
  tag,
  category,
  section,
  clause,
  conversation,
  label,
  form: reduxFormReducer,
});

const rootReducer = (state, action) => {
  if (action.type === SIGN_OUT) {
    return {
      ...state,
      auth: {
        isAuthenticated: false,
      },
      form: state.form,
    };
  }

  return reducer(state, action);
};

export default rootReducer;
