import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Form,
  Button,
  Badge,
  Image,
  Card,
  Row,
  Col,
  Container,
} from "react-bootstrap";
import {
  getDeals,
  getTeamsByCompanyId,
  archiveDeal,
  updateDeal,
  getFilteredDeals,
} from "redux/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaperPlane,
  faFile,
  faTag,
  faFolder,
  faFilter,
} from "@fortawesome/free-solid-svg-icons";
import { storeJsonInLocal } from "utils/lsUtils";
import NumberFormat from "react-number-format";
import Spacer from "components/layout/Spacer";
import moment from "moment";
import constants from "config/constants";
import Socket from "utils/Socket";
import AssignLenderTeam from "containers/deals/update/AssignLenderTeam";
import ManageDealLenders from "containers/deals/update/ManageDealLenders";
import FilterDeals from "containers/deals/update/FilterDeals";
import PopupPrompt from "components/ui/PopupPrompt";
import PassDealForm from "containers/deals/PassDealForm";
import AssignBorrowerTeam from "./AssignBorrowerTeam";
import Paginate from "components/ui/Paginate";
import { isEmpty } from "lodash";
import { DashboardSkeleton } from "components/ui/Skeletons";
import NoResultsFound, {
  EmptyDashboardBorrower,
  EmptyDashboardLender,
} from "components/emptystate/NoResultsFound";
import { getDealCreationPath } from "utils/commonUtils";

export const DEFAULT_PAGINATION = {
  page: 1,
  listOffset: 10,
};

const populateFilters = (filters) => {
  let propertyTypes = [];

  filters.propertyType &&
    filters.propertyType.map((type) => {
      propertyTypes.push(type.value);
      return true;
    });

  const filtersObj = {
    propertyType:
      propertyTypes.length > 0 ? JSON.stringify(propertyTypes) : undefined,
    loanType: filters.loanType ? filters.loanType.value : undefined,
    loanAmountMin: filters.minimumLoanAmountRequested
      ? filters.minimumLoanAmountRequested
      : undefined,
    loanAmountMax: filters.maximumLoanAmountRequested
      ? filters.maximumLoanAmountRequested
      : undefined,
    dealStatus: filters.dealStatus ? filters.dealStatus.value : undefined,
    archived: filters.archived
      ? filters.archived.value === "Yes"
        ? true
        : false
      : false,
  };
  return filtersObj;
};
class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      option: "",
      selectedDeal: "",
      selectedDealItem: null,
      filters: {},
      gotMyCompany: false,
      showPrompt: false,
      assignedTeam: false,
      viewArchived: false,
      badgeArchive: false,
      gotDeals: false,
      pagination: DEFAULT_PAGINATION,
    };

    this.handleShowPrompt = this.handleShowPrompt.bind(this);
    this.toggleNav = this.toggleNav.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { myCompany, whoami } = this.props;

    if (
      Object.keys(myCompany).length > 0 &&
      !myCompany.message &&
      !this.state.gotMyCompany
    ) {
      getTeamsByCompanyId(myCompany._id);

      this.setState({ gotMyCompany: true });
    }

    if (whoami?.role && !this.state.gotDeals) {
      this.fetchDeals(false, whoami);
      this.setState({ gotDeals: true });
    }

    if (
      prevProps.assignBorrowerTeamToDeal !== this.props.assignBorrowerTeamToDeal
    ) {
      this.fetchDeals(false, whoami);
    }
  }

  componentDidMount() {
    const { whoami } = this.props;

    if (whoami?.role && !this.state.gotDeals) {
      this.fetchDeals(false, whoami);
      this.setState({ gotDeals: true });
    }

    this.subscriptionId = Socket.subscribe("/deals", () =>
      this.fetchDeals(this.state.viewArchived, whoami)
    );
  }

  onResetFilters = () => {
    const { whoami } = this.props;

    this.setState(
      { filters: {}, viewArchived: false, pagination: DEFAULT_PAGINATION },
      () => {
        this.fetchDeals(false, whoami);
      }
    );
  };
  fetchDeals = (archived, whoami) => {
    const { _id } = this.props.myCompany;
    if (whoami && whoami.role === "Lender") {
      getDeals({
        archived,
        isPublic: false,
        pagination: this.state.pagination,
        dealStatus: "Active",
        companyId: _id,
        populate: true,
      });
    } else if (whoami && whoami.role === "Admin") {
      getDeals({
        archived,
        pagination: this.state.pagination,
        isPublic: true,
        populate: true,
      });
    } else {
      getDeals({
        archived,
        isPublic: false,
        pagination: this.state.pagination,
        populate: true,
      });
    }
  };

  onDealsclick = () => {
    const { whoami } = this.props;
    this.setState({ pagination: DEFAULT_PAGINATION }, () =>
      this.fetchDeals(this.state.viewArchived, whoami)
    );
  };
  handleShowPrompt(showPrompt, val) {
    this.setState({ showPrompt, body: val });
  }

  toggleNav(e) {
    this.setState({ selectedNav: e });
  }

  componentWillUnmount() {
    Socket.unsubscribe(this.subscriptionId);
  }

  onSubmitFilters = (filters) => {
    if (Object.keys(filters).some((datum) => filters[datum] !== undefined)) {
      this.setState({ filters, option: "", pagination: DEFAULT_PAGINATION });
    }
    const filtersObj = populateFilters(filters);
    getFilteredDeals({ ...filtersObj, pagination: DEFAULT_PAGINATION });
    this.handleShowPrompt(false);
  };

  paginationCallback = () => {
    const { whoami } = this.props;
    const { pagination, filters, viewArchived } = this.state;
    if (isEmpty(filters)) {
      this.fetchDeals(viewArchived, whoami);
    } else {
      const filtersObj = populateFilters(filters);
      getFilteredDeals({ ...filtersObj, pagination });
    }
  };

  handlePagination = (page) => {
    this.setState(
      (prevState) => ({
        pagination: { ...prevState.pagination, page },
      }),
      this.paginationCallback
    );
  };

  onEditDeal = (deal) => {
    let jsonObj = {};
    jsonObj[constants.TERMST_LOCAL_STORAGE.DEAL_ID] = deal._id;
    jsonObj[constants.TERMST_LOCAL_STORAGE.PORTIFOLIO_DEAL] = deal.isPortifolio
      ? deal._id
      : false;
    storeJsonInLocal(jsonObj);
    window.location.pathname = getDealCreationPath();
  };

  render() {
    const { action, deals, teams, whoami, myCompany, totalDeals, withSearch } =
      this.props;
    const { pagination, filters } = this.state;
    const isLoading =
      action.GET_DEALS.isFetching || action.GET_FILTERED_DEALS.isFetching;
    const noDeals = !isLoading && deals && deals.length === 0;
    return (
      <div className="dashboard-page">
        {whoami &&
          whoami.role === "Admin" &&
          this.state.option === "Lenders" && (
            <PopupPrompt
              promptTitle={"Assign Lenders"}
              promptBody={
                <ManageDealLenders
                  auth={whoami}
                  dealId={this.state.selectedDeal}
                  closePrompt={() => {
                    this.setState({ option: "" });
                    this.handleShowPrompt(false);
                  }}
                />
              }
              show={this.state.showPrompt}
              onCancel={() => {
                this.setState({ option: "" });
                this.handleShowPrompt(false);
              }}
              onHide={() => {
                this.setState({ option: "" });
                this.handleShowPrompt(false);
              }}
            />
          )}
        {this.state.option === "Team" && (
          <PopupPrompt
            promptTitle={"Assign a team to this deal"}
            promptBody={
              <AssignLenderTeam
                dealId={this.state.selectedDeal}
                closePrompt={() => {
                  this.setState({ option: "" });
                  this.handleShowPrompt(false);
                }}
              />
            }
            show={this.state.showPrompt}
            onCancel={() => {
              this.setState({ option: "" });
              this.handleShowPrompt(false);
            }}
            onHide={() => {
              this.setState({ option: "" });
              this.handleShowPrompt(false);
            }}
          />
        )}
        {this.state.option === "BorrowerTeam" && (
          <PopupPrompt
            promptTitle={"Assign a borrower team to this deal"}
            promptBody={
              <AssignBorrowerTeam
                dealId={this.state.selectedDeal}
                deal={this.state.selectedDealItem}
                closePrompt={() => {
                  this.setState({ option: "", assignedTeam: true });
                  this.handleShowPrompt(false);
                }}
              />
            }
            show={this.state.showPrompt}
            onCancel={() => {
              this.setState({ option: "" });
              this.handleShowPrompt(false);
            }}
            onHide={() => {
              this.setState({ option: "" });
              this.handleShowPrompt(false);
            }}
          />
        )}
        {this.state.option === "Filter" && (
          <PopupPrompt
            promptTitle={"Filter Deals"}
            promptBody={
              <FilterDeals
                onSubmit={this.onSubmitFilters}
                onResetFilters={() =>
                  this.setState({ filters: {}, pagination: DEFAULT_PAGINATION })
                }
                role={whoami.role}
                filters={this.state.filters}
              />
            }
            show={this.state.showPrompt}
            onCancel={() => {
              this.setState({ option: "" });
              this.handleShowPrompt(false);
            }}
            onHide={() => {
              this.setState({ option: "" });
              this.handleShowPrompt(false);
            }}
          />
        )}
        <Row className="mb-3 pt-3" noGutters={true}>
          <Col className="d-flex border-bottom" xs="12" lg="10">
            {whoami && whoami.role !== "Admin" && (
              <Button
                style={{ borderBottom: "solid 2px #0A467F" }}
                className="bold inbox-action-item fake-link"
                variant="fake-link"
                onClick={this.onDealsclick}
              >
                My Deals
              </Button>
            )}
            {whoami && whoami.role === "Admin" && (
              <Button
                style={{ borderBottom: "solid 2px #0A467F" }}
                className="bold inbox-action-item fake-link"
                variant="fake-link"
                onClick={this.onDealsclick}
              >
                All Deals
              </Button>
            )}
            <div style={{ flex: 1 }} className="text-right">
              <Button
                className="pt-2 pb-2"
                onClick={() => {
                  this.handleShowPrompt(true);
                  this.setState({ option: "Filter" });
                }}
                variant="tertiary"
              >
                <FontAwesomeIcon className="font-14 mr-3" icon={faFilter} />
                Filter
              </Button>
            </div>
          </Col>
          <Col className="quick-filters text-right mt-2" xs="12" lg="10">
            <Badge
              className={
                this.state.badgeArchive ? "ml-2 selected" : "ml-2 outline"
              }
              variant="secondary"
              tabIndex="0"
              pill
              onClick={() => {
                if (this.props.action.GET_FILTERED_DEALS.isFetching) {
                  return;
                }
                this.setState(
                  (prevState) => ({
                    badgeArchive: !prevState.badgeArchive,
                    viewArchived: !prevState.viewArchived,
                    filters: {},
                    pagination: DEFAULT_PAGINATION,
                  }),
                  function () {
                    getFilteredDeals({
                      propertyType: undefined,
                      loanType: undefined,
                      loanAmountMin: undefined,
                      loanAmountMax: undefined,
                      dealStatus: undefined,
                      pagination: this.state.pagination,
                      archived: this.state.badgeArchive,
                    });
                  }
                );
              }}
              onKeyDown={(e) =>
                e.keyCode === 13 ? document.activeElement.click() : null
              }
            >
              Archived
            </Badge>
          </Col>
        </Row>
        {isLoading && <DashboardSkeleton />}
        <Row noGutters={true}>
          <Col xs="12">
            <Spacer size={20} />
          </Col>
          {noDeals && isEmpty(filters) && !withSearch ? (
            <Col xs="12" lg="12">
              {
                <>
                  {whoami.role === "Lender" && <EmptyDashboardLender />}{" "}
                  {(whoami.role === "Borrower" || whoami.role === "Admin") && (
                    <EmptyDashboardBorrower />
                  )}
                </>
              }
            </Col>
          ) : (
            <>
              {deals &&
                !isLoading &&
                deals !== undefined &&
                deals.map((deal, idx) => {
                  const {
                    _id,
                    photos,
                    propertyName,
                    propertyAddress,
                    propertyCity,
                    propertyState,
                    propertyZip,
                    dealStatus,
                    maximumLoanAmountRequested,
                    purpose,
                    loanType,
                    propertyType,
                    propertySize,
                    propertySizeType,
                    sponsorName,
                    managementCompanyName,
                    createdAt,
                    preferredClosingDate,
                    isPortifolio,
                    properties,
                  } = deal;

                  let propertyTypeDisplay = propertyType;
                  let propertySizeTypeDisplay = propertySizeType;
                  let propertySizeDisplay = propertySize;
                  let isMultiplePropertyType = false;

                  if (isPortifolio && properties.length > 0) {
                    const [first] = properties;
                    const arePropertyTypesSame = properties.every(
                      (p) => p.propertyType === first.propertyType
                    );
                    if (arePropertyTypesSame) {
                      propertyTypeDisplay = first.propertyType;
                      propertySizeTypeDisplay = first.propertySizeType;
                      propertySizeDisplay = properties.reduce(
                        (acc, p) => acc + Number(p.propertySize),
                        0
                      );
                      isMultiplePropertyType = false;
                    } else {
                      propertyTypeDisplay = "Multiple";
                      isMultiplePropertyType = true;
                      propertySizeTypeDisplay = "";
                      propertySizeDisplay = 0;
                    }
                  }

                  const now = moment.utc(new Date());
                  const date = moment.utc(preferredClosingDate);
                  let days;

                  if (date.diff(now, "days") < 0) {
                    days = "Closed";
                  } else if (date.diff(now, "days") === 0) {
                    days = "Today";
                  } else if (!preferredClosingDate) {
                    days = "Not set";
                  } else {
                    days = date.diff(now, "days") + 1 + " Day(s)";
                  }

                  let isOnTeam;
                  let isAssignedLender;
                  let hasTeamAssigned;
                  let isPassed = false;

                  teams &&
                    teams !== undefined &&
                    teams.map((team) => {
                      if (team._id === deal.teamId._id) {
                        isOnTeam = team.teamMembers.some(
                          (member) => member.userId._id === whoami._id
                        );
                      }
                      return true;
                    });

                  deal.lendersAssigned.map((lender) => {
                    if (lender.companyId === myCompany._id) {
                      isAssignedLender = true;

                      if (!lender.teamId) {
                        hasTeamAssigned = false;
                      } else {
                        hasTeamAssigned = true;
                      }

                      if (lender.isPassed) {
                        isPassed = true;
                      }

                      return true;
                    }

                    return true;
                  });
                  const isDealInprogress = deal.dealStatus === "In progress";
                  // Hide the deal if passed
                  // TODO: Need to handle this from api
                  if (isPassed) {
                    return null;
                  }

                  return (
                    <React.Fragment key={deal._id}>
                      <Col xs="12" lg="10" key={idx}>
                        <Card className="deal-card">
                          <Card.Body>
                            <Container className="card-content" fluid>
                              <Row>
                                <Col className="text-center" xs="5" md="4">
                                  {photos &&
                                    photos !== undefined &&
                                    photos.map((photo, idx) => {
                                      if (photo.isPrimaryPicture) {
                                        return (
                                          <Fragment key={idx}>
                                            <Spacer size={15} />
                                            <Image
                                              src={photo.location}
                                              width="100%"
                                              height="auto"
                                              alt={
                                                deal.propertyName +
                                                " primary photo."
                                              }
                                            />
                                            <Spacer size={10} />
                                          </Fragment>
                                        );
                                      }

                                      return true;
                                    })}
                                  {photos &&
                                    photos !== undefined &&
                                    !photos.some(
                                      (photo) => photo.isPrimaryPicture
                                    ) && <Spacer size={15} />}
                                  <Button
                                    className="full-width"
                                    variant="dark"
                                    onClick={() => {
                                      archiveDeal(deal._id);
                                    }}
                                  >
                                    {deal.isArchivedBy.some(
                                      (user) => user === whoami._id
                                    )
                                      ? "UNARCHIVE"
                                      : "ARCHIVE"}
                                  </Button>
                                  {myCompany &&
                                    myCompany._id === deal.company._id &&
                                    isOnTeam &&
                                    deal &&
                                    deal.dealStatus === "In progress" && (
                                      <>
                                        <Spacer size={20} />
                                        <Button
                                          className="full-width"
                                          variant="primary"
                                          onClick={() => this.onEditDeal(deal)}
                                        >
                                          FINISH DEAL
                                        </Button>
                                        <Spacer size={10} />
                                      </>
                                    )}
                                  {whoami && whoami.role === "Lender" && (
                                    <>
                                      <Spacer size={20} />
                                      <PassDealForm dealId={deal._id}>
                                        <Button
                                          className="full-width"
                                          variant="primary"
                                        >
                                          PASS DEAL
                                        </Button>
                                      </PassDealForm>
                                      <Spacer size={10} />
                                    </>
                                  )}
                                  {whoami && whoami.role === "Admin" && (
                                    <>
                                      <Spacer size={20} />
                                      <Button
                                        className="full-width"
                                        variant="primary"
                                        onClick={() => this.onEditDeal(deal)}
                                      >
                                        EDIT DEAL
                                      </Button>
                                      <Spacer size={10} />
                                    </>
                                  )}
                                </Col>
                                <Col xs="7" md="8">
                                  <Spacer size={15} />
                                  <h2 className="bold deal-title">
                                    {propertyName}
                                  </h2>
                                  <Spacer size={5} />
                                  {propertyAddress ? (
                                    <p>
                                      {propertyAddress}, {propertyCity},{" "}
                                      {propertyState}{" "}
                                      <NumberFormat
                                        value={propertyZip}
                                        displayType={"text"}
                                        thousandSeparator={false}
                                      />
                                    </p>
                                  ) : null}
                                  <Spacer size={15} />
                                  <div className="dashboard-select-container">
                                    <p className="bold">Status: {dealStatus}</p>
                                    {whoami && whoami.role === "Admin" && (
                                      <Form.Group className="dashboard-select">
                                        <Form.Control
                                          size="sm"
                                          as="select"
                                          className={
                                            isDealInprogress
                                              ? "disable-action deal-link"
                                              : ""
                                          }
                                          placeholder="Change status"
                                          value="Change status"
                                          onChange={(e) => {
                                            updateDeal(_id, {
                                              dealStatus: e.target.value,
                                              fromDashboard: true,
                                            });
                                          }}
                                        >
                                          <option disabled>
                                            Change status
                                          </option>
                                          <option>Active</option>
                                          <option>Dead</option>
                                          <option>Awarded</option>
                                          <option>Hold</option>
                                          <option>In progress</option>
                                          <option>Under review</option>
                                          <option>Closed</option>
                                          <option>Removed</option>
                                        </Form.Control>
                                      </Form.Group>
                                    )}
                                  </div>
                                  {whoami && whoami.role !== "Lender" && (
                                    <div className="d-flex mt-2 mb-1">
                                      <h5>
                                        <Link
                                          className={
                                            isDealInprogress
                                              ? "disable-action deal-link"
                                              : ""
                                          }
                                          to={
                                            whoami.role === "Admin"
                                              ? {
                                                  pathname:
                                                    "/admin/lenders/deal",
                                                  state: { dealId: _id },
                                                }
                                              : {
                                                  pathname: `/deal/${_id}/stats`,
                                                }
                                          }
                                        >
                                          Deal Lenders
                                        </Link>
                                      </h5>
                                      {whoami.role === "Admin" && (
                                        <h5 className="ml-2">
                                          <a
                                            className={
                                              isDealInprogress
                                                ? "disable-action deal-link"
                                                : ""
                                            }
                                            href="/"
                                            onClick={(evt) => {
                                              evt.preventDefault();
                                              this.handleShowPrompt(true);
                                              this.setState({
                                                selectedDeal: _id,
                                                selectedDealItem: deal,
                                                option: "BorrowerTeam",
                                              });
                                            }}
                                          >
                                            Assign Team
                                          </a>
                                        </h5>
                                      )}
                                      <Spacer size={5} />
                                    </div>
                                  )}
                                  {myCompany &&
                                  deal &&
                                  myCompany._id === deal.company._id ? (
                                    isOnTeam ? (
                                      <h5>
                                        <Link
                                          to={"/deal/" + _id + "/team"}
                                          className={
                                            isDealInprogress
                                              ? "disable-action deal-link"
                                              : ""
                                          }
                                        >
                                          Manage Team
                                        </Link>
                                        <Spacer size={5} />
                                      </h5>
                                    ) : (
                                      <></>
                                    )
                                  ) : (
                                    <></>
                                  )}
                                  {isAssignedLender ? (
                                    !hasTeamAssigned ? (
                                      this.state.assignedTeam ? (
                                        <h5>
                                          <Link
                                            to={"/deal/" + _id + "/team"}
                                            className={
                                              isDealInprogress
                                                ? "disable-action deal-link"
                                                : ""
                                            }
                                          >
                                            Manage Team
                                          </Link>
                                        </h5>
                                      ) : (
                                        <h5>
                                          <Button
                                            variant="fake-link"
                                            className={
                                              isDealInprogress
                                                ? "disable-action deal-link fake-link font-14"
                                                : "fake-link font-14"
                                            }
                                            onClick={() => {
                                              this.handleShowPrompt(true);
                                              this.setState({
                                                selectedDeal: _id,
                                                option: "Team",
                                              });
                                            }}
                                          >
                                            Assign Lender Team
                                          </Button>
                                          <Spacer size={5} />
                                        </h5>
                                      )
                                    ) : (
                                      <h5>
                                        <Link
                                          to={"/deal/" + _id + "/team"}
                                          className={
                                            isDealInprogress
                                              ? "disable-action deal-link"
                                              : ""
                                          }
                                        >
                                          Manage Team
                                        </Link>
                                        <Spacer size={5} />
                                      </h5>
                                    )
                                  ) : (
                                    <></>
                                  )}

                                  <div className="deal-details">
                                    <Spacer size={5} />
                                    <div className="border-bottom"></div>
                                    <Spacer size={10} />
                                    <Row noGutters={true}>
                                      <Col xs="12">
                                        <h5>
                                          <span className="small-width">
                                            Amount:
                                          </span>
                                          <NumberFormat
                                            className="bold"
                                            value={maximumLoanAmountRequested}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            prefix={"$"}
                                          />
                                        </h5>
                                        <Spacer size={10} />
                                      </Col>
                                      <Col xs="12">
                                        <h5>
                                          <span className="small-width">
                                            Loan Type:
                                          </span>
                                          <span className="bold">
                                            {loanType}
                                          </span>
                                        </h5>
                                        <Spacer size={10} />
                                      </Col>
                                      <Col xs="12">
                                        <h5>
                                          <span className="small-width">
                                            Purpose:
                                          </span>
                                          <span className="bold">
                                            {purpose}
                                          </span>
                                        </h5>
                                        <Spacer size={10} />
                                      </Col>
                                      <Col xs="12">
                                        <h5>
                                          <span className="small-width">
                                            Property Type:
                                          </span>
                                          <span className="bold">
                                            {propertyTypeDisplay}
                                          </span>
                                        </h5>
                                        <Spacer size={10} />
                                      </Col>
                                      {(!isPortifolio ||
                                        !isMultiplePropertyType) &&
                                        propertySizeTypeDisplay && (
                                          <Col xs="12">
                                            <h5>
                                              <span className="small-width">
                                                {propertySizeTypeDisplay}:
                                              </span>
                                              <NumberFormat
                                                className="bold"
                                                value={propertySizeDisplay}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                              />
                                            </h5>
                                            <Spacer size={10} />
                                          </Col>
                                        )}
                                      <Col xs="12">
                                        <h5>
                                          <span className="small-width">
                                            Sponsor:
                                          </span>
                                          <span className="bold">
                                            {sponsorName}
                                          </span>
                                        </h5>
                                        <Spacer size={10} />
                                      </Col>
                                      <Col xs="12">
                                        <h5>
                                          <span className="small-width">
                                            Manager:
                                          </span>
                                          <span className="bold">
                                            {managementCompanyName}
                                          </span>
                                        </h5>
                                        <Spacer size={15} />
                                      </Col>
                                    </Row>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col xs="12">
                                  <div className="deal-details-mobile">
                                    <Spacer size={5} />
                                    <div className="border-bottom"></div>
                                    <Spacer size={5} />
                                    <Row noGutters={true}>
                                      <Col xs="4">
                                        <h5>
                                          <span className="small-width">
                                            Amount:
                                          </span>
                                          <br />
                                          <NumberFormat
                                            className="bold"
                                            value={maximumLoanAmountRequested}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            prefix={"$"}
                                          />
                                        </h5>
                                        <Spacer size={5} />
                                      </Col>
                                      <Col xs="4">
                                        <h5>
                                          <span className="small-width">
                                            Purpose:
                                          </span>
                                          <br />
                                          <span className="bold">
                                            {purpose}
                                          </span>
                                        </h5>
                                        <Spacer size={5} />
                                      </Col>
                                      <Col xs="4">
                                        <h5>
                                          <span className="small-width">
                                            Property Type:
                                          </span>
                                          <br />
                                          <span className="bold">
                                            {propertyTypeDisplay}
                                          </span>
                                        </h5>
                                        <Spacer size={5} />
                                      </Col>
                                      {(!isPortifolio ||
                                        !isMultiplePropertyType) &&
                                        propertySizeTypeDisplay && (
                                          <Col xs="4">
                                            <h5>
                                              <span className="small-width">
                                                {propertySizeTypeDisplay}:
                                              </span>
                                              <br />
                                              <NumberFormat
                                                className="bold"
                                                value={propertySizeDisplay}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                              />
                                            </h5>
                                            <Spacer size={5} />
                                          </Col>
                                        )}
                                      <Col xs="4">
                                        <h5>
                                          <span className="small-width">
                                            Sponsor:
                                          </span>
                                          <br />
                                          <span className="bold">
                                            {sponsorName}
                                          </span>
                                        </h5>
                                        <Spacer size={5} />
                                      </Col>
                                      <Col xs="4">
                                        <h5>
                                          <span className="small-width">
                                            Manager:
                                          </span>
                                          <br />
                                          <span className="bold">
                                            {managementCompanyName}
                                          </span>
                                        </h5>
                                        <Spacer size={15} />
                                      </Col>
                                    </Row>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col xs="12" md={{ span: 8, offset: 4 }}>
                                  <Spacer size={5} />
                                  <p className="bold">Critical Dates</p>
                                  <Spacer size={5} />
                                  <div className="border-bottom"></div>
                                  <Spacer size={10} />
                                  <Row noGutters={true}>
                                    <Col xs="4">
                                      <h5>
                                        <span className="bold">Submitted:</span>{" "}
                                        <br />
                                        {moment
                                          .utc(createdAt)
                                          .format("MM/DD/YY")}
                                      </h5>
                                    </Col>
                                    <Col xs="4">
                                      <h5>
                                        <span className="bold">
                                          Quotes due:
                                        </span>{" "}
                                        0
                                      </h5>
                                    </Col>
                                    <Col xs="4">
                                      <h5>
                                        <span className="bold">
                                          Closing in:
                                        </span>{" "}
                                        <br />
                                        {days}
                                      </h5>
                                    </Col>
                                  </Row>
                                  <Spacer size={10} />
                                </Col>
                              </Row>
                              {whoami && whoami.role === "Admin" && (
                                <Row>
                                  <Col xs="12" md={{ span: 8, offset: 4 }}>
                                    {/* <Spacer size={5} />
                                <p className="bold">Created By</p>
                                <Spacer size={5} />*/}
                                    <div className="border-bottom"></div>
                                    <Spacer size={10} />
                                    <Row noGutters={true}>
                                      <Col xs="4">
                                        <h5>
                                          <span className="bold">
                                            Created By:
                                          </span>{" "}
                                          {deal.createdBy?.firstName}{" "}
                                          {deal.createdBy?.lastName}
                                        </h5>
                                      </Col>
                                      <Col xs="4">
                                        <h5>
                                          <span className="bold">Company:</span>{" "}
                                          {deal.company?.companyName}
                                        </h5>
                                      </Col>
                                      <Col xs="4">
                                        <h5>
                                          <span className="bold">
                                            Assigned Team:
                                          </span>{" "}
                                          {deal.teamId?.teamName}
                                        </h5>
                                      </Col>
                                    </Row>
                                    <Spacer size={10} />
                                  </Col>
                                </Row>
                              )}
                            </Container>
                            <Row>
                              <Col className="deal-actions" xs="12">
                                <Row noGutters={true}>
                                  <Col xs="4" md="12">
                                    <Link
                                      to={"/deal/inbox/" + _id}
                                      className="deal-action-item align-items-center"
                                    >
                                      <div className="deal-action-item-wrapper">
                                        <FontAwesomeIcon icon={faPaperPlane} />
                                        <p>Inbox</p>
                                      </div>
                                    </Link>
                                  </Col>
                                  <Col xs="4" md="12">
                                    <Link
                                      to={`/deal/${_id}/quote-summary`}
                                      className={
                                        isDealInprogress
                                          ? "disable-action deal-card deal-action-item align-items-center"
                                          : "deal-action-item align-items-center"
                                      }
                                    >
                                      <div className="deal-action-item-wrapper">
                                        <FontAwesomeIcon icon={faTag} />
                                        <p>Quotes</p>
                                      </div>
                                    </Link>
                                  </Col>
                                  <Col xs="4" md="12">
                                    <Link
                                      to={"/deal/view/" + _id}
                                      className="deal-action-item align-items-center"
                                    >
                                      <div className="deal-action-item-wrapper">
                                        <FontAwesomeIcon icon={faFile} />
                                        <p>View Deal</p>
                                      </div>
                                    </Link>
                                  </Col>
                                  <Col
                                    className="action-hidden-mobile"
                                    xs="4"
                                    md="12"
                                  >
                                    <Link
                                      to={`/deal/files/${_id}`}
                                      className={
                                        isDealInprogress
                                          ? "disable-action deal-card deal-action-item align-items-center"
                                          : "deal-action-item align-items-center"
                                      }
                                    >
                                      <div className="deal-action-item-wrapper">
                                        <FontAwesomeIcon icon={faFolder} />
                                        <p>Files</p>
                                      </div>
                                    </Link>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col xs="12">
                        <Spacer size={50} />
                      </Col>
                    </React.Fragment>
                  );
                })}
              {!isLoading && !withSearch && (
                <div className="mb-3 col-12">
                  <Paginate
                    {...pagination}
                    list={deals}
                    listTotalCount={totalDeals}
                    previousPage={this.handlePagination}
                    nextPage={this.handlePagination}
                  />
                </div>
              )}
            </>
          )}

          <Col xs="12" lg="12">
            {noDeals && (!isEmpty(filters) || withSearch) && (
              <>
                <NoResultsFound onReset={this.onResetFilters} />
              </>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    action: state.action,
    myCompany: state.company.myCompany,
    whoami: state.auth.whoami,
    deals: state.deal.deals,
    totalDeals: state.deal.totalDeals,
    withSearch: state.deal.withSearch,
    teams: state.team.teams,
    assignBorrowerTeamToDeal: state.deal.assignBorrowerTeamToDeal,
  };
}

export default connect(mapStateToProps)(Dashboard);
