import React from "react";
import { connect } from "react-redux";
import { getDealById } from "redux/actions";
import { dealMapsDetails } from "../ApplicationSteps/DealMaps";
import { addMaps } from "components/google/maps";
import { storeJsonInLocal } from "utils/lsUtils";
import constants from "config/constants";
import Socket from "utils/Socket";
import { Col, Layout, Row } from "antd";
import DealTabs from "./dealTabs/DealTabs";
import DealInfo from "./dealInfo/DealInfo";

class DealV2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleDealInformation: true,
      togglePropertyDescription: true,
      toggleSponsorInformation: true,
      toggleImages: true,
      toggleLoanRequest: true,
      toggleSourcesAndUses: true,
      toggleManagerInformation: true,
      toggleCriticalExtras: true,
      toggleMaps: true,
      expandDisclosure: false,
    };
  }

  componentDidMount() {
    const { match } = this.props;

    getDealById(match.params.id, { populate: true });

    window.scrollTo({ top: 0, behavior: `smooth` });
    this.trackPageView();
  }

  trackPageView = () => {
    const { match, myCompany } = this.props;
    if (myCompany.companyType === "Lender") {
      Socket.send("DEAL_STATISTICS", {
        dealId: match.params.id,
        companyId: myCompany._id,
        actions: { type: "PAGE_VIEW" },
      });
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.deal !== prevProps.deal) {
      const { deal } = this.props;

      this.drawMaps();

      let jsonObj = {};
      jsonObj[constants.TERMST_LOCAL_STORAGE.CURRENT_DEAL] = deal;
      storeJsonInLocal(jsonObj);
    }
    if (prevProps.match.params.id !== this.props.match.params.id) {
      getDealById(this.props.match.params.id);
    }
    if (prevProps.myCompany._id !== this.props.myCompany._id) {
      this.trackPageView();
    }
  }

  drawMaps = () => {
    const { propertyLat: lat, propertyLong: lng } = this.props.deal;
    dealMapsDetails.map((detail, index) => {
      addMaps(`view-deal-map-${index}`, detail.meta.zoom, { lat, lng });

      return true;
    });
  };

  render() {
    const { deal } = this.props;
    return (
      <Layout style={{ background: "white" }} className="pb-5">
        <Layout.Content>
          <Row>
            <Col xs={24} xl={12}>
              <DealInfo deal={deal} />
            </Col>
            <Col xs={24} xl={12}>
              <DealTabs deal={deal} />
            </Col>
          </Row>
        </Layout.Content>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    deal: state.deal.deal,
    whoami: state.auth.whoami,
    myCompany: state.company.myCompany,
  };
}

export default connect(mapStateToProps)(DealV2);
