import { getSuccessType } from "./actionDefinitions";

const getSuccessFn = (key, successFn) => {
  if (successFn) return successFn;

  if (!key)
    return (action) => ({
      ...action.data
    });
  else {
    return (action) => ({
      [key]: action.data
    });
  }
};

const reducerCreator = ({ type, initialState = {}, key, successFn }) => {
  const successFnToUse = getSuccessFn(key, successFn);
  return (state = initialState, action) => {
    switch (action.type) {
      case getSuccessType(type):
        return {
          ...state,
          ...successFnToUse(action, state)
        };
      default:
        return state;
    }
  };
};
const genericReducerCreator = ({ type, initialState = {}, key, successFn }) => {
  const successFnToUse = getSuccessFn(key, successFn);
  return (state = initialState, action) => {
    switch (action.type) {
      case type:
        return {
          ...state,
          ...successFnToUse(action, state)
        };
      default:
        return state;
    }
  };
};
const multipleReducers = ({ initializers: { initialState, key }, list }) => {
  const reducerFns = list.map((c) => {
    const { reducerCreatorFn, params } = c;
    const paramsToPass = { initialState, key, ...params };
    return reducerCreatorFn(paramsToPass);
  });

  return ((reducerFns) => (state, action) =>
    reducerFns.reduce((a, c) => c(a, action), state))(reducerFns);
};

export { reducerCreator, genericReducerCreator, multipleReducers };
