import { last } from "lodash";
import { useCallback, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { archiveConversation } from "redux/actions";
import Socket from "utils/Socket";

const useAutoArchive = () => {
  const conversationSubscriptionId1 = useRef(null);
  const match = useRouteMatch();
  const dealId = match.params.id;
  const whoami = useSelector((state) => state.auth.whoami);
  const myCompanyTeams = useSelector((state) => state.team.teams);
  const lastMessageReply = useSelector(
    (state) => state.conversation.lastMessageReply
  );
  const lastQuestionAnswer = useSelector(
    (state) => state.conversation.lastQuestionAnswer
  );
  const conversationDetails = useSelector(
    (state) => state.conversation.conversationDetails
  );

  const runAutoArchive = useCallback(
    (conversation, updatedConversation) => {
      // myResponsesOnly = true (my responses only)
      if (
        conversation.status !== "Archived" &&
        whoami.commsPreferences.autoArchive.turnedOn &&
        whoami.commsPreferences.autoArchive.myResponsesOnly &&
        whoami._id === conversation.senderId &&
        whoami._id === last(conversation.messages)?.senderId
      ) {
        archiveConversation(conversation._id);
        return;
      }

      // myResponsesOnly = false (my responses and my team's responses)
      const myTeamMembers = myCompanyTeams.reduce(
        (acc, curr) => [...acc, ...curr.teamMembers],
        []
      );
      const [sender] = conversation._senderId || [];
      if (
        sender &&
        updatedConversation &&
        conversation.status !== "Archived" &&
        sender.commsPreferences.autoArchive.turnedOn &&
        sender.commsPreferences.autoArchive.myResponsesOnly === false &&
        whoami._id === last(updatedConversation.messages)?.senderId &&
        myTeamMembers.some((m) => m.userId._id === conversation.senderId) &&
        myTeamMembers.some((m) => m.userId._id === whoami._id)
      ) {
        archiveConversation(conversation._id);
        return;
      }
    },
    [myCompanyTeams, whoami]
  );

  useEffect(() => {
    conversationSubscriptionId1.current = Socket.subscribe(
      `/deal/${dealId}/conversations`,
      (socketData) => {
        if (
          socketData.action === "REPLY_MESSAGE_LIST_REFRESH" ||
          socketData.action === "ANSWER_QUESTION_LIST_REFRESH"
        ) {
          runAutoArchive(socketData.payload);
        }
      }
    );
    return () => {
      Socket.unsubscribe(conversationSubscriptionId1.current);
    };
  }, [runAutoArchive]);

  useEffect(() => {
    if (
      conversationDetails &&
      conversationDetails._id === lastMessageReply?._id
    ) {
      runAutoArchive(conversationDetails, lastMessageReply);
      return;
    }
    if (
      conversationDetails &&
      conversationDetails._id === lastQuestionAnswer?._id
    ) {
      runAutoArchive(conversationDetails, lastQuestionAnswer);
      return;
    }
  }, [
    conversationDetails,
    lastMessageReply,
    lastQuestionAnswer,
    runAutoArchive,
  ]);
};

export default useAutoArchive;
