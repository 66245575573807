import React from "react";
import { connect } from "react-redux";
import { Button, Row, Col, Modal, Spinner, Form } from "react-bootstrap";
import { Field, reduxForm } from "redux-form";
import { required } from "utils/validation";

import { canDeactivateAccount, deactivateRequest } from "redux/actions";
import Spacer from "components/layout/Spacer";
import PopupPrompt from "components/ui/PopupPrompt";
import { reduxFormInput } from "components/form/ReduxForm";
import { isEmpty } from "lodash";

class AccountDeactivate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPrompt: false,
      showLoading: false,
      currentPassword: undefined,
    };

    this.submitFn = this.submitFn.bind(this);
    this.handleShowPrompt = this.handleShowPrompt.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.showLoading &&
      !this.props.action.CAN_DEACTIVATE_ACCOUNT.isFetching
    ) {
      this.setState({ showPrompt: true, showLoading: false });
    }
  }

  submitFn(data) {
    const { email } = this.props.profile;
    // formData.append("accountActivated", false);

    deactivateRequest({ email, currentPassword: this.state.currentPassword });
  }

  handleShowPrompt(showPrompt) {
    this.setState({ showPrompt, showLoading: showPrompt });
  }

  handleShowLoading = (showLoading) => {
    this.setState({ showPrompt: !showLoading, showLoading });
  };

  handleDeactivate = () => {
    canDeactivateAccount();
    this.handleShowLoading(true);
  };

  onChange = (e) => {
    this.setState({ currentPassword: e.target.value });
  };
  render() {
    const { action, deactivateAccount, handleSubmit } = this.props;
    const { isFetching, isFetched } = action.REQUEST_DEACTIVATION;
    return (
      <>
        {/* {isFetching && <Loader />} */}
        <Row>
          <Col xs="12">
            <h3 className="bold">Deactivate Account</h3>
            <Spacer size={30} />
          </Col>
        </Row>
        <Form
          className="no-border no-padding"
          onSubmit={handleSubmit(this.submitFn)}
        >
          <Row>
            <Col xs="12" sm="6">
              To begin the deactivate process, provide your account password
              <Spacer size={10} />
              <Field
                className="form-control"
                component={reduxFormInput}
                validate={[required]}
                name="PASSWORD"
                type="password"
                input={{ onChange: this.onChange }}
                placeholder="Account Password"
                label="*Account Password"
              />
            </Col>
          </Row>
        </Form>
        <Spacer size={20} />

        <Row>
          <Col xs="12" sm="6" style={{ textAlign: " center" }}>
            <Button
              variant="primary"
              disabled={isEmpty(this.state.currentPassword)}
              onClick={this.handleDeactivate}
            >
              Deactivate Account
            </Button>
          </Col>
        </Row>

        <Spacer size={50} />
        {action.CAN_DEACTIVATE_ACCOUNT.isFetching && (
          <Modal
            show={this.state.showLoading}
            onHide={() => this.handleShowLoading(false)}
            size="sm"
            centered
          >
            <Modal.Body>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </div>
            </Modal.Body>
          </Modal>
        )}
        {deactivateAccount?.canDeactivate && (
          <PopupPrompt
            promptTitle={"Account Deactivation Request Confirmation"}
            confirmLabel="Deactivate"
            promptBody={
              isFetched
                ? "An email has been sent for confirmation!!"
                : "You have requested to deactivate this account. An email will be sent to the account holder to confirm deactivation."
            }
            show={this.state.showPrompt}
            isLoading={isFetching}
            onCancel={() => this.handleShowPrompt(false)}
            {...(!isFetched && {
              onConfirm: this.submitFn,
              withFooter: true,
              withCancel: true,
            })}
          />
        )}
        {!deactivateAccount?.canDeactivate && (
          <PopupPrompt
            confirmLabel="Deactivate"
            promptTitle={"Account Deactivation Request Confirmation"}
            promptBody={
              isFetched
                ? "An Email has been sent for confirmation!!"
                : `This user is the only one left receiving the notifications for team ${deactivateAccount?.team?.teamName}. You have requested to deactivate this account. An email will be sent to the account holder to confirm deactivation.`
            }
            show={this.state.showPrompt}
            isLoading={isFetching}
            onCancel={() => this.handleShowPrompt(false)}
            {...(!isFetched && {
              onConfirm: this.submitFn,
              withFooter: true,
              withCancel: true,
            })}
          />
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    action: state.action,
    deactivateAccount: state.auth.deactivateAccount,
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "DeactivateAccount",
    enableReinitialize: true,
  })(AccountDeactivate)
);
