import { actionTypes } from "redux/actions";
import { reducerCreator, multipleReducers } from "./meta";

export const auth = multipleReducers({
  initializers: {
    initialState: {
      isAuthenticated: false,
      whoami: {},
      accountstatus: {},
      signup: undefined,
      updatedProfile: {},
      deactivateAccount: {},
    },
  },
  list: [
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.REGISTER_USER,
        successFn: (action) => ({
          //
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.LOGIN_USER,
        successFn: (action) => {
          return {
            isAuthenticated: true,
          };
        },
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.VERIFY_ACCOUNT_STATUS,
        successFn: (action) => ({
          accountstatus: action.data,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.GET_USER_FOR_SIGNUP,
        successFn: (action) => ({
          signup: {
            user: action.data.message ? null : action.data.body,
          },
        }),
      },
    },

    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.SET_PASSWORD,
        successFn: (action) => ({
          isAuthenticated: true,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.SIGN_OUT,
        successFn: (action) => ({
          isAuthenticated: false,
          whoami: null,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.GET_MY_PROFILE,
        successFn: (action) => ({
          whoami: action.data.body,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.UPDATE_PROFILE,
        successFn: (action) => ({
          updatedProfile: action.data.body,
          whoami: action.data.body,
        }),
      },
    },
    {
      reducerCreatorFn: reducerCreator,
      params: {
        type: actionTypes.CAN_DEACTIVATE_ACCOUNT,
        successFn: (action) => ({
          deactivateAccount: action.data.body,
        }),
      },
    },
  ],
});
