import React from 'react';
import { connect } from "react-redux";
import ProgramCreateEdit from './ProgramCreateEdit';
import PopupPrompt from 'components/ui/PopupPrompt';
import { formValueSelector } from "redux-form";

class ProgramPrompt extends React.Component {
    constructor(props) {
        super(props);
        this.state = { stepName: "" }
    }
    setStepName = (stepName) => this.setState({ stepName })
    generateTitle = () => {
        let title = "";
        if (this.props.isUpdate) {
            title = `Edit`
        } else if (this.props.isClone) {
            title = "Clone"
        } else {
            title = `Create`
        }

        if (this.state.stepName) {
            title += ` - ${this.state.stepName}`
        }

        if (this.props.programName) {
            title += ` - ${this.props.programName}`
        }

        return title;
    }
    render() {

        return (
            <PopupPrompt
                promptTitle={this.generateTitle()}
                promptBody={
                    <ProgramCreateEdit
                        isCompanyAdmin={this.props.isCompanyAdmin}
                        companyId={this.props.companyId}
                        onSubmit={this.props.onSubmit}
                        setStepName={this.setStepName}
                        isUpdate={this.props.isUpdate}
                        isClone={this.props.isClone}
                        onCancel={this.props.onCancel}
                    />
                }
                show={this.props.show}
                onCancel={this.props.onCancel}
            // Not allowing to close popup on just clicking away
            // onHide={() => {}}
            />
        )
    }
}

const selector = formValueSelector("ProgramCreateInitialStep");

function mapStateToProps(state) {
    return {
        selectedProgram: state.program?.program || {},
        programName: selector(state, "programName") || "",
    };
}

export default connect(mapStateToProps)(ProgramPrompt);
