import { dispatch } from "config/store";
import { actionTypes } from "./actionTypes";

export const createClause = (data) => {
  const action = {
    type: actionTypes.CREATE_CLAUSE,
    config: {
      method: "post",
      url: `/clauses`,
      data,
    },
  };

  dispatch(action);
};

export const updateClause = (clauseId, data) => {
  const action = {
    type: actionTypes.UPDATE_CLAUSE,
    config: {
      method: "put",
      url: `/clauses/${clauseId}`,
      data,
    },
  };

  dispatch(action);
};

export const deleteClause = (clauseId) => {
  const action = {
    type: actionTypes.DELETE_CLAUSE,
    config: {
      method: "delete",
      url: `/clauses/${clauseId}`,
    },
  };

  dispatch(action);
};

export const getClauses = (query = { limit: 100, order: "ASC" }) => {
  const action = {
    type: actionTypes.GET_CLAUSES,
    config: {
      method: "get",
      url: `/clauses?limit=${query.limit}&order=${query.order}&orderColumn=${
        query.orderColumn
      }&parent=${query.parent}&status=${query.status || ""}&dealId=${
        query.dealId || ""
      }&companyIds=${query.companyIds || ""}`,
    },
  };

  dispatch(action);
};

export const getManageClauses = (
  query = { limit: 100, order: "ASC", orderColumn: "createdAt", page: 1 }
) => {
  const action = {
    type: actionTypes.GET_MANAGE_CLAUSES,
    config: {
      method: "get",
      url: `/clauses?limit=${query.limit}&order=${query.order}&orderColumn=${
        query.orderColumn
      }&page=${query.page}&parent=${query.parent || ""}&parentId=${
        query.parentId || ""
      }&status=${query.status || ""}`,
    },
  };

  dispatch(action);
};

export const mergeClauses = (data) => {
  const action = {
    type: actionTypes.MERGE_CLAUSES,
    config: {
      method: "post",
      url: `/clauses/merge`,
      data,
    },
  };

  dispatch(action);
};

export const reorderClauses = (data) => {
  const action = {
    type: actionTypes.REORDER_CLAUSES,
    config: {
      method: "put",
      url: `/clauses/bulk`,
      data,
    },
  };

  dispatch(action);
};
