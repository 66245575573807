import { actionTypes } from "redux/actions";

const { ISFETCHING } = actionTypes;

let isFetchingInitialState = {};
Object.values(actionTypes).forEach((act, i) => {
  isFetchingInitialState[act] = {
    isFetching: false,
    isFetched: false,
    isError: false
  };
});

const isFetchingReducer = ({
  type = ISFETCHING,
  initialState = isFetchingInitialState
}) => (state = initialState, action) => {
  switch (action.type) {
    case type:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          isFetching: action.isFetching || false,
          isFetched: action.isFetched || false,
          isError: action.isError || false
        }
      };
    default:
      return state;
  }
};

export const action = isFetchingReducer({});
