import React from "react";
import { Row, Col, Button, Card } from "react-bootstrap";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Spacer from "components/layout/Spacer";
import quotesConfig from "./quotesConfig";

function AddEmptyQuote({
  quote,
  firstQuote,
  copyQuote,
  deleteQuote,
  editQuote,
  whoami,
  quoteMatrices = [],
  onAddEmptyQuote,
  ...props
}) {
  const matrices = React.useMemo(() => {
    return [...quotesConfig, ...quoteMatrices];
  }, [quotesConfig, quoteMatrices]);

  return (
    <Row className={props.className}>
      <Col>
        <Card className={`quote-card add-quote`}>
          <div className="click-container">
            <Button
              variant="primary"
              className="plus-button my-2"
              onClick={onAddEmptyQuote}
            >
              <FontAwesomeIcon size="lg" icon={faPlus} />
            </Button>
            <p>
              <b>
                Click to add <br /> another quote
              </b>
            </p>
          </div>
          <Card.Body className="quote-card-body">
            {matrices.map((config, index) => {
              if (config.showIf && !config.showIf(firstQuote)) {
                return null;
              }
              return (
                <Row key={index}>
                  <div></div>
                </Row>
              );
            })}
            <Spacer size={23} />
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

export default AddEmptyQuote;
