import React from "react";
import { connect } from "react-redux";
import { Button, Form, Row, Col } from "react-bootstrap";
import { Field, reduxForm, reset } from "redux-form";
import { reduxFormSelect } from "components/form/ReduxForm";
import { getComms } from "redux/actions";
import Spacer from "components/layout/Spacer";

class FilterInbox extends React.Component {
  constructor(props) {
    super(props);

    this.resetFilters = this.resetFilters.bind(this);
    this.submitFn = this.submitFn.bind(this);
  }

  resetFilters() {
    const { dispatch, onResetFilters, dealId } = this.props;

    onResetFilters();
    dispatch(reset("FilterInbox"));

    getComms({
      dealId,
      archived: false,
      page: 1,
    });
  }

  submitFn(data) {
    const { onSubmit } = this.props;

    onSubmit(data);
  }

  render() {
    const { handleSubmit } = this.props;

    return (
      <Form
        className="no-padding no-border"
        onSubmit={handleSubmit(this.submitFn)}
      >
        <Row>
          <Col xs="12" sm="3">
            <Field
              className="form-control"
              component={reduxFormSelect}
              name="type"
              type="select"
              options={[
                { label: "Message", value: "Message" },
                { label: "Question", value: "Question" },
              ]}
              placeholder="Select..."
              label="Communication Type"
            />
            <Spacer size={20} />
          </Col>
          <Col xs="12" sm="3">
            <Field
              className="form-control"
              component={reduxFormSelect}
              name="isAnswered"
              type="select"
              options={[
                { label: "Yes", value: "Yes" },
                { label: "No", value: "No" },
              ]}
              placeholder="Select..."
              label="Answered"
            />
            <Spacer size={20} />
          </Col>
          <Col xs="12" sm="3">
            <Field
              className="form-control"
              component={reduxFormSelect}
              name="archived"
              type="select"
              options={[
                { label: "Yes", value: "Yes" },
                { label: "No", value: "No" },
              ]}
              placeholder="Select..."
              label="Archived"
            />
            <Spacer size={20} />
          </Col>
          <Col xs="12" sm="3">
            <Field
              className="form-control"
              component={reduxFormSelect}
              name="isPublic"
              type="select"
              options={[
                { label: "Public", value: true },
                { label: "Private", value: false },
              ]}
              placeholder="Select..."
              label="Status"
            />
            <Spacer size={20} />
          </Col>
        </Row>
        <Row>
          <Col className="text-right" xs="12">
            <Spacer size={50} />
            <Button className="mr-3" variant="dark" onClick={this.resetFilters}>
              Reset
            </Button>
            <Button type="submit" variant="primary">
              Apply
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

function mapStateToProps(state, props) {
  const { filters } = props;

  return {
    initialValues: {
      type: filters.type,
      isAnswered: filters.isAnswered,
      archived: filters.archived,
      isPublic: filters.isPublic,
    },
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "FilterInbox",
    enableReinitialize: true,
  })(FilterInbox)
);
