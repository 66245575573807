export const sagaActionTypes = {
  // Auth
  REGISTER_USER: "REGISTER_USER",
  LOGIN_USER: "LOGIN_USER",
  VERIFY_ACCOUNT_STATUS: "VERIFY_ACCOUNT_STATUS",
  GET_USER_FOR_SIGNUP: "GET_USER_FOR_SIGNUP",
  SET_PASSWORD: "SET_PASSWORD",
  UPDATE_PASSWORD: "UPDATE_PASSWORD",
  SIGN_OUT: "SIGN_OUT",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  RESET_PASSWORD: "RESET_PASSWORD",
  RESEND_VERIFICATION: "RESEND_VERIFICATION",
  GET_MY_PROFILE: "GET_MY_PROFILE",
  UPDATE_PROFILE: "UPDATE_PROFILE",
  UPDATE_USER: "UPDATE_USER",
  DEACTIVATE_ACCOUNT: "DEACTIVATE_ACCOUNT",
  REQUEST_DEACTIVATION: "REQUEST_DEACTIVATION",
  LOGIN_AS_USER: "LOGIN_AS_USER",
  CAN_DEACTIVATE_ACCOUNT: "CAN_DEACTIVATE_ACCOUNT",
  // Company
  CREATE_COMPANY: "CREATE_COMPANY",
  UPDATE_COMPANY: "UPDATE_COMPANY",
  GET_MY_COMPANY: "GET_MY_COMPANY",
  GET_COMPANY_BY_ID: "GET_COMPANY_BY_ID",
  GET_COMPANY_MEMBERS: "GET_COMPANY_MEMBERS",
  ADD_TO_COMPANY: "ADD_TO_COMPANY",
  INVITE_TO_COMPANY: "INVITE_TO_COMPANY",
  CREATE_SPONSOR: "CREATE_SPONSOR",
  REMOVE_SPONSOR: "REMOVE_SPONSOR",
  CREATE_MANAGER: "CREATE_MANAGER",
  UPDATE_MANAGER: "UPDATE_MANAGER",
  UPDATE_SPONSOR: "UPDATE_SPONSOR",
  REMOVE_MANAGER: "REMOVE_MANAGER",
  // Program
  GET_DROPDOWNS: "GET_DROPDOWNS",
  CREATE_PROGRAM: "CREATE_PROGRAM",
  UPDATE_PROGRAM: "UPDATE_PROGRAM",
  UPDATE_PARTIAL_PROGRAM: "UPDATE_PARTIAL_PROGRAM",
  DELETE_PROGRAM: "DELETE_PROGRAM",
  GET_PROGRAMS_BY_ID: "GET_PROGRAMS_BY_ID",
  GET_PROGRAMS_BY_COMPANY_ID: "GET_PROGRAMS_BY_COMPANY_ID",
  // Teams
  CREATE_TEAM: "CREATE_TEAM",
  GET_TEAMS_BY_COMPANY_ID: "GET_TEAMS_BY_COMPANY_ID",
  GET_TEAM_BY_ID: "GET_TEAM_BY_ID",
  UPDATE_TEAM: "UPDATE_TEAM",
  UPDATE_TEAM_NAME: "UPDATE_TEAM_NAME",
  UPDATE_TEAM_DEFAULT: "UPDATE_TEAM_DEFAULT",
  // Deals
  GET_DEALS: "GET_DEALS",
  GET_FILTERED_DEALS: "GET_FILTERED_DEALS",
  SEARCH_DEALS: "SEARCH_DEALS",
  GET_DEAL_BY_ID: "GET_DEAL_BY_ID",
  CLEAR_DEAL_DETAILS: "CLEAR_DEAL_DETAILS",
  CREATE_DEAL: "CREATE_DEAL",
  ADD_PROPERTY:"ADD_PROPERTY",
  UPDATE_DEAL: "UPDATE_DEAL",
  ARCHIVE_DEAL: "ARCHIVE_DEAL",
  GET_DEAL_NOTIFICATIONS: "GET_DEAL_NOTIFICATIONS",
  ENABLE_DEAL_NOTIFICATIONS: "ENABLE_DEAL_NOTIFICATIONS",
  DISABLE_DEAL_NOTIFICATIONS: "DISABLE_DEAL_NOTIFICATIONS",
  ADD_USES_TO_DEAL: "ADD_USES_TO_DEAL",
  ADD_SOURCES_TO_DEAL: "ADD_SOURCES_TO_DEAL",
  REMOVE_SOURCES_FROM_DEAL: "REMOVE_SOURCES_FROM_DEAL",
  REMOVE_USES_FROM_DEAL: "REMOVE_USES_FROM_DEAL",
  ADD_REFINANCE_USES_TO_DEAL: "ADD_REFINANCE_USES_TO_DEAL",
  ADD_REFINANCE_SOURCES_TO_DEAL: "ADD_REFINANCE_SOURCES_TO_DEAL",
  REMOVE_REFINANCE_USES_FROM_DEAL: "REMOVE_REFINANCE_USES_FROM_DEAL",
  REMOVE_REFINANCE_SOURCES_FROM_DEAL: "REMOVE_REFINANCE_SOURCES_FROM_DEAL",
  ASSIGN_TEAM_TO_DEAL: "ASSIGN_TEAM_TO_DEAL",
  ASSIGN_BORROWER_TEAM_TO_DEAL: "ASSIGN_BORROWER_TEAM_TO_DEAL",
  PASS_DEAL: "PASS_DEAL",
  ADD_DEAL_CARD: "ADD_DEAL_CARD",
  EDIT_DEAL_CARD: "EDIT_DEAL_CARD",
  DELETE_DEAL_CARD: "DELETE_DEAL_CARD",
  HIDDEN_DEAL_CARD: "HIDDEN_DEAL_CARD",
  REORDER_DEAL_CARDS: "REORDER_DEAL_CARDS",
  UPDATE_DEAL_MANAGER: "UPDATE_DEAL_MANAGER",

  // Communications
  CREATE_COMM: "CREATE_COMM",
  EDIT_COMM: "EDIT_COMM",
  GET_COMMS: "GET_COMMS",
  SEARCH_COMMS: "SEARCH_COMMS",
  GET_FILTERED_COMMS: "GET_FILTERED_COMMS",
  GET_COMM_BY_ID: "GET_COMM_BY_ID",
  ASSIGN_TO_COMM: "ASSIGN_TO_COMM",
  REMOVE_FROM_COMM: "REMOVE_FROM_COMM",
  ANSWER_COMM: "ANSWER_COMM",
  SEND_MESSAGE: "SEND_MESSAGE",
  EDIT_MESSAGE: "EDIT_MESSAGE",
  MERGE_MESSAGE: "MERGE_MESSAGE",
  UNMERGE_MESSAGE: "UNMERGE_MESSAGE",
  ARCHIVE_MESSAGE: "ARCHIVE_MESSAGE",
  SET_PUBLIC_STATUS: "SET_PUBLIC_STATUS",
  CREATE_GROUP: "CREATE_GROUP",
  GET_GROUPS: "GET_GROUPS",
  EDIT_GROUP: "EDIT_GROUP",
  DELETE_GROUP: "DELETE_GROUP",
  EDIT_COMM_GROUPS: "EDIT_COMM_GROUPS",
  // Admin
  GET_SCHEDULES: "GET_SCHEDULES",
  GET_ALL_COMPANIES: "GET_ALL_COMPANIES",
  GET_FILTERED_BORROWERS: "GET_FILTERED_BORROWERS",
  GET_FILTERED_LENDERS: "GET_FILTERED_LENDERS",
  SEARCH_COMPANIES: "SEARCH_COMPANIES",
  GET_ALL_COMPANIES_BY_SEARCH: "GET_ALL_COMPANIES_BY_SEARCH",
  GET_LENDERS_BY_PROGRAM_TAGS: "GET_LENDERS_BY_PROGRAM_TAGS",
  GET_LENDERS_BY_PROGRAM_NOTES: "GET_LENDERS_BY_PROGRAM_NOTES",
  UPDATE_DEAL_LENDERS: "UPDATE_DEAL_LENDERS",
  // File Manager
  GET_DEAL_FILES: "GET_DEAL_FILES",
  CREATE_DEAL_FOLDER: "CREATE_DEAL_FOLDER",
  CREATE_DEAL_FOLDERS: "CREATE_DEAL_FOLDERS",
  CREATE_PROPERTY_FOLDERS: "CREATE_PROPERTY_FOLDERS",
  SAVE_DEAL_FOLDER_FILES: "SAVE_DEAL_FOLDER_FILES",
  SAVE_FINANCIALS: "SAVE_FINANCIALS",
  SAVE_PICTURES: "SAVE_PICTURES",
  SAVE_PRIMARY_PICTURE: "SAVE_PRIMARY_PICTURE",
  DELETE_FILE: "DELETE_FILE",
  DELETE_FOLDER: "DELETE_FOLDER",
  GET_PERMISSION_USERS: "GET_PERMISSION_USERS",
  GET_PERMISSION: "GET_PERMISSION",
  ADD_PERMISSION: "ADD_PERMISSION",
  MANAGE_FILE_PERMISSION: "MANAGE_FILE_PERMISSION",
  REMOVE_PERMISSION: "REMOVE_PERMISSION",
  RENAME_FOLDER_FILE: "RENAME_FOLDER_FILE",
  UPDATE_PRIMARY_PICTURE: "UPDATE_PRIMARY_PICTURE",
  SEARCH_DEAL_FILES: "SEARCH_DEAL_FILES",
  //GENERIC
  CHANGE_ROUTE: "CHANGE_ROUTE",
  //TERMSHEET
  CREATE_TERMSHEET: "CREATE_TERMSHEET",
  GET_TERMSHEET: "GET_TERMSHEET",
  GET_ADMIN_DEFAULT_TERMSHEET: "GET_ADMIN_DEFAULT_TERMSHEET",
  GET_TERMSHEETS: "GET_TERMSHEETS",
  CLEAR_TERMSHEET: "CLEAR_TERMSHEET",
  CLEAR_QUOTES: "CLEAR_QUOTES",
  CREATE_TERMSHEET_COLUMN: "CREATE_TERMSHEET_COLUMN",
  CREATE_TERMSHEET_LANGUAGE: "CREATE_TERMSHEET_LANGUAGE",
  GET_TERMSHEET_LANGUAGE: "GET_TERMSHEET_LANGUAGE",
  GET_MANAGE_TERMSHEETS: "GET_MANAGE_TERMSHEETS",
  GET_QUOTES: "GET_QUOTES",
  GET_QUOTE: "GET_QUOTE",
  CREATE_QUOTE: "CREATE_QUOTE",
  DELETE_QUOTE: "DELETE_QUOTE",
  ARCHIVE_QUOTE: "ARCHIVE_QUOTE",
  STAR_QUOTE: "STAR_QUOTE",
  BULK_ARCHIVE_QUOTE: "BULK_ARCHIVE_QUOTE",
  BULK_STAR_QUOTE: "BULK_STAR_QUOTE",
  UPDATE_QUOTE: "UPDATE_QUOTE",
  UPDATE_TERMSHEET: "UPDATE_TERMSHEET",
  STORE_COMPARE_QUOTES: "STORE_COMPARE_QUOTES",
  STORE_QUOTE_MATRICES: "STORE_QUOTE_MATRICES",
  //USERS
  GET_USERS: "GET_USERS",
  GET_USER_PROFILE: "GET_USER_PROFILE",
  GET_MANAGE_USERS: "GET_MANAGE_USERS",
  PARTIAL_UPDATE_USER: "PARTIAL_UPDATE_USER",
  CREATE_USER: "CREATE_USER",
  // TAGS
  GET_TAGS: "GET_TAGS",
  GET_MANAGE_TAGS: "GET_MANAGE_TAGS",
  CREATE_TAG: "CREATE_TAG",
  UPDATE_TAG: "UPDATE_TAG",
  DELETE_TAG: "DELETE_TAG",
  MERGE_TAGS: "MERGE_TAGS",

  // NOTIFICATIONS
  GET_NOTIFICATIONS: "GET_NOTIFICATIONS",

  // CATEGORIES
  GET_CATEGORIES: "GET_CATEGORIES",
  GET_MANAGE_CATEGORIES: "GET_MANAGE_CATEGORIES",
  CREATE_CATEGORY: "CREATE_CATEGORY",
  UPDATE_CATEGORY: "UPDATE_CATEGORY",
  DELETE_CATEGORY: "DELETE_CATEGORY",
  MERGE_CATEGORIES: "MERGE_CATEGORIES",
  REORDER_CATEGORIES: "REORDER_CATEGORIES",

  // SECTIONS
  GET_SECTIONS: "GET_SECTIONS",
  GET_MANAGE_SECTIONS: "GET_MANAGE_SECTIONS",
  CREATE_SECTION: "CREATE_SECTION",
  GET_SECTION: "GET_SECTION",
  CLEAR_SECTION: "CLEAR_SECTION",
  UPDATE_SECTION: "UPDATE_SECTION",
  DELETE_SECTION: "DELETE_SECTION",
  MERGE_SECTIONS: "MERGE_SECTIONS",

  // CLAUSES
  GET_CLAUSES: "GET_CLAUSES",
  GET_MANAGE_CLAUSES: "GET_MANAGE_CLAUSES",
  CREATE_CLAUSE: "CREATE_CLAUSE",
  UPDATE_CLAUSE: "UPDATE_CLAUSE",
  DELETE_CLAUSE: "DELETE_CLAUSE",
  MERGE_CLAUSES: "MERGE_CLAUSES",
  REORDER_CLAUSES: "REORDER_CLAUSES",

  // CONVERSATIONS
  CREATE_CONVERSATION_MESSAGE: "CREATE_CONVERSATION_MESSAGE",
  CREATE_CONVERSATION_QUESTION: "CREATE_CONVERSATION_QUESTION",
  REPLY_TO_MESSAGE: "REPLY_TO_MESSAGE",
  ANSWER_TO_QUESTION: "ANSWER_TO_QUESTION",
  BULK_ARCHIVE_CONVERSATION: "BULK_ARCHIVE_CONVERSATION",
  BULK_DELETE_CONVERSATION: "BULK_DELETE_CONVERSATION",
  GET_CONVERSATIONS: "GET_CONVERSATIONS",
  GET_CONVERSATION_BY_ID: "GET_CONVERSATION_BY_ID",
  GET_CONVERSATION_BY_ID_OPTIMISTIC_UPDATE:
    "GET_CONVERSATION_BY_ID_OPTIMISTIC_UPDATE",
  GET_CONVERSATION_ALL_INBOX: "GET_CONVERSATION_ALL_INBOX",
  GET_CONVERSATION_MESSAGE_INBOX: "GET_CONVERSATION_MESSAGE_INBOX",
  GET_CONVERSATION_QUESTION_INBOX: "GET_CONVERSATION_QUESTION_INBOX",
  GET_CONVERSATION_ALL_ARCHIVED: "GET_CONVERSATION_ALL_ARCHIVED",
  GET_CONVERSATION_MESSAGES_ARCHIVED: "GET_CONVERSATION_MESSAGES_ARCHIVED",
  GET_CONVERSATION_QUESTIONS_ARCHIVED: "GET_CONVERSATION_QUESTIONS_ARCHIVED",
  GET_CONVERSATION_ALL_DRAFT: "GET_CONVERSATION_ALL_DRAFT",
  GET_CONVERSATION_MESSAGES_DRAFT: "GET_CONVERSATION_MESSAGES_DRAFT",
  GET_CONVERSATION_QUESTIONS_DRAFT: "GET_CONVERSATION_QUESTIONS_DRAFT",
  GET_CONVERSATION_ALL_SENT: "GET_CONVERSATION_ALL_SENT",
  GET_CONVERSATION_MESSAGES_SENT: "GET_CONVERSATION_MESSAGES_SENT",
  GET_CONVERSATION_QUESTIONS_SENT: "GET_CONVERSATION_QUESTIONS_SENT",
  GET_CONVERSATION_ALL_DELETED: "GET_CONVERSATION_ALL_DELETED",
  GET_CONVERSATION_MESSAGES_DELETED: "GET_CONVERSATION_MESSAGES_DELETED",
  GET_CONVERSATION_QUESTIONS_DELETED: "GET_CONVERSATION_QUESTIONS_DELETED",
  UPDATE_CONVERSATION: "UPDATE_CONVERSATION",
  DELETE_CONVERSATION: "DELETE_CONVERSATION",
  ARCHIVE_CONVERSATION: "ARCHIVE_CONVERSATION",
  DRAFT_CONVERSATION: "DRAFT_CONVERSATION",
  READ_CONVERSATION: "READ_CONVERSATION",
  UNREAD_CONVERSATION: "UNREAD_CONVERSATION",
  ASSIGN_CONVERSATION_LABELS: "ASSIGN_CONVERSATION_LABELS",
  CLEAR_CONVERSATION_DETAILS: "CLEAR_CONVERSATION_DETAILS",

  // LABELS
  CREATE_LABEL: "CREATE_LABEL",
  UPDATE_LABEL: "UPDATE_LABEL",
  GET_LABELS: "GET_LABELS",
  GET_LABELS_TREE_VIEW: "GET_LABELS_TREE_VIEW",
  DELETE_LABEL: "DELETE_LABEL",
};
