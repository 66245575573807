import React from "react";
import { connect } from "react-redux";
import { get } from "lodash";
import { Form, Button, Row, Col } from "react-bootstrap";
import { Field, reduxForm, formValueSelector } from "redux-form";
import {
  reduxFormInput,
  reduxFormSelect,
  reduxFormTextarea,
} from "components/form/ReduxForm";
import Spacer from "components/layout/Spacer";
import programCategories from "../constants/programCategories";
import { states, MSAs } from "utils/dataUtil";
import { noSpaces } from "utils/validation";
class GeographyStep extends React.Component {
  submitFn = () => {
    if (this.saveType === "saveAndContinue") {
      this.props.handleSaveAndContinue(this.props.nextStep);
    } else {
      this.props.handleSaveAndContinue();
    }
  };

  setSave = (type) => () => {
    this.saveType = type;
  };

  componentDidUpdate(prevProps) {
    if (prevProps.isActive !== this.props.isActive && this.props.isActive) {
      this.props.setStepName("Geography");
    }
  }

  render() {
    const {
      handleSubmit,
      selectedProgramType,
      action,
      selectedNonUsMarkets,
      selectedGeography,
    } = this.props;

    return (
      <fieldset>
        <Form
          className="no-padding no-border"
          onSubmit={handleSubmit(this.submitFn)}
        >
          <Row>
            <Col xs="12" sm="6">
              {programCategories.agency.indexOf(selectedProgramType.value) ===
                -1 && (
                <>
                  <Field
                    className="form-control"
                    component={reduxFormSelect}
                    name="viewMarkets"
                    type="select"
                    options={this.props.getDropdownValues("viewMarkets")}
                    isLoading={action.GET_DROPDOWNS.isFetching}
                    onFocus={() => this.props.getDropdownFromAPI("viewMarkets")}
                    placeholder="How we view markets?"
                    label="How we view markets?"
                    isMulti
                  />
                  <Spacer size={20} />
                </>
              )}
              <Field
                className="form-control"
                component={reduxFormSelect}
                name="geography"
                type="select"
                options={this.props.getDropdownValues("geography")}
                isLoading={action.GET_DROPDOWNS.isFetching}
                onFocus={() => this.props.getDropdownFromAPI("geography")}
                placeholder="Property Geography"
                label="Property Geography"
                isMulti
              />
              <Spacer size={20} />
              {selectedGeography !== undefined &&
                selectedGeography !== null &&
                selectedGeography.some(
                  (item) => item.label === "Or Pick States"
                ) && (
                  <>
                    <Field
                      className="form-control"
                      component={reduxFormSelect}
                      name="state"
                      type="select"
                      options={states}
                      placeholder="Select State"
                      label="Select State"
                      isMulti={true}
                    />
                    <Spacer size={20} />
                  </>
                )}
              {selectedGeography !== undefined &&
                selectedGeography !== null &&
                selectedGeography.some(
                  (item) => item.label === "Or Pick MSAs"
                ) && (
                  <>
                    <Field
                      className="form-control"
                      component={reduxFormSelect}
                      name="msaProperty"
                      type="select"
                      options={MSAs}
                      placeholder="Select MSA"
                      label="Select MSA"
                      isMulti={true}
                    />
                    <Spacer size={20} />
                  </>
                )}
              {selectedGeography !== undefined &&
                selectedGeography !== null &&
                selectedGeography.some(
                  (item) => item.label === "Or Describe Areas"
                ) && (
                  <>
                    <Field
                      className="form-control"
                      component={reduxFormTextarea}
                      validate={[noSpaces]}
                      name="otherGeography"
                      type="textarea"
                      rows="10"
                      placeholder="Additional information"
                      label="Or Describe Areas (Geography)"
                    />
                    <Spacer size={20} />
                  </>
                )}
            </Col>
            <Col xs="12" sm="6">
              {programCategories.agency.indexOf(selectedProgramType.value) ===
                -1 && (
                <>
                  <Field
                    className="form-control"
                    component={reduxFormSelect}
                    name="dealSponsorGeography"
                    type="select"
                    options={this.props.getDropdownValues(
                      "dealSponsorGeography"
                    )}
                    onFocus={() =>
                      this.props.getDropdownFromAPI("dealSponsorGeography")
                    }
                    isLoading={action.GET_DROPDOWNS.isFetching}
                    placeholder="Deal Sponsor Geography"
                    label="Deal Sponsor Geography"
                    isMulti
                  />
                  <Spacer size={20} />

                  <Field
                    className="form-control"
                    component={reduxFormSelect}
                    name="nonUsMarkets"
                    type="select"
                    options={this.props.getDropdownValues("nonUsMarkets")}
                    onFocus={() =>
                      this.props.getDropdownFromAPI("nonUsMarkets")
                    }
                    isLoading={action.GET_DROPDOWNS.isFetching}
                    placeholder="We can also lend in the following markets (not U.S. States)"
                    label="We can also lend in the following markets (not U.S. States)"
                    isMulti
                  />
                  {selectedNonUsMarkets.some(
                    (item) => item.value === "Other"
                  ) && (
                    <>
                      <Spacer size={20} />
                      <Field
                        className="form-control"
                        component={reduxFormInput}
                        name="otherNonUsMarket"
                        type="text"
                        placeholder="Other Non Us Market"
                        label="Other Non Us Market"
                      />
                      <Spacer size={20} />
                    </>
                  )}
                </>
              )}
            </Col>
          </Row>
          <Row>
            <Col className="text-left" xs="3">
              <Spacer size={50} />
              <Button
                onClick={this.props.handlePrevious(this.props.previousStep)}
                variant="dark"
              >
                Back
              </Button>
            </Col>
            <Col className="text-right" xs="9">
              <Spacer size={50} />
              <Button
                type="submit"
                variant="primary"
                onClick={this.setSave("saveAndExit")}
              >
                Save & Exit
              </Button>
              &nbsp;
              <Button
                type="submit"
                variant="primary"
                onClick={this.setSave("saveAndContinue")}
              >
                Save & Continue
              </Button>
            </Col>
          </Row>
        </Form>
      </fieldset>
    );
  }
}

const selectorInitialStep = formValueSelector("ProgramCreateInitialStep");
const selectorGeographyStep = formValueSelector("ProgramCreateGeographyStep");

const getInitialValues = ({ isUpdate, isClone, selectedProgram: program }) => {
  if (!isUpdate && !isClone) {
    return {};
  }
  return {
    viewMarkets: get(program, "viewMarkets", null),
    geography: get(program, "geography", null),
    dealSponsorGeography: get(program, "dealSponsorGeography", null),
    nonUsMarkets: get(program, "nonUsMarkets", null),
    otherNonUsMarket: program.otherNonUsMarket,
    state: get(program, "state", null),
    otherGeography: get(program, "otherGeography", null),
    msaProperty: get(program, "msaProperty", null),
  };
};
function mapStateToProps(state, ownProps) {
  return {
    action: state.action,
    selectedProgramType: selectorInitialStep(state, "programTypes") || "",
    selectedNonUsMarkets: selectorGeographyStep(state, "nonUsMarkets") || [],
    selectedGeography: selectorGeographyStep(state, "geography") || [],
    initialValues: getInitialValues(ownProps),
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "ProgramCreateGeographyStep",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    enableReinitialize: true,
  })(GeographyStep)
);
