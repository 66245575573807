import React from "react";
import { connect } from "react-redux";
import { Form, Row, Col } from "react-bootstrap";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { get } from "lodash";
import { reduxFormSelect, reduxFormTextarea } from "components/form/ReduxForm";
import { noSpaces } from "utils/validation";
import { createTag, getTags } from "redux/actions";
import Spacer from "components/layout/Spacer";

class NotesAndTags extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tagOptions: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.tags !== this.props.tags) {
      const tagOptions = this.props.tags.map((item) => ({
        value: item._id,
        label: item.tag,
      }));
      this.setState({ tagOptions });
    }
    if (
      prevProps.lastTagCreated !== this.props.lastTagCreated &&
      this.props.lastTagCreated
    ) {
      const newOption = {
        value: this.props.lastTagCreated._id,
        label: this.props.lastTagCreated.tag,
      };
      this.props.change("tags", [...this.props.selectedTags, newOption]);
      this.setState((prevState) => ({
        tagOptions: [...prevState.tagOptions, newOption],
      }));
    }
  }

  submitFn = () => {
    this.props.handleNext(this.props.nextStep);
  };

  handleGetTags = () => {
    getTags();
  };

  handleCreateTag = (tag) => {
    createTag({ tag });
  };

  render() {
    const { action, handleSubmit } = this.props;

    return (
      <fieldset>
        <Form
          className="no-padding no-border mt2"
          onSubmit={handleSubmit(this.submitFn)}
        >
          <Row>
            <Col xs="12">
              <Field
                className="form-control"
                component={reduxFormTextarea}
                validate={[noSpaces]}
                name="notesItemContent"
                type="textarea"
                rows="3"
                placeholder="Add Notes"
                label="Notes"
              />
              <Spacer size={20} />
              <Field
                isMulti
                isCreatable
                showLoading
                closeMenuOnSelect={false}
                className="form-control"
                component={reduxFormSelect}
                name="tags"
                type="select"
                isLoading={
                  action.GET_TAGS.isFetching || action.CREATE_TAG.isFetching
                }
                options={this.state.tagOptions}
                placeholder="Add tags..."
                label="Tags"
                formatCreateLabel={(label) => `Add tag "${label}"`}
                onFocus={this.handleGetTags}
                onCreateOption={this.handleCreateTag}
              />
            </Col>
          </Row>
        </Form>
      </fieldset>
    );
  }
}

const selector = formValueSelector("ProgramCreateNotesAndTags");
const getInitialValues = ({ isUpdate, isClone, selectedProgram: program }) => {
  if (!isUpdate && !isClone) {
    return {};
  }
  return {
    notesItemContent: get(program, "notesItemContent", null),
    tags: get(program, "tags", []).map((item) => ({
      value: item._id,
      label: item.tag,
    })),
  };
};
function mapStateToProps(state, ownProps) {
  return {
    action: state.action,
    tags: state.tag.tags,
    lastTagCreated: state.tag.lastTagCreated,
    selectedTags: selector(state, "tags") || [],
    initialValues: getInitialValues(ownProps),
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "ProgramCreateNotesAndTags",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    enableReinitialize: true,
  })(NotesAndTags)
);
