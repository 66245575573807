import React, { useEffect, useRef } from "react";
import { Form, Spinner } from "react-bootstrap";
import { Editor } from "@tinymce/tinymce-react";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import NumberFormat from "react-number-format";

const reduxFormInput = (props) => {
  const {
    input,
    type,
    placeholder,
    label,
    icon,
    disabled,
    defaultValue,
    max,
    min,
    id,
    onClick,
    onKeyDown,
    style,
    hideLabel,
    subLabel,
    meta: { touched, error },
    wrapperClassName,
    labelClassName = "",
  } = props;

  return (
    <div
      className={
        "field-wrapper " + (icon && "icon-input") + " " + wrapperClassName
      }
      onClick={onClick ? onClick : null}
      onKeyDown={onKeyDown ? onKeyDown : null}
      style={style}
    >
      {icon && icon}
      <Form.Control
        id={id}
        className={touched && error && "input-error"}
        {...input}
        type={type}
        defaultValue={defaultValue ? defaultValue : undefined}
        placeholder={
          touched && error && error === "Required" ? error : placeholder
        }
        disabled={disabled}
        max={max && type === "date" ? "3000-12-31" : undefined}
        min={min}
      />
      {touched && error && error !== "Required" && (
        <span className="error">{error}</span>
      )}
      {!hideLabel && (
        <label>
          <h6
            className={`${
              labelClassName ? labelClassName : "bold text-uppercase"
            }`}
          >
            {label}
          </h6>
        </label>
      )}
      {subLabel && <label>{subLabel}</label>}
    </div>
  );
};

const reduxFormSearch = (props) => {
  const { input, inputValue, type, placeholder, onClick, onKeyDown, style } =
    props;

  return (
    <div
      className="d-inline-block w-100 field-wrapper"
      onClick={onClick ? onClick : null}
      onKeyDown={onKeyDown ? onKeyDown : null}
      style={style}
    >
      <Form.Control
        className="search-input"
        {...input}
        value={inputValue}
        type={type}
        placeholder={placeholder}
        autoComplete="off"
      />
    </div>
  );
};

const ReduxFormTextarea = (props) => {
  const {
    input,
    refName,
    placeholder,
    label,
    rows,
    style,
    disabled,
    meta: { touched, error },
    wrapperClassName,
  } = props;
  const inputRef = useRef(null);

  const calcInputHeight = (value) => {
    const numberOfLineBreaks = (value.match(/\n/g) || []).length + 1;
    // min-height + lines x line-height + padding + border
    const newHeight = (props.rows || 2) * 20 + numberOfLineBreaks * 20 + 12 + 5;
    // No more increasing height If exceeds 350px,
    return newHeight > 350 ? 350 : newHeight;
  };

  const setInputHeight = () => {
    inputRef.current.style.height =
      calcInputHeight(inputRef.current.value) + "px";
  };

  useEffect(() => {
    if (props.adjustHeight) {
      setInputHeight();
      inputRef.current.addEventListener("keyup", setInputHeight);
    }
    return () => {
      if (props.adjustHeight) {
        inputRef.current.removeEventListener("keyup", setInputHeight);
      }
    };
  }, []);
  return (
    <div className={"field-wrapper" + " " + wrapperClassName}>
      <Form.Control
        className={touched && error && "input-error"}
        {...input}
        placeholder={touched && error ? error : placeholder}
        as="textarea"
        rows={rows}
        style={style ? style : undefined}
        ref={refName ? refName : inputRef}
        disabled={disabled}
      />
      {label && label !== "" && (
        <label>
          <h6 className="bold text-uppercase">{label}</h6>
        </label>
      )}
    </div>
  );
};

const reduxFormTextarea = ReduxFormTextarea;
const reduxFormWYSIWYG = (props) => {
  const {
    label,
    placeholder,
    initialValue,
    input,
    customError,
    height = 500,
    toolbar,
    disabled,
    init,
    hideLabel,
  } = props;

  return (
    <>
      <Editor
        disabled={disabled}
        placeholder={placeholder}
        initialValue={initialValue}
        value={input.value && input.value !== "" ? input.value : initialValue}
        init={{
          height,
          placeholder,
          menubar: false,
          plugins: [
            "advlist autolink lists link image charmap print preview anchor",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime media table paste code help wordcount",
          ],
          toolbar:
            typeof toolbar === "string"
              ? toolbar
              : "undo redo | h3 | bold italic underline removeformat | bullist numlist outdent indent | link unlink",
          ...init,
        }}
        apiKey="o1cqrcqrmtsfferoyrscgec3gmrkw4gwy0liv8eibw9n73ni"
        onEditorChange={(value) => input.onChange(value)}
      />
      {customError && customError !== null && (
        <span className="error">{customError}</span>
      )}
      {!hideLabel && (
        <label>
          <h6 className="bold text-uppercase">{label}</h6>
        </label>
      )}
    </>
  );
};

const FormSelect = (props) => {
  const {
    input,
    label,
    options,
    onClick,
    placeholder,
    disabled,
    classes,
    isCreatable,
    isMulti,
    isLoading,
    isClearable,
    formatGroupLabel,
    formatCreateLabel,
    showLoading,
    onCreateOption,
    defaultMenuIsOpen,
    components,
    onMenuClose,
    onClearValue,
    onInputChange,
    classNamePrefix,
    meta: { touched, error },
    wrapperClassName,
    menuPortalTarget,
    styles,
  } = props;

  const SelectComponent = useRef(isCreatable ? CreatableSelect : Select);

  return (
    <div className={"field-wrapper" + " " + wrapperClassName}>
      <SelectComponent.current
        className={
          classes ? classes : "" + (touched && error && "select-input-error")
        }
        {...input}
        value={input.value}
        onMouseDown={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        onChange={(value) => input.onChange(value)}
        onBlur={() => (input.onBlur ? input.onBlur() : null)}
        onClick={() => onClick()}
        noOptionsMessage={() => {
          if (isLoading) {
            return <Spinner animation="border" size="sm" />;
          } else {
            return <p>There are no results.</p>;
          }
        }}
        options={options}
        formatGroupLabel={formatGroupLabel ? formatGroupLabel : ""}
        placeholder={touched && error ? error : placeholder}
        isLoading={showLoading && isLoading}
        isDisabled={disabled}
        isMulti={isMulti ? isMulti : false}
        closeMenuOnSelect={isMulti ? !isMulti : true}
        isClearable={isClearable}
        formatCreateLabel={formatCreateLabel}
        onCreateOption={onCreateOption}
        defaultMenuIsOpen={defaultMenuIsOpen}
        components={components}
        onMenuClose={onMenuClose}
        onClearValue={onClearValue}
        onInputChange={onInputChange}
        classNamePrefix={classNamePrefix}
        menuPortalTarget={menuPortalTarget}
        styles={styles}
      />
      {label && (
        <label>
          <h6 className="bold text-uppercase">{label}</h6>
        </label>
      )}
    </div>
  );
};

const reduxFormSelect = FormSelect;

const reduxFormNumber = (props) => {
  const {
    icon,
    label,
    defaultValue,
    inputRef,
    placeholder,
    thousandSeparator,
    input: { onChange, onBlur, value },
    meta: { touched, error },
    numberFormat,
    numberMask,
    ...other
  } = props;
  let className = "form-control";
  if (touched && error) {
    className = "form-control input-error";
  }
  if (other.displayType === "text") {
    className = "";
  }
  return (
    <div className={icon ? "field-wrapper icon-input" : "field-wrapper"}>
      {icon && icon}
      <NumberFormat
        {...other}
        className={className}
        format={numberFormat ? numberFormat : null}
        mask={numberMask ? numberMask : null}
        getInputRef={inputRef}
        onValueChange={(e) => {
          if (onChange) {
            onChange(e.value);
          }
        }}
        onBlur={() => (onBlur ? onBlur() : null)}
        thousandSeparator={thousandSeparator}
        placeholder={
          touched && error && error === "Required" ? error : placeholder
        }
        defaultValue={defaultValue ? defaultValue : null}
        value={value ? value : defaultValue}
        isNumericString={true}
      />
      {touched && error && error !== "Required" && (
        <span className="error">{error}</span>
      )}
      {label && (
        <label>
          <h6 className="bold text-uppercase">{label}</h6>
        </label>
      )}
    </div>
  );
};

export {
  reduxFormInput,
  reduxFormSearch,
  reduxFormTextarea,
  ReduxFormTextarea,
  reduxFormSelect,
  reduxFormWYSIWYG,
  reduxFormNumber,
};

// defaultValue={
//   touched && error ? (error ? defaultValue : defaultValue) : null
// }
