import React, { useRef } from "react";
import { Button } from "react-bootstrap";
import { faFileAlt } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PhotoItem = (props) => {
  const { name, files, handleUploadFile } = props;
  const inputRef = useRef(null);

  return (
    <div className="PhotoItem d-flex flex-column mb-30 pl-3 pr-3 align-items-end">
      <div
        className={
          files.length > 0
            ? "grid-col-container dark folder tw-mt-[15px]"
            : "grid-col-container"
        }
      >
        <div className="grid-item grid-col-name">
          <div className="grid-row">
            <span className="font-16 bold grid-file-name">{name}</span>
          </div>
        </div>
        {files.length > 0 ? (
          <span className="mb-1 font-18">
            {files.length} <FontAwesomeIcon icon={faFileAlt} />
          </span>
        ) : (
          <span className="mb-1 font-12">Drop files here or</span>
        )}
        <Button
          variant="file-upload"
          className={files.length > 0 ? "dark" : null}
          onClick={() => {
            inputRef.current.click();
          }}
        >
          Select Files
        </Button>
        <input
          ref={inputRef}
          className="drag-drop-file-upload"
          type="file"
          id="photos"
          onChange={(event) => handleUploadFile(event)}
          accept=".jpeg, .jpg, .png"
          multiple
        />
      </div>
    </div>
  );
};

export default PhotoItem;
