import React, { useState } from "react";
import TextArea from "components/form/TextArea";
import { Button, Form, Select, Space, Switch, Upload } from "antd";
import { PaperClipOutlined } from "@ant-design/icons";
import { getInitialForwardMessage, normalizeFile } from "./inboxUtils";
import { useSelector } from "react-redux";
import { sortBy } from "lodash";
import { getDealById, getTeamById } from "redux/actions";
import { useRouteMatch } from "react-router-dom";
import { conversationType } from "./enums";

const allLenders = "All Lenders";
const all = {
  label: allLenders,
  value: allLenders,
};

export const InboxEditorSection = ({
  type,
  isForward,
  forwardMessage,
  senderCompanyId,
  onCancel,
  onSubmit,
}) => {
  const whoami = useSelector((state) => state.auth.whoami);
  const myCompany = useSelector((state) => state.company.myCompany);
  const deal = useSelector((state) => state.deal.deal);

  const [form] = Form.useForm();
  const isPrivate = Form.useWatch("isPrivate", form);

  const match = useRouteMatch();
  const dealId = match.params.id;

  const [message, setMessage] = useState("");

  React.useEffect(() => {
    if (isForward && !deal) {
      getDealById(dealId);
    }
  }, [dealId, isForward, deal]);

  React.useEffect(() => {
    if (deal && whoami.role !== "Lender") {
      getTeamById(deal.teamId._id);
    }
  }, [deal, whoami]);

  React.useEffect(() => {
    if (isForward) {
      const initForwardMessage = getInitialForwardMessage(forwardMessage);
      form.setFieldsValue("message", initForwardMessage);
      setMessage(initForwardMessage);
    }
  }, [isForward, forwardMessage]);

  const companyOptions = React.useMemo(() => {
    let options = [];

    const sortedLenders = sortBy(deal.lendersAssigned, "companyName")
      .filter((lender) => lender.companyId !== myCompany._id)
      .map((lender) => ({
        label: lender.companyName,
        value: lender.companyId,
      }));

    switch (whoami.role) {
      case "Admin": {
        options = [
          {
            label: "Select All Lenders",
            options: [all],
          },
          {
            label: "Companies",
            options: [
              {
                label: `${deal.company?.companyName} (Deal Borrower)`,
                value: deal.company?._id,
              },
              ...sortedLenders,
            ],
          },
        ];
        break;
      }
      case "Lender": {
        options = [
          {
            label: "Borrower",
            options: [
              {
                label: deal.company?.companyName,
                value: deal.company?._id,
              },
            ],
          },
        ];
        break;
      }
      case "Borrower": {
        options = [
          {
            label: "Select All Lenders",
            options: [all],
          },
          {
            label: "Companies",
            options: [...sortedLenders],
          },
        ];
        break;
      }
      default:
        options = [];
        break;
    }

    return {
      options,
      lenders: sortedLenders,
    };
  }, [deal, whoami, myCompany]);

  const questionCompanyOptions = React.useMemo(() => {
    const lenders = deal.lendersAssigned
      .filter((lender) => {
        const isNotSameCompany = lender.companyId !== myCompany._id;
        if (whoami.role !== "Lender") return isNotSameCompany;
        return isNotSameCompany && lender.companyId !== senderCompanyId;
      })
      .map((lender) => ({
        label: lender.companyName,
        value: lender.companyId,
      }));
    const options = [
      {
        label: "Select All Lenders",
        options: [all],
      },
      {
        label: "Lenders",
        options: lenders,
      },
    ];
    return {
      options,
      lenders,
    };
  }, [senderCompanyId, deal, whoami]);

  const onFinish = (values) => {
    onSubmit(values);
  };

  const onFinishFailed = () => {};

  const getValueFromEventReceiverCompanyIdsQuestion = (value) => {
    if (value.includes(allLenders))
      return questionCompanyOptions.lenders.map((e) => e.value);
    return value;
  };

  const getValueFromEventReceiverCompanyIdsMessage = (value) => {
    if (value.includes(allLenders))
      return companyOptions.lenders.map((e) => e.value);
    return value;
  };

  const isMessage = type === conversationType.Message;
  const title = isMessage
    ? isForward
      ? "Forwarding"
      : "Replying"
    : "Leave an answer";

  return (
    <section className="InboxEditorSection d-flex flex-column justify-content-start pt-3 border-top">
      <div className="mb-3">
        <p className="font-16 font-weight-bold">{title}</p>
      </div>

      <Form
        form={form}
        autoComplete="off"
        layout="vertical"
        style={{ border: 0 }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        {!isMessage && (
          <Form.Item
            label=""
            name="isPrivate"
            initialValue={false}
            className="d-flex justify-content-end"
          >
            <div className="align-self-end d-flex align-items-center">
              <p className="font-14 font-weight-bold mr-2">Answer Privately</p>
              <Switch
                size="small"
                defaultChecked={false}
                checked={form.getFieldValue("isPrivate")}
                onChange={() => {
                  form.setFieldValue(
                    "isPrivate",
                    !form.getFieldValue("isPrivate")
                  );
                }}
              />
            </div>
          </Form.Item>
        )}

        {!isMessage && !!isPrivate && (
          <Form.Item
            label=""
            name="receiverCompanyIds"
            rules={[{ required: true, message: "'recipient' is required" }]}
            getValueFromEvent={getValueFromEventReceiverCompanyIdsQuestion}
          >
            <Select
              showSearch
              allowClear
              mode="multiple"
              placeholder="Recipient"
              style={{ borderRadius: 0 }}
              options={questionCompanyOptions.options}
            />
          </Form.Item>
        )}

        {isMessage && isForward && (
          <Form.Item
            label=""
            name="receiverCompanyIds"
            rules={[{ required: true, message: "'recipient' is required" }]}
            getValueFromEvent={getValueFromEventReceiverCompanyIdsMessage}
          >
            <Select
              showSearch
              allowClear
              mode="multiple"
              placeholder="Recipient"
              style={{ borderRadius: 0 }}
              options={companyOptions.options}
            />
          </Form.Item>
        )}

        <Form.Item label="" name="message" rules={[{ required: true }]}>
          <TextArea
            hideLabel
            height={isForward ? 190 * 2 : 190}
            placeholder="Type your message here..."
            input={{
              value: message,
              onChange: (newMessage) => {
                setMessage(newMessage);
                form.setFieldValue("message", newMessage);
              },
            }}
            init={{
              content_style: "body { font-size:14px }",
            }}
          />
        </Form.Item>

        <Form.Item
          name="files"
          valuePropName="fileList"
          getValueFromEvent={normalizeFile}
        >
          <Upload name="logo" multiple beforeUpload={() => false}>
            <Button>
              Attach Files
              <PaperClipOutlined />
            </Button>
          </Upload>
        </Form.Item>

        <Form.Item>
          <Space wrap>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
            <Button onClick={onCancel}>Cancel</Button>
          </Space>
        </Form.Item>
      </Form>
    </section>
  );
};
