import { dispatch } from "config/store";
import { actionTypes } from "./actionTypes";

export const getComms = (params) => {
  const action = {
    type: actionTypes.GET_COMMS,
    config: {
      method: "get",
      url: `/communications?dealId=${params.dealId}&archived=${
        params.archived ? params.archived : false
      }&limit=15&page=${params.page}&order=DESC`,
    },
  };

  dispatch(action);
};

export const getFilteredComms = (params) => {
  const action = {
    type: actionTypes.GET_FILTERED_COMMS,
    config: {
      method: "get",
      url: `/communications?dealId=${params.dealId}&archived=${
        params.archived
      }&type=${params.type}&isAnswered=${params.isAnswered}&limit=15&page=${
        params.page
      }&order=DESC${
        params.isPublic !== undefined ? `&isPublic=${params.isPublic}` : ""
      }`,
    },
  };

  dispatch(action);
};

export const searchComms = (params) => {
  const action = {
    type: actionTypes.SEARCH_COMMS,
    config: {
      method: "get",
      url: `/communications?dealId=${params.dealId}&search=${
        params.search
      }&archived=${params.archived}&type=${params.type}&isAnswered=${
        params.isAnswered
      }&limit=15&page=${params.page}&order=DESC${
        typeof params.isPublic === "undefined"
          ? ""
          : `&isPublic=${params.isPublic}`
      }`,
    },
  };

  dispatch(action);
};

export const getCommById = (conversationId, isArchived) => {
  const action = {
    type: actionTypes.GET_COMM_BY_ID,
    config: {
      method: "get",
      url: `/communications/conversation/get/${conversationId}?archivedNonArchived=false&archived=${isArchived}`,
    },
  };

  dispatch(action);
};

export const createComm = (data) => {
  const action = {
    type: actionTypes.CREATE_COMM,
    config: {
      method: "post",
      url: `/communications/conversation/create`,
      data,
    },
  };

  dispatch(action);
};

export const editComm = (conversationId, data) => {
  const action = {
    type: actionTypes.EDIT_COMM,
    config: {
      method: "put",
      url: `/communications/conversation/update/${conversationId}`,
      data,
    },
  };

  dispatch(action);
};

export const answerComm = (conversationId, data) => {
  const action = {
    type: actionTypes.ANSWER_COMM,
    config: {
      method: "put",
      url: `/communications/conversation/answer/${conversationId}`,
      data,
    },
  };

  dispatch(action);
};

export const sendMessage = (conversationId, data) => {
  const action = {
    type: actionTypes.SEND_MESSAGE,
    config: {
      method: "post",
      url: `/communications/conversation/create/${conversationId}`,
      data,
    },
  };

  dispatch(action);
};

export const editMessage = (conversationId, messageId, data) => {
  const action = {
    type: actionTypes.EDIT_MESSAGE,
    config: {
      method: "put",
      url: `/communications/conversation/edit/${conversationId}/${messageId}`,
      data,
    },
  };

  dispatch(action);
};

export const mergeMessage = (conversationId, data) => {
  const action = {
    type: actionTypes.MERGE_MESSAGE,
    config: {
      method: "put",
      url: `/communications/conversation/merge/${conversationId}`,
      data,
    },
  };

  dispatch(action);
};

export const unmergeMessage = (conversationId, data) => {
  const action = {
    type: actionTypes.UNMERGE_MESSAGE,
    config: {
      method: "put",
      url: `/communications/conversation/unmerge/${conversationId}`,
      data,
    },
  };

  dispatch(action);
};

export const archiveMessage = (conversationId) => {
  const action = {
    type: actionTypes.ARCHIVE_MESSAGE,
    config: {
      method: "put",
      url: `/communications/archive/${conversationId}`,
    },
  };

  dispatch(action);
};

export const assignToComm = (conversationId, data) => {
  const action = {
    type: actionTypes.ASSIGN_TO_COMM,
    config: {
      method: "put",
      url: `/communications/conversation/assign/${conversationId}`,
      data,
    },
  };

  dispatch(action);
};

export const removeFromComm = (conversationId, data) => {
  const action = {
    type: actionTypes.REMOVE_FROM_COMM,
    config: {
      method: "put",
      url: `/communications/conversation/remove/${conversationId}`,
      data,
    },
  };

  dispatch(action);
};

export const setPublicStatus = (conversationId, data) => {
  const action = {
    type: actionTypes.SET_PUBLIC_STATUS,
    config: {
      method: "put",
      url: `/communications/public/${conversationId}`,
      data,
    },
  };

  dispatch(action);
};

export const createGroup = (data) => {
  const action = {
    type: actionTypes.CREATE_GROUP,
    config: {
      method: "post",
      url: `/communications/group/create`,
      data,
    },
  };

  dispatch(action);
};

export const getGroups = (dealId) => {
  const action = {
    type: actionTypes.GET_GROUPS,
    config: {
      method: "get",
      url: `/communications/group?dealId=${dealId}`,
    },
  };

  dispatch(action);
};

export const deleteGroup = (groupId, data) => {
  const action = {
    type: actionTypes.DELETE_GROUP,
    config: {
      method: "delete",
      url: `/communications/group/delete/${groupId}`,
      data,
    },
  };

  dispatch(action);
};

export const editGroup = (groupId, data) => {
  const action = {
    type: actionTypes.EDIT_GROUP,
    config: {
      method: "put",
      url: `/communications/group/edit/${groupId}`,
      data,
    },
  };

  dispatch(action);
};

export const editCommGroups = (commId, data) => {
  const action = {
    type: actionTypes.EDIT_COMM_GROUPS,
    config: {
      method: "put",
      url: `/communications/conversation/updateGroup/${commId}`,
      data,
    },
  };

  dispatch(action);
};
