import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";

// Auth util
import { isAuthenticated } from "utils/authUtils";

// Components
import Layout from "components/layout/Layout";
import ProtectedRoute from "components/routes/ProtectedRoute";
import Sidebar from "components/layout/Sidebar";
import Header from "components/layout/Header";
import { Container, Row, Col } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

// Pages
import Register from "containers/auth/Register";
import SetPassword from "containers/auth/SetPassword";
import ForgotPassword from "containers/auth/ForgotPassword";
import ResetPassword from "containers/auth/ResetPassword";
import ResendVerification from "containers/auth/ResendVerification";
import DeactivateConfirm from "containers/profile/update/DeactivateConfirm";
import NewLogin from "containers/auth/NewLogin";
import { isMobileDevice, reorderArray } from "utils/commonUtils";
import { DragDropContext } from "react-beautiful-dnd";
import {
  reorderDealCards,
  storeSections,
  updateTermsheet,
} from "redux/actions";
import { defaultColumn } from "containers/quotes/constants";
import {
  DealViewContext,
  defaultDealViewOrder,
  getCardOrder,
} from "containers/deals/view/DealViewContext";
import {
  getDndPlaceholderStyleValuesFromStart,
  getDndPlaceholderStyleValuesFromUpdate,
  updatePlaceholderStyle,
} from "utils/dndUtils";

import "antd/dist/reset.css";

class App extends React.Component {
  mainRef = React.createRef();

  get mainElement() {
    return this.mainRef.current;
  }

  componentDidMount() {
    window.isMobile = isMobileDevice();
  }

  saveTermsheetColumns = (columns) => {
    const termsheetId = this.props.termsheet._id;
    if (termsheetId) {
      const updatedCoumns = columns.map(({ clause, ...col }) => ({
        ...col,
        clauseId: clause?._id,
      }));
      updateTermsheet(termsheetId, {
        columns: updatedCoumns,
        doNotRedirect: true,
      });
    }
  };

  toggleDragging = (type) => {
    if (this.mainElement) {
      this.mainElement.classList.toggle(`is-dragging--${type}`);
    }
  };

  trackPlaceholderStart = (startEvent) => {
    const styleValues = getDndPlaceholderStyleValuesFromStart(startEvent);
    updatePlaceholderStyle(styleValues);
  };

  trackPlaceholderUpdate = (updateEvent) => {
    const styleValues = getDndPlaceholderStyleValuesFromUpdate(updateEvent);
    updatePlaceholderStyle(styleValues);
  };

  trackPlaceholderEnd = () => {
    const styleValues = {
      top: 0,
      left: 0,
      height: 0,
      width: 0,
    };
    updatePlaceholderStyle(styleValues);
  };

  onDragStart = (start) => {
    this.toggleDragging(start.type);
    this.trackPlaceholderStart(start);
  };

  onDragUpdate = (update) => {
    this.trackPlaceholderUpdate(update);
  };

  onDragEnd = (result) => {
    const { source, destination, draggableId, type } = result;

    this.toggleDragging(type);
    this.trackPlaceholderEnd();

    // dropped outside the list
    if (!destination) return;
    // dragged from sidebar to borrower view
    if (source.droppableId === "droppableId-sidebar") {
      const { section, option } = JSON.parse(atob(draggableId));
      const newColumn = {
        ...defaultColumn,
        category: section.title,
        sectionName: option.title,
        sectionType: option.sectionType,
        clause: option.clause,
      };
      const columns = [...this.props.columns, newColumn];
      const destinationColumn = columns[destination.index];
      if (destinationColumn.isPrimary) {
        return;
      }
      const newColumns = reorderArray(
        columns,
        columns.length - 1,
        destination.index
      );
      storeSections(newColumns);
      if (newColumns.length <= 1) {
        this.saveTermsheetColumns(newColumns);
      }
      return;
    }
    // dragged from borrower view to borrower view
    if (
      source.droppableId === "droppableId-clauses" &&
      source.droppableId === destination.droppableId
    ) {
      const destinationColumn = this.props.columns[destination.index];
      const sourceColumn = this.props.columns[source.index];
      if (
        destinationColumn.isPrimary &&
        sourceColumn?.clause?.format !== "Open Text, left aligned, no header"
      ) {
        return;
      }
      const newColumns = reorderArray(
        this.props.columns,
        source.index,
        destination.index
      );
      storeSections(newColumns);
      if (newColumns.length > 1) {
        this.saveTermsheetColumns(newColumns);
      }
    }
    // dragged from deals view sections
    if (
      source.droppableId === "droppableId-dealsView" &&
      source.droppableId === destination.droppableId
    ) {
      const cardOrder = reorderArray(
        this.props.cardOrder,
        source.index,
        destination.index
      );
      reorderDealCards(this.props.dealId, {
        dealId: this.props.dealId,
        cardOrder,
      });
    }
  };

  render() {
    const { auth, location } = this.props;
    const isUserAuthenticated = isAuthenticated();
    const isInboxLayout = ["/deal/inbox", "/deal/view"].some((path) =>
      location.pathname.includes(path)
    );

    if (isUserAuthenticated) {
      if (
        location.pathname === "/register" ||
        location.pathname === "/login" ||
        location.pathname === "/"
      ) {
        return <Redirect to={`/dashboard`} />;
      }
    } else if (!isUserAuthenticated && location.pathname === "/") {
      return <Redirect to={`/login`} />;
    }

    return (
      <DragDropContext
        onDragStart={this.onDragStart}
        onDragUpdate={this.onDragUpdate}
        onDragEnd={this.onDragEnd}
      >
        <div id="rbd-draggable" />
        <ToastContainer />
        {location.pathname === "/register" ||
        location.pathname === "/login" ||
        location.pathname.includes("/setpass") ||
        location.pathname === "/forgot-password" ||
        location.pathname.includes("/deactivate") ||
        location.pathname.includes("/reset-password") ||
        location.pathname === "/resend-verification" ? (
          <Switch>
            <Route exact path="/register" component={Register} />
            <Route exact path="/login" component={NewLogin} />
            <Route exact path="/setpass" component={SetPassword} />
            <Route exact path="/deactivate/" component={DeactivateConfirm} />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route exact path="/reset-password" component={ResetPassword} />
            <Route
              exact
              path="/resend-verification"
              component={ResendVerification}
            />
            <ProtectedRoute auth={auth} component={Layout} />
          </Switch>
        ) : (
          <Container fluid className={isInboxLayout ? "full-width" : ""}>
            <Row>
              <Sidebar />
              <Col id="content" className="app-layout-content content-col">
                {/* <Navbar sticky="top" expand="lg" className="app-header-navbar"> */}
                <Header />
                {/* </Navbar> */}
                <main ref={this.mainRef} className="app-main-layout">
                  <DealViewContext.Provider value={this.props.cardOrder}>
                    <Switch>
                      <Route exact path="/register" component={Register} />
                      <Route exact path="/login" component={NewLogin} />
                      <Route exact path="/setpass" component={SetPassword} />
                      <Route
                        exact
                        path="/forgot-password"
                        component={ForgotPassword}
                      />
                      <Route
                        exact
                        path="/reset-password"
                        component={ResetPassword}
                      />
                      <Route
                        exact
                        path="/resend-verification"
                        component={ResendVerification}
                      />
                      <ProtectedRoute auth={auth} component={Layout} />
                    </Switch>
                  </DealViewContext.Provider>
                </main>
              </Col>
            </Row>
          </Container>
        )}
      </DragDropContext>
    );
  }
}

function mapStateToProps(state) {
  const savedOrDefaultCardOrder = state.deal.deal.cardOrder?.length
    ? state.deal.deal.cardOrder
    : defaultDealViewOrder;
  const cardOrder = getCardOrder(
    savedOrDefaultCardOrder,
    state.deal.deal.additionalInfo
  );
  return {
    auth: state.auth,
    termsheet: state.termsheet.termsheet,
    columns: state.termsheet.termsheet.columns || [],
    dealId: state.deal.deal._id,
    cardOrder,
  };
}

export default connect(mapStateToProps)(App);
