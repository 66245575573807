import React from "react";
import { connect } from "react-redux";
import { Button, Form, Row, Col } from "react-bootstrap";
import { required, passwordRule, passwordsMatch } from "utils/validation";
import { Field, reduxForm } from "redux-form";
import { reduxFormInput } from "components/form/ReduxForm";
import { updateProfile } from "redux/actions";
import Spacer from "components/layout/Spacer";
import Loader from "components/ui/Loader";

class AccountSecurity extends React.Component {
  constructor(props) {
    super(props);

    this.submitFn = this.submitFn.bind(this);
  }

  submitFn(data) {
    const { address, city, state, zipCode, country } = this.props.profile;
    const formData = new FormData();

    formData.append("address", address);
    formData.append("city", city);
    formData.append("state", state);
    formData.append("zipCode", zipCode);
    formData.append("country", country);
    formData.append("currentPassword", data.CURRENT_PASSWORD);
    formData.append("password", data.PASSWORD);
    formData.append("accountActivated", true);

    updateProfile(formData);
  }

  render() {
    const { handleSubmit, action } = this.props;
    const isFetching = action.SET_PASSWORD.isFetching;

    return (
      <>
        {isFetching && <Loader />}
        <Spacer size={20} />

        <Row>
          <Col xs="12">
            <h3 className="bold">Reset Password</h3>
            <Spacer size={30} />
          </Col>
        </Row>
        <Form
          className="no-border no-padding"
          onSubmit={handleSubmit(this.submitFn)}
        >
          <Row>
            <Col xs="12" sm="6">
              <Field
                className="form-control"
                component={reduxFormInput}
                validate={[required]}
                name="CURRENT_PASSWORD"
                type="password"
                placeholder="Current Password"
                label="*Current Password"
              />
              <Field
                className="form-control"
                component={reduxFormInput}
                validate={[required, passwordRule]}
                name="PASSWORD"
                type="password"
                placeholder="New Password"
                label="*New Password"
              />
              <Spacer size={20} />
              <Field
                className="form-control"
                component={reduxFormInput}
                validate={[required, passwordRule, passwordsMatch]}
                name="CONFIRM_PASSWORD"
                type="password"
                placeholder="Confirm New Password"
                label="*Confirm Password"
              />
              <Spacer size={50} />
              <div style={{ textAlign: "center" }}>
                <Button type="submit" variant="primary">
                  Submit Password
                </Button>
              </div>
            </Col>
          </Row>
        </Form>

        <Spacer size={50} />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    action: state.action,
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "accountSecurity",
    enableReinitialize: true,
  })(AccountSecurity)
);
