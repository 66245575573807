import React from "react";

export const DealViewContext = React.createContext();

export const dealViewIds = {
  PropertyDetails: "PropertyDetails",
  ExecutiveSummary: "ExecutiveSummary",
  PropertyDescription: "PropertyDescription",
  SourcesAndUses: "SourcesAndUses",
  SponsorInformation: "SponsorInformation",
  ManagerInformation: "ManagerInformation",
  Disclosures: "Disclosures",
  AddSection: "AddSection",
};

export const defaultDealViewOrder = Object.values(dealViewIds).map(
  (card) => card
);

export const getCardOrder = (savedCardOrder = [], additionalInfo = []) => {
  const otherAddedSections = additionalInfo
    .filter((item) => !savedCardOrder.some((s) => s === item._id))
    .map((item) => item._id);
  const result = [...savedCardOrder, ...otherAddedSections];
  return result;
};
