import React, { useState, useCallback } from 'react'
import ReactCrop from 'react-easy-crop'
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

import getCroppedImg from './cropImage'
import Spacer from 'components/layout/Spacer';

const Cropper = (props) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [rotation, setRotation] = useState(0)
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const onSave = async () => {
        const finalImage = await getCroppedImg(
            props.src,
            croppedAreaPixels,
            rotation
        )
        props.onSave(finalImage);
    };
    if (!props.src) {
        return null;
    }
    return (
        <div>
            <div style={{ height: 360, width: '100%', position: 'relative' }}>
                <ReactCrop
                    image={props.src}
                    crop={crop}
                    rotation={rotation}
                    zoom={zoom}
                    aspect={1 / 1}
                    onCropChange={setCrop}
                    onRotationChange={setRotation}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                    minZoom={0.5}
                    restrictPosition={false}
                    zoomSpeed={0.5}
                />
            </div>
            <Spacer size={20} />
            <div style={{ textAlign: 'center' }}>
                <small>
                    <i> <FontAwesomeIcon
                        icon={faInfoCircle}
                    />Scroll Up/Down on hover the image to Zoom In/Out. Hold and Drag it to keep in the desired position</i>
                </small>
                <Spacer size={20} />
                <Button type="submit" variant="light" onClick={props.onCancel}>
                    Cancel
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                    type="submit"
                    variant="secondary"
                    onClick={props.onChangePhoto}
                >
                    Change Photo
                    </Button>
                    &nbsp;&nbsp;
                    <Button type="submit" variant="primary" onClick={onSave}>
                    Save
                    </Button>
            </div>
        </div>
    )
}
export default Cropper;