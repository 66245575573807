import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBellSlash } from "@fortawesome/free-solid-svg-icons";

const TeamLinkItem = ({ name, id, showIcon }) => {
  return (
    <Link
      to={"/team/view/" + id}
      className="team-link-item d-flex justify-content-center align-items-center p-0"
    >
      <span className="link-text">{name}</span>
      {showIcon && (
        <span
          className="d-flex justify-content-center align-items-center rounded-circle border border-dark"
          style={{
            height: 24,
            width: 24,
          }}
        >
          <FontAwesomeIcon
            style={{ width: 14, height: 14 }}
            className="text-danger"
            icon={faBellSlash}
          />
        </span>
      )}
    </Link>
  );
};

export default TeamLinkItem;
