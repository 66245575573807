import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  OverlayTrigger,
  Tooltip,
  Button,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import {
  getProgramsById,
  deleteProgram,
  getTags,
  createTag,
  updatePartialProgram,
} from "redux/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDollarSign,
  faPercent,
  faTrash,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import { faClone } from "@fortawesome/free-regular-svg-icons";
import NumberFormat from "react-number-format";
import Spacer from "components/layout/Spacer";
import PopupPrompt from "components/ui/PopupPrompt";
import Socket from "utils/Socket";
import Loader from "components/ui/Loader";
import ProgramPrompt from "../ProgramPrompt";
import { Field, formValueSelector, reduxForm } from "redux-form";
import { reduxFormSelect, reduxFormTextarea } from "components/form/ReduxForm";
import { get } from "lodash";
import { noSpaces } from "utils/validation";
import NotesTimeline from "./NotesTimeline";
import api from "utils/api";

class Program extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      promptAction: "",
      isCompanyAdmin: false,
      showPrompt: false,
      editNotesMode: false,
      editTagsMode: false,
      tagOptions: [],
      isCloning: false,
    };
  }

  componentDidMount() {
    this.getProgram();
    // We should wait until buckets created after program creates
    // TODO: Change logic to listen for entirely a program with buckets creation done
    // const { match } = this.props;
    // this.subscriptionId = Socket.subscribe(
    //   `/program/${match.params.id}`,
    //   this.getProgram
    // );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.tags !== this.props.tags) {
      const tagOptions = this.props.tags.map((item) => ({
        value: item._id,
        label: item.tag,
      }));
      this.setState({ tagOptions });
    }
    if (
      prevProps.lastTagCreated !== this.props.lastTagCreated &&
      this.props.lastTagCreated
    ) {
      const newOption = {
        value: this.props.lastTagCreated._id,
        label: this.props.lastTagCreated.tag,
      };
      this.props.change("tags", [...this.props.selectedTags, newOption]);
      this.setState((prevState) => ({
        tagOptions: [...prevState.tagOptions, newOption],
      }));
    }
  }

  getProgram = () => {
    const { match } = this.props;

    getProgramsById(match.params.id);
  };

  handleShowPrompt = (showPrompt) => {
    this.setState({ showPrompt });
  };

  componentWillUnmount() {
    Socket.unsubscribe(this.subscriptionId);
  }

  handlePromptClose = () => {
    this.setState({ promptAction: "" });
    this.handleShowPrompt(false);
  };

  handleGetTags = () => {
    getTags();
  };

  handleCreateTag = (tag) => {
    createTag({ tag });
  };

  handleUpdateProgram = () => {
    const { selectedTags, notesItemContent, program, whoami } = this.props;
    const tags = (selectedTags || []).map((item) => item.value);

    let data = {
      tags,
    };

    if (notesItemContent) {
      const origNotes = program.notes || [];
      data.notes = [
        ...origNotes,
        {
          content: notesItemContent,
          createdBy: whoami._id,
        },
      ];
    }

    this.props.change("notesItemContent", "");
    updatePartialProgram(program._id, data);
  };

  handleCreateProgram = (data) =>
    api.request({ url: "/programs", method: "post", data });

  handleCreateBucket = (data) =>
    api.request({ url: `/buckets`, method: "post", data });

  cloneProgram = () => {
    const newProgram = { ...this.props.program };
    delete newProgram._id;
    delete newProgram.buckets;
    newProgram.programName = `${newProgram.programName} Clone`;
    this.setState({ isCloning: true });
    this.handleCreateProgram(newProgram)
      .then((success) => {
        const { buckets } = this.props.program;
        const allPs = buckets.map((bucket) =>
          this.handleCreateBucket({ ...bucket, programId: success.data._id })
        );
        return Promise.all(allPs).then(() => {
          window.location.pathname = `/program/view/${success.data._id}`;
        });
      })
      .finally(() => {
        this.setState({ isCloning: false });
      });
  };

  render() {
    const { program, myCompany, whoami, action } = this.props;
    if (action.GET_PROGRAMS_BY_ID.isFetching) {
      return <Loader />;
    }
    const {
      programName,
      primaryContactName,
      primaryContactEmail,
      loanMinimum,
      loanMaximum,
      programTypes,
      otherProgramType,
      lenderType,
      otherLenderType,
      servicing,
      otherServicing,
      fixedFloating,
      recourseOptions,
      fixedRateLoanPrepay,
      floatingRateLoanPrepay,
      apartmentTypesList,
      officeTypesList,
      retailTypeList,
      industrialTypeList,
      hotelTypeList,
      seniorLivingList,
      otherPropertyDealTypesWeLike,
      otherOtherPropertyDealTypesWeLike,
      specialAgencyLicensesPrograms,
      otherSpecialAgencyLicensesPrograms,
      investmentPeriod,
      weCanInvestBehind,
      otherWeCanInvestBehind,
      transactionType,
      investmentStructure,
      minGPCoInvest,
      dealSize,
      typicalRates,
      targetIRR,
      targetCashOnCash,
      rateStructure,
      maxLTV,
      competitiveLTV,
      minimumRate,
      maximumRate,
      typicalIndex,
      otherTypicalIndex,
      minimumSpread,
      maximumSpread,
      geography,
      otherGeography,
      state,
      msaProperty,
      otherCarrots,
      otherOtherCarrots,
      websiteLink,
      otherRateProtectionOptions,
      typicalTermMin,
      typicalTermMax,
      typicalOriginationFeeMin,
      typicalOriginationFeeMax,
      typicalExitFeeMin,
      typicalExitFeeMax,
      sponsorQualityImportance,
      openForSponsorTypes,
      potentialRedFlags,
      considerTicDstIf,
      viewMarkets,
      dealSponsorGeography,
      nonUsMarkets,
      otherNonUsMarket,
      loanStructureComplexities,
      otherLoanStructureComplexities,
      otherComplexities,
      otherOtherComplexities,
      authoringContent,
      canShareContent,
      moreInfo,
      competitiveDeals,
      workWithCorrespondents,
      canPayBrokerPremium,
      premiumImpact,
      premiumType,
      otherPremiumType,
      primaryServingOffered,
    } = program;

    return (
      <>
        <div className="text-right">
          {myCompany &&
          program &&
          whoami &&
          myCompany._id === program.companyId ? (
            <>
              {this.state.promptAction === "Delete" && (
                <PopupPrompt
                  promptTitle={"Delete Program"}
                  promptBody={
                    "By clicking confirm, you agree to delete this program. This action cannot be undone."
                  }
                  show={this.state.showPrompt}
                  onCancel={this.handlePromptClose}
                  onConfirm={() => {
                    deleteProgram(program._id);
                    this.handlePromptClose();
                  }}
                  withFooter
                  withCancel
                />
              )}
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id={`tooltip-bottom`}>Delete</Tooltip>}
              >
                <Button
                  className="mr-2 pl-3 pt-2 pr-3 pb-2"
                  variant="danger"
                  onClick={() => {
                    this.setState({ promptAction: "Delete" });
                    this.handleShowPrompt(true);
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </OverlayTrigger>
            </>
          ) : (
            <></>
          )}
          {myCompany &&
          program &&
          whoami &&
          myCompany._id === program.companyId ? (
            <>
              {this.state.promptAction === "Clone" && (
                <PopupPrompt
                  promptTitle={"Clone Program"}
                  promptBody={`By clicking confirm, you agree to clone this program with name '${programName} Clone'. This action cannot be undone.`}
                  show={this.state.showPrompt}
                  onCancel={this.handlePromptClose}
                  onConfirm={() => {
                    this.cloneProgram();
                    // this.handlePromptClose();
                  }}
                  withFooter
                  withCancel
                  showLoader
                  isLoading={this.state.isCloning}
                />
              )}
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id={`tooltip-bottom`}>Clone</Tooltip>}
              >
                <Button
                  className="mr-2 pl-3 pt-2 pr-3 pb-2"
                  onClick={() => {
                    this.setState({ promptAction: "Clone" });
                    this.handleShowPrompt(true);
                  }}
                >
                  <FontAwesomeIcon icon={faClone} />
                </Button>
              </OverlayTrigger>
            </>
          ) : (
            <></>
          )}
          {myCompany &&
          program &&
          whoami &&
          (myCompany._id === program.companyId || whoami.role === "Admin") ? (
            <>
              {this.state.promptAction === "Edit" && (
                <ProgramPrompt
                  isUpdate
                  key={program._id}
                  isCompanyAdmin={myCompany.companyAdmin === whoami._id}
                  companyId={program.companyId}
                  onSubmit={() => {
                    this.getProgram();
                    this.handlePromptClose();
                  }}
                  show={this.state.showPrompt}
                  onCancel={this.handlePromptClose}
                />
              )}
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id={`tooltip-bottom`}>Edit</Tooltip>}
              >
                <Button
                  className="pl-3 pt-2 pr-3 pb-2"
                  onClick={() => {
                    this.setState({ promptAction: "Edit" });
                    this.handleShowPrompt(true);
                  }}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </Button>
              </OverlayTrigger>
            </>
          ) : (
            <></>
          )}
        </div>
        <Container className="program-view" fluid>
          <Row>
            <Col xs="12">
              <h3 className="bold">{programName}</h3>
              <Spacer size={5} />
              <label className="bold font-12 color-medium-gray">
                Program Name
              </label>
              <Spacer size={20} />
            </Col>
            <Col className="border-bottom" xs="12"></Col>
            <Col xs="12">
              <Spacer size={20} />
            </Col>
            {/* Primary Contact */}
            <Col xs="12" sm="6">
              <div>
                {primaryContactName && (
                  <h4>{JSON.parse(primaryContactName).label}</h4>
                )}
                <label className="bold font-12 color-medium-gray">
                  Primary Contact
                </label>
                <Spacer size={20} />
              </div>
              <div>
                {primaryContactName && (
                  <h4>{JSON.parse(primaryContactEmail).email}</h4>
                )}
                <label className="bold font-12 color-medium-gray">
                  Primary Contact Email
                </label>
                <Spacer size={20} />
              </div>
              <Row noGutters={true}>
                <Col xs="6">
                  {loanMinimum && (
                    <>
                      <h4>
                        <FontAwesomeIcon icon={faDollarSign} />{" "}
                        <NumberFormat
                          value={loanMinimum}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                      </h4>
                      <label className="bold font-12 color-medium-gray">
                        Loan Minimum
                      </label>
                      <Spacer size={20} />
                    </>
                  )}
                </Col>
                <Col xs="6">
                  {loanMaximum && (
                    <>
                      <h4>
                        <FontAwesomeIcon icon={faDollarSign} />{" "}
                        <NumberFormat
                          value={loanMaximum}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                      </h4>
                      <label className="bold font-12 color-medium-gray">
                        Loan Maximum
                      </label>
                      <Spacer size={20} />
                    </>
                  )}
                </Col>
              </Row>
              {lenderType && (
                <>
                  {lenderType === "Other" && otherLenderType ? (
                    <>
                      <div className="has-other">
                        <h4>{lenderType}</h4>
                        <label className="bold font-12 color-medium-gray">
                          Lender Type
                        </label>
                      </div>
                      {otherLenderType && (
                        <>
                          <div className="is-other">
                            <Spacer size={5} />
                            <p>{otherLenderType}</p>
                            <label className="bold font-12 color-medium-gray">
                              Other (Lender Type)
                            </label>
                          </div>
                          <Spacer size={20} />
                        </>
                      )}
                    </>
                  ) : (
                    <div>
                      <h4>{lenderType}</h4>
                      <label className="bold font-12 color-medium-gray">
                        Lender Type
                      </label>
                      <Spacer size={20} />
                    </div>
                  )}
                </>
              )}
            </Col>
            {/* Program Type */}
            <Col xs="12" sm="6">
              {programTypes &&
                (programTypes === "Fannie Mae" ||
                  programTypes === "Freddie Mac" ||
                  programTypes === "Perm" ||
                  programTypes === "Bridge" ||
                  programTypes === "CMBS" ||
                  programTypes === "Hard Money" ||
                  programTypes === "Construction" ||
                  programTypes === "HUD" ||
                  programTypes === "CTL" ||
                  programTypes === "Construction / Perm" ||
                  programTypes === "Credit Facility" ||
                  programTypes === "Stretch Senior" ||
                  programTypes === "Bridge to Perm" ||
                  programTypes === "USDA" ||
                  programTypes === "SBA 7a" ||
                  programTypes === "SBA 504" ||
                  programTypes === "PACE" ||
                  programTypes === "Loan Guaranty" ||
                  programTypes === "Other") && (
                  <>
                    {programTypes === "Other" && otherProgramType ? (
                      <>
                        <div className="has-other">
                          <h4>{programTypes}</h4>
                          <label className="bold font-12 color-medium-gray">
                            Program Type
                          </label>
                        </div>
                        {otherProgramType && (
                          <>
                            <div className="is-other">
                              <Spacer size={5} />
                              <p>{otherProgramType}</p>
                              <label className="bold font-12 color-medium-gray">
                                Other (Program Type)
                              </label>
                            </div>
                            <Spacer size={20} />
                          </>
                        )}
                      </>
                    ) : (
                      <div>
                        <h4>{programTypes}</h4>
                        <label className="bold font-12 color-medium-gray">
                          Program Type
                        </label>
                        <Spacer size={20} />
                      </div>
                    )}
                    {servicing && (
                      <>
                        {servicing === "Other - Please Describe" &&
                        otherServicing ? (
                          <>
                            <div className="has-other">
                              <h4>{servicing}</h4>
                              <label className="bold font-12 color-medium-gray">
                                Loan Servicing
                              </label>
                            </div>
                            {otherServicing && (
                              <>
                                <div className="is-other">
                                  <Spacer size={5} />
                                  <p>{otherServicing}</p>
                                  <label className="bold font-12 color-medium-gray">
                                    Other (Loan Servicing)
                                  </label>
                                </div>
                                <Spacer size={20} />
                              </>
                            )}
                          </>
                        ) : (
                          <div>
                            <h4>{servicing}</h4>
                            <label className="bold font-12 color-medium-gray">
                              Loan Servicing
                            </label>
                            <Spacer size={20} />
                          </div>
                        )}
                      </>
                    )}
                    {recourseOptions && (
                      <div>
                        {recourseOptions.map((obj, idx) => (
                          <h4 key={idx}>{obj.label}</h4>
                        ))}
                        <label className="bold font-12 color-medium-gray">
                          Recourse vs. Non-Recourse
                        </label>
                        <Spacer size={20} />
                      </div>
                    )}
                  </>
                )}

              {programTypes &&
                (programTypes === "Perm" ||
                  programTypes === "Bridge" ||
                  programTypes === "CMBS" ||
                  programTypes === "Hard Money" ||
                  programTypes === "Construction" ||
                  programTypes === "CTL" ||
                  programTypes === "Construction / Perm" ||
                  programTypes === "Credit Facility" ||
                  programTypes === "Stretch Senior" ||
                  programTypes === "Bridge to Perm") && (
                  <>
                    {/* {fixedFloating && (
                      <div>
                        {fixedFloating.map((obj, idx) => (
                          <h4 key={idx}>{obj.label}</h4>
                        ))}
                        <label className="bold font-12 color-medium-gray">
                          Fixed or Floating
                        </label>
                        <Spacer size={20} />
                      </div>
                    )} */}
                    {/* {recourseOptions && (
                      <div>
                        {recourseOptions.map((obj, idx) => (
                          <h4 key={idx}>{obj.label}</h4>
                        ))}
                        <label className="bold font-12 color-medium-gray">
                          Recourse vs. Non-Recourse
                        </label>
                        <Spacer size={20} />
                      </div>
                    )}
                    {fixedRateLoanPrepay && (
                      <div>
                        {fixedRateLoanPrepay.map((obj, idx) => (
                          <h4 key={idx}>{obj.label}</h4>
                        ))}
                        <label className="bold font-12 color-medium-gray">
                          Fixed Rate Loan Prepay
                        </label>
                        <Spacer size={20} />
                      </div>
                    )}
                    {floatingRateLoanPrepay && (
                      <div>
                        {floatingRateLoanPrepay.map((obj, idx) => (
                          <h4 key={idx}>{obj.label}</h4>
                        ))}
                        <label className="bold font-12 color-medium-gray">
                          Floating Rate Loan Prepay
                        </label>
                        <Spacer size={20} />
                      </div>
                    )} */}
                  </>
                )}
            </Col>
            {/* Spacer */}
            {programTypes && (
              <>
                <Col xs="12" className="border-bottom"></Col>
                <Col xs="12">
                  <Spacer size={20} />
                </Col>
              </>
            )}
            {/* Fixed or Floating */}
            <Col xs="12" sm="6">
              {programTypes &&
                (programTypes === "Perm" ||
                  programTypes === "Bridge" ||
                  programTypes === "CMBS" ||
                  programTypes === "Hard Money" ||
                  programTypes === "Construction" ||
                  programTypes === "CTL" ||
                  programTypes === "Construction / Perm" ||
                  programTypes === "Credit Facility" ||
                  programTypes === "Stretch Senior" ||
                  programTypes === "Bridge to Perm") && (
                  <>
                    {fixedFloating && (
                      <div>
                        {fixedFloating.map((obj, idx) => (
                          <h4 key={idx}>{obj.label}</h4>
                        ))}
                        <label className="bold font-12 color-medium-gray">
                          Fixed or Floating
                        </label>
                        <Spacer size={20} />
                      </div>
                    )}
                    {otherRateProtectionOptions && (
                      <div>
                        {otherRateProtectionOptions.map((obj, idx) => (
                          <h4 key={idx}>{obj.label}</h4>
                        ))}
                        <label className="bold font-12 color-medium-gray">
                          Other Rate Protection Options
                        </label>
                        <Spacer size={20} />
                      </div>
                    )}
                    {fixedRateLoanPrepay && (
                      <div>
                        {fixedRateLoanPrepay.map((obj, idx) => (
                          <h4 key={idx}>{obj.label}</h4>
                        ))}
                        <label className="bold font-12 color-medium-gray">
                          Fixed Rate Loan Prepay
                        </label>
                        <Spacer size={20} />
                      </div>
                    )}
                  </>
                )}
            </Col>
            {/* Loan Term - Range */}
            <Col xs="12" sm="6">
              {programTypes &&
                (programTypes === "Perm" ||
                  programTypes === "Bridge" ||
                  programTypes === "CMBS" ||
                  programTypes === "Hard Money" ||
                  programTypes === "Construction" ||
                  programTypes === "CTL" ||
                  programTypes === "Construction / Perm" ||
                  programTypes === "Credit Facility" ||
                  programTypes === "Stretch Senior" ||
                  programTypes === "Bridge to Perm") && (
                  <>
                    {fixedFloating && (
                      <div>
                        <h4>
                          {typicalTermMin} - ${typicalTermMax}
                        </h4>
                        <label className="bold font-12 color-medium-gray">
                          Loan Term - Range
                        </label>
                        <Spacer size={20} />
                      </div>
                    )}
                    {typicalOriginationFeeMin && (
                      <div>
                        <h4>
                          {typicalOriginationFeeMin}% - $
                          {typicalOriginationFeeMax}%
                        </h4>
                        <label className="bold font-12 color-medium-gray">
                          Origination Fee - Range
                        </label>
                        <Spacer size={20} />
                      </div>
                    )}
                    {typicalExitFeeMin && (
                      <div>
                        <h4>
                          {typicalExitFeeMin}% - ${typicalExitFeeMax}%
                        </h4>
                        <label className="bold font-12 color-medium-gray">
                          Exit Fee - Range
                        </label>
                        <Spacer size={20} />
                      </div>
                    )}
                  </>
                )}
            </Col>
            {/* Spacer */}
            {programTypes && (
              <>
                <Col xs="12" className="border-bottom"></Col>
                <Col xs="12">
                  <Spacer size={20} />
                </Col>
              </>
            )}
            {/* How Important is sponsor quality? */}
            <Col xs="12" sm="6">
              {programTypes &&
                (programTypes === "Perm" ||
                  programTypes === "Bridge" ||
                  programTypes === "CMBS" ||
                  programTypes === "Hard Money" ||
                  programTypes === "Construction" ||
                  programTypes === "CTL" ||
                  programTypes === "Construction / Perm" ||
                  programTypes === "Credit Facility" ||
                  programTypes === "Stretch Senior" ||
                  programTypes === "Bridge to Perm") && (
                  <>
                    {sponsorQualityImportance && (
                      <div>
                        {sponsorQualityImportance.map((obj, idx) => (
                          <h4 key={idx}>{obj.label}</h4>
                        ))}
                        <label className="bold font-12 color-medium-gray">
                          How Important is sponsor quality?
                        </label>
                        <Spacer size={20} />
                      </div>
                    )}
                    {openForSponsorTypes && (
                      <div>
                        {openForSponsorTypes.map((obj, idx) => (
                          <h4 key={idx}>{obj.label}</h4>
                        ))}
                        <label className="bold font-12 color-medium-gray">
                          Our Typical Sponsors Fall Into the following
                          Categories
                        </label>
                        <Spacer size={20} />
                      </div>
                    )}
                  </>
                )}
            </Col>
            {/* The following red flags are not necessarily deal killers for us */}
            <Col xs="12" sm="6">
              {programTypes &&
                (programTypes === "Perm" ||
                  programTypes === "Bridge" ||
                  programTypes === "CMBS" ||
                  programTypes === "Hard Money" ||
                  programTypes === "Construction" ||
                  programTypes === "CTL" ||
                  programTypes === "Construction / Perm" ||
                  programTypes === "Credit Facility" ||
                  programTypes === "Stretch Senior" ||
                  programTypes === "Bridge to Perm") && (
                  <>
                    {potentialRedFlags && (
                      <div>
                        {potentialRedFlags.map((obj, idx) => (
                          <h4 key={idx}>{obj.label}</h4>
                        ))}
                        <label className="bold font-12 color-medium-gray">
                          The following red flags are not necessarily deal
                          killers for us
                        </label>
                        <Spacer size={20} />
                      </div>
                    )}
                    {considerTicDstIf && (
                      <div>
                        {considerTicDstIf.map((obj, idx) => (
                          <h4 key={idx}>{obj.label}</h4>
                        ))}
                        <label className="bold font-12 color-medium-gray">
                          Will Consider a TIC or DST Transaction If
                        </label>
                        <Spacer size={20} />
                      </div>
                    )}
                  </>
                )}
            </Col>
            {/* Spacer */}
            {(sponsorQualityImportance ||
              openForSponsorTypes ||
              considerTicDstIf ||
              potentialRedFlags) && (
              <>
                <Col xs="12" className="border-bottom"></Col>
                <Col xs="12">
                  <Spacer size={20} />
                </Col>
              </>
            )}
            {/* How we view markets? */}
            <Col xs="12" sm="6">
              {viewMarkets && (
                <div>
                  {viewMarkets.map((obj, idx) => (
                    <h4 key={idx}>{obj.label}</h4>
                  ))}
                  <label className="bold font-12 color-medium-gray">
                    How we view markets?
                  </label>
                  <Spacer size={20} />
                </div>
              )}
              {geography && (
                <div>
                  {geography.map((obj, idx) => (
                    <h4 key={idx}>{obj.label}</h4>
                  ))}
                  <label className="bold font-12 color-medium-gray">
                    Geography
                  </label>
                  <Spacer size={20} />
                </div>
              )}
              <Row noGutters={true}>
                <Col xs="6">
                  {state && (
                    <>
                      {state.map((obj, idx) => (
                        <h4 key={idx}>{obj.label}</h4>
                      ))}
                      <label className="bold font-12 color-medium-gray">
                        States
                      </label>
                      <Spacer size={20} />
                    </>
                  )}
                </Col>
                <Col xs="6">
                  {msaProperty && (
                    <>
                      {msaProperty.map((obj, idx) => (
                        <h4 key={idx}>{obj.label}</h4>
                      ))}
                      <label className="bold font-12 color-medium-gray">
                        MSA
                      </label>
                      <Spacer size={20} />
                    </>
                  )}
                </Col>
              </Row>
              {geography?.some((item) => item.label === "Or Describe Areas") &&
                otherGeography && (
                  <>
                    <p>{otherGeography}</p>
                    <label className="bold font-12 color-medium-gray">
                      Other (Geography)
                    </label>
                    <Spacer size={20} />
                  </>
                )}
            </Col>
            {/* Deal Sponsor Geography */}
            <Col xs="12" sm="6">
              {dealSponsorGeography && (
                <div>
                  {dealSponsorGeography.map((obj, idx) => (
                    <h4 key={idx}>{obj.label}</h4>
                  ))}
                  <label className="bold font-12 color-medium-gray">
                    Deal Sponsor Geography
                  </label>
                  <Spacer size={20} />
                </div>
              )}
              {nonUsMarkets && (
                <div>
                  {nonUsMarkets.map((obj, idx) => (
                    <h4 key={idx}>{obj.label}</h4>
                  ))}
                  <label className="bold font-12 color-medium-gray">
                    We can also lend in the following markets (not U.S. States)
                  </label>
                  <Spacer size={20} />
                </div>
              )}

              {nonUsMarkets?.some((item) => item.label === "Other") &&
                otherNonUsMarket && (
                  <>
                    <p>{otherNonUsMarket}</p>
                    <label className="bold font-12 color-medium-gray">
                      Other (Non U.S. Market)
                    </label>
                    <Spacer size={20} />
                  </>
                )}
            </Col>
            {/* Spacer */}
            {(viewMarkets ||
              geography ||
              dealSponsorGeography ||
              nonUsMarkets) && (
              <>
                <Col xs="12" className="border-bottom"></Col>
                <Col xs="12">
                  <Spacer size={20} />
                </Col>
              </>
            )}
            {/* Borrower Transaction Incentives */}
            <Col xs="12" sm="6">
              {otherCarrots && (
                <div>
                  {otherCarrots.map((obj, idx) => (
                    <h4 key={idx}>{obj.label}</h4>
                  ))}
                  <label className="bold font-12 color-medium-gray">
                    Borrower Transaction Incentives
                  </label>
                  <Spacer size={20} />
                </div>
              )}
              {otherCarrots?.some((item) => item.label === "Other") &&
                otherOtherCarrots && (
                  <>
                    <p>{otherOtherCarrots}</p>
                    <label className="bold font-12 color-medium-gray">
                      Other (Transaction Incentives)
                    </label>
                    <Spacer size={20} />
                  </>
                )}
              {loanStructureComplexities && (
                <div>
                  {loanStructureComplexities.map((obj, idx) => (
                    <h4 key={idx}>{obj.label}</h4>
                  ))}
                  <label className="bold font-12 color-medium-gray">
                    Loan Structure Complexities we can work with
                  </label>
                  <Spacer size={20} />
                </div>
              )}
              {loanStructureComplexities?.some(
                (item) => item.label === "Other"
              ) &&
                otherLoanStructureComplexities && (
                  <>
                    <p>{otherLoanStructureComplexities}</p>
                    <label className="bold font-12 color-medium-gray">
                      Other Loan (Structure Complexities)
                    </label>
                    <Spacer size={20} />
                  </>
                )}
              {otherComplexities && (
                <div>
                  {otherComplexities.map((obj, idx) => (
                    <h4 key={idx}>{obj.label}</h4>
                  ))}
                  <label className="bold font-12 color-medium-gray">
                    Other Complexities
                  </label>
                  <Spacer size={20} />
                </div>
              )}
              {otherComplexities?.some((item) => item.label === "Other") &&
                otherOtherComplexities && (
                  <>
                    <p>{otherOtherComplexities}</p>
                    <label className="bold font-12 color-medium-gray">
                      Other (Complexities)
                    </label>
                    <Spacer size={20} />
                  </>
                )}
            </Col>
            {/* Would you consider authoring content for our blog? */}
            <Col xs="12" sm="6">
              {authoringContent && (
                <>
                  <p>{authoringContent}</p>
                  <label className="bold font-12 color-medium-gray">
                    Would you consider authoring content for our blog?
                  </label>
                  <Spacer size={20} />
                </>
              )}
              {canShareContent && (
                <>
                  <p>{canShareContent}</p>
                  <label className="bold font-12 color-medium-gray">
                    Would you like us to share content for your website?
                  </label>
                  <Spacer size={20} />
                </>
              )}
              {moreInfo && (
                <>
                  <p>{moreInfo}</p>
                  <label className="bold font-12 color-medium-gray">
                    What else should we know about this program?
                  </label>
                  <Spacer size={20} />
                </>
              )}
              {competitiveDeals && (
                <>
                  <p>{competitiveDeals}</p>
                  <label className="bold font-12 color-medium-gray">
                    What types of deals are you particularly competitive on with
                    this program?
                  </label>
                  <Spacer size={20} />
                </>
              )}
            </Col>

            {/* Spacer */}
            {(otherCarrots ||
              loanStructureComplexities ||
              otherComplexities ||
              authoringContent ||
              canShareContent ||
              moreInfo ||
              competitiveDeals) && (
              <>
                <Col xs="12" className="border-bottom"></Col>
                <Col xs="12">
                  <Spacer size={20} />
                </Col>
              </>
            )}

            {/* We only work with correspondents? */}
            <Col xs="12" sm="6">
              {workWithCorrespondents && (
                <>
                  <p>{workWithCorrespondents}</p>
                  <label className="bold font-12 color-medium-gray">
                    We only work with correspondents?
                  </label>
                  <Spacer size={20} />
                </>
              )}
              {canPayBrokerPremium && (
                <>
                  <p>{canPayBrokerPremium}</p>
                  <label className="bold font-12 color-medium-gray">
                    Can You Pay a Broker Premium?
                  </label>
                  <Spacer size={20} />
                </>
              )}
              {premiumImpact && (
                <>
                  <p>{premiumImpact}</p>
                  <label className="bold font-12 color-medium-gray">
                    Premium - Impact on Borrower
                  </label>
                  <Spacer size={20} />
                </>
              )}
            </Col>
            {/* Premium Type */}
            <Col xs="12" sm="6">
              {premiumType && (
                <>
                  <p>{premiumType}</p>
                  <label className="bold font-12 color-medium-gray">
                    Premium Type
                  </label>
                  <Spacer size={20} />
                </>
              )}
              {premiumType === "Other" && otherPremiumType && (
                <>
                  <p>{otherPremiumType}</p>
                  <label className="bold font-12 color-medium-gray">
                    Other (Premium Type)
                  </label>
                  <Spacer size={20} />
                </>
              )}
              {primaryServingOffered && (
                <>
                  <p>{primaryServingOffered}</p>
                  <label className="bold font-12 color-medium-gray">
                    Primary Servicing Can be Offered to Brokers
                  </label>
                  <Spacer size={20} />
                </>
              )}
            </Col>

            {/* Spacer */}
            {(workWithCorrespondents ||
              canPayBrokerPremium ||
              premiumImpact ||
              premiumType ||
              primaryServingOffered) && (
              <>
                <Col xs="12" className="border-bottom"></Col>
                <Col xs="12">
                  <Spacer size={20} />
                </Col>
              </>
            )}

            {/* Apartment Type */}
            <Col xs="12" sm="6">
              {programTypes &&
                (programTypes === "Perm" ||
                  programTypes === "Bridge" ||
                  programTypes === "CMBS" ||
                  programTypes === "Hard Money" ||
                  programTypes === "Construction" ||
                  programTypes === "CTL" ||
                  programTypes === "Construction / Perm" ||
                  programTypes === "Credit Facility" ||
                  programTypes === "Stretch Senior" ||
                  programTypes === "Bridge to Perm" ||
                  programTypes === "Mezzanine / Pref" ||
                  programTypes === "Equity") && (
                  <>
                    {apartmentTypesList && (
                      <div>
                        {apartmentTypesList.map((obj, idx) => (
                          <h4 key={idx}>{obj.label}</h4>
                        ))}
                        <label className="bold font-12 color-medium-gray">
                          Apartment Type
                        </label>
                        <Spacer size={20} />
                      </div>
                    )}
                    {officeTypesList && (
                      <div>
                        {officeTypesList.map((obj, idx) => (
                          <h4 key={idx}>{obj.label}</h4>
                        ))}
                        <label className="bold font-12 color-medium-gray">
                          Office Type
                        </label>
                        <Spacer size={20} />
                      </div>
                    )}
                    {retailTypeList && (
                      <div>
                        {retailTypeList.map((obj, idx) => (
                          <h4 key={idx}>{obj.label}</h4>
                        ))}
                        <label className="bold font-12 color-medium-gray">
                          Retail Type
                        </label>
                        <Spacer size={20} />
                      </div>
                    )}
                    {industrialTypeList && (
                      <div>
                        {industrialTypeList.map((obj, idx) => (
                          <h4 key={idx}>{obj.label}</h4>
                        ))}
                        <label className="bold font-12 color-medium-gray">
                          Industrial Type
                        </label>
                        <Spacer size={20} />
                      </div>
                    )}
                  </>
                )}
            </Col>
            {/*  Hotel Type */}
            <Col xs="12" sm="6">
              {programTypes &&
                (programTypes === "Perm" ||
                  programTypes === "Bridge" ||
                  programTypes === "CMBS" ||
                  programTypes === "Hard Money" ||
                  programTypes === "Construction" ||
                  programTypes === "CTL" ||
                  programTypes === "Construction / Perm" ||
                  programTypes === "Credit Facility" ||
                  programTypes === "Stretch Senior" ||
                  programTypes === "Bridge to Perm" ||
                  programTypes === "Mezzanine / Pref" ||
                  programTypes === "Equity") && (
                  <>
                    {hotelTypeList && (
                      <div>
                        {hotelTypeList.map((obj, idx) => (
                          <h4 key={idx}>{obj.label}</h4>
                        ))}
                        <label className="bold font-12 color-medium-gray">
                          Hotel Type
                        </label>
                        <Spacer size={20} />
                      </div>
                    )}
                    {seniorLivingList && (
                      <div>
                        {seniorLivingList.map((obj, idx) => (
                          <h4 key={idx}>{obj.label}</h4>
                        ))}
                        <label className="bold font-12 color-medium-gray">
                          Senior Living
                        </label>
                        <Spacer size={20} />
                      </div>
                    )}
                    {otherPropertyDealTypesWeLike && (
                      <>
                        {otherPropertyDealTypesWeLike.some(
                          (item) => item.label === "Other"
                        ) && otherOtherPropertyDealTypesWeLike ? (
                          <>
                            <div className="has-other">
                              {otherPropertyDealTypesWeLike.map((obj, idx) => (
                                <h4 key={idx}>{obj.label}</h4>
                              ))}
                              <label className="bold font-12 color-medium-gray">
                                Other Property Deal Type
                              </label>
                            </div>
                            {otherOtherPropertyDealTypesWeLike && (
                              <>
                                <div className="is-other">
                                  <Spacer size={5} />
                                  <p>{otherOtherPropertyDealTypesWeLike}</p>
                                  <label className="bold font-12 color-medium-gray">
                                    Other (Other Properties)
                                  </label>
                                </div>
                                <Spacer size={20} />
                              </>
                            )}
                          </>
                        ) : (
                          <div>
                            {otherPropertyDealTypesWeLike.map((obj, idx) => (
                              <h4 key={idx}>{obj.label}</h4>
                            ))}
                            <label className="bold font-12 color-medium-gray">
                              Other Property Deal Type
                            </label>
                            <Spacer size={20} />
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}
            </Col>

            {/* Spacer */}
            {programTypes &&
              (apartmentTypesList ||
                officeTypesList ||
                retailTypeList ||
                industrialTypeList ||
                hotelTypeList ||
                seniorLivingList ||
                otherPropertyDealTypesWeLike) && (
                <>
                  <Col xs="12" className="border-bottom"></Col>
                  <Col xs="12">
                    <Spacer size={20} />
                  </Col>
                </>
              )}
            {/* Max LTV */}
            <Col xs="12" sm="6">
              {programTypes &&
                (programTypes === "Perm" ||
                  programTypes === "Bridge" ||
                  programTypes === "CMBS" ||
                  programTypes === "Hard Money" ||
                  programTypes === "Construction" ||
                  programTypes === "CTL" ||
                  programTypes === "Construction / Perm" ||
                  programTypes === "Credit Facility" ||
                  programTypes === "Stretch Senior" ||
                  programTypes === "Bridge to Perm") && (
                  <>
                    <Row noGutters={true}>
                      <Col xs="6">
                        {maxLTV && (
                          <div>
                            <h4>{maxLTV}</h4>
                            <label className="bold font-12 color-medium-gray">
                              Max LTV
                            </label>
                            <Spacer size={20} />
                          </div>
                        )}
                      </Col>
                      <Col xs="6">
                        {competitiveLTV && (
                          <div>
                            <h4>{competitiveLTV}</h4>
                            <label className="bold font-12 color-medium-gray">
                              Most Competitive LTV
                            </label>
                            <Spacer size={20} />
                          </div>
                        )}
                      </Col>
                    </Row>
                    {rateStructure && (
                      <div>
                        <h4>{rateStructure}</h4>
                        <label className="bold font-12 color-medium-gray">
                          Rate Structure
                        </label>
                        <Spacer size={20} />
                      </div>
                    )}
                  </>
                )}
              {programTypes && programTypes === "Mezzanine / Pref" && (
                <>
                  {transactionType && (
                    <div>
                      {transactionType.map((obj, idx) => (
                        <h4 key={idx}>{obj.label}</h4>
                      ))}
                      <label className="bold font-12 color-medium-gray">
                        Transaction Type
                      </label>
                      <Spacer size={20} />
                    </div>
                  )}
                  {investmentPeriod && (
                    <div>
                      <h4>{investmentPeriod}</h4>
                      <label className="bold font-12 color-medium-gray">
                        Investment Period
                      </label>
                      <Spacer size={20} />
                    </div>
                  )}
                  {dealSize && (
                    <div>
                      <h4>
                        <FontAwesomeIcon icon={faDollarSign} />{" "}
                        <NumberFormat
                          value={dealSize}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                      </h4>
                      <label className="bold font-12 color-medium-gray">
                        Deal Size
                      </label>
                      <Spacer size={20} />
                    </div>
                  )}
                </>
              )}
              {programTypes && programTypes === "Equity" && (
                <>
                  {transactionType && (
                    <div>
                      {transactionType.map((obj, idx) => (
                        <h4 key={idx}>{obj.label}</h4>
                      ))}
                      <label className="bold font-12 color-medium-gray">
                        Transaction Type
                      </label>
                      <Spacer size={20} />
                    </div>
                  )}
                  {investmentStructure && (
                    <div>
                      <h4>{investmentStructure}</h4>
                      <label className="bold font-12 color-medium-gray">
                        Investment Structure
                      </label>
                      <Spacer size={20} />
                    </div>
                  )}
                  {investmentPeriod && (
                    <div>
                      <h4>{investmentPeriod}</h4>
                      <label className="bold font-12 color-medium-gray">
                        Investment Period
                      </label>
                      <Spacer size={20} />
                    </div>
                  )}
                  {dealSize && (
                    <div>
                      <h4>
                        <FontAwesomeIcon icon={faDollarSign} />{" "}
                        <NumberFormat
                          value={dealSize}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                      </h4>
                      <label className="bold font-12 color-medium-gray">
                        Deal Size
                      </label>
                      <Spacer size={20} />
                    </div>
                  )}
                </>
              )}
            </Col>
            {/* Minimum Rate */}
            <Col xs="12" sm="6">
              {programTypes &&
                (programTypes === "Perm" ||
                  programTypes === "Bridge" ||
                  programTypes === "CMBS" ||
                  programTypes === "Hard Money" ||
                  programTypes === "Construction" ||
                  programTypes === "CTL" ||
                  programTypes === "Construction / Perm" ||
                  programTypes === "Credit Facility" ||
                  programTypes === "Stretch Senior" ||
                  programTypes === "Bridge to Perm") && (
                  <>
                    <Row noGutters={true}>
                      <Col xs="6">
                        {minimumRate && rateStructure === "Rate" && (
                          <div>
                            <h4>
                              <NumberFormat
                                value={minimumRate}
                                displayType={"text"}
                                thousandSeparator={true}
                              />
                            </h4>
                            <label className="bold font-12 color-medium-gray">
                              Minimum Rate
                            </label>
                            <Spacer size={20} />
                          </div>
                        )}
                      </Col>
                      <Col xs="6">
                        {maximumRate && rateStructure === "Rate" && (
                          <div>
                            <h4>
                              <NumberFormat
                                value={maximumRate}
                                displayType={"text"}
                                thousandSeparator={true}
                              />
                            </h4>
                            <label className="bold font-12 color-medium-gray">
                              Maximum Rate
                            </label>
                            <Spacer size={20} />
                          </div>
                        )}
                      </Col>
                    </Row>
                    {typicalIndex && rateStructure === "Spread" && (
                      <>
                        {typicalIndex.some((item) => item.label === "Other") &&
                        otherTypicalIndex ? (
                          <>
                            <div className="has-other">
                              {typicalIndex.map((obj, idx) => (
                                <h4 key={idx}>{obj.label}</h4>
                              ))}
                              <label className="bold font-12 color-medium-gray">
                                Typical Index
                              </label>
                            </div>
                            {otherTypicalIndex && (
                              <>
                                <div className="is-other">
                                  <Spacer size={5} />
                                  <p>{otherTypicalIndex}</p>
                                  <label className="bold font-12 color-medium-gray">
                                    Other (Typical Index)
                                  </label>
                                </div>
                                <Spacer size={20} />
                              </>
                            )}
                          </>
                        ) : (
                          <div>
                            {typicalIndex.map((obj, idx) => (
                              <h4 key={idx}>{obj.label}</h4>
                            ))}
                            <label className="bold font-12 color-medium-gray">
                              Typical Index
                            </label>
                            <Spacer size={20} />
                          </div>
                        )}
                      </>
                    )}
                    <Row noGutters={true}>
                      <Col xs="6">
                        {minimumSpread && rateStructure === "Spread" && (
                          <div>
                            <h4>
                              <NumberFormat
                                value={minimumSpread}
                                displayType={"text"}
                                thousandSeparator={true}
                              />
                            </h4>
                            <label className="bold font-12 color-medium-gray">
                              Minimum Spread
                            </label>
                            <Spacer size={20} />
                          </div>
                        )}
                      </Col>
                      <Col xs="6">
                        {maximumSpread && rateStructure === "Spread" && (
                          <div>
                            <h4>
                              <NumberFormat
                                value={maximumSpread}
                                displayType={"text"}
                                thousandSeparator={true}
                              />
                            </h4>
                            <label className="bold font-12 color-medium-gray">
                              Maximum Spread
                            </label>
                            <Spacer size={20} />
                          </div>
                        )}
                      </Col>
                    </Row>
                  </>
                )}
              {programTypes && programTypes === "Mezzanine / Pref" && (
                <>
                  {weCanInvestBehind && (
                    <>
                      {weCanInvestBehind.some(
                        (item) => item.label === "Other"
                      ) && otherWeCanInvestBehind ? (
                        <>
                          <div className="has-other">
                            {weCanInvestBehind.map((obj, idx) => (
                              <h4 key={idx}>{obj.label}</h4>
                            ))}
                            <label className="bold font-12 color-medium-gray">
                              We Can Invest Behind
                            </label>
                          </div>
                          {otherWeCanInvestBehind && (
                            <>
                              <div className="is-other">
                                <Spacer size={5} />
                                <p>{otherWeCanInvestBehind}</p>
                                <label className="bold font-12 color-medium-gray">
                                  Other (We Can Invest Behind)
                                </label>
                              </div>
                              <Spacer size={20} />
                            </>
                          )}
                        </>
                      ) : (
                        <div>
                          {weCanInvestBehind.map((obj, idx) => (
                            <h4 key={idx}>{obj.label}</h4>
                          ))}
                          <label className="bold font-12 color-medium-gray">
                            We Can Invest Behind
                          </label>
                          <Spacer size={20} />
                        </div>
                      )}
                    </>
                  )}
                  {typicalRates && (
                    <div>
                      <h4>
                        <FontAwesomeIcon icon={faPercent} />{" "}
                        <NumberFormat
                          value={typicalRates}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                      </h4>
                      <label className="bold font-12 color-medium-gray">
                        Typical Rates
                      </label>
                      <Spacer size={20} />
                    </div>
                  )}
                </>
              )}
              {programTypes && programTypes === "Equity" && (
                <>
                  {minGPCoInvest && (
                    <div>
                      <h4>{minGPCoInvest}</h4>
                      <label className="bold font-12 color-medium-gray">
                        Min GP Co-Invest
                      </label>
                      <Spacer size={20} />
                    </div>
                  )}
                  {targetIRR && (
                    <div>
                      <h4>
                        <FontAwesomeIcon icon={faPercent} />{" "}
                        <NumberFormat
                          value={targetIRR}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                      </h4>
                      <label className="bold font-12 color-medium-gray">
                        Target IRR
                      </label>
                      <Spacer size={20} />
                    </div>
                  )}
                  {targetCashOnCash && (
                    <div>
                      <h4>
                        <FontAwesomeIcon icon={faPercent} />{" "}
                        <NumberFormat
                          value={targetCashOnCash}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                      </h4>
                      <label className="bold font-12 color-medium-gray">
                        Target Cash on Cash
                      </label>
                      <Spacer size={20} />
                    </div>
                  )}
                </>
              )}
            </Col>
            {/* Spacer */}
            {programTypes &&
              (programTypes === "Fannie Mae" ||
                programTypes === "Freddie Mac" ||
                programTypes === "Perm" ||
                programTypes === "Bridge" ||
                programTypes === "CMBS" ||
                programTypes === "Hard Money" ||
                programTypes === "Construction" ||
                programTypes === "HUD" ||
                programTypes === "CTL" ||
                programTypes === "Construction / Perm" ||
                programTypes === "Credit Facility" ||
                programTypes === "Stretch Senior" ||
                programTypes === "Bridge to Perm" ||
                programTypes === "USDA" ||
                programTypes === "SBA 7a" ||
                programTypes === "SBA 504" ||
                programTypes === "PACE" ||
                programTypes === "Loan Guaranty" ||
                programTypes === "Other") &&
              (minimumRate ||
                maximumRate ||
                typicalIndex ||
                minimumSpread ||
                maximumSpread) && (
                <>
                  <Col xs="12" className="border-bottom"></Col>
                  <Col xs="12">
                    <Spacer size={20} />
                  </Col>
                </>
              )}
            {websiteLink && (
              <>
                <Col xs="12" className="border-bottom"></Col>
                <Col xs="12">
                  <Spacer size={20} />
                </Col>
              </>
            )}
            <Col xs="12">
              {websiteLink && (
                <div>
                  <h4>
                    <a
                      href={
                        !websiteLink.includes("https://") ||
                        !websiteLink.includes("http://")
                          ? "https://" + websiteLink
                          : websiteLink
                      }
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {websiteLink}
                    </a>
                  </h4>
                  <label className="bold font-12 color-medium-gray">
                    Website Link
                  </label>
                  <Spacer size={20} />
                </div>
              )}
            </Col>
            {whoami.role === "Admin" && (
              <>
                <Col xs="12">
                  <div className="d-flex align-items-center">
                    <h4>Tags</h4>
                    <div className="ml-2">
                      <Button
                        size="sm"
                        variant="link"
                        className="p-0"
                        style={{ fontSize: "0.75rem" }}
                        onClick={() =>
                          this.setState({
                            editTagsMode: true,
                            editNotesMode: false,
                          })
                        }
                      >
                        Edit Tags
                      </Button>
                    </div>
                  </div>
                  {this.state.editTagsMode ? (
                    <div className="mt-2">
                      <Field
                        isMulti
                        isCreatable
                        showLoading
                        closeMenuOnSelect={false}
                        className="form-control"
                        component={reduxFormSelect}
                        name="tags"
                        type="select"
                        isLoading={
                          action.GET_TAGS.isFetching ||
                          action.CREATE_TAG.isFetching
                        }
                        options={this.state.tagOptions}
                        placeholder="Add tags..."
                        formatCreateLabel={(label) => `Add tag "${label}"`}
                        onFocus={this.handleGetTags}
                        onCreateOption={this.handleCreateTag}
                      />
                      <div className="mt-3">
                        <Button
                          size="sm"
                          className="mr-2"
                          onClick={() => {
                            this.handleUpdateProgram();
                            this.setState({ editTagsMode: false });
                          }}
                        >
                          Save
                        </Button>
                        <Button
                          size="sm"
                          variant="light"
                          onClick={() => this.setState({ editTagsMode: false })}
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div className="d-flex flex-column">
                      <label className="bold font-12 color-medium-gray">
                        {program.tags?.map((item) => item.tag)?.join(", ")}
                      </label>
                    </div>
                  )}
                  <Spacer size={20} />
                </Col>
                <Col xs="12">
                  <div className="d-flex align-items-center">
                    <h4>Notes</h4>
                    <div className="ml-2">
                      <Button
                        size="sm"
                        variant="link"
                        className="p-0"
                        style={{ fontSize: "0.75rem" }}
                        onClick={() =>
                          this.setState({
                            editNotesMode: true,
                            editTagsMode: false,
                          })
                        }
                      >
                        Add Notes
                      </Button>
                    </div>
                  </div>
                  {this.state.editNotesMode ? (
                    <div className="mt-2">
                      <Field
                        className="form-control"
                        component={reduxFormTextarea}
                        validate={[noSpaces]}
                        name="notesItemContent"
                        type="textarea"
                        rows="3"
                        placeholder="Add Notes"
                      />
                      <div className="mt-3">
                        <Button
                          size="sm"
                          className="mr-2"
                          onClick={() => {
                            this.handleUpdateProgram();
                            this.setState({ editNotesMode: false });
                          }}
                        >
                          Save
                        </Button>
                        <Button
                          size="sm"
                          variant="light"
                          onClick={() =>
                            this.setState({ editNotesMode: false })
                          }
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div className="d-flex flex-column">
                      <NotesTimeline className="mt-2" notes={program.notes} />
                    </div>
                  )}
                  <Spacer size={20} />
                </Col>
              </>
            )}
          </Row>
        </Container>
        <Spacer size={50} />
      </>
    );
  }
}

const selector = formValueSelector("ProgramView");
const getInitialValues = (program) => {
  return {
    notesItemContent: get(program, "notesItemContent", ""),
    tags: get(program, "tags", []).map((item) => ({
      value: item._id,
      label: item.tag,
    })),
  };
};
function mapStateToProps(state, ownProps) {
  return {
    action: state.action,
    whoami: state.auth.whoami,
    myCompany: state.company.myCompany,
    program: state.program.program,
    tags: state.tag.tags,
    lastTagCreated: state.tag.lastTagCreated,
    selectedTags: selector(state, "tags") || [],
    notesItemContent: selector(state, "notesItemContent") || "",
    initialValues: getInitialValues(state.program.program),
  };
}

export default withRouter(
  connect(mapStateToProps)(
    reduxForm({
      form: "ProgramView",
      destroyOnUnmount: false,
      forceUnregisterOnUnmount: true,
      enableReinitialize: true,
    })(Program)
  )
);
