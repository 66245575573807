import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Button } from "react-bootstrap";
import {
  getDealById,
  getDealFilesById,
  saveFinancials,
  updateDeal,
} from "redux/actions";
import { getKeyFromLocal } from "utils/lsUtils";
import moment from "moment";
import constants from "config/constants";
import Loader from "components/ui/Loader";
import FinancialFolderItem from "./FinancialFolderItem";
import imageCompression from "browser-image-compression";
import { toast } from "react-toastify";
import { ALLOWED_FILE_EXTS } from "containers/deals/FileManager/constants";
import { getDealCreationPath } from "utils/commonUtils";

const FinancialUploads = (props) => {
  const dealId = getKeyFromLocal(constants.TERMST_LOCAL_STORAGE.DEAL_ID);
  const [requiredFolders, setRequiredFolders] = useState([]);
  const [remainingFinancials, setRemainingFinancials] = useState([]);
  const [optionalFolders, setOptionalFolders] = useState([]);
  const [compressing, setCompressing] = useState(false);
  const [hasRentRoll, setHasRentRoll] = useState(false);
  const [hasOneOther, setHasOneOther] = useState(false);
  const [rentRollRequired, setRentRollRequired] = useState();

  const { whoami, isFetching, fileManagerData, filesSaved, deal } = props;

  useEffect(() => {
    if (!deal._id) {
      getDealById(dealId);
    }
  }, [dealId]);

  useEffect(() => {
    if (deal._id) {
      getDealFilesById(deal._id);
    }
  }, [deal._id, filesSaved]);

  useEffect(() => {
    if (requiredFolders.length) {
      const isRentRollRequired = requiredFolders.some(
        (e) => e.name === "Rent Roll"
      );
      setRentRollRequired(isRentRollRequired);
    }
  }, [requiredFolders]);

  useEffect(() => {
    const financialFolder = fileManagerData.filter(
      (data) => data.name === "Financials"
    );
    const tenantInfoFolder = fileManagerData.filter(
      (data) => data.name === "Tenant Information"
    );

    const required = [];
    const remaining = [];
    const optional = [];
    const year = moment().year();

    if (financialFolder.length > 0 && tenantInfoFolder.length > 0) {
      const tempFinancials = fileManagerData.filter(
        (data) => data.parent === financialFolder[0]._id
      );
      const tempTenants = fileManagerData.filter(
        (data) => data.parent === tenantInfoFolder[0]._id
      );

      tempTenants.forEach((financial) => {
        if (financial.name === "Rent Roll") {
          if (deal.propertyType === "Hotel") {
            optional.push(financial);
          } else {
            required.push(financial);
          }
        }
      });

      tempFinancials.forEach((financial) => {
        if (
          financial.name === "T12 Operating Statement" ||
          financial.name === year - 1 + " Operating Statement"
        ) {
          required.push(financial);
        } else {
          remaining.push(financial);
        }
      });
    }

    if (deal.propertyType === "Hotel") {
      const STRReport = fileManagerData.find(
        (data) => data.name === "STR Report"
      );
      if (STRReport) {
        optional.push(STRReport);
      }
    }

    setRequiredFolders(required);
    setRemainingFinancials(remaining);
    setOptionalFolders(optional);
  }, [fileManagerData]);

  useEffect(() => {
    const year = moment().year();
    const rentRollFolder = fileManagerData.find(
      (folder) => folder.name === "Rent Roll"
    );
    const t12Folder = fileManagerData.find(
      (folder) => folder.name === "T12 Operating Statement"
    );
    const yearFolder = fileManagerData.find(
      (folder) => folder.name === year - 1 + " Operating Statement"
    );

    if (
      (hasRentRoll && hasOneOther) ||
      (hasOneOther && rentRollRequired === false) ||
      (rentRollFolder &&
        t12Folder &&
        yearFolder &&
        fileManagerData.some((file) => file.parent === rentRollFolder._id) &&
        fileManagerData.some(
          (file) =>
            file.parent === t12Folder._id || file.parent === yearFolder._id
        ))
    ) {
      updateDeal(dealId, {
        financialUploads: true,
        fromDashboard: true,
      });
    }
  }, [fileManagerData, hasRentRoll, hasOneOther, rentRollRequired, dealId]);

  const getChildNodes = useCallback(
    (node) => {
      return fileManagerData.filter((n) => n.parent === node._id);
    },
    [fileManagerData]
  );

  const isValidFile = (file) => {
    for (let i in ALLOWED_FILE_EXTS) {
      if (file.name.toLowerCase().indexOf(ALLOWED_FILE_EXTS[i]) !== -1) {
        return true;
      }
    }
    return false;
  };

  const handleUploadFile = useCallback(
    async (event, folderId, folderName) => {
      if (event.target.files?.length > 5) {
        toast.error("You can not upload more than 5 files at a time", {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }
      for (let file of event.target.files) {
        if (!isValidFile(file)) {
          toast.error(
            `Contains Unsupported file(s). Allows only ${ALLOWED_FILE_EXTS.join(
              ", "
            )}`,
            {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
          return;
        }
      }
      const data = new FormData();
      const year = moment().year();
      const options = {
        maxSizeMB: 0.75,
      };

      for await (let file of event.target.files) {
        try {
          if (file["type"].split("/")[0] === "image") {
            setCompressing(true);
            const compressedFile = await imageCompression(file, options);
            setCompressing(false);

            data.append("files", compressedFile, file.name);
          } else if (isValidFile(file)) {
            data.append("files", file);
          }
        } catch (error) {
          return error;
        }
      }

      data.append("dealId", dealId);
      data.append("folderId", folderId);
      saveFinancials(data);

      if (folderName === "Rent Roll") {
        setHasRentRoll(true);
      } else if (
        folderName === "T12 Operating Statement" ||
        folderName === year - 1 + " Operating Statement"
      ) {
        setHasOneOther(true);
      }
    },
    [dealId]
  );

  const rentRoll = useMemo(
    () => requiredFolders.find((e) => e.name === "Rent Roll"),
    [requiredFolders]
  );
  const notRentRoll = useMemo(
    () => requiredFolders.filter((e) => e.name !== "Rent Roll"),
    [requiredFolders]
  );

  const onFinish = () => {
    const path = getDealCreationPath();
    props.history.push(path);
  };

  return (
    <>
      {(isFetching || compressing) && <Loader files={true} />}
      <Container fluid>
        <Row className="file-item-header pt-2 pb-2">
          <Col xs="12">
            <p className="font-16">Financial Uploads</p>
          </Col>
        </Row>
      </Container>
      {requiredFolders && requiredFolders.length > 0 && (
        <>
          <div className="grid-container pt-2 test">
            {rentRoll && (
              <FinancialFolderItem
                key={rentRoll._id}
                id={rentRoll._id}
                userId={whoami._id}
                category={rentRoll.name}
                files={getChildNodes(rentRoll)}
                handleUploadFile={handleUploadFile}
                isRequired={true}
                {...rentRoll}
              />
            )}
            <div className="grid-container">
              {notRentRoll[0] && (
                <FinancialFolderItem
                  key={notRentRoll[0]._id}
                  id={notRentRoll[0]._id}
                  userId={whoami._id}
                  category={notRentRoll[0].name}
                  files={getChildNodes(notRentRoll[0])}
                  handleUploadFile={handleUploadFile}
                  isTogether={true}
                  {...notRentRoll[0]}
                />
              )}
              {notRentRoll[1] && (
                <FinancialFolderItem
                  key={notRentRoll[1]._id}
                  id={notRentRoll[1]._id}
                  userId={whoami._id}
                  category={notRentRoll[1].name}
                  files={getChildNodes(notRentRoll[1])}
                  handleUploadFile={handleUploadFile}
                  isTogether={true}
                  {...notRentRoll[1]}
                />
              )}
              <div className="grid-together mt-2 text-center">
                <p className="font-14 color-purple">One required</p>
              </div>
            </div>
          </div>
        </>
      )}
      {remainingFinancials && remainingFinancials.length > 0 && (
        <div className="grid-container pt-2">
          {remainingFinancials.map((node) => {
            const childNodes = getChildNodes(node);
            return (
              <FinancialFolderItem
                key={node._id}
                id={node._id}
                userId={whoami._id}
                category={node.name}
                files={childNodes}
                handleUploadFile={handleUploadFile}
                isRequired={false}
                {...node}
              />
            );
          })}
        </div>
      )}
      {optionalFolders && optionalFolders.length > 0 && (
        <div className="grid-container pt-2">
          {optionalFolders.map((node) => {
            const childNodes = getChildNodes(node);
            return (
              <FinancialFolderItem
                key={node._id}
                id={node._id}
                userId={whoami._id}
                category={node.name}
                files={childNodes}
                handleUploadFile={handleUploadFile}
                isRequired={false}
                {...node}
              />
            );
          })}
        </div>
      )}
      <Container fluid>
        <Row>
          <Col xs="12">
            <Button variant="primary" onClick={onFinish}>
              Done
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};

function mapStateToProps(state) {
  return {
    whoami: state.auth.whoami,
    fileManagerData: state.fileManager.files,
    filesSaved: state.action.SAVE_FINANCIALS.isFetched,
    deal: state.deal.deal,
    isFetching:
      state.action.GET_DEAL_FILES.isFetching ||
      state.action.SAVE_FINANCIALS.isFetching,
  };
}

export default connect(mapStateToProps)(FinancialUploads);
