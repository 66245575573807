import React from "react";
import { connect } from "react-redux";
import { Form, Button, Row, Col } from "react-bootstrap";
import { Field, reduxForm, change } from "redux-form";
import { required } from "utils/validation";
import { reduxFormSelect, reduxFormInput } from "components/form/ReduxForm";
import { createTeam, getCompanyMembers } from "redux/actions";
import { toast } from "react-toastify";
import Spacer from "components/layout/Spacer";

class TeamCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      teamMembers: [],
      membersList: [],
      isSubmitting: false,
    };

    this.leaderRef = React.createRef();
    this.memberRef = React.createRef();

    this.submitFn = this.submitFn.bind(this);
    this.submitError = this.submitError.bind(this);
    this.addMember = this.addMember.bind(this);
    this.removeMember = this.removeMember.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.members !== this.props.members) {
      const { members } = this.props;

      members.map((member) => {
        this.setState((prevState) => ({
          membersList: [
            ...prevState.membersList,
            {
              label: member.firstName + " " + member.lastName,
              value: member._id,
            },
          ],
        }));

        return true;
      });
    }
  }

  componentDidMount() {
    const { companyId } = this.props;

    getCompanyMembers(companyId);
  }

  submitFn(data) {
    const { companyId } = this.props;

    const canSubmit = this.state.teamMembers.some((member) => {
      if (member.value === data.teamLeader.value) {
        return true;
      } else {
        return false;
      }
    });

    if (!canSubmit) {
      let newData = {};
      let teamMembersArray = [];

      teamMembersArray.push({
        userId: data.teamLeader.value,
        globalRole: "Team Leader",
      });

      this.state.teamMembers.map((member) => {
        teamMembersArray.push({
          userId: member.value,
          globalRole: "Team Member",
        });

        return true;
      });

      Object.assign(
        newData,
        { companyId },
        {
          teamName: data.teamLeader.label + "'s Team",
          teamMembers: teamMembersArray,
        }
      );

      createTeam(newData);
    } else {
      this.submitError("Team leader cannot also be a member!");
    }
  }

  submitError(text) {
    toast.error(text, {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  addMember(data) {
    const { dispatch } = this.props;

    if (
      this.leaderRef.current.value &&
      data.value === this.leaderRef.current.value.value
    ) {
      this.submitError("Member is already the leader!");
    } else {
      const hasMember = this.state.teamMembers.some((member) => {
        if (member.value === data.value) {
          return true;
        } else {
          return false;
        }
      });

      if (hasMember) {
        this.submitError("Member already added!");
      } else {
        this.setState((prevState) => ({
          teamMembers: [...prevState.teamMembers, data],
        }));
      }
    }

    dispatch(change("CreateTeam", "teamMember", null));
  }

  removeMember(id) {
    this.setState({
      teamMembers: this.state.teamMembers.filter((member) => {
        return member.value !== id;
      }),
    });
  }

  render() {
    const { action, handleSubmit } = this.props;

    return (
      <>
        <Form
          className="full-width no-border no-padding"
          onSubmit={handleSubmit(this.submitFn)}
        >
          <Row>
            <Col xs="12">
              <h4 className="bold">Leader</h4>
              <Spacer size={20} />
            </Col>
            <Col xs="12">
              <Field
                ref={this.leaderRef}
                className="form-control"
                component={reduxFormSelect}
                validate={[required]}
                name="teamLeader"
                type="select"
                options={this.state.membersList}
                isLoading={action.GET_COMPANY_MEMBERS.isFetching}
                placeholder="Select member"
                label="*Leader name"
              />
              <Spacer size={20} />
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <h4 className="bold">Members</h4>
              <Spacer size={20} />
            </Col>
            <Col xs="8" sm="10">
              <Field
                ref={this.memberRef}
                className="form-control"
                component={reduxFormSelect}
                name="teamMember"
                type="select"
                options={this.state.membersList}
                isLoading={action.GET_COMPANY_MEMBERS.isFetching}
                placeholder="Enter name"
                label="Member name"
              />
            </Col>
            <Col xs="4" sm="2">
              <Button
                className="full-width"
                variant="primary"
                onClick={() =>
                  this.memberRef.current.value
                    ? this.addMember(this.memberRef.current.value)
                    : null
                }
              >
                Add
              </Button>
            </Col>
            <Col xs="12">
              <Spacer size={20} />
            </Col>
          </Row>
          <Row>
            {this.state.teamMembers.map((member, idx) => {
              return (
                <Col className="field-wrapper-no-spacer" xs="12" key={idx}>
                  <Field
                    className="form-control"
                    component={reduxFormInput}
                    name={"member" + idx}
                    type="text"
                    placeholder={member.label}
                    disabled
                  />
                  <p
                    className="field-remove"
                    onClick={() => this.removeMember(member.value)}
                    onKeyDown={(e) =>
                      e.keyCode === 13 ? document.activeElement.click() : null
                    }
                    tabIndex="0"
                  >
                    Remove
                  </p>
                </Col>
              );
            })}
          </Row>
          <Row>
            <Col className="text-right" xs="12">
              <Spacer size={50} />
              <Button type="submit" variant="primary">
                CREATE
              </Button>
            </Col>
          </Row>
        </Form>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    action: state.action,
    members: state.company.members,
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "CreateTeam",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  })(TeamCreate)
);
