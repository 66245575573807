import React from "react";
import { connect } from "react-redux";
import { Button, Form, Container, Row, Col } from "react-bootstrap";
import { Field, reduxForm } from "redux-form";
import { required, noSpaces } from "utils/validation";
import { reduxFormInput } from "components/form/ReduxForm";
import { createCategory, updateCategory } from "redux/actions";
import Spacer from "components/layout/Spacer";

class CreateEditCategory extends React.Component {
  constructor(props) {
    super(props);

    this.submitFn = this.submitFn.bind(this);
  }

  submitFn(data) {
    const { promptType, selectedCategory } = this.props;

    if (promptType === "create") {
      createCategory({ categoryName: data.categoryName });
    } else {
      updateCategory(selectedCategory._id, { categoryName: data.categoryName });
    }
  }

  render() {
    const { action, handleSubmit } = this.props;
    const isProcessing =
      action.CREATE_TAG.isFetching || action.UPDATE_TAG.isFetching;

    return (
      <>
        <Container>
          <Form
            className="no-border no-padding"
            onSubmit={handleSubmit(this.submitFn)}
          >
            <Row>
              <Col xs="12">
                <p>
                  Categories are used to better organize your term sheet
                  clauses. Add a category here and then you can save clauses to
                  this new category.
                </p>
              </Col>
            </Row>
            <Spacer size={30} />
            <Row>
              <Col xs="12">
                <Field
                  className="form-control"
                  component={reduxFormInput}
                  validate={[required, noSpaces]}
                  name="categoryName"
                  type="text"
                  placeholder="Enter category name"
                  label="*Category name"
                />
              </Col>
            </Row>
            <Spacer size={50} />
            <Row className="justify-content-center">
              <Col className="text-center" xs="12" sm="6">
                <Button disabled={isProcessing} type="submit" variant="primary">
                  {isProcessing ? "Saving..." : "Save"}
                </Button>
              </Col>
            </Row>
          </Form>
          <Spacer size={30} />
        </Container>
      </>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    action: state.action,
    initialValues: {
      categoryName: props.selectedCategory?.categoryName || "",
    },
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "CreateEditCategory",
    enableReinitialize: true,
  })(CreateEditCategory)
);
