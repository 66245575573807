import React, { useEffect, useMemo, useState } from "react";
import toLower from "lodash/toLower";
import startCase from "lodash/startCase";
import { faEllipsisH, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Col,
  Dropdown,
  Row,
  Table as BootstrapTable,
} from "react-bootstrap";
import {
  useTable,
  usePagination,
  useRowSelect,
  useFilters,
  useGlobalFilter,
} from "react-table";
import IndeterminateCheckbox from "components/ui/IndeterminateCheckbox";
import CustomMenu from "components/ui/CustomMenu";
import CustomToggle from "components/ui/CustomToggle";
import DefaultColumnFilter from "components/ui/DefaultColumnFilter";
import { connect } from "react-redux";
import { getManageClauses, updateClause } from "redux/actions";
import PopupPrompt from "components/ui/PopupPrompt";
import CreateEditClause from "./CreateEditClause";
import Spacer from "components/layout/Spacer";

const ManageClauseLibrary = (props) => {
  const [state, setState] = useState({
    promptType: "",
    showPrompt: false,
    selectedClause: null,
    pagination: {
      page: 1,
      listOffset: 500,
    },
    sort: {
      direction: "ASC",
      column: "clauseName",
    },
  });

  const [selectedRows, setSelectedRows] = useState([]);

  const handleModalClose = () =>
    setState((prevState) => ({
      ...prevState,
      showPrompt: false,
      promptType: "",
      selectedClause: null,
    }));

  const handleModelOpen = (promptType, selectedClause) => {
    setState((prevState) => ({
      ...prevState,
      showPrompt: true,
      promptType,
      selectedClause,
    }));
  };

  const handleEdit = (rowValues) => {
    handleModelOpen("edit", rowValues);
  };

  const handleCopy = (rowValues) => {
    handleModelOpen("copy", rowValues);
  };

  const handleArchive = (rowValues) => {
    updateClause(rowValues._id, {
      status: "ARCHIVED",
    });
  };

  const handleChangeStatus = (rowValues) => {
    handleModelOpen("change_status", rowValues);
  };

  const handleChangeAvailability = (rowValues) => {
    handleModelOpen("change_availability", rowValues);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Category",
        accessor: "category",
        Cell: ({ row }) => {
          return (
            <div>
              <div>{row.original.category.categoryName}</div>
            </div>
          );
        },
        filter: (rows, columnIds, filterValue) => {
          return rows.filter((row) =>
            toLower(row.original.category.categoryName).includes(
              toLower(filterValue)
            )
          );
        },
      },
      {
        Header: () => (
          <span>
            Parent Clause <br /> Display Name
          </span>
        ),
        accessor: "parent_clause",
        Cell: ({ row }) => {
          return (
            <div>
              <div>{row.original.parent?.clauseName}</div>
              <div>{row.original.displayName}</div>
            </div>
          );
        },
        filter: (rows, columnIds, filterValue) => {
          return rows.filter((row) =>
            toLower(
              `${row.original.parent?.clauseName} ${row.original.displayName}`
            ).includes(toLower(filterValue))
          );
        },
      },
      {
        Header: "Clause Name",
        accessor: "clauseName",
        disableFilters: true,
      },
      {
        Header: "Company Owner",
        accessor: "company",
        Cell: ({ row }) => {
          return (
            <div>
              <div>{row.original.owner?.company?.companyName}</div>
              <div>
                {row.original.owner?.firstName +
                  " " +
                  row.original.owner?.lastName}
              </div>
            </div>
          );
        },
        filter: (rows, columnIds, filterValue) => {
          return rows.filter((row) =>
            toLower(
              `${row.original.owner?.company?.companyName} ${row.original.owner?.firstName} ${row.original.owner?.lastName}`
            ).includes(toLower(filterValue))
          );
        },
      },
      {
        Header: "Date Last Edited",
        accessor: "updatedAt",
        disableFilters: true,
        Cell: ({ row }) => {
          return (
            <div>{new Date(row.original.updatedAt).toLocaleDateString()}</div>
          );
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ row }) => {
          return <div>{startCase(toLower(row.original.status))}</div>;
        },
      },
      {
        Header: "Available to",
        accessor: "adminAvailability.key",
        Cell: ({ row }) => {
          return (
            <div>{startCase(toLower(row.original.adminAvailability.key))}</div>
          );
        },
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <Dropdown className="d-inline-block">
              <Dropdown.Toggle as={CustomToggle}>
                <p className="link-text">
                  <FontAwesomeIcon icon={faEllipsisH} />
                </p>
              </Dropdown.Toggle>

              <Dropdown.Menu as={CustomMenu}>
                <Dropdown.Item
                  onClick={() => handleEdit(cellProps.cell.row.original)}
                >
                  View / Edit
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => handleCopy(cellProps.cell.row.original)}
                >
                  Copy / Edit
                </Dropdown.Item>
                <Dropdown.Item
                  disabled={Boolean(cellProps.cell.row.original.inUse)}
                  onClick={() => handleArchive(cellProps.cell.row.original)}
                >
                  Archive
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    handleChangeStatus(cellProps.cell.row.original)
                  }
                >
                  Change Status
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    handleChangeAvailability(cellProps.cell.row.original)
                  }
                >
                  Change Availability
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          );
        },
      },
    ],
    []
  );

  const tableData = useMemo(
    () => props.manageClauses.rows.filter((e) => e.parent),
    [props.manageClauses]
  );

  const fetchClauses = () => {
    const { pagination, sort } = state;
    const { manageClauses } = props;
    getManageClauses({
      page: pagination.page,
      limit: pagination.listOffset,
      search: manageClauses.search,
      order: sort.direction,
      orderColumn: sort.column,
      parent: false,
    });
  };

  useEffect(() => {
    handleModalClose();
    fetchClauses();
  }, [
    props.lastClauseCreated,
    props.lastClauseUpdated,
    props.lastClauseDeleted,
    props.lastClauseMerged,
  ]);

  const handleRowSelectChange = (selectedRows) => {
    setSelectedRows(selectedRows);
  };

  return (
    <Row>
      <Col xs={12} className="d-flex justify-content-between">
        <div>
          {selectedRows.length > 0 && (
            <>
              Bulk Actions{" "}
              <Dropdown className="d-inline-block">
                <Dropdown.Toggle as={CustomToggle}>
                  <h4>
                    <FontAwesomeIcon icon={faChevronDown} />
                  </h4>
                </Dropdown.Toggle>

                <Dropdown.Menu as={CustomMenu}>
                  <Dropdown.Item>Archive</Dropdown.Item>
                  <Dropdown.Item>Change Status</Dropdown.Item>
                  <Dropdown.Item>Change Availability</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </>
          )}
        </div>
        <Button
          type="submit"
          variant="tertiary"
          onClick={() => handleModelOpen("create")}
        >
          New Clause
        </Button>
      </Col>
      <Col xs={12}>
        <Spacer size={8} />
        <Table
          columns={columns}
          data={tableData}
          onRowSelectChange={handleRowSelectChange}
        />
      </Col>

      {(state.promptType === "create" ||
        state.promptType === "edit" ||
        state.promptType === "copy" ||
        state.promptType === "change_status" ||
        state.promptType === "change_availability") && (
        <PopupPrompt
          promptTitle={
            state.promptType === "create"
              ? "Add New Clause"
              : state.promptType === "edit"
              ? "Edit Clause"
              : state.promptType === "copy"
              ? "Copy Clause"
              : state.promptType === "change_status"
              ? "Change Status"
              : "Change Availability"
          }
          promptBody={
            <CreateEditClause
              promptType={state.promptType}
              selectedClause={state.selectedClause}
              onCancel={handleModalClose}
            />
          }
          show={state.showPrompt}
          onCancel={handleModalClose}
          onHide={handleModalClose}
        />
      )}
    </Row>
  );
};

const Table = ({ columns, data, onRowSelectChange }) => {
  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        pageSize: 100,
      },
    },
    useFilters,
    useGlobalFilter,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

  useEffect(() => {
    onRowSelectChange(selectedFlatRows);
  }, [selectedFlatRows]);

  return (
    <BootstrapTable bordered hover {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}>
                <div className="d-flex flex-column">
                  {column.render("Header")}
                  {column.canFilter && column.render("Filter")}
                </div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {page.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </BootstrapTable>
  );
};

function mapStateToProps(state) {
  return {
    action: state.action,
    manageClauses: state.clause.manageClauses,
    lastClauseCreated: state.clause.lastClauseCreated,
    lastClauseUpdated: state.clause.lastClauseUpdated,
    lastClauseDeleted: state.clause.lastClauseDeleted,
    lastClauseMerged: state.clause.lastClauseMerged,
  };
}

export default connect(mapStateToProps)(ManageClauseLibrary);
