import { dispatch } from "config/store";
import { actionTypes } from "./actionTypes";

export const getSchedules = (data) => {
  const action = {
    type: actionTypes.GET_SCHEDULES,
    config: {
      method: "get",
      url: `/scheduler`,
      data,
    },
  };

  dispatch(action);
};

export const getAllCompanies = () => {
  const action = {
    type: actionTypes.GET_ALL_COMPANIES,
    config: {
      method: "get",
      url: `/company/lookup`,
    },
  };

  dispatch(action);
};

export const searchCompanies = (params) => {
  let url = `/company/lookup?companyType=${params.companyType}&dealId=${
    params.dealId ? params.dealId : ""
  }&search=${params.search || ""}&order=DESC`;
  if (params.limit && params.page) {
    url = url + `&limit=${params.limit}&page=${params.page}`;
  }
  const action = {
    type: actionTypes.SEARCH_COMPANIES,
    config: {
      method: "get",
      url,
    },
  };

  dispatch(action);
};

export const getAllCompaniesBySearch = (params) => {
  let url = `/company/search?`;
  if (params.search) {
    url += `&search=${params.search}`;
  }
  if (params.companyType) {
    url += `&companyType=${params.companyType}`;
  }
  const action = {
    type: actionTypes.GET_ALL_COMPANIES_BY_SEARCH,
    config: {
      method: "get",
      url,
    },
  };

  dispatch(action);
};

export const updateDealLenders = (dealId, data) => {
  const action = {
    type: actionTypes.UPDATE_DEAL_LENDERS,
    config: {
      method: "put",
      url: `/deals/update/${dealId}/lenders`,
      data,
    },
  };

  dispatch(action);
};

export const getFilteredBorrowers = (params) => {
  const action = {
    type: actionTypes.GET_FILTERED_BORROWERS,
    config: {
      method: "get",
      url: `/company/lookup?order=DESC&companyType=Borrower&search=${
        params.search
      }${params.dealStatus}${params.loginQuery ? params.loginQuery : ""}${
        params.limit ? `&limit=${params.limit}` : ""
      }${params.page ? `&page=${params.page}` : ""}`,
    },
  };

  dispatch(action);
};

export const getFilteredLenders = (params) => {
  const action = {
    type: actionTypes.GET_FILTERED_LENDERS,
    config: {
      method: "get",
      url: `/company/lookup?order=DESC&companyType=Lender&search=${
        params.search
      }&lenderType=${params.lenderType}${
        params.limit ? `&limit=${params.limit}` : ""
      }${params.page ? `&page=${params.page}` : ""}&dealId=${
        params.dealId ? params.dealId : ""
      }&${params.programFilters || ""}`,
    },
  };

  dispatch(action);
};

export const getLendersByProgramTags = (params = { tags: "" }) => {
  const action = {
    type: actionTypes.GET_LENDERS_BY_PROGRAM_TAGS,
    config: {
      method: "get",
      url: `/company/lookup?order=DESC&companyType=Lender&tags=${params.tags}`,
    },
  };

  dispatch(action);
};

export const getLendersByProgramNotes = (params = { notes: "" }) => {
  const action = {
    type: actionTypes.GET_LENDERS_BY_PROGRAM_NOTES,
    config: {
      method: "get",
      url: `/company/lookup?order=DESC&companyType=Lender&notes=${params.notes}`,
    },
  };

  dispatch(action);
};
