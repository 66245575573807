import React from "react";
import moment from "moment";
import _ from "lodash";
import { renderToString } from "react-dom/server";
import NumberFormat from "react-number-format";
import { getKeyFromLocal } from "./lsUtils";
import constants from "config/constants";

export const generateSelectOption = (label, value) => {
  if (!label) {
    return null;
  }
  return {
    label: label,
    value: value || label,
  };
};

export const reorderArray = (arr, fromIndex, toIndex) => {
  return arr.reduce((prev, current, idx, self) => {
    if (fromIndex === toIndex) {
      prev.push(current);
    }
    if (idx === fromIndex) {
      return prev;
    }
    if (fromIndex < toIndex) {
      prev.push(current);
    }
    if (idx === toIndex) {
      prev.push(self[fromIndex]);
    }
    if (fromIndex > toIndex) {
      prev.push(current);
    }
    return prev;
  }, []);
};

export const getInitials = (string) => {
  let names = string.trim().split(" ");
  let initials = names[0].substring(0, 1).toUpperCase();

  if (names.length === 1) {
    initials = names[0].substring(0, 2).toUpperCase();
  } else if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }

  return initials;
};

export const isMobileDevice = function () {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
};

export const noop = () => null;

export const toDataURL = (src) => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.crossOrigin = "Anonymous";
    image.onload = function () {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      canvas.height = this.naturalHeight;
      canvas.width = this.naturalWidth;
      context.drawImage(this, 0, 0);
      const dataURL = canvas.toDataURL("image/jpeg");
      resolve(dataURL);
    };
    image.onerror = reject;
    image.src = src;
  });
};

export const renderInnerHtml = (innerHTML) => {
  const newDiv = document.createElement("div");
  newDiv.innerHTML = innerHTML;
  const innerText = newDiv.innerText;
  const innerTextWithParagraphSpacing = innerText.replace(/\n/g, "\n\n");
  return innerTextWithParagraphSpacing;
};

export const formatToCurrency = (value) => {
  if (!value) return "$0";
  return renderToString(
    <NumberFormat
      value={value}
      displayType={"text"}
      thousandSeparator={true}
      prefix={"$"}
      renderText={(formattedValue) => formattedValue}
    />
  );
};

export const formatToNumber = (value) => {
  if (!value) return "0";
  return renderToString(
    <NumberFormat
      value={value}
      displayType={"text"}
      thousandSeparator={true}
      renderText={(formattedValue) => formattedValue}
    />
  );
};

export const formatToPercent = (value) => {
  if (!value) return "0%";
  return renderToString(
    <NumberFormat
      value={value}
      displayType={"text"}
      thousandSeparator={false}
      suffix={"%"}
      renderText={(formattedValue) => formattedValue}
    />
  );
};

export const getDisclosureValue = (value) => {
  return value === null || value === "None" ? "No response" : value;
};

export const getSpreadTextValue = (quote) => {
  const {
    indexOption,
    spread = 0,
    index1 = "",
    indexTerm1 = 0,
    index2 = "",
    indexTerm2 = 0,
  } = quote;
  const spreadText = `${spread} basis points over the`;
  if (indexOption === "Greater of Two Indexes") {
    return `${spreadText} greater of ${_.toLower(
      indexTerm1
    )} ${index1} or ${_.toLower(indexTerm2)} ${index2}`;
  }
  if (indexOption === "Lesser of Two Indexes") {
    return `${spreadText} lesser of ${_.toLower(
      indexTerm1
    )} ${index1} or ${_.toLower(indexTerm2)} ${index2}`;
  }
  return `${spreadText} ${_.toLower(indexTerm1)} ${index1}`;
};

export const getFloorTextValue = ({ floorOption = "", floorRate }) =>
  floorOption === "No Floor"
    ? floorOption
    : `${formatToPercent(floorRate)} ${floorOption}`;

export const getIndexAsOfLabel = () =>
  `Index As of [${moment(new Date()).format("YYYY-MM-DD HH:mm")}]`;

export const hasQuoteSpread = (quotes = []) =>
  quotes?.some((quote) => quote.isRateOrSpread === "Spread over Index");

export const isPortifolioDeal = () => {
  return getKeyFromLocal(constants.TERMST_LOCAL_STORAGE.PORTIFOLIO_DEAL);
};
export const getDealCreationPath = () => {
  const isPortifolio = isPortifolioDeal();
  const dealId = getKeyFromLocal(constants.TERMST_LOCAL_STORAGE.DEAL_ID)
  return isPortifolio
    ? `/deal/portifolio-application/${dealId}`
    : "/deal/application";
};
