import React, { createRef } from "react";
import { matchPath, withRouter } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { reduxForm, Field } from "redux-form";
import { reduxFormSearch, reduxFormSelect } from "components/form/ReduxForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  searchDeals,
  getDeals,
  searchComms,
  getComms,
  searchDealFiles,
  searchCompanies,
  getFilteredBorrowers,
  getDealFilesById,
  getFilteredLenders,
  getAllUsers,
  getLendersByProgramTags,
  getLendersByProgramNotes,
  getTags,
} from "redux/actions";
import searchConfig from "components/search/searchConfig";
import { connect } from "react-redux";
import { components } from "react-select";
import Highlighter from "react-highlight-words";
import { DEFAULT_PAGINATION } from "containers/auth/Dashboard";

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchInput: "",
      searchDefault: "",
      searchId: null,
      placeholder: "Search...",
      tagsLabel: "Enter Tags: ",
      notesLabel: "Enter Notes: ",
      tagOptions: [],
    };

    this.searchResults = createRef();
    this.handleSearchBlur = this.handleSearchBlur.bind(this);
    this.handleSearchKeyDown = this.handleSearchKeyDown.bind(this);
    this.handleOptionBlur = this.handleOptionBlur.bind(this);
    this.handleOptionKeyDown = this.handleOptionKeyDown.bind(this);
    this.submitFn = this.submitFn.bind(this);
    this.searchDeals = this.searchDeals.bind(this);
    this.searchInbox = this.searchInbox.bind(this);
    this.searchFiles = this.searchFiles.bind(this);
    this.searchQuotes = this.searchQuotes.bind(this);
    this.searchBorrowers = this.searchBorrowers.bind(this);
    this.searchLenders = this.searchLenders.bind(this);

    this.currentFocus = createRef(0);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.tags !== this.props.tags) {
      const tagOptions = this.props.tags.map((item) => ({
        value: item._id,
        label: item.tag,
      }));
      this.setState({ tagOptions });
    }
  }

  handleSearchBlur(e) {
    if (
      e.relatedTarget !==
      this.searchResults.current.childNodes[0].childNodes[this.currentFocus]
    ) {
      this.searchResults.current.style.display = "none";
    }
  }

  handleSearchKeyDown(e) {
    if (e.keyCode === 40) {
      this.currentFocus = 0;
      this.searchResults.current.childNodes[0].childNodes[0].focus();
      e.preventDefault();
    } else if (e.keyCode === 38) {
      const resultsLength =
        this.searchResults.current.childNodes[0].childNodes.length - 1;

      this.currentFocus = resultsLength;

      this.searchResults.current.childNodes[0].childNodes[
        resultsLength
      ].focus();

      e.preventDefault();
    }
  }

  handleOptionBlur(e) {
    if (
      e.relatedTarget !==
      this.searchResults.current.childNodes[0].childNodes[this.currentFocus]
    ) {
      this.searchResults.current.style.display = "none";
    }
  }

  handleOptionKeyDown(e) {
    const resultsLength =
      this.searchResults.current.childNodes[0].childNodes.length;

    if (e.keyCode === 40) {
      if (this.currentFocus < resultsLength - 1) {
        this.currentFocus++;
      }

      this.searchResults.current.childNodes[0].childNodes[
        this.currentFocus
      ].focus();
      e.preventDefault();
    } else if (e.keyCode === 38) {
      if (this.currentFocus !== 0) {
        this.currentFocus--;
      }

      this.searchResults.current.childNodes[0].childNodes[
        this.currentFocus
      ].focus();

      e.preventDefault();
    }
  }

  submitFn() {
    const { searchParam } = this.props;

    switch (searchParam) {
      case "deals":
        this.searchDeals();

        break;
      case "inbox":
        this.searchInbox();

        break;
      case "files":
        this.searchFiles();

        break;
      case "quotes":
        this.searchQuotes();

        break;
      case "borrowersPlatform":
        this.searchBorrowers();

        break;
      case "lendersPlatform":
        this.searchLenders();

        break;
      case "lendersDeal":
        this.searchLenders();
      case "usersPlatform":
        this.searchManageUsers();
        break;
      default:
        break;
    }
  }

  searchDeals() {
    if (
      this.state.searchInput.length === 0 &&
      this.state.searchDefault.length === 0
    ) {
      getDeals({
        isPublic: false,
        archived: false,
        pagination: DEFAULT_PAGINATION,
      });

      return;
    }

    const { searchParam } = this.props;
    const searchArr = searchConfig[searchParam];
    const searchObj = searchArr[this.state.searchId];

    searchDeals({
      isPublic: false,
      activeDeals: searchObj ? searchObj.search.isActive : "",
      isHotel: searchObj ? searchObj.search.isHotel : "",
      isApartment: searchObj ? searchObj.search.isApartment : "",
      isRefinance: searchObj ? searchObj.search.isRefinance : "",
      search: this.state.searchInput,
    });
  }

  searchInbox() {
    const { searchParam, location } = this.props;
    const match = matchPath(location.pathname, { path: "/deal/inbox/:id" });

    if (
      this.state.searchInput.length === 0 &&
      this.state.searchDefault.length === 0
    ) {
      getComms({
        dealId: match.params.id,
        archived: false,
        page: 1,
      });

      return;
    }

    const searchArr = searchConfig[searchParam];
    const searchObj = searchArr[this.state.searchId];

    searchComms({
      dealId: match.params.id,
      page: 1,
      type: searchObj ? searchObj.search.type : undefined,
      isAnswered: searchObj ? searchObj.search.isAnswered : undefined,
      archived: searchObj ? searchObj.search.archived : undefined,
      search: this.state.searchInput,
    });
  }

  searchFiles() {
    const { location } = this.props;
    const match = matchPath(location.pathname, { path: "/deal/files/:dealId" });
    if (this.state.searchInput.length === 0) {
      getDealFilesById(match.params.dealId);
      return;
    }
    searchDealFiles(match.params.dealId, this.state.searchInput);
  }

  searchQuotes() {
    if (this.state.searchInput.length === 0) {
      return;
    }
  }

  searchBorrowers() {
    if (
      this.state.searchInput.length === 0 &&
      this.state.searchDefault.length === 0
    ) {
      searchCompanies({
        companyType: "Borrower",
      });

      return;
    }

    const { searchParam } = this.props;
    const searchArr = searchConfig[searchParam];
    const searchObj = searchArr[this.state.searchId];

    getFilteredBorrowers({
      dealStatus: searchObj ? searchObj.search.dealStatus : "",
      search: this.state.searchInput,
    });
  }

  searchLenders() {
    const { searchParam } = this.props;
    const searchArr = searchConfig[searchParam];
    const searchObj = searchArr[this.state.searchId];

    if (this.isTags()) {
      this.searchTags();
      return;
    }

    if (this.isNotes()) {
      this.searchNotes();
      return;
    }

    if (
      this.state.searchInput.length === 0 &&
      this.state.searchDefault.length === 0
    ) {
      searchCompanies({
        companyType: "Lender",
      });
      return;
    }

    getFilteredLenders({
      lenderType: searchObj ? searchObj.search.lenderType : "",
      dealId: searchObj ? searchObj.search.dealId : "",
      search: this.state.searchInput,
    });
  }

  searchTags() {
    this.setState({ searchInput: this.state.tagsLabel });
    this.focusInput();
    getTags();
  }

  searchNotes() {
    this.setState({ searchInput: this.state.notesLabel });
    this.focusInput();
  }

  searchManageUsers() {
    if (
      this.state.searchInput.length === 0 &&
      this.state.searchDefault.length === 0
    ) {
      getAllUsers({});
      return;
    }

    getAllUsers({
      search: this.state.searchInput,
    });
  }

  focusInput() {
    const input = document.getElementsByName("searchParam")[0];
    if (input && this.searchResults.current) {
      setTimeout(() => {
        this.searchResults.current.style.display = "none";
        input.focus();
      });
    }

    const dropdownInput = document.querySelector(
      ".react-select-container_tags-dropdown input"
    );
    if (dropdownInput) {
      setTimeout(() => {
        dropdownInput.focus();
      });
    }
  }

  isTags() {
    const { searchParam } = this.props;
    const searchArr = searchConfig[searchParam];
    const searchObj = searchArr?.[this.state.searchId];
    return Boolean(searchObj?.search?.tags);
  }

  isNotes() {
    const { searchParam } = this.props;
    const searchArr = searchConfig[searchParam];
    const searchObj = searchArr?.[this.state.searchId];
    return Boolean(searchObj?.search?.notes);
  }

  isTagsOrNotes() {
    return this.isTags() || this.isNotes();
  }

  handleChange = (e) => {
    let value = e.target.value;

    if (this.isTagsOrNotes()) {
      if (this.isTags()) {
        value = value.includes(this.state.tagsLabel)
          ? value
          : this.state.tagsLabel;
      } else {
        value = value.includes(this.state.notesLabel)
          ? value
          : this.state.notesLabel;
      }
      this.setState({ searchInput: value });
    } else {
      this.setState(
        {
          searchInput: value,
          searchDefault: "",
          searchId: null,
        },
        function () {
          if (this.state.searchInput.length > 0) {
            this.searchResults.current.style.display = "none";
          } else {
            this.searchResults.current.style.display = "block";
          }
        }
      );
    }
  };

  handleSubmitTagsOrNotes() {
    const { searchInput, tagsLabel, notesLabel } = this.state;
    if (this.isTags()) {
      const tags = searchInput.replace(tagsLabel, "");
      getLendersByProgramTags({ tags });
    } else {
      const notes = searchInput.replace(notesLabel, "");
      getLendersByProgramNotes({ notes });
    }
  }

  handleChangeTagsDropdown = (selected) => {
    const tags = selected.label;
    getLendersByProgramTags({ tags });
  };

  render() {
    const { searchParam, handleSubmit, isMobile } = this.props;

    return (
      <>
        {searchParam && searchParam !== "none" ? (
          <Form
            className={
              isMobile
                ? "d-inline-block no-padding no-border search-form search-form-mobile"
                : "d-inline-block no-padding no-border search-form search-form-not-mobile"
            }
            onSubmit={handleSubmit(this.submitFn)}
          >
            {!this.isTags() && (
              <>
                <Field
                  className="form-control"
                  component={reduxFormSearch}
                  name="searchParam"
                  type="text"
                  placeholder={this.state.placeholder || "Search..."}
                  onFocus={() => {
                    if (!this.isTagsOrNotes()) {
                      this.searchResults.current.style.display = "block";
                    }
                  }}
                  value={this.state.searchInput}
                  onBlur={(e) => this.handleSearchBlur(e)}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      e.preventDefault();
                      if (this.isTagsOrNotes()) {
                        this.handleSubmitTagsOrNotes();
                      } else {
                        this.submitFn();
                      }
                    } else {
                      this.handleSearchKeyDown(e);
                    }
                  }}
                  onChange={this.handleChange}
                  inputValue={
                    this.state.searchInput.length > 0
                      ? this.state.searchInput
                      : this.state.searchDefault
                  }
                />
                {(this.state.searchInput.length > 0 ||
                  this.state.searchDefault.length > 0) && (
                  <Button
                    className="clear-icon color-medium-gray"
                    type="submit"
                    variant=""
                    onClick={() => {
                      this.setState(
                        {
                          searchInput: "",
                          searchDefault: "",
                          searchId: null,
                        },
                        function () {
                          this.submitFn();
                        }
                      );
                    }}
                  >
                    <FontAwesomeIcon className="search-icon" icon={faTimes} />
                  </Button>
                )}
                <Button className="search-icon" type="submit" variant="">
                  <FontAwesomeIcon className="search-icon" icon={faSearch} />
                </Button>
                <div
                  className="search-results-wrapper"
                  ref={this.searchResults}
                  style={{ display: "none" }}
                >
                  <div
                    className="search-results"
                    onBlur={(e) => this.handleOptionBlur(e)}
                    onKeyDown={(e) => this.handleOptionKeyDown(e)}
                  >
                    {searchParam &&
                      searchConfig[searchParam].map((option, idx) => {
                        return (
                          <p
                            key={idx}
                            tabIndex="1"
                            onMouseDown={() => {
                              this.setState(
                                {
                                  searchInput: "",
                                  searchDefault: option.label,
                                  searchId: option.id,
                                },
                                function () {
                                  if (this.searchResults.current) {
                                    this.searchResults.current.style.display =
                                      "none";
                                  }
                                  this.currentFocus = 0;
                                  this.submitFn();
                                }
                              );
                            }}
                          >
                            {option.icon && (
                              <FontAwesomeIcon
                                className="font-18 mr-3 search-option-icon"
                                icon={option.icon}
                              />
                            )}
                            <span className="color-medium-gray">
                              {option.label}
                            </span>
                          </p>
                        );
                      })}
                  </div>
                </div>
              </>
            )}
            {this.isTags() && (
              <Field
                defaultMenuIsOpen
                className="form-control"
                classes="react-select-container_tags-dropdown"
                classNamePrefix="react-select"
                component={reduxFormSelect}
                name="searchTag"
                type="select"
                options={this.state.tagOptions}
                placeholder="Search Tags..."
                components={{
                  Option,
                  DropdownIndicator,
                }}
                onClearValue={() => {
                  this.props.change("searchTag", "");
                  this.setState(
                    { searchInput: "", searchDefault: "", searchId: null },
                    function () {
                      this.submitFn();
                    }
                  );
                }}
                onChange={this.handleChangeTagsDropdown}
              />
            )}
          </Form>
        ) : (
          <></>
        )}
      </>
    );
  }
}

const Option = (props) => {
  return (
    <components.Option {...props}>
      <Highlighter
        highlightClassName="highlighted"
        searchWords={[props.selectProps.inputValue]}
        autoEscape={true}
        textToHighlight={props.label}
      />
    </components.Option>
  );
};

const DropdownIndicator = ({ innerRef, innerProps, selectProps }) => {
  return (
    <span
      ref={innerRef}
      {...innerProps}
      className="icon-container cursor-pointer d-flex p-2 mr-2"
    >
      <FontAwesomeIcon icon={faTimes} onClick={selectProps.onClearValue} />
    </span>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    tags: state.tag.tags,
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "Search",
    enableReinitialize: true,
  })(withRouter(Search))
);
