import React, { useState } from "react";
import parse from 'html-react-parser';


const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p className="text">
      {isReadMore ? text.slice(0, 100) : parse(text)}
      <span onClick={toggleReadMore} className="read-or-hide">
        {isReadMore ? "...read more" : " show less"}
      </span>
      <span className="read-or-hide">
        <br />
        {!isReadMore && text.length > 800
          ? "This message thread is too long to view here"
          : ""}
      </span>
    </p>
  );
};
export default ReadMore;
