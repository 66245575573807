import React from "react";
import { Spinner } from "react-bootstrap";

const Loader = (props) => {
  return (
    <>
      <div
        style={props.style}
        className={
          props.files ? "files-loader-wrapper loader-wrapper" : "loader-wrapper"
        }
      >
        <Spinner animation="border" role="status" variant="primary">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    </>
  );
};

export default Loader;
