import React from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
// import { reduxFormInput as Input } from "components/form/ReduxForm";
// import { loginAsUser, getUsers } from "redux/actions";
import constants from "config/constants";
// import _ from "lodash";
import {
  removeKeyFromLocal,
  storeJsonInLocal,
  getKeyFromLocal,
} from "utils/lsUtils";

// const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
//   <Button
//     ref={ref}
//     variant="fake-link no-padding"
//     onClick={(e) => {
//       e.preventDefault();
//       onClick(e);
//     }}
//   >
//     {children}
//   </Button>
// ));

const LoginAs = (props) => {
  //   const [searchkey, setSearchKey] = React.useState("");
  //   const fetchUsers = () => {
  //     if (searchkey.trim()) {
  //       getUsers({
  //         search: searchkey,
  //         accountActivated: true,
  //       });
  //     }
  //   };
  //   const handleLoginAsUser = (id) => {
  //     loginAsUser(id);
  //   };

  const handleExit = () => {
    const prevUserId = getKeyFromLocal(
      constants.TERMST_LOCAL_STORAGE.PREV_JWT_KEY
    );
    // Keep the origanl token in JWT_KEY
    storeJsonInLocal({
      [constants.TERMST_LOCAL_STORAGE.JWT_KEY]: prevUserId,
    });
    removeKeyFromLocal(constants.TERMST_LOCAL_STORAGE.PREV_JWT_KEY);
    window.location.reload(false);
  };
  //   const LookupBlock =
  //     props.whoami?.role === "Admin" ? (
  //       <Dropdown style={{ display: "block" }} drop="down">
  //         <Dropdown.Toggle as={CustomToggle}>View As</Dropdown.Toggle>
  //         <Dropdown.Menu style={{ width: 200 }}>
  //           <Input
  //             className="form-control"
  //             name="search"
  //             type="text"
  //             placeholder="Search users..."
  //             meta={{}}
  //             input={{
  //               value: searchkey,
  //               onChange: (e) => setSearchKey(e.target.value),
  //               onKeyDown: (e) => {
  //                 if (e.keyCode === 13) {
  //                   e.preventDefault();
  //                   fetchUsers();
  //                 }
  //               },
  //             }}
  //             style={{ margin: "0px 12px -10px 10px" }}
  //           />
  //           {props.action.GET_USERS.isFetching ? (
  //             <Dropdown.Item>Searching...</Dropdown.Item>
  //           ) : (
  //             props.users.map((user) => (
  //               <Dropdown.Item
  //                 onClick={() => handleLoginAsUser(user._id)}
  //                 title={`${user.firstName} ${user.lastName} | ${user.email} | ${
  //                   user.role
  //                 } | ${_.get(user, "company.companyName", "NA")}`}
  //               >
  //                 {user.firstName} {user.lastName}
  //                 <br />
  //                 <div className="login-as-item">
  //                   {user.role} | {_.get(user, "company.companyName", "NA")}
  //                 </div>
  //               </Dropdown.Item>
  //             ))
  //           )}
  //         </Dropdown.Menu>
  //       </Dropdown>
  //     ) : null;
  const className = props.isMobile
    ? "d-inline-block nav-link search-form search-form-mobile"
    : "d-inline-block nav-link search-form search-form-not-mobile";
  return (
    <div className={className}>
      {getKeyFromLocal(constants.TERMST_LOCAL_STORAGE.PREV_JWT_KEY) ? (
        <>
          <span
            title={`${props.whoami.firstName} ${props.whoami.lastName} | ${props.whoami.email} | ${props.whoami.role}`}
          >
            You are viewing as {props.whoami?.firstName}
          </span>{" "}
          &nbsp;&nbsp;
          <Button variant="fake-link no-padding" onClick={handleExit}>
            Exit
          </Button>
        </>
      ) : null}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    action: state.action,
    whoami: state.auth.whoami,
    users: state.user.users,
  };
}

export default connect(mapStateToProps)(LoginAs);
