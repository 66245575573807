import {
  faFolder,
  faEnvelope,
  faInbox,
  faEnvelopeOpenText,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFileAlt,
  faQuestionCircle,
  faCommentAlt,
} from "@fortawesome/free-regular-svg-icons";
import { getKeyFromLocal } from "utils/lsUtils";
import constants from "config/constants";

const dealId = getKeyFromLocal(constants.TERMST_LOCAL_STORAGE.CURRENT_DEAL)._id;

export default {
  files: [
    { id: 0, label: "Is Folder", icon: faFolder },
    { id: 1, label: "Is File", icon: faFileAlt },
  ],
  deals: [
    {
      id: 0,
      label: "Active Deals",
      icon: null,
      search: {
        isActive: true,
        isHotel: "",
        isApartment: "",
        isRefinance: "",
      },
    },
    {
      id: 1,
      label: "Apartment Deals",
      icon: null,
      search: {
        isActive: "",
        isHotel: "",
        isApartment: true,
        isRefinance: "",
      },
    },
    {
      id: 2,
      label: "Hotel Deals",
      icon: null,
      search: {
        isActive: "",
        isHotel: true,
        isApartment: "",
        isRefinance: "",
      },
    },
    {
      id: 3,
      label: "Refinances",
      icon: null,
      search: {
        isActive: "",
        isHotel: "",
        isApartment: "",
        isRefinance: true,
      },
    },
  ],
  inbox: [
    {
      id: 0,
      label: "Is Question",
      icon: faQuestionCircle,
      search: {
        type: "Question",
        isAnswered: undefined,
        archived: false,
      },
    },
    {
      id: 1,
      label: "Is Message",
      icon: faCommentAlt,
      search: {
        type: "Message",
        isAnswered: undefined,
        archived: false,
      },
    },
    {
      id: 2,
      label: "Archived",
      icon: faInbox,
      search: {
        type: undefined,
        isAnswered: undefined,
        archived: true,
      },
    },
    {
      id: 3,
      label: "Answered",
      icon: faEnvelopeOpenText,
      search: {
        type: undefined,
        isAnswered: true,
        archived: false,
      },
    },
    {
      id: 4,
      label: "Unanswered",
      icon: faEnvelope,
      search: {
        type: undefined,
        isAnswered: false,
        archived: false,
      },
    },
  ],
  quotes: [],
  usersPlatform: [],
  lendersPlatform: [
    {
      id: 0,
      label: "Hard Money Lenders",
      icon: null,
      search: {
        lenderType: "Hard Money",
      },
    },
    {
      id: 1,
      label: "CMBS Lenders",
      icon: null,
      search: {
        lenderType: "CMBS",
      },
    },
    {
      id: 2,
      label: "Life Insurance Lenders",
      icon: null,
      search: {
        lenderType: "Life Insurance",
      },
    },
    {
      id: 3,
      label: "Nationwide Banks",
      icon: null,
      search: {
        lenderType: "Bank",
      },
    },
    {
      id: 4,
      label: "Search for Tags",
      icon: null,
      search: {
        tags: true,
      },
    },
    {
      id: 5,
      label: "Search for Notes",
      icon: null,
      search: {
        notes: true,
      },
    },
  ],
  lendersDeal: [
    {
      id: 0,
      label: "Hard Money Lenders",
      icon: null,
      search: {
        lenderType: "Hard Money",
        dealId,
      },
    },
    {
      id: 1,
      label: "CMBS Lenders",
      icon: null,
      search: {
        lenderType: "CMBS",
        dealId,
      },
    },
    {
      id: 2,
      label: "Life Insurance Lenders",
      icon: null,
      search: {
        lenderType: "Life Insurance",
        dealId,
      },
    },
    {
      id: 3,
      label: "Nationwide Banks",
      icon: null,
      search: {
        lenderType: "Bank",
        dealId,
      },
    },
  ],
  borrowersPlatform: [
    {
      id: 0,
      label: "Has Active Deal",
      icon: null,
      search: {
        dealStatus: "Active",
      },
    },
    {
      id: 1,
      label: "Has Non-Submitted Deal",
      icon: null,
      search: {
        dealStatus: "Under review",
      },
    },
    {
      id: 2,
      label: "Has Closed a Deal in the Past",
      icon: null,
      search: {
        dealStatus: "Closed",
      },
    },
    {
      id: 3,
      label: "Has a Deal Under Application",
      icon: null,
      search: {
        dealStatus: "In progress",
      },
    },
  ],
};
