import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Row, Col } from 'react-bootstrap';
import Spacer from 'components/layout/Spacer';

class ProfileCompanies extends React.Component {
  render() {
    const { company } = this.props.user;

    return (
      <>
        <Row>
          <Col xs="12">
            <h3 className="bold">Company Affiliation</h3>
            <Spacer size={30} />
          </Col>
        </Row>
        <Row>
          {!company
            ?
            <>
              <Col xs="12">
                <p className="text-danger">Don't have a company </p>
                <Spacer size={10} />
              </Col>
              <Col xs="12">
                <Button as={Link} to="/company/create">Create Company</Button>
                <Spacer size={20} />
              </Col>
            </>
            :
            <Col xs="12" sm="4">
              <div className="panel">
                <h5 className="bold"><Link to={"/company/profile/" + company._id}>{company.companyName}</Link></h5>
              </div>
            </Col>
          }
        </Row>
        <Spacer size={20} />
      </>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.user.userProfile
  };
}
export default connect(mapStateToProps)(ProfileCompanies);