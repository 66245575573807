import React from "react";
import { connect } from "react-redux";
import { Container } from "react-bootstrap";
import StepWizard from "react-step-wizard";
import { isEmpty, partition } from "lodash";
import { reset, getFormValues } from "redux-form";
import { toast } from "react-toastify";

import InitialStep from "containers/program/WizardSteps/InitialStep";
import InvestmentStep from "containers/program/WizardSteps/InvestmentStep";
import OtherStep from "containers/program/WizardSteps/OtherStep";
import BrokerStep from "containers/program/WizardSteps/BrokerStep";
import GeographyStep from "containers/program/WizardSteps/GeographyStep";
import SponsorshipStep from "containers/program/WizardSteps/SponsorshipStep";
import { getDropdown, getProgramsById } from "redux/actions";
import BucketStep from "./WizardSteps/BucketStep";
import api from "utils/api";
import programCategories from "./constants/programCategories";
import Loader from "components/ui/Loader";
import NotesAndTags from "./WizardSteps/NotesAndTags";
import Spacer from "components/layout/Spacer";

class ProgramCreateEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOverflow: false,
      loadedDropdowns: [],
      buckets:
        (this.props.isUpdate || this.props.isClone) &&
        this.props.selectedProgram
          ? this.props.selectedProgram.buckets
          : [],
      bucketsWithFinalData: [],
      loading: false,
      isProgramCreated: this.props.isUpdate,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.isUpdate !== prevProps.isUpdate) {
      this.setState({ isProgramCreated: this.props.isUpdate });
    }
  }

  toggleOverflow = (data) => {
    this.setState({ isOverflow: !this.state.isOverflow });

    setTimeout(() => {
      this.setState({ isOverflow: !this.state.isOverflow });
    }, 700);
  };

  getDropdownFromAPI = (value) => {
    let canGet = false;

    if (this.state.loadedDropdowns.length > 0) {
      this.state.loadedDropdowns.some((dropdown) => {
        if (dropdown === value) {
          canGet = false;

          return true;
        } else {
          canGet = true;

          return false;
        }
      });
    } else {
      getDropdown(value);

      this.setState((prevState) => ({
        loadedDropdowns: [...prevState.loadedDropdowns, value],
      }));
    }

    if (canGet) {
      getDropdown(value);

      this.setState((prevState) => ({
        loadedDropdowns: [...prevState.loadedDropdowns, value],
      }));
    }
  };

  getDropdownValues = (type) => {
    const { dropdowns } = this.props;
    if (isEmpty(dropdowns[type])) {
      return [];
    }
    return dropdowns[type];
  };

  normalizeData = (data) => {
    const updatedData = { ...data };
    for (let property in data) {
      if (
        !Array.isArray(updatedData[property]) &&
        typeof updatedData[property] === "object" &&
        updatedData[property]
      ) {
        updatedData[property] = updatedData[property].value;
      }
    }
    return updatedData;
  };

  formatData = (data) => {
    const { selectedProgram, whoami } = this.props;
    const formattedData = { ...data };
    Object.entries(data).forEach(([property, value]) => {
      switch (property) {
        case "notesItemContent": {
          if (value) {
            const origNotes = selectedProgram.notes || [];
            formattedData.notes = [
              ...origNotes,
              {
                content: value,
                createdBy: whoami._id,
              },
            ];
            delete formattedData.notesItemContent;
          }
          break;
        }
        case "tags": {
          formattedData.tags = (formattedData?.tags || []).map(
            (item) => item.value
          );
          break;
        }
        default:
          break;
      }
    });
    return formattedData;
  };

  moveModelSmoothly = () => {
    setTimeout(() => {
      try {
        document
          .getElementsByClassName("modal")[0]
          .scrollTo({ top: 0, behavior: `smooth` });
      } catch (error) {
        console.log("Error", error);
      }
    }, 700);
  };

  handleNext = (next) => {
    next();
    this.moveModelSmoothly();
  };

  handlePrevious = (previous) => {
    return () => {
      previous();
      this.moveModelSmoothly();
    };
  };

  handleCreateProgram = (data) =>
    api.request({ url: "/programs", method: "post", data });

  handleUpdateProgram = (id, data) =>
    api.request({ url: `/programs/${id}`, method: "put", data });

  handleCreateBucket = (data) =>
    api.request({ url: `/buckets`, method: "post", data });

  handleUpdateBucket = (id, data) =>
    api.request({ url: `/buckets/${id}`, method: "put", data });

  handleDeleteBucket = (id) =>
    api.request({ url: `/buckets/${id}`, method: "delete" });

  handleBucketsChange = (buckets) => this.setState({ buckets });

  handleBucketsWithFinalDataChange = (bucketsWithFinalData) =>
    this.setState({ bucketsWithFinalData });

  handleBuckets = (recentProgram) => {
    const { bucketsWithFinalData } = this.state;
    const { selectedProgram } = this.props;
    let deletedB = [];
    let updatedB = [];
    let newB = [];
    if (this.props.isClone || !selectedProgram._id) {
      newB = bucketsWithFinalData;
    } else {
      deletedB =
        selectedProgram.buckets?.filter(
          (bkt) => !bucketsWithFinalData.some((uBkt) => bkt._id == uBkt._id)
        ) || [];
      [updatedB = [], newB = []] = partition(bucketsWithFinalData, (bkt) =>
        Boolean(bkt._id)
      );
    }

    const allPs = [];
    deletedB.map((b) => allPs.push(this.handleDeleteBucket(b._id)));
    updatedB.map((b) =>
      allPs.push(
        this.handleUpdateBucket(
          b._id,
          this.normalizeData({ ...b, programId: recentProgram._id })
        )
      )
    );
    newB.map((b) =>
      allPs.push(
        this.handleCreateBucket(
          this.normalizeData({ ...b, programId: recentProgram._id })
        )
      )
    );
    return Promise.all(allPs)
      .then(this.onSubmitComplete)
      .catch(this.onSubmitFail);
  };

  onSubmitFail = (error) => {
    this.setState({ loading: false });
    toast.error(error?.response?.data?.error || "Program saving failed", {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  onSubmitComplete = () => {
    // this.resetWholeForm();
    this.setState({ loading: false });
    // if (this.props.onSubmit) {
    // this.props.onSubmit();
    // }
  };

  handleProgramSubmit = (stepName, next) => {
    let { formValues, selectedProgram } = this.props;

    formValues.primaryContactName = JSON.stringify(formValues.primaryContact);
    formValues.primaryContactEmail = JSON.stringify(formValues.primaryContact);
    formValues = this.normalizeData(formValues);
    formValues = this.formatData(formValues);
    formValues.isExit = !Boolean(next);

    if (this.state.isProgramCreated) {
      return this.handleUpdateProgram(selectedProgram._id, formValues)
        .then((success) => {
          if (stepName === "bucketStep") {
            return this.handleBuckets(success.data.body);
          }
          return Promise.resolve();
        })
        .catch((error) => {
          this.onSubmitFail(error);
          return Promise.reject();
        });
    } else {
      return this.handleCreateProgram(formValues)
        .then((success) => {
          this.setState({ isProgramCreated: true });
          // this.handleBuckets(success.data);
          getProgramsById(success.data._id);
          return Promise.resolve();
        })
        .catch((error) => {
          this.onSubmitFail(error);
          return Promise.reject();
        });
    }
  };

  handleSaveAndContinue = (next, stepName) => {
    this.setState({ loading: true });
    this.handleProgramSubmit(stepName, next).then(() => {
      this.setState({ loading: false });
      if (next) {
        this.handleNext(next);
      } else {
        getProgramsById(this.props.selectedProgram._id);
        this.props.onCancel();
      }
    });
  };

  resetWholeForm = () => {
    const { dispatch } = this.props;
    dispatch(reset("ProgramCreateInitialStep"));
    dispatch(reset("ProgramCreateInvestmentStep"));
    dispatch(reset("ProgramCreateBrokerStep"));
    dispatch(reset("ProgramCreateInvestmentStep"));
    dispatch(reset("ProgramCreateOtherStep"));
    dispatch(reset("ProgramCreateSponsorshipStep"));
    dispatch(reset("ProgramCreateNotesAndTags"));
  };

  componentWillUnmount() {
    this.resetWholeForm();
  }

  render() {
    const { companyId, isCompanyAdmin, selectedProgram, formValues, whoami } =
      this.props;

    return (
      <Container className="form-wizard-wrapper no-border no-padding" fluid>
        <StepWizard
          className={"" + (this.state.isOverflow ? "overflow-hidden" : "")}
          onStepChange={this.toggleOverflow}
        >
          <InitialStep
            isCompanyAdmin={isCompanyAdmin}
            companyId={companyId}
            getDropdownFromAPI={this.getDropdownFromAPI}
            getDropdownValues={this.getDropdownValues}
            selectedProgram={selectedProgram}
            isUpdate={this.props.isUpdate}
            isClone={this.props.isClone}
            setStepName={this.props.setStepName}
            handleSaveAndContinue={this.handleSaveAndContinue}
          />
          {
            //Hide investment step for Min+OpenWrite && Agency
            !programCategories.minOpenWrite.includes(
              formValues.programTypes && formValues.programTypes.value
            ) &&
              !programCategories.agency.includes(
                formValues.programTypes && formValues.programTypes.value
              ) && (
                <InvestmentStep
                  getDropdownFromAPI={this.getDropdownFromAPI}
                  getDropdownValues={this.getDropdownValues}
                  handlePrevious={this.handlePrevious}
                  selectedProgram={selectedProgram}
                  isUpdate={this.props.isUpdate}
                  isClone={this.props.isClone}
                  setStepName={this.props.setStepName}
                  handleSaveAndContinue={this.handleSaveAndContinue}
                />
              )
          }
          {
            //Hide bucket step for Min+OpenWrite
            !programCategories.minOpenWrite.includes(
              formValues.programTypes && formValues.programTypes.value
            ) && (
              <BucketStep
                buckets={this.state.buckets}
                getDropdownFromAPI={this.getDropdownFromAPI}
                getDropdownValues={this.getDropdownValues}
                handlePrevious={this.handlePrevious}
                handleBucketsChange={this.handleBucketsChange}
                handleBucketsWithFinalDataChange={
                  this.handleBucketsWithFinalDataChange
                }
                handleProgramSubmit={this.handleProgramSubmit}
                isFinalStep={
                  programCategories.equity.includes(
                    formValues.programTypes && formValues.programTypes.value
                  ) ||
                  programCategories.mezz.includes(
                    formValues.programTypes && formValues.programTypes.value
                  )
                }
                setStepName={this.props.setStepName}
                handleSaveAndContinue={this.handleSaveAndContinue}
              />
            )
          }
          {
            //Hide sponsorship step for equity, Min+OpenWrite and mezz pref
            !programCategories.minOpenWrite.includes(
              formValues.programTypes && formValues.programTypes.value
            ) &&
              !programCategories.equity.includes(
                formValues.programTypes && formValues.programTypes.value
              ) &&
              !programCategories.mezz.includes(
                formValues.programTypes && formValues.programTypes.value
              ) && (
                <SponsorshipStep
                  getDropdownFromAPI={this.getDropdownFromAPI}
                  getDropdownValues={this.getDropdownValues}
                  handlePrevious={this.handlePrevious}
                  selectedProgram={selectedProgram}
                  isUpdate={this.props.isUpdate}
                  isClone={this.props.isClone}
                  setStepName={this.props.setStepName}
                  handleSaveAndContinue={this.handleSaveAndContinue}
                />
              )
          }
          {
            //Hide geography step for equity and mezz pref
            !programCategories.equity.includes(
              formValues.programTypes && formValues.programTypes.value
            ) &&
              !programCategories.mezz.includes(
                formValues.programTypes && formValues.programTypes.value
              ) && (
                <GeographyStep
                  getDropdownFromAPI={this.getDropdownFromAPI}
                  getDropdownValues={this.getDropdownValues}
                  handlePrevious={this.handlePrevious}
                  selectedProgram={selectedProgram}
                  isUpdate={this.props.isUpdate}
                  isClone={this.props.isClone}
                  setStepName={this.props.setStepName}
                  handleSaveAndContinue={this.handleSaveAndContinue}
                />
              )
          }
          {
            //Hide other step for equity and mezz pref
            !programCategories.equity.includes(
              formValues.programTypes && formValues.programTypes.value
            ) &&
              !programCategories.mezz.includes(
                formValues.programTypes && formValues.programTypes.value
              ) && (
                <OtherStep
                  getDropdownFromAPI={this.getDropdownFromAPI}
                  getDropdownValues={this.getDropdownValues}
                  handlePrevious={this.handlePrevious}
                  selectedProgram={selectedProgram}
                  isUpdate={this.props.isUpdate}
                  isClone={this.props.isClone}
                  setStepName={this.props.setStepName}
                  handleSaveAndContinue={this.handleSaveAndContinue}
                />
              )
          }
          {
            //Hide broker step for equity and mezz pref
            !programCategories.equity.includes(
              formValues.programTypes && formValues.programTypes.value
            ) &&
              !programCategories.mezz.includes(
                formValues.programTypes && formValues.programTypes.value
              ) && (
                <BrokerStep
                  getDropdownFromAPI={this.getDropdownFromAPI}
                  getDropdownValues={this.getDropdownValues}
                  handlePrevious={this.handlePrevious}
                  companyId={companyId}
                  selectedProgram={selectedProgram}
                  isUpdate={this.props.isUpdate}
                  isClone={this.props.isClone}
                  buckets={this.state.buckets}
                  loading={this.state.loading}
                  handleProgramSubmit={this.handleProgramSubmit}
                  setStepName={this.props.setStepName}
                  handleSaveAndContinue={this.handleSaveAndContinue}
                />
              )
          }
        </StepWizard>
        {whoami.role === "Admin" && (
          <>
            <Spacer size={20} />
            <NotesAndTags
              selectedProgram={selectedProgram}
              isUpdate={this.props.isUpdate}
              isClone={this.props.isClone}
            />
          </>
        )}
        {this.state.loading && <Loader />}
      </Container>
    );
  }
}

function mapStateToProps(state, props) {
  const { companyId } = props;
  const initialStep = getFormValues("ProgramCreateInitialStep")(state);
  const investmentStep = getFormValues("ProgramCreateInvestmentStep")(state);
  const sponsorshipStep = getFormValues("ProgramCreateSponsorshipStep")(state);
  const geographyStep = getFormValues("ProgramCreateGeographyStep")(state);
  const otherStep = getFormValues("ProgramCreateOtherStep")(state);
  const brokerStep = getFormValues("ProgramCreateBrokerStep")(state);
  const notesAndTags = getFormValues("ProgramCreateNotesAndTags")(state);

  const formValues = {};
  Object.assign(
    formValues,
    { companyId },
    initialStep,
    investmentStep,
    sponsorshipStep,
    geographyStep,
    otherStep,
    brokerStep,
    notesAndTags
  );

  return {
    formValues,
    dropdowns: state.program.dropdowns,
    selectedProgram: state.program?.program || {},
    whoami: state.auth.whoami,
  };
}

export default connect(mapStateToProps)(ProgramCreateEdit);
