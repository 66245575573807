import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import {
  Accordion,
  Dropdown,
  OverlayTrigger,
  Tooltip,
  Image,
  Form,
  Card,
  Container,
  Row,
  Col,
  Button,
  Badge,
  Spinner,
} from "react-bootstrap";

import { Field, reduxForm, reset, isPristine } from "redux-form";
import { reduxFormTextarea } from "components/form/ReduxForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLock,
  faArchive,
  faInbox,
  faCaretDown,
  faCaretUp,
  faSignInAlt,
  faUnlockAlt,
  faEllipsisH,
  faPaperclip,
  faTimes,
  faPlusCircle,
  faFileAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  faQuestionCircle,
  faCommentAlt,
  faImage,
  faTimesCircle,
} from "@fortawesome/free-regular-svg-icons";
import {
  sendMessage,
  archiveMessage,
  unmergeMessage,
  answerComm,
  assignToComm,
  editCommGroups,
  removeFromComm,
  setPublicStatus,
} from "redux/actions";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import PopupPrompt from "components/ui/PopupPrompt";
import CreateMessage from "containers/deals/Communications/actions/CreateMessage";
import UploadFiles from "containers/deals/Communications/actions/UploadFiles";
import Spacer from "components/layout/Spacer";
import moment from "moment";
import imageCompression from "browser-image-compression";
import Loader from "components/ui/Loader";
import MultiDropdown from "./MultiDropdown";
import { ALLOWED_FILE_EXTS } from "../FileManager/constants";
import constants from "config/constants";

const imageFileExtensions = [".png", ".jpg", ".jpeg", ".gif"];
const documentFileTypes = ALLOWED_FILE_EXTS.filter(
  (ext) => !imageFileExtensions.includes(ext)
);

class InboxMessenger extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      compressing: false,
      isAnswered: false,
      answerPublicly: true,
      showMerged: false,
      showPrompt: false,
      image: false,
      files: false,
      question: false,
      viewMobileActions: false,
      viewMobileMerged: false,
      uploads: [],
      body: "",
      selectedLendersAndGroups: [],
      showDropdown: false,
      lenderOptions: [],
      groupOptions: [],
    };

    this.form = React.createRef();
    this.textarea = React.createRef();
    this.messageContainer = React.createRef();
    this.input = React.createRef();
    this.submitFn = this.submitFn.bind(this);
    this.handleShowPrompt = this.handleShowPrompt.bind(this);
    this.uploadPhotos = this.uploadPhotos.bind(this);
  }

  isValidFile = (file) => {
    const ext = file.name.split(".").reverse()[0];
    if (ALLOWED_FILE_EXTS.includes(`.${ext.toLowerCase()}`)) {
      return true;
    }
    return false;
  };

  isValidFiles = (files, showToast = true) => {
    for (let file of files) {
      const ext = file.name.split(".").reverse()[0];
      if (!ALLOWED_FILE_EXTS.includes(`.${ext.toLowerCase()}`)) {
        if (showToast) {
          toast.error(
            `Contains Unsupported file(s). Allows only ${ALLOWED_FILE_EXTS.join(
              ", "
            )}`,
            {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        }
        return false;
      }
    }
    return true;
  };

  isValidImage = (file) => {
    for (let i in imageFileExtensions) {
      if (file.name.toLowerCase().indexOf(imageFileExtensions[i]) !== -1) {
        return true;
      }
    }
    return false;
  };

  isPhoto = (filename) => {
    if (!filename) return false;
    return !documentFileTypes.some((imageExt) => filename.includes(imageExt));
  };

  uploadPhotos(e) {
    if (e && e.target.files.length > 0) {
      const files = e.target.files;
      const options = {
        maxSizeMB: 0.75,
      };

      if (this.isValidFiles(files)) {
        Object.keys(files).forEach(async (file) => {
          const tempFile = files[file];
          const name = tempFile.name;

          try {
            this.setState({ compressing: true });
            let compressedFile;

            if (this.isValidImage(tempFile)) {
              compressedFile = await imageCompression(tempFile, options);
            } else {
              compressedFile = tempFile;
            }

            this.setState((prevState) => ({
              uploads: [
                ...prevState.uploads,
                { _id: uuidv4(), file: compressedFile, name },
              ],
              compressing: false,
            }));
          } catch (error) {
            return error;
          }
        });
      }
    }
  }

  handleShowPrompt(showPrompt, val) {
    this.setState({ showPrompt, body: val });
  }

  addRecipient(recipient) {
    assignToComm(this.props.conversation._id, {
      companyId: recipient.value,
      companyName: recipient.label,
      teamId: recipient.teamId,
    });
  }

  removeRecipient(recipient) {
    removeFromComm(this.props.conversation._id, {
      remove: recipient.value,
    });
  }

  updateGroupRecipient(groups = []) {
    editCommGroups(this.props.conversation._id, {
      groups,
    });
  }

  submitFn(data) {
    const { dispatch, whoami, conversation, myCompany } = this.props;
    const myCompanyName = myCompany.companyName;
    let formData;

    if (this.state.uploads.length > 0) {
      this.state.uploads.map((file) => {
        formData = new FormData();

        formData.append("messageFromName", myCompanyName);
        formData.append("dealId", conversation.dealId);
        formData.append("file", file.file ? file.file : "");
        formData.append("fileLink", file.fileLink);
        formData.append("originalFilename", file.name);
        formData.append("fileKey", file.fileKey);

        sendMessage(conversation._id, formData);

        return true;
      });
    }

    if (data.message) {
      formData = new FormData();
      formData.append("messageFromName", myCompanyName);
      formData.append("dealId", conversation.dealId);
      formData.append("message", data.message);
      sendMessage(conversation._id, formData);
    }

    if (
      conversation.messages[0] &&
      this.parsedMessage(conversation.messages[0].message).commStartedBy !==
        whoami._id &&
      this.state.isAnswered === false
    ) {
      answerComm(conversation._id, {
        isAnswered: true,
      });

      this.setState({ isAnswered: true });
    }

    if (
      conversation.type === "Question" &&
      (whoami?.role === "Borrower" || whoami?.role === "Admin")
    ) {
      const { answerPublicly, selectedLendersAndGroups } = this.state;

      if (this.isInitialResponse()) {
        setPublicStatus(conversation._id, {
          isPublic: Boolean(answerPublicly),
        });
      }

      if (this.isInitialResponse() && !answerPublicly) {
        const lenderCompanyIds = conversation?.lender?.map(
          (lender) => lender.companyId
        );
        const selectedLenderIds = selectedLendersAndGroups
          .filter((lender) => !lender.group)
          .map((lender) => lender.value);
        const addLenders = selectedLendersAndGroups.filter(
          (lender) => !lender.group && !lenderCompanyIds.includes(lender.value)
        );
        const removeLenders = this.createLenderOptions(
          conversation?.lender?.filter(
            (lender) =>
              lender.companyId && !selectedLenderIds.includes(lender.companyId)
          )
        );
        const selectedGroupIds = selectedLendersAndGroups
          .filter((group) => group.group)
          .map((group) => group.value);

        addLenders.forEach((lender) => this.addRecipient(lender));
        removeLenders.forEach((lender) => this.removeRecipient(lender));
        this.updateGroupRecipient(selectedGroupIds);
      }
    }

    dispatch(reset("CreateCommunication"));
    this.setState({ uploads: [] });
  }

  isInitialResponse() {
    const { conversation } = this.props;
    return conversation?.messages?.length <= 1;
  }

  createLenderOptions(lendersAssigned = []) {
    const { conversation } = this.props;
    return (
      lendersAssigned.map((e) => ({
        group: false,
        value: e.companyId,
        label: e.companyName,
        teamId: e.teamId,
        isDisabled: e.companyId === conversation.lender?.[0]?.companyId,
      })) || []
    );
  }

  createGroupOptions(groups = []) {
    return (
      groups.map((e) => ({
        group: true,
        value: e._id,
        label: e.groupName,
      })) || []
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.conversation !== this.props.conversation) {
      const { conversation } = this.props;

      this.setState({
        conversation,
        isAnswered: conversation.isAnswered,
      });
    }

    if (prevProps.groups !== this.props.groups) {
      const groupOptions = this.createGroupOptions(this.props.groups);
      this.setState({
        groupOptions,
      });
    }

    if (prevProps.conversation._id !== this.props.conversation._id) {
      if (this.textarea !== null && this.textarea.current !== null) {
        this.textarea.focus({
          preventScroll: true,
        });
      }
    }
  }

  componentDidMount() {
    const { conversation, groups } = this.props;
    const lenderOptions = this.createLenderOptions(
      this.props.deal.lendersAssigned
    );
    const selectedLenders = lenderOptions.filter((e) =>
      conversation.lender.some((s) => s.companyId === e.value)
    );
    const groupOptions = this.createGroupOptions(groups);
    const selectedGroups = groupOptions.filter((e) =>
      conversation.groups.some((group) => group === e.value)
    );
    const selectedLendersAndGroups = [...selectedLenders, ...selectedGroups];
    const isPublic = this.isInitialResponse() ? true : conversation.isPublic;

    this.setState({
      lenderOptions,
      groupOptions,
      selectedLendersAndGroups,
      conversation,
      isAnswered: conversation.isAnswered,
      answerPublicly: isPublic,
    });

    if (this.textarea !== null && this.textarea.current !== null) {
      this.textarea.focus({
        preventScroll: true,
      });
    }
    window.intercomSettings = {
      app_id: constants.INTERCOM_APP_ID,
      alignment: "left",
      horizontal_padding: 20,
      vertical_padding: 20,
    };

    this.messageContainer.current.firstChild.scrollTop =
      this.messageContainer.current.firstChild.scrollHeight;
  }

  parsedMessage(message) {
    let parsed;
    try {
      parsed = JSON.parse(message);
    } catch (error) {
      parsed = {};
    }
    return parsed;
  }

  showFollowUpQuestion() {
    const { conversation, whoami } = this.props;

    let show;

    if (whoami?.role === "Admin" || whoami?.role === "Lender") {
      show =
        conversation.messages[0] &&
        whoami?._id ===
          this.parsedMessage(conversation.messages[0].message).commStartedBy;
      show =
        show ||
        conversation.messages.filter((e) => e.messageFromUID === whoami?._id)
          .length >= 1;
      show =
        show ||
        conversation.messages.filter(
          (e) => e.messageFromUID === conversation.initiallyAskedBy
        ).length >= 2;
    } else if (whoami?.role === "Borrower") {
      if (conversation.initiallyAskedBy === whoami?._id) {
        show =
          conversation.messages.filter((e) => e.messageFromUID === whoami?._id)
            .length >= 2;
      } else {
        show =
          conversation.messages.filter((e) => e.messageFromUID === whoami?._id)
            .length >= 1;
      }
    }

    return show;
  }

  render() {
    const {
      dispatch,
      handleSubmit,
      deal,
      conversation,
      whoami,
      myCompany,
      isPristine,
      action,
      onGoToMergedQuestion,
      isOwner,
      onDragOver,
      onDragLeave,
      onDrop,
      onSetConversationPrivacy,
      onEditQuestion,
      onExit,
      onMergeQuestion,
      queryparams,
    } = this.props;
    const { isFetching } = action.SEND_MESSAGE;

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
      <Button
        ref={ref}
        variant="inbox-action"
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
      >
        {children}
      </Button>
    ));

    const CustomMenu = React.forwardRef(
      ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
        const [value] = useState("");

        return (
          <div
            ref={ref}
            style={style}
            className={className}
            aria-labelledby={labeledBy}
          >
            <ul className="list-unstyled">
              {React.Children.toArray(children).filter(
                (child) =>
                  !value || child.props.children.toLowerCase().startsWith(value)
              )}
            </ul>
          </div>
        );
      }
    );

    if (Object.keys(conversation).length === 0) {
      return <></>;
    } else {
      return (
        <>
          {this.state.compressing && <Loader files />}
          {this.state.image && (
            <PopupPrompt
              promptBody={
                <Image
                  alt="Inbox message photo enlarged."
                  className="d-block"
                  width="100%"
                  height="auto"
                  style={{ margin: "0 auto" }}
                  src={this.state.body}
                />
              }
              dark
              show={this.state.showPrompt}
              onCancel={() => {
                this.handleShowPrompt(false);
                this.setState({ image: false });
              }}
              onHide={() => {
                this.handleShowPrompt(false);
                this.setState({ image: false });
              }}
            />
          )}
          {this.state.files && (
            <PopupPrompt
              promptTitle="Insert Image"
              promptBody={
                <UploadFiles
                  dealId={conversation.dealId}
                  onCancel={() => {
                    this.setState({ files: false });
                    this.handleShowPrompt(false);
                  }}
                  onSubmit={(e) => {
                    e.map((file) => {
                      this.setState((prevState) => ({
                        uploads: [...prevState.uploads, file],
                      }));

                      return true;
                    });

                    this.setState({ files: false });
                    this.handleShowPrompt(false);
                  }}
                />
              }
              show={this.state.showPrompt}
              onCancel={() => {
                this.setState({ files: false });
                this.handleShowPrompt(false);
              }}
              onHide={() => {
                this.setState({ files: false });
                this.handleShowPrompt(false);
              }}
            />
          )}
          {this.state.question && (
            <PopupPrompt
              promptTitle={"Ask Follow-Up Question"}
              promptBody={
                <CreateMessage
                  option="Question"
                  isFollowUpQuestion={true}
                  deal={deal}
                  conversation={conversation}
                  lenderOptions={this.state.lenderOptions}
                  groupOptions={this.state.groupOptions}
                  linkedQuestionId={conversation._id}
                  linkedQuestionTitle={
                    conversation.editedMsg && conversation.editedMsg.length > 0
                      ? conversation.editedMsg
                      : conversation.initialMessage
                  }
                  dealId={conversation.dealId}
                  onHide={() => {
                    this.setState({ question: false });
                    this.handleShowPrompt(false);
                    dispatch(reset("CreateMessage"));
                  }}
                  onSubmit={({ onHide }) => {
                    if (!onHide) {
                      this.setState({ question: false });
                      this.handleShowPrompt(false);
                      dispatch(reset("CreateMessage"));
                    }
                  }}
                />
              }
              show={this.state.showPrompt}
              onCancel={() => {
                this.handleShowPrompt(false);
                this.setState({ question: false });
                dispatch(reset("CreateMessage"));
              }}
              onHide={() => {
                this.handleShowPrompt(false);
                this.setState({ question: false });
                dispatch(reset("CreateMessage"));
              }}
            />
          )}
          <Card
            id="messenger"
            className="message-card mobile-messenger"
            onDragOver={(e) => {
              onDragOver(e);
            }}
            onDragLeave={(e) => {
              onDragLeave(e);
            }}
            onDrop={(e) => {
              onDrop(e);
            }}
          >
            <Card.Header>
              <Row className="align-items-center">
                <Col xs="7">
                  <div>
                    <FontAwesomeIcon
                      icon={
                        conversation.type === "Question"
                          ? faQuestionCircle
                          : faCommentAlt
                      }
                    />
                    <span className="pl-2">
                      {conversation.type}
                      {conversation.isPublic ? (
                        <span className="font-12 bold ml-2 align-bottom">
                          (Public)
                        </span>
                      ) : (
                        <span className="font-12 bold ml-2 align-bottom">
                          (Private)
                        </span>
                      )}
                    </span>
                  </div>
                  <p className="color-medium-gray font-12 d-none d-sm-inline-block">
                    {moment(conversation.createdAt).fromNow()}
                  </p>
                </Col>

                <Col xs="5">
                  <Row className="justify-content-end">
                    {conversation.type === "Question" &&
                      (whoami?.role === "Borrower" ||
                        (whoami?.role === "Admin" &&
                          conversation.messages[0] &&
                          whoami._id !==
                            this.parsedMessage(conversation.messages[0].message)
                              ?.commStartedBy)) && (
                        <Col xs="auto" className="d-flex align-items-center">
                          <p style={{ whiteSpace: "nowrap" }}>
                            Answer Publicly &nbsp;
                          </p>
                          <Form.Check
                            disabled={
                              whoami?.role === "Borrower" ||
                              whoami?.role === "Admin"
                                ? !this.isInitialResponse()
                                : false
                            }
                            type="switch"
                            className="switch-lg"
                            id="custom-switch"
                            label=""
                            checked={this.state.answerPublicly}
                            onChange={() =>
                              this.setState((prevState) => ({
                                answerPublicly: !prevState.answerPublicly,
                              }))
                            }
                          />
                        </Col>
                      )}
                    <Col xs="auto" className="font-12 text-right px-0">
                      <Dropdown className="d-inline-block">
                        <div className="d-inline-block inbox-actions">
                          <Dropdown.Toggle
                            as={CustomToggle}
                            id="inbox-dropdown"
                          >
                            <FontAwesomeIcon icon={faEllipsisH} />
                          </Dropdown.Toggle>

                          <Dropdown.Menu as={CustomMenu}>
                            {conversation.type === "Question" && (
                              <>
                                {((whoami && whoami.role === "Admin") ||
                                  (whoami &&
                                    whoami._id ===
                                      conversation.initiallyAskedBy) ||
                                  isOwner) && (
                                  <>
                                    <Dropdown.Item
                                      onClick={() =>
                                        onEditQuestion(conversation)
                                      }
                                    >
                                      Edit Question
                                    </Dropdown.Item>
                                    <Dropdown.Divider></Dropdown.Divider>
                                  </>
                                )}
                                {/* {(isOwner ||
                                  (whoami && whoami.role === "Admin")) && (
                                  <>
                                    <Dropdown.Item
                                      onClick={() => {
                                        this.setState({ answerPublicly: true });
                                      }}
                                    >
                                      Answer Publicly
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() => {
                                        this.setState({
                                          answerPublicly: false,
                                        });
                                      }}
                                    >
                                      Answer Privately
                                    </Dropdown.Item>
                                    <Dropdown.Divider></Dropdown.Divider>
                                    <Dropdown.Item
                                      onClick={(e) => {
                                        onMergeQuestion(e, conversation);
                                      }}
                                    >
                                      Merge Question
                                    </Dropdown.Item>
                                  </>
                                )} */}
                              </>
                            )}
                            {/* {(isOwner ||
                              (whoami && whoami.role === "Admin")) && (
                              <>
                                <Dropdown.Item
                                  onClick={(e) => {
                                    onSetConversationPrivacy(e, conversation);
                                  }}
                                >
                                  Privacy Settings
                                </Dropdown.Item>
                              </>
                            )} */}
                            <Dropdown.Item
                              onClick={() => {
                                archiveMessage(conversation._id);
                              }}
                            >
                              {conversation.isArchivedBy.some(
                                (user) => user === whoami._id
                              )
                                ? "Unarchive"
                                : "Archive"}
                            </Dropdown.Item>
                            {/* <Dropdown.Item
                              onClick={() => {
                                this.setState({
                                  viewMobileActions:
                                    !this.state.viewMobileActions,
                                });
                              }}
                            >
                              More...
                            </Dropdown.Item> */}
                          </Dropdown.Menu>
                        </div>
                      </Dropdown>
                      <Button
                        variant="inbox-action"
                        onClick={() => {
                          onExit();
                        }}
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Header>

            {conversation.type === "Question" &&
              !this.state.answerPublicly &&
              (whoami.role === "Borrower" ||
                (whoami.role === "Admin" &&
                  conversation.messages[0] &&
                  whoami._id !==
                    this.parsedMessage(conversation.messages[0].message)
                      .commStartedBy)) && (
                <Card.Header className="header-dropdown">
                  <Row className="align-items-center">
                    <Col
                      xs="12"
                      className="d-flex align-items-center justify-content-between pr-2"
                    >
                      <div className="chips-container">
                        {this.state.selectedLendersAndGroups.map((item) => {
                          let isDisabled =
                            item.value === conversation.lender?.[0]?.companyId;

                          if (whoami.role === "Borrower") {
                            isDisabled =
                              isDisabled || !this.isInitialResponse();
                          }

                          return (
                            <Badge
                              key={item.value}
                              pill
                              variant="primary"
                              className="d-flex p-2 m-1"
                            >
                              {item.label}
                              {!isDisabled && (
                                <FontAwesomeIcon
                                  className="ml-1"
                                  style={{ cursor: "pointer" }}
                                  icon={faTimesCircle}
                                  onClick={() => {
                                    this.setState((prevState) => ({
                                      selectedLendersAndGroups:
                                        prevState.selectedLendersAndGroups.filter(
                                          (e) => e.value !== item.value
                                        ),
                                    }));
                                  }}
                                />
                              )}
                            </Badge>
                          );
                        })}
                      </div>

                      <div className="multiDropdown-wrapper">
                        {(whoami.role === "Admin" ||
                          this.isInitialResponse()) && (
                          <Button
                            size="sm"
                            variant="link"
                            name="lenders"
                            className="d-flex align-items-center mr-2 px-0 text-body"
                            onClick={() => {
                              this.setState((prevState) => ({
                                showDropdown: !prevState.showDropdown,
                              }));
                            }}
                          >
                            <FontAwesomeIcon
                              className="ml-2"
                              icon={faPlusCircle}
                            />
                          </Button>
                        )}
                        {this.state.showDropdown && (
                          <div className="multiDropdown-container">
                            <MultiDropdown
                              autoFocus
                              menuIsOpen
                              label="Lenders"
                              options={[
                                {
                                  label: "Groups",
                                  options: this.state.groupOptions,
                                },
                                {
                                  label: "Lenders",
                                  options: this.state.lenderOptions,
                                },
                              ]}
                              value={this.state.selectedLendersAndGroups}
                              onChange={(selected) => {
                                this.setState({
                                  selectedLendersAndGroups: selected,
                                  answerPublicly: false,
                                });
                              }}
                              onBlur={(evt) => {
                                if (evt.relatedTarget?.name !== "lenders") {
                                  this.setState({ showDropdown: false });
                                }
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Card.Header>
              )}
            <Card.Body>
              <div
                className="message-container font-14"
                ref={this.messageContainer}
              >
                <div className="message-inner-container">
                  {conversation.messages?.[0] &&
                    this.parsedMessage(conversation.messages?.[0]?.message)
                      ?.isFollowUpQuestion && (
                      <Row className="row-initial" noGutters={true}>
                        <div
                          className="d-block w-100 text-center"
                          style={{
                            backgroundColor: "#F0EFEF",
                          }}
                        >
                          <Spacer size={10} />
                          <p className="font-10 color-medium-gray">
                            This is a follow-up question for
                          </p>
                          <Button
                            className="font-12 fake-link"
                            variant="fake-link"
                            onClick={() => {
                              onGoToMergedQuestion(
                                conversation.messages[0] &&
                                  this.parsedMessage(
                                    conversation.messages[0].message
                                  ).linkedQuestionId
                              );
                            }}
                          >
                            {conversation.messages[0] &&
                              this.parsedMessage(
                                conversation.messages[0].message
                              ).linkedQuestionTitle}
                          </Button>
                          <Spacer size={5} />
                        </div>
                      </Row>
                    )}
                  {conversation.messages.map((message, idx) => {
                    const selectedMsg =
                      message._id === queryparams.msgId ||
                      (conversation._id === queryparams.convId &&
                        !queryparams.msgId)
                        ? "focus-msg"
                        : "";
                    if (message.messageFromName.includes("SYSTEM:MERGE")) {
                      return (
                        <Row
                          className={"row-initial " + selectedMsg}
                          noGutters={true}
                          key={idx}
                        >
                          <Col xs="12" className="message-initial">
                            <Spacer size={10} />
                            <p className="d-block">{message.message}</p>
                          </Col>
                          <div className="d-block w-100 text-center">
                            <div className="border-bottom"></div>
                            <Spacer size={5} />
                            <p className="font-10 color-medium-gray">
                              {conversation.type} merged to this conversation.
                            </p>
                            <Spacer size={5} />
                          </div>
                        </Row>
                      );
                    }

                    if (whoami && whoami._id === message.messageFromUID) {
                      if (
                        message.messageFromName.includes("SYSTEM:WAS_MERGED")
                      ) {
                        return (
                          <Row
                            className={"row-initial " + selectedMsg}
                            noGutters={true}
                            key={idx}
                          >
                            <div
                              className="d-block w-100 text-center"
                              style={{ backgroundColor: "#F0EFEF" }}
                            >
                              <Spacer size={10} />
                              <p className="font-10 color-medium-gray">
                                This question has been merged into
                              </p>
                              <Button
                                className="font-12 fake-link"
                                variant="fake-link"
                                onClick={() => {
                                  onGoToMergedQuestion(
                                    message.message.split(":")[0]
                                  );
                                }}
                              >
                                {message.message.split(":")[1]}
                              </Button>
                              <Spacer size={5} />
                            </div>
                          </Row>
                        );
                      } else {
                        return (
                          <Fragment key={idx}>
                            <Row
                              className={"row-me " + selectedMsg}
                              noGutters={true}
                            >
                              <Col xs="2"></Col>
                              <Col xs="10" className="message-me text-right">
                                <span className="message-from-name bold">
                                  {myCompany.companyName}
                                </span>
                                {message.editedMsg?.length > 0 && (
                                  <p className="text-right text-break">
                                    {message.editedMsg}
                                  </p>
                                )}
                                {(idx === 0 || message.message) && (
                                  <p className="text-right text-break">
                                    {idx === 0 &&
                                    this.parsedMessage(message.message)
                                      ?.message ? (
                                      <>
                                        {
                                          this.parsedMessage(message.message)
                                            ?.message
                                        }
                                      </>
                                    ) : (
                                      <>{message.message}</>
                                    )}
                                  </p>
                                )}
                                {message.file && this.isPhoto(message.file) && (
                                  <div className="message-content">
                                    <Image
                                      src={message.file}
                                      alt="Inbox message photo."
                                      onClick={() => {
                                        this.setState({ image: true });
                                        this.handleShowPrompt(
                                          true,
                                          message.file
                                        );
                                      }}
                                    />
                                  </div>
                                )}
                                {message.file && !this.isPhoto(message.file) && (
                                  <div className="message-content d-flex align-items-center justify-content-end">
                                    <FontAwesomeIcon
                                      title={message.file}
                                      size="3x"
                                      icon={faFileAlt}
                                      className="cursor-pointer"
                                      onClick={() =>
                                        window.open(message.file, "_blank")
                                      }
                                    />
                                    <a
                                      title={message.file}
                                      href={message.file}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="text-right ml-2"
                                    >
                                      {message.file.slice(0, 30)}...
                                      {message.file.slice(
                                        message.file.length - 4
                                      )}
                                    </a>
                                  </div>
                                )}
                              </Col>
                            </Row>
                          </Fragment>
                        );
                      }
                    } else if (
                      whoami &&
                      whoami._id !== message.messageFromUID &&
                      !message.messageFromName.includes("SYSTEM:MERGE") &&
                      !message.messageFromName.includes("SYSTEM:WAS_MERGED")
                    ) {
                      return (
                        <Row
                          className={"row-them " + selectedMsg}
                          noGutters={true}
                          key={idx}
                        >
                          <Col xs="10" className="message-them">
                            <span className="message-from-name bold">
                              {message.messageFromName}
                            </span>
                            {message.editedMsg?.length > 0 && (
                              <p className="text-right text-break">
                                {message.editedMsg}
                              </p>
                            )}
                            {(idx === 0 || message.message) && (
                              <p className="text-right text-break">
                                {idx === 0 &&
                                this.parsedMessage(message.message)?.message ? (
                                  <>
                                    {
                                      this.parsedMessage(message.message)
                                        ?.message
                                    }
                                  </>
                                ) : (
                                  <>{message.message}</>
                                )}
                              </p>
                            )}
                            {message.file && this.isPhoto(message.file) && (
                              <div className="message-content">
                                <Image
                                  src={message.file}
                                  alt="Inbox message photo."
                                  onClick={() => {
                                    this.handleShowPrompt(true, message.file);
                                  }}
                                />
                              </div>
                            )}
                            {message.file && !this.isPhoto(message.file) && (
                              <div className="message-content d-flex align-items-center justify-content-start">
                                <FontAwesomeIcon
                                  title={message.file}
                                  size="3x"
                                  icon={faFileAlt}
                                  className="cursor-pointer"
                                  onClick={() =>
                                    window.open(message.file, "_blank")
                                  }
                                />
                                <a
                                  title={message.file}
                                  href={message.file}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="text-right ml-2"
                                >
                                  {message.file.slice(0, 30)}...
                                  {message.file.slice(message.file.length - 4)}
                                </a>
                              </div>
                            )}
                          </Col>
                          <Col xs="2"></Col>
                        </Row>
                      );
                    } else if (
                      message.messageFromName.includes("SYSTEM:WAS_MERGED")
                    ) {
                      return (
                        <Row
                          className={"row-initial " + selectedMsg}
                          noGutters={true}
                          key={idx}
                        >
                          <div
                            className="d-block w-100 text-center"
                            style={{ backgroundColor: "#F0EFEF" }}
                          >
                            <Spacer size={10} />
                            <p className="font-10 color-medium-gray">
                              This question has been merged into
                            </p>
                            <Button
                              className="font-12 fake-link"
                              variant="fake-link"
                              onClick={() => {
                                onGoToMergedQuestion(
                                  message.message.split(":")[0]
                                );
                              }}
                            >
                              {message.message.split(":")[1]}
                            </Button>
                            <Spacer size={5} />
                          </div>
                        </Row>
                      );
                    }

                    return true;
                  })}
                  {isFetching && (
                    <div className="text-center">
                      <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    </div>
                  )}
                </div>
                <Form
                  ref={this.form}
                  className="no-padding no-border messenger-actions"
                  onSubmit={handleSubmit(this.submitFn)}
                >
                  <Spacer size={10} />
                  {conversation.type === "Question" ? (
                    <>
                      {this.showFollowUpQuestion() ? (
                        <></>
                      ) : (
                        <Field
                          refName={(ref) => (this.textarea = ref)}
                          withRef
                          className="form-control"
                          component={reduxFormTextarea}
                          name="message"
                          type="textarea"
                          rows="2"
                          placeholder="Enter message..."
                        />
                      )}
                    </>
                  ) : (
                    <Field
                      refName={(ref) => (this.textarea = ref)}
                      withRef
                      className="form-control"
                      component={reduxFormTextarea}
                      name="message"
                      type="textarea"
                      rows="2"
                      placeholder="Enter message..."
                    />
                  )}
                  <Row noGutters={true}>
                    <Col className="inbox-actions" xs="3">
                      {conversation.type === "Question" ? (
                        <>
                          {whoami &&
                          whoami.role !== "Borrower" &&
                          conversation.messages[0] &&
                          whoami._id ===
                            this.parsedMessage(conversation.messages[0].message)
                              .commStartedBy ? (
                            <></>
                          ) : (
                            <>
                              <Spacer size={10} />
                              <input
                                ref={this.input}
                                className="d-none"
                                type="file"
                                accept={imageFileExtensions.join(",")}
                                onChange={(e) => {
                                  this.uploadPhotos(e);
                                }}
                                multiple
                              />
                              <Button
                                variant="inbox-action"
                                onClick={() => {
                                  this.input.current.click();
                                }}
                              >
                                <FontAwesomeIcon icon={faImage} />
                              </Button>
                              <Button
                                variant="inbox-action"
                                onClick={() => {
                                  this.setState({ files: true });
                                  this.handleShowPrompt(true);
                                }}
                              >
                                <FontAwesomeIcon icon={faPaperclip} />
                              </Button>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <Spacer size={10} />
                          <input
                            ref={this.input}
                            className="d-none"
                            type="file"
                            accept={imageFileExtensions.join(",")}
                            onChange={(e) => {
                              this.uploadPhotos(e);
                            }}
                            multiple
                          />
                          <Button
                            variant="inbox-action"
                            onClick={() => {
                              this.input.current.click();
                            }}
                          >
                            <FontAwesomeIcon icon={faImage} />
                          </Button>
                          <Button
                            variant="inbox-action"
                            onClick={() => {
                              this.setState({ files: true });
                              this.handleShowPrompt(true);
                            }}
                          >
                            <FontAwesomeIcon icon={faPaperclip} />
                          </Button>
                        </>
                      )}
                    </Col>
                    <Col className="text-right" xs="9">
                      <Spacer size={10} />
                      {conversation.type === "Question" ? (
                        <>
                          {this.state.isAnswered ? (
                            <>
                              {this.showFollowUpQuestion() ? (
                                <Button
                                  onClick={() => {
                                    this.setState({ question: true });
                                    this.handleShowPrompt(true);
                                  }}
                                >
                                  ASK A FOLLOW-UP QUESTION
                                </Button>
                              ) : (
                                <>
                                  {!isPristine ||
                                  this.state.uploads.length > 0 ? (
                                    <Button
                                      type="submit"
                                      className="message-send"
                                    >
                                      Answer
                                    </Button>
                                  ) : (
                                    <Button>Answer</Button>
                                  )}
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              {this.showFollowUpQuestion() ? (
                                <Button
                                  onClick={() => {
                                    this.setState({ question: true });
                                    this.handleShowPrompt(true);
                                  }}
                                >
                                  ASK A FOLLOW-UP QUESTION
                                </Button>
                              ) : (
                                <>
                                  {!isPristine ||
                                  this.state.uploads.length > 0 ? (
                                    <Button
                                      type="submit"
                                      className="message-send"
                                    >
                                      Answer
                                    </Button>
                                  ) : (
                                    <Button>Answer</Button>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {!isPristine || this.state.uploads.length > 0 ? (
                            <Button type="submit" className="message-send">
                              Reply
                            </Button>
                          ) : (
                            <Button>Reply</Button>
                          )}
                        </>
                      )}
                      <Spacer size={5} />
                      {(isOwner ||
                        (whoami &&
                          whoami.role === "Admin" &&
                          conversation.messages[0] &&
                          whoami._id !==
                            this.parsedMessage(conversation.messages[0].message)
                              .commStartedBy)) &&
                        conversation.type === "Question" && (
                          // <Button
                          //   className="fake-link font-10 mr-1"
                          //   variant="fake-link"
                          //   onClick={() => {
                          //     this.setState((prevState) => ({
                          //       answerPublicly: !prevState.answerPublicly,
                          //     }));
                          //   }}
                          // >
                          <>
                            Answering{" "}
                            {conversation.type === "Question"
                              ? this.state.answerPublicly
                                ? "Publicly"
                                : "Privately"
                              : this.state.answerPublicly
                              ? "Publicly"
                              : "Privately"}
                          </>
                          // </Button>
                        )}
                    </Col>
                  </Row>
                  <Spacer size={10} />
                  <div className="message-uploads">
                    {this.state.uploads.map((file, idx) => {
                      return (
                        <Badge
                          className="mr-2"
                          variant="secondary"
                          key={idx}
                          tabIndex="0"
                          pill
                          onClick={() => {
                            this.setState({
                              uploads: this.state.uploads.filter((arrFile) => {
                                return arrFile._id !== file._id;
                              }),
                            });
                          }}
                          onKeyDown={(e) =>
                            e.keyCode === 13
                              ? document.activeElement.click()
                              : null
                          }
                        >
                          <FontAwesomeIcon icon={faTimesCircle} /> {file.name}
                        </Badge>
                      );
                    })}
                    {this.state.uploads.length > 0 && <Spacer size={10} />}
                  </div>
                </Form>
                {this.state.viewMobileMerged && (
                  <Container className="mobile-merged" fluid>
                    <Spacer size={10} />
                    {conversation.mergedComms.length > 0 &&
                      conversation.mergedComms.map((comm, idx) => {
                        const parsedComm = JSON.parse(comm);

                        return (
                          <Fragment key={"mobile-" + parsedComm._id}>
                            <Row>
                              <Col xs="8">
                                <FontAwesomeIcon
                                  icon={
                                    parsedComm.type === "Question"
                                      ? faQuestionCircle
                                      : faCommentAlt
                                  }
                                />
                                <span className="d-inline-block pl-2">
                                  {parsedComm.type === "Question"
                                    ? "Question"
                                    : "Message"}
                                  {parsedComm.isPublic ? (
                                    <span className="d-inline-block font-12 bold ml-2 align-bottom">
                                      (Public)
                                    </span>
                                  ) : (
                                    <span className="d-inline-block font-12 bold ml-2 align-bottom">
                                      (Private)
                                    </span>
                                  )}
                                </span>
                              </Col>
                              <Col className="font-12 text-right" xs="4">
                                <p className="bold">
                                  {parsedComm.lenderCompany}
                                </p>
                                <p className="color-medium-gray">
                                  {moment(parsedComm.createdAt).fromNow()}
                                </p>
                              </Col>
                              <Col xs="12">
                                <Spacer size={10} />
                                <span className="d-block border-bottom"></span>
                              </Col>
                            </Row>
                            <Spacer size={20} />
                            <p key={idx}>
                              {parsedComm.editedInitialMsg &&
                              parsedComm.editedInitialMsg.length > 0
                                ? parsedComm.editedInitialMsg
                                : parsedComm.initialMessage}
                            </p>
                            <Spacer size={20} />
                            <Button
                              onClick={() => {
                                unmergeMessage(conversation._id, {
                                  merge: JSON.stringify(parsedComm),
                                  whoami,
                                });
                              }}
                            >
                              Unmerge
                            </Button>
                            <Spacer size={50} />
                          </Fragment>
                        );
                      })}
                  </Container>
                )}
              </div>
            </Card.Body>
            {this.state.viewMobileActions && (
              <Card.Footer>
                <Row>
                  <Col xs="6">
                    {conversation.mergedComms.length > 0 && (
                      <span
                        className="d-inline"
                        tabIndex="0"
                        onClick={() => {
                          if (this.state.viewMobileMerged) {
                            this.setState({ viewMobileMerged: false });
                          } else {
                            this.setState({ viewMobileMerged: true });
                          }
                        }}
                      >
                        <Spacer size={10} />
                        {this.state.viewMobileMerged ? (
                          <p
                            className="d-inline-block"
                            style={{ cursor: "pointer" }}
                          >
                            Hide merged{" "}
                            <FontAwesomeIcon
                              className="d-inline-block"
                              icon={faCaretUp}
                            />
                          </p>
                        ) : (
                          <p
                            className="d-inline-block"
                            style={{ cursor: "pointer" }}
                          >
                            Show merged{" "}
                            <FontAwesomeIcon
                              className="d-inline-block"
                              icon={faCaretDown}
                            />
                          </p>
                        )}
                        <Spacer size={10} />
                      </span>
                    )}
                  </Col>
                  <Col className="inbox-actions text-right" xs="6">
                    {(isOwner || (whoami && whoami.role === "Admin")) && (
                      <>
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip id={`tooltip-top`}>Merge</Tooltip>}
                        >
                          <Button
                            className="d-inline-block"
                            variant="inbox-action"
                            onClick={(e) => {
                              onMergeQuestion(e, conversation);
                            }}
                          >
                            <FontAwesomeIcon
                              className="color-medium-gray"
                              icon={faSignInAlt}
                            />
                          </Button>
                        </OverlayTrigger>
                      </>
                    )}
                    {(isOwner || (whoami && whoami.role === "Admin")) && (
                      <>
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id={`tooltip-top`}>Set Privacy</Tooltip>
                          }
                        >
                          <Button
                            className="d-inline-block"
                            variant="inbox-action"
                            onClick={(e) => {
                              onSetConversationPrivacy(e, conversation);
                            }}
                          >
                            <FontAwesomeIcon
                              className="color-medium-gray"
                              icon={
                                conversation.isPublic ? faLock : faUnlockAlt
                              }
                            />
                          </Button>
                        </OverlayTrigger>
                      </>
                    )}
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id={`tooltip-top`}>
                          {conversation.isArchivedBy.some(
                            (user) => user === whoami._id
                          )
                            ? "Unarchive"
                            : "Archive"}
                        </Tooltip>
                      }
                    >
                      <Button
                        className="d-inline-block"
                        variant="inbox-action"
                        onClick={(e) => {
                          e.stopPropagation();
                          clearTimeout(this.buttonPressTimer);

                          archiveMessage(conversation._id);
                        }}
                      >
                        <FontAwesomeIcon
                          className="color-medium-gray"
                          icon={
                            conversation.isArchivedBy.some(
                              (user) => user === whoami._id
                            )
                              ? faInbox
                              : faArchive
                          }
                        />
                      </Button>
                    </OverlayTrigger>
                  </Col>
                </Row>
              </Card.Footer>
            )}
          </Card>
          <Spacer size={20} />
          {conversation.mergedComms.length > 0 && (
            <Accordion className="merged-questions">
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  className="focusable-header text-center"
                  tabIndex="0"
                  eventKey="0"
                  onClick={() => {
                    if (this.state.showMerged) {
                      this.setState({ showMerged: false });
                    } else {
                      this.setState({ showMerged: true });
                    }
                  }}
                >
                  <Spacer size={10} />
                  {this.state.showMerged ? (
                    <p className="d-block w-100">
                      Hide {conversation.mergedComms.length} merged question(s){" "}
                      <FontAwesomeIcon
                        className="d-inline-block"
                        icon={faCaretUp}
                      />
                    </p>
                  ) : (
                    <p className="d-block w-100">
                      Show {conversation.mergedComms.length} merged question(s){" "}
                      <FontAwesomeIcon
                        className="d-inline-block"
                        icon={faCaretDown}
                      />
                    </p>
                  )}
                  <Spacer size={10} />
                </Accordion.Toggle>
              </Card>
              <Spacer size={20} />
              <Card>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    {conversation.mergedComms.length > 0 &&
                      conversation.mergedComms.map((comm, idx) => {
                        const parsedComm = JSON.parse(comm);

                        return (
                          <Fragment key={parsedComm._id}>
                            <Row>
                              <Col xs="8">
                                <FontAwesomeIcon
                                  icon={
                                    parsedComm.type === "Question"
                                      ? faQuestionCircle
                                      : faCommentAlt
                                  }
                                />
                                <span className="d-inline-block pl-2">
                                  {parsedComm.type === "Question"
                                    ? "Question"
                                    : "Message"}
                                  {parsedComm.isPublic ? (
                                    <span className="d-inline-block font-12 bold ml-2 align-bottom">
                                      (Public)
                                    </span>
                                  ) : (
                                    <span className="d-inline-block font-12 bold ml-2 align-bottom">
                                      (Private)
                                    </span>
                                  )}
                                </span>
                              </Col>
                              <Col className="font-12 text-right" xs="4">
                                <p className="bold">
                                  {parsedComm.lenderCompany}
                                </p>
                                <p className="color-medium-gray">
                                  {moment(parsedComm.createdAt).fromNow()}
                                </p>
                              </Col>
                              <Col xs="12">
                                <Spacer size={10} />
                                <span className="d-block border-bottom"></span>
                              </Col>
                            </Row>
                            <Spacer size={20} />
                            <p key={idx}>
                              {parsedComm.editedInitialMsg &&
                              parsedComm.editedInitialMsg.length > 0
                                ? parsedComm.editedInitialMsg
                                : parsedComm.initialMessage}
                            </p>
                            <Spacer size={20} />
                            <Button
                              onClick={() => {
                                unmergeMessage(conversation._id, {
                                  merge: JSON.stringify(parsedComm),
                                  whoami,
                                });
                              }}
                            >
                              Unmerge
                            </Button>
                            <Spacer size={50} />
                          </Fragment>
                        );
                      })}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          )}
        </>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    isPristine: isPristine("CreateCommunication")(state),
    action: state.action,
    whoami: state.auth.whoami,
    deal: state.deal.deal,
    groups: state.comm.groups,
    myCompany: state.company.myCompany,
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "CreateCommunication",
    enableReinitialize: true,
  })(InboxMessenger)
);
