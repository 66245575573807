import React, { useEffect, useMemo } from "react";
import { Button, Form, Input, Modal, Select, TreeSelect, Upload } from "antd";
import { PaperClipOutlined } from "@ant-design/icons";
import TextArea from "components/form/TextArea";
import { useRouteMatch } from "react-router-dom";
import { getDealById } from "redux/actions";
import { connect } from "react-redux";
import { sortBy } from "lodash";
import { createTreeData, normalizeFile } from "../inboxUtils";

const allLenders = "All Lenders";
const all = {
  label: allLenders,
  value: allLenders,
};

const CreateMessage = ({
  open,
  onCreate,
  onCancel,
  whoami,
  myCompany,
  deal,
  labelsTreeView,
}) => {
  const [form] = Form.useForm();
  const match = useRouteMatch();
  const dealId = match.params.id;

  useEffect(() => {
    getDealById(dealId);
  }, [dealId]);

  const companyOptions = useMemo(() => {
    let options = [];

    const sortedLenders = sortBy(deal.lendersAssigned, "companyName")
      .filter((lender) => lender.companyId !== myCompany._id)
      .map((lender) => ({
        label: lender.companyName,
        value: lender.companyId,
      }));

    switch (whoami.role) {
      case "Admin": {
        options = [
          {
            label: "Select All Lenders",
            options: [all],
          },
          {
            label: "Companies",
            options: [
              {
                label: `${deal.company?.companyName} (Deal Borrower)`,
                value: deal.company?._id,
              },
              ...sortedLenders,
            ],
          },
        ];
        break;
      }
      case "Lender": {
        options = [
          {
            label: "Borrower",
            options: [
              {
                label: deal.company?.companyName,
                value: deal.company?._id,
              },
            ],
          },
        ];
        break;
      }
      case "Borrower": {
        options = [
          {
            label: "Select All Lenders",
            options: [all],
          },
          {
            label: "Companies",
            options: [...sortedLenders],
          },
        ];
        break;
      }
      default:
        options = [];
        break;
    }

    return {
      options,
      lenders: sortedLenders,
    };
  }, [deal, whoami, myCompany]);

  const treeSelectData = useMemo(
    () => createTreeData(labelsTreeView, "value"),
    [labelsTreeView]
  );

  const onFinish = () => {};

  const onFinishFailed = (errorInfo) => {};

  const getValueFromEventReceiverCompanyIdsMessage = (value) => {
    if (value.includes(allLenders))
      return companyOptions.lenders.map((e) => e.value);
    return value;
  };

  return (
    <Modal
      open={open}
      title="Create Message"
      okText="Submit"
      cancelText="Cancel"
      onCancel={onCancel}
      afterClose={form.resetFields}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form
        form={form}
        autoComplete="off"
        layout="vertical"
        style={{ border: 0 }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label="Recipient"
          name="receiverCompanyIds"
          rules={[{ required: true, message: "'recipient' is required" }]}
          getValueFromEvent={getValueFromEventReceiverCompanyIdsMessage}
        >
          <Select
            showSearch
            allowClear
            mode="multiple"
            placeholder="Select here"
            style={{ borderRadius: 0 }}
            options={companyOptions.options}
          />
        </Form.Item>

        <Form.Item label="Subject" name="subject" rules={[{ required: true }]}>
          <Input placeholder="Type here" />
        </Form.Item>

        <Form.Item label="Message" name="message" rules={[{ required: true }]}>
          <TextArea
            hideLabel
            height={190}
            placeholder="Type your message here..."
            input={{
              value: form.getFieldValue("message"),
              onChange: (message) => {
                form.setFieldValue("message", message);
              },
            }}
            init={{
              content_style: "body { font-size:14px }",
            }}
          />
        </Form.Item>

        <Form.Item
          name="files"
          valuePropName="fileList"
          getValueFromEvent={normalizeFile}
        >
          <Upload name="logo" multiple beforeUpload={() => false}>
            <Button>
              Attach Files
              <PaperClipOutlined />
            </Button>
          </Upload>
        </Form.Item>

        <Form.Item label="Tags" name="labels" rules={[{ required: false }]}>
          <TreeSelect
            multiple
            showSearch
            style={{ width: "100%" }}
            dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
            placeholder="Select here"
            treeNodeFilterProp="title"
            allowClear
            treeDefaultExpandAll
            treeData={treeSelectData}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

function mapStateToProps(state) {
  return {
    whoami: state.auth.whoami,
    myCompany: state.company.myCompany,
    deal: state.deal.deal,
    labelsTreeView: state.label.labelsTreeView,
  };
}

export default connect(mapStateToProps)(CreateMessage);
