import React from "react";
import { Col, Row, Tooltip } from "antd";
import Spacer from "components/layout/Spacer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { dealViewIds } from "../DealViewContext";
import useCardHidden from "../hooks/useCardHidden";
import { useSelector } from "react-redux";
import NumberFormat from "react-number-format";

const PropertyDetails = ({ deal }) => {
  const {
    propertyName,
    propertyAddress,
    propertyCity,
    propertyState,
    propertyZip,
    propertyType,
    propertySize,
    propertyYearBuilt,
    propertyYearMostRecentRenovation,
    propertyNumberOfBuildings,
    propertyNumberOfFloors,
    propertyClass,
    parkingSpacesGarage,
    parkingSpacesCovered,
    parkingSpacesSurface,
    numberOfDriveInDoors,
    numberOfDockDoors,
    propertyMinimumClearHeight,
    columnHeight,
    officeSpacePercentage,
    cardHidden = [],
  } = deal;

  const whoami = useSelector((state) => state.auth.whoami);
  const { isHidden, toggleHidden } = useCardHidden({
    dealId: deal._id,
    cardHidden,
    cardId: dealViewIds.PropertyDetails,
  });

  if (whoami.role !== "Admin" && isHidden) return null;

  return (
    <section className={isHidden ? "tw-bg-gray-200" : ""}>
      <div className="tw-flex tw-space-x-3 tw-items-center">
        <h4>Property Details</h4>
        {whoami.role === "Admin" && (
          <span className="px-2 cursor-pointer">
            <Tooltip
              title={isHidden ? "Show this section" : "Hide this section"}
            >
              <FontAwesomeIcon
                icon={isHidden ? faEyeSlash : faEye}
                className="tw-text-gray-500"
                onClick={toggleHidden}
              />
            </Tooltip>
          </span>
        )}
      </div>
      <Spacer size={5} />

      <div className="border-bottom"></div>
      <Spacer size={20} />
      <h3 className="bold">{propertyName || "-"}</h3>
      <p className="font-12 color-medium-gray">Property Name</p>
      <Spacer size={20} />
      <p className="bold">
        {propertyAddress
          ? `${propertyAddress}, ${propertyCity}, ${propertyState}, ${propertyZip}`
          : "-"}
      </p>
      <p className="font-12 color-medium-gray">Address</p>
      <Spacer size={20} />
      <Spacer size={20} />
      <Row>
        {propertyYearBuilt && (
          <Col xs={6} md={4}>
            <p className="bold">
              <NumberFormat
                value={propertyYearBuilt}
                displayType={"text"}
                thousandSeparator={false}
              />
            </p>
            <p className="font-12 color-medium-gray">Property Built Year</p>
          </Col>
        )}
        {propertyClass && (
          <Col xs={6} md={4}>
            <p className="bold">{propertyClass}</p>
            <p className="font-12 color-medium-gray">Property Class</p>
          </Col>
        )}
        {propertyYearMostRecentRenovation && (
          <Col xs={6} md={4}>
            <p className="bold">
              <NumberFormat
                value={propertyYearMostRecentRenovation}
                displayType={"text"}
                thousandSeparator={false}
              />
            </p>
            <p className="font-12 color-medium-gray">Last Renovation</p>
          </Col>
        )}
      </Row>
      <Spacer size={20} />
      <Row>
        {propertyType && (
          <Col xs={6} md={4}>
            <p className="bold">{propertyType}</p>
            <p className="font-12 color-medium-gray">Property Type</p>
          </Col>
        )}
        {propertyType && (
          <Col xs={6} md={4}>
            <p className="bold">{propertyType}</p>
            <p className="font-12 color-medium-gray">Property Sub-Type</p>
          </Col>
        )}
        {propertySize && (
          <Col xs={6} md={4}>
            <p className="bold">
              <NumberFormat
                className="bold"
                value={propertySize}
                displayType={"text"}
                thousandSeparator={true}
              />
            </p>
            <p className="font-12 color-medium-gray">Property Size</p>
          </Col>
        )}
        {propertyNumberOfBuildings && (
          <Col xs={6} md={4}>
            <p className="bold">
              <NumberFormat
                value={propertyNumberOfBuildings}
                displayType={"text"}
                thousandSeparator={true}
              />
            </p>
            <p className="font-12 color-medium-gray">Number of Buildings</p>
          </Col>
        )}
        {propertyNumberOfFloors && (
          <Col xs={6} md={4}>
            <p className="bold">
              <NumberFormat
                value={propertyNumberOfFloors}
                displayType={"text"}
                thousandSeparator={true}
              />
            </p>
            <p className="font-12 color-medium-gray">Number of Floors</p>
          </Col>
        )}
      </Row>
      <Spacer size={20} />
      <Row>
        {(parkingSpacesGarage ||
          parkingSpacesCovered ||
          parkingSpacesSurface) && (
          <Col xs={6} md={4}>
            <p className="bold">
              <NumberFormat
                value={
                  parkingSpacesGarage +
                  parkingSpacesCovered +
                  parkingSpacesSurface
                }
                displayType={"text"}
                thousandSeparator={true}
              />
            </p>
            <p className="font-12 color-medium-gray">Total Parking</p>
          </Col>
        )}
        {parkingSpacesSurface && (
          <Col xs={6} md={4}>
            <p className="bold">
              <NumberFormat
                value={parkingSpacesSurface}
                displayType={"text"}
                thousandSeparator={true}
              />
            </p>
            <p className="font-12 color-medium-gray">Surface Parking</p>
          </Col>
        )}
        {parkingSpacesCovered && (
          <Col xs={6} md={4}>
            <p className="bold">
              <NumberFormat
                value={parkingSpacesCovered}
                displayType={"text"}
                thousandSeparator={true}
              />
            </p>
            <p className="font-12 color-medium-gray">Covered Parking</p>
          </Col>
        )}
        {parkingSpacesGarage && (
          <Col xs={6} md={4}>
            <p className="bold">
              <NumberFormat
                value={parkingSpacesGarage}
                displayType={"text"}
                thousandSeparator={true}
              />
            </p>
            <p className="font-12 color-medium-gray">Garage Parking</p>
          </Col>
        )}
      </Row>
      <Spacer size={20} />
      <Row>
        {propertyMinimumClearHeight && (
          <Col xs={6} md={4}>
            <p className="bold">
              <NumberFormat
                value={propertyMinimumClearHeight}
                displayType={"text"}
                thousandSeparator={true}
                suffix={" ft"}
              />
            </p>
            <p className="font-12 color-medium-gray">Minimum Clear Height</p>
          </Col>
        )}
        {columnHeight && (
          <Col xs={6} md={4}>
            <p className="bold">
              <NumberFormat
                value={columnHeight}
                displayType={"text"}
                thousandSeparator={true}
              />
            </p>
            <p className="font-12 color-medium-gray">Column Spacing</p>
          </Col>
        )}
        {numberOfDockDoors && (
          <Col xs={6} md={4}>
            <p className="bold">
              <NumberFormat
                value={numberOfDockDoors}
                displayType={"text"}
                thousandSeparator={true}
              />
            </p>
            <p className="font-12 color-medium-gray">Dock Doors</p>
          </Col>
        )}
        {numberOfDriveInDoors && (
          <Col xs={6} md={4}>
            <p className="bold">
              <NumberFormat
                value={numberOfDriveInDoors}
                displayType={"text"}
                thousandSeparator={true}
              />
            </p>
            <p className="font-12 color-medium-gray">Dock Doors</p>
          </Col>
        )}
        {officeSpacePercentage && (
          <Col xs={6} md={4}>
            <p className="bold">
              <NumberFormat
                value={officeSpacePercentage}
                displayType={"text"}
                thousandSeparator={false}
                suffix={"%"}
              />
            </p>
            <p className="font-12 color-medium-gray">Office Space</p>
          </Col>
        )}
      </Row>
    </section>
  );
};

export default PropertyDetails;
