import React, { useState } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { reduxFormSelect as Select } from 'components/form/ReduxForm';
import Spacer from 'components/layout/Spacer';
import { archiveFilters, statusFilters, defaultFilters } from 'containers/quotes/constants';

const FilterQuotes = (props) => {
    const [filters, setFilters] = useState(props.selectedFilters)
    const handleReset = () => {
        setFilters(defaultFilters);
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        props.onFiltersApply(filters);
    }
    return (
        <Form
            className="no-padding no-border"
            onSubmit={handleSubmit}
        >
            <Row>
                <Col xs="12" sm="6">
                    <Select
                        className="form-control"
                        name="status"
                        placeholder="Select..."
                        meta={{}}
                        input={{
                            value: filters.status,
                            onChange: (item) => setFilters({ ...filters, status: item }),
                        }}
                        options={Object.values(statusFilters)}
                        label="Status"
                    />
                </Col>
                <Col xs="12" sm="6">
                    <Select
                        className="form-control"
                        name="archived"
                        placeholder="Select..."
                        meta={{}}
                        input={{
                            value: filters.archived,
                            onChange: (item) => setFilters({ ...filters, archived: item }),
                        }}
                        options={Object.values(archiveFilters)}
                        label="Archived"
                    />
                </Col>
            </Row>
            <Row>
                <Col className="text-right" xs="12">
                    <Spacer size={50} />
                    <Button className="mr-3" variant="dark" onClick={handleReset}>
                        Reset
                    </Button>
                    <Button type="submit" variant="primary">
                        Apply
                    </Button>
                </Col>
            </Row>
        </Form>
    )
}
export default FilterQuotes;