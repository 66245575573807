import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Form, Container, Row, Col, Alert } from "react-bootstrap";
import { Field, reduxForm } from "redux-form";
import { required, email, passwordRule, noSpaces } from "utils/validation";
import { reduxFormInput } from "components/form/ReduxForm";
import {
  loginUser,
  resendVerification,
  verifyAccountStatus,
} from "redux/actions";
import Spacer from "components/layout/Spacer";
import Loader from "components/ui/Loader";
import TSLogo from "components/ui/TSLogo";
import withPageTitle from "components/hoc/withPageTitle";
import Welcome from "./Welcome";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

const NewLogin = (props) => {
  const [showWelcome, setShowWelcome] = React.useState(false);
  const [emailId, setEmail] = React.useState(undefined);
  const [showVerification, setShowVerification] = React.useState(false);
  useEffect(() => {
    if (props.auth.accountstatus.type === "active") {
      setShowWelcome(true);
    }
  }, [props.auth.accountstatus.type]);

  const submitFn = (data) => {
    setEmail(data.email);
    // setShowWelcome(true);
    // loginUser(data);
    verifyAccountStatus(data);
  };

  const onSendVerification = () => {
    resendVerification({ email: emailId });
    setShowVerification(true);
  };

  const onChangeEmail = () => {
    setEmail("");
    setShowWelcome(false);
    setShowVerification(false);
  };

  const { handleSubmit, action, auth } = props;
  const { VERIFY_ACCOUNT_STATUS, RESEND_VERIFICATION } = action;
  if (showWelcome) {
    return <Welcome setShowWelcome={onChangeEmail} email={emailId} />;
  }
  if (RESEND_VERIFICATION.isFetched) {
    return <SuccesVerification emailId={emailId} />;
  }
  const showError = auth.accountstatus.type === "unconfirmed";
  return (
    <>
      {(VERIFY_ACCOUNT_STATUS.isFetching || RESEND_VERIFICATION.isFetching) && (
        <Loader />
      )}
      <header className="border-bottom">
        <Spacer size={23} />
        <h1 className="text-center">Login</h1>
        <Spacer size={23} />
      </header>
      <Container>
        <Spacer size={50} />
        <Form className="auth-form" onSubmit={handleSubmit(submitFn)}>
          <Row className="justify-content-center">
            <Col className="d-flex justify-content-center" xs="6">
              <TSLogo blue title />
            </Col>
          </Row>
          {showError && (
            <>
              <Spacer size={50} />

              <Row className="justify-content-center">
                <Col xs={12} sm={6} className="text-center">
                  <div className="welcome-user-email">
                    {" "}
                    <FontAwesomeIcon
                      icon={faUser}
                      tabIndex="0"
                      className="font-20 mr-3"
                    />
                    {emailId}
                  </div>
                  {/* <div
                  onClick={this.onChangeEmail}
                  className="welcome-user-change tex-center"
                >
                  Change
                </div> */}
                </Col>
                <Col className="text-center" xs={12} sm={10}>
                  <Spacer size={50} />
                  <Alert variant="danger">
                    <div>
                      you already have a termstreet account, but email is not
                      verified !
                    </div>
                    <p>
                      Please{" "}
                      <span
                        className="welcome-error-link"
                        onClick={onSendVerification}
                      >
                        Click here{" "}
                      </span>
                      to verify your email
                    </p>
                  </Alert>
                </Col>
              </Row>
            </>
          )}
          {!showError && !showVerification && (
            <>
              <Row className="justify-content-center">
                <Col xs="12" className="text-center">
                  <Spacer size={30} />
                </Col>
                <Col xs="12" sm="6">
                  <Field
                    className="form-control"
                    component={reduxFormInput}
                    validate={[required, email, noSpaces]}
                    name="email"
                    type="email"
                    placeholder="Enter email"
                    label="Email"
                    normalize={(value) => value.toLowerCase()}
                  />
                </Col>
              </Row>
              <Spacer size={50} />
              <Row className="justify-content-center">
                <Col className="text-center" xs="12" sm="6">
                  <Button
                    variant="primary"
                    type="submit"
                    onClick={handleSubmit(submitFn)}
                  >
                    Next
                  </Button>
                  <Spacer size={50} />
                </Col>
                <Col xs="12" className="text-center">
                  <Link to="/register">
                    Don't have an account? Register now.
                  </Link>
                  <Spacer size={15} />
                </Col>
                <Col className="text-center" xs="12" sm="8">
                  <Link to="/resend-verification">
                    Didn’t get your verification email? Click here.
                  </Link>
                </Col>
              </Row>
            </>
          )}
        </Form>
        <Spacer size={50} />
      </Container>
    </>
  );
};

const SuccesVerification = (props) => (
  <>
    <header className="border-bottom">
      <Spacer size={23} />
      <h1 className="text-center">Verification</h1>
      <Spacer size={23} />
    </header>
    <Container>
      <Spacer size={50} />
      <Form className="auth-form">
        <Row className="justify-content-center">
          <Col className="d-flex justify-content-center" xs="6">
            <TSLogo blue title />
          </Col>
        </Row>
        <div className="text-center">
          <Spacer size={50} />
          <p className="bold">Thank you for verifying !</p>
          <p>
            An email has been sent to{" "}
            <Button className="fake-link" variant="fake-link">
              {props.emailId}.
            </Button>
          </p>
          <p>Please confirm your email to continue.</p>
        </div>
      </Form>
    </Container>
  </>
);
function mapStateToProps(state) {
  return {
    action: state.action,
    auth: state.auth,
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "login",
    enableReinitialize: true,
  })(withPageTitle(NewLogin, "Login"))
);
