import React, { Component } from "react";
import CategoryItem from "./CategoryItem";
import CategoryItemDropTarget from "./CategoryItemDropTarget";

export default class Tree extends Component {
  render() {
    const {
      nodes,
      getChildNodes,
      onCategorySelect,
      handleUploadFile,
      groupFolderFile,
      showSecuritySharing,
      fetchFolder,
      fetchFile,
      addFolder,
      removeFolder,
      showRenaming,
      fromComms,
      canManage,
      hideEmptyFolders,
      canHideNode
    } = this.props;
    return (
      <>
        {nodes.map((node) => {
          if (canHideNode(node)) {
            return null
          }
          return (<CategoryItemDropTarget
            key={node._id}
            _id={node._id}
            name={node.name}
            canHideNode={canHideNode}
            hideEmptyFolders={hideEmptyFolders}
            node={node}
            getChildNodes={getChildNodes}
            onToggle={onCategorySelect}
            handleUploadFile={handleUploadFile}
            groupFolderFile={groupFolderFile}
            fetchFile={fetchFile}
            fetchFolder={fetchFolder}
            addFolder={addFolder}
            removeFolder={removeFolder}
            showSecuritySharing={showSecuritySharing}
            showRenaming={showRenaming}
            fromComms={fromComms}
            canManage={canManage && !node.isUncategorized}
          />
          )
        })}
      </>
    );
  }
}
