import React from "react";
import { Col, Button, Row, Form } from "react-bootstrap";
import { Field, reduxForm } from "redux-form";
import { reduxFormInput, reduxFormTextarea } from "components/form/ReduxForm";
import { required } from "utils/validation";
import Spacer from "components/layout/Spacer";

class EditCategory extends React.Component {
  constructor(props) {
    super(props);
    this.rename = this.rename.bind(this);

    this.state = {
      isPublic: true,
      showDropdown: false,
      groupOptions: [],
      selectedLendersAndGroups: [],
      lenderOptions: this.getLenderOptions(),
    };
  }

  rename(data) {
    const { onSubmit, id } = this.props;
    onSubmit(id, data);
  }

  handlePublicToggle = (isPublic) => {
    this.setState({ isPublic });
  };

  handleSelectLendersAndGroups = (selectedLendersAndGroups) => {
    this.setState({ selectedLendersAndGroups });
  };

  getLenderOptions() {
    const { deal } = this.props;
    return (
      deal?.lendersAssigned?.map((e) => ({
        group: false,
        value: e.companyId,
        label: e.companyName,
        teamId: e.teamId,
      })) || []
    );
  }

  render() {
    const { handleSubmit } = this.props;

    return (
      <Form
        className="full-width no-border no-padding"
        onSubmit={handleSubmit(this.rename)}
      >
        <Row>
          <Col xs="12">
            <Field
              className="form-control"
              component={reduxFormInput}
              validate={[required]}
              name="name"
              type="text"
              placeholder="Enter category name..."
              label="Category Name"
            />
            <Spacer size={20} />
          </Col>
          <Col xs="12">
            <Field
              className="form-control"
              component={reduxFormTextarea}
              validate={[required]}
              name="description"
              type="text"
              placeholder="Enter category description..."
              label="Category Description"
              rows={3}
            />
            <Spacer size={20} />
          </Col>
          <Col className="text-right" xs="12">
            <Button type="submit" variant="primary">
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default reduxForm({
  form: "EditCategory",
  enableReinitialize: true,
})(EditCategory);
